import { createAction, props } from '@ngrx/store';
import { GetTagListRequest, GetTagListResponse, Tag } from '../../services/models/tag.models';

export const clearTagDetailStateAction = createAction(
  '[TagDetail] Clear',
);

// Add Tag
export const addTagAction = createAction(
  '[AddTag/API] Send Request',
  props<{ tagRequest: Tag }>(),
);

export const addTagSuccessAction = createAction(
  '[AddTag/API] Success',
);

export const addTagFailureAction = createAction(
  '[AddTag/API] Failure',
  props<{ error?: any }>(),
);

// Edit Tag
export const editTagAction = createAction(
  '[EditTag/API] Send Request',
  props<{ tagRequest: Tag }>(),
);

export const editTagSuccessAction = createAction(
  '[EditTag/API] Success',
);

export const editTagFailureAction = createAction(
  '[EditTag/API] Failure',
  props<{ error?: any }>(),
);

// Get List Tag
export const getTagListAction = createAction(
  '[TagList/API] Send Request',
  props<{ request: GetTagListRequest }>(),
);

export const getTagListActionSuccess = createAction(
  '[TagList/API] Success',
  props<{ response: GetTagListResponse }>(),
);

export const getTagListActionFailure = createAction(
  '[TagList/API] Failure',
  props<{ error?: any }>(),
);

// Remove Tag
export const removeTagAction = createAction(
  '[RemoveTag/API] Send Request',
  props<{ tagId: string }>()
);

export const removeTagSuccessAction = createAction(
  '[RemoveTag/API] Success',
);
export const removeTagFailureAction = createAction(
  '[RemoveTag/API] Failure',
  props<{ error?: any }>()
);

export const clearRemoveTagStateAction = createAction(
  '[RemoveTag] Clear'
);

export const clearGetTagListState = createAction(
  '[TagList/API] Clear'
);

