<a
  *ngIf="text"
  stopPropagation
  [href]="href ? link : 'javascript:void(0)'"
  class="link"
  [class.disable-link]="disableLink"
  (click)="onClickEntityReference()"
>
  {{ text }}
</a>
