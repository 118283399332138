export interface LookupTableList {
  metadataLookupTables: LookupTable[];
}

export enum LookupTableDefaultType {
  None,
  State,
  Country,
  Prefix
}

export interface LookupTable {
  id: string;
  name: string;
  totalValue: number;
  active: boolean;
  disabled?: boolean;
  defaultType: LookupTableDefaultType
}

export interface GetLookupTableQuery {
  SortNames: string;
  SortType: number;
}

export interface LookupTableDetail {
  id: string;
  name: string;
  active: boolean;
  disabled?: boolean;
  metadataLookupTableOptions: MetadataLookupTableOption[];
}

export interface MetadataLookupTableOption {
  id: string;
  code: string;
  description: string;
  longDescription?: string;
  longName?: string;
}


export interface LookupTableRequest {
  id?: string;
  name: string;
  active: boolean;
}

export interface CheckExistLookupTableQuery {
  id?: string;
  loopUpTableId?: string;
  name: string;
}

export interface LookupTableValueRequest {
  lookUpId?: string;
  name: string;
  code: string;
  longName?: string;
}

export interface ImportSessionDetail {
  batchId: string;
  dateUploaded: string;
  error?: string;
  fileName: string;
  fileSize: number;
  id: string;
  size: number;
  status: string;
}

export interface ReplaceValueBody {
  lookUpValueId: string;
  replacementValue?: string;
}


export interface DisplayFormatRes {
  metadataLookupTableDisplayFormats: MetaDataLookupTableDisplayFormat[];
}

export interface MetaDataLookupTableDisplayFormat {
  id?: string;
  order?: number;
  screenId: string;
  screenName: string;
  showDescription: boolean;
  showId: boolean;
  showLongDescription: boolean;
  lookupTableId?: string;
}

export interface ExitsDisplayFormatRequest {
  lookupTableId: string;
  displayFormats: MetaDataLookupTableDisplayFormat[];
}


export interface ExitsDisplayFormatRes {
  exists: boolean;
  message: string;
  displayFormats: DisplayFormatRes[]
}

export interface DisplayFormatRes {
  isExistsId: boolean;
  isExistsName: boolean;
  screenId: string,
  screenName: string,
}

export interface DisplayFormatDetailForm {
  screenId: string;
  screenName: string;
  showDescription: boolean;
  showId: boolean;
  showLongDescription: boolean;
}