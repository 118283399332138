import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { BaseComponent } from '@ptg-shared/components';
import {
  ACTION_COLUMN,
  Align,
  Column,
  GridComponent,
  ReorderInfo,
  Row,
  getValidatorsFromColumns,
} from '@ptg-shared/controls/grid';
import {
  COMMA_SEPARATOR,
  VERTICAL_LINE_SEPARATOR,
} from '@ptg-shared/constance/common.const';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import {
  ADMIN_SYSTEM,
  CANCEL_CONFIRM_MESSAGE,
  SORT_TYPE,
} from '@ptg-shared/constance/value.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { RadioOption } from '@ptg-shared/controls/radio-button/radio-button.component';
import { Option } from '@ptg-shared/controls/select/select.component';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';

import { EntityPropertyType, FixedPropertyKey } from '@ptg-entity-management/types/enums';
import { EntityPropertyDisplayConfiguration,
         EntitySectionConfig
} from '@ptg-entity-management/services/models';
import { CheckboxOption } from '@ptg-member/types/models';
import { SearchConfigurationDisplayConfig,
         SearchPropertyDisplayConfig
} from '@ptg-member/types/models/participant-search-configuration.model';
import { deepClone } from '@ptg-shared/utils/common.util';

@Component({
  selector: 'ptg-search-property-display-configuration',
  templateUrl: './search-property-display-configuration.component.html',
  styleUrls: ['./search-property-display-configuration.component.scss']
})
export class SearchPropertyDisplayConfigurationComponent extends BaseComponent {
  readonly ACTION_COLUMN = ACTION_COLUMN;
  ADMIN_SYSTEM = ADMIN_SYSTEM;
  @ViewChild('sortPropertyTable')
  gridview!: GridComponent<SearchConfigurationDisplayConfig>;
  @ViewChild('sortRowTable')
  gridviewRow!: GridComponent<SearchConfigurationDisplayConfig>;
  @Input() isParticipantSearchConfiguration: boolean = false;
  @Input() propertyConfigs: SearchPropertyDisplayConfig[] = [];
  @Input() propertyDisplayConfigurations: SearchConfigurationDisplayConfig[] = [];
  @Input() addPropertySection: EntitySectionConfig = {
    title: 'Add Property',
    columnName: 'Column Name',
    propertyName: 'Property Name',
  };
  @Input() sortPropertySection: EntitySectionConfig = { title: 'Sort Property' };
  @Input() sortRowSection!: EntitySectionConfig;
  @Input() columnNameMaxLength: number = 150;
  @Input() isMemberListConfiguration: boolean = false;
  @Input() sortable: boolean = true;

  @Input() canSelectMultipleOption: boolean = false;

  @Output() onSubmitEvent: EventEmitter<SearchConfigurationDisplayConfig[]> = new EventEmitter();
  @Output() formValueChange: EventEmitter<void> = new EventEmitter();

  get entityPropertyType() {
    return EntityPropertyType; 
  }

  orderColumns: Column[] = [
    {
      name: 'columnName',
      editable: true,
      truncate: true,
      validators: {
        required: {
          type: (obj: any) => Validators.required,
          message: (error: any, fieldName: string) =>
            `${fieldName} is required.`,
        },
        maxlength: {
          type: (obj: any) => Validators.maxLength(this.columnNameMaxLength),
          message: (error: any, fieldName: string) =>
            `Exceed the ${error.requiredLength} character limit.`,
        },
        existed: {
          type: (obj: any) => this.checkExits(obj),
          message: (error: any, fieldName: string) =>
            `${fieldName} already exists.`,
        },
      },
      controlArgs: {      
        placeholder: 'Column Name'
      },
    },
    {
      name: ACTION_COLUMN,
      align: Align.Right,
      width: '50px',
    },
  ];

  sortColumns: Column[] = [
    {
      name: 'columnName',
      truncate: true,
    },
    {
      name: ACTION_COLUMN,
      align: Align.Right,
      width: '50px',
    },
  ];

  ACTION = {
    ADD_SORT_ROW: 'addSortRow',
    EDIT_COLUMN_NAME: 'editColumnName',
    REMOVE: 'remove',
    SORT_CHANGE: 'sortChange',
  };
  sortPropertySectionDataTable: (SearchConfigurationDisplayConfig & Row)[] = [];
  sortRowSectionDataTable: (SearchConfigurationDisplayConfig & Row)[] = [];
  isLoading = true;
  formData: FormGroup = this.fb.group({
    entityPropertyId: this.fb.control('', [Validators.required]),
    option: '',
    columnName: this.fb.control('', [
      Validators.required,
      Validators.maxLength(this.columnNameMaxLength),
      this.checkDuplicated(),
    ]),
    propertyName: '',
    type: null,
    propertyNameEntityRef: null,
    entityId: null,
    propertyKey: null,
    entityReferencePropertyId: null,
    viewConfigType: null
  });
  availablePropertyConfigs!: Option[] | any[];
  propertyOptions: RadioOption[] = [];
  canSubmit: boolean = false;
  listEntityTypeData = [
    EntityPropertyType.Fixed,
    EntityPropertyType.Calculation,
    EntityPropertyType.System,
    EntityPropertyType.Aggregation,
    EntityPropertyType['Entity Reference'],
    EntityPropertyType.Identifier
  ];

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public route: ActivatedRoute
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.propertyConfigs || changes.propertyDisplayConfigurations) {
      this.getDataTable();
      this.getAvailablePropertyConfigs();
    }
    if (changes.columnNameMaxLength) {
      this.formData
        .get('columnName')
        ?.addValidators(Validators.maxLength(this.columnNameMaxLength));
    }

  }

  ngOnInit(): void {
    this.isLoading = false;
  }

  getDataTable() {
    if (!this.propertyConfigs?.length) {
      return;
    }
    this.propertyDisplayConfigurations =
      this.getPropertyDisplayConfigurations();
    this.sortPropertySectionDataTable = this.propertyDisplayConfigurations
      .map((config) => {
        const obj = {
          ...this.getPropertyDisplayConfig(config),
          order: config.orderColumn || config.order,
          isUsed: config.orderRow !== null || !this.sortRowSection,
        };
        return this.createInlineEditFormControls(obj);
      })
      .sort((a, b) => Number(a.order) - Number(b.order));
    this.sortRowSectionDataTable = this.propertyDisplayConfigurations
      .reduce((result, config) => {
        if (config.orderRow) {
          result.push({
            ...this.getPropertyDisplayConfig(config),
            order: config.orderRow,
            sortType: config.sortType,
          });
        }
        return result;
      }, [] as SearchConfigurationDisplayConfig[])
      .sort((a, b) => Number(a.order) - Number(b.order));
  }

  getPropertyDisplayConfigurations() {
    return this.propertyDisplayConfigurations.map((config) => {
      const cloneConfig = Object.assign({}, config); 
      const item = (this.propertyConfigs as SearchPropertyDisplayConfig[]).find(
        (property) => {
          return this.isExisted(
            config,
            {
              id: property.id,
            } as any,
            false
          );
        }
      ) as SearchPropertyDisplayConfig;
      if (item) {
        cloneConfig.propertyName = item.propertyName;
        cloneConfig.optionName = cloneConfig.option
          ?.split('|')
          .map(
            (el: any) => (item.options)?.find((item) => item.key === el)?.value
          )
          .join(', ');  
      }
      return cloneConfig;
    });
  }

  getType(entityPropertyType: EntityPropertyType): string {
    if (
      this.listEntityTypeData.indexOf(entityPropertyType) !== -1
    ) {
      return EntityPropertyType[entityPropertyType];
    } else {
      return 'Data';
    }
  }
  
  createDescription(formValue: any, optionName: string) {
    let columnNameDescription = ` ${this.getType(formValue.type)} / ${formValue.propertyName}
    ${ formValue.option ? ` / ${optionName}` : '' }`;
    if(formValue?.propertyNameEntityRef) {
      columnNameDescription = ` ${this.getType(54)} / ${formValue.propertyNameEntityRef} / ${formValue.propertyName} ${
        formValue.option ? ` / ${optionName}` : ''
        }`
    }
    return columnNameDescription;
  }

  getPropertyDisplayConfig(config: SearchConfigurationDisplayConfig) {
    const property = this.propertyConfigs.find(property => property.id === config.entityPropertyId || 
      (!property.type && property.propertyKey === config.propertyKey)) as any;
    const configs = {
      ...config,
      type: property?.type,
      propertyNameEntityRef: property?.propertyNameEntityRef
    };
    return {
      columnName: configs.columnName,
      columnNameDescription: this.createDescription(
        configs,
        config.optionName as string
      ),
      id: configs.id,
      type: property?.type,
      entityPropertyId: configs.entityPropertyId,
      propertyName: configs.propertyName,
      option: configs.option,
      propertyKey: configs.propertyKey,
      entityReferencePropertyId: configs.entityReferencePropertyId,
      viewConfigType: configs.viewConfigType
    };
  }

  getAvailablePropertyConfigs() {
    this.availablePropertyConfigs = (this.propertyConfigs as SearchPropertyDisplayConfig[]).reduce(
      (result: Option[] | any, propertyConfig: SearchPropertyDisplayConfig) => {
        const isExisted = this.propertyDisplayConfigurations.find((item) => {
          return this.isExisted(item, propertyConfig);
        });
        if (isExisted) {
          return result;
        }
        let valueDescription = ` ${this.getType(propertyConfig.type)}`;
        let displayValue = `${propertyConfig.propertyName}`;
        if(propertyConfig?.propertyNameEntityRef) {
          displayValue = `${propertyConfig.propertyName}`;
          valueDescription = ` ${this.getType(54)} / ${ propertyConfig.propertyNameEntityRef}`;
        }
        result.push({
          value: propertyConfig.id,
          displayValue: displayValue,
          valueDescription: valueDescription,
        });
        return result;
      },
      [] as Option[]
    );
  }

  changeItem(event: ReorderInfo, isSortColumn = false) {
    this.canSubmit = true;
    this.formValueChange.emit();
    if (isSortColumn) {
      this.sortPropertySectionDataTable = this.gridview.dataSource;
      return;
    }
    this.sortRowSectionDataTable = this.gridviewRow.dataSource;
  }

  onSoftDeleteSectionConfig(row: SearchConfigurationDisplayConfig & Row): void {
    row.deleted = true;
    const sortItem = this.sortPropertySectionDataTable.find(
      (item) =>
        item.entityPropertyId === row.entityPropertyId &&
        (!item.option || item.option === row.option)
    );
    if (sortItem) {
      sortItem.deleted = true;
    }

    const index = this.sortRowSectionDataTable.findIndex(
      (item) =>
        item.entityPropertyId === row.entityPropertyId &&
        (!item.option || item.option === row.option)
    );
    if (index > -1) {
      this.sortRowSectionDataTable[index].deleted = true;
      this.sortRowSectionDataTable = [...this.sortRowSectionDataTable];
    }
  }

  onRowActions(
    event: { row: SearchConfigurationDisplayConfig; type: string },
    isSortColumn = false
  ) {
    this.canSubmit = true;
    this.formValueChange.emit();
    switch (event.type) {
      case this.ACTION.ADD_SORT_ROW: {
        event.row.isUsed = true;
        this.addSortRow(event.row);
        const index = this.sortPropertySectionDataTable.findIndex(
          (item) =>
            item.entityPropertyId === event.row.entityPropertyId &&
            (!item.option || item.option === event.row.option)
        );
        if (index > -1) {
          this.sortPropertySectionDataTable[index].isUsed = true;
          this.sortPropertySectionDataTable = [...this.sortPropertySectionDataTable];
        }
        break;
      }
      case this.ACTION.SORT_CHANGE: {
        this.changeSortType(event.row);
        break;
      }
      case this.ACTION.REMOVE: {
        event.row.deleted = true;
        if (isSortColumn) {
          const sortItem = this.sortPropertySectionDataTable.find(
            (item) =>
              item.entityPropertyId === event.row.entityPropertyId &&
              (!item.option || item.option === event.row.option)
          );
          if (sortItem) {
            sortItem.deleted = true;
          }
        }

        const index = this.sortRowSectionDataTable.findIndex(
          (item) =>
            item.entityPropertyId === event.row.entityPropertyId &&
            (!item.option || item.option === event.row.option)
        );
        if (index > -1) {
          this.sortRowSectionDataTable[index].deleted = true;
          this.sortRowSectionDataTable = [...this.sortRowSectionDataTable];
        }
      }
    }
  }

  resetAddPropertyForm() {
    this.formData.reset();
    this.propertyOptions = [];
  }

  changeProperty() {
    const selectedConfig = (this.propertyConfigs as any).find(
      (config: SearchConfigurationDisplayConfig | SearchPropertyDisplayConfig) => {
        return (
          (config as SearchPropertyDisplayConfig)?.id ===
          this.formData.value.entityPropertyId
        );
      }
    );
    let formValue: any = {
      option: '',
      columnName: selectedConfig?.propertyName,
      type: selectedConfig.type,
      propertyNameEntityRef: selectedConfig.propertyNameEntityRef,
      entityPropertyId: selectedConfig.type ? selectedConfig.id : null,
    };
    const options: any[] = selectedConfig?.options;

    this.propertyOptions = (options || []).reduce(
      (result: any, currentValue: any) => {
        const selectedOptions = this.sortPropertySectionDataTable.reduce(
          (selectedOptionResult, currentItem) => {
            this.sortPropertySectionDataTable.forEach((property) => {
              if (
                property.entityPropertyId === selectedConfig.id || 
                (
                  property.propertyKey === FixedPropertyKey.RelateTo &&
                  selectedConfig?.propertyKey === FixedPropertyKey.RelateTo
                )
              ) {
                selectedOptionResult.push(
                  ...property.option.split(VERTICAL_LINE_SEPARATOR)
                );
              }
            });
            return selectedOptionResult;
          },
          [] as any[]
        );
        let selectedItem: any = {
          value: currentValue.key,
          label: currentValue.value,
        };
        const checked = !!selectedOptions.find(
          (selectedOption: any) => selectedOption === currentValue.key
        );
        if (this.canSelectMultipleOption) {
          selectedItem = {
            checked,
            disabled: checked,
            value: {
              propertyKey: currentValue.key,
              propertyName: currentValue.value,
            },
          } as any;
        } else if (options?.length > 1 && checked) {
          return result;
        }
        result.push(selectedItem);
        return result;
      },
      [] as any[]
    );
    const firstUncheckedItem = (this.propertyOptions as any[]).find(
      (propertyOption: any) =>
        !propertyOption.checked && !propertyOption.disabled
    );
    if (firstUncheckedItem) {
      firstUncheckedItem.checked = true;
    }
    formValue = {
      ...formValue,
      option: (this.canSelectMultipleOption
        ? firstUncheckedItem?.value?.propertyKey
        : firstUncheckedItem?.value) || '',
      propertyName: selectedConfig.propertyName,
      entityPropertyId: selectedConfig?.id,
      propertyNameEntityRef: selectedConfig?.propertyNameEntityRef,
      type: selectedConfig?.type,
      entityId: selectedConfig?.entityId,
      propertyKey: selectedConfig?.propertyKey,
      entityReferencePropertyId: selectedConfig?.propertyRefId,
      viewConfigType: selectedConfig?.viewConfigType,
    };
    if (this.propertyOptions.length) {
      this.formData.get('option')?.addValidators(Validators.required);
    } else {
      this.formData.get('option')?.removeValidators(Validators.required);
    }
    this.formData.patchValue(formValue);
  }

  addProperty() {
    this.formData.markAllAsTouched();
    if (!this.formData.valid) {
      return;
    }
    const formValue = this.formData.value;
    const optionName = this.getOptionName(formValue.option);
    const addedIndex = this.availablePropertyConfigs.findIndex(
      (item) =>
        item.value === formValue.entityPropertyId
    );
    const newRecord: SearchConfigurationDisplayConfig = {
      columnName: formValue.columnName,
      columnNameDescription: this.createDescription(formValue, optionName),
      order: this.sortPropertySectionDataTable?.length + 1,
      id: undefined,
      propertyName: this.availablePropertyConfigs[addedIndex]?.displayValue,
      viewConfigType: formValue?.viewConfigType,
      type: formValue.type,
      option: formValue.option,
      entityPropertyId: formValue.type ? formValue.entityPropertyId : null,
      optionName: this.availablePropertyConfigs[addedIndex]?.displayValue,
      isUsed: !this.sortRowSection,
      columnNameMaxLength: this.columnNameMaxLength,
      entityId: formValue?.entityId,
      propertyKey: formValue?.propertyKey,
      entityReferencePropertyId: formValue?.entityReferencePropertyId,
    };

    this.sortPropertySectionDataTable = [
      ...this.sortPropertySectionDataTable,
      this.createInlineEditFormControls(newRecord),
    ];

    this.setAvailablePropertyConfigs(addedIndex);
    this.resetAddPropertyForm();
    this.canSubmit = true;
    this.formValueChange.emit();
  }

  setAvailablePropertyConfigs(addedIndex: number) {
    const selectedPropertyOptions = this.propertyOptions
      .filter((item: any) => item.checked)
      .map((item: any) => item.value.propertyName);
    if (
      (this.canSelectMultipleOption || this.propertyOptions?.length > 1) &&
      !!this.propertyOptions?.length &&
      selectedPropertyOptions?.length < this.propertyOptions?.length
    ) {
      return;
    }
    if (
      !this.canSelectMultipleOption ||
      !this.propertyOptions ||
      this.propertyOptions.length <= 1 ||
      !this.isMemberListConfiguration
    ) {
      this.availablePropertyConfigs.splice(addedIndex, 1);
    }
  }

  getOptionName(options: string) {
    if (this.canSelectMultipleOption) {
      return (this.propertyOptions as any)
        .filter((item: any) =>
          options
            .split(VERTICAL_LINE_SEPARATOR)
            .includes(item.value.propertyKey)
        )
        .map((item: any) => item.value.propertyName)
        .join(COMMA_SEPARATOR);
    }
    return (this.propertyOptions as any).find(
      (item: RadioOption) => item.value === options
    )?.label;
  }

  addSortRow(config: SearchConfigurationDisplayConfig) {
    if (
      this.sortRowSectionDataTable.find(
        (item) =>
          item.entityPropertyId === config.entityPropertyId &&
          (!item.option || item.option === config.option)
      )
    ) {
      return;
    }
    this.canSubmit = true;
    this.formValueChange.emit();
    this.sortRowSectionDataTable = [
      ...this.sortRowSectionDataTable,
      {
        ...this.getPropertyDisplayConfig(config),
        columnNameDescription: config.columnNameDescription,
        order: this.sortRowSectionDataTable?.length + 1,
        sortType: this.sortable ? SORT_TYPE.ASC : undefined,
      },
    ];
  }

  getSubmitData() {
    let order = 1;
    const result: (SearchConfigurationDisplayConfig & Row)[] =
      this.sortPropertySectionDataTable.map(
        (item: SearchConfigurationDisplayConfig & Row) => {
          const obj = {
            id: item.id,
            entityPropertyId: item.entityPropertyId,
            option: item.option ? item.option : '',
            columnName: item.form?.value?.columnName,
            orderColumn: item.deleted ? -1 : order,
            entityId: item?.entityId,
            propertyKey: item?.propertyKey,
            entityReferencePropertyId: item?.entityReferencePropertyId,
            viewConfigType: item?.viewConfigType
          };
          order += item.deleted ? 0 : 1;
          return obj;
        }
      );
    order = 1;
    this.sortRowSectionDataTable.forEach((config) => {
      const index = result.findIndex(
        (item) =>
          item.entityPropertyId === config.entityPropertyId &&
          (!item.option || item.option === config.option)
      );
      if (index < 0) {
        return;
      }
      result[index].orderRow = config.deleted ? -1 : order;
      order += 1;
      result[index].sortType = config.sortType;
    });
    return result;
  }

  changeSortType(row: SearchConfigurationDisplayConfig) {
    const index = this.sortRowSectionDataTable.findIndex(
      (item) =>
        item.entityPropertyId === row.entityPropertyId &&
        (!item.option || item.option === row.option)
    );
    if (index < 0) {
      return;
    }
    this.sortRowSectionDataTable[index].sortType =
      this.sortRowSectionDataTable[index].sortType === SORT_TYPE.ASC
        ? SORT_TYPE.DESC
        : SORT_TYPE.ASC;
    this.sortRowSectionDataTable = [...this.sortRowSectionDataTable];
  }

  checkDuplicated(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.sortPropertySectionDataTable?.length) {
        return null;
      }
      if (
        this.sortPropertySectionDataTable?.find(
          (item: { columnName: string }) =>
            item.columnName?.trim()?.toLowerCase() ===
            control.value?.trim()?.toLowerCase()
        )
      ) {
        return {
          duplicatedValue: 'Column Name already exists.',
        };
      }
      return null;
    };
  }

  isExisted(
    item: SearchConfigurationDisplayConfig,
    config: SearchPropertyDisplayConfig,
    checkSelectMultipleTimes: boolean = true
  ) {
    const hasOptions =
      config.options instanceof Array &&
      config.options &&
      config.options.length > 1;
    const hasUnselectedOptions = !((config?.options || [])).some(
      (option: any) =>
      !this.propertyDisplayConfigurations.some(
        (property) =>
          (
            property.entityPropertyId === (config as any).id ||
            (property?.propertyKey === FixedPropertyKey.RelateTo && config.propertyKey === FixedPropertyKey.RelateTo)
          ) &&
          property.option
            ?.split(VERTICAL_LINE_SEPARATOR)
            ?.includes(option.key)
      )
    );

    const isExistedProperty =
      ((item as SearchConfigurationDisplayConfig).entityPropertyId ===
          (config as SearchPropertyDisplayConfig).id) ||
          (!item.type && item.propertyKey === (config as SearchPropertyDisplayConfig).id);
    const isAllOptionSelected = hasOptions
      ? hasUnselectedOptions
      : isExistedProperty;
    if (checkSelectMultipleTimes) {
      return (isAllOptionSelected);
    }
    return isAllOptionSelected;
  }

  onChangeOrderColumns() {
    this.canSubmit = true;
    this.formValueChange.emit();
  }

  private checkExits(obj: EntityPropertyDisplayConfiguration): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const allRows = [...this.sortPropertySectionDataTable];
      const existed = allRows.some(
        (item) =>
          !(
            item.entityPropertyId === obj.entityPropertyId &&
            (!item.option || item.option === obj.option)
          ) &&
          item.form?.value?.columnName?.toLowerCase()?.trim() ===
            control.value.toLowerCase().trim()
      );
      return existed ? { existed: true } : null;
    };
  }

  private createInlineEditFormControls(
    obj: EntityPropertyDisplayConfiguration
  ): EntityPropertyDisplayConfiguration {
    const result = {
      ...obj,
      form: this.fb.group({
        columnName: new FormControl(
          obj.columnName,
          getValidatorsFromColumns(
            this.orderColumns[0].name,
            this.orderColumns,
            obj
          )
        ),
      }),
    };
    return result;
  }


  selectSectionProperty() {
    const options = (this.propertyOptions as any).reduce(
      (result: string, propertyOption: CheckboxOption) => {
        if (propertyOption.checked && !propertyOption.disabled) {
          result +=
            (!result ? '' : VERTICAL_LINE_SEPARATOR) +
            propertyOption.value.propertyKey;
        }
        return result;
      },
      ''
    );
    this.formData.get('option')?.setValue(options);
  }
  
  onSubmit() {
    if (this.gridview.formStatus !== AbstractControlStatus.VALID) {
      return;
    }
    const result = deepClone(this.getSubmitData()).map(item => {
      if(item.entityReferencePropertyId) {
        item.entityPropertyId = item?.entityPropertyId?.split(VERTICAL_LINE_SEPARATOR)[0];
      }
      return item;
    });
    this.onSubmitEvent.emit(result);
    this.resetAddPropertyForm();
    this.canSubmit = false;
  }

  
  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: CANCEL_CONFIRM_MESSAGE,
        type: ConfirmType.Cancel,
        title: 'Cancel Action',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getDataTable();
        this.getAvailablePropertyConfigs();
        this.resetAddPropertyForm();
        this.canSubmit = false;
      }
    });
  }
}
