import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as StatusHistoryAction from '../../store/actions/status-history.action';
import {
  GetAuditTrailsResponse,
  MemberStatusList,
  StatusHistoryList,
} from '../../types/models';
import { StatusHistoryService } from '../../services/status-history.service';

@Injectable()
export class StatusHistoryEffects {
  getStatusHistoryList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StatusHistoryAction.getStatusHistoryList),
      switchMap(({ query }) => {
        return this.statusHistoryService.getStatusHistoryList(query).pipe(
          map((statusHistoryList: StatusHistoryList) => {
            return StatusHistoryAction.statusHistoryListSuccess({
              statusHistoryList,
            });
          }),
          catchError((err) => {
            return of(StatusHistoryAction.statusHistoryListFailure());
          })
        );
      })
    )
  );

  getMemberStatusList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StatusHistoryAction.getMemberStatusList),
      switchMap(() => {
        return this.statusHistoryService.getMemberStatusList().pipe(
          map((memberStatusList: MemberStatusList) => {
            return StatusHistoryAction.getMemberStatusListSuccess({
              listMemberStatus: memberStatusList.memberStatuses,
            });
          }),
          catchError((err) => {
            return of(StatusHistoryAction.getMemberStatusListFailure());
          })
        );
      })
    )
  );

  createMemberStatusHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StatusHistoryAction.createMemberStatusHistoryRequest),
      switchMap(({ body }) => {
        return this.statusHistoryService.createMemberStatus(body).pipe(
          map(() => {
            return StatusHistoryAction.createMemberStatusHistorySuccess();
          }),
          catchError((err) => {
            return of(StatusHistoryAction.createMemberStatusHistoryFailure());
          })
        );
      })
    )
  );

  editMemberStatusHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StatusHistoryAction.editMemberStatusHistoryRequest),
      switchMap(({ body }) => {
        return this.statusHistoryService.editMemberStatus(body).pipe(
          map(() => {
            return StatusHistoryAction.editMemberStatusHistorySuccess();
          }),
          catchError((err) => {
            return of(StatusHistoryAction.editMemberStatusHistoryFailure());
          })
        );
      })
    )
  );

  getAuditTrails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StatusHistoryAction.getAuditTrails),
      switchMap(({ request }) => {
        return this.statusHistoryService.getAuditTrails(request).pipe(
          map((response: GetAuditTrailsResponse) => {
            return StatusHistoryAction.getAuditTrailsSuccess({ response });
          }),
          catchError((error) => {
            return of(StatusHistoryAction.getAuditTrailsFailure({ error }));
          })
        );
      })
    )
  );
  constructor(
    private actions$: Actions,
    private statusHistoryService: StatusHistoryService
  ) {}
}
