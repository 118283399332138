import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

import { PaymentCurrent, ListPayment } from './models';

@Injectable()
export class PaymentInformationService {
  constructor(private httpClient: HttpClient) {}

  getCurrentPayment() {
    return this.httpClient.get<PaymentCurrent>(
      `${environment.apiUrl}/Payroll/PaymentInformation/NextPaymentDetail`
    );
  }

  getListPayment(query: any) {
    let params = new HttpParams();
    if (query.totalPerPage) {
      params = params.append('PageSize', query.totalPerPage);
    }
    params = params.append('PageIndex', query.pageIndex);

    if (query.sortField) {
      params = params.append(
        'SortNames',
        query.sortField[0].toUpperCase() + query.sortField.substring(1)
      );
      params = params.append('SortType', query.sortType ? query.sortType : 0);
    }
    return this.httpClient.get<ListPayment>(
      `${environment.apiUrl}/Payroll/PaymentInformation/PaymentDetailHistory`,
      { params }
    );
  }
}
