import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { InputType } from '@ptg-shared/controls/dynamic-input/types/enums/dynamic-input.enum';
import { ValidationOperator, ValidationType, FundType } from '@ptg-shared/types/enums';
import { DateValidationValue, FormControlDetail, PropValidation } from '@ptg-shared/types/models';

import { isEmpty, stringToBoolean } from '@ptg-shared/utils/string.util';

@Component({
  selector: 'ptg-dynamic-input',
  templateUrl: './dynamic-input.component.html',
  styleUrls: ['./dynamic-input.component.scss'],
})
export class DynamicInputComponent implements OnInit {
  InputType = InputType;
  @Input() controlField!: AbstractControl | FormControl;
  @Input() formControlDetail!: FormControlDetail;
  @Input() isAllowAction?: boolean;

  @Output() valueChange = new EventEmitter();

  datePropValidation: PropValidation[] = [];
  propValidation: string[] = [];
  dateValidationValue: DateValidationValue[] = [];
  isShowGranulation: boolean = false;

  ngOnInit(): void {
    this.getControlConfig();
  }

  dateValueChange(event: any) {
    this.valueChange.emit(event);
  }

  private getControlConfig() {
    const prop = this.formControlDetail;
    if (this.formControlDetail.type === InputType.Address || this.formControlDetail.type === InputType.Person_Name) {
      // Address type | Person name type
      this.formControlDetail = {
        ...this.formControlDetail,
        isDisabled: prop.isDisabled,
      } as FormControlDetail;
      return;
    }

    // One input type
    const formControlDetail: FormControlDetail = {
      ...this.formControlDetail,
      name: prop.name,
      label: prop.label,
      type: prop.type,
      formControl: this.controlField as FormControl,
    };

    // validate by property config
    const minValue =
      stringToBoolean(prop.config?.inclusiveInRange) &&
      this.isValidString(prop.config?.minInput) &&
      !isNaN(Number(prop.config?.minInput));
    const maxValue =
      stringToBoolean(prop.config?.inclusiveInRange) &&
      this.isValidString(prop.config?.maxInput) &&
      !isNaN(Number(prop.config?.maxInput));
    const maxLength =
      stringToBoolean(prop.config?.maximumLength) &&
      this.isValidString(prop.config?.maxLengthInput) &&
      !isNaN(Number(prop.config?.maxLengthInput));
    const fractionalLength =
      stringToBoolean(prop.config?.fractionalLength) &&
      this.isValidString(prop.config?.fractionalLengthInput) &&
      !isNaN(Number(prop.config?.fractionalLengthInput));

    formControlDetail.isRequired = prop.isRequired || stringToBoolean(prop.config?.required);

    if (minValue) {
      formControlDetail.min = prop.config?.minInput.trim();
    }
    if (maxValue) {
      formControlDetail.max = prop.config?.maxInput.trim();
    }
    if (fractionalLength) {
      const fractionalLength = Number(prop.config?.fractionalLengthInput);
      if (fractionalLength === 0) {
        prop.type = InputType.Whole_Number;
      } else {
        const rgx = new RegExp(`^[+-]?[0-9]{1,999999}(?:\\.[0-9]{0,${fractionalLength}})?$`);
        this.controlField.addValidators(Validators.pattern(rgx));
        formControlDetail.fractionalLength = fractionalLength.toString();
      }
    }

    this.getConfigByType(prop, formControlDetail);
    if (stringToBoolean(prop.config?.readOnly)) {
      this.controlField.disable();
    }

    formControlDetail.type = prop.type;
    formControlDetail.maxLength =
      formControlDetail.maxLength ?? (maxLength && !minValue && !maxValue ? prop.config?.maxLengthInput : 'none');
    formControlDetail.lstOption = prop.lstOption;
    formControlDetail.config = prop.config;
    formControlDetail.isDisabled = prop.isDisabled;
    this.formControlDetail = formControlDetail;
    this.isShowGranulation =
      formControlDetail.type === InputType.Text &&
      !isEmpty(formControlDetail.option) &&
      formControlDetail.option !== '';
  }

  private getConfigByType(prop: FormControlDetail, formControlDetail: FormControlDetail) {
    if (prop.type === InputType.Currency) {
      // @REVIEW: need to comment this one for data binding correct
      // this.controlField.setValue(null);
      return;
    }
    const fractionalLength =
      stringToBoolean(prop.config?.fractionalLength) &&
      this.isValidString(prop.config?.fractionalLengthInput) &&
      !isNaN(Number(prop.config?.fractionalLengthInput));
    if (prop.type === InputType.Decimal && !fractionalLength) {
      prop.type = InputType.Whole_Number;
      return;
    }

    if (prop.type === InputType.Percentage) {
      this.controlField.addValidators(Validators.min(Number(0)));
      formControlDetail.min = 0;

      this.controlField.addValidators(Validators.max(Number(100)));
      formControlDetail.max = 100;

      const rgx = new RegExp(`^[+-]?[0-9]{1,999999}(?:\\.[0-9]{0,${2}})?$`);
      this.controlField.addValidators(Validators.pattern(rgx));
      formControlDetail.fractionalLength = 2;
      return;
    }

    if (prop.type === InputType.DateTime) {
      let minDate: Date | undefined = undefined;
      let maxDate: Date | undefined = undefined;
      // this.controlField = new FormControl(new Date());
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      this.propValidation.push(prop.name);

      if (prop.config?.excludeFutureDates?.toLowerCase() == 'true') {
        currentDate.setHours(24);
        maxDate = currentDate;
      }

      if (prop.config?.excludePastDates?.toLowerCase() == 'true') {
        currentDate.setHours(-24);
        minDate = currentDate;
      }

      if (prop.config?.validation?.toLowerCase() == 'true') {
        formControlDetail.isValidation = true;
        const rules = JSON.parse(prop.config.dateValidationExpressions);
        if (rules?.length > 0) {
          rules.forEach((rule: any) => {
            switch (rule.validationType) {
              case ValidationType.Year:
                const yearValidation = +rule.value;
                if (yearValidation) {
                  const validationDate = new Date();
                  validationDate.setFullYear(validationDate.getFullYear() - yearValidation);
                  if (rule.validation === ValidationOperator.LessThan) {
                    let dateVal = new Date(validationDate);
                    dateVal.setHours(0);
                    if (minDate == undefined || dateVal > minDate) {
                      minDate = dateVal;
                    }
                  }

                  if (rule.validation === ValidationOperator.GreaterThan) {
                    let dateVal = new Date(validationDate);
                    dateVal.setHours(0);
                    if (maxDate == undefined || dateVal < maxDate) {
                      maxDate = dateVal;
                    }
                  }
                }
                break;
              case ValidationType.SpecificDate:
                const dateValidation = rule.value;
                if (dateValidation) {
                  if (rule.validation === ValidationOperator.LessThan) {
                    const dateVal = new Date(dateValidation);
                    dateVal.setHours(0);
                    if (maxDate == undefined || dateVal < maxDate) {
                      maxDate = dateVal;
                    }
                  }

                  if (rule.validation === ValidationOperator.GreaterThan) {
                    const dateVal = new Date(dateValidation);
                    dateVal.setHours(0);
                    if (minDate == undefined || dateVal > minDate) {
                      minDate = dateVal;
                    }
                  }
                }
                break;
              case ValidationType.Property:
                this.datePropValidation.push({
                  property: prop.name,
                  validationProp: rule.value,
                  operator: rule.validation,
                });
            }
          });
        }
      }

      this.dateValidationValue.push({
        property: prop.name,
        max: maxDate,
        min: minDate,
      });

      if (minDate) {
        this.setMinDate(formControlDetail, minDate);
      }

      if (maxDate) {
        this.setMaxDate(formControlDetail, maxDate);
      }
      this.setValidationMessage(formControlDetail);
      return;
    }

    // set list option for List type
    if (prop.type === InputType.Tier && prop.lstOption?.length) {
      prop.lstOption = prop.lstOption.map((x: any) => ({
        displayValue: x.text,
        value: x.id,
      }));
      return;
    }
    if (prop.type === InputType.List || prop.type === InputType.Lookup) {
      const options = prop.options?.length ? prop.options : prop.lstOption ?? [];
      prop.lstOption = (options as any[]).map((option) => {
        return {
          value: option.id ?? option.value,
          displayValue: option.text ?? option.displayValue,
        };
      });
      return;
    }
    if ((prop.type === InputType.Employer || prop.type === InputType.Department) && prop.lstOption?.length) {
      prop.lstOption = prop.lstOption.map((x: any) => ({
        displayValue: x.text,
        value: x.id,
        valueDescription: x.description,
      }));
    }
  }

  private isValidString(value: string) {
    return value?.trim();
  }

  private setMinDate(formControlDetail: FormControlDetail, validationDate: Date) {
    if (validationDate) {
      validationDate.setHours(24);
      formControlDetail.min = validationDate;
    }
  }

  private setMaxDate(formControlDetail: FormControlDetail, validationDate: Date) {
    if (validationDate) {
      validationDate.setHours(-24);

      formControlDetail.max = validationDate;
    }
  }

  private setValidationMessage(formControlDetail: FormControlDetail) {
    const min = formControlDetail.min as Date;
    const max = formControlDetail.max as Date;
    const current = new Date();

    min?.setHours(0, 0, 0, 0);
    max?.setHours(0, 0, 0, 0);
    current.setHours(0, 0, 0, 0);

    formControlDetail.minMessage = formControlDetail.maxMessage = 'The input does not meet the validation.';

    if (min?.getTime() === current.getTime()) {
      formControlDetail.minMessage = 'The input must be greater than today';
    }

    if (max?.getTime() === current.getTime()) {
      formControlDetail.maxMessage = 'The input must be less than today';
    }

    if (max && min && min.getTime() >= max.getTime()) {
      formControlDetail.maxMessage = formControlDetail.minMessage = 'No date satisfies the validation.';
    }
  }
  changeValue(event: any) {
    if (event?.invalid) {
      this.controlField.setErrors({ invalid: true });
      return;
    }
    this.controlField.setValue(event);
  }

  changeOptionData(event: any) {
    if (!this.isAllowAction) {
      return;
    }
    this.valueChange.emit(event);
  }
}
