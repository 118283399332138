import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Column } from '@ptg-shared/controls/grid';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { SignatoryService } from '@ptg-shared/services/signatory-service';
import { FileService } from '@ptg-shared/services/file-service';
import { PageEvent } from '@ptg-shared/controls/pagination';
import { BaseComponent } from '@ptg-shared/components';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';

import { SectionLayout } from '../../constance/metadata.const';
import { MemberNavigationResponse, MetadataSection } from '../../types/models';
import * as fromMember from '../../store/reducers';
import { MemberSectionAttachmentComponent } from '../member-section-attachment/member-section-attachment.component';
import { MemberManageAttachmentListComponent } from '../member-manage-attachment-list/member-manage-attachment-list.component';
import { DocumentsState } from 'src/app/admin/features/file/store/reducers';
import { clearGetDocumentDownloadStateAction, getDocumentDownloadAction } from 'src/app/admin/features/file/store/actions';

export interface AttachmentData {
  memberId: string;
  itemKey: string;
  configSection: MetadataSection;
  rowId: string;
  menuName: string;
}
@Component({
  selector: 'ptg-manage-member-attachment',
  templateUrl: './manage-member-attachment.component.html',
  styleUrls: ['./manage-member-attachment.component.scss'],
})
export class ManageMemberAttachmentComponent
  extends BaseComponent
  implements OnInit
{
  @Input() rowId: string = '';
  @Input() viewId: string = '';
  @Input() entityComponentId: string = '';
  @Input() isOverview: boolean = false;
  @Input() menuItemId: string = '';
  @Input() title: string = '';
  @Input() isEntity: boolean = false;
  @Input() dataTable: any = [];
  @Input() lengthPg!: number | any;
  @Input() pageSize!: number;
  @Input() pageNumber!: number;
  @Input() metadataSection?: MetadataSection;
  @Input() isLoading: boolean = true;
  @Input() cardName: string = ''

  @Output() pageChange = new EventEmitter<PageEvent>();
  memberId: string = '';
  unsubscribe$ = new Subject<void>();
  menuId?: string = '';
  sectionKey?: string = '';
  sectionId?: string = '';
  menuName?: string = '';
  sectionType?: SectionLayout;
  columns: Column[] = [
    {
      name: 'dateCreated',
      width: '205px',
    },
    {
      name: 'fileName',
      truncate: true,
    },
  ];
  listBreadcrumbs: Breadcrumb[] = [];
  specificDetailViewUrl: string = '';

  constructor(
    public dialog: MatDialog,
    private memberStore: Store<fromMember.MemberState>,
    private documentStore: Store<DocumentsState>,
    private signatoryService: SignatoryService,
    private fileService: FileService,
    public route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.getParamValue();
    if (this.isEntity) {
    } else {
      this.getMemberNavigationListState();
    }
  }

  getParamValue() {
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.menuId = params?.menuId;
      this.memberId = params.memberId;
    });
    this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((queryParams) => {
      if (!this.menuId && queryParams?.profileNavigate) {
        this.specificDetailViewUrl = `/member/detail-view/${this.isOverview}/${this.viewId}/${this.memberId}?profileNavigate=${queryParams?.profileNavigate}`;
      }
    });
  }

  getMemberNavigationListState() {
    this.memberStore
      .pipe(
        select(fromLayoutReducer.selectMemberNavigationListState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((memberNavigationList) => {
        const memberNavigationMenu: MemberNavigationResponse[] =
          memberNavigationList?.memberNavigationMenu;
        const menuItems = memberNavigationMenu
          ?.map((item) => {
            return item.menuItem;
          })
          ?.reduce((a, b) => {
            return a.concat(b);
          });

        if (this.menuId) {
          const menu = menuItems.find((item) => item.id === this.menuId);

          if (menu) {
            this.sectionKey = menu.itemKey;
            this.sectionId = menu.id;
            this.menuName = menu.name;
            this.sectionType = menu.type;
          }
        } else {
          this.sectionKey = this.metadataSection?.key;
          this.menuName = this.metadataSection?.name;
          this.sectionType = this.metadataSection?.type;
        }
      });
  }

  openListAttachments() {
    if (this.isLoading) {
      return;
    }
    if (this.isEntity) {
      this.listBreadcrumbs = [{
          name: this.title,
          url: `/member/detail-view/${this.isOverview}/${this.menuItemId}/${this.viewId}/${this.memberId}`
        },
        {
          name:'Documents'
        }
      ];
      this.dialog.open(MemberManageAttachmentListComponent, {
        panelClass: 'dialog-full-screen',
        disableClose: true,
        data: {
          memberId: this.memberId,
          breadcrumbs: this.listBreadcrumbs,
          recordId: this.rowId,
          menuName: this.menuName,
          viewId: this.viewId,
          entityComponentId: this.entityComponentId,
          specificDetailViewUrl: this.specificDetailViewUrl,
          cardName: this.cardName,
        },
      });
    } else {
      if (this.menuId) {
        this.listBreadcrumbs = [
          {
            name: this.menuName!,
            url: `/member/navigation/${this.sectionType}/${this.sectionId}/${this.memberId}`,
          },
          {
            name: 'Documents',
          },
        ];
      } else {
        this.listBreadcrumbs = [
          {
            name: this.menuName!,
            url: `/member/navigation/${SectionLayout.List}/${this.memberId}?itemKey=${this.sectionKey}&currentRowIndex=0&pageNumber=1&pageSize=50&isDragDrop=true`,
          },
          {
            name: 'Documents',
          },
        ];
      }
      this.dialog.open(MemberSectionAttachmentComponent, {
        panelClass: 'dialog-full-screen',
        disableClose: true,
        data: {
          memberId: this.memberId,
          configSection: this.metadataSection,
          breadcrumbs: this.listBreadcrumbs,
          isNavigationPage: true,
          rowId: this.rowId,
          menuName: this.menuName,
        },
      });
    }
  }

  onChangePage(event: PageEvent) {
    this.pageChange.emit(event);
  }

  openPDF(row: any) {
    if (!row) {
      return;
    }
    this.documentStore.dispatch(clearGetDocumentDownloadStateAction());
    this.documentStore.dispatch(
      getDocumentDownloadAction({ fileId: row?.id as string, fileName: row.fileName as string }),
    );
  }
}
