<div class="payment-list-container flex flex-col gap-6">
  <ptg-banner
    *ngIf="bannerType"
    [(bannerType)]="bannerType"
    [message]="message"
    [isHideCloseButton]="true"
  ></ptg-banner>

  <div class="box">
    <div class="title-container">
      <span>Next Payment Detail</span>
    </div>
    <ptg-grid
      [data]="paymentData"
      [columns]="columns"
      [errorMessage]="errorMsg"
      [isLoading]="isLoading"
      [paginable]="false"
      [fitToParent]="true"
      (rowClick)="selectPayment($event)"
      keyColumn="id"
    >
      <ng-template cellContent columnName="payingAgentError" let-row>
        <mat-icon *ngIf="row?.hasError" class="icon">error</mat-icon>
      </ng-template>
    </ptg-grid>
  </div>
</div>
