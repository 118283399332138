import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import * as fromReducer from '../../../shared/layout/reducers';
import { LayoutActions } from '@ptg-shared/layout/actions';

@Component({
  selector: 'ptg-bulk-content',
  templateUrl: './bulk-content.component.html',
  styleUrls: ['./bulk-content.component.scss']
})
export class BulkContentComponent implements OnInit {
  importKey: string = '';
  metadataKey: string = '';
  supportedFiles: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private store: Store<fromReducer.State>
  ) { }

  ngOnInit(): void {
    this.store.dispatch(LayoutActions.getReportCategorySidebar());

    combineLatest([
      this.route.params,
      this.store.select(fromReducer.selectBulkContentState)
    ]).subscribe(([params, bcs]) => {
      this.metadataKey = params.key;

      if(params.key === 'mixed') {
        this.importKey = "Mixed";
        this.supportedFiles = ['xls', 'xlsx', 'zip'];
      } 
      else if(bcs && bcs.items.length > 0) {
        var item = bcs.items.find(x => x.key === params.key) ?? { importKey:'', hasAttachment: true };
        this.importKey = item.importKey;

        if(item.hasAttachment) {
          this.supportedFiles = ['xls', 'xlsx', 'zip'];
        } else {
          this.supportedFiles = ['xls', 'xlsx'];
        }
      }
      
    });

  }

}
