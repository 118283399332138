import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '@ptg-shared/components';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { downloadFile } from '@ptg-shared/utils/common.util';
import { NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';

export interface PreviewPdfFileDialogData {
  file: string | Blob;
  fileName: string;
  getFileName?: () => string;
}

@Component({
  selector: 'ptg-preview-pdf-file-dialog',
  templateUrl: './preview-pdf-file-dialog.component.html',
  styleUrls: ['./preview-pdf-file-dialog.component.scss'],
})
export class PreviewPdfFileDialogComponent extends BaseComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PreviewPdfFileDialogData,
    public dialogRef: MatDialogRef<PreviewPdfFileDialogComponent>,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    public dialog: MatDialog,
    private pdfViewerService: NgxExtendedPdfViewerService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onCancel() {
    this.dialogRef.close();
  }

  async onPrint() {
    const fileContent = await this.pdfViewerService.getCurrentDocumentAsBlob();
    const fileName = this.data.getFileName ? this.data.getFileName() : this.data.fileName;
    downloadFile(fileContent, fileName);
  }
}
