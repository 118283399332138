<div class="dialog-container">
  <div class="header-title">Add New List</div>
  <form class="edit-form" [formGroup]="editForm">
    <div class="input-container">
      <ptg-textbox
        [controlField]="editForm.get('name')"
        placeholder="List Name"
        [hasLabel]="true"
        [isRequired]="true"
        [maxLength]="250"
        errorAsync="List Name or Import Label already exists."
        maxErrorMessage="Exceed the 250 characters limit."
      ></ptg-textbox>
    </div>

    <div class="row-button">
      <button
        mat-raised-button
        type="button"
        class="primary"
        (click)="formSubmit$.next(true)"
      >
        Create & Continue
      </button>
      <button
        mat-raised-button
        type="button"
        class="secondary"
        (click)="formSubmit$.next(false)"
      >
        Create & Add Another
      </button>
      <button
        mat-raised-button
        type="button"
        class="cancel-button"
        (click)="onCancel()"
      >
        Cancel
      </button>
    </div>
  </form>
</div>
