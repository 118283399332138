import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageEvent } from '@ptg-shared/controls/pagination';
import { Column, Align, ColumnType, ACTION_COLUMN } from '@ptg-shared/controls/grid';
import { ParameterType } from '../../constants';
import { MappingParameter, ParameterMappingSection } from '../../services/models';

@Component({
  selector: 'ptg-retirement-benefit-calculation-detail-parameter',
  templateUrl: './retirement-benefit-calculation-detail-parameter.component.html',
  styleUrls: ['./retirement-benefit-calculation-detail-parameter.component.scss']
})
export class RetirementBenefitCalculationDetailParameterComponent implements OnInit {
  @Input() parameterMappingSection!: ParameterMappingSection;
  columns: Column[] = [
    {
      name: 'isError',
      header: {
        title: '',
      },
      align: Align.Center,
      sortable: true,
      truncate: true,
      width: '65px'
    },
    {
      name: 'name',
      header: {
        title: 'Name',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: 'dataType',
      header: {
        title: 'Data type',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: 'mapping',
      header: {
        title: 'Mapping',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: 'description',
      header: {
        title: 'Description',
      },
      sortable: true,
      truncate: true,
    },
  ];

  @Output() sortChange = new EventEmitter();
  @Output() changePage = new EventEmitter();
  @Output() onEdit = new EventEmitter();
  @Output() onAdd = new EventEmitter();
  @Output() onRemove = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    if(this.parameterMappingSection.parameterType === ParameterType.Input) {
      this.columns.push({
        name: 'linkedProperty',
        header: {
          title: 'Linked property',
        },
        sortable: true,
        truncate: true,
      });
    }
    this.columns.push({
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      width: '170px'
    });
  }

  onSortChange(event: any) {
    this.sortChange.emit({ parameterType: this.parameterMappingSection.parameterType, event});
  }

  onChangePage(event: PageEvent) {
    this.changePage.emit({ parameterType: this.parameterMappingSection.parameterType, event});
  }

  onClickEdit(row: MappingParameter | any) {
    this.onEdit.emit(row);
  }

  onClickRemove(row: MappingParameter | any) {
    this.onRemove.emit(row);
  }

  onClickAddNew(parameterType?: ParameterType) {
    this.onAdd.emit(parameterType);
  }
}
