import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BulkUpdateLog } from '../models/bulk-update-log.model';
import { BulkUpdateHistoryData, BulkUpdateQuery } from '../models/bulk-update.model';

@Injectable({
  providedIn: 'root'
})
export class BulkUpdateService {

  constructor(private httpClient: HttpClient) {
  }

  getHistory(target: string, type: string, pagination: BulkUpdateQuery): Observable<BulkUpdateHistoryData> {
    let params = new HttpParams();
    params = params.append('PageSize', pagination.totalPerPage);
    params = params.append('PageIndex', pagination.pageIndex);

    if (pagination.sortField) {
      params = params.append('SortNames', pagination.sortField[0].toUpperCase() + pagination.sortField.substring(1));
      params = params.append('SortType', pagination.sortType ? pagination.sortType : 0);
    } else {
      params = params.append('SortNames', 'DateUploaded');
      params = params.append('SortType', 1);
    }

    return this.httpClient.get<BulkUpdateHistoryData>(`${ environment.apiUrl }/Imports/${ target }/${ type }`, { params });
  }

  getLogs(sessionId: string): Observable<BulkUpdateLog> {
    return this.httpClient.post<BulkUpdateLog>(`${ environment.apiUrl }/Imports/Logs`, { sessionId });
  }

  cancelSession(sessionId: string): Observable<any> {
    return this.httpClient.post(`${ environment.apiUrl }/Imports/${ sessionId }/Cancel`, {});
  }

  continueSession(sessionId: string): Observable<any> {
    return this.httpClient.post(`${ environment.apiUrl }/Imports/${ sessionId }/Proceed`, {});
  }

  removeHistory(sessionId: string): Observable<any> {
    return this.httpClient.delete(`${ environment.apiUrl }/Imports/${ sessionId }`);
  }

  validateExistsImport(source: string, sourceId: string): Observable<{exists: boolean, message: string}> {
    return this.httpClient.post<{exists: boolean, message: string}>(`${ environment.apiUrl }/Imports/${ source }/${ sourceId }/ValidateExistsImport`, {});
  }

  validateExistsAnotherImport(sessionId: string): Observable<{exists: boolean, message: string}> {
    return this.httpClient.post<{exists: boolean, message: string}>(`${ environment.apiUrl }/Imports/${sessionId}/ValidateExistsAnotherImport`, {});
  }

  getUniqueKey(sectionKey: string) {
    return this.httpClient.get(`${ environment.apiUrl }/Imports/${ sectionKey }/UniqueProperties`);
  }
}
