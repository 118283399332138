import { Injectable } from '@angular/core';
import { BaseComponent } from "@ptg-shared/components/base.component";
import { LayoutService } from "@ptg-shared/services/layout.service";
import { DEFAULT_PAGE_SIZE } from "@ptg-shared/constance";
import { PageEvent } from "@ptg-shared/controls/pagination";
import { takeUntil } from "rxjs/operators";

// This class is open to common implementation
// Please discuss with your team before implementing
@Injectable()
export abstract class BaseListComponent extends BaseComponent {
  PAGE_KEY: string = '';
  pageSize: number = DEFAULT_PAGE_SIZE;

  get pageSizeKey() {
    return `${this.layoutService.fundKey}-${this.PAGE_KEY}-page-size`;
  }

  get pageNumberKey() {
    return `${this.layoutService.fundKey}-${this.PAGE_KEY}-page-number`;
  }

  get selectedPageSize() {
    return Number(sessionStorage.getItem(this.pageSizeKey));
  }

  set selectedPageSize(pageSize: number) {
    sessionStorage.setItem(this.pageSizeKey, pageSize.toString());
  }

  protected constructor(
    public layoutService: LayoutService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getDefaultPageSize();
    this.checkFundChange();
  }

  checkFundChange() {
    this.layoutService.currentFund$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(currentFund => {
        if (currentFund) {
          this.getDefaultPageSize();
        }
      });
  }

  getDefaultPageSize() {
    this.pageSize = this.selectedPageSize || (this.layoutService.currentFund$.value.defaultPageSize ?? DEFAULT_PAGE_SIZE);
  }

  onChangePage(event: PageEvent): void {
    this.selectedPageSize = event.pageSize;
  }
}
