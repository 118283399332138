import { createAction, props } from '@ngrx/store';

import {
  IssueOffCyclePaymentRequest,
  MemberAddressItem,
  NextPaymentData,
  OffCyclesPayments,
  PaymentDeduction,
  PaymentDeductionQuery,
  PaymentHistoryListResponse,
  PaymentHistoryQuery,
  PaymentInforConfig,
  PaymentInforConfigData,
  PaymentNotesQuery,
  PendingPaymentListResponse,
  PendingPaymentQuery,
  RecalculateTotalDeductionsData,
  RepresentativePayee,
  SetOffCyclePaymentPayPeriodRequest,
} from '../../types/models';

export const NextPaymentRequest = '[NextPayment/API] Send Request';
export const NextPaymentFailure = '[NextPayment/API] Failure';
export const NextPaymentSuccess = '[NextPayment/API] Success';
export const AddNoteRequest = '[AddNote/API] Send Request';
export const AddNoteFailure = '[AddNote/API] Failure';
export const AddNoteSuccess = '[AddNote/API] Success';
export const UpdateEarningsRequest = '[UpdateEarnings/API] Send Request';
export const UpdateEarningsFailure = '[UpdateEarnings/API] Failure';
export const UpdateEarningsSuccess = '[UpdateEarnings/API] Success';
export const UpdatePayrollSettingsRequest =
  '[UpdatePayrollSettings/API] Send Request';
export const UpdatePayrollSettingsFailure =
  '[UpdatePayrollSettings/API] Failure';
export const UpdatePayrollSettingsSuccess =
  '[UpdatePayrollSettings/API] Success';
export const NextPaymentHistoryRequest =
  '[NextPaymentHistory/API] Send Request';
export const NextPaymentHistoryFailure = '[NextPaymentHistory/API] Failure';
export const NextPaymentHistorySuccess = '[NextPaymentHistory/API] Success';
export const ClearGetPaymentHistoryState =
  '[ClearGetPaymentHistoryState] Clear';
export const UpdateDeductionRetiredRequest =
  '[UpdateDeductionRetired/API] Send Request';
export const UpdateDeductionRetiredFailure =
  '[UpdateDeductionRetired/API] Failure';
export const UpdateDeductionRetiredSuccess =
  '[UpdateDeductionRetired/API] Success';
export const ClearNextPayment = '[NextPayment] Clear';
export const UpdatePaymentRequest = '[UpdatePayment/API] Send Request';
export const UpdatePaymentFailure = '[UpdatePayment/API] Failure';
export const UpdatePaymentSuccess = '[UpdatePayment/API] Success';
export const GetMemberAddressItemsRequest =
  '[GetMemberAddressItems/API] Send Request';
export const GetMemberAddressItemsFailure =
  '[GetMemberAddressItems/API] Failure';
export const GetMemberAddressItemsSuccess =
  '[GetMemberAddressItems/API] Success';
export const RecalculateTotalDeductionsRequest =
  '[RecalculateTotalDeductions/API] Send Request';
export const RecalculateTotalDeductionsFailure =
  '[RecalculateTotalDeductions/API] Failure';
export const RecalculateTotalDeductionsSuccess =
  '[RecalculateTotalDeductions/API] Success';
export const ClearTotalDeductionsState = '[ClearTotalDeductionsState] Clear';
export const UpdateMemoRequest = '[UpdateMemo/API] Send Request';
export const UpdateMemoFailure = '[UpdateMemo/API] Failure';
export const UpdateMemoSuccess = '[UpdateMemo/API] Success';
export const RemoveMemoRequest = '[RemoveMemo/API] Send Request';
export const RemoveMemoFailure = '[RemoveMemo/API] Failure';
export const RemoveMemoSuccess = '[RemoveMemo/API] Success';
export const NextPaymentNotesRequest = '[NextPaymentNotes/API] Send Request';
export const NextPaymentNotesFailure = '[NextPaymentNotes/API] Failure';
export const NextPaymentNotesSuccess = '[NextPaymentNotes/API] Success';
export const GetRepresentativePayeesRequest =
  '[GetRepresentativePayees/API] Send Request';
export const GetRepresentativePayeesFailure =
  '[GetRepresentativePayees/API] Failure';
export const GetRepresentativePayeesSuccess =
  '[GetRepresentativePayees/API] Success';
export const GetPendingPaymentRequest = '[GetPendingPayment/API] Send Request';
export const GetPendingPaymentFailure = '[GetPendingPayment/API] Failure';
export const GetPendingPaymentSuccess = '[GetPendingPayment/API] Success';
export const ClearGetPendingPaymentLoadingState =
  '[ClearGetPendingPaymentLoadingState] Clear';
export const ClearGetPendingPaymentState =
  '[ClearGetPendingPaymentState] Clear';
export const IssueOffCyclePayment = '[IssueOffCyclePayment/API] Send Request';
export const IssueOffCyclePaymentFailure = '[IssueOffCyclePayment/API] Failure';
export const IssueOffCyclePaymentSuccess = '[IssueOffCyclePayment/API] Success';
export const ClearIssueOffCyclePaymentState =
  '[ClearIssueOffCyclePaymentState] Clear';
export const GetPaymentDeductionsRequest =
  '[GetPaymentDeductions/API] Send Request';
export const GetPaymentDeductionsSuccess = '[GetPaymentDeductions/API] Success';
export const GetPaymentDeductionsFailure = '[GetPaymentDeductions/API] Failure';

export const CreateOffCyclePaymentRequest =
  '[CreateOffCyclePayment/API] Send Request';
export const CreateOffCyclePaymentSuccess =
  '[CreateOffCyclePayment/API] Success';
export const CreateOffCyclePaymentFailure =
  '[CreateOffCyclePayment/API] Failure';
export const ClearCreateOffCyclePaymentState =
  '[ClearCreateOffCyclePaymentState] Clear';

export const GetParticipantNextPaymentRequest =
  '[GetParticipantNextPayment/API] Send Request';
export const GetParticipantNextPaymentSuccess =
  '[GetParticipantNextPayment/API] Success';
export const GetParticipantNextPaymentFailure =
  '[GetParticipantNextPayment/API] Failure';

export const ReloadPendingPayments = '[ReloadPendingPayments] Reload';
export const ClearReloadPendingPaymentsState =
  '[ClearReloadPendingPaymentsState] Clear';

export const WithdrawOffCyclePaymentRequest =
  '[WithdrawOffCyclePayment/API] Send Request';
export const WithdrawOffCyclePaymentFailure =
  '[WithdrawOffCyclePayment/API] Failure';
export const WithdrawOffCyclePaymentSuccess =
  '[WithdrawOffCyclePayment/API] Success';

export const RemoveOffCyclePaymentRequest =
  '[RemoveOffCyclePayment/API] Send Request';
export const RemoveOffCyclePaymentSuccess =
  '[RemoveOffCyclePayment/API] Success';
export const RemoveOffCyclePaymentFailure =
  '[RemoveOffCyclePayment/API] Failure';

export const SavePaymentInforConfigRequest =
  '[SavePaymentInforConfig/API] Send Request';
export const SavePaymentInforConfigSuccess =
  '[SavePaymentInforConfig/API] Success';
export const SavePaymentInforConfigFailure =
  '[SavePaymentInforConfig/API] Failure';

export const GetPaymentInforConfigRequest =
  '[GetPaymentInforConfig/API] Send Request';
export const GetPaymentInforConfigSuccess =
  '[GetPaymentInforConfig/API] Success';
export const GetPaymentInforConfigFailure =
  '[GetPaymentInforConfig/API] Failure';

export const SetOffCyclePaymentPayPeriodRequestAction =
  '[SetOffCyclePaymentPayPeriod/API] Send Request';
export const SetOffCyclePaymentPayPeriodFailureAction =
  '[SetOffCyclePaymentPayPeriod/API] Failure';
export const SetOffCyclePaymentPayPeriodSuccessAction =
  '[SetOffCyclePaymentPayPeriod/API] Success';
export const ClearSetOffCyclePaymentPayPeriodStateAction =
  '[SetOffCyclePaymentPayPeriod] Clear';

export const getNextPayment = createAction(
  NextPaymentRequest,
  props<{ section: number; isHistory?: boolean; targetId?: string; benefitId?: string }>()
);
export const getNextPaymentSuccess = createAction(
  NextPaymentSuccess,
  props<{
    dataNextPayment: NextPaymentData;
    section: number;
    depositDate?: string | any;
    targetId?: string | any;
  }>()
);
export const getNextPaymentFailure = createAction(
  NextPaymentFailure,
  props<{ errorMsg: string }>()
);
export const addNote = createAction(
  AddNoteRequest,
  props<{ body: any; targetId?: string }>()
);
export const addNoteSuccess = createAction(AddNoteSuccess);
export const addNoteFailure = createAction(
  AddNoteFailure,
  props<{ errorMsg: string }>()
);

export const updateEarnings = createAction(
  UpdateEarningsRequest,
  props<{ body: any; targetId?: string }>()
);
export const updateEarningsSuccess = createAction(UpdateEarningsSuccess);
export const updateEarningsFailure = createAction(
  UpdateEarningsFailure,
  props<{ error?: any }>()
);

export const updatePayrollSettings = createAction(
  UpdatePayrollSettingsRequest,
  props<{ body: any; targetId?: string }>()
);
export const updatePayrollSettingsSuccess = createAction(
  UpdatePayrollSettingsSuccess
);
export const updatePayrollSettingsFailure = createAction(
  UpdatePayrollSettingsFailure,
  props<{ error?: any }>()
);
export const getNextPaymentHistory = createAction(
  NextPaymentHistoryRequest,
  props<{ query: PaymentHistoryQuery; memberId: string }>()
);
export const getNextPaymentHistorySuccess = createAction(
  NextPaymentHistorySuccess,
  props<{ response: PaymentHistoryListResponse }>()
);
export const getNextPaymentHistoryFailure = createAction(
  NextPaymentHistoryFailure,
  props<{ error?: any }>()
);
export const clearGetPaymentHistoryState = createAction(
  ClearGetPaymentHistoryState
);

export const updateDeduction = createAction(
  UpdateDeductionRetiredRequest,
  props<{ body: any; targetId?: string }>()
);
export const updateDeductionSuccess = createAction(
  UpdateDeductionRetiredSuccess
);
export const updateDeductionFailure = createAction(
  UpdateDeductionRetiredFailure,
  props<{ error?: any }>()
);

export const clearNextPayment = createAction(ClearNextPayment);

export const updatePayment = createAction(
  UpdatePaymentRequest,
  props<{ memberId: string; body: any; targetId?: string }>()
);
export const updatePaymentSuccess = createAction(UpdatePaymentSuccess);
export const updatePaymentFailure = createAction(
  UpdatePaymentFailure,
  props<{ errorMsg: string }>()
);

export const getMemberAddressItems = createAction(GetMemberAddressItemsRequest);
export const getMemberAddressItemsSuccess = createAction(
  GetMemberAddressItemsSuccess,
  props<{ memberAddressItems: MemberAddressItem[] }>()
);
export const getMemberAddressItemsFailure = createAction(
  GetMemberAddressItemsFailure,
  props<{ errorMsg: string }>()
);

export const recalculateTotalDeductions = createAction(
  RecalculateTotalDeductionsRequest,
  props<{ body: RecalculateTotalDeductionsData }>()
);
export const recalculateTotalDeductionsSuccess = createAction(
  RecalculateTotalDeductionsSuccess,
  props<{ totalDeductions: number }>()
);
export const recalculateTotalDeductionsFailure = createAction(
  RecalculateTotalDeductionsFailure,
  props<{ error?: any }>()
);
export const clearTotalDeductionsState = createAction(
  ClearTotalDeductionsState
);

export const updateMemo = createAction(
  UpdateMemoRequest,
  props<{ body: any }>()
);
export const updateMemoSuccess = createAction(UpdateMemoSuccess);
export const updateMemoFailure = createAction(
  UpdateMemoFailure,
  props<{ errorMsg: string }>()
);

export const removeMemo = createAction(
  RemoveMemoRequest,
  props<{ id: string }>()
);
export const removeMemoSuccess = createAction(RemoveMemoSuccess);
export const removeMemoFailure = createAction(
  RemoveMemoFailure,
  props<{ errorMsg: string }>()
);

export const getNotes = createAction(
  NextPaymentNotesRequest,
  props<{ query: PaymentNotesQuery; targetId?: string }>()
);
export const getNotesSuccess = createAction(
  NextPaymentNotesSuccess,
  props<{ paymentInfoNotes: any }>()
);
export const getNotesFailure = createAction(
  NextPaymentNotesFailure,
  props<{ errorMsg: string }>()
);

export const getRepresentativePayees = createAction(
  GetRepresentativePayeesRequest
);
export const getRepresentativePayeesSuccess = createAction(
  GetRepresentativePayeesSuccess,
  props<{ representativePayees: RepresentativePayee[] }>()
);
export const getRepresentativePayeesFailure = createAction(
  GetRepresentativePayeesFailure,
  props<{ errorMsg: string }>()
);

export const getPendingPaymentRequest = createAction(
  GetPendingPaymentRequest,
  props<{ query: PendingPaymentQuery }>()
);
export const getPendingPaymentSuccess = createAction(
  GetPendingPaymentSuccess,
  props<{ response: PendingPaymentListResponse }>()
);
export const getPendingPaymentFailure = createAction(
  GetPendingPaymentFailure,
  props<{ error?: any }>()
);
export const clearGetPendingPaymentLoadingState = createAction(
  ClearGetPendingPaymentLoadingState
);
export const clearGetPendingPaymentState = createAction(
  ClearGetPendingPaymentState
);

export const issueOffCyclePayment = createAction(
  IssueOffCyclePayment,
  props<{ body: IssueOffCyclePaymentRequest }>()
);
export const issueOffCyclePaymentSuccess = createAction(
  IssueOffCyclePaymentSuccess
);
export const issueOffCyclePaymentFailure = createAction(
  IssueOffCyclePaymentFailure,
  props<{ error: any }>()
);

export const clearIssueOffCyclePaymentState = createAction(
  ClearIssueOffCyclePaymentState
);
export const getPaymentDeductionsRequest = createAction(
  GetPaymentDeductionsRequest,
  props<{ query: PaymentDeductionQuery; clientId: string }>()
);
export const getPaymentDeductionsSuccess = createAction(
  GetPaymentDeductionsSuccess,
  props<{ paymentDeductions: PaymentDeduction[] }>()
);
export const getPaymentDeductionsFailure = createAction(
  GetPaymentDeductionsFailure
);

export const createOffCyclePaymentRequest = createAction(
  CreateOffCyclePaymentRequest,
  props<{ body: OffCyclesPayments }>()
);
export const createOffCyclePaymentSuccess = createAction(
  CreateOffCyclePaymentSuccess,
  props<{ newOffCyclePaymentId: string }>()
);
export const createOffCyclePaymentFailure = createAction(
  CreateOffCyclePaymentFailure
);
export const clearCreateOffCyclePaymentState = createAction(
  ClearCreateOffCyclePaymentState
);

export const reloadPendingPayments = createAction(
  ReloadPendingPayments,
  props<{ isReload: boolean; selectedRowId?: string; resetSort?: boolean }>()
);
export const clearReloadPendingPaymentsState = createAction(
  ClearReloadPendingPaymentsState
);

export const getParticipantNextPaymentRequest = createAction(
  GetParticipantNextPaymentRequest,
  props<{ section?: number }>()
);
export const getParticipantNextPaymentSuccess = createAction(
  GetParticipantNextPaymentSuccess,
  props<{ participantNextPaymentSetting: NextPaymentData }>()
);
export const getParticipantNextPaymentFailure = createAction(
  GetParticipantNextPaymentFailure,
  props<{ errorMsg: string }>()
);

export const withdrawOffCyclePayment = createAction(
  WithdrawOffCyclePaymentRequest,
  props<{ offCyclePaymentId: string }>()
);
export const withdrawOffCyclePaymentSuccess = createAction(
  WithdrawOffCyclePaymentSuccess
);
export const withdrawOffCyclePaymentFailure = createAction(
  WithdrawOffCyclePaymentFailure,
  props<{ errorMsg: string }>()
);

export const removeOffCyclePaymentRequest = createAction(
  RemoveOffCyclePaymentRequest,
  props<{ offCyclePaymentId: string }>()
);
export const removeOffCyclePaymentSuccess = createAction(
  RemoveOffCyclePaymentSuccess
);
export const removeOffCyclePaymentFailure = createAction(
  RemoveOffCyclePaymentFailure
);

export const savePaymentInforConfig = createAction(
  SavePaymentInforConfigRequest,
  props<{ paymentInforConfig: PaymentInforConfig[] }>()
);
export const savePaymentInforConfigSuccess = createAction(
  SavePaymentInforConfigSuccess
);
export const savePaymentInforConfigFailure = createAction(
  SavePaymentInforConfigFailure
);

export const getPaymentInforConfig = createAction(GetPaymentInforConfigRequest);
export const getPaymentInforConfigSuccess = createAction(
  GetPaymentInforConfigSuccess,
  props<{ paymentInforConfigData: PaymentInforConfigData[] }>()
);
export const getPaymentInforConfigFailure = createAction(
  GetPaymentInforConfigFailure
);

export const setOffCyclePaymentPayPeriod = createAction(
  SetOffCyclePaymentPayPeriodRequestAction,
  props<{ id: string; body: SetOffCyclePaymentPayPeriodRequest }>()
);
export const setOffCyclePaymentPayPeriodSuccess = createAction(
  SetOffCyclePaymentPayPeriodSuccessAction,
);
export const setOffCyclePaymentPayPeriodFailure = createAction(
  SetOffCyclePaymentPayPeriodFailureAction,
  props<{ error?: any }>()
);
export const clearSetOffCyclePaymentPayPeriodState = createAction(
  ClearSetOffCyclePaymentPayPeriodStateAction
);