import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(phone: any) {
    if (phone && (/^([0-9]{10})$/).test(phone?.trim())) {
      let match = phone.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        phone = '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
    }
    return phone;
  }

}
