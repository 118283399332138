import { createAction, props } from '@ngrx/store';
import {
  GetCardQuery,
  GetListCardResponse,
  GetViewListResponse,
  NewView,
  RemoveAccessResponse,
  SetViewDetailwRequest,
  ViewDetail,
  ViewDataless,
} from '@ptg-member/types/models';
import { RolesQuery } from 'src/app/access-management/models/roles.model';
import { GetEntitiesQuery, GetEntitiesResponse } from 'src/app/entity-management/services/models';

export const getViewList = createAction(
  '[ViewList/API] Send Request',
  props<{ query: RolesQuery }>()
);
export const getViewListSuccess = createAction(
  '[ViewList/API] Success',
  props<{ viewList: GetViewListResponse }>()
);
export const getViewListFailure = createAction(
  '[ViewList/API] Failure',
  props<{ error?: any }>()
);

export const removeView = createAction(
  '[RemoveView/API] Send Request',
  props<{ id: string }>()
);
export const removeViewSuccess = createAction(
  '[RemoveView/API] Success'
);
export const removeViewFailure = createAction(
  '[RemoveView/API] Failure',
  props<{ errorMsg: string }>()
);

export const getViewDetail = createAction(
  '[ViewDetail/API] Send Request',
  props<{ id: string }>()
);
export const getViewDetailSuccess = createAction(
  '[ViewDetail/API] Success',
  props<{ viewDetail: ViewDetail }>()
);
export const getViewDetailFailure = createAction(
  '[ViewDetail/API] Failure',
  props<{ error?: any }>()
);

export const getCardList = createAction(
  '[CardList/API] Send Request',
  props<{ query: GetCardQuery }>()
);
export const getCardListSuccess = createAction(
  '[CardList/API] Success',
  props<{ cardList: GetListCardResponse }>()
);
export const getCardListFailure = createAction(
  '[CardList/API] Failure',
  props<{ error?: any }>()
);

export const createView = createAction(
  '[CreateView/API] Send Request',
  props<{ body: NewView }>()
);
export const createViewSuccess = createAction(
  '[CreateView/API] Success',
  props<{ id: string }>()
);
export const createViewFailure = createAction(
  '[CreateView/API] Failure',
  props<{ errorMsg: string }>()
);

export const editView = createAction(
  '[EditView/API] Send Request',
  props<{ body: SetViewDetailwRequest }>()
);
export const editViewSuccess = createAction(
  '[EditView/API] Success'
);
export const editViewFailure = createAction(
  '[EditView/API] Failure',
  props<{ errorMsg: string }>()
);

export const updateView = createAction(
  '[UpdateView/API] Send Request',
  props<{ id: string; body: SetViewDetailwRequest }>()
);
export const updateViewSuccess = createAction('[UpdateView/API] Success');
export const updateViewFailure = createAction(
  '[UpdateView/API] Failure',
  props<{ errorMsg: string }>()
);

export const getEntitiesAction = createAction(
  '[GetViewEntities/API] Send Request',
  props<{ query: GetEntitiesQuery }>()
);
export const getEntitiesSuccessAction = createAction(
  '[GetViewEntities/API] Success',
  props<{ response: GetEntitiesResponse }>()
);
export const getEntitiesFailureAction = createAction(
  '[GetViewEntities/API] Failure',
  props<{ error?: any }>()
);

export const getRemoveAccesssAction = createAction(
  '[GetRemoveAccess/API] Send Request',
  props<{ id: string }>()
);
export const getRemoveAccessSuccessAction = createAction(
  '[GetRemoveAccess/API] Success',
  props<{ response: RemoveAccessResponse }>()
);
export const getRemoveAccessFailureAction = createAction(
  '[GetRemoveAccess/API] Failure',
  props<{ error?: any }>()
);

export const clearViewState = createAction(
  '[ClearViewState/API] Failure',
);

export const getViewDataless = createAction(
  '[ViewDataless/API] Send Request',
  props<{ id: string }>()
);
export const getViewDatalessSuccess = createAction(
  '[ViewDataless/API] Success',
  props<{ viewDataless: ViewDataless }>()
);
export const getViewDatalessFailure = createAction(
  '[ViewDataless/API] Failure',
  props<{ error?: any }>()
);


export const updateViewDataless = createAction(
  '[UpdateViewDataless/API] Send Request',
  props<{ id: string; body: ViewDataless }>()
);
export const updateViewDatalessSuccess = createAction('[UpdateViewDataless/API] Success');
export const updateViewDatalessFailure = createAction(
  '[UpdateViewDataless/API] Failure',
  props<{ errorMsg: string }>()
);