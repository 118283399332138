import { Action, combineReducers } from '@ngrx/store';

import { EntityDataState, entityDataReducer } from './entity-data.reducer';
import {
  EntityListViewConfigurationState,
  entityListViewConfigurationReducer,
} from './entity-list-view-configuration.reducer';
import { EntityProfileConfigState, entityProfileConfigReducer } from './entity-profile-config.reducer';
import { EntityPropertyState, entityPropertyReducer } from './entity-property.reducer';
import { EntityViewState, entityViewReducer } from './entity-view.reducer';
import { EntityState, entityReducer } from './entity.reducer';

export interface EntityManagementState {
  entityData: EntityDataState;
  entityListViewConfiguration: EntityListViewConfigurationState;
  entityProfileConfig: EntityProfileConfigState;
  entityProperty: EntityPropertyState;
  entityView: EntityViewState;
  entity: EntityState;
}

export function entityManagementReducer(state: EntityManagementState | undefined, action: Action) {
  return combineReducers({
    entityData: entityDataReducer,
    entityListViewConfiguration: entityListViewConfigurationReducer,
    entityProfileConfig: entityProfileConfigReducer,
    entityProperty: entityPropertyReducer,
    entityView: entityViewReducer,
    entity: entityReducer,
  })(state, action);
}

export * from './entity-data.reducer';
export * from './entity-list-view-configuration.reducer';
export * from './entity-profile-config.reducer';
export * from './entity-property.reducer';
export * from './entity-view.reducer';
export * from './entity.reducer';
