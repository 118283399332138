import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import {
  ACTION_COLUMN,
  Align,
  Column,
  getValidatorsFromColumns,
  GridComponent,
  ReorderInfo,
  Row,
} from '@ptg-shared/controls/grid';
import { BaseComponent } from '@ptg-shared/components';
import { deepClone } from '@ptg-shared/utils/common.util';
import { CANCEL_CONFIRM_MESSAGE } from '@ptg-shared/constance';
import { Option } from '@ptg-shared/controls/select/select.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';

import { take, takeUntil } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import {
  CalculationState,
  getBenefitCalculationParameterMappingAction,
  getBenefitCalculationParameterMappingSelector,
  setModifyCalculationDisplayConfigurationSelector,
} from '../../store';

import {
  DisplayOutputsConfiguration,
  ModifiedDisplayOutputsConfiguration,
  ParameterMapping,
} from '../../services/models';
import { GetParameterMappingType } from '../../types/enums';

@Component({
  selector: 'ptg-add-output-configuration',
  templateUrl: './add-output-configuration.component.html',
  styleUrls: ['./add-output-configuration.component.scss'],
})
export class AddOutputConfigurationComponent extends BaseComponent implements OnChanges {
  @ViewChild('sortPropertyTable')
  gridView!: GridComponent<DisplayOutputsConfiguration>;

  @Input() isLoading = false;
  @Input() labelMaxLength = 150;
  @Input() outputOptions!: Option[];
  @Input() configurations: DisplayOutputsConfiguration[] = [];
  @Input() configSectionLabel = '';
  @Input() displayId = '';

  @Output() onSubmitEvent: EventEmitter<ModifiedDisplayOutputsConfiguration> = new EventEmitter();
  @Output() formValueChange: EventEmitter<void> = new EventEmitter();

  readonly ACTION_COLUMN = ACTION_COLUMN;

  canSubmit = false;
  isDragDrop = false;
  sectionLabel = '';
  formData: FormGroup = this.initFormData;
  orderOutputDataTable: (DisplayOutputsConfiguration & Row)[] = [];
  orderColumns: Column[] = this.initOrderColumns;
  listOutputOptions: Option[] = [];
  constructor(
    public readonly router: Router,
    public readonly dialog: MatDialog,
    public readonly calculationStore: Store<CalculationState>,
    private readonly fb: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    this.selectSetModifyCalculationDisplayConfigurationState();
  }

  private get initFormData(): FormGroup {
    return this.fb.group({
      calculationDisplayId: '',
      calculationParameterMappingId: '',
      parameterName: this.fb.control('', [Validators.required]),
      label: this.fb.control('', [Validators.required, Validators.maxLength(this.labelMaxLength)]),
      configSectionLabel: this.fb.control('', [Validators.required, Validators.maxLength(this.labelMaxLength)]),
      displayId: '',
      editable: '',
      benefitTypeName: '',
      benefitSubTypeName: '',
    });
  }

  private get initOrderColumns(): Column[] {
    return [
      {
        name: 'label',
        editable: true,
        truncate: true,
        validators: {
          required: {
            type: () => Validators.required,
            message: (error: any, fieldName: string) => `${fieldName} is required.`,
          },
          maxlength: {
            type: () => Validators.maxLength(this.labelMaxLength),
            message: (error: any, fieldName: string) => `Exceed the ${error.requiredLength} character limit.`,
          },
        },
        controlArgs: {
          placeholder: 'Output Label',
          maxLength: 150,
        },
      },
      {
        name: ACTION_COLUMN,
        align: Align.Right,
        width: '50px',
      },
    ];
  }

  onSubmit(): void {
    if (this.gridView.formStatus !== AbstractControlStatus.VALID) {
      return;
    }

    const outputData = {
      label: this.formData.get('configSectionLabel')?.value,
      displayOutputs: this.getSubmitData().map(({ description, configSectionLabel, ...rest }) => rest),
    };

    const isNotAllowSave = outputData.displayOutputs.some((item, index) => {
      const originIndex = this.configurations.findIndex((config) => config.id === item.id);
      const originalData = deepClone(this.configurations[originIndex]);
      return (
        item.isInitiatedOrPendingApproval &&
        (item.deleted || index !== originIndex || originalData.label !== item.label)
      );
    });

    if (isNotAllowSave) {
      this.dialog.open(ConfirmPopupComponent, {
        panelClass: 'confirm-popup',
        data: {
          text: 'Unable to remove output when Benefit Option is already initiated.',
          title: 'Error',
          type: ConfirmType.Warning,
          cancelButtonTitle: 'Close',
          hideConfirmButton: true,
        },
      });
      return;
    }

    this.onSubmitEvent.emit(outputData);
    this.resetAddConfigForm();
    this.canSubmit = false;
  }

  onCancel(): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: CANCEL_CONFIRM_MESSAGE,
        type: ConfirmType.Cancel,
        title: 'Cancel Action',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: boolean) => {
        if (result) {
          this.getDataTable();
          this.resetAddConfigForm();
          this.canSubmit = false;
        }
      });
  }

  private selectSetModifyCalculationDisplayConfigurationState() {
    this.calculationStore
      .select(setModifyCalculationDisplayConfigurationSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result?.success) {
          this.getBenefitCalculationParameterData();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.displayId) {
      this.formData.get('displayId')?.patchValue(this.displayId);
    }
    if (changes.configurations) {
      this.getDataTable();
    }
    if (changes.outputOptions) {
      this.getOutputOptions();
    }
    if (changes.labelMaxLength) {
      this.formData.get('label')?.addValidators(Validators.maxLength(this.labelMaxLength));
      this.formData.get('configSectionLabel')?.addValidators(Validators.maxLength(this.labelMaxLength));
    }
  }

  onChangeSectionLabel(): void {
    this.canSubmit = true;
  }

  onChangeOrderColumns(): void {
    this.canSubmit = true;
    this.formValueChange.emit();
  }

  onChangeRow(event: ReorderInfo, isSortColumn = false): void {
    this.canSubmit = true;
    this.formValueChange.emit();
    if (isSortColumn) {
      this.orderOutputDataTable = this.gridView.dataSource;
    }
  }

  onChangeOutputName(): void {
    this.formData.get('label')?.setValue(this.formData?.value?.parameterName?.name);
  }

  addOutput(): void {
    this.formData.markAllAsTouched();

    if (!this.formData.valid) {
      return;
    }

    const { label, parameterName, order, displayId, configSectionLabel, editable } = deepClone(this.formData.value);
    const description = [parameterName.name, parameterName.benefitEntityName].filter((x) => !!x).join('/');

    const newRecord: DisplayOutputsConfiguration = {
      calculationDisplayId: displayId,
      calculationParameterMappingId: parameterName.id,
      label,
      parameterName: parameterName.name,
      benefitOptionName: parameterName.benefitEntityName,
      order,
      editable,
      description,
      configSectionLabel,
    };

    this.orderOutputDataTable = [...this.orderOutputDataTable, this.createInlineEditFormControls(newRecord)];
    this.getOutputOptions();
    this.resetAddConfigForm();
    this.canSubmit = true;
    this.formValueChange.emit();
  }

  onSoftDeleteConfig(row: DisplayOutputsConfiguration & Row): void {
    row.deleted = true;
    const sortItem = this.orderOutputDataTable.find(
      (item) => item?.calculationParameterMappingId === row?.calculationParameterMappingId,
    );
    if (sortItem) {
      sortItem.deleted = true;
    }
  }

  calculateOutputOptions(parameterMappings: ParameterMapping[]): void {
    this.listOutputOptions = parameterMappings?.map((parameterMapping) => {
      return {
        value: parameterMapping,
        displayValue: parameterMapping.name,
        valueDescription: parameterMapping.benefitEntityName,
      };
    });
  }

  getOutputOptions(): void {
    this.listOutputOptions = deepClone(this.outputOptions).filter(
      (item) =>
        !this.orderOutputDataTable.find((outputItem) => outputItem.calculationParameterMappingId === item.value.id),
    );
  }

  getBenefitCalculationParameterData(): void {
    this.calculationStore.dispatch(
      getBenefitCalculationParameterMappingAction({
        parameterType: GetParameterMappingType.Output,
      }),
    );
    this.calculationStore
      .select(getBenefitCalculationParameterMappingSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((parameterMappings) => {
        this.calculateOutputOptions(parameterMappings?.payload?.outputMappings ?? []);
      });
  }

  private getSubmitData(): (DisplayOutputsConfiguration & Row)[] {
    let order = 1;
    const result: (DisplayOutputsConfiguration & Row)[] = this.orderOutputDataTable.map((item, idx) => {
      const obj = {
        ...item,
        orderColumn: item.deleted ? -1 : order,
        order: item.deleted ? -1 : order,
        label: item.form?.value?.label,
      };
      order += item.deleted ? 0 : 1;
      delete obj.form;
      return obj;
    });
    return result;
  }

  private resetAddConfigForm(): void {
    this.formData.reset({
      ...this.formData.value,
      calculationParameterMappingId: '',
      parameterName: '',
      label: '',
      editable: '',
      benefitTypeName: '',
      benefitSubTypeName: '',
    });
  }

  private getDataTable(): void {
    this.orderOutputDataTable = this.configurations
      .map((config) => this.createInlineEditFormControls(config))
      .sort((a, b) => Number(a.order) - Number(b.order));
    this.getOutputOptions();
    this.formData.get('configSectionLabel')?.patchValue(this.configSectionLabel);
  }

  private createInlineEditFormControls(calculationParameter: DisplayOutputsConfiguration): DisplayOutputsConfiguration {
    return {
      ...calculationParameter,
      form: this.fb.group({
        label: this.fb.control(
          calculationParameter.label,
          getValidatorsFromColumns(this.orderColumns[0].name, this.orderColumns, calculationParameter as any),
        ),
      }),
    } as any;
  }
}
