import { createAction, props } from '@ngrx/store';
import { GetParameterMappingType } from '@ptg-member/features/calculation/types/enums';
import {
  CalculationParameter,
  CalculationParameters,
  ParameterMappings,
  SectionProperties,
  UpdateCalculationBenefitDetailRequest,
} from '@ptg-member/features/calculation/services/models';

export const getParameterMappingsAction = createAction(
  '[GetParameterMappings/API] Send Request',
  props<{ parameterType?: GetParameterMappingType }>(),
);

export const getParameterMappingsSuccessAction = createAction(
  '[GetParameterMappings/API] Success',
  props<{ response: ParameterMappings }>(),
);

export const getParameterMappingsFailureAction = createAction(
  '[GetParameterMappings/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetParameterMappingsStateAction = createAction('[GetParameterMappings] Clear');

export const getCalculationParameterAction = createAction('[GetCalculationParameter/API] Send Request');

export const getCalculationParameterSuccessAction = createAction(
  '[GetCalculationParameter/API] Success',
  props<{ response: CalculationParameters }>(),
);

export const getCalculationParameterFailureAction = createAction(
  '[GetCalculationParameter/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetCalculationParameterStateAction = createAction('[GetCalculationParameter] Clear');

export const setCalculationParameterAction = createAction(
  '[SetCalculationParameter/API] Send Request',
  props<{ request: CalculationParameters }>(),
);

export const setCalculationParameterSuccessAction = createAction('[SetCalculationParameter/API] Success');

export const setCalculationParameterFailureAction = createAction(
  '[SetCalculationParameter/API] Failure',
  props<{ error?: any }>(),
);

export const updateCalculationBenefitDetailByTypeAction = createAction(
  '[UpdateCalculationBenefitDetailByType/API] Send Request',
  props<{
    memberId: string;
    calculationBenefitId: string;
    detailType: number;
    request: UpdateCalculationBenefitDetailRequest;
  }>(),
);

export const updateCalculationBenefitDetailByTypeSuccessAction = createAction(
  '[UpdateCalculationBenefitDetailByType/API] Success',
);

export const updateCalculationBenefitDetailByTypeFailureAction = createAction(
  '[UpdateCalculationBenefitDetailByType/API] Failure',
  props<{ error?: any }>(),
);

export const clearUpdateCalculationBenefitDetailByTypeStateAction = createAction(
  '[UpdateCalculationBenefitDetailByType]',
);

export const clearSetCalculationParameterStateAction = createAction('[SetCalculationParameter] Clear');

export const getCalculationPropertyAction = createAction('[GetCalculationProperty/API] Send Request');

export const getCalculationPropertySuccessAction = createAction(
  '[GetCalculationProperty/API] Success',
  props<{ response: SectionProperties }>(),
);

export const getCalculationPropertyFailureAction = createAction(
  '[GetCalculationProperty/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetCalculationPropertyStateAction = createAction('[GetCalculationProperty] Clear');
