import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BaseComponent } from '@ptg-shared/components';
import { CANCEL_CONFIRM_MESSAGE, SortType } from '@ptg-shared/constance';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { Option } from '@ptg-shared/controls/select/select.component';
import { PropertyTypeLabel } from '@ptg-shared/types/enums/entity.enum';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { deepClone } from '@ptg-shared/utils/common.util';
import { combineLatest, Subject } from 'rxjs';
import { filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ParameterType } from '../../constants';
import {
  FileParameter,
  GetFileParametersRequest,
  GetListSavePropertyRequest,
  GetRetirementBenefitInputOutputListQuery,
  MappingParameter,
  ParameterMappingsRequest,
} from '../../services/models';
import {
  CalculationState,
  clearGetFileParametersAction,
  clearGetListSavePropertyAction,
  clearGetRetirementBenefitInputOutputsAction,
  createParameterMappingAction,
  getFileParametersAction,
  getFileParametersSelector,
  getListSavePropertyAction,
  getListSavePropertySelector,
  getRetirementBenefitInputOutputsRequest,
  selectRetirementBenefitInputOutputListState,
  setParameterMappingAction,
} from '../../store';

@Component({
  selector: 'ptg-add-parameter-mapping',
  templateUrl: './add-parameter-mapping.component.html',
  styleUrls: ['./add-parameter-mapping.component.scss'],
})
export class AddParameterMappingComponent extends BaseComponent {
  ParameterType = ParameterType;
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: `${this.data?.id ? 'Edit' : 'Add New'} Mapping ${
        this.data?.type === ParameterType.Input ? 'Input' : 'Output'
      }`,
    },
  ];
  formAddParameterMapping = new FormGroup({});
  formSubmit$ = new Subject<boolean>();
  listMasterParameter: Option[] = [];
  listFileParameter: FileParameter[] = [];
  listSheetName: string[] = [];
  listFieldName: string[] = [];
  listSaveProperty: Option[] = [];
  listMasterParameterData: any;

  constructor(
    private dialogRef: MatDialogRef<AddParameterMappingComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: MappingParameter & { calculationId: string },
    private fb: FormBuilder,
    private calculationStore: Store<CalculationState>,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getListMasterParameter();
    this.getListFileParameter();
    this.selectListOptionState();
    if (this.data?.dataType) {
      this.getListSaveProperty();
    }
    this.selectListSaveProperty();
    this.initFormGroup();
    this.formSubmit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.clearData();
  }

  clearData() {
    this.listSaveProperty = [];
    this.listFileParameter = [];
    this.listSheetName = [];
    this.listMasterParameter = [];
    this.listMasterParameterData = [];
    this.calculationStore.dispatch(clearGetRetirementBenefitInputOutputsAction());
    this.calculationStore.dispatch(clearGetFileParametersAction());
    this.calculationStore.dispatch(clearGetListSavePropertyAction());
  }

  initFormGroup() {
    this.formAddParameterMapping = this.fb.group({
      calculationMasterParameterId: this.fb.control(this.data?.masterParameterId),
      sheetName: this.fb.control(this.data?.sheetName),
      fieldName: this.fb.control(this.data?.fieldName),
      saveToPropertyId: this.fb.control(this.data?.saveToPropertyId),
    });
  }

  get masterParameterIdControl(): FormControl {
    return this.formAddParameterMapping?.get('calculationMasterParameterId') as FormControl;
  }

  get sheetNameControl(): FormControl {
    return this.formAddParameterMapping?.get('sheetName') as FormControl;
  }

  get fieldNameControl(): FormControl {
    return this.formAddParameterMapping?.get('fieldName') as FormControl;
  }

  get savePropertyControl(): FormControl {
    return this.formAddParameterMapping?.get('saveToPropertyId') as FormControl;
  }

  getListMasterParameter() {
    const query: GetRetirementBenefitInputOutputListQuery = {
      sortField: 'Name',
      sortType: SortType.ASC,
      calculationId: this.data?.calculationId,
    };
    this.calculationStore.dispatch(getRetirementBenefitInputOutputsRequest({ query }));
  }

  getListFileParameter() {
    const request: GetFileParametersRequest = {
      calculationId: this.data?.calculationId ?? '',
      parameterMappingId: this.data?.id,
      parameterType: this.data?.type,
    };
    this.calculationStore.dispatch(getFileParametersAction({ request }));
  }

  selectListOptionState() {
    combineLatest([
      this.calculationStore.select(selectRetirementBenefitInputOutputListState).pipe(
        filter((response) => !!response && !response.isLoading),
        map((response) => response?.payload ?? []),
      ),
      this.calculationStore.select(getFileParametersSelector).pipe(
        filter((response) => !!response && !response.isLoading),
        map((response) => response?.payload ?? []),
      ),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([listMasterParameterData, listFileParameter]) => {
        this.calculationStore.dispatch(clearGetRetirementBenefitInputOutputsAction());
        this.calculationStore.dispatch(clearGetFileParametersAction());
        this.listMasterParameterData = deepClone(listMasterParameterData);
        this.listMasterParameter = deepClone(listMasterParameterData)
          .filter((p) => p.type === this.data.type)
          .map((p) => {
            return {
              value: p.id,
              displayValue: p.name,
            };
          });
        this.listFileParameter = deepClone(listFileParameter);
        this.listSheetName = this.listFileParameter.map((p) => p.sheetName);
        this.getListFieldName(this.data?.sheetName);
      });
  }

  onChangeParameterType() {
    this.getListSaveProperty();
  }
  onChangeSheetNameValue() {
    this.fieldNameControl.reset();
    this.getListFieldName(this.sheetNameControl.value);
    this.masterParameterIdControl.updateValueAndValidity();
  }

  onChangeFieldNameValue() {
    this.masterParameterIdControl.updateValueAndValidity();
  }

  getListSaveProperty() {
    this.savePropertyControl?.setValue('');
    this.listSaveProperty = [];
    const selectedPropertyItem = this.listMasterParameterData?.find(
      (item: any) => item.id === this.masterParameterIdControl?.value,
    );
    let dataType = selectedPropertyItem?.dataType;
    if (this.data?.dataType) {
      dataType = PropertyTypeLabel[this.data?.dataType as unknown as PropertyTypeLabel];
    }
    const request: GetListSavePropertyRequest = {
      calculationId: this.data?.calculationId ?? '',
      parameterMappingId: this.data?.id,
      dataType: dataType,
    };
    this.calculationStore.dispatch(getListSavePropertyAction({ request }));
  }

  selectListSaveProperty() {
    this.calculationStore.select(getListSavePropertySelector)
      .pipe(
        filter((response) => !!response && !response.isLoading),
        map((response) => response?.payload?.saveToProperties ?? []),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((saveToProperties) => {
        this.calculationStore.dispatch(clearGetListSavePropertyAction());
        this.listSaveProperty = deepClone(saveToProperties).map((p) => {
          return {
            value: p.entityPropertyId,
            displayValue: p.name,
          };
        });
      });
  }

  getListFieldName(sheetName?: string) {
    this.listFieldName = this.listFileParameter.find((p) => p.sheetName === sheetName)?.fieldNames ?? [];
  }

  formSubmit() {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.formAddParameterMapping.markAllAsTouched();
        }),
        switchMap(() =>
          this.formAddParameterMapping.statusChanges.pipe(
            startWith(this.formAddParameterMapping.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1),
          ),
        ),
        filter((status) => status === AbstractControlStatus.VALID),
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  onSubmit() {
    const request = {
      ...deepClone(this.formAddParameterMapping.getRawValue() as ParameterMappingsRequest),
      id: this.data?.id,
      type: this.data?.type,
      calculationId: this.data?.calculationId,
    };
    if (this.data?.id) {
      this.calculationStore.dispatch(setParameterMappingAction({ request }));
    } else {
      this.calculationStore.dispatch(createParameterMappingAction({ request }));
    }
    this.dialogRef.close();
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        text: CANCEL_CONFIRM_MESSAGE,
        type: ConfirmType.CancelPopup,
        cancelButtonTitle: 'No',
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }
}
