import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import {
  clearRemoveOverviewDocumentStateAction,
  removeOverviewDocumentAction,
  removeRemoveOverviewDocumentFailureAction,
  removeRemoveOverviewDocumentSuccessAction,
} from '../actions';

export interface RemoveOverviewDocumentState {
  removeRemoveOverviewDocument?: BaseActionState;
}

const initialState: RemoveOverviewDocumentState = {};

export const removeOverviewDocumentReducer = createReducer(
  initialState,
  on(removeOverviewDocumentAction, (state) => ({
    ...state,
    removeRemoveOverviewDocument: {
      isLoading: true,
    },
  })),
  on(removeRemoveOverviewDocumentSuccessAction, (state) => ({
    ...state,
    removeRemoveOverviewDocument: {
      isLoading: false,
      success: true,
    },
  })),
  on(removeRemoveOverviewDocumentFailureAction, (state, { error }) => ({
    ...state,
    removeRemoveOverviewDocument: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearRemoveOverviewDocumentStateAction, (state) => ({
    ...state,
    removeRemoveOverviewDocument: undefined,
  }))
);