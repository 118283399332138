<div class="property-config-container custom-class">
	<div class="content-container grid grid-cols-3 gap-6">
		<div class="col-span-2 box-container box-container-benefit">
			<div class="box">
				<div class="title">{{ calculationStep?.name }}</div>
				<div class="form-container">
					<ptg-toggle-button
						[controlField]="byPassControl"
						label="Bypass"
					></ptg-toggle-button>
					<ptg-textbox
						[controlField]="stepLabelControl"
						[hasLabel]="true"
						[maxLength]="150"
						[isRequired]="true"
						placeholder="Step Label"
					></ptg-textbox>
				</div>
			</div>
		</div>

		<div class="row-start-2 box-container add-property-section">
			<div class="box">
				<div class="title">Input</div>
				<div class="form-container">
					<div>
						<div class="flex type-checkbox">
							<ptg-checkbox
								[controlField]="isInitiateControl"
								label="Initiate"
								(changeValue)="onTypeCheckboxesChange()"
							></ptg-checkbox>
		
							<ptg-checkbox
								[controlField]="isRecalculateControl"
								label="Recalculate"
								(changeValue)="onTypeCheckboxesChange()"
							></ptg-checkbox>
	
						</div>
						<mat-error class="w-100" *ngIf="!isTypeChecked">At least 1 checkbox has to be checked</mat-error>
					</div>

					<ptg-select
						[isOptionObj]="true"
						[isSetMaxWidthSelect]="true"
						[listData]="filteredInputMappingOptions"
						[controlField]="inputMappingControl"
						[placeholder]="addPropertySection?.propertyName || 'Input Mapping'"
						[isMultipleLineOption]="true"
						panelClass="property-name-dropdown"
						(changeOptionValue)="changeInputMapping()"
					></ptg-select>
					<ptg-textbox
						[controlField]="inputLabelControl"
						[hasLabel]="true"
						[maxLength]="inputLabelMaxLength"
						customError="duplicatedValue"
						[placeholder]="addPropertySection?.columnName || 'Column Name'"
					></ptg-textbox>
					<ptg-button
						buttonName="Add Input"
						classInput="add-button"
						(clickButton)="addInputMapping()"
					>
						<mat-icon>add</mat-icon>
					</ptg-button>
				</div>
			</div>
		</div>

		<div class="row-start-2 box-container sort-property">
			<div class="box">
				<div class="title" [ngClass]="{ 'no-data': !sortPropertySectionDataTable || sortPropertySectionDataTable.length === 0 }">
					Order Section
				</div>
				<ptg-grid
					#sortPropertyTable
					[data]="sortPropertySectionDataTable"
					[columns]="orderColumns"
					[notFoundMessage]="''"
					[isLoading]="isLoading"
					[fitToParent]="true"
					[paginable]="false"
					[allowDragDrop]="true"
					[hideHeader]="true"
					[inlineEditable]="true"
					[softDeletable]="true"
					(softDelete)="onSoftDeleteSectionConfig($event)"
					(rowDrop)="changeItem($event, true)"
					(change)="onChangeOrderColumns()"
					(cancelInlineEdit)="onChangeOrderColumns()"
				>
					<ng-template cellContent [columnName]="'inputLabel'" let-row>
						<div class="multiple-line-text">
							<span class="caption truncate">{{ row.inputLabel }}</span>
							<span class="description truncate">{{ row.description }}</span>
							<span class="description truncate">{{ row.stepPropertyTypeName }}</span>
						</div>
					</ng-template>
				</ptg-grid>
			</div>
		</div>
	</div>
</div>
