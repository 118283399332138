import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CheckExistItemRequest,
         CheckExistRequest,
         CheckExistResponse,
         MemberParticipantNavigationDetailResponse,
         MemberParticipantNavigationResponse,
         MenuParametersQuery,
         NavigationItemRequest,
         NavigationViewResponse,
         ProfileResponse,
         QueryDetailRequest,
         ReorderRequest,
         SetNavigationRequest,
         UpdateNavigationRequest
        } from '@ptg-member/types/models/participant-navigation-configuration.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ParticipantNavigationConfigurationService {
  constructor(private httpClient: HttpClient) {}

  getParticipantNavigationConfiguration(query: MenuParametersQuery): Observable<MemberParticipantNavigationResponse> {
    const params = new HttpParams({ fromObject: query as any });
    return this.httpClient.get<MemberParticipantNavigationResponse>(
      `${environment.apiUrl}/entity/navigation`, { params }
    );
  }

  getParticipantNavigationConfigurationDetail(query: QueryDetailRequest): Observable<MemberParticipantNavigationDetailResponse> {
    return this.httpClient.get<MemberParticipantNavigationDetailResponse>(
      `${environment.apiUrl}/entity/navigation/${query.id}`
    );
  }

  setNavigationConfiguration(body: SetNavigationRequest): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.apiUrl}/entity/navigation`, body
    );
  }

  updateNavigationConfiguration(body: UpdateNavigationRequest): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/entity/navigation`, body
    );
  }

  getListView(entityId?: string): Observable<NavigationViewResponse> {
    const body: any = {
      entityId: entityId,
      sortNames: 'EntityName',
      SortType: 0
    };
    const params = new HttpParams({ fromObject: body as any });
    return this.httpClient.get<NavigationViewResponse>(
      `${environment.apiUrl}/entity/views`, { params }
    );
  }

  checkExits(query: CheckExistRequest): Observable<CheckExistResponse> {
    const params = new HttpParams({ fromObject: query as any });
    return this.httpClient.get<CheckExistResponse>(
      `${environment.apiUrl}/entity/navigation/checkExisted`, { params }
    );
  }

  checkExitsItem(query: CheckExistItemRequest): Observable<CheckExistResponse> {
    const body: any = {
      name: query.name
    };
    if(query.id) {
      body.id = query.id;
    }
    let params = new HttpParams({fromObject: body as any});
    return this.httpClient.get<CheckExistResponse>(
      `${environment.apiUrl}/entity/navigation/${query.navigationId}/item/check-existed`, { params }
    );
  }

  setNavigationConfigurationItem(body: NavigationItemRequest): Observable<void> {
    return this.httpClient.post<any>(
      `${environment.apiUrl}/entity/navigation/item`, body
    );
  }

  updateNavigationConfigurationItem(body: NavigationItemRequest): Observable<void> {
    return this.httpClient.put<any>(
      `${environment.apiUrl}/entity/navigation/item`, body
    );
  }

  removeNavigationConfiguration(query: QueryDetailRequest): Observable<void> {
    return this.httpClient.delete<void>(
      `${environment.apiUrl}/entity/navigation/${query.id }`,
    );
  }

  removeNavigationConfigurationItem(query: QueryDetailRequest): Observable<void> {
    return this.httpClient.delete<void>(
      `${environment.apiUrl}/entity/navigation/item/${query.id }`,
    );
  }

  reorderNavigationConfiguration(body: ReorderRequest): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/entity/navigation/item/reorder`, body
    );
  }

  getNavigationConfigurationProfile(id: string): Observable<ProfileResponse> {
    return this.httpClient.get<ProfileResponse>(
      `${environment.apiUrl}/entity/navigation/${id}/entity-profile`
    );
  }
}
