<ptg-panel [title]="title" [noPaddingBody]="true" [hideSeparator]="true">
  <ng-template panelBody>
    <ptg-grid
      [columns]="columns"
      [data]="entitiesData"
      [fitToParent]="true"
      [pageSize]="pageSize"
      [pageNumber]="pageNumber"
      [totalRecords]="totalRecords"
      [isLoading]="isLoading"
      notFoundMessage="No Data to Display"
      [paginable]="true"
      [hideScrollbar]="false"
      (pageChange)="onChangePage($event)"
    ></ptg-grid>
  </ng-template>
</ptg-panel>

