<div class="title">
  <div
    [ngClass]="{
      'item-2': !hasStatus,
      'item-3': hasStatus && !isSummary,
      'item-4': isSummary
    }"
  >
    <span>{{ title }}</span>
  </div>
  <div
    class="flex gap-5"
    [ngClass]="{
      'item-2': !hasStatus,
      'item-3': hasStatus && !isSummary,
      'item-5': isSummary
    }"
  >
    <div
      *ngIf="isPublishable"
      class="flat-button"
      [class.disabled]="isDisabled"
      (click)="onPublish()"
    >
      <mat-icon>publish</mat-icon>
      <span>Publish</span>
    </div>

    <div
      *ngIf="!isSummary"
      class="flat-button"
      [class.disabled]="isDisabled"
      (click)="onEdit()"
    >
      <mat-icon>edit</mat-icon>
      <span>Edit</span>
    </div>
    <div *ngIf="isSummary" class="flat-button" (click)="onEdit()">
      <mat-icon svgIcon="upload-icon"></mat-icon>
      <span>Upload</span>
    </div>
  </div>
  <div
    *ngIf="hasStatus && !isWelcome"
    [ngClass]="{ 'item-2': !hasStatus, 'item-1': hasStatus }"
  >
    <ptg-toggle-button
      [controlField]="active"
      (valueChange)="setStatusView()"
      [single]="true"
    ></ptg-toggle-button>
  </div>
</div>
