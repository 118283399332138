<div class="tag-dialog">
  <div class="title">
    <div class="title-name"> {{ data?.tagDetail?.id ? 'Edit' : 'Create' }} Tag</div>
  </div>
  <form [formGroup]="editForm" (ngSubmit)="formSubmit$.next()" class="flex flex-col gap-2">
    <div class="wrap-btn">
      <button mat-raised-button class="button-save" type="submit">
        Save
      </button>
      <button mat-raised-button class="button-cancel" type="button" (click)="onCancel()">
        Cancel
      </button>
    </div>
    <div class="flex gap-4">
      <div class="flex-1">
        <ptg-textbox
          [controlField]="editForm.get('name')"
          placeholder="Tag Name"
          class="documentTag"
          [maxLength]="250"
          [hasLabel]="true"
          customError="errorMessageTagName"
        ></ptg-textbox>
      </div>
    </div>
    <div class="flex gap-4">
      <div class="flex-1">
        <ptg-input-area
          [controlField]="editForm.get('description')"
          [maxLength]="255"
          placeholder="Description"
          class="description"
          [hasLabel]="true"
          errorMaxLength="Exceed the 255 character limit."
        >
        </ptg-input-area>
      </div>
    </div>
  </form>
</div>

