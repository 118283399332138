import { FormGroup } from "@angular/forms";
import { Menu } from "@ptg-shared/layout/models/layout.model";

export interface Header {
  id?: string;
  headerName?: string;
  linkedEntity?: string;
  entityId?: string;
  totalProperties?: number;
  properties?: Property[];
  profiles?: Profile[];
  ppName?: string;
  option?: string;
} 

export interface NewHeader {
  name: string; 
  entityId: string; 
}

export interface Profile {
  id?: string;
  profileName: string;
  linkedEntity?: string;
}

export interface Property {
  id?: string;
  label?: string;
  isRemoved?: boolean;
  order: number;
  propertyId?: string;
  propertyName?: string;
  propertyType?: string;
  option?: string;
  name: string;
  type?: number;
  sortType?: number;
  entityReference?: string;
  entityReferencePropertyNames?: (string | entityReferencePropeprtyName)[];
  form?: FormGroup;
  isDataType?: boolean;
  isEntityReference?: boolean;
  entityReferencePropertyId?: string;
}

export interface entityReferencePropeprtyName {
  id: string;
  name: string;
  entityLinkedId?: string;
}

export interface ItemDto {
  id?: string;
  label?: string;
  order: number;
  isRemoved?: boolean;
  propertyId?: string;
  propertyName?: string;
}

export interface UpdateHeaderDetailRequest {
  id?: string;
  name: string;
  itemDtos: ItemDto[];
}

export interface GetHeaderListResponse {
  response?: Header[];
  total?: number;
}

export interface HeaderDetailResponse {
  data: Header;
}

export interface GetHeaderListRequest {
  sortField: string;
  sortType: number;
  pageIndex?: number;
  pageSize?: number
}

export interface GetHeaderPropertiesRequest {
  entityId: string;
  type?: number;
  isGetForAcm?: boolean;
  isExcludeSystemType?: boolean;
}

export interface HeaderProperty {
  id: string;
  type: number;
  name: string;
  options?: Record<string, string>;
  entityReferencePropertyNames?: {
    id: string;
    name: string;
  }[];
}

export interface HeaderPropertiesResponse {
  response: HeaderProperty[];
}

export interface HeaderItemItemExisResponse {
  exists: boolean;
  message: string;
}