import { createAction, props } from '@ngrx/store';

import {
  GetTierConfigurationsResponse,
  EditTierRequest,
  ParametersQueryEditRequest,
  PlanResponse,
  ConditionPropertiesResponse,
  ParametersQueryOperators,
  ConditionOperatorsResponse,
  ParametersQueryConditionLookup,
  ConditionLookupResponse,
  BinaryValuesResponse,
} from '../../types/models';
import { ParametersQuery } from '@ptg-employer/models';

export const SetTierConfigurationRequestAction =
  '[SetTierConfiguration/API] Send Request';
export const SetTierConfigurationSuccessAction =
  '[SetTierConfiguration/API] Success';
export const SetTierConfigurationFailureAction =
  '[SetTierConfiguration/API] Failure';

export const EditTierConfigurationRequestAction =
  '[EditTierConfiguration/API] Send Request';
export const EditTierConfigurationSuccessAction =
  '[EditTierConfiguration/API] Success';
export const EditTierConfigurationFailureAction =
  '[EditTierConfiguration/API] Failure';

export const GetTierConfigurationRequestAction =
  '[GetTierConfiguration/API] Send Request';
export const GetTierConfigurationSuccessAction =
  '[GetTierConfiguration/API] Success';
export const GetTierConfigurationFailureAction =
  '[GetTierConfiguration/API] Failure';

export const GetTierConfigurationPlanRequestAction =
  '[GetTierConfigurationPlan/API] Send Request';
export const GetTierConfigurationPlanSuccessAction =
  '[GetTierConfigurationPlan/API] Success';
export const GetTierConfigurationPlanFailureAction =
  '[GetTierConfigurationPlan/API] Failure';

export const GetTierConfigurationMetadataRequestAction =
  '[GetTierConfigurationMetadata/API] Send Request';
export const GetTierConfigurationMetadataSuccessAction =
  '[GetTierConfigurationMetadata/API] Success';
export const GetTierConfigurationMetadataFailureAction =
  '[GetTierConfigurationMetadata/API] Failure';

export const GetTierConfigurationOperatorRequestAction =
  '[GetTierConfigurationOperator/API] Send Request';
export const GetTierConfigurationOperatorSuccessAction =
  '[GetTierConfigurationOperator/API] Success';
export const GetTierConfigurationOperatorFailureAction =
  '[GetTierConfigurationOperator/API] Failure';

export const GetTierConfigurationConditionLookupRequestAction =
  '[GetTierConfigurationConditionLookup/API] Send Request';
export const GetTierConfigurationConditionLookupSuccessAction =
  '[GetTierConfigurationConditionLookup/API] Success';
export const GetTierConfigurationConditionLookupFailureAction =
  '[GetTierConfigurationConditionLookup/API] Failure';

export const GetTierConfigurationBinaryValuesAction =
  '[GetTierConfigurationBinaryValues/API] Send Request';
export const GetTierConfigurationBinaryValuesSuccessAction =
  '[GetTierConfigurationBinaryValues/API] Success';
export const GetTierConfigurationBinaryValuesFailureAction =
  '[GetTierConfigurationBinaryValues/API] Failure';

export const ClearTierConfiguration = '[ClearTierConfiguration] Clear';

export const setTierConfiguration = createAction(
  SetTierConfigurationRequestAction,
  props<{ body: EditTierRequest }>()
);

export const setTierConfigurationsSuccess = createAction(
  SetTierConfigurationSuccessAction
);

export const setTierConfigurationsFailure = createAction(
  SetTierConfigurationFailureAction,
  props<{ error?: any }>()
);

export const editTierConfiguration = createAction(
  EditTierConfigurationRequestAction,
  props<{ body: EditTierRequest; query: ParametersQueryEditRequest }>()
);

export const editTierConfigurationsSuccess = createAction(
  EditTierConfigurationSuccessAction
);

export const editTierConfigurationsFailure = createAction(
  EditTierConfigurationFailureAction,
  props<{ error?: any }>()
);

export const getTierConfiguration = createAction(
  GetTierConfigurationRequestAction,
  props<{ query: ParametersQuery }>()
);

export const getTierConfigurationSuccess = createAction(
  GetTierConfigurationSuccessAction,
  props<{ tierConfiguration: GetTierConfigurationsResponse }>()
);

export const getTierConfigurationFailure = createAction(
  GetTierConfigurationFailureAction,
  props<{ error?: any }>()
);

export const getTierConfigurationPlan = createAction(
  GetTierConfigurationPlanRequestAction
);

export const getTierConfigurationPlanSuccess = createAction(
  GetTierConfigurationPlanSuccessAction,
  props<{ plans: PlanResponse }>()
);

export const getTierConfigurationPlanFailure = createAction(
  GetTierConfigurationPlanFailureAction,
  props<{ error?: any }>()
);

export const getTierConfigurationMetadata = createAction(
  GetTierConfigurationMetadataRequestAction
);

export const getTierConfigurationMetadataSuccess = createAction(
  GetTierConfigurationMetadataSuccessAction,
  props<{ properties: ConditionPropertiesResponse }>()
);

export const getTierConfigurationMetadataFailure = createAction(
  GetTierConfigurationMetadataFailureAction,
  props<{ error?: any }>()
);

export const getTierConfigurationOperator = createAction(
  GetTierConfigurationOperatorRequestAction,
  props<{ query: ParametersQueryOperators }>()
);

export const getTierConfigurationOperatorSuccess = createAction(
  GetTierConfigurationOperatorSuccessAction,
  props<{ operator: ConditionOperatorsResponse }>()
);

export const getTierConfigurationOperatorFailure = createAction(
  GetTierConfigurationOperatorFailureAction,
  props<{ error?: any }>()
);

export const getTierConfigurationConditionLookup = createAction(
  GetTierConfigurationConditionLookupRequestAction,
  props<{ query: ParametersQueryConditionLookup }>()
);

export const getTierConfigurationConditionLookupSuccess = createAction(
  GetTierConfigurationConditionLookupSuccessAction,
  props<{ result: ConditionLookupResponse }>()
);

export const getTierConfigurationConditionLookupFailure = createAction(
  GetTierConfigurationConditionLookupFailureAction,
  props<{ error?: any }>()
);

export const getTierConfigurationBinaryValues = createAction(
  GetTierConfigurationBinaryValuesAction,
  props<{ query: ParametersQueryConditionLookup }>()
);

export const getTierConfigurationBinaryValuesSuccess = createAction(
  GetTierConfigurationBinaryValuesSuccessAction,
  props<{ resultBinary: BinaryValuesResponse }>()
);

export const getTierConfigurationBinaryValuesFailure = createAction(
  GetTierConfigurationBinaryValuesFailureAction,
  props<{ error?: any }>()
);

export const clearTierConfigurationState = createAction(ClearTierConfiguration);
