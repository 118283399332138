import { createReducer, on } from '@ngrx/store';

import { STATE } from '@ptg-shared/constance/value.const';

import { ParticipantNavigationConfigurationAction } from '../actions';
import { MemberParticipantNavigationResponse } from '@ptg-member/types/models/participant-navigation-configuration.model';

export const navigationConfigurationKey = 'navigationConfigurationMember';

export interface State {
  isLoading?: boolean;
  navigationConfigurationResponse?: MemberParticipantNavigationResponse;
  viewResponse?: any,
  createState?: string,
  navigationConfigurationId?: any,
  navigationConfigurationDetailResponse?: any,
  removeMenuState?: string
}

const initialState: State = {
};

export const reducer = createReducer(
  initialState,
  on(ParticipantNavigationConfigurationAction.clearNavigationConfigurationState, (state) => ({
    ...state,
    createState: '',
    removeMenuState: '',
    navigationConfigurationDetailResponse : {
      updateMenuState: '',
      removeMenuState: '',
      createItemState: '',
      updateItemState: '',
      removeItemState: ''
    }
  })),
  on(ParticipantNavigationConfigurationAction.getNavigationConfigurationSuccess,
    (state, { navigationConfigurationResponse }) => ({
      ...state,
      isLoading: false,
      navigationConfigurationResponse
    })
  ),
  on(ParticipantNavigationConfigurationAction.getNavigationConfigurationFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
    })
  ),
  on(ParticipantNavigationConfigurationAction.getViewSuccess,
    (state, { viewResponse }) => ({
      ...state,
      isLoading: false,
      viewResponse: viewResponse
    })
  ),
  on(ParticipantNavigationConfigurationAction.getViewFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
    })
  ),
  on(ParticipantNavigationConfigurationAction.setNavigationConfigurationSuccess,
    (state, { id }) => ({
      ...state,
      isLoading: false,
      createState: STATE.SUCCESS,
      navigationConfigurationId: id
    })
  ),
  on(ParticipantNavigationConfigurationAction.setNavigationConfigurationFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      createState: STATE.FAIL,
    })
  ),
  on(ParticipantNavigationConfigurationAction.getNavigationConfigurationDetailSuccess,
    (state, { navigationConfigurationDetailResponse }) => ({
      ...state,
      isLoading: false,
      navigationConfigurationDetailResponse
    })
  ),
  on(ParticipantNavigationConfigurationAction.getNavigationConfigurationDetailFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
    })
  ),
  on(ParticipantNavigationConfigurationAction.updateNavigationConfigurationSuccess,
    (state) => ({
      ...state,
      isLoading: false,
      navigationConfigurationDetailResponse : {
        updateMenuState: STATE.SUCCESS,
      }
    })
  ),
  on(ParticipantNavigationConfigurationAction.updateNavigationConfigurationFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      navigationConfigurationDetailResponse : {
        updateMenuState: STATE.FAIL,
      }
    })
  ),
  on(ParticipantNavigationConfigurationAction.setNavigationConfigurationItemSuccess,
    (state) => ({
      ...state,
      isLoading: false,
      navigationConfigurationDetailResponse : {
        createItemState: STATE.SUCCESS,
      }
    })
  ),
  on(ParticipantNavigationConfigurationAction.setNavigationConfigurationItemFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      navigationConfigurationDetailResponse : {
        createItemState: STATE.FAIL,
      }
    })
  ),
  on(ParticipantNavigationConfigurationAction.updateNavigationConfigurationItemSuccess,
    (state) => ({
      ...state,
      isLoading: false,
      navigationConfigurationDetailResponse : {
        updateItemState: STATE.SUCCESS,
      }
    })
  ),
  on(ParticipantNavigationConfigurationAction.updateNavigationConfigurationItemFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      navigationConfigurationDetailResponse : {
        updateItemState: STATE.FAIL,
      }
    })
  ),
  on(ParticipantNavigationConfigurationAction.removeNavigationConfigurationSuccess,
    (state) => ({
      ...state,
      isLoading: false,
      removeMenuState:  STATE.SUCCESS,
    })
  ),
  on(ParticipantNavigationConfigurationAction.removeNavigationConfigurationFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      removeMenuState:  STATE.FAIL,
    })
  ),
  on(ParticipantNavigationConfigurationAction.removeNavigationConfigurationItemSuccess,
    (state) => ({
      ...state,
      isLoading: false,
      navigationConfigurationDetailResponse : {
        removeItemState: STATE.SUCCESS,
      }
    })
  ),
  on(ParticipantNavigationConfigurationAction.removeNavigationConfigurationItemFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      navigationConfigurationDetailResponse : {
        removeItemState: STATE.FAIL,
      }
    })
  ),
);
