import { createSelector } from '@ngrx/store';

import { selectCalculationModuleState } from './selector';

export const BeneficiaryConfirmationSelector = createSelector(
  selectCalculationModuleState,
  (state) => state?.beneficiaryConfirmation
);

export const metaDataSelector = createSelector(
  BeneficiaryConfirmationSelector,
  (state) => state?.metaData
);

export const memberDataSelector = createSelector(
  BeneficiaryConfirmationSelector,
  (state) => state?.memberData
);
