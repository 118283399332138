import { EntityViewFixType, EntityViewType } from '@ptg-entity-management/types/enums';
import { Menu } from '@ptg-shared/layout/models/layout.model';

export interface EntityProfileConfigOverview {
  id: string;
  entityId: string;
  profileName: string;
  overviewLabel: string;
  entityViewId: string;
  navigations: EntityNavigationDto[];
  memberStatusEventItem?: any;
  headerId: string;
  headerComponentItems: any[];
  isOverviewDetailView: boolean;
  subPageHeaderId: string;
  subPageHeaderComponentItems: any[];
  entityViewType: EntityViewType;
  isShowSms: boolean;
}

export interface EntityNavigationDto {
  id: string;
  isSingleView: boolean;
  name: string;
  color: string;
  totalItem: number;
  order: number;
  menuItems: EntityNavigationItem[];
}

export interface EntityNavigationItem {
  id: string;
  name: string;
  entityViewId: string;
  order: number;
  viewFixType: EntityViewFixType;
}

export interface GetEntityProfileConfigOverviewResponse {
  profileConfigOverview?: EntityProfileConfigOverview;
  entityId: string;
  recordId: string;
  menus: Menu[];
}
