import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { DefinedBenefitActions } from '../../store/actions';
import {
  GetBenefitTypesResponse,
  GetStatusAndEventsResponse,
  GetBenefitRecordsResponse,
  GetLookupTableOptionsResponse,
  GetBenefitResponse,
  GetQDROBenefitTypesResponse,
  GetLookupTablesReponse,
  GetQDROMetaDataReponse,
} from '../../types/models';
import { DefinedBenefitService } from '../../services/defined-benefits.service';

@Injectable()
export class DefinedBenefitEffects {
  constructor(
    private actions$: Actions,
    private definedBenefitService: DefinedBenefitService
  ) {}

  getBenefit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefinedBenefitActions.getBenefit),
      switchMap(() => {
        return this.definedBenefitService.getBenefit().pipe(
          map((response: GetBenefitResponse) => {
            return DefinedBenefitActions.getBenefitSuccess({ response });
          }),
          catchError((error) => {
            return of(DefinedBenefitActions.getBenefitFailure({ error }));
          })
        );
      })
    )
  );

  getBenefitTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefinedBenefitActions.getBenefitTypes),
      switchMap(({ request }) => {
        return this.definedBenefitService.getBenefitTypes(request).pipe(
          map((response: GetBenefitTypesResponse) => {
            return DefinedBenefitActions.getBenefitTypesSuccess({ response });
          }),
          catchError((error) => {
            return of(DefinedBenefitActions.getBenefitTypesFailure({ error }));
          })
        );
      })
    )
  );

  getAllBenefitType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefinedBenefitActions.getAllBenefitTypes),
      switchMap(({request}) => {
        return this.definedBenefitService.getAllBenefitType(request).pipe(
          map((response: GetBenefitTypesResponse) => {
            return DefinedBenefitActions.getAllBenefitTypesSuccess({
              response,
            });
          }),
          catchError((error) => {
            return of(
              DefinedBenefitActions.getAllBenefitTypesFailure({ error })
            );
          })
        );
      })
    )
  );

  addBenefitType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefinedBenefitActions.addBenefitType),
      switchMap(({ request }) => {
        return this.definedBenefitService.addBenefitType(request).pipe(
          map(() => {
            return DefinedBenefitActions.addBenefitTypeSuccess();
          }),
          catchError((error) => {
            return of(DefinedBenefitActions.addBenefitTypeFailure({ error }));
          })
        );
      })
    )
  );

  getLookupTableOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefinedBenefitActions.getLookupTableOptions),
      switchMap(() => {
        return this.definedBenefitService.GetLookupTableOptions().pipe(
          map((response: GetLookupTableOptionsResponse) => {
            return DefinedBenefitActions.getLookupTableOptionsSuccess({
              response,
            });
          }),
          catchError((error) => {
            return of(
              DefinedBenefitActions.getLookupTableOptionsFailure({ error })
            );
          })
        );
      })
    )
  );

  getStatusAndEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefinedBenefitActions.getStatusAndEvents),
      switchMap(() => {
        return this.definedBenefitService.getStatusAndEvents().pipe(
          map((response: GetStatusAndEventsResponse) => {
            return DefinedBenefitActions.getStatusAndEventsSuccess({
              response,
            });
          }),
          catchError((error) => {
            return of(
              DefinedBenefitActions.getStatusAndEventsFailure({ error })
            );
          })
        );
      })
    )
  );

  getBenefitRecords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefinedBenefitActions.getBenefitRecords),
      switchMap(() => {
        return this.definedBenefitService.getBenefitRecord().pipe(
          map((response: GetBenefitRecordsResponse) => {
            return DefinedBenefitActions.getBenefitRecordsSuccess({ response });
          }),
          catchError((error) => {
            return of(
              DefinedBenefitActions.getBenefitRecordsFailure({ error })
            );
          })
        );
      })
    )
  );

  updateBenefit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefinedBenefitActions.updateBenefit),
      switchMap(({ request }) => {
        return this.definedBenefitService.updateBenefit(request).pipe(
          map(() => {
            return DefinedBenefitActions.updateBenefitSuccess();
          }),
          catchError((error) => {
            return of(DefinedBenefitActions.updateBenefitFailure({ error }));
          })
        );
      })
    )
  );

  getQDROBenefitTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefinedBenefitActions.getQDROBenefitTypesRequest),
      switchMap(() => {
        return this.definedBenefitService.getQDROBenefitTypes().pipe(
          map((response: GetQDROBenefitTypesResponse) => {
            return DefinedBenefitActions.getQDROBenefitTypesSuccess({
              response,
            });
          }),
          catchError((error) => {
            return of(
              DefinedBenefitActions.getQDROBenefitTypesFailure({ error })
            );
          })
        );
      })
    )
  );

  getLookupTables$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefinedBenefitActions.getLookupTablesRequest),
      switchMap(() => {
        return this.definedBenefitService.getLookupTables().pipe(
          map((response: GetLookupTablesReponse) => {
            return DefinedBenefitActions.getLookupTablesSuccess({ response });
          }),
          catchError((error) => {
            return of(DefinedBenefitActions.getLookupTablesFailure({ error }));
          })
        );
      })
    )
  );

  getQDROMetadatas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefinedBenefitActions.getQDROMetadatasRequest),
      switchMap(() => {
        return this.definedBenefitService.getQDROMetadatas().pipe(
          map((response: GetQDROMetaDataReponse) => {
            return DefinedBenefitActions.getQDROMetadatasSuccess({ response });
          }),
          catchError((error) => {
            return of(DefinedBenefitActions.getQDROMetadatasFailure({ error }));
          })
        );
      })
    )
  );

  removeBenefitType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefinedBenefitActions.removeBenefitType),
      switchMap(({ benefitTypeId }) => {
        return this.definedBenefitService.removeBenefitType(benefitTypeId).pipe(
          map(() => {
            return DefinedBenefitActions.removeBenefitTypeSuccess();
          }),
          catchError((error) => {
            return of(
              DefinedBenefitActions.removeBenefitTypeFailure({ error })
            );
          })
        );
      })
    )
  );

  updateBenefitType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DefinedBenefitActions.updateBenefitType),
      switchMap(({ request }) => {
        return this.definedBenefitService.updateBenefitType(request).pipe(
          map(() => {
            return DefinedBenefitActions.updateBenefitTypeSuccess();
          }),
          catchError((error) => {
            return of(
              DefinedBenefitActions.updateBenefitTypeFailure({ error })
            );
          })
        );
      })
    )
  );
}
