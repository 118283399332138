export interface MemberStatusList {
  memberStatuses: MemberStatus[];
}

export interface MemberStatus {
  id: string;
  clientId: string;
  name: string;
  color: string;
  iconName?: string;
  totalNumberOfEvent: number;
  active: boolean;
  disabled?: boolean;
  memberEvent?: MemberEvent[];
  events?: MemberEvent[];
  createdBy: string;
  dateCreated: Date;
  updatedBy: string;
  dateUpdated: Date;
}

export interface GetStatusQuery {
  SortNames: string;
  SortType: number;
  Id?: string;
}

export interface MemberStatusDetail {
  id?: string;
  clientId?: string;
  name: string;
  color: string;
  iconName: string;
  active: boolean;
  disabled?: boolean;
  memberEvents?: MemberEvent[];
}

export interface MemberEvent {
  id: string;
  memberStatusId: string;
  name: string;
  active: boolean;
  memberStatus: MemberStatus;
  disabled?: boolean;
  createdBy: string;
  dateCreated: Date;
  updatedBy: string;
  dateUpdated: Date;
}

export interface CheckExistStatusBody {
  memberStatusId: string;
  name: string;
}

export interface StatusDetailState {
  statusDetail: MemberStatusDetail;
  isLoading: boolean;
  success?: boolean;
  error?: any;
}
