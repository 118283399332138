import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ParticipantReportService } from '@ptg-member/services/participant-report.service';
import * as ParticipantReportActions from '../../store/actions/participant-report.actions';

@Injectable()
export class ParticipantReportEffect {
  constructor(
    private actions$: Actions,
    private participantReportService: ParticipantReportService
  ) { }

  getManageReportRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipantReportActions.getManageReportRequest),
      switchMap(({ query }) => {
        return this.participantReportService.getManageReport(query)
          .pipe(
            map((res) => {
              return ParticipantReportActions.getManageReportSuccess({ res: res });
            }),
            catchError((err) => {
              return of(
                ParticipantReportActions.getManageReportFailure({ error: err })
              );
            })
          );
      }
      )
    )
  );

  deleteReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipantReportActions.deleteReport),
      switchMap(({ reportId }) => {
        return this.participantReportService.deleteReport(reportId)
          .pipe(
            map((res) => {
              return ParticipantReportActions.deleteReportSuccess();
            }),
            catchError((err) => {
              return of(
                ParticipantReportActions.deleteReportFailure({ error: err })
              );
            })
          );
      }
      )
    )
  );

  createReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipantReportActions.createReportConfig),
      switchMap(({ body }) => {
        return this.participantReportService.createReportConfig(body)
          .pipe(
            map((res) => {
              return ParticipantReportActions.createReportConfigSuccess();
            }),
            catchError((err) => {
              return of(
                ParticipantReportActions.createReportConfigFailure({ error: err })
              );
            })
          );
      }
      )
    )
  );

  getReportCategoryName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipantReportActions.getReportCategoryName),
      switchMap(() => {
        return this.participantReportService.getReportCategoryName()
          .pipe(
            map((res) => {
              return ParticipantReportActions.getReportCategoryNameSuccess({ res: res });
            }),
            catchError((err) => {
              return of(
                ParticipantReportActions.getReportCategoryNameFailure({ error: err })
              );
            })
          );
        }
      )
    )
  );

  editReportConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipantReportActions.editReportConfig),
      switchMap(({ reportId, body }) => {
        return this.participantReportService.editReportConfig(reportId, body)
          .pipe(
            map((res) => 
            {
              return ParticipantReportActions.editReportConfigSuccess();
            }),
            catchError((err) => {
              return of(
                ParticipantReportActions.editReportConfigFailure({ error: err })
              );
            })
          );
      }
      )
    )
  );

  getGeneratedReportRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipantReportActions.getGeneratedReportRequest),
      switchMap(({ query }) => {
        return this.participantReportService.getGeneratedReport(query)
          .pipe(
            map((res) => {
              return ParticipantReportActions.getGeneratedReportSuccess({ res: res });
            }),
            catchError((err) => {
              return of(
                ParticipantReportActions.getGeneratedReportFailure({ error: err })
              );
            })
          );
      }
      )
    )
  );

  deleteGeneratedReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipantReportActions.deleteGeneratedReport),
      switchMap(({ reportId }) => {
        return this.participantReportService.deleteGeneratedReport(reportId)
          .pipe(
            map((res) => {
              return ParticipantReportActions.deleteGeneratedReportSuccess();
            }),
            catchError((err) => {
              return of(
                ParticipantReportActions.deleteGeneratedReportFailure({ error: err })
              );
            })
          );
      }
      )
    )
  );
}
