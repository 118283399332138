import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ExceptionConfigurationService } from '../../services';

import {
  getExceptionConfigurationsAction,
  getExceptionConfigurationsFailureAction,
  getExceptionConfigurationsSuccessAction,
  getExceptionConfigurationDetailAction,
  getExceptionConfigurationDetailFailureAction,
  getExceptionConfigurationDetailSuccessAction,
  setExceptionConfigurationsDetailAction,
  setExceptionConfigurationsDetailFailureAction,
  setExceptionConfigurationsDetailSuccessAction,
  getValidationExceptionConfigurationSuccessAction,
  getValidationExceptionConfigurationFailureAction,
  getValidationExceptionConfigurationAction,
} from '../actions';

import {
  GetExceptionConfigurationResponse,
  ExceptionConfigurationDetailResponse,
  GetValidationExceptionResponse,
} from '../../services/models/exception-configuration.model';

@Injectable()
export class ExceptionConfigurationEffects {
  constructor(
    private actions$: Actions,
    private exceptionConfigurationService: ExceptionConfigurationService,
  ) {}

  getExceptionConfigurations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getExceptionConfigurationsAction),
      switchMap(({ request }) =>
        this.exceptionConfigurationService.getExceptionConfigurations(request).pipe(
          map((response: GetExceptionConfigurationResponse) => getExceptionConfigurationsSuccessAction({ response })),
          catchError((error) => of(getExceptionConfigurationsFailureAction({ error }))),
        ),
      ),
    ),
  );

  getExceptionConfigurationDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getExceptionConfigurationDetailAction),
      switchMap(({ request }) =>
        this.exceptionConfigurationService.getExceptionConfigurationDetail(request).pipe(
          map((response: ExceptionConfigurationDetailResponse) =>
            getExceptionConfigurationDetailSuccessAction({ response }),
          ),
          catchError((error) => of(getExceptionConfigurationDetailFailureAction({ error }))),
        ),
      ),
    ),
  );

  setExceptionConfigurationsDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setExceptionConfigurationsDetailAction),
      switchMap(({ exceptionId, request }) =>
        this.exceptionConfigurationService.setExceptionConfigurationsDetail(exceptionId, request).pipe(
          map((response: any) => setExceptionConfigurationsDetailSuccessAction({ response })),
          catchError((error) => of(setExceptionConfigurationsDetailFailureAction({ error }))),
        ),
      ),
    ),
  );

  getValidationExceptionConfigurations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getValidationExceptionConfigurationAction),
      switchMap(({ request }) =>
        this.exceptionConfigurationService.getValidationExceptionConfigurations(request).pipe(
          map((response: GetValidationExceptionResponse) =>
            getValidationExceptionConfigurationSuccessAction({ response }),
          ),
          catchError((error) => of(getValidationExceptionConfigurationFailureAction({ error }))),
        ),
      ),
    ),
  );
}
