import { Component, Input, OnInit } from '@angular/core';
import { stringToBoolean } from '@ptg-shared/utils/string.util';

@Component({
  selector: 'ptg-binary-column',
  templateUrl: './binary-column.component.html',
  styleUrls: ['./binary-column.component.scss'],
})
export class BinaryColumnComponent implements OnInit {
  @Input() value!: string;
  @Input () templateArgs!: any;

  valueDisplay: string = '';

  constructor() {
  }

  ngOnInit(): void {
    this.valueDisplay = stringToBoolean(this.value) ? this.templateArgs?.affirmative : this.templateArgs?.negative;
  }
}
