<ptg-panel [title]="title" [noPaddingBody]="true" [hideSeparator]="true">
  <ng-template panelBody>
    <ptg-grid
      [columns]="payeeInformationColumns"
      [data]="payeeInformationList || []"
      notFoundMessage="No Data to Display"
      [hideScrollbar]="false"
      [paginable]="false"
    >
      <ng-template cellContent columnName="Percentage" let-row>
        <span>{{ row?.Percentage }}</span>
      </ng-template>
      <ng-template cellContent columnName="Amount" let-row>
        <span>{{ row?.Amount }}</span>
      </ng-template>
    </ptg-grid>
  </ng-template>
</ptg-panel>
