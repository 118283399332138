import { DatePipe } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import {
  ACTION,
  NO_MATCHING_TAX_BRACKET_EXCEPTION,
  PaymentInfoNoteType,
  STATE,
} from '@ptg-shared/constance/value.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { HistoryService } from '@ptg-shared/services/history.service';
import { Row } from '@ptg-shared/controls/grid';
import {
  deepClone,
  isNumeric,
  showBanner,
} from '@ptg-shared/utils/common.util';
import { FundType } from '@ptg-shared/types/enums';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { isEmpty, stringToBoolean } from '@ptg-shared/utils/string.util';
import { BaseComponent } from '@ptg-shared/components';
import * as fromReducer from '@ptg-reducers/index';
import { ScreenType } from '@ptg-processing/features/off-cycle/types/enums';
import { AddOffCyclePaymentDialogComponent } from '@ptg-processing/features/off-cycle/components';

import {
  OffCyclePaymentStatusType,
  PaymentInfoType,
} from '../../constance/next-payment.const';
import * as fromNextPayment from '../../store/reducers';
import * as NextPaymentActions from '../../store/actions/next-payment.actions';
import * as MemberDetailActions from '../../store/actions/member-detail.actions';
import {
  IssueOffCyclePaymentRequest,
  MemberNavigationResponse,
  OffCyclePayment,
  PaymentHistory,
  PaymentInforConfigData,
  PendingPayment,
} from '../../types/models';
import { MemberDetailService } from '../../services/member-detail.service';
import { NextPaymentService } from '../../services/next-payment.service';
import { PaymentInformationConfigurationComponent } from '../../components/payment-information-configuration/payment-information-configuration.component';
import { DeductionDetailComponent } from '../../components/deduction-detail/deduction-detail.component';
import { NoteMemoDetailComponent } from '../../components/note-memo-detail/note-memo-detail.component';
import { PaymentListConfigurationComponent } from '../../components/payment-list-configuration/payment-list-configuration.component';
import { AddNoteComponent } from '../../components/add-note/add-note.component';

const datepipe = new DatePipe('en-US');

@Component({
  selector: 'ptg-next-payment',
  templateUrl: './next-payment.component.html',
  styleUrls: ['./next-payment.component.scss'],
})
export class NextPaymentComponent extends BaseComponent {
  readonly PaymentInfoTypeEnum = PaymentInfoType;

  prevScreen?: ScreenType;
  // TODO
  listBreadcrumbs = [{ name: 'Next Payment' }];
  listBreadcrumbsBackToNextPayroll = [
    {
      name: 'Next Payroll',
      url: '/processing/next-payroll?keptState=true',
    },
    { 
      name: 'Payment Information',
      url: this.route.toString()
    }
  ];
  listBreadcrumbsBackToOffCyclePayments = [
    {
      name: 'Off-cycle Payments',
      url: '/processing/off-cycle-payments?keptState=true',
    },
    { 
      name: 'Payment Information',
      url: this.route.toString()
    }
  ];
  listBreadcrumbsHistory = [{ name: 'Payment Information' }];
  listBreadcrumbsBackHistory = [
    {
      name: 'Payroll Register',
      url: '/processing/pension-payroll',
    },
    { 
      name: 'Payment Information',
      url: this.route.toString()
    }
  ];
  breadcrumbs?: Breadcrumb[];
  isEstablishBenefit?: boolean = true;
  bannerType: BannerType = BannerType.Hidden;
  message = '';
  messageSuccess = '';
  messageFail = '';
  depositDate: any = '';
  isLoading: boolean = true;
  notIssueOffCyclePaymentId: string | undefined = '';
  memberId: string = '';
  selectedTargetId?: string;
  isIssuedOffCyclePayment: boolean | undefined = undefined;
  isReissuedOffCyclePayment: boolean | undefined = undefined;
  isPaymentHistory: boolean = false;

  // TODO Offcycle
  // Handle spinner
  offCyclePaymentId?: string;
  currentOffCyclePaymentId?: string;
  offCyclePayment?: OffCyclePayment;

  paymentSourceId?: string;
  isRedirectParticipant?: boolean = true;
  isReloading: boolean = false;
  payeeId?: string;
  benefitId?: string;
  originalTransactionId?: string;
  offCycleDepositDate?: any;
  paymentListConfig?: string = 'none';
  settings: Breadcrumb[] = [
    {
      name: 'Payment Info Configuration',
    },
    {
      name: 'Payment List Configuration',
    },
  ];

  paymentInforConfig: any = {};
  paymentInforConfigData: PaymentInforConfigData[] = [];
  isHiddenDetailContent: boolean = false;
  isMobileAl: boolean = false;
  sectionClassName: string = 'section';
  paymentInfoNoteType?: PaymentInfoNoteType;
  isEncryptedConfiguredName: boolean = false;
  isShowNote: boolean = false;
  menuId: string = '';
  currentSideMenuName: string = '';
  payPeriod = new FormControl('', Validators.required);
  currentPayPeriod?: string;
  isEditPayPeriod: boolean = false;
  isEntityView: boolean = false;
  viewId: string = '';

  @ViewChild(NoteMemoDetailComponent)
  noteMemoDetailComponent!: NoteMemoDetailComponent;
  @ViewChild(DeductionDetailComponent)
  deductionDetailComponent!: DeductionDetailComponent;

  @Input() isHistory: boolean = false;

  constructor(
    public route: ActivatedRoute,
    public memberDetailService: MemberDetailService,
    private nextPaymentService: NextPaymentService,
    private store: Store,
    private dialog: MatDialog,
    public historyService: HistoryService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.getRouteParams();
    this.getNavigationMenu();

    this.store.dispatch(NextPaymentActions.getPaymentInforConfig());
    this.combinePayment();

    this.getDataNextPayment();

    this.historyService.isBackNextPayroll =
      sessionStorage.getItem('isBackNextPayroll') === 'true';
    this.checkCreateOffCycleStatus();

    this.store.dispatch(MemberDetailActions.getMemberConfiguredIdNameRequest());
    combineLatest([
      this.store.pipe(
        select(fromNextPayment.selectPayrollConfigurationInfoState)
      ),
      this.store.pipe(select(fromNextPayment.selectIssueOffCyclePaymentState)),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        const configuredIdName = state[0]?.configuredIdName;
        this.isEncryptedConfiguredName =
          state[0]?.isEncryptedConfiguredName === true;
        const issueOffCyclePaymentState = state[1];
        if (issueOffCyclePaymentState?.issueState === STATE.SUCCESS) {
          const options = {
            customMessage: 'Off-cycle Payment successfully issued.',
          };
          showBanner.call(this, BannerType.Success, '', '', options);
          this.store.dispatch(
            NextPaymentActions.getNextPayment({
              section: 0,
              targetId: this.notIssueOffCyclePaymentId,
            })
          );
          this.notIssueOffCyclePaymentId = '';
          this.noteMemoDetailComponent.getDataFirstPage();
        } else if (issueOffCyclePaymentState?.issueState === STATE.FAIL) {
          let missingInfo = issueOffCyclePaymentState?.missingInfomation;
          if (
            missingInfo &&
            missingInfo?.split(', ')?.some((x) => x === 'Id') &&
            configuredIdName
          ) {
            missingInfo = missingInfo.replace('Id', configuredIdName);
          }
          const options = {
            customMessage: missingInfo
              ? `Error occurred issuing Off-cycle Payment. The following required information is missing: ${missingInfo}. Please fill in missing information.`
              : 'Error occurred issuing Off-cycle Payment. Please try again.',
          };
          showBanner.call(this, BannerType.Fail, '', '', options);
        }
        if (issueOffCyclePaymentState?.issueState) {
          this.store.dispatch(
            NextPaymentActions.clearIssueOffCyclePaymentState()
          );
        }
      });

    // Listen for Withdraw Off Cycle Payment actions
    this.store
      .pipe(
        select(fromNextPayment.selectWithdrawOffCyclePayment),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state?.withdrawOffCyclePaymentState === STATE.SUCCESS) {
          const options = {
            customMessage: 'Off-cycle Payment successfully withdrawn.',
          };
          showBanner.call(this, BannerType.Success, '', '', options);
          this.store.dispatch(NextPaymentActions.clearNextPayment());

          // Reload data
          this.store.dispatch(
            NextPaymentActions.getNextPayment({
              section: 0,
              targetId: this.selectedTargetId || this.offCyclePaymentId,
            })
          );
        }

        if (state?.withdrawOffCyclePaymentState === STATE.FAIL) {
          const options = {
            customMessage:
              'Error occurred withdrawing Off-cycle Payment. Please try again.',
          };
          showBanner.call(this, BannerType.Fail, '', '', options);
          this.store.dispatch(NextPaymentActions.clearNextPayment());
        }
      });

    this.store
      .pipe(select(fromNextPayment.selectRemoveOffCyclePaymentState))
      .subscribe((state) => {
        if (state === STATE.SUCCESS) {
          this.isRedirectParticipant = true;
          this.store.dispatch(
            NextPaymentActions.reloadPendingPayments({ isReload: true })
          );
          this.offCyclePaymentId = undefined;
        }

        showBanner.call(this, state || '', 'Off-Cycle Payment', ACTION.REMOVE);
        this.store.dispatch(NextPaymentActions.clearNextPayment());
      });

    this.store
      .pipe(select(fromReducer.selectCurrentFundState))
      .subscribe((el) => {
        this.isMobileAl = el?.fundType === FundType.MobileAL;
        this.sectionClassName = this.isMobileAl ? 'section-2' : 'section';
      });

    this.store
      .pipe(select(fromNextPayment.selectSetOffCyclePaymentPayPeriodState))
      .subscribe((el) => {
        if (el?.success) {
          showBanner.call(
            this,
            el?.success ? BannerType.Success : BannerType.Fail,
            'Pay Period',
            ACTION.EDIT
          );
          this.store.dispatch(NextPaymentActions.clearNextPayment());
          this.store.dispatch(
            NextPaymentActions.getNextPayment({
              section: 0,
              targetId: this.selectedTargetId || this.offCyclePaymentId,
            })
          );
        }
      });
  }

  getDataNextPayment() {
    this.store
      .pipe(
        select(fromNextPayment.selectNextPayment),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data) => {
        this.depositDate = data?.depositDate;
        this.isEstablishBenefit = data?.isEstablishBenefit;
        this.isLoading = data.isLoading;
        this.payPeriod.setValue(data?.payPeriod);
        this.currentPayPeriod = data?.payPeriod;

        this.offCyclePayment = data?.offCyclePayment;
        if (this.offCyclePayment) {
          this.offCycleDepositDate =
            data?.paymentHistoryList?.payload?.paymentHistories.find(
              (item: any) => item.id === this.offCyclePayment?.id
            )?.depositDate;
        }
        this.originalTransactionId =
          data?.offCyclePayment?.originalTransactionId;
        this.isIssuedOffCyclePayment =
          (this.offCyclePayment?.paymentInfoType === PaymentInfoType.Reissue ||
            this.offCyclePayment?.paymentInfoType ===
              PaymentInfoType['Off-Cycle']) &&
          this.offCyclePayment?.paymentStatus ===
            OffCyclePaymentStatusType.Issued;
        this.notIssueOffCyclePaymentId =
          (this.offCyclePayment?.paymentInfoType === PaymentInfoType.Reissue ||
            this.offCyclePayment?.paymentInfoType ===
              PaymentInfoType['Off-Cycle']) &&
          this.offCyclePayment?.paymentStatus ===
            OffCyclePaymentStatusType.NotIssued
            ? data.targetId
            : '';
        this.isReissuedOffCyclePayment =
          this.offCyclePayment?.paymentInfoType === PaymentInfoType.Reissue;

        if (
          this.prevScreen === ScreenType.CompleteOffCyclePayment &&
          data.paymentHistoryList?.payload?.paymentHistories?.length! > 0 &&
          this.offCyclePayment?.offCycleId
        ) {
          this.listBreadcrumbsBackToOffCyclePayments = [
            {
              name: 'Complete Off-cycle Payment',
              url: `/processing/off-cycle-payments/complete/${this.offCyclePayment.offCycleId}?prevScreen=${ScreenType.OffCyclePayments}&keptState=true`,
            },
            { 
              name: 'Payment Information',
              url: this.route.toString()
            }
          ];
        }

        if (
          this.offCycleDepositDate &&
          data.paymentHistoryList?.payload?.paymentHistories?.length! > 0
        ) {
          setTimeout(() => {
            if (this.historyService.isBackNextPayroll) {
              if (this.offCyclePayment) {
                this.listBreadcrumbsBackToOffCyclePayments = [
                  {
                    name:
                      'Off-Cycle Payment ' +
                      datepipe.transform(
                        this.offCycleDepositDate,
                        'MM/dd/yyyy'
                      ),
                    url: `/processing/off-cycle-payments/complete/${this.offCyclePayment.offCycleId}?prevScreen=${ScreenType.PaymentHistory}&keptState=true`,
                  },
                  { 
                    name: 'Payment Information',
                    url: this.route.toString()
                  }
                ];
              }
            }
          });
        }
        if (
          this.depositDate &&
          data.paymentHistoryList?.payload?.paymentHistories?.length! > 0
        ) {
          setTimeout(() => {
            if (this.historyService.isBackNextPayroll) {
              this.listBreadcrumbsBackHistory = [
                {
                  name:
                    'Payment ' +
                    datepipe.transform(this.depositDate, 'MM/dd/yyyy'),
                  url: `/processing/payment-information/${this.nextPaymentService.payrollId}?keptState=true`,
                },
                { 
                  name: 'Payment Information',
                  url: this.route.toString()
                }
              ];
            }
          });
        }
        if (
          data.addNoteState === STATE.SUCCESS ||
          data.updateEarningsState === STATE.SUCCESS ||
          data.updateDeductionState === STATE.SUCCESS ||
          data.updatePayrollSettingsState === STATE.SUCCESS ||
          data.updatePaymentState === STATE.SUCCESS ||
          data.updateMemoState === STATE.SUCCESS ||
          data.removeMemoState === STATE.SUCCESS ||
          data.savePaymentInforConfigState === STATE.SUCCESS
        ) {
          let addNoteState = data.addNoteState;
          let updateMemoState = data.updateMemoState;
          let removeMemoState = data.removeMemoState;
          let updateEarningsState = data.updateEarningsState;
          let updateDeductionState = data.updateDeductionState;
          let updatePayrollSettingsState = data.updatePayrollSettingsState;
          let updatePaymentState = data.updatePaymentState;

          this.bannerType = BannerType.Success;
          this.message = this.messageSuccess;
          this.store.dispatch(NextPaymentActions.clearNextPayment());
          if (
            addNoteState === 'Success' ||
            updateMemoState === STATE.SUCCESS ||
            removeMemoState === STATE.SUCCESS
          ) {
            this.noteMemoDetailComponent.getDataFirstPage();
            if (addNoteState === 'Success') {
              this.store.dispatch(
                NextPaymentActions.reloadPendingPayments({
                  isReload: true,
                  selectedRowId: this.offCyclePaymentId || this.payeeId,
                })
              );
            }
            showBanner.call(
              this,
              BannerType.Success,
              this.paymentInfoNoteType === PaymentInfoNoteType.Memo ||
                removeMemoState
                ? 'Memo'
                : 'Note',
              addNoteState
                ? ACTION.ADD
                : updateMemoState
                ? ACTION.EDIT
                : ACTION.REMOVE
            );
          }
          if (updateEarningsState === 'Success') {
            this.store.dispatch(
              NextPaymentActions.getNextPayment({
                section: 2,
                targetId: data?.targetId || this.nextPaymentService.memberId,
              })
            );
            this.store.dispatch(
              NextPaymentActions.reloadPendingPayments({
                isReload: true,
                selectedRowId: this.offCyclePaymentId || this.payeeId,
              })
            );
          }
          if (updatePayrollSettingsState === 'Success') {
            this.store.dispatch(
              NextPaymentActions.getNextPayment({
                section: 3,
                targetId: data?.targetId || this.nextPaymentService.memberId,
              })
            );
            this.store.dispatch(
              NextPaymentActions.reloadPendingPayments({
                isReload: true,
                selectedRowId: this.offCyclePaymentId || this.payeeId,
              })
            );
          }
          if (updatePaymentState === 'Success') {
            this.store.dispatch(
              NextPaymentActions.getNextPayment({
                section: 4,
                targetId: data?.targetId || this.nextPaymentService.memberId,
              })
            );
            this.store.dispatch(
              NextPaymentActions.reloadPendingPayments({
                isReload: true,
                selectedRowId: this.offCyclePaymentId || this.payeeId,
              })
            );
          }
          if (updateDeductionState === 'Success') {
            this.store.dispatch(
              NextPaymentActions.getNextPayment({
                section: 1,
                targetId: data?.targetId || this.nextPaymentService.memberId,
              })
            );
            this.store.dispatch(
              NextPaymentActions.reloadPendingPayments({
                isReload: true,
                selectedRowId: this.offCyclePaymentId || this.payeeId,
              })
            );
          }

          if (data.savePaymentInforConfigState === STATE.SUCCESS) {
            showBanner.call(
              this,
              BannerType.Success,
              'Payment Information Configuration',
              ACTION.EDIT
            );
            this.store.dispatch(NextPaymentActions.getPaymentInforConfig());
          }
        }
        if (
          data.addNoteState === STATE.FAIL ||
          data.updateEarningsState === STATE.FAIL ||
          data.updateDeductionState === STATE.FAIL ||
          data.updatePaymentState === STATE.FAIL ||
          data.updatePayrollSettingsState === STATE.FAIL ||
          data.updateMemoState === STATE.FAIL ||
          data.removeMemoState === STATE.FAIL ||
          data.savePaymentInforConfigState === STATE.FAIL
        ) {
          if (
            data.addNoteState === STATE.FAIL ||
            data.updateMemoState === STATE.FAIL ||
            data.removeMemoState === STATE.FAIL
          ) {
            const content =
              this.paymentInfoNoteType === PaymentInfoNoteType.Memo
                ? 'Memo'
                : 'Note';
            const action = data.addNoteState
              ? ACTION.ADD
              : data.updateMemoState
              ? ACTION.EDIT
              : ACTION.REMOVE;
            showBanner.call(this, BannerType.Fail, content, action);
          }

          this.store.dispatch(NextPaymentActions.clearNextPayment());

          if (
            data.recalculateDeductionsError?.error?.errorType ===
              NO_MATCHING_TAX_BRACKET_EXCEPTION &&
            data.recalculateDeductionsError?.error?.errorMessage
          ) {
            this.bannerType = BannerType.Fail;
            this.message = this.messageFail;
            const options = {
              customMessage:
                data.recalculateDeductionsError.error.errorMessage[0],
            };
            showBanner.call(this, BannerType.Fail, '', '', options);
          }

          if (data.savePaymentInforConfigState === STATE.FAIL) {
            showBanner.call(
              this,
              BannerType.Fail,
              'Payment Information Configuration',
              ACTION.EDIT
            );
          }
        }
      });
  }
  getNavigationMenu() {
    combineLatest([
      this.route.params,
      this.store.select(fromLayoutReducer.selectMemberNavigationListState),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (!data[0] || !data[1]?.memberNavigationMenu) {
          return;
        }
        const currentMenu = data[0];
        const memberNavigationMenu: MemberNavigationResponse[] =
          data[1].memberNavigationMenu;

        this.menuId = currentMenu.menuId;
        const currentNavigationMenu = memberNavigationMenu?.find((menu) =>
          menu?.menuItem?.some((item) => item.id === this.menuId)
        );
        // TODO: comment for apply entity.
        // const memberNavigationItem = currentNavigationMenu?.menuItem?.find(
        //   (navigation) => navigation.id === this.menuId
        // );

        const menuItems = memberNavigationMenu
          ?.map((item) => {
            return item.menuItems;
          })
          ?.reduce((a, b) => {
            return a.concat(b);
          });

          this.currentSideMenuName = menuItems?.find((item: any) => item?.entityViewId === this.viewId)?.name || '';
      });
  }

  combinePayment() {
    combineLatest([
      this.store.pipe(select(fromNextPayment.selectPendingPaymentState)),
      this.store.pipe(select(fromNextPayment.selectNextPaymentHistory)),
      this.store.pipe(select(fromNextPayment.selectPaymentInforConfigState)),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        const listPendingPayment = state[0]?.success
          ? (deepClone(state[0]?.payload?.pendingPayments) as any)
          : null;
        const listPaymentHistory = state[1]?.success
          ? (deepClone(state[1]?.payload?.paymentHistories) as any)
          : null;
        if (state[2].length > 0) {
          this.paymentInforConfigData = state[2];
          this.paymentInforConfigData.forEach((ele) => {
            this.paymentInforConfig[ele.code] = ele.isDisplay;
            if (ele.children.length > 0) {
              ele.children.forEach((child) => {
                this.paymentInforConfig[child.code] = child.isDisplay;
              });
            }
          });
          if (!this.paymentInforConfig.PENDINGPAYMENTS) {
            this.isHistory = true;
          }
          this.isShowNote = this.paymentInforConfig.NOTE;
          if (
            !this.paymentInforConfig.PENDINGPAYMENTS &&
            !this.paymentInforConfig.DEDUCTIONS &&
            !this.paymentInforConfig.EARNINGS &&
            !this.paymentInforConfig.PAYMENTS &&
            !this.paymentInforConfig.PAYROLLSETTINGS &&
            !this.paymentInforConfig.NOTE
          ) {
            this.isHiddenDetailContent = true;
          } else {
            this.isHiddenDetailContent = false;
          }
        }

        const recurringPayment = listPendingPayment?.find(
          (x: any) => x.type === PaymentInfoType.Recurring
        );
        if (
          this.isRedirectParticipant &&
          state[0]?.isLoading === false &&
          state[1]?.isLoading === false
        ) {
          if (recurringPayment && this.paymentInforConfig.PENDINGPAYMENTS) {
            if (!this.offCyclePaymentId) {
              this.isHistory = false;
              this.payeeId = recurringPayment.id;
              this.selectedTargetId = recurringPayment.id;
              this.offCyclePaymentId = undefined;
              this.paymentSourceId = recurringPayment.paymentSourceId;
              this.store.dispatch(
                NextPaymentActions.getNextPayment({
                  section: 0,
                  targetId: recurringPayment.targetId,
                  benefitId: this.benefitId
                })
              );
            } else {
              this.store.dispatch(
                NextPaymentActions.getNextPayment({
                  section: 0,
                  targetId: this.offCyclePaymentId,
                })
              );
            }
          } else {
            const paymentHistory = listPaymentHistory?.find((x: any) => x.id);
            this.nextPaymentService.payrollId = paymentHistory?.paymentSourceId;
            this.paymentSourceId = paymentHistory?.paymentSourceId;

            if (paymentHistory?.type === PaymentInfoType.Recurring) {
              this.payeeId = paymentHistory?.id;
              this.nextPaymentService.payeeId = paymentHistory?.id;
              this.offCyclePaymentId = undefined;
              this.selectedTargetId = paymentHistory?.id;
            } else {
              this.payeeId = undefined;
              this.nextPaymentService.payeeId = undefined;
              this.offCyclePaymentId = paymentHistory?.targetId;
              this.selectedTargetId = paymentHistory?.targetId;
            }
            this.isHistory = !isEmpty(this.selectedTargetId);
            if (paymentHistory) {
              this.store.dispatch(
                NextPaymentActions.getNextPayment({
                  section: 0,
                  isHistory: true,
                  targetId: this.selectedTargetId,
                  benefitId: this.benefitId
                })
              );
            }
          }
        } else if (
          !this.isRedirectParticipant &&
          state[0]?.isLoading === false &&
          state[1]?.isLoading === false &&
          state[2]?.length > 0
        ) {
          if (this.paymentInforConfig.PENDINGPAYMENTS) {
            if (!this.isPaymentHistory) {
              if (
                this.payeeId !== undefined &&
                this.currentOffCyclePaymentId === undefined
              ) {
                if (listPendingPayment?.length > 0) {
                  const pendingPayment = listPendingPayment?.find(
                    (x: any) => x.id && x.type === PaymentInfoType.Recurring
                  );
                  this.nextPaymentService.payrollId =
                    pendingPayment?.paymentSourceId;
                  this.paymentSourceId = pendingPayment?.paymentSourceId;
                  this.payeeId = pendingPayment?.id;
                  this.nextPaymentService.payeeId = pendingPayment?.targetId;
                  this.offCyclePaymentId = undefined;
                  this.selectedTargetId = pendingPayment?.targetId;
                  this.store.dispatch(
                    NextPaymentActions.getNextPayment({
                      section: 0,
                      targetId: this.selectedTargetId,
                      benefitId: this.benefitId
                    })
                  );
                }
              }

              if (
                this.offCyclePaymentId !== undefined ||
                this.currentOffCyclePaymentId !== undefined
              ) {
                const pendingPayment = listPendingPayment?.find(
                  (x: any) =>
                    (x.id === this.offCyclePaymentId ||
                      x.id === this.currentOffCyclePaymentId) &&
                    x.type === PaymentInfoType['Off-Cycle']
                );
                this.nextPaymentService.payrollId =
                  pendingPayment?.paymentSourceId;
                this.paymentSourceId = pendingPayment?.paymentSourceId;
                this.payeeId = undefined;
                this.nextPaymentService.payeeId = undefined;
                this.offCyclePaymentId = pendingPayment?.id;

                this.selectedTargetId = pendingPayment?.id;
                this.store.dispatch(
                  NextPaymentActions.getNextPayment({
                    section: 0,
                    targetId: this.selectedTargetId,
                  })
                );
              }
            } else {
              const paymentHistory = listPaymentHistory?.find(
                (x: any) => x.id === this.payeeId
              );
              if (paymentHistory !== undefined) {
                this.payeeId = paymentHistory?.id;
                this.nextPaymentService.payeeId = paymentHistory?.targetId;
                this.offCyclePaymentId = undefined;
                this.selectedTargetId = paymentHistory?.targetId;
                this.store.dispatch(
                  NextPaymentActions.getNextPayment({
                    section: 0,
                    isHistory: true,
                    targetId: this.selectedTargetId,
                    benefitId: this.benefitId
                  })
                );
              }
            }
          } else {
            if (this.payeeId !== undefined) {
              const paymentHistory = listPaymentHistory?.find(
                (x: any) => x.id === this.payeeId
              );

              if (paymentHistory !== undefined) {
                if (paymentHistory?.type === PaymentInfoType.Recurring) {
                  this.payeeId = paymentHistory?.id;
                  this.nextPaymentService.payeeId = paymentHistory?.id;
                  this.offCyclePaymentId = undefined;
                  this.selectedTargetId = paymentHistory?.id;
                } else {
                  this.payeeId = undefined;
                  this.nextPaymentService.payeeId = undefined;
                  this.offCyclePaymentId = paymentHistory?.targetId;
                  this.selectedTargetId = paymentHistory?.targetId;
                }
                this.store.dispatch(
                  NextPaymentActions.getNextPayment({
                    section: 0,
                    isHistory: true,
                    targetId: this.selectedTargetId,
                    benefitId: this.benefitId
                  })
                );
              } else {
                if (listPaymentHistory.length > 0) {
                  this.nextPaymentService.payrollId =
                    listPaymentHistory[0]?.paymentSourceId;
                  this.paymentSourceId = listPaymentHistory[0]?.paymentSourceId;
                  this.payeeId = listPaymentHistory[0]?.id;
                  this.nextPaymentService.payeeId =
                    listPaymentHistory[0]?.targetId;
                  this.offCyclePaymentId = undefined;
                  this.selectedTargetId = listPaymentHistory[0]?.targetId;
                  this.store.dispatch(
                    NextPaymentActions.getNextPayment({
                      section: 0,
                      isHistory: true,
                      targetId: this.selectedTargetId,
                      benefitId: this.benefitId
                    })
                  );
                }
              }
            }

            if (this.offCyclePaymentId !== undefined) {
              const paymentHistory = listPaymentHistory?.find(
                (x: any) => x.id === this.offCyclePaymentId
              );

              if (paymentHistory !== undefined) {
                if (paymentHistory?.type === PaymentInfoType.Recurring) {
                  this.payeeId = paymentHistory?.id;
                  this.nextPaymentService.payeeId = paymentHistory?.id;
                  this.offCyclePaymentId = undefined;
                  this.selectedTargetId = paymentHistory?.id;
                } else {
                  this.payeeId = undefined;
                  this.nextPaymentService.payeeId = undefined;
                  this.offCyclePaymentId = paymentHistory?.targetId;
                  this.selectedTargetId = paymentHistory?.targetId;
                }
                this.store.dispatch(
                  NextPaymentActions.getNextPayment({
                    section: 0,
                    isHistory: true,
                    targetId: this.selectedTargetId,
                    benefitId: this.benefitId
                  })
                );
              } else {
                if (listPaymentHistory.length > 0) {
                  this.nextPaymentService.payrollId =
                    listPaymentHistory[0]?.paymentSourceId;
                  this.paymentSourceId = listPaymentHistory[0]?.paymentSourceId;
                  this.payeeId = listPaymentHistory[0]?.id;
                  this.nextPaymentService.payeeId =
                    listPaymentHistory[0]?.targetId;
                  this.offCyclePaymentId = undefined;
                  this.payeeId = listPaymentHistory[0]?.id;
                  this.selectedTargetId = listPaymentHistory[0]?.targetId;
                  this.store.dispatch(
                    NextPaymentActions.getNextPayment({
                      section: 0,
                      isHistory: true,
                      targetId: this.selectedTargetId,
                    })
                  );
                }
              }
            }
          }
        }

        if ((state[0]?.success || state[1]?.success) && state[2]?.length > 0) {
          this.paymentListConfig =
            state[0]?.payload?.columns?.length! > 0 ||
            state[1]?.payload?.columns?.length! > 0
              ? 'show'
              : 'hide';
        }
      });
  }

  getRouteParams() {
    combineLatest([this.route.queryParams, this.route.params])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([queryParams, params]) => {
        // TODO: need remove logic after complete implement entity
        this.isEntityView = this.route.snapshot.url.some(path => path.path === 'system-view');
        this.viewId = params.viewId;

        if (isNumeric(queryParams.prevScreen))
          this.prevScreen = Number(queryParams.prevScreen);

        if (queryParams.offCyclePaymentId) {
          this.offCyclePaymentId = queryParams.offCyclePaymentId;
          this.currentOffCyclePaymentId = queryParams.offCyclePaymentId;
          this.isRedirectParticipant = false;
        }

        if (queryParams.paymentSourceId) {
          this.paymentSourceId = queryParams.paymentSourceId;
          this.isRedirectParticipant = false;
        }

        if (queryParams.payeeId) {
          this.payeeId = queryParams.payeeId;
          this.isRedirectParticipant = false;
        }

        this.benefitId = queryParams.benefitId;
        this.isHistory = stringToBoolean(queryParams.isHistory);
        this.isPaymentHistory = stringToBoolean(queryParams.isHistory);

        if (params.id) {
          this.memberId = params.id;
          this.memberDetailService.memberId = params.id;
          this.nextPaymentService.memberId = params.id;
          this.store.dispatch(NextPaymentActions.clearGetPendingPaymentState());
          this.store.dispatch(NextPaymentActions.clearGetPaymentHistoryState());
          this.store.dispatch(
            NextPaymentActions.getNextPayment({
              section: 0,
              isHistory: this.isHistory,
              targetId: this.offCyclePaymentId,
              benefitId: this.benefitId
            })
          );
        }

        if (this.isEntityView) {
          this.getViewName();
        }
      });
  }

  getViewName() {    
    this.store.select(fromLayoutReducer.selectMemberNavigationListState).pipe(takeUntil(this.unsubscribe$))
      .subscribe(menu => {
        if (menu.memberNavigationMenu) {
          const memberNavigationMenu: MemberNavigationResponse[] =
          menu?.memberNavigationMenu;
          const menuItems = memberNavigationMenu
            ?.map((item) => {
              return item.menuItems;
            })
            ?.reduce((a, b) => {
              return a.concat(b);
            });

          const menuName = menuItems?.find((item: any) => item?.entityViewId === this.viewId)?.name;
          this.historyService.isBackNextPayroll = true;
          this.breadcrumbs = [{
            name: menuName || '',
          }];
        }
      });

  }

  selectPaymentInforConfig(settingName: string) {
    this.route.params.subscribe((params) => {
      if (settingName === 'Payment Info Configuration') {
        this.dialog.open(PaymentInformationConfigurationComponent, {
          panelClass: 'dialog-full-screen',
          autoFocus: false,
          disableClose: true,
          data: {
            sectionType: params['sectionType'],
            sectionId: params['menuId'],
            memberId: params['id'],
            menuItemName: this.currentSideMenuName,
          },
        });
      } else if (settingName === 'Payment List Configuration') {
        this.dialog.open(PaymentListConfigurationComponent, {
          panelClass: 'dialog-full-screen',
          autoFocus: false,
          disableClose: true,
          data: {
            sectionType: params['sectionType'],
            sectionId: params['menuId'],
            memberId: params['id'],
            menuItemName: this.currentSideMenuName,
          },
        });
      }
    });
  }

  checkCreateOffCycleStatus() {
    this.store
      .pipe(select(fromNextPayment.selectCreateOffCycleState))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (!state?.state) {
          return;
        }
        if (state?.state === STATE.SUCCESS) {
          this.isHistory = false;
          this.payeeId = undefined;
          this.nextPaymentService.payeeId = undefined;
          this.offCyclePaymentId = state.newOffCyclePaymentId;
          this.selectedTargetId = state.newOffCyclePaymentId;

          this.store.dispatch(
            NextPaymentActions.reloadPendingPayments({
              isReload: true,
              resetSort: true,
              selectedRowId: state.newOffCyclePaymentId,
            })
          );
          this.store.dispatch(
            NextPaymentActions.getNextPayment({
              section: 0,
              targetId: state.newOffCyclePaymentId,
            })
          );
        }
        showBanner.call(
          this,
          state?.state || '',
          'Off-cycle Payment',
          ACTION.ADD
        );
        this.store.dispatch(
          NextPaymentActions.clearCreateOffCyclePaymentState()
        );
      });
  }

  onClickAdd(event: any) {
    this.paymentInfoNoteType = event.noteType;
    const dialogRef = this.dialog.open(AddNoteComponent, {
      panelClass:
        event.noteType === PaymentInfoNoteType.Note
          ? 'add-note-popup'
          : 'add-memo-popup',
      disableClose: true,
      autoFocus: false,
      data: {
        offCyclePayment: this.offCyclePayment,
        paymentSourceId: this.paymentSourceId,
        noteType: event.noteType,
        memoDetails: event.memoDetails,
        isBackNextPayroll: this.historyService.isBackNextPayroll,
      },
    });
  }

  onRemoveMemo(event: any) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        type: ConfirmType.Delete,
        title: 'Remove',
        text: 'Are you sure you want to remove this Memo and all its contents?',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(NextPaymentActions.removeMemo({ id: event.id }));
        showBanner.call(this, BannerType.Success, 'Memo', ACTION.REMOVE);
        if (result.error) {
          showBanner.call(this, BannerType.Fail, 'Memo', ACTION.REMOVE);
        }
      }
    });
  }

  // Banner
  changeBannerDeduction() {
    this.messageSuccess = `Deductions successfully updated.`;
    this.messageFail = 'Error occurred updating Deductions. Please try again.';
  }

  changeBannerEarning(): void {
    this.messageSuccess = `Earnings successfully updated.`;
    this.messageFail = 'Error occurred updating Earnings. Please try again.';
  }

  changeBannerPayrollSetting(): void {
    if (this.offCyclePayment) {
      this.messageSuccess = `Payment Settings successfully updated.`;
      this.messageFail =
        'Error occurred updating Payment Settings. Please try again.';
    } else {
      this.messageSuccess = `Payroll Settings successfully updated.`;
      this.messageFail =
        'Error occurred updating Payroll Settings. Please try again.';
    }
  }

  changeBannerPayment() {
    this.messageSuccess = `Payments successfully updated.`;
    this.messageFail = 'Error occurred updating Payments. Please try again.';
  }

  onSelectPaymentHistoryRow(event: PaymentHistory & Row) {
    if (!this.breadcrumbs) {
      this.breadcrumbs =
        this.isHistory && !this.offCyclePaymentId
          ? this.listBreadcrumbsBackHistory
          : this.offCyclePaymentId
          ? this.listBreadcrumbsBackToOffCyclePayments
          : this.listBreadcrumbsBackToNextPayroll;
    }
    const paymentHistory = event;
    this.offCycleDepositDate = event.depositDate;

    this.isHistory = true;
    this.benefitId = event.benefitId;
    if (paymentHistory?.type === PaymentInfoType.Recurring) {
      this.payeeId = paymentHistory?.id;
      this.nextPaymentService.payeeId = paymentHistory?.id;
      this.offCyclePaymentId = undefined;
      this.selectedTargetId = paymentHistory?.id;
    } else {
      this.payeeId = undefined;
      this.nextPaymentService.payeeId = undefined;
      this.offCyclePaymentId = paymentHistory?.targetId;
      this.selectedTargetId = paymentHistory?.targetId;
    }

    this.nextPaymentService.payrollId = paymentHistory?.paymentSourceId;
    this.paymentSourceId = paymentHistory?.paymentSourceId;
    this.store.dispatch(
      NextPaymentActions.getNextPayment({
        section: 0,
        isHistory: true,
        targetId: this.selectedTargetId,
        benefitId: event.benefitId
      })
    );

    //close banner
    this.bannerType = BannerType.Hidden;
  }

  onSelectRowPendingPayment(event: PendingPayment & Row) {
    if (!this.breadcrumbs) {
      this.breadcrumbs =
        this.isHistory && !this.offCyclePaymentId
          ? this.listBreadcrumbsBackHistory
          : this.offCyclePaymentId
          ? this.listBreadcrumbsBackToOffCyclePayments
          : this.listBreadcrumbsBackToNextPayroll;
    }
    const pendingPayment = event;
    this.isHistory = false;
    this.paymentSourceId = pendingPayment.paymentSourceId;

    this.benefitId = event.benefitId;
    if (pendingPayment?.type === PaymentInfoType.Recurring) {
      this.payeeId = pendingPayment?.id;
      this.nextPaymentService.payeeId = undefined;
      this.offCyclePaymentId = undefined;
      this.selectedTargetId = pendingPayment.targetId;
    } else {
      this.payeeId = undefined;
      this.nextPaymentService.payeeId = undefined;
      this.offCyclePaymentId = pendingPayment.id;
      this.selectedTargetId = pendingPayment.id;
    }

    this.store.dispatch(
      NextPaymentActions.getNextPayment({
        section: 0,
        targetId: this.selectedTargetId,
        benefitId: this.benefitId
      })
    );

    //close banner
    this.bannerType = BannerType.Hidden;
  }

  onClickNewOffCycle() {
    this.dialog.open(AddOffCyclePaymentDialogComponent, {
      panelClass: 'add-off-cycle-payment-dialog',
      disableClose: true,
      width: '570px',
      height: 'auto',
      maxHeight: '90vh',
      autoFocus: false,
    });
  }

  onClickIssueOffCyclePayment() {
    if (this.notIssueOffCyclePaymentId) {
      const body: IssueOffCyclePaymentRequest = {
        participantId: this.memberId,
        offCyclePaymentId: this.notIssueOffCyclePaymentId,
      };
      this.store.dispatch(NextPaymentActions.issueOffCyclePayment({ body }));
    }
  }

  onClickWithdrawPayment(): void {
    const targetId =
      this.selectedTargetId && this.offCyclePayment
        ? this.selectedTargetId
        : this.offCyclePaymentId;
    if (targetId)
      this.store.dispatch(
        NextPaymentActions.withdrawOffCyclePayment({
          offCyclePaymentId: targetId,
        })
      );
  }

  onClickRemoveOffCyclePayment() {
    if (!this.notIssueOffCyclePaymentId) {
      return;
    }
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        title: 'Remove',
        text: `Are you sure you want to remove this incomplete Off-Cycle payment and all its content?`,
        type: ConfirmType.Delete,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(
          NextPaymentActions.removeOffCyclePaymentRequest({
            offCyclePaymentId: this.notIssueOffCyclePaymentId || '',
          })
        );
      }
    });
  }

  beginEditPayPeriod() {
    this.isEditPayPeriod = true;
  }

  editPayPeriod(action: string) {
    if (action === 'close') {
      this.isEditPayPeriod = false;
      this.payPeriod.setValue(this.currentPayPeriod);
    } else {
      this.payPeriod.markAsTouched();
      if (this.payPeriod.valid) {
        this.isEditPayPeriod = false;
        this.store.dispatch(
          NextPaymentActions.setOffCyclePaymentPayPeriod({
            id: this.offCyclePaymentId!,
            body: {
              payPeriod: this.payPeriod?.value,
            },
          })
        );
      }
    }
  }
}
