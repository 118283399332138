import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { filter, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { BaseComponent } from '@ptg-shared/components';
import * as fromReducer from '@ptg-reducers';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { Option } from '@ptg-shared/controls/select/select.component';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { ACTION, CANCEL_CONFIRM_MESSAGE } from '@ptg-shared/constance';
import { deepClone, showBanner } from '@ptg-shared/utils/common.util';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { RadioOption } from '@ptg-shared/controls/radio-button/radio-button.component';
import { ACTION_COLUMN, Align, Column, GridComponent, Row, getValidatorsFromColumns } from '@ptg-shared/controls/grid';

import { Header, HeaderProperty, ItemDto, Property, UpdateHeaderDetailRequest } from '@ptg-member/types/models';
import { selectHeaderDetailState, selectHeaderPropertiesState, updateHeaderSelector } from '@ptg-member/store/selectors';
import { clearHeaderDetailState, getHeaderDetail, getHeaderProperties, updateHeader } from '@ptg-member/store/actions/header.actions';
import { propertyType } from '@ptg-member/constants';
import { HeaderService } from '@ptg-member/services/header.service';
import { VERTICAL_LINE_SEPARATOR } from '@ptg-shared/constance/common.const';

@Component({
  selector: 'ptg-header-detail',
  templateUrl: './header-detail.component.html',
  styleUrls: ['./header-detail.component.scss'],
})
export class HeaderDetailComponent extends BaseComponent {
  readonly ACTION_COLUMN = ACTION_COLUMN;

  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Participant List',
      url: '/member',
    },
    {
      name: 'Header List',
      url: '/member/header',
    },
    {
      name: 'Current View Name',
    },
  ];

  configuredPropertyColumns: Column[] = [
    {
      name: 'name',
      truncate: true,
      editable: true,
      validators: {
        required: {
          type: (obj: any) => Validators.required,
          message: (error: any, fieldName: string) =>
            `Label is required.`,
        },
        maxlength: {
          type: (obj: any) => Validators.maxLength(250),
          message: (error: any, fieldName: string) =>
            `Exceed the ${error.requiredLength} characters limit.`,
        },
        existed: {
          type: (obj: any) => this.checkExits(obj),
          message: (error: any, fieldName: string) =>
            `Label already exists.`,
        },
      },
      controlArgs: {
        placeholder: 'Label',
        maxLength: 250,
      }
    },
    {
      name: ACTION_COLUMN,
      align: Align.Left,
      width: '50px',
    },
  ];

  headerDetailData!: Header;

  availableTypeConfigs!: Option[];
  propertyOptions: RadioOption[] = [];
  availablePropertyConfigs!: Option[];

  propertyConfigs!: HeaderProperty[];
  leftCard: (Header & Row)[] = [];

  message: string = '';
  headerId!: string;
  bannerType: BannerType = BannerType.Hidden;

  isChanged: boolean = false;
  isLoadingAddProperty: boolean = false;
  formSubmit$ = new Subject<boolean>();
  isLoading: boolean = true;

  editForm?: FormGroup;

  addPropertyForm: FormGroup = this.fb.group({
    option: '',
    propertyType: this.fb.control('', [Validators.required]),
    propertyName: this.fb.control('', [Validators.required]),
    propertyLabel: this.fb.control('', [Validators.required]),
  });

  // Form controls
  get nameCtrl(): FormControl {
    return this.editForm?.get('name') as FormControl;
  }

  @ViewChild('gridLeftCard')
  gridLeftCard?: GridComponent<Header>;

  constructor(
    private store: Store<fromReducer.State>,
    private dialog: MatDialog,
    public memberStore: Store<fromReducer.State>,
    public headerService: HeaderService,
    private fb: FormBuilder,
    public route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.headerId = params.id;
    });

    // Init form
    this.initFormGroup();

    // Get view detail
    this.loadDataHeaderDetail();

    this.loadDataHeaderDetailAction();
    // Listen for submit form
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm?.markAllAsTouched();
        }),
        switchMap(() =>
          this.editForm!.statusChanges.pipe(
            startWith(this.editForm!.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter(
          (status) =>
            status === AbstractControlStatus.VALID &&
            this.gridLeftCard?.formStatus === AbstractControlStatus.VALID
        )
      )
      .subscribe(() => {
        this.onSubmit();
      });
    
    // Get current list property name
    this.memberStore.pipe(select(selectHeaderPropertiesState), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data?.success && data.payload?.response) {
        this.propertyConfigs = data.payload?.response.map(item => {
          const cloneData = deepClone(item);
          if (item?.entityReferencePropertyNames?.length) {
            cloneData.id = `${item.id}|${item.entityReferencePropertyNames[0].id}`;
          }
          return cloneData;
        });
        this.addPropertyForm.get('propertyName')?.reset();
      } else {
        this.propertyConfigs = [];
      }
      
      this.getAvailablePropertyConfigs();
    })

    // Listen update status
    this.memberStore.pipe(select(updateHeaderSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data?.success && !data?.isLoading) {
        this.loadDataHeaderDetailAction();
        showBanner.call(this, BannerType.Success, 'Header', ACTION.EDIT);
      } else if (data?.error) {
        showBanner.call(this, BannerType.Fail, 'Header', ACTION.EDIT);
      }

      this.propertyOptions = [];
      this.addPropertyForm.reset();
    }, (error) => {
      if (error) {
      }
    })

    this.getAvailableTypeConfigs();
    
    this.store.dispatch(LayoutActions.hiddenSideMenu());
  }

  ngOnDestroy(): void {
    this.editForm?.reset();
    this.addPropertyForm.reset();
    this.memberStore.dispatch(clearHeaderDetailState());
  }

  changeProperty() {
    const currentProperty = this.addPropertyForm.get('propertyName')?.value;
    currentProperty?.name && this.addPropertyForm.get('propertyLabel')?.setValue(currentProperty?.name);

    let options: RadioOption[] = [];
    if (currentProperty?.options) {
      options = Object.keys(currentProperty?.options)?.map((key) => {
        return { 
          label: key, 
          value: currentProperty.options[key]
        }
      });
    }

    this.propertyOptions = (options || []).filter(option => {
      const isExisted = this.gridLeftCard?.dataSource.find(
        item => (item?.ppName === currentProperty?.name && option.label === item?.option)
      );
      return !isExisted;
    });

    if (this.propertyOptions?.length) {
      this.addPropertyForm.patchValue({
        option: this.propertyOptions[0].value
      })
    }
  }

  changePropertyType() {
    if (this.headerDetailData.entityId) {
      this.memberStore.dispatch(getHeaderProperties({
        query: {
          entityId: this.headerDetailData.entityId,
          type: this.addPropertyForm.get('propertyType')?.value
        }
      }))
      this.addPropertyForm.get('propertyLabel')?.setValue('');
      this.propertyOptions = [];
    }
  }

  private checkExits(obj?: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isDuplicatePropertyDraft = this.gridLeftCard?.dataSource
        .filter((item: any) => item.propertyId !== obj?.propertyId )
        .find((item: any) => (item?.label ?? item?.name)?.toLowerCase()?.trim() === control.value?.toLowerCase()?.trim());
        
        return isDuplicatePropertyDraft ? { existed: "Label already exists." } : null;
    };
  }

  onChangeData(): void {
    this.isChanged = true;
  }

  onSoftDeleteSectionConfig(row: Header & Row): void {
    // Soft delete corresponding item at the dataless table
    this.isChanged = true;
  }

  loadDataHeaderDetailAction() {
    this.memberStore.dispatch(getHeaderDetail({ id: this.headerId }));
  }

  loadDataHeaderDetail() {
    this.memberStore
      .pipe(
        select(selectHeaderDetailState),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        if (state?.success && state?.payload?.data && !state.isLoading) {
         
          this.headerDetailData = state.payload?.data as Header;
          this.listBreadcrumbs[2].name = this.headerDetailData.headerName ?? "";
          this.editForm?.get('name')?.setValue(this.headerDetailData?.headerName ?? "");

          this.leftCard = (this.headerDetailData?.properties ?? [])
            .filter((config: Property) => config)
            .sort((a: Property, b: Property) =>
              a.order > b.order ? 1 : a.order < b.order ? -1 : 0
            )
            .map(
              (config: Property) => {
                const cloneConfig = deepClone(config);
                const crrPropertyType = cloneConfig?.entityReference ? propertyType[2].displayValue : cloneConfig.propertyType;
                if (cloneConfig.entityReferencePropertyId) {
                  cloneConfig.propertyId = `${cloneConfig.propertyId}|${cloneConfig.entityReferencePropertyId}`;
                }
                return ({
                  ...cloneConfig,
                  name: cloneConfig.label,
                  ppName: cloneConfig.propertyName,
                  ...(cloneConfig?.entityReference ? { propertyType: crrPropertyType } : {}),
                  propertyName:  `${crrPropertyType} ${cloneConfig.entityReference ? `/ ${cloneConfig?.entityReference}` : ''}/ ${cloneConfig.propertyName}${config.option ? ` / ${cloneConfig.option}` : ''}`,
                  form: this.createInlineEditFormControls(config),
                } as Header & Row)
              }
            );

          // Load init property type
          this.addPropertyForm.patchValue({
            propertyType: propertyType[0].value
          });
          this.changePropertyType();
          this.isLoading = state.isLoading;
        }
      });
  }

  onCancel(): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: CANCEL_CONFIRM_MESSAGE,
        type: ConfirmType.Cancel,
        title: 'Cancel Action',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.editForm?.reset();
        this.addPropertyForm.reset();
        this.loadDataHeaderDetailAction();
        this.isChanged = false;
        this.propertyOptions = [];
      }
    });
  }

  onSubmit(): void {
    if (this.headerDetailData?.id) {
      this.headerService.getHeaderExist(this.headerDetailData.id, this.nameCtrl.value?.trim()).subscribe((res) => {
        if (!res?.exists) {

          const leftSections = (this.getProfileOverviewConfigs(
            this.gridLeftCard!?.dataSource
          ) ?? []).filter(item => !(!item?.id && item.isRemoved));
  
          const metadataProfileOverview: UpdateHeaderDetailRequest = {
            id: this.headerDetailData.id,
            name: this.nameCtrl.value.trim(),
            itemDtos: leftSections
          };
  
          this.memberStore.dispatch(updateHeader({
            body: metadataProfileOverview
          }))
        } else {
          this.nameCtrl.setErrors({ inValidAsync: true });
        }
      })
    }

    this.isChanged = false;
  }
  // Initial form group when edit profile overview detail
  private initFormGroup(data?: Header): void {
    this.editForm = this.fb.group({
      name: new FormControl(data?.headerName || '', [
        Validators.required
      ]),
    });

    this.nameCtrl.clearAsyncValidators();
  }

  private createInlineEditFormControls(
    config: Header & Row
  ): FormGroup {
    return this.fb.group({
      name: new FormControl(
        config?.name ?? config.label,
        getValidatorsFromColumns('name', this.configuredPropertyColumns, config)
      ),
    });
  }

  getAvailableTypeConfigs() {
    this.availableTypeConfigs = propertyType.reduce((result: Option[], typeConfig: Header & Row) => {
      result.push({
        value: typeConfig.value,
        displayValue: typeConfig?.displayValue,
      });
      return result;
    }, [] as Option[]);
  }

  getAvailablePropertyConfigs() {
    const allPropertyAdded = this.gridLeftCard!?.dataSource.map((item: Header & Row) => item?.propertyId);

    this.availablePropertyConfigs = this.propertyConfigs.reduce((result: Option[], propertyConfig: HeaderProperty) => {
      const existedOption = Object.keys(propertyConfig?.options ?? {}).every(
        key => this.gridLeftCard?.dataSource.find(item => (item.ppName === propertyConfig?.name && key === item.option))
      )
      const entityReferencePropertyName = propertyConfig?.entityReferencePropertyNames?.[0]?.name;
        
      if (allPropertyAdded?.includes(propertyConfig.id) && existedOption) {
        return result;
      } else {
        result.push({
          value: propertyConfig,
          displayValue: propertyConfig?.name,
          ...(entityReferencePropertyName ? { valueDescription: entityReferencePropertyName } : {}) 
        });
        return result;
      }
    }, [] as Option[]);
  }



  private getProfileOverviewConfigs(
    data: (Header & Row)[]
  ): (ItemDto & Row)[] {
    let order: number = 1;
    return data?.map((config: (Header & Row)) => {
      const { id, name, propertyId, propertyName, option, entityReferencePropertyNames } = config;   
      
      // Always exists
      const newConfig: ItemDto & Row = {
        id: id,
        label: name,
        propertyId: propertyId?.split(VERTICAL_LINE_SEPARATOR)[0],
        propertyName: propertyName,
        order: config.deleted ? -1 : order,
        isRemoved: config.deleted,
        ...(option ? { option: option } : {}),
        ...(entityReferencePropertyNames ? { entityReferencePropertyId: entityReferencePropertyNames[0]?.id } : {})
      };

      if (!config.deleted) order++;
      return newConfig;
    });
  }

  addProperty() {
    if (this.headerDetailData.id) {
      this.addPropertyForm.markAllAsTouched();
      
      if (!this.addPropertyForm.valid) {
        return;
      }
      
      this.isLoadingAddProperty = true;

      this.headerService.getHeaderItemExist(
        this.headerDetailData.id,
        this.addPropertyForm.get('propertyLabel')?.value?.trim()
        ).subscribe((res) => {
          const formValue = this.addPropertyForm.value;
          const isDuplicatePropertyDraft = this.gridLeftCard?.dataSource.find((item: Row) => item.name?.toLowerCase()?.trim() === formValue.propertyLabel?.toLowerCase().trim());

          if (!(res?.exists || isDuplicatePropertyDraft)) { 
            const optionValue = this.addPropertyForm.get('option')?.value;
            const { id, name, options, entityReferencePropertyNames } = formValue.propertyName;
            const propertyTypeName = propertyType.find(item => item.value === this.addPropertyForm.get('propertyType')?.value)?.displayValue;
            
            const newViewCard: Property & Row = {
              isRemoved: false,
              name: formValue.propertyLabel,
              propertyId: id,
              ppName: name,
              entityReferencePropertyNames: entityReferencePropertyNames,
              propertyName: `${propertyTypeName} ${entityReferencePropertyNames ? `/ ${entityReferencePropertyNames[0]?.name}` : ''} / ${name}${Object.keys(options)?.length ? ` / ${optionValue}` : ''}`,
              order: this.gridLeftCard!.dataSource.length,
              ...(options ? { option: optionValue } : {})
            }
            newViewCard.form = this.createInlineEditFormControls(newViewCard);
            
            // Insert new row into the left card table
            this.leftCard = [...this.gridLeftCard!.dataSource, newViewCard];
        
            options && this.addPropertyForm.patchValue({
              option: null
            });
            this.propertyOptions = [];
            this.availablePropertyConfigs = [];
            this.addPropertyForm.reset();
            this.addPropertyForm.get('propertyType')?.setValue(204);
            this.changePropertyType();
            this.isChanged = true;
          } else {
            this.addPropertyForm.get('propertyLabel')?.setErrors({ inValidAsync: true });
          }

          this.isLoadingAddProperty = false;
      });
    }
  };
}