import { createReducer, on } from '@ngrx/store';
import { ParticipantDocumentsBenefit, ParticipantDocumentsListModel } from '@ptg-member/types/models/participant-documents.model';
import {
  clearDocumentAttachStateAction,
  clearGetParticipantDocumentsBenefitsStateAction,
  documentAttachAction,
  documentAttachActionFailureAction,
  documentAttachActionSuccessAction,
  getParticipantDocuments,
  getParticipantDocumentsBenefitsAction,
  getParticipantDocumentsBenefitsFailureAction,
  getParticipantDocumentsBenefitsSuccessAction,
  getParticipantDocumentsFailure,
  getParticipantDocumentsSuccess,
  removeDocumentsParticipantFailure,
  removeDocumentsParticipantSuccess,
} from '../actions/participant-documents.actions';
import { ACTION, STATE } from '@ptg-shared/constance';
import { ActionState } from '@ptg-shared/types/models/common.model';
import { BaseActionState } from '@ptg-shared/types/models';

export const ParticipantDocumentsFeatureKey = 'participantDocuments';

export interface documentAttachState extends ActionState {
  isLoading: boolean;
}

export interface State {
  isLoading: boolean;
  lstDoc: ParticipantDocumentsListModel;
  removeState: string,
  documentAttach?: documentAttachState;
  getParticipantDocumentsBenefits?: BaseActionState<ParticipantDocumentsBenefit[]>;
}

const initialState: State = {
  isLoading: true,
  lstDoc: {
    total: 0,
    participantDocuments: [],
  },
  removeState: '',
};

export const reducer = createReducer(
  initialState,

  on(getParticipantDocuments, (state, {}) => ({
    ...state,
    isLoading: true,
    lstDoc: {
      total: 0,
      participantDocuments: [],
    },
  })),
  on(getParticipantDocumentsSuccess, (state, { lstDoc }) => ({
    ...state,
    isLoading: false,
    lstDoc,
  })),
  on(getParticipantDocumentsFailure, (state) => ({
    ...state,
    isLoading: false,
    lstDoc: {
      total: 0,
      participantDocuments: [],
    },
  })),

  on(removeDocumentsParticipantSuccess, (state) => ({
    ...state,
    removeState: 'Success',
  })),
  on(removeDocumentsParticipantFailure, (state) => ({
    ...state,
    removeState: 'Fail',
  })),

  //document attach to profile
  on(documentAttachAction, (state) => ({
    ...state,
    documentAttach: {
      isLoading: true,
      action: ACTION.ATTACH,
      state: '',
    },
  })),
  on(documentAttachActionSuccessAction, (state) => ({
    ...state,
    documentAttach: {
      isLoading: false,
      action: ACTION.ATTACH,
      state: STATE.SUCCESS,
    },
  })),
  on(documentAttachActionFailureAction, (state, { error }) => ({
    ...state,
    documentAttach: {
      isLoading: false,
      action: ACTION.ATTACH,
      state: STATE.FAIL,
      error,
    },
  })),
  on(clearDocumentAttachStateAction, (state) => ({
    ...state,
    documentAttach: undefined,
  })),

  on(getParticipantDocumentsBenefitsAction, (state) => ({
    ...state,
    getParticipantDocumentsBenefits: {
      isLoading: true,
    },
  })),
  on(getParticipantDocumentsBenefitsSuccessAction, (state, { response }) => ({
    ...state,
    getParticipantDocumentsBenefits: {
      isLoading: false,
      success: true,
      payload: response.participantDocuments,
    },
  })),
  on(getParticipantDocumentsBenefitsFailureAction, (state, { error }) => ({
    ...state,
    getParticipantDocumentsBenefits: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetParticipantDocumentsBenefitsStateAction, (state) => ({
    ...state,
    getParticipantDocumentsBenefits: undefined,
  })),

);
