import { createReducer, on } from '@ngrx/store';

import { STATE } from '@ptg-shared/constance/value.const';
import { BaseActionState } from '@ptg-shared/types/models';

import { MemberDetailActions } from '../actions';
import {
  AddSectionState,
  MemberDetail,
  MemberDetailListWithPaging,
  MemberListAttachments,
  MemberMetadataAttachmentState,
  MemberStatus,
  MetadataOverView,
  MetadataSection,
  PayrollConfigurationInfo,
  ClientSetting,
  CheckAttachmentResponsive,
} from '../../types/models';
import { getClientSettingAction, getClientSettingFailureAction, getClientSettingSuccessAction } from '../actions/member-detail.actions';
import { EntityViewItems, GetCardsByViewResponse, ListPropertyForCard, MemberEntityAttachments, StandAlonePropertyOnlyCardData } from '@ptg-entity-management/services/models';

export const memberDetailFeatureKey = 'memberDetail';

export interface State {
  isLoading: boolean;
  isDeleting: boolean;
  wasDeleted: boolean;
  memberDetail: MemberDetail | null;
  memberDetailUnMasked: MemberDetail | null;
  tmp: any;
  addSectionState: AddSectionState;
  metadata: MetadataSection[] | null;
  getMetadataNonList?: BaseActionState<MetadataSection[]>;
  memberInfo: any;
  removeSectionState?: string;
  lockMemberState: string;
  unLockMemberState: string;
  removeMemberState: string;
  listStatus: MemberStatus[];
  setMemberEventState: string;
  memberDetailWithPagingState: MemberDetailListWithPaging;
  memberMetadataAttachmentState: MemberMetadataAttachmentState;
  metadataOverview: MetadataSection[] | null;
  profileOverviewName: string;
  isLoadingMetadata: boolean;
  isLoadingMemberInfo: boolean;
  isLoadingMetadataOverview: boolean;
  payrollConfigurationInfo?: PayrollConfigurationInfo;
  memberAttachmentsListSection: MemberListAttachments;
  profileOverviewConfig: MetadataOverView;
  retireRehireConfirmState?: {
    isRetireRehire?: boolean;
    isShowRetireRehireConfirmPopup?: boolean;
  };
  entityViewItems?: BaseActionState<EntityViewItems>;
  memberDataSummary?: BaseActionState;
  getGetCardsByView?: BaseActionState<GetCardsByViewResponse>;
  getListPropertyForCards?: BaseActionState<ListPropertyForCard[]>;
  getAllListPropertyForCards?: BaseActionState<ListPropertyForCard[]>;
  getListDataSnapshot?: BaseActionState<ListPropertyForCard[]>;
  getStandAlonePropertyOnlyCardData?: BaseActionState<StandAlonePropertyOnlyCardData[]>;
  getMemberEntityAttachments?: BaseActionState<MemberEntityAttachments[]>;
  createUploadMemberEntityAttachments?: BaseActionState;
  removeMemberEntityAttachments?: BaseActionState;
  getClientSetting?: BaseActionState<ClientSetting>;
  deactivateMemberState: BaseActionState;
  removeExitAttachment?: BaseActionState<CheckAttachmentResponsive>;

}

const initialState: State = {
  isLoading: false,
  isDeleting: false,
  wasDeleted: false,
  memberDetail: {},
  memberDetailUnMasked: {},
  tmp: {},
  addSectionState: {} as AddSectionState,
  metadata: [],
  memberInfo: {},
  removeSectionState: '',
  lockMemberState: '',
  removeMemberState: '',
  unLockMemberState: '',
  listStatus: [],
  setMemberEventState: '',
  memberDetailWithPagingState: {},
  memberMetadataAttachmentState: {} as MemberMetadataAttachmentState,
  metadataOverview: [],
  profileOverviewName: '',
  isLoadingMetadata: true,
  isLoadingMemberInfo: true,
  isLoadingMetadataOverview: false,
  memberAttachmentsListSection: {} as MemberListAttachments,
  profileOverviewConfig: {} as MetadataOverView,
  deactivateMemberState: {isLoading: true}
};

export const reducer = createReducer(
  initialState,
  on(MemberDetailActions.getMemberDetail, (state, { id }) => ({
    ...state,
    isLoading: true,
    memberDetail: null,
  })),
  on(MemberDetailActions.getMemberAttachmentsListSection, (state) => ({
    ...state,
    isLoading: true,
    memberAttachmentsListSection: {
      total: 0,
      items: [],
      isLoading: true
    },
  })),
  on(
    MemberDetailActions.getMemberAttachmentsListSectionSuccess,
    (state, { memberAttachmentsListSection }) => ({
      ...state,
      isLoading: false,
      memberAttachmentsListSection: {
        total: memberAttachmentsListSection?.total,
        items: memberAttachmentsListSection?.items,
        success: true,
        isLoading: false
      },
    })
  ),
  on(MemberDetailActions.getMemberAttachmentsListSectionFailure, (state) => ({
    ...state,
    isLoading: false,
    memberAttachmentsListSection: {
      total: 0,
      items: [],
      success: false,
      isLoading: false
    },
  })),
  on(MemberDetailActions.getMemberDetailSuccess, (state, { memberDetail }) => {
    const temp = { ...memberDetail };
    Object.entries(temp).forEach(([key, value]) => {
      if (
        !value ||
        Object.keys(value).length === 0 ||
        (value.table && !value.table.length)
      ) {
        temp[key as keyof MemberDetail] = null;
      }
    });
    return {
      ...state,
      isLoading: false,
      memberDetail: temp,
    };
  }),
  on(MemberDetailActions.getMemberDetailFailure, (state, { errorMsg }) => ({
    ...state,
    isLoading: false,
    memberDetail: null,
  })),
  on(MemberDetailActions.getMemberDataSummarySuccess, (state, { memberDataSummary }) => {
    return {
      ...state,
      memberDataSummary: {
        isLoading: false,
        payload: memberDataSummary
      },
    };
  }),
  on(MemberDetailActions.getMemberDataSummaryFailure, (state, { errorMsg }) => ({
    ...state,
    memberDataSummary: {
      isLoading: false,
      payload: undefined
    },
  })),
  on(MemberDetailActions.getMemberDataCardSuccess, (state, { memberDataCard, cardId, currentPageIndex }) => {
    const temp = state.memberDataSummary?.payload ? JSON.parse(JSON.stringify(state.memberDataSummary?.payload)) : {};
    temp[cardId] = {...memberDataCard, currentPageIndex}
    return {
      ...state,
      memberDataSummary: {
        isLoading: false,
        payload: temp,
      },
    };
  }),
  on(MemberDetailActions.getMemberDataCardFailure, (state, { cardId }) => {
    const temp = state.memberDataSummary?.payload ;
    if (temp) {
      Object.entries(temp).forEach(([key, value]) => {
        if (key === cardId) {
          (temp as any)[key] = null;
        }
      });
    }
    return {
      ...state,
      memberDataSummary: {
        isLoading: false,
        payload: temp
      },
    };
  }),
  on(MemberDetailActions.getMemberById, (state, { id }) => ({
    ...state,
    isLoadingMemberInfo: true,
    memberInfo: null,
  })),
  on(MemberDetailActions.getMemberByIdSuccess, (state, { memberInfo }) => ({
    ...state,
    isLoadingMemberInfo: false,
    memberInfo,
  })),
  on(MemberDetailActions.getMemberByIdFailure, (state) => ({
    ...state,
    isLoadingMemberInfo: false,
    memberInfo: null,
  })),
  on(MemberDetailActions.getMetadata, (state) => ({
    ...state,
    isLoadingMetadata: true,
  })),
  on(
    MemberDetailActions.getMetaDataSuccess,
    (state, { metadata, listStatus }) => ({
      ...state,
      isLoadingMetadata: false,
      metadata,
      listStatus,
    })
  ),
  on(MemberDetailActions.getMetaDataFailure, (state) => ({
    ...state,
    isLoading: false,
    isLoadingMetadata: false,
  })),
  on(MemberDetailActions.clearMetadata, (state) => ({
    ...state,
    metadata: null,
  })),
  on(MemberDetailActions.getMetadataNonListAction, (state) => ({
    ...state,
    isLoadingMetadata: true,
    getMetadataNonList: {
      isLoading: true,
    },
  })),
  on(
    MemberDetailActions.getMetadataNonListSuccessAction,
    (state, { response }) => ({
      ...state,
      isLoadingMetadata: false,
      getMetadataNonList: {
        isLoading: false,
        success: true,
        payload: response.metadata || []
      }
    })
  ),
  on(
    MemberDetailActions.getMetadataNonListFailureAction,
    (state: State, { error }) => ({
      ...state,
      isLoadingMetadata: false,
      getMetadataNonList: createFailureState(error),
    })
  ),
  on(MemberDetailActions.clearMetadata, (state) => ({
    ...state,
    getMetadataNonList: undefined,
  })),
  on(MemberDetailActions.clearMemberDetail, (state) => ({
    ...initialState,
    memberDetail: null,
  })),
  on(MemberDetailActions.getMetadataNonListAction, (state) => ({
    ...state,
    getMetadataNonList: {
      isLoading: true,
    },
  })),
  on(
    MemberDetailActions.getMetadataNonListSuccessAction,
    (state, { response }) => ({
      ...state,
      getMetadataNonList: {
        isLoading: false,
        success: true,
        payload: response.metadata || [],
      },
    })
  ),
  on(
    MemberDetailActions.getMetadataNonListFailureAction,
    (state: State, { error }) => ({
      ...state,
      getMetadataNonList: createFailureState(error),
    })
  ),
  on(MemberDetailActions.clearMetadata, (state) => ({
    ...state,
    getMetadataNonList: undefined,
  })),
  on(MemberDetailActions.removeMember, (state, {}) => ({
    ...state,
    isDeleting: true,
    memberDetail: null,
  })),
  on(MemberDetailActions.removeMemberSuccess, (state, {}) => ({
    ...state,
    isDeleting: false,
    removeMemberState: STATE.SUCCESS,
    wasDeleted: true,
    memberDetail: null,
  })),
  on(MemberDetailActions.resetStateRemoveMember, (state, {}) => ({
    ...state,
    wasDeleted: false,
    lockMemberState: '',
    unLockMemberState: '',
    removeMemberState: '',
    addSectionState: {} as AddSectionState,
    memberMetadataAttachmentState: {},
  })),
  on(MemberDetailActions.removeMemberFailure, (state, {}) => ({
    ...state,
    isDeleting: false,
    removeMemberState: STATE.FAIL,
  })),

  on(MemberDetailActions.removeMemberSection, (state, { sectionKey }) => {
    let memberDetail: any = { ...state.memberDetail };
    let currentSection: any = {};
    const temp = { ...state.tmp };
    temp[`${sectionKey}`] = memberDetail[sectionKey];
    const currentSectionOverview = state.metadataOverview?.find(
      (x) => x.key === sectionKey
    );
    if (!currentSectionOverview) return state;
    if (currentSectionOverview.type) {
      currentSection = { ...memberDetail[currentSectionOverview.key] };
      const notBenefitProperties = currentSectionOverview.properties.filter(
        (x) => x.type !== 'Benefit'
      );
      notBenefitProperties.forEach((bp) => {
        delete currentSection[bp.key];
      });
    } else {
      currentSection = null;
    }

    return {
      ...state,
      memberDetail: {
        ...memberDetail,
        [currentSectionOverview.key]: currentSection,
      },
      tmp: temp,
    };
  }),
  on(
    MemberDetailActions.removeMemberSectionSuccess,
    (state, { sectionKey }) => {
      const temp = { ...state.tmp };
      temp[`${sectionKey}`] = null;
      return {
        ...state,
        removeSectionState: STATE.SUCCESS,
        memberDetail: {
          ...state.memberDetail,
        },
        tmp: temp,
      };
    }
  ),
  on(
    MemberDetailActions.removeMemberSectionFailure,
    (state, { sectionKey }) => {
      const temp = { ...state.tmp };
      temp[sectionKey] = null;
      const memberDetail = { ...state.memberDetail } as any;
      memberDetail[sectionKey] = state.tmp[sectionKey];
      return {
        ...state,
        removeSectionState: STATE.FAIL,
        memberDetail: memberDetail,
        tmp: temp,
      };
    }
  ),
  on(MemberDetailActions.removeSectionReset, (state, {}) => ({
    ...state,
    removeSectionState: '',
  })),

  on(MemberDetailActions.lockEntityMember, (state, {}) => ({
    ...state,
    isLoading: true,
  })),
  on(MemberDetailActions.lockEntityMemberSuccess, (state, {}) => ({
    ...state,
    isLoading: false,
    lockMemberState: STATE.SUCCESS,
    memberInfo: {
      ...state.memberInfo,
      isLocked: true,
    },
  })),
  on(MemberDetailActions.lockEntityMemberFailure, (state, {}) => ({
    ...state,
    isLoading: false,
    lockMemberState: STATE.FAIL,
    memberInfo: {
      ...state.memberInfo,
      isLocked: false,
    },
  })),
  on(MemberDetailActions.unLockEntityMember, (state, {}) => ({
    ...state,
    isLoading: true,
    memberInfo: {
      ...state.memberInfo,
      isLocked: false,
    },
  })),
  on(MemberDetailActions.unLockEntityMemberSuccess, (state, {}) => ({
    ...state,
    isLoading: false,
    unLockMemberState: STATE.SUCCESS,
    memberInfo: {
      ...state.memberInfo,
      isLocked: false,
    },
  })),
  on(MemberDetailActions.unLockEntityMemberFailure, (state, {}) => ({
    ...state,
    isLoading: false,
    unLockMemberState: STATE.FAIL,
    memberInfo: {
      ...state.memberInfo,
      isLocked: true,
    },
  })),

  on(MemberDetailActions.lockMember, (state, {}) => ({
    ...state,
    isLoading: true,
  })),
  on(MemberDetailActions.lockMemberSuccess, (state, {}) => ({
    ...state,
    isLoading: false,
    lockMemberState: STATE.SUCCESS,
    memberInfo: {
      ...state.memberInfo,
      isLocked: true,
    },
  })),
  on(MemberDetailActions.lockMemberFailure, (state, {}) => ({
    ...state,
    isLoading: false,
    lockMemberState: STATE.FAIL,
    memberInfo: {
      ...state.memberInfo,
      isLocked: false,
    },
  })),
  on(MemberDetailActions.unlockMember, (state, {}) => ({
    ...state,
    isLoading: true,
    memberInfo: {
      ...state.memberInfo,
      isLocked: false,
    },
  })),
  on(MemberDetailActions.unlockMemberSuccess, (state, {}) => ({
    ...state,
    isLoading: false,
    unLockMemberState: STATE.SUCCESS,
    memberInfo: {
      ...state.memberInfo,
      isLocked: false,
    },
  })),
  on(MemberDetailActions.unlockMemberFailure, (state, {}) => ({
    ...state,
    isLoading: false,
    unLockMemberState: STATE.FAIL,
    memberInfo: {
      ...state.memberInfo,
      isLocked: true,
    },
  })),
  on(
    MemberDetailActions.addSectionTableSuccess,
    (state, { sectionData, memberId }) => ({
      ...state,
      addSectionState: {
        ...state.addSectionState,
        saveSectionDataSuccess: true,
        sectionData,
        memberId,
        reloadSection: true,
        isEdit: false,
        isRemove: false,
        error: undefined,
      },
    })
  ),
  on(MemberDetailActions.addSectionTableFailure, (state, { error }) => ({
    ...state,
    addSectionState: {
      ...state.addSectionState,
      saveSectionDataSuccess: false,
      reloadSection: true,
      isEdit: false,
      isRemove: false,
      error: error,
    },
  })),
  on(MemberDetailActions.resetAddSectionState, (state) => ({
    ...state,
    addSectionState: {
      ...state.addSectionState,
      saveSectionDataSuccess: false,
      isEdit: false,
      isRemove: false,
      error: undefined,
    },
  })),
  on(MemberDetailActions.getSectionData, (state) => ({
    ...state,
    addSectionState: {
      ...state.addSectionState,
      reloadSection: false,
      error: undefined,
    },
  })),
  on(
    MemberDetailActions.getSectionDataSuccess,
    (state, { metaDataPropertyValues, sectionKey }) => ({
      ...state,
      memberDetail: {
        ...state.memberDetail,
        [sectionKey]: metaDataPropertyValues,
      },
      addSectionState: {
        ...state.addSectionState,
        metaDataPropertyValues,
        error: undefined,
      },
    })
  ),
  on(
    MemberDetailActions.getSectionDataFailure,
    (state, { error, sectionKey }) => ({
      ...state,
      memberDetail: {
        ...state.memberDetail,
        [sectionKey]: undefined,
      },
      addSectionState: {
        ...state.addSectionState,
        metaDataPropertyValues: { table: [] },
        error: error,
      },
    })
  ),
  on(MemberDetailActions.reorderSectionTableFailure, (state, { error }) => ({
    ...state,
    addSectionState: {
      ...state.addSectionState,
      reloadSection: true,
      error: error,
    },
  })),
  on(
    MemberDetailActions.addSectionDataListViewSuccess,
    (state, { sectionData }) => ({
      ...state,
      addSectionState: {
        ...state.addSectionState,
        sectionData,
        saveSectionDataSuccess: true,
        reloadSection: true,
        error: undefined,
      },
    })
  ),
  on(
    MemberDetailActions.addSectionDataListViewFailure,
    (state, { sectionData }) => ({
      ...state,
      addSectionState: {
        ...state.addSectionState,
        sectionData,
        saveSectionDataSuccess: false,
        error: undefined,
      },
    })
  ),
  on(
    MemberDetailActions.editSectionDataListViewSuccess,
    (state, { sectionData }) => ({
      ...state,
      addSectionState: {
        ...state.addSectionState,
        isEdit: true,
        saveSectionDataSuccess: true,
        reloadSection: true,
        sectionData,
        error: undefined,
      },
    })
  ),
  on(
    MemberDetailActions.editSectionDataListViewFailure,
    (state, { error, sectionData }) => ({
      ...state,
      addSectionState: {
        ...state.addSectionState,
        isEdit: true,
        saveSectionDataSuccess: false,
        sectionData,
        error: error,
      },
    })
  ),
  on(MemberDetailActions.getSectionOverview, (state) => ({
    ...state,
    addSectionState: {
      ...state.addSectionState,
      error: undefined,
    },
  })),
  on(
    MemberDetailActions.getSectionOverviewSuccess,
    (state, { sectionOverview }) => ({
      ...state,
      addSectionState: {
        ...state.addSectionState,
        sectionData: sectionOverview.sectionData,
        metaDataPropertyValues: sectionOverview.metaDataPropertyValues,
        error: undefined,
      },
    })
  ),
  on(
    MemberDetailActions.editSectionTableSuccess,
    (state, { sectionData, memberId }) => ({
      ...state,
      addSectionState: {
        ...state.addSectionState,
        sectionData,
        memberId,
        isEdit: true,
        isRemove: false,
        isUploadFile: false,
        saveSectionDataSuccess: true,
        reloadSection: true,
        error: undefined,
      },
    })
  ),
  on(MemberDetailActions.editSectionTableFailure, (state, { error }) => ({
    ...state,
    addSectionState: {
      ...state.addSectionState,
      isEdit: true,
      isRemove: false,
      isUploadFile: false,
      saveSectionDataSuccess: false,
      reloadSection: true,
      error: error,
    },
  })),
  on(
    MemberDetailActions.removeSectionTableSuccess,
    (state, { sectionData }) => ({
      ...state,
      addSectionState: {
        ...state.addSectionState,
        sectionData,
        isEdit: false,
        isRemove: true,
        isUploadFile: false,
        saveSectionDataSuccess: true,
        reloadSection: true,
        error: undefined,
      },
    })
  ),
  on(
    MemberDetailActions.removeSectionTableFailure,
    (state, { error, sectionData }) => ({
      ...state,
      addSectionState: {
        ...state.addSectionState,
        sectionData,
        isEdit: false,
        isRemove: true,
        isUploadFile: false,
        saveSectionDataSuccess: false,
        reloadSection: true,
        error: error,
      },
    })
  ),
  on(MemberDetailActions.uploadFileSectionTableSuccess, (state) => ({
    ...state,
    addSectionState: {
      ...state.addSectionState,
      isEdit: false,
      isRemove: false,
      isUploadFile: true,
      saveSectionDataSuccess: true,
      reloadSection: true,
      error: undefined,
    },
  })),
  on(MemberDetailActions.uploadFileSectionTableFailure, (state, { error }) => ({
    ...state,
    addSectionState: {
      ...state.addSectionState,
      isEdit: false,
      isRemove: false,
      isUploadFile: true,
      saveSectionDataSuccess: false,
      reloadSection: true,
      error: error,
    },
  })),
  on(MemberDetailActions.setMemberEventSuccess, (state) => ({
    ...state,
    setMemberEventState: STATE.SUCCESS,
  })),
  on(MemberDetailActions.setMemberEventFailure, (state) => ({
    ...state,
    setMemberEventState: STATE.FAIL,
  })),
  on(MemberDetailActions.clearSetMemberEventState, (state) => ({
    ...state,
    setMemberEventState: '',
  })),

  on(MemberDetailActions.getMemberDetailWithPaging, (state) => ({
    ...state,
    isLoading: true,
    memberDetailWithPagingState: {
      total: 0,
      isLoading: true
    },
  })),
  on(
    MemberDetailActions.getMemberDetailWithPagingSuccess,
    (state, { memberDetailList }) => ({
      ...state,
      isLoading: false,
      memberDetailWithPagingState: {
        total: memberDetailList?.total,
        memberMetaData: memberDetailList?.data,
        success: true,
        error: undefined,
        isLoading: false
      },
    })
  ),
  on(
    MemberDetailActions.getMemberDetailWithPagingFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      memberDetailWithPagingState: {
        total: 0,
        success: false,
        error: error,
        isLoading: false
      },
    })
  ),

  on(MemberDetailActions.createMemberUploadAttachments, (state) => ({
    ...state,
    isLoading: true,
    memberMetadataAttachmentState: {isLoading: true},
  })),
  on(MemberDetailActions.createMemberUploadAttachmentsSuccess, (state) => ({
    ...state,
    isLoading: false,
    memberMetadataAttachmentState: {
      ...state.memberMetadataAttachmentState,
      success: true,
      isCreate: true,
      isEdit: false,
      isRemove: false,
      isLoading: false
    },
  })),
  on(
    MemberDetailActions.createMemberUploadAttachmentsFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      memberMetadataAttachmentState: {
        ...state.memberMetadataAttachmentState,
        success: false,
        isCreate: true,
        isEdit: false,
        isRemove: false,
        isLoading: false
      },
    })
  ),

  on(MemberDetailActions.updateMemberUploadAttachments, (state) => ({
    ...state,
    isLoading: true,
    memberMetadataAttachmentState: {isLoading: true},
  })),
  on(MemberDetailActions.updateMemberUploadAttachmentsSuccess, (state) => ({
    ...state,
    isLoading: false,
    memberMetadataAttachmentState: {
      ...state.memberMetadataAttachmentState,
      success: true,
      isCreate: false,
      isEdit: true,
      isRemove: false,
      isLoading: false
    },
  })),
  on(
    MemberDetailActions.updateMemberUploadAttachmentsFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      memberMetadataAttachmentState: {
        ...state.memberMetadataAttachmentState,
        success: false,
        isCreate: false,
        isEdit: true,
        isRemove: false,
        isLoading: false
      },
    })
  ),

  on(MemberDetailActions.updateMemberUploadAttachments, (state) => ({
    ...state,
    isLoading: true,
    memberMetadataAttachmentState: {isLoading: true},
  })),
  on(
    MemberDetailActions.removeMemberUploadAttachmentsSuccess,
    (state, { body }) => ({
      ...state,
      isLoading: false,
      memberMetadataAttachmentState: {
        ...state.memberMetadataAttachmentState,
        success: true,
        isCreate: false,
        isEdit: false,
        isRemove: true,
        documentName: body.documentName,
        isLoading: false
      },
    })
  ),
  on(
    MemberDetailActions.removeMemberUploadAttachmentsFailure,
    (state, { errorMsg, documentName }) => ({
      ...state,
      isLoading: false,
      memberMetadataAttachmentState: {
        ...state.memberMetadataAttachmentState,
        success: false,
        isCreate: false,
        isEdit: false,
        isRemove: true,
        documentName: documentName,
        isLoading: false
      },
    })
  ),

  on(MemberDetailActions.resetMemberMetadataAttachmentState, (state, {}) => ({
    ...state,
    isLoading: false,
    memberMetadataAttachmentState: { },
  })),

  on(MemberDetailActions.getMetadataBasedOnProfileOverviewConfig, (state) => ({
    ...state,
    isLoadingMetadataOverview: true,
  })),
  on(
    MemberDetailActions.getMetadataBasedOnProfileOverviewConfigSuccess,
    (
      state,
      {
        metadataOverview,
        listStatus,
        profileOverviewName,
        profileOverviewConfig,
      }
    ) => ({
      ...state,
      isLoadingMetadataOverview: false,
      metadataOverview,
      listStatus,
      profileOverviewName,
      profileOverviewConfig,
    })
  ),
  on(
    MemberDetailActions.getMetadataBasedOnProfileOverviewConfigFailure,
    (state) => ({
      ...state,
      isLoadingMetadataOverview: false,
    })
  ),

  on(MemberDetailActions.getViewConfigsAction, (state) => ({
    ...state,
    entityViewItems: {
      isLoading: true
    }
  })),
  on(
    MemberDetailActions.getViewConfigsActionSuccess,
    (
      state,
      {
        entityViewItems,
        viewName,
        isSummaryView
      }
    ) => ({
      ...state,
      entityViewItems: {
        isLoading: false,
        payload: {
          entityViewItems,
          viewName,
          isSummaryView
        }
      }
    })
  ),
  on(
    MemberDetailActions.getViewConfigsActionFailure,
    (state) => ({
      ...state,
      entityViewItems: {
        isLoading: false
      }
    })
  ),
  on(
    MemberDetailActions.getMemberDetailUnMaskedSuccess,
    (state, { memberDetailUnMasked }) => ({
      ...state,
      memberDetailUnMasked,
    })
  ),
  on(MemberDetailActions.getMemberDetailUnMaskedFailure, (state) => ({
    ...state,
    memberDetailUnMasked: null,
  })),
  on(MemberDetailActions.getMemberConfiguredIdNameRequest, (state) => ({
    ...state,
    payrollConfigurationInfo: undefined,
  })),
  on(
    MemberDetailActions.getMemberConfiguredIdNameSuccess,
    (state, { payrollConfigurationInfo }) => ({
      ...state,
      payrollConfigurationInfo,
    })
  ),
  on(MemberDetailActions.getMemberConfiguredIdNameFailure, (state) => ({
    ...state,
    payrollConfigurationInfo: undefined,
  })),

  on(MemberDetailActions.confirmRetireRehireSuccess,(state, { isRetireRehire, isShowRetireRehireConfirmPopup }) => ({
    ...state,
    retireRehireConfirmState: {
      isRetireRehire,
      isShowRetireRehireConfirmPopup
    }
  })),
  on(MemberDetailActions.confirmRetireRehireFailure, (state) => ({
    ...state,
    retireRehireConfirmState: undefined,
  })),
  on(MemberDetailActions.getCardsByViewAction , (state) => ({
    ...state,
    getGetCardsByView: {
      isLoading: true,
    },
  })),
  on(MemberDetailActions.getCardsByViewSuccessAction, (state, { response }) => ({
    ...state,
    getGetCardsByView: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(MemberDetailActions.getCardsByViewFailureAction, (state, { error }) => ({
    ...state,
    getGetCardsByView: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(MemberDetailActions.clearGetCardsByViewStateAction, (state) => ({
    ...state,
    getGetCardsByView: undefined,
  })),

  on(MemberDetailActions.getAllListPropertyForCardsAction, (state) => ({
    ...state,
    getAllListPropertyForCards: {
      isLoading: true,
      payload: [],
      total: 0,
    },
  })),
  on(MemberDetailActions.getAllListPropertyForCardsSuccessAction, (state, { response }) => ({
    ...state,
    getAllListPropertyForCards: {
      isLoading: false,
      success: true,
      payload: response.rowData,
      total: response.total,
      isCourtOrder: response?.isCourtOrder ? true : false,
    },
  })),
  on(MemberDetailActions.getAllListPropertyForCardsFailureAction, (state, { error }) => ({
    ...state,
    getAllListPropertyForCards: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(MemberDetailActions.clearGetAllListPropertyForCardsStateAction, (state) => ({
    ...state,
    getAllListPropertyForCards: undefined,
  })),

  on(MemberDetailActions.getListPropertyForCardsAction, (state) => ({
    ...state,
    getListPropertyForCards: {
      isLoading: true,
      payload: [],
      total: 0,
    },
  })),
  on(MemberDetailActions.getListPropertyForCardsSuccessAction, (state, { response }) => ({
    ...state,
    getListPropertyForCards: {
      isLoading: false,
      success: true,
      payload: response.rowData,
      total: response.total,
      isCourtOrder: response?.isCourtOrder ? true : false,
    },
  })),
  on(MemberDetailActions.getListPropertyForCardsFailureAction, (state, { error }) => ({
    ...state,
    getListPropertyForCards: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(MemberDetailActions.clearGetListPropertyForCardsStateAction, (state) => ({
    ...state,
    getListPropertyForCards: undefined,
  })),
  on(MemberDetailActions.getListDataSnapshotSuccessAction, (state, { response }) => ({
    ...state,
    getListDataSnapshot: {
      isLoading: false,
      success: true,
      payload: response.rowData,
      total: response.total,
      isCourtOrder: response?.isCourtOrder ? true : false,
    },
  })),
  on(MemberDetailActions.getListDataSnapshotFailureAction, (state, { error }) => ({
    ...state,
    getListDataSnapshot: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(MemberDetailActions.clearListDataSnapshotStateAction, (state) => ({
    ...state,
    getListDataSnapshot: undefined,
  })),

  on(MemberDetailActions.getStandAlonePropertyOnlyCardDataAction, (state) => ({
    ...state,
    getStandAlonePropertyOnlyCardData: {
      isLoading: true,
    },
  })),
  on(MemberDetailActions.getStandAlonePropertyOnlyCardDataSuccessAction, (state, { response }) => ({
    ...state,
    getStandAlonePropertyOnlyCardData: {
      isLoading: false,
      success: true,
      payload: response.results,
    },
  })),
  on(MemberDetailActions.getStandAlonePropertyOnlyCardDataFailureAction, (state, { error }) => ({
    ...state,
    getStandAlonePropertyOnlyCardData: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(MemberDetailActions.clearGetStandAlonePropertyOnlyCardDataStateAction, (state) => ({
    ...state,
    getStandAlonePropertyOnlyCardData: undefined,
  })),

  on(MemberDetailActions.getMemberEntityAttachmentsAction, (state) => ({
    ...state,
    getMemberEntityAttachments: {
      isLoading: true,
    },
  })),
  on(MemberDetailActions.getMemberEntityAttachmentsSuccessAction, (state, { response }) => ({
    ...state,
    getMemberEntityAttachments: {
      isLoading: false,
      success: true,
      payload: response.items,
      total: response.total,
    },
  })),
  on(MemberDetailActions.getMemberEntityAttachmentsFailureAction, (state, { error }) => ({
    ...state,
    getMemberEntityAttachments: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(MemberDetailActions.clearGetMemberEntityAttachmentsStateAction, (state) => ({
    ...state,
    getMemberEntityAttachments: undefined,
  })),
  on(MemberDetailActions.createUploadMemberEntityAttachmentsAction, (state) => ({
    ...state,
    createUploadMemberEntityAttachments: {
      isLoading: true,
    },
  })),
  on(MemberDetailActions.createUploadMemberEntityAttachmentsSuccessAction, (state) => ({
    ...state,
    createUploadMemberEntityAttachments: {
      isLoading: false,
      success: true,
    },
  })),
  on(MemberDetailActions.createUploadMemberEntityAttachmentsFailureAction, (state, { error }) => ({
    ...state,
    createUploadMemberEntityAttachments: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(MemberDetailActions.clearCreateUploadMemberEntityAttachmentsStateAction, (state) => ({
    ...state,
    createUploadMemberEntityAttachments: undefined,
  })),

  on(MemberDetailActions.removeMemberEntityAttachmentsAction, (state) => ({
    ...state,
    removeMemberEntityAttachments: {
      isLoading: true,
    },
  })),
  on(MemberDetailActions.removeMemberEntityAttachmentsSuccessAction, (state) => ({
    ...state,
    removeMemberEntityAttachments: {
      isLoading: false,
      success: true,
    },
  })),
  on(MemberDetailActions.removeMemberEntityAttachmentsFailureAction, (state, { error }) => ({
    ...state,
    removeMemberEntityAttachments: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(MemberDetailActions.clearRemoveMemberEntityAttachmentsStateAction, (state) => ({
    ...state,
    removeMemberEntityAttachments: undefined,
  })),

  on(getClientSettingAction, (state) => ({
    ...state,
    getClientSetting: {
      isLoading: true,
    },
  })),
  on(getClientSettingSuccessAction, (state, { response }) => ({
    ...state,
    getClientSetting: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getClientSettingFailureAction, (state, { error }) => ({
    ...state,
    getClientSetting: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(MemberDetailActions.deactivateMember, (state, {}) => ({
    ...state,
    deactivateMemberState: {
      isLoading: true,
    }
  })),
  on(MemberDetailActions.deactivateMemberSuccess, (state, {}) => ({
    ...state,
    deactivateMemberState: {
      isLoading: false,
      success: true,
    }
  })),
  on(MemberDetailActions.deactivateMemberFailure, (state, {}) => ({
    ...state,
    deactivateMemberState: {
      isLoading: false,
      success: false,
    }
  })),
  on(MemberDetailActions.checkRemoveExitAttachmentAction, (state, {}) => ({
    ...state,
    removeExitAttachment: {
      isLoading: false,
      success: false,
    },
  })),
  on(MemberDetailActions.checkRemoveExitAttachmentSuccess, (state, { response }) => ({
    ...state,
    removeExitAttachment: {
      isLoading: false,
      success: true,
      response
    },
  })),
  on(MemberDetailActions.checkRemoveExitAttachmentFailure, (state) => ({
    ...state,
    removeExitAttachment: {
      isLoading: false,
      success: false,
    },
  })),
  on(MemberDetailActions.clearRemoveExitAttachmentFailure, (state) => ({
    ...state,
    removeExitAttachment: undefined,
  })),
);
const createFailureState = (error: any) => {
  return {
    isLoading: false,
    success: false,
    error: error,
  };
};
