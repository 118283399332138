import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import {
  CheckComputeDisabilityDataResponse,
  ComputationDisabilityDataResponse,
  GetDisabilityBenefitDetailResponse,
  GetTotalDaysUsedResponse,
  GetDisabilityCalculationDetailResponse,
  InitiateDisabilityBenefitResponse,
} from '../../services/models';
import {
  approveDisabilityBenefitAction,
  approveDisabilityBenefitFailureAction,
  approveDisabilityBenefitSuccessAction,
  clearAddDisabilityStateAction,
  clearApproveDisabilityBenefitStateAction,
  clearCompleteDisabilityBenefitStateAction,
  clearComputeDisabilityBenefitStateAction,
  clearEditDisabilityBenefitStateAction,
  clearGetDisabilityBenefitDetailStateAction,
  clearInitiateDisabilityBenefitStateAction,
  clearValidateDisabilityBenefitStateAction,
  clearValidateBeforeComputingAction,
  completeDisabilityBenefitAction,
  completeDisabilityBenefitFailureAction,
  completeDisabilityBenefitSuccessAction,
  computeDisabilityBenefitAction,
  computeDisabilityBenefitFailureAction,
  computeDisabilityBenefitSuccessAction,
  editDisabilityBenefitAction,
  editDisabilityBenefitFailureAction,
  editDisabilityBenefitSuccessAction,
  getDisabilityBenefitDetailAction,
  getDisabilityBenefitDetailFailureAction,
  getDisabilityBenefitDetailSuccessAction,
  initiateDisabilityBenefitAction,
  initiateDisabilityBenefitFailureAction,
  initiateDisabilityBenefitSuccessAction,
  validateBeforeComputingAction,
  validateBeforeComputingFailureAction,
  validateBeforeComputingSuccessAction,
  validateDisabilityBenefitAction,
  validateDisabilityBenefitFailureAction,
  validateDisabilityBenefitSuccessAction,
  getTotalDaysUsedAction,
  getTotalDaysUsedSuccessAction,
  getTotalDaysUsedFailureAction,
  clearGetTotalDaysUsedStateAction,
  removeDisabilityCalculationAction,
  removeDisabilityCalculationSuccessAction,
  removeDisabilityCalculationFailureAction,
  clearRemoveDisabilityCalculationStateAction,
  getDisabilityCalculationDetailAction,
  getDisabilityCalculationDetailSuccessAction,
  getDisabilityCalculationDetailFailureAction,
  clearDisabilityCalculationDetailStateAction,
  validateRemoveCalculationAction,
  validateRemoveCalculationSuccessAction,
  validateRemoveCalculationFailureAction,
  clearValidateRemoveCalculationAction,
  editCalculationDetailAction,
  editCalculationDetailSuccessAction,
  editCalculationDetailFailureAction,
  clearEditCalculationDetailStateAction,
  clearDisabilityCalculationDetailAllStateAction,
} from '../actions';

export interface DisabilityBenefitState {
  getDisabilityBenefitDetail?: BaseActionState<GetDisabilityBenefitDetailResponse>;
  completeDisabilityBenefit?: BaseActionState;
  approveDisabilityBenefit?: BaseActionState;
  validateDisabilityBenefit?: BaseActionState<CheckComputeDisabilityDataResponse>;
  computeDisabilityBenefit?: BaseActionState<ComputationDisabilityDataResponse>;
  initiateDisabilityBenefit?: BaseActionState<InitiateDisabilityBenefitResponse>;
  getTotalDaysUsed?: BaseActionState<GetTotalDaysUsedResponse>;
  removeDisabilityCalculation?: BaseActionState;
  getDisabilityCalculationDetail?: BaseActionState<GetDisabilityCalculationDetailResponse>;
  editCalculationDetail?: BaseActionState<InitiateDisabilityBenefitResponse>;
}

const initialState: DisabilityBenefitState = {};

export const disabilityBenefitReducer = createReducer(
  initialState,

  on(clearAddDisabilityStateAction, (state) => ({
    ...state,
    getDisabilityBenefitDetail: undefined,
    initiateDisabilityBenefit: undefined,
    validateDisabilityBenefit: undefined,
    computeDisabilityBenefit: undefined,
    completeDisabilityBenefit: undefined,
    approveDisabilityBenefit: undefined,
    editCalculationDetail: undefined,
  })),

  on(clearDisabilityCalculationDetailAllStateAction, (state) => ({
    ...state,
    getDisabilityCalculationDetail: undefined,

    validateDisabilityBenefit: undefined,
    reopenCalculationBenefit: undefined,
    computeDisabilityBenefit: undefined,
    completeDisabilityBenefit: undefined,
    approveDisabilityBenefit: undefined,

    getExceptionList: undefined,
    checkExceptionResult: undefined,

    getCalculationAuditTrailList: undefined,

    getRetirementBenefitDocuments: undefined,
    editRetirementBenefitDocumentState: undefined,
    getRetirementBenefitDetailDocuments: undefined,
    getRetirementBenefitDownloadDocument: undefined,
    removeRemoveCalculationDocumentDetail: undefined,
    checkExistCheckRetirementDocumentCanRemove: undefined,
    createRetirementBenefitDetailUploadDocument: undefined,
  })),

  on(getDisabilityBenefitDetailAction, (state) => ({
    ...state,
    getDisabilityBenefitDetail: {
      isLoading: true,
    },
  })),
  on(getDisabilityBenefitDetailSuccessAction, (state, { response }) => ({
    ...state,
    getDisabilityBenefitDetail: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getDisabilityBenefitDetailFailureAction, (state, { error }) => ({
    ...state,
    getDisabilityBenefitDetail: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetDisabilityBenefitDetailStateAction, (state) => ({
    ...state,
    getDisabilityBenefitDetail: undefined,
  })),

  // Complete Disability Benefit
  on(completeDisabilityBenefitAction, (state) => ({
    ...state,
    completeDisabilityBenefit: {
      isLoading: true,
    },
  })),
  on(completeDisabilityBenefitSuccessAction, (state) => ({
    ...state,
    completeDisabilityBenefit: {
      isLoading: false,
      success: true,
    },
  })),
  on(completeDisabilityBenefitFailureAction, (state, { error }) => ({
    ...state,
    completeDisabilityBenefit: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearCompleteDisabilityBenefitStateAction, (state) => ({
    ...state,
    completeDisabilityBenefit: undefined,
  })),
  // Approve Disability Benefit
  on(approveDisabilityBenefitAction, (state) => ({
    ...state,
    approveDisabilityBenefit: {
      isLoading: true,
    },
  })),
  on(approveDisabilityBenefitSuccessAction, (state) => ({
    ...state,
    approveDisabilityBenefit: {
      isLoading: false,
      success: true,
    },
  })),
  on(approveDisabilityBenefitFailureAction, (state, { error }) => ({
    ...state,
    approveDisabilityBenefit: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearApproveDisabilityBenefitStateAction, (state) => ({
    ...state,
    approveDisabilityBenefit: undefined,
  })),

  // validate Disability Benefit
  on(validateDisabilityBenefitAction, (state) => ({
    ...state,
    validateDisabilityBenefit: {
      isLoading: true,
    },
  })),
  on(validateDisabilityBenefitSuccessAction, (state, { response }) => ({
    ...state,
    validateDisabilityBenefit: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(validateDisabilityBenefitFailureAction, (state, { error }) => ({
    ...state,
    validateDisabilityBenefit: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearValidateDisabilityBenefitStateAction, (state) => ({
    ...state,
    validateDisabilityBenefit: undefined,
  })),

  // validate Before Computing
  on(validateBeforeComputingAction, (state) => ({
    ...state,
    validateDisabilityBenefit: {
      isLoading: true,
    },
  })),
  on(validateBeforeComputingSuccessAction, (state, { response }) => ({
    ...state,
    validateDisabilityBenefit: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(validateBeforeComputingFailureAction, (state, { error }) => ({
    ...state,
    validateDisabilityBenefit: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearValidateBeforeComputingAction, (state) => ({
    ...state,
    validateDisabilityBenefit: undefined,
  })),

  // compute Disability Benefit
  on(computeDisabilityBenefitAction, (state) => ({
    ...state,
    computeDisabilityBenefit: {
      isLoading: true,
    },
  })),
  on(computeDisabilityBenefitSuccessAction, (state, { response }) => ({
    ...state,
    computeDisabilityBenefit: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(computeDisabilityBenefitFailureAction, (state, { error }) => ({
    ...state,
    computeDisabilityBenefit: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearComputeDisabilityBenefitStateAction, (state) => ({
    ...state,
    computeDisabilityBenefit: undefined,
  })),

  // initiate Disability Benefit
  on(initiateDisabilityBenefitAction, (state) => ({
    ...state,
    initiateDisabilityBenefit: {
      isLoading: true,
    },
  })),
  on(initiateDisabilityBenefitSuccessAction, (state, { response }) => ({
    ...state,
    initiateDisabilityBenefit: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(initiateDisabilityBenefitFailureAction, (state, { error }) => ({
    ...state,
    initiateDisabilityBenefit: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearInitiateDisabilityBenefitStateAction, (state) => ({
    ...state,
    initiateDisabilityBenefit: undefined,
  })),

  // edit Disability Benefit
  on(editDisabilityBenefitAction, (state) => ({
    ...state,
    initiateDisabilityBenefit: {
      isLoading: true,
    },
  })),
  on(editDisabilityBenefitSuccessAction, (state, { response }) => ({
    ...state,
    initiateDisabilityBenefit: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(editDisabilityBenefitFailureAction, (state, { error }) => ({
    ...state,
    initiateDisabilityBenefit: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearEditDisabilityBenefitStateAction, (state) => ({
    ...state,
    initiateDisabilityBenefit: undefined,
  })),

  // get total days
  on(getTotalDaysUsedAction, (state) => ({
    ...state,
    getTotalDaysUsed: {
      isLoading: true,
    },
  })),
  on(getTotalDaysUsedSuccessAction, (state, { response }) => ({
    ...state,
    getTotalDaysUsed: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getTotalDaysUsedFailureAction, (state, { error }) => ({
    ...state,
    getTotalDaysUsed: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetTotalDaysUsedStateAction, (state) => ({
    ...state,
    getTotalDaysUsed: undefined,
  })),

  // validate remove calculation
  on(validateRemoveCalculationAction, (state) => ({
    ...state,
    validateDisabilityBenefit: {
      isLoading: true,
    },
  })),
  on(validateRemoveCalculationSuccessAction, (state, { response }) => ({
    ...state,
    validateDisabilityBenefit: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(validateRemoveCalculationFailureAction, (state, { error }) => ({
    ...state,
    validateDisabilityBenefit: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearValidateRemoveCalculationAction, (state) => ({
    ...state,
    validateDisabilityBenefit: undefined,
  })),

  //remove Disability Calculation
  on(removeDisabilityCalculationAction, (state) => ({
    ...state,
    removeDisabilityCalculation: {
      isLoading: true,
    },
  })),
  on(removeDisabilityCalculationSuccessAction, (state, { response }) => ({
    ...state,
    removeDisabilityCalculation: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),

  on(removeDisabilityCalculationFailureAction, (state, { error }) => ({
    ...state,
    removeDisabilityCalculation: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),

  on(clearRemoveDisabilityCalculationStateAction, (state) => ({
    ...state,
    removeDisabilityCalculation: undefined,
  })),

  //get Disability Calculation detail
  on(getDisabilityCalculationDetailAction, (state) => ({
    ...state,
    getDisabilityCalculationDetail: {
      isLoading: true,
    },
  })),
  on(getDisabilityCalculationDetailSuccessAction, (state, { response }) => ({
    ...state,
    getDisabilityCalculationDetail: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getDisabilityCalculationDetailFailureAction, (state, { error }) => ({
    ...state,
    getDisabilityCalculationDetail: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearDisabilityCalculationDetailStateAction, (state) => ({
    ...state,
    getDisabilityCalculationDetail: undefined,
  })),
  // edit Disability Calculation detail
  on(editCalculationDetailAction, (state) => ({
    ...state,
    editCalculationDetail: {
      isLoading: true,
    },
  })),
  on(editCalculationDetailSuccessAction, (state, { response }) => ({
    ...state,
    editCalculationDetail: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(editCalculationDetailFailureAction, (state, { error }) => ({
    ...state,
    editCalculationDetail: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearEditCalculationDetailStateAction, (state) => ({
    ...state,
    editCalculationDetail: undefined,
  })),
);
