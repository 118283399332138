import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as ProfileNavigationConfigurationAction from '../../store/actions/profile-navigation-configuration.action';
import {
  MemberNavigation,
  MemberNavigationDetailResponse,
  MemberNavigationList,
  SectionList,
} from '../../types/models';
import { ProfileNavigationConfigurationService } from '../../services/profile-navigation-configuration.service';

@Injectable()
export class ProfileNavigationConfigurationEffects {
  constructor(
    private actions$: Actions,
    private profileNavigationService: ProfileNavigationConfigurationService
  ) {}

  getMemberNavigationList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileNavigationConfigurationAction.getMemberNavigationList),
      switchMap(() => {
        return this.profileNavigationService.getMemberNavigationList().pipe(
          map((memberNavigations: MemberNavigationList) => {
            return ProfileNavigationConfigurationAction.getMemberNavigationListSuccess(
              { memberNavigations }
            );
          }),
          catchError((error) => {
            return of(
              ProfileNavigationConfigurationAction.getMemberNavigationListFailure(
                { error }
              )
            );
          })
        );
      })
    )
  );

  reorderMemberNavigationList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileNavigationConfigurationAction.reorderMemberNavigationList),
      switchMap(({ body }) => {
        return this.profileNavigationService.reorderMemberNavigation(body).pipe(
          map(() => {
            return ProfileNavigationConfigurationAction.reorderMemberNavigationListSuccess();
          }),
          catchError((err) => {
            return of(
              ProfileNavigationConfigurationAction.reorderMemberNavigationListFailure(
                { errorMsg: err.message }
              )
            );
          })
        );
      })
    )
  );

  createMemberNavigation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProfileNavigationConfigurationAction.createMemberNavigationRequest
      ),
      switchMap(({ menuName }) => {
        return this.profileNavigationService
          .createMemberNavigation(menuName)
          .pipe(
            map(() => {
              return ProfileNavigationConfigurationAction.createMemberNavigationSuccess();
            }),
            catchError((err) => {
              return of(
                ProfileNavigationConfigurationAction.createMemberNavigationFailure()
              );
            })
          );
      })
    )
  );

  removeMemberNavigation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProfileNavigationConfigurationAction.removeMemberNavigationRequest
      ),
      switchMap(({ menuId }) => {
        return this.profileNavigationService
          .removeMemberNavigation(menuId)
          .pipe(
            map(() => {
              return ProfileNavigationConfigurationAction.removeMemberNavigationSuccess();
            }),
            catchError((err) => {
              return of(
                ProfileNavigationConfigurationAction.removeMemberNavigationFailure()
              );
            })
          );
      })
    )
  );

  getMemberNavigationDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileNavigationConfigurationAction.getMemberNavigationDetail),
      switchMap(({ menuId }) => {
        return this.profileNavigationService
          .getMemberNavigationDetail(menuId)
          .pipe(
            map((res: MemberNavigationDetailResponse) => {
              const memberNavigation = {
                id: res?.id,
                name: res?.name,
                color: res?.color,
                order: 0,
                disabled: !res?.active,
                totalItem: 0,
                menuItems: res?.memberNavigationItems,
                statusList: res?.listStatus?.filter((x) =>
                  res?.status?.some(
                    (id) => x?.id?.toLowerCase() === id?.toLowerCase()
                  )
                ),
              } as MemberNavigation;
              return ProfileNavigationConfigurationAction.memberNavigationDetailSuccess(
                { memberNavigation, listAllStatus: res?.listStatus }
              );
            }),
            catchError((error) => {
              return of(
                ProfileNavigationConfigurationAction.memberNavigationDetailFailure(
                  { error }
                )
              );
            })
          );
      })
    )
  );

  reorderMemberNavigationItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileNavigationConfigurationAction.reorderMemberNavigationItem),
      switchMap(({ menuId, body }) => {
        return this.profileNavigationService
          .reorderMemberNavigationItem(menuId, body)
          .pipe(
            map(() => {
              return ProfileNavigationConfigurationAction.reorderMemberNavigationItemSuccess();
            }),
            catchError((error) => {
              return of(
                ProfileNavigationConfigurationAction.reorderMemberNavigationItemFailure(
                  { error }
                )
              );
            })
          );
      })
    )
  );

  editMemberNavigation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileNavigationConfigurationAction.editMemberNavigation),
      switchMap(({ body }) => {
        return this.profileNavigationService.editMemberNavigation(body).pipe(
          map(() => {
            return ProfileNavigationConfigurationAction.editMemberNavigationSuccess();
          }),
          catchError((error) => {
            return of(
              ProfileNavigationConfigurationAction.editMemberNavigationFailure({
                error,
              })
            );
          })
        );
      })
    )
  );

  createMemberNavigationItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileNavigationConfigurationAction.createMemberNavigationItem),
      switchMap(({ menuId, body }) => {
        return this.profileNavigationService
          .createMemberNavigationItem(menuId, body)
          .pipe(
            map(() => {
              return ProfileNavigationConfigurationAction.createMemberNavigationItemSuccess();
            }),
            catchError((error) => {
              return of(
                ProfileNavigationConfigurationAction.createMemberNavigationItemFailure(
                  { error }
                )
              );
            })
          );
      })
    )
  );

  editMemberNavigationItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileNavigationConfigurationAction.editMemberNavigationItem),
      switchMap(({ body }) => {
        return this.profileNavigationService
          .editMemberNavigationItem(body)
          .pipe(
            map(() => {
              return ProfileNavigationConfigurationAction.editMemberNavigationItemSuccess();
            }),
            catchError((error) => {
              return of(
                ProfileNavigationConfigurationAction.editMemberNavigationItemFailure(
                  { error }
                )
              );
            })
          );
      })
    )
  );

  removeMemberNavigationItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileNavigationConfigurationAction.removeMemberNavigationItem),
      switchMap(({ body }) => {
        return this.profileNavigationService
          .removeMemberNavigationItem(body)
          .pipe(
            map(() => {
              return ProfileNavigationConfigurationAction.removeMemberNavigationItemSuccess();
            }),
            catchError((error) => {
              return of(
                ProfileNavigationConfigurationAction.removeMemberNavigationItemFailure(
                  { error }
                )
              );
            })
          );
      })
    )
  );

  getSectionList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileNavigationConfigurationAction.getSectionList),
      switchMap(() => {
        return this.profileNavigationService.getDropDownSection().pipe(
          map((res: SectionList) => {
            return ProfileNavigationConfigurationAction.getSectionListSuccess({
              listSection: res?.sections,
            });
          }),
          catchError((err) => {
            return of(
              ProfileNavigationConfigurationAction.getSectionListFailure()
            );
          })
        );
      })
    )
  );
}
