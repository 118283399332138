import { createSelector } from '@ngrx/store';
import { selectCalculationModuleState } from './selector';

export const exceptionConfigurationSelector = createSelector(
  selectCalculationModuleState,
  (state) => state?.exceptionConfiguration,
);

export const getExceptionConfigurationsSelector = createSelector(
  exceptionConfigurationSelector,
  (state) => state?.getExceptionConfiguration,
);

export const getExceptionConfigurationDetailSelector = createSelector(
  exceptionConfigurationSelector,
  (state) => state?.getExceptionConfigurationDetail,
);

export const setExceptionConfigurationDetailSelector = createSelector(
  exceptionConfigurationSelector,
  (state) => state?.getSetExceptionConfigurationDetailResponse,
);

export const getValidationExceptionConfigurationSelector = createSelector(
  exceptionConfigurationSelector,
  (state) => state?.getValidationExceptionConfigurationResponse,
);
