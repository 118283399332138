import { Action, combineReducers } from '@ngrx/store';
import {
  stepConfigurationReducer,
  StepConfigurationState,
} from './step-configuration.reducer';
import {
  calculationListReducer,
  CalculationListState,
} from './calculation-list.reducer';
import { inputOutputReducer, InputOutputState } from './input-output.reducer';
import {
  calculationParameterConfigurationReducer,
  CalculationParameterConfigurationState,
} from './calculation-parameter-configuration.reducer';
import {
  retirementBenefitReducer,
  RetirementBenefitState,
} from './retirement-benefit-history.reducer';
import {
  benefitCalculationConfigurationReducer,
  BenefitCalculationConfigurationState,
} from './benefit-calculation-configuration.reducer';
import {
  RetirementBenefitDialogState,
  retirementBenefitDialogReducer,
} from './retirement-benefit-dialog.reducer';
import {
  RetirementBenefitUploadDocumentState,
  retirementBenefitUploadDocumentReducer,
} from './retirement-benefit-upload-document.reducer';
import {
  ExceptionConfigurationState,
  exceptionConfigurationReducer,
} from './exception-configuration.reducer';
import {
  RetirementBenefitDetailDocumentState,
  retirementBenefitDetailDocumentReducer,
} from './retirement-benefit-detail-document.reducer';
import {
  RetirementBenefitDetailUploadDocumentState,
  retirementBenefitDetailUploadDocumentReducer,
} from './retirement-benefit-detail-upload-document.reducer';
import {
  RemoveCalculationDocumentDetailState,
  removeCalculationDocumentDetailReducer,
} from './remove-calculation-document-detail.reducer';
import {
  CheckRetirementDocumentCanRemoveState,
  checkRetirementDocumentCanRemoveReducer,
} from './check-retirement-document-can-remove.reducer';
import {
  RemoveOverviewDocumentState,
  removeOverviewDocumentReducer,
} from './remove-overview-document.reducer';
import {
  GetCalculationBenefitDetailsState,
  getCalculationBenefitDetailsReducer,
} from './calculation-benefit-details.reducer';
import {
  BeneficiaryConfirmationState,
  beneficiaryConfirmationReducer,
} from './beneficiary-confirmation.reducer';
import {
  DisabilityBenefitState,
  disabilityBenefitReducer,
} from './disability-benefit.reducer';
import {
  memberEntityReducer,
  MemberEntityState,
} from '@ptg-member/features/calculation/store/reducers/member-entity.reducer';
import { GetEntityComponentListDataByComponentIdState, getEntityComponentListDataByComponentIdReducer } from './get-entity-component-list-data-by-component-id.reducer';
import { BenefitRefundState, benefitRefundReducer } from './benefit-refund.reducer';
import { SubHeaderConfigurationState, subHeaderConfigurationReducer } from './subheader-configuration.reducer';
import { CalculationLoddBenefitState, calculationLoddBenefitReducer } from './calculation-lodd-benefit.reducer';
import { CalculationQDROState, calculationQDROReducer } from './calculation-qdro.reducer';

export interface CalculationState {
  stepConfiguration: StepConfigurationState;
  calculationList: CalculationListState;
  inputOutput: InputOutputState;
  calculationParameterConfiguration: CalculationParameterConfigurationState;
  benefitCalculationConfiguration: BenefitCalculationConfigurationState;
  retirementBenefit: RetirementBenefitState;
  retirementBenefitDialog: RetirementBenefitDialogState;
  exceptionConfiguration: ExceptionConfigurationState;
  retirementBenefitDetailDocument: RetirementBenefitDetailDocumentState;
  retirementBenefitDetailUploadDocument: RetirementBenefitDetailUploadDocumentState;
  removeRemoveCalculationDocumentDetail: RemoveCalculationDocumentDetailState;
  checkRetirementDocumentCanRemove: CheckRetirementDocumentCanRemoveState;
  removeOverviewDocument: RemoveOverviewDocumentState;
  retirementBenefitUploadDocument: RetirementBenefitUploadDocumentState;
  getCalculationBenefitDetails: GetCalculationBenefitDetailsState;
  beneficiaryConfirmation: BeneficiaryConfirmationState;
  disabilityBenefit: DisabilityBenefitState;
  memberEntity: MemberEntityState;
  getEntityComponentListDataByComponentId: GetEntityComponentListDataByComponentIdState;
  benefitRefund: BenefitRefundState,
  subHeaderConfiguration: SubHeaderConfigurationState,
  calculationLoddBenefit: CalculationLoddBenefitState,
  calculationQDRO: CalculationQDROState
}

export const calculationReducers = (
  state: CalculationState | undefined,
  action: Action,
) =>
  combineReducers({
    stepConfiguration: stepConfigurationReducer,
    calculationList: calculationListReducer,
    inputOutput: inputOutputReducer,
    calculationParameterConfiguration: calculationParameterConfigurationReducer,
    benefitCalculationConfiguration: benefitCalculationConfigurationReducer,
    retirementBenefit: retirementBenefitReducer,
    retirementBenefitDialog: retirementBenefitDialogReducer,
    retirementBenefitUploadDocument: retirementBenefitUploadDocumentReducer,
    exceptionConfiguration: exceptionConfigurationReducer,
    retirementBenefitDetailDocument: retirementBenefitDetailDocumentReducer,
    retirementBenefitDetailUploadDocument:
      retirementBenefitDetailUploadDocumentReducer,
    removeRemoveCalculationDocumentDetail:
      removeCalculationDocumentDetailReducer,
    checkRetirementDocumentCanRemove: checkRetirementDocumentCanRemoveReducer,
    removeOverviewDocument: removeOverviewDocumentReducer,
    getCalculationBenefitDetails: getCalculationBenefitDetailsReducer,
    beneficiaryConfirmation: beneficiaryConfirmationReducer,
    disabilityBenefit: disabilityBenefitReducer,
    memberEntity: memberEntityReducer,
    getEntityComponentListDataByComponentId: getEntityComponentListDataByComponentIdReducer,
    benefitRefund: benefitRefundReducer,
    subHeaderConfiguration: subHeaderConfigurationReducer,
    calculationLoddBenefit: calculationLoddBenefitReducer,
    calculationQDRO: calculationQDROReducer,
  })(state, action);

export * from './step-configuration.reducer';
export * from './benefit-calculation-configuration.reducer';
export * from './retirement-benefit-upload-document.reducer';
export * from './exception-configuration.reducer';
export * from './disability-benefit.reducer';
export * from './member-entity.reducer';
export * from './benefit-refund.reducer';
export * from './subheader-configuration.reducer';
