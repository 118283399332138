import { Component, Inject, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup, FormArray, FormBuilder } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { showBanner } from '@ptg-shared/utils/common.util';

import { PaymentInfoType } from '../../constance/next-payment.const';
import { OffCyclePayment } from '../../types/models';
import { PaymentEditBankAccountPopupComponent } from '../payment-edit-bank-account/payment-edit-bank-account-popup.component';

@Component({
  selector: 'ptg-payment-edit',
  templateUrl: './payment-edit-popup.component.html',
  styleUrls: ['./payment-edit-popup.component.scss'],
  providers: [DatePipe],
})
export class PaymentEditPopupComponent implements OnInit {
  readonly PaymentInfoTypeEnum = PaymentInfoType;

  formGroup: FormGroup;
  formPayment = new FormArray([]);
  allowEdits = new Array<boolean>();
  listType = ['Percent', 'Amount'];
  listPaymentType = [
    {
      value: 0,
      displayValue: 'Check',
    },
    {
      value: 1,
      displayValue: 'Direct Deposit',
    },
  ];

  isPercent = true;
  offCyclePayment: OffCyclePayment;

  constructor(
    public dialogRef: MatDialogRef<PaymentEditPopupComponent>,
    public dialogAccountRef: MatDialogRef<PaymentEditBankAccountPopupComponent>,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      netPayment: any;
      payments: any;
      offCyclePayment: OffCyclePayment;
      benefitId: string;
    }
  ) {
    if (this.data.payments?.length > 0) {
      this.data.payments.map((payment: any) => {
        this.formPayment.push(
          this.fb.group({
            id: payment.id,
            paymentPercent: new FormControl(payment.percentage),
            paymentAmount: new FormControl(payment.amount),
            paymentType: new FormControl(payment.paymentType),
            accountNumber: payment?.paymentContent?.account,
            accountType: payment?.paymentContent?.accountType,
            bankName: payment?.paymentContent?.bankName,
            bankId: payment?.paymentContent?.bankId,
            routing: payment?.paymentContent?.routing,
            bankInfo:
              payment.paymentType == 1 && payment.paymentContent != null
                ? new FormControl(
                    this.formatBankNumber(payment.paymentContent.account) +
                      ' - ' +
                      payment.paymentContent.bankName +
                      ' - ' +
                      (payment.paymentContent.accountType == 0
                        ? 'Checking'
                        : 'Savings')
                  )
                : '',
          })
        );

        this.isPercent = payment.splitPaymentType == 0;
        this.allowEdits.push(payment.paymentType);
      });
    } else {
      this.formPayment.push(
        this.fb.group({
          paymentPercent: new FormControl(),
          paymentAmount: new FormControl(),
          paymentType: new FormControl(),
          accountNumber: '',
          accountType: '',
          bankId: '',
          bankName: '',
          routing: '',
          bankInfo: '',
        })
      );

      this.allowEdits.push(false);
    }

    this.formGroup = new FormGroup({
      splitPaymentType: new FormControl(this.isPercent ? 'Percent' : 'Amount'),
      payments: this.formPayment,
    });

    this.offCyclePayment = data.offCyclePayment;
  }

  ngOnInit(): void {}

  onSubmit() {
    if (this.data.netPayment > 0) {
      this.checkTotal();
    }

    if (this.formPayment.valid) {
      let payments = [];
      let data = this.formGroup.getRawValue();
      for (let i = 0; i < data.payments.length; i++) {
        let item = data.payments[i];
        payments.push({
          id: item.id,
          paymentContent:
            item.paymentType == '1'
              ? {
                  bankId: item.bankId,
                  routing: item.routing,
                  bankName: item.bankName,
                  account: item.accountNumber,
                  accountType: item.accountType,
                }
              : null,
          order: i,
          value:
            data.splitPaymentType == 'Percent'
              ? Number(item.paymentPercent)
              : Number(item.paymentAmount),
          paymentType: item.paymentType,
        });
      }

      let body = {
        splitPaymentType: data.splitPaymentType == 'Percent' ? 0 : 1,
        payments: payments,
        benefitId: this.data.benefitId,
      };
      let arrPayment = [];
      for (let i = 0; i < body.payments.length; i++) {
        const ele = body.payments[i];
        if (ele.paymentContent && !ele.paymentContent?.routing) {
          arrPayment.push(`Payment ${i + 1}`);
        }
      }
      if (arrPayment.length === 0) {
        this.dialogRef.close(body);
      } else {
        const options = {
          customMessage: `${arrPayment.join(
            ', '
          )}: Bank information is missing.`,
        };
        showBanner.call(this, BannerType.Fail, '', '', options);
      }
    }
  }

  onChangeSlipPaymentType(event: any) {
    if (event == 'Percent') {
      this.isPercent = true;
    } else {
      this.isPercent = false;
    }

    this.convertData();
  }

  onChangePaymentType(index: any) {
    let paymentType =
      this.formPayment.controls[index].get('paymentType')?.value;
    if (paymentType == 1) {
      this.allowEdits[index] = true;
    } else {
      this.allowEdits[index] = false;
      this.formPayment.controls[index].get('bankInfo')?.setValue(null);
      this.formPayment.controls[index].get('routing')?.setValue(null);
      this.formPayment.controls[index].get('bankId')?.setValue(null);
      this.formPayment.controls[index].get('bankName')?.setValue(null);
      this.formPayment.controls[index].get('accountNumber')?.setValue(null);
      this.formPayment.controls[index].get('accountType')?.setValue(0);
    }
  }

  addPayment() {
    this.formPayment.push(
      this.fb.group({
        paymentPercent: new FormControl(),
        paymentAmount: new FormControl(),
        paymentType: new FormControl(),
        accountNumber: '',
        accountType: '',
        bankId: '',
        bankName: '',
        routing: '',
        bankInfo: '',
      })
    );

    this.allowEdits.push(false);
  }

  onRemove(index: number) {
    this.formPayment.removeAt(index);
    this.allowEdits.splice(index, 1);
  }

  onEdit(index: number) {
    let item = this.formPayment.controls[index];
    let banks: { routing: any; accountNumber: any }[] = [];
    this.formPayment.controls.forEach((element, i) => {
      if (i != index) {
        banks.push({
          routing: element.get('routing')?.value,
          accountNumber: element.get('accountNumber')?.value,
        });
      }
    });

    let bankInfo = {
      index: index,
      routing: item.get('routing')?.value,
      bankId: item.get('bankId')?.value,
      bankName: item.get('bankName')?.value,
      accountNumber: item.get('accountNumber')?.value,
      accountType: item.get('accountType')?.value,
    };
    const dialogRef = this.dialog.open(PaymentEditBankAccountPopupComponent, {
      panelClass: 'bank-account-popup',
      autoFocus: false,
      disableClose: true,
      data: {
        bank: bankInfo,
        banks: banks,
        parentId: this.dialogRef.id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.formPayment.controls[index]
          .get('routing')
          ?.setValue(result.routing);
        this.formPayment.controls[index]
          .get('accountNumber')
          ?.setValue(result.accountNumber);
        this.formPayment.controls[index].get('bankId')?.setValue(result.bankId);
        this.formPayment.controls[index]
          .get('bankName')
          ?.setValue(result.bankName);
        this.formPayment.controls[index]
          .get('accountType')
          ?.setValue(result.accountType == 'Checking' ? 0 : 1);
        this.formPayment.controls[index]
          .get('bankInfo')
          ?.setValue(
            this.formatBankNumber(result.accountNumber) +
              ' - ' +
              result.bankName +
              ' - ' +
              result.accountType
          );
      }
    });
  }

  onCancel(): void {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }

  onFocusField() {
    if (this.isPercent) {
      for (let i = 0; i < this.formPayment.length; i++) {
        if (
          this.formPayment.controls[i].get('paymentPercent')?.errors
            ?.inValidAsync
        ) {
          this.formPayment.controls[i].get('paymentPercent')?.setErrors(null);
        }
      }
    } else {
      for (let i = 0; i < this.formPayment.length; i++) {
        if (
          this.formPayment.controls[i].get('paymentAmount')?.errors
            ?.inValidAsync
        ) {
          this.formPayment.controls[i].get('paymentAmount')?.setErrors(null);
        }
      }
    }
  }

  convertData() {
    let sumPercent = 0;
    let sumAmount = 0;
    this.formPayment.controls.forEach((element) => {
      if (this.isPercent) {
        if (this.data.netPayment > 0) {
          let amount = element.get('paymentAmount')?.value;
          let percent =
            (amount || amount == 0) && this.data.netPayment
              ? ((amount / this.data.netPayment) * 100).toFixed(2)
              : null;
          element.get('paymentPercent')?.setValue(percent);
          element.get('paymentAmount')?.setErrors(null);
          sumAmount += Number(amount);
        }
      } else {
        if (this.data.netPayment > -1) {
          let percent = element.get('paymentPercent')?.value;
          let amount =
            percent || percent == 0
              ? ((percent * this.data.netPayment) / 100).toFixed(2)
              : null;
          element.get('paymentAmount')?.setValue(amount);
          element.get('paymentPercent')?.setErrors(null);
          sumPercent += Number(percent);
        } else {
          element.get('paymentAmount')?.setValue(0);
          element.get('paymentPercent')?.setErrors(null);
        }
      }
    });
    let sumPercentExcludeLast = 0;
    let sumAmountExcludeLast = 0;
    let lastIndex = this.formPayment.controls.length - 1;
    if (this.isPercent && Number(sumAmount) === Number(this.data.netPayment)) {
      for (
        let index = 0;
        index < this.formPayment.controls.length - 1;
        index++
      ) {
        sumPercentExcludeLast += Number(
          this.formPayment.controls[index].get('paymentPercent')?.value
        );
      }
      this.formPayment.controls[lastIndex]
        .get('paymentPercent')
        ?.setValue(100 - sumPercentExcludeLast);
    }
    if (!this.isPercent && Number(sumPercent) === 100) {
      for (
        let index = 0;
        index < this.formPayment.controls.length - 1;
        index++
      ) {
        sumAmountExcludeLast += Number(
          this.formPayment.controls[index].get('paymentAmount')?.value
        );
      }
      this.formPayment.controls[lastIndex]
        .get('paymentAmount')
        ?.setValue((this.data.netPayment - sumAmountExcludeLast).toFixed(2));
    }
  }

  formatBankNumber(accountNumber: string) {
    if (accountNumber && accountNumber.length > 4) {
      let maskedNumbers = accountNumber.slice(0, -4);
      let visibleNumbers = accountNumber.slice(-4);
      return maskedNumbers.replace(/./g, 'X') + visibleNumbers;
    } else {
      return accountNumber;
    }
  }

  controlSplitButton() {
    let i = this.formPayment.length - 1;
    let dataCheck = this.formPayment.getRawValue()[i];
    let res = true;
    if (
      (this.isPercent && dataCheck?.paymentPercent === null) ||
      (!this.isPercent && dataCheck?.paymentAmount === null) ||
      dataCheck?.paymentType === null
    ) {
      res = false;
    }
    return res;
  }

  checkTotal() {
    let data = this.formGroup.getRawValue();
    let totalPercent = 0;
    let totalAmount = 0;
    data.payments.forEach((ele: any) => {
      totalPercent = totalPercent + +ele.paymentPercent;
      totalAmount = totalAmount + +ele.paymentAmount;
    });
    let idx = this.formPayment.length - 1;
    if (this.isPercent) {
      if (totalPercent.toFixed(2) !== (100).toFixed(2)) {
        if (this.formPayment.controls[idx].get('paymentPercent')?.value) {
          this.formPayment.controls[idx]
            .get('paymentPercent')
            ?.setErrors({ inValidAsync: true });
        }
        for (let i = 0; i < this.formPayment.length - 1; i++) {
          if (
            this.formPayment.controls[i].get('paymentPercent')?.errors
              ?.inValidAsync
          ) {
            this.formPayment.controls[i].get('paymentPercent')?.setErrors(null);
          }
        }
      } else {
        this.formPayment.controls.forEach((control) => {
          if (control.get('paymentPercent')?.errors?.inValidAsync) {
            control.get('paymentPercent')?.setErrors(null);
          }
          control.get('paymentAmount')?.setErrors(null);
        });
      }
      this.formPayment.controls[idx].get('paymentAmount')?.setErrors(null);
    } else {
      if (totalAmount.toFixed(2) !== this.data.netPayment.toFixed(2)) {
        if (this.formPayment.controls[idx].get('paymentAmount')?.value) {
          this.formPayment.controls[idx]
            .get('paymentAmount')
            ?.setErrors({ inValidAsync: true });
        }
        for (let i = 0; i < this.formPayment.length - 1; i++) {
          if (
            this.formPayment.controls[i].get('paymentAmount')?.errors
              ?.inValidAsync
          ) {
            this.formPayment.controls[i].get('paymentAmount')?.setErrors(null);
          }
        }
      } else {
        this.formPayment.controls.forEach((control) => {
          if (control.get('paymentAmount')?.errors?.inValidAsync) {
            control.get('paymentAmount')?.setErrors(null);
          }
          control.get('paymentPercent')?.setErrors(null);
        });
      }
      this.formPayment.controls[idx].get('paymentPercent')?.setErrors(null);
    }
  }
}
