import { Action, combineReducers } from '@ngrx/store';
import { BuyBackMakeUpState, buyBackMakeUpReducer } from './buyback-makeup.reducer';

export interface PurchaseServiceState {
  buyBackMakeUp: BuyBackMakeUpState;
}

export const purchaseServiceReducer = (state: PurchaseServiceState | undefined, action: Action) =>
  combineReducers({
    buyBackMakeUp: buyBackMakeUpReducer,
  })(state, action);

export * from './buyback-makeup.reducer';
