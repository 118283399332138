import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as FundListActions from '../actions/fund-list.actions';
import { AddFundResponse } from '../models/add-fund.model';
import { FundListQuery, GetFundListResponse, GetNotificationAlertResponse } from '../models/fund-list.model';
import { AddFundService } from '../services/add-fund.service';
import { FundListService } from '../services/fund-list.service';

@Injectable()
export class FundListEffects {
  getFundList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FundListActions.getFundList),
      switchMap(({ query }) => {
        return this.fundListService.clientList(query as FundListQuery).pipe(
          map((response: GetFundListResponse) => {
            return FundListActions.getFundListSuccess({ response });
          }),
          catchError((error) => {
            return of(FundListActions.getFundListFailure({ error }));
          })
        );
      })
    )
  );

  getAllFund$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FundListActions.getAllFund),
      switchMap(() => {
        return this.fundListService.clientList().pipe(
          map((response: GetFundListResponse) => {
            return FundListActions.getAllFundSuccess({ response });
          }),
          catchError((error) => {
            return of(FundListActions.getAllFundFailure({ error }));
          })
        );
      })
    )
  );

  changeStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FundListActions.changeStatusFund),
      switchMap(({ active, id }) => {
        return this.fundListService.changeStatus(active, id).pipe(
          map((el) => {
            return FundListActions.changeStatusSuccess({ active, id });
          }),
          catchError((err) => {
            return of(FundListActions.changeStatusFailure({ active, id }));
          })
        );
      })
    )
  );

  removeFund$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FundListActions.removeFund),
      switchMap(({ id }) => {
        return this.fundListService.remove(id).pipe(
          map(() => {
            return FundListActions.removeFundSuccess();
          }),
          catchError((err) => {
            return of(
              FundListActions.removeFundFailure({ errorMsg: err.message })
            );
          })
        );
      })
    )
  );

  addFund$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FundListActions.addNewFund),
      switchMap(({ dataFund }) => {
        return this.addFundService.addNewFund(dataFund).pipe(
          map((response: AddFundResponse) => {
            return FundListActions.addNewFundSuccess({ response });
          }),
          catchError((err) => {
            return of(
              FundListActions.addNewFundFailure({ errorMsg: err.message })
            );
          })
        );
      })
    )
  );

  getTimeZones$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FundListActions.getTimeZones),
      switchMap(() => {
        return this.fundListService.getTimeZones().pipe(
          map((response) => {
            return FundListActions.getTimeZonesSuccess({
              timeZones: response.timeZones,
            });
          }),
          catchError((err) => {
            return of(
              FundListActions.getTimeZonesFailure({ errorMsg: err.message })
            );
          })
        );
      })
    )
  );

  getNotificationAlert$ = createEffect(() =>
  this.actions$.pipe(
    ofType(FundListActions.getNotificationAlert),
    switchMap(({}) => {
      return this.fundListService.getNotificationAlert()
        .pipe(
          map((response: GetNotificationAlertResponse) => {
            return FundListActions.getNotificationAlertSuccess({ response });
          }),
          catchError((error) => {
            return of(FundListActions.getNotificationAlertFailure({ error }));
          })
        );
    })
    )
  );

  updateNotificationAlertIsRead$ = createEffect(() =>
  this.actions$.pipe(
    ofType(FundListActions.updateNotificationAlertIsRead),
    switchMap(({ notificationAlertId }) => {
      return this.fundListService.updateNotificationAlertIsRead(notificationAlertId)
        .pipe(
          map((response) => {
            return FundListActions.updateNotificationAlertIsReadSuccess({ response });
          }),
          catchError((error) => {
            return of(FundListActions.updateNotificationAlertIsReadFailure({ error }));
          })
        );
    }))
  );

  createNotificationAlert$ = createEffect(() =>
  this.actions$.pipe(
    ofType(FundListActions.createNotificationAlert),
    switchMap(({ request }) => {
      return this.fundListService.createNotificationAlert(request)
        .pipe(
          map((response) => {
            return FundListActions.createNotificationAlertSuccess({ response });
          }),
          catchError((error) => {
            return of(FundListActions.createNotificationAlertFailure({ error }));
          })
        );
    }))
  );

  constructor(
    private actions$: Actions,
    private fundListService: FundListService,
    private addFundService: AddFundService
  ) {}
}
