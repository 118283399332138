<ng-container *ngIf="!cardData?.cardNoData || cardData?.cardNoData && !configDataless.isHideEmptyCard && configDataless?.propertyDisplayPreferenceType === PROPERTY_DISPLAY_PREFERENCE_TYPE.DisplayLabelOnly">
  <ng-container *ngFor="let item of propertiesConfig; index as i">
    <div
      class="row-normal"
      *ngIf="
          item?.value ||
          item?.value === 0 ||
          item?.value === false && item?.type === EntityPropertyType.Binary ||
          (!item?.value &&
          (cardData?.cardNoData && configDataless.propertyDisplayPreferenceType === PROPERTY_DISPLAY_PREFERENCE_TYPE.DisplayLabelOnly ||
          configDataless?.nonValuePropertyDisplayType === NON_VALUE_PROPERTY_DISPLAY_TYPE.DisplayLabelOnly))
      "
      [ngClass]="{
        'masked-value': item?.value && item?.config?.masked == 'true'
      }"
    >
      <span class="config-name truncate">{{ item?.propertyLabel }}:</span>
      <div class="flex justify-start w-1/2 gap-2">
        <ng-container [ngSwitch]="item.type">
          <span class="value truncate" *ngSwitchCase="EntityPropertyType.Currency">{{ (item?.value | numberLocalDecimal : "$") || "-" }}</span>
          <span *ngSwitchCase="EntityPropertyType.Date">{{ (item?.value | date : "MM/dd/yyyy") || "-" }}</span>
          <span class="value truncate" *ngSwitchCase="EntityPropertyType['Person Name']">{{ (item?.value | personName : item.options) || "-" }}</span>
          <div class="mw-50 value" *ngSwitchCase="EntityPropertyType.Address">
            <ptg-view-address
              [singleLine]="true"
              [value]="item?.value"
              [options]="item.options"
            ></ptg-view-address>
          </div>
          <span class="value truncate" *ngSwitchCase="EntityPropertyType.Decimal">{{
              (item?.value
                | numberDecimal
                  : {
                      decimal: item.config?.fractionalLengthInput
                    }) || "-"
            }}</span>
          <span class="value truncate" *ngSwitchCase="EntityPropertyType['Whole Number']">{{ (item?.value | numberDecimal) || "-" }}</span>
          <span class="value truncate" *ngSwitchCase="EntityPropertyType.Lookup">{{ (item?.value | propertyList : item.options) || "-" }}</span>
          <span class="value truncate" *ngSwitchCase="EntityPropertyType.Binary">{{
            item?.value === null || item?.value === undefined
                ? "-"
                : item?.value
                ? item.config.affirmative
                : item.config.negative
            }}</span>
          <span class="value truncate" *ngSwitchCase="EntityPropertyType.Percentage">{{
              item?.value ? (item?.value | numberDecimal : { decimal: (item.config?.fractionalLengthInput) }) + " %" : "-"
            }}</span>
          <span class="value truncate" *ngSwitchCase="EntityPropertyType.Employer">{{ (item?.value | propertyList : item.options) || "-" }}</span>
          <span class="value truncate" *ngSwitchCase="EntityPropertyType.Department">{{ (item?.value | propertyList : item.options) || "-" }}</span>
          <span class="value truncate" *ngSwitchCase="EntityPropertyType.Tier">{{ (item?.value | propertyList : item.options) || "-" }}</span>
          <ng-container *ngSwitchCase="EntityPropertyType.RichText">
            <ptg-view-rich-text
              [title]="item?.propertyLabel"
              [content]="item?.value"
            ></ptg-view-rich-text>
          </ng-container>
          <span class="value truncate" *ngSwitchCase="EntityPropertyType['Date Time']">{{ (item?.value | date : "MM/dd/yyyy hh:mm a") || "-" }}</span>
          <span class="value truncate" *ngSwitchCase="EntityPropertyType.Identifier">
            {{ 
              (item?.config?.autogenerated === 'true' && item?.value
                ? item?.config?.patternPrefix + item?.value + item?.config?.patternSuffix
                : item?.value
              ) || "-" 
            }}
          </span>
          <span class="value truncate" *ngSwitchDefault>{{ item?.value || "-" }}</span>
        </ng-container>
        <div *ngIf="item?.value && item?.config?.masked == 'true'" class="masked-icon">
          <mat-icon matSuffix (click)="onClickIcon(item)">{{ item.isMasked ? 'visibility_off' : 'visibility' }}</mat-icon>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-container
  *ngIf="cardData?.cardNoData && !configDataless.isHideEmptyCard && configDataless?.propertyDisplayPreferenceType === PROPERTY_DISPLAY_PREFERENCE_TYPE.DisplayMessage"
>
  <div class="data-not-found">No Data to Display</div>
</ng-container>
