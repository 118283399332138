<div class="beneficiary-confirmation flex flex-col">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>
  <div class="flex flex-col p-6 gap-6">
    <div class="flex justify-start gap-3">
      <ptg-button (click)="onNext()" classInput="mat-flat-button primary" buttonName="Next"></ptg-button>
      <ptg-button (click)="onCancel()" classInput="mat-flat-button tertiary" buttonName="Cancel"></ptg-button>
    </div>
    <div class="flex flex-col confirmation-container">
      <div class="flex w-full">
        <ptg-select
          class="w-full"
          placeholder="Select from existing beneficiaries"
          [isRequired]="isRequiredBeneficiary"
          [hasNoneValue]="!isRequiredBeneficiary"
          [isOptionObj]="true"
          [isSetMaxWidthSelect]="true"
          [isMultipleLineOption]="true"
          [listData]="beneficiaryOptions"
          [controlField]="selectedBeneficiary"
          (changeOptionValue)="onChangeBeneficiary()">
        </ptg-select>
        <div></div>
      </div>

      <form class="add-section-form" *ngIf="selectedBeneficiary.value">
        <div class="flex flex-col benefit-other-component-container" *ngIf="propertiesFormArray?.length">
          <div *ngFor="let property of propertiesFormArray.controls; let index = index" class="value-container">
            <ptg-dynamic-input
              [controlField]="$any(property.get('value'))"
              [formControlDetail]="formControlDetails[index]"></ptg-dynamic-input>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
