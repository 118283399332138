<ptg-dialog class="preview-pdf-file-dialog-container" [isFullScreen]="false">
  <ng-template dialogBody>
    <ngx-extended-pdf-viewer
      *ngIf="data.file"
      [src]="data.file"
      [showToolbar]="false"
      [showSidebarButton]="false"
      [showFindButton]="false"
      [showPagingButtons]="false"
      [showDrawEditor]="false"
      [showStampEditor]="false"
      [showTextEditor]="false"
      [showZoomButtons]="false"
      [showPresentationModeButton]="false"
      [showOpenFileButton]="false"
      [showPrintButton]="false"
      [showDownloadButton]="false"
      [showSecondaryToolbarButton]="false"
      [showRotateButton]="false"
      [showHandToolButton]="false"
      [showScrollingButton]="false"
      [showSpreadButton]="false"
      [showPropertiesButton]="false"
      [showBorders]="false"
      backgroundColor="#ffffff"
    ></ngx-extended-pdf-viewer>
  </ng-template>
  <ng-template dialogFooter>
    <div class="w-full flex justify-end gap-2 mt-6 mt-7">
      <ptg-button classInput="primary" (click)="onPrint()"> Print </ptg-button>
      <ptg-button classInput="tertiary" (click)="onCancel()"> Close </ptg-button>
    </div>
  </ng-template>
</ptg-dialog>
