import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppInsights } from 'applicationinsights-js';

@Injectable({ providedIn: 'root' })
export class MyMonitoringService {
  private config: Microsoft.ApplicationInsights.IConfig = {
    instrumentationKey: environment.appInsights.instrumentationKey
  };
  constructor() {
    if (AppInsights && AppInsights.downloadAndSetup && !AppInsights.config) {
      AppInsights.downloadAndSetup(this.config);
    }
  }
  logPageView() {
    AppInsights.trackPageView();
  }
  startNavigationEvent(url: string) {
    if (AppInsights.context) {
      AppInsights.context.operation.id =
        Microsoft.ApplicationInsights.UtilHelpers.newId();
      AppInsights.context.operation.name = url;
    }
    AppInsights.startTrackEvent(url);
  }
  endNavigationEvent(url: string) {
    AppInsights.stopTrackEvent(url, { type: 'PAGE LOAD TIME' });
  }
}