import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Input, Output, Component, OnChanges, EventEmitter, SimpleChanges } from '@angular/core';

import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '@ptg-shared/components';
import { CANCEL_CONFIRM_MESSAGE } from '@ptg-shared/constance';
import { Option } from '@ptg-shared/controls/select/select.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { CalculationDetailOption, MenuItem, MenuItemsList, PropertiesList, SetSubHeaderConfigurationDetailRequest } from '@ptg-member/features/calculation/services/models/subheader-configuration.model';
import { deepClone } from '@ptg-shared/utils/common.util';

@Component({
  selector: 'ptg-edit-subheader',
  templateUrl: './edit-subheader.component.html',
  styleUrls: ['./edit-subheader.component.scss'],
})
export class EditSubHeaderComponent extends BaseComponent implements OnChanges {
  @Output() handleClickSaveBtn = new EventEmitter<SetSubHeaderConfigurationDetailRequest>();
  @Output() handleClickCancelBtn = new EventEmitter<null>();

  @Input() currentSubHeaderId?: string = '';
  @Input() currentSelectedEntityId?: string = '';
  @Input() currentSelectedPropertyId?: string = '';
  @Input() currentSelectedValueId?: string = '';
  @Input() menuItemsList?: MenuItemsList[] = [];
  @Input() chipList?: MenuItem[] = [];

  @Input() description? = '';
  @Input() calculationDetailOption?: CalculationDetailOption[] = [];
  @Input() isSpouseAge? = false;

  entityList: Option[] = [];
  propertyList: Option[] = [];
  entityValueList: Option[] = [];
  menuItemOptionList: Option[] = [];

  currentSelectedEntity: CalculationDetailOption | null = null;
  currentSelectedProperty: PropertiesList | null = null;

  isFormDirty = false;

  editForm!: FormGroup;

  constructor(
    public readonly route: ActivatedRoute,

    private readonly fb: FormBuilder,
    private readonly dialog: MatDialog,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const description = changes.description?.currentValue as string;
    const calculationDetailOption = changes.calculationDetailOption?.currentValue as CalculationDetailOption[];
    const isSpouseAge = changes.isSpouseAge?.currentValue as boolean;
    const menuItemsList = changes.menuItemsList?.currentValue as MenuItemsList[];
    const chipList = changes.chipList?.currentValue as MenuItem[];;

    this.initFormGroup();

    if (description) {
      this.descriptionControl.setValue(description);
    }
    if (isSpouseAge) {
      this.isSpouseAge = isSpouseAge;
    }
    if (calculationDetailOption) {
      this.calculationDetailOption = deepClone(calculationDetailOption);
      this.prepareDataForSpouseAgeCase();
    }
    if (menuItemsList) {
      this.menuItemOptionList = menuItemsList?.map(menu => ({
        ...menu,
        value: menu?.title,
        valueDescription: menu?.subTitle,
        displayValue: menu?.title,
      }));
    }
    if (chipList) {
      this.chipList = chipList;
      if (this.chipListControl) {
        this.chipListControl.clear();

        chipList.forEach((chip: MenuItem) => {
          this.chipListControl.push(this.newChipControl(chip));

          const menuItemUsed = this.menuItemOptionList?.find(item => item?.value === chip?.menuItem?.title);
          if (menuItemUsed) {
            menuItemUsed.isHide = true;
          }

          if (chip.menuItem.title === 'All') {
            this.menuItemDropdownControl.disable();
            this.labelTextboxControl.disable();
          }
        });
      }
    }
  }

  ngOnInit(): void {
    this.listenFormDirty();
  }

  initFormGroup() {
    this.editForm = this.fb.group({
      description: this.fb.control(this.description ?? ''),

      entity: this.fb.control(this.currentSelectedEntityId ?? ''),
      property: this.fb.control(this.currentSelectedPropertyId ?? ''),
      additionalEntityValue: this.fb.control(this.currentSelectedValueId ?? ''),

      menuItem: this.fb.control(''),
      label: this.fb.control(''),
      
      chipList: this.fb.array(this.chipList ?? []),
    });
  }

  get descriptionControl(): FormControl {
    return this.editForm?.get('description') as FormControl;
  }

  get entityControl(): FormControl {
    return this.editForm?.get('entity') as FormControl;
  }

  get propertyControl(): FormControl {
    return this.editForm?.get('property') as FormControl;
  }

  get additionalEntityValueControl(): FormControl {
    return this.editForm?.get('additionalEntityValue') as FormControl;
  }

  get menuItemDropdownControl(): FormControl {
    return this.editForm?.get('menuItem') as FormControl;
  }

  get labelTextboxControl(): FormControl {
    return this.editForm?.get('label') as FormControl;
  }

  get chipListControl(): FormArray {
    return this.editForm?.get('chipList') as FormArray;
  }

  private newChipControl(value?: MenuItem): FormControl {
    return this.fb.control(value);
  }

  private resetFormData(): void {
    this.chipListControl.clear();
    this.editForm.reset();
    this.isFormDirty = false;
  }

  triggerValidateAllFormFields() {
    this.labelTextboxControl?.markAsTouched();
    this.menuItemDropdownControl?.markAsTouched();
    if (this.isSpouseAge) {
      this.entityControl?.markAsTouched();
      this.propertyControl?.markAllAsTouched();
      this.additionalEntityValueControl?.markAsTouched();
    }
  }

  onSubmit(): void {
    this.triggerValidateAllFormFields();
    if (this.isSpouseAge && (
      !this.entityControl.value || this.entityControl.invalid ||
      !this.propertyControl.value || this.propertyControl.invalid ||
      !this.additionalEntityValueControl.value || this.additionalEntityValueControl.invalid
    )) {
      return;
    }

    if (this.chipListControl?.controls?.length === 0) {
      const dialogRef = this.dialog.open(ConfirmPopupComponent, {
        panelClass: 'confirm-popup',
        data: {
          text: 'There is no menu item. Are you sure you want to save?',
          type: ConfirmType.Warning,
          title: 'Warning',
          cancelButtonTitle: 'No',
        }
      });

      this.labelTextboxControl?.reset();
      this.menuItemDropdownControl?.reset();

      dialogRef.afterClosed().subscribe((result: any) => {
        // If user choose Yes, keep saving <> If user choose No, keep configuring
        if (result) {
          this.onSave();
          return;
        }
      });
    } else {
      this.onSave();
    }
  }

  onSave(): void {
    const formValue = this.editForm.getRawValue();
    const submitData: SetSubHeaderConfigurationDetailRequest = this.manipulateSubmitData(formValue);
    this.handleClickSaveBtn.emit(submitData);
  }

  onCancel(): void {
    this.handleClickCancelBtn.emit(null);
  }

  manipulateSubmitData(formValue: any): SetSubHeaderConfigurationDetailRequest {
    return {
      id: this.currentSubHeaderId ?? '',
      description: formValue?.description,
      calculationDetail: this.isSpouseAge ? {
        memberListId: formValue?.entity,
        propertyId: formValue?.property,
        lookUpId: formValue?.additionalEntityValue
      } : null,
      menuItems: formValue?.chipList
    };
  }

  prepareDataForSpouseAgeCase() {
    if (this.isSpouseAge && this.calculationDetailOption && this.calculationDetailOption.length) {
      // Prepare List Dropdown data
      this.entityList = this.calculationDetailOption.map((entity) => ({
        ...entity,
        value: entity.memberListId,
        displayValue: entity.memberListName,
      })).sort((record1, record2) => record1?.displayValue?.localeCompare(record2?.displayValue)) ?? [];

      // If List Dropdown selected an Entity
      if (this.currentSelectedEntityId && this.entityList.length) {
        this.entityControl.setValue(this.currentSelectedEntityId);

        // Find current selected Entity
        this.currentSelectedEntity = this.calculationDetailOption.
          find(entity => entity?.memberListId === this.currentSelectedEntityId) ?? null;

        // Prepare Property Dropdown data
        this.propertyList = this.currentSelectedEntity?.propertiesList?.map(property => ({
          ...property,
          value: property.propertyId,
          displayValue: property.propertyName,
        })).sort((record1, record2) => record1?.displayValue?.localeCompare(record2?.displayValue)) ?? [];

        // If Property Dropdown selected a Property
        if (this.currentSelectedPropertyId && this.propertyList.length) {
          this.propertyControl.setValue(this.currentSelectedPropertyId);

          // Find current selected Property
          this.currentSelectedProperty = this.currentSelectedEntity?.propertiesList?.
            find(property => property.propertyId === this.currentSelectedPropertyId) ?? null;

          // Prepare Value Dropdown data
          this.entityValueList = this.currentSelectedProperty?.valueList?.map(value => ({
            ...value,
            value: value.lookUpId,
            displayValue: value.description,
          })).sort((record1, record2) => record1?.displayValue?.localeCompare(record2?.displayValue)) ?? [];


          if (this.currentSelectedValueId && this.entityValueList.length) {
            this.additionalEntityValueControl.setValue(this.currentSelectedValueId);
          }
        }
      }
    }
  }

  onChangeList(): void {
    // Reset related variables to default
    this.currentSelectedEntity = null;
    this.currentSelectedProperty = null;

    // Remove the current [Property] dropdown list
    this.propertyControl.reset();
    this.propertyList = [];

    // Remove the current [Value] dropdown list
    this.additionalEntityValueControl.reset();
    this.entityValueList = [];

    // Set current selected Entity
    if (this.calculationDetailOption) {
      this.currentSelectedEntity = 
        this.calculationDetailOption.find((entity) => entity?.memberListId === this.entityControl.value) ?? null;
    }

    // If [Entity List] is not selected
    if (!this.currentSelectedEntity) {
      return;
    }

    // Provide list data for Property Dropdown
    this.propertyList = this.currentSelectedEntity.propertiesList.map((property) => ({
      ...property,
      value: property?.propertyId,
      displayValue: property?.propertyName
    })).sort((record1, record2) => record1?.displayValue?.localeCompare(record2?.displayValue));
  }

  onChangeProperty(): void {
    // Remove the current [Value] dropdown list
    this.additionalEntityValueControl.reset();
    this.entityValueList = [];

    // Set current selected Property
    this.currentSelectedProperty = 
      this.currentSelectedEntity?.propertiesList?.find((property) => property.propertyId === this.propertyControl.value) ?? null;

    // If [Property] is not selected
    if (!this.currentSelectedProperty) {
      return;
    }

    // Provide list data for Value Dropdown
    this.entityValueList = this.currentSelectedProperty.valueList.map((valueItem) => ({
      ...valueItem,
      value: valueItem?.lookUpId,
      displayValue: valueItem?.description,
    })).sort((record1, record2) => record1?.displayValue?.localeCompare(record2?.displayValue));
  }

  onAddNewChip(): void {
    // Not allow to add, trigger error if have to user
    if (!this.menuItemDropdownControl.value || 
      this.menuItemDropdownControl.invalid || 
      !this.labelTextboxControl.value || 
      this.labelTextboxControl.invalid) {
        this.menuItemDropdownControl.markAsTouched();
        this.labelTextboxControl.markAsTouched();
      return;
    }

    const newChipList: FormControl[] = [];
    const selectedMenuItem = this.menuItemDropdownControl.value;
    const definedLabelContent = this.labelTextboxControl.value;
    const selectedMenuItemSubTitle = this.menuItemOptionList.find(item => item.value === selectedMenuItem)?.valueDescription;

    newChipList.push(this.newChipControl({
      menuItem: {
        title: selectedMenuItem,
        subTitle: selectedMenuItemSubTitle ?? '',
      },
      label: definedLabelContent,
    }));

    //  Select [All] option
    if (selectedMenuItem === 'All') {
      // Remove: all added chips
      this.chipListControl.clear();
      // Disable Menu Item Dropdown
      this.menuItemDropdownControl.disable();
      this.labelTextboxControl.disable();
    } else {
      // Select [Specific] option
      // Hide selected option, Show [ALl] option from the dropdown list
      const selectedOption = this.menuItemOptionList.find((option) => option.value === selectedMenuItem);

      if (selectedOption) {
        selectedOption.isHide = true;
      }
    }

    newChipList.forEach((item) => {
      this.chipListControl.push(item);
    });

    this.menuItemDropdownControl.reset();
    this.labelTextboxControl.reset();
    this.isFormDirty = true;
  }

  onRemoveChip(index: number): void {
    // Get should be remove Chip in Form array list
    const removedChipCtrl = this.chipListControl.at(index);

    // Get should be remove Menu Item in Dropdown list
    const removedChipOption: Option | undefined = this.menuItemOptionList.find(
      (item) => item.value === removedChipCtrl.value?.menuItem?.title,
    );
    if (removedChipOption) {
      removedChipOption.isHide = false;
      if (removedChipOption.value === 'All') {
        this.menuItemDropdownControl.enable();
        this.labelTextboxControl.enable();
        this.menuItemOptionList.forEach((item, index) => {
          this.menuItemOptionList[index].isHide = false;
        });
      }
    }

    this.chipListControl.removeAt(index);
    this.isFormDirty = true;
  }

  private listenFormDirty(): void {
    this.editForm.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.isFormDirty = this.editForm.dirty;
    });
  }
}
