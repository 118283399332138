import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import { Note } from '../models/employer-note.model';
import {
  getNotesAction,
  getNotesFailureAction,
  getNotesSuccessAction,
  clearGetNotesStateAction,
  createNoteAction,
  createNoteFailureAction,
  createNoteSuccessAction,
  clearCreateNoteStateAction,
} from '../actions/employer-note.action';

export const employerNoteFeatureKey = 'employerNote';

export interface NoteState {
  getNotes?: BaseActionState<Note[]>;
  createNote?: BaseActionState<{ isManageNote: boolean }>;
}

const initialState: NoteState = {};

export const noteReducer = createReducer(
  initialState,
  on(getNotesAction, (state) => ({
    ...state,
    getNotes: {
      isLoading: true,
    },
  })),
  on(getNotesSuccessAction, (state, { response }) => ({
    ...state,
    getNotes: {
      isLoading: false,
      success: true,
      payload: response.employerNotes,
      total: response.total,
    },
  })),
  on(getNotesFailureAction, (state, { error }) => ({
    ...state,
    getNotes: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetNotesStateAction, (state) => ({
    ...state,
    getNotes: undefined,
  })),

  on(createNoteAction, (state) => ({
    ...state,
    createNote: {
      isLoading: true,
    },
  })),
  on(createNoteSuccessAction, (state, { isManageNote }) => {
    return {
      ...state,
      createNote: {
        isLoading: false,
        success: true,
        payload: { isManageNote },
      },
    };
  }),
  on(createNoteFailureAction, (state, { error }) => ({
    ...state,
    createNote: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearCreateNoteStateAction, (state) => ({
    ...state,
    createNote: undefined,
  })),
);
