import { Injectable } from '@angular/core';
import { Option } from '@ptg-shared/controls/select/select.component';
import { FundType } from '@ptg-shared/types/enums';
import { CalculationDocumentType } from '../../types/enums';
import { CalculationStep } from '../../services/models';

@Injectable()
export class RetirementBenefitDialogComponentService {
  getCalculationDocumentTypeList(fundType: FundType): Option[] {
    if (fundType === FundType.ChicagoParks) {
      return [
        {
          value: CalculationDocumentType.CalculationWorksheet,
          displayValue: 'Calculation worksheet',
        },
      ];
    }

    return [
      {
        value: CalculationDocumentType.CertificateOfEligibility,
        displayValue: 'Certificate of Eligibility',
      },
      {
        value: CalculationDocumentType.NoticeOfRetirement,
        displayValue: 'Notice of Retirement',
      },
      {
        value: CalculationDocumentType.MarriedCertificate,
        displayValue: 'Married Certificate',
      },
      {
        value: CalculationDocumentType.InvoiceVoucher,
        displayValue: 'Invoice Voucher',
      },
      {
        value: CalculationDocumentType.CalculationWorksheet,
        displayValue: 'Calculation worksheet',
      },
    ];
  }

  getStepConfigurationData(order: 1 | 2 | 3, calculationSteps: CalculationStep[] = []): CalculationStep | undefined {
    return calculationSteps.find((item) => item.order === order);
  }
}
