import { Pipe, PipeTransform } from '@angular/core';
import {
  isEmpty,
} from '../utils/string.util';

@Pipe({
  name: 'wholeNumber',
})
export class WholeNumberPipe implements PipeTransform {
  transform(
    value: number
  ): string {
    if (isEmpty(value)) return '';

    // Set default locale to 'en' (English)
    return BigInt(value).toLocaleString('en');
  }
}
