import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FilterPropertyType } from 'src/app/member/constance/member-list.const';
import { PropertyType as PROPERTY_TYPE } from 'src/app/member/constance/metadataPropertyType.const';
import { PropertyValue } from 'src/app/shared/types/models/detail-display.model';
import { deepClone } from 'src/app/shared/utils/common.util';

@Component({
  selector: 'ptg-view-value',
  templateUrl: './view-value.component.html',
  styleUrls: ['./view-value.component.scss']
})
export class ViewValueComponent implements OnInit, OnChanges {
  @Input() data!: PropertyValue;
  @Input() isTruncate: boolean = false;
  @Input() isSeparateLineAddress: boolean = false;

  PROPERTY_TYPE = PROPERTY_TYPE;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.mapData();
  }

  mapData() {
    const PROPERTY_TYPE_CONVERSION: any = {
      [FilterPropertyType.Text]: PROPERTY_TYPE.TYPE_TEXT,
      [FilterPropertyType.Email]: PROPERTY_TYPE.TYPE_EMAIL,
      [FilterPropertyType.Phone]: PROPERTY_TYPE.TYPE_PHONE,
      [FilterPropertyType['Whole Number']]: PROPERTY_TYPE.TYPE_WHOLE_NUMBER,
      [FilterPropertyType.Currency]: PROPERTY_TYPE.TYPE_CURRENCY,
      [FilterPropertyType.Decimal]: PROPERTY_TYPE.TYPE_DECIMAL,
      [FilterPropertyType.Date]: PROPERTY_TYPE.TYPE_DATE,
      [FilterPropertyType.DateTime]: PROPERTY_TYPE.TYPE_DATE_TIME,
      [FilterPropertyType.List]: PROPERTY_TYPE.TYPE_LIST,
      [FilterPropertyType.Address]: PROPERTY_TYPE.TYPE_ADDRESS,
      [FilterPropertyType['Person Name']]: PROPERTY_TYPE.TYPE_PERSON_NAME,
      [FilterPropertyType.Employer]: PROPERTY_TYPE.TYPE_EMPLOYER,
      [FilterPropertyType.Percentage]: PROPERTY_TYPE.TYPE_PERCENTAGE,
      [FilterPropertyType.SSN]: PROPERTY_TYPE.TYPE_SSN,
      [FilterPropertyType.Status]: PROPERTY_TYPE.TYPE_STATUS,
      [FilterPropertyType.Register]: PROPERTY_TYPE.TYPE_TEXT,
      [FilterPropertyType.RichText]: PROPERTY_TYPE.TYPE_RICH_TEXT
    }
    if (this.data.type === FilterPropertyType.Register) {
      this.data.value = this.data.value ? 'Yes' : 'No';
    }
    this.data.type = PROPERTY_TYPE_CONVERSION[deepClone(this.data).type];
  }
}
