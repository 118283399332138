import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'ptg-input-area',
  templateUrl: './input-area.component.html',
  styleUrls: ['./input-area.component.scss']
})
export class InputAreaComponent implements OnInit {

  @Input() controlField!: AbstractControl | any;
  @Input() placeholder!: string;
  @Input() maxLength?: number;
  @Input() errorRequire?: string;
  @Input() errorMaxLength?: string;
  @Input() errorPattern?: string;
  @Input() minRows?: number = 5;
  @Input() maxRows?: number;
  @Input() hasLabel?: boolean = true;
  @Input() errorAsync: string = '';
  @Input() class?: string;

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  onValueChange(event?: Event) {
    this.valueChange.emit(this.controlField.value);
  }

  trimValue() {
    if (this.controlField.value) {
      this.controlField.setValue(this.controlField.value.trim());
    }
  }

}
