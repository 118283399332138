import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { Sort } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SUBMODULE_KEY } from '@ptg-shared/constance/permission.const';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ACTION_COLUMN, Align, Column } from '@ptg-shared/controls/grid';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { BaseComponent } from '@ptg-shared/components';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { showBanner } from '@ptg-shared/utils/common.util';
import { ACTION, STATE } from '@ptg-shared/constance';
import { LayoutActions } from '@ptg-shared/layout/actions';

import * as fromReducer from '@ptg-reducers';
import { AddNewCartComponent } from '@ptg-member/components/add-new-cart/add-new-cart.component';
import * as CartActions from '../../store/actions/cart.actions';
import { State } from '@ptg-member/store/reducers/cart.reducer';
import { CardItem, ParametersQuery } from '@ptg-member/types/models/card.model';
import * as fromMember from '../../store/reducers';
import { EntityViewService } from '@ptg-entity-management/services';

@Component({
  selector: 'ptg-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss'],
})
export class CardListComponent extends BaseComponent {
  unsubscribe$ = new Subject<void>();
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Participant List',
      moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
      url: '/member',
    },
    {
      name: 'Card List',
      url: '',
    },
  ];
  columns: Column[] = [
    {
      name: 'default',
      header: {
        title: '',
      },
      width: '72px',
    },
    {
      name: 'cardName',
      header: {
        title: 'Card Name',
      },
      width: '462px',
      sortable: true,
      truncate: true,
    },
    {
      name: 'entityName',
      header: {
        title: 'Entity',
      },
      width: '462px',
      sortable: true,
      truncate: true,
    },
    {
      name: 'includesList',
      header: {
        title: 'Includes List',
      },
      width: '153px',
      truncate: true,
    },
    {
      name: 'totalProperties',
      header: {
        title: 'Total Properties',
      },
      align: Align.Right,
      width: '260px',
      sortable: true,
      truncate: true,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      width: '464px',
    },
  ];
  dataTable: CardItem[] = [];
  isLoading: boolean = true;
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  sortInfo: {} | any = {};

  constructor(
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
    public dialog: MatDialog,
    private entityViewService: EntityViewService,
    public router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch(LayoutActions.hiddenSideMenu());
    this.getData();
    this.initPage();
  }

  onClickRemoveItem(data: CardItem) {
    data.isDisabled = true;
    this.entityViewService
    .getEntityViewsByCard(data.id).pipe(takeUntil(this.unsubscribe$))
    .subscribe((res: any) => {
      data.isDisabled = false;
      if (res?.entityViews.length) {
        this.dialog.open(ConfirmPopupComponent, {
          panelClass: 'confirm-popup',
          data: {
            text: `This card cannot be removed because it is being used in the following Views: ${res.entityViews
              .map((item: any) => item.viewName)
              .join(', ')}.`,
            type: ConfirmType.Warning,
            title: 'Attention',
            cancelButtonTitle: 'Close',
            hideConfirmButton: true,
          },
        });
      } else {
        const dialogRef = this.dialog.open(ConfirmPopupComponent, {
          panelClass: 'confirm-popup',
          data: {
            type: ConfirmType.Delete,
            title: 'Remove Item',
            cancelButtonTitle: 'No',
            text: `Are you sure you want to remove this card?`
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            const query = {
              id: data.id
            };
            this.memberStore.dispatch(CartActions.removeCart({ query }));
          }
        });
      }
    });
  }

  initPage() {
    this.memberStore
      .pipe(select(fromMember.selectCart), takeUntil(this.unsubscribe$))
      .subscribe((state: State) => {
        this.isLoading = state?.isLoading;
        this.dataTable = state?.cartResponse?.cards.map((item) => {
          return {
            ...item,
            isDisabled: false
          }
        });
        showBanner.call(
          this,
          state.removeCardState || '',
          'Card',
          ACTION.REMOVE
        );
        if (state.createState === STATE.FAIL) {
          showBanner.call(
            this,
            state.createState || '',
            'Card',
            ACTION.ADD
          );
        }
        if (state.removeCardState === STATE.SUCCESS) {
         this.getData();
        }
        if (state.removeCardState) {
          this.memberStore.dispatch(
            CartActions.clearCartState()
          );
        }
      });
  }

  getData() {
    let sortType = 0;
    let sortNames = [''];
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = [capitalizeFirstLetter(this.sortInfo.active)];
      sortType = this.sortInfo.direction === 'desc' ? 1 : 0;
    }
    const query: ParametersQuery = {
      sortNames: sortNames,
      sortType: sortType,
    };
    this.isLoading = true;
    this.memberStore.dispatch(CartActions.getCartRequest({ query }));
  }

  onCreateNewCart() {
    this.dialog.open(AddNewCartComponent, {
      panelClass: ['edit-popup', 'add-new-cart'],
      width: '848px',
      disableClose: true,
      autoFocus: false,
      data: this.sortInfo,
    });
  }

  onChangeSort(event: Sort) {
    this.sortInfo = event;
    this.getData();
  }

  onUpdateCart(data: CardItem) {
    this.router.navigateByUrl(`/member/card/${data?.id}`);
  }
}
