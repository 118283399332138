import { createAction, props } from '@ngrx/store';

import {
  CheckExistLoddDocumentCanRemoveRequest,
  CheckExistLoddDocumentCanRemoveResponse,
  RemoveRemoveCalculationDocumentDetailRequest,
} from '../../services/models';

export const removeRemoveCalculationDocumentDetailAction = createAction(
  '[RemoveRemoveCalculationDocumentDetail/API] Send Request',
  props<{ request: RemoveRemoveCalculationDocumentDetailRequest }>()
);

export const removeRemoveCalculationDocumentDetailSuccessAction = createAction(
  '[RemoveRemoveCalculationDocumentDetail/API] Success'
);

export const removeRemoveCalculationDocumentDetailFailureAction = createAction(
  '[RemoveRemoveCalculationDocumentDetail/API] Failure',
  props<{ error?: any }>()
);

export const clearRemoveRemoveCalculationDocumentDetailStateAction = createAction(
  '[RemoveRemoveCalculationDocumentDetail] Clear'
);

export const checkLoddDocumentCanRemoveAction = createAction(
  '[CheckLoddDocumentCanRemove/API] Send Request',
  props<{ request: CheckExistLoddDocumentCanRemoveRequest }>()
);

export const checkLoddDocumentCanRemoveSuccessAction = createAction(
  '[CheckLoddDocumentCanRemove/API] Success',
  props<{ response: CheckExistLoddDocumentCanRemoveResponse }>()
);

export const checkLoddDocumentCanRemoveFailureAction = createAction(
  '[CheckLoddDocumentCanRemove/API] Failure',
  props<{ error?: any }>()
);

export const clearCheckLoddDocumentCanRemoveStateAction = createAction(
  '[CheckLoddDocumentCanRemove] Clear'
);

export const checkLoddDocumentCanEditAction = createAction(
  '[CheckLoddDocumentCanEdit/API] Send Request',
  props<{ request: CheckExistLoddDocumentCanRemoveRequest }>()
);

export const checkLoddDocumentCanEditSuccessAction = createAction(
  '[CheckLoddDocumentCanEdit/API] Success',
  props<{ response: CheckExistLoddDocumentCanRemoveResponse }>()
);

export const checkLoddDocumentCanEditFailureAction = createAction(
  '[CheckLoddDocumentCanEdit/API] Failure',
  props<{ error?: any }>()
);

export const clearCheckLoddDocumentCanEditStateAction = createAction(
  '[CheckLoddDocumentCanEdit] Clear'
);
