import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { UploadComponent } from 'src/app/shared/controls/upload/upload.component';
import { ConfirmType } from '../../../shared/constance/confirm-type.const';
import { CANCEL_CONFIRM_MESSAGE } from '../../../shared/constance/value.const';
import { ConfirmPopupComponent } from '../../../shared/controls/confirm-popup/confirm-popup.component';
import { MemberDetailActions } from '../../store/actions';
import { MemberDetailService } from '../../services/member-detail.service';
import { MemberState } from '../../store/reducers';
import { IncludeAttachmentType } from '@ptg-entity-management/types/enums';

interface MemberAttachmentData {
  memberId: string;
  itemKey: string;
  attachment: any;
  rowId: string;
  isEntity?: boolean;
  cardId?: string;
  recordId?: string;
  attachmentType?: IncludeAttachmentType;
}

@Component({
  selector: 'ptg-member-attachment-modal',
  templateUrl: './member-attachment-modal.component.html',
  styleUrls: ['./member-attachment-modal.component.scss'],
})
export class MemberAttachmentModalComponent implements OnInit, AfterViewInit {
  formGroup!: FormGroup;
  file: any;
  acceptDocument = 'application/pdf';
  typeFileDocument = 'pdf file format';
  checkPatternDocument = new RegExp(/^[\x00-\x7F]+\.(pdf)$/, 'i');
  errDuplicated?: string;
  @ViewChild('fileDocument')
  private fileDocument!: UploadComponent;

  constructor(
    private store: Store<MemberState>,
    private memberDetailService: MemberDetailService,
    public dialogRef: MatDialogRef<MemberAttachmentModalComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: MemberAttachmentData
  ) {}

  ngOnInit(): void {
    this.onInitForm();
  }

  ngAfterViewInit(): void {
    if (!this.data?.attachment?.id) {
      this.fileDocument.controlField.setValue(
        this.data?.attachment?.attachment
      );
    }
  }

  onInitForm() {
    this.formGroup = new FormGroup({
      attachmentId: new FormControl(this.data?.attachment?.id),
      documentName: new FormControl(
        this.data?.attachment?.documentName,
        Validators.required
      ),
      description: new FormControl(this.data?.attachment?.description),
    });
  }

  onSubmit() {
    if (!this.data?.attachment?.id) {
      this.fileDocument.firstTouch = false;
      this.fileDocument.controlField.updateValueAndValidity({
        onlySelf: true,
        emitEvent: false,
      });
    }
    if (this.formGroup.pending) {
      let sub = this.formGroup.statusChanges.subscribe(() => {
        if (
          this.formGroup.valid &&
          (this.fileDocument?.fileSelected || this.data?.attachment?.id)
        ) {
          this.onSave();
        }
        sub.unsubscribe();
      });
    } else if (
      this.formGroup.valid &&
      (this.fileDocument?.fileSelected || this.data?.attachment?.id)
    ) {
      this.onSave();
    }
  }

  onSave() {
    if (this.data?.isEntity) {
      this.onSaveAttachmentEntity();
    } else {
      if (!this.data.attachment?.id && this.file) {
        const attachmentFile: any = {
          ...this.formGroup.getRawValue(),
          memberId: this.data.memberId,
          itemKey: this.data.itemKey,
          file: this.file,
          rowId: this.data.rowId,
        };

        this.memberDetailService
          .checkMetaDataAttachmentExists({
            memberId: this.data.memberId,
            cardId: this.data?.cardId,
            fileName: this.file.name,
            rowId: this.data.rowId,
          })
          .subscribe(
            (res: any) => {
              if (res && res.exists) {
                this.fileDocument.isDuplicated = true;
                this.errDuplicated = `${this.file.name} (${this.file.size} bytes) already exists. Please select another file.`;
              } else {
                this.fileDocument.isDuplicated = false;
                this.store.dispatch(
                  MemberDetailActions.createMemberUploadAttachments({
                    body: [attachmentFile],
                  })
                );
                this.dialogRef.close(true);
              }
            },
            (error) => {
              this.fileDocument.isDuplicated = false;
              this.dialogRef.close(false);
            }
          );
      } else {
        const attachmentFile = {
          ...this.formGroup.getRawValue(),
          memberId: this.data.memberId,
          itemKey: this.data.itemKey,
          rowId: this.data.rowId,
        };
        this.store.dispatch(
          MemberDetailActions.updateMemberUploadAttachments({
            body: attachmentFile,
          })
        );
        this.dialogRef.close(true);
      }
    }
  }

  onSaveAttachmentEntity() {
    if (!this.data.attachment?.id && this.file) {
      const attachmentFile: any = {
        ...this.formGroup.getRawValue(),
        memberId: this.data.memberId,
        targetId: this.data.cardId || this.data.recordId,
        file: this.file,
        attachmentType: this.data.attachmentType
      };

      this.store.dispatch(
        MemberDetailActions.createUploadMemberEntityAttachmentsAction({
          body: [attachmentFile],
        })
      );
      this.dialogRef.close({ documentName: this.file.name });
    } else {
      const attachmentFile = {
        ...this.formGroup.getRawValue(),
        memberId: this.data.memberId,
        targetId: this.data.cardId || this.data.recordId,
        attachmentType: this.data.attachmentType,
        id: this.data.attachment.id
      };
      this.store.dispatch(
        MemberDetailActions.createUploadMemberEntityAttachmentsAction({
          body: [attachmentFile],
        })
      );
      this.dialogRef.close({ documentName: this.formGroup.get('documentName')?.value });
    }
  }

  uploadFile(event: any) {
    if (this.data?.isEntity) {
      this.file = event;
      this.memberDetailService
        .checkMemberEntityAttachmentExists({
          memberId: this.data.memberId,
          targetId: this.data.cardId || this.data.recordId,
          name: event.name,
        })
        .subscribe(
          (res: any) => {
            if (res && res.exists) {
              this.fileDocument.isDuplicated = true;
              this.errDuplicated = `${event.name} (${event.size} bytes) already exists. Please select another file.`;
            } else {
              this.fileDocument.isDuplicated = false;
            }
          },
          (error) => {
            this.fileDocument.isDuplicated = false;
          }
        );
    } else {
      if (event) {
        this.file = event;
        this.memberDetailService
          .checkMetaDataAttachmentExists({
            memberId: this.data.memberId,
            itemKey: this.data.itemKey,
            fileName: event.name,
            rowId: this.data.rowId,
          })
          .subscribe(
            (res: any) => {
              if (res && res.exists) {
                this.fileDocument.isDuplicated = true;
                this.errDuplicated = `${event.name} (${event.size} bytes) already exists. Please select another file.`;
              } else {
                this.fileDocument.isDuplicated = false;
              }
            },
            (error) => {
              this.fileDocument.isDuplicated = false;
            }
          );
      }
    }
  }

  removeFile(event: any) {
    this.file = event;
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: CANCEL_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }
}
