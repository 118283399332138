import { Component, Inject } from '@angular/core';
import { Form, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { combineLatest, forkJoin } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { BaseComponent } from '@ptg-shared/components';
import { Option } from '@ptg-shared/controls/select/select.component';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';

import {
  getAllEntitiesSelector,
  getAllEntityPropertiesSelector,
  updateEntitySelector,
} from '../../store/selectors';
import { EntityService } from '../../services';
import {
  CreateEntityRequest,
  GetEntityDetailResponse,
  UpdateEntityRequest,
} from '../../services/models';
import { EntityType } from '../../types/enums';
import { EntityState } from '../../store/reducers';
import {
  getAllEntitiesAction,
  getAllEntityPropertiesAction,
  updateEntity,
} from '../../store/actions';
import { EntityPropertyType } from '@ptg-entity-management/types/enums';

@Component({
  selector: 'ptg-edit-entity-information',
  templateUrl: './edit-entity-information.component.html',
  styleUrls: ['./edit-entity-information.component.scss'],
})
export class EditEntityInformationComponent extends BaseComponent {
  ALL_ENTITY_TYPE = EntityType;
  formGroup?: FormGroup;
  parentEntityOptions: Option[] = [];
  entityKeyCandidates: Option[] = [];
  entityDisplayFieldCandidates: Option[] = [];
  savedParentName: string = '';

  get nameCtrl() {
    return this.formGroup?.get('name') as FormControl;
  }
  get parentCtrl() {
    return this.formGroup?.get('parent') as FormControl;
  }
  get descriptionCtrl() {
    return this.formGroup?.get('description') as FormControl;
  }
  get entityKeyCtrl() {
    return this.formGroup?.get('entityKey') as FormControl;
  }
  get displayFieldCtrl() {
    return this.formGroup?.get('displayField') as FormControl;
  }
  get codeCtrl() {
    return this.formGroup?.get('code') as FormControl;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { entity: GetEntityDetailResponse },
    public fb: FormBuilder,
    public entityStore: Store<EntityState>,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditEntityInformationComponent>,
    public router: Router,
    public entityService: EntityService,
    public switchConfirmPopupService: SwitchConfirmPopupService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.createForm();
    this.initData();
  }

  private initData(): void {
    this.entityStore.dispatch(
      getAllEntitiesAction({
        query: {
          sortField: 'Name',
          sortType: 0,
        },
      })
    );
    this.entityStore.dispatch(
      getAllEntityPropertiesAction({
        request: {
          entityId: this.data.entity.id,
          sortNames: 'PropertyName',
          sortType: 0
        }
      })
    );
    combineLatest([
      this.entityStore.select(getAllEntitiesSelector),
      this.entityStore.select(getAllEntityPropertiesSelector),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([entities, properties]) => {
        if (entities && properties) {
          this.parentEntityOptions = (
            entities?.payload?.filter(
              (x: any) => x.id != this.data.entity.id
            ) ?? []
          ).map((element: any) => {
            return {
              value: element.id,
              displayValue: element.name,
            };
          });
          this.entityKeyCandidates = (
            properties?.payload?.propertyItems.filter(
              (x: any) =>
                x.type != EntityPropertyType.System &&
                EntityPropertyType.Aggregation &&
                EntityPropertyType.Calculation &&
                x.isUnique
            ) ?? []
          ).map((element: any) => {
            return {
              value: element.id,
              displayValue: element.propertyName,
            };
          });
          this.entityDisplayFieldCandidates = ((
            properties?.payload?.propertyItems ?? []
          ).filter(item => ![EntityPropertyType.System, EntityPropertyType['Entity Reference'], EntityPropertyType.Aggregation, EntityPropertyType.Calculation, EntityPropertyType.Identifier].includes(item.type)) ?? []
          ).map((element: any) => {
            return {
              value: element.id,
              displayValue: element.propertyName,
            };
          });
        }
      });

    this.entityStore
      .pipe(select(updateEntitySelector), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state && (state?.success || state?.error)) {
          this.dialogRef.close({
            state,
          });
        }
      });
  }

  private createForm(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(this.data.entity.entityName, {
        validators: [Validators.required, Validators.maxLength(250)],
      }),
      code: new FormControl(this.data.entity.code, {
        validators: [Validators.required, Validators.maxLength(250)],
      }),
      parent: new FormControl({
        value: this.data.entity.parentId,
        disabled:
          this.data.entity.parentId || this.data.entity.type != EntityType.None,
      }),
      description: new FormControl(this.data.entity.description),
      entityKey: new FormControl({ value: this.data.entity.entityKeyId, disabled: false }),
      displayField: new FormControl({ value: this.data.entity.displayFieldId, disabled: false }),
    });
  }

  onClickSaveData(): void {
    this.formGroup?.markAllAsTouched();
    if (this.formGroup?.valid) {
      this.entityService.checkExists({
          name: this.nameCtrl.value.trim(), 
          code: this.codeCtrl.value.trim(),
          id: this.data.entity.id
        }).pipe(
          map((el) => {
            return el;
          },
          catchError(async (error) => this.handleError(error))),
          takeUntil(this.unsubscribe$)
        ).subscribe((response) => { 
        if (response.isExistsName) {
          this.nameCtrl.setErrors({inValidAsync: true});
        }
        if (response.isExistsCode) {
          this.codeCtrl.setErrors({inValidAsync: true});
        }

        this.formGroup?.markAllAsTouched();
        this.savedParentName =
          this.parentEntityOptions.find(
            (x) => x.value === this.formGroup?.value.parent
          )?.displayValue ?? '';
        const body: UpdateEntityRequest = {
          name: this.formGroup?.value.name,
          description: this.formGroup?.value.description,
          parentId: this.formGroup?.value.parent,
          entityKeyId: this.formGroup?.value.entityKey,
          displayFieldId: this.formGroup?.value.displayField,
          id: this.data.entity.id,
          code: this.formGroup?.value.code
        };
        if (this.formGroup?.pending) {
          const sub = this.formGroup.statusChanges.subscribe(() => {
            if (this.formGroup?.valid) {
              this.saveData(body);
            }
            sub.unsubscribe();
          });
        } else if (this.formGroup?.valid) {
          this.saveData(body);
        }
      })
    }
  }

  private saveData(body: UpdateEntityRequest) {
    this.entityStore.dispatch(updateEntity({ request: body }));
  }

  onCancel(): void {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }

  handleError(error:any) {
    this.dialogRef.close({
      hasError: true,
    });
  }
}
