import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberLocalDecimal',
})
export class NumberLocalDecimalPipe implements PipeTransform {
  transform(
    value: number | string | undefined,
    unit?: string,
    decimal: number = 2,
    locales: string = 'en' // Set default locale to 'en' (English)
  ): string {
    if (value === null || value === undefined || value === '') return '';

    if (isNaN(Number(value))) return value.toString();

    const firstVal = value.toString().split('.')[0];
    return (
      (unit ? unit + ' ' : '') +
      (firstVal === '-0' ? '-0' : BigInt(firstVal).toLocaleString(
        locales
      )) +
      (decimal ? '.' + Number(value).toFixed(decimal).split('.')[1] : '')
    );
  }
}
