<div class="datetime-picker">
  <mat-form-field appearance="fill" class="{{class}}">
    <mat-label>{{placeholder}}</mat-label>
    <input matInput 
      [matDatepicker]="picker" 
      [formControl]="controlField"
      [min]="minDate"
      [max]="maxDate"
      >
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-error *ngIf="controlField?.errors?.required && !controlField?.errors?.matDatepickerParse">{{errorRequire}}</mat-error>
    <mat-error *ngIf="controlField?.errors?.matDatepickerParse">{{errorInvalid}}</mat-error>
    <mat-error *ngIf="!controlField?.errors?.required && !controlField?.errors?.matDatepickerParse && controlField?.errors?.matDatepickerMin">{{errorMinDate}}</mat-error>
    <mat-error *ngIf="!controlField?.errors?.required && !controlField?.errors?.matDatepickerParse && controlField?.errors?.matDatepickerMax">{{errorMaxDate}}</mat-error>
  </mat-form-field>
</div>