<div class="entity-summary-view-container">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs" [settings]="settings"></ptg-breadcrumb>

  <ptg-entity-overview-header></ptg-entity-overview-header>

  <div class="entity-summary-view-content flex flex-1 flex-col p-6 gap-6">
    <div *ngIf="profileConfigOverview" class="content-detail">
      <div class="side-layout" *ngFor="let direction of ['left', 'right']">
        <ng-container *ngFor="let card of cards">
          <div
            *ngIf="
              ((direction === 'left' && !card.isRight) || (direction === 'right' && card.isRight)) &&
              !card.profileFixType
            "
            class="mb-7"
          >
            <ptg-entity-summary-card
              [targetId]="targetId"
              [entityId]="entityId"
              [screenId]="screenId"
              [card]="card"
              [pageTitle]="pageTitle"
            ></ptg-entity-summary-card>
          </div>

          <div
            *ngIf="
              ((direction === 'left' && !card.isRight) || (direction === 'right' && card.isRight)) &&
              card.profileFixType === EntityProfileFixType.StatusHistory
            "
            class="mb-7"
          >
            <!-- Status History -->
            <ptg-entity-status-history [memberId]="targetId"></ptg-entity-status-history>
          </div>

          <div
            *ngIf="
              ((direction === 'left' && !card.isRight) || (direction === 'right' && card.isRight)) &&
              card.profileFixType === EntityProfileFixType.ServiceHistory
            "
            class="mb-7"
          >
            <!-- Service History -->
            <ptg-entity-service-history [memberId]="targetId"></ptg-entity-service-history>
          </div>

          <div
            *ngIf="
              ((direction === 'left' && !card.isRight) || (direction === 'right' && card.isRight)) &&
              card.profileFixType === EntityProfileFixType.Totals
            "
            class="mb-7"
          >
            <!-- Total Service -->
            <ptg-entity-total-service [memberId]="targetId"></ptg-entity-total-service>
          </div>
        </ng-container>
      </div>
    </div>

    <div *ngIf="!profileConfigOverview?.navigations?.length" class="center-msg">
      <div class="not-configured">
        <mat-icon class="material-icons-outlined">visibility_off</mat-icon>
        <span class="message">Profile has not been configured yet</span>
      </div>
    </div>
  </div>
</div>
