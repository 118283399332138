<div class="dialog-container" id="upload-accident-document-dialog">
  <div class="header-title">Upload Document</div>
  <form [formGroup]="formGroup" (ngSubmit)="formSubmit$.next()">
    <div class="form-container">
      <div class="input-group flex gap-3">
        <ptg-textbox
          [controlField]="documentNameControl"
          placeholder="Document Name"
          errorAsync="Document Name already exists."
          [isRequired]="true"
          [maxLength]="250"
          [hasLabel]="true"
        ></ptg-textbox>
        <ptg-select
          placeholder="Type"
          [controlField]="documentTypeControl"
          [listData]="listDocumentType"
          [isOptionObj]="true"
          [isRequired]="true"
          customError="errorMessage"
          [isMultipleLineOption]="true"
          [onlyTruncateTitle]="true"
        ></ptg-select>
      </div>
      <div class="file-upload">
        <ptg-upload
          #fileDocument
          (onUploadAction)="uploadFile($event)"
          (deleteFile)="removeFile($event)"
          [checkPattern]="checkPatternDocument"
          [errMsg]="'Unsupported file name or file format.'"
          [typeFile]="typeFileDocument"
          [controlField]="fileControl"
          [noUpload]="true"
          [isRequired]="true"
          [accept]="acceptDocument"
          uploadButtonName="Upload Document"
          errRequired="Upload Document is required."
          [errDuplicated]="errDuplicated"
        ></ptg-upload>
      </div>
    </div>
    <div class="row-button">
      <button class="submit-button" mat-raised-button type="submit">Save</button>
      <button (click)="onCancel()" class="cancel-button" mat-raised-button type="button">Cancel</button>
    </div>
  </form>
</div>
