import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ParametersQuery } from '@ptg-employer/models';
import { 
  CheckExistParameter, 
  CheckExistResponse, 
  CheckOverlapRequest, 
  CheckOverlapResponse, 
  GetDropPlanResponse,
  SetDropPlanRequest
  } from '@ptg-member/types/models/drop-plan.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DropPlanService {
  dropPlanId:string = '';
  constructor(private httpClient: HttpClient) { }

  getDropPlan(query: ParametersQuery): Observable<GetDropPlanResponse> {
    const params = new HttpParams({ fromObject: query as any });
    return this.httpClient.get<GetDropPlanResponse>(
      `${environment.apiUrl}/Members/drop-plans`,
      { params }
    );
  }
  checkExitsPlanYear = (body: CheckExistParameter): Observable<CheckExistResponse> => {
    if(this.dropPlanId) {
      body.dropPlanId = this.dropPlanId;
    }
    return this.httpClient.post<CheckExistResponse>(`${environment.apiUrl}/Members/drop-plans/exists`, body);
  }

  checkExitsOverlap(body: CheckOverlapRequest): Observable<CheckOverlapResponse>{
    return this.httpClient.post<CheckOverlapResponse>(`${environment.apiUrl}/Members/drop-plans/overlap`, body);
  }

  setDropPlan(body: SetDropPlanRequest): Observable<void> {
    return this.httpClient.post<void>(`${environment.apiUrl}/Members/drop-plan`, body);
  }
}
