<ptg-dialog class="edit-payee-info">
  <ng-template dialogContentHeader>
    <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>
  </ng-template>

  <ng-template dialogBody>
    <div class="edit-payee-info-container">
      <div class="edit-payee-info-btns flex p-6 pt-0">
        <ptg-button
          buttonName="Save"
          class="edit-payee-info-btn mr-4"
          classInput="primary save-button"
          (clickButton)="onSavePayeeInfo()"
          [isDisabled]="isLoading"
        ></ptg-button>
        <ptg-button
          buttonName="Cancel"
          class="edit-payee-info-btn"
          classInput="secondary cancel-button"
          (clickButton)="onCancelPayeeInfo()"
        ></ptg-button>
      </div>

      <ptg-grid
        [data]="entitiesData"
        [columns]="payeeColumns"
        [isLoading]="isLoading"
        [totalRecords]="totalRecords"
        [pageSize]="pageSize"
        [pageNumber]="pageNumber"
        (pageChange)="changePage($event)"
        (sortChange)="sortChange($event)"
        [fitToParent]="true"
        [hideScrollbar]="false"
        notFoundMessage="No Data to Display"
      >
        <ng-template cellContent columnName="radio" let-row>
          <mat-radio-button
            [checked]="row.checked"
            (change)="onChangeRadioButton(row)"
          ></mat-radio-button>
        </ng-template>
      </ptg-grid>
    </div>
  </ng-template>
</ptg-dialog>
