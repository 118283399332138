import { createAction, props } from '@ngrx/store';

import {
  CreateStepConfigurationsRequest,
  GetAllowRemoveStepConfigurationResponse,
  GetBenefitTypeRequest,
  GetBenefitOptionsResponse,
  GetStepConfigurationPropertiesResponse,
  GetStepConfigurationResponse,
  GetStepConfigurationsRequest,
  GetStepConfigurationsResponse,
  SetStepConfigurationRequest,
  StepConfigurationValuesRequest,
  GetMemberEntitiesResponse,
  GetCardsResponse,
  GetCardsRequest,
  CheckStepConfigurationExistsRequest,
  CheckStepConfigurationExistsResponse,
} from '../../services/models';

export const getStepConfigurationsAction = createAction(
  '[GetStepConfigurations/API] Send Request',
  props<{ request: GetStepConfigurationsRequest }>(),
);

export const getStepConfigurationsSuccessAction = createAction(
  '[GetStepConfigurations/API] Success',
  props<{ response: GetStepConfigurationsResponse }>(),
);

export const getStepConfigurationsFailureAction = createAction(
  '[GetStepConfigurations/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetStepConfigurationsStateAction = createAction('[GetStepConfigurations] Clear');

export const checkStepConfigurationExistsAction = createAction(
  '[CheckStepConfigurationExists/API] Send Request',
  props<{ request: CheckStepConfigurationExistsRequest }>(),
);

export const checkStepConfigurationExistsSuccessAction = createAction(
  '[CheckStepConfigurationExists/API] Success',
  props<{ response: CheckStepConfigurationExistsResponse }>(),
);

export const checkStepConfigurationExistsFailureAction = createAction(
  '[CheckStepConfigurationExists/API] Failure',
  props<{ error?: any }>(),
);

export const clearCheckStepConfigurationExistsStateAction = createAction('[CheckStepConfigurationExists] Clear');

export const createStepConfigurationsAction = createAction(
  '[CreateStepConfigurations/API] Send Request',
  props<{ request: CreateStepConfigurationsRequest; isContinue: boolean }>(),
);

export const createStepConfigurationsSuccessAction = createAction(
  '[CreateStepConfigurations/API] Success',
  props<{ stepConfigurationId: string; isContinue: boolean }>(),
);

export const createStepConfigurationsFailureAction = createAction(
  '[CreateStepConfigurations/API] Failure',
  props<{ error?: any }>(),
);

export const clearStepConfigurationsActionStateAction = createAction('[StepConfigurationsAction] Clear');

export const getBenefitTypesAction = createAction(
  '[GetCalculationBenefitTypes/API] Send Request',
  props<{ request?: GetBenefitTypeRequest }>(),
);

export const getBenefitTypesSuccessAction = createAction(
  '[GetCalculationBenefitTypes/API] Success',
  props<{ response: GetBenefitOptionsResponse }>(),
);

export const getBenefitTypesFailureAction = createAction(
  '[GetCalculationBenefitTypes/API] Failure',
  props<{ error?: any }>(),
);

export const clearBenefitTypeStateAction = createAction('[GetCalculationBenefitTypes] Clear');

export const getStepConfigurationAction = createAction(
  '[GetStepConfiguration/API] Send Request',
  props<{ stepConfigurationId: string; memberId: string }>(),
);

export const getStepConfigurationSuccessAction = createAction(
  '[GetStepConfiguration/API] Success',
  props<{ response: GetStepConfigurationResponse }>(),
);

export const getStepConfigurationFailureAction = createAction(
  '[GetStepConfiguration/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetStepConfigurationStateAction = createAction('[GetStepConfiguration] Clear');

export const setStepConfigurationAction = createAction(
  '[SetStepConfiguration/API] Send Request',
  props<{ request: SetStepConfigurationRequest }>(),
);

export const setStepConfigurationSuccessAction = createAction('[SetStepConfiguration/API] Success');

export const setStepConfigurationFailureAction = createAction(
  '[SetStepConfiguration/API] Failure',
  props<{ error?: any }>(),
);

export const clearSetStepConfigurationStateAction = createAction('[SetStepConfigurations] Clear');

export const getStepConfigurationPropertiesAction = createAction('[GetStepConfigurationProperties/API] Send Request');

export const getStepConfigurationPropertiesSuccessAction = createAction(
  '[GetStepConfigurationProperties/API] Success',
  props<{ response: GetStepConfigurationPropertiesResponse }>(),
);

export const getStepConfigurationPropertiesFailureAction = createAction(
  '[GetStepConfigurationProperties/API] Failure',
  props<{ error?: any }>(),
);

//get step configuration values base on benefitTypeId and benefitSubTypeId

export const getStepConfigurationValueAction = createAction(
  '[GetStepConfigurationValue/API] Send Request',
  props<{ request: StepConfigurationValuesRequest }>(),
);

export const getStepConfigurationValueSuccessAction = createAction(
  '[GetStepConfigurationValue/API] Success',
  props<{ response: GetStepConfigurationResponse }>(),
);

//remove
export const removeStepConfiguration = createAction(
  '[RemoveStepConfiguration/API] Send Request',
  props<{ stepConfigurationId: string }>(),
);
export const removeStepConfigurationSuccess = createAction('[RemoveStepConfiguration/API] Success');
export const removeStepConfigurationFailure = createAction(
  '[RemoveStepConfiguration/API] Fail',
  props<{ error: any }>(),
);

export const checkAllowRemoveStepConfiguration = createAction(
  '[checkAllowRemoveStepConfiguration/API] Send Request',
  props<{ stepConfigurationId: string }>(),
);
export const checkAllowRemoveStepConfigurationSuccess = createAction(
  '[checkAllowRemoveStepConfiguration/API] Success',
  props<{ response: GetAllowRemoveStepConfigurationResponse }>(),
);
export const checkAllowRemoveStepConfigurationFailure = createAction(
  '[checkAllowRemoveStepConfiguration/API] Fail',
  props<{ error: any }>(),
);
export const clearCheckAllowRemoveStepConfigurationStateAction = createAction(
  '[checkAllowRemoveStepConfiguration] Clear',
);

export const getMemberEntitiesAction = createAction(
  '[GetMemberEntities/API] Send Request',
);

export const getMemberEntitiesSuccessAction = createAction(
  '[GetMemberEntities/API] Success',
  props<{ response: GetMemberEntitiesResponse }>()
);

export const getMemberEntitiesFailureAction = createAction(
  '[GetMemberEntities/API] Failure',
  props<{ error?: any }>()
);

export const clearGetMemberEntitiesStateAction = createAction(
  '[GetMemberEntities] Clear'
);
export const getCardsAction = createAction(
  '[GetCards/API] Send Request',
  props<{ request: GetCardsRequest }>()
);

export const getCardsSuccessAction = createAction(
  '[GetCards/API] Success',
  props<{ response: GetCardsResponse }>()
);

export const getCardsFailureAction = createAction(
  '[GetCards/API] Failure',
  props<{ error?: any }>()
);

export const clearGetCardsStateAction = createAction(
  '[GetCards] Clear'
);
