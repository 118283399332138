import { createAction, props } from '@ngrx/store';
import { Department, DepartmentList, DepartmentListQuery } from '../models/department.model';
import { Employer } from '../models/employer.model';

export const DepartmentListRequest = '[DepartmentList/API] Send Request';
export const DepartmentListFailure = '[DepartmentList/API] Failure';
export const DepartmentListSuccess = '[DepartmentList/API] Success';
export const ClearDepartmentState = '[Department] Clear';
export const CreateDepartmentRequest = '[CreateDepartmentRequest/API] Send Request';
export const CreateDepartmentSuccess = '[CreateDepartment/API] Success';
export const CreateDepartmentFailure = '[CreateDepartment/API] Failure';
export const UpdateDepartmentRequest = '[UpdateDepartmentRequest/API] Send Request';
export const UpdateDepartmentSuccess = '[UpdateDepartment/API] Success';
export const UpdateDepartmentFailure = '[UpdateDepartment/API] Failure';
export const DepartmentEmployerDetailRequest = '[DepartmentEmployerDetail/API] Send Request';
export const DepartmentEmployerDetailFailure = '[DepartmentEmployerDetail/API] Failure';
export const DepartmentEmployerDetailSuccess = '[DepartmentEmployerDetail/API] Success';


export const getDepartmentList = createAction(
  DepartmentListRequest,
  props<{ employerId: string, query: DepartmentListQuery }>()
);
export const getDepartmentListSuccess = createAction(
  DepartmentListSuccess,
  props<{ departmentList: DepartmentList }>()
);
export const getDepartmentListFailure = createAction(
  DepartmentListFailure,
  props<{ error?: any }>()
);
export const clearDepartmentState = createAction(
  ClearDepartmentState
);
export const createDepartment = createAction(
  CreateDepartmentRequest,
  props<{ department: Department }>()
);
export const createDepartmentSuccess = createAction(
  CreateDepartmentSuccess
);
export const createDepartmentFailure = createAction(
  CreateDepartmentFailure,
  props<{ errorMsg: string }>()
);
export const updateDepartment = createAction(
  UpdateDepartmentRequest,
  props<{ department: Department }>()
);
export const updateDepartmentSuccess = createAction(
  UpdateDepartmentSuccess
);
export const updateDepartmentFailure = createAction(
  UpdateDepartmentFailure,
  props<{ errorMsg: string }>()
);
export const getDepartmentEmployerDetail = createAction(
  DepartmentEmployerDetailRequest,
  props<{ employerId: string, clientId: string }>()
);
export const getDepartmentEmployerDetailSuccess = createAction(
  DepartmentEmployerDetailSuccess,
  props<{ employer: Employer }>()
);
export const getDepartmentEmployerDetailFailure = createAction(
  DepartmentEmployerDetailFailure,
  props<{ errorMsg: string }>()
);
