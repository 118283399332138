<div class="entity-profile-config" id="entity-profile-config-page">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"
  (onAdd)="openForm()"
  buttonAddLabel="New Profile"></ptg-breadcrumb>

  <div class="entity-profile-config-content flex flex-col gap-6">
    <ptg-banner
      *ngIf="message"
      [(bannerType)]="bannerType"
      [message]="message"
      (closeBanner)="closeBanner()"
    ></ptg-banner>

    <div class="flex-grow overflow-hidden">
      <ptg-grid
        [paginable]="true"
        [isLoading]="(isLoading || isLoadingRemove)"
        [data]="profileConfigsData"
        [columns]="columns"
        [fitToParent]="true"
        [totalRecords]="lengthPg"
        [pageSize]="pageSize"
        [pageNumber]="pageNumber"
        (pageChange)="onChangePage($event)"
        (sortChange)="sortChange($event)"
        notFoundMessage="No Profile to Display"
      >
        <ng-template cellContent columnName="statusEvents" let-row>
          <mat-chip-list>
            <mat-chip *ngFor="let status of row.statusEvents">
              <mat-icon
                *ngIf="status?.iconName"
                [style.color]="status?.color"
              >
                {{ status?.iconName }}
              </mat-icon>
              <span>{{ status?.statusEventName }}</span>
            </mat-chip>
          </mat-chip-list>
        </ng-template>
          <ng-template cellContent columnName="action" let-row>
            <ptg-button
              buttonName="Edit"
              classInput="edit-button"
              (clickButton)="editView(row)"
            >
              <mat-icon>edit</mat-icon>
            </ptg-button>
            <ptg-button
              buttonName="Remove"
              classInput="remove-button"
              (clickButton)="onClickRemoveProfileConfig(row)"
            >
              <mat-icon>delete_forever</mat-icon>
            </ptg-button>
          </ng-template>
      </ptg-grid>
    </div>
  </div>
</div>
