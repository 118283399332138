import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BaseComponent } from '@ptg-shared/components';
import * as fromReducer from '@ptg-reducers';
import * as fromMember from '@ptg-member/store/reducers';
import { FIRST_PAGE } from '@ptg-shared/controls/pagination';
import * as TotalServicesAction from '@ptg-member/store/actions/total-services.actions';
import { takeUntil } from 'rxjs/operators';
import { TotalServicesReponse } from '@ptg-member/types/models/total-services.model';

@Component({
  selector: 'ptg-entity-total-service',
  templateUrl: './entity-total-service.component.html',
  styleUrls: ['./entity-total-service.component.scss']
})
export class EntityTotalServiceComponent extends BaseComponent {
  @Input() memberId!: string;
  totalServiceData!: TotalServicesReponse;
  pageSize: number = 50;
  pageIndex: number = FIRST_PAGE;
  constructor(
    public route: ActivatedRoute,
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>
  ) {
    super();
   }

  ngOnInit(): void {
    this.getData();
    this.getTotalServicesSelector();
  }

  getTotalServicesSelector() {
    this.memberStore
    .pipe(
      select(fromMember.selectTotalServicesState),
      takeUntil(this.unsubscribe$)
    )
    .subscribe((state) => {
      if (state) {
        this.totalServiceData = state;
      }
    });
  }

  getData() {
    const query = {
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
    }
    this.memberStore.dispatch(TotalServicesAction.getTotalServices({query: query, memberId: this.memberId}))
  }

}
