import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ViewCurrentFiltersComponent } from './view-current-filters.component';



@NgModule({
  declarations: [ViewCurrentFiltersComponent],
  imports: [
    CommonModule,
    MatIconModule,
  ],
  exports: [ViewCurrentFiltersComponent]
})
export class ViewCurrentFiltersModule { }
