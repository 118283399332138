import { FormControl } from '@angular/forms';

export class FieldData {
  key: string;
  name: string;
  label: string;
  type: string;
  required: boolean;
  maxLength: any;
  formControl?: FormControl;
  lstOption?: any[];
  className?: string;
  min?: string;
  max?: string;
  messageError?: string;
  isSetRequired?: boolean;
  constructor(
    key: string,
    name: string,
    label: string,
    type: string,
    required: boolean,
    maxLength: any,
    className: string
  ) {
    this.key = key;
    this.name = name;
    this.label = label;
    this.type = type;
    this.required = required;
    this.maxLength = maxLength;
    this.className = className;
  }
}
