export const PERSON_PROPERTY_MAPPING = {
  Name: '0AC1B906-C396-4BC5-A83B-EEC542482445',
  DateOfBirth: '220E5FCB-9A56-4C89-88B2-C717F6DF2B5E',
  DateOfDeath: '92EB0499-7C10-485F-B9F1-757F8F9C1632',
  MaritalStatus: '1FA73B73-7C1C-4BA8-93A5-782429C3EFDD',
  Gender: '5F4D07BE-F13B-4C9C-99AA-CE9B228F07C7',
  Phone: '94566AA1-5318-4FC3-B89C-3D683AD8CB5F',
  Email: '00FABCB5-D468-460B-BEBB-23F06178DC19',
  PrimaryAddress: 'A499344F-F95C-43B2-B716-516C8AC3754B',
  SSN: '4BE56F09-EA2F-4DCC-8C53-5E44707A124B',
  SSNHash: '4BE56F09-EA2F-4DCC-8C53-5E44707A124B',
  Age: 'C171F9AA-BBDD-4DAF-81DD-C5C54CA62889',
};
