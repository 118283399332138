import { createReducer, on } from '@ngrx/store';
import { TransactionsActions} from '../actions';
import { TransactionHeaderData, TransactionList, TransactionResponse } from '@ptg-employer/models/transactions.model';
import { BaseActionState } from '@ptg-shared/types/models';
import { ACTION, STATE } from '@ptg-shared/constance';

export const municipalityTransactionsFeatureKey = ' municipalityTransactions';

export interface State {
  listMunicipalityTransactions?: BaseActionState<TransactionList[]>;
  lisTransactions?: BaseActionState<TransactionList[]>;
  municipality?: TransactionHeaderData,
  editCashJournalTransaction?: string,
  exportTransactionState?: BaseActionState<Blob[]>,
}

const initialState: State = {
  listMunicipalityTransactions: {
    isLoading: true,
  },
  municipality: {} as TransactionHeaderData,
  lisTransactions: {
    isLoading: true,
  },
  editCashJournalTransaction: '',
  exportTransactionState: undefined,
}
export const reducer = createReducer(
  initialState,
  on(TransactionsActions.getMunicipalityTransaction, (state) => ({
    ...state,
    listMunicipalityTransactions: {
      ...state.listMunicipalityTransactions,
      isLoading: true,
      total: 0,
      payload: []
    }
  })),
  on(TransactionsActions.getMunicipalityTransactionSuccess, (state: State, { response }) => ({
    listMunicipalityTransactions: {
      ...state.listMunicipalityTransactions,
      isLoading: false,
      total: response?.total,
      payload: response?.transactions,
      success: true,
    },
    municipality: response?.municipality
  })),
  on(TransactionsActions.getMunicipalityTransactionFailure, (state: State, { error }) => ({
    listMunicipalityTransactions: {
      ...state.listMunicipalityTransactions,
      isLoading: false,
      total: 0,
      payload: [],
      success: false
    }
  })),
  on(TransactionsActions.clearGetMunicipalityTransactionState, (state: State) => ({
    ...state,
  })),

  on(TransactionsActions.getTransaction, (state) => ({
    ...state,
    lisTransactions: {
      ...state.lisTransactions,
      isLoading: true,
      total: 0,
      payload: []
    }
  })),
  on(TransactionsActions.getTransactionSuccess, (state: State, { response }) => ({
    lisTransactions: {
      ...state.lisTransactions,
      isLoading: false,
      total: response?.total,
      payload: response?.transactions,
      success: true,
    },
    municipality: response?.municipality
  })),
  on(TransactionsActions.getTransactionFailure, (state: State, { error }) => ({
    lisTransactions: {
      ...state.lisTransactions,
      isLoading: false,
      total: 0,
      payload: [],
      success: false
    }
  })),
  on(TransactionsActions.clearGetTransactionState, (state: State) => ({
    ...state,
  })),
  on(TransactionsActions.editCashJournalTransactionSuccess, (state, { }) => ({
    ...state,
    editCashJournalTransaction: STATE.SUCCESS
  })),
  on(TransactionsActions.editCashJournalTransactionFailure, (state, { errorMsg }) => ({
    ...state,
    editCashJournalTransaction: STATE.FAIL
  })),
  on(TransactionsActions.clearCashJournalTransactionState, (state) => ({
    ...state,
    editCashJournalTransaction: ''
  })),

  on(TransactionsActions.getExportTransaction, (state: State) => ({
    ...state,
    isLoading: true,
    exportTransactionState: undefined,
  })),
  on(TransactionsActions.getExportTransactionSuccess, (state: State, { response }) => ({
    ...state,
    exportTransactionState: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(TransactionsActions.getExportTransactionFailure, (state: State, { error }) => ({
    ...state,
    exportTransactionState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(TransactionsActions.clearGetExportTransactionState, (state: State) => ({
    ...state,
    isLoading: false,
    exportTransactionState: undefined,
  })),
)
