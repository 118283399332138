import { Component, ContentChild, Input } from '@angular/core';

import { BaseComponent } from '@ptg-shared/components';
import { PanelExtraHeader } from '@ptg-shared/controls/panel';

import { PROPERTY_DISPLAY } from '@ptg-member/constants';
import { EntityViewItem } from '@ptg-entity-management/services/models';
import { EntityProfileComponentType } from '@ptg-entity-management/types/enums';

@Component({
  selector: 'ptg-entity-summary-card',
  templateUrl: './entity-summary-card.component.html',
  styleUrls: ['./entity-summary-card.component.scss'],
})
export class EntitySummaryCardComponent extends BaseComponent {
  readonly PROPERTY_DISPLAY = PROPERTY_DISPLAY;
  readonly EntityProfileComponentType = EntityProfileComponentType;

  @Input() entityId!: string;
  @Input() targetId!: string;
  @Input() screenId?: string;
  @Input() card!: EntityViewItem;
  @Input() pageTitle: string = '';

  @ContentChild(PanelExtraHeader) panelExtraHeader?: PanelExtraHeader;

  constructor() {
    super();
  }
}
