import { createSelector } from '@ngrx/store';

import { selectCalculationModuleState } from './selector';

export const benefitRefundSelector = createSelector(
  selectCalculationModuleState,
  (state) => state?.benefitRefund
);

export const getInitiateBenefitValidationsSelector = createSelector(
  benefitRefundSelector,
  (state) => state?.getInitiateBenefitValidations
);

export const createInitiateRefundCalculationInformationSelector = createSelector(
  benefitRefundSelector,
  (state) => state?.createInitiateRefundCalculationInformation
);

export const getRefundCalculationRecordsSelector = createSelector(
  benefitRefundSelector,
  (state) => state?.getRefundCalculationRecords
);

export const getRefundCalculationRecordByIdsSelector = createSelector(
  benefitRefundSelector,
  (state) => state?.getRefundCalculationRecordByIds
);

export const getCalculationRefundYearsSelector = createSelector(
  benefitRefundSelector,
  (state) => state?.getCalculationRefundYears
);

export const updatePayeeInformationSelector = createSelector(
  benefitRefundSelector,
  (state) => state?.updatePayeeInfo
);

export const getPayeeListSelector = createSelector(
  benefitRefundSelector,
  (state) => state?.getPayeeList
);

export const getRefundPayeeSelector = createSelector(
  benefitRefundSelector,
  (state) => state?.getRefundPayee
);

export const createReSelectRefundYearsSelector = createSelector(
  benefitRefundSelector,
  (state) => state?.createReSelectRefundYears
);

export const getValidateBeforeRemoveCalculationBenefitSelector = createSelector(
  benefitRefundSelector,
  (state) => state?.getValidateBeforeRemoveCalculationBenefit
);

