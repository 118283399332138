import { Component, Input } from '@angular/core';

import { BaseGridTemplateComponent } from '@ptg-shared/components';

import { Status } from '../../../types/models/profile-navigation-configuration.model';

@Component({
  selector: 'ptg-list-status',
  templateUrl: './list-status.component.html',
  styleUrls: ['./list-status.component.scss'],
  inputs: BaseGridTemplateComponent.genericInputs,
})
export class ListStatusComponent {
  @Input() statusList!: Status[];
}
