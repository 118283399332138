import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  GetGetEntityComponentListDataByComponentIdsRequest,
  GetGetEntityComponentListDataByComponentIdsResponse,
} from './models';

@Injectable()
export class GetEntityComponentListDataByComponentIdService {
  constructor(private httpClient: HttpClient) {}

  getGetEntityComponentListDataByComponentIds(
    request: GetGetEntityComponentListDataByComponentIdsRequest
  ): Observable<GetGetEntityComponentListDataByComponentIdsResponse> {
    const { memberId, entityComponentId } = request
    return this.httpClient.get<GetGetEntityComponentListDataByComponentIdsResponse>(
      `${environment.apiUrl}/entities/members/${memberId}/components/${entityComponentId}/entity-component-list-data`,
    );
  }
}