import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

import {
  AggregationGroupBody,
  AggregationGroupBodyChangeKey,
  AggregationGroup,
} from '../types/models/aggregation-group.model';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AggregationGroupService {
  sectionKey: string = '';
  currentAggregationGroup: any;

  constructor(private httpClient: HttpClient) {}

  getAggregationGroupList() {
    // return this.httpClient.get<AggregationGroup[]>(
    //   `${environment.apiUrl}/Metadata/${this.sectionKey}/Aggregation/Groups`
    // );

    // TODO remove when complete module entity
   return of();
  }

  orderTable(body: AggregationGroupBodyChangeKey) {
    // return this.httpClient.put(
    //   `${environment.apiUrl}/Metadata/${this.sectionKey}/Aggregation/Groups/${body.reorderItemId}/Reorder`,
    //   { upperAdjacentId: body.upperAdjacentId }
    // );

    // TODO remove when complete module entity
   return of();
  }

  checkGroupNameExist = (bodyCheck: any) => {
    // let body = {
    //   ...bodyCheck,
    //   aggregationGroupId: this.currentAggregationGroup?.id,
    // };
    // return this.httpClient.post(
    //   `${environment.apiUrl}/Metadata/${this.sectionKey}/Aggregation/Groups/Exists`,
    //   body
    // );

    // TODO remove when complete module entity
   return of();
  };

  addAggregationGroup(body: AggregationGroupBody) {
    // return this.httpClient.post(
    //   `${environment.apiUrl}/Metadata/${this.sectionKey}/Aggregation/Groups/Add`,
    //   body
    // );

    // TODO remove when complete module entity
   return of();
  }

  updateAggregationGroup(id: string, body: AggregationGroupBody) {
    // return this.httpClient.put(
    //   `${environment.apiUrl}/Metadata/${this.sectionKey}/Aggregation/Groups/${id}/Edit`,
    //   body
    // );

    // TODO remove when complete module entity
   return of();
  }

  removeAggregationGroup(id: string) {
    // return this.httpClient.delete(
    //   `${environment.apiUrl}/Metadata/${this.sectionKey}/Aggregation/Groups/${id}`
    // );

    // TODO remove when complete module entity
   return of();
  }
  getAggregationGroupDetails(body: any) {
    // return this.httpClient.get(
    //   `${environment.apiUrl}/Metadata/${body.itemKey}/${body.memberId}/Aggregation/Groups/Details`
    // );

    // TODO remove when complete module entity
   return of();
  }
}
