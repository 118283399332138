import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { SORT_TYPE } from '@ptg-shared/constance';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { Subject } from 'rxjs';
import { filter, startWith, switchMap, take, tap } from 'rxjs/operators';
import { BaseComponent } from '@ptg-shared/components';
import { Router } from '@angular/router';
import * as fromMember from '../../store/reducers';
import { Option } from '@ptg-shared/controls/select/select.component';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { NewView, View } from '@ptg-member/types/models';
import { GetEntitiesQuery } from 'src/app/entity-management/services/models';
import { createView, getEntitiesAction, updateView } from '@ptg-member/store/actions/view.actions';
import { LIST_TYPE } from '@ptg-member/constants';
import { createViewSelector, getEntitiesSelector, getListSelector } from '@ptg-member/store/selectors';

@Component({
  selector: 'ptg-add-view',
  templateUrl: './add-view.component.html',
  styleUrls: ['./add-view.component.scss'],
})
export class AddViewComponent extends BaseComponent {
  LIST_TYPE = LIST_TYPE;
  
  formSubmit$ = new Subject<boolean>();
  isContinue = false;
  listEntity: Option[] = [];
  listView: View[] = [];
  editForm!: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      view: NewView;
    },
    public fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddViewComponent>,
    public memberStore: Store<fromMember.MemberState>,
    public router: Router,
    public switchConfirmPopupService: SwitchConfirmPopupService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getEntityData();
    this.emitFormSubmit();

    this.editForm = this.fb.group({
      name: this.fb.control(this.data?.view?.name, {
        validators: [Validators.required],
      }),
      type: this.fb.control(this.data?.view?.type, Validators.required),
      entity: this.fb.control(this.data?.view?.entityId, Validators.required)
    });

    this.memberStore.pipe(select(getEntitiesSelector)).subscribe((data) => {
      this.listEntity = (data?.payload ?? []).map((ele) => {
        return {
          value: ele.id,
          displayValue: ele.name,
        };
      });
    });

    this.memberStore.pipe(select(getListSelector)).subscribe((data) => {
      this.listView = data?.payload ?? [];
    });

    this.memberStore.pipe(select(createViewSelector)).subscribe((data) => {
      if (data?.success) {
        if (this.isContinue) {
          this.dialogRef.close();
          this.router.navigateByUrl(`/member/view/${data?.payload?.id}`);
        } else {
          this.editForm.reset();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.isContinue = false;
  }

  onSubmit(): void {
    const body = this.editForm.getRawValue();
    const isDuplicateEntity = this.listView?.some(viewItem => viewItem.viewName?.toLocaleLowerCase() === body.name?.toLocaleLowerCase())

    this.editForm.markAllAsTouched();
    if (!this.editForm.valid) {
      return;
    }
    
    if(isDuplicateEntity) {
      this.editForm.controls["name"]?.setErrors({ inValidAsync: true });
      return;
    }

    if (this.data.view) {
      this.memberStore.dispatch(
        updateView({ 
          id: this.data.view.id || '',
          body: body,
        })
      );
      return;
    }
    this.memberStore.dispatch(
      createView({
        body: {
          name: body.name,
          type: body.type,
          entityId: body.entity,
        },
      })
    );
  }

  emitFormSubmit(): void {
    this.formSubmit$
      .pipe(
        tap((isContinue) => {
          this.isContinue = isContinue;
          this.editForm.markAllAsTouched();
          this.editForm.get('name')?.updateValueAndValidity();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter((status) => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  getEntityData(): void {
    const query: GetEntitiesQuery = {
      sortField: 'name',
      sortType: SORT_TYPE.ASC,
    };

    this.memberStore.dispatch(getEntitiesAction({ query: query }));
  }

  onCancel(): void {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }
}
