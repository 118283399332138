<div id="lookup-table-detail-detail-page" class="lookup-table-detail-container">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>
  <mat-tab-group
    mat-align-tabs="start"
    animationDuration="0ms"
    (selectedTabChange)="onSelectedTabChange($event)"
  >
  <mat-tab label="Details">
    <div class="flex flex-col pt-6 pb-6 pl-64 pr-64 gap-6">
      <ptg-banner
        *ngIf="bannerType"
        [(bannerType)]="bannerType"
        [message]="message"
      ></ptg-banner>
  
      <div *ngIf="lookupTableDetail" class="box">
        <div class="title">
          <span class="title-name">Table Details</span>
          <div class="separator"></div>
          <div class="button-group">
            <ptg-button
              buttonName="Edit"
              classInput="edit-button"
              (clickButton)="editLookupTable()"
            >
              <mat-icon>edit</mat-icon>
            </ptg-button>
          </div>
        </div>
        <div class="detail-container">
          <div class="detail">
            <span class="label" id="label-Table Name">Table Name</span>
            <span class="value" id="value-Table Name">
              <ng-container>{{ lookupTableDetail?.name }}</ng-container>
            </span>
          </div>
          <div class="detail">
            <span class="label" id="label-Disabled">Disabled</span>
            <span class="value" id="value-Disabled">
              {{ lookupTableDetail?.active ? "No" : "Yes" }}
            </span>
          </div>
        </div>
      </div>
  
      <div class="box">
        <div class="title">
          <span class="title-name">Value List</span>
          <div class="separator"></div>
          <div class="button-group">
            <ptg-button
              buttonName="New Value"
              classInput="edit-button"
              (clickButton)="editValue(undefined)"
            >
              <mat-icon>add</mat-icon>
            </ptg-button>
          </div>
        </div>
        <ptg-grid
          [data]="lookupTableDetailsData"
          [columns]="columns"
          keyColumn="id"
          [isLoading]="isLoading"
          [errorMessage]="errorMsg"
          [paginable]="false"
          notFoundMessage="No Value to Display"
        >
          <ng-template cellContent columnName="action" let-row>
            <ptg-button
              buttonName="Edit"
              classInput="edit-button"
              (clickButton)="editValue(row)"
            >
              <mat-icon>edit</mat-icon>
            </ptg-button>
            <ptg-button
              buttonName="Remove"
              classInput="remove-button"
              (clickButton)="removeRecord(row)"
            >
              <mat-icon>delete_forever</mat-icon>
            </ptg-button>
          </ng-template>
        </ptg-grid>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Display Format">
    <ptg-lookup-table-detail-display-format *ngIf="lookupTableDetail.id" [id]="lookupTableDetail.id">
    </ptg-lookup-table-detail-display-format>
  </mat-tab>
</mat-tab-group>
</div>

