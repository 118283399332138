<div class="address-history-container" id="address-history-container">
  <ptg-title-bar
    [name]="data.address ? 'Edit' : 'Add New'"
    [viewName]="data.viewName"
    [onlyTitle]="true"
  ></ptg-title-bar>

  <ptg-overview-header-entity
    [targetIdFromDialog]="data?.memberId">
  </ptg-overview-header-entity>

  <form [formGroup]="editForm">
    <div class="wrap-btn">
      <button mat-raised-button type="submit" class="submit-button" (click)="onSubmit()">Save</button>
      <button mat-raised-button type="button" class="cancel-button" (click)="onCancel()">Cancel</button>
    </div>
    <div class="form-control-container">
      <ptg-select
        placeholder="Address Type"
        class="input-field"
        [controlField]="editForm.get('addressType')"
        [listData]="listOptionAddressType"
        [isOptionObj]="true"
        errorRequire="Address Type is required."
        [isSetMaxWidthSelect]="true"
        (changeOptionValue)="onChangeStatusValue()"
        [class.disabled]="data?.address"
      ></ptg-select>
      <ptg-input-address
        #address
        [isAddNew]="!data?.address"
        [addressProperty]="addressPropertySelected"
        [addressForm]="editForm.get('address')"
        [isAddressHistory]="true"
      ></ptg-input-address>
    </div>
  </form>
</div>
