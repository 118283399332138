import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SHOW_LOADING } from '@ptg-interceptor/httpconfig.interceptor';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable()
export class RemoveOverviewDocumentService {
  constructor(private httpClient: HttpClient) {}

  removeRemoveOverviewDocument(
    id: string
  ): Observable<void> {
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.delete<void>(
      `${environment.apiUrl}/calculations/calculation-options/calculation-benefit-documents/${id}`,
      { context }
    );
  }
}
