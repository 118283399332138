export enum TierConditionOperatorType {
  Equal,
  NotEqual,
  Contains,
  NotContains,
  GreaterThan,
  LessThan,
  IsOrGreaterThan,
  IsOrLessThan,
  IsBetween,
  IsIn,
  IsNotIn,
}

export enum TierFilePropertyType {
  Text = 1,
  Whole_Number = 4,
  Currency = 5,
  Decimal = 6,
  Date = 7,
  Department = 52,
  Binary = 9,
  Percentage = 10,
  Lookup = 50,
}