import { createReducer, on } from '@ngrx/store';
import {
  getPaymentReceiptAction,
  getPaymentReceiptSuccessAction,
  getPaymentReceiptFailureAction,
  clearGetPaymentReceiptStateAction,
  clearDownloadPaymentReceiptStateAction,
  downloadPaymentReceiptAction,
  downloadPaymentReceiptFailureAction,
  downloadPaymentReceiptSuccessAction
} from '@ptg-employer/actions/payment-receipt.action';
import { PaymentReportFormat } from '@ptg-employer/constance/municipality.const';
import { PaymentReceipt } from '@ptg-employer/models/payment-receipt.model';
import { BaseActionState } from '@ptg-shared/types/models';

export interface PaymentReceiptState {
  getPaymentReceipt?: BaseActionState<PaymentReceipt>;
  downloadPaymentReceipt?: BaseActionState<{ blob: Blob, reportFormat: PaymentReportFormat, receiptNumber: string  }>;
}

const initialState: PaymentReceiptState = {};

export const paymentReceiptReducer = createReducer(
  initialState,
  on(getPaymentReceiptAction, (state) => ({
    ...state,
    getPaymentReceipt: {
      isLoading: true,
    },
  })),
  on(getPaymentReceiptSuccessAction, (state, { response }) => ({
    ...state,
    getPaymentReceipt: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getPaymentReceiptFailureAction, (state, { error }) => ({
    ...state,
    getPaymentReceipt: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetPaymentReceiptStateAction, (state) => ({
    ...state,
    getPaymentReceipt: undefined,
  })),

  on(downloadPaymentReceiptAction, (state) => ({
    ...state,
    downloadPaymentReceipt: {
      isLoading: true,
    },
  })),
  on(downloadPaymentReceiptSuccessAction, (state) => ({
    ...state,
    downloadPaymentReceipt: {
      isLoading: false,
      success: true,
    },
  })),
  on(downloadPaymentReceiptFailureAction, (state, { error }) => ({
    ...state,
    downloadPaymentReceipt: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearDownloadPaymentReceiptStateAction, (state) => ({
    ...state,
    downloadPaymentReceipt: undefined,
  })),

);
