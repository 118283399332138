import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '@ptg-shared/shared.module';
import { ControlsModule } from '@ptg-shared/controls/controls.module';
import { DocumentListViewComponent } from './pages/document-list-view/document-list-view.component';
import { IndividualDocumentListComponent } from './pages/individual-document-list/individual-document-list.component';
import { FileEffects } from './store/effects';
import { TagService } from './services';
import { fileReducers } from './store/reducers';
import { OverviewHeaderEntityModule } from '@ptg-member/components/overview-header-entity/overview-header-entity.module';
import { EmployerOverviewHeaderModule } from '@ptg-employer/components/employer-overview-header/employer-overview-header.module';
import { DocumentsService } from './services/documents.service';
import { MODULE_KEY } from './constants';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DocumentViewCurrentFilterComponent } from './components/document-view-current-filter/document-view-current-filter.component';
import { DocumentPreviewComponent } from 'src/app/admin/features/file/components/document-preview/document-preview.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { GeneratedDocumentComponent } from 'src/app/admin/features/file/pages/generated-document/generated-document.component';

const COMPONENTS = [
  DocumentListViewComponent,
  IndividualDocumentListComponent,
  DocumentViewCurrentFilterComponent,
  DocumentPreviewComponent,
  GeneratedDocumentComponent
];
const EFFECTS = [...FileEffects];
const SERVICES = [
  TagService,
  DocumentsService,
];

@NgModule({
  declarations: [COMPONENTS],
  imports: [
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ControlsModule,
    ReactiveFormsModule,
    StoreModule.forFeature(MODULE_KEY, fileReducers),
    EffectsModule.forFeature(EFFECTS),
    OverviewHeaderEntityModule,
    EmployerOverviewHeaderModule,
    NgxExtendedPdfViewerModule,
  ],
  exports: [
    DocumentListViewComponent,
    IndividualDocumentListComponent,
    DocumentPreviewComponent,
    GeneratedDocumentComponent
  ],
  providers: [...SERVICES],
})
export class FileSharedModule {
}
