<div class="property-config-container custom-class">
  <div class="row-button">
    <button
      mat-raised-button
      type="button"
      class="submit-button"
      (click)="onSubmit()"
      [disabled]="!canSubmit"
    >
      Save
    </button>
    <button
      mat-raised-button
      type="button"
      class="cancel-button"
      (click)="onCancel()"
    >
      Cancel
    </button>
  </div>

  <div class="section-label-container">
    <div class="section-label">
      <ptg-textbox
        (valueChange)="onChangeSectionLabel()"
        [controlField]="formData.get('configSectionLabel')"
        [hasLabel]="true"
        [maxLength]="labelMaxLength"
        [placeholder]="'Section Label'"
      ></ptg-textbox>
    </div>
  </div>

  <div class="content-container">
    <div class="box-container add-property-section w-1/2">
      <div class="box">
        <div class="title">Add Output</div>
        <div class="form-container">
          <ptg-select
            (changeOptionValue)="onChangeOutputName()"
            [controlField]="formData.get('parameterName')"
            [isMultipleLineOption]="true"
            [isOptionObj]="true"
            [isSetMaxWidthSelect]="true"
            [listData]="listOutputOptions"
            [placeholder]="'Output Name'"
            panelClass="input-name-dropdown"
          >
          </ptg-select>
          <ptg-textbox
            [controlField]="formData.get('label')"
            [hasLabel]="true"
            [maxLength]="labelMaxLength"
            [placeholder]="'Output Label'"
            customError="duplicatedValue"
          >
          </ptg-textbox>
          <ptg-button
            (clickButton)="addOutput()"
            [buttonName]="'Add Output'"
            classInput="add-button"
          >
            <mat-icon>add</mat-icon>
          </ptg-button>
        </div>
      </div>
    </div>
    <div class="box-container sort-property w-1/2">
      <div class="box">
        <div
          [ngClass]="{ 'no-data': !orderOutputDataTable.length }"
          class="title"
        >
          Order Outputs
        </div>
        <ptg-grid
          #sortPropertyTable
          (change)="onChangeOrderColumns()"
          (rowDrop)="onChangeRow($event, true)"
          (softDelete)="onSoftDeleteConfig($event)"
          [allowDragDrop]="true"
          [columns]="orderColumns"
          [data]="orderOutputDataTable"
          [fitToParent]="true"
          [hideHeader]="true"
          [inlineEditable]="true"
          [isLoading]="isLoading"
          [notFoundMessage]="''"
          [paginable]="false"
          [softDeletable]="true"
        >
          <ng-template [columnName]="'label'" cellContent let-row>
            <ng-container>
              <div class="multiple-line-text">
                <span class="caption truncate">{{ row.label }}</span>
                <span class="description truncate">{{ row.description }}</span>
              </div>
            </ng-container>
          </ng-template>
        </ptg-grid>
      </div>
    </div>
  </div>
</div>
