<div class="retirement-benefit-input-output-list">
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
    (onAdd)="onClickAddNewInputOutput()"
    buttonAddLabel="New Input/Output"
  ></ptg-breadcrumb>

  <div class="content-container flex flex-col" [ngClass]="{'has-banner': !!bannerType}">
    <div *ngIf="bannerType" class="p-6">
      <ptg-banner [(bannerType)]="bannerType" [message]="message"></ptg-banner>
    </div>

    <div class="flex-grow overflow-hidden">
      <ptg-grid
        [data]="listParameter"
        [columns]="columns"
        [isLoading]="isLoading"
        [totalRecords]="lengthPg"
        [pageSize]="pageSize"
        [pageNumber]="pageIndex"
        [fitToParent]="true"
        (pageChange)="onChangePage($event)"
        (sortChange)="onChangeSort($event)"
        notFoundMessage="No Data to Display"
      >
        <ng-template cellContent columnName="type" let-row>
          {{ ParameterType[row?.type] }}
        </ng-template>

        <ng-template cellContent columnName="action" let-row>
          <ptg-button buttonName="Edit" classInput="edit-button" (clickButton)="onClickEdit(row)">
            <mat-icon>edit</mat-icon>
          </ptg-button>
          <ptg-button buttonName="Remove" classInput="edit-button" (clickButton)="onClickRemove(row)">
              <mat-icon>delete_forever</mat-icon>
          </ptg-button>
        </ng-template>
      </ptg-grid>
    </div>
  </div>
</div>

