<ptg-dialog class="upload-documents">
  <ng-template dialogContentHeader>
    <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>
    <ptg-overview-header-entity [injectedMemberId]="memberId" [isShowChangeMemberDetailBtn]="false" [isSubHeader]="true"></ptg-overview-header-entity>
  </ng-template>

  <ng-template dialogBody>
    <div class="upload-documents-container" *ngIf="!isUploading else loadingTemplate">
      <div class="upload-documents-btns flex mb-4">
        <ptg-button
          buttonName="Save"
          class="upload-documents-btn mr-4"
          classInput="primary save-button"
          (clickButton)="onSaveUploadDocument()"
          [isDisabled]="isUploading"
        ></ptg-button>
        <ptg-button
          buttonName="Cancel"
          class="upload-documents-btn"
          classInput="primary cancel-button"
          (clickButton)="onCancelUploadDocument()"
        ></ptg-button>
      </div>

      <ptg-radio-button
        [controlField]="isNewDocumentControl"
        [isObject]="true"
        [listOption]="newDocumentOptions"
      ></ptg-radio-button>

      <div class="flex mt-4" *ngIf="!isNewDocumentControl?.value">
        <ptg-select
          (changeOptionValue)="onChangeDocumentName()"
          [controlField]="documentIdControl"
          [isMultipleLineOption]="true"
          [isOptionObj]="true"
          [isSetMaxWidthSelect]="true"
          [listData]="existDocumentList"
          class="full-width"
          placeholder="Document Name"
        ></ptg-select>

        <ptg-textbox
          [controlField]="existingDocumentTypeControl"
          [hasLabel]="true"
          [isDisabled]="true"
          placeholder="Document Type"
        ></ptg-textbox>

        <ptg-button
          (click)="addExistingDocument()"
          buttonName="Select"
          classInput="mat-raised-button primary"
        ></ptg-button>
      </div>

      <div class="mt-4" *ngIf="isNewDocumentControl?.value">
        <div class="upload-documents-dropdowns flex mt-4">
          <ptg-textbox
            placeholder="Document Name"
            errorAsync="Document Name already exists."
            [ngClass]="{ 'has-error': documentNameControl.touched && documentNameControl.errors }"
            [controlField]="documentNameControl"
            [hasLabel]="true"
            [maxLength]="250"
            maxErrorMessage="Exceed the 250 character limit."
            errorDuplicated="Document Name already exists."
          ></ptg-textbox>

          <ptg-select
            [isOptionObj]="true"
            [isSetMaxWidthSelect]="true"
            [listData]="calculationDocumentTypeOptions"
            [controlField]="documentTypeControl"
            placeholder="Document Type"
            [isMultipleLineOption]="true"
            panelClass="property-name-dropdown"
          ></ptg-select>

        </div>

        <ptg-upload
          #fileDocument
          (onUploadAction)="uploadFile($event)"
          [accept]="acceptFile"
          [checkPattern]="checkPattern"
          [isRequired]="true"
          [typeFile]="supportedFileTypes"
          [fileSelected]="fileSelected"
          [controlField]="$any(fileControl)"
          [noUpload]="false"
          errRequired="Upload Signed Documentation is required."
          class="m-0"
          pageHeader="Signed Documentation"
          [imageFile]="true"
          [ignoreCancel]="true"
          [validateOnUpload]="true"
        ></ptg-upload>
      </div>

      <ptg-panel [title]="documentTableTitle" [noPaddingBody]="true">
        <ng-template panelBody>
          <ptg-grid
            [columns]="documentColumns"
            [data]="filteredDocuments"
            [fitToParent]="true"
            [pageSize]="pageSize"
            [pageNumber]="pageNumber"
            [totalRecords]="documentList.length"
            [notFoundMessage]="notFoundMessage"
            [paginable]="true"
            (sortChange)="onSortDocumentsTable($event)"
            (pageChange)="onChangePage($event)"
          >
            <ng-template cellContent columnName="fileName" let-row>
              <div class="flex file-document-container">
                <span class="file-document-name" target="_blank" (click)="downloadSelectedFile(row)"><span class="truncate">{{ row?.fileName }}</span></span>
              </div>
            </ng-template>
            <ng-template cellContent columnName="action" let-row>
              <ptg-button
                buttonName="Remove"
                classInput="remove-button"
                (clickButton)="removeDocument(row)"
              >
                <mat-icon>delete_forever</mat-icon>
              </ptg-button>
            </ng-template>
          </ptg-grid>
        </ng-template>
      </ptg-panel>
    </div>

    <ng-template #loadingTemplate>
      <ptg-spinner [isLoading]="isUploading"></ptg-spinner>
    </ng-template>
  </ng-template>
</ptg-dialog>
