import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { SUBMODULE_KEY } from '@ptg-shared/constance/permission.const';
import { BaseComponent } from '@ptg-shared/components';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ACTION } from '@ptg-shared/constance/value.const';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import {
  ACTION_COLUMN,
  Align,
  Column,
  ColumnType,
} from '@ptg-shared/controls/grid';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { DisplayedTabName } from '@ptg-shared/layout/constance/layout.const';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { showBanner } from '@ptg-shared/utils/common.util';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';

import { StatusService } from '../../services/status.service';
import { ICON_STATUS_FIELDS } from '../../constance/member-list.const';
import * as fromReducer from '../../../reducers';
import * as StatusActions from '../../store/actions/status.actions';
import { EditStatusComponent } from '../../components/edit-status/edit-status.component';
import { MemberStatus } from '../../types/models';
import * as fromMember from '../../store/reducers';

@Component({
  selector: 'ptg-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent extends BaseComponent {
  bannerType: BannerType = BannerType.Hidden;
  message = '';
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Participant List',
      moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
      url: '/member',
    },
    {
      name: 'Status',
      url: '',
    },
  ];
  columns: Column[] = [
    {
      name: 'name',
      header: {
        title: 'Status',
      },
      type: ColumnType.Icon,
      templateArgs: {
        nameField: ICON_STATUS_FIELDS.statusIconName,
        colorField: ICON_STATUS_FIELDS.statusIconColor,
        labelField: ICON_STATUS_FIELDS.statusIconLabel,
      },
      truncate: true,
      sortable: true,
      width: '35%',
    },
    {
      name: 'totalNumberOfEvent',
      header: {
        title: 'Total Number Of Events',
      },
      truncate: true,
      sortable: true,
      width: '25%',
      align: Align.Center,
    },
    {
      name: 'disabled',
      header: {
        title: 'Disabled',
      },
      sortable: true,
      width: '25%',
      align: Align.Center,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      width: '230px',
    },
  ];
  errorMsg: string = '';
  memberStatussData!: MemberStatus[];
  isLoading: boolean = true;
  sortInfo!: Sort;

  constructor(
    private memberStore: Store<fromMember.MemberState>,
    public dialog: MatDialog,
    private router: Router,
    private statusService: StatusService,
    private store: Store<fromReducer.State>
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store.dispatch(LayoutActions.hiddenSideMenu());
    this.store.dispatch(
      LayoutActions.selectTab({ tab: DisplayedTabName.Participants })
    );
    this.getData();
    this.memberStore
      .select(fromMember.selectMemberStatus)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((memberState) => {
        this.isLoading = memberState.isLoading;
        if (!memberState.success && memberState.error) {
          this.errorMsg = memberState.error.statusText;
        }
        this.memberStatussData = memberState.statusList;
        if (this.memberStatussData.length > 0) {
          this.memberStatussData = this.memberStatussData.map((item: any) => {
            return {
              ...item,
              [ICON_STATUS_FIELDS.statusIconName]: item.iconName,
              [ICON_STATUS_FIELDS.statusIconColor]: item.color,
              [ICON_STATUS_FIELDS.statusIconLabel]: item.name,
            };
          });
        }

        if (memberState.createStatusState) {
          showBanner.call(
            this,
            memberState.createStatusState,
            'Status',
            ACTION.ADD
          );
          this.memberStore.dispatch(StatusActions.clearStatusState());
          this.getData();
        } else if (memberState.removeStatusState) {
          showBanner.call(
            this,
            memberState.removeStatusState,
            'Status',
            ACTION.REMOVE
          );
          this.memberStore.dispatch(StatusActions.clearStatusState());
          this.getData();
        }
      });
  }

  getData() {
    let SortType = 0;
    let SortNames = 'Name';
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      SortNames = capitalizeFirstLetter(
        this.sortInfo.active === 'disabled' ? 'active' : this.sortInfo.active
      );
      SortType = this.sortInfo.direction === 'desc' ? 1 : 0;
    }
    this.memberStore.dispatch(
      StatusActions.getStatus({ query: { SortNames, SortType } })
    );
  }

  sortChange(event: Sort) {
    this.sortInfo = event;
    this.getData();
  }

  addStatus() {
    this.dialog.open(EditStatusComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {},
    });
  }

  onRemoveStatus(statusId: any) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        text: 'Are you sure you want to remove this Status?',
        type: ConfirmType.Delete,
        title: 'Remove Item',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.memberStore.dispatch(StatusActions.removeStatus({ statusId }));
      }
    });
  }

  onShowStatusPopupAttention() {
    this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        title: 'Attention',
        text: 'This Status cannot be removed since it had been linked to a participant or it is being used in a different configuration.',
        type: ConfirmType.Warning,
        cancelButtonTitle: 'Close',
        hideConfirmButton: true,
      },
    });
  }

  onCheckLinkedStatus(event: any) {
    const body = {
      statusId: event?.id,
    };
    this.statusService.validateMemberStatus(body).subscribe((result: any) => {
      if (!result.isValid) {
        this.onShowStatusPopupAttention();
      } else {
        this.onRemoveStatus(event.id);
      }
    });
  }

  viewStatusDetail(event: any) {
    this.router.navigateByUrl('/member/status/' + event.id);
  }
}
