import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { Subject } from 'rxjs';
import { filter, startWith, switchMap, take, tap } from 'rxjs/operators';
import { AccidentState } from '../../store/reducers';
import { Store } from '@ngrx/store';
import { uploadAccidentDocumentsAction } from '../../store/actions';
import { AccidentDocumentTypeDisplay } from '../../constants';
import { AccidentParticipantsService } from '../../services/accident-participants.service';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { UploadComponent } from '@ptg-shared/controls/upload/upload.component';

@Component({
  selector: 'ptg-upload-accident-document',
  templateUrl: './upload-accident-document.component.html',
  styleUrls: ['./upload-accident-document.component.scss']
})
export class UploadAccidentDocumentComponent implements OnInit {
  @ViewChild('fileDocument') private fileDocument!: UploadComponent;
  readonly AccidentDocumentTypeDisplay = AccidentDocumentTypeDisplay
  formGroup!: FormGroup;
  formSubmit$ = new Subject<boolean>();
  listDocumentType = [
    {
      displayValue: 'Denial Letter', value: AccidentDocumentTypeDisplay['Denial Letter']
    },
    {
      displayValue: 'Report of Accident', value: AccidentDocumentTypeDisplay['Report Of Accident']
    },
    {
      displayValue: 'Medical Records', value: AccidentDocumentTypeDisplay['Medical Records']
    },
    {
      displayValue: 'Invoice Voucher', value: AccidentDocumentTypeDisplay['Invoice Voucher']
    },
    {
      displayValue: 'Invoice Voucher - Paid', value: AccidentDocumentTypeDisplay['Invoice Voucher Paid']
    },
    {
      displayValue: 'Subrogation Letter', value: AccidentDocumentTypeDisplay['Subrogation Letter']
    },
    {
      displayValue: 'Other', value: AccidentDocumentTypeDisplay['Other']
    },
  ];
  attachment?: File;
  acceptDocument = 'application/pdf';
  typeFileDocument = 'pdf file format';
  checkPatternDocument = new RegExp(/^[\x00-\x7F]+\.(pdf)$/, 'i');
  errDuplicated?: string;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      memberId: string,
      accidentId: string
    },
    public dialogRef: MatDialogRef<UploadAccidentDocumentComponent>,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    private store: Store<AccidentState>,
    private accidentParticipantsService: AccidentParticipantsService
  ) { }

  ngOnInit(): void {
    this.initFormGroup();
    this.formSubmit$
      .pipe(
        tap(() => {
          this.formGroup.markAllAsTouched();
          this.fileDocument.firstTouch = false;
        }),
        switchMap(() =>
          this.formGroup.statusChanges.pipe(
            startWith(this.formGroup.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter((status) => status === AbstractControlStatus.VALID && !this.fileDocument.hasError)
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  initFormGroup() {
    this.formGroup = new FormGroup({
      documentName: new FormControl('', {
        validators: [Validators.required],
        asyncValidators: checkApiValidator(
          this.accidentParticipantsService.checkExistDocumentName,
          'documentName',
          undefined,
          { params: { memberId: this.data.memberId, accidentId: this.data.accidentId } }
        ),
      }),
      type: new FormControl('', Validators.required),
      attachment: new FormControl(null, [Validators.required]),
    });
  }

  get documentTypeControl(): FormControl {
    return this.formGroup.get('type') as FormControl;
  }

  get documentNameControl(): FormControl {
    return this.formGroup.get('documentName') as FormControl;
  }

  get fileControl(): FormControl {
    return this.formGroup.get('attachment') as FormControl;
  }

  uploadFile(selectedFile?: File) {
   this.setSelectedFile(selectedFile);
  }

  setSelectedFile(event: any) {
    this.fileDocument.firstTouch = false;
    if (event) {
      this.attachment = event;
    }
    this.fileControl?.setValue(event.name);
    this.fileControl?.updateValueAndValidity();
  }

  removeFile(event: any) {
    this.attachment = event;
  }

  onSubmit() {
    this.accidentParticipantsService.accidentDocumentFile = this.attachment;
    const { documentName, type } = this.formGroup.value;
    this.store.dispatch(uploadAccidentDocumentsAction({
      request: { documentName, type },
      memberId: this.data.memberId,
      accidentId: this.data.accidentId
    }));
    this.dialogRef.close(true);
  }

  onCancel() {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }

}
