import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, startWith, switchMap, take, tap } from 'rxjs/operators';

import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { BaseComponent } from '@ptg-shared/components';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';

import { EntityService } from '../../services';
import { UpdateListConfigRequest } from '../../services/models';
import { EntityState } from '../../store/reducers';
import { updateListConfig } from '../../store/actions';

@Component({
  selector: 'ptg-edit-list-item-configuration',
  templateUrl: './edit-list-item-configuration.component.html',
  styleUrls: ['./edit-list-item-configuration.component.scss'],
})
export class EditListItemConfigurationComponent extends BaseComponent {
  formSubmit$ = new Subject<boolean>();
  listDetail: any;

  editForm!: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      clientId: string;
      list: any;
      entityId: string;
    },
    public fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditListItemConfigurationComponent>,
    public entityStore: Store<EntityState>,
    public entityService: EntityService,
    public switchConfirmPopupService: SwitchConfirmPopupService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.listDetail = this.data.list;
    this.emitFormSubmit();
    this.editForm = this.fb.group({
      name: this.fb.control(this.listDetail.name, {
        validators: [Validators.required],
        asyncValidators: checkApiValidator(
          this.entityService.checkListExists,
          'name',
          this.listDetail.name,
          {
            params: {
              entityComponentId: this.data.list.id,
              entityId: this.data.entityId,
            },
          }
        ),
      }),
      importLabel: this.fb.control(this.listDetail.importKey, {
        validators: [Validators.required],
        asyncValidators: checkApiValidator(
          this.entityService.checkListExists,
          'importKey',
          this.listDetail.importKey,
          {
            params: {
              entityComponentId: this.data.list.id,
              entityId: this.data?.entityId,
            },
          }
        ),
      }),
      bulkUpload: this.fb.control(this.listDetail.importKey),
      editByCreatorOnly: this.fb.control(this.listDetail.editByCreatorOnly || false),
      removeByCreatorOnly: this.fb.control(this.listDetail.removeByCreatorOnly || false),
    });
  }

  emitFormSubmit(): void {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
          this.editForm.get('name')?.updateValueAndValidity();
          if (this.editForm.get('bulkUpload')?.value) {
            this.editForm.get('importLabel')?.updateValueAndValidity();
          } else {
            this.editForm.get('importLabel')?.setErrors(null);
          }
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter((status) => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  onSubmit(): void {
    this.editForm.markAllAsTouched();
    if (!this.editForm.valid) {
      return;
    }
    const body: UpdateListConfigRequest = Object.assign({
      name: this.editForm.value.name,
      importKey: this.editForm.value.bulkUpload
        ? this.editForm.value.importLabel
        : null,
      entityId: this.data?.entityId,
      entityComponentId: this.data.list.id,
      editBy: this.data.list.id,
      editByCreatorOnly: this.editForm.value.editByCreatorOnly,
      removeByCreatorOnly: this.editForm.value.removeByCreatorOnly,
    });
    this.entityStore.dispatch(
      updateListConfig({
        request: body,
      })
    );
    this.dialogRef.close();
  }

  onCancel(): void {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }

  changeToggleBulkUpdate(): void {
    if (!this.editForm.value.bulkUpload) {
      this.editForm.get('importLabel')?.setValue('');
    } else {
      this.editForm.get('importLabel')?.updateValueAndValidity();
    }
  }
}
