import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import * as fromReducer from '@ptg-reducers/index';

import {
  MemberListQuery,
  MembersBenefitEstablish,
  MembersBenefitList,
  MembersBenefitType,
} from '../types/models';

@Injectable({
  providedIn: 'root',
})
export class MemberBenefitService {
  activeFund: any;
  clientId: string = '';

  constructor(
    private httpClient: HttpClient,
    private store: Store<fromReducer.State>
  ) {
    this.store
      .pipe(select(fromReducer.selectCurrentFundState))
      .subscribe((el) => (this.activeFund = el));
  }

  memberBenefitList(
    query: MemberListQuery,
    clientId: string
  ): Observable<MembersBenefitList> {
    this.clientId = clientId;
    let params = new HttpParams();
    params = params.append('ClientId', clientId);
    if (query.totalPerPage) {
      params = params.append('PageSize', query.totalPerPage);
    }
    params = params.append('PageIndex', query.pageIndex);
    if (query.sortField) {
      params = params.append(
        'SortNames',
        query.sortField === 'providerTypeString'
          ? 'ProviderType'
          : query.sortField[0].toUpperCase() + query.sortField.substring(1)
      );
      params = params.append(
        'SortNames',
        query.sortField === 'paymentTypeString'
          ? 'PaymentType'
          : query.sortField[0].toUpperCase() + query.sortField.substring(1)
      );
      params = params.append('SortType', query.sortType ? query.sortType : 0);
    }
    return this.httpClient.get<MembersBenefitList>(
      `${environment.apiUrl}/Members/Benefit`,
      { params }
    );
  }

  getBenefitType(memberId: any): Observable<MembersBenefitType> {
    return this.httpClient.get<MembersBenefitType>(
      `${environment.apiUrl}/Members/${memberId}/BenefitTypes`
    );
  }

  establishMemberBenefit(body: MembersBenefitEstablish, memberId: any) {
    return this.httpClient.post(
      `${environment.apiUrl}/Members/${memberId}/EstablishBenefit`,
      body
    );
  }
}
