import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ELEMENT_TYPE } from '../../constance/value.const';
import { getElementId } from '../../utils/string.util';

@Component({
  selector: 'ptg-title-button',
  templateUrl: './title-button.component.html',
  styleUrls: ['./title-button.component.scss']
})
export class TitleButtonComponent implements OnInit, OnChanges {

  @Input() name?: string;
  @Input() buttonName?: string;
  @Output() addNewEvent = new EventEmitter();

  buttonId: string = '';

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.buttonName) {
      this.buttonId = getElementId(ELEMENT_TYPE.BUTTON, this.buttonName || '');
    }
  }

  ngOnInit(): void {
  }

  addNew() {
    this.addNewEvent.emit();
  }
}
