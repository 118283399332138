import { Component, OnDestroy } from '@angular/core';
import { AsyncValidatorFn, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@ptg-shared/components';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, filter, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import {
  CalculationState,
  clearGetStepConfigurationStateAction,
  clearSetStepConfigurationStateAction,
  getBenefitTypesAction,
  getBenefitTypesSelector,
  getStepConfigurationAction,
  getStepConfigurationSelector,
  setStepConfigurationAction,
  setStepConfigurationSelector,
} from '../../store';
import { select, Store } from '@ngrx/store';
import { Option } from '@ptg-shared/controls/select/select.component';
import { deepClone, showBanner } from '@ptg-shared/utils/common.util';
import { StepConfigurationService } from '../../services';
import {
  CalculationStep,
  CalculationStepOption,
  CalculationStepProperty,
  GetStepConfigurationResponse,
  SetStepConfigurationRequest,
} from '../../services/models';
import { BenefitRequestType, CalculationStepType, CalculationType } from '../../types/enums';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { CALCULATION_OPTION } from '../../constants';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ACTION, CANCEL_CONFIRM_MESSAGE, STATE } from '@ptg-shared/constance';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { DateTime } from 'luxon';

@Component({
  selector: 'ptg-edit-step-configuration',
  templateUrl: './edit-step-configuration.component.html',
  styleUrls: ['./edit-step-configuration.component.scss'],
})
export class EditStepConfigurationComponent extends BaseComponent implements OnDestroy {
  CalculationStepType = CalculationStepType;
  listBreadcrumbs!: Breadcrumb[];
  memberId: string = '';
  stepConfigurationId: string = '';
  bannerType: BannerType = BannerType.Hidden;
  message = '';
  editForm!: FormGroup;
  formSubmit$ = new Subject<boolean>();
  isLoading?: boolean = false;
  canSubmit: boolean = false;
  benefitOptions: Option[] = [];
  stepConfigurationDetail?: GetStepConfigurationResponse;
  cancelTime = DateTime.now();
  selectedTabIndex: number = 0;

  constructor(
    public calculationStore: Store<CalculationState>,
    public route: ActivatedRoute,
    private fb: FormBuilder,
    public router: Router,
    public dialog: MatDialog,
    private stepConfigurationService: StepConfigurationService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initFormGroup();
    this.getRouteData();
    this.getListBenefitType();
    this.getStepConfigurationState();
    this.selectSetStepConfigurationState();
    this.submitForm();
  }

  get benefitOptionControl(): FormControl {
    return this.editForm?.get('benefitEntityId') as FormControl;
  }

  getBreadcrumbs() {
    return [
      {
        name: 'Retirement Benefit Overview',
        url: `/member/benefit-overview/${CalculationType.RetirementBenefit}/${this.memberId}`,
      },
      {
        name: 'Step Configuration',
        url: `/member/calculation/step-configuration/${this.memberId}`,
      },
      {
        name: 'Edit Step Configuration',
        url: '',
      },
    ];
  }

  getRouteData() {
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.memberId = params.memberId;
      this.stepConfigurationId = params.stepConfigurationId;
      this.listBreadcrumbs = this.getBreadcrumbs();
      this.getData();
    });
  }

  selectSetStepConfigurationState() {
    this.calculationStore
      .pipe(select(setStepConfigurationSelector), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state) {
          const title = 'Step Configuration';

          this.calculationStore.dispatch(clearSetStepConfigurationStateAction());
          if (state.success) {
            this.canSubmit = false;
            showBanner.call(this, STATE.SUCCESS, title, ACTION.EDIT);
            this.getData();
          }
          //case can't edit step configuration
          if (!state?.success) {
            showBanner.call(this, STATE.FAIL, title, ACTION.EDIT);
          }
        }
      });
  }

  getListBenefitType() {
    this.calculationStore.dispatch(getBenefitTypesAction({ request: { type: BenefitRequestType.UploadCalculationFile } }));
  }

  getData() {
    this.calculationStore.dispatch(
      getStepConfigurationAction({ stepConfigurationId: this.stepConfigurationId, memberId: this.memberId }),
    );
  }

  getStepConfigurationState() {
    combineLatest([
      this.calculationStore.pipe(select(getBenefitTypesSelector)),
      this.calculationStore.pipe(select(getStepConfigurationSelector)),
    ])
      .pipe(
        tap(([benefitOptionState, stepConfigurationState]) => {
          this.isLoading = benefitOptionState?.isLoading || stepConfigurationState?.isLoading;
        }),
        filter(
          ([benefitOptionState, stepConfigurationState]) =>
            !benefitOptionState?.isLoading && !stepConfigurationState?.isLoading,
        ),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(([benefitOptionState, stepConfigurationState]) => {
        this.benefitOptions = deepClone(benefitOptionState?.payload ?? [])?.map((item) => {
          return {
            value: item.id,
            displayValue: item.name,
          };
        });
        this.stepConfigurationDetail = stepConfigurationState?.payload;
        this.initFormGroup();
        if (this.stepConfigurationDetail) {
          this.stepConfigurationDetail.calculationSteps.forEach((step) => {
            if (!this.editForm.get(step.id)) {
              this.editForm.setControl(step.id, new FormGroup({}));
            }
          });
        }
      });
  }

  initFormGroup() {
    this.editForm = this.fb.group({
      benefitEntityId: this.fb.control(this.stepConfigurationDetail?.stepConfiguration?.benefitEntityId, {asyncValidators: this.validateBenefitTypeExist()}),
    });
  }

  validateBenefitTypeExist(): AsyncValidatorFn {
    return this.stepConfigurationService.validateStepConfigExists(
      this.benefitOptions,
      this.stepConfigurationId,
      false
    );
  }

  onChangeBenefitOption() {
    this.canSubmit = true;
  }

  submitForm() {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
          this.selectInvalidTab();
        }),
        debounceTime(500),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1),
          ),
        ),
        filter((status) => status === AbstractControlStatus.VALID),
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  selectInvalidTab() {
    if (
      this.editForm.valid ||
      this.benefitOptionControl.invalid ||
      !this.stepConfigurationDetail?.calculationSteps ||
      this.stepConfigurationDetail.calculationSteps.length < 2
    ) {
      return;
    }
    const firstStepInvalid = this.stepConfigurationDetail.calculationSteps.find(
      (step) => this.editForm.get(step.id)?.invalid,
    );
    if (firstStepInvalid) {
      this.selectedTabIndex = this.stepConfigurationDetail.calculationSteps.indexOf(firstStepInvalid);
    }
  }

  onSubmit() {
    const request = this.getFormData();
    this.calculationStore.dispatch(setStepConfigurationAction({ request }));
  }

  getFormData() {
    const calculationSteps = this.stepConfigurationDetail?.calculationSteps?.map((step) => {
      const stepGroupData = this.editForm.get(step.id)?.value;
      const calculationStepOptions = Object.keys(stepGroupData)
        .filter((key) => key !== CALCULATION_OPTION.CalculationStepProperties)
        .map((key) => {
          return {
            key: key,
            value: stepGroupData[key]?.toString(),
          } as CalculationStepOption;
        });
      let calculationStepProperties: CalculationStepProperty[] = [];
      if (step.type === CalculationStepType.NonList) {
        calculationStepProperties =
          stepGroupData?.calculationStepProperties?.map((item: any) => {
            return {
              ...item,
              order: item.orderColumn,
              orderColumn: item.orderColumn || -1,
            };
          }) ?? [];
      }
      return {
        ...step,
        calculationStepOptions,
        calculationStepProperties,
      } as CalculationStep;
    });
    return {
      stepConfiguration: {
        ...this.stepConfigurationDetail?.stepConfiguration,
        benefitEntityId: this.benefitOptionControl.value,
      },
      calculationSteps,
    } as SetStepConfigurationRequest;
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: CANCEL_CONFIRM_MESSAGE,
        type: ConfirmType.Cancel,
        title: 'Cancel Action',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.canSubmit = false;
        this.editForm?.markAsUntouched();
        this.cancelTime = DateTime.now();
      }
    });
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.calculationStore.dispatch(clearGetStepConfigurationStateAction());
  }
}
