import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import { AlternatePayee, QDROBenefitInformation, GetQDROLabelNameResponse, QDROValidationBeforeInitialization } from '../../services/models';
import {
  clearGetAlternatePayeesStateAction,
  clearGetQDROBenefitInformationStateAction,
  clearGetQDROLabelNameStateAction,
  clearGetQDROValidationBeforeInitializationStateAction,
  getAlternatePayeesAction,
  getAlternatePayeesFailureAction,
  getAlternatePayeesSuccessAction,
  getQDROBenefitInformationAction,
  getQDROBenefitInformationFailureAction,
  getQDROBenefitInformationSuccessAction,
  getQDROLabelNameAction,
  getQDROLabelNameFailureAction,
  getQDROLabelNameSuccessAction,
  getQDROValidationBeforeInitializationAction,
  getQDROValidationBeforeInitializationFailureAction,
  getQDROValidationBeforeInitializationSuccessAction,
} from '../actions';

export interface CalculationQDROState {
  getQDROLabelName?: BaseActionState<GetQDROLabelNameResponse>;
  getAlternatePayees?: BaseActionState<AlternatePayee[]>;
  getQDROValidationBeforeInitialization?: BaseActionState<QDROValidationBeforeInitialization>;  
  getGetQDROBenefitInformation?: BaseActionState<QDROBenefitInformation[]>;
}

const initialState: CalculationQDROState = {};

export const calculationQDROReducer = createReducer(
  initialState,
  on(getQDROLabelNameAction, (state) => ({
    ...state,
    getQDROLabelName: {
      isLoading: true,
    },
  })),
  on(getQDROLabelNameSuccessAction, (state, { response }) => ({
    ...state,
    getQDROLabelName: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getQDROLabelNameFailureAction, (state, { error }) => ({
    ...state,
    getQDROLabelName: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetQDROLabelNameStateAction, (state) => ({
    ...state,
    getQDROLabelName: undefined,
  })),
  on(getAlternatePayeesAction, (state) => ({
    ...state,
    getAlternatePayees: {
      isLoading: true,
    },
  })),
  on(getAlternatePayeesSuccessAction, (state, { response }) => ({
    ...state,
    getAlternatePayees: {
      isLoading: false,
      success: true,
      payload: response.alternatePayee,
    },
  })),
  on(getAlternatePayeesFailureAction, (state, { error }) => ({
    ...state,
    getAlternatePayees: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetAlternatePayeesStateAction, (state) => ({
    ...state,
    getAlternatePayees: undefined,
  })),
  on(getQDROValidationBeforeInitializationAction, (state) => ({
    ...state,
    getQDROValidationBeforeInitialization: {
      isLoading: true,
    },
  })),
  on(getQDROValidationBeforeInitializationSuccessAction, (state, { response }) => ({
    ...state,
    getQDROValidationBeforeInitialization: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getQDROValidationBeforeInitializationFailureAction, (state, { error }) => ({
    ...state,
    getQDROValidationBeforeInitialization: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetQDROValidationBeforeInitializationStateAction, (state) => ({
    ...state,
    getQDROValidationBeforeInitialization: undefined,
  })),
  on(getQDROBenefitInformationAction, (state) => ({
    ...state,
    getGetQDROBenefitInformation: {
      isLoading: true,
    },
  })),
  on(getQDROBenefitInformationSuccessAction, (state, { response }) => ({
    ...state,
    getGetQDROBenefitInformation: {
      isLoading: false,
      success: true,
      payload: response.qdroBenefitInformation,
      total: response.total,
    },
  })),
  on(getQDROBenefitInformationFailureAction, (state, { error }) => ({
    ...state,
    getGetQDROBenefitInformation: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetQDROBenefitInformationStateAction, (state) => ({
    ...state,
    getGetQDROBenefitInformation: undefined,
  }))

);
