import { createAction, props } from '@ngrx/store';

import {
  LumpSumPaymentHistoryList,
  LumpSumPaymentHistoryQuery,
  LumpSumPaymentInformation,
} from '../../services/models';

export const getLumpSumPaymentAction = createAction(
  '[GetLumpSumPayment/API] Send Request'
);
export const getLumpSumPaymentSuccessAction = createAction(
  '[GetLumpSumPayment/API] Success',
  props<{ lumpSumPaymentList: LumpSumPaymentInformation[] }>()
);
export const getLumpSumPaymentFailureAction = createAction(
  '[GetLumpSumPayment/API] Failure',
  props<{ error?: any }>()
);

export const getLumpSumPaymentHistoryAction = createAction(
  '[GetLumpSumPaymentHistory/API] Send Request',
  props<{ query: LumpSumPaymentHistoryQuery }>()
);
export const getLumpSumPaymentHistorySuccessAction = createAction(
  '[GetLumpSumPaymentHistory/API] Success',
  props<{ lumpSumPaymentHistoryList: LumpSumPaymentHistoryList }>()
);
export const getLumpSumPaymentHistoryFailureAction = createAction(
  '[GetLumpSumPaymentHistory/API] Failure',
  props<{ errorMsg: string }>()
);
