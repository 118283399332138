import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import {
  clearCreateRetirementBenefitUploadDocumentsStateAction,
  clearEditRetirementBenefitDocumentStateAction,
  createRetirementBenefitUploadDocumentsAction,
  createRetirementBenefitUploadDocumentsFailureAction,
  createRetirementBenefitUploadDocumentsSuccessAction,
  editRetirementBenefitDocumentAction,
  editRetirementBenefitDocumentFailureAction,
  editRetirementBenefitDocumentSuccessAction,
} from '../actions';
import { ACTION, ActionState, STATE } from '@ptg-shared/constance';

export interface RetirementBenefitUploadDocumentState {
  createRetirementBenefitUploadDocuments?: BaseActionState;
  editRetirementBenefitDocumentState?: {
    state: ActionState,
     errorMsg?: string
  };
}

const initialState: RetirementBenefitUploadDocumentState = {};

// Upload Document
export const retirementBenefitUploadDocumentReducer = createReducer(
  initialState,
  on(createRetirementBenefitUploadDocumentsAction, (state) => ({
    ...state,
    createRetirementBenefitUploadDocuments: {
      isLoading: true,
    },
  })),
  on(createRetirementBenefitUploadDocumentsSuccessAction, (state) => ({
    ...state,
    createRetirementBenefitUploadDocuments: {
      isLoading: false,
      success: true,
    },
  })),
  on(
    createRetirementBenefitUploadDocumentsFailureAction,
    (state, { error }) => ({
      ...state,
      createRetirementBenefitUploadDocuments: {
        isLoading: false,
        success: false,
        error: error,
      },
    })
  ),
  on(clearCreateRetirementBenefitUploadDocumentsStateAction, (state) => ({
    ...state,
    createRetirementBenefitUploadDocuments: undefined,
  })),

  // Edit Document
  on(editRetirementBenefitDocumentAction, (state) => ({
    ...state,
    isLoading: false,
    editRetirementBenefitDocumentState: undefined,
  })),
  on(editRetirementBenefitDocumentSuccessAction, (state, { }) => ({
    ...state,
    isLoading: false,
    editRetirementBenefitDocumentState: {
      state: {
        action: ACTION.EDIT,
        state: STATE.SUCCESS,
      }
    },
  })),
  on(editRetirementBenefitDocumentFailureAction, (state, { errorMsg }) => ({
    ...state,
    editRetirementBenefitDocumentState: {
      state: {
        action: ACTION.EDIT,
        state: STATE.FAIL,
      },
      errorMsg: errorMsg,
    },
  })),
  on(clearEditRetirementBenefitDocumentStateAction, (state) => ({
    ...state,
    isLoading: false,
    editRetirementBenefitDocumentState: undefined,
  })),
);
