import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { NonListConfigurationActions } from '../../store/actions';
import {
  GetNonListPropertiesResponse,
  GetNonListItemConfigsResponse,
} from '../../types/models';
import { NonListConfigurationService } from '../../services/non-list-configuration.service';

@Injectable()
export class NonListConfigurationEffects {
  constructor(
    private actions$: Actions,
    private nonListConfigurationService: NonListConfigurationService
  ) {}

  getNonListProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NonListConfigurationActions.getNonListProperties),
      switchMap(({ sectionKey }) => {
        return this.nonListConfigurationService
          .getNonListProperties(sectionKey)
          .pipe(
            map((response: GetNonListPropertiesResponse) => {
              return NonListConfigurationActions.getNonListPropertiesSuccess({
                response,
              });
            }),
            catchError((error) => {
              return of(
                NonListConfigurationActions.getNonListPropertiesFailure({
                  error,
                })
              );
            })
          );
      })
    )
  );

  getNonListItemConfigs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NonListConfigurationActions.getNonListItemConfigs),
      switchMap(({ navigationItemId }) => {
        return this.nonListConfigurationService
          .getNonListItemConfigs(navigationItemId)
          .pipe(
            map((response: GetNonListItemConfigsResponse) => {
              return NonListConfigurationActions.getNonListItemConfigsSuccess({
                response,
              });
            }),
            catchError((error) => {
              return of(
                NonListConfigurationActions.getNonListItemConfigsFailure({
                  error,
                })
              );
            })
          );
      })
    )
  );

  saveNonListItemConfigs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NonListConfigurationActions.saveNonListItemConfigs),
      switchMap(({ request }) => {
        return this.nonListConfigurationService
          .saveNonListItemConfigs(request)
          .pipe(
            map(() => {
              return NonListConfigurationActions.saveNonListItemConfigsSuccess();
            }),
            catchError((error) => {
              return of(
                NonListConfigurationActions.saveNonListItemConfigsFailure({
                  error,
                })
              );
            })
          );
      })
    )
  );
}
