import { FileSnapshot } from "./file-snapshot";

export interface DocumentSnapshot extends FileSnapshot {
    documentType: DocumentType
}

export enum DocumentType {
    Unspecified = "Unspecified",
    AssetGrowth = "AssetGrowth",
    Benefit = "Benefit",
    DROP = "Drop",
    Form = "Form",
    SPD = "Spd"
}