import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  CheckExistCheckRetirementDocumentCanRemoveRequest,
  CheckExistCheckRetirementDocumentCanRemoveResponse,
} from './models';
import { SHOW_LOADING } from '@ptg-interceptor/httpconfig.interceptor';

@Injectable()
export class CheckRetirementDocumentCanRemoveService {
  constructor(private httpClient: HttpClient) {}

  checkExistCheckRetirementDocumentCanRemove(
    request: CheckExistCheckRetirementDocumentCanRemoveRequest,
  ): Observable<CheckExistCheckRetirementDocumentCanRemoveResponse> {
    const context = new HttpContext().set(SHOW_LOADING, true);
    const { calculationBenefitDocumentId, calculationBenefitId, calculationType, calculationRecordId } = request;
    let params = new HttpParams();
    if (calculationBenefitId) params = params.append('calculationBenefitId', calculationBenefitId);
    if (calculationType) params = params.append('calculationType', calculationType);
    if (calculationRecordId) params = params.append('calculationRecordId', calculationRecordId);

    return this.httpClient.get<CheckExistCheckRetirementDocumentCanRemoveResponse>(
      `${environment.apiUrl}/Calculations/calculation-options/calculation-benefit-documents/${calculationBenefitDocumentId}/status-verification`,
      { params, context },
    );
  }
}
