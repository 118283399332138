<div class="list-config-container custom-class">
	<div class="content-container">
		<div class="box-container">
			<div class="box">
				<div class="form-container">
					<ptg-toggle-button
						[controlField]="byPassControl"
						label="Bypass"
					></ptg-toggle-button>
					<ptg-textbox
            *ngIf="stepLabelControl"
						[controlField]="stepLabelControl"
						[hasLabel]="true"
						[maxLength]="150"
						placeholder="Step Label"
					></ptg-textbox>
					<ptg-select
            *ngIf="cardControl"
						[controlField]="cardControl"
						[isOptionObj]="true"
						[isSetMaxWidthSelect]="true"
						[isMultipleLineOption]="true"
						[listData]="cardOptions"
            (changeOptionValue)="changeCard()"
						placeholder="Card"
					></ptg-select>
				</div>
			</div>
		</div>
	</div>
</div>
