import { Pipe, PipeTransform } from '@angular/core';
import {
  isEmpty,
  setFractionalLength,
  toFixedTrunc,
} from '../utils/string.util';
import { isNumeric } from '@ptg-shared/utils/common.util';

@Pipe({
  name: 'numberDecimal',
})
export class NumberDecimalPipe implements PipeTransform {
  transform(
    value: number,
    option?: { decimal: number | string; isCheckCurrentDecimal?: boolean }
  ): string {
    if (isEmpty(value)) return '';

    if (option) {
      const decimalConfig = isNumeric(option.decimal) ? Number(option.decimal) : 0;
      const decimal = option.isCheckCurrentDecimal
        ? setFractionalLength(value, decimalConfig)
        : decimalConfig;
      return toFixedTrunc(value, decimal);
    }

    // Set default locale to 'en' (English)
    return !!(value % 1)
      ? BigInt(value.toString().split('.')[0]).toLocaleString('en') + '.' + value.toString().split('.')[1]
      : BigInt(value).toLocaleString('en');
  }
}
