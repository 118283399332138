import { createAction, props } from '@ngrx/store';
import { 
  AddCommentBody,
  DetailException,
  DetailWageFileException,
  GetDetailWageFileExceptionQuery, 
  CreateWageRecordSection,
  GetDataListQuery,
  GetWageSectionsListQuery,
  ParticipantContribution,
  ParticipantWageSectionDetail,
  ParticipantWageSection,
  ParticipantWageSectionsList,
  SetWageRecordDetailQuery,
  RemoveWageRecordSection,
  WageFileValidationAuditTrail,
  WageFileValidationReport,
  WageProcessingDataList,
  WageRecordListConfiguration,
  RemoveWageRecordSectionField,
  CreateWageRecordSectionFieldQuery,
  SetWageRecordSectionFieldQuery,
  AvailableRecordSectionFields,
  ReOrderWageRecordSectionFieldBody,
  CorrectedExceptionsBody,
  WageRecordDetailException,
  WageRecordSectionData,
  ExceptionComment,
} from '../models/employer-wage-processing.model';
import { FileTemplateType } from '../models/employer.model';

export const GetEmployerWageProcessingListRequest = '[GetEmployerWageProcessingList/API] Send Request';
export const GetEmployerWageProcessingListSuccess = '[GetEmployerWageProcessingList/API] Success';
export const GetEmployerWageProcessingListFailure = '[GetEmployerWageProcessingList/API] Failure';

export const GetWageFileValidationReportRequest = '[GetWageFileValidationReport/API] Send Request';
export const GetWageFileValidationReportSuccess = '[GetWageFileValidationReport/API] Success';
export const GetWageFileValidationReportFailure = '[GetWageFileValidationReport/API] Failure';

export const GetWageFileValidationAuditTrailsRequest = '[GetWageFileValidationAuditTrails/API] Send Request';
export const GetWageFileValidationAuditTrailsSuccess = '[GetWageFileValidationAuditTrails/API] Success';
export const GetWageFileValidationAuditTrailsFailure = '[GetWageFileValidationAuditTrails/API] Failure';

export const GetWageFileParticipantContributionRequest = '[GetWageFileParticipantContribution/API] Send Request';
export const GetWageFileParticipantContributionSuccess = '[GetWageFileParticipantContribution/API] Success';
export const GetWageFileParticipantContributionFailure = '[GetWageFileParticipantContribution/API] Failure';

export const GetParticipantWageSectionsRequest = '[GetParticipantWageSections/API] Send Request';
export const GetParticipantWageSectionsSuccess = '[GetParticipantWageSections/API] Success';
export const GetParticipantWageSectionsFailure = '[GetParticipantWageSections/API] Failure';

export const GetParticipantWageSectionDetailRequest = '[GetParticipantWageSectionDetail/API] Send Request';
export const GetParticipantWageSectionDetailSuccess = '[GetParticipantWageSectionDetail/API] Success';
export const GetParticipantWageSectionDetailFailure = '[GetParticipantWageSectionDetail/API] Failure';

export const SetWageRecordSectionDetailRequest = '[SetParticipantWageSectionDetail/API] Send Request';
export const SetWageRecordSectionDetailSuccess = '[SetParticipantWageSectionDetail/API] Success';
export const SetWageRecordSectionDetailFailure = '[SetParticipantWageSectionDetail/API] Failure';

export const CreateParticipantWageSectionsRequest = '[CreateParticipantWageSections/API] Send Request';
export const CreateParticipantWageSectionsSuccess = '[CreateParticipantWageSections/API] Success';
export const CreateParticipantWageSectionsFailure = '[CreateParticipantWageSections/API] Failure';

export const RemoveParticipantWageSectionRequest = '[RemoveParticipantWageSection/API] Send Request';
export const RemoveParticipantWageSectionSuccess = '[RemoveParticipantWageSection/API] Success';
export const RemoveParticipantWageSectionFailure = '[RemoveParticipantWageSection/API] Failure';

export const UploadWageFileRequest = '[UploadWageFileRequest/API] Send Request';
export const UploadWageFileSuccess = '[UploadWageFileRequest/API] Success';
export const UploadWageFileFailure = '[UploadWageFileRequest/API] Failure';

export const ClearUploadWageFileState = '[ClearUploadWageFileState] Clear';

export const GetEmployerWageRecordListConfigurationRequest = '[GetEmployerWageRecordListConfiguration/API] Send Request';
export const GetEmployerWageRecordListConfigurationSuccess = '[GetEmployerWageRecordListConfiguration/API] Success';
export const GetEmployerWageRecordListConfigurationFailure = '[GetEmployerWageRecordListConfiguration/API] Failure';

export const SetEmployerWageRecordListConfigurationRequest = '[SetEmployerWageRecordListConfiguration/API] Send Request';
export const SetEmployerWageRecordListConfigurationSuccess = '[SetEmployerWageRecordListConfiguration/API] Success';
export const SetEmployerWageRecordListConfigurationFailure = '[SetEmployerWageRecordListConfiguration/API] Failure';

export const ClearEmployerWageRecordConfigurationState = '[ClearEmployerWageRecordConfigurationState] Clear';
export const ClearEmployerWageRecordSectionState = '[ClearEmployerWageRecordSectionState] Clear';
export const GetDetailWageFileExceptionsRequest = '[GetDetailWageFileExceptions/API] Send Request';
export const GetDetailWageFileExceptionsSuccess = '[GetDetailWageFileExceptions/API] Success';
export const GetDetailWageFileExceptionsFailure = '[GetDetailWageFileExceptions/API] Failure';
export const GetDetailExceptionRequest = '[GetDetailException/API] Send Request';
export const GetDetailExceptionSuccess = '[GetDetailException/API] Success';
export const GetDetailExceptionFailure = '[GetDetailException/API] Failure';
export const AddCommentExceptionRequest = '[AddCommentException/API] Send Request';
export const AddCommentExceptionSuccess = '[AddCommentException/API] Success';
export const AddCommentExceptionFailure = '[AddCommentException/API] Failure';
export const RemoveCommentExceptionRequest = '[RemoveCommentException/API] Send Request';
export const RemoveCommentExceptionSuccess = '[RemoveCommentException/API] Success';
export const RemoveCommentExceptionFailure = '[RemoveCommentException/API] Failure';
export const ClearWageRecordSectionFieldState = '[ClearWageRecordSectionFieldState] Clear';

export const GetAvailableWageRecordSectionFieldsRequest = '[GetAvailableWageRecordSectionField/API] Send Request';
export const GetAvailableWageRecordSectionFieldsSuccess = '[GetAvailableWageRecordSectionField/API] Success';
export const GetAvailableWageRecordSectionFieldsFailure = '[GetAvailableWageRecordSectionField/API] Failure';

export const RemoveWageRecordSectionFieldRequest = '[RemoveParticipantWageSectionField/API] Send Request';
export const RemoveWageRecordSectionFieldSuccess = '[RemoveParticipantWageSectionField/API] Success';
export const RemoveWageRecordSectionFieldFailure = '[RemoveParticipantWageSectionField/API] Failure';

export const CreateWageRecordSectionFieldRequest = '[CreateWageRecordSectionField/API] Send Request';
export const CreateWageRecordSectionFieldSuccess = '[CreateWageRecordSectionField/API] Success';
export const CreateWageRecordSectionFieldFailure = '[CreateWageRecordSectionField/API] Failure';

export const SetWageRecordSectionFieldRequest = '[SetWageRecordSectionField/API] Send Request';
export const SetWageRecordSectionFieldSuccess = '[SetWageRecordSectionField/API] Success';
export const SetWageRecordSectionFieldFailure = '[SetWageRecordSectionField/API] Failure';

export const ReOrderWageRecordSectionField = '[ReOrderWageRecordSectionField/API] Send Request';

export const SetEmployerWageRecordDetailConfigurationRequest = '[SetEmployerWageRecordDetailConfiguration/API] Send Request';
export const SetEmployerWageRecordDetailConfigurationSuccess = '[SetEmployerWageRecordDetailConfiguration/API] Success';
export const SetEmployerWageRecordDetailConfigurationFailure = '[SetEmployerWageRecordDetailConfiguration/API] Failure';

export const SaveCorrectedExceptionsRequest = '[SaveCorrectedExceptionsRequest/API] Send Request';
export const SaveCorrectedExceptionsSuccess = '[SaveCorrectedExceptionsSuccess/API] Success';
export const SaveCorrectedExceptionsFailure = '[SaveCorrectedExceptionsFailure/API] Failure';
export const ClearSaveCorrectedExceptionsState = '[ClearSaveCorrectedExceptionsState] Clear';

export const GetWageRecordCommentsRequest = '[GetWageRecordComments/API] Send Request';
export const GetWageRecordCommentsSuccess = '[GetWageRecordComments/API] Success';
export const GetWageRecordCommentsFailure = '[GetWageRecordComments/API] Failure';
export const GetWageRecordDetailsRequest = '[GetWageRecordDetails/API] Send Request';
export const GetWageRecordDetailsSuccess = '[GetWageRecordDetails/API] Success';
export const GetWageRecordDetailsFailure = '[GetWageRecordDetails/API] Failure';
export const GetWageRecordDetailsExceptionsRequest = '[GetWageRecordDetailsExceptions/API] Send Request';
export const GetWageRecordDetailsExceptionsSuccess = '[GetWageRecordDetailsExceptions/API] Success';
export const GetWageRecordDetailsExceptionsFailure = '[GetWageRecordDetailsExceptions/API] Failure';

export const RunAuditWageFile = '[RunAuditWageFile/API] Send Request';
export const RunAuditWageFileSuccess = '[RunAuditWageFile/API] Success';
export const RunAuditWageFileFailure = '[RunAuditWageFile/API] Failure';
export const ClearRunAuditWageFileState = '[ClearRunAuditWageFileState] Clear';

export const RejectWageFile = '[RejectWageFile/API] Send Request';
export const RejectWageFileSuccess = '[RejectWageFile/API] Success';
export const RejectWageFileFailure = '[RejectWageFile/API] Failure';
export const ClearRejectWageFileState = '[RejectWageFileState] Clear';

export const getEmployerWageProcessingList = createAction(
  GetEmployerWageProcessingListRequest,
  props<{ employerId: string, query: GetDataListQuery }>()
);
export const getEmployerWageProcessingListSuccess = createAction(
  GetEmployerWageProcessingListSuccess,
  props<{ wageProcessingDataList: WageProcessingDataList }>()
);
export const getEmployerWageProcessingListFailure = createAction(
  GetEmployerWageProcessingListFailure,
  props<{ error?: any }>()
);

export const getWageFileValidationReport = createAction(
  GetWageFileValidationReportRequest,
  props<{ employerId: string, wageFileId: string }>()
);
export const getWageFileValidationReportSuccess = createAction(
  GetWageFileValidationReportSuccess,
  props<{ wageFileValidationReport: WageFileValidationReport }>()
);
export const getWageFileValidationReportFailure = createAction(
  GetWageFileValidationReportFailure,
  props<{ error?: any }>()
);

export const getWageFileValidationAuditTrails = createAction(
  GetWageFileValidationAuditTrailsRequest,
  props<{ employerId: string, wageFileId: string }>()
);
export const getWageFileValidationAuditTrailsSuccess = createAction(
  GetWageFileValidationAuditTrailsSuccess,
  props<{ wageFileValidationAuditTrails: WageFileValidationAuditTrail }>()
);
export const getWageFileValidationAuditTrailsFailure = createAction(
  GetWageFileValidationAuditTrailsFailure,
  props<{ error?: any }>()
);

export const uploadWageFile = createAction(
  UploadWageFileRequest,
  props<{ employerId: string, templateId: string, isOverride: boolean, wageFileId?: string }>()
);
export const uploadWageFileSuccess = createAction(
  UploadWageFileSuccess
);
export const uploadWageFileFailure = createAction(
  UploadWageFileFailure,
  props<{ errorMessage: string }>()
);

export const getWageFileParticipantContribution = createAction(
  GetWageFileParticipantContributionRequest,
  props<{ employerId: string, wageFileId: string, query: GetDataListQuery }>()
);
export const getWageFileParticipantContributionSuccess = createAction(
  GetWageFileParticipantContributionSuccess,
  props<{ participantContribution: ParticipantContribution }>()
);
export const getWageFileParticipantContributionFailure = createAction(
  GetWageFileParticipantContributionFailure,
  props<{ error?: any }>()
);

export const getParticipantWageSections = createAction(
  GetParticipantWageSectionsRequest,
  props<{ query: GetWageSectionsListQuery }>()
);
export const getParticipantWageSectionsSuccess = createAction(
  GetParticipantWageSectionsSuccess,
  props<{ participantWageSectionsList: ParticipantWageSectionsList }>()
);
export const getParticipantWageSectionsFailure = createAction(
  GetParticipantWageSectionsFailure,
  props<{ error?: any }>()
);

export const getParticipantWageSectionDetail = createAction(
  GetParticipantWageSectionDetailRequest,
  props<{ employerId: string, sectionRecordId: string }>()
);
export const getParticipantWageSectionDetailSuccess = createAction(
  GetParticipantWageSectionDetailSuccess,
  props<{ participantWageSectionDetail: ParticipantWageSectionDetail }>()
);
export const getParticipantWageSectionDetailFailure = createAction(
  GetParticipantWageSectionDetailFailure,
  props<{ error?: any }>()
);

export const createParticipantWageSections = createAction(
  CreateParticipantWageSectionsRequest,
  props<{ body: CreateWageRecordSection }>()
);
export const createParticipantWageSectionsSuccess = createAction(
  CreateParticipantWageSectionsSuccess,
  props<{recordId: string, sectionName: string}> ()
);
export const createParticipantWageSectionsFailure = createAction(
  CreateParticipantWageSectionsFailure,
  props<{ sectionName: string, error?: any }>()
);

export const removeParticipantWageSection = createAction(
  RemoveParticipantWageSectionRequest,
  props<{ body: RemoveWageRecordSection }>()
);
export const removeParticipantWageSectionSuccess = createAction(
  RemoveParticipantWageSectionSuccess,
  props<{ sectionName: string }>()
);
export const removeParticipantWageSectionFailure = createAction(
  RemoveParticipantWageSectionFailure,
  props<{ sectionName: string, error?: any }>()
);

export const clearEmployerWageRecordConfigurationState = createAction(
  ClearEmployerWageRecordConfigurationState
);

export const clearEmployerWageRecordSectionState = createAction(
  ClearEmployerWageRecordSectionState
);

export const clearUploadWageFileState = createAction(
  ClearUploadWageFileState
);

export const clearRunAuditWageFileState = createAction(
  ClearRunAuditWageFileState
);

export const getEmployerWageRecordListConfiguration = createAction(
  GetEmployerWageRecordListConfigurationRequest,
  props<{ employerId: string, fileTemplateId: string }>()
);
export const getEmployerWageRecordListConfigurationSuccess = createAction(
  GetEmployerWageRecordListConfigurationSuccess,
  props<{ wageRecordListConfigurations: WageRecordListConfiguration[] }>()
);
export const getEmployerWageRecordListConfigurationFailure = createAction(
  GetEmployerWageRecordListConfigurationFailure,
  props<{ error?: any }>()
);

export const setEmployerWageRecordListConfiguration = createAction(
  SetEmployerWageRecordListConfigurationRequest,
  props<{ employerId: string, fileTemplateId: string, body: WageRecordListConfiguration[] }>()
);
export const setEmployerWageRecordListConfigurationSuccess = createAction(
  SetEmployerWageRecordListConfigurationSuccess
);
export const setEmployerWageRecordListConfigurationFailure = createAction(
  SetEmployerWageRecordListConfigurationFailure,
  props<{ error?: any }>()
);
export const setWageRecordSectionDetail = createAction(
  SetWageRecordSectionDetailRequest,
  props<{query: SetWageRecordDetailQuery}>()
);
export const setWageRecordSectionDetailSuccess = createAction(
  SetWageRecordSectionDetailSuccess,
);
export const setWageRecordSectionDetailFailure = createAction(
  SetWageRecordSectionDetailFailure,
  props<{error?: any}>()
);

export const removeWageRecordSectionField = createAction(
  RemoveWageRecordSectionFieldRequest,
  props<{query: RemoveWageRecordSectionField}>()
);
export const removeWageRecordSectionFieldSuccess = createAction(
  RemoveWageRecordSectionFieldSuccess, 
  props<{fieldName: string}>()
);
export const removeWageRecordSectionFieldFailure = createAction(
  RemoveWageRecordSectionFieldFailure,
  props<({fieldName: string, error?: any})>()
);

export const clearWageRecordSectionFieldState = createAction(
  ClearWageRecordSectionFieldState
);
export const setEmployerWageRecordDetailConfiguration = createAction(
  SetEmployerWageRecordDetailConfigurationRequest,
  props<{ employerId: string, body: ParticipantWageSection[] }>()
);
export const setEmployerWageRecordDetailConfigurationSuccess = createAction(
  SetEmployerWageRecordDetailConfigurationSuccess
);
export const setEmployerWageRecordDetailConfigurationFailure = createAction(
  SetEmployerWageRecordDetailConfigurationFailure,
  props<{ error?: any }>()
);

export const getDetailWageFileExceptions = createAction(
  GetDetailWageFileExceptionsRequest,
  props<{ employerId: string, wageFileId: string, query: GetDetailWageFileExceptionQuery }>()
);
export const getDetailWageFileExceptionsSuccess = createAction(
  GetDetailWageFileExceptionsSuccess,
  props<{ exceptions: DetailWageFileException }>()
);
export const getDetailWageFileExceptionsFailure = createAction(
  GetDetailWageFileExceptionsFailure,
  props<{ error?: any }>()
);

export const getDetailException = createAction(
  GetDetailExceptionRequest,
  props<{ employerId: string, wageFileId: string, query: GetDetailWageFileExceptionQuery }>()
);
export const getDetailExceptionSuccess = createAction(
  GetDetailExceptionSuccess,
  props<{ detailException: DetailException }>()
);
export const getDetailExceptionFailure = createAction(
  GetDetailExceptionFailure,
  props<{ error?: any }>()
);

export const addCommentException = createAction(
  AddCommentExceptionRequest,
  props<{ employerId: string, wageFileId: string, body: AddCommentBody }>()
);
export const addCommentExceptionSuccess = createAction(
  AddCommentExceptionSuccess
);
export const addCommentExceptionFailure = createAction(
  AddCommentExceptionFailure,
  props<{ error?: any }>()
);
export const removeCommentException = createAction(
  RemoveCommentExceptionRequest,
  props<{ employerId: string, wageFileId: string, commentId: string }>()
);
export const removeCommentExceptionSuccess = createAction(
  RemoveCommentExceptionSuccess
);
export const removeCommentExceptionFailure = createAction(
  RemoveCommentExceptionFailure,
  props<{ error?: any }>()
);
export const getAvailableWageRecordSectionFields = createAction(
  GetAvailableWageRecordSectionFieldsRequest,
  props<{employerId: string, sectionId: string}>()
);
export const getAvailableWageRecordSectionFieldsSuccess = createAction(
  GetAvailableWageRecordSectionFieldsSuccess,
  props<{availables: AvailableRecordSectionFields}>()
);
export const getAvailableWageRecordSectionFieldsFailure = createAction(
  GetAvailableWageRecordSectionFieldsFailure,
  props<{error?: any}>()
);
export const createWageRecordSectionField = createAction(
  CreateWageRecordSectionFieldRequest,
  props<{query: CreateWageRecordSectionFieldQuery}>()
);
export const createWageRecordSectionFieldSuccess = createAction(
  CreateWageRecordSectionFieldSuccess,
  props<{fieldName: string}>()
);
export const createWageRecordSectionFieldFailure = createAction(
  CreateWageRecordSectionFieldFailure,
  props<{error?: any, fieldName: string}>()
);
export const setWageRecordSectionField = createAction(
  SetWageRecordSectionFieldRequest,
  props<{query: SetWageRecordSectionFieldQuery}>()
);
export const setWageRecordSectionFieldSuccess = createAction(
  SetWageRecordSectionFieldSuccess,
  props<{fieldName: string}>()
);
export const setWageRecordSectionFieldFailure = createAction(
  SetWageRecordSectionFieldFailure,
  props<{error?: any, fieldName: string}>()
);
export const reOrderWageRecordSectionField = createAction(
  ReOrderWageRecordSectionField,
  props<{body: ReOrderWageRecordSectionFieldBody}>()
);
export const saveCorrectedExceptions = createAction(
  SaveCorrectedExceptionsRequest,
  props<{ employerId: string, wageFileId: string, body: CorrectedExceptionsBody[] }>()
);
export const saveCorrectedExceptionsSuccess = createAction(
  SaveCorrectedExceptionsSuccess
);
export const saveCorrectedExceptionsFailure = createAction(
  SaveCorrectedExceptionsFailure,
  props<{ error?: any }>()
);
export const clearSaveCorrectedExceptionsState = createAction(
  ClearSaveCorrectedExceptionsState
);

export const getWageRecordComments = createAction(
  GetWageRecordCommentsRequest,
  props<{ employerId: string, wageFileId: string, rowIndex: number, query: GetDataListQuery }>()
);
export const getWageRecordCommentsSuccess = createAction(
  GetWageRecordCommentsSuccess,
  props<{ comments: ExceptionComment[], total: number }>()
);
export const getWageRecordCommentsFailure = createAction(
  GetWageRecordCommentsFailure,
  props<{ error?: any }>()
);

export const getWageRecordDetails = createAction(
  GetWageRecordDetailsRequest,
  props<{ employerId: string, wageFileId: string, rowIndex: number, screenId?: string }>()
);
export const getWageRecordDetailsSuccess = createAction(
  GetWageRecordDetailsSuccess,
  props<{ wageRecordSectionData: WageRecordSectionData[], options: any[] }>()
);
export const getWageRecordDetailsFailure = createAction(
  GetWageRecordDetailsFailure,
  props<{ error?: any }>()
);

export const getWageRecordDetailsExceptions = createAction(
  GetWageRecordDetailsExceptionsRequest,
  props<{ employerId: string, wageFileId: string, rowIndex: number, query: GetDataListQuery }>()
);
export const getWageRecordDetailsExceptionsSuccess = createAction(
  GetWageRecordDetailsExceptionsSuccess,
  props<{ exceptions: WageRecordDetailException[], columns: [], total: number, displayName: string }>()
);
export const getWageRecordDetailsExceptionsFailure = createAction(
  GetWageRecordDetailsExceptionsFailure,
  props<{ error?: any }>()
);

export const runAuditWageFile = createAction(
  RunAuditWageFile,
  props<{employerId: string, wageFileId: string}>()
);
export const runAuditWageFileSuccess = createAction(
  RunAuditWageFileSuccess
);
export const runAuditWageFileFailure = createAction(
  RunAuditWageFileFailure,
  props<{ error?: any }>()
);

export const rejectWageFile = createAction(
  RejectWageFile,
  props<{ employerId: string, wageFileId: string, reason: string }>()
);
export const rejectWageFileSuccess = createAction(
  RejectWageFileSuccess
);
export const rejectWageFileFailure = createAction(
  RejectWageFileFailure,
  props<{ error?: any }>()
);
export const clearRejectWageFileState = createAction(
  ClearRejectWageFileState
);

