import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import { CalculationParameter, ParameterMappings, SectionProperty } from '../../services/models';
import {
  clearGetCalculationParameterStateAction,
  clearGetCalculationPropertyStateAction,
  clearGetParameterMappingsStateAction,
  clearSetCalculationParameterStateAction,
  clearUpdateCalculationBenefitDetailByTypeStateAction,
  getCalculationParameterAction,
  getCalculationParameterFailureAction,
  getCalculationParameterSuccessAction,
  getCalculationPropertyAction,
  getCalculationPropertyFailureAction,
  getCalculationPropertySuccessAction,
  getParameterMappingsAction,
  getParameterMappingsFailureAction,
  getParameterMappingsSuccessAction,
  setCalculationParameterAction,
  setCalculationParameterFailureAction,
  setCalculationParameterSuccessAction,
  updateCalculationBenefitDetailByTypeAction,
  updateCalculationBenefitDetailByTypeFailureAction,
  updateCalculationBenefitDetailByTypeSuccessAction,
} from '../actions';

export interface CalculationParameterConfigurationState {
  getParameterMappings?: BaseActionState<ParameterMappings>;
  getCalculationParameter?: BaseActionState<CalculationParameter[]>;
  setCalculationParameter?: BaseActionState;
  getCalculationProperty?: BaseActionState<SectionProperty[]>;
}

const initialState: CalculationParameterConfigurationState = {};

export const calculationParameterConfigurationReducer = createReducer(
  initialState,
  on(getParameterMappingsAction, (state) => ({
    ...state,
    getParameterMappings: {
      isLoading: true,
    },
  })),
  on(getParameterMappingsSuccessAction, (state, { response }) => ({
    ...state,
    getParameterMappings: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getParameterMappingsFailureAction, (state, { error }) => ({
    ...state,
    getParameterMappings: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetParameterMappingsStateAction, (state) => ({
    ...state,
    getParameterMappings: undefined,
  })),

  on(getCalculationParameterAction, (state) => ({
    ...state,
    getCalculationParameter: {
      isLoading: true,
    },
  })),
  on(getCalculationParameterSuccessAction, (state, { response }) => ({
    ...state,
    getCalculationParameter: {
      isLoading: false,
      success: true,
      payload: response.calculationParameters,
    },
  })),
  on(getCalculationParameterFailureAction, (state, { error }) => ({
    ...state,
    getCalculationParameter: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetCalculationParameterStateAction, (state) => ({
    ...state,
    getCalculationParameter: undefined,
  })),

  on(getCalculationPropertyAction, (state) => ({
    ...state,
    getCalculationProperty: {
      isLoading: true,
    },
  })),
  on(getCalculationPropertySuccessAction, (state, { response }) => ({
    ...state,
    getCalculationProperty: {
      isLoading: false,
      success: true,
      payload: response.listSectionProperty,
    },
  })),
  on(getCalculationPropertyFailureAction, (state, { error }) => ({
    ...state,
    getCalculationProperty: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetCalculationPropertyStateAction, (state) => ({
    ...state,
    getCalculationProperty: undefined,
  })),

  on(setCalculationParameterAction, (state) => ({
    ...state,
    setCalculationParameter: {
      isLoading: true,
    },
  })),
  on(setCalculationParameterSuccessAction, (state) => ({
    ...state,
    setCalculationParameter: {
      isLoading: false,
      success: true,
    },
  })),
  on(setCalculationParameterFailureAction, (state, { error }) => ({
    ...state,
    setCalculationParameter: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearSetCalculationParameterStateAction, (state) => ({
    ...state,
    setCalculationParameter: undefined,
  })),
  on(updateCalculationBenefitDetailByTypeAction, (state) => ({
    ...state,
    setCalculationParameter: {
      isLoading: true,
    },
  })),
  on(updateCalculationBenefitDetailByTypeSuccessAction, (state) => ({
    ...state,
    setCalculationParameter: {
      isLoading: false,
      success: true,
    },
  })),
  on(updateCalculationBenefitDetailByTypeFailureAction, (state, { error }) => ({
    ...state,
    setCalculationParameter: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearUpdateCalculationBenefitDetailByTypeStateAction, (state) => ({
    ...state,
    setCalculationParameter: undefined,
  })),
);
