import { Action, combineReducers } from '@ngrx/store';
import { AccidentParticipantsState, accidentReducer } from './accident-participants.reducer';

export interface AccidentState {
  accident: AccidentParticipantsState;
}

export const accidentReducers = (state: AccidentState | undefined, action: Action) =>
  combineReducers({
  accident: accidentReducer
  })(state, action);


