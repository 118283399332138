import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { SwitchConfirmPopupService } from 'src/app/shared/services/switch-confirm-popup.service';
import { VendorMaintenanceService } from '../../../processing/features/payroll/services/vendor-maintenance.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'ptg-edit-bank-account',
  templateUrl: './payment-edit-bank-account-popup.component.html',
  styleUrls: ['./payment-edit-bank-account-popup.component.scss'],
  providers: [DatePipe]
})
export class PaymentEditBankAccountPopupComponent implements OnInit {
  formGroup: FormGroup;
  minDate = new Date();
  listAccountType = ['Checking', 'Savings'];
  isShowError = false;
  constructor(
    public dialogRef: MatDialogRef<PaymentEditBankAccountPopupComponent>,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    public dialog: MatDialog,
    public vendorMaintenanceService: VendorMaintenanceService,
    @Inject(MAT_DIALOG_DATA) public data: { banks: any, bank: any, parentId: string },
  ) {

    this.vendorMaintenanceService.bankInfo = null;
    this.formGroup = new FormGroup({
      routing: new FormControl(this.data.bank.routing, {
        validators: [Validators.required, Validators.maxLength(9)],
        asyncValidators: this.vendorMaintenanceService.checkApiValidator(this.data.bank ? this.data.bank.routing : '')
      }),
      accountNumber: new FormControl(this.data.bank.accountNumber, { validators: [Validators.required, Validators.maxLength(30)] }),
      accountType: this.data.bank.accountType == 1 ? new FormControl('Savings') : new FormControl('Checking')
    });
  } 
  
  ngOnInit(): void {
    const parentPopup = document.getElementById(this.data.parentId);
    parentPopup?.classList?.add('hidden-popup');
    this.formGroup.valueChanges.subscribe(val => {
      if (this.checkDublicatedBank(val.routing, val.accountNumber)){
        this.formGroup.get('accountNumber')?.setErrors({inValidAsync: true});
      } else {
        if (this.formGroup.get('accountNumber')?.value) {
          this.formGroup.get('accountNumber')?.setErrors(null);
        }
      }
    });
  }

  onSubmit() {
    if (this.formGroup.valid) {
      let data =  this.formGroup.getRawValue();
      data.bankName = this.vendorMaintenanceService.bankInfo != null ? this.vendorMaintenanceService.bankInfo.name : this.data.bank.bankName;
      data.bankId = this.vendorMaintenanceService.bankInfo != null ? this.vendorMaintenanceService?.bankInfo?.id : this.data.bank.bankId;
      this.dialogRef.close(data);
      const parentPopup = document.getElementById(this.data.parentId);
      parentPopup?.classList?.remove('hidden-popup');
    } 
  }

  checkDublicatedBank(routing:string, accountNumber: string) {
    let isDublicated = false;
    if(this.data.banks){
      this.data.banks.forEach((item: { routing: string; accountNumber: string; })=>{
        if(item.routing == routing && item.accountNumber == accountNumber){
          isDublicated = true;
        }
      })
    }

    return isDublicated;
  }
  
  onCancel(): void {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef,"", "", this.data.parentId);
  }
}
