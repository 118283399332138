import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models/base-action-state';

import * as DefinedBenefitActions from '../actions/defined-benefits.actions';
import {
  BenefitRecord,
  BenefitType,
  LookupTableOption,
  MemberStatus,
  QDROMetadata,
  QDROBenefitType,
  Benefit,
  LookupTableForBenefit,
} from '../../types/models';

export const definedBenefitsFeatureKey = 'definedBenefits';

export interface State {
  getBenefitTypes?: BaseActionState<BenefitType[]>;
  getBenefit?: BaseActionState<Benefit>;
  getAllBenefitTypes?: BaseActionState<BenefitType[]>;
  getStatusAndEvents?: BaseActionState<MemberStatus[]>;
  getBenefitRecords?: BaseActionState<BenefitRecord[]>;
  getLookupTableOptions?: BaseActionState<LookupTableOption[]>;
  getLookupTables?: BaseActionState<LookupTableForBenefit[]>;
  getQDROBenefitTypes?: BaseActionState<QDROBenefitType[]>;
  getQDROMetadatas?: BaseActionState<QDROMetadata[]>;
  addBenefitType?: BaseActionState;
  updateBenefit?: BaseActionState;
  updateBenefitType?: BaseActionState;
  removeBenefitType?: BaseActionState;
}

const initialState: State = {};

export const reducer = createReducer(
  initialState,

  on(DefinedBenefitActions.getBenefit, (state, {}) => ({
    ...state,
    getBenefit: {
      isLoading: true,
    },
  })),
  on(DefinedBenefitActions.getBenefitSuccess, (state: State, { response }) => ({
    ...state,
    getBenefit: {
      isLoading: false,
      success: true,
      payload: response.benefit,
    },
  })),
  on(DefinedBenefitActions.getBenefitFailure, (state: State, { error }) => ({
    ...state,
    getBenefit: createFailureState(error),
  })),
  on(DefinedBenefitActions.clearGetBenefitState, (state, {}) => ({
    ...initialState,
    getBenefit: undefined,
  })),

  on(DefinedBenefitActions.getBenefitTypes, (state, {}) => ({
    ...state,
    getBenefitTypes: {
      isLoading: true,
    },
  })),
  on(
    DefinedBenefitActions.getBenefitTypesSuccess,
    (state: State, { response }) => ({
      ...state,
      getBenefitTypes: {
        isLoading: false,
        success: true,
        payload: response.benefitTypes || [],
        total: response.total || 0,
      },
    })
  ),
  on(
    DefinedBenefitActions.getBenefitTypesFailure,
    (state: State, { error }) => ({
      ...state,
      getBenefitTypes: createFailureState(error),
    })
  ),
  on(DefinedBenefitActions.clearGetBenefitTypesState, (state) => ({
    ...initialState,
    getBenefitTypes: undefined,
  })),

  on(DefinedBenefitActions.getAllBenefitTypes, (state, {}) => ({
    ...state,
    getAllBenefitTypes: {
      isLoading: true,
    },
  })),
  on(
    DefinedBenefitActions.getAllBenefitTypesSuccess,
    (state: State, { response }) => ({
      ...state,
      getAllBenefitTypes: {
        isLoading: false,
        success: true,
        payload: response.benefitTypes || [],
        total: response.total || 0,
      },
    })
  ),
  on(
    DefinedBenefitActions.getAllBenefitTypesFailure,
    (state: State, { error }) => ({
      ...state,
      getAllBenefitTypes: createFailureState(error),
    })
  ),

  on(DefinedBenefitActions.getStatusAndEvents, (state) => ({
    ...state,
    getStatusAndEvents: {
      isLoading: true,
    },
  })),
  on(
    DefinedBenefitActions.getStatusAndEventsSuccess,
    (state: State, { response }) => ({
      ...state,
      getStatusAndEvents: {
        isLoading: false,
        success: true,
        payload: response.memberStatuses || [],
        total: response.memberStatuses?.length || 0,
      },
    })
  ),
  on(DefinedBenefitActions.getStatusAndEventsFailure, (state, { error }) => ({
    ...state,
    getStatusAndEvents: createFailureState(error),
  })),

  on(DefinedBenefitActions.getBenefitRecords, (state, {}) => ({
    ...state,
    getBenefitRecords: {
      isLoading: true,
    },
  })),
  on(
    DefinedBenefitActions.getBenefitRecordsSuccess,
    (state: State, { response }) => ({
      ...state,
      getBenefitRecords: {
        isLoading: false,
        success: true,
        payload: response.benefitRecords || [],
        total: response.benefitRecords?.length || 0,
      },
    })
  ),
  on(DefinedBenefitActions.getBenefitRecordsFailure, (state, { error }) => ({
    ...state,
    getBenefitRecords: createFailureState(error),
  })),

  on(DefinedBenefitActions.getLookupTableOptions, (state, {}) => ({
    ...state,
    getLookupTableOptions: {
      isLoading: true,
    },
  })),
  on(
    DefinedBenefitActions.getLookupTableOptionsSuccess,
    (state: State, { response }) => ({
      ...state,
      getLookupTableOptions: {
        isLoading: false,
        success: true,
        payload: response.lookupTableOptions || [],
        total: response.lookupTableOptions?.length || 0,
      },
    })
  ),
  on(
    DefinedBenefitActions.getLookupTableOptionsFailure,
    (state, { error }) => ({
      ...state,
      getLookupTableOptions: createFailureState(error),
    })
  ),

  on(DefinedBenefitActions.getLookupTablesRequest, (state) => ({
    ...state,
    getLookupTables: {
      isLoading: true,
    },
  })),
  on(
    DefinedBenefitActions.getLookupTablesSuccess,
    (state: State, { response }) => ({
      ...state,
      getLookupTables: {
        isLoading: false,
        success: true,
        payload: response.lookupTables || [],
        total: response.lookupTables?.length || 0,
      },
    })
  ),
  on(
    DefinedBenefitActions.getLookupTablesFailure,
    (state: State, { error }) => ({
      ...state,
      getLookupTables: createFailureState(error),
    })
  ),

  on(DefinedBenefitActions.getQDROBenefitTypesRequest, (state) => ({
    ...state,
    getQDROBenefitTypes: {
      isLoading: true,
    },
  })),
  on(
    DefinedBenefitActions.getQDROBenefitTypesSuccess,
    (state: State, { response }) => ({
      ...state,
      getQDROBenefitTypes: {
        isLoading: false,
        success: true,
        payload: response.qdroBenefitTypes || [],
        total: response.qdroBenefitTypes?.length || 0,
      },
    })
  ),
  on(
    DefinedBenefitActions.getQDROBenefitTypesFailure,
    (state: State, { error }) => ({
      ...state,
      getQDROBenefitTypes: createFailureState(error),
    })
  ),

  on(DefinedBenefitActions.getQDROMetadatasRequest, (state) => ({
    ...state,
    getQDROMetadatas: {
      isLoading: true,
    },
  })),
  on(
    DefinedBenefitActions.getQDROMetadatasSuccess,
    (state: State, { response }) => ({
      ...state,
      getQDROMetadatas: {
        isLoading: false,
        success: true,
        payload: response.qdroMetadatas || [],
        total: response.qdroMetadatas?.length || 0,
      },
    })
  ),
  on(
    DefinedBenefitActions.getQDROMetadatasFailure,
    (state: State, { error }) => ({
      ...state,
      qdroMetaData: createFailureState(error),
    })
  ),

  on(DefinedBenefitActions.addBenefitType, (state) => ({
    ...state,
    addBenefitType: {
      isLoading: true,
    },
  })),
  on(DefinedBenefitActions.addBenefitTypeSuccess, (state) => ({
    ...state,
    addBenefitType: {
      isLoading: false,
      success: true,
    },
  })),
  on(DefinedBenefitActions.addBenefitTypeFailure, (state, { error }) => ({
    ...state,
    addBenefitType: createFailureState(error),
  })),

  on(DefinedBenefitActions.updateBenefit, (state) => ({
    ...state,
    updateBenefit: {
      isLoading: true,
    },
  })),
  on(DefinedBenefitActions.updateBenefitSuccess, (state) => ({
    ...state,
    updateBenefit: {
      isLoading: false,
      success: true,
    },
  })),
  on(DefinedBenefitActions.updateBenefitFailure, (state, { error }) => ({
    ...state,
    updateBenefit: createFailureState(error),
  })),

  on(DefinedBenefitActions.removeBenefitType, (state) => ({
    ...state,
    removeBenefitType: {
      isLoading: true,
    },
  })),
  on(DefinedBenefitActions.removeBenefitTypeSuccess, (state) => ({
    ...state,
    removeBenefitType: {
      isLoading: false,
      success: true,
    },
  })),
  on(DefinedBenefitActions.removeBenefitTypeFailure, (state, { error }) => ({
    ...state,
    removeBenefitType: createFailureState(error),
  })),
  on(DefinedBenefitActions.updateBenefitType, (state) => ({
    ...state,
    updateBenefitType: {
      isLoading: true,
    },
  })),
  on(DefinedBenefitActions.updateBenefitTypeSuccess, (state) => ({
    ...state,
    updateBenefitType: {
      isLoading: false,
      success: true,
    },
  })),
  on(DefinedBenefitActions.updateBenefitTypeFailure, (state, { error }) => ({
    ...state,
    updateBenefitType: createFailureState(error),
  }))
);

const createFailureState = (error: any) => {
  return {
    isLoading: false,
    success: false,
    error: error,
  };
};
