import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '@ptg-shared/shared.module';
import { ControlsModule } from '@ptg-shared/controls/controls.module';
import { MODULE_KEY } from './constants';
import { AccidentClaimsComponent } from './pages/accident-claims/accident-claims.component';
import { AccidentEffects } from './store/effects';
import { AccidentParticipantsRoutingModule } from './accident-participants-routing.module';
import { AccidentParticipantsService } from './services/accident-participants.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AccidentDetailComponent } from './pages/accident-detail/accident-detail/accident-detail.component';
import { AddParticipantAccidentPostCardComponent } from './components/add-participant-accident-post-card/add-participant-accident-post-card.component';
import { AddParticipantAccidentReportComponent } from './components/add-participant-accident-report/add-participant-accident-report.component';
import { AddAccidentClaimComponent } from './components/add-accident-claim/add-accident-claim.component';
import { DisbursementReportService, VendorService } from 'src/app/admin/features/external-payment/services';
import { accidentReducers } from './store/reducers';
import { UploadAccidentDocumentComponent } from './components/upload-accident-document/upload-accident-document.component';
import { AddAccidentNoteComponent } from './components/add-accident-note/add-accident-note.component';
import { AccidentNoteDetailComponent } from './components/accident-note-detail/accident-note-detail.component';
import { EditParticipantAccidentStatusComponent } from './components/edit-participant-accident-status/edit-participant-accident-status.component';
import { OverviewHeaderEntityModule } from '@ptg-member/components/overview-header-entity/overview-header-entity.module';
import { SubHeaderModule } from '@ptg-member/components/sub-header/sub-header.component.module';

const COMPONENTS = [
  AccidentClaimsComponent,
  AccidentDetailComponent,
  AddParticipantAccidentPostCardComponent,
  AddParticipantAccidentReportComponent,
  EditParticipantAccidentStatusComponent,
  AddAccidentClaimComponent,
  UploadAccidentDocumentComponent,
  AddAccidentNoteComponent,
  AccidentNoteDetailComponent
]
const SERVICES = [
  DisbursementReportService,
  VendorService
]
@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    SharedModule,
    CommonModule,
    ControlsModule,
    FormsModule,
    ReactiveFormsModule,
    AccidentParticipantsRoutingModule,
    StoreModule.forFeature(
      MODULE_KEY,
      accidentReducers
    ),
    EffectsModule.forFeature(AccidentEffects),
    OverviewHeaderEntityModule,
    SubHeaderModule,
  ],
  providers: [...SERVICES],
})
export class AccidentParticipantsModule { }
