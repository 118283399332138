import { AfterViewInit, Directive, ElementRef } from '@angular/core';

import { STOP_PROPAGATION_CLASS } from '../constants';

@Directive({
  selector: '[stopPropagation]',
})
export class StopPropagation implements AfterViewInit {
  constructor(private elem: ElementRef<Element>) {}

  ngAfterViewInit(): void {
    // Add stop propagation class for the current element and its children
    this.elem.nativeElement.classList.add(STOP_PROPAGATION_CLASS);
    const childElems = this.elem.nativeElement.getElementsByTagName('*');
    for (let i = 0; i < childElems.length; i++) {
      childElems[i].classList.add(STOP_PROPAGATION_CLASS);
    }
  }
}
