import { createReducer, on } from '@ngrx/store';
import { Header, HeaderDetailResponse, HeaderPropertiesResponse } from '@ptg-member/types/models';
import { BaseActionState } from '@ptg-shared/types/models';
import { clearHeaderDetailState, createHeader, createHeaderFailure, createHeaderSuccess, getEntitiesAction, getEntitiesFailureAction, getEntitiesSuccessAction, getHeaderDetailFailure, getHeaderDetailSuccess, getHeaderListFailure, getHeaderListSuccess, getHeaderProfile, getHeaderProfileFailure, getHeaderProfileSuccess, getHeaderPropertiesFailure, getHeaderPropertiesSuccess, removeHeader, removeHeaderFailure, removeHeaderSuccess, updateHeader, updateHeaderFailure, updateHeaderSuccess } from '../actions/header.actions';
import { Entity } from 'src/app/entity-management/services/models';
import { ProfileConfigListResponse } from '@ptg-member/types/models/profile-configuration.model';

export interface HeaderState {
  getHeaders?: BaseActionState<Header[]>;
  getHeadersDetail?: BaseActionState<HeaderDetailResponse>;
  getHeadersProperties?: BaseActionState<HeaderPropertiesResponse>
  getEntities?: BaseActionState<Entity[]>;
  headerProfile?: BaseActionState<ProfileConfigListResponse>;
  createHeader?: BaseActionState<Header>;
  updateHeader?: BaseActionState;
  removeHeader?: BaseActionState;
}

export const headerKey = 'header';

const initialState: HeaderState = {
  getHeaders: {
    isLoading: true,
    success: true,
    payload: []
  },
  removeHeader: {
    isLoading: false,
    success: false,
  },
  headerProfile: {
    isLoading: false,
    success: false,
    payload: {
      profileConfigs: []
    }
  }
};

export const headerReducer = createReducer(
  initialState,
  on(clearHeaderDetailState, () => ({
    ...initialState
  })),
  on(getHeaderListSuccess, (state: HeaderState, { headerList }) => ({
    ...state,
    getHeaders: {
      isLoading: false,
      success: true,
      payload: headerList?.response,
      total: headerList.total
    }
  })),
  on(getHeaderListFailure, (state, { error }) => ({
    ...state,
    getHeaders: {
      isLoading: false,
      success: false,
      payload: []
    }
  })),

  on(getHeaderDetailSuccess, (state: HeaderState, { headerDetail }) => ({
    ...state,
    getHeadersDetail: {
      isLoading: false,
      success: true,
      payload: headerDetail
    }
  })),
  on(getHeaderDetailFailure, (state, { error }) => ({
    ...state,
    getHeadersDetail: {
      isLoading: false,
      payload: undefined
    }
  })),

  on(getHeaderPropertiesSuccess, (state: HeaderState, { headerProperties }) => ({
    ...state,
    getHeadersProperties: {
      isLoading: false,
      success: true,
      payload: headerProperties
    }
  })),
  on(getHeaderPropertiesFailure, (state, { error }) => ({
    ...state,
    getHeadersProperties: {
      isLoading: false,
      success: false,
      payload: { response: [] }
    }
  })),

  on(createHeader, (state: HeaderState) => ({
    ...state,
    createHeader: {
      isLoading: false,
      success: true,
    }
  })),
  on(createHeaderSuccess, (state: HeaderState, { body }) => ({
    ...state,
    createHeader: {
      isLoading: false,
      success: true,
      payload: body
    }
  })),
  on(createHeaderFailure, (state, { error }) => ({
    ...state,
    createHeader: {
      isLoading: false,
      success: false,
      error: error,
    }
  })),

  on(getHeaderProfile, (state: HeaderState) => ({
    ...state,
    headerProfile: {
      isLoading: true,
      success: false,
    }
  })),
  on(getHeaderProfileSuccess, (state: HeaderState, { profileConfigs }) => ({
    ...state,
    headerProfile: {
      isLoading: false,
      success: true,
      payload: profileConfigs
    }
  })),
  on(getHeaderProfileFailure, (state, { error }) => ({
    ...state,
    headerProfile: {
      isLoading: false,
      success: false,
      error: error,
    }
  })),

  on(updateHeader, (state: HeaderState) => ({
    ...state,
    updateHeader: {
      isLoading: true,
      success: false,
    }
  })),
  on(updateHeaderSuccess, (state: HeaderState) => ({
    ...state,
    updateHeader: {
      isLoading: false,
      success: true
    }
  })),
  on(updateHeaderFailure, (state, { error }) => ({
    ...state,
    updateHeader: {
      isLoading: false,
      success: false,
      error: error,
    }
  })),
  
  on(removeHeader, (state: HeaderState) => ({
    ...state,
    removeHeader: {
      isLoading: false,
      success: false,
    }
  })),
  on(removeHeaderSuccess, (state) => ({
    ...state,
    removeHeader: {
      isLoading: false,
      success: true,
    }
  })),
  on(removeHeaderFailure, (state, { error }) => ({
    ...state,
    removeHeader: {
      isLoading: false,
      success: false,
      error: error,
    }
  })),

  on(getEntitiesAction, (state: HeaderState) => ({
    ...state,
    getEntities: {
      isLoading: true,
    },
  })),

  on(getEntitiesFailureAction, (state: HeaderState, { error }) => ({
    ...state,
    getEntities: {
      isLoading: false,
      success: false,
      error: error,
      total: 0,
    },
  })),

  on(getEntitiesSuccessAction, (state: HeaderState, { response }) => ({
    ...state,
    getEntities: {
      isLoading: false,
      success: true,
      payload: response.entities,
      total: response.total,
    },
  })),
);
