import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as CartActions from '../actions/cart.actions';

import {
  CardDetail,
  GetCartResponse,
  GetEntityResponse,
  GetEntityComponentResponse,
  GetEntityPropertyResponse,
} from '@ptg-member/types/models/card.model';
import { CartService } from '@ptg-member/services/cart.service';

@Injectable()
export class CartEffects {
  constructor(private actions$: Actions, private cartService: CartService) {}

  getListCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.getCartRequest),
      switchMap(({ query }) => {
        return this.cartService.getCart(query).pipe(
          map((cartResponse: GetCartResponse) => {
            return CartActions.getCartSuccess({ cartResponse });
          }),
          catchError((err) => {
            return of(
              CartActions.getCartFailure({
                error: err.message,
              })
            );
          })
        );
      })
    )
  );

  removeCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.removeCart),
      switchMap(({ query }) => {
        return this.cartService.removeCard(query).pipe(
          map(() => {
            return CartActions.removeCartSuccess();
          }),
          catchError((err) => {
            return of(
              CartActions.removeCartFailure({
                error: err.message,
              })
            );
          })
        );
      })
    )
  );
  getListEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.getEntityRequest),
      switchMap(({ query }) => {
        return this.cartService.getEntity(query).pipe(
          map((entityResponse: GetEntityResponse) => {
            return CartActions.getEntitySuccess({ entityResponse });
          }),
          catchError((err) => {
            return of(
              CartActions.getEntityFailure({
                error: err.message,
              })
            );
          })
        );
      })
    )
  );

  setCart$ = createEffect(() =>
  this.actions$.pipe(
    ofType(CartActions.setCartRequest),
    switchMap(({body}) => {
      return this.cartService.setCart(body).pipe(
        map((id: any) => {
          return CartActions.setCartSuccess({id});
        }),
        catchError((error) => {
          return of(
            CartActions.setCartFailure({
              error
            })
          );
        })
      );
    })
  )
);

getCardDetail$ = createEffect(() =>
  this.actions$.pipe(
    ofType(CartActions.getCardDetail),
    switchMap(({ id }) => {
      return this.cartService.getCardDetail(id).pipe(
        map((cardDetail: CardDetail) => {
          return CartActions.getCardDetailSuccess({ cardDetail });
        }),
        catchError((err) => {
          return of(
            CartActions.getCardDetailFailure({
              error: err.message,
            })
          );
        })
      );
    })
  )
);

getEntityComponent$ = createEffect(() =>
  this.actions$.pipe(
    ofType(CartActions.getEntityComponent),
    switchMap(({ cardId, entityComponentId }) => {
      return this.cartService.getEntityComponent(cardId, entityComponentId).pipe(
        map((entityComponent: GetEntityComponentResponse) => {
          return CartActions.getEntityComponentSuccess({ entityComponent });
        }),
        catchError((err) => {
          return of(
            CartActions.getEntityComponentFailure({
              error: err.message,
            })
          );
        })
      );
    })
  )
);

getEntityProperty$ = createEffect(() =>
  this.actions$.pipe(
    ofType(CartActions.getEntityProperty),
    switchMap(({ id }) => {
      return this.cartService.getEntityProperty(id).pipe(
        map((entityProperty: GetEntityPropertyResponse) => {
          return CartActions.getEntityPropertySuccess({ entityProperty });
        }),
        catchError((err) => {
          return of(
            CartActions.getEntityPropertyFailure({
              error: err.message,
            })
          );
        })
      );
    })
  )
);

getEntityPropertyVersion$ = createEffect(() =>
  this.actions$.pipe(
    ofType(CartActions.getEntityPropertyVersion),
    switchMap(({ id, isVersionHistory }) => {
      return this.cartService.getEntityProperty(id, isVersionHistory).pipe(
        map((entityProperty: GetEntityPropertyResponse) => {
          return CartActions.getEntityPropertyVersionSuccess({ entityPropertyVersion: entityProperty });
        }),
        catchError((err) => {
          return of(
            CartActions.getEntityPropertyVersionFailure({
              error: err.message,
            })
          );
        })
      );
    })
  )
);

updateCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.updateCard),
      switchMap(({ id, body }) => {
        return this.cartService.updateCard(id, body).pipe(
          map(() => {
            return CartActions.updateCardSuccess();
          }),
          catchError((err) => {
            return of(
              CartActions.updateCardFailure({ error: err.message })
            );
          })
        );
      })
    )
  );
}
