import { createReducer, on } from '@ngrx/store';

import { STATE } from '@ptg-shared/constance/value.const';
import { BaseActionState } from '@ptg-shared/types/models';

import * as CartActions from '../actions/cart.actions';
import {
  GetCartResponse,
  GetEntityResponse,
  CardDetail,
  GetEntityComponentResponse,
  GetEntityPropertyResponse,
} from '@ptg-member/types/models/card.model';

export const cartFeatureKey = 'cartMember';

export interface State {
  isLoading: boolean;
  removeCardState: string;
  cartResponse: GetCartResponse;
  createState: string;
  listEntity: GetEntityResponse;
  idCart: any;
  getCardDetail?: BaseActionState<CardDetail>;
  getEntityComponent?: BaseActionState<GetEntityComponentResponse>;
  getEntityProperty?: BaseActionState<GetEntityPropertyResponse>;
  updateCard?: BaseActionState;
  getEntityPropertyVersion?: BaseActionState<GetEntityPropertyResponse>;
}

const initialState: State = {
  isLoading: true,
  cartResponse: {
    cards: [],
  },
  createState: '',
  removeCardState: '',
  listEntity: {
    entities: []
  },
  idCart: {
    id: ''
  },
  getCardDetail: {
    isLoading: true,
    payload: undefined,
  },
  getEntityComponent: {
    isLoading: true,
    payload: undefined,
  },
  getEntityProperty: {
    isLoading: true,
    payload: undefined,
  },
};

export const reducer = createReducer(
  initialState,
  on(CartActions.clearCartState, (state) => ({
    ...state,
    createState: '',
    removeCardState: '',
  })),
  on(CartActions.getCartSuccess,
    (state, { cartResponse }) => ({
      ...state,
      isLoading: false,
      cartResponse
    })
  ),
  on(CartActions.getCartFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
    })
  ),
  on(CartActions.getEntitySuccess,
    (state, { entityResponse }) => ({
      ...state,
      isLoading: false,
      listEntity: entityResponse
    })
  ),
  on(CartActions.getCartFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
    })
  ),
  on(CartActions.setCartSuccess, (state,  { id }) => ({
    ...state,
    isLoading: false,
    createState: STATE.SUCCESS,
    idCart: id
  })),

  on(CartActions.setCartFailure, (state, {}) => ({
    ...state,
    isLoading: false,
    createState: STATE.FAIL,
  })),
  on(CartActions.removeCartSuccess, (state, {}) => ({
    ...state,
    isLoading: false,
    removeCardState: STATE.SUCCESS,
  })),

  on(CartActions.removeCartFailure, (state, {}) => ({
    ...state,
    isLoading: false,
    removeCardState: STATE.FAIL,
  })),
  
  on(CartActions.getCardDetailSuccess, (state: State, { cardDetail }) => ({
    ...state,
    getCardDetail: {
      isLoading: false,
      success: true,
      payload: cardDetail
    }
  })),
  on(CartActions.getCardDetailFailure, (state, { error }) => ({
    ...state,
    getCardDetail: {
      isLoading: false,
      success: false,
      payload: undefined
    }
  })),

  on(CartActions.getEntityComponentSuccess, (state: State, { entityComponent }) => ({
    ...state,
    getEntityComponent: {
      isLoading: false,
      success: true,
      payload: entityComponent
    }
  })),
  on(CartActions.getEntityComponentFailure, (state, { error }) => ({
    ...state,
    getEntityComponent: {
      isLoading: false,
      success: false,
      payload: undefined
    }
  })),

  on(CartActions.getEntityPropertySuccess, (state: State, { entityProperty }) => ({
    ...state,
    getEntityProperty: {
      isLoading: false,
      success: true,
      payload: entityProperty
    }
  })),
  on(CartActions.getEntityPropertyFailure, (state, { error }) => ({
    ...state,
    getEntityProperty: {
      isLoading: false,
      success: false,
      payload: undefined
    }
  })),

  on(CartActions.getEntityPropertyVersionSuccess, (state: State, { entityPropertyVersion }) => ({
    ...state,
    getEntityPropertyVersion: {
      isLoading: false,
      success: true,
      payload: entityPropertyVersion
    }
  })),
  on(CartActions.getEntityPropertyVersionFailure, (state, { error }) => ({
    ...state,
    getEntityPropertyVersion: {
      isLoading: false,
      success: false,
      payload: undefined
    }
  })),

  on(CartActions.updateCard, (state) => ({
    ...state,
    updateCard: {
      isLoading: true,
    },
  })),

  on(CartActions.updateCardSuccess, (state: State) => ({
    ...state,
    updateCard: {
      isLoading: false,
      success: true,
    },
  })),

  on(CartActions.updateCardFailure, (state: State, { error }) => ({
    ...state,
    updateCard: {
      isLoading: false,
      success: false,
      error,
    },
  })),

  on(CartActions.clearCardState, (state: State) => ({
    ...initialState
  })),
);
