export interface MembersBenefitList {
  total: number;
  membersBenefit: any[];
}

export interface MembersBenefitEstablish {
  benefitType: string;
  parentParticipant: string;
}

export interface benefitType {
  name: string;
  code: string;
  memberStatusId: string;
  memberEventId: string;
  sectionKey: string;
  parentType: string;
}

export interface MembersBenefitType {
  benefitType: any[];
}
