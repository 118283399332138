import { Component } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';

import { takeUntil, tap, filter } from 'rxjs/operators';

import { Store, select } from '@ngrx/store';

import { CalculationType } from '../../types/enums';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { DEFAULT_PAGE_SIZE, SORT_TYPE, STATE } from '@ptg-shared/constance';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';
import { ACTION_COLUMN, Column, Row } from '@ptg-shared/controls/grid';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { BaseListComponent } from '@ptg-shared/components/base-list.component';
import { CalculationState, clearSetSubHeaderConfigurationDetailStateAction, getSubHeaderConfigurationsListAction, getSubHeaderConfigurationsListSelector, setSubHeaderConfigurationDetailSelector } from '../../store';
import { GetListRequest, SubHeaderListItem } from '../../services/models/subheader-configuration.model';
import { EditSubHeaderConfigurationComponent } from '../../components/edit-subheader-configuration/edit-subheader-configuration.component';
import { MatDialog } from '@angular/material/dialog';
import { showBanner } from '@ptg-shared/utils/common.util';

@Component({
  selector: 'ptg-subheader-configuration',
  templateUrl: './subheader-configuration.component.html',
  styleUrls: ['./subheader-configuration.component.scss'],
})
export class SubHeaderConfigurationComponent extends BaseListComponent {
  readonly PAGE_KEY = '-ptg-subheader-configuration';
  listBreadcrumbs: Breadcrumb[] = this.getBreadcrumbs();
  columns: Column[] = [
    {
      name: 'name',
      header: {
        title: 'Sub-header Component',
        style: {
          padding: '0.625rem 1rem 0.625rem 88px',
        },
      },
      width: '35.83%',
      style: {
        padding: '0.625rem 1rem 0.625rem 88px',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'menuItemsCount',
      header: {
        title: 'Number of Applied Menu Items',
        style: {
          padding: '0.625rem 1rem',
        },
      },
      width: '32.08%',
      style: {
        padding: '0.625rem 1rem',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
        style: {
          padding: '0.625rem 1rem',
        },
      },
      width: 'auto',
      style: {
        padding: '0.625rem 1rem',
      },
    },
  ];
  totalRecords: number = 0;
  isLoading = true;
  subHeaderConfigurationsList: (SubHeaderListItem & Row)[] = [];
  sortInfo?: Sort = {
    active: 'name',
    direction: 'asc',
  };
  memberId: string = '';
  defaultPageSize: number = DEFAULT_PAGE_SIZE;
  pageNumber = FIRST_PAGE;
  pageSize: number = DEFAULT_PAGE_SIZE;
  title: string = '';

  constructor(
    public readonly route: ActivatedRoute,
    public readonly layoutService: LayoutService,
    public readonly router: Router,
    private readonly store: Store<CalculationState>,
    private dialog: MatDialog,
  ) {
    super(layoutService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.checkRoute();
    this.getData();
    this.registerGetSubHeaderConfigurationDetailSelector();
    this.registerSetSubHeaderConfigurationDetailSelector();
  }

  private checkRoute(): void {
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.memberId = params.memberId;
      this.listBreadcrumbs = this.getBreadcrumbs();
    });
  }

  private getBreadcrumbs(): Breadcrumb[] {
    return [
      {
        name: 'Retirement Benefit Overview',
        url: `/member/benefit-overview/${CalculationType.RetirementBenefit}/${this.memberId}`,
      },
      {
        name: 'Sub-header Component Configuration',
        url: '',
      },
    ];
  }

  private getData(): void {
    let sortType = SORT_TYPE.ASC;
    let sortNames = 'Name';

    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = capitalizeFirstLetter(this.sortInfo.active);
      sortType = this.sortInfo.direction === 'desc' ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }

    const request: GetListRequest = {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      sortNames,
      sortType,
    };

    this.store.dispatch(getSubHeaderConfigurationsListAction({memberId: this.memberId, request}));
  }

  private registerGetSubHeaderConfigurationDetailSelector(): void {
    this.store
      .pipe(
        select(getSubHeaderConfigurationsListSelector),
        tap((res) => (this.isLoading = !!res?.isLoading)),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        this.totalRecords = state?.total as number;
        this.subHeaderConfigurationsList = state?.payload?.items ?? [];
      });
  }

  onChangeSort(event: Sort): void {
    this.sortInfo = event;
    this.getData();
  }

  onChangePage(event: PageEvent): void {
    super.onChangePage(event);
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    this.getData();
  }

  onClickEditSubHeaderConfiguration(row: SubHeaderListItem): void {
    this.router.navigateByUrl(`/member/calculation/subheader-configuration/detail/${row.id}/${this.memberId}`);
  }

  registerSetSubHeaderConfigurationDetailSelector(): void {
    this.store
      .pipe(
        select(setSubHeaderConfigurationDetailSelector),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        if (response) {
          if (response?.state?.state === STATE.SUCCESS) {
            this.getData();
          }
        }
      }
    );
  }
}
