<div class="title-button flex">
  <div class="title-name flex" id="page-name">
    {{name}}
  </div>
  <div class="line-space"></div>
  <div class="flex">
    <div class="add-btn flex" [id]="buttonId" (click)="addNew()">
      <mat-icon>{{buttonName === 'New User' ? 'person_add' : 'add'}}</mat-icon> 
      <span class="button-name">{{buttonName}}</span>
    </div>
  </div>
</div>
