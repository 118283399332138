import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { MembersListComponent } from '@ptg-member/containers/members-list/members-list.component';
import { CheckFundService } from '@ptg-shared/services/check-fund.service';
import { CheckPermissionService } from '@ptg-shared/services/check-permission.service';
import { CheckRoleService } from '@ptg-shared/services/check-role.service';
import {
  ExceptionConfigurationComponent,
  RetirementBenefitCalculationDetailComponent,
  RetirementBenefitCalculationListComponent,
  RetirementBenefitInputOutputListComponent,
  StepConfigurationComponent
} from './pages';
import {
  EditExceptionConfigurationComponent,
  EditStepConfigurationComponent
} from './components';
import {
  CalculationParameterConfigurationComponent
} from '@ptg-member/features/calculation/pages/calculation-parameter-configuration/calculation-parameter-configuration.component';
import { BenefitCalculationConfigurationComponent } from './pages/benefit-calculation-configuration/benefit-calculation-configuration.component';
import { SubHeaderConfigurationComponent } from './pages/subheader-configuration/subheader-configuration.component';
import { EditSubHeaderConfigurationComponent } from './components/edit-subheader-configuration/edit-subheader-configuration.component';

export const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: MembersListComponent,
  },
  {
    path: 'step-configuration/:memberId',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: StepConfigurationComponent,
  },
  {
    path: 'step-configuration/:stepConfigurationId/:memberId',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: EditStepConfigurationComponent,
  },
  {
    path: 'retirement-benefit-calculation-list/:memberId',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: RetirementBenefitCalculationListComponent,
  },
  {
    path: 'retirement-benefit-calculation/:calculationId/:memberId',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: RetirementBenefitCalculationDetailComponent,
  },
  {
    path: 'retirement-benefit-input-outputs/:memberId',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: RetirementBenefitInputOutputListComponent,
  },
  {
    path: 'calculation-parameter-configuration/:memberId',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: CalculationParameterConfigurationComponent,
  },
  {
    path: 'benefit-calculation-configuration/:memberId',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: BenefitCalculationConfigurationComponent,
  },
  {
    path: 'exception-configuration/:memberId',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: ExceptionConfigurationComponent,
  },
  {
    path: 'exception-configuration/detail/:exceptionId/:memberId',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: EditExceptionConfigurationComponent,
  },
  {
    path: 'subheader-configuration/:memberId',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: SubHeaderConfigurationComponent,
  },
  {
    path: 'subheader-configuration/detail/:subHeaderId/:memberId',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: EditSubHeaderConfigurationComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CalculationRoutingModule {
}
