import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { uploadFile, uploadIcon } from '../../constance/listIcons.const';
import { Auth0Service } from '../../auth/services/auth0.service';
import { ADMIN_SYSTEM } from '../../constance/value.const';

@Component({
  selector: 'ptg-title-bar',
  templateUrl: './title-bar.component.html',
  styleUrls: ['./title-bar.component.scss']
})
export class TitleBarComponent implements OnInit, OnChanges {
  active = new FormControl(false);
  domainURL: string | any = '';

  @Input() name!: string;
  @Input() domain?: string;
  @Input() isTheme?: boolean;
  @Input() activeValue?: boolean;
  @Input() viewName?: string;
  @Input() uploadName?: string;
  @Input() onlyUpload?: boolean;
  @Input() isProfile?: boolean;
  @Input() onlyTitle?: boolean;
  @Input() hasImportKey?: boolean = true;
  @Input() haveDownLoad?: boolean;
  @Input() isZipping?: boolean;

  @Output() upload = new EventEmitter();
  @Output() onUploadHistory = new EventEmitter();
  @Output() changeToggle = new EventEmitter();
  @Output() backProfessionals = new EventEmitter();
  @Output() onDownload = new EventEmitter();

  ADMIN_SYSTEM = ADMIN_SYSTEM;

  constructor(
    private router: Router,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    public authService: Auth0Service
  ) {
    iconRegistry.addSvgIconLiteral('upload-icon', sanitizer.bypassSecurityTrustHtml(uploadFile));
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if(this.domain !== undefined) {
      this.domainURL = `${environment.memberAppUrl}/${this.domain}`;
    }
    this.active.setValue(!!this.activeValue);    
  }

  routeAppContent() {
    this.router.navigateByUrl('/fund-management/app-content');
  }

  setStatusView(event: any) {
    this.changeToggle.emit(!!this.active.value);
  }

  uploadFile() {
    this.upload.emit('');
  }

  uploadHistory() {
    this.onUploadHistory.emit('');
  }

  backView() {
    this.backProfessionals.emit();
  }

  download() {
    this.onDownload.emit();
  }
}
