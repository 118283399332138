import { createAction, props } from '@ngrx/store';

import { GetInitiationPropertiesResponse, GetPayeePropertiesResponse, ModuleConnections, PayeeRequest } from '../../services/models';

export const getInitiationPropertiesAction = createAction(
  '[GetInitiationProperties/API] Send Request',
  props<{
    entityId: string,
    includeEntityReference?: boolean,
    cardId?: string,
    isList?: boolean,
    isSummaryView?: boolean,
    includeInitiationProperties?: boolean,
    entityReferencePropertyId?: string,
    screenId?: string,
    includeEntitySystem?: boolean
  }>()
);

export const getInitiationPropertiesSuccessAction = createAction(
  '[GetInitiationProperties/API] Success',
  props<{ response: GetInitiationPropertiesResponse }>()
);

export const getInitiationPropertiesFailureAction = createAction(
  '[GetInitiationProperties/API] Failure',
  props<{ error?: any }>()
);

export const clearGetInitiationPropertiesStateAction = createAction(
  '[GetInitiationProperties] Clear'
);

export const getModuleConnectionsAction = createAction(
  '[GetModuleConnections/API] Send Request'
);

export const getModuleConnectionsSuccessAction = createAction(
  '[GetModuleConnections/API] Success',
  props<{ moduleConnections: ModuleConnections }>()
);

export const getModuleConnectionsFailureAction = createAction(
  '[GetModuleConnections/API] Failure',
  props<{ error?: any }>()
);

export const clearModuleConnectionsStateAction = createAction(
  '[GetModuleConnections] Clear'
);


export const getPayeePropertiesAction = createAction(
  '[GetPayeeProperties/API] Send Request',
  props<{
    body: PayeeRequest
  }>()
);

export const getPayeePropertiesSuccessAction = createAction(
  '[GetPayeeProperties/API] Success',
  props<{ response: GetPayeePropertiesResponse }>()
);

export const getPayeePropertiesFailureAction = createAction(
  '[GetPayeeProperties/API] Failure',
  props<{ error?: any }>()
);

export const clearPayeePropertiesStateAction = createAction(
  '[GetPayeeProperties] Clear'
);