<ptg-dialog class="recalculate-benefit-dialog" [isFullScreen]="true">
  <ng-template dialogContentHeader>
    <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>
    <ptg-overview-header-entity #overViewHeader [targetIdFromDialog]="data.memberId"></ptg-overview-header-entity>
  </ng-template>
  <ng-template dialogBody>
    <div class="flex flex-col">
      <div *ngIf="bannerType" class="p-6">
        <ptg-banner [(bannerType)]="bannerType" [message]="message"></ptg-banner>
      </div>
    </div>
    <div class="flex gap-2 pb-2 mb-4">
      <ptg-button classInput="primary" [isDisabled]="isDisableSaveButton" (click)="onSubmit()"> Save </ptg-button>

      <ptg-button classInput="tertiary" (click)="onCancel()"> Cancel </ptg-button>
    </div>
    <form [formGroup]="formData">
      <ng-container>
        <div class="flex flex-col mb-4" [class]="'w-1/4'">
          <ptg-select
            *ngIf="isShowBenefitOptions"
            [isOptionObj]="true"
            [isSetMaxWidthSelect]="true"
            [listData]="benefitOptions"
            [controlField]="benefitOptionsControl"
            placeholder="Benefit Options"
            [isMultipleLineOption]="true"
            panelClass="property-name-dropdown"
            errorRequire="Benefit Options is required."
            (changeOptionValue)="onChangeBenefitOption()"
          ></ptg-select>
          <ng-container
            *ngIf="!isLoading && propertyValueFromPriorStepsFormArray?.length"
          >
            <div
              class="flex flex-col benefit-other-component-container mb-4"
              *ngIf="propertyValueFromPriorStepsFormArray?.length"
            >
              <div
                *ngFor="let property of propertyValueFromPriorStepsFormArray.controls; let index = index"
                class="value-container flex"
              >
                <ptg-dynamic-input
                  [controlField]="$any(property.get('value'))"
                  [formControlDetail]="propertyValueFromPriorStepConfigs[index]"
                ></ptg-dynamic-input>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </form>

    <div class="spinner-wrapper" [class.active]="isLoading">
      <ptg-spinner [isLoading]="isLoading"></ptg-spinner>
    </div>
  </ng-template>
</ptg-dialog>
