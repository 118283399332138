import { createAction, props } from "@ngrx/store";
import { ContributionInterestRateList, GetDataListQuery, InterestPeriodRequest } from "@ptg-employer/models/contribution-interest-rate.model";

export const GetContributionInterestRateListRequest = '[GetContributionInterestRateList/API] Send Request';
export const GetContributionInterestRateListSuccess = '[GetContributionInterestRateList/API] Success';
export const GetContributionInterestRateListFailure = '[GetContributionInterestRateList/API] Failure';
export const AddInterestPeriodRequest = '[AddInterestPeriod/API] Send Request';
export const AddInterestPeriodFailure = '[AddInterestPeriod/API] Failure';
export const AddInterestPeriodSuccess = '[AddInterestPeriod/API] Success';
export const UpdateInterestPeriodRequest = '[UpdateInterestPeriod/API] Send Request';
export const UpdateInterestPeriodFailure = '[UpdateInterestPeriod/API] Failure';
export const UpdateInterestPeriodSuccess = '[UpdateInterestPeriod/API] Success';
export const ClearContributionInterestRateState = '[ContributionInterestRate] Clear';

export const getContributionInterestRateList = createAction(
    GetContributionInterestRateListRequest,
    props<{ query: GetDataListQuery }>()
);
export const getContributionInterestRateListSuccess = createAction(
    GetContributionInterestRateListSuccess,
    props<{ contributionInterestRateList: ContributionInterestRateList }>()
);
export const getContributionInterestRateListFailure = createAction(
    GetContributionInterestRateListFailure,
    props<{ error?: any }>()
);

export const addInterestPeriod = createAction(
    AddInterestPeriodRequest,
    props<{ interestPeriodRequest: InterestPeriodRequest }>()
);
export const addInterestPeriodSuccess = createAction(
    AddInterestPeriodSuccess
);
export const addInterestPeriodFailure = createAction(
    AddInterestPeriodFailure,
    props<{ errorMsg: string}>()
);

export const updateInterestPeriod = createAction(
    UpdateInterestPeriodRequest,
    props<{ interestPeriodRequest: InterestPeriodRequest }>()
);
export const updateInterestPeriodSuccess = createAction(
    UpdateInterestPeriodSuccess
);
export const updateInterestPeriodFailure = createAction(
    UpdateInterestPeriodFailure,
    props<{ errorMsg: string}>()
);

export const clearContributionInterestRateState = createAction(
    ClearContributionInterestRateState
);

