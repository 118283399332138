import { createAction, props } from '@ngrx/store';

import { CreateRetirementBenefitUploadDocumentsRequest, EditRetirementBenefitDocumentRequest } from '../../services/models';

// Upload Document
export const createRetirementBenefitUploadDocumentsAction = createAction(
  '[CreateRetirementBenefitUploadDocuments/API] Send Request',
  props<{ request: CreateRetirementBenefitUploadDocumentsRequest }>()
);
export const createRetirementBenefitUploadDocumentsSuccessAction = createAction(
  '[CreateRetirementBenefitUploadDocuments/API] Success'
);
export const createRetirementBenefitUploadDocumentsFailureAction = createAction(
  '[CreateRetirementBenefitUploadDocuments/API] Failure',
  props<{ error?: any }>()
);
export const clearCreateRetirementBenefitUploadDocumentsStateAction =
  createAction('[CreateRetirementBenefitUploadDocuments] Clear');

// Edit Document
export const editRetirementBenefitDocumentAction = createAction(
  '[EditRetirementBenefitDocument/API] Send Request',
  props<{ memberId: string, calculationBenefitDocumentId: string, request: EditRetirementBenefitDocumentRequest }>()
);
export const editRetirementBenefitDocumentSuccessAction = createAction(
  '[EditRetirementBenefitDocument/API] Success'
);
export const editRetirementBenefitDocumentFailureAction = createAction(
  '[EditRetirementBenefitDocument/API] Failure',
  props<{ errorMsg?: any }>()
);
export const clearEditRetirementBenefitDocumentStateAction =
  createAction('[EditRetirementBenefitDocument] Clear');
