import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import {
  checkLoddDocumentCanEditAction,
  checkLoddDocumentCanEditFailureAction,
  checkLoddDocumentCanEditSuccessAction,
  checkLoddDocumentCanRemoveAction,
  checkLoddDocumentCanRemoveFailureAction,
  checkLoddDocumentCanRemoveSuccessAction,
  clearCheckLoddDocumentCanRemoveStateAction,
  clearRemoveRemoveCalculationDocumentDetailStateAction,
  clearCheckLoddDocumentCanEditStateAction,
  removeRemoveCalculationDocumentDetailAction,
  removeRemoveCalculationDocumentDetailFailureAction,
  removeRemoveCalculationDocumentDetailSuccessAction,
} from '../actions';
import { CheckExistLoddDocumentCanRemoveResponse } from '../../services/models';
import { ACTION, ActionState, STATE } from '@ptg-shared/constance';

export interface RemoveCalculationDocumentDetailState {
  removeRemoveCalculationDocumentDetail?:ActionState;
  checkExistLoddDocumentCanRemove?: BaseActionState<CheckExistLoddDocumentCanRemoveResponse>;
  checkExistLoddDocumentCanEdit?: BaseActionState<CheckExistLoddDocumentCanRemoveResponse>
}

const initialState: RemoveCalculationDocumentDetailState = {};

export const removeCalculationDocumentDetailReducer = createReducer(
  initialState,
  on(removeRemoveCalculationDocumentDetailAction, (state) => ({
    ...state,
    isLoading: true,
    removeRemoveCalculationDocumentDetail: undefined
  })),
  on(removeRemoveCalculationDocumentDetailSuccessAction, (state) => ({
    ...state,
    isLoading: false,
    removeRemoveCalculationDocumentDetail: {
      state: STATE.SUCCESS,
      action: ACTION.REMOVE
    },
  })),
  on(removeRemoveCalculationDocumentDetailFailureAction, (state, { error }) => ({
    ...state,
    isLoading: false,
    removeRemoveCalculationDocumentDetail: {
      state: STATE.FAIL,
      action: ACTION.REMOVE
    },
  })),
  on(clearRemoveRemoveCalculationDocumentDetailStateAction, (state) => ({
    ...state,
    removeRemoveCalculationDocumentDetail: undefined,
  })),

  on(checkLoddDocumentCanRemoveAction, (state) => ({
    ...state,
    checkExistLoddDocumentCanRemove: {
      isLoading: true,
    },
  })),
  on(checkLoddDocumentCanRemoveSuccessAction, (state, { response }) => ({
    ...state,
    checkExistLoddDocumentCanRemove: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(checkLoddDocumentCanRemoveFailureAction, (state, { error }) => ({
    ...state,
    checkExistLoddDocumentCanRemove: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearCheckLoddDocumentCanRemoveStateAction, (state) => ({
    ...state,
    checkExistLoddDocumentCanRemove: undefined,
  })),

  on(checkLoddDocumentCanEditAction, (state) => ({
    ...state,
    checkExistLoddDocumentCanEdit: {
      isLoading: true,
    },
  })),
  on(checkLoddDocumentCanEditSuccessAction, (state, { response }) => ({
    ...state,
    checkExistLoddDocumentCanEdit: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(checkLoddDocumentCanEditFailureAction, (state, { error }) => ({
    ...state,
    checkExistLoddDocumentCanEdit: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearCheckLoddDocumentCanEditStateAction, (state) => ({
    ...state,
    checkExistLoddDocumentCanEdit: undefined,
  }))
);
