import { Injectable } from '@angular/core';
import { ValidateDisabilityBenefitErrorType } from '../../types/enums';

@Injectable()
export class AddDisabilityComponentService {
  getInvalidMessageOnEdit(errorType?: ValidateDisabilityBenefitErrorType, confirmMessage?: string): string {
    switch (errorType) {
      case ValidateDisabilityBenefitErrorType.NotInitiatedStatus:
        return 'Can only edit disability benefit if the disability calculation status is "Initiated", and there is no calculation record has been paid.';
      case ValidateDisabilityBenefitErrorType.OverDueDate:
        return 'This benefit is already end. Cannot edit.';
      case ValidateDisabilityBenefitErrorType.AccidentStatusNotApproved:
        return 'An approved accident must be selected.';
      case ValidateDisabilityBenefitErrorType.AccidentFatalNotYes:
        return 'Can\'t initiate disability benefit with accident\'s fatal is "Yes".';
      case ValidateDisabilityBenefitErrorType.InvalidLongTermDisabilityDate:
        return confirmMessage ?? '';
      default:
        return '';
    }
  }
}
