import { createReducer, on } from '@ngrx/store';
import { BaseActionState } from '@ptg-shared/types/models';
import {
  getRemittanceFeeInvoiceAction,
  getRemittanceFeeInvoiceSuccessAction,
  getRemittanceFeeInvoiceFailureAction,
  clearRemittanceFeeInvoiceStateAction,
  validateBeforePrintFeeInvoiceAction,
  validateBeforePrintFeeInvoiceSuccessAction,
  validateBeforePrintFeeInvoiceFailureAction,
  clearValidateBeforePrintFeeInvoiceStateAction,
  printRemittanceFeeInvoiceAction,
  printRemittanceFeeInvoiceSuccessAction,
  printRemittanceFeeInvoiceFailureAction,
  clearPrintRemittanceFeeInvoiceStateAction,
} from '@ptg-employer/actions/remittance-print-invoice.actions';
import {
  GetRemittanceFeeInvoiceResponse,
  ValidateBeforePrintFeeInvoiceResponse,
} from '@ptg-employer/models/remittance-print-invoice.model';

export interface RemittancePrintInvoiceState {
  getRemittanceFeeInvoice?: BaseActionState<GetRemittanceFeeInvoiceResponse>;
  validateBeforePrintFeeInvoice?: BaseActionState<ValidateBeforePrintFeeInvoiceResponse>;
  printRemittanceFeeInvoice?: BaseActionState<Blob[]>;
}

const initialState: RemittancePrintInvoiceState = {};

export const remittancePrintInvoiceReducer = createReducer(
  initialState,

  /* Start of GetRemittanceFeeInvoice */
  on(getRemittanceFeeInvoiceAction, (state) => ({
    ...state,
    getRemittanceFeeInvoice: {
      isLoading: true,
    },
  })),
  on(getRemittanceFeeInvoiceSuccessAction, (state, { response }) => ({
    ...state,
    getRemittanceFeeInvoice: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getRemittanceFeeInvoiceFailureAction, (state, { error }) => ({
    ...state,
    getRemittanceFeeInvoice: {
      isLoading: false,
      success: false,
      error,
    },
  })),
  on(clearRemittanceFeeInvoiceStateAction, (state) => ({
    ...state,
    getRemittanceFeeInvoice: undefined,
  })),
  /* End of GetRemittanceFeeInvoice */

  /* Start of ValidateBeforePrintFeeInvoice */
  on(validateBeforePrintFeeInvoiceAction, (state) => ({
    ...state,
    validateBeforePrintFeeInvoice: {
      isLoading: true,
    },
  })),
  on(validateBeforePrintFeeInvoiceSuccessAction, (state, { response }) => ({
    ...state,
    validateBeforePrintFeeInvoice: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(validateBeforePrintFeeInvoiceFailureAction, (state, { error }) => ({
    ...state,
    validateBeforePrintFeeInvoice: {
      isLoading: false,
      success: false,
      error,
    },
  })),
  on(clearValidateBeforePrintFeeInvoiceStateAction, (state) => ({
    ...state,
    validateBeforePrintFeeInvoice: undefined,
  })),
  /* End of ValidateBeforePrintFeeInvoice */

  /* Start of PrintRemittanceFeeInvoice */
  on(printRemittanceFeeInvoiceAction, (state) => ({
    ...state,
    printRemittanceFeeInvoice: {
      isLoading: true,
    },
  })),
  on(printRemittanceFeeInvoiceSuccessAction, (state, { response }) => ({
    ...state,
    printRemittanceFeeInvoice: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(printRemittanceFeeInvoiceFailureAction, (state, { error }) => ({
    ...state,
    printRemittanceFeeInvoice: {
      isLoading: false,
      success: false,
      error,
    },
  })),
  on(clearPrintRemittanceFeeInvoiceStateAction, (state) => ({
    ...state,
    printRemittanceFeeInvoice: undefined,
  })),
  /* End of PrintRemittanceFeeInvoice */
);
