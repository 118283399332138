<div mat-card class="card-description flex flex-col">
  <div class="card-description__header">
    <div mat-card-title class="card-description__header--title">
      {{ data.title }}
    </div>
    <div mat-card-actions class="card-description__actions">
      <ng-container *ngIf="showMenu">
        <button
          mat-icon-button
          [matMenuTriggerFor]="beforeMenu"
          aria-label="Icon-button with a menu"
          class="group-button ignore-row"
        >
          <mat-icon class="ignore-row">more_horiz</mat-icon>
        </button>
        <mat-menu #beforeMenu="matMenu" xPosition="before">
          <ng-container *ngFor="let button of data?.action?.list">
            <button mat-menu-item (click)="onActionClick(button?.callback)">
              <mat-icon>{{ button?.iconFont }}</mat-icon>
              <span class="label-icon">{{ button?.buttonLabel }}</span>
            </button>
          </ng-container>
        </mat-menu>
      </ng-container>

      <ng-container *ngIf="!showMenu">
        <ng-container *ngFor="let button of data?.action?.list">
          <ptg-button
            [buttonName]="button?.buttonLabel"
            labelClass="text-[14px] {{button?.labelClass ?? ''}}"
            classInput="label-icon {{button?.class ?? ''}}"
            (clickButton)="onActionClick(button?.callback)"
            [isDisabled]="button?.isDisabled"
          >
            <mat-icon *ngIf="button?.iconFont" class="mr-[4px]">{{ button?.iconFont }}</mat-icon>
          </ptg-button>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div
    #contentBody
    mat-card-content
    class="card-description__body"
    [ngClass]="{
      'flex justify-center items-center': !data.body?.content?.length
    }"
    *ngIf="!data.body?.hasOverridden"
  >
    <ptg-field-display
      [data]="data.body?.content"
      [columnNumber]="data.columnNumber ?? 2"
      ngClass="flex flex-wrap"
      [isCustomData]="isCustomData"
      (fieldClick)="onFieldClick($event)"
    ></ptg-field-display>
    <div class="data-not-found" *ngIf="!data?.body?.content?.length">No Data to Display</div>
  </div>

  <div
    #contentBody
    mat-card-content
    class="card-description__body flex flex-row flex-wrap"
    *ngIf="data.body?.hasOverridden"
  >
    <div class="w-50">
      <div class="sub-title">
        <span>System Calc Values</span>
      </div>
      <ptg-field-display
        #contentEl
        [data]="data.body?.content"
        [columnNumber]="data.columnNumber ?? 1"
      ></ptg-field-display>
    </div>
    <div class="w-50">
      <div class="sub-title">
        <span>User Overridden</span>
      </div>
      <ptg-field-display
        #contentEl
        [data]="data.body?.contentOverridden"
        [columnNumber]="data.columnNumber ?? 1"
      ></ptg-field-display>
    </div>
    <div class="data-not-found" *ngIf="!data?.body?.content?.length">No Data to Display</div>
  </div>
</div>
