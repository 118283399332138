import { createReducer, on } from '@ngrx/store';
import { EmployerWageProcessingActions } from '../actions';
import { ACTION, ActionState, STATE } from '@ptg-shared/constance/value.const';
import { 
  AvailableRecordSectionFields,
  DetailException,
  DetailWageFileException,
  ExceptionComment,
  ParticipantContribution, ParticipantWageSectionDetail, 
  ParticipantWageSectionsList,
  WageFileValidationAuditTrail,
  WageFileValidationReport,
  WageProcessingDataList,
  WageRecordDetailException,
  WageRecordListConfiguration,
  WageRecordSectionData
} from '../models/employer-wage-processing.model';

export const employerWageProcessingFeatureKey = 'EmployerWageProcessing';

export interface State {
  wageProcessingData: {
    isLoading: boolean;
    wageProcessingDataList: WageProcessingDataList
  };
  uploadWageFileState: {
    uploading: boolean;
    state: string | undefined;
    error?: any
  };
  wageFileValidationReportData: {
    isLoading: boolean;
    wageFileValidationReport?: WageFileValidationReport;
  };
  wageFileValidationAuditTrailsData: {
    isLoading: boolean;
    wageFileValidationAuditTrails?: WageFileValidationAuditTrail;
  };
  participantContributionData: {
    isLoading: boolean;
    participantContribution?: ParticipantContribution;
  };
  participantWageSectionsData: {
    isLoading: boolean;
    participantWageSectionsList: ParticipantWageSectionsList;
  };
  wageRecordListConfigurations: {
    isLoading: boolean;
    wageRecordListConfigurations: WageRecordListConfiguration[];
  };
  setEmployerWageRecordListConfigurationState?: ActionState;
  setEmployerWageRecordDetailConfigurationState?: ActionState;
  createWageRecordState?: ActionState;
  detailWageFileException: {
    isLoading: boolean;
    exceptions: DetailWageFileException
  };
  detailException: {
    isLoading: boolean;
    data: DetailException;
  };
  addRemoveCommentExceptionState?: ActionState;
  participantWageSectionDetailData: {
    isLoading: boolean;
    participantWageSectionDetail?: ParticipantWageSectionDetail;
  };
  wageRecordState?: {
    actionState?: ActionState;
    sectionName?: string;
    newlyAddedWageRecordId?: string;
  };
  wageRecordFieldState?: {
    actionState?: ActionState;
    fieldName?: string;
    fieldId?: string;
  };
  availableWageRecordFieldsState?: {
    isLoading: boolean;
    availables: AvailableRecordSectionFields;
  };
  saveCorrectedExceptionsState?: ActionState;
  wageRecordComment: {
    isLoading: boolean;
    comments: {
      comments: ExceptionComment[],
      total: number
    }
  };
  wageRecordSectionData: {
    isLoading: boolean;
    wageRecordSectionData: {
      options: any[],
      sections: WageRecordSectionData[]
    }
  };
  wageRecordDetailException: {
    isLoading: boolean;
    total: number;
    columns: any[];
    exceptions: WageRecordDetailException[];
    displayName: string;
  };
  runAuditWageFileState: {
    auditing: boolean;
    state?: string;
    error?: any
  };
  rejectWageFileState?: ActionState;
}

const initialState: State = {
  wageProcessingData: {
    isLoading: true,
    wageProcessingDataList: {
      total: 0,
      highlightedPropertyConfigs: [],
      employerWageFiles: []
    }
  },
  uploadWageFileState: {
    uploading: false,
    state: '',
    error: ''
  },
  wageFileValidationReportData: {
    isLoading: true,
    wageFileValidationReport: undefined
  },
  wageFileValidationAuditTrailsData: {
    isLoading: true,
    wageFileValidationAuditTrails: undefined
  },
  participantContributionData: {
    isLoading: true,
    participantContribution: undefined
  },
  wageRecordListConfigurations: {
    isLoading: true,
    wageRecordListConfigurations: []
  },
  detailWageFileException: {
    isLoading: true,
    exceptions: {
      participantKeyHeader: '',
      total: 0,
      totalError: 0,
      detailExceptions: []
    }
  },  
  detailException: {
    isLoading: true,
    data: {
      identificatoryProperties: [],
      exceptions: [],
      comments: [],
      options: []
    }
  },
  participantWageSectionsData: {
    isLoading: true,
    participantWageSectionsList:
    {
      total: 0,
      filterTypes: [],
      wageRecordSections: []
    }
  },
  participantWageSectionDetailData: {
    isLoading: true,
    participantWageSectionDetail: undefined
  },
  wageRecordComment: {
    isLoading: true,
    comments: {
      comments: [],
      total: 0
    }
  },
  wageRecordSectionData: {
    isLoading: true,
    wageRecordSectionData: {
      options: [],
      sections: []
    }
  },
  wageRecordDetailException: {
    isLoading: true,
    total: 0,
    columns: [],
    exceptions: [],
    displayName: ''
  },
  runAuditWageFileState: {
    auditing: false,
    state: undefined,
    error: undefined
  },
  rejectWageFileState: {
    state: '',
    action: ''
  }
};

export const reducer = createReducer(
  initialState,
  on(EmployerWageProcessingActions.getEmployerWageProcessingListSuccess, (state, { wageProcessingDataList }) => ({
    ...state,
    wageProcessingData: {
      isLoading: false,
      wageProcessingDataList
    }
  })),
  on(EmployerWageProcessingActions.getEmployerWageProcessingListFailure, (state, { error }) => ({
    ...state,
    wageProcessingData: {
      isLoading: false,
      wageProcessingDataList: {
        total: 0,
        highlightedPropertyConfigs: [],
        employerWageFiles: []
      }
    }
  })),
  on(EmployerWageProcessingActions.uploadWageFile, (state) => ({
    ...state,
    uploadWageFileState: {
      uploading: true,
      state: '',
      error: ''
    }
  })),
  on(EmployerWageProcessingActions.uploadWageFileSuccess, (state) => ({
    ...state,
    uploadWageFileState: {
      uploading: false,
      state: STATE.SUCCESS
    }
  })),
  on(EmployerWageProcessingActions.uploadWageFileFailure, (state, error) => ({
    ...state,
    uploadWageFileState: {
      uploading: false,
      state: STATE.FAIL,
      error
    }
  })),
  on(EmployerWageProcessingActions.clearUploadWageFileState, (state, error) => ({
    ...state,
    uploadWageFileState: {
      uploading: false,
      state: undefined
    }
  })),
  on(EmployerWageProcessingActions.getWageFileValidationReport, (state) => ({
    ...state,
    wageFileValidationReportData: {
      isLoading: true,
      wageFileValidationReport: undefined
    },
  })),
  on(EmployerWageProcessingActions.getWageFileValidationReportSuccess, (state, { wageFileValidationReport }) => ({
    ...state,
    wageFileValidationReportData: {
      isLoading: false,
      wageFileValidationReport: wageFileValidationReport
    }
  })),
  on(EmployerWageProcessingActions.getWageFileValidationReportFailure, (state) => ({
    ...state,
    wageFileValidationReportData: {
      isLoading: false,
      wageFileValidationReport: undefined
    }
  })),
  on(EmployerWageProcessingActions.getWageFileValidationAuditTrails, (state) => ({
    ...state,
    wageFileValidationAuditTrailsData: {
      isLoading: true,
      wageFileValidationAuditTrails: undefined
    },
  })),
  on(EmployerWageProcessingActions.getWageFileValidationAuditTrailsSuccess, (state, { wageFileValidationAuditTrails }) => ({
    ...state,
    wageFileValidationAuditTrailsData: {
      isLoading: false,
      wageFileValidationAuditTrails: wageFileValidationAuditTrails
    }
  })),
  on(EmployerWageProcessingActions.getWageFileValidationAuditTrailsFailure, (state) => ({
    ...state,
    wageFileValidationAuditTrailsData: {
      isLoading: false,
      wageFileValidationAuditTrails: undefined
    }
  })),

  on(EmployerWageProcessingActions.getWageFileParticipantContribution, (state) => ({
    ...state,
    participantContributionData: {
      isLoading: true,
      participantContribution: undefined
    },
  })),
  on(EmployerWageProcessingActions.getWageFileParticipantContributionSuccess, (state, { participantContribution }) => ({
    ...state,
    participantContributionData: {
      isLoading: false,
      participantContribution
    }
  })),
  on(EmployerWageProcessingActions.getWageFileParticipantContributionFailure, (state) => ({
    ...state,
    participantContributionData: {
      isLoading: false,
      participantContribution: undefined
    }
  })),
  on(
    EmployerWageProcessingActions.getEmployerWageRecordListConfiguration,
    (state) => ({
      ...state,
      wageRecordListConfigurations: {
        isLoading: true,
        wageRecordListConfigurations: []
      },
    })
  ),
  on(
    EmployerWageProcessingActions.getEmployerWageRecordListConfigurationSuccess,
    (state, { wageRecordListConfigurations }) => ({
      ...state,
      wageRecordListConfigurations: {
        isLoading: false,
        wageRecordListConfigurations
      },
    })
  ),
  on(EmployerWageProcessingActions.getEmployerWageRecordListConfigurationFailure, (state) => ({
    ...state,
    wageRecordListConfigurations: {
      isLoading: false,
      wageRecordListConfigurations: []
    },
  })),
  on(EmployerWageProcessingActions.setEmployerWageRecordListConfigurationSuccess, (state, { }) => ({
    ...state,
    setEmployerWageRecordListConfigurationState: {
      action: ACTION.EDIT,
      state: STATE.SUCCESS,
    },
  })),
  on(EmployerWageProcessingActions.setEmployerWageRecordListConfigurationFailure, (state, { }) => ({
    ...state,
    setEmployerWageRecordListConfigurationState: {
      action: ACTION.EDIT,
      state: STATE.FAIL,
    },
  })),
  on(EmployerWageProcessingActions.clearEmployerWageRecordConfigurationState, (state) => ({
    ...state,
    setEmployerWageRecordListConfigurationState: undefined,
    setEmployerWageRecordDetailConfigurationState: undefined,
  })),
  on(EmployerWageProcessingActions.setEmployerWageRecordDetailConfigurationSuccess, (state, { }) => ({
    ...state,
    setEmployerWageRecordDetailConfigurationState: {
      action: ACTION.EDIT,
      state: STATE.SUCCESS,
    },
  })),
  on(EmployerWageProcessingActions.setEmployerWageRecordDetailConfigurationFailure, (state, { }) => ({
    ...state,
    setEmployerWageRecordDetailConfigurationState: {
      action: ACTION.EDIT,
      state: STATE.FAIL,
    },
  })),
  on(EmployerWageProcessingActions.getDetailWageFileExceptions, (state) => ({
    ...state,
    detailWageFileException: {
      isLoading: true,
      exceptions: {
        participantKeyHeader: '',
        total: 0,
        totalError: 0,
        detailExceptions: []
      }
    }
  })),
  on(EmployerWageProcessingActions.getDetailWageFileExceptionsSuccess, (state, { exceptions }) => ({
    ...state,
    detailWageFileException: {
      isLoading: false,
      exceptions: exceptions
    }
  })),
  on(EmployerWageProcessingActions.getDetailWageFileExceptionsFailure, (state) => ({
    ...state,
    detailWageFileException: {
      isLoading: false,
      exceptions: {
        participantKeyHeader: '',
        total: 0,
        totalError: 0,
        detailExceptions: []
      }
    }
  })),
  on(EmployerWageProcessingActions.getDetailException, (state) => ({
    ...state,
    detailException: {
      isLoading: true,
      data: {
        identificatoryProperties: [],
        exceptions: [],
        comments: [],
        options: []
      }
    }
  })),
  on(EmployerWageProcessingActions.getDetailExceptionSuccess, (state, { detailException }) => ({
    ...state,
    detailException: {
      isLoading: false,
      data: detailException
    }
  })),
  on(EmployerWageProcessingActions.getDetailExceptionFailure, (state) => ({
    ...state,
    detailException: {
      isLoading: false,
      data: {
        identificatoryProperties: [],
        exceptions: [],
        comments: [],
        options: []
      }
    }
  })),
  on(EmployerWageProcessingActions.addCommentExceptionSuccess, (state) => ({
    ...state,
    addRemoveCommentExceptionState: {
      state: STATE.SUCCESS,
      action: ACTION.ADD
    }
  })),
  on(EmployerWageProcessingActions.addCommentExceptionFailure, (state) => ({
    ...state,
    addRemoveCommentExceptionState: {
      state: STATE.FAIL,
      action: ACTION.ADD
    }
  })),
  on(EmployerWageProcessingActions.removeCommentExceptionSuccess, (state) => ({
    ...state,
    addRemoveCommentExceptionState: {
      state: STATE.SUCCESS,
      action: ACTION.REMOVE
    }
  })),
  on(EmployerWageProcessingActions.saveCorrectedExceptionsSuccess, (state) => ({
    ...state,
    saveCorrectedExceptionsState: {
      state: STATE.SUCCESS,
      action: ACTION.EDIT
    }
  })),
  on(EmployerWageProcessingActions.saveCorrectedExceptionsFailure, (state) => ({
    ...state,
    saveCorrectedExceptionsState: {
      state: STATE.FAIL,
      action: ACTION.EDIT
    }
  })),
  on(EmployerWageProcessingActions.clearSaveCorrectedExceptionsState, (state) => ({
    ...state,
    saveCorrectedExceptionsState: undefined
  })),
  on(EmployerWageProcessingActions.getParticipantWageSections, (state) => ({
    ...state,
    participantWageSectionsData: {
      isLoading: true,
      participantWageSectionsList:
      {
        total: 0,
        filterTypes: [],
        wageRecordSections: []
      }
    },
  })),
  on(EmployerWageProcessingActions.getParticipantWageSectionsSuccess, (state, { participantWageSectionsList }) => ({
    ...state,
    participantWageSectionsData:
    {
      isLoading: false,
      participantWageSectionsList
    }
  })),
  on(EmployerWageProcessingActions.getWageFileParticipantContributionFailure, (state) => ({
    ...state,
    participantWageSectionsData: {
      isLoading: false,
      participantWageSectionsList:
      {
        total: 0,
        filterTypes: [],
        wageRecordSections: []
      }
    }
  })),

  on(EmployerWageProcessingActions.clearEmployerWageRecordSectionState, (state) => ({
    ...state,
    wageRecordState: undefined
  })),
  on(EmployerWageProcessingActions.createParticipantWageSectionsSuccess, (state, { recordId }) => ({
    ...state,
    wageRecordState: {
      actionState: {
        action: ACTION.ADD,
        state: STATE.SUCCESS
      },
      newlyAddedWageRecordId: recordId
    }
  })),
  on(EmployerWageProcessingActions.createParticipantWageSectionsFailure, (state, { }) => ({
    ...state,
    wageRecordState: {
      actionState: {
        action: ACTION.ADD,
        state: STATE.FAIL
      }
    }
  })),

  on(EmployerWageProcessingActions.getParticipantWageSectionDetail, (state) => ({
    ...state,
    participantWageSectionDetailData: {
      isLoading: true,
      participantWageSectionDetail: undefined
    }
  })),
  on(EmployerWageProcessingActions.getParticipantWageSectionDetailSuccess, (state, { participantWageSectionDetail }) => ({
    ...state,
    participantWageSectionDetailData: {
      isLoading: false,
      participantWageSectionDetail
    }
  })),
  on(EmployerWageProcessingActions.getParticipantWageSectionDetailFailure, (state) => ({
    ...state,
    participantContributionData: {
      isLoading: false,
      participantWageSectionDetail: undefined
    }
  })),

  on(EmployerWageProcessingActions.clearEmployerWageRecordConfigurationState, (state) => ({
    ...state,
    setEmployerWageRecordListConfigurationState: undefined
  })),

  on(EmployerWageProcessingActions.setWageRecordSectionDetailSuccess, (state) => ({
    ...state,
    wageRecordState: {
      actionState: {
        action: ACTION.EDIT,
        state: STATE.SUCCESS
      }
    }
  })),
  on(EmployerWageProcessingActions.setWageRecordSectionDetailFailure, (state) => ({
    ...state,
    wageRecordState: {
      actionState: {
        action: ACTION.EDIT,
        state: STATE.FAIL
      }
    }
  })),

  on(EmployerWageProcessingActions.removeParticipantWageSectionSuccess, (state, { sectionName }) => ({
    ...state,
    wageRecordState: {
      actionState: {
        action: ACTION.REMOVE,
        state: STATE.SUCCESS
      },
      sectionName: sectionName
    }
  })),
  on(EmployerWageProcessingActions.removeParticipantWageSectionFailure, (state, { sectionName }) => ({
    ...state,
    wageRecordState: {
      actionState: {
        action: ACTION.REMOVE,
        state: STATE.FAIL
      },
      sectionName: sectionName
    }
  })),

  on(EmployerWageProcessingActions.removeWageRecordSectionFieldSuccess, (state, {fieldName}) => ({
    ...state,
    wageRecordFieldState: {
      actionState: {
        action: ACTION.REMOVE,
        state: STATE.SUCCESS
      },
      fieldName: fieldName
    }
  })),
  on(EmployerWageProcessingActions.removeWageRecordSectionFieldFailure, (state, {fieldName}) => ({
    ...state,
    wageRecordFieldState: {
      actionState: {
        action: ACTION.REMOVE,
        state: STATE.FAIL
      },
      fieldName: fieldName
    }
  })),
  on(EmployerWageProcessingActions.clearWageRecordSectionFieldState, (state) => ({
    ...state,
    wageRecordFieldState: undefined
  })),

  on(EmployerWageProcessingActions.getAvailableWageRecordSectionFields, (state) => ({
    ...state,
    availableWageRecordFieldsState: {
      isLoading: true,
      availables: {
        fields: []
      }
    }
  })),
  on(EmployerWageProcessingActions.getAvailableWageRecordSectionFieldsSuccess, (state, {availables}) => ({
    ...state,
    availableWageRecordFieldsState: {
      isLoading: false,
      availables: availables
    }
  })),
  on(EmployerWageProcessingActions.getAvailableWageRecordSectionFieldsFailure, (state) => ({
    ...state,
    availableWageRecordFieldsState: {
      isLoading: false,
      availables: {
        fields: []
      }
    }
  })),

  on(EmployerWageProcessingActions.createWageRecordSectionField, (state) => ({
    ...state,
    wageRecordFieldState: undefined
  })),

  on(EmployerWageProcessingActions.createWageRecordSectionFieldSuccess, (state, {fieldName}) => ({
    ...state,
    wageRecordFieldState: {
      actionState: {
        action: ACTION.ADD,
        state: STATE.SUCCESS
      },
      fieldName: fieldName
    }
  })),
  on(EmployerWageProcessingActions.createWageRecordSectionFieldFailure, (state, {fieldName}) => ({
    ...state,
    wageRecordFieldState: {
      actionState: {
        action: ACTION.ADD,
        state: STATE.FAIL
      },
      fieldName: fieldName
    }
  })),

  on(EmployerWageProcessingActions.setWageRecordSectionField, (state) => ({
    ...state,
    wageRecordFieldState: undefined
  })),
  on(EmployerWageProcessingActions.setWageRecordSectionFieldSuccess, (state, {fieldName}) => ({
    ...state,
    wageRecordFieldState: {
      actionState: {
        action: ACTION.EDIT,
        state: STATE.SUCCESS
      },
      fieldName: fieldName
    }
  })),
  on(EmployerWageProcessingActions.setWageRecordSectionFieldFailure, (state, {fieldName}) => ({
    ...state,
    wageRecordFieldState: {
      actionState: {
        action: ACTION.EDIT,
        state: STATE.FAIL
      },
      fieldName: fieldName
    }
  })),  
  on(EmployerWageProcessingActions.getWageRecordCommentsSuccess, (state, { comments, total }) => ({
    ...state,
    wageRecordComment: {
      isLoading: false,
      comments: {
        comments,
        total
      }
    }
  })),
  on(EmployerWageProcessingActions.getWageRecordDetailsFailure, (state) => ({
    ...state,
    wageRecordComment: {
      isLoading: false,
      comments: {
        comments: [],
        total: 0
      }
    }
  })),
  on(EmployerWageProcessingActions.getWageRecordDetails, (state) => ({
    ...state,
    wageRecordSectionData: {
      isLoading: true,
      wageRecordSectionData: {
        options: [],
        sections: []
      }
    }
  })),
  on(EmployerWageProcessingActions.getWageRecordDetailsSuccess, (state, { wageRecordSectionData, options }) => ({
    ...state,
    wageRecordSectionData: {
      isLoading: false,
      wageRecordSectionData: {
        options: options,
        sections: wageRecordSectionData
      }
    }
  })),
  on(EmployerWageProcessingActions.getWageRecordDetailsFailure, (state) => ({
    ...state,
    wageRecordSectionData: {
      isLoading: false,
      wageRecordSectionData: {
        options: [],
        sections: []
      }
    }
  })),
  on(EmployerWageProcessingActions.getWageRecordDetailsExceptions, (state) => ({
    ...state,
    wageRecordDetailException: {
      isLoading: true,
      total: 0,
      columns: [],
      exceptions: [],
      displayName: ''
    }
  })),
  on(EmployerWageProcessingActions.getWageRecordDetailsExceptionsSuccess, (state, { exceptions, columns, total, displayName }) => ({
    ...state,
    wageRecordDetailException: {
      isLoading: false,
      total,
      columns,
      exceptions,
      displayName
    }
  })),
  on(EmployerWageProcessingActions.getWageRecordDetailsExceptionsFailure, (state) => ({
    ...state,
    wageRecordDetailException: {
      isLoading: false,
      total: 0,
      columns: [],
      exceptions: [],
      displayName: ''
    }
  })),
  on(EmployerWageProcessingActions.runAuditWageFile, (state) => ({
    ...state,
    runAuditWageFileState: {
      auditing: true,
      state: ''
    }
  })),
  on(EmployerWageProcessingActions.runAuditWageFileSuccess, (state) => ({
    ...state,
    runAuditWageFileState: {
      auditing: false,
      state: STATE.SUCCESS
    }
  })),
  on(EmployerWageProcessingActions.runAuditWageFileFailure, (state, error) => ({
    ...state,
    runAuditWageFileState: {
      auditing: false,
      state: STATE.FAIL,
      error: error
    }
  })),
  on(EmployerWageProcessingActions.clearRunAuditWageFileState, (state) => ({
    ...state,
    runAuditWageFileState: {
      auditing: false,
      state: undefined,
      error: undefined
    }
  })),
  on(EmployerWageProcessingActions.rejectWageFileSuccess, (state) => ({
    ...state,
    rejectWageFileState: {
      state: STATE.SUCCESS,
      action: ACTION.REJECT
    }
  })),
  on(EmployerWageProcessingActions.rejectWageFileFailure, (state, error) => ({
    ...state,
    rejectWageFileState: {
      state: STATE.FAIL,
      action: ACTION.REJECT
    }
  })),
  on(EmployerWageProcessingActions.clearRejectWageFileState, (state) => ({
    ...state,
    rejectWageFileState: undefined
  })),
);