<div class="profile-overview-configuration-detail">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>

  <div class="flex flex-col p-6 gap-6">
    <ptg-banner
      *ngIf="bannerType"
      [(bannerType)]="bannerType"
      [message]="message"
    ></ptg-banner>

    <div class="wrap-btn">
      <button
        mat-raised-button
        type="submit"
        class="submit-button"
        [disabled]="!isChanged"
        (click)="formSubmit$.next()"
      >
        Save
      </button>
      <button
        mat-raised-button
        type="button"
        class="cancel-button"
        (click)="onCancel()"
      >
        Cancel
      </button>
    </div>

    <div class="form-control-container">
      <div class="flex gap-5">
        <div class="left-controls flex flex-col">
          <form
            *ngIf="editForm"
            [formGroup]="editForm"
            class="row-controls flex flex-col"
          >
            <div class="row-controls flex">
              <ptg-textbox
                placeholder="View Name"
                errorAsync="View Name already exists."
                [ngClass]="{
                  'has-error': nameCtrl.errors && nameCtrl.touched
                }"
                [width]="'300px'"
                [controlField]="nameCtrl"
                [hasLabel]="true"
                [maxLength]="100"
                (valueChange)="onChangeData()"
              ></ptg-textbox>

              <div>
                <ptg-select
                  [placeholder]="'Status'"
                  [controlField]="statusCtrl"
                  [listData]="notUsedStatusOptions"
                  [isOptionObj]="true"
                  [width]="'300px'"
                ></ptg-select>
                <mat-error
                  *ngIf="!selectedStatuses?.length && statusCtrl.touched"
                  class="inline-error-msg"
                >
                  Status is required.
                </mat-error>
              </div>
            </div>

            <div class="row-controls flex">
              <ptg-select
                [placeholder]="'Display Non-List Section without Data'"
                [controlField]="displayNonListSectionWODataCtrl"
                [listData]="displayNonListSectionWODataOptions"
                [isOptionObj]="true"
                [width]="'300px'"
                (changeOptionValue)="onChangeData()"
              ></ptg-select>

              <ptg-select
                [placeholder]="'Display List Section without Data'"
                [controlField]="displayListSectionWODataCtrl"
                [listData]="displayListSectionWODataOptions"
                [isOptionObj]="true"
                [width]="'300px'"
                (changeOptionValue)="onChangeData()"
              ></ptg-select>
            </div>
          </form>

          <div class="divider"></div>

          <div class="row-controls flex">
            <ptg-select
              panelClass="property-name-dropdown"
              [placeholder]="'Section Name'"
              [controlField]="sectionNameCtrl"
              [listData]="availableSectionOptions"
              [isOptionObj]="true"
              [width]="'300px'"
              (changeOptionValue)="onChangeSectionName()"
            ></ptg-select>

            <ptg-textbox
              [placeholder]="'Section Label'"
              [width]="'300px'"
              [controlField]="sectionLabelCtrl"
              [hasLabel]="true"
              customError="duplicatedValue"
            ></ptg-textbox>
          </div>

          <div *ngIf="propertyOptions.length" class="row-controls">
            <div class="flex flex-wrap">
              <ng-container *ngFor="let option of propertyOptions">
                <div class="checkbox-container" *ngIf="!option.disabled">
                  <mat-checkbox
                    [checked]="option.checked"
                    (change)="onChangeOptions(option)"
                  >
                    {{ option.value.propertyName }}
                  </mat-checkbox>
                </div>
              </ng-container>
            </div>

            <mat-error
              *ngIf="optionsCtrl.touched && optionsCtrl.errors"
              class="mt-1"
            >
              At least one item is required.
            </mat-error>
          </div>

          <div class="row-controls flex">
            <ptg-button
              [buttonName]="'Add Section'"
              classInput="add-button"
              type="button"
              (clickButton)="onClickAddSection()"
            >
              <mat-icon>add</mat-icon>
            </ptg-button>
          </div>
        </div>

        <div class="flex">
          <div class="flex">
            <div
              class="add-btn"
              [ngClass]="{ 'add-btn-disable': isAddStatusDisabled }"
            >
              <mat-icon
                (click)="!isAddStatusDisabled ? onClickAddStatus() : null"
              >
                add_circle
              </mat-icon>
            </div>

            <div class="chips-style">
              <mat-chip-list>
                <mat-chip *ngFor="let status of selectedStatuses">
                  <mat-icon
                    *ngIf="status?.iconName"
                    [style.color]="status?.color"
                  >
                    {{ status?.iconName }}
                  </mat-icon>
                  <span>{{ status?.name }}</span>
                  <div class="remove-btn" *ngIf="!isStatic">
                    <mat-icon (click)="onClickRemoveStatus(status)">
                      clear
                    </mat-icon>
                  </div>
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex gap-5">
      <div class="flex-1">
        <div class="box-info">
          <div class="box-info-header">Dataless Display Preferences</div>

          <div class="box-info-content">
            <ptg-grid
              #gridDataless
              [data]="configuredSections"
              [columns]="datalessColumns"
              [isLoading]="isLoadingSections"
              [paginable]="false"
              [notFoundMessage]="''"
            >
              <ng-template cellContent columnName="showSectionDataless" let-row>
                <ptg-toggle-button
                  [disabled]="row.deleted"
                  [(value)]="row.showSectionDataless"
                  (valueChange)="onChangeDatalessToggle()"
                ></ptg-toggle-button>
              </ng-template>

              <ng-template
                cellContent
                columnName="showPropertyDataless"
                let-row
              >
                <ptg-toggle-button
                  *ngIf="row.sectionType !== SectionLayout.List"
                  [disabled]="row.deleted"
                  [(value)]="row.showPropertyDataless"
                  (valueChange)="onChangeDatalessToggle()"
                ></ptg-toggle-button>
              </ng-template>
            </ptg-grid>
          </div>
        </div>
      </div>

      <div class="flex-1">
        <div class="box-info">
          <div class="box-info-header">Left Column</div>

          <div class="box-info-content">
            <ptg-grid
              #gridLeftSections
              [data]="leftSections"
              [columns]="configuredSectionColumns"
              [isLoading]="isLoadingSections"
              [paginable]="false"
              [notFoundMessage]="''"
              [allowDragDrop]="true"
              [hideHeader]="true"
              [inlineEditable]="true"
              [softDeletable]="true"
              (softDelete)="onSoftDeleteSectionConfig($event)"
              (change)="onChangeData()"
            >
              <ng-template cellContent columnName="name" let-row>
                <div class="multiple-line-text">
                  <span class="caption">{{ row.name }}</span>
                  <span class="description">{{ row.sectionName }}</span>
                </div>
              </ng-template>

              <ng-template cellContent [columnName]="ACTION_COLUMN" let-row>
                <ng-container *ngIf="!row.editing">
                  <ptg-button
                    (clickButton)="onClickMove2Right(row)"
                    classInput="edit-button"
                  >
                    <mat-icon>arrow_right_alt</mat-icon>
                  </ptg-button>
                </ng-container>
              </ng-template>
            </ptg-grid>
          </div>
        </div>
      </div>

      <div class="flex-1">
        <div class="box-info">
          <div class="box-info-header">Right Column</div>

          <div class="box-info-content">
            <ptg-grid
              #gridRightSections
              [data]="rightSections"
              [columns]="configuredSectionColumns"
              [isLoading]="isLoadingSections"
              [paginable]="false"
              [notFoundMessage]="''"
              [allowDragDrop]="true"
              [hideHeader]="true"
              [inlineEditable]="true"
              [softDeletable]="true"
              (softDelete)="onSoftDeleteSectionConfig($event)"
              (change)="onChangeData()"
            >
              <ng-template cellContent columnName="name" let-row>
                <div class="multiple-line-text">
                  <span class="caption">{{ row.name }}</span>
                  <span class="description">{{ row.sectionName }}</span>
                </div>
              </ng-template>

              <ng-template cellContent [columnName]="ACTION_COLUMN" let-row>
                <ng-container *ngIf="!row.editing">
                  <ptg-button
                    (clickButton)="onClickMove2Left(row)"
                    classInput="edit-button"
                  >
                    <mat-icon style="transform: scaleX(-1)">
                      arrow_right_alt
                    </mat-icon>
                  </ptg-button>
                </ng-container>
              </ng-template>
            </ptg-grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
