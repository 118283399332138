import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { BaseComponent } from '@ptg-shared/components';
import { DocumentFilter } from '../../services/models/documents.model';

export interface ChipData {
  contents: ContentConfig[];
}

export interface ContentConfig {
  value: string;
  className:
  | 'normal'
  | 'italic'
  | 'colored'
  | 'normal not-space-after'
  | 'colored not-space-after';
}

@Component({
  selector: 'ptg-document-view-current-filter',
  templateUrl: './document-view-current-filter.component.html',
  styleUrls: ['./document-view-current-filter.component.scss'],
})
export class DocumentViewCurrentFilterComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() currentFilters!: DocumentFilter;
  @Input() canRemove: boolean = false;

  @Output() removed: EventEmitter<DocumentFilter> = new EventEmitter<DocumentFilter>();
  @Output() notExistsFilter: EventEmitter<boolean> = new EventEmitter<boolean>();

  listChipData: ChipData[] = [];
  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  removeFilter(index: number) {
    this.listChipData.splice(index, 1);
  }

  getChipData(documentFilter: DocumentFilter): ChipData[] {
    let chipData: ChipData[] = [];
    let result: ContentConfig[] = [];
    if (documentFilter.documentName) {
      result.push({ value: 'Document Name', className: 'normal' });
      result.push({ value: 'is', className: 'italic' });
      result.push({ value: documentFilter.documentName, className: 'colored' });
      chipData.push({ contents: result });
    }
    if (documentFilter.fileName) {
      result = [];
      result.push({ value: 'File Name', className: 'normal' });
      result.push({ value: 'is', className: 'italic' });
      result.push({ value: documentFilter.fileName, className: 'colored' });
      chipData.push({ contents: result });
    }
    if (documentFilter.uploadedFrom || documentFilter.uploadedTo) {
      result = [];
      if (documentFilter.uploadedFrom && !documentFilter.uploadedTo) {
        result.push({ value: 'Uploaded Date', className: 'normal' });
        result.push({ value: 'is after', className: 'italic' });
        result.push({ value: documentFilter.uploadedFrom ?? '', className: 'colored' });
      } else if (!documentFilter.uploadedFrom && documentFilter.uploadedTo) {
        result.push({ value: 'Uploaded Date', className: 'normal' });
        result.push({ value: 'earlier than', className: 'italic' });
        result.push({ value: documentFilter.uploadedTo ?? '', className: 'colored' });
      } else {
        const uploadedFrom = documentFilter.uploadedFrom ?? '';
        const uploadedTo = documentFilter.uploadedTo ?? '';
        result.push({ value: 'Uploaded Date', className: 'normal' });
        result.push({ value: 'is between', className: 'italic' });
        result.push({ value: `${uploadedFrom} - ${uploadedTo}`, className: 'colored' });
      }
      chipData.push({ contents: result });
    }

    if (documentFilter.types && documentFilter.types.length > 0) {
      result = [];
      if (documentFilter.types.length === 1) {
        result.push({ value: 'File Extension', className: 'normal' });
        result.push({ value: 'is', className: 'italic' });
        result.push({ value: documentFilter.typesDisplay[0], className: 'colored' });
      }

      if (documentFilter.types.length > 1) {
        result.push({ value: 'File Extension', className: 'normal' });
        result.push({ value: 'are', className: 'italic' });
        result.push({ value: documentFilter.typesDisplay.join(', '), className: 'colored' });
      }
      chipData.push({ contents: result });
    }

    if (documentFilter.tags && documentFilter.tags.length > 0) {
      result = [];
      if (documentFilter.tags.length === 1) {
        result.push({ value: 'Document Tag', className: 'normal' });
        result.push({ value: 'is', className: 'italic' });
        result.push({ value: documentFilter.tagsDisplay[0], className: 'colored' });
      }
      if (documentFilter.tags.length > 1) {
        result.push({ value: 'Document Tag', className: 'normal' });
        result.push({ value: 'are', className: 'italic' });
        result.push({ value: documentFilter.tagsDisplay.join(', '), className: 'colored' });
      }
      chipData.push({ contents: result });
    }

    if (documentFilter.showOnOverview !== null && documentFilter.showOnOverview !== undefined) {
      result = [];
      result.push({ value: 'Show on Overview', className: 'normal' });
      result.push({ value: 'is', className: 'italic' });
      result.push({ value: documentFilter.showOnOverview ? 'Yes' : 'No', className: 'colored' });
      chipData.push({ contents: result });
    }
    if (chipData.length === 0) {
      this.notExistsFilter.emit();
    }
    return chipData;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentFilters) {
      this.listChipData = this.getChipData(this.currentFilters);
    }
  }
}
