<div class="benefit-participants">
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
    [settings]="settings"
  ></ptg-breadcrumb>
  <ptg-grid
    *ngIf="columns?.length"
    [data]="membersBenefitsData"
    [columns]="columns"
    [isLoading]="isLoading"
    [paginable]="membersBenefitsData.length > 0"
    [totalRecords]="lengthPg"
    [pageSize]="pageSize"
    [pageNumber]="pageNumber"
    (pageChange)="changePagging($event)"
    (sortChange)="sortChange($event)"
    [errorMessage]="errorMsg"
    [fitToParent]="true"
    [hideScrollbar]="false"
    notFoundMessage="No Eligible Participants to Establish Benefit"
  >
    <ng-template cellContent columnName="action" let-row>
      <ptg-button
        buttonName="Edit"
        classInput="edit-button"
        (clickButton)="onEditClick(row)"
      >
        <mat-icon>edit</mat-icon>
      </ptg-button>
    </ng-template>
  </ptg-grid>
  <div *ngIf="!isLoading && !memberData?.memberList?.columns?.length">
    <div
      class="data-not-found"
      [ngClass]="{
        'no-columns-display': !memberData?.memberList?.columns?.length
      }"
    >
      <mat-icon *ngIf="!memberData?.memberList?.columns?.length">
        grid_off
      </mat-icon>
      <div class="message">
        {{
          !memberData?.memberList?.columns?.length
            ? "List not yet configured"
            : "No Eligible Participants to Establish Benefit"
        }}
      </div>
    </div>
  </div>
</div>
