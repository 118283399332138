import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

import { BaseComponent } from '@ptg-shared/components';
import {
  ACTION_COLUMN,
  Align,
  Column,
  GridComponent,
  ReorderInfo,
  Row,
  getValidatorsFromColumns,
} from '@ptg-shared/controls/grid';
import {
  COMMA_SEPARATOR,
  VERTICAL_LINE_SEPARATOR,
} from '@ptg-shared/constance/common.const';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import {
  ADMIN_SYSTEM,
  CANCEL_CONFIRM_MESSAGE,
  ParticipantPropertyType,
  SORT_TYPE,
} from '@ptg-shared/constance/value.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { RadioOption } from '@ptg-shared/controls/radio-button/radio-button.component';
import { Option } from '@ptg-shared/controls/select/select.component';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';

import {
  CheckExitsRequest,
  CheckExitsResponse,
  CheckboxOption,
  ColumnDetail,
  PropertyDisplayConfig,
  PropertyDisplayConfiguration,
  SectionConfig,
  SectionMetadata,
} from '../../types/models';
import { AddColumnSetComponent } from '../add-column-set/add-column-set.component';
import { MemberListService } from '@ptg-member/services/member-list.service';


@Component({
  selector: 'ptg-property-display-configuration',
  templateUrl: './property-display-configuration.component.html',
  styleUrls: ['./property-display-configuration.component.scss'],
})
export class PropertyDisplayConfigurationComponent
  extends BaseComponent
  implements OnChanges
{
  readonly ACTION_COLUMN = ACTION_COLUMN;
  ADMIN_SYSTEM = ADMIN_SYSTEM;
  @ViewChild('sortPropertyTable')
  gridview!: GridComponent<PropertyDisplayConfiguration>;
  @ViewChild('sortRowTable')
  gridviewRow!: GridComponent<PropertyDisplayConfiguration>;

  @Input() propertyConfigs: PropertyDisplayConfig[] | SectionMetadata[] = [];
  @Input() propertyDisplayConfigurations: PropertyDisplayConfiguration[] = [];
  @Input() addPropertySection: SectionConfig = {
    title: 'Add Property',
    columnName: 'Column Name',
    propertyName: 'Property Name',
  };
  @Input() sortPropertySection: SectionConfig = { title: 'Sort Property' };
  @Input() sortRowSection!: SectionConfig;
  @Input() columnNameMaxLength: number = 150;
  @Input() isSectionConfiguration: boolean = false;
  @Input() isMemberListConfiguration: boolean = false;
  @Input() canSelectMultipleOption: boolean = false;
  @Input() canSelectMultipleTimes: boolean = true;
  @Input() sortable: boolean = true;
  @Input() canSelectMultipleStatusItem: boolean = false;
  @Input() isPaymentListConfiguration: boolean = false;
  @Input() isParticipantSearchConfiguration: boolean = false;
  @Input() listColumnConfig: ColumnDetail[] = [];
  @Input() dataDetail!: any;
  @Output() onSubmitEvent: EventEmitter<PropertyDisplayConfiguration[]> =
    new EventEmitter();
  @Output() formValueChange: EventEmitter<void> = new EventEmitter();
  @Output() onSubmitEventColumnSet: EventEmitter<any> = new EventEmitter();

  orderColumns: Column[] = [
    {
      name: 'columnName',
      editable: true,
      truncate: true,
      validators: {
        required: {
          type: (obj: any) => Validators.required,
          message: (error: any, fieldName: string) =>
            `${fieldName} is required.`,
        },
        maxlength: {
          type: (obj: any) => Validators.maxLength(this.columnNameMaxLength),
          message: (error: any, fieldName: string) =>
            `Exceed the ${error.requiredLength} character limit.`,
        },
        existed: {
          type: (obj: any) => this.checkExits(obj),
          message: (error: any, fieldName: string) =>
            `${fieldName} already exists.`,
        },
      },
      controlArgs: {      
        placeholder: 'Column Name'
      },
    },
    {
      name: ACTION_COLUMN,
      align: Align.Right,
      width: '50px',
    },
  ];

  sortColumns: Column[] = [
    {
      name: 'columnName',
      truncate: true,
    },
    {
      name: ACTION_COLUMN,
      align: Align.Right,
      width: '50px',
    },
  ];

  ACTION = {
    ADD_SORT_ROW: 'addSortRow',
    EDIT_COLUMN_NAME: 'editColumnName',
    REMOVE: 'remove',
    SORT_CHANGE: 'sortChange',
  };
  sortPropertySectionDataTable: (PropertyDisplayConfiguration & Row)[] = [];
  sortRowSectionDataTable: (PropertyDisplayConfiguration & Row)[] = [];
  isLoading = true;
  formData: FormGroup = this.fb.group({
    propertyKey: this.fb.control('', [Validators.required]),
    options: '',
    columnName: this.fb.control('', [
      Validators.required,
      Validators.maxLength(this.columnNameMaxLength),
      this.checkDuplicated(),
    ]),
    sectionKey: '',
    sectionName: '',
    propertyName: '',
    aggregationId: '',
    aggregationName: '',
    headerType: '',
    calculationId: '',
    calculationName: '',
    key: this.fb.control('', [Validators.required]),
  });
  availablePropertyConfigs!: Option[] | any[];
  propertyOptions: RadioOption[] | CheckboxOption[] = [];
  canSubmit: boolean = false;
  isDragDrop: boolean = false;
  isDragDropSetting: boolean = false;
  editColumnForm!: FormGroup;
  messageExits: string = '';
  isCompareColumn: boolean = false;
  selectedStartWith: ColumnDetail = {} as ColumnDetail;
  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public dialogRef: MatDialogRef<AddColumnSetComponent>,
    private memberListService: MemberListService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.propertyConfigs || changes.propertyDisplayConfigurations) {
      this.getDataTable();
      this.getAvailablePropertyConfigs();
    }
    if (changes.columnNameMaxLength) {
      this.formData
        .get('columnName')
        ?.addValidators(Validators.maxLength(this.columnNameMaxLength));
    }
    if (changes.isSectionConfiguration && this.isSectionConfiguration) {
      this.orderColumns[0].header = {
        title: 'Section Label',
      };
      this.formData = this.fb.group({
        options: '',
        columnName: this.fb.control('', [
          Validators.required,
          Validators.maxLength(this.columnNameMaxLength),
          this.checkDuplicated(),
        ]),
        sectionKey: this.fb.control('', [Validators.required]),
        sectionName: '',
        propertyName: '',
        calculationId: '',
        calculationName: '',
      });
    }
    if (changes.listColumnConfig) {
      this.editColumnForm = this.fb.group({
        columnSetName: [
          this.dataDetail?.columSelected ? this.dataDetail?.columSelected.columnSetName : '',
          [Validators.required],
        ],
        startWith: [''],
      });
    }
  }

  ngOnInit(): void {
    this.isLoading = false;
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        if (params?.isDragDrop) {
          this.isDragDrop = params?.isDragDrop === 'true';
          this.isDragDropSetting = true
        }
      });
  }

  getDataTable() {
    if (!this.propertyConfigs?.length) {
      return;
    }
    this.propertyDisplayConfigurations =
      this.getPropertyDisplayConfigurations();
    this.sortPropertySectionDataTable = this.propertyDisplayConfigurations
      .map((config) => {
        const obj = {
          ...this.getPropertyDisplayConfig(config),
          order: config.orderColumn || config.order,
          isUsed: config.orderRow !== null || !this.sortRowSection,
        };
        return this.createInlineEditFormControls(obj);
      })
      .sort((a, b) => Number(a.order) - Number(b.order));
    this.sortRowSectionDataTable = this.propertyDisplayConfigurations
      .reduce((result, config) => {
        if (config.orderRow) {
          result.push({
            ...this.getPropertyDisplayConfig(config),
            order: config.orderRow,
            sortType: config.sortType,
          });
        }
        return result;
      }, [] as PropertyDisplayConfiguration[])
      .sort((a, b) => Number(a.order) - Number(b.order));
  }

  getPropertyDisplayConfigurations() {
    return this.propertyDisplayConfigurations.map((config) => {
      const cloneConfig = Object.assign({}, config); 
      if (!this.isSectionConfiguration) {
        const item = (this.propertyConfigs as PropertyDisplayConfig[]).find(
          (property) => {
            if (property.aggregationId) {
              return property.aggregationId === config.aggregationId;
            }
            return this.isExisted(
              config,
              {
                sectionKey: property.sectionKey,
                propertyKey: property.propertyKey,
              } as any,
              false
            );
          }
        ) as PropertyDisplayConfig;
        if (item) {
          cloneConfig.propertyName = item.propertyName;
          cloneConfig.sectionName = item.sectionName;
          cloneConfig.headerType = item.headerType;
          cloneConfig.optionName = cloneConfig.options
            ?.split('|')
            .map(
              (el: any) => item.options?.find((item) => item.key === el)?.value
            )
            .join(', ');
          if (item.headerType === ParticipantPropertyType.Aggregation) {
            cloneConfig.aggregationName = item.aggregationName;
          } else if (item.headerType === ParticipantPropertyType.Calculation) {
            cloneConfig.calculationId = item.calculationId;
            cloneConfig.calculationName = item.calculationName;
          }
        }
      }
      return cloneConfig;
    });
  }

  createDescription(formValue: any, optionName: string) {
    if (this.isPaymentListConfiguration) {
      return formValue.sectionName;
    }

    if (this.isSectionConfiguration) {
      return formValue.sectionName;
    }
    if (
      formValue.headerType === ParticipantPropertyType.Aggregation ||
      formValue?.aggregationId
    ) {
      return `${formValue.sectionName} / ${formValue.propertyName} / ${formValue.aggregationName}`;
    }

    if (formValue.headerType === ParticipantPropertyType.Calculation) {
      return 'Calculation';
    }

    return `${formValue.sectionName} / ${formValue.propertyName}${
      formValue.options ? ` / ${optionName}` : ''
    }`;
  }

  getPropertyDisplayConfig(config: PropertyDisplayConfiguration) {
    return {
      columnName: config.columnName,
      columnNameDescription: this.createDescription(
        config,
        config.optionName as string
      ),
      id: config.id,
      clientKey: config.clientKey,
      sectionKey: config.sectionKey,
      propertyKey: config.propertyKey,
      sectionName: config.sectionName,
      propertyName: config.propertyName,
      options: config.options,
      aggregationId: config.aggregationId,
      aggregationName: config.aggregationName,
      headerType: config.headerType,
      calculationId: config.calculationId,
      calculationName: config.calculationName,
    };
  }

  getAvailablePropertyConfigs() {
    this.availablePropertyConfigs = (this.propertyConfigs as any).reduce(
      (result: Option[] | any, propertyConfig: any) => {
        const isExisted = this.propertyDisplayConfigurations.find((item) => {
          if (item.aggregationId) {
            return item.aggregationId === propertyConfig.aggregationId;
          }
          return this.isExisted(item, propertyConfig);
        });
        if (isExisted) {
          return result;
        }
        if (this.isSectionConfiguration) {
          result.push({
            value: propertyConfig.sectionKey,
            displayValue: propertyConfig.sectionName,
          });
        } else {
          if (this.isParticipantSearchConfiguration) {
            if (
              propertyConfig?.headerType ===
              ParticipantPropertyType.ParticipantMetadata
            ) {
              result.push({
                value: this.getValueProperty(propertyConfig),
                displayValue: this.getValueDisplayProperty(propertyConfig),
                valueDescription: this.getValueDescription(propertyConfig),
              });
            }
          } else {
            result.push({
              value: this.getValueProperty(propertyConfig),
              displayValue: this.getValueDisplayProperty(propertyConfig),
              valueDescription: this.getValueDescription(propertyConfig),
            });
          }
        }
        return result;
      },
      [] as Option[]
    );
  }

  private getValueProperty(propertyConfig: any): string {
    if (propertyConfig.headerType === ParticipantPropertyType.Aggregation) {
      return propertyConfig?.aggregationId;
    } else if (
      propertyConfig.headerType === ParticipantPropertyType.ParticipantMetadata
    ) {
      return propertyConfig.propertyKey + propertyConfig.sectionKey;
    } else if (
      propertyConfig.headerType === ParticipantPropertyType.Calculation
    ) {
      return propertyConfig?.calculationId;
    } else {
      return '';
    }
  }

  private getValueDisplayProperty(propertyConfig: any): string {
    if (propertyConfig.headerType === ParticipantPropertyType.Aggregation) {
      return propertyConfig?.aggregationName;
    } else if (
      propertyConfig.headerType === ParticipantPropertyType.ParticipantMetadata
    ) {
      return propertyConfig?.propertyName;
    } else if (
      propertyConfig.headerType === ParticipantPropertyType.Calculation
    ) {
      return propertyConfig?.calculationName;
    } else {
      return '';
    }
  }

  private getValueDescription(propertyConfig: any): string {
    if (propertyConfig.headerType === ParticipantPropertyType.Aggregation) {
      return `${propertyConfig?.sectionName} / ${propertyConfig?.propertyName} / ${propertyConfig?.aggregationName}`;
    } else if (
      propertyConfig.headerType === ParticipantPropertyType.ParticipantMetadata
    ) {
      return propertyConfig?.sectionName;
    } else if (
      propertyConfig.headerType === ParticipantPropertyType.Calculation
    ) {
      return 'Calculation';
    } else {
      return '';
    }
  }

  changeItem(event: ReorderInfo, isSortColumn = false) {
    this.canSubmit = true;
    this.isCompareColumn = true;
    this.formValueChange.emit();
    if (isSortColumn) {
      this.sortPropertySectionDataTable = this.gridview.dataSource;
      return;
    }
    this.sortRowSectionDataTable = this.gridviewRow.dataSource;
  }
  onSoftDeleteSectionConfig(row: PropertyDisplayConfiguration & Row): void {
    row.deleted = true;
    const sortItem = this.sortPropertySectionDataTable.find(
      (item) =>
        item.sectionKey === row.sectionKey &&
        item.propertyKey === row.propertyKey &&
        (!item.options || item.options === row.options)
    );
    if (sortItem) {
      sortItem.deleted = true;
    }

    const index = this.sortRowSectionDataTable.findIndex(
      (item) =>
        item.sectionKey === row.sectionKey &&
        item.propertyKey === row.propertyKey &&
        (!item.options || item.options === row.options)
    );
    if (index > -1) {
      this.sortRowSectionDataTable[index].deleted = true;
      this.sortRowSectionDataTable = [...this.sortRowSectionDataTable];
    }
  }
  onRowActions(
    event: { row: PropertyDisplayConfiguration; type: string },
    isSortColumn = false
  ) {
    this.canSubmit = true;
    this.isCompareColumn = true;
    this.formValueChange.emit();
    switch (event.type) {
      case this.ACTION.ADD_SORT_ROW: {
        event.row.isUsed = true;
        this.addSortRow(event.row);
        break;
      }
      case this.ACTION.SORT_CHANGE: {
        this.changeSortType(event.row);
        break;
      }
      case this.ACTION.REMOVE: {
        event.row.deleted = true;
        if (isSortColumn) {
          const sortItem = this.sortPropertySectionDataTable.find(
            (item) =>
              item.sectionKey === event.row.sectionKey &&
              item.propertyKey === event.row.propertyKey &&
              (!item.options || item.options === event.row.options)
          );
          if (sortItem) {
            sortItem.deleted = true;
          }
        }

        const index = this.sortRowSectionDataTable.findIndex(
          (item) =>
            item.sectionKey === event.row.sectionKey &&
            item.propertyKey === event.row.propertyKey &&
            (!item.options || item.options === event.row.options)
        );
        if (index > -1) {
          this.sortRowSectionDataTable[index].deleted = true;
          this.sortRowSectionDataTable = [...this.sortRowSectionDataTable];
        }
      }
    }
  }

  resetAddPropertyForm() {
    this.formData.reset();
    this.propertyOptions = [];
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: CANCEL_CONFIRM_MESSAGE,
        type: ConfirmType.Cancel,
        title: 'Cancel Action',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getDataTable();
        this.getAvailablePropertyConfigs();
        this.resetAddPropertyForm();
        this.canSubmit = false;
      }
    });
  }

  changeProperty() {
    const selectedConfig = (this.propertyConfigs as any).find(
      (config: PropertyDisplayConfig | SectionMetadata | any) => {
        if (
          config?.headerType === ParticipantPropertyType.ParticipantMetadata
        ) {
          return (
            (config as PropertyDisplayConfig)?.propertyKey +
              config.sectionKey ===
            this.formData.value.key
          );
        }
        if (config?.headerType === ParticipantPropertyType.Aggregation) {
          return config.aggregationId === this.formData.value.key;
        } else if (config?.headerType === ParticipantPropertyType.Calculation) {
          return config.calculationId === this.formData.value.key;
        }

        return false;
      }
    );
    let formValue: any = {
      options: '',
      sectionKey: selectedConfig?.sectionKey || '',
      sectionName: selectedConfig?.sectionName || '',
      columnName: selectedConfig?.sectionName || '',
    };
    if (!this.isSectionConfiguration) {
      this.propertyOptions = (selectedConfig?.options || []).reduce(
        (result: any, currentValue: any) => {
          const selectedOptions = this.sortPropertySectionDataTable.reduce(
            (selectedOptionResult, currentItem) => {
              this.sortPropertySectionDataTable.forEach((property) => {
                if (
                  selectedConfig.propertyType !== 9 &&
                  property.sectionKey === selectedConfig.sectionKey &&
                  property.propertyKey === selectedConfig.propertyKey
                ) {
                  selectedOptionResult.push(
                    ...property.options.split(VERTICAL_LINE_SEPARATOR)
                  );
                }
              });
              return selectedOptionResult;
            },
            [] as any[]
          );
          let selectedItem: any = {
            value: currentValue.key,
            label: currentValue.value,
          };
          const checked = !!selectedOptions.find(
            (selectedOption: any) => selectedOption === currentValue.key
          );
          if (this.canSelectMultipleOption) {
            selectedItem = {
              checked,
              disabled: checked,
              value: {
                propertyKey: currentValue.key,
                propertyName: currentValue.value,
              },
            } as any;
          } else if (selectedConfig?.options?.length > 1 && checked) {
            return result;
          }
          result.push(selectedItem);
          return result;
        },
        [] as any[]
      );
      const firstUncheckedItem = (this.propertyOptions as any[]).find(
        (propertyOption: any) =>
          !propertyOption.checked && !propertyOption.disabled
      );
      if (firstUncheckedItem) {
        firstUncheckedItem.checked = true;
      }
      formValue = {
        ...formValue,
        options:
          (this.canSelectMultipleOption
            ? firstUncheckedItem?.value?.propertyKey
            : firstUncheckedItem?.value) || '',
        columnName: this.getValueDisplayProperty(selectedConfig),
        propertyKey: selectedConfig?.propertyKey,
        propertyName: selectedConfig?.propertyName,
        headerType: selectedConfig?.headerType,
        aggregationName: selectedConfig?.aggregationName,
        calculationId: selectedConfig?.calculationId,
        calculationName: selectedConfig?.calculationName,
      };
    } else {
      this.propertyOptions = (selectedConfig?.options || []).map(
        (option: { propertyKey: string; propertyName: string }) => {
          return { checked: false, value: option };
        }
      );
      if (!this.propertyOptions.length) {
        this.formData.get('options')?.removeValidators(Validators.required);
      } else {
        this.formData.get('options')?.addValidators(Validators.required);
      }
    }
    this.formData.patchValue(formValue);
  }

  addProperty() {
    this.formData.markAllAsTouched();
    if (!this.formData.valid) {
      return;
    }
    const formValue = this.formData.value;
    const optionName = this.getOptionName(formValue.options);
    const addedIndex = this.availablePropertyConfigs.findIndex(
      (item) =>
        item.value === formValue.key ||
        (this.isSectionConfiguration && item.value === formValue.sectionKey)
    );
    let itemAdd;
    if (addedIndex > -1) {
      itemAdd = this.availablePropertyConfigs[addedIndex];
    }

    var newRecord: PropertyDisplayConfiguration = {
      columnName: formValue.columnName,
      columnNameDescription: this.createDescription(formValue, optionName),
      order: this.sortPropertySectionDataTable?.length + 1,
      id: undefined,
      clientKey: formValue.clientKey,
      sectionKey: formValue.sectionKey,
      propertyKey: formValue.propertyKey,
      sectionName: formValue.sectionName,
      propertyName: formValue.propertyName,
      options: formValue.options,
      optionName: this.availablePropertyConfigs[addedIndex]?.displayValue,
      isUsed: !this.sortRowSection,
      columnNameMaxLength: this.columnNameMaxLength,
      aggregationId: itemAdd?.isAggregationItem ? formValue.key : '',
      aggregationName: formValue.aggregationName,
      headerType: formValue.headerType,
      calculationId: formValue.calculationId,
      calculationName: formValue.calculationName,
    };

    this.sortPropertySectionDataTable = [
      ...this.sortPropertySectionDataTable,
      this.createInlineEditFormControls(newRecord),
    ];

    this.setAvailablePropertyConfigs(addedIndex);
    this.resetAddPropertyForm();
    this.canSubmit = true;
    this.isCompareColumn = true;
    this.formValueChange.emit();
  }

  setAvailablePropertyConfigs(addedIndex: number) {
    const selectedPropertyOptions = (this.propertyOptions as any)
      .filter((item: any) => item.checked)
      .map((item: any) => item.value.propertyName);
    if (
      this.canSelectMultipleTimes &&
      (this.canSelectMultipleOption || this.propertyOptions?.length > 1) &&
      !!this.propertyOptions?.length &&
      selectedPropertyOptions?.length < this.propertyOptions?.length
    ) {
      return;
    }
    if (
      !this.canSelectMultipleOption ||
      !this.propertyOptions ||
      this.propertyOptions.length <= 1 ||
      !this.isMemberListConfiguration
    ) {
      this.availablePropertyConfigs.splice(addedIndex, 1);
    }
  }

  getOptionName(options: string) {
    if (this.canSelectMultipleOption) {
      return (this.propertyOptions as any)
        .filter((item: any) =>
          options
            .split(VERTICAL_LINE_SEPARATOR)
            .includes(item.value.propertyKey)
        )
        .map((item: any) => item.value.propertyName)
        .join(COMMA_SEPARATOR);
    }
    return (this.propertyOptions as any).find(
      (item: RadioOption) => item.value === options
    )?.label;
  }

  addSortRow(config: PropertyDisplayConfiguration) {
    if (
      this.sortRowSectionDataTable.find(
        (item) =>
          item.sectionKey === config.sectionKey &&
          item.propertyKey === config.propertyKey &&
          (!item.options || item.options === config.options)
      )
    ) {
      return;
    }
    this.canSubmit = true;
    this.isCompareColumn = true;
    this.formValueChange.emit();
    this.sortRowSectionDataTable = [
      ...this.sortRowSectionDataTable,
      {
        ...this.getPropertyDisplayConfig(config),
        columnNameDescription: config.columnNameDescription,
        order: this.sortRowSectionDataTable?.length + 1,
        sortType: this.sortable ? SORT_TYPE.ASC : undefined,
      },
    ];
  }

  onSubmit() {
    if (this.gridview.formStatus !== AbstractControlStatus.VALID) {
      return;
    }
    const result = this.getSubmitData();
    this.onSubmitEvent.emit(result);
    this.resetAddPropertyForm();
    this.canSubmit = false;
  }

  getSubmitData() {
    let order = 1;
    const result: (PropertyDisplayConfiguration & Row)[] =
      this.sortPropertySectionDataTable.map(
        (item: PropertyDisplayConfiguration & Row) => {
          const obj = {
            ...item,
            orderColumn: item.deleted ? -1 : order,
            columnName: item.form?.value?.columnName,
          };
          order += item.deleted ? 0 : 1;
          delete obj.order;
          delete obj.columnNameDescription;
          delete obj.form;
          return obj;
        }
      );
    order = 1;
    this.sortRowSectionDataTable.forEach((config) => {
      const index = result.findIndex(
        (item) =>
          item.sectionKey === config.sectionKey &&
          item.propertyKey === config.propertyKey &&
          (!item.options || item.options === config.options)
      );
      if (index < 0) {
        return;
      }
      result[index].orderRow = config.deleted ? -1 : order;
      order += 1;
      result[index].sortType = config.sortType;
    });
    return result;
  }

  changeSortType(row: PropertyDisplayConfiguration) {
    const index = this.sortRowSectionDataTable.findIndex(
      (item) =>
        item.sectionKey === row.sectionKey &&
        item.propertyKey === row.propertyKey &&
        (!item.options || item.options === row.options)
    );
    if (index < 0) {
      return;
    }
    this.sortRowSectionDataTable[index].sortType =
      this.sortRowSectionDataTable[index].sortType === SORT_TYPE.ASC
        ? SORT_TYPE.DESC
        : SORT_TYPE.ASC;
    this.sortRowSectionDataTable = [...this.sortRowSectionDataTable];
  }

  checkDuplicated(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.sortPropertySectionDataTable?.length) {
        return null;
      }
      if (
        this.sortPropertySectionDataTable?.find(
          (item: { columnName: string }) =>
            item.columnName?.trim()?.toLowerCase() ===
            control.value?.trim()?.toLowerCase()
        )
      ) {
        return {
          duplicatedValue: `${this.addPropertySection.columnName} already exists.`,
        };
      }
      return null;
    };
  }

  isExisted(
    item:
      | PropertyDisplayConfiguration
      | PropertyDisplayConfig
      | SectionMetadata,
    config:
      | PropertyDisplayConfiguration
      | PropertyDisplayConfig
      | SectionMetadata,
    checkSelectMultipleTimes: boolean = true
  ) {
    const hasOptions =
      config.options instanceof Array &&
      config.options &&
      config.options.length > 1;
    const hasUnselectedOptions = !((config?.options || []) as any[]).some(
      (option: any) =>
        !this.propertyDisplayConfigurations.some(
          (property) =>
            property.sectionKey === config.sectionKey &&
            property.propertyKey === (config as any).propertyKey &&
            property.options
              ?.split(VERTICAL_LINE_SEPARATOR)
              ?.includes(option.key)
        )
    );
    const isExistedProperty =
      item.sectionKey === config.sectionKey &&
      (this.isSectionConfiguration ||
        (item as PropertyDisplayConfig).propertyKey ===
          (config as PropertyDisplayConfig).propertyKey);
    const isAllOptionSelected = hasOptions
      ? hasUnselectedOptions
      : isExistedProperty;
    if (checkSelectMultipleTimes) {
      return (
        (this.canSelectMultipleTimes && isAllOptionSelected) ||
        (!this.canSelectMultipleTimes && isExistedProperty)
      );
    }
    return isAllOptionSelected;
  }

  selectSectionProperty() {
    const options = (this.propertyOptions as any).reduce(
      (result: string, propertyOption: CheckboxOption) => {
        if (propertyOption.checked && !propertyOption.disabled) {
          result +=
            (!result ? '' : VERTICAL_LINE_SEPARATOR) +
            propertyOption.value.propertyKey;
        }
        return result;
      },
      ''
    );
    this.formData.get('options')?.setValue(options);
  }

  onChangeOrderColumns() {
    this.canSubmit = true;
    this.isCompareColumn = true;
    if(this.listColumnConfig.length) {
      const columnSet = this.sortPropertySectionDataTable.filter(x => x?.deleted !== true).length;
      this.canSubmit = columnSet === 0 ? false : true;
    }

    this.formValueChange.emit();
  }

  private checkExits(obj: PropertyDisplayConfiguration): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const allRows = [...this.sortPropertySectionDataTable];
      const existed = allRows.some(
        (item) =>
          !(
            item.sectionKey === obj.sectionKey &&
            item.propertyKey === obj.propertyKey &&
            (!item.options || item.options === obj.options)
          ) &&
          item.form?.value?.columnName?.toLowerCase()?.trim() ===
            control.value.toLowerCase().trim()
      );
      return existed ? { existed: true } : null;
    };
  }

  private createInlineEditFormControls(
    obj: PropertyDisplayConfiguration
  ): PropertyDisplayConfiguration {
    var result = {
      ...obj,
      form: this.fb.group({
        columnName: new FormControl(
          obj.columnName,
          getValidatorsFromColumns(
            this.orderColumns[0].name,
            this.orderColumns,
            obj
          )
        ),
      }),
    };
    return result;
  }

  onChangeStartWidth() {
    const { startWith } = this.editColumnForm.value;
    this.selectedStartWith = this.listColumnConfig.find((item: ColumnDetail) => item.columnSetName === startWith)!;
    this.isCompareColumn = false;
    if(this.selectedStartWith) {
      if(this.selectedStartWith.listConfigs.length) {
        this.canSubmit = true;
      }
      this.propertyDisplayConfigurations = this.selectedStartWith.listConfigs;
      this.getDataTable();
      this.getAvailablePropertyConfigs();
      this.resetAddPropertyForm();
    }
  }

  onSubmitColumnSet(flag: boolean = false) {
    if (this.gridview.formStatus !== AbstractControlStatus.VALID || this.editColumnForm.invalid) {
      this.editColumnForm.markAllAsTouched();
      return;
    }
    const { columnSetName } = this.editColumnForm.value;
    const result: CheckExitsRequest = {
      columnSetName: columnSetName,
    };
    if(this.dataDetail?.columSelected) {
      result.id = this.dataDetail.columSelected.id;
    }
    this.memberListService.checkExitsColumnSet(result).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((res: CheckExitsResponse) => {
        if(res?.exists) {
          this.showMessageExits();
          return;
        }
        if(this.selectedStartWith?.columnSetName && !this.isCompareColumn) {
          const dialogRef = this.dialog.open(ConfirmPopupComponent, {
            panelClass: 'confirm-popup',
            data: {
              type: ConfirmType.Warning,
              title: 'Confirmation',
              cancelButtonTitle: 'No',
              text: `${this.selectedStartWith.columnSetName} has the same configuration. Do you want to save ${columnSetName}?`,
            },
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              this.updateColumnSet(flag);
            }
          });
        } else {
          this.updateColumnSet(flag);  
        }
    });
  }

  showMessageExits() {
    const { columnSetName } = this.editColumnForm.value;
    this.messageExits = `${columnSetName} is taken.`;
    this.editColumnForm.get('columnSetName')?.setErrors({inValidAsync:true});
  }

  updateColumnSet(flag: boolean = false) {
    const { columnSetName } = this.editColumnForm.value;
    const listConfigs = this.getSubmitData();
    const result: any = {
      columnSetName: columnSetName,
      auditTrail: columnSetName,
      listMetadataMemberListConfig: listConfigs,
      isDragDrop: flag ? true : false
    };
    this.onSubmitEventColumnSet.emit(result);
  }

  onCancelColumnSet() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: CANCEL_CONFIRM_MESSAGE,
        type: ConfirmType.Cancel,
        title: 'Cancel Action',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }
}
