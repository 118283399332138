import { createReducer, on } from '@ngrx/store';

import { FIRST_PAGE } from '@ptg-shared/controls/pagination';

import * as MemberNavigationActions from '../actions/member-navigation';
import { MemberNavigationState } from '../../types/models/member-detail.model';

export const memberNavigationFeatureKey = 'memberNavigation';

export interface State {
  memberNavigateState: MemberNavigationState;
}

const initialState: State = {
  memberNavigateState: {
    members: [],
    memberIndex: -1,
    totalPage: 0,
    startPage: 0,
    endPage: 0,
  },
};

export const reducer = createReducer(
  initialState,
  on(MemberNavigationActions.setMemberIndex, (state, { memberIndex }) => ({
    ...state,
    memberNavigateState: {
      ...state.memberNavigateState,
      memberIndex,
    },
  })),
  on(MemberNavigationActions.getMoreMembers, (state, { query }) => ({
    ...state,
    memberNavigateState: {
      ...state.memberNavigateState,
      query,
    },
  })),
  on(
    MemberNavigationActions.getMoreMembersSuccess,
    (state, { memberList, isAppend }) => {
      const page = state.memberNavigateState.query?.pageIndex || FIRST_PAGE;
      let startPage = state.memberNavigateState.startPage;
      let endPage = state.memberNavigateState.endPage;
      if (!startPage || startPage > page) {
        startPage = page;
      }
      if (!endPage || endPage < page) {
        endPage = page;
      }
      const memberIndex = state.memberNavigateState.members.findIndex(
        (member) =>
          member._id ===
          state.memberNavigateState.members[
            state.memberNavigateState.memberIndex
          ]._id
      );
      return {
        ...state,
        isLoading: true,
        memberNavigateState: {
          ...state.memberNavigateState,
          startPage: startPage,
          endPage: endPage,
          members: isAppend
            ? [
                ...state.memberNavigateState.members,
                ...(memberList.members || []),
              ]
            : [
                ...(memberList.members || []),
                ...state.memberNavigateState.members,
              ],
          memberIndex,
        },
      };
    }
  ),
  on(MemberNavigationActions.setQuery, (state, { query }) => {
    const page = state.memberNavigateState.query?.pageIndex || FIRST_PAGE;
    let startPage = state.memberNavigateState.startPage;
    let endPage = state.memberNavigateState.endPage;
    if (!startPage || startPage > page) {
      startPage = page;
    }
    if (!endPage || endPage < page) {
      endPage = page;
    }
    return {
      ...state,
      memberNavigateState: {
        ...state.memberNavigateState,
        query,
        startPage,
        endPage,
      },
    };
  }),
  on(MemberNavigationActions.setMembers, (state, { memberList, pageSize }) => {
    const memberIndex = state.memberNavigateState.members.findIndex(
      (member) =>
        member._id ===
        state.memberNavigateState.members[state.memberNavigateState.memberIndex]
          ._id
    );
    const totalPage = Math.ceil(memberList.total / pageSize);
    return {
      ...state,
      memberNavigateState: {
        ...state.memberNavigateState,
        members: memberList.members,
        totalPage,
        memberIndex,
      },
    };
  }),
  on(MemberNavigationActions.clearMemberNavigationState, (state) => {
    return {
      ...state,
      memberNavigateState: {
        members: [],
        memberIndex: -1,
        totalPage: 0,
        startPage: 0,
        endPage: 0,
      },
    };
  })
);
