import { Component, Input } from '@angular/core';

@Component({
  selector: 'ptg-icon-column',
  templateUrl: './icon-column.component.html',
  styleUrls: ['./icon-column.component.scss'],
})
export class IconColumnComponent {
  @Input() color!: string;
  @Input() name!: string;
  @Input() label!: string;
  @Input() style!: {
    [key: string]: string | number;
  };
}
