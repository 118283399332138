import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { ACTION } from '@ptg-shared/constance/value.const';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { deepClone, showBanner } from '@ptg-shared/utils/common.util';
import { ACTION_COLUMN, Column } from '@ptg-shared/controls/grid';
import { BaseComponent } from '@ptg-shared/components';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';

import * as ProfileOverviewConfigurationActions from '../../store/actions/profile-overview-configuration.actions';
import {
  ProfileOverview,
  ProfileOverviewList,
  PropertyDisplayConfiguration,
  SectionMetadata,
} from '../../types/models';
import * as fromReducer from '../../../reducers';
import * as fromMember from '../../store/reducers';

@Component({
  selector: 'ptg-profile-overview-configuration',
  templateUrl: './profile-overview-configuration.component.html',
  styleUrls: ['./profile-overview-configuration.component.scss'],
})
export class ProfileOverviewConfigurationComponent extends BaseComponent {
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Overview',
      url: '',
    },
    {
      name: 'Profile Overview View',
      url: '',
    },
  ];
  propertyConfigs!: SectionMetadata[];
  propertyDisplayConfigurations!: PropertyDisplayConfiguration[];
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  columns: Column[] = [
    {
      name: 'name',
      header: {
        title: 'View Name',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: 'totalProfileConfig',
      header: {
        title: 'Section Count',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: 'statuses',
      header: {
        title: 'Status',
      },
      style: {
        'max-width': 'unset',
      },
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      width: '170px',
    },
  ];
  errorMsg: string = '';
  profileOverviewsData: ProfileOverview[] = [];
  isLoading: boolean = true;
  sortInfo: Sort = {} as Sort;
  memberId: string = '';
  viewName: string = '';

  constructor(
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
    public route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store.dispatch(LayoutActions.hiddenSideMenu());
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.memberId = params.id;
      this.listBreadcrumbs[0].url = `/member/detail/${params.id}`;
    });
    this.getData();
    this.memberStore
      .pipe(select(fromMember.selectLoadingState), takeUntil(this.unsubscribe$))
      .subscribe((isLoading) => {
        this.isLoading = isLoading;
      });
    this.memberStore
      .pipe(
        select(fromMember.selectProfileOverviewList),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((selectProfileOverviewList) => {
        this.isLoading = false;
        if (
          !selectProfileOverviewList.success &&
          selectProfileOverviewList.error
        ) {
          this.errorMsg = selectProfileOverviewList.error.statusText;
        }
        const statusList = (
          deepClone(selectProfileOverviewList.payload) as ProfileOverviewList
        )?.statuses;
        if (
          !selectProfileOverviewList?.payload?.metadataProfileOverviews?.length
        ) {
          this.profileOverviewsData = [];
          return;
        }
        this.profileOverviewsData = (
          deepClone(selectProfileOverviewList.payload) as ProfileOverviewList
        )?.metadataProfileOverviews.map((profileOverview) => {
          profileOverview.statuses = statusList.filter((status) =>
            profileOverview.statuses.find(
              (item) => item.id.toLowerCase() === status.id.toLowerCase()
            )
          );
          return profileOverview;
        });
      });
    this.memberStore
      .pipe(
        select(fromMember.selectProfileOverviewConfigurationsDeleteState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        this.getData();
        showBanner.call(this, state, this.viewName, ACTION.REMOVE);
        this.memberStore.dispatch(
          ProfileOverviewConfigurationActions.clearProfileOverviewConfigurationState()
        );
      });
  }

  getData() {
    this.isLoading = true;
    let SortType = 0;
    let SortNames = '';
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      SortNames = capitalizeFirstLetter(
        this.sortInfo.active === 'disabled' ? 'active' : this.sortInfo.active
      );
      SortType = this.sortInfo.direction === 'desc' ? 1 : 0;
    }
    this.memberStore.dispatch(
      ProfileOverviewConfigurationActions.getListProfileOverviewConfiguration({
        query: {
          SortNames,
          SortType,
        },
      })
    );
  }

  sortChange(event: Sort) {
    this.sortInfo = event;
    this.getData();
  }

  addProfileConfig() {
    void this.router.navigateByUrl(
      `/member/profile-overview-configuration/${this.memberId}/detail/`
    );
  }

  removeRecord(profileOverview: ProfileOverview) {
    this.viewName = profileOverview.name;
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        title: 'Remove Item',
        text: `Are you sure you want to remove this View?`,
        type: ConfirmType.Delete,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const body = {
          id: profileOverview?.id,
        };
        this.store.dispatch(
          ProfileOverviewConfigurationActions.deleteProfileOverviewConfiguration(
            body
          )
        );
      }
    });
  }

  editRecord(profileOverview: ProfileOverview) {
    void this.router.navigateByUrl(
      `/member/profile-overview-configuration/${this.memberId}/detail/${profileOverview.id}`
    );
  }
}
