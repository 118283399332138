import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { HistoryService } from 'src/app/shared/services/history.service';
import { NextPaymentService } from '../../services/next-payment.service';
import * as fromNextPayment from '../../store/reducers/index';
import { MemberState } from '../../store/reducers/index';

@Component({
  selector: 'ptg-payment-history-screen',
  templateUrl: './payment-history-screen.component.html',
  styleUrls: ['./payment-history-screen.component.scss']
})
export class PaymentHistoryScreenComponent implements OnInit, OnDestroy {
  subscription: any;
  isLoading = true;
  dataTable: any[] = [];
  listBreadcrumbsHistory = [
    {name: 'Payment History'}
  ];
  
  constructor(
    private nextPaymentService: NextPaymentService,
    private store: Store<MemberState>,
    public historyService: HistoryService
  ) { }

  ngOnInit(): void {
    this.subscription = this.store.pipe(select(fromNextPayment.selectNextPaymentHistory)).subscribe(el => {
      this.isLoading = el?.isLoading!;
      this.dataTable = el?.payload?.paymentHistories!;
    });
  }

  ngOnDestroy(): void {
    this.nextPaymentService.payeeId = '';
    this.nextPaymentService.payrollId = '';
  }
}
