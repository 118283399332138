import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as RelatedPersonAction from '../actions/related-person.action';
import {
  RelatedPersonList,
} from '../../types/models';
import { RelatedPersonService } from '../../services/related-person.service';

@Injectable()
export class RelatedPersonEffects {
  getRelatedPersonList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RelatedPersonAction.getRelatedPersonList),
      switchMap(({ query }) => {
        return this.relatedPersonService.getRelatedPersonList(query).pipe(
          map((relatedPersonList: RelatedPersonList) => {
            return RelatedPersonAction.getRelatedPersonListSuccess({
              relatedPersonList,
            });
          }),
          catchError((err) => {
            return of(RelatedPersonAction.getRelatedPersonListFailure());
          })
        );
      })
    )
  );  
  constructor(
    private actions$: Actions,
    private relatedPersonService: RelatedPersonService
  ) {}
}
