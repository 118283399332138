import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { GetParameterMappingType } from '@ptg-member/features/calculation/types/enums';
import {
  CalculationParameters,
  ParameterMappings,
  UpdateCalculationBenefitDetailRequest,
} from '@ptg-member/features/calculation/services/models';
import { SHOW_LOADING } from '@ptg-interceptor/httpconfig.interceptor';

@Injectable({
  providedIn: 'root',
})
export class CalculationParameterConfigurationService {
  constructor(private httpClient: HttpClient) {}

  getParameterMappings(parameterType?: GetParameterMappingType): Observable<ParameterMappings> {
    return this.httpClient.get<ParameterMappings>(
      `${environment.apiUrl}/calculations/calculation-options/parameter-mapping/${parameterType ?? ''}`,
    );
  }

  getCalculationParameter(): Observable<CalculationParameters> {
    return this.httpClient.get<CalculationParameters>(
      `${environment.apiUrl}/calculations/calculation-options/calculation-parameter/0`,
    );
  }

  setCalculationParameter(request: CalculationParameters): Observable<void> {
    return this.httpClient.post<void>(
      `${environment.apiUrl}/calculations/calculation-options/calculation-parameter/`,
      request,
    );
  }

  updateCalculationBenefitDetailByType(
    memberId: string,
    calculationBenefitId: string,
    detailType: number,
    request: UpdateCalculationBenefitDetailRequest,
  ): Observable<void> {
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.put<void>(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-benefit-histories/${calculationBenefitId}/details/${detailType}`,
      request,
      { context },
    );
  }
}
