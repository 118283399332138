import { Pipe, PipeTransform } from '@angular/core';
import { getConcatString } from '../utils/string.util';

@Pipe({
  name: 'personName'
})
export class PersonNamePipe implements PipeTransform {

    transform(value: { prefix: string, first: string, middle: string, last: string, suffix: string } | string | undefined, options?: {id: string, text: string}[]): string {
    if (!value) {
      return '';
    }
    if (typeof value === 'string') {
      if (options && options.length) {
        value = options.find(el => el.id === value)?.text || value;
      };
      return value;
    }
    let prefix = value?.prefix ? value?.prefix : '';
    if (options && options.length) {
      prefix = options.find(el => el.id === prefix)?.text || '';
  }
    return (prefix || '') + (prefix ? '. ' : '') + `${ getConcatString([value.first, value.middle, value.last], ' ') }` + (value.suffix ? ', ' + value.suffix : '');
  }

}
