import { FilterInfo } from './member-list';

export interface MemberFilterName {
  id?: string;
  name: string;
  listFilterInfo?: any;
}

export interface MemberFilterConfig {
  name: string;
  listFilterInfo: FilterInfo[];
}

export interface FilterInformationList {
  listFilterInfo: FilterInformation[];
}

export interface FilterInformation {
  clientId: string;
  id: string;
  listFilterInfo: FilterInfo[];
  name: string;
}
