import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { GetPaymentListConfigurationsResponse } from '../../types/models/payment-list-configuration.model';
import { PaymentListConfigurationService } from '../../services/payment-list-configuration.service';
import * as PaymentListConfigurationActions from '../../store/actions/payment-list-configuration.actions';

@Injectable()
export class PaymentListConfigurationEffects {
  constructor(
    private actions$: Actions,
    private paymentListConfigurationService: PaymentListConfigurationService
  ) {}

  setPaymentListConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentListConfigurationActions.setPaymentListConfigurations),
      switchMap(({ body }) => {
        return this.paymentListConfigurationService
          .setPaymentListConfiguration(body)
          .pipe(
            map(() => {
              return PaymentListConfigurationActions.setPaymentListConfigurationsSuccess();
            }),
            catchError((error) => {
              return of(
                PaymentListConfigurationActions.setPaymentListConfigurationsFailure(
                  { error }
                )
              );
            })
          );
      })
    )
  );

  getPaymentListConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PaymentListConfigurationActions.getPaymentListConfigurations),
      switchMap(() => {
        return this.paymentListConfigurationService
          .getPaymentListConfiguration()
          .pipe(
            map((response: GetPaymentListConfigurationsResponse) => {
              return PaymentListConfigurationActions.getPaymentListConfigurationsSuccess(
                { response }
              );
            }),
            catchError((error) => {
              return of(
                PaymentListConfigurationActions.getPaymentListConfigurationsFailure(
                  { error }
                )
              );
            })
          );
      })
    )
  );
}
