import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { CalculationService } from '../../services/calculation.service';
import { CalculationActions } from '../../store/actions';
import {
  CalculationDetailResponse,
  CalculationPropertiesResponse,
  GetCalculationsResponse,
} from '../../types/models';

@Injectable()
export class CalculationEffects {
  constructor(
    private actions$: Actions,
    private calculationService: CalculationService
  ) {}

  getCalculations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalculationActions.getCalculations),
      switchMap(({ request }) => {
        return this.calculationService.getCalculations(request).pipe(
          map((response: GetCalculationsResponse) => {
            return CalculationActions.getCalculationsSuccess({ response });
          }),
          catchError((error) => {
            return of(CalculationActions.getCalculationsFailure({ error }));
          })
        );
      })
    )
  );

  addCalculation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalculationActions.addCalculation),
      switchMap(({ body }) => {
        return this.calculationService.addCalculation(body).pipe(
          map(() => {
            return CalculationActions.addCalculationSuccess();
          }),
          catchError((error) => {
            return of(CalculationActions.addCalculationFailure({ error }));
          })
        );
      })
    )
  );

  updateCalculation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalculationActions.updateCalculation),
      switchMap(({ body }) => {
        return this.calculationService.updateCalculation(body).pipe(
          map(() => {
            return CalculationActions.updateCalculationSuccess();
          }),
          catchError((error) => {
            return of(CalculationActions.updateCalculationFailure({ error }));
          })
        );
      })
    )
  );

  removeCalculation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalculationActions.removeCalculation),
      switchMap(({ id }) => {
        return this.calculationService.removeCalculation(id).pipe(
          map(() => {
            return CalculationActions.removeCalculationSuccess();
          }),
          catchError((error) => {
            return of(CalculationActions.removeCalculationFailure({ error }));
          })
        );
      })
    )
  );

  getCalculationProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalculationActions.getCalculationProperties),
      switchMap(() => {
        return this.calculationService.getCalculationProperties().pipe(
          map((response: CalculationPropertiesResponse) => {
            return CalculationActions.getCalculationPropertiesSuccess({
              response,
            });
          }),
          catchError((error) => {
            return of(
              CalculationActions.getCalculationPropertiesFailure({ error })
            );
          })
        );
      })
    )
  );

  getCalculationDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CalculationActions.getCalculationDetail),
      switchMap(({ id }) => {
        return this.calculationService.getCalculationDetail(id).pipe(
          map((response: CalculationDetailResponse) => {
            return CalculationActions.getCalculationDetailSuccess({ response });
          }),
          catchError((error) => {
            return of(
              CalculationActions.getCalculationDetailFailure({ error })
            );
          })
        );
      })
    )
  );
}
