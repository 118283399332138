import { Pipe, PipeTransform } from '@angular/core';

import { LookupTableType } from '@ptg-shared/types/enums';
import { Address } from '@ptg-shared/types/models/common.model';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

  transform(address: Address, options?: [{id: string, text: string, type?: LookupTableType}] | any[], useCode: boolean = false): string {
    if (!address) {
      return '';
    }
    if (options?.length) {
      const state = options.find(x => x?.type === LookupTableType.State && x?.id.toLowerCase() === address?.state?.toLowerCase())?.text || '';
      const country = options.find(x => x?.type === LookupTableType.Country && x?.id.toLowerCase() === address?.country?.toLowerCase())?.text || '';
      return this.setAddressValue(address?.street1, address?.street2 || '', address?.city, state, address?.zipCode, country);
    }
    return this.setAddressValue(
      address?.street1,
      address?.street2 || '',
      address?.city,
      (useCode ? address?.stateCode : address?.state) || '',
      address?.zipCode,
      (useCode ? address?.countryCode : address?.country) || ''
    );
  }

  setAddressValue(street1: string, street2: string, city: string, state: string, zipCode: string, country: string) {
    return [street1, street2, city, state, zipCode, country || ''].filter(x => x).join(', ');
  }
}
