import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { CalculationParameterConfigurationService } from '../../services';
import {
  getCalculationParameterAction,
  getCalculationParameterFailureAction,
  getCalculationParameterSuccessAction,
  getCalculationPropertyAction,
  getCalculationPropertyFailureAction,
  getCalculationPropertySuccessAction,
  getParameterMappingsAction,
  getParameterMappingsFailureAction,
  getParameterMappingsSuccessAction,
  setCalculationParameterAction,
  setCalculationParameterFailureAction,
  setCalculationParameterSuccessAction,
  updateCalculationBenefitDetailByTypeAction,
  updateCalculationBenefitDetailByTypeFailureAction,
  updateCalculationBenefitDetailByTypeSuccessAction,
} from '../actions';
import {
  CalculationParameters,
  ParameterMappings,
  SectionProperties,
} from '@ptg-member/features/calculation/services/models';

@Injectable()
export class CalculationParameterConfigurationEffects {
  getParameterMappings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getParameterMappingsAction),
      switchMap(({ parameterType }) =>
        this.calculationParameterConfigurationService.getParameterMappings(parameterType).pipe(
          map((response: ParameterMappings) => getParameterMappingsSuccessAction({ response })),
          catchError((error) => of(getParameterMappingsFailureAction({ error }))),
        ),
      ),
    ),
  );
  getCalculationParameter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCalculationParameterAction),
      switchMap(() =>
        this.calculationParameterConfigurationService.getCalculationParameter().pipe(
          map((response: CalculationParameters) => getCalculationParameterSuccessAction({ response })),
          catchError((error) => of(getCalculationParameterFailureAction({ error }))),
        ),
      ),
    ),
  );

  setCalculationParameter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setCalculationParameterAction),
      switchMap(({ request }) =>
        this.calculationParameterConfigurationService.setCalculationParameter(request).pipe(
          map(() => setCalculationParameterSuccessAction()),
          catchError((error) => of(setCalculationParameterFailureAction({ error }))),
        ),
      ),
    ),
  );

  updateCalculationBenefitDetailByType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCalculationBenefitDetailByTypeAction),
      switchMap(({ memberId, calculationBenefitId, detailType, request }) =>
        this.calculationParameterConfigurationService
          .updateCalculationBenefitDetailByType(memberId, calculationBenefitId, detailType, request)
          .pipe(
            map(() => updateCalculationBenefitDetailByTypeSuccessAction()),
            catchError((error) => of(updateCalculationBenefitDetailByTypeFailureAction({ error }))),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private calculationParameterConfigurationService: CalculationParameterConfigurationService,
  ) {}
}
