<div class="release-version-container">
    <ptg-title-bar
    name="Release Note"
    [onlyTitle]="true"
    ></ptg-title-bar>
    <div class="content-wrap">
        <button mat-raised-button type="button" class="cancel-button" (click)="onCancel()">Back</button>
        <h4>Release Date: {{dataVersion.release_date}}</h4>
        <ul>
            <li *ngFor="let note of dataVersion.release_note"><span>{{ note }}</span></li>
        </ul>
    </div>
  
</div>

