<div class="audit-trails-container">
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
    (clickBreadcrumb)="onClickBreadcrumb()"
  ></ptg-breadcrumb>

  <ptg-overview-header-entity
    [targetIdFromDialog]="data?.memberId">
  </ptg-overview-header-entity>

  <div class="content-detail flex p-6 gap-6">
    <div class="main-cell-detail">
      <div class="list-display">
        <div class="list-header">Audit Trail List</div>
        <div class="grid-wrapper">
          <ptg-grid
            [data]="auditTrailList"
            [columns]="columns"
            keyColumn="id"
            [isLoading]="isLoading"
            [fitToParent]="true"
            [totalRecords]="lengthPg"
            [pageSize]="pageSize"
            [pageNumber]="pageNumber"
            notFoundMessage="No Audit Trail to Display"
            (pageChange)="onChangePage($event)"
            (sortChange)="onChangeSort($event)"
            (rowClick)="selectRow($event)"
          ></ptg-grid>
        </div>
      </div>
    </div>

    <div class="sub-cell-detail">
      <div class="list-display">
        <div class="list-header">Selected Audit Trail</div>
        <ptg-grid
          [data]="selectedAuditTrailRows"
          [columns]="selectedAuditTrailColumns"
          [isLoading]="isLoading"
          keyColumn="id"
          [paginable]="false"
        ></ptg-grid>
      </div>
    </div>
  </div>
</div>
