import { createReducer, on } from '@ngrx/store';
import { ACTION, ActionState, STATE } from '@ptg-shared/constance';
import { BaseActionState } from '@ptg-shared/types/models';
import {
  RetirementBenefitCalculations,
  CalculationDetail,
  MappingParameter,
  FileParameter,
  GetListSavePropertyResponse,
} from '../../services/models';
import {
  getCalculationListRequest,
  getCalculationListSuccess,
  getCalculationListFailure,
  getCalculationDetailRequest,
  getCalculationDetailSuccess,
  getCalculationDetailFailure,
  getCalculationInputMappingRequest,
  getCalculationInputMappingSuccess,
  getCalculationInputMappingFailure,
  getCalculationOutputMappingRequest,
  getCalculationOutputMappingSuccess,
  getCalculationOutputMappingFailure,
  uploadCalculationFileSuccess,
  uploadCalculationFileFailure,
  clearRetirementBenefitCalculationState,
  deleteCalculationRequest,
  deleteCalculationSuccess,
  deleteCalculationFailure,
  clearCalculationState,
  createParameterMappingFailureAction,
  createParameterMappingSuccessAction,
  clearParameterMappingStateAction,
  getFileParametersSuccessAction,
  getFileParametersFailureAction,
  setParameterMappingSuccessAction,
  setParameterMappingFailureAction,
  removeParameterMappingFailureAction,
  removeParameterMappingSuccessAction,
  getListSavePropertyAction,
  getListSavePropertySuccessAction,
  getListSavePropertyFailureAction,
  clearGetListSavePropertyAction,
  clearGetFileParametersAction,
} from '../actions';
import { ParameterType } from '../../constants';

export interface CalculationListState {
  retirementBenefitCalculationListState?: {
    isLoading: boolean;
    retirementBenefitCalculations: RetirementBenefitCalculations;
  };
  calculationDetailState?: BaseActionState<CalculationDetail>;
  calculationInputMapping?: BaseActionState<MappingParameter[]>;
  calculationOutputMapping?: BaseActionState<MappingParameter[]>;
  uploadCalculationFileState: ActionState;
  removeCalculationFileState?: ActionState;
  parameterMappingActionState?: {
    action: string;
    state: string;
    parameterType: ParameterType;
  };
  getFileParameters?: BaseActionState<FileParameter[]>;
  saveToProperty?: BaseActionState<GetListSavePropertyResponse>;
}

const initialState: CalculationListState = {
  retirementBenefitCalculationListState: {
    retirementBenefitCalculations: {} as any,
    isLoading: false,
  },
  calculationDetailState: {
    isLoading: true,
  },
  calculationInputMapping: {
    isLoading: true,
    payload: [],
    total: 0,
  },
  calculationOutputMapping: {
    isLoading: true,
    payload: [],
    total: 0,
  },
  uploadCalculationFileState: {
    state: '',
    action: '',
  },
};

export const calculationListReducer = createReducer(
  initialState,
  on(getCalculationListRequest, (state, {}) => ({
    ...state,
    retirementBenefitCalculationListState: {
      retirementBenefitCalculations: {} as any,
      isLoading: true,
    },
  })),
  on(getCalculationListSuccess, (state, { retirementBenefitCalculations }) => ({
    ...state,
    retirementBenefitCalculationListState: {
      retirementBenefitCalculations,
      isLoading: false,
    },
  })),
  on(getCalculationListFailure, (state, {}) => ({
    ...state,
    retirementBenefitCalculationListState: {
      retirementBenefitCalculations: {} as any,
      isLoading: false,
    },
  })),

  on(getCalculationDetailRequest, (state) => ({
    ...state,
    calculationDetailState: {
      isLoading: true,
    },
  })),
  on(getCalculationDetailSuccess, (state, { calculationDetail }) => ({
    ...state,
    calculationDetailState: {
      isLoading: false,
      success: true,
      payload: calculationDetail,
    },
  })),
  on(getCalculationDetailFailure, (state) => ({
    ...state,
    calculationDetailState: {
      isLoading: false,
      success: false,
    },
  })),

  on(getCalculationInputMappingRequest, (state) => ({
    ...state,
    calculationInputMapping: {
      isLoading: true,
      payload: [],
      total: 0,
    },
  })),
  on(getCalculationInputMappingSuccess, (state, { calculationInputMapping }) => ({
    ...state,
    calculationInputMapping: {
      isLoading: false,
      success: true,
      payload: calculationInputMapping.inputDetailCalculationDataMapping,
      total: calculationInputMapping.total,
    },
  })),
  on(getCalculationInputMappingFailure, (state) => ({
    ...state,
    calculationInputMapping: {
      isLoading: false,
      success: false,
      payload: [],
      total: 0,
    },
  })),

  on(getCalculationOutputMappingRequest, (state) => ({
    ...state,
    calculationOutputMapping: {
      isLoading: true,
      payload: [],
      total: 0,
    },
  })),
  on(getCalculationOutputMappingSuccess, (state, { calculationOutputMapping }) => ({
    ...state,
    calculationOutputMapping: {
      isLoading: false,
      success: true,
      payload: calculationOutputMapping.outputDetailCalculationDataMapping,
      total: calculationOutputMapping.total,
    },
  })),
  on(getCalculationOutputMappingFailure, (state) => ({
    ...state,
    calculationOutputMapping: {
      isLoading: false,
      success: false,
      payload: [],
      total: 0,
    },
  })),
  on(uploadCalculationFileSuccess, (state, { action }) => ({
    ...state,
    uploadCalculationFileState: {
      state: STATE.SUCCESS,
      action,
    },
  })),
  on(uploadCalculationFileFailure, (state, { error, action }) => ({
    ...state,
    uploadCalculationFileState: {
      state: STATE.FAIL,
      action,
    },
  })),
  on(clearRetirementBenefitCalculationState, (state) => ({
    ...state,
    uploadCalculationFileState: {
      state: '',
      action: '',
    },
  })),

  on(deleteCalculationRequest, (state) => ({
    ...state,
    removeCalculationFileState: {
      action: ACTION.REMOVE,
      state: '',
    },
  })),
  on(deleteCalculationSuccess, (state) => ({
    ...state,
    removeCalculationFileState: {
      action: ACTION.REMOVE,
      state: STATE.SUCCESS,
    },
  })),
  on(deleteCalculationFailure, (state) => ({
    ...state,
    removeCalculationFileState: {
      action: ACTION.REMOVE,
      state: STATE.FAIL,
    },
  })),

  on(clearCalculationState, (state) => ({
    ...state,
    removeCalculationFileState: undefined,
  })),

  on(createParameterMappingSuccessAction, (state, { parameterType }) => ({
    ...state,
    parameterMappingActionState: {
      action: ACTION.ADD,
      state: STATE.SUCCESS,
      parameterType,
    },
  })),
  on(createParameterMappingFailureAction, (state, { parameterType, error }) => ({
    ...state,
    parameterMappingActionState: {
      action: ACTION.ADD,
      state: STATE.FAIL,
      parameterType,
    },
  })),
  on(clearParameterMappingStateAction, (state) => ({
    ...state,
    parameterMappingActionState: undefined,
  })),

  on(getFileParametersSuccessAction, (state, { response }) => ({
    ...state,
    getFileParameters: {
      isLoading: false,
      success: true,
      payload: response.calculationFileParameters,
    },
  })),
  on(getFileParametersFailureAction, (state, { error }) => ({
    ...state,
    getFileParameters: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetFileParametersAction, (state) => ({
    ...state,
    getFileParameters: undefined,
  })),

  on(setParameterMappingSuccessAction, (state, { parameterType }) => ({
    ...state,
    parameterMappingActionState: {
      action: ACTION.EDIT,
      state: STATE.SUCCESS,
      parameterType,
    },
  })),
  on(setParameterMappingFailureAction, (state, { parameterType, error }) => ({
    ...state,
    parameterMappingActionState: {
      action: ACTION.EDIT,
      state: STATE.FAIL,
      parameterType,
    },
  })),

  on(removeParameterMappingSuccessAction, (state, { parameterType }) => ({
    ...state,
    parameterMappingActionState: {
      action: ACTION.REMOVE,
      state: STATE.SUCCESS,
      parameterType,
    },
  })),
  on(removeParameterMappingFailureAction, (state, { parameterType, error }) => ({
    ...state,
    parameterMappingActionState: {
      action: ACTION.REMOVE,
      state: STATE.FAIL,
      parameterType,
    },
  })),
  on(getListSavePropertyAction, (state, {}) => ({
    ...state,
    saveToProperty: {
      isLoading: true,
    },
  })),
  on(getListSavePropertySuccessAction, (state, { response }) => ({
    ...state,
    saveToProperty: {
      payload: response,
      isLoading: false,
    },
  })),
  on(getListSavePropertyFailureAction, (state, { error }) => ({
    ...state,
    saveToProperty: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetListSavePropertyAction, (state) => ({
    ...state,
    saveToProperty: undefined,
  })),
);
