import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ACTION_COLUMN, Align, Column } from '@ptg-shared/controls/grid';
import { EditDisplayFormatComponent } from '../edit-display-format/edit-display-format.component';
import { Store } from '@ngrx/store';
import * as fromMember from '../../store/reducers';
import { filter, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@ptg-shared/components';
import { DisplayFormatRes } from '@ptg-member/types/models';
import { showBanner } from '@ptg-shared/utils/common.util';
import { ACTION, STATE } from '@ptg-shared/constance';
import * as LookupTableActions from '../../store/actions/lookup-table.actions';

@Component({
  selector: 'ptg-lookup-table-detail-display-format',
  templateUrl: './lookup-table-detail-display-format.component.html',
  styleUrls: ['./lookup-table-detail-display-format.component.scss'],
})
export class LookupTableDetailDisplayFormatComponent extends BaseComponent {
  @Input() id!: string;
  lookupTableDetailsData: DisplayFormatRes[] = [];
  columns: Column[] = [
    {
      name: 'screenName',
      header: {
        title: 'Screen',
      },
      truncate: true,
    },
    {
      name: 'showId',
      header: {
        title: 'ID',
      },
      align: Align.Center,
    },
    {
      name: 'showDescription',
      header: {
        title: 'Short Description',
      },
      align: Align.Center,
    },
    {
      name: 'showLongDescription',
      header: {
        title: 'Long Description',
      },
      align: Align.Center,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      width: '230px',
    },
  ];
  isLoading: boolean = true;
  errorMsg?: string;
  bannerType: BannerType = BannerType.Hidden;
  message = '';
  selectedValue: DisplayFormatRes = {} as DisplayFormatRes;
  constructor(
    public dialog: MatDialog,
    private memberStore: Store<fromMember.MemberState>
  ) {
    super();
  }

  ngOnInit(): void {
    this.getDisplayFormatList();
    this.updateDisplayFormat();
    this.createDisplayFormat();
  }

  getDisplayFormatList() {
    this.memberStore
      .select(fromMember.selectDisplayFormatList)
      .pipe(
        filter((state: any) => state?.metadataLookupTableDisplayFormats),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        this.lookupTableDetailsData = state.metadataLookupTableDisplayFormats;
        this.isLoading = false;
      });
  }

  createDisplayFormat() {
    this.memberStore
      .select(fromMember.createDisplayFormatState)
      .pipe(filter((state: any) => state),takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        showBanner.call(this, state, state === STATE.SUCCESS ? 'Screen display format' : 'screen display format', ACTION.ADD);
        this.memberStore.dispatch(LookupTableActions.clearLookupTableState());
        if(state === STATE.FAIL ) {
          this.isLoading = false;
          return;
        }
        this.memberStore.dispatch(
          LookupTableActions.getDisplayFormatList({ id: this.id })
        );
      });
  }

  updateDisplayFormat() {
    this.memberStore
      .select(fromMember.editDisplayFormatState)
      .pipe(filter((state: any) => state),takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        showBanner.call(this, state, state === STATE.SUCCESS ? 'Screen display format' : 'screen display format', ACTION.EDIT);
        if(state === STATE.FAIL ) {
          this.isLoading = false;
          return;
        }
        this.memberStore.dispatch(LookupTableActions.clearLookupTableState());
        this.memberStore.dispatch(
          LookupTableActions.getDisplayFormatList({ id: this.id })
        );
      });
  }

  editValue(value?: any) {
    this.selectedValue = value;
    const dialogRef = this.dialog.open(EditDisplayFormatComponent, {
      panelClass: ['dialog-full-screen'],
      autoFocus: false,
      disableClose: true,
      data: {
        id: this.id,
        data: value,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.isLoading = true;
      }
    });
  }
}
