import { AfterViewChecked, Directive, ElementRef, Input } from '@angular/core';
import { SearchMemberService } from '../services/search-member.service';

@Directive({
  selector: '[ptgSearchBold]'
})
export class SearchBoldDirective implements AfterViewChecked {
  @Input() searchClassName: string = 'value';

  constructor(private elementRef: ElementRef, private searchMemberService: SearchMemberService) {}

  ngAfterViewChecked(): void {
    if (this.searchMemberService.searchValue && this.elementRef.nativeElement) {
      this.boldText();
    }
  }

  boldText() {
    let searchValues = this.searchMemberService.searchValue.split(' ').filter(value => value?.trim()).join('|');
    searchValues = searchValues.replace(new RegExp('>', 'gi'), (match) => `&gt;`);
    searchValues = searchValues.replace(new RegExp('<', 'gi'), (match) => `&lt;`);
    const regex = new RegExp(searchValues, 'gi');
    const element = this.elementRef.nativeElement.getElementsByClassName(this.searchClassName)[0] as HTMLElement;
    if (element) {
      let innerText = element.innerText;
      innerText = innerText.replace(new RegExp('>', 'gi'), (match) => `&gt;`);
      innerText = innerText.replace(new RegExp('<', 'gi'), (match) => `&lt;`);
      innerText = innerText.replace(regex, (match) => `<b>${ match }</b>`);
      element.innerHTML = innerText;
    }
  }
}
