import { createSelector } from '@ngrx/store';

import { entityManagementModuleSelector } from './selector';

export const entityViewSelector = createSelector(entityManagementModuleSelector, (state) => state?.entityView);

export const getEntityViewsByEntitySelector = createSelector(
  entityViewSelector,
  (state) => state?.getEntityViewsByEntity,
);

export const getEntityViewsByCardSelector = createSelector(entityViewSelector, (state) => state?.getEntityViewsByCard);

export const getCardsByViewSelector = createSelector(entityViewSelector, (state) => state?.getCardsByView);
