import { createReducer, on } from '@ngrx/store';
import { STATE } from 'src/app/shared/constance/value.const';
import {
  AggregationGroup,
  AggregationGroupDetails,
} from '../../types/models/aggregation-group.model';
import { AggregationGroupActions } from '../actions';

export const aggregationGroupFeatureKey = 'aggregationGroup';
export interface State {
  isLoading: boolean;
  aggregationGroup: AggregationGroup[];
  aggregationGroupDetails: AggregationGroupDetails[];
  removeState: string;
  createState: string;
  updateState: string;
  error?: any;
}

const initialState: State = {
  isLoading: true,
  aggregationGroup: [],
  aggregationGroupDetails: [],
  removeState: '',
  createState: '',
  updateState: '',
  error: undefined,
};

export const reducer = createReducer(
  initialState,
  on(AggregationGroupActions.getAggregationGroupList, (state, {}) => ({
    ...state,
    isLoading: true,
    aggregationGroup: [],
    error: undefined,
  })),
  on(
    AggregationGroupActions.getAggregationGroupSuccess,
    (state, { aggregationGroup }) => ({
      ...state,
      isLoading: false,
      aggregationGroup,
      error: undefined,
    })
  ),
  on(
    AggregationGroupActions.getAggregationGroupListFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      aggregationGroup: [],
      error: error,
    })
  ),
  on(AggregationGroupActions.clearAggregationGroup, (state, {}) => ({
    ...initialState,
    aggregationGroup: [],
    removeState: '',
    createState: '',
    updateState: '',
  })),
  on(AggregationGroupActions.addAggregationGroupSuccess, (state) => ({
    ...state,
    createState: STATE.SUCCESS,
  })),
  on(
    AggregationGroupActions.addAggregationGroupFailure,
    (state, { errorMsg }) => ({
      ...state,
      createState: STATE.FAIL,
    })
  ),
  on(AggregationGroupActions.updateAggregationGroupSuccess, (state) => ({
    ...state,
    createState: STATE.SUCCESS,
  })),
  on(
    AggregationGroupActions.updateAggregationGroupFailure,
    (state, { errorMsg }) => ({
      ...state,
      updateState: STATE.FAIL,
    })
  ),
  on(AggregationGroupActions.removeAggregationGroupSuccess, (state) => ({
    ...state,
    removeState: STATE.SUCCESS,
  })),
  on(AggregationGroupActions.removeAggregationGroupFailure, (state) => ({
    ...state,
    removeState: STATE.FAIL,
  })),
  on(AggregationGroupActions.getAggregationGroupDetails, (state, {}) => ({
    ...state,
    isLoading: true,
    aggregationGroupDetails: [],
  })),
  on(
    AggregationGroupActions.getAggregationGroupDetailsSuccess,
    (state, { response }) => ({
      ...state,
      isLoading: false,
      aggregationGroupDetails: response.aggregationGroups,
    })
  ),
  on(
    AggregationGroupActions.getAggregationGroupDetailsFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      aggregationGroupDetails: [],
    })
  )
);
