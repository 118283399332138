import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetHeaderListRequest, GetHeaderPropertiesRequest, HeaderItemItemExisResponse, HeaderPropertiesResponse, NewHeader, UpdateHeaderDetailRequest } from '@ptg-member/types/models';
import { ProfileConfigListResponse } from '../types/models/profile-configuration.model';
import { SortType } from '@ptg-shared/constance';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  constructor(private httpClient: HttpClient) { }

  getHeaderList(query: GetHeaderListRequest): Observable<any> {
    let params = new HttpParams();

    if (query.sortField) {
      params = params.append('SortNames', capitalizeFirstLetter(query.sortField));
      params = params.append('SortType', query.sortType ?? SortType.ASC);
    }
    if (query?.pageSize) {
      params = params.append('PageSize', query?.pageSize);
    }
    if (query?.pageIndex) {
      params = params.append('PageIndex', query.pageIndex.toString());
    }

    return this.httpClient.get<void>(`${environment.apiUrl}/entity/headers`, { params });
  }

  getHeaderDetail(id: string): Observable<any> {
    return this.httpClient.get<void>(`${environment.apiUrl}/entity/header/${id}`);
  }

  getHeaderProperties(query: GetHeaderPropertiesRequest): Observable<HeaderPropertiesResponse> {
    let params = new HttpParams();
    if (query?.entityId) {
      params = params.append('EntityId', query?.entityId);
    }
    if (query?.type) {
      params = params.append('Type', query.type.toString());
    }

    return this.httpClient.get<HeaderPropertiesResponse>(`${environment.apiUrl}/entity/header-properties`, { params });
  }

  createHeader(body: NewHeader) {
    return this.httpClient.post(`${environment.apiUrl}/entity/header`, body);
  }

  updateHeader(body: UpdateHeaderDetailRequest) {
    return this.httpClient.put(`${environment.apiUrl}/entity/header`, body);
  }

  removeHeader(id: string) {
    return this.httpClient.delete(`${environment.apiUrl}/entity/header/${id}`);
  }

  getProfileConifg(id: string): Observable<ProfileConfigListResponse> {
    let params = new HttpParams();
    if (id) {
      params = params.append('EntityHeaderId', id);
    }

    return this.httpClient.get<ProfileConfigListResponse>(`${environment.apiUrl}/entity/header/profile-configs`, { params });
  }

  getHeaderExist(id: string, name: string): Observable<HeaderItemItemExisResponse> {
    let params = new HttpParams();
    if (id) {
      params = params.append('id', id);
    }
    if (name) {
      params = params.append('name', name);
    }

    return this.httpClient.get<HeaderItemItemExisResponse>(`${environment.apiUrl}/entity/header-exists`, { params });
  }

  getHeaderItemExist(id: string, name: string): Observable<HeaderItemItemExisResponse> {
    let params = new HttpParams();
    if (id) {
      params = params.append('id', id);
    }
    if (name) {
      params = params.append('name', name);
    }

    return this.httpClient.get<HeaderItemItemExisResponse>(`${environment.apiUrl}/entity/header-item-exists`, { params });
  }
}
