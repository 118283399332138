import { FileSignature } from "./file-signature";

export interface FileSnapshot {
    fileType: FileType,
    fileName: string,
    label: string,
    description: string,
    lastModified?: Date,
    signatureRequired: boolean,
    uri: string,
    key: string,
    signature?: FileSignature
}

export enum FileType {
    Document = "Document",
    LogFile = "LogFile",
    Image = "Image"
}