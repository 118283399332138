import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import {
  getExceptionListAction,
  getExceptionListSuccessAction,
  getExceptionListFailureAction,
  getRetirementBenefitDetailDocumentsAction,
  getRetirementBenefitDetailDocumentsFailureAction,
  getRetirementBenefitDetailDocumentsSuccessAction,
  clearGetExceptionListStateAction,
  clearGetRetirementBenefitDetailDocumentsStateAction,
  checkExceptionConfigurationAction,
  checkExceptionConfigurationFailureAction,
  checkExceptionConfigurationSuccessAction,
  clearCheckExceptionConfigurationStateAction,
  getCalculationAuditTrailAction,
  getCalculationAuditTrailFailureAction,
  getCalculationAuditTrailSuccessAction,
  clearGetCalculationAuditTrailStateAction,
} from '../actions';

import { ActionButtonOnCalculationDetail } from '../../types/enums';
import { ExceptionListItem, RetirementBenefitDetailDocument, CalculationAuditTrail } from '../../services/models';

export interface RetirementBenefitDetailDocumentState {
  getRetirementBenefitDetailDocuments?: BaseActionState<RetirementBenefitDetailDocument[]>;
  getExceptionList?: BaseActionState<ExceptionListItem[]>;
  checkExceptionResult?: BaseActionState<{
    exceptionsOccur?: boolean;
    buttonAction?: ActionButtonOnCalculationDetail;
  }>;
  getCalculationAuditTrailList?: BaseActionState<CalculationAuditTrail[]>;
}

const initialState: RetirementBenefitDetailDocumentState = {};

export const retirementBenefitDetailDocumentReducer = createReducer(
  initialState,
  on(getRetirementBenefitDetailDocumentsAction, (state) => ({
    ...state,
    getRetirementBenefitDetailDocuments: {
      isLoading: true,
    },
  })),
  on(getRetirementBenefitDetailDocumentsSuccessAction, (state, { response }) => ({
    ...state,
    getRetirementBenefitDetailDocuments: {
      isLoading: false,
      success: true,
      payload: response.benefitDocuments,
      total: response.total,
    },
  })),
  on(getRetirementBenefitDetailDocumentsFailureAction, (state, { error }) => ({
    ...state,
    getRetirementBenefitDetailDocuments: {
      isLoading: false,
      success: false,
      error,
    },
  })),
  on(clearGetRetirementBenefitDetailDocumentsStateAction, (state) => ({
    ...state,
    getRetirementBenefitDetailDocuments: undefined,
  })),

  on(getExceptionListAction, (state) => ({
    ...state,
    getExceptionList: {
      isLoading: true,
    },
  })),
  on(getExceptionListSuccessAction, (state, { response }) => ({
    ...state,
    getExceptionList: {
      isLoading: false,
      success: true,
      payload: response.details,
      total: response.total,
    },
  })),
  on(getExceptionListFailureAction, (state, { error }) => ({
    ...state,
    getExceptionList: {
      isLoading: false,
      success: false,
      error,
    },
  })),
  on(clearGetExceptionListStateAction, (state) => ({
    ...state,
    getExceptionList: undefined,
  })),

  on(checkExceptionConfigurationAction, (state) => ({
    ...state,
    checkExceptionResult: {
      isLoading: true,
    },
  })),
  on(checkExceptionConfigurationSuccessAction, (state, { response, buttonAction }) => ({
    ...state,
    checkExceptionResult: {
      isLoading: false,
      success: true,
      payload: {
        exceptionsOccur: response,
        buttonAction,
      },
    },
  })),
  on(checkExceptionConfigurationFailureAction, (state, { error, buttonAction }) => ({
    ...state,
    checkExceptionResult: {
      isLoading: false,
      success: false,
      payload: {
        buttonAction,
      },
      error,
    },
  })),
  on(clearCheckExceptionConfigurationStateAction, (state) => ({
    ...state,
    checkExceptionResult: undefined,
  })),

  on(getCalculationAuditTrailAction, (state) => ({
    ...state,
    getCalculationAuditTrailList: {
      isLoading: true,
    },
  })),
  on(getCalculationAuditTrailSuccessAction, (state, { response }) => ({
    ...state,
    getCalculationAuditTrailList: {
      isLoading: false,
      success: true,
      payload: response.auditTrails,
      total: response.total,
    },
  })),
  on(getCalculationAuditTrailFailureAction, (state, { error }) => ({
    ...state,
    getCalculationAuditTrailList: {
      isLoading: false,
      success: false,
      error,
    },
  })),
  on(clearGetCalculationAuditTrailStateAction, (state) => ({
    ...state,
    getCalculationAuditTrailList: undefined,
  })),
);
