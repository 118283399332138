import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { ACTION_COLUMN } from '@ptg-shared/controls/grid';
import { Option } from '@ptg-shared/controls/select/select.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { ACTION, ADMIN_SYSTEM, STATE } from '@ptg-shared/constance';
import { BaseComponent } from '@ptg-shared/components';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { Store } from '@ngrx/store';
import {
  CalculationState,
  clearSetCalculationParameterStateAction,
  getCalculationParameterAction,
  getCalculationParametersSelector,
  getCalculationPropertyAction,
  getCalculationPropertySelector,
  getParameterMappingsAction,
  getParameterMappingsSelector,
  setCalculationParameterAction,
  setCalculationParameterSelector,
} from '@ptg-member/features/calculation/store';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { CalculationParameterType, CalculationType, GetParameterMappingType } from '@ptg-member/features/calculation/types/enums';
import { CalculationParameter, ParameterMapping, SectionProperty } from '@ptg-member/features/calculation/services/models';
import { deepClone, showBanner } from '@ptg-shared/utils/common.util';

@Component({
  selector: 'ptg-calculation-parameter-configuration',
  templateUrl: './calculation-parameter-configuration.component.html',
  styleUrls: ['./calculation-parameter-configuration.component.scss'],
})
export class CalculationParameterConfigurationComponent extends BaseComponent implements OnChanges {
  readonly ACTION_COLUMN = ACTION_COLUMN;
  readonly ADMIN_SYSTEM = ADMIN_SYSTEM;
  readonly CalculationParameterType = CalculationParameterType;

  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  listBreadcrumbs: Breadcrumb[] = this.getBreadcrumbs();
  memberId: string = '';
  parameterNameOptions!: Option[];
  propertyOptions!: Option[];
  inputOptions!: Option[];
  outputOptions!: Option[];
  configurations: CalculationParameter[] = [];
  isLoading: boolean = false;

  constructor(
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public calculationStore: Store<CalculationState>,
    public router: Router,
    private layoutService: LayoutService,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit(): void {
    this.checkRouteParams();
    this.getData();
    this.selectParameterConfigurationState();
    this.selectSetParameterConfigState();
  }

  getData() {
    this.calculationStore.dispatch(getCalculationParameterAction());
    this.calculationStore.dispatch(
      getParameterMappingsAction({
        parameterType: GetParameterMappingType.Input,
      }),
    );
    this.calculationStore.dispatch(getParameterMappingsAction({}));
    this.calculationStore.dispatch(getCalculationPropertyAction());
  }

  selectParameterConfigurationState() {
    this.calculationStore
      .select(getCalculationPropertySelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((calculationProperties) => {
        this.getPropertyOptions(calculationProperties?.payload ?? []);
      });
    this.calculationStore
      .select(getParameterMappingsSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((parameterMappings) => {
        this.getInputOptions(parameterMappings?.payload?.inputMappings ?? []);
        this.getOutputOptions(parameterMappings?.payload?.outputMappings ?? []);
      });
    this.calculationStore
      .select(getCalculationParametersSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((calculationParameters) => {
        this.isLoading = !!calculationParameters?.isLoading;
        if (!calculationParameters?.success) {
          return;
        }
        this.configurations = (calculationParameters?.payload ?? []).map((calculation) => {
          return {
            ...calculation,
            description: [
              calculation.parameterName,
              CalculationParameterType[calculation.type],
              calculation.benefitOptionName,
            ]
              .filter((x) => !!x)
              .join('/'),
          };
        });
      });
  }

  selectSetParameterConfigState() {
    this.calculationStore
      .select(setCalculationParameterSelector)
      .pipe(
        filter((setCalculationParameter) => !!setCalculationParameter && !setCalculationParameter?.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((setCalculationParameter) => {
        showBanner.call(
          this,
          setCalculationParameter?.success ? STATE.SUCCESS : STATE.FAIL,
          'Calculation Parameter',
          ACTION.EDIT,
        );
        this.calculationStore.dispatch(clearSetCalculationParameterStateAction());
        if (setCalculationParameter?.success) {
          this.calculationStore.dispatch(getCalculationParameterAction());
          return;
        }
      });
  }

  checkRouteParams() {
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe(({ memberId }) => {
      if (memberId) {
        this.memberId = memberId;
        this.listBreadcrumbs = this.getBreadcrumbs();
      }
    });
  }

  getBreadcrumbs() {
    return [
      {
        name: 'Retirement Benefit Overview',
        url: `/member/benefit-overview/${CalculationType.RetirementBenefit}/${this.memberId}`,
      },
      {
        name: 'Calculation Parameter',
        url: '',
      },
    ];
  }

  onSubmit(calculationParameters: CalculationParameter[]) {
    this.calculationStore.dispatch(
      setCalculationParameterAction({
        request: {
          clientId: this.layoutService.fundId,
          calculationParameters,
        },
      }),
    );
  }

  getPropertyOptions(calculationProperties: SectionProperty[]) {
    this.propertyOptions = deepClone(calculationProperties).map((property) => {
      return {
        value: property,
        displayValue: property.propertyName || property.calculationName,
        valueDescription: `${property.sectionName}/${property.propertyName}`,
      };
    });
  }

  getInputOptions(parameterMappings: ParameterMapping[]) {
    this.inputOptions = deepClone(parameterMappings).map((parameterMapping) => {
      return {
        value: parameterMapping,
        displayValue: parameterMapping.name,
        valueDescription: `${parameterMapping.benefitEntityName}`,
      };
    });
  }

  getOutputOptions(parameterMappings: ParameterMapping[]) {
    this.outputOptions = deepClone(parameterMappings).map((parameterMapping) => {
      return {
        value: parameterMapping,
        displayValue: parameterMapping.name,
        valueDescription: `${parameterMapping.benefitEntityName}`,
      };
    });
  }
}
