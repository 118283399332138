<div class="list-attachments flex flex-col">
  <div class="header">
    <span class="title">Attachments</span>
    <div class="space-line"></div>
    <div (click)="openListAttachments()" class="btn-manage">
      <mat-icon>list_alt</mat-icon>
      <span class="btn-title">Manage</span>
    </div>
  </div>

  <div class="flex-grow">
    <ptg-grid
      [columns]="columns"
      [data]="dataTable"
      [fitToParent]="true"
      [hideHeader]="true"
      [isLoading]="isLoading"
      [pageSize]="pageSize"
      [pageNumber]="pageNumber"
      [totalRecords]="lengthPg"
      [hideScrollbar]="false"
      keyColumn="id"
      notFoundMessage="No Attachment to Display"
      (pageChange)="onChangePage($event)"
    >
      <ng-template cellContent columnName="dateCreated" let-row>
        {{ row.dateCreated | date : "MM/dd/yyyy" }}
      </ng-template>
      <ng-template cellContent columnName="attachment" let-row>
        <a
          (click)="openPDF(row.fileSnapshot)"
          href="javascript:void(0)"
          style="
            color: #408BF9;
            cursor: pointer;
            text-decoration: underline;
          "
          >{{ row.attachment }}</a
        >
      </ng-template>
    </ptg-grid>
  </div>
</div>
