import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, delay, map, switchMap } from 'rxjs/operators';

import * as NavigationConfigurationActions from '../actions/participant-navigation-configuration.actions';

import { CartService } from '@ptg-member/services/cart.service';
import { ParticipantNavigationConfigurationService } from '@ptg-member/services/participant-navigation-configuration.service';
import { MemberParticipantNavigationDetailResponse, MemberParticipantNavigationResponse, NavigationViewResponse } from '@ptg-member/types/models/participant-navigation-configuration.model';

@Injectable()
export class ParticipantNavigationConfigurationEffects {
  constructor(
    private actions$: Actions,
    private participantNavigationConfigurationService: ParticipantNavigationConfigurationService
  ) {}

  getListNavigationConfigurationS$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationConfigurationActions.getNavigationConfigurationRequest),
      switchMap(({ query }) => {
        return this.participantNavigationConfigurationService
          .getParticipantNavigationConfiguration(query)
          .pipe(
            map(
              (
                navigationConfigurationResponse: MemberParticipantNavigationResponse
              ) => {
                return NavigationConfigurationActions.getNavigationConfigurationSuccess(
                  { navigationConfigurationResponse }
                );
              }
            ),
            catchError((err) => {
              return of(
                NavigationConfigurationActions.getNavigationConfigurationFailure(
                  {
                    error: err.message,
                  }
                )
              );
            })
          );
      })
    )
  );

  getNavigationConfigurationDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        NavigationConfigurationActions.getNavigationConfigurationDetailRequest
      ),
      switchMap(({ query }) => {
        return this.participantNavigationConfigurationService
          .getParticipantNavigationConfigurationDetail(query)
          .pipe(
            map((navigationConfigurationDetailResponse: MemberParticipantNavigationDetailResponse) => {
              return NavigationConfigurationActions.getNavigationConfigurationDetailSuccess(
                { navigationConfigurationDetailResponse }
              );
            }),
            catchError((err) => {
              return of(
                NavigationConfigurationActions.getNavigationConfigurationDetailFailure(
                  {
                    error: err.message,
                  }
                )
              );
            })
          );
      })
    )
  );

  removeNavigationConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        NavigationConfigurationActions.removeNavigationConfiguration
      ),
      switchMap(({ query }) => {
        return this.participantNavigationConfigurationService
          .removeNavigationConfiguration(query)
          .pipe(
            map(() => {
              return NavigationConfigurationActions.removeNavigationConfigurationSuccess(
              );
            }),
            catchError((err) => {
              return of(
                NavigationConfigurationActions.removeNavigationConfigurationFailure(
                  {
                    error: err.message,
                  }
                )
              );
            })
          );
      })
    )
  );

  removeNavigationConfigurationItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        NavigationConfigurationActions.removeNavigationConfigurationItem
      ),
      switchMap(({ query }) => {
        return this.participantNavigationConfigurationService
          .removeNavigationConfigurationItem(query)
          .pipe(
            map(() => {
              return NavigationConfigurationActions.removeNavigationConfigurationItemSuccess(
              );
            }),
            catchError((err) => {
              return of(
                NavigationConfigurationActions.removeNavigationConfigurationItemFailure(
                  {
                    error: err.message,
                  }
                )
              );
            })
          );
      })
    )
  );    
  setNavigationConfigurationS$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationConfigurationActions.setNavigationConfigurationRequest),
      switchMap(({ body }) => {
        return this.participantNavigationConfigurationService
          .setNavigationConfiguration(body)
          .pipe(
            map((id: string) => {
              return NavigationConfigurationActions.setNavigationConfigurationSuccess(
                { id }
              );
            }),
            catchError((err) => {
              return of(
                NavigationConfigurationActions.setNavigationConfigurationFailure(
                  {
                    error: err.message,
                  }
                )
              );
            })
          );
      })
    )
  );

  updateNavigationConfigurationS$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        NavigationConfigurationActions.updateNavigationConfigurationRequest
      ),
      switchMap(({ body }) => {
        return this.participantNavigationConfigurationService
          .updateNavigationConfiguration(body)
          .pipe(
            map(() => {
              return NavigationConfigurationActions.updateNavigationConfigurationSuccess();
            }),
            catchError((err) => {
              return of(
                NavigationConfigurationActions.updateNavigationConfigurationFailure(
                  {
                    error: err.message,
                  }
                )
              );
            })
          );
      })
    )
  );

  setNavigationConfigurationItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        NavigationConfigurationActions.setNavigationConfigurationItemRequest
      ),
      switchMap(({ body }) => {
        return this.participantNavigationConfigurationService
          .setNavigationConfigurationItem(body)
          .pipe(
            map(() => {
              return NavigationConfigurationActions.setNavigationConfigurationItemSuccess();
            }),
            catchError((err) => {
              return of(
                NavigationConfigurationActions.setNavigationConfigurationItemFailure(
                  {
                    error: err.message,
                  }
                )
              );
            })
          );
      })
    )
  );

  reorderNavigationConfigurationItem$ = createEffect(() =>
  this.actions$.pipe(
    ofType(
      NavigationConfigurationActions.reorderNavigationConfiguration
    ),
    switchMap(({ body }) => {
      return this.participantNavigationConfigurationService
        .reorderNavigationConfiguration(body)
        .pipe(
          map(() => {
            return NavigationConfigurationActions.reorderNavigationConfigurationSuccess();
          }),
          catchError((err) => {
            return of(
              NavigationConfigurationActions.reorderNavigationConfigurationFailure(
                {
                  error: err.message,
                }
              )
            );
          })
        );
    })
  )
);
  updateNavigationConfigurationItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        NavigationConfigurationActions.updateNavigationConfigurationItemRequest
      ),
      switchMap(({ body }) => {
        return this.participantNavigationConfigurationService
          .updateNavigationConfigurationItem(body)
          .pipe(
            map(() => {
              return NavigationConfigurationActions.updateNavigationConfigurationItemSuccess();
            }),
            catchError((err) => {
              return of(
                NavigationConfigurationActions.updateNavigationConfigurationItemFailure(
                  {
                    error: err.message,
                  }
                )
              );
            })
          );
      })
    )
  );
  getListView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationConfigurationActions.getViewRequest),
      switchMap(({entityId}) => {
        return this.participantNavigationConfigurationService
          .getListView(entityId)
          .pipe(
            map((viewResponse: NavigationViewResponse) => {
              return NavigationConfigurationActions.getViewSuccess({
                viewResponse,
              });
            }),
            catchError((err) => {
              return of(
                NavigationConfigurationActions.getViewFailure({
                  error: err.message,
                })
              );
            })
          );
      })
    )
  );
}
