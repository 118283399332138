import { createSelector } from "@ngrx/store";
import { selectMemberModuleState } from "../reducers";

// header
export const selectHeader = createSelector(
    selectMemberModuleState,
    (state) => state.entityHeader
  );
  
  export const selectHeaderListState = createSelector(
    selectHeader,
    (state) => state.getHeaders
  );
  
  export const selectHeaderDetailState = createSelector(
    selectHeader,
    (state) => state.getHeadersDetail
  );
  
  export const selectHeaderPropertiesState = createSelector(
    selectHeader,
    (state) => state.getHeadersProperties
  );
  
  export const getEntitiesHeaderSelector = createSelector(
    selectHeader,
    (state) => state.getEntities
  );
  
  export const createHeaderSelector = createSelector(
    selectHeader,
    (state) => state.createHeader
  );
  
  export const updateHeaderSelector = createSelector(
    selectHeader,
    (state) => state.updateHeader
  );
  
  export const removeHeaderSelector = createSelector(
    selectHeader,
    (state) => state.removeHeader
  );
  export const getHeaderProfileSelector = createSelector(
    selectHeader,
    (state) => state.headerProfile
  )