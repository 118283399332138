<div class="edit-step-configuration">
  <div class="header-container">
    <ptg-breadcrumb
      [listBreadcrumbs]="listBreadcrumbs"
    ></ptg-breadcrumb>
  </div>
  <div class="flex flex-col p-6 gap-6">
    <ptg-banner
      *ngIf="bannerType"
      [(bannerType)]="bannerType"
      [message]="message"
    ></ptg-banner>

    <div class="wrap-btn">
      <button
        mat-raised-button
        type="submit"
        class="submit-button"
        [disabled]="!canSubmit"
        (click)="formSubmit$.next()"
      >
        Save
      </button>
      <button
        mat-raised-button
        type="button"
        class="cancel-button"
        (click)="onCancel()"
      >
        Cancel
      </button>
    </div>
    <div class="select-container flex">
      <ptg-select
        [controlField]="benefitOptionControl"
        [isOptionObj]="true"
        [isSetMaxWidthSelect]="true"
        [listData]="benefitOptions"
        placeholder="Benefit Option"
        [isRequired]="true"
        (changeOptionValue)="onChangeBenefitOption()"
        customError="existsBenefitOption"
      ></ptg-select>
    </div>
    <div class="tab-group flex flex-col gap-6">
      <mat-tab-group [(selectedIndex)]="selectedTabIndex">
        <ng-container *ngFor="let step of stepConfigurationDetail?.calculationSteps">
          <mat-tab label="{{ step?.name }}">
            <ng-container [ngSwitch]="step?.type">
              <ng-container *ngSwitchCase="CalculationStepType.NonList">
                <ptg-step-configuration-nonlist-step
                  [stepDataFormGroup]="editForm?.get(step?.id ?? '')"
                  [calculationStep]="step"
                  [(canSubmit)]="canSubmit"
                  [cancelTime]="cancelTime"
                  [benefitEntityId]="benefitOptionControl.value"
                ></ptg-step-configuration-nonlist-step>
              </ng-container>
              <ng-container *ngSwitchCase="CalculationStepType.List">
                <ptg-step-configuration-list-step
                  [stepDataFormGroup]="editForm?.get(step?.id ?? '')"
                  [calculationStep]="step"
                  [(canSubmit)]="canSubmit"
                  [cancelTime]="cancelTime"
                ></ptg-step-configuration-list-step>
              </ng-container>
            </ng-container>
          </mat-tab>
        </ng-container>
      </mat-tab-group>
    </div>
  </div>
</div>
