import { createAction, props } from '@ngrx/store';
import { TotalServicesReponse } from '@ptg-member/types/models/total-services.model';

export const GetTotalServicesRequest =
  '[GetTotalServices/API] Send Request';
export const GetTotalServicesSuccess = '[GetTotalServices/API] Success';
export const GetTotalServicesFailure = '[GetTotalServices/API] Failure';

export const getTotalServices = createAction(
  GetTotalServicesRequest,
  props<{ query: any, memberId: string }>()
);
export const getTotalServicesSuccess = createAction(
  GetTotalServicesSuccess,
  props<{ totalServices: TotalServicesReponse }>()
);
export const getetTotalServicesFailure = createAction(
  GetTotalServicesFailure
);
