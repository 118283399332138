import { createAction, props } from '@ngrx/store';

import { MemberList, MemberListQuery } from '../../types/models/member-list';

export const SetMemberIndex = '[SetMemberIndex] Set';
export const GetMoreMembersRequest = '[GetMoreMembersRequest/API] Send Request';
export const GetMoreMembersSuccess = '[GetMoreMembersSuccess/API] Success';
export const SetMembers = '[SetMembers/API] Set';
export const SetQuery = '[SetQuery/API] Set';
export const ClearMemberNavigationState = '[ClearMemberNavigationState] Clear';

export const setMemberIndex = createAction(
  SetMemberIndex,
  props<{ memberIndex: number }>()
);
export const getMoreMembers = createAction(
  GetMoreMembersRequest,
  props<{ query: MemberListQuery; idClient: string; isAppend: boolean }>()
);
export const getMoreMembersSuccess = createAction(
  GetMoreMembersSuccess,
  props<{ memberList: MemberList; isAppend: boolean }>()
);
export const setQuery = createAction(
  SetQuery,
  props<{ query: MemberListQuery }>()
);
export const setMembers = createAction(
  SetMembers,
  props<{ memberList: MemberList; pageSize: number }>()
);
export const clearMemberNavigationState = createAction(
  ClearMemberNavigationState
);
