import { createReducer, on } from '@ngrx/store';

import { STATE } from '@ptg-shared/constance/value.const';

import * as DropPlanActions from '../actions/drop-plan.actions';
import { GetDropPlanResponse } from '@ptg-member/types/models/drop-plan.model';

export const dropPlanFeatureKey = 'dropPlan';

export interface State {
  isLoading: boolean;
  dropPlan: GetDropPlanResponse;
  updateState: string;
}

const initialState: State = {
  isLoading: true,
  dropPlan: {
    dropPlans: [],
    total: 0,
  },
  updateState: '',
};

export const reducer = createReducer(
  initialState,
  on(DropPlanActions.clearDropPlanState, (state) => ({
    ...state,
    updateState: '',
  })),
  on(DropPlanActions.getDropPlanSuccess,
    (state, { dropPlan }) => ({
      ...state,
      isLoading: false,
      dropPlan: dropPlan,
    })
  ),
  on(DropPlanActions.getDropPlanFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
    })
  ),
  on(DropPlanActions.setDropPlanSuccess, (state) => ({
    ...state,
    isLoading: false,
    updateState: STATE.SUCCESS,
  })),

  on(DropPlanActions.setDropPlanFailure, (state, {}) => ({
    ...state,
    isLoading: false,
    updateState: STATE.FAIL,
  })),
);
