import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { interval, Subject, Subscription } from 'rxjs';
import { startWith, take, takeUntil } from 'rxjs/operators';
import { DateTime } from 'luxon';

import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { CANCEL_CONFIRM_MESSAGE } from '@ptg-shared/constance/value.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { AzureStorageService } from '@ptg-shared/services/azure-storage.service';
import { BulkUpdateService } from '@ptg-bulk-update/services/bulk-update.service';
import { FundModel } from '@ptg-fund-list/models/fund-list.model';
import { ActiveFundService } from '@ptg-fund-list/services/active-fund.service';
import * as fromReducer from '@ptg-reducers/index';

import { UPLOAD_STATUS } from '../../constance/lookupTable.const';
import * as fromMember from '../../store/reducers';
import * as LookupTableActions from '../../store/actions/lookup-table.actions';
import { ImportSessionDetail } from '../../types/models';

@Component({
  selector: 'ptg-upload-lookup-table',
  templateUrl: './upload-lookup-table.component.html',
  styleUrls: ['./upload-lookup-table.component.scss'],
})
export class UploadLookupTableComponent implements OnInit, OnDestroy {
  FILE_NAME_SEPARATOR: string = '___';
  SOURCE: string = 'LookupTable';
  showTransitionDialog: boolean = false;
  supportedFileTypes: string = 'xls, xlsx file format.';
  checkPattern: RegExp = new RegExp(/^[\x00-\x7F]+\.(xls|xlsx)$/, 'i');
  acceptFile = '.xls,.xlsx';
  unsubscribe$ = new Subject<void>();
  activeFund!: FundModel;
  UPLOAD_STATUS = UPLOAD_STATUS;
  uploadStatusMessage: string =
    'File is validating, this might take a few minutes for everything to be to be fully validated.';
  pollInterval?: Subscription;
  sessionDetail!: ImportSessionDetail;
  proceedImportSubject$: Subject<string> = new Subject<string>();
  fileName: string = '';

  constructor(
    private activeFundService: ActiveFundService,
    private bulkUpdateService: BulkUpdateService,
    private store: Store<fromReducer.State>,
    public azureStorageService: AzureStorageService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<UploadLookupTableComponent>,
    private memberStore: Store<fromMember.MemberState>
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(select(fromReducer.selectCurrentFundState))
      .subscribe((currentFund) => {
        this.activeFund = currentFund;
      });
    this.proceedImport();
    this.memberStore
      .select(fromMember.selectSessionDetailState)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        this.sessionDetail = state?.sessionDetail;
        if (
          [
            UPLOAD_STATUS.INVALID,
            UPLOAD_STATUS.IMPORTED,
            UPLOAD_STATUS.CANCELED,
            UPLOAD_STATUS.ERRONEOUS,
          ].includes(state?.sessionDetail?.status)
        ) {
          this.dialogRef.close();
        }
        if (this.sessionDetail?.status === UPLOAD_STATUS.IMPORTING) {
          this.uploadStatusMessage =
            'File is uploading, this might take a few minutes for everything to be fully uploaded.';
        }
        if (this.sessionDetail?.status === UPLOAD_STATUS.VALIDATED) {
          this.proceedImportSubject$.next(this.sessionDetail?.id);
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.proceedImportSubject$.next();
    this.proceedImportSubject$.complete();
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: CANCEL_CONFIRM_MESSAGE,
        type: ConfirmType.Cancel,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  uploadFile(event: File): void {
    this.showTransitionDialog = true;
    this.fileName = event.name;
    if (this.fileName.indexOf(this.FILE_NAME_SEPARATOR) >= 0) {
      const fileParts = this.fileName.split(this.FILE_NAME_SEPARATOR);
      this.fileName = fileParts.join('_');
    }
    const sourceId = DateTime.fromJSDate(new Date()).toFormat(
      'yyMMddHHmmssSSS'
    );
    const batchId = new Date().getTime().toString();
    const blobName = `${this.activeFund?.key}/${
      this.SOURCE
    }/${sourceId}/propertyKey/${batchId}${'___'}${this.FILE_NAME_SEPARATOR}${
      this.fileName
    }`;
    this.azureStorageService.uploadBlob(event, blobName).then(() => {
      this.getImportSession(sourceId, batchId);
    }).catch((error) => {
      this.dialogRef.close('Fail');
    });
  }

  getImportSession(sourceId: string, batchId: string) {
    this.pollInterval = interval(2500)
      .pipe(startWith(0), takeUntil(this.unsubscribe$))
      .subscribe(() =>
        this.memberStore.dispatch(
          LookupTableActions.getImportSessionDetail({
            source: this.SOURCE,
            sourceId,
            batchId,
          })
        )
      );
  }

  proceedImport() {
    this.proceedImportSubject$
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe((id) => {
        this.memberStore.dispatch(LookupTableActions.proceedImport({ id }));
      });
  }

  cancelUpload() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: 'Are you sure you want to cancel the uploading process?',
        type: ConfirmType.Cancel,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }
      this.azureStorageService.cancelUpload();
      if (this.sessionDetail?.id) {
        this.memberStore.dispatch(
          LookupTableActions.cancelImport({ id: this.sessionDetail.id })
        );
      }
      this.memberStore.dispatch(LookupTableActions.cancelImportSuccess());
      this.dialogRef.close();
    });
  }
}
