<span class="checkbox-container">
  <mat-checkbox [formControl]="controlField" class="input-form-field" (change)="onChangeValue($event)" [disabled]="isDisabled || controlField?.disabled">
    <ng-container *ngIf="!single; else singleControl">
      {{label}}
    </ng-container>
    <ng-template #singleControl>
      {{controlField.value ? 'Active' : 'Inactive'}}
    </ng-template>
  </mat-checkbox>
  <mat-error *ngIf="controlField?.errors?.[customError] && !controlField?.errors?.required">
    {{ controlField.getError([customError]) }}
  </mat-error>
</span>