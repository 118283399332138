import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

@Injectable()
export class PayrollPayeeService {
  constructor(private httpClient: HttpClient) {}

  searchPayrollPayees(
    searchValue: string,
    clientId: string,
    payrollId: string
  ) {
    let params = new HttpParams();
    params = params.append('clientId', clientId);
    params = params.append('searchValue', searchValue);
    params = params.append('timeZoneOffset', new Date().getTimezoneOffset());

    return this.httpClient.get<any>(
      `${environment.apiUrl}/Payroll/${payrollId}/Payees/Search`,
      { params }
    );
  }
}
