import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mask'
})
export class MaskPipe implements PipeTransform {

  transform(value: string, isFirst: boolean): unknown {
    let length = value.length;
    if (isFirst) {
      if (length < 5) {
        return '';
      } else {
        let res = '';
        for (let i = 0; i < (length - 4); i += 1) {
          res += 'X';
        }
        return res;
      }
    } else {
      if (length < 5) {
        return value;
      } else {
        return value.substring(length - 4)
      }
    }
  }

}
