import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActiveFundService {
  private _brandRefreshed: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _metadataUpdated: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _navigationUpdated: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _id?: string;
  private _key?: string;
  
  currentFund: any = {};

  get HasKey(): boolean { return (this.Key !== undefined && this.Key !== null && this.Key.length > 0); }

  get Key(): string { return this._key ?? this.currentFund?.domain ?? this.currentFund?.key; }
  set Key(value: string) { this._key = value; }

  get Id(): string { return this._id ?? this.currentFund?.id; }
  set Id(value: string) { this._id = value; }

  brandRefreshed$: Observable<string>;
  metadataUpdated$: Observable<string>;
  navigationUpdated$: Observable<string>;

  constructor() { 
    this.brandRefreshed$ = this._brandRefreshed.asObservable();
    this.metadataUpdated$ = this._metadataUpdated.asObservable();
    this.navigationUpdated$ = this._navigationUpdated.asObservable();
  }

  refreshBrand(): void {
    if(this.HasKey) {
      this._brandRefreshed.next(this.Key);
    }
  }

  updateMetadata(): void {
    if(this.HasKey) {
      this._metadataUpdated.next(this.Key);
    }
  }  

  updateNavigation(): void {
    if(this.HasKey) {
      this._navigationUpdated.next(this.Key);
    }
  }  
}