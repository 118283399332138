import { createAction, props } from "@ngrx/store";
import { GetHeaderListRequest, GetHeaderListResponse, GetHeaderPropertiesRequest, Header, HeaderDetailResponse, HeaderPropertiesResponse, NewHeader, UpdateHeaderDetailRequest } from "@ptg-member/types/models";
import { ProfileConfigListResponse } from "@ptg-member/types/models/profile-configuration.model";
import { GetEntitiesQuery, GetEntitiesResponse } from "src/app/entity-management/services/models";

export const clearHeaderDetail = '[ClearHeaderDetailState]';
export const headerListRequest = '[headerList/API] Send Request';
export const headerListFailure = '[headerList/API] Failure';
export const headerListSuccess = '[headerList/API] Success';
export const hederProfileRequest = '[hederProfile/API] Send Request';
export const hederProfileFailure = '[hederProfile/API] Failure';
export const hederProfileSuccess = '[hederProfile/API] Success';
export const headerDetailRequest = '[headerDetail/API] Send Request';
export const headerDetailFailure = '[headerDetail/API] Failure';
export const headerDetailSuccess = '[headerDetail/API] Success';
export const headerPropertiesRequest = '[headerProperties/API] Send Request';
export const headerPropertiesSuccess = '[headerProperties/API] Success';
export const headerPropertiesFailure = '[headerProperties/API] Failure';

export const clearHeaderDetailState = createAction(
  clearHeaderDetail
);

export const getHeaderList = createAction(
    headerListRequest,
  props<{ query: GetHeaderListRequest }>()
);
export const getHeaderListSuccess = createAction(
    headerListSuccess,
  props<{ headerList: GetHeaderListResponse }>()
);
export const getHeaderListFailure = createAction(
    headerListFailure,
  props<{ error?: any }>()
);

export const getHeaderDetail = createAction(
  headerDetailRequest,
  props<{ id: string }>()
);
export const getHeaderDetailSuccess = createAction(
  headerDetailSuccess,
props<{ headerDetail: HeaderDetailResponse }>()
);
export const getHeaderDetailFailure = createAction(
  headerDetailFailure,
props<{ error?: any }>()
);

export const getHeaderProperties = createAction(
  headerPropertiesRequest,
  props<{ query: GetHeaderPropertiesRequest }>()
);
export const getHeaderPropertiesSuccess = createAction(
  headerPropertiesSuccess,
props<{ headerProperties: HeaderPropertiesResponse }>()
);
export const getHeaderPropertiesFailure = createAction(
  headerPropertiesFailure,
props<{ error?: any }>()
);

export const getHeaderProfile = createAction(
  hederProfileRequest,
  props<{ id: string }>()
);
export const getHeaderProfileSuccess = createAction(
  hederProfileSuccess,
props<{ profileConfigs: ProfileConfigListResponse }>()
);
export const getHeaderProfileFailure = createAction(
  hederProfileFailure,
props<{ error?: any }>()
);

export const getEntitiesAction = createAction(
  '[GetEntitiesHeader/API] Send Request',
  props<{ query: GetEntitiesQuery }>()
);

export const getEntitiesSuccessAction = createAction(
  '[GetEntitiesHeader/API] Success',
  props<{ response: GetEntitiesResponse }>()
);

export const getEntitiesFailureAction = createAction(
  '[GetEntitiesHeader/API] Failure',
  props<{ error?: any }>()
);

export const createHeader = createAction(
  '[CreateHeader/API] Send Request',
  props<{ body: NewHeader }>()
);
export const createHeaderSuccess = createAction(
  '[CreateHeader/API] Success',
  props<{ body: Header }>()
);
export const createHeaderFailure = createAction(
  '[CreateHeader/API] Failure',
  props<{ error: string }>()
);

export const updateHeader = createAction(
  '[UpdateHeader/API] Send Request',
  props<{ body: UpdateHeaderDetailRequest }>()
);
export const updateHeaderSuccess = createAction(
  '[UpdateHeader/API] Success'
);
export const updateHeaderFailure = createAction(
  '[UpdateHeader/API] Failure',
  props<{ error: string }>()
);

export const removeHeader = createAction(
  '[RemoveHeader/API] Send Request',
  props<{ id: string }>()
);
export const removeHeaderSuccess = createAction(
  '[RemoveHeader/API] Success'
);
export const removeHeaderFailure = createAction(
  '[RemoveHeader/API] Failure',
  props<{ error: string }>()
);