export enum ColumnIndex {
  Column1 = 1,
  Column2 = 2,
  Column3 = 3,
  Column4 = 4,
}

export enum NonListPropertyType {
  Property,
  Aggregation,
  Calculation,
  BlankSpace,
}
