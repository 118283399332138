export enum DataType {
    TYPE_TEXT = 'Text',
    TYPE_DATE = 'Date',
    TYPE_BOOLEAN = 'Boolean',
    TYPE_STATUS = 'Status',
    TYPE_NUMBER = 'Number',
    TYPE_CURRENCY = 'Currency',
    TYPE_LIST = 'List',
    TYPE_PERCENTAGE = 'Percentage'
};
