import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';
import { STATE } from '@ptg-shared/constance/value.const';

import * as StatusHistoryAction from '../actions/status-history.action';
import { AuditTrail, MemberStatus, StatusHistoryList } from '../../types/models';

export const StatusHistoryFeatureKey = 'statusHistory';
export interface State {
  isLoading: boolean;
  statusHistoryList: StatusHistoryList;
  createState?: string;
  updateState?: string;
  listMemberStatus: MemberStatus[];
  getAuditTrails?: BaseActionState<AuditTrail[]>;
}

const initialState: State = {
  isLoading: true,
  statusHistoryList: {
    total: 0,
    memberStatusHistoryDto: [],
  },
  createState: '',
  updateState: '',
  listMemberStatus: [],
};

export const reducer = createReducer(
  initialState,
  on(StatusHistoryAction.getStatusHistoryList, (state, {}) => ({
    ...state,
    isLoading: true,
    statusHistoryList: {
      total: 0,
      memberStatusHistoryDto: [],
    },
  })),
  on(
    StatusHistoryAction.statusHistoryListSuccess,
    (state, { statusHistoryList }) => ({
      ...state,
      isLoading: false,
      statusHistoryList,
    })
  ),
  on(StatusHistoryAction.statusHistoryListFailure, (state) => ({
    ...state,
    isLoading: false,
    statusHistoryList: {
      total: 0,
      memberStatusHistoryDto: [],
    },
  })),
  on(
    StatusHistoryAction.getMemberStatusListSuccess,
    (state, { listMemberStatus }) => ({
      ...state,
      listMemberStatus,
    })
  ),
  on(StatusHistoryAction.getMemberStatusListFailure, (state) => ({
    ...state,
    listMemberStatus: [],
  })),
  on(StatusHistoryAction.clearStatusHistoryState, (state) => ({
    ...state,
    createState: '',
    updateState: '',
  })),
  on(StatusHistoryAction.createMemberStatusHistorySuccess, (state) => ({
    ...state,
    createState: STATE.SUCCESS,
  })),
  on(StatusHistoryAction.createMemberStatusHistoryFailure, (state) => ({
    ...state,
    createState: STATE.FAIL,
  })),
  on(StatusHistoryAction.editMemberStatusHistorySuccess, (state) => ({
    ...state,
    updateState: STATE.SUCCESS,
  })),
  on(StatusHistoryAction.editMemberStatusHistoryFailure, (state) => ({
    ...state,
    updateState: STATE.FAIL,
  })),

  on(StatusHistoryAction.getAuditTrails, (state) => ({
    ...state,
    getAuditTrails: {
      isLoading: true,
    },
  })),
  on(StatusHistoryAction.getAuditTrailsSuccess, (state, { response }) => ({
    ...state,
    getAuditTrails: {
      isLoading: false,
      success: true,
      payload: response.auditTrails || [],
      total: response.total || 0,
    },
  })),
  on(StatusHistoryAction.getAuditTrailsFailure, (state, { error }) => ({
    ...state,
    getAuditTrails: {
      isLoading: false,
      success: false,
      error: error,
    },
  }))
);
