<div class="calculation-parameter-configuration-container">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>

  <div class="flex flex-col p-6 gap-6">
    <ptg-banner
      *ngIf="bannerType"
      [(bannerType)]="bannerType"
      [message]="message"
    ></ptg-banner>

    <ptg-add-parameter-configuration [propertyOptions]="propertyOptions"
                                     [inputOptions]="inputOptions"
                                     [outputOptions]="outputOptions"
                                     [configurations]="configurations"
                                     [isLoading]="isLoading"
                                     (onSubmitEvent)="onSubmit($event)"
    ></ptg-add-parameter-configuration>
  </div>
</div>
