import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SortType } from '@ptg-shared/constance';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';
import { CreateProfileRequest, GetProfileConfigsQuery, MenuItemExistRequest, ProfileConfigDetailResponse, ProfileConfigListResponse, ProfileExistRequest, ProfileExistResponse, ProfileMenuItemExisResponse, ProfileMenuResponse, ProfileOverHeaderResponse, ProfileOverViewResponse, StatusEventResponse, UpdateProfileRequest } from '../types/models/profile-configuration.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileConfigService {
  constructor(private httpClient: HttpClient) { }
  getProfileConfigList(query: GetProfileConfigsQuery): Observable<ProfileConfigListResponse> {
    let params = new HttpParams();
    if (query.sortField) {
      params = params.append('SortNames', capitalizeFirstLetter(query.sortField));
      params = params.append('SortType', query.sortType ?? SortType.ASC);
    }
    if (query?.pageSize) {
      params = params.append('PageSize', query?.pageSize);
    }
    if (query?.pageIndex) {
      params = params.append('PageIndex', query.pageIndex.toString());
    }
    return this.httpClient.get<ProfileConfigListResponse>(`${environment.apiUrl}/entity/profiles`, { params });
  }

  getMenuItemExist(body?: MenuItemExistRequest): Observable<ProfileMenuItemExisResponse> {
    return this.httpClient.put<ProfileMenuItemExisResponse>(`${environment.apiUrl}/entity/profiles/check-exist-menu`, body);
  }

  getProfileView(id?: string): Observable<ProfileOverViewResponse> {
    return this.httpClient.get<ProfileOverViewResponse>(
      `${environment.apiUrl}/entity/profiles/view/${id}`);
  }

  getProfileDetail(id?: string): Observable<ProfileConfigDetailResponse> {
    return this.httpClient.get<ProfileConfigDetailResponse>(
      `${environment.apiUrl}/entity/profiles/${id}`);
  }

  getProfileHeader(id?: string): Observable<ProfileOverHeaderResponse> {
    return this.httpClient.get<ProfileOverHeaderResponse>(
      `${environment.apiUrl}/entity/profiles/header/${id}`);
  }

  getProfileStatusEvent(id?: string): Observable<StatusEventResponse> {
    return this.httpClient.get<StatusEventResponse>(
      `${environment.apiUrl}/entity/profiles/status-events/${id}`);
  }

  getProfileMenu(id?: string): Observable<ProfileMenuResponse> {
    return this.httpClient.get<ProfileMenuResponse>(
      `${environment.apiUrl}/entity/navigation/byEntityId/${id}`);
  }

  updateProfile(body?: UpdateProfileRequest): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/entity/profiles/${body?.id}`, body);
  }

  createProfile(body?: CreateProfileRequest): Observable<void> {
    return this.httpClient.post<void>(
      `${environment.apiUrl}/entity/profiles`, body);
  }

  removeProfile(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${environment.apiUrl}/entity/profiles/${id}`);
  }

  getProfileExist(body?: ProfileExistRequest): Observable<ProfileExistResponse> {
    return this.httpClient.put<ProfileExistResponse>(`${environment.apiUrl}/entity/profiles/check-exist-name`, body);
  }
}
