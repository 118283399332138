import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AddressHistoryAction } from '../../store/actions';
import {
  AddressEntityProperty,
  AddressHistoryList
} from '../../types/models';
import { AddressHistoryService } from '@ptg-member/services/address-history.service';

@Injectable()
export class AddressHistoryEffects {
  getAddressHistoryList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddressHistoryAction.getAddressHistoryList),
      switchMap(({ query, entityId }) => {
        return this.addressHistoryService.getAddressHistoryList(query, entityId).pipe(
          map((addressHistoryResult: AddressHistoryList) => {
            return AddressHistoryAction.getListSuccess({
              addressHistoryResult
            });
          }),
          catchError((err) => {
            return of(AddressHistoryAction.getListFailure());
          })
        );
      })
    )
  );

  getAddressEntityProperty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddressHistoryAction.getAddressProperties),
      switchMap(({ entityId }) => {
        return this.addressHistoryService.getAddressEntityProperty(entityId).pipe(
          map((result: AddressEntityProperty) => {
            let properties = result?.properties;
            return AddressHistoryAction.getAddressPropertiesSuccess({
              properties
            });
          }),
          catchError((err) => {
            return of(AddressHistoryAction.getAddressPropertiesFailure());
          })
        );
      })
    )
  );

  createAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddressHistoryAction.createAddress),
      switchMap(({ body }) => {
        return this.addressHistoryService.createAddress(body).pipe(
          map(() => {
            return AddressHistoryAction.createAddressSuccess();
          }),
          catchError((err) => {
            return of(AddressHistoryAction.createAddressFailure());
          })
        );
      })
    )
  );

  updateAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddressHistoryAction.updateAddress),
      switchMap(({ body }) => {
        return this.addressHistoryService.updateAddress(body).pipe(
          map(() => {
            return AddressHistoryAction.updateAddressSuccess();
          }),
          catchError((err) => {
            return of(AddressHistoryAction.updateAddressFailure());
          })
        );
      })
    )
  );
  checkExitAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddressHistoryAction.checkExitAddress),
      switchMap(({ body }) => {
        return this.addressHistoryService.checkExit({requests: body}).pipe(
          map((messages: any) => {
            return AddressHistoryAction.checkExitAddressSuccess({messages});
          }),
          catchError((err) => {
            return of(AddressHistoryAction.checkExitAddressFailure());
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private addressHistoryService: AddressHistoryService
  ) {}
}
