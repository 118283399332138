import * as fromBulkUpdateHistory from './bulk-update.reducer';
import * as fromBulkUpdateLog from './bulk-update-log.reducer';
import * as fromRoot from '../../reducers'
import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

export const bulkUpdateModuleFeatureKey = 'bulkUpdateModule';

export interface BulkUpdateState {
  [fromBulkUpdateHistory.bulkUpdateHistoryFeatureKey]: fromBulkUpdateHistory.State;
  [fromBulkUpdateLog.bulkUpdateLogFeatureKey]: fromBulkUpdateLog.State;
}

export interface State extends fromRoot.State {
  [bulkUpdateModuleFeatureKey]: BulkUpdateState;
}

/** Provide reducer in AoT-compilation happy way */
export function reducers(state: BulkUpdateState | undefined, action: Action) {
    return combineReducers({
        [fromBulkUpdateHistory.bulkUpdateHistoryFeatureKey]: fromBulkUpdateHistory.reducer,
        [fromBulkUpdateLog.bulkUpdateLogFeatureKey]: fromBulkUpdateLog.reducer
    })(state, action);
}

/**
 * The createFeatureSelector function selects a piece of state from the root of the state object.
 * This is used for selecting feature states that are loaded eagerly or lazily.
 */
export const selectBulkUpdateModuleState = createFeatureSelector<BulkUpdateState>(
    bulkUpdateModuleFeatureKey
);
  
/**
 * Every reducer module exports selector functions, however child reducers
 * have no knowledge of the overall state tree. To make them usable, we
 * need to make new selectors that wrap them.
 *
 * The createSelector function creates very efficient selectors that are memoized and
 * only recompute when arguments change. The created selectors can also be composed
 * together to select different pieces of state.
 */
export const selectBulkUpdateHistoryState = createSelector(
  selectBulkUpdateModuleState,
  (state) => state[fromBulkUpdateHistory.bulkUpdateHistoryFeatureKey]
);

export const selectBulkUpdateLogState = createSelector(
  selectBulkUpdateModuleState,
  (state) => state[fromBulkUpdateLog.bulkUpdateLogFeatureKey]
);