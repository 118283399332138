import { Injectable } from '@angular/core';
import { Breadcrumb } from '../types/models/breadcrumb.model';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  previousProcessing: Breadcrumb = {
    name: '',
    url: ''
  }

  isBackNextPayroll: boolean = false;
  isNextPayroll: boolean = false;
  currentNextPayrollBanner: any;
  constructor() { }
}
