import { createAction, props } from '@ngrx/store';
import {
  GetCartResponse,
  GetEntityResponse,
  ParametersQuery,
  QueryRequest,
  SetCartRequest,
  CardDetail,
  GetEntityComponentResponse,
  GetEntityPropertyResponse,
  GetEntityRequest,
} from '@ptg-member/types/models/card.model';

export const GetCartRequestAction = '[GetCart/API] Send Request';
export const GetCartSuccessAction = '[GetCart/API] Success';
export const GetCartFailureAction = '[GetCart/API] Failure';

export const GetEntityRequestAction = '[GetEntity/API] Send Request';
export const GetEntitySuccessAction = '[GetEntity/API] Success';
export const GetEntityFailureAction = '[GetEntity/API] Failure';

export const SetCartRequestAction = '[SetCart/API] Send Request';
export const SetCartSuccessAction = '[SetCart/API] Success';
export const SetCartFailureAction = '[SetCart/API] Failure';

export const RemoveCartRequestAction =
  '[RemoveCart/API] Send Request';
export const RemoveCartFailureAction =
  '[RemoveCart/API] Failure';
export const RemoveCartSuccessAction =
  '[RemoveCart/API] Success';


export const ClearCart = '[ClearCart] Clear';

export const getCartRequest = createAction(
  GetCartRequestAction,
  props<{ query: ParametersQuery }>()
);

export const getCartSuccess = createAction(
  GetCartSuccessAction,
  props<{ cartResponse: GetCartResponse }>()
);

export const getCartFailure = createAction(
  GetCartFailureAction,
  props<{ error?: any }>()
);

export const getEntityRequest = createAction(
  GetEntityRequestAction,
  props<{ query?: GetEntityRequest }>(),
);

export const getEntitySuccess = createAction(
  GetEntitySuccessAction,
  props<{ entityResponse: GetEntityResponse }>()
);

export const getEntityFailure = createAction(
  GetEntityFailureAction,
  props<{ error?: any }>()
);

export const setCartRequest = createAction(
  SetCartRequestAction,
  props<{ body: SetCartRequest }>()
);

export const setCartSuccess = createAction(
  SetCartSuccessAction,
  props<{ id: string }>()
);

export const setCartFailure = createAction(
  SetCartFailureAction,
  props<{ error?: any }>()
);

export const removeCart = createAction(
  RemoveCartRequestAction,
  props<{ query: QueryRequest }>()
);
export const removeCartSuccess = createAction(
  RemoveCartSuccessAction
);
export const removeCartFailure = createAction(
  RemoveCartFailureAction,
  props<{ error?: any }>()
);

export const clearCartState = createAction(ClearCart);

export const getCardDetail = createAction(
  '[CardDetail/API] Send Request',
  props<{ id: string }>()
);
export const getCardDetailSuccess = createAction(
  '[CardDetail/API] Success',
  props<{ cardDetail: CardDetail }>()
);
export const getCardDetailFailure = createAction(
  '[CardDetail/API] Failure',
  props<{ error?: any }>()
);

export const getEntityComponent = createAction(
  '[EntityComponent/API] Send Request',
  props<{ cardId: string, entityComponentId: string }>()
);
export const getEntityComponentSuccess = createAction(
  '[EntityComponent/API] Success',
  props<{ entityComponent: GetEntityComponentResponse }>()
);
export const getEntityComponentFailure = createAction(
  '[EntityComponent/API] Failure',
  props<{ error?: any }>()
);

export const getEntityProperty = createAction(
  '[EntityProperty/API] Send Request',
  props<{ id: string }>()
);
export const getEntityPropertySuccess = createAction(
  '[EntityProperty/API] Success',
  props<{ entityProperty: GetEntityPropertyResponse }>()
);
export const getEntityPropertyFailure = createAction(
  '[EntityProperty/API] Failure',
  props<{ error?: any }>()
);

export const updateCard = createAction(
  '[UpdateCard/API] Send Request',
  props<{ id: string, body: CardDetail }>()
);
export const updateCardSuccess = createAction(
  '[UpdateCard/API] Success'
);
export const updateCardFailure = createAction(
  '[UpdateCard/API] Failure',
  props<{ error?: any }>()
);

export const clearCardState = createAction(
  '[ClearCardState/API]',
);

export const getEntityPropertyVersion = createAction(
  '[EntityPropertyVersion/API] Send Request',
  props<{ id: string, isVersionHistory: boolean }>()
);
export const getEntityPropertyVersionSuccess = createAction(
  '[EntityPropertyVersion/API] Success',
  props<{ entityPropertyVersion: GetEntityPropertyResponse }>()
);
export const getEntityPropertyVersionFailure = createAction(
  '[EntityPropertyVersion/API] Failure',
  props<{ error?: any }>()
);