<div class="detail-header">
  <div class="header-title">
    <div class="title">{{ title }}</div>
  </div>

  <div class="header-infos">
    <div class="item" *ngFor="let field of listFieldData">
      <span class="label truncate">{{ field.name }}</span>
      <ng-container>
        <span class="value truncate">{{ field.value || '-' }}</span>
      </ng-container>
    </div>
  </div>

  <div class="header-action">
    <ptg-button buttonName="Edit" classInput="edit-button" (clickButton)="onClickEdit()">
      <mat-icon>edit</mat-icon>
    </ptg-button>
  </div>
</div>