export const DEFAULT_KEY_COLUMN = 'id';
export const SELECTION_COLUMN = 'selection';
export const RADIO_COLUMN = 'radio';
export const DRAG_DROP_COLUMN = 'dragdrop';
export const ACTION_COLUMN = 'action';
export const GHOST_COLUMN = 'ghost';
export const DEFAULT_CURRENT_ROW_INDEX = -1;
export const DEFAULT_NOT_FOUND_MSG = 'Data Not Found';
export const STOP_PROPAGATION_CLASS = 'stop-propagation';
export const EXPAND_COLLAPSE_COLUMN = 'expand-collapse-action';
