import { createReducer, on } from '@ngrx/store';

import { 
  clearViewState,
  createView, 
  createViewFailure, 
  createViewSuccess, 
  getCardListFailure, 
  getCardListSuccess, 
  getEntitiesAction, 
  getEntitiesFailureAction, 
  getEntitiesSuccessAction, 
  getRemoveAccessFailureAction, 
  getRemoveAccessSuccessAction, 
  getViewDetailFailure, 
  getViewDetailSuccess, 
  getViewListFailure, 
  getViewListSuccess, 
  removeView, 
  removeViewFailure, 
  removeViewSuccess, 
  updateView, 
  updateViewFailure, 
  updateViewSuccess,
  getViewDatalessSuccess,
  getViewDatalessFailure,
  updateViewDataless,
  updateViewDatalessSuccess,
  updateViewDatalessFailure,
} from '../actions/view.actions';
import { BaseActionState } from '@ptg-shared/types/models';
import {
  View,
  ViewDetail,
  CreateViewResponse,
  GetListCardResponse,
  RemoveAccessResponse,
  ViewDataless,
} from '@ptg-member/types/models';
import { Entity } from 'src/app/entity-management/services/models';

export interface ViewState {
  getViews?: BaseActionState<View[]>;
  getCards?: BaseActionState<GetListCardResponse>;
  getEntities?: BaseActionState<Entity[]>;
  getViewDetail?: BaseActionState<ViewDetail>;
  getRemoveAccess?: BaseActionState<RemoveAccessResponse>;
  createView?: BaseActionState<CreateViewResponse>;
  removeView?: BaseActionState;
  updateView?: BaseActionState;
  getViewDataless?: BaseActionState<ViewDataless>;
  updateViewDataless?: BaseActionState;
}

export const viewFeatureKey = 'view';

const initialState: ViewState = {
  getCards: {
    isLoading: true,
    payload: { cards: []}
  },
  getViewDetail: {
    isLoading: true,
    payload: {}
  },
  getViewDataless: {
    isLoading: true,
    payload: {}
  },
  getRemoveAccess: {
    isLoading: true,
    success: false,
    payload: { 
      listEntityNavigation: [],
      listProfileConfigView: []
    }
  },
};

export const viewReducer = createReducer(
  initialState,
  on(clearViewState, (state: ViewState) => ({
    ...initialState
  })),
  on(getViewListSuccess, (state: ViewState, { viewList }) => ({
    ...state,
    getViews: {
      isLoading: false,
      success: true,
      payload: viewList.entityViews
    }
  })),
  on(getViewListFailure, (state, { error }) => ({
    ...state,
    getViews: {
      isLoading: false,
      success: false,
      payload: []
    }
  })),

  on(getViewDetailSuccess, (state: ViewState, { viewDetail }) => ({
    ...state,
    getViewDetail: {
      isLoading: false,
      success: true,
      payload: viewDetail
    }
  })),
  on(getViewDetailFailure, (state, { error }) => ({
    ...state,
    getViewDetail: {
      isLoading: false,
      success: false,
      payload: {}
    }
  })),

  on(getCardListSuccess, (state: ViewState, { cardList }) => ({
    ...state,
    getCards: {
      isLoading: false,
      success: true,
      payload: cardList
    }
  })),
  on(getCardListFailure, (state, { error }) => ({
    ...state,
    getCards: {
      isLoading: false,
      success: false,
      payload: { cards: []}
    }
  })),

  on(createView, (state) => ({
    ...state,
    createView: {
      isLoading: true,
    },
  })),

  on(createViewSuccess, (state: ViewState, { id }) => ({
    ...state,
    createView: {
      isLoading: false,
      success: true,
      payload: { id }
    },
  })),

  on(createViewFailure, (state: ViewState, { errorMsg }) => ({
    ...state,
    createView: {
      isLoading: false,
      success: false,
      error: errorMsg,
    },
  })),
  
  on(getRemoveAccessSuccessAction, (state: ViewState, { response }) => ({
    ...state,
    getRemoveAccess: {
      isLoading: false,
      success: true,
      payload: response
    },
  })),

  on(getRemoveAccessFailureAction, (state: ViewState) => ({
    ...state,
    getRemoveAccess: {
      isLoading: false,
      success: false,
    },
  })),

  on(removeView, (state) => ({
    ...state,
    removeView: {
      isLoading: true,
    },
  })),

  on(removeViewSuccess, (state: ViewState) => ({
    ...state,
    removeView: {
      isLoading: false,
      success: true,
    },
  })),

  on(removeViewFailure, (state: ViewState, { errorMsg }) => ({
    ...state,
    removeView: {
      isLoading: false,
      success: false,
      error: errorMsg,
    },
  })),

  on(updateView, (state) => ({
    ...state,
    updateView: {
      isLoading: true,
    },
  })),

  on(updateViewSuccess, (state: ViewState) => ({
    ...state,
    updateView: {
      isLoading: false,
      success: true,
    },
  })),

  on(updateViewFailure, (state: ViewState, { errorMsg }) => ({
    ...state,
    updateView: {
      isLoading: false,
      success: false,
      error: errorMsg,
    },
  })),

  on(getEntitiesAction, (state: ViewState) => ({
    ...state,
    getEntities: {
      isLoading: true,
    },
  })),
  on(getEntitiesFailureAction, (state: ViewState, { error }) => ({
    ...state,
    getEntities: {
      isLoading: false,
      success: false,
      error: error,
      total: 0,
    },
  })),
  on(getEntitiesSuccessAction, (state: ViewState, { response }) => ({
    ...state,
    getEntities: {
      isLoading: false,
      success: true,
      payload: response.entities,
      total: response.total,
    },
  })),

  on(getViewDatalessSuccess, (state: ViewState, { viewDataless }) => ({
    ...state,
    getViewDataless: {
      isLoading: false,
      success: true,
      payload: viewDataless
    }
  })),
  on(getViewDatalessFailure, (state, { error }) => ({
    ...state,
    getViewDataless: {
      isLoading: false,
      success: false,
      payload: {}
    }
  })),

  on(updateViewDataless, (state) => ({
    ...state,
    updateViewDataless: {
      isLoading: true,
    },
  })),

  on(updateViewDatalessSuccess, (state: ViewState) => ({
    ...state,
    updateViewDataless: {
      isLoading: false,
      success: true,
    },
  })),

  on(updateViewDatalessFailure, (state: ViewState, { errorMsg }) => ({
    ...state,
    updateViewDataless: {
      isLoading: false,
      success: false,
      error: errorMsg,
    },
  })),
);
