import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import {
  filter,
  tap,
  switchMap,
  startWith,
  take,
  takeUntil,
} from 'rxjs/operators';

import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import * as fromMember from '../../store/reducers';
import { AddMenuComponent } from '../add-menu/add-menu.component';
import { BaseComponent } from '@ptg-shared/components';
import { ParticipantNavigationConfigurationAction } from '../../store/actions';
import { ParticipantNavigationConfigurationService } from '@ptg-member/services/participant-navigation-configuration.service';
import { Router } from '@angular/router';
import { EntitiesItem, GetEntityResponse } from '@ptg-member/types/models/card.model';
import * as CartActions from '../../store/actions/cart.actions';
import { CheckExistResponse, MenuParametersQuery } from '@ptg-member/types/models/participant-navigation-configuration.model';
@Component({
  selector: 'ptg-add-new-menu',
  templateUrl: './add-new-menu.component.html',
  styleUrls: ['./add-new-menu.component.scss'],
})
export class AddNewMenuComponent extends BaseComponent {
  addForm: FormGroup = this.fb.group({
    name: this.fb.control('', {
      validators: [Validators.required],
    }),
    entityId: this.fb.control('', {
      validators: [Validators.required],
    }),
    isSingleView: this.fb.control(false),
  });
  formSubmit$ = new Subject<boolean>();
  listEntity: EntitiesItem[] = [];
  isContinue: boolean = false;
  constructor(
    public fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddMenuComponent>,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    private memberStore: Store<fromMember.MemberState>,
    private participantNavigationConfigurationService: ParticipantNavigationConfigurationService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public dataDetail: {
      query: MenuParametersQuery
    },
  ) {
    super();
  }

  ngOnInit(): void {
    this.getListEntity();
    this.createNavigationConfiguration();
    this.formSubmit$
      .pipe(
        tap((isContinue) => {
          this.isContinue = isContinue;
          this.addForm.markAllAsTouched();
        }),
        switchMap(() =>
          this.addForm.statusChanges.pipe(
            startWith(this.addForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter((status) => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  getListEntity() {
    this.memberStore.dispatch(CartActions.getEntityRequest({}));
    this.memberStore
      .select(fromMember.selectEntity)
      .pipe(
        filter((state: any) => state?.entities?.length),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state: GetEntityResponse) => {
        if (state?.entities?.length) {
          this.listEntity = state?.entities?.map((item: EntitiesItem) => {
            return {
              ...item,
              value: item.id,
              displayValue: item.entityName,
            };
          });
        }
      });
  }
  
  createNavigationConfiguration() {
    this.memberStore
    .pipe(
      select(fromMember.selectParticipantNavigationConfiguration),
      takeUntil(this.unsubscribe$)
    )
    .subscribe((state) => {
      if(state.createState) {
        if (this.isContinue) {
          this.dialogRef.close();
          this.router.navigateByUrl(
            `/member/participant-navigation-configuration/${state?.navigationConfigurationId}`
          );
        } else {
          this.addForm.reset();
          this.addForm.patchValue({
            isSingleView: false
          });
        }
        const query = this.dataDetail.query;
        this.memberStore.dispatch(
          ParticipantNavigationConfigurationAction.getNavigationConfigurationRequest({ query })
        );
        this.memberStore.dispatch(
          ParticipantNavigationConfigurationAction.clearNavigationConfigurationState()
        );
      }
    }); 
  }

  onSubmit() {
    if (!this.addForm.valid) {
      return;
    }
    const { name, entityId } = this.addForm.value;
    this.participantNavigationConfigurationService
      .checkExits({ name: name, entityId: entityId })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: CheckExistResponse) => {
        if (res?.existed) {
          this.addForm.get('name')?.setErrors({ inValidAsync: true });
          return;
        }
        const body = this.addForm.value;
        this.memberStore.dispatch(
          ParticipantNavigationConfigurationAction.setNavigationConfigurationRequest(
            { body }
          )
        );
      });
  }

  onCancel() {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }
}
