import { createAction, props } from '@ngrx/store';

import {
  CreateRetirementBenefitDetailUploadDocumentRequest,
} from '../../services/models';

export const createRetirementBenefitDetailUploadDocumentAction = createAction(
  '[CreateRetirementBenefitDetailUploadDocument/API] Send Request',
  props<{ request: CreateRetirementBenefitDetailUploadDocumentRequest }>()
);

export const createRetirementBenefitDetailUploadDocumentSuccessAction = createAction(
  '[CreateRetirementBenefitDetailUploadDocument/API] Success'
);

export const createRetirementBenefitDetailUploadDocumentFailureAction = createAction(
  '[CreateRetirementBenefitDetailUploadDocument/API] Failure',
  props<{ error?: any }>()
);

export const clearCreateRetirementBenefitDetailUploadDocumentStateAction = createAction(
  '[CreateRetirementBenefitDetailUploadDocument] Clear'
);
