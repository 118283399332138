import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ViewCurrentFilterComponent } from './view-current-filter.component';



@NgModule({
  declarations: [ViewCurrentFilterComponent],
  imports: [
    CommonModule,
    MatIconModule,
  ],
  exports: [ViewCurrentFilterComponent]
})
export class ViewCurrentFilterModule { }
