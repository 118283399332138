import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { RetirementBenefitUploadDocumentService } from '../../services';
import {
  createRetirementBenefitUploadDocumentsAction,
  createRetirementBenefitUploadDocumentsFailureAction,
  createRetirementBenefitUploadDocumentsSuccessAction,
  editRetirementBenefitDocumentAction,
  editRetirementBenefitDocumentFailureAction,
  editRetirementBenefitDocumentSuccessAction,
} from '../actions';

@Injectable()
export class RetirementBenefitUploadDocumentEffects {
  constructor(
    private actions$: Actions,
    private retirementBenefitUploadDocumentService: RetirementBenefitUploadDocumentService
  ) {}

  createRetirementBenefitUploadDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createRetirementBenefitUploadDocumentsAction),
      switchMap(({ request }) => {
        return this.retirementBenefitUploadDocumentService
          .createRetirementBenefitUploadDocuments(request)
          .pipe(
            map(() => createRetirementBenefitUploadDocumentsSuccessAction()),
            catchError((error) =>
              of(createRetirementBenefitUploadDocumentsFailureAction({ error }))
            )
          );
      })
    )
  );

  editBuyBackMakeUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editRetirementBenefitDocumentAction),
      switchMap(({ memberId, calculationBenefitDocumentId, request }) =>
        this.retirementBenefitUploadDocumentService.editRetirementBenefitDocument(memberId, calculationBenefitDocumentId, request).pipe(
          map(() => editRetirementBenefitDocumentSuccessAction()),
          catchError((error) => {
            let errorMessage = ''
            if (error?.error?.errorType === 'CommonException') {
              errorMessage = error?.error?.errorMessage?.[0] ?? '';
            }
            return of(editRetirementBenefitDocumentFailureAction({ errorMsg: errorMessage }));
          })
        )
      )
    )
  );
}
