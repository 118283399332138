import { createAction, props } from '@ngrx/store';
import {
  CreateMemberStatusRequest,
  EditMemberStatusRequest,
  GetAuditTrailsRequest,
  GetAuditTrailsResponse,
  GetListRelatedPersonQuery,
  GetListStatusHistoryQuery,
  RelatedPersonList
} from '../../types/models';

export const RelatedPersonListRequest = '[RelatedPersonList/API] Send Request';
export const RelatedPersonListSuccess = '[RelatedPersonList/API] Success';
export const RelatedPersonListFailure = '[RelatedPersonList/API] Failure';
export const GetRelatedPersonListRequest =
  '[GetRelatedPersonList/API] Send Request';
export const GetRelatedPersonListSuccess = '[GetRelatedPersonList/API] Success';
export const GetRelatedPersonListFailure = '[GetRelatedPersonList/API] Failure';
export const CreateMemberStatusHistoryRequest =
  '[CreateMemberRelatedPerson/API] Send Request';
export const CreateMemberStatusHistorySuccess =
  '[CreateMemberRelatedPerson/API] Success';
export const CreateMemberStatusHistoryFailure =
  '[CreateMemberRelatedPerson/API] Failure';
export const EditMemberStatusHistoryRequest =
  '[EditMemberRelatedPerson/API] Send Request';
export const EditMemberStatusHistorySuccess =
  '[EditMemberRelatedPerson/API] Success';
export const EditMemberStatusHistoryFailure =
  '[EditMemberRelatedPerson/API] Failure';
export const ClearStatusHistoryState = '[ClearRelatedPersonState]';
export const GetAuditTrailsRequestAction = '[GetAuditTrailsRelatedPerson/API] Send Request';
export const GetAuditTrailsSuccessAction = '[GetAuditTrailsRelatedPerson/API] Success';
export const GetAuditTrailsFailureAction = '[GetAuditTrailsRelatedPerson/API] Failure';

export const getRelatedPersonList = createAction(
  RelatedPersonListRequest,
  props<{ query: GetListRelatedPersonQuery }>()
);
export const getRelatedPersonListSuccess = createAction(
  RelatedPersonListSuccess,
  props<{ relatedPersonList: RelatedPersonList }>()
);
export const getRelatedPersonListFailure = createAction(RelatedPersonListFailure);

export const createMemberStatusHistoryRequest = createAction(
  CreateMemberStatusHistoryRequest,
  props<{ body: CreateMemberStatusRequest }>()
);
export const createMemberStatusHistorySuccess = createAction(
  CreateMemberStatusHistorySuccess
);
export const createMemberStatusHistoryFailure = createAction(
  CreateMemberStatusHistoryFailure
);
export const editMemberStatusHistoryRequest = createAction(
  EditMemberStatusHistoryRequest,
  props<{ body: EditMemberStatusRequest }>()
);
export const editMemberStatusHistorySuccess = createAction(
  EditMemberStatusHistorySuccess
);
export const editMemberStatusHistoryFailure = createAction(
  EditMemberStatusHistoryFailure
);
export const clearStatusHistoryState = createAction(ClearStatusHistoryState);
export const getAuditTrails = createAction(
  GetAuditTrailsRequestAction,
  props<{ request: GetAuditTrailsRequest }>()
);
export const getAuditTrailsSuccess = createAction(
  GetAuditTrailsSuccessAction,
  props<{ response: GetAuditTrailsResponse }>()
);
export const getAuditTrailsFailure = createAction(
  GetAuditTrailsFailureAction,
  props<{ error?: any }>()
);
