import { createSelector } from '@ngrx/store';

import { offCycleSelector } from './selector';

export const offCyclePaymentHistorySelector = createSelector(
  offCycleSelector,
  (state) => state?.offCyclePaymentHistory
);

export const getLumpSumPaymentSelector = createSelector(
  offCyclePaymentHistorySelector,
  (state) => state?.lumpSumPaymentList
);

export const getLumpSumPaymentHistorySelector = createSelector(
  offCyclePaymentHistorySelector,
  (state) => state?.lumpSumPaymentHistoryList
);
