import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PaymentDeductionsService } from '@ptg-processing/features/payroll/services';

import { OffCyclePayment } from '../../types/models';
import { OffCyclePaymentStatusType } from '../../constance/next-payment.const';
import { PayStatus, TaxType } from '../../constance/member-list.const';
import * as fromNextPayment from '../../store/reducers';
import * as NextPaymentActions from '../../store/actions/next-payment.actions';
import { EditPayrollSettingsComponent } from '../edit-payroll-settings/edit-payroll-settings.component';

@Component({
  selector: 'ptg-payroll-settings-detail',
  templateUrl: './payroll-settings-detail.component.html',
  styleUrls: ['./payroll-settings-detail.component.scss'],
})
export class PayrollSettingsDetailComponent implements OnInit, OnDestroy {
  readonly PayStatusEnum = PayStatus;
  readonly OffCyclePaymentStatusTypeEnum = OffCyclePaymentStatusType;

  federalSettings: any;
  stateSettings: any;
  participantSetting: any;
  indexFederal: any;
  indexState: any;
  participantDeductions: any = [];
  deductionList: any = [];
  offCyclePayment?: OffCyclePayment;

  unsubscribe$ = new Subject<void>();

  @Input() isHistory?: boolean;

  @Output() changeBannerEvent = new EventEmitter();

  constructor(
    private store: Store<fromNextPayment.MemberState>,
    private dialog: MatDialog,
    private paymentDeductionsService: PaymentDeductionsService
  ) {}

  ngOnInit(): void {
    this.store.select(fromNextPayment.selectNextPayment).subscribe((data) => {
      if (data) this.offCyclePayment = data.offCyclePayment;
    });

    this.store
      .select(fromNextPayment.selectNextPaymentSettings)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state) {
          this.participantSetting = state.participantSetting;
          if (state.payrollSettings) {
            this.federalSettings = state.payrollSettings.find(
              (ele: any) => ele.type === TaxType.FederalTax
            );
            this.stateSettings = state.payrollSettings.find(
              (ele: any) => ele.type === TaxType.StateTax
            );
          } else {
            this.federalSettings = null;
            this.stateSettings = null;
          }
        }
      });

    this.store
      .select(fromNextPayment.selectNextPaymentDeduction)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (
          !state ||
          !state.deductions ||
          this.participantDeductions === state.deductions ||
          state.deductions.length === 0
        ) {
          this.participantDeductions = [];
          this.indexFederal = -1;
          this.indexState = -1;
          return;
        }
        this.participantDeductions = state?.deductions || [];
        this.paymentDeductionsService.getActiveDeduction().subscribe((list) => {
          this.deductionList = list?.deductions;
          this.indexFederal = this.participantDeductions.findIndex(
            (el: any) =>
              JSON.parse(el?.deductionSetting)?.TaxType === TaxType.FederalTax
          );
          this.indexState = this.participantDeductions.findIndex(
            (el: any) =>
              JSON.parse(el?.deductionSetting)?.TaxType === TaxType.StateTax
          );
        });
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onClickEdit() {
    const dialogRef = this.dialog.open(EditPayrollSettingsComponent, {
      panelClass: 'payroll-settings-popup',
      disableClose: true,
      autoFocus: false,
      height: 'auto',
      data: {
        federalSettings: this.federalSettings,
        stateSettings: this.stateSettings,
        participantSetting: this.participantSetting,
        offCyclePayment: this.offCyclePayment,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.changeBannerEvent.emit();
      if (result)
        this.store.dispatch(
          NextPaymentActions.updatePayrollSettings({
            body: result,
            targetId: this.offCyclePayment?.id,
          })
        );
    });
  }
}
