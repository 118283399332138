import { createSelector } from '@ngrx/store';

import { selectCalculationModuleState } from './selector';

export const retirementBenefitDetailUploadDocumentSelector = createSelector(
  selectCalculationModuleState,
  (state) => state?.retirementBenefitDetailUploadDocument
);

export const createRetirementBenefitDetailUploadDocumentSelector = createSelector(
  retirementBenefitDetailUploadDocumentSelector,
  (state) => state?.createRetirementBenefitDetailUploadDocument
);
