import * as fromMember from '../../store/reducers';
import { Store, select } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import * as fromReducer from '@ptg-reducers';
import { DISCARD_CONFIRM_MESSAGE, ACTION } from '@ptg-shared/constance';
import { BaseComponent } from '@ptg-shared/components';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { showBanner } from '@ptg-shared/utils/common.util';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { clearViewState, getViewDataless, updateViewDataless } from '@ptg-member/store/actions/view.actions';
import { getViewDatalessSelector, updateViewDatalessSelector } from '@ptg-member/store/selectors';
import { CardConfiguration, ViewDataless } from '@ptg-member/types/models';

@Component({
  selector: 'ptg-view-dataless',
  templateUrl: './view-dataless.component.html',
  styleUrls: ['./view-dataless.component.scss'],
})
export class DatalessViewComponent extends BaseComponent {
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Participant List',
      url: '/member',
    },
    {
      name: 'View List',
      url: '/member/view',
    },
    {
      name: 'Dataless Display Preference',
    },
  ];
  viewId = '';
  isLoadingView = false;
  bannerType: BannerType = BannerType.Hidden;
  message = '';

  // Constants Data
  propertyDisplayPreferenceData = [
    {
      value: 0,
      displayValue: 'Hide',
    },
    {
      value: 1,
      displayValue: 'Display Label only',
    },
    {
      value: 2,
      displayValue: 'Display message',
    },
  ];
  listDisplayPreferenceData = [
    {
      value: 0,
      displayValue: 'Hide',
    },
    {
      value: 1,
      displayValue: 'Display Column Headers',
    },
    {
      value: 2,
      displayValue: 'Display Column Headers and message',
    },
  ];
  nonValuePropertyDisplayData = [
    {
      value: 0,
      displayValue: 'Hide',
    },
    {
      value: 1,
      displayValue: 'Display Label only',
    },
  ];

  // Form Variable
  viewDatalessData!: ViewDataless;
  viewForm!: FormGroup;

  constructor(
    private store: Store<fromReducer.State>,
    private memberStore: Store<fromMember.MemberState>,
    public route: ActivatedRoute,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch(LayoutActions.hiddenSideMenu());

    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.viewId = params.id;
    });

    // update view dataless status
    this.memberStore.pipe(select(updateViewDatalessSelector)).subscribe((data) => {
      if (data?.success) {
        this.loadDataViewDataless();
        showBanner.call(this, BannerType.Success, 'Dataless Display', ACTION.EDIT);
      } else if (data?.error) {
        showBanner.call(this, BannerType.Fail, 'Dataless Display', ACTION.EDIT);
      }
    });

    // Get view detail
    this.loadDataViewDataless();
  }

  ngOnDestroy(): void {
    this.memberStore.dispatch(clearViewState());
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: DISCARD_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.memberStore.dispatch(clearViewState());
        this.bannerType = BannerType.Hidden;
        this.viewForm.reset();
        this.loadDataViewDataless();
      }
    });
  }

  private loadDataViewDataless() {
    this.isLoadingView = true;
    this.memberStore.dispatch(getViewDataless({ id: this.viewId }))
    this.memberStore
      .pipe(
        select(getViewDatalessSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state?.success && state?.payload) {
          this.viewDatalessData = { ...state?.payload };
          this.initForm();
          
          setTimeout(() => {
            this.isLoadingView = false;
          }, 1)
        }
      });
  }

  private initForm(): void {
    this.viewForm = this.fb.group({
      cards: this.fb.array([]),
    });
    (this.viewDatalessData.cards || []).forEach((item) => {
      (this.viewForm.get('cards') as FormArray).push(
        this.fb.group({
          id: item.id,
          label: item.label,
          name: item.name,
          order: item.order,
          chips: item.chips?.join(','),
          isHideEmptyCard: item.configuration?.isHideEmptyCard,
          propertyDisplayPreferenceType: item.configuration?.propertyDisplayPreferenceType,
          listDisplayPreferenceType: item.configuration?.listDisplayPreferenceType,
          nonValuePropertyDisplayType: item.configuration?.nonValuePropertyDisplayType,
          nonRecordListDisplayType: item.configuration?.nonRecordListDisplayType,
        })
      )
    })
 
    if (this.getControls.length > 0) {
      for (let i = 0; i < this.getControls.length; i++) {
        this.changeToggleHideEmptyCard(this.getControls[i].get('isHideEmptyCard')?.value, i, true);
      }
    }
  }

  get getControls() {
    return (this.viewForm?.get('cards') as FormArray).controls;
  }

  changeToggleHideEmptyCard(value: boolean, index: number, isInitForm = false) {
    const propertyCtrl = this.getControls[index].get('propertyDisplayPreferenceType');
    const listCtrl = this.getControls[index].get('listDisplayPreferenceType');
    if (value) {
      if (!isInitForm) {
        propertyCtrl?.setValue('');
        listCtrl?.setValue('');
      }
      propertyCtrl?.removeValidators(Validators.required);
      listCtrl?.removeValidators(Validators.required);
    } else {
      const chips = this.getControls[index].get('chips')?.value.split(',');
      if (chips.includes('List')) {
        listCtrl?.addValidators(Validators.required);
      }
      if (chips.includes('Properties')) {
        propertyCtrl?.addValidators(Validators.required);
      }
    }
    propertyCtrl?.updateValueAndValidity({ emitEvent: false });
    listCtrl?.updateValueAndValidity({ emitEvent: false });
  }

  submit() {
    this.viewForm.markAllAsTouched();
    if (this.viewForm.invalid) {
      return;
    }
    const data: {
      cards: CardConfiguration[],
    } = this.viewForm?.value;
    if (this.viewDatalessData.id) {
      this.isLoadingView = true;
      this.memberStore.dispatch(updateViewDataless({
        id: this.viewDatalessData.id,
        body: {
          cards: (data.cards || []).map(i => ({
            id: i.id,
            configuration: {
              isHideEmptyCard: i.isHideEmptyCard,
              propertyDisplayPreferenceType: i.propertyDisplayPreferenceType,
              listDisplayPreferenceType: i.listDisplayPreferenceType,
              nonValuePropertyDisplayType: i.nonValuePropertyDisplayType,
              nonRecordListDisplayType: i.nonRecordListDisplayType,
            }
          }))
        }
      }));
    }
  }
}
