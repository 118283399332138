import { Component, Input, ViewChild } from '@angular/core';
import { FIRST_PAGE, PageEvent, PaginationComponent } from '@ptg-shared/controls/pagination';
import { AccidentParticipantsState } from '../../store/reducers/accident-participants.reducer';
import { Store, select } from '@ngrx/store';
import { BaseComponent } from '@ptg-shared/components';
import { takeUntil } from 'rxjs/operators';
import { editParticipantAccidentStatusSelector, getAccidentNotesSelector } from '../../store/selectors';
import { STATE } from '@ptg-shared/constance';
import { getAccidentNotesAction } from '../../store/actions';
import * as fromReducer from '@ptg-reducers';
import { AccidentNote } from '../../services/models';

const PAGE_SIZE_CONST = '-ptg-accident-note-detail-pageSize';

@Component({
  selector: 'ptg-accident-note-detail',
  templateUrl: './accident-note-detail.component.html',
  styleUrls: ['./accident-note-detail.component.scss']
})
export class AccidentNoteDetailComponent extends BaseComponent {
  @ViewChild(PaginationComponent) paginator!: PaginationComponent;
  @Input() memberId?: string;
  @Input() accidentId?: string;
  lengthNotePg: number | any;
  pageNoteSize: number = 2;
  pageNoteIndex: number = FIRST_PAGE;
  currentFund: any = {};
  sortInfo: {} | any = {};
  isLoading: boolean = true;
  listAccidentNote: AccidentNote[] = [];

  constructor(
    private store: Store<AccidentParticipantsState>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getCurrentFundState();
    this.getAccidentNoteList();
    this.store
        .pipe(select(getAccidentNotesSelector),
          takeUntil(this.unsubscribe$)
        )
        .subscribe((data) => {
          if (data) {
            this.isLoading = data?.isLoading;
          }
          this.lengthNotePg = data?.total;
          this.listAccidentNote = data?.payload?.map((item:any) => {
            return {
              ...item,
              dateCreated:
              !item.dateCreated ||
              item.dateCreated.includes('+') ||
              item.dateCreated.includes('Z')
                ? item.dateCreated
                : item.dateCreated + 'Z',
            isTruncate: true
            }
          }) ?? []
    });

    this.store
    .pipe(
      select(fromReducer.selectCurrentFundState),
      takeUntil(this.unsubscribe$)
    )
    .subscribe((el) => {
      this.currentFund = el;
    });

    this.store
      .pipe(select(editParticipantAccidentStatusSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data) => {
        if (data && data?.state?.state === STATE.SUCCESS) {
          this.getAccidentNoteList();
          this.paginator?.jumpToFirst();
        }
      });
  }

  getCurrentFundState() {
    this.store.pipe(
      select(fromReducer.selectCurrentFundState),
      takeUntil(this.unsubscribe$)
    ).subscribe(el => {
      this.currentFund = el;
    });
  }

  getAccidentNoteList() {
    this.store.dispatch(
      getAccidentNotesAction({
        query: {
          pageIndex: this.pageNoteIndex,
          pageSize: this.pageNoteSize
        },
        memberId: this.memberId ?? '',
        accidentId: this.accidentId ?? ''
      })
    );
  }

  getDataFirstPage() {
    if (this.listAccidentNote?.length > 0) {
      this.paginator?.jumpToFirst();
    } else {
      this.store.dispatch(
        getAccidentNotesAction({
          query: {
            pageIndex: 1,
            pageSize: this.pageNoteSize,
          },
          memberId: this.memberId ?? '',
          accidentId: this.accidentId ?? ''
        })
      );
    }
  }

  changeNotePaging(event: PageEvent) {
    this.pageNoteSize = event.pageSize;
    this.pageNoteIndex = event.pageNumber;
    sessionStorage.setItem(
      this.currentFund.key + PAGE_SIZE_CONST,
      this.pageNoteSize.toString()
    );
    this.getAccidentNoteList();
  }

}
