<mat-form-field class="input-form-field {{class}}" appearance="fill" *ngIf="controlField">
    <mat-label *ngIf="hasLabel">{{placeholder}}</mat-label>
    <input matInput 
        type="text"
        [mask]="mask"
        [thousandSeparator]="thousandSeparator"
        [allowNegativeNumbers]="allowNegativeNumbers"
        [formControl]="controlField"
        [placeholder]="hasLabel ? '' : placeholder" 
        [maxlength]="maxLength ? maxLength : 'none'"
        (focusout)="onFocusOut($event)"
        [validation]="isValidation"
        [suffix]="isPercentage ? '%' : ''"
        >
    <mat-error *ngIf="controlField?.errors?.required">{{errorRequire}}</mat-error>
    <mat-error *ngIf="controlField?.errors?.pattern">{{errorPattern}}</mat-error>
    <mat-error *ngIf="controlField?.errors?.maxlength">{{errorMaxLength}}</mat-error>
    <mat-error *ngIf="!controlField?.errors?.pattern && min && !max && controlField?.errors?.min">{{errorMin}}</mat-error>
    <mat-error *ngIf="!controlField?.errors?.pattern && controlField?.errors?.min && isPercentage">{{errorMin}}</mat-error>
    <mat-error *ngIf="!controlField?.errors?.pattern && !min && max && controlField?.errors?.max">{{errorMax}}</mat-error>
    <mat-error *ngIf="!controlField?.errors?.pattern && min && max && (controlField?.errors?.min || controlField?.errors?.max)">{{errorBetween}}</mat-error>
</mat-form-field>
