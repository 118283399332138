import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'ptg-entity-reference-column',
  templateUrl: './entity-reference-column.component.html',
  styleUrls: ['./entity-reference-column.component.scss'],
})
export class EntityReferenceColumnComponent implements OnChanges{
  link!: SafeUrl;
  @Input() text: string = '';
  @Input() href?: string;
  @Input() disableLink: boolean = false;

  @Output() clickEntityReference = new EventEmitter<void>();

  constructor(
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.link = this.sanitizer.bypassSecurityTrustUrl(this.href ?? '');
  }

  onClickEntityReference(): void {
    this.clickEntityReference.emit();
  }
}
