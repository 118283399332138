<div class="entity-list-container">
  <ptg-breadcrumb 
    [listBreadcrumbs]="listBreadcrumbs"
    (onAdd)="openForm()"
    buttonAddLabel="New View"
  ></ptg-breadcrumb>
  <div class="drop-banner" *ngIf="bannerType">
    <ptg-banner [(bannerType)]="bannerType" [message]="message"></ptg-banner>
  </div>
  <ptg-grid
    [isLoading]="isLoading"
    [data]="viewsData"
    [columns]="columns"
    [paginable]="false"
    [fitToParent]="true"
    (sortChange)="sortChange($event)"
    notFoundMessage="No View to Display"
  >
    <ng-template cellContent columnName="action" let-row>
      <button
        stopPropagation
        mat-icon-button
        [matMenuTriggerFor]="menu"
        class="group-button-container"
        *ngIf="row.type !== VIEW_TYPE.System"
      >
        <mat-icon class="group-button-icon">more_vert</mat-icon>
        <mat-icon class="material-icons-round group-button-icon">
          arrow_drop_down
        </mat-icon>
      </button>
      <mat-menu
        stopPropagation
        #menu="matMenu"
        xPosition="before"
      >
        <button stopPropagation mat-menu-item (click)="editView(row)">
          <mat-icon>edit</mat-icon>
          <span>Edit</span>
        </button>
        <button
          stopPropagation
          mat-menu-item
          (click)="datalessDisplay(row)"
          *ngIf="row.type === VIEW_TYPE.Summary"
        >
          <mat-icon>list_alt</mat-icon>
          <span>Dataless Display</span>
        </button>
        <button stopPropagation mat-menu-item (click)="onClickRemoveView(row)">
          <mat-icon>delete_forever</mat-icon>
          <span>Remove</span>
        </button>
      </mat-menu>
    </ng-template>
  </ptg-grid>
</div>
