import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { EntityViewService } from '../../services';
import { GetEntityViewsResponse } from '../../services/models';
import {
  getCardsByViewAction,
  getCardsByViewFailureAction,
  getCardsByViewSuccessAction,
  getEntityViewsByCardAction,
  getEntityViewsByCardFailureAction,
  getEntityViewsByCardSuccessAction,
  getEntityViewsByEntityAction,
  getEntityViewsByEntityFailureAction,
  getEntityViewsByEntitySuccessAction,
} from '../actions';

@Injectable()
export class EntityViewEffect {
  constructor(
    private actions$: Actions,
    private entityViewService: EntityViewService,
  ) {}

  getEntityViewsByEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEntityViewsByEntityAction),
      switchMap(({ entityId: cardId }) =>
        this.entityViewService.getEntityViewsByEntity(cardId).pipe(
          map((response: GetEntityViewsResponse) => getEntityViewsByEntitySuccessAction({ response })),
          catchError((error) => of(getEntityViewsByEntityFailureAction({ error }))),
        ),
      ),
    ),
  );

  getEntityViewsByCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEntityViewsByCardAction),
      switchMap(({ cardId }) =>
        this.entityViewService.getEntityViewsByCard(cardId).pipe(
          map((response: GetEntityViewsResponse) => getEntityViewsByCardSuccessAction({ response })),
          catchError((error) => of(getEntityViewsByCardFailureAction({ error }))),
        ),
      ),
    ),
  );

  getCardsByViewAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCardsByViewAction),
      switchMap(({ key, viewId }) =>
        this.entityViewService.getCardsByView(viewId).pipe(
          map((response) => getCardsByViewSuccessAction({ key, response })),
          catchError((error) => of(getCardsByViewFailureAction({ key, error }))),
        ),
      ),
    ),
  );
}
