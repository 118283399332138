import { createAction, props } from '@ngrx/store';

import {
  EntityMemberDetail,
  MetadataProfileHeaderConfiguration,
  ProfileHeaderData,
  ProfileHeaderList,
  PropertyDisplayConfig,
  Status,
} from '../../types/models';
import { HeaderPropertyConfig } from '@ptg-member/types/models/header-configuration.model';

export const ClearProfileHeaderConfiguration =
  '[ProfileHeaderConfiguration] Clear';
export const GetProfileHeaderPropertyListRequest =
  '[GetProfileHeaderPropertyList/API] Send Request';
export const GetProfileHeaderPropertyListSuccess =
  '[GetProfileHeaderPropertyList/API] Success';
export const GetProfileHeaderPropertyListFailure =
  '[GetProfileHeaderPropertyList/API] Failure';
export const GetProfileHeaderConfigurationRequest =
  '[GetProfileHeaderConfiguration/API] Send Request';
export const GetProfileHeaderConfigurationSuccess =
  '[GetProfileHeaderConfiguration/API] Success';
export const GetProfileHeaderConfigurationFailure =
  '[GetProfileHeaderConfiguration/API] Failure';
export const SetProfileHeaderConfigurationRequest =
  '[SetProfileHeaderConfiguration/API] Send Request';
export const SetProfileHeaderConfigurationSuccess =
  '[SetProfileHeaderConfiguration/API] Success';
export const SetProfileHeaderConfigurationFailure =
  '[SetProfileHeaderConfiguration/API] Failure';
export const GetMemberProfileHeaderDataRequest =
  '[GetMemberProfileHeaderData/API] Send Request';
export const GetMemberProfileHeaderDataSuccess =
  '[GetMemberProfileHeaderData/API] Success';
export const GetMemberProfileHeaderDataFailure =
  '[GetMemberProfileHeaderData/API] Failure';
export const GetListProfileHeaderConfigurationRequest =
  '[GetListProfileHeaderConfiguration/API] Send Request';
export const GetListProfileHeaderConfigurationSuccess =
  '[GetListProfileHeaderConfiguration/API] Success';
export const GetListProfileHeaderConfigurationFailure =
  '[GetListProfileHeaderConfiguration/API] Failure';
export const DeleteProfileHeaderConfigurationRequest =
  '[DeleteProfileHeaderConfiguration/API] Send Request';
export const DeleteProfileHeaderConfigurationSuccess =
  '[DeleteProfileHeaderConfiguration/API] Success';
export const DeleteProfileHeaderConfigurationFailure =
  '[DeleteProfileHeaderConfiguration/API] Failure';
export const GetMembersStatusNotUsedRequest =
  '[GetMembersStatusNotUsed/API] Send Request';
export const GetMembersStatusNotUsedSuccess =
  '[GetMembersStatusNotUsed/API] Success';
export const GetMembersStatusNotUsedFailure =
  '[GetMembersStatusNotUsed/API] Failure';

export const clearProfileHeaderConfigurationState = createAction(
  ClearProfileHeaderConfiguration
);
export const getProfileHeaderPropertyList = createAction(
  GetProfileHeaderPropertyListRequest
);
export const getProfileHeaderPropertyListSuccess = createAction(
  GetProfileHeaderPropertyListSuccess,
  props<{ propertyConfigs: PropertyDisplayConfig[] }>()
);
export const getProfileHeaderPropertyListFailure = createAction(
  GetProfileHeaderPropertyListFailure,
  props<{ errorMsg: string }>()
);
export const getProfileHeaderConfiguration = createAction(
  GetProfileHeaderConfigurationRequest,
  props<{ id: string }>()
);
export const getProfileHeaderConfigurationSuccess = createAction(
  GetProfileHeaderConfigurationSuccess,
  props<{ profileHeader: MetadataProfileHeaderConfiguration }>()
);
export const getProfileHeaderConfigurationFailure = createAction(
  GetProfileHeaderConfigurationFailure,
  props<{ errorMsg: string }>()
);
export const setProfileHeaderConfiguration = createAction(
  SetProfileHeaderConfigurationRequest,
  props<{ body: MetadataProfileHeaderConfiguration }>()
);

export const setProfileHeaderConfigurationSuccess = createAction(
  SetProfileHeaderConfigurationSuccess,
  props<{ id: string }>()
);
export const setProfileHeaderConfigurationFailure = createAction(
  SetProfileHeaderConfigurationFailure,
  props<{ errorMsg: string }>()
);
export const getMemberProfileHeaderData = createAction(
  GetMemberProfileHeaderDataRequest,
  props<{ memberId: string }>()
);
export const getMemberProfileHeaderDataSuccess = createAction(
  GetMemberProfileHeaderDataSuccess,
  props<{ profileHeaderData: ProfileHeaderData }>()
);
export const getMemberProfileHeaderDataFailure = createAction(
  GetMemberProfileHeaderDataFailure,
  props<{ errorMsg: string }>()
);

export const getProfileHeaderDataAction = createAction(
  '[GetProfileHeaderData/API] Send Request',
  props<{ cardId: string, memberId: string, entityId: string, screenId: string}>()
);
export const getProfileHeaderDataSuccess = createAction(
  '[GetProfileHeaderData/API] Success',
  props<{ headerPropertyConfig: HeaderPropertyConfig[] }>()
);
export const getProfileHeaderDataFailure = createAction(
  '[GetProfileHeaderData/API] Failure',
  props<{ errorMsg: string }>()
);

export const getProfileSubHeaderDataAction = createAction(
  '[GetProfileSubHeaderData/API] Send Request',
  props<{ cardId: string, memberId: string, entityId: string, screenId: string}>()
);
export const getProfileSubHeaderDataSuccess = createAction(
  '[GetProfileSubHeaderData/API] Success',
  props<{ headerPropertyConfig: HeaderPropertyConfig[] }>()
);
export const getProfileSubHeaderDataFailure = createAction(
  '[GetProfileSubHeaderData/API] Failure',
  props<{ errorMsg: string }>()
);

export const getMemberDetailAction = createAction(
  '[GetMemberDetail/API] Send Request',
  props<{ memberId: string}>()
);
export const getMemberDetailSuccess = createAction(
  '[GetMemberDetail/API] Success',
  props<{ memberDetail: EntityMemberDetail }>()
);
export const getMemberDetailFailure = createAction(
  '[GetMemberDetail/API] Failure',
  props<{ errorMsg: string }>()
);

export const getListProfileHeaderConfiguration = createAction(
  GetListProfileHeaderConfigurationRequest,
  props<{ query: { SortNames: string; SortType: number } }>()
);
export const getListProfileHeaderConfigurationSuccess = createAction(
  GetListProfileHeaderConfigurationSuccess,
  props<{ profileHeaderList: ProfileHeaderList }>()
);
export const getListProfileHeaderConfigurationFailure = createAction(
  GetListProfileHeaderConfigurationFailure,
  props<{ error?: any }>()
);
export const deleteProfileHeaderConfiguration = createAction(
  DeleteProfileHeaderConfigurationRequest,
  props<{ id: string }>()
);
export const deleteProfileHeaderConfigurationSuccess = createAction(
  DeleteProfileHeaderConfigurationSuccess
);
export const deleteProfileHeaderConfigurationFailure = createAction(
  DeleteProfileHeaderConfigurationFailure,
  props<{ errorMsg: string }>()
);
export const getMembersStatusNotUsed = createAction(
  GetMembersStatusNotUsedRequest
);
export const getMembersStatusNotUsedSuccess = createAction(
  GetMembersStatusNotUsedSuccess,
  props<{ statusNotUsedList: Status[] }>()
);
export const getMembersStatusNotUsedFailure = createAction(
  GetMembersStatusNotUsedFailure,
  props<{ errorMsg: string }>()
);
