<div class="dialog-container">
    <h3>{{ data?.memberNavigationItem ? 'Edit Menu Item' : 'Add New Menu Item'}}</h3>
    <form class="edit-form" [formGroup]="addForm">
      <div class="input-container flex">
        <ptg-select
          [controlField]="addForm.get('entityViewId')"
          [isOptionObj]="true"
          [isSetMaxWidthSelect]="true"
          [isMultipleLineOption]="true"
          [listData]="listView"
          class="full-width"
          placeholder="View"
        >
        </ptg-select>
        <ptg-textbox
            [controlField]="addForm.get('name')"
            placeholder="Menu Item Name"
            [hasLabel]="true"
            [isRequired]="true"
            [maxLength]="100"
            customError="errorMessageName"
        ></ptg-textbox>
      </div>
      <div class="wrap-btn">
        <button
          mat-raised-button
          type="button"
          class="btn-create-continue"
          (click)="formSubmit$.next(true)"
        >
          {{ data?.memberNavigationItem ? 'Save' : 'Add'}}
        </button>
        <button
          mat-raised-button
          type="button"
          class="btn-cancel"
          (click)="onCancel()"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
