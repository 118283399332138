import { createReducer, on } from '@ngrx/store';

import { ReportingCalendarActions } from '../actions';
import { ACTION, ActionState, STATE } from '../../shared/constance/value.const';
import { PeriodList, FiscalYearList, FiscalYear } from '../models/reporting-calendar.model';


export const reportingCalendarFeatureKey = 'ReportingCalendar';

export interface State {
  yearState: {
    isLoading: boolean;
    yearList: FiscalYearList;
    success?: boolean,
    error?: any,
  };
  periodState: {    
    isLoading: boolean;
    periodList: PeriodList;
    success?: boolean,
    error?: any,
  };
  actionState?: any;
}

const initialState: State = {
  yearState: {
    isLoading: true,
    yearList: {} as FiscalYearList
  },
  periodState: {
    isLoading: true,
    periodList: {} as PeriodList
  },
  actionState: undefined
};

export const reducer = createReducer(
  initialState,
  on(ReportingCalendarActions.getYearList, (state, {}) => ({
    ...state,
    yearState: {
      isLoading: true,
      yearList: {
        total: 0,
        fiscalYears: []
      }
    }
  })),
  on(ReportingCalendarActions.getYearListSuccess, (state, { yearList }) => ({
    ...state,
    yearState: {
      isLoading: false,
      yearList: yearList,
      success: true,
      error: undefined,
    }
  })),
  on(ReportingCalendarActions.getYearListFailure, (state, { error }) => ({
    ...state,
    yearState: {
      isLoading: false,
      yearList: {
        total: 0,
        fiscalYears: []
      },
      success: false,
      error: error,
    },    
    periodState: {
      isLoading: false,
      periodList: {
        total: 0,
        fiscalYear: {} as FiscalYear,
        periods: []
      }
    }
  })),
  on(ReportingCalendarActions.getPeriodList, (state, {}) => ({
    ...state,
    periodState: {
      isLoading: true,
      periodList: {
        total: 0,
        fiscalYear: {} as FiscalYear,
        periods: []
      }
    }
  })),
  on(ReportingCalendarActions.getPeriodListSuccess, (state, { periodList }) => ({
    ...state,
    periodState: {
      isLoading: false,
      periodList: periodList,
      success: true,
      error: undefined,
    }
  })),
  on(ReportingCalendarActions.getPeriodListFailure, (state, { error }) => ({
    ...state,
    periodState: {
      isLoading: false,
      periodList: {
        total: 0,
        fiscalYear: {} as FiscalYear,
        periods: []
      },
      success: false,
      error: error,
    }
  })),
  on(ReportingCalendarActions.addFiscalYear, (state, {}) => ({
    ...state,
    actionState: undefined
  })),
  on(ReportingCalendarActions.addFiscalYearSuccess, (state, { yearId, yearName }) => ({
    ...state,
    actionState: {
      action: ACTION.ADD,
      state: STATE.SUCCESS,
      yearId,
      yearName
    }
  })),
  on(ReportingCalendarActions.addFiscalYearFailure, (state, { yearName }) => ({
    ...state,
    actionState: {
      action: ACTION.ADD,
      state: STATE.FAIL,
      yearName
    }
  })),
  on(ReportingCalendarActions.updateFiscalYear, (state, {}) => ({
    ...state,
    actionState: undefined
  })),
  on(ReportingCalendarActions.updateFiscalYearSuccess, (state, { yearId, yearName }) => ({
    ...state,
    actionState: {
      action: ACTION.EDIT,
      state: STATE.SUCCESS,
      yearId,
      yearName
    }
  })),
  on(ReportingCalendarActions.updateFiscalYearFailure, (state, { yearName }) => ({
    ...state,
    actionState: {
      action: ACTION.EDIT,
      state: STATE.FAIL,
      yearName
    }
  })),
  on(ReportingCalendarActions.clearReportingCalendarState, (state) => ({
    ...state,
    yearId: '',
    actionState: undefined
  })),
);

