import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import { EntityView, GetCardsByViewResponse } from '../../services/models';
import {
  clearGetCardsByViewStateAction,
  clearGetEntityViewsByCardStateAction,
  clearGetEntityViewsByEntityStateAction,
  getCardsByViewAction,
  getCardsByViewFailureAction,
  getCardsByViewSuccessAction,
  getEntityViewsByCardAction,
  getEntityViewsByCardFailureAction,
  getEntityViewsByCardSuccessAction,
  getEntityViewsByEntityAction,
  getEntityViewsByEntityFailureAction,
  getEntityViewsByEntitySuccessAction,
} from '../actions';

export interface EntityViewState {
  getEntityViewsByEntity?: BaseActionState<EntityView[]>;
  getEntityViewsByCard?: BaseActionState<EntityView[]>;
  getCardsByView?: BaseActionState<GetCardsByViewResponse>;
}

const initialState: EntityViewState = {};

export const entityViewReducer = createReducer(
  initialState,
  on(getEntityViewsByEntityAction, (state) => ({
    ...state,
    getEntityViewsByEntity: {
      isLoading: true,
    },
  })),
  on(getEntityViewsByEntitySuccessAction, (state, { response }) => ({
    ...state,
    getEntityViewsByEntity: {
      isLoading: false,
      success: true,
      payload: response.entityViews,
    },
  })),
  on(getEntityViewsByEntityFailureAction, (state, { error }) => ({
    ...state,
    getEntityViewsByEntity: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetEntityViewsByEntityStateAction, (state) => ({
    ...state,
    getEntityViewsByEntity: undefined,
  })),

  on(getEntityViewsByCardAction, (state) => ({
    ...state,
    getEntityViewsByCard: {
      isLoading: true,
    },
  })),
  on(getEntityViewsByCardSuccessAction, (state, { response }) => ({
    ...state,
    getEntityViewsByCard: {
      isLoading: false,
      success: true,
      payload: response.entityViews,
    },
  })),
  on(getEntityViewsByCardFailureAction, (state, { error }) => ({
    ...state,
    getEntityViewsByCard: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetEntityViewsByCardStateAction, (state) => ({
    ...state,
    getEntityViewsByCard: undefined,
  })),

  on(getCardsByViewAction, (state, { key }) => ({
    ...state,
    getCardsByView: {
      key: key,
      isLoading: true,
    },
  })),
  on(getCardsByViewSuccessAction, (state, { response, key }) => ({
    ...state,
    getCardsByView: {
      key,
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getCardsByViewFailureAction, (state, { error, key }) => ({
    ...state,
    getCardsByView: {
      key,
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetCardsByViewStateAction, (state) => ({
    ...state,
    getCardsByView: undefined,
  })),
);
