import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';

import * as ParticipantRelationshipActions from '../../store/actions/participant-relationship.actions';
import { MemberNavigationResponse, ParticipantRelationship } from '../../types/models';
import * as fromMember from '../../store/reducers';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';

@Component({
  selector: 'ptg-participant-relationship',
  templateUrl: './participant-relationship.component.html',
  styleUrls: ['./participant-relationship.component.scss'],
})
export class ParticipantRelationshipComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<void>();
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: '',
    },
  ];
  isLoading = false;
  participantRelationships: ParticipantRelationship[] = [];
  memberId: string = '';
  menuItemId: string = '';
  screenId: string = '';
  isEntityView: boolean = false;
  viewId: string = '';

  constructor(
    private memberStore: Store<fromMember.MemberState>,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.memberStore.select(fromLayoutReducer.selectProfileNavigationState)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((menuItem) => {
      if (Object.keys(menuItem?.memberNavigationList)?.length) {
        var menu = menuItem.memberNavigationList.memberNavigationMenu?.find(x => x.menuItems.find(item => item.id == this.menuItemId) != null);
        var menuName = menu?.name;
        var menuItemName = menu?.menuItems.find(x => x.id === this.menuItemId)?.name;
        this.screenId = `${menuItem.profileName.replace(/\s/g, '')}_${menuName?.replace(/\s/g, '')}_${menuItemName?.replace(/\s/g, '')}_`;
        this.getParticipantRelationship();
      }
    });
    this.route.params.subscribe((params) => {
      // TODO: need remove logic after complete implement entity
      this.isEntityView = this.route.snapshot.url.some(path => path.path === 'system-view');
      this.viewId = params.viewId;
      this.memberId = params['id'];
      this.menuItemId = params['menuId'];
      this.getViewName();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getViewName() {    
    this.memberStore.select(fromLayoutReducer.selectMemberNavigationListState).pipe(takeUntil(this.unsubscribe$))
      .subscribe(menu => {
        if (menu.memberNavigationMenu) {
          const memberNavigationMenu: MemberNavigationResponse[] =
          menu?.memberNavigationMenu;
          const menuItems = memberNavigationMenu
            ?.map((item) => {
              return item.menuItems;
            })
            ?.reduce((a, b) => {
              return a.concat(b);
            });

          const menuName = menuItems?.find((item: any) => item?.entityViewId === this.viewId)?.name;

          this.listBreadcrumbs[0] = {
            name: menuName || '',
          };
        }
      });
  }

  onChangeMemberDetail() {}

  getParticipantRelationship() {
    this.memberStore
      .select(fromMember.selectParticipantRelationships)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((participantRelationships) => {
        this.participantRelationships = participantRelationships;
      });
    this.memberStore
      .select(fromMember.selectParticipantRelationshipLoadingState)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isLoading) => {
        this.isLoading = isLoading;
      });
      
    this.memberStore.dispatch(
      ParticipantRelationshipActions.getParticipantRelationship({
        memberId: this.memberId,
        screenId: this.screenId
      })
    );
  }
}
