import { createAction, props } from '@ngrx/store';
import {
  AddSectionBody,
  BodyChangeKey,
  MetadataSection,
} from '../../types/models';

export const MetadataRequest = '[Metadata/API] Send Request';
export const MetadataFailure = '[Metadata/API] Failure';
export const MetadataSuccess = '[Metadata/API] Success';
export const ClearMetadata = '[Metadata] Clear';
export const MetadataOrder = '[MetadataOrder/API] Send Request';
export const AddSectionRequest = '[AddSection/API] Send Request';
export const AddSectionSuccess = '[AddSection/API] Success';
export const AddSectionFailure = '[AddSection/API] Failure';
export const RemoveSectionMetadataRequest =
  '[RemoveSectionMetadata/API] Send Request';
export const RemoveSectionMetadataSuccess =
  '[RemoveSectionMetadata/API] Success';
export const RemoveSectionMetadataFailure =
  '[RemoveSectionMetadata/API] Failure';

export const getMetadata = createAction(
  MetadataRequest,
  props<{ query?: any }>()
);
export const getMetadataSuccess = createAction(
  MetadataSuccess,
  props<{ metadata: MetadataSection[] }>()
);
export const getMetadataFailure = createAction(
  MetadataFailure,
  props<{ error?: any }>()
);
export const clearMetadata = createAction(ClearMetadata);
export const orderTable = createAction(
  MetadataOrder,
  props<{ body: BodyChangeKey }>()
);

export const addSection = createAction(
  AddSectionRequest,
  props<{
    body: AddSectionBody;
    isContinue: boolean;
  }>()
);
export const addSectionSuccess = createAction(
  AddSectionSuccess,
  props<{
    newSectionKey: string;
    isContinue: boolean;
  }>()
);
export const addSectionFailure = createAction(
  AddSectionFailure,
  props<{ errorMsg: string }>()
);
export const removeSectionMetadata = createAction(
  RemoveSectionMetadataRequest,
  props<{ metadataKey: string }>()
);
export const removeSectionMetadataSuccess = createAction(
  RemoveSectionMetadataSuccess
);
export const removeSectionMetaFailure = createAction(
  RemoveSectionMetadataFailure,
  props<{ errorMsg: string }>()
);


