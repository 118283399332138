import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as ProfileHeaderConfigurationActions from '../../store/actions/profile-header-configuration.actions';
import {
  EntityMemberDetail,
  ProfileHeaderData,
  ProfileHeaderList,
  PropertyConfigs,
} from '../../types/models';
import { ProfileHeaderConfigurationService } from '../../services/profile-header-configuration.service';

@Injectable()
export class ProfileHeaderConfigurationEffects {
  constructor(
    private actions$: Actions,
    private profileHeaderConfigurationService: ProfileHeaderConfigurationService
  ) {}

  // getPropertyList$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ProfileHeaderConfigurationActions.getProfileHeaderPropertyList),
  //     switchMap(() => {
  //       return this.profileHeaderConfigurationService.getPropertyList().pipe(
  //         map((res: PropertyConfigs) => {
  //           return ProfileHeaderConfigurationActions.getProfileHeaderPropertyListSuccess(
  //             { propertyConfigs: res.listSectionProperty }
  //           );
  //         }),
  //         catchError((err) => {
  //           return of(
  //             ProfileHeaderConfigurationActions.getProfileHeaderPropertyListFailure(
  //               { errorMsg: err.message }
  //             )
  //           );
  //         })
  //       );
  //     })
  //   )
  // );

  getProfileHeaderConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileHeaderConfigurationActions.getProfileHeaderConfiguration),
      switchMap(({ id }) => {
        return this.profileHeaderConfigurationService
          .getProfileHeaderConfiguration(id)
          .pipe(
            map((res) => {
              return ProfileHeaderConfigurationActions.getProfileHeaderConfigurationSuccess(
                { profileHeader: res }
              );
            }),
            catchError((err) => {
              return of(
                ProfileHeaderConfigurationActions.getProfileHeaderPropertyListFailure(
                  { errorMsg: err.message }
                )
              );
            })
          );
      })
    )
  );

  setProfileHeaderConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileHeaderConfigurationActions.setProfileHeaderConfiguration),
      switchMap(({ body }) => {
        return this.profileHeaderConfigurationService
          .manageConfigurationMember(body)
          .pipe(
            map((id) => {
              return ProfileHeaderConfigurationActions.setProfileHeaderConfigurationSuccess(
                { id }
              );
            }),
            catchError((err) => {
              return of(
                ProfileHeaderConfigurationActions.setProfileHeaderConfigurationFailure(
                  { errorMsg: err.message }
                )
              );
            })
          );
      })
    )
  );

  getMemberProfileHeaderData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileHeaderConfigurationActions.getMemberProfileHeaderData),
      switchMap(({ memberId }) => {
        return this.profileHeaderConfigurationService
          .getMemberProfileHeaderData(memberId)
          .pipe(
            map((profileHeaderData: ProfileHeaderData) => {
              return ProfileHeaderConfigurationActions.getMemberProfileHeaderDataSuccess(
                { profileHeaderData }
              );
            }),
            catchError((err) => {
              return of(
                ProfileHeaderConfigurationActions.getMemberProfileHeaderDataFailure(
                  { errorMsg: err.message }
                )
              );
            })
          );
      })
    )
  );

  getProfileHeaderDataData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileHeaderConfigurationActions.getProfileHeaderDataAction),
      switchMap(({ cardId, memberId, entityId, screenId }) => {
        return this.profileHeaderConfigurationService
          .getProfileHeaderData(cardId, memberId, entityId, screenId)
          .pipe(
            map((res) => {

              return ProfileHeaderConfigurationActions.getProfileHeaderDataSuccess(
                { headerPropertyConfig: res.results }
              );
            }),
            catchError((err) => {
              return of(
                ProfileHeaderConfigurationActions.getProfileHeaderDataFailure(
                  { errorMsg: err.message }
                )
              );
            })
          );
      })
    )
  );

  getProfileSubHeaderDataData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileHeaderConfigurationActions.getProfileSubHeaderDataAction),
      switchMap(({ cardId, memberId, entityId, screenId }) => {
        return this.profileHeaderConfigurationService
          .getProfileHeaderData(cardId, memberId, entityId, screenId)
          .pipe(
            map((res) => {

              return ProfileHeaderConfigurationActions.getProfileSubHeaderDataSuccess(
                { headerPropertyConfig: res.results }
              );
            }),
            catchError((err) => {
              return of(
                ProfileHeaderConfigurationActions.getProfileSubHeaderDataFailure(
                  { errorMsg: err.message }
                )
              );
            })
          );
      })
    )
  );

  getMemberDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileHeaderConfigurationActions.getMemberDetailAction),
      switchMap(({ memberId }) => {
        return this.profileHeaderConfigurationService
          .getMemberDetail(memberId)
          .pipe(
            map((memberDetail: EntityMemberDetail) => {
              return ProfileHeaderConfigurationActions.getMemberDetailSuccess(
                { memberDetail }
              );
            }),
            catchError((err) => {
              return of(
                ProfileHeaderConfigurationActions.getMemberDetailFailure(
                  { errorMsg: err.message }
                )
              );
            })
          );
      })
    )
  );

  getListProfileHeaderConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProfileHeaderConfigurationActions.getListProfileHeaderConfiguration
      ),
      switchMap(({ query }) => {
        return this.profileHeaderConfigurationService
          .getListProfileHeaderConfiguration(query)
          .pipe(
            map((profileHeaderList: ProfileHeaderList) => {
              return ProfileHeaderConfigurationActions.getListProfileHeaderConfigurationSuccess(
                { profileHeaderList }
              );
            }),
            catchError((error) => {
              return of(
                ProfileHeaderConfigurationActions.getListProfileHeaderConfigurationFailure(
                  { error }
                )
              );
            })
          );
      })
    )
  );
  deleteProfileHeaderConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProfileHeaderConfigurationActions.deleteProfileHeaderConfiguration
      ),
      switchMap(({ id }) => {
        return this.profileHeaderConfigurationService
          .deleteProfileHeaderConfiguration(id)
          .pipe(
            map(() => {
              return ProfileHeaderConfigurationActions.deleteProfileHeaderConfigurationSuccess();
            }),
            catchError((err) => {
              return of(
                ProfileHeaderConfigurationActions.deleteProfileHeaderConfigurationFailure(
                  { errorMsg: err.message }
                )
              );
            })
          );
      })
    )
  );
  getMembersStatusNotUsedList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileHeaderConfigurationActions.getMembersStatusNotUsed),
      switchMap(() => {
        return this.profileHeaderConfigurationService
          .getMembersStatusNotUsed()
          .pipe(
            map(({ listStatusUsing }) => {
              return ProfileHeaderConfigurationActions.getMembersStatusNotUsedSuccess(
                { statusNotUsedList: listStatusUsing }
              );
            }),
            catchError((err) => {
              return of(
                ProfileHeaderConfigurationActions.getMembersStatusNotUsedFailure(
                  { errorMsg: err.message }
                )
              );
            })
          );
      })
    )
  );
}
