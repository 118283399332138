import { createAction, props } from '@ngrx/store';
import {
  DisplayFormatRes,
  ExitsDisplayFormatRequest,
  GetLookupTableQuery,
  ImportSessionDetail,
  LookupTable,
  LookupTableDetail,
  LookupTableRequest,
  LookupTableValueRequest,
  MetaDataLookupTableDisplayFormat,
  ReplaceValueBody,
} from '../../types/models';

export const ClearLookupTableState = '[LookupTable] Clear';
export const LookupTableListRequest = '[LookupTableList/API] Send Request';
export const LookupTableListFailure = '[LookupTableList/API] Failure';
export const LookupTableListSuccess = '[LookupTableList/API] Success';
export const LookupTableDetailRequest = '[LookupTableDetail/API] Send Request';
export const LookupTableDetailFailure = '[LookupTableDetail/API] Failure';
export const LookupTableDetailSuccess = '[LookupTableDetail/API] Success';
export const CreateLookupTableRequest = '[CreateLookupTable/API] Send Request';
export const CreateLookupTableFailure = '[CreateLookupTable/API] Failure';
export const CreateLookupTableSuccess = '[CreateLookupTable/API] Success';
export const UpdateLookupTableRequest = '[UpdateLookupTable/API] Send Request';
export const UpdateLookupTableFailure = '[UpdateLookupTable/API] Failure';
export const UpdateLookupTableSuccess = '[UpdateLookupTable/API] Success';
export const CreateLookupTableValueRequestRequest =
  '[CreateLookupTableValueRequest/API] Send Request';
export const CreateLookupTableValueRequestFailure =
  '[CreateLookupTableValueRequest/API] Failure';
export const CreateLookupTableValueRequestSuccess =
  '[CreateLookupTableValueRequest/API] Success';
export const UpdateLookupTableValueRequest =
  '[UpdateLookupTableValue/API] Send Request';
export const UpdateLookupTableValueFailure =
  '[UpdateLookupTableValue/API] Failure';
export const UpdateLookupTableValueSuccess =
  '[UpdateLookupTableValue/API] Success';
export const RemoveLookupTableValueRequest =
  '[RemoveLookupTableValue/API] Send Request';
export const RemoveLookupTableValueFailure =
  '[RemoveLookupTableValue/API] Failure';
export const RemoveLookupTableValueSuccess =
  '[RemoveLookupTableValue/API] Success';
export const GetImportSessionDetailRequest =
  '[GetImportSessionDetail/API] Send Request';
export const GetImportSessionDetailSuccess =
  '[GetImportSessionDetail/API] Success';
export const GetImportSessionDetailFailure =
  '[GetImportSessionDetail/API] Failure';
export const ProceedImportRequest = '[ProceedImport/API] Send Request';
export const ProceedImportSuccess = '[ProceedImport/API] Success';
export const ProceedImportFailure = '[ProceedImport/API] Failure';
export const CancelImportRequest = '[CancelImport/API] Send Request';
export const CancelImportSuccess = '[CancelImport/API] Success';
export const CancelImportFailure = '[CancelImport/API] Failure';


export const DisplayFormatListRequest = '[DisplayFormatList/API] Send Request';
export const DisplayFormatListFailure = '[DisplayFormatList/API] Failure';
export const DisplayFormatListSuccess = '[DisplayFormatList/API] Success';

export const CreateDisplayFormatRequest = '[CreateDisplayFormat/API] Send Request';
export const CreateDisplayFormatFailure = '[CreateDisplayFormat/API] Failure';
export const CreateDisplayFormatSuccess = '[CreateDisplayFormat/API] Success';

export const EditDisplayFormatRequest = '[EditDisplayFormat/API] Send Request';
export const EditDisplayFormatFailure = '[EditDisplayFormat/API] Failure';
export const EditDisplayFormatSuccess = '[EditDisplayFormat/API] Success';

export const clearLookupTableState = createAction(ClearLookupTableState);
export const getLookupTableList = createAction(
  LookupTableListRequest,
  props<{ query: GetLookupTableQuery }>()
);
export const getLookupTableListSuccess = createAction(
  LookupTableListSuccess,
  props<{ lookupTableList: LookupTable[] }>()
);
export const getLookupTableListFailure = createAction(
  LookupTableListFailure,
  props<{ error?: any }>()
);
export const getLookupTableDetail = createAction(
  LookupTableDetailRequest,
  props<{ id: string }>()
);
export const getLookupTableDetailSuccess = createAction(
  LookupTableDetailSuccess,
  props<{ lookupTableDetail: LookupTableDetail }>()
);
export const getLookupTableDetailFailure = createAction(
  LookupTableDetailFailure,
  props<{ error?: any }>()
);
export const createLookupTable = createAction(
  CreateLookupTableRequest,
  props<{ lookupTableDetail: LookupTableRequest }>()
);
export const createLookupTableSuccess = createAction(CreateLookupTableSuccess);
export const createLookupTableFailure = createAction(
  CreateLookupTableFailure,
  props<{ errorMsg: string }>()
);
export const updateLookupTable = createAction(
  UpdateLookupTableRequest,
  props<{ lookupTableDetail: LookupTableRequest }>()
);
export const updateLookupTableSuccess = createAction(UpdateLookupTableSuccess);
export const updateLookupTableFailure = createAction(
  UpdateLookupTableFailure,
  props<{ errorMsg: string }>()
);
export const createLookupTableValue = createAction(
  CreateLookupTableValueRequestRequest,
  props<{ id: string; body: LookupTableValueRequest }>()
);
export const createLookupTableValueSuccess = createAction(
  CreateLookupTableValueRequestSuccess
);
export const createLookupTableValueFailure = createAction(
  CreateLookupTableValueRequestFailure,
  props<{ error?: any }>()
);
export const updateLookupTableValue = createAction(
  UpdateLookupTableValueRequest,
  props<{ id: string; body: LookupTableValueRequest }>()
);
export const updateLookupTableValueSuccess = createAction(
  UpdateLookupTableValueSuccess
);
export const updateLookupTableValueFailure = createAction(
  UpdateLookupTableValueFailure,
  props<{ error?: any }>()
);
export const removeLookupTableValue = createAction(
  RemoveLookupTableValueRequest,
  props<{ body: ReplaceValueBody }>()
);
export const removeLookupTableValueSuccess = createAction(
  RemoveLookupTableValueSuccess
);
export const removeLookupTableValueFailure = createAction(
  RemoveLookupTableValueFailure,
  props<{ error?: any }>()
);
export const getImportSessionDetail = createAction(
  GetImportSessionDetailRequest,
  props<{ source: string; sourceId: string; batchId: string }>()
);
export const getImportSessionDetailSuccess = createAction(
  GetImportSessionDetailSuccess,
  props<{ sessionDetail: ImportSessionDetail }>()
);
export const getImportSessionDetailFailure = createAction(
  GetImportSessionDetailFailure,
  props<{ errorMsg: string }>()
);
export const proceedImport = createAction(
  ProceedImportRequest,
  props<{ id: string }>()
);
export const proceedImportSuccess = createAction(ProceedImportSuccess);
export const proceedImportFailure = createAction(
  ProceedImportFailure,
  props<{ errorMsg: string }>()
);
export const cancelImport = createAction(
  CancelImportRequest,
  props<{ id: string }>()
);
export const cancelImportSuccess = createAction(CancelImportSuccess);
export const cancelImportFailure = createAction(
  CancelImportFailure,
  props<{ errorMsg: string }>()
);


export const getDisplayFormatList = createAction(
  DisplayFormatListRequest,
  props<{ id: string }>()
);
export const getDisplayFormatListSuccess = createAction(
  DisplayFormatListSuccess,
  props<{ metadataLookupTableDisplayFormats: DisplayFormatRes }>()
);
export const getDisplayFormatListFailure = createAction(
  DisplayFormatListFailure,
  props<{ error?: any }>()
);

export const createDisplayFormatRequest = createAction(
  CreateDisplayFormatRequest,
  props<{ body: ExitsDisplayFormatRequest }>()
);
export const createDisplayFormatSuccess = createAction(CreateDisplayFormatSuccess);
export const createDisplayFormatFailure = createAction(
  CreateDisplayFormatFailure,
  props<{ errorMsg: string }>()
);

export const editDisplayFormatRequest = createAction(
  EditDisplayFormatRequest,
  props<{ body: MetaDataLookupTableDisplayFormat }>()
);
export const editDisplayFormatSuccess = createAction(EditDisplayFormatSuccess);
export const editDisplayFormatFailure = createAction(
  EditDisplayFormatFailure,
  props<{ errorMsg: string }>()
);