import { inject, Injectable, InjectionToken } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromReducer from '@ptg-reducers';
import { AppSettingService } from '@ptg-shared/layout/services/app-setting.service';
import { BaseComponent } from '@ptg-shared/components';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import {filter, map} from 'rxjs/operators';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { deepClone } from '@ptg-shared/utils/common.util';

@Injectable({
  providedIn: 'root'
})
export class CheckVersionFlagService extends BaseComponent {
  ALL_FUND = '*';
  appSettings: BehaviorSubject<any> = new BehaviorSubject<any>('');

  get currentAppSetting() {
    return this.appSettings.value;
  }

  constructor(
    private store: Store<fromReducer.State>,
    private appSettingService: AppSettingService,
    private layoutService: LayoutService,
  ) {
    super();
  }

  getAppSettings() {
    this.appSettingService.getAppSettings().subscribe((appSettings) => {
      const currentAppSettings = {} as any;
      Object.entries(deepClone(appSettings)).forEach(([key, value]) => {
        let fundKeys = value;
        try {
          if (value !== this.ALL_FUND) {
            fundKeys = JSON.parse(value as string)
          }
        } catch (e) {
          console.log(e);
        }
        currentAppSettings[key] = fundKeys;
      });
      this.appSettings.next(currentAppSettings);
    });
  }

  checkVersionFlag(versionFlags: string | string[]): boolean | Observable<boolean> {
    const appSettings = this.currentAppSetting;
    if (!appSettings) {
      return this.appSettings.pipe(
        filter(appSettings => !!appSettings),
        map(appSettings => {
          return this.isApplyVersionFlag(versionFlags, appSettings);
        })
      );
    }
    return this.isApplyVersionFlag(versionFlags, appSettings);
  }

  private isApplyVersionFlag(versionFlags: string | string[], appSettings: Record<string, string | string[]>) {
    if (typeof versionFlags === 'string') {
      const fundKeys = appSettings?.[versionFlags];
      if (!fundKeys) {
        return false;
      }
      return (
        fundKeys === this.ALL_FUND ||
        (fundKeys as string[])?.some(
          (fundKey: string) => fundKey?.toLowerCase() === this.layoutService.fundKey?.toLowerCase(),
        )
      );
    }
    return versionFlags.some(versionFlag => this.checkVersionFlag(versionFlag));
  }

  static canActivateWithFlags(flags: string | string[]) {
    return new InjectionToken<CanActivate>('GuardByVersionFlags', {
      providedIn: 'root',
      factory: () => {
        const checkVersionFlagService = inject(CheckVersionFlagService);
        return {
          canActivate (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
            return checkVersionFlagService.checkVersionFlag(flags);
          }
        }
      },
    });
  }
}
