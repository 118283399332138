export interface RelatedPersonList {
  relatedPersons: RelatedPerson[];
  total: number;
}

export interface RelatedPerson {
  personId: string;
  relationship: string;
  personName: string;
}
export interface GetListRelatedPersonQuery {
  screenId?: string;
  pageIndex: number;
  pageSize: number;
  sortNames?: string[];
  sortType?: number;
  memberId: string;
}

export interface Documents {
  id?: string,
  uploadedDate: string,
  fileName: string,
  documentLocation: string
}

