import { createReducer, on } from '@ngrx/store';

import * as ProppertyActions from '../actions/property.actions';
import { MetadataPropertyType } from '../../types/models';

export const AddPropertyFeatureKey = 'addProperty';
export interface State {
  isLoading: boolean;
  propertyTypes: MetadataPropertyType[];
  name: string;
  selectedPropTypeValue: number;
}

const initialState: State = {
  isLoading: true,
  name: '',
  propertyTypes: [],
  selectedPropTypeValue: -1,
};

export const reducer = createReducer(
  initialState,
  on(
    ProppertyActions.getMetadataPropertyTypesSuccess,
    (state, { propertyTypes }) => ({
      ...state,
      propertyTypes,
    })
  ),
  on(
    ProppertyActions.selectPropertyType,
    (state, { selectedPropTypeValue }) => ({
      ...state,
      selectedPropTypeValue,
    })
  ),
  on(ProppertyActions.inputPropertyName, (state, { name }) => ({
    ...state,
    name,
  }))
);
