import { EntityDataEffect } from './entity-data.effect';
import { EntityListViewConfigurationEffect } from './entity-list-view-configuration.effect';
import { EntityProfileConfigEffect } from './entity-profile-config.effect';
import { EntityPropertyEffect } from './entity-property.effect';
import { EntityViewEffect } from './entity-view.effect';
import { EntityEffect } from './entity.effect';

export const EntityManagementEffects = [
  EntityDataEffect,
  EntityListViewConfigurationEffect,
  EntityProfileConfigEffect,
  EntityPropertyEffect,
  EntityViewEffect,
  EntityEffect,
];
