import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import * as fromReducer from '@ptg-reducers/index';

import { GetEntityProfileConfigOverviewResponse } from './models';

@Injectable()
export class EntityProfileConfigService {
  activeFund: any;

  constructor(
    private httpClient: HttpClient,
    public store: Store<fromReducer.State>,
  ) {
    this.store.select(fromReducer.selectCurrentFundState).subscribe((state) => (this.activeFund = state));
  }

  getEntityProfileConfigOverview(
    entityId: string,
    recordId: string,
  ): Observable<GetEntityProfileConfigOverviewResponse> {
    return this.httpClient.get<GetEntityProfileConfigOverviewResponse>(
      `${environment.apiUrl}/entity/entities/${entityId}/entity-data/${recordId}/profile-config-overview`,
    );
  }
}
