import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Auth0Service } from '@ptg-shared/auth/services/auth0.service';
import { EMPLOYER_SUBMODULES_BVFF_NOT_ACCESS } from '@ptg-shared/constance/employer-portal-permission.const';
import { FundType } from '@ptg-shared/types/enums';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromReducer from './../../reducers';

@Injectable({
  providedIn: 'root'
})
export class CheckFundService implements CanActivate {
  constructor(private _router: Router, private store: Store<fromReducer.State>, private authService: Auth0Service) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let curent$ = this.store.pipe(select(fromReducer.selectCurrentFundState));
    const rootUrl = state?.url?.split('?')?.[0] || '';
    return curent$.pipe(
      map((el: any) => {
        if (el && el.id) {
          if (el.fundType === FundType.BVFF && this.checkPermissionModuleBVFF(rootUrl)) {
            return false;
          }
          return true;
        } else {
          this._router.navigateByUrl('/');
        }
        return false;
      })
    );
  }

  checkPermissionModuleBVFF(url: string) {
    return EMPLOYER_SUBMODULES_BVFF_NOT_ACCESS.some((item: any) => {
      return url.includes(item);
    });
  }
}
