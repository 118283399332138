import { createReducer, on } from "@ngrx/store";
import { LayoutActions } from "../actions";
import { BulkContentItem } from "../models/bulk-content-item.model";

export const BulkContentFeatureKey = 'bulkContent';

export interface State {
  isLoading: boolean;
  items: BulkContentItem[];
}

const initialState: State = {
  isLoading: true,
  items: []
};

export const reducer = createReducer(
  initialState,
  on(LayoutActions.getBulkContentItemRequest, (state) => ({
    ...state,
    isLoading: true,
    items: []
  })),
  on(LayoutActions.getBulkContentItemRequestSuccess, (state, { items }) => ({
      ...state,
      isLoading: false,
      items
  })),
  on(LayoutActions.getBulkContentItemRequestFailure, (state, { errorMsg }) => ({
    ...state,
    isLoading: false,
    items: []
  }))
);
