import { createAction, props } from '@ngrx/store';

import {
  CalculationRefundRecordResponse,
  CalculationRefundYearResponse,
  CreateInitiateRefundCalculationInformationRequest,
  CreateInitiateRefundCalculationInformationResponse,
  CreateReSelectRefundYearsRequest,
  GetInitiateBenefitValidationsRequest,
  GetInitiateBenefitValidationsResponse,
  GetRefundCalculationRecordsRequest,
  GetRefundCalculationRecordsResponse,
  GetRefundCalculationRequest,
  GetRefundPayeeRequest,
  GetRefundPayeeResponse,
  GetRetirementBenefitRequest,
  PayeeListRequest,
  PayeeListResponse,
  UpdatePayeeInfoRequest,
  UpdatePayeeInfoResponse,
  ValidateBeforeRemoveCalculationBenefit,
} from '../../services/models';

// InitiateBenefitValidations

export const getInitiateBenefitValidationsAction = createAction(
  '[GetInitiateBenefitValidations/API] Send Request',
  props<{ request: GetInitiateBenefitValidationsRequest }>()
);

export const getInitiateBenefitValidationsSuccessAction = createAction(
  '[GetInitiateBenefitValidations/API] Success',
  props<{ response: GetInitiateBenefitValidationsResponse }>()
);

export const getInitiateBenefitValidationsFailureAction = createAction(
  '[GetInitiateBenefitValidations/API] Failure',
  props<{ error?: any }>()
);

export const clearGetInitiateBenefitValidationsStateAction = createAction(
  '[GetInitiateBenefitValidations] Clear'
);

// InitiateRefundCalculationInformation

export const createInitiateRefundCalculationInformationAction = createAction(
  '[CreateInitiateRefundCalculationInformation/API] Send Request',
  props<{ request: CreateInitiateRefundCalculationInformationRequest }>()
);

export const createInitiateRefundCalculationInformationSuccessAction = createAction(
  '[CreateInitiateRefundCalculationInformation/API] Success',
  props<{ response: CreateInitiateRefundCalculationInformationResponse }>()
);

export const createInitiateRefundCalculationInformationFailureAction = createAction(
  '[CreateInitiateRefundCalculationInformation/API] Failure',
  props<{ error?: any }>()
);

export const clearCreateInitiateRefundCalculationInformationStateAction = createAction(
  '[CreateInitiateRefundCalculationInformation] Clear'
);

// GetRefundCalculationRecords

export const getRefundCalculationRecordsAction = createAction(
  '[GetRefundCalculationRecords/API] Send Request',
  props<{ request: GetRefundCalculationRecordsRequest }>()
);

export const getRefundCalculationRecordsSuccessAction = createAction(
  '[GetRefundCalculationRecords/API] Success',
  props<{ response: GetRefundCalculationRecordsResponse }>()
);

export const getRefundCalculationRecordsFailureAction = createAction(
  '[GetRefundCalculationRecords/API] Failure',
  props<{ error?: any }>()
);

export const clearGetRefundCalculationRecordsStateAction = createAction(
  '[GetRefundCalculationRecords] Clear'
);

// GetRefundCalculationRecordsById

export const getRefundCalculationRecordByIdsAction = createAction(
  '[GetRefundCalculationRecordByIds/API] Send Request',
  props<{ request: GetRefundCalculationRequest }>()
);

export const getRefundCalculationRecordByIdsSuccessAction = createAction(
  '[GetRefundCalculationRecordByIds/API] Success',
  props<{ response: CalculationRefundRecordResponse }>()
);

export const getRefundCalculationRecordByIdsFailureAction = createAction(
  '[GetRefundCalculationRecordByIds/API] Failure',
  props<{ error?: any }>()
);

export const clearGetRefundCalculationRecordByIdsStateAction = createAction(
  '[GetRefundCalculationRecordByIds] Clear'
);

// GetCalculationRefundYears

export const getCalculationRefundYearsAction = createAction(
  '[GetCalculationRefundYears/API] Send Request',
  props<{ request: GetRefundCalculationRequest, getRefundYearRequest: GetRetirementBenefitRequest }>()
);

export const getCalculationRefundYearsSuccessAction = createAction(
  '[GetCalculationRefundYears/API] Success',
  props<{ response: CalculationRefundYearResponse }>()
);

export const getCalculationRefundYearsFailureAction = createAction(
  '[GetCalculationRefundYears/API] Failure',
  props<{ error?: any }>()
);

export const clearGetCalculationRefundYearsStateAction = createAction(
  '[GetCalculationRefundYears] Clear'
);

// CreateReSelectRefundYears

export const createReSelectRefundYearsAction = createAction(
  '[CreateReSelectRefundYears/API] Send Request',
  props<{ request: CreateReSelectRefundYearsRequest }>()
);

export const createReSelectRefundYearsSuccessAction = createAction(
  '[CreateReSelectRefundYears/API] Success',
  props<{ response: boolean }>()
);

export const createReSelectRefundYearsFailureAction = createAction(
  '[CreateReSelectRefundYears/API] Failure',
  props<{ error?: any }>()
);

export const clearCreateReSelectRefundYearsStateAction = createAction(
  '[CreateReSelectRefundYears] Clear'
);


// Change Payee Information
export const updatePayeeInformationAction = createAction(
  '[UpdatePayeeInformationAction/API] Send Request',
  props<{ request: UpdatePayeeInfoRequest }>(),
);

export const updatePayeeInformationSuccessAction = createAction(
  '[UpdatePayeeInformationAction/API] Success',
  props<{ response: UpdatePayeeInfoResponse }>(),
);

export const updatePayeeInformationFailureAction = createAction(
  '[UpdatePayeeInformationAction/API] Failure',
  props<{ error?: any }>(),
);

export const clearUpdatePayeeInformationStateAction = createAction(
  '[UpdatePayeeInformationAction] Clear'
);

export const getRefundPayeeAction = createAction(
  '[GetRefundPayee/API] Send Request',
  props<{ request: GetRefundPayeeRequest }>()
);

export const getRefundPayeeSuccessAction = createAction(
  '[GetRefundPayee/API] Success',
  props<{ response: GetRefundPayeeResponse }>()
);

export const getRefundPayeeFailureAction = createAction(
  '[GetRefundPayee/API] Failure',
  props<{ error?: any }>()
);

export const clearGetRefundPayeeStateAction = createAction(
  '[GetRefundPayee] Clear'
);

// Get Payee List
export const getPayeeListAction = createAction(
  '[GetPayeeList/API] Send Request',
  props<{ request: PayeeListRequest }>()
);

export const getPayeeListSuccessAction = createAction(
  '[GetPayeeList/API] Success',
  props<{ response: PayeeListResponse }>()
);

export const getPayeeListFailureAction = createAction(
  '[GetPayeeList/API] Failure',
  props<{ error?: any }>()
);

export const clearGetPayeeListStateAction = createAction(
  '[GetPayeeList] Clear'
);

// GetValidateBeforeRemoveCalculationBenefit

export const getValidateBeforeRemoveCalculationBenefitAction = createAction(
  '[GetValidateBeforeRemoveCalculationBenefit/API] Send Request',
  props<{ request: GetRefundCalculationRecordsRequest }>()
);

export const getValidateBeforeRemoveCalculationBenefitSuccessAction = createAction(
  '[GetValidateBeforeRemoveCalculationBenefit/API] Success',
  props<{ response: ValidateBeforeRemoveCalculationBenefit }>()
);

export const getValidateBeforeRemoveCalculationBenefitFailureAction = createAction(
  '[GetValidateBeforeRemoveCalculationBenefit/API] Failure',
  props<{ error?: any }>()
);

export const clearGetValidateBeforeRemoveCalculationBenefitStateAction = createAction(
  '[GetValidateBeforeRemoveCalculationBenefit] Clear'
);
