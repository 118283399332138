import { createSelector } from '@ngrx/store';

import { selectCalculationModuleState } from './selector';

export const memberEntitySelector = createSelector(
  selectCalculationModuleState,
  (state) => state?.memberEntity
);

export const getMemberEntityListComponentsSelector = createSelector(
  memberEntitySelector,
  (state) => state?.getMemberEntityListComponents
);
