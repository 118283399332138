export enum MissingPayeeInformationConfigType {
  None = 0,
  ConfiguredId = 1,
  PaymentAddress = 2,
  PayeeName = 4,
}

export enum MissingRepresentativePayeeType {
  None = 0,
  MetaData = 1,
  Name = 2,
  Address = 4,
  Type = 8,
}

export enum PayeeFilterType {
  Recurring,
  Added,
  Suspend,
  Terminated,
  NegativeNetPayment,
}

export enum ProcessingType {
  Payroll,
  ServiceProvider,
  OffCyclePayment,
}

export enum TransactionType {
  Payee,
  ServiceProvider,
}

export enum PayeeInfoField {
  Payee = 'payeeName',
  OrderByPayee = 'OrderByPayeeName',
  ConfiguredId = 'configuredId',
  OrderByConfiguredId = 'OrderByConfiguredId',
  Recipient = 'recipient',
  OrderByRecipient = 'OrderByRecipient',
}

export enum ConcurrentActionType {
  Processing,
  Success,
  Error,
}

export enum PaymentAction {
  Reset,
  SendToBank,
  UpdateTransaction,
  Finalize,
  Cancel,
}
