import { createAction, props } from '@ngrx/store';

import {
  GetExceptionRequest,
  GetExceptionResponse,
  GetRetirementBenefitDetailDocumentsRequest,
  GetRetirementBenefitDetailDocumentsResponse,
  CheckExceptionRequest,
  GetCalculationAuditTrailRequest,
  GetCalculationAuditTrailResponse,
} from '../../services/models';
import { ActionButtonOnCalculationDetail } from '../../types/enums';

export const getRetirementBenefitDetailDocumentsAction = createAction(
  '[GetRetirementBenefitDetailDocuments/API] Send Request',
  props<{ request: GetRetirementBenefitDetailDocumentsRequest }>(),
);

export const getRetirementBenefitDetailDocumentsSuccessAction = createAction(
  '[GetRetirementBenefitDetailDocuments/API] Success',
  props<{ response: GetRetirementBenefitDetailDocumentsResponse }>(),
);

export const getRetirementBenefitDetailDocumentsFailureAction = createAction(
  '[GetRetirementBenefitDetailDocuments/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetRetirementBenefitDetailDocumentsStateAction = createAction(
  '[GetRetirementBenefitDetailDocuments] Clear',
);

export const getExceptionListAction = createAction(
  '[GetExceptionList/API] Send Request',
  props<{ request: GetExceptionRequest }>(),
);

export const getExceptionListSuccessAction = createAction(
  '[GetExceptionList/API] Success',
  props<{ response: GetExceptionResponse }>(),
);

export const getExceptionListFailureAction = createAction('[GetExceptionList/API] Failure', props<{ error?: any }>());

export const clearGetExceptionListStateAction = createAction('[GetExceptionList] Clear');

export const checkExceptionConfigurationAction = createAction(
  '[CheckExceptionConfiguration/API] Send Request',
  props<{
    request: CheckExceptionRequest;
    buttonAction?: ActionButtonOnCalculationDetail;
  }>(),
);

export const checkExceptionConfigurationSuccessAction = createAction(
  '[CheckExceptionConfiguration/API] Success',
  props<{
    response: boolean;
    buttonAction?: ActionButtonOnCalculationDetail;
  }>(),
);

export const checkExceptionConfigurationFailureAction = createAction(
  '[CheckExceptionConfiguration/API] Failure',
  props<{ error?: any; buttonAction?: ActionButtonOnCalculationDetail }>(),
);

export const clearCheckExceptionConfigurationStateAction = createAction('[CheckExceptionConfiguration] Clear');

export const getCalculationAuditTrailAction = createAction(
  '[GetCalculationAuditTrails/API] Send Request',
  props<{ request: GetCalculationAuditTrailRequest }>(),
);

export const getCalculationAuditTrailSuccessAction = createAction(
  '[GetCalculationAuditTrails/API] Success',
  props<{ response: GetCalculationAuditTrailResponse }>(),
);

export const getCalculationAuditTrailFailureAction = createAction(
  '[GetCalculationAuditTrails/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetCalculationAuditTrailStateAction = createAction('[GetCalculationAuditTrails] Clear');
