import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ConfigColums } from '../gridview/gridview.component';
import { STYLE_COLUMN } from '../gridview/gridview.const';
import { dragHandle } from '../../constance/listIcons.const';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'ptg-form-table',
  templateUrl: './form-table.component.html',
  styleUrls: ['./form-table.component.scss']
})
export class FormTableComponent implements OnInit, OnChanges, AfterViewInit {
  dataSource: never | any = new MatTableDataSource([]);;
  STYLE_COLUMN = STYLE_COLUMN;
  styleDisplayedColumns: string[] = [];
  displayedColumns: string[] = [];
  headerColumns: string[] = [];

  @ViewChild('table') table!: MatTable<any>;
  @ViewChild(MatSort) sort!: MatSort;

  @Input() formDataArray!: FormArray;
  @Input() sortStatus?: boolean;
  @Input() canDropDrag?: boolean;
  @Input() isNotSticky?: boolean;
  @Input() isHiddenHeader?: boolean;
  @Input() configColums!: ConfigColums;
  @Input() sortHeader?: boolean;
  @Input() tableStriped?: boolean;
  @Input() isHighlightRow?: boolean;
  @Input() errorRequire?: string;

  @Output() onDropDragAction = new EventEmitter();
  @Output() sortChange = new EventEmitter();
  @Output() buttonEditRowClick = new EventEmitter();
  
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIconLiteral('drag-handle', sanitizer.bypassSecurityTrustHtml(dragHandle));
  }

  ngOnInit(): void {
    this.styleDisplayedColumns = this.configColums.styleColums;
    this.displayedColumns = this.configColums.displayedColumns;
    this.headerColumns = this.configColums.headerColumns;
    this.dataSource = new MatTableDataSource(this.formDataArray.controls);
    this.dataSource.sort = this.sort;
  }

  ngOnChanges() {
    this.dataSource = new MatTableDataSource(this.formDataArray.controls);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  sortData(sort: Sort) {
    this.sortChange.emit(sort);
  }

  fnButtonEdit(event: any, element: any, type: string) {
    this.buttonEditRowClick.emit({element, type});
  }

  dropTable(event: CdkDragDrop<any[]>) {
    let res: any = [];
    let newData = JSON.parse(JSON.stringify(this.formDataArray.getRawValue()));
 
    for (let i = 0; i < newData.length; i += 1) {
      if ((i < event.currentIndex && i >= event.previousIndex) ||
      (i > event.currentIndex && i <= event.previousIndex)) {
        res[i] = newData[i + (event.currentIndex > event.previousIndex ? 1 : -1)];
      } else {
        res[i] = newData[i];
      }
    }
    res[event.currentIndex] = newData[event.previousIndex];
    this.onDropDragAction.emit(res);
  }
}
