import { createReducer, on } from '@ngrx/store';

import { ParametersActions } from '../actions';
import { ACTION, ActionState, STATE } from '../../shared/constance/value.const';
import { ContributionPlanConfiguration, ContributionPlanConfigurations, PlanDetail, PlanDetailExpand, PlanList, TaxStatusList } from '../models/parameters.model';
import { CONTRIBUTION_TERM } from '../constance/employer.const';
import { BaseActionState } from '@ptg-shared/types/models';


export const parametersFeatureKey = 'Parameters';

export interface State {
  taxStatusState: {
    isLoading: boolean;
    taxStatusList: TaxStatusList;
    success?: boolean,
    error?: any,
  };
  planState: {    
    isLoading: boolean;
    planList: PlanList;
    success?: boolean,
    error?: any,
  };
  planDetailState: {    
    isLoading: boolean;
    planDetail: PlanDetail;
    success?: boolean,
    error?: any,
  };
  actionState?: any;
  getContributionPlanConfigurationsState?: BaseActionState & ContributionPlanConfigurations;
  setContributionPlanConfigurationsState?: BaseActionState;
}

const initialState: State = {
  taxStatusState: {
    isLoading: true,
    taxStatusList: {} as TaxStatusList
  },
  planState: {
    isLoading: true,
    planList: {} as PlanList,
  },
  planDetailState: {
    isLoading: true,
    planDetail: {} as PlanDetail
  },
  actionState: undefined
};

export const reducer = createReducer(
  initialState,
  on(ParametersActions.getTaxStatusList, (state, {}) => ({
    ...state,
    taxStatusState: {
      isLoading: true,
      taxStatusList: {
        total: 0,
        contributionTaxStatus: []
      }
    }
  })),
  on(ParametersActions.getTaxStatusListSuccess, (state, { taxStatusList }) => ({
    ...state,
    taxStatusState: {
      isLoading: false,
      taxStatusList: taxStatusList,
      success: true,
      error: undefined,
    }
  })),
  on(ParametersActions.getTaxStatusListFailure, (state, { error }) => ({
    ...state,
    taxStatusState: {
      isLoading: false,
      taxStatusList: {
        total: 0,
        contributionTaxStatus: []
      },
      success: false,
      error: error,
    }
  })),
  on(ParametersActions.getPlanList, (state, {}) => ({
    ...state,
    planState: {
      isLoading: true,
      planList: {
        total: 0,
        contributionPlan: [],
      }
    }
  })),
  on(ParametersActions.getPlanListSuccess, (state, { planList }) => ({
    ...state,
    planState: {
      isLoading: false,
      planList: planList,
      success: true,
      error: undefined,
    }
  })),
  on(ParametersActions.getPlanListFailure, (state, { error }) => ({
    ...state,
    planState: {
      isLoading: false,
      planList: {
        total: 0,
        contributionPlan: [],
      },
      success: false,
      error: error,
    }
  })),
  on(ParametersActions.getPlanDetail, (state, {}) => ({
    ...state,
    planDetailState: {
      isLoading: true,
      planDetail: {
        id: '',
        planName: '',
        planCode: '',
        employerId: '',
        effectiveStartDate: '',
        effectiveEndDate: '',
        contributionTerm: CONTRIBUTION_TERM.Percentage,
        contributionTermDisplay: '',
        contributionDetailPlans: [],
      }
    }
  })),
  on(ParametersActions.getPlanDetailSuccess, (state, { planDetail }) => ({
    ...state,
    planDetailState: {
      isLoading: false,
      planDetail: planDetail,
      success: true,
      error: undefined,
    }
  })),
  on(ParametersActions.getPlanDetailFailure, (state, { error }) => ({
    ...state,
    planDetailState: {
      isLoading: false,
      planDetail: {
        id: '',
        planName: '',
        planCode: '',
        employerId: '',
        effectiveStartDate: '',
        effectiveEndDate: '',
        contributionTerm: CONTRIBUTION_TERM.Percentage,
        contributionTermDisplay: '',
        contributionDetailPlans: [],
      },
      success: false,
      error: error,
    }
  })),
  on(ParametersActions.getContributionPlanConfigurations, (state) => ({
    ...state,
    getContributionPlanConfigurationsState: {
      isLoading: true,
      contributionPlanConfigurations: []
    },
  })),
  on(ParametersActions.getContributionPlanConfigurationsSuccess, (state: State, { response }) => ({
    ...state,
    getContributionPlanConfigurationsState: {
      isLoading: false,
      success: true,
      contributionPlanConfigurations: response.contributionPlanConfigurations
    },
  })),
  on(ParametersActions.getContributionPlanConfigurationsFailure, (state: State, { error }) => ({
    ...state,
    getContributionPlanConfigurationsState: {
      isLoading: false,
      success: false,
      error: error,
      contributionPlanConfigurations: []
    },
  })),
  on(ParametersActions.clearGetContributionPlanConfigurationsState, (state: State) => ({
    ...state,
    getContributionPlanConfigurationsState: undefined,
  })),
  on(ParametersActions.setContributionPlanConfigurations, (state) => ({
    ...state,
    setContributionPlanConfigurationsState: {
      isLoading: true,
    },
  })),
  on(ParametersActions.setContributionPlanConfigurationsSuccess, (state: State) => ({
    ...state,
    setContributionPlanConfigurationsState: {
      isLoading: false,
      success: true,
    },
  })),
  on(ParametersActions.setContributionPlanConfigurationsFailure, (state: State, { error }) => ({
    ...state,
    setContributionPlanConfigurationsState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  
);

