import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, startWith, switchMap, take, tap } from 'rxjs/operators';

import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';

import * as LookupTableActions from '../../store/actions/lookup-table.actions';
import * as fromMember from '../../store/reducers';
import { MetadataLookupTableOption } from '../../types/models/lookup-table.model';
import { LookupTableService } from '../../services/lookup-table.service';

@Component({
  selector: 'ptg-edit-lookup-table-value',
  templateUrl: './edit-lookup-table-value.component.html',
  styleUrls: ['./edit-lookup-table-value.component.scss'],
})
export class EditLookupTableValueComponent implements OnInit {
  editForm!: FormGroup;
  formSubmit$ = new Subject<boolean>();

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditLookupTableValueComponent>,
    @Inject(MAT_DIALOG_DATA)
    public lookupTableDetail: {
      loopUpTableId: string;
      lookupTableValue: MetadataLookupTableOption;
    },
    private fb: FormBuilder,
    private memberStore: Store<fromMember.MemberState>,
    public lookupTableService: LookupTableService,
    public switchConfirmPopupService: SwitchConfirmPopupService
  ) {}

  ngOnInit(): void {
    this.initFormGroup(
      this.lookupTableDetail.lookupTableValue ||
        ({} as MetadataLookupTableOption)
    );
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter((status) => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  initFormGroup(formData: MetadataLookupTableOption) {
    this.editForm = this.fb.group({
      id: this.fb.control(formData.id),
      code: this.fb.control(
        formData.code,
        [Validators.maxLength(10)],
      ),
      name: this.fb.control(formData.description, [
        Validators.required,
        Validators.maxLength(255)
      ],
      checkApiValidator(
        this.lookupTableService.checkExistCode,
        'Name',
        this.lookupTableDetail.lookupTableValue?.description,
        {
          params: {
            loopUpTableId: this.lookupTableDetail.loopUpTableId,
            id: this.lookupTableDetail.lookupTableValue?.id,
          },
        }
      )),
      longName: this.fb.control(formData.longDescription, [
        Validators.maxLength(500),
      ]),
    });
  }

  onSubmit() {
    const body = JSON.parse(
      JSON.stringify(this.editForm.value as MetadataLookupTableOption)
    );
    body.id = body.id || undefined;
    if (body.id) {
      this.memberStore.dispatch(
        LookupTableActions.updateLookupTableValue({
          id: this.lookupTableDetail.loopUpTableId,
          body: {
            name: body.name,
            code: body.code,
            longName: body.longName,
            lookUpId: body.id,
          },
        })
      );
    } else {
      this.memberStore.dispatch(
        LookupTableActions.createLookupTableValue({
          id: this.lookupTableDetail.loopUpTableId,
          body,
        })
      );
    }
    this.dialogRef.close();
  }

  onCancel() {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }
}
