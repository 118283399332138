import { SortType } from '@ptg-shared/constance/value.const';

import { MemberStatus } from './status.model';

export interface GetBenefitResponse {
  benefit: Benefit;
}

export interface Benefit {
  id: string;
  minimumAmount: string;
  lookupTableId: string;
  isActiveQDRO: boolean;
  qdroBenefitTypeId: string;
  qdroSectionKey: string;
  isLinkedLookupTable?: boolean;
}

export interface UpdateBenefitRequest {
  id: string;
  minimumAmount: string;
  lookupTableId: string;
  isActiveQDRO: boolean;
  qdroBenefitTypeId: string;
  qdroSectionKey: string;
}

export interface GetBenefitTypesRequest {
  benefitTypeId?: string;
  pageSize?: number;
  pageNumber?: number;
  sortNames?: string;
  sortType?: SortType;
}

export interface GetBenefitTypesResponse {
  benefitTypes?: BenefitType[];
  total?: number;
}

export interface BenefitType {
  id: string;
  name: string;
  code: string | null;
  status: string;
  statusEvent: string;
  parentTypes: ParentBenefitType[];
  parentTypesString: string;
  totalParticipants: number;
  allowRemove: boolean;
  isDeletable?: boolean;
}

export interface AddBenefitTypeRequest {
  clientId?: string;
  name?: string;
  code?: string;
  memberStatusId?: string;
  memberEventId?: string;
  sectionKey?: string;
  parentTypes?: ParentBenefitType[];
}

export interface SetBenefitTypeRequest {
  id: string;
  clientId?: string;
  name?: string;
  code?: string;
  memberStatusId?: string;
  memberEventId?: string;
  sectionKey?: string;
  parentTypes?: ParentBenefitType[];
}

export interface ParentBenefitType {
  benefitTypeId?: string;
  benefitTypeCode?: string;
  relationshipTypeId?: string;
  parentLabel?: string;
  childLabel?: string;
}

export interface GetStatusAndEventsResponse {
  memberStatuses?: MemberStatus[];
}

export interface GetLookupTableOptionsResponse {
  lookupTableOptions?: LookupTableOption[];
}
export interface LookupTableOption {
  id: string;
  description: string;
}

export interface GetBenefitRecordsResponse {
  benefitRecords?: BenefitRecord[];
}

export interface BenefitRecord {
  name: string;
  key: string;
}

export interface GetLookupTablesReponse {
  lookupTables: LookupTableForBenefit[];
}

export interface LookupTableForBenefit {
  id: string;
  name: string;
  isActive: boolean;
}

export interface GetQDROBenefitTypesResponse {
  qdroBenefitTypes: QDROBenefitType[];
}

export interface QDROBenefitType {
  id: string;
  name: string;
}

export interface GetQDROMetaDataReponse {
  qdroMetadatas: QDROMetadata[];
}

export interface QDROMetadata {
  key: string;
  name: string;
}
