import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromReducer from '@ptg-reducers';
import { environment } from 'src/environments/environment';
import { ReportCategoryResponse, UpdateReportCategoriesRequest } from '@ptg-member/types/models/report.model';
import { MemberFilterModal } from '../types/models';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  activeFund: any;
  currentFilter: MemberFilterModal | undefined;
  controller: string = 'report';

  constructor(
    private httpClient: HttpClient,
    private store: Store<fromReducer.State>
  ) {
    this.store
      .pipe(select(fromReducer.selectCurrentFundState))
      .subscribe((el) => (this.activeFund = el));
  }

  getCategories(): Observable<ReportCategoryResponse> {

    return this.httpClient.get<ReportCategoryResponse>(
      `${environment.apiUrl}/${this.controller}/report-categories?clientId=${this.activeFund?.id}`      
    );
  }

  UpdateCategories(body: UpdateReportCategoriesRequest): Observable<any> {  
    return this.httpClient.put(
      `${environment.apiUrl}/${this.controller}/report-categories`,
      body
    );
  }  
}
