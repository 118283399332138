<div class="dialog-header">
  <span class="title">Add New Step Configuration</span>
</div>
<form [formGroup]="editForm" class="edit-form">
  <div class="form-content">
    <div class="flex flex-col">
      <ptg-select
        [controlField]="benefitOptionControl"
        [isOptionObj]="true"
        [isSetMaxWidthSelect]="true"
        [listData]="benefitOptions"
        customError="existsBenefitOption"
        [isRequired]="true"
        placeholder="Benefit Option"
      ></ptg-select>
    </div>
  </div>
  <div class="dialog-footer">
    <ptg-button
      (click)="formSubmit$.next(true)"
      buttonName="Create & Continue"
      classInput="primary"></ptg-button>
    <ptg-button (click)="onCancel()" buttonName="Cancel" classInput="tertiary"></ptg-button>
  </div>
</form>

