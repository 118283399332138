import { createSelector } from '@ngrx/store';

import { selectCalculationModuleState } from './selector';

export const selectCalculationQDRO = createSelector(selectCalculationModuleState, (state) => state?.calculationQDRO);

export const getCalculationQDROSelector = createSelector(selectCalculationQDRO, (state) => state?.getQDROLabelName);

export const getAlternatePayeesSelector = createSelector(selectCalculationQDRO, (state) => state?.getAlternatePayees);

export const getQDROValidationBeforeInitializationSelector = createSelector(
  selectCalculationQDRO,
  (state) => state?.getQDROValidationBeforeInitialization,
);

export const getQDROBenefitInformationSelector = createSelector(
  selectCalculationQDRO,
  (state) => state?.getGetQDROBenefitInformation
);
