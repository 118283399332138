import { createReducer, on } from '@ngrx/store';
import { BaseActionState } from '@ptg-shared/types/models';
import { Entity } from '@ptg-entity-management/services/models';
import {
  UpdateProfileAction,
  UpdateProfileFailureAction,
  UpdateProfileSuccessAction,
  clearProfileConfigState,
  clearProfileViewHeaderAction,
  createProfileAction,
  createProfileFailureAction,
  createProfileSuccessAction,
  getEntitiesProfileAction,
  getEntitiesProfileFailureAction,
  getEntitiesProfileSuccessAction,
  getProfileConfigList,
  getProfileConfigListFailure,
  getProfileConfigListSuccess,
  getProfileDetailAction,
  getProfileDetailFailureAction,
  getProfileDetailSuccessAction,
  getProfileExistAction,
  getProfileExistFailureAction,
  getProfileExistSuccessAction,
  getProfileHeaderAction,
  getProfileHeaderFailureAction,
  getProfileHeaderSuccessAction,
  getProfileMenuAction,
  getProfileMenuFailureAction,
  getProfileMenuSuccessAction,
  getProfileStatusEventAction,
  getProfileStatusEventFailureAction,
  getProfileStatusEventSuccessAction,
  getProfileViewAction,
  getProfileViewFailureAction,
  getProfileViewSuccessAction,
  removeProfileAction,
  removeProfileFailureAction,
  removeProfileSuccessAction,
} from '../actions/profile-configuration.actions';
import { Profile } from '@ptg-member/types/models';
import { ProfileConfigDetailResponse, ProfileConfigListResponse, ProfileExistResponse, ProfileMenuResponse, ProfileOverHeader, ProfileOverView, StatusEvent, StatusEventResponse } from '@ptg-member/types/models/profile-configuration.model';

export interface ProfileConfigState {
  getEntities?: BaseActionState<Entity[]>;
  getProfileStatusEventList?: BaseActionState<StatusEventResponse>;
  getProfileMenuList?: BaseActionState<ProfileMenuResponse>;
  getProfileView?: BaseActionState<ProfileOverView[]>;
  getProfileHeader?: BaseActionState<ProfileOverHeader[]>;
  getProfileExist?: BaseActionState<ProfileExistResponse>;
  getProfileDetail?: BaseActionState<ProfileConfigDetailResponse>;
  createProfile?: BaseActionState<Profile>;
  removeProfile?: BaseActionState;
  updateProfileList?: BaseActionState;
  getProfileConfigList?: BaseActionState<ProfileConfigListResponse>;
}

const initialState: ProfileConfigState = {
  getProfileConfigList: {
    isLoading: true,
    payload: { profileConfigs: [] }
  },
  removeProfile: {
    isLoading: false,
    success: false,
  }
};

export const profileConfigReducer = createReducer(
  initialState,
  on(clearProfileConfigState, () => ({
    ...initialState
  })),
  on(getProfileConfigList, (state: ProfileConfigState) => ({
    ...state,
    getProfileConfigList: {
      ...state.getProfileConfigList,
      isLoading: true,
    }
  })),
  on(getProfileConfigListSuccess, (state: ProfileConfigState, { profileConfigList }) => ({
    ...state,
    getProfileConfigList: {
      isLoading: false,
      success: true,
      payload: profileConfigList, 
      total: profileConfigList.total
    }
  })),
  on(getProfileConfigListFailure, (state, { error }) => ({
    ...state,
    getProfileConfigList: {
      isLoading: false,
      success: false,
      payload: { profileConfigs: [] }
    }
  })),
  on(getEntitiesProfileAction, (state: ProfileConfigState) => ({
    ...state,
    getEntities: {
      isLoading: true,
    },
  })),
  on(getEntitiesProfileFailureAction, (state: ProfileConfigState, { error }) => ({
    ...state,
    getEntities: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(getEntitiesProfileSuccessAction, (state: ProfileConfigState, { response }) => ({
    ...state,
    getEntities: {
      isLoading: false,
      success: true,
      payload: response.entities,
      total: response.total,
    },
  })),

  on(getProfileStatusEventAction, (state: ProfileConfigState) => ({
    ...state,
    getProfileStatusEventList: {
      isLoading: true,
    },
  })),
  on(getProfileStatusEventFailureAction, (state: ProfileConfigState, { error }) => ({
    ...state,
    getProfileStatusEventList: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(getProfileStatusEventSuccessAction, (state: ProfileConfigState, { response }) => ({
    ...state,
    getProfileStatusEventList: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),

  on(getProfileMenuAction, (state: ProfileConfigState) => ({
    ...state,
    getProfileMenuList: {
      isLoading: true,
    },
  })),
  on(getProfileMenuFailureAction, (state: ProfileConfigState, { error }) => ({
    ...state,
    getProfileMenuList: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(getProfileMenuSuccessAction, (state: ProfileConfigState, { response }) => ({
    ...state,
    getProfileMenuList: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),

  on(UpdateProfileAction, (state: ProfileConfigState) => ({
    ...state,
    updateProfileList: {
      isLoading: true,
    },
  })),
  on(UpdateProfileFailureAction, (state: ProfileConfigState, { error }) => ({
    ...state,
    updateProfileList: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(UpdateProfileSuccessAction, (state: ProfileConfigState, { response }) => ({
    ...state,
    updateProfileList: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),

  on(getProfileDetailAction, (state: ProfileConfigState) => ({
    ...state,
    getProfileDetail: {
      isLoading: true,
    },
  })),
  on(getProfileDetailFailureAction, (state: ProfileConfigState, { error }) => ({
    ...state,
    getProfileDetail: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(getProfileDetailSuccessAction, (state: ProfileConfigState, { response }) => ({
    ...state,
    getProfileDetail: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),

  on(getProfileViewAction, (state: ProfileConfigState) => ({
    ...state,
    getProfileView: {
      isLoading: true,
    },
  })),
  on(getProfileViewSuccessAction, (state: ProfileConfigState, { response }) => ({
    ...state,
    getProfileView: {
      isLoading: false,
      success: true,
      payload: response.entityViews,
      total: response.total,
    },
  })),
  on(getProfileViewFailureAction, (state: ProfileConfigState, { error }) => ({
    ...state,
    getProfileView: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),

  on(getProfileHeaderAction, (state: ProfileConfigState) => ({
    ...state,
    getProfileHeader: {
      isLoading: true,
    },
  })),
  on(getProfileHeaderSuccessAction, (state: ProfileConfigState, { response }) => ({
    ...state,
    getProfileHeader: {
      isLoading: false,
      success: true,
      payload: response.entityHeaders,
      total: response.total,
    },
  })),
  on(getProfileHeaderFailureAction, (state: ProfileConfigState, { error }) => ({
    ...state,
    getProfileHeader: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),

  on(clearProfileViewHeaderAction, (state: ProfileConfigState) => ({
    ...state,
    getProfileView: undefined,
    getProfileHeader: undefined,
  })),

  on(getProfileExistAction, (state: ProfileConfigState) => ({
    ...state,
    getProfileExist: {
      isLoading: true,
    },
  })),
  on(getProfileExistSuccessAction, (state: ProfileConfigState, { response }) => ({
    ...state,
    getProfileExist: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getProfileExistFailureAction, (state: ProfileConfigState, { error }) => ({
    ...state,
    getProfileExist: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),

  on(createProfileAction, (state: ProfileConfigState) => ({
    ...state,
    createProfile: {
      isLoading: true,
    },
  })),
  on(createProfileSuccessAction, (state: ProfileConfigState, { response }) => ({
    ...state,
    createProfile: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(createProfileFailureAction, (state: ProfileConfigState, { error }) => ({
    ...state,
    createProfile: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),

  on(removeProfileAction, (state: ProfileConfigState) => ({
    ...state,
    removeProfile: {
      isLoading: true,
    },
  })),
  on(removeProfileSuccessAction, (state: ProfileConfigState) => ({
    ...state,
    removeProfile: {
      isLoading: false,
      success: true,
    },
  })),
  on(removeProfileFailureAction, (state: ProfileConfigState, { error }) => ({
    ...state,
    removeProfile: {
      isLoading: false,
      success: false,
      error: error,
    },
  }))
);
