import { createAction, props } from '@ngrx/store';
import {
  GetStatusQuery,
  MemberEvent,
  MemberStatus,
  MemberStatusDetail,
} from '../../types/models';

export const ClearStatusState = '[Status] Clear';
export const StatusRequest = '[Status/API] Send Request';
export const StatusFailure = '[Status/API] Failure';
export const StatusSuccess = '[Status/API] Success';
export const CreateStatusRequest = '[Status/API] Create Status Request';
export const CreateStatusSuccess = '[Status/API] Create Status Success';
export const CreateStatusFailure = '[Status/API] Create Status Failure';
export const EditStatusRequest = '[Status/API] Edit Status Request';
export const EditStatusSuccess = '[Status/API] Edit Status Success';
export const EditStatusFailure = '[Status/API] Edit Status Failure';
export const RemoveStatusRequest = '[Status/API] Remove Status Request';
export const RemoveStatusSuccess = '[Status/API] Remove Status Success';
export const RemoveStatusFailure = '[Status/API] Remove Status Failure';
export const StatusDetailRequest = '[Status/API] Send Get Detail Request';
export const StatusDetailFailure = '[Status/API] Get Detail Failure';
export const StatusDetailSuccess = '[Status/API] Get Detail Success';
export const CreateEventRequest = '[Status/API] Create Event Request';
export const CreateEventSuccess = '[Status/API] Create Event Success';
export const CreateEventFailure = '[Status/API] Create Event Failure';
export const EditEventRequest = '[Status/API] Edit Event Request';
export const EditEventSuccess = '[Status/API] Edit Event Success';
export const EditEventFailure = '[Status/API] Edit Event Failure';
export const RemoveEventRequest = '[Status/API] Remove Event Request';
export const RemoveEventSuccess = '[Status/API] Remove Event Success';
export const RemoveEventFailure = '[Status/API] Remove Event Failure';

export const getStatus = createAction(
  StatusRequest,
  props<{ query: GetStatusQuery }>()
);
export const getStatusSuccess = createAction(
  StatusSuccess,
  props<{ statusList: MemberStatus[] }>()
);
export const getStatusFailure = createAction(
  StatusFailure,
  props<{ error?: any }>()
);
export const clearStatusState = createAction(ClearStatusState);
export const createStatus = createAction(
  CreateStatusRequest,
  props<{ statusDetail: MemberStatusDetail }>()
);
export const createStatusSuccess = createAction(CreateStatusSuccess);
export const createStatusFailure = createAction(
  CreateStatusFailure,
  props<{ errorMsg: string }>()
);
export const editStatus = createAction(
  EditStatusRequest,
  props<{ statusDetail: MemberStatusDetail }>()
);
export const editStatusSuccess = createAction(EditStatusSuccess);
export const editStatusFailure = createAction(
  EditStatusFailure,
  props<{ errorMsg: string }>()
);
export const removeStatus = createAction(
  RemoveStatusRequest,
  props<{ statusId: string }>()
);
export const removeStatusSuccess = createAction(RemoveStatusSuccess);
export const removeStatusFailure = createAction(
  RemoveStatusFailure,
  props<{ errorMsg: string }>()
);
export const getStatusDetail = createAction(
  StatusDetailRequest,
  props<{ query: GetStatusQuery }>()
);
export const getStatusDetailSuccess = createAction(
  StatusDetailSuccess,
  props<{ statusDetail: MemberStatusDetail }>()
);
export const getStatusDetailFailure = createAction(
  StatusDetailFailure,
  props<{ error?: any }>()
);
export const createEvent = createAction(
  CreateEventRequest,
  props<{ eventDetail: MemberEvent }>()
);
export const createEventSuccess = createAction(CreateEventSuccess);
export const createEventFailure = createAction(
  CreateEventFailure,
  props<{ errorMsg: string }>()
);
export const editEvent = createAction(
  EditEventRequest,
  props<{ eventDetail: MemberEvent }>()
);
export const editEventSuccess = createAction(EditEventSuccess);
export const editEventFailure = createAction(
  EditEventFailure,
  props<{ errorMsg: string }>()
);
export const removeEvent = createAction(
  RemoveEventRequest,
  props<{ eventId: string }>()
);
export const removeEventSuccess = createAction(RemoveEventSuccess);
export const removeEventFailure = createAction(
  RemoveEventFailure,
  props<{ errorMsg: string }>()
);
