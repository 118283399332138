import { Component, Inject, ViewChild } from '@angular/core';
import { BaseComponent } from '@ptg-shared/components';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { Option } from 'src/app/shared/controls/select/select.component';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { CalculationDocumentType, CalculationType, ResponseStatus } from '../../types/enums';
import { CALCULATION_DOCUMENT_TYPE_OPTIONS, DISABILITY_DOCUMENT_TYPE_OPTIONS } from '../../constants';
import { RetirementBenefitUploadDocumentService } from '../../services';
import { RetirementBenefitDialogService } from '../../services/retirement-benefit-dialog.service';
import { UploadComponent } from '@ptg-shared/controls/upload/upload.component';
import { Store } from '@ngrx/store';
import {
  RetirementBenefitUploadDocumentState,
  clearCreateRetirementBenefitUploadDocumentsStateAction,
  createRetirementBenefitUploadDocumentsAction,
} from '../../store';
import { CreateRetirementBenefitUploadDocumentsRequest } from '../../services/models';
import { deepClone } from '@ptg-shared/utils/common.util';
import { createRetirementBenefitUploadDocumentsSelector } from '../../store/selectors/retirement-benefit-upload-document.selector';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { RetirementBenefitDialogComponentService } from '../retirement-benefit-dialog/retirement-benefit-dialog.component.service';
import { FundType } from '@ptg-shared/types/enums';
import { LayoutService } from '@ptg-shared/services/layout.service';

@Component({
  selector: 'ptg-retirement-benefit-upload-document',
  templateUrl: './retirement-benefit-upload-document.component.html',
  styleUrls: ['./retirement-benefit-upload-document.component.scss'],
  providers: [RetirementBenefitDialogComponentService],
})
export class RetirementBenefitUploadDocumentComponent extends BaseComponent {
  memberId: string = '';
  listBreadcrumbs: Breadcrumb[] = this.getBreadcrumbs();
  listProperty!: Option[];
  uploadDocumentForm!: FormGroup;

  acceptFile = 'application/pdf';
  supportedFileTypes = 'pdf file format';
  checkPattern = new RegExp(/^[\x00-\x7F]+\.(pdf)$/, 'i');
  fileSelected!: File;
  uploadDate!: Date;
  @ViewChild('fileDocument')
  private fileDocument!: UploadComponent;

  editForm: FormGroup = this.fb.group({
    property: this.fb.control('', []),
  });

  file?: File;

  calculationDocumentTypeOptions: Option[] = deepClone(CALCULATION_DOCUMENT_TYPE_OPTIONS);
  isUploading: boolean = false;

  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  calculationStore: any;

  calculationType = CalculationType.RetirementBenefit;
  calculationBenefitId: string = '';

  constructor(
    public route: ActivatedRoute,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private retirementBenefitDialogService: RetirementBenefitDialogService,
    public retirementBenefitUploadDocumentService: RetirementBenefitUploadDocumentService,
    public retirementBenefitUploadDocumentStore: Store<RetirementBenefitUploadDocumentState>,
    public dialogRef: MatDialogRef<RetirementBenefitUploadDocumentComponent>,
    public dialog: MatDialog,
    private readonly retirementBenefitDialogComponentService: RetirementBenefitDialogComponentService,
    private readonly layoutService: LayoutService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.memberId = this.data.memberId;
    if (this.data.calculationType) this.calculationType = this.data.calculationType;
    if (this.data.calculationBenefitId) this.calculationBenefitId = this.data.calculationBenefitId;

    this.setCalculationDocumentTypes();
    this.checkRoute();
    this.uploadDocumentForm = this.initSelectDocumentFormGroup();
    this.uploadDocumentSelector();
  }

  checkRoute() {
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.listBreadcrumbs = this.getBreadcrumbs();
    });
  }

  getBreadcrumbs() {
    return [
      {
        name: CalculationType.Refund ? 'Add Document' : 'Upload File',
      },
    ];
  }

  initSelectDocumentFormGroup() {
    return this.fb.group({
      documentName: this.fb.control('', {
        validators: [Validators.required],
        asyncValidators: checkApiValidator(
          this.retirementBenefitDialogService.checkExits,
          'name',
          undefined,
          { params: { memberId: this.memberId } }
        ),
      }),
      documentType: this.fb.control('', [Validators.required]),
      file: this.fb.control(null, [Validators.required]),
    });
  }

  get documentTypeControl(): FormControl {
    return this.uploadDocumentForm.get('documentType') as FormControl;
  }

  get documentNameControl(): FormControl {
    return this.uploadDocumentForm.get('documentName') as FormControl;
  }

  get fileControl(): FormControl {
    return this.uploadDocumentForm.get('file') as FormControl;
  }

  validationBeforeSave() {
    const controls = this.uploadDocumentForm.controls;
    for (const control in controls) {
      if (!controls[control].valid) {
        if (control === 'file') {
          this.fileDocument.firstTouch = false;
        } else {
          this.uploadDocumentForm.get(control)?.markAllAsTouched();
        }
      }
    }
  }

  uploadFile(selectedFile?: File) {
    if (selectedFile) {
      this.file = selectedFile;
      this.uploadDate = new Date();
    }
  }

  uploadDocumentSelector() {
    this.retirementBenefitUploadDocumentStore
      .select(createRetirementBenefitUploadDocumentsSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((retirementBenefitDocument) => {
        if (retirementBenefitDocument && !retirementBenefitDocument?.isLoading) {
          this.isUploading = retirementBenefitDocument?.isLoading;
          if (retirementBenefitDocument?.success) {
            this.onCloseUploadDialog(ResponseStatus.Success);
          } else {
            this.onCloseUploadDialog(ResponseStatus.Error);
          }
        }
      });
  }

  onSaveUploadDocument() {
    if (!this.uploadDocumentForm.valid) {
      this.validationBeforeSave();
      return;
    }
    this.isUploading = true;
    const request: CreateRetirementBenefitUploadDocumentsRequest = {
      memberId: this.memberId,
      calculationType: this.calculationType,
      calculationBenefitId: this.calculationBenefitId,
      file: [
        {
          file: this.file,
          uploadDate: deepClone(this.uploadDate),
          documentName: this.documentNameControl.value,
          calculationDocumentType: this.documentTypeControl.value,
        },
      ],
    };
    this.retirementBenefitUploadDocumentStore.dispatch(
      createRetirementBenefitUploadDocumentsAction({ request })
    );
  }

  onCloseUploadDialog(event: string) {
    this.dialogRef.close({
      event: event
    });
    this.retirementBenefitUploadDocumentStore.dispatch(
      clearCreateRetirementBenefitUploadDocumentsStateAction()
    );
  }

  onCancelUploadDocument() {
    const ALERT_MESSAGE = 'Are you sure you want to cancel any/all changes?';
    const confirmDialog = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: ALERT_MESSAGE,
        type: ConfirmType.ConfirmWithDontShowAgain,
        title: 'Cancel Action',
        cancelButtonTitle: 'No',
      },
    });

    confirmDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.onCloseUploadDialog('close');
      }
    });
  }

  private setCalculationDocumentTypes() {
    switch (this.calculationType) {
      case CalculationType.Refund:
        this.calculationDocumentTypeOptions = this.calculationDocumentTypeOptions.filter(
          (item) => item.value === CalculationDocumentType.InvoiceVoucher,
        );
        break;
      case CalculationType.DisabilityLongTerm:
      case CalculationType.DisabilityShortTerm:
      case CalculationType.Disability:
        this.calculationDocumentTypeOptions = deepClone(DISABILITY_DOCUMENT_TYPE_OPTIONS);
        break;
      case CalculationType.RetirementBenefit:
        this.calculationDocumentTypeOptions = this.retirementBenefitDialogComponentService.getCalculationDocumentTypeList(
          this.layoutService.fundType as unknown as FundType,
        );
        break;
      default:
        break;
    }
  }
}
