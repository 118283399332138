<div class="group-aggregation" *ngFor="let group of aggregationDetails">
  <div class="group-title">
    <div class="title">{{group.label}}</div>
  </div>

    <div class="detail-aggregation">
      <div class="item"  *ngFor="let item of group.items">
        <span class="label">{{item.name}}</span>
        <ng-container *ngIf="item.aggregationType === 0; else otherType">
          <span class="value">{{ (item.value | numberDecimal) || '-' }}</span>
        </ng-container>
        <ng-template [ngSwitch]="item?.propertyType" #otherType>
          <span class="value" *ngSwitchCase="5">{{ (item.value | numberLocalDecimal:'$') || '-' }}</span>
          <span class="value" *ngSwitchCase="10">{{ (item.value | numberLocalDecimal:'') || '-' }}{{ (item.value | numberLocalDecimal:'') ? '%' : '' }}</span>
          <span class="value" *ngSwitchCase="7">{{ !item.value ? '-' : item.value | date: 'MM/dd/yyyy' }}</span>
          <span class="value" *ngSwitchCase="6">{{ (item.value | numberDecimal) || '-' }}</span>
          <span class="value" *ngSwitchCase="4">{{ (item.value | numberDecimal) || '-' }}</span>
          <span class="value" *ngSwitchCase="8">{{ (item.value | date: 'MM/dd/yyyy hh:mm a') || '-' }}</span>
          <span class="value" *ngSwitchDefault>{{ item.value || '-' }}</span>
        </ng-template>
      </div>
    </div>

</div>