<div class="property-config-container custom-class">
  <div class="row-button">
    <button (click)="onSubmit()" [disabled]="!canSubmit" class="submit-button" mat-raised-button type="button">
      Save
    </button>
    <button (click)="onCancel()" class="cancel-button" mat-raised-button type="button">Cancel</button>
  </div>

  <div class="content-container">
    <div class="box-container add-property-section w-1/2">
      <div class="box">
        <div class="title">Add Parameter</div>
        <div class="form-container">
          <ptg-select
            (changeOptionValue)="changeParameterType()"
            [controlField]="formData.get('type')"
            [isMultipleLineOption]="isMultipleLineOption"
            [isOptionObj]="true"
            [isSetMaxWidthSelect]="true"
            [listData]="parameterTypeOptions"
            [placeholder]="'Parameter Type'"
            panelClass="parameter-type-dropdown"
          >
          </ptg-select>
          <ng-container>
            <ptg-select
              (changeOptionValue)="changeParameterName()"
              [compareWithFunction]="compareWithFunction"
              [controlField]="formData.get('parameterName')"
              [isOptionObj]="true"
              [isSetMaxWidthSelect]="true"
              [listData]="parameterNameOptions"
              [placeholder]="'Parameter Name'"
              panelClass="input-name-dropdown"
              [isMultipleLineOption]="true"
              [isDisabledSelect]="isDisableField"
            >
            </ptg-select>
            <mat-error
              class="error-text parameter-name-errors"
              *ngIf="formData.get('parameterName')?.errors?.isInitiatedOrPendingApproval"
            >
              Unable to add new parameter as {{ initiatedOrPendingApprovalErrorMessage }} is already initiated.
            </mat-error>
            <ptg-textbox
              [controlField]="formData.get('label')"
              [hasLabel]="true"
              [maxLength]="labelMaxLength"
              [placeholder]="'Parameter Label'"
              customError="duplicatedValue"
            >
            </ptg-textbox>
            <ptg-select
              (changeOptionValue)="changeOverrideOnInput()"
              [compareWithFunction]="compareWithFunction"
              [controlField]="formData.get('overrideOnInput')"
              [isOptionObj]="true"
              [isSetMaxWidthSelect]="true"
              [listData]="overrideOnInputOptions"
              [placeholder]="'Override On Input'"
              [isRequired]="true"
              panelClass="override-on-input-dropdown"
              [isMultipleLineOption]="true"
              [isDisabledSelect]="isDisableField"
            >
            </ptg-select>

            <ptg-granulation-options
              *ngIf="isShowGranulation"
              [formControlType]="1"
              [controlField]="0"
              (valueChange)="changeGranulationValue($event)"
            ></ptg-granulation-options>
          </ng-container>
          <ptg-button (clickButton)="addParameter()" [buttonName]="'Add Parameter'" classInput="add-button">
            <mat-icon>add</mat-icon>
          </ptg-button>
        </div>
      </div>
    </div>
    <div class="box-container sort-property w-1/2">
      <div class="box">
        <div [ngClass]="{ 'no-data': !orderParameterDataTable?.length }" class="title">Order Parameter</div>
        <ptg-grid
          #sortPropertyTable
          (change)="onChangeOrderColumns()"
          (rowDrop)="changeItem($event, true)"
          (softDelete)="onSoftDeleteConfig($event)"
          [allowDragDrop]="true"
          [columns]="orderColumns"
          [data]="orderParameterDataTable"
          [fitToParent]="true"
          [hideHeader]="true"
          [inlineEditable]="true"
          [isLoading]="isLoading"
          [notFoundMessage]="''"
          [paginable]="false"
          [softDeletable]="true"
        >
          <ng-template [columnName]="'label'" cellContent let-row>
            <ng-container>
              <div class="multiple-line-text">
                <span class="caption truncate">{{ row.label }}</span>
                <span class="description truncate">{{ row.description }}</span>
              </div>
            </ng-container>
          </ng-template>
        </ptg-grid>
      </div>
    </div>
  </div>
</div>
