import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { AddEditParameterRequest, CheckExistParameterNameRequest, CheckExistResponse, GetAllEntityAndPropertyResponse, GetParameterDetailResponse, GetParameterListRequest, GetParameterListResponse } from './models';
import { SortType } from '@ptg-shared/constance';

@Injectable({
  providedIn: 'root',
})
export class ParameterService {
  constructor(private httpClient: HttpClient) {}
  getParameterList(
    request: GetParameterListRequest
  ): Observable<GetParameterListResponse> {
    let params = new HttpParams();
    if(request?.type !== undefined && request?.type !== null) {
      params = params.append('ParameterType', request.type)
    }
    if(request?.rule?.length) {
      params = params.append('ParameterRules', request.rule)
    }
    if(request?.name) {
      params = params.append('ParameterName', request.name)
    }
    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }
    if (request?.isUsedForCreateParameter) {
      params = params.append('IsUsedForCreateParameter', request.isUsedForCreateParameter);
    }
    if (request?.isUsedForCreateTemplate) {
      params = params.append('IsUsedForCreateTemplate', request.isUsedForCreateTemplate);
    }

    return this.httpClient.get<GetParameterListResponse>(
      `${environment.apiUrl}/template/parameters`, { params }
    );
  }

  checkParameterMappedTemplate = (parameterId: string) => {
    return this.httpClient.get(
      `${environment.apiUrl}/template/parameters/${parameterId}/mapping`
    );
  };

  removeParameter(parameterId: string) {
    return this.httpClient.delete(
      `${environment.apiUrl}/template/parameters/${parameterId}`
    );
  }
  
  getAllEntityAndProperty() {
    return this.httpClient.get<GetAllEntityAndPropertyResponse>(
      `${environment.apiUrl}/entity/parameters/entities`,
    );
  }

  checkExistParameterName = (checkExistTemplateNameRequest: CheckExistParameterNameRequest) => {
    let params = new HttpParams();

    if (checkExistTemplateNameRequest?.parameterId) {
      params = params.append('parameterId', checkExistTemplateNameRequest.parameterId);
    }
    if (checkExistTemplateNameRequest?.parameterName) {
      params = params.append('parameterName', checkExistTemplateNameRequest.parameterName);
    }

    return this.httpClient.get<CheckExistResponse>(
      `${environment.apiUrl}/template/parameters/name/existing`, { params }
    );
  }

  getParameterDetail(parameterId: string) {
    return this.httpClient.get<GetParameterDetailResponse>(
      `${environment.apiUrl}/template/parameters/${parameterId}`,
    );
  }

  addParameter(request: AddEditParameterRequest) {
    return this.httpClient.post(
      `${environment.apiUrl}/template/parameters`,
      request,
    );
  }

  editParameter(request: AddEditParameterRequest) {
    const parameterId = request.parameterId;
    return this.httpClient.put(
      `${environment.apiUrl}/template/parameters/${parameterId}`,
      request,
    );
  }
}
