import { createReducer, on } from "@ngrx/store";
import { ContributionInterestRateActions } from "../actions";
import { ContributionInterestRateList } from "@ptg-employer/models/contribution-interest-rate.model";
import { ACTION, ActionState, STATE } from "@ptg-shared/constance";

export const contributionInterestRateFeatureKey = 'contributionInterestRate';

export interface State {
  contributionInterestRate: {
    isLoading: boolean;
    contributionInterestRateList: ContributionInterestRateList;
  };
  actionState?: ActionState;
}

const initialState: State = {
  contributionInterestRate: {
    isLoading: true,
    contributionInterestRateList: {
      interestRates: [],
      total: 0
    }
  },
  actionState: undefined,
}

export const reducer = createReducer(
  initialState,
  on(ContributionInterestRateActions.getContributionInterestRateList, (state) => ({
    ...state,
    contributionInterestRate: {
      isLoading: true,
      contributionInterestRateList: {
        interestRates: [],
        total: 0
      }
    }
  })),
  on(ContributionInterestRateActions.getContributionInterestRateListSuccess, (state, { contributionInterestRateList }) => ({
    ...state,
    contributionInterestRate: {
      isLoading: false,
      contributionInterestRateList
    }
  })),
  on(ContributionInterestRateActions.getContributionInterestRateListFailure, (state, { error }) => ({
    ...state,
    contributionInterestRate: {
      isLoading: false,
      contributionInterestRateList: {
        interestRates: [],
        total: 0
      }
    }
  })),
  on(ContributionInterestRateActions.addInterestPeriod, (state, {}) => ({
    ...state,
    actionState: undefined
  })),
  on(ContributionInterestRateActions.addInterestPeriodSuccess, (state) => ({
    ...state,
    actionState: {
      action: ACTION.ADD,
      state: STATE.SUCCESS
    }
  })),
  on(ContributionInterestRateActions.addInterestPeriodFailure, (state, {}) => ({
    ...state,
    actionState: {
      action: ACTION.ADD,
      state: STATE.FAIL
    }
  })),
  on(ContributionInterestRateActions.updateInterestPeriod, (state, {}) => ({
    ...state,
    actionState: undefined
  })),
  on(ContributionInterestRateActions.updateInterestPeriodSuccess, (state) => ({
    ...state,
    actionState: {
      action: ACTION.EDIT,
      state: STATE.SUCCESS
    }
  })),
  on(ContributionInterestRateActions.updateInterestPeriodFailure, (state, {}) => ({
    ...state,
    actionState: {
      action: ACTION.EDIT,
      state: STATE.FAIL
    }
  })),
  on(ContributionInterestRateActions.clearContributionInterestRateState, (state) => ({
    ...state,
    actionState: undefined
  })),
)