import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { summary, warning, error } from '../../constance/listIcons.const';

@Component({
  selector: 'ptg-message-result',
  templateUrl: './message-result.component.html',
  styleUrls: ['./message-result.component.scss']
})
export class MessageResultComponent implements OnInit {
  @Input() summary: string[] = [];
  @Input() warnings: string[] = [];
  @Input() errors: string[] = [];
  @Input() status: string = '';

  constructor(
    iconRegistry: MatIconRegistry, sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIconLiteral('summary', sanitizer.bypassSecurityTrustHtml(summary));
    iconRegistry.addSvgIconLiteral('warning', sanitizer.bypassSecurityTrustHtml(warning));
    iconRegistry.addSvgIconLiteral('error', sanitizer.bypassSecurityTrustHtml(error));
  }

  ngOnInit(): void {
  }

}
