import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'ptg-view-rich-text-dialog',
  templateUrl: './view-rich-text-dialog.component.html',
  styleUrls: ['./view-rich-text-dialog.component.scss']
})
export class ViewRichTextDialogComponent implements OnInit {

  constructor(
    public sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<ViewRichTextDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      content: string
    }
  ) {
  }

  ngOnInit(): void {
  }

}
