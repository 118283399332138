import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import {
  clearCreateRetirementBenefitDetailUploadDocumentStateAction,
  createRetirementBenefitDetailUploadDocumentAction,
  createRetirementBenefitDetailUploadDocumentFailureAction,
  createRetirementBenefitDetailUploadDocumentSuccessAction,
} from '../actions';

export interface RetirementBenefitDetailUploadDocumentState {
  createRetirementBenefitDetailUploadDocument?: BaseActionState;
}

const initialState: RetirementBenefitDetailUploadDocumentState = {};

export const retirementBenefitDetailUploadDocumentReducer = createReducer(
  initialState,
  on(createRetirementBenefitDetailUploadDocumentAction, (state) => ({
    ...state,
    createRetirementBenefitDetailUploadDocument: {
      isLoading: true,
    },
  })),
  on(createRetirementBenefitDetailUploadDocumentSuccessAction, (state) => ({
    ...state,
    createRetirementBenefitDetailUploadDocument: {
      isLoading: false,
      success: true,
    },
  })),
  on(createRetirementBenefitDetailUploadDocumentFailureAction, (state, { error }) => ({
    ...state,
    createRetirementBenefitDetailUploadDocument: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearCreateRetirementBenefitDetailUploadDocumentStateAction, (state) => ({
    ...state,
    createRetirementBenefitDetailUploadDocument: undefined,
  }))
);