import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ptg-release-version',
  templateUrl: './release-version.component.html',
  styleUrls: ['./release-version.component.scss']
})
export class ReleaseVersionComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ReleaseVersionComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dataVersion: any,
    ) { }

  ngOnInit(): void {
  }

  onCancel() {
    this.dialogRef.close();
  }
}
