<div *ngIf="!this.isBucketsDialog" class="earning-container">
  <div class="control-bar">
    <span class="name"> Earnings </span>
    <ng-container *ngIf="earnings.length >= 1 && buckets.length > 1; else earningEmpty">
      <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      aria-label="Icon-button with a menu"
      class="group-button ignore-row"
      >
        <mat-icon class="ignore-row">more_horiz</mat-icon>
      </button>
      <mat-menu #menu="matMenu"> 
        <button
          *ngIf="
          !isHistory &&
          (!offCyclePayment ||
          (offCyclePayment &&
            offCyclePayment.paymentInfoType !== PaymentInfoTypeEnum.Reissue &&
            offCyclePayment.paymentStatus === OffCyclePaymentStatusTypeEnum.NotIssued))
          "
          mat-menu-item (click)="onClickEdit()">
          <mat-icon class="member-section-action-icon">edit</mat-icon>
          <span class="label-icon">Edit</span>
        </button>
        <button 
          mat-menu-item (click)="onClickManage()">
          <mat-icon class="member-section-action-icon">list_alt</mat-icon>
          <span class="label-icon">View Detail</span>
        </button>
      </mat-menu>
    </ng-container>
    <ng-template #earningEmpty>
      <div
        *ngIf="
        !isHistory &&
        (!offCyclePayment ||
        (offCyclePayment &&
          offCyclePayment.paymentInfoType !== PaymentInfoTypeEnum.Reissue &&
          offCyclePayment.paymentStatus === OffCyclePaymentStatusTypeEnum.NotIssued))
        "
        class="edit-btn"
        (click)="onClickEdit()"
        >
        <mat-icon>edit</mat-icon>
        <span class="title-edit"> Edit</span>
       </div>
    </ng-template>
  </div>  
          
  <div>
    <table class="earnings-table">
      <tr>
        <th></th>
        <th>Current</th>
        <th *ngIf="paymentInforConfig.EARNINGSYEARTODATE">YTD</th>
      </tr>

      <tr *ngFor="let ele of earnings" [hidden]="(offCyclePayment && offCyclePayment.paymentInfoType === PaymentInfoTypeEnum.Reissue) || !paymentInforConfig.EARNINGITEMS">
        <td class="label-col" *ngIf="showEarning || (!showEarning && ele.name != null && (ele.name.trim().toLowerCase() != 'cola' && ele.name.trim().toLowerCase() != 'annuity'))">
          <mat-icon
            *ngIf="ele.manualAdjustmented"
            matTooltip="Manually Adjusted"
            matTooltipClass="custom-tooltip"
            >star</mat-icon>
          <span
            class="label"
            [ngClass]="{
              'not-star-el': hasManualAdjusted && !ele.manualAdjustmented
            }"
          >
            {{ ele.name }}
          </span>
        </td>
        <td class="value-col-current" *ngIf="showEarning || (!showEarning && ele.name != null && (ele.name.trim().toLowerCase() != 'cola' && ele.name.trim().toLowerCase() != 'annuity'))">
          <span class="dollar-sign">$</span>
          {{ ele.value | numberDecimal : { decimal: 2 } }}
        </td>
        <td class="value-col-ytd" *ngIf="paymentInforConfig.EARNINGSYEARTODATE && (showEarning || (!showEarning && ele.name != null && (ele.name.trim().toLowerCase() != 'cola' && ele.name.trim().toLowerCase() != 'annuity')))">
          <span class="dollar-sign">$</span>
          {{ ele.ytd | numberDecimal : { decimal: 2 } }}
        </td>
      </tr>

      <tr [hidden]="(offCyclePayment && offCyclePayment.paymentInfoType === PaymentInfoTypeEnum.Reissue) || !paymentInforConfig.EARNINGITEMS">
        <td colspan="3">
          <div [ngClass]="{'line-break': earnings?.length}"></div>
        </td>
      </tr>

      <tr>
        <td class="label-col" [ngClass]="{'not-star': hasManualAdjusted}">Gross Payment</td>
        <td class="value-col-current">
          <span class="dollar-sign">$</span>
          {{ earningsInfo?.grossPayment | numberDecimal : { decimal: 2 } }}
        </td>
        <td class="value-col-ytd" *ngIf="paymentInforConfig.EARNINGSYEARTODATE">
          <span class="dollar-sign">$</span>
          {{ earningsInfo?.ytdGrossPayment| numberDecimal : { decimal: 2 } }}
        </td>
      </tr>
      <tr>
        <td class="label-col" [ngClass]="{'not-star': hasManualAdjusted}">Total Deductions</td>
        <td class="value-col-current">
          <span class="dollar-sign">$</span>
          {{ earningsInfo?.totalActiveDeductions | numberDecimal : { decimal: 2 } }}
        </td>
        <td class="value-col-ytd" *ngIf="paymentInforConfig.EARNINGSYEARTODATE">
          <span class="dollar-sign">$</span>
          {{ earningsInfo?.ytdTotalDeductions | numberDecimal : { decimal: 2 } }}
        </td>
      </tr>
      <tr>
        <td class="label-col" [ngClass]="{'not-star': hasManualAdjusted}">Net Payment</td>
        <td class="value-col-current-bold">
          <span class="dollar-sign">$</span>
          {{ earningsInfo?.netPayment | numberDecimal : { decimal: 2 } }}
        </td>
        <td class="value-col-ytd"  *ngIf="paymentInforConfig.EARNINGSYEARTODATE">
          <span class="dollar-sign">$</span>
          {{ earningsInfo?.ytdNetPayment | numberDecimal : { decimal: 2 } }}
        </td>
      </tr>
    </table>
  </div>
</div>
<div *ngIf="this.isBucketsDialog" class="earning-container">
  <div class="control-bar">
    <span class="name"> Funding Buckets </span>
  </div>  
          
  <div>
    <table class="earnings-table">
      <tr>
        <th></th>
        <th>Current</th>
        <th *ngIf="paymentInforConfig.EARNINGSYEARTODATE">YTD</th>
      </tr>

      <tr *ngFor="let ele of buckets">
        <td class="label-col not-star">
          <span
            class="label"
          >
            {{ ele.bucketName }}
          </span>
        </td>
        <td class="value-col-current">
          <span class="dollar-sign">$</span>
          {{ ele.value | numberDecimal : { decimal: 2 } }}
        </td>
        <td class="value-col-ytd">
          <span class="dollar-sign">$</span>
          {{ ele.ytd | numberDecimal : { decimal: 2 } }}
        </td>
      </tr>

      <tr>
        <td colspan="3">
          <div [ngClass]="{'line-break': buckets?.length}"></div>
        </td>
      </tr>

      <tr>
        <td class="label-col not-star">Gross Payment</td>
        <td class="value-col-current">
          <span class="dollar-sign">$</span>
          {{ earningsInfo?.grossPayment | numberDecimal : { decimal: 2 } }}
        </td>
        <td class="value-col-ytd" *ngIf="paymentInforConfig.EARNINGSYEARTODATE">
          <span class="dollar-sign">$</span>
          {{ earningsInfo?.ytdGrossPayment| numberDecimal : { decimal: 2 } }}
        </td>
      </tr>
    </table>
    <div class="dialog-footer">
      <button
        mat-raised-button
        type="button"
        style="background-color: #ffffff; color: #090909"
        (click)="onClose()"
      >
        Close
      </button>
    </div>
  </div>
</div>

