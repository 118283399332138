import { Component } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { SORT_TYPE } from '@ptg-shared/constance/value.const';
import { BaseComponent } from '@ptg-shared/components';
import { Align, Column, ColumnType } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import * as fromReducer from '@ptg-reducers';

import { ScreenType } from '../../types/enums';
import { LumpSumPaymentInformation } from '../../services/models';
import { getLumpSumPaymentHistoryAction } from '../../store/actions';
import { OffCyclePaymentHistoryState } from '../../store/reducers';
import { getLumpSumPaymentHistorySelector } from '../../store/selectors';

@Component({
  selector: 'ptg-lump-sum-payment-history-list',
  templateUrl: './lump-sum-payment-history-list.component.html',
  styleUrls: ['./lump-sum-payment-history-list.component.scss'],
})
export class LumpSumPaymentHistoryListComponent extends BaseComponent {
  columns: Column[] = [
    {
      name: 'none',
      truncate: true,
      width: '30px',
    },
    {
      name: 'depositDate',
      header: {
        title: 'Deposit Date',
      },
      sortable: true,
      truncate: true,
      type: ColumnType.DateTime,
      templateArgs: {
        format: 'MM/dd/yyyy',
      },
    },
    {
      name: 'totalPayee',
      header: {
        title: 'Total Payees',
      },
      align: Align.Right,
      sortable: true,
      truncate: true,
    },
    {
      name: 'totalServiceProvider',
      header: {
        title: 'Total Service Providers',
      },
      align: Align.Right,
      sortable: true,
      truncate: true,
    },
    {
      name: 'gross',
      header: {
        title: 'Gross',
      },
      sortable: true,
      type: ColumnType.Decimal,
      templateArgs: {
        decimal: 2,
        unit: '$',
        unitPosition: 'left',
      },
      truncate: true,
    },
  ];

  errorMsg: string = '';
  isLoading: boolean = true;
  totalRecords: number = 0;
  pageSize: number = 50;
  sort!: Sort;
  lumpSumPaymentData: LumpSumPaymentInformation[] = [];
  currentFund: any;
  pageName = 'ptg-lump-sum-payment-history-list';
  pageNumber = FIRST_PAGE;

  constructor(
    private store: Store<OffCyclePaymentHistoryState>,
    private fundStore: Store<fromReducer.State>,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.fundStore
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.pageSize = el.defaultPageSize ?? 50;
        this.currentFund = el;
      });
    this.pageSize =
      Number(
        sessionStorage.getItem(
          this.currentFund.key + '-' + this.pageName + '-pageSize'
        )
      ) === 0
        ? this.pageSize
        : Number(
            sessionStorage.getItem(
              this.currentFund.key + '-' + this.pageName + '-pageSize'
            )
          );
    this.getLumpSumPaymentHistoryList();
  }

  getLumpSumPaymentHistoryList(data?: PageEvent) {
    const query = {
      sortNames: this.sort?.active,
      sortType: (SORT_TYPE as any)?.[this.sort?.direction?.toUpperCase()],
      pageIndex: data?.pageNumber || this.pageNumber,
      pageSize: data?.pageSize || this.pageSize,
    };
    this.store.dispatch(getLumpSumPaymentHistoryAction({ query }));
    this.store
      .pipe(
        select(getLumpSumPaymentHistorySelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((lumpSumPaymentList) => {
        this.isLoading = lumpSumPaymentList.isLoading;
        this.lumpSumPaymentData =
          lumpSumPaymentList.data.offCyclePaymentDetailsHistory;
        this.totalRecords = lumpSumPaymentList.data.total;
      });
  }

  onChangePage(event: PageEvent) {
    sessionStorage.setItem(
      this.currentFund.key + '-' + this.pageName + '-pageSize',
      event.pageSize.toString()
    );
    sessionStorage.setItem(
      this.currentFund.key + '-' + this.pageName + '-pageNumber',
      event.pageNumber.toString()
    );
    this.getLumpSumPaymentHistoryList(event);
  }

  onChangeSort(sort: Sort) {
    this.sort = sort;
  }

  selectPayment(event: any) {
    this.router.navigateByUrl(
      `/processing/off-cycle-payments/complete/${event?.id}?prevScreen=${ScreenType.PaymentHistory}`
    );
  }
}
