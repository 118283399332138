import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'propertyList'
})
export class PropertyListPipe implements PipeTransform {

  transform(value: unknown, option: any[]): string {
    return option?.find(el => el.id === value)?.text;
  }

}
