import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import * as fromMember from '../../store/reducers';
import { LayoutActions } from '@ptg-shared/layout/actions';
import * as fromReducer from 'src/app/reducers';
import { EditNewMenuComponent } from '@ptg-member/components/edit-new-menu/edit-new-menu.component';
import { EditNewMenuItemComponent } from '@ptg-member/components/edit-new-menu-item/edit-new-menu-item.component';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ACTION_COLUMN, Align, Column, ReorderInfo } from '@ptg-shared/controls/grid';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ParticipantNavigationConfigurationAction } from '@ptg-member/store/actions';
import { Subject } from 'rxjs';
import { showBanner } from '@ptg-shared/utils/common.util';
import { ACTION, STATE } from '@ptg-shared/constance';
import { MenuItems } from '@ptg-member/types/models/participant-navigation-configuration.model';

@Component({
  selector: 'ptg-participant-navigation-configuration-detail',
  templateUrl: './participant-navigation-configuration-detail.component.html',
  styleUrls: ['./participant-navigation-configuration-detail.component.scss']
})
export class ParticipantNavigationConfigurationDetailComponent implements OnInit, OnDestroy {
  state!: any;
  dataTable: MenuItems[] = [];
  memberNavigation!: any;
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Participant List',
      url: '/member',
    },
    {
      name: 'Navigation Configuration',
      url: '/member/participant-navigation-configuration',
    },
    {
      name: '',
      url: '',
    },
  ];
  columns: Column[] = [
    {
      name: 'name',
      header: {
        title: 'Menu Item',
      },
      sortable: false,
      truncate: true,
    },
    {
      name: 'entityViewName',
      header: {
        title: 'View',
      },
      sortable: false,
      truncate: true,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      align: Align.Center,
      width: '170px',
    },
  ];
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  errorMsg?: string;
  isLoading: boolean = false;
  unsubscribe$ = new Subject<void>();
  paramsId: string = '';
  constructor(     
    private memberStore: Store<fromMember.MemberState>,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public router: Router,
    private store: Store<fromReducer.State>
    ) {
  }

  ngOnInit(): void {
    this.store.dispatch(LayoutActions.hiddenSideMenu());
    this.route.params.pipe().subscribe((params) => {
      this.paramsId = params.id;
      this.getDetail(this.paramsId);
    });
    this.memberStore
    .pipe(
      select(fromMember.selectParticipantNavigationConfiguration),
      takeUntil(this.unsubscribe$)
    )
    .subscribe((state) => {
      const navigationConfigurationDetail = state?.navigationConfigurationDetailResponse;
      if(navigationConfigurationDetail) {
        this.memberNavigation = navigationConfigurationDetail;
        this.listBreadcrumbs[this.listBreadcrumbs.length - 1].name = this.memberNavigation.name;
        this.dataTable = this.memberNavigation.menuItems;
        showBanner.call(this, navigationConfigurationDetail.updateMenuState || '', 'Menu', ACTION.EDIT);
        showBanner.call(
          this,
          navigationConfigurationDetail.createItemState || '',
          'Menu Item',
          ACTION.ADD
        );
        showBanner.call(
          this,
          navigationConfigurationDetail.updateItemState || '',
          'Menu Item',
          ACTION.EDIT
        );
        showBanner.call(
          this,
          navigationConfigurationDetail.removeItemState || '',
          'Menu Item',
          ACTION.REMOVE
        );
        if (
          navigationConfigurationDetail.updateMenuState == STATE.SUCCESS ||
          navigationConfigurationDetail.createItemState == STATE.SUCCESS ||
          navigationConfigurationDetail.updateItemState == STATE.SUCCESS ||
          navigationConfigurationDetail.removeItemState == STATE.SUCCESS
        ) {
          this.getDetail(this.paramsId); 
        }
        if (
          navigationConfigurationDetail.updateMenuState ||
          navigationConfigurationDetail.createItemState ||
          navigationConfigurationDetail.updateItemState ||
          navigationConfigurationDetail.removeItemState
        ) {
          this.memberStore.dispatch(
            ParticipantNavigationConfigurationAction.clearNavigationConfigurationState()
          );
        }
      }

    });  
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getDetail(menuId: string) {
    if (!menuId) {
      return;
    }
    const query = {
      id: menuId
    };

    this.memberStore.dispatch(
      ParticipantNavigationConfigurationAction.getNavigationConfigurationDetailRequest({query})
    );
  }

  onEditMenu() {
    if (!this.memberNavigation) {
      return;
    }
    this.dialog.open(EditNewMenuComponent, {
      panelClass: 'dialog-full-screen',
      autoFocus: false,
      disableClose: true,
      data: {
        memberNavigation: this.memberNavigation,
      },
    });
  }

  editMenuItem(memberNavigationItem?: MenuItems) {
    this.dialog.open(EditNewMenuItemComponent, {
      panelClass: ['edit-popup', 'edit-menu-item-popup'],
      autoFocus: false,
      disableClose: true,
      height: 'auto',
      maxWidth: '856px',
      width: '100%',
      data: {
        memberNavigation: this.memberNavigation,
        memberNavigationItem,
      },
    });
  }

  changeItemIndex(event: ReorderInfo) {
    const body = {
      entityNavigationId: this.memberNavigation?.id,
      upperAdjacentKey: event.upperAdjacentKey ? event.upperAdjacentKey : null,
      reorderingItemId: event.reorderItemKey,
    };
    this.memberStore.dispatch(
      ParticipantNavigationConfigurationAction.reorderNavigationConfiguration({body})
    );
  }

  removeRecord(memberNavigationItem: MenuItems) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        title: 'Remove Menu Item',
        text: `Are you sure you want to remove this Menu Item?`,
        type: ConfirmType.Delete,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const query = {
          id: memberNavigationItem.id,
        };
        this.memberStore.dispatch(
          ParticipantNavigationConfigurationAction.removeNavigationConfigurationItem(
            { query }
          )
        );
      }
    });
  }
}
