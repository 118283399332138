import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

import { MemberFilter } from '@ptg-member/types/models';
import {
  FilterPropertyType,
  Operator,
  OPERATOR_LABEL,
} from '@ptg-member/constance/member-list.const';

@Pipe({
  name: 'filterDisplay',
})
export class FilterDisplayPipe implements PipeTransform {
  PROPERTY_ITEM: Record<string, string> = {
    Prefix: 'Salutation',
    First: 'First Name',
    Middle: 'Middle Name',
    Last: 'Last Name',
    Suffix: 'Suffix',
    Street1: 'Street 1',
    Street2: 'Street 2',
    City: 'City',
    State: 'State',
    ZipCode: 'Zip Code',
    ZipPlus4: 'Zip+4',
  };

  transform(value: MemberFilter): string {
    const filter = JSON.parse(JSON.stringify(value)) as MemberFilter;
    if (filter.type === FilterPropertyType.Status) {
      let result = '';
      const statusName = value.values?.[0];
      const statusEvent = value.event;
      const statusDate = value.date;
      if (statusName) {
        result = `Status is ${statusName}`;
        if (statusEvent) {
          if (statusEvent.operator == Operator.NotEquals) {
            result += ` (with Status Event is not ${statusEvent.values[0]})`;
          }
          if (statusEvent.operator == Operator.Equals) {
            result += ` (${statusEvent.values[0]})`;
          }
        }
        if (statusDate) {
          switch (statusDate.operator) {
            case Operator.Between:
              result += ` from ${DateTime.fromISO(
                statusDate.values[0] as string
              ).toFormat('MM/dd/yyyy')} to ${DateTime.fromISO(
                statusDate.values[1] as string
              ).toFormat('MM/dd/yyyy')}`;
              break;
            case Operator.IsCurrentStatus:
              result += ` on ${DateTime.fromISO(
                statusDate.values[0] as string
              ).toFormat('MM/dd/yyyy')}`;
              break;
            case Operator.WithinLastDay:
              result += ` within last ${statusDate.values[0]} days`;
              break;
            case Operator.AtAnyPoint:
              result += ' at any point';
              break;
          }
        }
      }
      return result;
    }
    filter.selectedPropertyItem = `${
      filter.propertyName || filter.propertyKey
    }${filter.options ? ` - ${this.PROPERTY_ITEM[filter.options || '']}` : ''}`;
    if (filter.type === FilterPropertyType.Date) {
      filter.values = filter.values.map((item) =>
        DateTime.fromISO(item as string).toFormat('MM/dd/yyyy')
      );
    }
    let operatorName = OPERATOR_LABEL[Operator[filter.operator]];
    if (
      FilterPropertyType.Date === filter.type &&
      ['GreaterThan', 'LessThan'].includes(Operator[filter.operator])
    ) {
      operatorName = OPERATOR_LABEL['Date' + Operator[filter.operator]];
    }
    if (
      ['registered', 'IsLocked'].includes(filter.propertyKey) ||
      filter.type === FilterPropertyType.Boolean
    ) {
      return `${filter.selectedPropertyItem} ${operatorName} ${
        filter.values[0] ? 'Yes' : 'No'
      } `;
    }
    switch (filter.operator) {
      case Operator.Between: {
        return `${filter.selectedPropertyItem} ${operatorName} ${filter.values[0]} and ${filter.values[1]}`;
      }
      case Operator.IsEmpty:
      case Operator.IsNotEmpty: {
        return `${filter.selectedPropertyItem} ${operatorName}`;
      }
      default: {
        return `${filter.selectedPropertyItem} ${operatorName} ${filter.values[0]}`;
      }
    }
  }
}
