import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as ParticipantSearchConfigurationActions from '../../store/actions/participant-search-configuration.actions';
import {
  ParticipantSearchConfigurations
} from '../../types/models';
import { ParticipantSearchConfigurationService } from '../../services/participant-search-configuration.service';
import { ConfigurationsResponse, PropertyResponse } from '@ptg-member/types/models/participant-search-configuration.model';

@Injectable()
export class ParticipantSearchConfigurationEffects {
  constructor(
    private actions$: Actions,
    private participantSearchConfigurationService: ParticipantSearchConfigurationService
  ) {}

  getPropertyList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ParticipantSearchConfigurationActions.getParticipantSearchPropertyList
      ),
      switchMap(({entityId}) => {
        return this.participantSearchConfigurationService
          .getParticipantSearchPropertyList(entityId)
          .pipe(
            map((res: PropertyResponse) => {
              return ParticipantSearchConfigurationActions.getParticipantSearchPropertyListSuccess(
                { propertyConfigs: res?.propertyOfParticipants }
              );
            }),
            catchError((err) => {
              return of(
                ParticipantSearchConfigurationActions.getParticipantSearchPropertyListFailure(
                  { errorMsg: err.message }
                )
              );
            })
          );
      })
    )
  );

  getParticipantSearchConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ParticipantSearchConfigurationActions.getParticipantSearchConfiguration
      ),
      switchMap(() => {
        return this.participantSearchConfigurationService
          .getParticipantSearchConfiguration()
          .pipe(
            map((res: ParticipantSearchConfigurations) => {
              return ParticipantSearchConfigurationActions.getParticipantSearchConfigurationSuccess(
                { propertyDisplayConfigurations: res.listMemberSearchConfig }
              );
            }),
            catchError((err) => {
              return of(
                ParticipantSearchConfigurationActions.getParticipantSearchConfigurationFailure(
                  { errorMsg: err.message }
                )
              );
            })
          );
      })
    )
  );

  setParticipantSearchConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ParticipantSearchConfigurationActions.setParticipantSearchConfiguration
      ),
      switchMap(({ body, entityId }) => {
        return this.participantSearchConfigurationService
          .manageParticipantSearchConfiguration(body, entityId)
          .pipe(
            map(() => {
              return ParticipantSearchConfigurationActions.setParticipantSearchConfigurationSuccess();
            }),
            catchError((err) => {
              return of(
                ParticipantSearchConfigurationActions.setParticipantSearchConfigurationFailure(
                  { errorMsg: err.message }
                )
              );
            })
          );
      })
    )
  );

  getParticipantSearchConfigurationEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ParticipantSearchConfigurationActions.getParticipantSearchConfigurationEntity
      ),
      switchMap(({ entityId }) => {
        return this.participantSearchConfigurationService
          .getSearchConfiguration(entityId)
          .pipe(
            map((res: ConfigurationsResponse) => {
              return ParticipantSearchConfigurationActions.getParticipantSearchConfigurationEntitySuccess(
                { configurations: res.configurations }
              );
            }),
            catchError((err) => {
              return of(
                ParticipantSearchConfigurationActions.getParticipantSearchConfigurationEntityFailure(
                  { errorMsg: err.message }
                )
              );
            })
          );
      })
    )
  );
}
