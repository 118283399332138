import * as fromRoles from './roles.reducer';
import * as fromAccessAccounts from './access-accounts.reducer';
import * as fromRoot from '../../reducers'
import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

export const accessMngModuleFeatureKey = 'accessMng';

export interface AccessMngState {
  [fromRoles.RoleFeatureKey]: fromRoles.State;
  [fromAccessAccounts.AccessAccountsFeatureKey]: fromAccessAccounts.State;
}

export interface State extends fromRoot.State {
  [accessMngModuleFeatureKey]: AccessMngState;
}

export function reducers(state: AccessMngState | undefined, action: Action) {
  return combineReducers({
    [fromRoles.RoleFeatureKey]: fromRoles.reducer,
    [fromAccessAccounts.AccessAccountsFeatureKey]: fromAccessAccounts.reducer
  })(state, action);
}

export const selectAccessMngModuleState = createFeatureSelector<AccessMngState>(
  accessMngModuleFeatureKey
);

export const selectRolesState = createSelector(
  selectAccessMngModuleState,
  (state) => state[fromRoles.RoleFeatureKey]
);

export const selectRolesByPortalTypeState = createSelector(
  selectAccessMngModuleState,
  (state) => state[fromRoles.RoleFeatureKey]
);

export const selectAccessAccountsState = createSelector(
  selectAccessMngModuleState,
  (state) => state[fromAccessAccounts.AccessAccountsFeatureKey]
);

export const selectRolePermissionState = createSelector(
  selectRolesState,
  (state) => state.clientModules
);
