import { createAction, props } from '@ngrx/store';

import {
  CreateCalculationLoddBenefitsRequest, CreateCalculationLoddBenefitsResponse, LoddValidationBeforeRecalculateRequest, LoddValidationBeforeRecalculateResponse,
} from '../../services/models';

export const createCalculationLoddBenefitsAction = createAction(
  '[CreateCalculationLoddBenefits/API] Send Request',
  props<{ request: CreateCalculationLoddBenefitsRequest }>()
);

export const createCalculationLoddBenefitsSuccessAction = createAction(
  '[CreateCalculationLoddBenefits/API] Success',
  props<{ response: CreateCalculationLoddBenefitsResponse }>(),
);

export const createCalculationLoddBenefitsFailureAction = createAction(
  '[CreateCalculationLoddBenefits/API] Failure',
  props<{ error?: any }>()
);

export const clearCreateCalculationLoddBenefitsStateAction = createAction(
  '[CreateCalculationLoddBenefits] Clear'
);

// getLoddValidationBeforeRecalculatesAction

export const getLoddValidationBeforeRecalculatesAction = createAction(
  '[GetLoddValidationBeforeRecalculates/API] Send Request',
  props<{ request: LoddValidationBeforeRecalculateRequest }>()
);

export const getLoddValidationBeforeRecalculatesSuccessAction = createAction(
  '[GetLoddValidationBeforeRecalculates/API] Success',
  props<{ response: LoddValidationBeforeRecalculateResponse }>()
);

export const getLoddValidationBeforeRecalculatesFailureAction = createAction(
  '[GetLoddValidationBeforeRecalculates/API] Failure',
  props<{ error?: any }>()
);

export const clearGetLoddValidationBeforeRecalculatesStateAction = createAction(
  '[GetLoddValidationBeforeRecalculates] Clear'
);


