<ptg-dialog
  class="update-entity-dialog"
  [title]="'Edit General Information'"
  [footerPosition]="'bottom'"
  [footerAlign]="'right'"
  [isFullScreen]="false"
>
  <ng-template dialogBody>
    <form
      *ngIf="formGroup"
      id="formData"
      [formGroup]="formGroup"
    >
      <div class="flex gap-2 group-control">
        <ptg-textbox
          [controlField]="nameCtrl"
          [isRequired]="true"
          placeholder="Entity Name"
          [hasLabel]="true"
          [errorAsync]="'Entity Name already exists.'"
          [maxLength]="250"
        ></ptg-textbox>
        <ptg-textbox
          [controlField]="codeCtrl"
          [isRequired]="true"
          placeholder="Entity Code"
          [hasLabel]="true"
          [errorAsync]="'Entity Code already exists.'"
          [maxLength]="250"
        ></ptg-textbox>
      </div>
      <div class="flex gap-2 group-control">
        <ptg-select *ngIf="!data.entity.parentId && data.entity.type !== ALL_ENTITY_TYPE.BaseEntity"
          [controlField]="parentCtrl"
          [listData]="parentEntityOptions"
          [isOptionObj]="true"
          placeholder="Parent Entity"
          [hasNoneValue]="true"
          [isSetMaxWidthSelect]="true"
        ></ptg-select>
        <div class="detail" *ngIf="data.entity.parentName || data.entity.type === ALL_ENTITY_TYPE.BaseEntity">
          <span class="label">Parent Entity</span>
          <span class="value truncate">{{ data.entity.parentName || "-"}}</span>
        </div>
        <div class="detail">
          <span class="label">Enable Versioning</span>
          <span class="value truncate">{{ data.entity.isEnableVersioning ? "Yes" : "No" || "-"}}</span>
        </div>
      </div>
      <div class="flex gap-2 group-control">
        <ptg-select
          [controlField]="entityKeyCtrl"
          [listData]="entityKeyCandidates"
          [isOptionObj]="true"
          placeholder="Entity Key"
          [hasNoneValue]="true"
          [isSetMaxWidthSelect]="true"
        ></ptg-select>
        <ptg-select
          [controlField]="displayFieldCtrl"
          [listData]="entityDisplayFieldCandidates"
          [isOptionObj]="true"
          placeholder="Display Field"
          [hasNoneValue]="true"
          [isSetMaxWidthSelect]="true"
        ></ptg-select>
      </div>
      <ptg-input-area 
        [controlField]="descriptionCtrl"
        placeholder="Description"
        [hasLabel]="true">
      </ptg-input-area>
    </form>
  </ng-template>

  <ng-template dialogFooter>
    <button
      mat-raised-button
      type="button"
      class="submit"
      (click)="onClickSaveData()"
    >
      Save
    </button>

    <button mat-raised-button type="button" class="cancel" (click)="onCancel()">
      Cancel
    </button>
  </ng-template>
</ptg-dialog>
