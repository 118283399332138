import { createAction, props } from '@ngrx/store';
import {
  GetListAddressHistoryQuery,
  AddressHistoryList,
  PayloadAddress
} from '../../types/models';
import { generateActionName } from '@ptg-shared/utils/common.util';
import { InitiationProperty } from '@ptg-entity-management/services/models';

export const ClearAddressHistoryState = '[ClearAddressHistoryState]';

export const getAddressHistoryList = createAction(
  generateActionName('AddressHistoryList/API').Send,
  props<{ query: GetListAddressHistoryQuery, entityId: string }>()
);
export const getListSuccess = createAction(
  generateActionName('AddressHistoryList/API').Success,
  props<{ addressHistoryResult: AddressHistoryList }>()
);
export const getListFailure = createAction(
  generateActionName('AddressHistoryList/API').Failure
);

export const getAddressProperties = createAction(
  generateActionName('AddressProperties/API').Send,
  props<{ entityId: string }>()
);
export const getAddressPropertiesSuccess = createAction(
  generateActionName('AddressProperties/API').Success,
  props<{ properties: InitiationProperty[]}>()
);
export const getAddressPropertiesFailure = createAction(
  generateActionName('AddressProperties/API').Failure
);

export const createAddress = createAction(
  generateActionName('CreateAddress/API').Send,
  props<{ body: PayloadAddress }>()
);
export const createAddressSuccess = createAction(
  generateActionName('CreateAddress/API').Success,
);
export const createAddressFailure = createAction(
  generateActionName('CreateAddress/API').Failure
);

export const updateAddress = createAction(
  generateActionName('UpdateAddress/API').Send,
  props<{ body: PayloadAddress }>()
);
export const updateAddressSuccess = createAction(
  generateActionName('UpdateAddress/API').Success,
);
export const updateAddressFailure = createAction(
  generateActionName('UpdateAddress/API').Failure
);

export const clearAddressHistoryState = createAction(
  generateActionName('AddressHistory/API').Clear
);

export const checkExitAddress = createAction(
  generateActionName('CheckExitAddress/API').Send,
  props<{ body: any }>()
);
export const checkExitAddressSuccess = createAction(
  generateActionName('CheckExitAddress/API').Success,
  props<{ messages: any}>()
);
export const checkExitAddressFailure = createAction(
  generateActionName('CheckExitAddress/API').Failure
);