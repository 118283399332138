import { createReducer, on } from '@ngrx/store';
import { BaseActionState } from '@ptg-shared/types/models';
import { ActionState } from '@ptg-shared/types/models/common.model';
import { GetSubHeaderConfigurationsListResponse, GetSubHeaderDataResponse, SubHeaderConfigurationDetailResponse } from '../../services/models/subheader-configuration.model';
import { getSubHeaderConfigurationsListAction, getSubHeaderConfigurationsListSuccessAction, getSubHeaderConfigurationsListFailureAction, clearGetSubHeaderConfigurationsListStateAction, clearGetSubHeaderConfigurationDetailStateAction, clearSetSubHeaderConfigurationDetailStateAction, getSubHeaderConfigurationDetailAction, getSubHeaderConfigurationDetailFailureAction, getSubHeaderConfigurationDetailSuccessAction, setSubHeaderConfigurationDetailAction, setSubHeaderConfigurationDetailFailureAction, setSubHeaderConfigurationDetailSuccessAction, clearGetSubHeaderDataStateAction, getSubHeaderDataAction, getSubHeaderDataFailureAction, getSubHeaderDataSuccessAction } from '../actions';
import { ACTION, STATE } from '@ptg-shared/constance/value.const';

export interface SubHeaderConfigurationState {
  getSubHeaderConfigurationsListState?: BaseActionState<GetSubHeaderConfigurationsListResponse>;
  getSubHeaderConfigurationDetailState?: BaseActionState<SubHeaderConfigurationDetailResponse>;
  setSubHeaderConfigurationDetailState?: {
    state: ActionState,
    errorMsg?: string
  };
  getSubHeaderDataState?: BaseActionState<GetSubHeaderDataResponse>;
}

const initialState: SubHeaderConfigurationState = {};

export const subHeaderConfigurationReducer = createReducer(
  initialState,

  // Get Sub-Header Configuration List
  on(getSubHeaderConfigurationsListAction, (state) => ({
    ...state,
    getSubHeaderConfigurationsListState: {
      isLoading: true,
    },
  })),
  on(getSubHeaderConfigurationsListSuccessAction, (state, { response }) => ({
    ...state,
    getSubHeaderConfigurationsListState: {
      isLoading: false,
      success: true,
      payload: response,
      total: response.total,
    },
  })),
  on(getSubHeaderConfigurationsListFailureAction, (state, { error }) => ({
    ...state,
    getSubHeaderConfigurationsListState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetSubHeaderConfigurationsListStateAction, (state) => ({
    ...state,
    getSubHeaderConfigurationsListState: undefined,
  })),

  // Get Sub-Header Configuration Detail
  on(getSubHeaderConfigurationDetailAction, (state) => ({
    ...state,
    getSubHeaderConfigurationDetailState: {
      isLoading: true,
    },
  })),
  on(getSubHeaderConfigurationDetailSuccessAction, (state, { response }) => ({
    ...state,
    getSubHeaderConfigurationDetailState: {
      isLoading: false,
      success: true,
      payload: response,
      total: response.total,
    },
  })),
  on(getSubHeaderConfigurationDetailFailureAction, (state, { error }) => ({
    ...state,
    getSubHeaderConfigurationDetailState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetSubHeaderConfigurationDetailStateAction, (state) => ({
    ...state,
    getSubHeaderConfigurationDetailState: undefined,
  })),

  // Config Sub-Header Configuration
  on(setSubHeaderConfigurationDetailAction, (state) => ({
    ...state,
    isLoading: false,
    setSubHeaderConfigurationDetailState: undefined,
  })),
  on(setSubHeaderConfigurationDetailSuccessAction, (state, { }) => ({
    ...state,
    isLoading: false,
    setSubHeaderConfigurationDetailState: {
      state: {
        action: ACTION.EDIT,
        state: STATE.SUCCESS,
      }
    },
  })),
  on(setSubHeaderConfigurationDetailFailureAction, (state, { errorMsg }) => ({
    ...state,
    setSubHeaderConfigurationDetailState: {
      state: {
        action: ACTION.EDIT,
        state: STATE.FAIL,
      },
      errorMsg: errorMsg,
    },
  })),
  on(clearSetSubHeaderConfigurationDetailStateAction, (state) => ({
    ...state,
    isLoading: false,
    setSubHeaderConfigurationDetailState: undefined,
  })),

  // Get Sub-Header Data
  on(getSubHeaderDataAction, (state) => ({
    ...state,
    getSubHeaderDataState: {
      isLoading: true,
    },
  })),
  on(getSubHeaderDataSuccessAction, (state, { response }) => ({
    ...state,
    getSubHeaderDataState: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getSubHeaderDataFailureAction, (state, { error }) => ({
    ...state,
    getSubHeaderDataState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetSubHeaderDataStateAction, (state) => ({
    ...state,
    getSubHeaderDataState: undefined,
  })),
);
