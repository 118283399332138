export enum DisplaySectionWODataType {
  TitleOnly = 0,
  TitleAndMessage = 1,
  TitleAndProperties_IfAny = 2,
}

export enum SideType {
  Left = 0,
  Right = 1,
}

export enum PROPERTY_DISPLAY_PREFERENCE_TYPE {
  Hide,
  DisplayLabelOnly,
  DisplayMessage
}

export enum LIST_DISPLAY_PREFERENCE_TYPE {
  Hide,
  DisplayColumnHeaders,
  DisplayColumnHeadersAndMessage
}

export enum NON_VALUE_PROPERTY_DISPLAY_TYPE {
  Hide,
  DisplayLabelOnly
}

export enum NON_RECORD_LIST_DISPLAY_TYPE {
  Hide,
  DisplayColumnHeaders,
  DisplayColumnHeadersAndMessage
}
