import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as MetadataActions from '../../store/actions/metadata.actions';
import { MetadataView } from '../../types/models/metadata.model';
import { MetadataService } from '../../services/metadata.service';

@Injectable()
export class MetadataEffects {
  getMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MetadataActions.getMetadata),
      switchMap(({ query }) => {
        return this.metadataService.getMetadata(query).pipe(
          map((res: MetadataView) => {
            let metadata = res?.metadata;
            return MetadataActions.getMetadataSuccess({ metadata });
          }),
          catchError((error) => {
            return of(MetadataActions.getMetadataFailure({ error }));
          })
        );
      })
    )
  );

  orderTable$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MetadataActions.orderTable),
        switchMap(({ body }) => {
          return this.metadataService.orderTable(body);
        })
      ),
    { dispatch: false }
  );

  addSection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MetadataActions.addSection),
      switchMap(({ body, isContinue }) => {
        return this.metadataService.addSection(body).pipe(
          map((response) => {
            return MetadataActions.addSectionSuccess({
              isContinue,
              newSectionKey: response as string,
            });
          }),
          catchError((err) => {
            return of(
              MetadataActions.addSectionFailure({ errorMsg: err.message })
            );
          })
        );
      })
    )
  );

  removeSectionMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MetadataActions.removeSectionMetadata),
      switchMap((action) => {
        return this.metadataService.removeSection(action.metadataKey).pipe(
          map(() => {
            return MetadataActions.removeSectionMetadataSuccess();
          }),
          catchError((err) => {
            return of(
              MetadataActions.removeSectionMetaFailure({
                errorMsg: err.message,
              })
            );
          })
        );
      })
    )
  );

  

  constructor(
    private actions$: Actions,
    private metadataService: MetadataService
  ) {}
}
