import { createReducer, on } from '@ngrx/store';

import { STATE } from '@ptg-shared/constance/value.const';
import { reorderItems } from '@ptg-shared/utils/common.util';
import { BaseActionState } from '@ptg-shared/types/models';

import * as ProfileNavigationConfigurationAction from '../actions/profile-navigation-configuration.action';
import {
  MemberNavigationList,
  MemberNavigation,
  Status,
  Section,
  NonListProperty,
  NonListItemConfig,
} from '../../types/models';

export const profileNavigationConfigurationFeatureKey =
  'profileNavigationConfiguration';
export interface State {
  isLoading: boolean;
  memberNavigations: MemberNavigationList;
  orderState?: string;
  createMenuState?: string;
  removeMenuState?: string;
  memberNavigationDetailState: {
    isLoading: boolean;
    memberNavigationDetail: MemberNavigation;
    listAllStatus: Status[];
    updateMenuState?: string;
    orderItemState?: string;
    createItemState?: string;
    updateItemState?: string;
    removeItemState?: string;
    error?: any;
  };
  listSection: Section[];
  error?: any;
  getNonListProperties?: BaseActionState<NonListProperty[]>;
  getNonListItemConfigs?: BaseActionState<NonListItemConfig[]>;
}

const initialState: State = {
  isLoading: true,
  memberNavigations: {
    memberNavigationMenu: [],
    listStatus: [],
  },
  orderState: '',
  createMenuState: '',
  removeMenuState: '',
  memberNavigationDetailState: {
    isLoading: false,
    memberNavigationDetail: {} as MemberNavigation,
    listAllStatus: [],
    updateMenuState: '',
    orderItemState: '',
    createItemState: '',
    updateItemState: '',
    removeItemState: '',
    error: undefined,
  },
  listSection: [],
  error: undefined,
};

export const reducer = createReducer(
  initialState,
  on(
    ProfileNavigationConfigurationAction.getMemberNavigationList,
    (state, {}) => ({
      ...state,
      isLoading: true,
      memberNavigations: {
        memberNavigationMenu: [],
        listStatus: [],
      },
      error: undefined,
    })
  ),
  on(
    ProfileNavigationConfigurationAction.getMemberNavigationListSuccess,
    (state, { memberNavigations }) => ({
      ...state,
      isLoading: false,
      memberNavigations,
      error: undefined,
    })
  ),
  on(
    ProfileNavigationConfigurationAction.getMemberNavigationListFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      memberNavigations: {
        memberNavigationMenu: [],
        listStatus: [],
      },
      error: error,
    })
  ),
  on(
    ProfileNavigationConfigurationAction.reorderMemberNavigationList,
    (state, { body }) => {
      let tmp: MemberNavigationList = JSON.parse(
        JSON.stringify(state.memberNavigations)
      );
      tmp.memberNavigationMenu = reorderItems(
        tmp.memberNavigationMenu,
        body.reorderPropertyKey,
        body.upperAdjacentKey || undefined
      );
      return {
        ...state,
        memberNavigations: tmp,
      };
    }
  ),
  on(
    ProfileNavigationConfigurationAction.reorderMemberNavigationListSuccess,
    (state) => ({
      ...state,
      orderState: STATE.SUCCESS,
    })
  ),
  on(
    ProfileNavigationConfigurationAction.reorderMemberNavigationListFailure,
    (state: State, { errorMsg }) => ({
      ...state,
      orderState: STATE.FAIL,
    })
  ),
  on(
    ProfileNavigationConfigurationAction.clearStateMemberNavigation,
    (state: State) => ({
      ...state,
      orderState: '',
      createMenuState: '',
      removeMenuState: '',
      memberNavigationDetailState: {
        ...state.memberNavigationDetailState,
        updateMenuState: '',
        orderItemState: '',
        createItemState: '',
        updateItemState: '',
        removeItemState: '',
        error: undefined,
      },
    })
  ),
  on(
    ProfileNavigationConfigurationAction.createMemberNavigationSuccess,
    (state) => ({
      ...state,
      createMenuState: STATE.SUCCESS,
    })
  ),
  on(
    ProfileNavigationConfigurationAction.createMemberNavigationFailure,
    (state) => ({
      ...state,
      createMenuState: STATE.FAIL,
    })
  ),
  on(
    ProfileNavigationConfigurationAction.removeMemberNavigationSuccess,
    (state) => ({
      ...state,
      removeMenuState: STATE.SUCCESS,
    })
  ),
  on(
    ProfileNavigationConfigurationAction.removeMemberNavigationFailure,
    (state) => ({
      ...state,
      removeMenuState: STATE.FAIL,
    })
  ),
  on(
    ProfileNavigationConfigurationAction.getMemberNavigationDetail,
    (state, {}) => ({
      ...state,
      memberNavigationDetailState: {
        isLoading: true,
        memberNavigationDetail: {} as MemberNavigation,
        listAllStatus: [],
        error: undefined,
      },
    })
  ),
  on(
    ProfileNavigationConfigurationAction.memberNavigationDetailSuccess,
    (state, { memberNavigation, listAllStatus }) => ({
      ...state,
      memberNavigationDetailState: {
        isLoading: false,
        memberNavigationDetail: memberNavigation,
        listAllStatus,
        error: undefined,
      },
    })
  ),
  on(
    ProfileNavigationConfigurationAction.memberNavigationDetailFailure,
    (state, { error }) => ({
      ...state,
      memberNavigationDetailState: {
        isLoading: false,
        memberNavigationDetail:
          state.memberNavigationDetailState.memberNavigationDetail,
        listAllStatus: state.memberNavigationDetailState.listAllStatus,
        error: error,
      },
    })
  ),
  on(
    ProfileNavigationConfigurationAction.reorderMemberNavigationItem,
    (state, { body }) => {
      let tmp = JSON.parse(JSON.stringify(state.memberNavigationDetailState));
      tmp.memberNavigationDetail.menuItems = reorderItems(
        tmp.memberNavigationDetail.menuItems,
        body.reorderItemId,
        body.upperAdjacentId || undefined
      );
      return {
        ...state,
        memberNavigationDetailState: tmp,
      };
    }
  ),
  on(
    ProfileNavigationConfigurationAction.reorderMemberNavigationItemSuccess,
    (state) => ({
      ...state,
      memberNavigationDetailState: {
        ...state.memberNavigationDetailState,
        orderItemState: STATE.SUCCESS,
        error: undefined,
      },
    })
  ),
  on(
    ProfileNavigationConfigurationAction.reorderMemberNavigationItemFailure,
    (state, { error }) => ({
      ...state,
      memberNavigationDetailState: {
        ...state.memberNavigationDetailState,
        orderItemState: STATE.FAIL,
        error: error,
      },
    })
  ),
  on(
    ProfileNavigationConfigurationAction.editMemberNavigationSuccess,
    (state) => ({
      ...state,
      memberNavigationDetailState: {
        ...state.memberNavigationDetailState,
        updateMenuState: STATE.SUCCESS,
        error: undefined,
      },
    })
  ),
  on(
    ProfileNavigationConfigurationAction.editMemberNavigationFailure,
    (state, { error }) => ({
      ...state,
      memberNavigationDetailState: {
        ...state.memberNavigationDetailState,
        updateMenuState: STATE.FAIL,
        error: error,
      },
    })
  ),
  on(
    ProfileNavigationConfigurationAction.createMemberNavigationItemSuccess,
    (state) => ({
      ...state,
      memberNavigationDetailState: {
        ...state.memberNavigationDetailState,
        createItemState: STATE.SUCCESS,
        error: undefined,
      },
    })
  ),
  on(
    ProfileNavigationConfigurationAction.createMemberNavigationItemFailure,
    (state, { error }) => ({
      ...state,
      memberNavigationDetailState: {
        ...state.memberNavigationDetailState,
        createItemState: STATE.FAIL,
        error: error,
      },
    })
  ),
  on(
    ProfileNavigationConfigurationAction.editMemberNavigationItemSuccess,
    (state) => ({
      ...state,
      memberNavigationDetailState: {
        ...state.memberNavigationDetailState,
        updateItemState: STATE.SUCCESS,
        error: undefined,
      },
    })
  ),
  on(
    ProfileNavigationConfigurationAction.editMemberNavigationItemFailure,
    (state, { error }) => ({
      ...state,
      memberNavigationDetailState: {
        ...state.memberNavigationDetailState,
        updateItemState: STATE.FAIL,
        error: error,
      },
    })
  ),
  on(
    ProfileNavigationConfigurationAction.removeMemberNavigationItemSuccess,
    (state) => ({
      ...state,
      memberNavigationDetailState: {
        ...state.memberNavigationDetailState,
        removeItemState: STATE.SUCCESS,
        error: undefined,
      },
    })
  ),
  on(
    ProfileNavigationConfigurationAction.removeMemberNavigationItemFailure,
    (state, { error }) => ({
      ...state,
      memberNavigationDetailState: {
        ...state.memberNavigationDetailState,
        removeItemState: STATE.FAIL,
        error: error,
      },
    })
  ),
  on(
    ProfileNavigationConfigurationAction.getSectionListSuccess,
    (state, { listSection }) => ({
      ...state,
      listSection,
    })
  ),
  on(ProfileNavigationConfigurationAction.getSectionListFailure, (state) => ({
    ...state,
    listSection: [],
  }))
);
