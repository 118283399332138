import { Pipe, PipeTransform } from '@angular/core';
import { Calculation } from '@ptg-entity-management/services/models';

@Pipe({
  name: 'calculationName'
})
export class CalculationNamePipe implements PipeTransform {

  transform(value: string, args: Calculation[]): string {
    if (!value) {
      return '-';
    }
    const checkValue = args.find((item) => item.id === value);
    return checkValue ? checkValue?.name : '-';
  }

}
