import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';

import { Sort } from '@angular/material/sort';
import { BaseComponent } from '@ptg-shared/components';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ACTION_COLUMN, Column, ColumnType, EXPAND_COLLAPSE_COLUMN, Row } from '@ptg-shared/controls/grid';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import * as fromMember from '../../store/reducers';
import * as fromReducer from '@ptg-reducers';
import { DistributionMappingAction } from '@ptg-member/store/actions';
import { distributionMappingSelector } from '@ptg-member/store/selectors';
import { ActivatedRoute, Router } from '@angular/router';
import { GetHeaderListRequest } from '@ptg-member/types/models';
import { ACTION, SORT_TYPE } from '@ptg-shared/constance';
import { EditDistributionMappingComponent } from '@ptg-member/components/edit-distribution-mapping/edit-distribution-mapping.component';
import { takeUntil } from 'rxjs/operators';
import { ICON_STATUS_FIELDS } from '@ptg-member/constance/member-list.const';
import { DistributionDetail } from '@ptg-member/types/models/distribution-mapping.model';
import { showBanner } from '@ptg-shared/utils/common.util';

@Component({
  selector: 'ptg-distribution-code-mapping',
  templateUrl: './distribution-code-mapping.component.html',
  styleUrls: ['./distribution-code-mapping.component.scss']
})
export class DistributionCodeMappingComponent extends BaseComponent implements OnInit {
  typeName = '';
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: '1099-R Info',
      url: '',
    },
    {
      name: 'Distribution Code Mapping',
    },
  ];

  columns: Column[] = [
    {
      name: EXPAND_COLLAPSE_COLUMN,
      type: ColumnType.Icon,
      templateArgs: { nameField: ICON_STATUS_FIELDS.statusIconName, colorField: ICON_STATUS_FIELDS.statusIconColor, labelField: ICON_STATUS_FIELDS.statusIconLabel},
      header: {
        title: '',
        style: {
          'width': '60px'
        }
      },
      sortable: false,
      width: '60px'
    },
    {
      name: 'benefitType',
      header: {
        title: 'Benefit Type',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'benefitPeriod',
      header: {
        title: '59 1/2',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: 'distributionCode1',
      header: {
        title: 'Distribution Code 1',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'distributionCode2',
      header: {
        title: 'Distribution Code 2',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'modifiedAt',
      header: {
        title: 'Modified At',
      },
      truncate: true,
      sortable: true,
      type: ColumnType.DateTime,
      templateArgs: {
        format: 'MM/dd/yyyy hh:mm a',
      },
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      width: '300px',
    },
  ];

  sortInfo?: Sort;
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  crrHeaderDeleteID?: string | null;

  dataSource: any[] = [];
  isLoading: boolean = true;

  constructor(
    private memberStore: Store<fromMember.MemberState>,
    public dialog: MatDialog,
    public router: Router,
    private store: Store<fromReducer.State>,
    public route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.route?.queryParams.subscribe((queryParams) => {
      this.listBreadcrumbs[0] = {name: queryParams?.prevName, url: queryParams?.prevUrl};
    });

    this.memberStore.pipe(
      select(distributionMappingSelector),
      takeUntil(this.unsubscribe$)
    ).subscribe(el => {
      this.dataSource = JSON.parse(JSON.stringify(el.getDataList?.payload ?? []));
      this.isLoading = !!el.getDataList?.isLoading;
      this.dataSource = this.dataSource.map((item) => {
        return {
          ...item,
          isDisabledExpandRow: item.distributionCode[0].benefitPeriod === 'N/A',
          benefitPeriod: item.distributionCode[0].benefitPeriod === 'N/A' ? '-' : '',
          detail: item.distributionCode[0].benefitPeriod === 'N/A' ? [] : item.distributionCode,
          distributionCode1: item.distributionCode[0].benefitPeriod === 'N/A' ? item.distributionCode[0].distributionCode1 : '',
          distributionCode2: item.distributionCode[0].benefitPeriod === 'N/A' ? item.distributionCode[0].distributionCode2 : '',
          [ICON_STATUS_FIELDS.statusIconName]: item.distributionCode[0].benefitPeriod === 'N/A' ? "" : "keyboard_arrow_down",
          [ICON_STATUS_FIELDS.statusIconColor]: "#CECECE",
          [ICON_STATUS_FIELDS.statusIconLabel]: "",
        }
      });
      if (el.updateMapping) {
        if (el.updateMapping?.success) {
          this.getData();
          showBanner.call(this, BannerType.Success, this.typeName, ACTION.EDIT);
        } else if (el.updateMapping?.error) {
          showBanner.call(this, BannerType.Fail, this.typeName, ACTION.EDIT);
        }
        this.memberStore.dispatch(DistributionMappingAction.clearHeaderDetailState());
      }
    });
    this.getData();
  }

  getData(): void {
    this.isLoading = true;
    let query: GetHeaderListRequest = {
      sortField: '',
      sortType: 0,
    };

    if (this.sortInfo) {
      const field = this.sortInfo.active[0].toUpperCase() + this.sortInfo.active.substr(1);
      query = {
        ...query,
        sortField: this.sortInfo.direction ? field : '',
        sortType: this.sortInfo.direction === 'asc' ? SORT_TYPE.ASC : SORT_TYPE.DESC,
      };
    }

    this.memberStore.dispatch(DistributionMappingAction.getDataList({ query: query }));
  }

  sortChange(event: Sort) {
    this.sortInfo = event;
    this.getData();
  }

  handleEdit(row: DistributionDetail): void {
    this.typeName = row.benefitType;
    const dialogRef = this.dialog.open(EditDistributionMappingComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      autoFocus: false,
      data: {...row},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.memberStore.dispatch(DistributionMappingAction.updateMappingRequest({body: result, id: row?.id ?? ''}));
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    // this.memberStore.dispatch(clearHeaderDetailState());
  }
}
