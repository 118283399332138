<div class="flex-col justify-center items-end gap-12 confirmation-dialog-container">
  <div class="flex-col justify-start items-start gap-4 flex">
    <div class="title">Confirmation</div>
    <div class="content-container gap-4">
      <mat-icon class="info-icon">info</mat-icon>
      <div class="w-full flex flex-col information-container">
        <span *ngFor="let msg of data.messages">
          {{ msg }}
        </span>
      </div>
    </div>
  </div>
  <div class="flex w-full my-12" [ngClass]="{'mb-0': data.hideCashJournal}">
    <ptg-textbox
      *ngIf="!data.hideCashJournal"
      [hasLabel]="true"
      [controlField]="cashJournalControl"
      [inputType]="InputType.TYPE_NUMBER"
      [maxLength]="4"
      placeholder="CJ #"
      customError="invalidCashJournal"
    ></ptg-textbox>
  </div>
  <div class="dialog-footer flex justify-end items-center gap-2">
    <ptg-button classInput="save-button" (clickButton)="formSubmit$.next()">{{ data.saveButtonLabel || 'Save' }}</ptg-button>
    <ptg-button classInput="cancel-button" (clickButton)="cancel()">Cancel</ptg-button>
  </div>
</div>
