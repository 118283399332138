<div class="parameter-mapping-section">
  <div class="header">
      <span class="title">{{ parameterMappingSection?.title }}</span>
      <div class="separator"></div>
      <div class="button-group">
        <ptg-button [buttonName]="'New ' + parameterMappingSection?.title" classInput="edit-button" (clickButton)="onClickAddNew(parameterMappingSection?.parameterType)">
          <mat-icon>add</mat-icon>
        </ptg-button>
      </div>
  </div>
  <ptg-grid
    #table
    [data]="parameterMappingSection?.parameterMappings || []"
    [columns]="columns"
    [isLoading]="parameterMappingSection?.isLoading"
    [totalRecords]="parameterMappingSection?.lengthPg || 0"
    [pageSize]="parameterMappingSection?.pageSize || 10"
    [pageNumber]="parameterMappingSection?.pageIndex || 1"
    (pageChange)="onChangePage($event)"
    (sortChange)="onSortChange($event)"
    notFoundMessage="No Data to Display"
  >
    <ng-template cellContent columnName="isError" let-row>
      <ptg-button 
        *ngIf="row.isError"
        classInput="error-button"
        #tooltip="matTooltip"
        matTooltip="Parameter does not exist in the calculation file."
        matTooltipClass="custom-tooltip"
        matTooltipPosition="below"
      >
        <mat-icon class="error">error</mat-icon>
      </ptg-button>
    </ng-template>

    <ng-template cellContent columnName="action" let-row>
      <ptg-button buttonName="Edit" classInput="edit-button" (clickButton)="onClickEdit(row)">
        <mat-icon>edit</mat-icon>
      </ptg-button>
      <ptg-button buttonName="Remove" classInput="edit-button" (clickButton)="onClickRemove(row)">
        <mat-icon>delete_forever</mat-icon>
      </ptg-button>
    </ng-template>

  </ptg-grid>
</div>

