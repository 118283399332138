import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AddColumnSetComponent } from '@ptg-member/components/add-column-set/add-column-set.component';
import { GetParameterMappingType, StepPropertiesType } from '@ptg-member/features/calculation/types/enums';
import { SectionConfig } from '@ptg-member/types/models';

import { BaseComponent } from '@ptg-shared/components';
import { ADMIN_SYSTEM } from '@ptg-shared/constance/value.const';
import {
  ACTION_COLUMN,
  Align,
  Column,
  getValidatorsFromColumns,
  GridComponent,
  ReorderInfo,
  Row,
} from '@ptg-shared/controls/grid';
import { Option } from '@ptg-shared/controls/select/select.component';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { deepClone } from '@ptg-shared/utils/common.util';
import { DateTime } from 'luxon';
import { filter, takeUntil } from 'rxjs/operators';
import { BOOLEAN_VALUE, CALCULATION_OPTION } from '../../constants';
import { CalculationStep, CalculationStepProperty, ParameterMapping } from '../../services/models';
import {
  CalculationState,
  getBenefitCalculationParameterMappingAction,
  getBenefitCalculationParameterMappingSelector,
} from '../../store';

@Component({
  selector: 'ptg-step-configuration-nonlist-step',
  templateUrl: './step-configuration-nonlist-step.component.html',
  styleUrls: ['./step-configuration-nonlist-step.component.scss'],
})
export class StepConfigurationNonlistStepComponent extends BaseComponent implements OnChanges {
  readonly ACTION_COLUMN = ACTION_COLUMN;
  ADMIN_SYSTEM = ADMIN_SYSTEM;
  @ViewChild('sortPropertyTable') gridview!: GridComponent<CalculationStepProperty>;
  @ViewChild('sortRowTable') gridviewRow!: GridComponent<CalculationStepProperty>;

  @Input() stepDataFormGroup: any = new FormGroup({});
  @Input() calculationStep?: CalculationStep;
  @Input() canSubmit: boolean = false;
  @Input() cancelTime = DateTime.now();
  @Input() benefitEntityId: string = '';
  @Output() canSubmitChange = new EventEmitter<boolean>();

  initStepOptionValue: any = {};
  inputLabelMaxLength: number = 255;
  sortRowSection!: SectionConfig;
  isMemberListConfiguration: boolean = false;
  sortable: boolean = true;
  listColumnConfig: any = [];
  addPropertySection: SectionConfig = {
    title: 'Benefit Option',
    columnName: 'Input Label',
  };
  inputMappings: ParameterMapping[] = [];

  orderColumns: Column[] = [
    {
      name: 'inputLabel',
      editable: true,
      truncate: true,
      validators: {
        required: {
          type: (obj: any) => Validators.required,
          message: (error: any, fieldName: string) => `${fieldName} is required.`,
        },
        maxlength: {
          type: (obj: any) => Validators.maxLength(this.inputLabelMaxLength),
          message: (error: any, fieldName: string) => `Exceed the ${error.requiredLength} character limit.`,
        },
        existed: {
          type: (obj: any) => this.checkExits(obj),
          message: (error: any, fieldName: string) => `${fieldName} already exists.`,
        },
      },
      controlArgs: {
        placeholder: 'Label',
      },
    },
    {
      name: ACTION_COLUMN,
      align: Align.Right,
      width: '50px',
    },
  ];

  sortColumns: Column[] = [
    {
      name: 'columnName',
      truncate: true,
    },
    {
      name: ACTION_COLUMN,
      align: Align.Right,
      width: '50px',
    },
  ];

  ACTION = {
    ADD_SORT_ROW: 'addSortRow',
    EDIT_COLUMN_NAME: 'editColumnName',
    REMOVE: 'remove',
    SORT_CHANGE: 'sortChange',
  };
  isLoading = true;
  addInputMappingFormGroup: FormGroup = this.fb.group({
    calculationParamMappingId: this.fb.control('', [Validators.required]),
    inputLabel: this.fb.control('', [
      Validators.required,
      Validators.maxLength(this.inputLabelMaxLength),
      this.checkDuplicated(),
    ]),
    isInitiate: this.fb.control(true),
    isRecalculate: this.fb.control('')
  });

  sortPropertySectionDataTable: (CalculationStepProperty & Row)[] = [];
  inputMappingOptions!: Option[] | any[];
  filteredInputMappingOptions: Option[] = [];
  editColumnForm!: FormGroup;
  isTypeChecked:boolean = true;


  get isInitiateControl() {
    return this.addInputMappingFormGroup.get('isInitiate') as FormControl;
  }

  get isRecalculateControl() {
    return this.addInputMappingFormGroup.get('isRecalculate') as FormControl;
  }

  get inputMappingControl() {
    return this.addInputMappingFormGroup.get('calculationParamMappingId') as FormControl;
  }

  get inputLabelControl() {
    return this.addInputMappingFormGroup.get('inputLabel') as FormControl;
  }

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddColumnSetComponent>,
    public calculationStore: Store<CalculationState>,
  ) {
    super();
  }

  get stepLabelControl(): FormControl {
    return this.stepDataFormGroup?.get(CALCULATION_OPTION.StepLabelKey) as FormControl;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cancelTime && !changes.cancelTime.firstChange) {
      this.resetAddPropertyForm();
      this.resetPropertyGrid();
      this.getFilteredInputMappingOption();
      this.resetStepForm();
    }
  }

  ngOnInit(): void {
    this.isLoading = false;
    this.calculationStep?.calculationStepOptions?.map((o) => {
      this.initStepOptionValue[o.key] = o.value;
    });
    this.editColumnForm = this.fb.group({
      columnSetName: ['', [Validators.required]],
      startWith: [''],
    });
    this.getInputMappings();
    this.initStepDataFormGroup();
    this.onChangeByPassValue();
    this.onChangeStepLabelValue();
  }

  get byPassControl(): FormControl {
    return this.stepDataFormGroup?.get(CALCULATION_OPTION.ByPass) as FormControl;
  }

  initStepDataFormGroup() {
    this.stepDataFormGroup?.setControl(
      CALCULATION_OPTION.ByPass,
      new FormControl(this.initStepOptionValue[CALCULATION_OPTION.ByPass] === BOOLEAN_VALUE.TrueValue),
    );
    this.stepDataFormGroup?.setControl(
      CALCULATION_OPTION.StepLabelKey,
      new FormControl(this.initStepOptionValue[CALCULATION_OPTION.StepLabelKey]),
    );
    this.stepDataFormGroup?.setControl(CALCULATION_OPTION.CalculationStepProperties, new FormControl(null));
  }

  get stepPropertiesControl(): FormControl {
    return this.stepDataFormGroup?.get(CALCULATION_OPTION.CalculationStepProperties) as FormControl;
  }

  onChangeByPassValue() {
    this.byPassControl.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((c) => !this.byPassControl.pristine),
      )
      .subscribe(() => {
        this.canSubmitChange.emit(true);
      });
  }

  onChangeStepLabelValue() {
    this.stepLabelControl.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((c) => !this.stepLabelControl.pristine),
      )
      .subscribe(() => {
        this.canSubmitChange.emit(true);
      });
  }

  getInputMappings() {
    this.calculationStore.dispatch(
      getBenefitCalculationParameterMappingAction({
        parameterType: GetParameterMappingType.Input,
      }),
    );
    this.calculationStore
      .select(getBenefitCalculationParameterMappingSelector)
      .pipe(
        filter((parameterMappingState) => !parameterMappingState?.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((parameterMappings) => {
        this.inputMappings = parameterMappings?.payload?.inputMappings ?? [];
        this.getDataTable();
        this.getInputMappingOptions();
      });
  }

  getDataTable() {
    if (!this.inputMappings?.length) {
      return;
    }

    this.sortPropertySectionDataTable = deepClone(this.calculationStep?.calculationStepProperties ?? [])
      ?.map((config) => {
        const obj = {
          ...config,
          order: config.orderColumn || config.order,
          isUsed: !this.sortRowSection,
          description: [config?.parameterName, config.inputMappingSheetName, config.inputMappingFieldName]
            .filter((item) => !!item)
            .join('/'),
        };
        return this.createInlineEditFormControls(obj);
      })
      .sort((a, b) => Number(a.order) - Number(b.order));
    this.setStepPropertiesControlValue();
  }

  getInputMappingOptions() {
    this.inputMappingOptions = (this.inputMappings as any)
      ?.filter((item: any) => item.benefitEntityId === this.benefitEntityId)
      ?.reduce((result: Option[] | any, inputMapping: any) => {
        if (this.isExisted(inputMapping.id)) {
          return result;
        }
        result.push({
          value: inputMapping.id,
          displayValue: inputMapping.name,
          valueDescription: [inputMapping?.sheetName, inputMapping?.fieldName].filter((item) => !!item).join('/'),
        });
        return result;
      }, [] as Option[]);
    this.getFilteredInputMappingOption();
  }

  changeItem(event: ReorderInfo, isSortColumn = false) {
    this.setStepPropertiesControlValue();
    this.canSubmitChange.emit(true);

    if (isSortColumn) {
      this.sortPropertySectionDataTable = this.gridview.dataSource;
      return;
    }
  }

  onSoftDeleteSectionConfig(row: CalculationStepProperty & Row): void {
    row.deleted = true;
    const sortItem = this.sortPropertySectionDataTable.find((item) => item.id === row.id);

    if (sortItem) {
      sortItem.deleted = true;
    }
  }

  resetAddPropertyForm() {
    this.addInputMappingFormGroup.reset();
    this.isInitiateControl.setValue(true);
    this.isTypeChecked = true
  }

  resetStepForm() {
    this.byPassControl.markAsUntouched();
    this.byPassControl.markAsPristine();
    this.byPassControl.setValue(this.initStepOptionValue[CALCULATION_OPTION.ByPass] === BOOLEAN_VALUE.TrueValue);

    this.stepLabelControl.markAsUntouched();
    this.stepLabelControl.markAsPristine();
    this.stepLabelControl.setValue(this.initStepOptionValue[CALCULATION_OPTION.StepLabelKey]);
  }

  resetPropertyGrid() {
    this.getDataTable();
  }

  changeInputMapping() {
    const label = this.inputMappingOptions.find((item) => item.value === this.inputMappingControl.value)?.displayValue;
    this.inputLabelControl.setValue(label ?? '');
  }

  changeOverrideOnInput() {
    this.getFilteredInputMappingOption();
  }

  addInputMapping() {
    this.addInputMappingFormGroup.markAllAsTouched();
    if (!this.isInitiateControl.value && !this.isRecalculateControl.value) {
      this.isTypeChecked = false;
      return;
    }
    if (this.addInputMappingFormGroup.invalid) {
      return;
    }
    const formValue = this.addInputMappingFormGroup.value;
    const addedIndex = this.inputMappingOptions.findIndex((item) => item.value === formValue.key);
    let itemAdd;
    if (addedIndex > -1) {
      itemAdd = this.inputMappingOptions[addedIndex];
    }
    const inputMapping = this.inputMappings.find((item) => item.id === formValue.calculationParamMappingId);
    const stepPropertyType = this.convertType(formValue);

    const newRecord: CalculationStepProperty = {
      ...formValue,
      id: undefined,
      calculationStepId: formValue.calculationStepId,
      order: this.sortPropertySectionDataTable?.length + 1,
      description: [inputMapping?.name, inputMapping?.sheetName, inputMapping?.fieldName]
        .filter((item) => !!item)
        .join('/'),
      stepPropertyTypeName: stepPropertyType?.stepPropertyTypeName,
      type: stepPropertyType?.type
    };

    this.sortPropertySectionDataTable = [
      ...this.sortPropertySectionDataTable,
      this.createInlineEditFormControls(newRecord),
    ];
    this.resetAddPropertyForm();
    this.setStepPropertiesControlValue();
    this.getFilteredInputMappingOption();
    this.canSubmitChange.emit(true);
  }

  onTypeCheckboxesChange() {
    if(this.isInitiateControl.value || this.isRecalculateControl.value) this.isTypeChecked = true
  }

  private convertType(formValue: any) {
    const { isInitiate, isRecalculate } = formValue;
    if (isInitiate && isRecalculate) return { type: StepPropertiesType.Both, stepPropertyTypeName: 'Initiate/Recalculate' };
    if (isInitiate) return { type: StepPropertiesType.Initiate, stepPropertyTypeName: 'Initiate' };
    if (isRecalculate) return { type: StepPropertiesType.Recalculate, stepPropertyTypeName: 'Recalculate' };
    return {};
  }

  setStepPropertiesControlValue() {
    let order = 1;
    const propertyDisplayConfigurations: (CalculationStepProperty & Row)[] = this.sortPropertySectionDataTable.map(
      (item: CalculationStepProperty & Row) => {
        const obj: any = {
          ...item,
          orderColumn: item.deleted ? -1 : order,
          inputLabel: item.form?.value?.inputLabel,
        };
        order += item.deleted ? 0 : 1;
        delete obj.order;
        delete obj.form;
        return obj;
      },
    );
    this.stepPropertiesControl?.setValue(propertyDisplayConfigurations);
    this.stepPropertiesControl?.setErrors(
      this.gridview.formStatus !== AbstractControlStatus.VALID ? { error: 'error' } : null,
    );
  }

  checkDuplicated(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.sortPropertySectionDataTable?.length) {
        return null;
      }
      if (
        this.sortPropertySectionDataTable?.find(
          (item: { inputLabel: string }) =>
            item.inputLabel?.trim()?.toLowerCase() === control.value?.trim()?.toLowerCase(),
        )
      ) {
        return {
          duplicatedValue: `${this.addPropertySection.columnName} already exists.`,
        };
      }
      return null;
    };
  }

  isExisted(paramMappingId: string) {
    return (this.sortPropertySectionDataTable ?? []).find((item) => {
      return item.calculationParamMappingId === paramMappingId;
    });
  }

  onChangeOrderColumns() {
    this.setStepPropertiesControlValue();
    this.canSubmitChange.emit(true);
  }

  getFilteredInputMappingOption() {
    this.filteredInputMappingOptions = deepClone(this.inputMappingOptions).filter((item) => {
      return !this.isExisted(item.value);
    });
  }

  private checkExits(obj: CalculationStepProperty): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const allRows = [...this.sortPropertySectionDataTable];
      const existed = allRows.some(
        (item) => item.form?.value?.columnName?.toLowerCase()?.trim() === control.value?.toLowerCase()?.trim(),
      );
      return existed ? { existed: true } : null;
    };
  }

  private createInlineEditFormControls(calculationStepProperty: CalculationStepProperty): CalculationStepProperty {
    const result = {
      ...calculationStepProperty,
      form: this.fb.group({
        inputLabel: new FormControl(
          calculationStepProperty.inputLabel,
          getValidatorsFromColumns(this.orderColumns[0].name, this.orderColumns, calculationStepProperty),
        ),
      }),
    };
    return result;
  }
}

