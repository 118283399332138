<ptg-dialog
  class="edit-entity-reference-dialog"
  [title]="'Edit Property'"
  [footerPosition]="'bottom'"
  [footerAlign]="'right'"
  [isFullScreen]="false"
>
  <ng-template dialogBody>
    <form class="edit-form" [formGroup]="editForm">
      <div class="input-container">
        <ptg-textbox
          [controlField]="propertyNameCtrl"
          placeholder="Property Name"
          [maxLength]="250"
          [hasLabel]="true"
          [isRequired]="true"
          errorAsync="Property Name or Import Label already exists."
        ></ptg-textbox>
        <div class="break-line">
          <span class="title">Property Type</span>
          <span class="value"> Entity Reference</span>
        </div>
      </div>
      <div class="input-container">
        <ptg-toggle-button
          [controlField]="includeInBulkUpdateCtrl"
          label="Enable Bulk Update"
          (valueChange)="cleanImportLabel($event)"
        ></ptg-toggle-button>
        <ng-container *ngIf="includeInBulkUpdateCtrl?.value">
          <div
            class="input-import-label"
            [ngClass]="{
              'error-margin-bottom':
                editForm.get('importLabel')?.touched &&
                editForm.get('importLabel')?.errors
            }"
          >
            <ptg-textbox
              [controlField]="importLabelCtrl"
              [maxLength]="250"
              [hasLabel]="true"
              placeholder="Import Label"
              [isRequired]="true"
              errorAsync="Property Name or Import Label already exists."
            ></ptg-textbox>
          </div>
        </ng-container>
        <div class="divider"></div>
        <span class="linked-entity"> Linked Entity </span>
        <div class= "entity">
          <div *ngFor="let item of listEntity">
            <div class="break-line-entity">
              <span class="title">Entity</span>
              <span class="value"> {{ item }}</span>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template dialogFooter>
    <button
      mat-raised-button
      type="button"
      class="submit"
      (click)="formSubmit$.next()"
    >
      Save
    </button>
    <button
      mat-raised-button
      type="button"
      class="cancel"
      (click)="onClickCancel()"
    >
      Cancel
    </button>
  </ng-template>
</ptg-dialog>
