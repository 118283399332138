import { createAction, props } from '@ngrx/store';
import { GetStepConfigurationResponse } from '@ptg-member/features/calculation/services/models';
import {
  CreateRetirementBenefitRequest,
  GetDetectRecalculationBenefitRequest,
  GetDetectRecalculationBenefitResponse, GetValidateBeforeRecalculationBenefitResponse,
} from '../../services/models/retirement-benefit-dialog.model';
import { StepConfigurationValues } from '../../services/models/retirement-benefit.model';
import { StepConfigurationValuesRequest } from '../../services/models';

export const createRetirementBenefitAction = createAction(
  '[CreateRetirementBenefit/API] Send Request',
  props<{ request: CreateRetirementBenefitRequest }>(),
);

export const createRetirementBenefitSuccessAction = createAction(
  '[CreateRetirementBenefit/API] Success',
  props<{ retirementBenefitId: string }>(),
);

export const createRetirementBenefitFailureAction = createAction(
  '[CreateRetirementBenefit/API] Failure',
  props<{ error?: any }>(),
);

export const clearCreateRetirementBenefitDialogsStateAction = createAction('[CreateRetirementBenefitDialogs] Clear');

export const getBenefitOptionsAction = createAction(
  '[GetBenefitOptions/API] Send Request',
  props<{ request: StepConfigurationValuesRequest }>(),
);

export const getBenefitOptionsSuccessAction = createAction(
  '[GetBenefitOptions/API] Success',
  props<{ response: StepConfigurationValues }>(),
);

export const getBenefitOptionsFailureAction = createAction('[GetBenefitOptions/API] Failure', props<{ error?: any }>());

export const getBenefitOptionsStateAction = createAction('[GetBenefitOptions] Clear');

export const getStepConfigurationDetailByBenefitOptionAction = createAction(
  '[GetStepConfigurationDetailByBenefitOption/API] Send Request',
  props<{ memberId: string; benefitEntityId: string, calculationType?: number }>(),
);

export const getStepConfigurationDetailByBenefitOptionSuccessAction = createAction(
  '[GetStepConfigurationDetailByBenefitOption/API] Success',
  props<{ response: GetStepConfigurationResponse }>(),
);

export const getStepConfigurationDetailByBenefitOptionFailureAction = createAction(
  '[GetStepConfigurationDetailByBenefitOption/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetStepConfigurationDetailByBenefitOptionStateAction = createAction(
  '[GetStepConfigurationDetailByBenefitOption] Clear',
);

export const getDetectRecalculationBenefitAction = createAction(
  '[GetDetectRecalculationBenefit/API] Send Request',
  props<{ request: GetDetectRecalculationBenefitRequest }>(),
);
export const getDetectRecalculationBenefitSuccessAction = createAction(
  '[GetDetectRecalculationBenefit/API] Success',
  props<{ response: GetDetectRecalculationBenefitResponse }>(),
);
export const getDetectRecalculationBenefitFailureAction = createAction(
  '[GetDetectRecalculationBenefit/API] Failure',
  props<{ error?: any }>(),
);
export const clearGetDetectRecalculationBenefitStateAction = createAction('[GetDetectRecalculationBenefit] Clear');

export const getValidateBeforeRecalculationBenefitAction = createAction(
  '[GetValidateBeforeRecalculationBenefit/API] Send Request',
  props<{ memberId: string; calcAsOfDate: string }>(),
);

export const getValidateBeforeRecalculationBenefitSuccessAction = createAction(
  '[GetValidateBeforeRecalculationBenefit/API] Success',
  props<{ response: GetValidateBeforeRecalculationBenefitResponse }>()
);

export const getValidateBeforeRecalculationBenefitFailureAction = createAction(
  '[GetValidateBeforeRecalculationBenefit/API] Failure',
  props<{ error?: any }>()
);

export const clearGetValidateBeforeRecalculationBenefitStateAction = createAction(
  '[GetValidateBeforeRecalculationBenefit] Clear'
);
