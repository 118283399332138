import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ptg-retirement-benefit-calculation-detail-header',
  templateUrl: './retirement-benefit-calculation-detail-header.component.html',
  styleUrls: ['./retirement-benefit-calculation-detail-header.component.scss']
})
export class RetirementBenefitCalculationDetailHeaderComponent implements OnInit {
  @Input() title: string = '';
  @Input() listFieldData: any[] = [];

  @Output() onEdit = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  onClickEdit() {
    this.onEdit.emit();
  }
}
