import { createAction, props } from "@ngrx/store";
import { DistributionDetail, DistributionMappingEditPayload, GetDataListRequest } from "@ptg-member/types/models/distribution-mapping.model";

export const ClearDistributionEditState = '[Clear Distribution Edit State]';
export const distributionListRequest = '[DistributionList/API] Send Request';
export const distributionListFailure = '[DistributionList/API] Failure';
export const distributionListSuccess = '[DistributionList/API] Success';

export const UpdateMappingRequest = '[UpdateMapping/API] Send Request';
export const UpdateMappingFailure = '[UpdateMapping/API] Failure';
export const UpdateMappingSuccess = '[UpdateMapping/API] Success';

export const clearHeaderDetailState = createAction(
  ClearDistributionEditState
);

export const getDataList = createAction(
  distributionListRequest,
  props<{ query: GetDataListRequest }>()
);
export const getDataListSuccess = createAction(
  distributionListSuccess,
  props<{ dataList: DistributionDetail[] }>()
);
export const getDataListFailure = createAction(
  distributionListFailure,
props<{ error?: any }>()
);

export const updateMappingRequest = createAction(
  UpdateMappingRequest,
  props<{ body: DistributionMappingEditPayload, id: string }>()
);
export const updateMappingSuccess = createAction(
  UpdateMappingSuccess,
);
export const updateMappingFailure = createAction(
  UpdateMappingFailure,
  props<{ error: string }>()
);
