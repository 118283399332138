import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import {
  clearCreateEntityDataStateAction,
  clearGetDetailsEntityDatasStateAction,
  clearSearchEntityDatasStateAction,
  createEntityDataAction,
  createEntityDataFailureAction,
  createEntityDataSuccessAction,
  getDetailsEntityDatasAction,
  getDetailsEntityDatasFailureAction,
  getDetailsEntityDatasSuccessAction,
  searchEntityDatasAction,
  searchEntityDatasFailureAction,
  searchEntityDatasSuccessAction,
  upsertComponentListDataAction,
  upsertComponentListDataFailureAction,
  upsertComponentListDataSuccessAction,
  clearUpsertComponentListDataStateAction,
  getEntityMetadataAction,
  getEntityMetadataSuccessAction,
  getEntityMetadataFailureAction,
  checkIfRecordExistedAction,
  checkIfRecordExistedSuccess,
  checkIfRecordExistedFailure,
  clearCheckIfRecordExistedStateAction,
} from '../actions';
import { CheckIsRecordExistedResponse, DetailsEntityData, GetEntityMetaDataResponse, SearchResult } from '@ptg-entity-management/services/models';

export interface EntityDataState {
  createEntityData?: BaseActionState<{ recordId: string, cardName: string }>;
  searchEntityDatas?: BaseActionState< { propertyId: string, searchResult: SearchResult }>;
  detailsEntityDatas?: BaseActionState<DetailsEntityData>;
  upsertComponentListData?: BaseActionState<{ memberId: string }>;
  entityMetadata?: BaseActionState<GetEntityMetaDataResponse>;
  isSelectedRecordExisted?: BaseActionState<CheckIsRecordExistedResponse>
}

const initialState: EntityDataState = {};

export const entityDataReducer = createReducer(
  initialState,
  on(createEntityDataAction, (state) => ({
    ...state,
    createEntityData: {
      isLoading: true,
    },
  })),
  on(createEntityDataSuccessAction, (state, { recordId, cardName }) => ({
    ...state,
    createEntityData: {
      isLoading: false,
      success: true,
      payload: { recordId, cardName }
    },
  })),
  on(createEntityDataFailureAction, (state, { error }) => ({
    ...state,
    createEntityData: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearCreateEntityDataStateAction, (state) => ({
    ...state,
    createEntityData: undefined,
  })),
  on(searchEntityDatasAction, (state) => ({
    ...state,
    searchEntityDatas: {
      isLoading: true,
    },
  })),
  on(searchEntityDatasSuccessAction, (state, { propertyId, searchResult }) => ({
    ...state,
    searchEntityDatas: {
      isLoading: false,
      success: true,
      payload: {
        propertyId,
        searchResult
      }
    },
  })),
  on(searchEntityDatasFailureAction, (state, { error }) => ({
    ...state,
    searchEntityDatas: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearSearchEntityDatasStateAction, (state) => ({
    ...state,
    searchEntityDatas: undefined,
  })),
  
on(getDetailsEntityDatasAction, (state) => ({
  ...state,
  detailsEntityDatas: {
    isLoading: true,
  },
})),
on(getDetailsEntityDatasSuccessAction, (state, { detailsEntityData }) => ({
  ...state,
  detailsEntityDatas: {
    isLoading: false,
    success: true,
    payload: detailsEntityData,
  },
})),
on(getDetailsEntityDatasFailureAction, (state, { error }) => ({
  ...state,
  detailsEntityDatas: {
    isLoading: false,
    success: false,
    error: error,
  },
})),
on(clearGetDetailsEntityDatasStateAction, (state) => ({
  ...state,
  detailsEntityDatas: undefined,
})),

on(upsertComponentListDataAction, (state) => ({
  ...state,
  upsertComponentListData: {
    isLoading: true,
  },
})),
on(upsertComponentListDataSuccessAction, (state, { memberId }) => ({
  ...state,
  upsertComponentListData: {
    isLoading: false,
    success: true,
    payload: { memberId : memberId }
  },
})),
on(upsertComponentListDataFailureAction, (state, { error }) => ({
  ...state,
  upsertComponentListData: {
    isLoading: false,
    success: false,
    error: error,
  },
})),
on(clearUpsertComponentListDataStateAction, (state) => ({
  ...state,
  upsertComponentListData: undefined,
})),

on(getEntityMetadataAction, (state) => ({
  ...state,
  entityMetadata: {
    isLoading: true,
  },
})),
on(getEntityMetadataSuccessAction, (state, { entityMetadata }) => ({
  ...state,
  entityMetadata: {
    isLoading: false,
    success: true,
    payload: entityMetadata,
  },
})),
on(getEntityMetadataFailureAction, (state, { error }) => ({
  ...state,
  entityMetadata: {
    isLoading: false,
    success: false,
    error: error,
  },
})),
// Check if Selected Related Entity/Beneficiary (Record) existed in list 
  on(checkIfRecordExistedAction, (state) => ({
    ...state,
    isSelectedRecordExisted: {
      isLoading: true
    },
  })),
  on(checkIfRecordExistedSuccess, (state, {response}) => ({
    ...state,
    isSelectedRecordExisted: {
      isLoading: false,
      success: true,
      payload: response
    },
  })),
  on(checkIfRecordExistedFailure, (state, error) => ({
    ...state,
    isSelectedRecordExisted: {
      isLoading: false,
      success: false,
      error: error
    },
  })),
  on(clearCheckIfRecordExistedStateAction, (state) => ({
    ...state,
    isSelectedRecordExisted: undefined,
  })),
);
