import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';

import { BaseComponent } from '@ptg-shared/components';
import { SUBMODULE_KEY } from '@ptg-shared/constance/permission.const';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { LayoutActions } from '@ptg-shared/layout/actions';
import * as fromReducer from '@ptg-reducers';

import * as fromMember from '../../store/reducers';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ACTION_COLUMN, Column, ColumnType, Row } from '@ptg-shared/controls/grid';
import { Sort } from '@angular/material/sort';
import { ACTION, SORT_TYPE } from '@ptg-shared/constance';
import { AddHeaderComponent } from '@ptg-member/components/add-header/add-header.component';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { showBanner } from '@ptg-shared/utils/common.util';
import { Router } from '@angular/router';
import { clearHeaderDetailState, getHeaderList, getHeaderProfile, removeHeader } from '@ptg-member/store/actions/header.actions';
import { BaseActionState } from '@ptg-shared/types/models';
import { takeUntil } from 'rxjs/operators';
import { ProfileConfigListResponse } from '@ptg-member/types/models/profile-configuration.model';
import { GetHeaderListRequest, Header } from '@ptg-member/types/models';
import { createHeaderSelector, getHeaderProfileSelector, removeHeaderSelector, selectHeaderListState } from '@ptg-member/store/selectors';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';

const PAGE_SIZE_CONST = '-ptg-entity-header-pagesize'

@Component({
  selector: 'ptg-header-list',
  templateUrl: './header-list.component.html',
  styleUrls: ['./header-list.component.scss'],
})

export class HeaderListComponent extends BaseComponent implements OnInit {
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Participant List',
      moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
      url: '/member',
    },
    {
      name: 'Header List',
    },
  ];

  columns: Column[] = [
    {
      name: 'headerName',
      header: {
        title: 'Header Name',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'linkedEntity',
      header: {
        title: 'Entity',
      },
      sortable: true,
      truncate: true,
      width: '400px',
    },
    {
      name: 'totalProperties',
      header: {
        title: 'Total Properties',
      },
      type: ColumnType.Decimal,
      templateArgs: { 
        decimal: 0 
      },
      truncate: true,
      sortable: true,
      width: '300px',
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      width: '300px',
    },
  ];

  sortInfo?: Sort;
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  crrHeaderDeleteID?: string | null;

  headersData: (Header & Row)[] = [];
  isLoading: boolean = true;

  currentFund: any = {};
  lengthPg: number | any;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;

  constructor(
    private memberStore: Store<fromMember.MemberState>,
    public dialog: MatDialog,
    public router: Router,
    private store: Store<fromReducer.State>
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.pageSize = el.defaultPageSize ?? 50;
        this.currentFund = el;
        this.pageSize = Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)) === 0 ? this.pageSize : Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST));
        this.getData();
      });

    this.store.pipe(select(selectHeaderListState), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data?.payload?.length) {
        this.headersData = data?.payload ?? [];
        this.lengthPg = data?.total;
      }
      this.isLoading = !!data?.isLoading;
    });

    this.memberStore.pipe(select(createHeaderSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data?.success && data?.payload) {
        this.getData();
      }
    });
    
    this.memberStore.pipe(select(getHeaderProfileSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.handleHeaderProfile(data);
    });

    this.memberStore.pipe(select(removeHeaderSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data?.success) {
        this.getData();
        showBanner.call(this, BannerType.Success, 'Header', ACTION.REMOVE);
      } else if(data?.error) {
        showBanner.call(this, BannerType.Fail, 'Header', ACTION.REMOVE);
      }
    });
    
    this.store.dispatch(LayoutActions.hiddenSideMenu());
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.memberStore.dispatch(clearHeaderDetailState());
  }

  handleHeaderProfile(data: BaseActionState<ProfileConfigListResponse> | undefined) {
    if (data?.success && data?.payload) {
      if (data.payload?.profileConfigs?.length) {
        this.dialog.open(ConfirmPopupComponent, {
          panelClass: 'confirm-popup',
          autoFocus: false,
          data: {
            title: 'Attention',
            text: `This Header cannot be removed since it is being used in the following Profile: ${data.payload?.profileConfigs.map(
              profile => `${profile.name}`
            ).join(", ")}.`,
            type: ConfirmType.Warning,
            cancelButtonTitle: 'Close',
            hideConfirmButton: true,
          },
        })
        this.crrHeaderDeleteID = null;
      } else {
        const dialogRef = this.dialog.open(ConfirmPopupComponent, {
          panelClass: 'confirm-popup',
          autoFocus: false,
          data: {
            title: 'Remove Item',
            text: 'Are you sure you want to remove this Header?',
            type: ConfirmType.Delete,
          },
        });
    
        dialogRef.afterClosed().subscribe((result) => {
          if (result && this.crrHeaderDeleteID) {
            this.memberStore.dispatch(removeHeader({ id: this.crrHeaderDeleteID }));
          }
          this.crrHeaderDeleteID = null;
        });
      }
    }
  }

  sortChange(event: Sort) {
    this.sortInfo = event;
    this.getData();
  }

  openForm(): void {
    this.dialog.open(AddHeaderComponent, {
      panelClass: 'edit-popup',
      disableClose: true,
      autoFocus: false,
      width: '847px',
      height: 'auto',
    });
  }

  handleEdit(row?: Row): void {
    this.router.navigateByUrl(`/member/header/${row?.id}`);
  }

  onClickRemoveHeader(row: Header & Row): void {
    if (row?.id) {
      this.crrHeaderDeleteID = row.id;
      this.store.dispatch(getHeaderProfile({ id: row?.id }));
    }
  }

  onChangePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    sessionStorage.setItem(this.currentFund.key + PAGE_SIZE_CONST, this.pageSize.toString());
    this.getData();
  }

  getData(): void {
    this.isLoading = true;
    let query: GetHeaderListRequest = {
      sortField: '',
      sortType: 0,
      pageIndex: this.pageNumber,
      pageSize: this.pageSize,
    };

    if (this.sortInfo) {
      const field = this.sortInfo.active[0].toUpperCase() + this.sortInfo.active.substr(1);
      query = {
        ...query,
        sortField: this.sortInfo.direction ? field : '',
        sortType: this.sortInfo.direction === 'asc' ? SORT_TYPE.ASC : SORT_TYPE.DESC,
      };
    }

    this.store.dispatch(getHeaderList({ query: query }));
  }
}
