import { createReducer, on } from "@ngrx/store";
import * as AccessAccountsActions from "../actions/access-accounts.actions";
import { AccessAccountsData } from "../models/access-accounts.model";

export const AccessAccountsFeatureKey = 'access-accounts';

export interface State {
  isLoading: boolean;
  accessAccountsData: AccessAccountsData;
  removeState: string;
  updateState: string;
  createState: string;
  success?: boolean;
  error?: any;
}

const initialState: State = {
  isLoading: true,
  accessAccountsData: {
    total: 0,
    users: []
  },
  removeState: '',
  updateState: '',
  createState: ''
};

export const reducer = createReducer(
  initialState,
  on(AccessAccountsActions.getAccessAccounts, (state) => ({
    ...state,
    isLoading: true,
    accessAccountsData: {
      total: 0,
      users: []
    }
  })),
  on(AccessAccountsActions.getAccessAccountsSuccess, (state, { accessAccountsData }) => ({
      ...state,
      isLoading: false,
      accessAccountsData,
      success: true,
      error: undefined
  })),
  on(AccessAccountsActions.getAccessAccountsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    accessAccountsData: {
      total: 0,
      users: []
    },
    success: false,
    error: error
  })),

  on(AccessAccountsActions.removeAccessAccountSuccess, (state) => ({
    ...state,
    removeState: 'Success'
  })),
  on(AccessAccountsActions.updateAccessAccountSuccess, (state) => ({
    ...state,
    updateState: 'Success'
  })),
  on(AccessAccountsActions.createtAccessAccountSuccess, (state) => ({
    ...state,
    createState: 'Success'
  })),
  on(AccessAccountsActions.updateAccessAccountFailure, (state) => ({
    ...state,
    updateState: 'Fail'
  })),
  on(AccessAccountsActions.createAccessAccountFailure, (state) => ({
    ...state,
    createState: 'Fail'
  })),
  on(AccessAccountsActions.removeAccessAccountFailure, (state) => ({
    ...state,
    removeState: 'Fail'
  })),
  on(AccessAccountsActions.accessAccountClear, (state) => ({
    ...state,
    removeState: '',
    updateState: '',
    createState: ''
  })),
);
