<div class="status-history-container" id="status-history-page">
  <ptg-breadcrumb
    *ngIf="isEntityView"
    [listBreadcrumbs]="listBreadcrumbs"
    [isDisabledGear]="true"
  >
  </ptg-breadcrumb>
  <div *ngIf="!isEntityView; else entityView">
    <ptg-overview-header
      #overViewHeader
      (changeMemberDetailEvent)="onChangeMemberDetail()"
    ></ptg-overview-header>
  </div>
  <ng-template #entityView>
    <ptg-overview-header-entity
      #overViewHeaderEntity
    ></ptg-overview-header-entity>
  </ng-template>
  
  <div class="status-history-content flex flex-col p-6 gap-6">
    <ptg-banner
      *ngIf="message"
      [(bannerType)]="bannerType"
      [message]="message"
    ></ptg-banner>

    <div *ngIf="statusHistorysData[currentRowIndex]" class="box">
      <div class="title">
        <span class="title-name">Selected Status</span>
        <div class="separator"></div>
        <div class="button-group">
          <ptg-button
            buttonName="Edit"
            classInput="edit-button"
            (clickButton)="
              onEditMemberStatus(statusHistorysData[currentRowIndex])
            "
          >
            <mat-icon>edit</mat-icon>
          </ptg-button>
        </div>
        <div class="button-group">
          <ptg-button
            buttonName="Audit"
            classInput="edit-button"
            (clickButton)="
              onClickSecondButton(statusHistorysData[currentRowIndex].id)
            "
          >
            <mat-icon>history</mat-icon>
          </ptg-button>
        </div>
      </div>
      <div class="detail-container">
        <div class="detail">
          <span class="label">Event Date</span>
          <span class="value">
            <ng-container>
              <ng-container>
                <ng-container>
                  {{
                    statusHistorysData[currentRowIndex].statusDate
                      | date : "MM/dd/yyyy"
                  }}
                </ng-container>
              </ng-container>
            </ng-container>
          </span>
        </div>

        <div class="detail">
          <span class="label">Status</span>
          <span class="value">
            <ng-container>
              <mat-icon
                class="icon-status"
                [ngStyle]="{
                  color: statusHistorysData[currentRowIndex]?._typedValue?.color
                }"
                >{{
                  statusHistorysData[currentRowIndex]?._typedValue?.iconName
                }}</mat-icon
              >
              <div class="icon-name">
                {{ statusHistorysData[currentRowIndex].statusName }}
              </div>
            </ng-container>
          </span>
        </div>

        <div class="detail">
          <span class="label">Event</span>
          <span class="value">
            <ng-container>
              {{ statusHistorysData[currentRowIndex].statusEvent }}
            </ng-container>
          </span>
        </div>

        <div class="detail">
          <span class="label">Last Modified At</span>
          <span class="value">
            <ng-container>
              {{
                statusHistorysData[currentRowIndex].lastModifiedAt
                  | date : "MM/dd/yyyy hh:mm a"
              }}
            </ng-container>
          </span>
        </div>
        <div class="detail">
          <span class="label">Modified By</span>
          <span class="value">
            <ng-container>
              {{ statusHistorysData[currentRowIndex].lastModifiedBy }}
            </ng-container>
          </span>
        </div>
        <div class="detail">
          <span class="label"></span>
          <span class="value">
            <ng-container> </ng-container>
          </span>
        </div>
      </div>
    </div>

    <div class="flex-grow overflow-hidden">
      <ptg-list-display-box
        title="Status History"
        pageName="Status"
        btnAddTitle="New Status"
        [isLoading]="isLoading"
        [dataTable]="statusHistorysData"
        [columns]="columns"
        [sortInfo]="sortInfo"
        [lengthPg]="lengthPg"
        [pageSize]="pageSize"
        [pageNumber]="pageNumber"
        [currentRowIndex]="currentRowIndex"
        (changePage)="changePage($event)"
        (sortChange)="sortChange($event)"
        (selectRow)="selectRow($event)"
        (addItem)="onEditMemberStatus(undefined)"
      ></ptg-list-display-box>
    </div>
  </div>
</div>
