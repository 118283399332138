import { createAction, props } from '@ngrx/store';
import { DownloadReceiptRequest, GetRemittancesQuery, GetRemittancesResponse, RemittanceReceipt } from '../models/remittance-submission-history.model';

export const GetRemittancesRequest = '[GetRemittances/API] Send Request';
export const GetRemittancesSuccess = '[GetRemittances/API] Success';
export const GetRemittancesFailure = '[GetRemittances/API] Failure';
export const ClearGetRemitancesState = '[ClearGetRemittancesState] Clear'

export const GetRemittanceReceiptRequest = '[GetRemittanceReceipt/API] Send Request';
export const GetRemittanceReceiptSuccess = '[GetRemittanceReceipt/API] Success';
export const GetRemittanceReceiptFailure = '[GetRemittanceReceipt/API] Failure';

export const DownloadRemittanceReceiptRequest = '[DownloadRemittanceReceipt/API] Send Request';
export const DownloadRemittanceReceiptSuccess = '[DownloadRemittanceReceipt/API] Success';
export const DownloadRemittanceReceiptFailure = '[DownloadRemittanceReceipt/API] Failure';
export const DownloadRemittanceReceiptClear = '[DownloadRemittanceReceipt] Clear';

export const AddNewRemittanceRequest = '[AddNewRemittance/API] Send Request';
export const AddNewRemittanceSuccess = '[AddNewRemittance/API] Success';
export const AddNewRemittanceFailure = '[AddNewRemittance/API] Failure';

export const ClearRemittanceSubmissionHistoryState = '[RemittanceSubmissionHistoryState] Clear';

export const clearRemittanceSubmissionHistoryState = createAction(
  ClearRemittanceSubmissionHistoryState,
);

export const getRemittancesRequest = createAction(
  GetRemittancesRequest,
  props<{ query: GetRemittancesQuery }>()
);
export const getRemittancesSuccess = createAction(
  GetRemittancesSuccess,
  props<{ response: GetRemittancesResponse }>()
);
export const getRemittancesFailure = createAction(
  GetRemittancesFailure
);

export const clearGetRemittancesState = createAction(
  ClearGetRemitancesState
)

export const getRemittanceReceiptRequest = createAction(
  GetRemittanceReceiptRequest,
  props<{ municipalityId: string, remittanceId: string }>()
);
export const getRemittanceReceiptSuccess = createAction(
  GetRemittanceReceiptSuccess,
  props<{ response: RemittanceReceipt }>()
);
export const getRemittanceReceiptFailure = createAction(
  GetRemittanceReceiptFailure
);

export const getDownloadRemittanceReceiptAction = createAction(
  DownloadRemittanceReceiptRequest,
  props<{ request: DownloadReceiptRequest }>()
);

export const getDownloadRemittanceReceiptSuccessAction = createAction(
  DownloadRemittanceReceiptSuccess,
  props<{ response: Blob[] }>()
);

export const getDownloadRemittanceReceiptFailureAction = createAction(
  DownloadRemittanceReceiptFailure,
  props<{ error?: any }>()
);

export const clearGetDownloadRemittanceReceiptStateAction = createAction(
  DownloadRemittanceReceiptClear
);

export const addNewRemittanceRequest = createAction(
  AddNewRemittanceRequest,
  props<{ muniId: string }>()
);
export const addNewRemittanceSuccess = createAction(
  AddNewRemittanceSuccess,
  props<{ newRemittanceId?: string, messageError?: string }>()
);
export const addNewRemittanceFailure = createAction(
  AddNewRemittanceFailure
);
