import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { GetEntityComponentListDataByComponentIdService } from '../../services';
import { GetGetEntityComponentListDataByComponentIdsResponse } from '../../services/models';
import {
  getGetEntityComponentListDataByComponentIdsAction,
  getGetEntityComponentListDataByComponentIdsFailureAction,
  getGetEntityComponentListDataByComponentIdsSuccessAction,
} from '../actions';

@Injectable()
export class GetEntityComponentListDataByComponentIdEffect {
  constructor(
    private actions$: Actions,
    private getEntityComponentListDataByComponentIdService: GetEntityComponentListDataByComponentIdService
  ) {}

  getGetEntityComponentListDataByComponentIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGetEntityComponentListDataByComponentIdsAction),
      switchMap(({ request }) =>
        this.getEntityComponentListDataByComponentIdService.getGetEntityComponentListDataByComponentIds(request).pipe(
          map((response: GetGetEntityComponentListDataByComponentIdsResponse) => getGetEntityComponentListDataByComponentIdsSuccessAction({ response })),
          catchError((error) => of(getGetEntityComponentListDataByComponentIdsFailureAction({ error })))
        )
      )
    )
  );
}
