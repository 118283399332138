<div class="benefit-detail-view-container">
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
    (clickBreadcrumb)="onClickBreadcrumb($event)"
  ></ptg-breadcrumb>

  <ptg-overview-header-entity
    [targetIdFromDialog]="data?.memberId"
  ></ptg-overview-header-entity>

  <div *ngIf="card" class="benefit-detail-view-content flex flex-1 flex-col p-6 gap-6">
    <ptg-banner *ngIf="bannerType" [(bannerType)]="bannerType" [message]="message"></ptg-banner>

    <div class="content-detail">
      <ptg-panel
        [title]="isVersionHistory ? 'Selected ' + detailSectionLabel : detailSectionLabel"
        [noPaddingBody]="true"
      >
        <ng-template panelExtraHeader>
          <div
            *ngIf="isLoadingListDetailView && columns && columns.length > 0"
            class="edit-btn"
            [ngClass]="{'disabled': isLoadingStandalone || isLoadingListDetailView}"
            (click)="onClickEditDetail()"
          >
            <mat-icon>edit</mat-icon>
            <span class="title-edit"> Edit</span>
          </div>
        </ng-template>
        <ng-template panelBody>
          <ptg-entity-standalone-property
            [isEditable]="true"
            [currentRow]="standalonePropertyValues"
            [metadataSection]="standaloneProperties"
            [currentSectionLayout]="standaloneViewType"
            [isLoading]="isLoadingStandalone || isLoadingListDetailView"
          >
          </ptg-entity-standalone-property>
        </ng-template>
      </ptg-panel>
    </div>

    <div
      *ngIf="isVersionHistory"
      class="list-display"
      [ngClass]="{
        noDetail: !listCardData?.length,
        noConfigColumn: columns?.length === 0
      }"
    >
      <div class="list-header">
        <span class="title truncate"> {{ listLabel }} History </span>
      </div>

      <ptg-grid
        *ngIf="columns?.length"
        [data]="listCardData"
        [columns]="columns"
        [isLoading]="isLoadingListDetailView"
        [totalRecords]="lengthPg"
        [pageSize]="pageSize"
        [pageNumber]="pageNumber"
        (pageChange)="changePagging($event)"
        (rowClick)="selectRow($event)"
        [(currentRowIndex)]="currentRowIndex"
        [errorMessage]="errorMsg"
        [fitToParent]="true"
        [hideScrollbar]="false"
        [notFoundMessage]="'No ' + card?.listLabel + ' to Display'"
      >
      </ptg-grid>
      <div *ngIf="!isLoadingListDetailView && (!columns || columns?.length === 0)" class="list-data-not-found no-columns-display">
        <div class="message">Display has not been configured</div>
      </div>
    </div>
  </div>
</div>
