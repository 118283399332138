import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import * as CalculationActions from '../actions/calculation.action';
import {
  Calculation,
  CalculationDetail,
  CalculationProperty,
} from '../../types/models';

export const calculationFeatureKey = 'calculations';

export interface State {
  getCalculations?: BaseActionState<Calculation[]>;
  addCalculation?: BaseActionState;
  updateCalculation?: BaseActionState;
  removeCalculation?: BaseActionState;
  calculationProperties?: BaseActionState<CalculationProperty[]>;
  calculationDetail?: BaseActionState<CalculationDetail>;
}

const initialState: State = {};

export const reducer = createReducer(
  initialState,
  on(CalculationActions.getCalculations, (state, {}) => ({
    ...state,
    getCalculations: {
      isLoading: true,
    },
  })),
  on(
    CalculationActions.getCalculationsSuccess,
    (state: State, { response }) => ({
      ...state,
      getCalculations: {
        isLoading: false,
        success: true,
        payload: response.viewCalculation || [],
        total: response.total || 0,
      },
    })
  ),
  on(CalculationActions.getCalculationsFailure, (state: State, { error }) => ({
    ...state,
    getCalculations: createFailureState(error),
  })),
  on(CalculationActions.clearCalculationState, (state) => ({
    ...initialState,
    getCalculations: undefined,
  })),
  on(CalculationActions.addCalculation, (state) => ({
    ...state,
    addCalculation: {
      isLoading: true,
    },
  })),
  on(CalculationActions.addCalculationSuccess, (state) => ({
    ...state,
    addCalculation: {
      isLoading: false,
      success: true,
    },
  })),
  on(CalculationActions.addCalculationFailure, (state, { error }) => ({
    ...state,
    addCalculation: createFailureState(error),
  })),
  on(CalculationActions.updateCalculation, (state) => ({
    ...state,
    updateCalculation: {
      isLoading: true,
    },
  })),
  on(CalculationActions.updateCalculationSuccess, (state) => ({
    ...state,
    updateCalculation: {
      isLoading: false,
      success: true,
    },
  })),
  on(CalculationActions.updateCalculationFailure, (state, { error }) => ({
    ...state,
    updateCalculation: createFailureState(error),
  })),

  on(CalculationActions.removeCalculation, (state) => ({
    ...state,
    removeCalculation: {
      isLoading: true,
    },
  })),
  on(CalculationActions.removeCalculationSuccess, (state) => ({
    ...state,
    removeCalculation: {
      isLoading: false,
      success: true,
    },
  })),
  on(CalculationActions.removeCalculationFailure, (state, { error }) => ({
    ...state,
    removeCalculation: createFailureState(error),
  })),
  on(CalculationActions.getCalculationProperties, (state, {}) => ({
    ...state,
    calculationProperties: {
      isLoading: true,
    },
  })),
  on(
    CalculationActions.getCalculationPropertiesSuccess,
    (state: State, { response }) => ({
      ...state,
      calculationProperties: {
        isLoading: false,
        success: true,
        payload: response.calculationProperties || [],
      },
    })
  ),
  on(
    CalculationActions.getCalculationPropertiesFailure,
    (state: State, { error }) => ({
      ...state,
      calculationProperties: createFailureState(error),
    })
  ),
  on(CalculationActions.clearCalculationPropertiesState, (state) => ({
    ...initialState,
    calculationProperties: undefined,
  })),
  on(CalculationActions.getCalculationDetail, (state, {}) => ({
    ...state,
    calculationDetail: {
      isLoading: true,
    },
  })),
  on(
    CalculationActions.getCalculationDetailSuccess,
    (state: State, { response }) => ({
      ...state,
      calculationDetail: {
        isLoading: false,
        success: true,
        payload: response.calculationData || [],
      },
    })
  ),
  on(
    CalculationActions.getCalculationDetailFailure,
    (state: State, { error }) => ({
      ...state,
      calculationDetail: createFailureState(error),
    })
  )
);

const createFailureState = (error: any) => {
  return {
    isLoading: false,
    success: false,
    error: error,
  };
};
