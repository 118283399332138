import { NgModule } from '@angular/core';

import { ColorPickerModule } from 'ngx-color-picker';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { QuillModule } from 'ngx-quill';

const NGX_MODULE_EXPORTS = [
  ColorPickerModule,
  NgxCurrencyModule,
  NgxMaterialTimepickerModule,
  NgxMaskModule,
  QuillModule,
];

const NGX_MODULE_IMPORTS = [
  ColorPickerModule,
  NgxCurrencyModule,
  NgxMaterialTimepickerModule,
  NgxMaskModule.forRoot(),
  QuillModule.forRoot(),
];

@NgModule({
  exports: NGX_MODULE_EXPORTS,
  imports: NGX_MODULE_IMPORTS,
})
export class NgxUIModule {}
