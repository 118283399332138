import { Component, Inject } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import {
  CalculationState,
  StepConfigurationState,
  clearCreateCalculationLoddBenefitsStateAction,
  clearGetLoddValidationBeforeRecalculatesStateAction,
  clearGetStepConfigurationDetailByBenefitOptionStateAction,
  clearGetValidationExceptionConfigurationStateAction,
  clearInitiateSurvivorStateAction,
  createCalculationLoddBenefitsAction,
  createCalculationLoddBenefitsSelector,
  getLoddValidationBeforeRecalculatesAction,
  getLoddValidationBeforeRecalculatesSelector,
  getStepConfigurationDetailByBenefitOptionAction,
  getValidateBeforeRecalculationBenefitAction,
  getValidationExceptionConfigurationAction,
  getValidationExceptionConfigurationSelector,
} from '@ptg-member/features/calculation/store';
import { stepConfigurationDetailByBenefitOptionSelector } from '@ptg-member/features/calculation/store/selectors/retirement-benefit-dialog.selectors';
import { CalculationType, ExceptionType } from '@ptg-member/features/calculation/types/enums';
import { BaseComponent } from '@ptg-shared/components';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { deepClone, parseDateToSubmit, showBanner, showCancelDialog } from '@ptg-shared/utils/common.util';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import { Option } from 'src/app/shared/controls/select/select.component';
import {
  CalculationStep,
  CalculationStepProperty,
  CheckStepConfigurationExistsResponse,
  CreateCalculationLoddBenefitsRequest,
  LoddValidationBeforeRecalculateRequest,
  SetBenefitDetailProperties,
  StepConfigurationValuesRequest,
} from '../../services/models';
import { RecalculateBenefitOptions } from '../../services/models/retirement-benefit-dialog.model';
import { StepConfigurationService } from '../../services';
import { FormControlDetail } from '@ptg-shared/types/models';
import { InputType } from '@ptg-shared/controls/dynamic-input/types/enums/dynamic-input.enum';
import { CALCULATION_OPTION } from '../../constants';
import { isEmpty, stringToBoolean } from '@ptg-shared/utils/string.util';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { DateTime } from 'luxon';
import { BenefitDetail, GetValidationExceptionRequest } from '../../services/models/exception-configuration.model';
import { validationData } from '../component.util';
import { Router } from '@angular/router';
import { EntityPropertyType } from '@ptg-entity-management/types/enums';

export interface RecalculateBenefitDialogData {
  memberId: string;
  benefitEntityId: string;
  calculationType: CalculationType;
  benefitOptions?: RecalculateBenefitOptions[];
}

@Component({
  selector: 'ptg-recalculate-benefit',
  templateUrl: './recalculate-benefit-dialog.component.html',
  styleUrls: ['./recalculate-benefit-dialog.component.scss'],
})
export class RecalculateBenefitDialogComponent extends BaseComponent {
  formData: FormGroup = this.initForm;
  dialogTitle: string = this.title;
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: this.dialogTitle,
    },
  ];

  isShowBenefitOptions: boolean = false;
  benefitOptions: Option[] = [];
  isLoading: boolean = false;

  loddSelectedCalculationType?: CalculationType;

  private calculationSteps: CalculationStep[] = [];
  private calculationStepProperties: CalculationStepProperty[] = [];
  propertyValueFromPriorStepConfigs: FormControlDetail[] = [];
  stepOptionByPass: boolean = false;
  validationExceptionInfo: BenefitDetail[] | undefined;
  isDisableSaveButton: boolean = false;

  get initForm() {
    return this.fb.group({
      benefitOptions: this.fb.control('', [this.checkValidateBenefitOptions()]),
      propertyValueFromPriorSteps: this.fb.array([]),
    });
  }

  get benefitOptionsControl(): FormControl {
    return this.formData.get('benefitOptions') as FormControl;
  }

  get propertyValueFromPriorStepsFormArray(): FormArray {
    return this.formData.get('propertyValueFromPriorSteps') as FormArray;
  }

  get title() {
    switch (this.data.calculationType) {
      case CalculationType.Survivor:
        return 'Recalculate Survivor Calculation';
      case CalculationType.JointSurvivor:
        return 'Recalculate Joint Survivor Calculation';
      case CalculationType.LODDBenefit:
        return 'Recalculate LODD Calculation';
      default:
        return '';
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: RecalculateBenefitDialogData,
    public dialogRef: MatDialogRef<RecalculateBenefitDialogComponent>,
    public dialog: MatDialog,
    private fb: FormBuilder,
    public readonly calculationStore: Store<CalculationState>,
    private readonly stepConfigurationService: StepConfigurationService,
    public readonly stepConfigurationsStore: Store<StepConfigurationState>,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.checkShowBenefitOptions();
    this.initialBenefitOptionList();

    this.selectGetStepConfiguration();
    this.selectLoddValidationBeforeRecalculates();
    this.selectValidationExceptionState();
    this.selectInitiateLoddBenefit();
  }

  onCancel() {
    showCancelDialog(this.dialog, this.dialogRef);
  }

  onSubmit() {
    this.formData.markAllAsTouched();
    if (this.formData.invalid) {
      return;
    }
    this.isDisableSaveButton = true;
    this.validateBeforeRecalculateBenefit();
  }

  initialBenefitOptionList() {
    if (!this.data.benefitOptions) return;

    this.benefitOptions = this.data.benefitOptions.map((item) => ({
      value: item.benefitEntityId,
      displayValue: item.name,
    }));
  }

  onChangeBenefitOption() {
    const request: StepConfigurationValuesRequest = {
      memberId: this.data.memberId,
      benefitEntityId: this.benefitOptionsControl.value,
    };
    this.loddSelectedCalculationType = this.data.benefitOptions?.find(
      (item) => item.benefitEntityId === this.benefitOptionsControl.value,
    )?.calculationType;
    this.validateBenefitTypeExist(request);
    this.getValidationExceptionConfigurationData();
  }

  clearDataState() {
    this.calculationStore.dispatch(clearInitiateSurvivorStateAction());
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.clearDataState();
  }

  validateBeforeRecalculateBenefit() {
    if (this.data.calculationType === CalculationType.LODDBenefit) {
      const request: LoddValidationBeforeRecalculateRequest = {
        memberId: this.data.memberId,
        calculationType: this.loddSelectedCalculationType ?? 0,
      };
      this.calculationStore.dispatch(getLoddValidationBeforeRecalculatesAction({ request }));

      return;
    }
    // TO DO
    // const request = {
    //   memberId: this.data.memberId,
    //   calcAsOfDate: parseDateToSubmit(this.calculateAsOfDateControl.value),
    // }
    // this.calculationStore.dispatch(
    //   getValidateBeforeRecalculationBenefitAction(request),
    // );
  }

  selectLoddValidationBeforeRecalculates() {
    this.calculationStore
      .select(getLoddValidationBeforeRecalculatesSelector)
      .pipe(
        filter((res) => !!res && !res?.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((isValidReCalculate) => {
        this.calculationStore.dispatch(clearGetLoddValidationBeforeRecalculatesStateAction());
        const validatePayload = isValidReCalculate?.payload;
        if (!validatePayload?.isError) {
          this.recalculateLoddRequest();
          return;
        }

        this.dialog.open(ConfirmPopupComponent, {
          panelClass: 'confirm-popup',
          data: {
            text: validatePayload.message,
            type: ConfirmType.Warning,
            title: validatePayload.title,
            cancelButtonTitle: 'Close',
            hideConfirmButton: true,
          },
        });
        this.isDisableSaveButton = false;
      });
  }

  private validateBenefitTypeExist(request: StepConfigurationValuesRequest) {
    const benefitEntityId = request.benefitEntityId;
    this.isLoading = true;

    this.stepConfigurationService
      .checkStepConfigurationExists({ benefitEntityId })
      .pipe(take(1))
      .subscribe((response: CheckStepConfigurationExistsResponse) => {
        //if valid then continue
        if (response?.isValid) {
          this.stepConfigurationsStore.dispatch(getStepConfigurationDetailByBenefitOptionAction(request));
        }
      });
  }

  private selectGetStepConfiguration() {
    this.stepConfigurationsStore
      .select(stepConfigurationDetailByBenefitOptionSelector)
      .pipe(
        tap((response) => (this.isLoading = !!response?.isLoading)),
        filter((result) => !!result && !result.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.stepConfigurationsStore.dispatch(clearGetStepConfigurationDetailByBenefitOptionStateAction());
        this.calculationSteps = deepClone(response?.payload?.calculationSteps ?? [])?.sort((a, b) => b.order - a.order);
        const firstStep = this.getStepConfiguration(1);
        if (firstStep) {
          this.propertyValueFromPriorStepsFormArray.clear();
          this.calculationStepProperties = firstStep?.calculationStepProperties;
          this.stepOptionByPass = stringToBoolean(this.getConfigurationByKey(1, CALCULATION_OPTION.ByPass));
          this.propertyValueFromPriorStepMapping();
        }
      });
  }

  private getConfigurationByKey(order: number, key: string) {
    return this.getStepConfiguration(order)?.calculationStepOptions?.find((item) => item.key === key)?.value ?? '';
  }

  private getStepConfiguration(order: number) {
    return this.calculationSteps.find((item) => item.order === order);
  }

  private propertyValueFromPriorStepMapping() {
    this.propertyValueFromPriorStepConfigs = deepClone(this.calculationStepProperties).map((property) => {
      return {
        ...property,
        type: (InputType as any)[EntityPropertyType[property.dataType] as any],
        label: property.inputLabel,
        config: { ...(property?.inputConfigs ?? (property as any)?.config) },
        options: property.inputOptions ?? property.options,
      } as any;
    });
    this.initFormGroup();
  }

  private initFormGroup() {
    this.propertyValueFromPriorStepsFormArray.clear();
    this.propertyValueFromPriorStepConfigs.forEach((property: any) => {
      this.propertyValueFromPriorStepsFormArray.push(
        this.fb.group({
          calculationParamMappingId: property.calculationParamMappingId,
          calculationStepId: property.calculationStepId,
          id: property.id,
          options: property.inputOptions ?? property.options,
          order: property.order,
          orderColumn: property.orderColumn,
          propertyLabel: property.inputLabel,
          value: this.fb.control(this.initFieldsValue(property), [this.customValidation(property)]),
          type: property.dataType ?? property.type,
          benefitDetailKey: property.benefitDetailKey ?? property.id,
          inputConfigs: property.inputConfigs ?? property.config,
        }),
      );
    });
  }

  private initFieldsValue(property: any): any {
    if (isEmpty(property.value) || property.value === '') {
      return null;
    }
    if (property.type === InputType.Binary) {
      return stringToBoolean(property.value);
    }
    return property.value;
  }

  private checkValidateBenefitOptions(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.data.calculationType !== CalculationType.LODDBenefit) {
        return null;
      }
      if (!control.value) {
        return {
          required: true,
        };
      }
      return null;
    };
  }

  private checkShowBenefitOptions() {
    this.isShowBenefitOptions = this.data.calculationType === CalculationType.LODDBenefit;
  }

  private recalculateLoddRequest() {
    const formData = this.formData.getRawValue();
    const request: CreateCalculationLoddBenefitsRequest = {
      memberId: this.data.memberId,
      body: {
        calculationType: this.loddSelectedCalculationType ?? 0,
        benefitEntityId: formData.benefitOptions,
        benefitOptionInputs: this.formData.getRawValue().propertyValueFromPriorSteps.map((item: any) => {
          item.value = this.getPropertyValue(item.value, this.getPropertyType(item.type));
          return item;
        }),
      },
    };

    this.calculationStore.dispatch(createCalculationLoddBenefitsAction({ request }));
  }

  private selectInitiateLoddBenefit(): void {
    this.calculationStore
      .select(createCalculationLoddBenefitsSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.calculationStore.dispatch(clearCreateCalculationLoddBenefitsStateAction());

        if (!response?.success) {
          showBanner.call(this, BannerType.Fail, '', '', {
            customMessage: `Error occurred recalculating LODD Benefit. Please try again.`,
          });
          this.isDisableSaveButton = true;
          return;
        }
        const payload = response?.payload;
        const { directCalculationId, calculationType } = payload ?? {};
        this.dialogRef.close();
        this.router.navigateByUrl(
          `member/benefit-overview/${calculationType}/${this.data.memberId}/detail/${directCalculationId}`,
        );
      });
  }

  private getPropertyType(type: InputType | EntityPropertyType): EntityPropertyType {
    if (typeof type === 'number') {
      return type;
    }
    return (EntityPropertyType as any)[type];
  }

  private getPropertyValue(value: any, type: EntityPropertyType) {
    if (type === EntityPropertyType.Date) {
      return DateTime.fromISO(value).toFormat('yyyy-MM-dd');
    }
    return value;
  }

  private customValidation(field: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const properties: SetBenefitDetailProperties[] = this.formData.getRawValue().overriddenParameters;
      const data = this.validationExceptionInfo?.filter(
        (item) =>
          item.benefitId === this.data.benefitEntityId &&
          item.additionalInfos.length > 0 &&
          item.additionalInfos[0].value === field.calculationParamMappingId,
      );
      return validationData(data, properties);
    };
  }

  private getValidationExceptionConfigurationData(): void {
    this.calculationStore.dispatch(clearGetValidationExceptionConfigurationStateAction());
    const request: GetValidationExceptionRequest = {
      memberId: this.data.memberId,
      exceptionType: ExceptionType.DateValueValidation,
      calculationBenefitId: this.benefitOptionsControl.value,
    };
    this.calculationStore.dispatch(getValidationExceptionConfigurationAction({ request }));
  }

  private selectValidationExceptionState(): void {
    this.calculationStore
      .pipe(
        select(getValidationExceptionConfigurationSelector),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.calculationStore.dispatch(clearGetValidationExceptionConfigurationStateAction());
        if (response?.payload) {
          this.validationExceptionInfo = response?.payload?.benefitDetails;
        }
      });
  }
}
