import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { GridComponent, Row } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { Sort } from '@angular/material/sort';
import { BaseListComponent } from '@ptg-shared/components/base-list.component';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { GeneratedDocumentInfo } from 'src/app/admin/features/file/types/models/generated-document.model';

@Component({
  selector: 'ptg-generated-document',
  templateUrl: './generated-document.component.html',
  styleUrls: ['./generated-document.component.scss']
})
export class GeneratedDocumentComponent extends BaseListComponent implements OnInit {
  @ViewChild('gridGeneratedDocumentList') gridGeneratedDocumentList!: GridComponent<any>;
  @Input() listBreadcrumbs: Breadcrumb[] = [];
  @Input() generatedDocumentInfo!: GeneratedDocumentInfo;
  @Input() columns: Breadcrumb[] = [];
  @Input() dataTable: (any & Row)[] = [];
  @Input() sortInfo: any = {};
  @Input() totalRecords!: number | any;
  @Input() pageSize: number = 50;
  @Input() pageNumber: number = FIRST_PAGE;
  @Input() isLoading!: boolean;
  @Input() disableSelection!: boolean;
  @Input() message!: string;
  @Input() bannerType!: BannerType;

  @Output() downloadFileEvent = new EventEmitter();
  @Output() attachToProfileEvent = new EventEmitter();
  @Output() sortChangeEvent = new EventEmitter();
  @Output() changePagingEvent = new EventEmitter();

  listRecordSelected: any = [];

  constructor(
    public layoutService: LayoutService,
  ) {
    super(layoutService);
  }

  ngOnInit(): void {
  }

  onAttachToProfile() {
    this.attachToProfileEvent.emit(this.listRecordSelected);
  }

  onChangeSort(event: Sort) {
    this.sortChangeEvent.emit(event);
  }

  onChangePage(event: PageEvent) {
    super.onChangePage(event);
    this.changePagingEvent.emit(event);
  }

  onChangeSelection() {
    this.listRecordSelected = this.gridGeneratedDocumentList.selection.selected
      .filter((row: any & Row) => row.checked && !row.hideCheckBox);
  }

  onDownloadFile(row: any) {
    this.downloadFileEvent.emit(row);
  }

}
