import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ptg-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss']
})
export class DialogHeaderComponent implements OnInit {

  @Input() header?: string;
  @Input() prefix?: string;

  get headerValue(): string {
      return `${this.prefix || ''} ${this.header || ''}`.trim();
  }
  constructor() {
  }

  ngOnInit(): void {
  }
}
