<div class="group-aggregation" *ngFor="let group of aggregationDetails">
  <div class="group-title">
    <div class="title">Total Service</div>
  </div>

  <div class="detail-aggregation">
    <div class="item"  *ngFor="let item of group.items">
      <span class="label">{{item.name}}</span>
      <ng-container>
        <span class="value">{{ item.value || '-' }}</span>
      </ng-container>
    </div>
  </div>

</div>