import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import {
  filter,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import * as fromReducer from '@ptg-reducers';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import {
  ACTION,
  CANCEL_CONFIRM_MESSAGE,
  STATE,
} from '@ptg-shared/constance/value.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { deepClone, showBanner } from '@ptg-shared/utils/common.util';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';

import * as ProfileHeaderConfigurationActions from '../../store/actions/profile-header-configuration.actions';
import {
  MetadataProfileHeaderConfiguration,
  PropertyDisplayConfig,
  PropertyDisplayConfiguration,
  SectionConfig,
  Status,
} from '../../types/models';
import * as fromMember from '../../store/reducers';
import { selectProfileHeaderConfigurationId } from '../../store/reducers';
import { PropertyDisplayConfigurationComponent } from '../../components/property-display-configuration/property-display-configuration.component';
import { ProfileHeaderConfigurationService } from '../../services/profile-header-configuration.service';

@Component({
  selector: 'ptg-profile-header-configuration',
  templateUrl: './profile-header-configuration.component.html',
  styleUrls: ['./profile-header-configuration.component.scss'],
})
export class ProfileHeaderConfigurationComponent implements OnInit {
  @ViewChild('profileHeaderConfigs')
  profileHeaderConfigs!: PropertyDisplayConfigurationComponent;
  unsubscribe$ = new Subject<void>();
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Overview',
      url: '',
    },
    {
      name: 'Profile Header View',
      url: '',
    },
    {
      name: 'Profile Header Configuration',
      url: '',
    },
  ];
  propertyConfigs!: PropertyDisplayConfig[];
  propertyDisplayConfigurations!: PropertyDisplayConfiguration[];
  addPropertySection: SectionConfig = {
    title: 'Add Property',
    columnName: 'Label Name',
  };
  sortPropertySection: SectionConfig = { title: 'Order Properties' };
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  editForm!: FormGroup;
  formSubmit$ = new Subject<boolean>();
  statusSelected = this.fb.control(
    {
      value: {},
      disabled: false,
    },
    [this.validateSelectedStatus(this)]
  );
  listStatusOption: Status[] = [];
  allStatus: Status[] = [];
  isStatic: boolean = false;
  memberId: string = '';
  metadataProfileHeader!: MetadataProfileHeaderConfiguration;
  canSubmit: boolean = false;

  constructor(
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private profileHeaderConfigurationService: ProfileHeaderConfigurationService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.store.dispatch(LayoutActions.hiddenSideMenu());
    this.memberStore.dispatch(
      ProfileHeaderConfigurationActions.getMembersStatusNotUsed()
    );
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.memberId = params.memberId;
      this.listBreadcrumbs[0].url = `/member/detail/${params.memberId}`;
      this.listBreadcrumbs[1].url = `/member/profile-header-configuration/${params.memberId}`;
      if (params.id) {
        this.getDetail(params.id);
        this.handleProfileHeaderDetailData();
      } else {
        this.initDefaultData();
      }
    });
    this.memberStore.dispatch(
      ProfileHeaderConfigurationActions.getProfileHeaderPropertyList()
    );
    this.memberStore
      .pipe(
        select(fromMember.selectProfileHeaderPropertyConfigs),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((propertyConfigs) => {
        this.propertyConfigs = propertyConfigs;
      });
    this.memberStore
      .pipe(
        select(fromMember.selectProfileHeaderUpdateState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (!state) {
          return;
        }
        if (state !== STATE.SUCCESS) {
          showBanner.call(
            this,
            state,
            'Profile Header',
            !this.editForm?.value?.id ? ACTION.ADD : ACTION.EDIT
          );
          return;
        }
        if (!this.editForm?.value?.id) {
          this.getNewDetailId(state);
        } else {
          this.resetConfigurationDetail(state);
        }
      });
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
          this.statusSelected.markAsTouched();
          this.statusSelected.updateValueAndValidity();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter((status) => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        if (
          this.profileHeaderConfigs?.gridview?.formStatus !==
          AbstractControlStatus.VALID
        ) {
          return;
        }
        this.onSubmit();
      });
  }
  changeValueInput() {
    this.canSubmit = true;
  }
  changeValueProperty() {
    this.canSubmit = true;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.memberStore.dispatch(
      ProfileHeaderConfigurationActions.clearProfileHeaderConfigurationState()
    );
  }
  getNewDetailId(state: string) {
    this.memberStore
      .pipe(
        select(selectProfileHeaderConfigurationId),
        take(1),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((id) => {
        const url = `${window.location.origin}/profile-header-configuration/${this.memberId}/detail/${id}`;
        window.history.pushState(null, '', url);
        this.editForm.controls['id'].setValue(id);
        this.resetConfigurationDetail(state, true);
      });
  }
  resetConfigurationDetail(state: string, isAdd: boolean = false) {
    this.propertyDisplayConfigurations = [];
    this.profileHeaderConfigs.resetAddPropertyForm();
    this.getDetail(this.editForm.value.id);
    this.handleProfileHeaderDetailData();
    showBanner.call(
      this,
      state,
      'Profile Header',
      isAdd ? ACTION.ADD : ACTION.EDIT
    );
    this.memberStore.dispatch(
      ProfileHeaderConfigurationActions.clearProfileHeaderConfigurationState()
    );
  }
  getAllStatus() {
    return deepClone(this.allStatus) as Status[];
  }

  initDefaultData() {
    this.memberStore
      .pipe(
        select(fromMember.selectMembersStatusNotUsedList),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((allStatus) => {
        this.allStatus = allStatus;
        this.initFormGroup({} as MetadataProfileHeaderConfiguration);
        this.getListStatusOption([]);
      });
  }

  getDetail(profileHeaderId: string) {
    if (!profileHeaderId) {
      return;
    }
    this.memberStore.dispatch(
      ProfileHeaderConfigurationActions.getProfileHeaderConfiguration({
        id: profileHeaderId,
      })
    );
  }

  initFormGroup(formData: MetadataProfileHeaderConfiguration) {
    this.propertyDisplayConfigurations = (
      JSON.parse(
        JSON.stringify(formData?.metadataProfileHeaderConfigurations || [])
      ) as PropertyDisplayConfiguration[]
    ).map((item: any) => {
      if (item?.headerItemType === 1) {
        return {
          ...item,
          aggregationId: item.headerItemKey,
          headerType: item.headerItemType,
        };
      }
      return {
        ...item,
        headerType: item.headerItemType,
        propertyKey: item.headerItemKey,
      };
    });
    const selectedStatus = this.getAllStatus().filter((status) =>
      formData?.statuses?.find(
        (selected) => selected.id.toLowerCase() === status.id.toLowerCase()
      )
    );

    this.editForm = this.fb.group({
      id: this.fb.control(formData?.id),
      name: this.fb.control(
        formData?.name,
        [Validators.required, Validators.maxLength(100)],
        checkApiValidator(
          this.profileHeaderConfigurationService.checkProfileHeaderExists,
          'name',
          formData?.name,
          { params: { profileHeaderId: formData?.id } }
        )
      ),
      statuses: this.fb.array(selectedStatus, Validators.required),
    });
  }

  onSubmit() {
    const formValue = this.editForm.value;
    const metadataProfileHeaderConfigurations: PropertyDisplayConfiguration[] =
      this.profileHeaderConfigs.getSubmitData().map((item) => {
        const itemPropertyConfig = this.propertyConfigs.find(x => x.sectionKey === item.sectionKey && x.propertyKey === item.propertyKey);
        return {
          id: item.id || undefined,
          clientKey: item.clientKey || '',
          sectionKey: item.sectionKey,
          headerItemKey: item.propertyKey || itemPropertyConfig?.aggregationId || '',
          headerType: item.headerType || 0,
          columnName: item.columnName,
          orderColumn: item.orderColumn || -1,
          options: item.options,
          aggregationId: item.aggregationId || itemPropertyConfig?.aggregationId || '',
        };
      });

    const metadataProfileHeader: MetadataProfileHeaderConfiguration = {
      id: formValue?.id || undefined,
      name: formValue?.name || '',
      statuses: formValue?.statuses || [],
      isStatic: false,
      metadataProfileHeaderConfigurations: metadataProfileHeaderConfigurations,
    };
    this.memberStore.dispatch(
      ProfileHeaderConfigurationActions.setProfileHeaderConfiguration({
        body: metadataProfileHeader,
      })
    );
    this.canSubmit = false;
  }
  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: CANCEL_CONFIRM_MESSAGE,
        type: ConfirmType.Cancel,
        title: 'Cancel Action',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.initFormGroup(this.metadataProfileHeader);
        this.editForm.markAsUntouched();
        this.statusSelected.markAsUntouched();
        this.getListStatusOption(this.metadataProfileHeader?.statuses || []);
        this.profileHeaderConfigs.getDataTable();
        this.profileHeaderConfigs.getAvailablePropertyConfigs();
        this.profileHeaderConfigs.resetAddPropertyForm();
        this.profileHeaderConfigs.canSubmit = false;
        this.canSubmit = false;
      }
    });
  }
  addStatus() {
    if (!this.statusSelected?.value.id || this.isStatic) {
      return;
    }
    if (this.statusSelected?.value.id === 'Catch-All') {
      this.getListStatusOption([this.statusSelected?.value]);
      return;
    }
    const isAll = this.editForm.value.statuses.find(
      (status: Status) => status.id === 'Catch-All'
    );
    const listStatusSelected = isAll
      ? [this.statusSelected?.value]
      : [
          ...deepClone(this.editForm.value.statuses),
          this.statusSelected?.value,
        ];
    this.statusSelected.setValue({});
    this.getListStatusOption(listStatusSelected);
    this.editForm.controls['statuses'].markAsTouched();
    this.statusSelected.updateValueAndValidity({ emitEvent: false });
    this.canSubmit = true;
  }

  removeStatus(deletedStatus: Status) {
    const deletedIndex = this.editForm.value.statuses.findIndex(
      (status: Status) =>
        status?.id?.toLowerCase() === deletedStatus?.id?.toLowerCase()
    );
    if (deletedIndex > -1) {
      const listSelectedStatus = deepClone(this.editForm.value.statuses);
      listSelectedStatus.splice(deletedIndex, 1);
      this.getListStatusOption([...listSelectedStatus]);
      this.editForm.controls['statuses'].markAsTouched();
    }
    this.statusSelected.markAllAsTouched();
    this.statusSelected.updateValueAndValidity({ emitEvent: false });
    this.canSubmit = true;
  }

  handleProfileHeaderDetailData() {
    combineLatest([
      this.memberStore.pipe(
        select(fromMember.selectProfileHeaderPropertyDisplayConfigurations)
      ),
      this.memberStore.pipe(select(fromMember.selectMembersStatusNotUsedList)),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (!state) {
          return;
        }
        const profileOverviewDetail = state[0];
        if (!profileOverviewDetail.metadataProfileHeaderConfigurations) {
          return;
        }
        this.isStatic = profileOverviewDetail.isStatic;
        this.metadataProfileHeader = profileOverviewDetail;
        this.statusSelected = this.fb.control(
          {
            value: {},
            disabled: this.isStatic,
          },
          [this.validateSelectedStatus(this)]
        );
        this.allStatus = (
          deepClone(profileOverviewDetail.allStatuses) as Status[]
        ).filter((status) =>
          [...state[1], ...(profileOverviewDetail.statuses || [])].find(
            (item) => item.id.toLowerCase() === status.id.toLowerCase()
          )
        );
        this.initFormGroup(profileOverviewDetail);
        this.getListStatusOption(profileOverviewDetail.statuses);
      });
  }

  getListStatusOption(listSelectedStatus: Status[]) {
    this.listStatusOption = this.getAllStatus();
    const listStatusOptionSelected = listSelectedStatus.map(
      (selectedStatus) => {
        return this.listStatusOption?.find(
          (status) =>
            status.id.toLowerCase() === selectedStatus.id.toLowerCase()
        );
      }
    );
    this.editForm.setControl(
      'statuses',
      this.fb.array(listStatusOptionSelected, Validators.required)
    );
    this.listStatusOption = this.listStatusOption?.filter(
      (status) =>
        !listStatusOptionSelected?.find(
          (selected) =>
            selected?.id?.toLowerCase() === status?.id?.toLowerCase()
        )
    );
  }

  validateSelectedStatus(
    that: ProfileHeaderConfigurationComponent
  ): ValidatorFn {
    return (): ValidationErrors | null => {
      if (
        !that.editForm?.controls['statuses'].touched ||
        that.editForm?.value?.statuses?.length
      ) {
        return null;
      }
      return { required: true };
    };
  }
}
