import { createAction, props } from '@ngrx/store';
import {
  FilterByPropertyConfig,
  FilterInformation,
  RegisterFilter,
  RegisterFilterConfig,
  RegisterFilterName,
  ReversePayeeTransactionResponse,
  SummaryList,
} from '../../services/models';

export const clearRegisterAction = createAction('[RegisterClear]');

export const getSummaryListAction = createAction(
  '[GetSummaryList/API] Send Request',
  props<{ query: any }>()
);
export const getSummaryListSuccessAction = createAction(
  '[GetSummaryList/API] Success',
  props<{ summaryList: SummaryList }>()
);
export const getSummaryListFailureAction = createAction(
  '[GetSummaryList/API] Failure',
  props<{ error?: any }>()
);

export const getFilterByPropertyListAction = createAction(
  '[GetRegisterFilterByPropertyList/API] Send Request'
);
export const getFilterByPropertyListSuccessAction = createAction(
  '[GetRegisterFilterByPropertyList/API] Success',
  props<{ listFilterProperty: FilterByPropertyConfig[] }>()
);
export const getFilterByPropertyListFailureAction = createAction(
  '[GetRegisterFilterByPropertyList/API] Failure',
  props<{ errorMsg: string }>()
);

export const setRegisterFilterAction = createAction(
  '[SetRegisterFilters] Set',
  props<{ filters: RegisterFilter[] }>()
);

export const createRegisterFilterAction = createAction(
  '[CreateRegisterFilter/API] Send Request',
  props<{ body: RegisterFilterConfig }>()
);
export const createRegisterFilterSuccessAction = createAction(
  '[CreateRegisterFilter/API] Success'
);
export const createRegisterFilterFailureAction = createAction(
  '[CreateRegisterFilter/API] Failure'
);

export const getRegisterFilterAction = createAction(
  '[GetRegisterFilter/API] Send Request'
);
export const getRegisterFilterSuccessAction = createAction(
  '[GetRegisterFilter/API] Success',
  props<{ listFilterInfo: FilterInformation[] }>()
);
export const getRegisterFilterFailureAction = createAction(
  '[GetRegisterFilter/API] Failure'
);

export const updateFilterNameAction = createAction(
  '[UpdateRegisterFilterName/API] Send Request',
  props<{ registerFilterName: RegisterFilterName }>()
);
export const updateFilterNameSuccessAction = createAction(
  '[UpdateRegisterFilterName/API] Success',
  props<{ id: string; listFilterInfo: FilterInformation[] }>()
);
export const updateFilterNameFailureAction = createAction(
  '[UpdateRegisterFilterName/API] Failure',
  props<{ id: string; listFilterInfo: FilterInformation[] }>()
);

export const removeRegisterFilterAction = createAction(
  '[RemoveRegisterFilter/API] Send Request',
  props<{ id: string }>()
);
export const removeRegisterFilterSuccessAction = createAction(
  '[RemoveRegisterFilter/API] Success',
  props<{ id: string }>()
);
export const removeRegisterFilterFailureAction = createAction(
  '[RemoveRegisterFilter/API] Failure'
);

export const reissueTransactionRegisterAction = createAction(
  '[ReissueTransactionRegister/API] Send Request',
  props<{ body: any }>()
);
export const reissueTransactionRegisterSuccessAction = createAction(
  '[ReissueTransactionRegister/API] Success',
  props<{ paymentType: number; isReissue: boolean; status: number }>()
);
export const reissueTransactionRegisterFailureAction = createAction(
  '[ReissueTransactionRegister/API] Failure',
  props<{
    errorMsg: string;
    paymentType: number;
    isReissue: boolean;
    status: number;
  }>()
);

export const getReversePayeeTransactionAction = createAction(
  '[GetReversePayeeTransaction/API] Send Request',
  props<{ transactionRegisterId: string }>()
);
export const getReversePayeeTransactionSuccessAction = createAction(
  '[GetReversePayeeTransaction/API] Success',
  props<{ reversePayeeTransaction: ReversePayeeTransactionResponse }>()
);
export const getReversePayeeTransactionFailureAction = createAction(
  '[GetReversePayeeTransaction/API] Failure',
  props<{ errorMsg: string; }>()
);

export const reverseTransactionRegisterAction = createAction(
  '[ReverseTransactionRegister/API] Send Request',
  props<{ body: any }>()
);
export const reverseTransactionRegisterSuccessAction = createAction(
  '[ReverseTransactionRegister/API] Success'
);
export const reverseTransactionRegisterFailureAction = createAction(
  '[ReverseTransactionRegister/API] Failure',
  props<{ errorMsg: string; }>()
);

export const createNewAdjustmentAction = createAction(
  '[CreateNewAdjustment/API] Send Request',
  props<{ body: any }>()
);
export const createNewAdjustmentSuccessAction = createAction(
  '[CreateNewAdjustment/API] Success'
);
export const createNewAdjustmentFailureAction = createAction(
  '[CreateNewAdjustment/API] Failure',
  props<{ errorMsg: string }>()
);

export const exportTransactionRegisterAction = createAction(
  '[ExportTransactionRegisterRequest/API] Send Request',
  props<{ request: any }>()
);
export const exportTransactionRegisterSuccessAction = createAction(
  '[ExportTransactionRegisterSuccess/API] Success',
  props<{ data: any }>()
);
export const exportTransactionRegisterFailureAction = createAction(
  '[ExportTransactionRegisterFailure/API] Failure'
);

export const downloadTransactionRegisterAction = createAction(
  '[DownloadransactionRegisterRequest/API] Send Request',
  props<{ fileName: string }>()
);
export const downloadTransactionRegisterSuccessAction = createAction(
  '[DownloadTransactionRegisterSuccess/API] Success'
);
export const downloadTransactionRegisterFailureAction = createAction(
  '[DownloadTransactionRegisterFailure/API] Failure'
);
