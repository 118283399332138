import { ENTITY_TYPE } from "@ptg-member/constants";
import { SideType } from "@ptg-member/types/enums";
import { VIEW_TYPE } from "@ptg-shared/constance/common.const";
import { Row } from "@ptg-shared/controls/grid";

export interface ViewList {
  viewList: View[]
}
export interface entityNavigationItem {
  id: string;
  name: string;
}
export interface View {
  id: string;
  viewName: string;
  type: VIEW_TYPE;
  linkedEntity: string; 
  cardCount: any;
  listEntityNavigationItem: entityNavigationItem[]
}
export interface RemoveAccessResponse {
  listEntityNavigation: {
    id: string;
    name: string;
  }[];
  listProfileConfigView: {
    id: string;
    name: string;
  }[];
}

export interface NewView {
  id?: string;
  name: string;
  type: string;
  entityId: string
}

export interface entityViewItem {
  id?: string;
  name?: string;
  isRemoved?: boolean;
  isRightColumn?: boolean;
  order: number;
  sectionName?: string;
  sectionKey?: string;
  hideEmptyCard?: boolean;
  propertyDisplayPreference?: number,
  listDisplayPreference?: number,
  nonValuePropertyDisplay?: number,
  listDisplayPreference1?: number,
}

export interface ViewDetail {
  entityId?: string,
  entityName?: string,
  entityType?: ENTITY_TYPE,
  entityTypeName?: string,
  entityViewItemList?: entityViewItem[],
  id?: string,
  name?: string,
  cardId?: string;
}

export interface ViewCard {
  id?: string;
  cardId?: string;
  cardName?: string;
  entityName?: string;
  includesList?: boolean;
  totalProperties?: number;
}

export interface SetViewDetailwRequest {
  id?: string;
  name: string;
  cardId?: string;
  entityViewItemList?: (entityViewItem & Row)[];
}

export interface GetCardQuery {
  entityId?: string;
  pageIndex?: number;
  pageSize?: number;
  sortNames?: string[];
  sortType?: number;
  includeSystemCard?: boolean;
}

export interface CreateViewResponse {
  entityId?: string;
  name?: string;
  type?: number;
  id?: string;
}

export interface GetViewListResponse {
  entityViews?: View[];
}

export interface GetListCardResponse { 
  cards: ViewCard[]
}

export interface CardConfiguration {
  id?: string;
  isHideEmptyCard: boolean,
  propertyDisplayPreferenceType: number,
  listDisplayPreferenceType: number,
  nonValuePropertyDisplayType: number,
  nonRecordListDisplayType: number,
}

export interface DatalessCard {
  id?: string,
  name?: string,
  label?: string,
  order?: number,
  chips?: string[],
  configuration?: CardConfiguration,
}

export interface ViewDataless {
  id?: string;
  name?: string;
  cards?: DatalessCard[];
}