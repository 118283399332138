import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { SortType } from '@ptg-shared/constance';

import {
  GetVendorsRequest,
  GetVendorsResponse,
  Vendor,
} from './models';

@Injectable()
export class VendorService {
  constructor(private httpClient: HttpClient) {}

  getVendorsMasterData(
    request: GetVendorsRequest, clientId: string
  ): Observable<GetVendorsResponse> {
    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }

    return this.httpClient.get<GetVendorsResponse>(
      `${environment.apiUrl}/external-vendors`,
      { params }
    );
  }

  checkkUniqueVendorNumber = (vendorNumber: string, vendorId: string, clientId: string) => {
    let params = '';
    if(vendorId){
      params = `vendorId=${vendorId}`
    }
    return this.httpClient.get(
      `${environment.apiUrl}/external-vendors/vendor-number/${vendorNumber}/existing?${params}`
    );
  };


  editVendors(request: Vendor, vendorId: string) {
    return this.httpClient.put(
      `${environment.apiUrl}/external-vendors/${vendorId}`, request
    );
  }

  removeVendor(vendorId: string) {
    return this.httpClient.delete(
      `${environment.apiUrl}/external-vendors/${vendorId}`
    );
  }

}
