import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, startWith, switchMap, take, tap } from 'rxjs/operators';

import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { DISCARD_CONFIRM_MESSAGE } from '@ptg-shared/constance/value.const';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import * as ProfileNavigationConfigurationActions from '@ptg-member/store/actions/profile-navigation-configuration.action';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';

import {
  MemberNavigation,
  SetMemberNavigationRequest,
  Status,
} from '../../types/models';
import * as fromMember from '../../store/reducers';

interface StatusOption {
  key: string;
  value: string;
  color: string;
  iconName: string;
  order: number;
}

@Component({
  selector: 'ptg-edit-profile-navigation',
  templateUrl: './edit-profile-navigation.component.html',
  styleUrls: ['./edit-profile-navigation.component.scss'],
})
export class EditProfileNavigationComponent implements OnInit {
  editForm!: FormGroup;
  formSubmit$ = new Subject<boolean>();
  listStatusOption: StatusOption[] = [];
  listStatusOptionSelected: StatusOption[] = [];
  isInvalidStatus: boolean = false;
  isAllSelected: boolean = false;
  @ViewChild('select') private select!: MatSelect;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditProfileNavigationComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      memberNavigation: MemberNavigation;
      listAllStatus: Status[];
    },
    private fb: FormBuilder,
    private memberStore: Store<fromMember.MemberState>
  ) {}

  ngOnInit(): void {
    this.initData();
    this.initFormGroup(this.data.memberNavigation || ({} as MemberNavigation));
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter((status) => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  initData() {
    this.listStatusOption = this.data.listAllStatus?.map((s: Status, index) => {
      return {
        key: s.name,
        value: s.id,
        color: s.color,
        iconName: s.iconName,
        order: index,
      } as StatusOption;
    });
    this.listStatusOptionSelected = this.listStatusOption?.filter((x) =>
      this.data.memberNavigation?.statusList?.some((s) => x.value === s.id)
    );
    this.listStatusOption = this.listStatusOption?.filter(
      (x) =>
        !this.data.memberNavigation?.statusList?.some((s) => x.value === s.id)
    );
  }

  initFormGroup(formData: MemberNavigation) {
    this.editForm = this.fb.group({
      id: this.fb.control(formData.id),
      disabled: this.fb.control(formData.disabled),
      name: this.fb.control(formData.name, [
        Validators.required,
        Validators.maxLength(100),
      ]),
      color: this.fb.control(formData.color, [
        Validators.required,
        Validators.maxLength(7),
      ]),
    });
  }

  onSubmit() {
    this.isInvalidStatus = this.listStatusOptionSelected.length === 0;
    if (this.isInvalidStatus) {
      return;
    }
    const body: SetMemberNavigationRequest = {
      id: this.editForm.controls.id.value,
      name: this.editForm.controls.name.value,
      color: this.editForm.controls.color.value,
      active: !this.editForm.controls.disabled.value,
      status: this.listStatusOptionSelected?.map((x) => x.value),
    };
    this.memberStore.dispatch(
      ProfileNavigationConfigurationActions.editMemberNavigation({ body })
    );
    this.dialogRef.close();
  }

  addStatus() {
    this.listStatusOptionSelected = this.listStatusOptionSelected.concat(
      this.select.value
    );
    this.listStatusOption = this.listStatusOption.filter(
      (item) => !this.select.value.some((s: StatusOption) => s.key === item.key)
    );
    this.select.value = [];
    this.isInvalidStatus = this.listStatusOptionSelected.length === 0;
  }

  removeStatus(deletedStatus: StatusOption) {
    this.listStatusOptionSelected = this.listStatusOptionSelected.filter(
      (ele: any) => ele !== deletedStatus
    );
    this.listStatusOption.splice(deletedStatus.order, 0, deletedStatus);
    this.isAllSelected =
      this.select.value?.length === this.listStatusOption?.length;
    this.isInvalidStatus = this.listStatusOptionSelected.length === 0;
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: DISCARD_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  toggleAllOption() {
    if (this.isAllSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }

  toggleOneOption() {
    this.isAllSelected =
      this.select.value?.length === this.listStatusOption?.length;
  }
}
