import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateTime } from 'luxon';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { MY_DATE } from 'src/app/shared/controls/datepicker/datepicker.component';

@Component({
  selector: 'ptg-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
})
export class DatetimePickerComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() controlField!: AbstractControl | any;
  @Input() isRequired: boolean = false;
  @Input() isDisabled?: boolean = false;
  @Input() minDate: Date = MY_DATE.minDate;
  @Input() customError: string = '';
  @Input() datePlaceholder: string = 'Date';
  @Input() timePlaceholder: string = 'Time';
  @Input() filterDateTime: boolean = false;
  @Input() filterTimeTo: boolean = false;

  @Output() changeValue = new EventEmitter();

  unsubscribe$ = new Subject<void>();

  editForm: FormGroup = this.fb.group({
    date: '',
    time: '',
  });

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.controlField['_markAsTouched'] = this.controlField.markAsTouched;
    this.controlField.markAsTouched = () => {
      this.controlField['_markAsTouched']();
      this.editForm.markAllAsTouched();
    };
    this.editForm = this.initFormGroup(this.controlField.value);
    this.controlField.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe((currentDate: any) => {
        if (currentDate && !this.controlField.dirty) {
          this.editForm = this.initFormGroup(this.controlField.value);
        }
        if (this.controlField.dirty && this.editForm.get('time')?.value) {
          this.editForm.controls['date'].addValidators(Validators.required);
        } else if (!this.isRequired) {
          this.editForm.controls['date'].removeValidators(Validators.required);
        }
        if (this.isRequired) {
          this.editForm.controls['time'].addValidators(Validators.required);
        } else {
          this.editForm.controls['time'].removeValidators(Validators.required);
        }
        this.editForm.controls['date'].updateValueAndValidity({ emitEvent: false });
        this.editForm.controls['time'].updateValueAndValidity({ emitEvent: false });
      });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.controlField.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe((currentDate: any) => {
        this.editForm = this.initFormGroup(this.controlField.value);
      })
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.checkIsRequiredChange(changes);
    this.editForm = this.initFormGroup(this.controlField.value);
  }

  initFormGroup(currentDateChose?: string) {
    let date: DateTime | string = '';
    let time = '';
    if (currentDateChose && DateTime.fromISO(currentDateChose).isValid) {
      date = DateTime.fromISO(currentDateChose).startOf('day');
      time = DateTime.fromISO(currentDateChose).toFormat('hh:mm a');
    }
    const formGroup = this.fb.group({
      date: this.fb.control(date),
      time: this.fb.control(time),
    });
    if (this.isRequired) {
      formGroup.get('date')?.addValidators(Validators.required);
      formGroup.get('time')?.addValidators(Validators.required);
    }
    return formGroup;
  }

  changeDateTime() {
    this.controlField.markAsDirty();
    if ((this.editForm.get('date')?.value as DateTime)?.isValid) {
      const { hour, minute } = DateTime.fromFormat(this.editForm.get('time')?.value || '12:00 AM', 'h:mm a').toObject();
      const currentDateChose = this.editForm.get('date')?.value.set({ hour: hour, minute: minute }).toUTC().toString();
      this.controlField.setValue(currentDateChose, { emitEvent: false });
    } else {
      this.controlField.setValue('', { emitEvent: false });
    }
    this.editForm.get('date')?.updateValueAndValidity();
    if (this.editForm.get('date')?.errors || this.editForm.get('time')?.errors) {
      this.controlField.setErrors({ invalid: true });
    } else {
      this.controlField.setErrors(null);
    }
    if (this.filterDateTime) {
      if (this.filterTimeTo && this.editForm.valid) {
        this.changeValue.emit(this.controlField.value);
      }
    } else {
      this.changeValue.emit(this.controlField.value);
    }
  }

  checkIsRequiredChange(changes: SimpleChanges) {
    if (!changes.isRequired) {
      return;
    }
    if (this.isRequired) {
      this.controlField.addValidators(Validators.required);
    } else {
      this.controlField.removeValidators(Validators.required);
    }
    this.controlField.updateValueAndValidity({ emitEvent: false });
  }
}
