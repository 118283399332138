import { createAction, props } from "@ngrx/store";
import { BulkUpdateLog } from "../models/bulk-update-log.model";

export const BulkUpdateLogRequest = '[BulkUpdateLog/API] Send Request';
export const BulkUpdateLogSuccess = '[BulkUpdateLog/API] Success';
export const BulkUpdateLogFailure = '[BulkUpdateLog/API] Failure';
export const BulkUpdateCancelRequest = '[BulkUpdateCancel/API] Send Request';
export const BulkUpdateCancelSuccess = '[BulkUpdateCancel/API] Success';
export const BulkUpdateCancelFailure = '[BulkUpdateCancel/API] Failure';
export const BulkUpdateProceedRequest = '[BulkUpdateProceed/API] Send Request';
export const BulkUpdateProceedSuccess = '[BulkUpdateProceed/API] Success';
export const BulkUpdateProceedFailure = '[BulkUpdateProceed/API] Failure';

export const getBulkUpdateLog = createAction(
  BulkUpdateLogRequest,
  props<{ sessionId: string }>()
);
export const getBulkUpdateLogSuccess = createAction(
  BulkUpdateLogSuccess,
  props<{ bulkUpdateLog: BulkUpdateLog }>()
);
export const getBulkUpdateLogFailure = createAction(
  BulkUpdateLogFailure,
  props<{ errorMsg: string }>()
);

export const cancelBulkUpdate = createAction(
  BulkUpdateCancelRequest,
  props<{ sessionId: string }>()
);
export const cancelBulkUpdateSuccess = createAction(
  BulkUpdateCancelSuccess
);
export const cancelBulkUpdateFailure = createAction(
  BulkUpdateCancelFailure,
  props<{ errorMsg: string }>()
);

export const proceedBulkUpdate = createAction(
  BulkUpdateProceedRequest,
  props<{ sessionId: string }>()
);
export const proceedBulkUpdateSuccess = createAction(
  BulkUpdateProceedSuccess
);
export const proceedBulkUpdateFailure = createAction(
  BulkUpdateProceedFailure,
  props<{ errorMsg: string }>()
);