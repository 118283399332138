import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import * as fromMember from '@ptg-member/store/reducers';
import * as MemberPropertyActions from '@ptg-member/store/actions/member-property.actions';
import { MemberPropetyDataByMaskedConfigQuery } from '@ptg-member/types/models';

@Component({
  selector: 'ptg-masked-eye',
  templateUrl: './masked-eye.component.html',
  styleUrls: ['./masked-eye.component.scss'],
})
export class MaskedEyeComponent implements OnInit, OnDestroy {
  @Input() propertyValue!: any;
  @Input() page!: string;
  @Input() memberId!: string;
  @Input() itemKey!: string;
  @Input() propertyKey!: string;
  @Input() index!: string;
  unsubscribe$ = new Subject<void>();

  constructor(private memberStore: Store<fromMember.MemberState>) {}

  ngOnInit(): void {
    this.propertyValue.maskValue = this.propertyValue?.value;
    this.propertyValue.isMasked = true;
    this.memberStore
      .pipe(
        select(fromMember.selectMemberPropertyDataByMaskedConfigState),
        filter(
          (x) =>
            x.isReloading &&
            x.page === this.page &&
            x.memberId === this.memberId &&
            x.itemKey === this.itemKey &&
            x.propertyKey === this.propertyKey &&
            x.index === this.index
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        this.propertyValue.value = state.value;
        this.propertyValue.isMasked = false;
        this.memberStore.dispatch(MemberPropertyActions.clearReloadingState());
      });
  }

  onClickIcon() {
    if (this.propertyValue.isMasked) {
      const query: MemberPropetyDataByMaskedConfigQuery = {
        memberId: this.memberId,
        page: this.page,
        itemKey: this.itemKey,
        propertyKey: this.propertyKey,
        index: this.index,
        optionMasked: false,
      };
      this.memberStore.dispatch(
        MemberPropertyActions.getPropetyDataByMaskedConfigRequest({ query })
      );
    } else {
      this.propertyValue.value = this.propertyValue.maskValue;
      this.propertyValue.isMasked = true;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
