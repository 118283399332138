import {
  AfterContentInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  Optional,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { NgControl } from '@angular/forms';

export const INTEGER: string = '^-?[0-9]+$';
export const POSITIVE_INTEGER: string = '^[0-9]*$';
export const DECIMAL: string = '^-?[0-9]+(.[0-9]+)?$';
export const POSITIVE_DECIMAL: string = '^[0-9]+(.[0-9]+)?$';

export const TYPING_ERROR = /^-?(\d*).?(\d*)?$/;

@Directive({
  selector: '[ptgNumberOnly]'
})
export class NumberOnlyDirective implements AfterContentInit, OnChanges {
  @Input() isPositive: boolean = false;
  @Input() isDecimal: boolean = true;
  @Input() allowZero: boolean = true;
  @Input() isStrict: boolean = true;
  REGEX = new RegExp(POSITIVE_INTEGER);
  TYPING_REGEX = TYPING_ERROR;

  valueBeforeChange: string = '';
  nativeElement;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    @Optional() private control: NgControl
  ) {
    this.nativeElement = element.nativeElement;
  }

  ngAfterContentInit() {
    this.setRegex();
  }

  ngOnChanges(changes: SimpleChanges): void {
    /** Change regex according changing decimal length */
    if (changes.isDecimal) {
      this.setRegex();
    }
    /** After changing decimal length, if value is invalid then ...  */
    if (!this.isMatchRegex(this.nativeElement.value)) {
      this.handleInvalidValue();
    }
  }

  /** Block key */
  @HostListener('keydown', ['$event']) onKeyDown(keyboardEvent: KeyboardEvent) {
    /** Allow "-" (if is positive and is the first char) */
    const currentPosition = (keyboardEvent?.target as HTMLInputElement)?.selectionStart;
    if (
      !this.isPositive
      && (keyboardEvent.keyCode === 109 || keyboardEvent.keyCode === 189)
      && !currentPosition
      && this.nativeElement.value.indexOf('-') === -1
    ) {
      return;
    }

    /** Allow "." (only 1 time, not the first char and not after "-") */
    if (
      this.isDecimal
      && (keyboardEvent.keyCode === 110 || keyboardEvent.keyCode === 190)
      && this.nativeElement.value
      && this.nativeElement.value.indexOf('.') === -1
      && this.nativeElement.value !== '-'
    ) {
      return;
    }

    /** Allow */
    if (
      [46, 8, 9, 27, 13].indexOf(keyboardEvent.keyCode) !== -1  // delete, backspace, tab, Esc, Enter
      || (keyboardEvent.keyCode >= 35 && keyboardEvent.keyCode <= 39) // home, end, left, right
    ) {
      return;
    }

    /** Allow ctrlKey */
    if (keyboardEvent.ctrlKey) {
      return;
    }

    /** Allow digit keys */
    if (
      (keyboardEvent.keyCode >= 48 && keyboardEvent.keyCode <= 57 && !keyboardEvent.shiftKey)
      || (keyboardEvent.keyCode >= 96 && keyboardEvent.keyCode <= 105) // numbers from NumPad
    ) {
      return;
    }

    keyboardEvent.preventDefault();
  }

  @HostListener('input', ['$event']) onInput(event: any) {
    let isInvalid = false;

    // if(event.inputType === 'insertFromPaste' && !this.isMatchRegex(this.nativeElement.value)){
    //   this.handleInvalidValue();
    // }

    if (!this.isMatchRegex(this.nativeElement.value, true)) {
      this.handleInvalidValue();
    }
    /** Not allow decimal */
    const decimal = this.nativeElement.value.toString().split('.');
    if (decimal[1]?.length && !this.isDecimal) {
      this.handleInvalidValue();
    }
    if (!this.allowZero) {
      this.restrictZeroNumber();
    }
    // handle invalid input
    if (isInvalid) {
      this.handleInvalidValue();
    } else {
      this.valueBeforeChange = this.nativeElement.value;
      return;
    }

    this.validateAndHandleValue(true);
  }

  @HostListener('input', ['$event']) onPaste(e: ClipboardEvent) {
    if (!this.isMatchRegex(this.nativeElement.value)) {
      this.handleInvalidValue();
    }
  }

  validateAndHandleValue(isTyping?: boolean) {
    if (this.isMatchRegex(this.nativeElement.value)) {
      this.valueBeforeChange = this.nativeElement.value; // Save value if it's valid
      return;
    }
    if (!isTyping && !this.isMatchRegex(this.nativeElement.value, isTyping)) {
      this.handleInvalidValue();
    }
  }

  handleInvalidValue() {
    if (this.isStrict && this.control) {
      this.nativeElement.value = this.valueBeforeChange;
      this.control.control?.setValue(this.nativeElement.value);
    }
  }

  /** Store value before change */
  @HostListener('focus') onFocus() {
    this.validateAndHandleValue();
  }

  /** Show error for form control */
  @HostListener('focusout') onFocusOut() {
    if (((!this.isStrict && !this.isMatchRegex(this.nativeElement.value)) || this.nativeElement.value === '-') && this.control) {
      this.control.control?.setErrors({ 'invalidFormat': 'Invalid Format' });
      this.control.control?.markAsTouched();
    }
    if (this.nativeElement.value.slice(-1) === '.') {
      this.nativeElement.value = this.nativeElement.value.substring(0, this.nativeElement.value.length - 1);
    }
  }

  /** Accept valid value or Reject invalid value */
  @HostListener('change') onChange() {
    this.validateAndHandleValue();

    if (this.control && this.isStrict) { // If it will show error then not set value for form again
      this.control.control?.setValue(this.nativeElement.value);
    }
  }

  isMatchRegex(value: string, isTyping?: boolean): boolean {
    if (isTyping) {
      return this.TYPING_REGEX.test(value);
    } else {
      return this.REGEX.test(value);
    }
  }

  setRegex(): void {
    if (!this.isDecimal) {
      if (this.isPositive) {
        this.REGEX = new RegExp(POSITIVE_INTEGER);
      } else {
        this.REGEX = new RegExp(INTEGER);
      }
      return;
    }
    if (this.isPositive) {
      this.REGEX = new RegExp(POSITIVE_DECIMAL);
    } else {
      this.REGEX = new RegExp(DECIMAL);
    }
  }

  restrictZeroNumber() {
    if (this.control) {
      while (this.control.control?.value.toString()[0] === '0') {
        this.control.control.setValue(this.control.control.value.toString().slice(1, 4));
        const element = this.element.nativeElement as HTMLInputElement;
        element.setSelectionRange(0, 0);
      }
    }
  }
}
