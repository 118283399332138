import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import { environment } from 'src/environments/environment';
import * as fromReducer from '@ptg-reducers';

import { MemberNotificationOptInsList } from '../types/models';

@Injectable({
  providedIn: 'root',
})
export class MemberNotificationOptInService {
  sectionKey: string = '';
  propertyKey: string = '';
  ssnLabel: any = '';
  activeFund: any;

  constructor(
    private httpClient: HttpClient,
    private store: Store<fromReducer.State>
  ) {
    this.store
      .pipe(select(fromReducer.selectCurrentFundState))
      .subscribe((el) => (this.activeFund = el));
  }

  getNotificationOptIn(
    memberId: string
  ): Observable<MemberNotificationOptInsList> {
    return this.httpClient.get<MemberNotificationOptInsList>(
      `${environment.apiUrl}/Notification/NotificationOptIn/${memberId}`
    );
  }

  editNotificationOptIn(memberId: string, body: any) {
    return this.httpClient.put(
      `${environment.apiUrl}/Members/NotificationOptIn/${memberId}`,
      body
    );
  }
}
