<div class="dialog-container" [ngClass]="{ 'full-screen': isFullScreen }">
  <div class="dialog-header" *ngIf = "title">
    <div class="dialog-title">{{ title }}</div>
  </div>
  <div
    class="dialog-content"
    [ngStyle]="{
      'flex-direction': footerPosition === 'top' ? 'column-reverse' : 'column'
    }"
  >
    <ng-container
      *ngIf="dialogContentHeader"
      [ngTemplateOutlet]="dialogContentHeader.templateRef"
    ></ng-container>
    <div
      class="dialog-body"
      [ngStyle]="{
        'padding-bottom': footerPosition === 'top' && !isFullScreen ? '0' : null
      }"
    >
      <ng-container
        *ngIf="dialogBody"
        [ngTemplateOutlet]="dialogBody.templateRef"
      ></ng-container>
    </div>

    <div
      *ngIf="dialogFooter"
      class="dialog-footer"
      [ngStyle]="{
        'padding-top': footerPosition === 'bottom' ? '0' : null,
        'padding-bottom': footerPosition === 'top' ? '0' : null,
        'justify-content': footerAlign === 'right' ? 'flex-end' : null
      }"
    >
      <ng-container
        [ngTemplateOutlet]="dialogFooter.templateRef"
      ></ng-container>
    </div>
  </div>
</div>
