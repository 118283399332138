import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';

import * as PropertyActions from '../../store/actions/property.actions';
import {
  MetadataPropertyTypeView,
  PropertyNavType,
} from '../../types/models/metadata.model';
import { MetadataService } from '../../services/metadata.service';

@Injectable()
export class PropertyEffects {
  getMetadataPropertyTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PropertyActions.MetadataPropertyTypesRequest),
      switchMap((navType: PropertyNavType) => {
        return this.metadataService.getMetadataPropertyTypes(navType).pipe(
          map((res: MetadataPropertyTypeView) => {
            let propTypes = res.propertyTypes;
            return PropertyActions.getMetadataPropertyTypesSuccess({
              propertyTypes: propTypes,
            });
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private metadataService: MetadataService
  ) {}
}
