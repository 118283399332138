
    <div class="flex flex-col pt-6 pb-6 pl-64 pr-64 gap-6">
      <ptg-banner
        *ngIf="bannerType"
        [(bannerType)]="bannerType"
        [message]="message"
      ></ptg-banner>
  
      <div class="box">
        <div class="title">
          <span class="title-name">Display Format</span>
          <div class="separator"></div>
          <div class="button-group">
            <ptg-button
              buttonName="New Screen"
              classInput="edit-button"
              (clickButton)="editValue()"
            >
              <mat-icon>add</mat-icon>
            </ptg-button>
          </div>
        </div>
        <ptg-grid
          [data]="lookupTableDetailsData"
          [columns]="columns"
          keyColumn="id"
          [isLoading]="isLoading"
          [errorMessage]="errorMsg"
          [paginable]="false"
          notFoundMessage="No Value to Display"
        >
          <ng-template cellContent columnName="showId" let-row>
            <mat-icon class="icon-table" *ngIf="row.showId">
              check_circle
            </mat-icon>
          </ng-template>
          <ng-template cellContent columnName="showDescription" let-row>
            <mat-icon class="icon-table" *ngIf="row.showDescription">
              check_circle
            </mat-icon>
          </ng-template>
          <ng-template cellContent columnName="showLongDescription" let-row>
            <mat-icon class="icon-table" *ngIf="row.showLongDescription">
              check_circle
            </mat-icon>
          </ng-template>
          <ng-template cellContent columnName="action" let-row>
            <ptg-button
              buttonName="Edit"
              classInput="edit-button"
              (clickButton)="editValue(row)"
            >
              <mat-icon>edit</mat-icon>
            </ptg-button>
          </ng-template>
        </ptg-grid>
      </div>
    </div>
