import { createSelector } from '@ngrx/store';

import { selectCalculationModuleState } from './selector';

export const calculationListSelector = createSelector(selectCalculationModuleState, (state) => state?.calculationList);

export const selectRetirementBenefitCalculationState = createSelector(
  calculationListSelector,
  (state) => state.retirementBenefitCalculationListState,
);

export const selectUploadCalculationFileState = createSelector(
  calculationListSelector,
  (state) => state.uploadCalculationFileState,
);

export const selectRetirementBenefitCalculationDetailState = createSelector(
  calculationListSelector,
  (state) => state.calculationDetailState,
);

export const selectCalculationInputMappingState = createSelector(
  calculationListSelector,
  (state) => state.calculationInputMapping,
);

export const selectCalculationOutputMappingState = createSelector(
  calculationListSelector,
  (state) => state.calculationOutputMapping,
);

export const selectRemoveCalculationFileState = createSelector(
  calculationListSelector,
  (state) => state.removeCalculationFileState,
);

export const parameterMappingActionSelector = createSelector(
  calculationListSelector,
  (state) => state?.parameterMappingActionState,
);

export const getFileParametersSelector = createSelector(calculationListSelector, (state) => state?.getFileParameters);

export const getListSavePropertySelector = createSelector(calculationListSelector, (state) => state?.saveToProperty);
