import { createAction, props } from '@ngrx/store';
import {
  GetRemittanceFeeInvoiceRequest,
  GetRemittanceFeeInvoiceResponse,
  PrintRemittanceFeeInvoiceRequest,
  ValidateBeforePrintFeeInvoiceRequest,
  ValidateBeforePrintFeeInvoiceResponse,
} from '@ptg-employer/models/remittance-print-invoice.model';

/* Start of GetRemittanceFeeInvoice */
export const getRemittanceFeeInvoiceAction = createAction(
  '[GetRemittanceFeeInvoice/API] Send Request',
  props<{ request: GetRemittanceFeeInvoiceRequest }>(),
);

export const getRemittanceFeeInvoiceSuccessAction = createAction(
  '[GetRemittanceFeeInvoice/API] Success',
  props<{ response: GetRemittanceFeeInvoiceResponse }>(),
);

export const getRemittanceFeeInvoiceFailureAction = createAction(
  '[GetRemittanceFeeInvoice/API] Failure',
  props<{ error?: any }>(),
);

export const clearRemittanceFeeInvoiceStateAction = createAction('[GetRemittanceFeeInvoice] Clear');
/* End of GetRemittanceFeeInvoice */

/* Start of ValidateBeforePrintFeeInvoice */
export const validateBeforePrintFeeInvoiceAction = createAction(
  '[ValidateBeforePrintFeeInvoice/API] Send Request',
  props<{ request: ValidateBeforePrintFeeInvoiceRequest }>(),
);

export const validateBeforePrintFeeInvoiceSuccessAction = createAction(
  '[ValidateBeforePrintFeeInvoice/API] Success',
  props<{ response: ValidateBeforePrintFeeInvoiceResponse }>(),
);

export const validateBeforePrintFeeInvoiceFailureAction = createAction(
  '[ValidateBeforePrintFeeInvoice/API] Failure',
  props<{ error?: any }>(),
);

export const clearValidateBeforePrintFeeInvoiceStateAction = createAction('[ValidateBeforePrintFeeInvoice] Clear');
/* End of ValidateBeforePrintFeeInvoice */

/* Start of PrintRemittanceFeeInvoice */
export const printRemittanceFeeInvoiceAction = createAction(
  '[PrintRemittanceFeeInvoice/API] Send Request',
  props<{ request: PrintRemittanceFeeInvoiceRequest }>(),
);

export const printRemittanceFeeInvoiceSuccessAction = createAction(
  '[PrintRemittanceFeeInvoice/API] Success',
  props<{ response: Blob[] }>(),
);

export const printRemittanceFeeInvoiceFailureAction = createAction(
  '[PrintRemittanceFeeInvoice/API] Failure',
  props<{ error?: any }>(),
);

export const clearPrintRemittanceFeeInvoiceStateAction = createAction('[PrintRemittanceFeeInvoice] Clear');
/* End of PrintRemittanceFeeInvoice */
