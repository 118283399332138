export enum ParameterType {
  System = 0,
  Generic = 1,
}

export enum ParameterTypeDisplay {
  System = 'System',
  Generic = 'Generic',
}

export enum ParameterRule {
  'Get Value' = 0,
  Condition = 1,
}

export enum ParameterRuleDisplay {
  GetValue = 'Get Value',
  Condition = 'Condition',
}

export const ListParameterType = [
  {
    value: ParameterType.System,
    displayValue: ParameterTypeDisplay.System
  },
  {
    value: ParameterType.Generic,
    displayValue: ParameterTypeDisplay.Generic
  },
]

export const ListParameterRule = [
  {
    value: ParameterRule["Get Value"],
    displayValue: ParameterRuleDisplay.GetValue
  },
  {
    value: ParameterRule.Condition,
    displayValue: ParameterRuleDisplay.Condition
  },
]

export enum MergeFieldsError {
  Ok,
  MissingTag,
  WrongTagOrder,
}

export const DOCUMENT_TEMPLATE = {
  AGE65_LETTER: 'Age 65 Letter',
}
