import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as fromReducer from 'src/app/reducers';

import { LayoutActions } from '@ptg-shared/layout/actions';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { showBanner } from '@ptg-shared/utils/common.util';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ACTION, STATE } from '@ptg-shared/constance/value.const';
import {
  ACTION_COLUMN,
  Align,
  Column,
  ReorderInfo,
} from '@ptg-shared/controls/grid';

import * as fromMember from '../../store/reducers';
import * as ProfileNavigationConfigurationActions from '../../store/actions/profile-navigation-configuration.action';
import {
  MemberNavigation,
  MemberNavigationItem,
  RemoveMemberNavigationItem,
  Status,
} from '../../types/models';
import { EditProfileNavigationComponent } from '../../components/edit-profile-navigation/edit-profile-navigation.component';
import { EditProfileNavigationItemComponent } from '../../components/edit-profile-navigation-item/edit-profile-navigation-item.component';

@Component({
  selector: 'ptg-profile-navigation-configuration-detail',
  templateUrl: './profile-navigation-configuration-detail.component.html',
  styleUrls: ['./profile-navigation-configuration-detail.component.scss'],
})
export class ProfileNavigationConfigurationDetailComponent implements OnInit {
  unsubscribe$ = new Subject<void>();
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Overview',
      url: '',
    },
    {
      name: 'Navigation Configuration',
      url: '',
    },
    {
      name: '',
      url: '',
    },
  ];
  columns: Column[] = [
    {
      name: 'name',
      header: {
        title: 'Menu Item',
      },
      sortable: false,
    },
    {
      name: 'itemName',
      header: {
        title: 'Metadata Section',
      },
      sortable: false,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      align: Align.Center,
      width: '170px',
    },
  ];
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  errorMsg?: string;
  isLoading: boolean = true;
  memberNavigation!: MemberNavigation;
  dataTable!: any[];
  listAllStatus!: Status[];

  constructor(
    private memberStore: Store<fromMember.MemberState>,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private store: Store<fromReducer.State>
  ) {}

  ngOnInit(): void {
    this.store.dispatch(LayoutActions.hiddenSideMenu());
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.listBreadcrumbs[0].url = `/member/detail/${params.memberId}`;
      this.listBreadcrumbs[1].url = `/member/profile-navigation-configuration/${params.memberId}`;
      this.getDetail(params.id);
    });

    this.memberStore
      .pipe(
        select(fromMember.selectMemberNavigationDetailState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        this.isLoading = state.isLoading;

        // Get an error message (if any)
        if (state.error) {
          this.errorMsg = state.error.statusText;
        }

        this.listAllStatus = state.listAllStatus;
        this.memberNavigation = state.memberNavigationDetail;
        this.dataTable = state.memberNavigationDetail?.menuItems;
        this.listBreadcrumbs[2].name = state.memberNavigationDetail?.name;
        showBanner.call(this, state.updateMenuState || '', 'Menu', ACTION.EDIT);
        showBanner.call(
          this,
          state.createItemState || '',
          'Menu Item',
          ACTION.ADD
        );
        showBanner.call(
          this,
          state.updateItemState || '',
          'Menu Item',
          ACTION.EDIT
        );
        showBanner.call(
          this,
          state.removeItemState || '',
          'Menu Item',
          ACTION.REMOVE
        );

        if (
          state.updateMenuState == STATE.SUCCESS ||
          state.createItemState == STATE.SUCCESS ||
          state.updateItemState == STATE.SUCCESS ||
          state.removeItemState == STATE.SUCCESS
        ) {
          this.getDetail(state.memberNavigationDetail?.id);
        }
        if (
          state.updateMenuState ||
          state.orderItemState ||
          state.createItemState ||
          state.updateItemState ||
          state.removeItemState
        ) {
          this.memberStore.dispatch(
            ProfileNavigationConfigurationActions.clearStateMemberNavigation()
          );
        }
      });
  }

  getDetail(menuId: string) {
    if (!menuId) {
      return;
    }
    this.memberStore.dispatch(
      ProfileNavigationConfigurationActions.getMemberNavigationDetail({
        menuId,
      })
    );
  }

  onEditMenu() {
    if (!this.memberNavigation) {
      return;
    }
    this.dialog.open(EditProfileNavigationComponent, {
      panelClass: 'dialog-full-screen',
      autoFocus: false,
      disableClose: true,
      data: {
        memberNavigation: this.memberNavigation,
        listAllStatus: this.listAllStatus.filter((x) => x.id !== 'All'),
      },
    });
  }

  editMenuItem(memberNavigationItem?: MemberNavigationItem) {
    const newOrder =
      this.memberNavigation?.menuItems?.length > 0
        ? Math.max(...this.memberNavigation?.menuItems.map((x) => x.order)) + 1
        : 1;
    this.dialog.open(EditProfileNavigationItemComponent, {
      panelClass: ['edit-menu-item-popup'],
      autoFocus: false,
      disableClose: true,
      data: {
        memberNavigation: this.memberNavigation,
        memberNavigationItem,
        newOrder,
      },
    });
  }

  changeItemIndex(event: ReorderInfo) {
    this.store.dispatch(
      ProfileNavigationConfigurationActions.reorderMemberNavigationItem({
        menuId: this.memberNavigation?.id,
        body: {
          reorderItemId: event.reorderItemKey,
          upperAdjacentId: event.upperAdjacentKey,
        },
      })
    );
  }

  removeRecord(memberNavigationItem: MemberNavigationItem) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        title: 'Remove Menu Item',
        text: `Are you sure you want to remove this Menu Item?`,
        type: ConfirmType.Delete,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const body: RemoveMemberNavigationItem = {
          id: memberNavigationItem?.id,
          memberNavigationId: this.memberNavigation?.id,
        };
        this.store.dispatch(
          ProfileNavigationConfigurationActions.removeMemberNavigationItem({
            body,
          })
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
