import { createAction, props } from "@ngrx/store";
import { BulkUpdateQuery, BulkUpdateHistoryData } from "../models/bulk-update.model";

export const BulkUpdateHistoryRequest = '[BulkUpdateHistory/API] Send Request';
export const BulkUpdateHistorySuccess = '[BulkUpdateHistory/API] Success';
export const BulkUpdateHistoryFailure = '[BulkUpdateHistory/API] Failure';

export const RemoveBulkUpdateHistoryRequest = '[RemoveBulkUpdateHistory/API] Send Request';
export const RemoveBulkUpdateHistorySuccess = '[RemoveBulkUpdateHistory/API] Success';
export const RemoveBulkUpdateHistoryFailure = '[RemoveBulkUpdateHistory/API] Failure';
export const RemoveBulkUpdateHistoryClear = '[RemoveBulkUpdateHistory] Clear';

export const getBulkUpdateHistory = createAction(
  BulkUpdateHistoryRequest,
  props<{ initialLoad: boolean, target: string, targetType: string, pagination: BulkUpdateQuery }>()
);
export const getBulkUpdateHistorySuccess = createAction(
  BulkUpdateHistorySuccess,
  props<{ bulkUpdateHistoryData: BulkUpdateHistoryData }>()
);
export const getBulkUpdateHistoryFailure = createAction(
  BulkUpdateHistoryFailure,
  props<{ error?: any }>()
);

export const removeBulkUpdateHistory = createAction(
  RemoveBulkUpdateHistoryRequest,
  props<{ sessionId: string}>()
);
export const removeBulkUpdateHistorySuccess = createAction(
  RemoveBulkUpdateHistorySuccess
);
export const removeBulkUpdateHistoryFailure = createAction(
  RemoveBulkUpdateHistoryFailure,
  props<{ errorMsg: string }>()
);
export const removeBulkUpdateHistoryClear = createAction(
  RemoveBulkUpdateHistoryClear
);