import { createAction, props } from '@ngrx/store';
import { GetListRequest, GetSubHeaderConfigurationsListResponse, GetSubHeaderDataResponse, SetSubHeaderConfigurationDetailRequest, SubHeaderConfigurationDetailResponse } from '../../services/models/subheader-configuration.model';

// Get List Sub-Header Configurations
export const getSubHeaderConfigurationsListAction = createAction(
  '[GetSubHeaderConfigurations/API] Send Request',
  props<{ memberId: string, request: GetListRequest }>(),
);
export const getSubHeaderConfigurationsListSuccessAction = createAction(
  '[GetSubHeaderConfigurations/API] Success',
  props<{ response: GetSubHeaderConfigurationsListResponse }>(),
);
export const getSubHeaderConfigurationsListFailureAction = createAction(
  '[GetSubHeaderConfigurations/API] Failure',
  props<{ error?: unknown }>(),
);
export const clearGetSubHeaderConfigurationsListStateAction = createAction(
  '[GetSubHeaderConfigurations/API] Clear',
);

// Get Sub-Header Configuration Detail
export const getSubHeaderConfigurationDetailAction = createAction(
  '[GetSubHeaderConfigurationDetail/API] Send Request',
  props<{ memberId: string, subHeaderId: string }>(),
);
export const getSubHeaderConfigurationDetailSuccessAction = createAction(
  '[GetSubHeaderConfigurationDetail/API] Success',
  props<{ response: SubHeaderConfigurationDetailResponse }>(),
);
export const getSubHeaderConfigurationDetailFailureAction = createAction(
  '[GetSubHeaderConfigurationDetail/API] Failure',
  props<{ error?: any }>(),
);
export const clearGetSubHeaderConfigurationDetailStateAction = createAction(
  '[GetSubHeaderConfigurationDetail/API] Clear',
);

// Config Sub-Header Configuration
export const setSubHeaderConfigurationDetailAction = createAction(
  '[SetSubHeaderConfigurationsDetail/API] Send Request',
  props<{ memberId: string; subHeaderId: string, request: SetSubHeaderConfigurationDetailRequest }>(),
);
export const setSubHeaderConfigurationDetailSuccessAction = createAction(
  '[SetSubHeaderConfigurationsDetail/API] Success'
);
export const setSubHeaderConfigurationDetailFailureAction = createAction(
  '[SetSubHeaderConfigurationsDetail/API] Failure',
  props<{ errorMsg?: any }>(),
);
export const clearSetSubHeaderConfigurationDetailStateAction = createAction(
  '[SetSubHeaderConfigurationsDetail] Clear'
);

// Get Sub-Header Data
export const getSubHeaderDataAction = createAction(
  '[GetSubHeaderData/API] Send Request',
  props<{ memberId: string }>(),
);
export const getSubHeaderDataSuccessAction = createAction(
  '[GetSubHeaderData/API] Success',
  props<{ response: GetSubHeaderDataResponse }>(),
);
export const getSubHeaderDataFailureAction = createAction(
  '[GetSubHeaderData/API] Failure',
  props<{ error?: any }>(),
);
export const clearGetSubHeaderDataStateAction = createAction(
  '[GetSubHeaderData/API] Clear',
);
