<div class="dialog-container">
  <div class="header-title">Edit List Configuration</div>
  <form class="edit-form" [formGroup]="editForm">
    <div class="input-container">
      <ptg-textbox
        [controlField]="editForm.get('name')"
        placeholder="List Name"
        [hasLabel]="true"
        [isRequired]="true"
        [maxLength]="250"
        errorAsync="List Name already exists."
      ></ptg-textbox>

      <ptg-toggle-button
        *ngIf="listDetail?.isParticipant"
        [controlField]="editForm.get('bulkUpload')"
        label="Enable Bulk Update"
        [isIncludeChild]="true"
        (valueChange)="changeToggleBulkUpdate()"
      ></ptg-toggle-button>
      <div class="import-content">
        <ptg-textbox
          *ngIf="listDetail?.isParticipant && editForm.value.bulkUpload"
          [controlField]="editForm.get('importLabel')"
          placeholder="Import Label"
          [hasLabel]="true"
          [maxLength]="250"
          [isRequired]="true"
          errorAsync="Import Label already exists."
        ></ptg-textbox>
      </div>
      <div class="container-toggle" *ngIf="listDetail?.isParticipant">
        <ptg-toggle-button
          [controlField]="editForm.get('editByCreatorOnly')"
          label="Edit by Creator Only"
          (valueChange)="changeToggleBulkUpdate()"
        ></ptg-toggle-button>
        <ptg-toggle-button 
          [controlField]="editForm.get('removeByCreatorOnly')"
          label="Remove by Creator Only"
          (valueChange)="changeToggleBulkUpdate()"
          class="remove-toggle"
        ></ptg-toggle-button>
      </div>
    </div>
    <div class="row-button">
      <button
        mat-raised-button
        type="button"
        class="primary"
        (click)="formSubmit$.next(true)"
      >
        Save
      </button>
      <button
        mat-raised-button
        type="button"
        class="cancel-button"
        (click)="onCancel()"
      >
        Cancel
      </button>
    </div>
  </form>
</div>
