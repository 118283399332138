import { Component, Inject, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { SectionLayout } from '@ptg-member/constance/metadata.const';
import { CALCULATION_OPTION } from '@ptg-member/features/calculation/constants';
import {
  CalculationStep,
  GetGetEntityComponentListDataByComponentIdsRequest,
  MemberEvent,
  SetBenefitDetailParams,
  SetBenefitDetailProperties,
  SetBenefitDetailRequest,
  Status,
  StepConfigurationValuesRequest,
} from '@ptg-member/features/calculation/services/models';
import { RetirementBenefitDataInput } from '@ptg-member/features/calculation/services/models/beneficiary-confirmation.model';
import { CalculationBenefitDetailType } from '@ptg-member/features/calculation/services/models/retirement-benefit-detail.model';
import { BaseComponent } from '@ptg-shared/components';
import {
  ACTION,
  CANCEL_CONFIRM_MESSAGE,
  STATE,
} from '@ptg-shared/constance';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import {
  InputType,
  PropertyType,
} from '@ptg-shared/controls/dynamic-input/types/enums/dynamic-input.enum';
import { Option } from '@ptg-shared/controls/select/select.component';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { LookupTableType } from '@ptg-shared/types/enums';
import {
  FieldData,
  FormControlDetail,
  OptionValue,
} from '@ptg-shared/types/models';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { deepClone, showBanner } from '@ptg-shared/utils/common.util';
import {
  setFractionalLength,
  stringToBoolean,
} from '@ptg-shared/utils/string.util';
import { DateTime } from 'luxon';
import { filter, takeUntil } from 'rxjs/operators';
import {
  CalculationState,
  clearSetBenefitDetailStateAction,
  getGetEntityComponentListDataByComponentIdsAction,
  getGetEntityComponentListDataByComponentIdsSelector,
  getSetBenefitDetailSelector,
  getStepConfigurationDetailByBenefitOptionAction,
  getStepConfigurationSelector,
  setBenefitDetailAction,
  StepConfigurationState,
} from '../../store';
import { AddressField, CalculationType, PersonNameField } from '../../types/enums';

@Component({
  selector: 'ptg-beneficiary-confirmation',
  templateUrl: './beneficiary-confirmation.component.html',
  styleUrls: ['./beneficiary-confirmation.component.scss'],
})
export class BeneficiaryConfirmationComponent
  extends BaseComponent
  implements OnInit
{
  listBreadcrumbs: Breadcrumb[] = [{ name: 'Beneficiary Confirmation' }];

  formData = this.initFormData();
  selectedBeneficiary: FormControl = this.fb.control('');
  beneficiaryOptions: Option[] = [];
  formControlDetails: FormControlDetail[] = [];
  sectionLayout = SectionLayout;
  private listInputAddress: FieldData[] = [
    new FieldData('street1', 'Street1', 'Street1', 'text', true, '46', 'w380'),
    new FieldData(
      'street2',
      'Street2',
      'Street 2 (Apartment No./Suite No.)',
      'text',
      false,
      '46',
      'w380',
    ),
    new FieldData('city', 'City', 'City', 'text', true, 'none', 'w182'),
    new FieldData(
      'state',
      'State',
      'State/Province/Territory',
      'select',
      true,
      'none',
      'w182',
    ),
    new FieldData(
      'zipCode',
      'ZipCode',
      'Zip Code/Postal Code',
      'text',
      true,
      '10',
      'w182',
    ),
    new FieldData(
      'country',
      'Country',
      'Country',
      'select',
      false,
      'none',
      'w182',
    ),
  ];
  private listInputPersonName: FieldData[] = [
    new FieldData(
      'prefix',
      'Prefix',
      'Prefix',
      'select',
      false,
      'none',
      'w167',
    ),
    new FieldData(
      'first',
      'First',
      'First Name',
      'text',
      true,
      'none',
      'w152 first-name',
    ),
    new FieldData(
      'middle',
      'Middle',
      'Middle Name',
      'text',
      false,
      'none',
      'w152',
    ),
    new FieldData(
      'last',
      'Last',
      'Last Name',
      'text',
      true,
      'none',
      'w152 last-name',
    ),
    new FieldData('suffix', 'Suffix', 'Suffix', 'text', false, 'none', 'w89'),
  ];
  listStatus: Status[] = [];
  metadata: any[] = [];
  isRequiredBeneficiary: boolean = false;

  entityColumns: any[] = [];
  entityRows: any[] = [];

  get propertiesFormArray() {
    return this.formData.get('properties') as FormArray;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: RetirementBenefitDataInput,
    readonly route: ActivatedRoute,
    private readonly fb: FormBuilder,
    private readonly dialog: MatDialog,
    private readonly calculationStore: Store<CalculationState>,
    private readonly dialogRef: MatDialogRef<BeneficiaryConfirmationComponent>,
    public stepConfigurationsStore: Store<StepConfigurationState>,
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.data.memberId || !this.data.sectionKey) {
      return;
    }
    this.selectSetBenefitDetailState();
    this.getEntityComponentList();
    this.selectorGetEntityComponent();
  }

  getEntityComponentList() {
    const request: GetGetEntityComponentListDataByComponentIdsRequest = {
      entityComponentId: this.data.sectionKey,
      memberId: this.data.memberId
    };
    this.calculationStore.dispatch(
      getGetEntityComponentListDataByComponentIdsAction({ request }),
    );
  }

  selectorGetEntityComponent() {
    this.stepConfigurationsStore
      .select(getGetEntityComponentListDataByComponentIdsSelector)
      .pipe(
        filter((result) => !!result && !result?.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        if (response?.success && !response?.isLoading) {
          this.entityColumns = response?.payload?.columns.map((column: any) => {
            return {
              ...column,
              type: PropertyType[column.type]
            }
          });
          this.entityRows = response?.payload?.rows;
          const firstColumn = this.entityColumns[0];
          const getRowsValueByFirstColumn = this.entityRows.map(item => {
            return {
              displayValue: item[firstColumn.entityPropertyId],
              value: item.Id
            }
          })
          this.beneficiaryOptions = this.getBeneficiaryOptions(getRowsValueByFirstColumn, firstColumn);
          this.formControlDetails = this.getFormControlDetails();
          this.initPropertiesFormArray();
          if (this.data.rowId) {
            this.selectedBeneficiary.setValue(this.data.rowId)
            this.onChangeBeneficiary();
          }
        }
      });
  }

  onNext(): void {
    this.selectedBeneficiary.markAsTouched();
    if (!this.selectedBeneficiary.valid) {
      return;
    }
    this.propertiesFormArray.markAllAsTouched();
    if (this.selectedBeneficiary.value && this.propertiesFormArray.invalid) {
      return;
    }
    this.setBenefitDetail();
  }

  getPropertyValue(value: any, type: PropertyType) {
    if (type === PropertyType.Date) {
      return !value ? '' : DateTime.fromISO(value).toFormat('yyyy-MM-dd');
    }
    if (type === PropertyType.Binary) {
      return value.toString();
    }
    return value;
  }

  selectGetStepConfiguration() {
    this.stepConfigurationsStore
      .select(getStepConfigurationSelector)
      .pipe(
        filter((result) => !!result && !result?.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        const byPass = stringToBoolean(
          this.getConfigurationByKey(
            response?.payload?.calculationSteps ?? [],
            2,
            CALCULATION_OPTION.ByPass,
          ),
        );
        this.isRequiredBeneficiary = !byPass;
      });
    const request: StepConfigurationValuesRequest = {
      memberId: this.data.memberId,
      benefitEntityId: this.data.benefitEntityId ?? '',
    };
    this.stepConfigurationsStore.dispatch(
      getStepConfigurationDetailByBenefitOptionAction(request),
    );
  }

  onCancel(): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: CANCEL_CONFIRM_MESSAGE,
        type: ConfirmType.Cancel,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  onChangeBeneficiary(): void {
    const currentData = this.entityRows.find(row => row.Id === this.selectedBeneficiary.value)
    this.setValue(currentData);
  }

  getStepConfiguration(calculationSteps: CalculationStep[], order: number) {
    return calculationSteps?.find((item) => item.order === order);
  }

  private getBeneficiaryOptions(data: any[], firstColumn: any) {
    const { type } = firstColumn;

    const dropdownValues = deepClone(data).map((item) => {
      return {
        ...item,
        displayValue: Object.keys(item.displayValue).includes('originalValue')
          ? item.displayValue.originalValue
          : item.displayValue,
      };
    });

    switch (type) {
      case InputType.Person_Name:
        return dropdownValues.map((item) => {
          return {
            value: item.value,
            displayValue: this.getDisplayValue(item.value, [
              PersonNameField.First,
              PersonNameField.Last,
            ]),
          };
        });
      case InputType.Address:
        return dropdownValues.map((item) => {
          return {
            value: item.value,
            displayValue: this.getDisplayValue(item.displayValue, [
              AddressField.Street1,
              AddressField.City,
              AddressField.State,
              AddressField.ZipCode,
              AddressField.Country,
            ]),
          };
        });
      default:
        return dropdownValues.map((item) => ({
          value: item.value,
          displayValue: item.displayValue,
        }));
    }
  }

  private getDisplayValue(value: any, keys: string[]): string {
    return keys
      .map((key) => {
        value[key];
      })
      .join(' ');
  }

  getConfigurationByKey(
    calculationSteps: CalculationStep[],
    order: number,
    key: string,
  ) {
    return (
      this.getStepConfiguration(
        calculationSteps,
        order,
      )?.calculationStepOptions?.find((item) => item.key === key)?.value ?? ''
    );
  }

  private initFormData() {
    return this.fb.group({
      properties: this.fb.array([]),
    });
  }

  selectSetBenefitDetailState() {
    this.calculationStore
      .select(getSetBenefitDetailSelector)
      .pipe(
        filter(
          (setBenefitDetail) =>
            !!setBenefitDetail && !setBenefitDetail.isLoading,
        ),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((setBenefitDetail) => {
        if (setBenefitDetail?.error) {
          showBanner.call(
            this,
            STATE.FAIL,
            setBenefitDetail.payload?.editItem ?? 'Beneficiary Confirmation',
            ACTION.EDIT,
          );
          this.calculationStore.dispatch(clearSetBenefitDetailStateAction());
          return;
        }
        this.dialogRef.close(true);
      });
  }

  private setBenefitDetail() {
    const formValue = this.propertiesFormArray.getRawValue();
    const params: SetBenefitDetailParams = {
      memberId: this.data.memberId,
      calculationBenefitId: this.data.calculationBenefitId ?? '',
      detailType: CalculationBenefitDetailType.Beneficiary,
    };
    const selectedBeneficiary = {
      benefitDetailKey: 'rowId',
      type: PropertyType.Text,
      value: this.selectedBeneficiary.value,
      userOverridden: ''
    };
    if (this.selectedBeneficiary.value) {
    }
    const properties: SetBenefitDetailProperties[] = !this.selectedBeneficiary
      .value
      ? []
      : formValue.map((property: any) => {
          const type = (PropertyType as any)[property.type];
          return {
            benefitDetailKey: property.benefitDetailKey,
            type,
            value: this.getPropertyValue(property.value, type),
            userOverridden: ''
          };
        });
    const request: SetBenefitDetailRequest = {
      // TODO: implement later
      properties: [selectedBeneficiary, ...properties],
      benefitEntityId: this.data.benefitEntityId ?? '',
      targetType: CalculationType.RetirementBenefit,
      targetId: this.data.calculationBenefitId,
    };
    this.calculationStore.dispatch(
      setBenefitDetailAction({
        params,
        request,
        editItem: 'Beneficiary Confirmation',
      }),
    );
  }

  private setValue(currentData?: any) {
    this.propertiesFormArray.disable();
    this.formControlDetails.forEach((element, index) => {
      if (!element?.entityPropertyId) return;
      const formValue = currentData[element?.entityPropertyId];
      switch (element.type) {
        case InputType.Address:
        case InputType.Person_Name:
          (this.propertiesFormArray?.controls[index] as FormGroup)?.controls['value']?.patchValue(formValue);
          break;
        case InputType.Binary:
          (this.propertiesFormArray?.controls[index] as FormGroup)?.controls['value']?.setValue(
            stringToBoolean(formValue),
          );
          break;
        case InputType.Ssn:
        case InputType.Email:
        case InputType.Phone:
          (this.propertiesFormArray?.controls[index] as FormGroup)?.controls['value']?.setValue(formValue.originalValue);
          break;
        default:
          if (element?.fractionalLength) {
            const fractionalLengthValue = setFractionalLength(formValue, element?.fractionalLength);
            (this.propertiesFormArray?.controls[index] as FormGroup)?.controls['value']?.setValue(
              (+formValue)?.toFixed(fractionalLengthValue),
            );
          } else {
            (this.propertiesFormArray?.controls[index] as FormGroup)?.controls['value']?.setValue(formValue);
          }
          break;
      }
    });
  }

  private initPropertiesFormArray(savedProperty?: any) {
    this.propertiesFormArray.clear();
    this.formControlDetails.forEach((property: any) => {
      this.propertiesFormArray.push(
        this.fb.group({
          propertyKey: property.entityPropertyName,
          benefitDetailKey: property.entityPropertyId,
          value: this.initDefaultForm(property),
          type: property.type,
        }),
      );
    });
  }

  private initDefaultForm(property: any) {
    if (property.type === InputType.Address || property.type === InputType.Person_Name) {
      const form: FormGroup = new FormGroup({});
      property.lstChildFormControl.forEach((childControl: FieldData) => {
        form.addControl(
          childControl.key,
          new FormControl(
            childControl.formControl?.value,
            childControl.formControl?.validator,
            childControl.formControl?.asyncValidator,
          ),
          { emitEvent: false },
        );
      });
      return form;
    }
    return this.fb.control(property.value ?? '');
  }

  private getFormControlDetails() {
    return (this.entityColumns ?? []).map((property) => {
      if (
        property.type === InputType.Address ||
        property.type === InputType.Person_Name
      ) {
        // Address type | Person name type
        let lstChildForm =
          property.type === InputType.Address
            ? deepClone(this.listInputAddress)
            : deepClone(this.listInputPersonName);
        lstChildForm = deepClone(lstChildForm).map((fieldData: FieldData) => {
          const formControl = new FormControl(null);
          if (
            fieldData.type === 'select' &&
            property.configs?.prefixList &&
            property.options?.length
          ) {
            fieldData.lstOption = property.options.map((x: any) => {
              return {
                displayValue: x.text,
                value: x.id,
              };
            });
          }
          if (fieldData.key === 'state' && property.options?.length) {
            fieldData.lstOption = deepClone(property)
              .options.filter(
                (option: OptionValue) => option.type === LookupTableType.State,
              )
              .map((option: OptionValue) => {
                return {
                  displayValue: option.text,
                  value: option.id,
                };
              });
          }
          if (fieldData.key === 'country' && property.options?.length) {
            fieldData.lstOption = deepClone(property)
              .options.filter(
                (option: OptionValue) =>
                  option.type === LookupTableType.Country,
              )
              .map((option: OptionValue) => {
                return {
                  displayValue: option.text,
                  value: option.id,
                };
              });
          }
          fieldData.formControl = formControl;
          if (stringToBoolean(property.config?.readOnly)) {
            formControl.disable();
          }
          return fieldData;
        });
        return {
          entityPropertyId: property.entityPropertyId,
          label: property.entityPropertyName,
          type: property.type,
          lstChildFormControl: deepClone(lstChildForm),
          isRequired: stringToBoolean(property.config?.required),
          isDisabled: true,
        } as FormControlDetail;
      }
      const lstOption = this.getListOption(
        property.type as InputType,
        property,
      );
      return {
        ...property,
        name: property.key,
        label: property.entityPropertyName,
        property: property,
        isDisabled: true,
        lstOption,
      } as any;
    });
  }

  private getListOption(type: InputType, property: any): Option[] {
    if (property.propertyKey === 'status') {
      if (property.options === 'Name') {
        return deepClone(this.listStatus).map((status) => {
          return {
            value: status.id,
            displayValue: status.name,
          };
        });
      }
      if (property.options === 'Event') {
        const result: Option[] = [];
        deepClone(this.listStatus)
          .filter((status) => status.memberEvent.length)
          .forEach((status) => {
            result.push(
              ...status.memberEvent.map((memberEvent: MemberEvent) => {
                return {
                  value: memberEvent.id,
                  displayValue: memberEvent.name,
                };
              }),
            );
          });
        return result;
      }
    }
    if (!property) {
      return [];
    }
    if (
      property.type === InputType.Person_Name &&
      property?.configs?.prefixList
    ) {
      return (property.options as any[]).map((option) => {
        return {
          value: option.id,
          displayValue: option.text,
        };
      });
    }
    if (
      property.type === InputType.Address &&
      ['State', 'Country'].includes(property?.options)
    ) {
      if (property.options === 'State') {
        return (property.options as any[])
          .filter((option) => option.type === 1)
          .map((option) => {
            return {
              value: option.id,
              displayValue: option.text,
            };
          });
      }
      return (property.options as any[])
        .filter((option) => option.type === 2)
        .map((option) => {
          return {
            value: option.id,
            displayValue: option.text,
          };
        });
    }
    if (
      property.type === InputType.List ||
      property.type === InputType.Lookup
    ) {
      return (property.options as any[]).map((option) => {
        return {
          value: option.id,
          displayValue: option.text,
        };
      });
    }
    if (type === InputType.List) {
      return property.options.map((option: any) => {
        return {
          value: option.id,
          displayValue: option.name,
        };
      });
    }
    return [];
  }
}
