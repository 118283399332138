import { createAction, props } from '@ngrx/store';
import {
  GetExceptionConfigurationRequest,
  GetExceptionConfigurationResponse,
  ExceptionConfigurationDetailResponse,
  SaveExceptionConfigurationDetailRequest,
  GetValidationExceptionRequest,
  GetValidationExceptionResponse,
} from '../../services/models/exception-configuration.model';

export const getExceptionConfigurationsAction = createAction(
  '[GetExceptionConfigurations/API] Send Request',
  props<{ request: GetExceptionConfigurationRequest }>(),
);

export const getExceptionConfigurationsSuccessAction = createAction(
  '[GetExceptionConfigurations/API] Success',
  props<{ response: GetExceptionConfigurationResponse }>(),
);

export const getExceptionConfigurationsFailureAction = createAction(
  '[GetExceptionConfigurations/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetExceptionConfigurationsStateAction = createAction('[GetExceptionConfigurations] Clear');

export const getExceptionConfigurationDetailAction = createAction(
  '[GetExceptionConfigurationDetail/API] Send Request',
  props<{ request: string }>(),
);

export const getExceptionConfigurationDetailSuccessAction = createAction(
  '[GetExceptionConfigurationDetail/API] Success',
  props<{ response: ExceptionConfigurationDetailResponse }>(),
);

export const getExceptionConfigurationDetailFailureAction = createAction(
  '[GetExceptionConfigurationDetail/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetExceptionConfigurationDetailStateAction = createAction('[GetExceptionConfigurationDetail] Clear');

export const setExceptionConfigurationsDetailAction = createAction(
  '[SetExceptionConfigurationsDetail/API] Send Request',
  props<{ exceptionId: string; request: SaveExceptionConfigurationDetailRequest }>(),
);

export const setExceptionConfigurationsDetailSuccessAction = createAction(
  '[SetExceptionConfigurationsDetail/API] Success',
  props<{ response: any }>(),
);

export const setExceptionConfigurationsDetailFailureAction = createAction(
  '[SetExceptionConfigurationsDetail/API] Failure',
  props<{ error?: any }>(),
);

export const clearSetExceptionConfigurationsDetailStateAction = createAction(
  '[ExceptionConfigurationsDetailState] Clear',
);

export const getValidationExceptionConfigurationAction = createAction(
  '[GetValidationExceptionConfiguration/API] Send Request',
  props<{
    request: GetValidationExceptionRequest;
  }>(),
);

export const getValidationExceptionConfigurationSuccessAction = createAction(
  '[GetValidationExceptionConfiguration/API] Success',
  props<{
    response: GetValidationExceptionResponse;
  }>(),
);

export const getValidationExceptionConfigurationFailureAction = createAction(
  '[GetValidationExceptionConfiguration/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetValidationExceptionConfigurationStateAction = createAction(
  '[GetValidationExceptionConfiguration] Clear',
);
