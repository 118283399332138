import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { environment } from 'src/environments/environment';
import { CheckResponse } from '@ptg-fund-list/models/add-fund.model';
import * as fromReducer from '@ptg-reducers';

import { AggregationPayload } from '../types/models';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AggregationConfigService {
  activeFund: any;
  currentNewAggregationId?: string;
  sectionKey?: string;

  constructor(
    private httpClient: HttpClient,
    private store: Store<fromReducer.State>
  ) {
    this.store
      .pipe(select(fromReducer.selectCurrentFundState))
      .subscribe((el) => (this.activeFund = el));
  }

  getAggregationList() {
    // return this.httpClient.get(
    //   `${environment.apiUrl}/Metadata/${this.sectionKey}/Aggregation`
    // );

    // TODO remove when complete module entity
   return of();
  }

  orderTable(body: any) {
    // return this.httpClient.put(
    //   `${environment.apiUrl}/Metadata/${this.sectionKey}/Aggregation/${body?.reorderItemId}/Reorder`,
    //   body
    // );

    // TODO remove when complete module entity
   return of();
  }

  checkExits = (body: any) => {
    // if (this.currentNewAggregationId) {
    //   body.aggregationId = this.currentNewAggregationId;
    // }
    // return this.httpClient.post<CheckResponse>(
    //   `${environment.apiUrl}/Metadata/${this.sectionKey}/Aggregation/Exists`,
    //   body
    // );

    // TODO remove when complete module entity
   return of();
  };

  addAggregation(body: AggregationPayload) {
    // return this.httpClient.post(
    //   `${environment.apiUrl}/Metadata/${this.sectionKey}/Aggregation/Add`,
    //   body
    // );

    // TODO remove when complete module entity
   return of();
  }

  editAggregation(body: AggregationPayload) {
    // return this.httpClient.put(
    //   `${environment.apiUrl}/Metadata/${this.sectionKey}/Aggregation/${this.currentNewAggregationId}/Edit`,
    //   body
    // );

    // TODO remove when complete module entity
   return of();
  }

  removeAggregation(aggregationId: string) {
    // return this.httpClient.delete(
    //   `${environment.apiUrl}/Metadata/${this.sectionKey}/Aggregation/${aggregationId}`
    // );

    // TODO remove when complete module entity
   return of();
  }
}
