<div class="select-bank">
  <div class="header-title">Select Bank</div>
  <form class="edit-form" [formGroup]="editForm">
    <ptg-select
      placeholder="Bank Name"
      [controlField]="this.editForm?.get('boardBankId')"
      [listData]="listBoardBanks"
      [isOptionObj]="true"
      [isRequired]="true"
      errorAsync="Bank Name is Disabled. Please select another Bank or modify Bank Information in Fund Management module."
    ></ptg-select>
    <div class="row-button">
      <button mat-raised-button type="submit" class="save-button" (click)="onSubmit()">
        Select
      </button>
      <button mat-raised-button type="button" class="cancel-button" (click)="onCancel()">
        Cancel
      </button>
    </div>
  </form>
</div>
