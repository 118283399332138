import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[ptgTruncateText]'
})
export class TruncateTextDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    let element = this.elementRef.nativeElement;
    if (element.offsetHeight < element.scrollHeight) {
      element.parentElement.classList.add('truncated');
    } else {
      element.parentElement.classList.add('noTruncated');
      element.parentElement.classList.remove('truncated');
    }
  }
}
