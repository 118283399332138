<ptg-title-bar
  [name]="'Edit'"
  viewName="Deductions"
  [onlyTitle]="true"
></ptg-title-bar>
<div class="edit-deductions">
  <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div class="wrap-btn">
      <button
        mat-raised-button
        type="submit"
        class="submit-button"
        [disabled]="!deductions?.length && !activeDeductions?.length"
      >
        Save
      </button>
      <button
        mat-raised-button
        type="button"
        class="cancel-button"
        (click)="onCancel()"
      >
        Cancel
      </button>
    </div>
    <div class="form-content flex flex-col gap-5">
      <div *ngIf="!isHiddenTaxes" class="deduction-box">
        <div class="box-title">Taxes</div>
        <div class="flex gap-5">
          <div class="box" *ngIf="federalTax">
            <ptg-toggle-button
              class="mt-auto mb-auto"
              label="Recalculate"
              [controlField]="recalculateFederalTaxCtrl"
            ></ptg-toggle-button>
            <div class="flex gap-4 mt-3">
              <div class="flex flex-col tax-label">
                <span class="title">Deduction</span>
                <span class="value">
                  {{
                    federalTax?.deductionCode +
                      " - " +
                      federalTax?.deductionDescription
                  }}
                </span>
              </div>    
              <ptg-textbox
                *ngIf="!recalculateFederalTaxCtrl.value"
                [controlField]="federalCtrl"
                [hasLabel]="true"
                placeholder="Amount"
                [isDecimal]="true"
                [isPositive]="true"
                [allowZero]="true"
                inputType="Currency"
                [min]="0.0"
                [max]="MAX_VALUE_NUMBER"
                [maxLength]="16"
                [isRequired]="true"
                errorMinMax="Amount must be within the range of 0.00 - 9,999,999,999,999.99."
              ></ptg-textbox>
            </div>
            <div class="flex gap-4">
              <ptg-datepicker
                placeholder="Start Date"
                [controlField]="federalStartDateCtrl"
                [isRequired]="true"
                [minDate]="formGroup.get('federalMinDate')?.value"
                [errorMinDate]="formGroup.get('errorFederalMinDate')?.value"   
                [maxDate]="formGroup.get('federalMaxDate')?.value"        
                errorMaxDate="Start Date and End Date must be in defined date range in Deduction List (master data)."
                errorRange="Start Date must be earlier than End Date."
                (changeValue)="compareDates(federalStartDateCtrl, federalEndDateCtrl, null, true)"
              ></ptg-datepicker>
              <ptg-datepicker
                placeholder="End Date"
                [controlField]="federalEndDateCtrl"
                [minDate]="formGroup.get('federalMinDate')?.value"   
                [maxDate]="formGroup.get('federalMaxDate')?.value"   
                [errorMinDate]="formGroup.get('errorFederalMinDate')?.value"       
                errorMaxDate="Start Date and End Date must be in defined date range in Deduction List (master data)."
                errorRange="Start Date must be earlier than End Date."
                (changeValue)="compareDates(federalStartDateCtrl, federalEndDateCtrl, null, true)"
              ></ptg-datepicker>
            </div>
          </div>
          <div class="box" *ngIf="stateTax">
            <ptg-toggle-button
              class="mt-auto mb-auto"
              label="Recalculate"
              [controlField]="recalculateStateTaxCtrl"
            ></ptg-toggle-button>
            <div class="flex gap-4 mt-3">
              <div class="flex flex-col tax-label">
                <span class="title">Deduction</span>
                <span class="value">
                  {{
                    stateTax?.deductionCode +
                      " - " +
                      stateTax?.deductionDescription
                  }}
                </span>
              </div>
              <ptg-textbox
                *ngIf="!recalculateStateTaxCtrl.value"
                [controlField]="stateCtrl"
                [hasLabel]="true"
                placeholder="Amount"
                [isDecimal]="true"
                [isPositive]="true"
                [allowZero]="true"
                inputType="Currency"
                [min]="0.0"
                [max]="MAX_VALUE_NUMBER"
                [maxLength]="16"
                [isRequired]="true"
                errorMinMax="Amount must be within the range of 0.00 - 9,999,999,999,999.99."
              ></ptg-textbox>
            </div>
            <div class="flex gap-4">
              <ptg-datepicker
                placeholder="Start Date"
                [controlField]="stateStartDateCtrl"
                [isRequired]="true"
                [minDate]="formGroup.get('stateMinDate')?.value"
                [errorMinDate]="formGroup.get('errorStateMinDate')?.value" 
                [maxDate]="formGroup.get('stateMaxDate')?.value"            
                errorMaxDate="Start Date and End Date must be in defined date range in Deduction List (master data)."
                errorRange="Start Date must be earlier than End Date."
                (changeValue)="compareDates(stateStartDateCtrl, stateEndDateCtrl, null, false)"
              ></ptg-datepicker>
              <ptg-datepicker
                placeholder="End Date"
                [controlField]="stateEndDateCtrl"
                [minDate]="formGroup.get('stateMinDate')?.value"
                [errorMinDate]="formGroup.get('errorStateMinDate')?.value"
                [maxDate]="formGroup.get('stateMaxDate')?.value"            
                errorMaxDate="Start Date and End Date must be in defined date range in Deduction List (master data)."
                errorRange="Start Date must be earlier than End Date."
                (changeValue)="compareDates(stateStartDateCtrl, stateEndDateCtrl, null, false)"
              ></ptg-datepicker>
            </div>
          </div>
        </div>

        <ptg-button
          *ngIf="isDisplayAddTaxes"
          buttonName="Add Taxes"
          classInput="add-button"
          (clickButton)="onClickAddTaxes()"
        >
          <mat-icon>add</mat-icon>
        </ptg-button>
      </div>

      <div
        *ngIf="
          selectedInsuranceCtrls?.controls?.length || allActiveInsurances?.length
        "
        class="deduction-box"
      >
        <div class="box-title">Insurance</div>
          <div class="flex flex-col gap-5">
            <div class="box" *ngFor="let row of selectedInsuranceCtrls.controls; index as i">
              <div class="flex gap-4">
                <ptg-select
                  [placeholder]="'Insurance ' + (i + 1)"
                  [controlField]="row.get('deduction')"
                  [listData]="row.get('deductionOptions')?.value"
                  [isOptionObj]="true"
                  [errorRequire]="'Insurance ' + (i + 1) + ' is required.'"
                  errorDuplicated="Chosen deduction already exists."
                  (changeOptionValue)="
                    onChangeDeduction(DeductionType.Insurance, row)
                  "
                ></ptg-select>
                <ptg-textbox
                  [controlField]="row.get('amount')"
                  [hasLabel]="true"
                  placeholder="Amount"
                  [isDecimal]="true"
                  [isPositive]="true"
                  [allowZero]="true"
                  [isDisabled]="checkDisableAmount(row.get('endDate'))"
                  inputType="Currency"
                  [min]="0.0"
                  [max]="MAX_VALUE_NUMBER"
                  [maxLength]="16"
                  [isRequired]="true"
                  errorMinMax="Amount must be within the range of 0.00 - 9,999,999,999,999.99."
                ></ptg-textbox>
                <div class="flex right-padding-space">
                  <mat-icon
                    class="remove-button"
                    (click)="onClickRemoveRow(DeductionType.Insurance, i)"
                  >
                    delete_forever
                  </mat-icon>
                </div>
              </div>
              <div class="flex gap-4">
                <ptg-datepicker
                  placeholder="Start Date"
                  [controlField]="row.get('startDate')"
                  [isRequired]="true"
                  [minDate]="row.get('minDate')?.value" 
                  [errorMinDate]="row.get('errorMinDate')?.value"
                  [maxDate]="row.get('maxDate')?.value"              
                  errorMaxDate="Start Date and End Date must be in defined date range in Deduction List (master data)."
                  errorRange="Start Date must be earlier than End Date."
                  (changeValue)="compareDates(row.get('startDate'), row.get('endDate'), row)"
                ></ptg-datepicker>
                <ptg-datepicker
                  placeholder="End Date"
                  [controlField]="row.get('endDate')"
                  [minDate]="row.get('minDate')?.value" 
                  [errorMinDate]="row.get('errorMinDate')?.value"
                  [maxDate]="row.get('maxDate')?.value"              
                  errorMaxDate="Start Date and End Date must be in defined date range in Deduction List (master data)."
                  errorRange="Start Date must be earlier than End Date."
                  (changeValue)="compareDates(row.get('startDate'), row.get('endDate'), row)"
                ></ptg-datepicker>
              </div>
              <div *ngIf="i < selectedInsuranceCtrls.controls.length - 1" class="line"></div>
            </div>
          </div>
          <ptg-button
            *ngIf="showAddButton(DeductionType.Insurance)"
            buttonName="New Insurance"
            classInput="add-button"
            (clickButton)="onClickAddRow(DeductionType.Insurance)"
          >
            <mat-icon>add</mat-icon>
          </ptg-button>
      </div>

      <div
        *ngIf="selectedOtherCtrls?.controls?.length || allActiveOthers.length"
        class="deduction-box"
      >
        <div class="box-title">Others</div>

        <div class="flex flex-col gap-5">
          <div class="flex flex-col gap-4">
            <div class="box" *ngFor="let row of selectedOtherCtrls.controls; index as i">
              <div class="flex gap-4">
                <ptg-select
                  [placeholder]="'Others ' + (i + 1)"
                  [controlField]="row?.get('deduction')"
                  [listData]="row.get('deductionOptions')?.value"
                  [isOptionObj]="true"
                  [errorRequire]="'Others ' + (i + 1) + ' is required.'"
                  errorDuplicated="Chosen deduction already exists."
                  (changeOptionValue)="onChangeDeduction(DeductionType.Others, row)"
                ></ptg-select>
                <ptg-textbox
                  [controlField]="row.get('amount')"
                  [hasLabel]="true"
                  placeholder="Amount"
                  [isDecimal]="true"
                  [isPositive]="true"
                  [allowZero]="true"
                  [isDisabled]="checkDisableAmount(row.get('endDate'))"
                  inputType="Currency"
                  [min]="0.0"
                  [max]="MAX_VALUE_NUMBER"
                  [maxLength]="16"
                  [isRequired]="true"
                  errorMinMax="Amount must be within the range of 0.00 - 9,999,999,999,999.99."
                ></ptg-textbox>
                <div class="flex right-padding-space">
                  <mat-icon
                    class="remove-button"
                    (click)="onClickRemoveRow(DeductionType.Others, i)"
                  >
                    delete_forever
                  </mat-icon>
                </div>
              </div>
              <div class="flex gap-4">
                <ptg-textbox
                  [controlField]="row.get('totalAmount')"
                  [hasLabel]="true"
                  placeholder="Total Amount"
                  [isDecimal]="true"
                  [isPositive]="true"
                  [allowZero]="true"
                  inputType="Currency"
                  [min]="0.0"
                  [max]="MAX_VALUE_NUMBER"
                  [maxLength]="16"
                  errorMinMax="Amount must be within the range of 0.00 - 9,999,999,999,999.99."
                ></ptg-textbox>
                <div class="flex gap-5">
                  <div class="flex-grow">
                    <div class="col-title">Deduction Balance</div>
                    <div class="col-value">$ {{ row.get('deductionBalance')?.value | numberDecimal : { decimal: 2 } }}</div>
                  </div>
                </div>
              </div>
              <div class="flex gap-4">
                <ptg-datepicker
                  placeholder="Start Date"
                  [controlField]="row.get('startDate')"
                  [isRequired]="true"
                  [minDate]="row.get('minDate')?.value" 
                  [errorMinDate]="row.get('errorMinDate')?.value"
                  [maxDate]="row.get('maxDate')?.value"              
                  errorMaxDate="Start Date and End Date must be in defined date range in Deduction List (master data)."
                  errorRange="Start Date must be earlier than End Date."
                  (changeValue)="compareDates(row.get('startDate'), row.get('endDate'), row)"
                ></ptg-datepicker>
                <ptg-datepicker
                  placeholder="End Date"
                  [controlField]="row.get('endDate')"
                  [minDate]="row.get('minDate')?.value"
                  [maxDate]="row.get('maxDate')?.value"  
                  [errorMinDate]="row.get('errorMinDate')?.value"
                  [maxDate]="row.get('maxDate')?.value"              
                  errorMaxDate="Start Date and End Date must be in defined date range in Deduction List (master data)."
                  errorRange="Start Date must be earlier than End Date."
                  (changeValue)="compareDates(row.get('startDate'), row.get('endDate'), row)"
                ></ptg-datepicker>
              </div>
              <div *ngIf="i < selectedOtherCtrls.controls.length - 1" class="line"></div>
            </div>
          </div>

          <ptg-button
            *ngIf="showAddButton(DeductionType.Others)"
            buttonName="New Others"
            classInput="add-button"
            (clickButton)="onClickAddRow(DeductionType.Others)"
          >
            <mat-icon>add</mat-icon>
          </ptg-button>
        </div>
      </div>

      <div
        *ngIf="selectedGarnishmentCtrls?.controls?.length"
        class="deduction-box"
      >
        <div class="box-title">Garnishment</div>

        <div class="flex flex-col gap-5">
          <div class="flex flex-col gap-4">
            <div class="box">
              <div class="flex gap-4">
                <div style="width: 325px">
                  <div class="col-title">Deduction</div>
                </div>
                <div style="width: 325px">
                  <div class="col-title">Amount</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          *ngFor="let row of selectedGarnishmentCtrls.controls; index as i"
          class="box flex gap-4 row-garnishment"
        >
          <div class="flex flex-col" style="width: 325px">
            <span class="mb-3">
              {{ row.get('name')?.value }}
            </span>
            <ptg-datepicker
              placeholder="Start Date"
              [controlField]="row.get('startDate')"
              [isRequired]="true"
              [minDate]="row.get('minDate')?.value"  
              [maxDate]="row.get('maxDate')?.value"              
              errorMaxDate="Start Date and End Date must be in defined date range in Deduction List (master data)." 
              [errorMinDate]="row.get('errorMinDate')?.value"
              errorRange="Start Date must be earlier than End Date."
              (changeValue)="compareDates(row.get('startDate'), row.get('endDate'), row)"
            ></ptg-datepicker>
          </div>

          <div class="flex flex-col">
            <div class="mb-3">
              <div style="width: 325px">
                <span class="dollar-sign">$</span>
                <span>
                  {{ row.get("value")?.value | numberDecimal : { decimal: 2 } }}
                </span>
              </div>
            </div>
            
            <ptg-datepicker
              placeholder="End Date"
              [controlField]="row.get('endDate')"
              [minDate]="row.get('minDate')?.value"
              [maxDate]="row.get('maxDate')?.value"              
              errorMaxDate="Start Date and End Date must be in defined date range in Deduction List (master data)." 
              [errorMinDate]="row.get('errorMinDate')?.value" 
              errorRange="Start Date must be earlier than End Date."
              (changeValue)="compareDates(row.get('startDate'), row.get('endDate'), row)"
            ></ptg-datepicker>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
