<div class="dialog-container">
  <div class="header-title">Add New Property</div>
  <form class="edit-form" [formGroup]="editForm">
    <div>
      <div class="input-container">
        <ptg-textbox
          [controlField]="propertyNameCtrl"
          placeholder="Property Name"
          [hasLabel]="true"
          [isRequired]="true"
          [maxLength]="250"
          errorAsync="Property Name or Import Label already exists."
          customError="errorMessagePropertyName"
        ></ptg-textbox>

        <ptg-select
          placeholder="Type"
          [controlField]="propertyTypeCtrl"
          [listData]="properties"
          [isOptionObj]="true"
          [isRequired]="true"
          (changeOptionValue)="onValueChangePropertyType()"
        ></ptg-select>
      </div>
      <div class="input-container">
        <ptg-select
          *ngIf="editForm.value.propertyType === EntityPropertyType.Data"
          placeholder="Data Type"
          [controlField]="dataTypeCtrl"
          [listData]="dataProperties"
          [isOptionObj]="true"
          [isRequired]="true"
          (changeOptionValue)="onValueChangeDataType()"
        ></ptg-select>
        <ptg-select
          *ngIf="
            editForm.value.dataType === EntityPropertyType.Lookup &&
            editForm.value.propertyType !== EntityPropertyType['Entity Reference']
          "
          placeholder="Lookup Table"
          [controlField]="lookupTableIdCtrl"
          [listData]="lookupTableOptions"
          [isOptionObj]="true"
          [isSetMaxWidthSelect]="true"
          [isRequired]="true"
        ></ptg-select>
        <ptg-select
          *ngIf="editForm.value.propertyType === EntityPropertyType.Aggregation"
          placeholder="List"
          [controlField]="listIdCtrl"
          [listData]="listOptions"
          [isOptionObj]="true"
          [isSetMaxWidthSelect]="true"
          [isRequired]="true"
          (changeOptionValue)="onChangeListOption()"
        ></ptg-select>
        <ptg-select
          *ngIf="editForm.value.propertyType === EntityPropertyType.Aggregation"
          placeholder="Property"
          [controlField]="propertyIdCtrl"
          [listData]="propertyOptions"
          [isOptionObj]="true"
          [isSetMaxWidthSelect]="true"
          [isRequired]="true"
          (changeOptionValue)="onChangePropertyType()"
        ></ptg-select>
      </div>
      <div
        *ngIf="
          editForm.value.propertyType === EntityPropertyType['Entity Reference']
        "
      >
        <div class="divider"></div>
        <span class="label-reference">Add Entity</span>
        <div class="list-parameter">
          <div
            class="row-param"
            *ngFor="let rowControls of listParameter?.controls; index as i"
          >
            <ptg-select
              placeholder="Entity"
              [controlField]="rowControls?.get('entityId')"
              [listData]="rowControls?.get('entityOptions')?.value || []"
              [isMultipleLineOption]="true"
              [isOptionObj]="true"
              [isSetMaxWidthSelect]="true"
              (changeOptionValue)="onChangeEntityValue()"
            ></ptg-select>
            <span *ngIf="listParameter.length > 1 && i > 0" class="remove-btn" (click)="onClickRemoveRow(i)">
              <mat-icon>delete_forever</mat-icon>
              Remove
            </span>
          </div>
        </div>

        <ptg-button
          *ngIf="addNewButtonIsValid()"
          buttonName="Add New"
          class="add-btn"
          classInput="add-button"
          (clickButton)="onClickAddNew()"
        >
          <mat-icon>add</mat-icon>
        </ptg-button>
      </div>
      <div class="input-container">
        <ptg-select
          *ngIf="editForm.value.propertyType === EntityPropertyType.Aggregation"
          placeholder="Aggregate"
          [controlField]="aggregateIdCtrl"
          [listData]="aggregateOptions"
          [isOptionObj]="true"
          [isSetMaxWidthSelect]="true"
          [isRequired]="true"
        ></ptg-select>
      </div>
      <div class="toggle-container">
        <div *ngIf="showEncrypted">
          <ptg-toggle-button
            [controlField]="encryptedCtrl"
            label="Encrypted"
          ></ptg-toggle-button>
        </div>
        <div *ngIf="showUnique">
          <ptg-toggle-button
            [controlField]="uniqueCtrl"
            label="Unique"
          ></ptg-toggle-button>
        </div>
      </div>
    </div>

    <div class="row-button">
      <button
        mat-raised-button
        type="button"
        class="primary"
        (click)="formSubmit$.next(true)"
      >
        Create & Continue
      </button>
      <button
        mat-raised-button
        type="button"
        class="secondary"
        (click)="formSubmit$.next(false)"
      >
        Create & Add Another
      </button>
      <button
        mat-raised-button
        type="button"
        class="cancel-button"
        (click)="onCancel()"
      >
        Cancel
      </button>
    </div>
  </form>
</div>
