import { InitiationProperty } from "@ptg-entity-management/services/models";

export interface GetListAddressHistoryQuery {
  pageIndex: number;
  pageSize: number;
  sortNames?: string[];
  sortType?: number;
  memberId: string;
  type?: string;
}

export interface AddressHistoryList {
  total: number;
  listAddressHistory: any[];
}

export interface AddressEntityProperty {
  properties: InitiationProperty[]
}

export interface PayloadAddress {

}
