<div [ngClass]="{
  hidden: !(relatedPersonData.length > 0 || !configCard?.datalessConfig?.isHideEmptyCard)
}" class="related-person-container">
  <div class="flex-grow overflow-hidden">
    <ptg-list-display-box
      [title]="cardTitle"
      pageName="Data"
      [isLoading]="isLoading"
      [dataTable]="relatedPersonData"
      [columns]="relatedPersonData.length > 0 || configCard?.datalessConfig?.listDisplayPreferenceType !== LIST_DISPLAY_PREFERENCE_TYPE.Hide ? columns : []"
      [sortInfo]="sortInfo"
      [lengthPg]="lengthPg"
      [pageSize]="pageSize"
      [pageNumber]="pageNumber"
      [currentRowIndex]="currentRowIndex"
      [paginable]="relatedPersonData.length> 0 || configCard?.datalessConfig?.listDisplayPreferenceType !== LIST_DISPLAY_PREFERENCE_TYPE.Hide"
      [isHideNotFoundMessage]="configCard?.datalessConfig?.listDisplayPreferenceType === LIST_DISPLAY_PREFERENCE_TYPE.Hide || 
        configCard?.datalessConfig?.listDisplayPreferenceType === LIST_DISPLAY_PREFERENCE_TYPE.DisplayColumnHeaders"
      (changePage)="changePage($event)"
      (sortChange)="sortChange($event)"
      (selectRow)="selectRow($event)"
      (addItem)="onEditMemberStatus(undefined)"
    ></ptg-list-display-box>
  </div>
</div>
