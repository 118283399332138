import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccidentState } from '../../store/reducers';
import { Store } from '@ngrx/store';
import { createAccidentNoteAction } from '../../store/actions';

@Component({
  selector: 'ptg-add-accident-note',
  templateUrl: './add-accident-note.component.html',
  styleUrls: ['./add-accident-note.component.scss']
})
export class AddAccidentNoteComponent implements OnInit {
  formGroup!: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      memberId: string,
      accidentId: string
    },
    public dialogRef: MatDialogRef<AddAccidentNoteComponent>,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    private store: Store<AccidentState>,
  ) { }

  ngOnInit(): void {
    this.innitForm();
  }


  innitForm() {
    this.formGroup = new FormGroup({
      note: new FormControl(null, Validators.required),
    })
  }

  onSubmit() {
    if (this.formGroup.valid) {
      let data = this.formGroup.getRawValue();
      this.store.dispatch(createAccidentNoteAction({
        note: data,
        memberId: this.data.memberId,
        accidentId: this.data.accidentId
      }))
      this.dialogRef.close(true);
    }
  }

  onCancel(): void {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }
}

