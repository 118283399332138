import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  CheckMasterParameterUsingResponse,
  GetLinkedPropertiesResponse,
  GetPropertiesByLinkedIdResponse,
  GetReferenceByLinkedIdResponse,
  GetRetirementBenefitInputOutputListResponse,
} from '../../services/models';
import {
  addMasterInputOutputFailure,
  addMasterInputOutputRequest,
  addMasterInputOutputSuccess,
  checkMasterParameterUsingAction,
  checkMasterParameterUsingFailureAction,
  checkMasterParameterUsingSuccessAction,
  deleteMasterInputOutputFailure,
  deleteMasterInputOutputRequest,
  deleteMasterInputOutputSuccess,
  editMasterInputOutputFailure,
  editMasterInputOutputRequest,
  editMasterInputOutputSuccess,
  getEntityReferenceByLinkedIdFailure,
  getEntityReferenceByLinkedIdRequest,
  getEntityReferenceByLinkedIdSuccess,
  getLinkedPropertiesFailure,
  getLinkedPropertiesRequest,
  getLinkedPropertiesSuccess,
  getPropertiesByLinkedIdFailure,
  getPropertiesByLinkedIdRequest,
  getPropertiesByLinkedIdSuccess,
  getRetirementBenefitInputOutputsFailure,
  getRetirementBenefitInputOutputsRequest,
  getRetirementBenefitInputOutputsSuccess,
} from '../actions';
import { InputOutputService } from '../../services/input-output.service';

@Injectable()
export class InputOutputEffects {
  getRetirementBenefitInputOutputs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRetirementBenefitInputOutputsRequest),
      switchMap(({ query }) => {
        return this.inputOutputService.getInputOutputList(query).pipe(
          map((response: GetRetirementBenefitInputOutputListResponse) => {
            return getRetirementBenefitInputOutputsSuccess({ response });
          }),
          catchError((error) => {
            return of(getRetirementBenefitInputOutputsFailure());
          }),
        );
      }),
    ),
  );

  getLinkedProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getLinkedPropertiesRequest),
      switchMap(({ dataType, lookupTableId }) => {
        return this.inputOutputService.getLinkedProperties(dataType, lookupTableId).pipe(
          map((response: GetLinkedPropertiesResponse) => {
            return getLinkedPropertiesSuccess({ linkedProperties: response?.linkedProperties ?? [] });
          }),
          catchError((error) => {
            return of(getLinkedPropertiesFailure());
          }),
        );
      }),
    ),
  );

  addMasterInputOutput$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addMasterInputOutputRequest),
      switchMap(({ body }) => {
        return this.inputOutputService.addMasterInputOutput(body).pipe(
          map(() => {
            return addMasterInputOutputSuccess({ parameterType: body.type });
          }),
          catchError((error) => {
            return of(addMasterInputOutputFailure({ parameterType: body.type }));
          }),
        );
      }),
    ),
  );

  editMasterInputOutput$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editMasterInputOutputRequest),
      switchMap(({ id, body }) => {
        return this.inputOutputService.editMasterInputOutput(id, body).pipe(
          map(() => {
            return editMasterInputOutputSuccess({ parameterType: body.type });
          }),
          catchError((error) => {
            return of(editMasterInputOutputFailure({ parameterType: body.type }));
          }),
        );
      }),
    ),
  );

  deleteMasterInputOutput$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteMasterInputOutputRequest),
      switchMap(({ id, parameterType }) => {
        return this.inputOutputService.deleteMasterInputOutput(id).pipe(
          map(() => {
            return deleteMasterInputOutputSuccess({ parameterType });
          }),
          catchError((error) => {
            return of(deleteMasterInputOutputFailure({ parameterType }));
          }),
        );
      }),
    ),
  );

  checkMasterParameterUsing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkMasterParameterUsingAction),
      switchMap(({ id, parameterType }) =>
        this.inputOutputService.checkMasterParameterUsing(id).pipe(
          map((response: CheckMasterParameterUsingResponse) =>
            checkMasterParameterUsingSuccessAction({ response, id, parameterType }),
          ),
          catchError((error) => of(checkMasterParameterUsingFailureAction({ error }))),
        ),
      ),
    ),
  );

  getPropertiesByLinkedId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPropertiesByLinkedIdRequest),
      switchMap(({ dataType, linkedPropertyId }) => {
        return this.inputOutputService.getPropertiesByLinkedId(dataType, linkedPropertyId).pipe(
          map((response: GetPropertiesByLinkedIdResponse) => {
            return getPropertiesByLinkedIdSuccess({ response });
          }),
          catchError((error) => {
            return of(getPropertiesByLinkedIdFailure());
          }),
        );
      }),
    ),
  );

  getEntityReferenceByLinkedId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEntityReferenceByLinkedIdRequest),
      switchMap(({ dataType, linkedPropertyId, lookupTableId }) => {
        return this.inputOutputService.getEntityReferenceByLinkedId(dataType, linkedPropertyId, lookupTableId).pipe(
          map((response: GetReferenceByLinkedIdResponse) => {
            return getEntityReferenceByLinkedIdSuccess({ response });
          }),
          catchError((error) => {
            return of(getEntityReferenceByLinkedIdFailure());
          }),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private inputOutputService: InputOutputService,
  ) {}
}
