import { createSelector } from "@ngrx/store";
import { selectMemberModuleState } from "./selector";

// profile configuration
export const profileConfigSelector = createSelector(
    selectMemberModuleState,
    (state) => state.profileConfig
  );
  export const getEntitiesProfileListSelector = createSelector(
    profileConfigSelector,
    (state) => state.getProfileConfigList
  );
  
  export const getEntitiesProfileSelector = createSelector(
    profileConfigSelector,
    (state) => state.getEntities
  );
  
  export const getProfileViewSelector = createSelector(
    profileConfigSelector,
    (state) => state.getProfileView
  );
  
  export const getProfileHeaderSelector = createSelector(
    profileConfigSelector,
    (state) => state.getProfileHeader
  );
  
  export const getProfileExistSelector = createSelector(
    profileConfigSelector,
    (state) => state.getProfileExist
  );
  
  export const createProfileSelector = createSelector(
    profileConfigSelector,
    (state) => state.createProfile
  );
  
  export const removeProfileSelector = createSelector(
    profileConfigSelector,
    (state) => state.removeProfile
  );
  
  export const getProfileDetailSelector = createSelector(
    profileConfigSelector,
    (state) => state.getProfileDetail
  );

  export const getProfileStatusEventSelector = createSelector(
    profileConfigSelector,
    (state) => state.getProfileStatusEventList
  );

  export const getProfileMenuSelector = createSelector(
    profileConfigSelector,
    (state) => state.getProfileMenuList
  );

  export const updateProfileSelector = createSelector(
    profileConfigSelector,
    (state) => state.updateProfileList
  );