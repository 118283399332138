import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlsModule } from '@ptg-shared/controls/controls.module';
import { SharedModule } from '@ptg-shared/shared.module';
import { EmployerOverviewHeaderComponent } from './employer-overview-header.component';



@NgModule({
  declarations: [EmployerOverviewHeaderComponent],
  imports: [
    CommonModule,
    ControlsModule,
    SharedModule,
  ],
  exports: [EmployerOverviewHeaderComponent]
})
export class EmployerOverviewHeaderModule { }
