export enum SECTION_DISPLAY_TYPE {
  Hide,
  Left,
  Right
}

export enum SECTION_TYPE {
  'Employer Information',
  Contacts,
  Documents,
  Notes,
  'Portal Users',
  'Payment Preferences'
}

export enum PropertyType {
  Text = 1,
  Email = 2,
  Phone = 3,
  WholeNumber = 4,
  Currency = 5,
  Decimal = 6,
  Date = 7,
  DateTime = 8,
  Binary = 9,
  Percentage = 10,
  SSN = 11,
  RichText = 12,
  Lookup = 50,
  Benefit = 53,
  Address = 100,
  PersonName = 101,
  Aggregation = 103,
  Calculation = 104,
  Register = 200,
  SmsOptedIn = 201,
  PaperlessOptedIn = 202,
  System = 203,
  Data = 204,
  Year = 205,
  Month = 206
}

export const ADDRESS_FIELD = {
  Street1: 'Street1',
  Street2: 'Street2',
  City: 'City',
  State: 'State',
  StateCode: 'StateCode',
  ZipCode: 'ZipCode',
  Country: 'Country',
  CountryCode: 'CountryCode'
};

export const STATIC_PROPERTY = {
  Disabled: 'disabled',
  EmployerId: 'employerId',
  EmployerName: 'employerName'
};

export const PROPERTY_VALUE = {
  TrueValue: 'true',
  FalseValue: 'false'
};

export enum PropertyTypeLabel {
  Currency = 'Currency',
  Text = 'Text',
  Decimal = 'Decimal',
  Email = 'Email',
  'Whole Number' = 'Whole Number',
  Phone = 'Phone',
  Date = 'Date',
  Address = 'Address',
  'Person Name' = 'Person Name',
  Lookup = 'Lookup',
  Binary = 'Binary',
  Employer = 'Employer',
  SSN = 'SSN',
  RichText = 'RichText',
  Benefit = 'Benefit',
  Aggregation = 'Aggregation',
}

export type EmployerPropertyTypeLabelValue = keyof typeof PropertyTypeLabel;

export enum PERIOD_LENGTH {
  '1 week' = '1 week',
  '2 weeks' = '2 weeks',
  '1 month' = '1 month',
  '2 months' = '2 months',
  '1 quarter' = '1 quarter',
}

export enum FREQUENCY_LENGTH {
  'Daily' = 'Daily',
  'Weekly' = 'Weekly',
  'Monthly' = 'Monthly',
  'Quarterly' = 'Quarterly',
  'Annually' = 'Annually',
}

export enum FILE_LAYOUT_SECTION_TYPE {
  'Header Section',
  'Detail Section',
  'Trailer Section'
}
export enum WAGE_PROCESSING_STATUS {
  Imported,
  Validated,
  Audited,
  Rejected,
  Posted
}
export enum CONTRIBUTION_TERM {
  Percentage,
  DollarAmount
}

export const EMPLOYER_FIXED_PROPERTIES = {
  EmployerName: 'employerName',
  CertificationStatus: '_certificationStatus',
  Type: 'type',
  Disabled: 'disabled',
  Inactive: 'Inactive',
  InactivationDate: 'InactivationDate',
}

export const EMPLOYER_MESSAGE = {
  confirm: 'This Municipality is inactive. Are you sure you want to reactivate this Municipality?',
}

export enum RULE_CATEGORY_TYPE {
  Error,
  Warning,
  Information
}

export enum RULE_CATEGORY_TYPE_DISPLAY {
  Error = 'Errors',
  Warning = 'Warnings',
  Information = 'Information'
}

export enum EXCEPTION_COLUMN_KEY {
  Exception_Type = 'exceptionType',
  Resolved_At = 'resolvedAt',
  Corrected_Value = 'correctedValue',
  Resolved_By = 'resolvedBy'
}

export const EMPLOYER_ENTITY_CONNECTION_NAME = 'employers';

export const REPORT_NAME = {
  CashJournal: 'Cash Journal Report',
  ServiceList: 'ActiveReport',
  MemberCount: 'MemberCount',
}
