<mat-form-field class="input-form-field {{class}}" appearance="fill" *ngIf="controlField">
  <mat-label *ngIf="hasLabel">{{placeholder}}</mat-label>
  <input matInput type="text"
         [formControl]="controlField"
         (blur)="trimValue()"
         (input)="onValueChange($event)"
         [placeholder]="hasLabel ? '' : placeholder"
         [maxlength]="maxLength ? maxLength : 'none'"
         [id]="id">
  <mat-icon *ngIf="icon" matSuffix>{{icon}}</mat-icon>
  <mat-error *ngIf="controlField?.errors?.required">{{errorRequire}}</mat-error>
  <mat-error *ngIf="controlField?.errors?.pattern && !controlField?.errors?.maxlength">{{errorPattern}}</mat-error>
  <mat-error *ngIf="controlField?.errors?.inValidAsync">{{errorAsync}}</mat-error>
  <mat-error *ngIf="controlField?.errors?.maxlength">{{errorMaxLength}}</mat-error>
  <mat-error *ngIf="controlField?.errors?.[customError]">{{controlField.getError([customError])}}</mat-error>
</mat-form-field>
