import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { SortType } from '@ptg-shared/constance';
import { RetirementBenefitDetailGridDataType } from '../types/enums';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';
import { GetCalculationAuditTrailRequest, GetExceptionRequest } from './models';

@Injectable()
export class BenefitDetailComponentService {
  getGridDataRequest(
    dataTypeOfGrid: RetirementBenefitDetailGridDataType,
    payload: any,
    sortParam: Sort = { active: '', direction: '' },
  ): GetExceptionRequest | GetCalculationAuditTrailRequest {
    const sortNames = capitalizeFirstLetter(sortParam.active);
    const sortType = !sortParam.direction.length || sortParam.direction === 'asc' ? SortType.ASC : SortType.DESC;

    const { pageNumber, pageSize, memberId, calculationType, benefitTypeId, calculationId } = payload;
    const request: any = { pageNumber, pageSize, sortNames, sortType, memberId, calculationType };

    switch (dataTypeOfGrid) {
      case RetirementBenefitDetailGridDataType.Exceptions:
        request.benefitTypeId = benefitTypeId;
        request.calculationId = calculationId;
        break;
      case RetirementBenefitDetailGridDataType.CalculationAuditTrails:
        request.calculationId = calculationId;
        break;
      case RetirementBenefitDetailGridDataType.RetirementBenefitDocuments:
        request.benefitTypeId = benefitTypeId;
        request.calculationRecordId = calculationId;
        break;
      default:
        break;
    }

    return request;
  }
}
