import { createReducer, on } from '@ngrx/store';
import { DepartmentList } from '../models/department.model';
import { DepartmentActions } from '../actions';
import { ACTION, ActionState, STATE } from '../../shared/constance/value.const';
import { Employer } from '../models/employer.model';


export const departmentFeatureKey = 'department';

export interface State {
  isLoading: boolean;
  departmentList: DepartmentList,
  employerDetail: Employer,
  actionState?: ActionState;
  success?: boolean,
  error?: any,
}

const initialState: State = {
  isLoading: true,
  departmentList: {} as DepartmentList,
  employerDetail: {} as Employer,
  actionState: undefined
};

export const reducer = createReducer(
  initialState,
  on(DepartmentActions.getDepartmentList, (state, {}) => ({
    ...state,
    isLoading: true,
    departmentList: {
      total: 0,
      departments: []
    },
    actionState: undefined
  })),
  on(DepartmentActions.getDepartmentListSuccess, (state, { departmentList }) => ({
    ...state,
    isLoading: false,
    success: true,
    error: undefined,
    departmentList
  })),
  on(DepartmentActions.getDepartmentListFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    success: false,
    error: error,
    departmentList: {
      total: 0,
      departments: []
    }
  })),
  on(DepartmentActions.getDepartmentEmployerDetail, (state, {}) => ({
    ...state,
    employerDetail: {} as Employer
  })),
  on(DepartmentActions.getDepartmentEmployerDetailSuccess, (state, { employer }) => ({
    ...state,
    employerDetail: employer
  })),
  on(DepartmentActions.getDepartmentEmployerDetailFailure, (state, { errorMsg }) => ({
    ...state,
    employerDetail: {} as Employer
  })),
  on(DepartmentActions.clearDepartmentState, (state) => ({
    ...state,
    isLoading: true,
    actionState: undefined
  })),
  on(DepartmentActions.createDepartment, (state, {}) => ({
    ...state,
    isLoading: true
  })),
  on(DepartmentActions.createDepartmentSuccess, (state) => ({
    ...state,
    isLoading: false,
    actionState: {
      action: ACTION.ADD,
      state: STATE.SUCCESS
    }
  })),
  on(DepartmentActions.createDepartmentFailure, (state, { errorMsg }) => ({
    ...state,
    isLoading: false,
    actionState: {
      action: ACTION.ADD,
      state: STATE.FAIL
    }
  })),
  on(DepartmentActions.updateDepartment, (state, {}) => ({
    ...state,
    isLoading: true
  })),
  on(DepartmentActions.updateDepartmentSuccess, (state) => ({
    ...state,
    isLoading: false,
    actionState: {
      action: ACTION.EDIT,
      state: STATE.SUCCESS
    }
  })),
  on(DepartmentActions.updateDepartmentFailure, (state, { errorMsg }) => ({
    ...state,
    isLoading: false,
    actionState: {
      action: ACTION.EDIT,
      state: STATE.FAIL
    }
  }))
);

