import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { isObjectEmpty } from '@ptg-shared/utils/common.util';
import { isEmpty } from '@ptg-shared/utils/string.util';
import { AddressPipe } from '@ptg-shared/pipes/address.pipe';
import { PersonNamePipe } from '@ptg-shared/pipes/person-name.pipe';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { PaymentDeductionsService } from '@ptg-processing/features/payroll/services';

import {
  MemberAddressItem,
  OffCyclePayment,
  RepresentativePayee,
} from '../../types/models';
import * as NextPaymentActions from '../../store/actions/next-payment.actions';
import { PayStatus } from '../../constance/member-list.const';
import * as fromNextPayment from '../../store/reducers';
import { NextPaymentService } from '../../services/next-payment.service';

@Component({
  selector: 'ptg-edit-payroll-settings',
  templateUrl: './edit-payroll-settings.component.html',
  styleUrls: ['./edit-payroll-settings.component.scss'],
  providers: [AddressPipe, PersonNamePipe],
})
export class EditPayrollSettingsComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;
  listForm = [
    {
      value: -1,
      displayValue: 'None',
    },
    {
      value: 0,
      displayValue: 'W-4 2019 or Earlier',
    },
    {
      value: 1,
      displayValue: 'W-4P; W-4 2020 or Later',
    },
  ];
  listTable = [];
  listFederalTable = [
    {
      value: 'None',
      displayValue: 'None',
    },
  ];
  listStateTable = [
    {
      value: 'None',
      displayValue: 'None',
    },
  ];
  listAdditional = [
    {
      value: 0,
      displayValue: 'None',
    },
    {
      value: 1,
      displayValue: 'Absolute',
    },
    {
      value: 2,
      displayValue: 'Percent',
    },
  ];
  optionsAddress: any = [];
  memberAddressItems: MemberAddressItem[] | undefined = [];
  representativePayees: RepresentativePayee[] | undefined = [];
  optionsRepresentativePayees: any = [];
  unsubscribe$ = new Subject<void>();
  disabledWithholding: boolean = false;
  submitted = false;
  indexFederal: any;
  indexState: any;
  participantDeductions: any = [];
  deductionList: any = [];
  disabledFederal: boolean = false;
  disabledState: boolean = false;
  disabledSuspend: boolean = false;
  overrideAddress: string = '';
  offCyclePayment: OffCyclePayment;
  currentAddress: any;

  constructor(
    public dialogRef: MatDialogRef<EditPayrollSettingsComponent>,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    private store: Store<fromNextPayment.MemberState>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dataDeductions: any,
    private nextPaymentService: NextPaymentService,
    private addressPipe: AddressPipe,
    private personNamePipe: PersonNamePipe,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private paymentDeductionsService: PaymentDeductionsService
  ) {
    this.formGroup = new FormGroup({
      payStatus: new FormControl(false),
      formFederal: new FormControl(null),
      formState: new FormControl(null),
      isRecalculateFederal: new FormControl(false),
      isRecalculateState: new FormControl(false),
      incomeFederal: new FormControl(null),
      claimDependentsFederal: new FormControl(null),
      additionalIncomeFederal: new FormControl(null),
      additionalDeductionsFederal: new FormControl(null),
      incomeState: new FormControl(null),
      claimDependentsState: new FormControl(null),
      additionalIncomeState: new FormControl(null),
      additionalDeductionsState: new FormControl(null),
      withholdingFederal: new FormControl(null, Validators.required),
      withholdingState: new FormControl(null, Validators.required),
      additionalWithholdingFederal: new FormControl(null),
      additionalWithholdingState: new FormControl(null),
      absoluteFederal: new FormControl(null, Validators.maxLength(16)),
      absoluteState: new FormControl(null, Validators.maxLength(16)),
      percentFederal: new FormControl(null),
      percentState: new FormControl(null),
      exemptionFederal: new FormControl(null, Validators.maxLength(15)),
      exemptionState: new FormControl(null, Validators.maxLength(15)),
      address: new FormControl(null),
      representativePayee: new FormControl(null),
    });

    this.formGroup.setValue({
      payStatus: !this.data.participantSetting
        ? false
        : this.data.participantSetting?.payStatus === PayStatus.Suspend,
      formFederal:
        this.data.federalSettings?.form || this.data.federalSettings?.form == 0
          ? this.data.federalSettings?.form
          : this.data.federalSettings && !this.data.federalSettings?.form
          ? -1
          : null,
      formState:
        this.data.stateSettings?.form || this.data.stateSettings?.form == 0
          ? this.data.stateSettings?.form
          : this.data.stateSettings && !this.data.stateSettings?.form
          ? -1
          : null,
      isRecalculateFederal: !this.data.federalSettings?.recalculateTax
        ? false
        : this.data.federalSettings?.recalculateTax,
      isRecalculateState: !this.data.stateSettings?.recalculateTax
        ? false
        : this.data.stateSettings?.recalculateTax,
      incomeFederal:
        this.data.federalSettings?.income ||
        this.data.federalSettings?.income === 0
          ? this.data.federalSettings?.income
          : null,
      claimDependentsFederal:
        this.data.federalSettings?.claimDependents ||
        this.data.federalSettings?.claimDependents === 0
          ? this.data.federalSettings?.claimDependents
          : null,
      additionalIncomeFederal:
        this.data.federalSettings?.additionalIncome ||
        this.data.federalSettings?.additionalIncome === 0
          ? this.data.federalSettings?.additionalIncome
          : null,
      additionalDeductionsFederal:
        this.data.federalSettings?.additionalDeductions ||
        this.data.federalSettings?.additionalDeductions === 0
          ? this.data.federalSettings?.additionalDeductions
          : null,
      incomeState:
        this.data.stateSettings?.income || this.data.stateSettings?.income === 0
          ? this.data.stateSettings?.income
          : null,
      claimDependentsState:
        this.data.stateSettings?.claimDependents ||
        this.data.stateSettings?.claimDependents === 0
          ? this.data.stateSettings?.claimDependents
          : null,
      additionalIncomeState:
        this.data.stateSettings?.additionalIncome ||
        this.data.stateSettings?.additionalIncome === 0
          ? this.data.stateSettings?.additionalIncome
          : null,
      additionalDeductionsState:
        this.data.stateSettings?.additionalDeductions ||
        this.data.stateSettings?.additionalDeductions === 0
          ? this.data.stateSettings?.additionalDeductions
          : null,
      withholdingFederal: this.data.federalSettings?.federalTableId
        ? this.data.federalSettings?.federalTableId
        : 'None',
      withholdingState: this.data.stateSettings?.federalTableId
        ? this.data.stateSettings?.federalTableId
        : 'None',
      additionalWithholdingFederal:
        this.data.federalSettings?.additionalWithHolding ||
        this.data.federalSettings?.additionalWithHolding === 0
          ? this.data.federalSettings?.additionalWithHolding
          : null,
      additionalWithholdingState:
        this.data.stateSettings?.additionalWithHolding ||
        this.data.stateSettings?.additionalWithHolding === 0
          ? this.data.stateSettings?.additionalWithHolding
          : null,
      absoluteFederal:
        this.data.federalSettings?.additionalWithHolding === 1
          ? this.data.federalSettings.value
          : null,
      absoluteState:
        this.data.stateSettings?.additionalWithHolding === 1
          ? this.data.stateSettings.value
          : null,
      percentFederal:
        this.data.federalSettings?.additionalWithHolding === 2
          ? this.data.federalSettings.value
          : null,
      percentState:
        this.data.stateSettings?.additionalWithHolding === 2
          ? this.data.stateSettings.value
          : null,
      exemptionFederal: this.data.federalSettings?.exemption || null,
      exemptionState: this.data.stateSettings?.exemption || null,
      address: this.data.participantSetting?.representativePayee
        ? this.getValueRepresentativePayeeAddress(
            this.data.participantSetting?.representativePayee
          )
        : this.getValueAddress(this.data.participantSetting?.paymentAddress),
      representativePayee: this.data.participantSetting?.representativePayee
        ? this.getValueRepresentativePayee(
            this.data.participantSetting?.representativePayee
          )
        : null,
    });

    this.offCyclePayment = this.data.offCyclePayment;
    this.disabledSuspend = this.data.participantSetting?.updateBySystem;
    this.currentAddress = this.data.participantSetting?.paymentAddress?.address
      ? this.getValueAddress(this.data.participantSetting?.paymentAddress)
      : null;

    if (this.formGroup.get('payStatus')?.value) {
      this.formGroup.get('address')?.removeValidators(Validators.required);
    } else {
      this.formGroup.get('address')?.addValidators(Validators.required);
    }

    this.formGroup.get('address')?.updateValueAndValidity();
  }

  ngOnInit(): void {
    this.nextPaymentService.getFederalTables().subscribe((data: any) => {
      if (data.federalTables) {
        this.listTable = this.listTable.concat(
          data.federalTables.map((item: any) => {
            return {
              value: item.id,
              displayValue: item.tableName,
              form: item.form,
            };
          })
        );

        this.onChangeFormFederal();
        this.onChangeFormState();
        this.onChangeAdditionalFederal();
        this.onChangeAdditionalState();
        if (this.formGroup.get('representativePayee')?.value === null) {
          this.formGroup.get('address')?.enable();
        } else {
          this.formGroup.get('address')?.disable();
        }
      }
    });

    this.store
      .select(fromNextPayment.selectNextPaymentDeduction)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        this.participantDeductions = state?.deductions || [];
        if (!this.offCyclePayment) {
          this.paymentDeductionsService
            .getActiveDeduction()
            .subscribe((list) => {
              this.deductionList = list?.deductions;
              this.indexFederal = this.participantDeductions.findIndex(
                (el: any) => JSON.parse(el?.deductionSetting)?.TaxType === 0
              );
              this.indexState = this.participantDeductions.findIndex(
                (el: any) => JSON.parse(el?.deductionSetting)?.TaxType === 1
              );
              if (this.indexFederal > -1) {
                this.disabledFederal = !this.deductionList?.find(
                  (el: any) => JSON.parse(el?.deductionSetting)?.TaxType === 0
                );
              }
              if (this.indexState > -1) {
                this.disabledState = !this.deductionList?.find(
                  (el: any) => JSON.parse(el?.deductionSetting)?.TaxType === 1
                );
              }

              this.changeValidateOfControls();
            });
        } else {
          this.indexFederal = -1;
          this.indexState = -1;
          this.changeValidateOfControls();
        }
      });

    this.store.dispatch(NextPaymentActions.getMemberAddressItems());
    this.store
      .select(fromNextPayment.selectNextPayment)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((el) => {
        this.memberAddressItems = el.memberAddressItems;
        this.optionsAddress = el.memberAddressItems?.map((item: any) => {
          let addressObj = JSON.parse(item.address);

          return {
            value: this.getValueAddress(item),
            displayValue: this.addressPipe.transform(
              this.lowerCaseKey(addressObj),
              [],
              true
            ),
            valueDescription: `${item.itemName} / ${item.propertyName}`,
          };
        });
      });

    this.store.dispatch(NextPaymentActions.getRepresentativePayees());
    this.store
      .select(fromNextPayment.selectNextPayment)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((el) => {
        let data: any = [];
        this.optionsRepresentativePayees = [];

        this.representativePayees = el.representativePayees;
        data = el.representativePayees
          ?.filter(
            (item) =>
              !isObjectEmpty(item.address) &&
              !isObjectEmpty(item.name) &&
              !isEmpty(item.type)
          )
          ?.map((item: any) => {
            let personNameObj = item.name;
            let addressObj = item.address;
            return {
              value: this.getValueRepresentativePayee(item),
              displayValue: this.personNamePipe.transform(
                this.lowerCaseKey(personNameObj)
              ),
              valueDescription: `${item.type} / ${this.addressPipe.transform(
                this.lowerCaseKey(addressObj),
                [],
                true
              )}`,
            };
          });
        if (data) {
          this.optionsRepresentativePayees.push(...data);
        }
      });
  }

  lowerCaseKey(address: any) {
    return Object.keys(address).reduce((result: any, key: any) => {
      result[key[0].toLowerCase() + key.substring(1)] = address[key];
      return result;
    }, {});
  }

  getValueAddress(address: any) {
    if (!address) {
      return null;
    }
    return [address.itemKey, address.propertyKey, address.index].join('_');
  }

  getValueRepresentativePayee(representativePayee: any) {
    if (!representativePayee) {
      return null;
    }
    return [representativePayee.itemKey, representativePayee.index].join('_');
  }

  getValueRepresentativePayeeAddress(representativePayeeAddress: any) {
    if (
      !representativePayeeAddress ||
      !representativePayeeAddress.address ||
      !representativePayeeAddress.addressPropertyKey
    ) {
      return null;
    }
    return [
      representativePayeeAddress.itemKey,
      representativePayeeAddress.addressPropertyKey,
      representativePayeeAddress.index,
    ].join('_');
  }

  onChangePayStatus() {
    if (this.formGroup.get('payStatus')?.value) {
      this.formGroup.get('address')?.removeValidators(Validators.required);
    } else {
      this.formGroup.get('address')?.addValidators(Validators.required);
    }
    this.formGroup.get('address')?.updateValueAndValidity();
  }

  onChangeFormFederal() {
    if (
      this.formGroup.get('formFederal')?.value !== 0 &&
      this.formGroup.get('formFederal')?.value !== 1
    ) {
      this.formGroup.get('withholdingFederal')?.clearValidators();
      this.formGroup.get('withholdingFederal')?.setValue(null);
      this.formGroup.get('withholdingFederal')?.setErrors(null);
      this.formGroup.get('exemptionFederal')?.setValue(null);
    } else {
      this.formGroup
        .get('withholdingFederal')
        ?.setValidators([Validators.required]);
      this.formGroup
        .get('withholdingFederal')
        ?.setValue(
          this.formGroup.get('formFederal')?.value ===
            this.data.federalSettings?.form
            ? this.data.federalSettings &&
              !this.data.federalSettings?.federalTableId
              ? 'None'
              : this.data.federalSettings?.federalTableId
            : null
        );
      this.formGroup
        .get('exemptionFederal')
        ?.setValue(
          this.formGroup.get('formFederal')?.value !== 1 &&
            this.formGroup.get('formFederal')?.value ===
              this.data.federalSettings?.form
            ? this.data.federalSettings?.exemption
            : null
        );
      this.listFederalTable = [this.listFederalTable[0]];
      this.listFederalTable.push(
        ...this.listTable
          .filter(
            (item: any) =>
              item.form === this.formGroup.get('formFederal')?.value
          )
          .map((tb: any) => {
            return {
              value: tb.value,
              displayValue: tb.displayValue,
            };
          })
      );
    }
  }

  onChangeFormState() {
    if (
      this.formGroup.get('formState')?.value !== 0 &&
      this.formGroup.get('formState')?.value !== 1
    ) {
      this.formGroup.get('withholdingState')?.clearValidators();
      this.formGroup.get('withholdingState')?.setValue(null);
      this.formGroup.get('withholdingState')?.setErrors(null);
      this.formGroup.get('exemptionState')?.setValue(null);
    } else {
      this.formGroup
        .get('withholdingState')
        ?.setValidators([Validators.required]);
      this.formGroup
        .get('withholdingState')
        ?.setValue(
          this.formGroup.get('formState')?.value ===
            this.data.stateSettings?.form
            ? this.data.stateSettings &&
              !this.data.stateSettings?.federalTableId
              ? 'None'
              : this.data.stateSettings?.federalTableId
            : null
        );
      this.formGroup
        .get('exemptionState')
        ?.setValue(
          this.formGroup.get('formState')?.value !== 1 &&
            this.formGroup.get('formState')?.value ===
              this.data.stateSettings?.form
            ? this.data.stateSettings?.exemption
            : null
        );
      this.listStateTable = [this.listStateTable[0]];
      this.listStateTable.push(
        ...this.listTable
          .filter(
            (item: any) => item.form === this.formGroup.get('formState')?.value
          )
          .map((tb: any) => {
            return {
              value: tb.value,
              displayValue: tb.displayValue,
            };
          })
      );
    }
  }

  onChangeAdditionalFederal() {
    if (this.formGroup.get('additionalWithholdingFederal')?.value === 1) {
      this.formGroup
        .get('absoluteFederal')
        ?.addValidators([Validators.required]);
      this.formGroup.get('percentFederal')?.clearValidators();
      this.formGroup.get('percentFederal')?.setValue(null);
      this.formGroup.get('percentFederal')?.setErrors(null);
    } else {
      this.formGroup.get('absoluteFederal')?.clearValidators();
      this.formGroup.get('absoluteFederal')?.setValue(null);
      this.formGroup.get('absoluteFederal')?.setErrors(null);
      if (this.formGroup.get('additionalWithholdingFederal')?.value === 2) {
        this.formGroup
          .get('percentFederal')
          ?.addValidators([Validators.required, Validators.maxLength(5)]);
      } else {
        this.formGroup.get('percentFederal')?.clearValidators();
        this.formGroup.get('percentFederal')?.setValue(null);
        this.formGroup.get('percentFederal')?.setErrors(null);
      }
    }
  }

  onChangeAdditionalState() {
    if (this.formGroup.get('additionalWithholdingState')?.value === 1) {
      this.formGroup.get('absoluteState')?.addValidators([Validators.required]);
      this.formGroup.get('percentState')?.clearValidators();
      this.formGroup.get('percentState')?.setValue(null);
      this.formGroup.get('percentState')?.setErrors(null);
    } else {
      this.formGroup.get('absoluteState')?.clearValidators();
      this.formGroup.get('absoluteState')?.setValue(null);
      this.formGroup.get('absoluteState')?.setErrors(null);
      if (this.formGroup.get('additionalWithholdingState')?.value === 2) {
        this.formGroup
          .get('percentState')
          ?.addValidators([Validators.required, Validators.maxLength(5)]);
      } else {
        this.formGroup.get('percentState')?.clearValidators();
        this.formGroup.get('percentState')?.setValue(null);
        this.formGroup.get('percentState')?.setErrors(null);
      }
    }
  }

  changeValidateOfControls() {
    if (this.indexFederal < 0) {
      this.formGroup.get('formFederal')?.clearValidators();
      this.formGroup.get('formFederal')?.setValue(null);
      this.formGroup.get('formFederal')?.setErrors(null);
      this.formGroup.get('withholdingFederal')?.clearValidators();
      this.formGroup.get('withholdingFederal')?.setValue(null);
      this.formGroup.get('withholdingFederal')?.setErrors(null);
      this.formGroup.get('additionalWithholdingFederal')?.clearValidators();
      this.formGroup.get('additionalWithholdingFederal')?.setValue(null);
      this.formGroup.get('additionalWithholdingFederal')?.setErrors(null);
    }

    if (this.indexState < 0) {
      this.formGroup.get('formState')?.clearValidators();
      this.formGroup.get('formState')?.setValue(null);
      this.formGroup.get('formState')?.setErrors(null);
      this.formGroup.get('withholdingState')?.clearValidators();
      this.formGroup.get('withholdingState')?.setValue(null);
      this.formGroup.get('withholdingState')?.setErrors(null);
      this.formGroup.get('additionalWithholdingState')?.clearValidators();
      this.formGroup.get('additionalWithholdingState')?.setValue(null);
      this.formGroup.get('additionalWithholdingState')?.setErrors(null);
    }
  }

  changeRepresentativePayeeOptionValue() {
    if (!this.formGroup.get('representativePayee')?.value) {
      this.formGroup.get('address')?.enable();
      this.optionsAddress = this.memberAddressItems?.map((item: any) => {
        let addressObj = JSON.parse(item.address);
        return {
          value: this.getValueAddress(item),
          displayValue: this.addressPipe.transform(
            this.lowerCaseKey(addressObj),
            [],
            true
          ),
          valueDescription: `${item.itemName} / ${item.propertyName}`,
        };
      });
      this.formGroup.get('address')?.setValue(this.currentAddress);
    } else {
      const representativePayeeValue = this.formGroup.get(
        'representativePayee'
      )?.value;
      let arr = representativePayeeValue.split('_');
      let currentRepresentativePayee = this.representativePayees?.filter(
        (item: any) => item.itemKey === arr[0] && item.index === +arr[1]
      ) as any;
      const representativePayeeAddress = currentRepresentativePayee.map(
        (tb: any) => {
          return {
            value: this.getValueRepresentativePayeeAddress(tb),
            displayValue: this.addressPipe.transform(
              this.lowerCaseKey(tb.address),
              [],
              true
            ),
            valueDescription: `${tb.itemKey} / ${tb.addressPropertyKey}`,
          };
        }
      );

      this.optionsAddress.push(...representativePayeeAddress);
      this.formGroup
        .get('address')
        ?.setValue(
          this.getValueRepresentativePayeeAddress(currentRepresentativePayee[0])
        );
      this.formGroup.get('address')?.disable();
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.formGroup.valid) {
      let data = this.formGroup.getRawValue();
      let address = null;
      if (data.address) {
        let arr = data.address.split('_'); //addressValue: 'itemKey_propertyKey_index'
        let paymentAddress = this.memberAddressItems?.find(
          (item: any) =>
            item.itemKey === arr[0] &&
            item.propertyKey === arr[1] &&
            item.index === +arr[2]
        ) as any;
        address = {
          clientKey: paymentAddress?.clientKey,
          itemKey: paymentAddress?.itemKey,
          propertyKey: paymentAddress?.propertyKey,
          index: paymentAddress?.index,
          address: paymentAddress?.address
            ? this.lowerCaseKey(JSON.parse(paymentAddress?.address))
            : null,
        };
      }
      let payrollSettings = [];
      if (this.indexFederal >= 0 && !this.offCyclePayment) {
        payrollSettings.push({
          recalculateTax: data.isRecalculateFederal,
          form: data.formFederal === -1 ? null : data.formFederal,
          federalTableId:
            data.withholdingFederal === 'None' ? null : data.withholdingFederal,
          type: 0,
          additionalWithHolding: data.additionalWithholdingFederal,
          value:
            data.additionalWithholdingFederal === 1
              ? data.absoluteFederal
              : data.additionalWithholdingFederal === 2
              ? data.percentFederal
              : null,
          exemption: data.exemptionFederal,
          income: data.incomeFederal,
          claimDependents: data.claimDependentsFederal,
          additionalIncome: data.additionalIncomeFederal,
          additionalDeductions: data.additionalDeductionsFederal,
        });
      }

      let representativePayee: any;
      let arrRepresentativePayee = !!data.representativePayee
        ? data.representativePayee.split('_')
        : null;
      let representativePayeeData = !!arrRepresentativePayee
        ? (this.representativePayees?.find(
            (item: any) =>
              item.itemKey === arrRepresentativePayee[0] &&
              item.index === +arrRepresentativePayee[1]
          ) as any)
        : null;
      if (!!representativePayeeData) {
        representativePayee = {
          clientKey: representativePayeeData?.clientKey,
          itemKey: representativePayeeData?.itemKey,
          namePropertyKey: representativePayeeData?.namePropertyKey,
          addressPropertyKey: representativePayeeData?.addressPropertyKey,
          typePropertyKey: representativePayeeData?.typePropertyKey,
          name: representativePayeeData?.name,
          address: representativePayeeData?.address,
          type: representativePayeeData?.type,
          index: representativePayeeData?.index,
        };

        address = {
          clientKey: representativePayeeData?.clientKey,
          itemKey: representativePayeeData?.itemKey,
          propertyKey: representativePayeeData?.addressPropertyKey,
          index: representativePayeeData?.index,
          address: representativePayeeData?.address,
        };
      }

      if (this.indexState >= 0 && !this.offCyclePayment) {
        payrollSettings.push({
          recalculateTax: data.isRecalculateState,
          form: data.formState === -1 ? null : data.formState,
          federalTableId:
            data.withholdingState === 'None' ? null : data.withholdingState,
          type: 1,
          additionalWithHolding: data.additionalWithholdingState,
          value:
            data.additionalWithholdingState === 1
              ? data.absoluteState
              : data.additionalWithholdingState === 2
              ? data.percentState
              : null,
          exemption: data.exemptionState,
          income: data.incomeState,
          claimDependents: data.claimDependentsState,
          additionalIncome: data.additionalIncomeState,
          additionalDeductions: data.additionalDeductionsState,
        });
      }

      let body = {
        suspend: data.payStatus,
        paymentAddress: address,
        payrollSettings: payrollSettings,
        representativePayee: representativePayee,
      };
      this.dialogRef.close(body);
    }
  }

  onCancel(): void {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
