import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  getAlternatePayeesAction,
  getAlternatePayeesFailureAction,
  getAlternatePayeesSuccessAction,
  getQDROBenefitInformationAction,
  getQDROBenefitInformationFailureAction,
  getQDROBenefitInformationSuccessAction,
  getQDROLabelNameAction,
  getQDROLabelNameFailureAction,
  getQDROLabelNameSuccessAction,
  getQDROValidationBeforeInitializationAction,
  getQDROValidationBeforeInitializationFailureAction,
  getQDROValidationBeforeInitializationSuccessAction,
} from '../actions';
import { CalculationQDROService } from '../../services';
import {
  GetAlternatePayeesResponse,
  GetQDROBenefitInformationResponse,
  GetQDROLabelNameResponse,
  GetQDROValidationBeforeInitializationResponse,
} from '../../services/models';

@Injectable()
export class CalculationQDROEffect {
  constructor(
    private actions$: Actions,
    private calculationQDROService: CalculationQDROService,
  ) {}

  getColaGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getQDROLabelNameAction),
      switchMap(() =>
        this.calculationQDROService.getCalculationQDROLabelName().pipe(
          map((response: GetQDROLabelNameResponse) => getQDROLabelNameSuccessAction({ response })),
          catchError((error) => of(getQDROLabelNameFailureAction({ error }))),
        ),
      ),
    ),
  );

  getAlternatePayees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAlternatePayeesAction),
      switchMap(({ request }) =>
        this.calculationQDROService.getAlternatePayees(request).pipe(
          map((response: GetAlternatePayeesResponse) => getAlternatePayeesSuccessAction({ response })),
          catchError((error) => of(getAlternatePayeesFailureAction({ error }))),
        ),
      ),
    ),
  );

  getQDROValidationBeforeInitialization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getQDROValidationBeforeInitializationAction),
      switchMap(({ request }) =>
        this.calculationQDROService.getQDROValidationBeforeInitialization(request).pipe(
          map((response: GetQDROValidationBeforeInitializationResponse) =>
            getQDROValidationBeforeInitializationSuccessAction({ response }),
          ),
          catchError((error) => of(getQDROValidationBeforeInitializationFailureAction({ error }))),
        ),
      ),
    ),
  );

  getGetQDROBenefitInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getQDROBenefitInformationAction),
      switchMap(({ request }) =>
        this.calculationQDROService.getGetQDROBenefitInformation(request).pipe(
          map((response: GetQDROBenefitInformationResponse) => getQDROBenefitInformationSuccessAction({ response })),
          catchError((error) => of(getQDROBenefitInformationFailureAction({ error })))
        )
      )
    )
  );

}
