import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '@ptg-shared/components';
import { CalculationStep, GetMemberEntitiesResponse } from '../../services/models';
import { DateTime } from 'luxon';
import { Store, select } from '@ngrx/store';
import {
  CalculationState,
  getCardsAction,
  getCardsSelector,
  getMemberEntitiesAction,
  getMemberEntitiesSelector,
} from '../../store';
import { BOOLEAN_VALUE, CALCULATION_OPTION } from '../../constants';
import { filter, first, map, takeUntil } from 'rxjs/operators';
import { deepClone } from '@ptg-shared/utils/common.util';
import * as fromReducer from '@ptg-reducers/index';
import { SortType } from '@ptg-shared/constance';
import {Option} from "@ptg-shared/controls/select/select.component";

@Component({
  selector: 'ptg-step-configuration-list-step',
  templateUrl: './step-configuration-list-step.component.html',
  styleUrls: ['./step-configuration-list-step.component.scss'],
})
export class StepConfigurationListStepComponent extends BaseComponent implements OnChanges {
  @Input() stepDataFormGroup: any = new FormGroup({});
  @Input() calculationStep?: CalculationStep;
  @Input() canSubmit: boolean = false;
  @Input() cancelTime = DateTime.now();
  @Output() canSubmitChange = new EventEmitter<boolean>();

  initStepOptionValue: any = {};
  cardOptions: Option[] = [];

  constructor(
    private fb: FormBuilder,
    public calculationStore: Store<CalculationState>,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cancelTime && !changes.cancelTime.firstChange) {
      this.initStepDataFormGroup();
    }
  }

  ngOnInit(): void {
    this.calculationStep?.calculationStepOptions?.map((o) => {
      this.initStepOptionValue[o.key] = o.value;
    });
    this.getMemberEntities();
    this.onFormValueChange();
  }

  getMemberEntities() {
    this.calculationStore
      .select(getMemberEntitiesSelector)
      .pipe(
        filter((response) => !!response && !response.isLoading),
        map((response) => response?.payload ?? ({} as GetMemberEntitiesResponse)),
        first(),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((getMemberEntities) => {
        const filterByEntityIds = Object.values(getMemberEntities)
          .map((item) => item?.id)
          .filter((id) => !!id) as string[];
        this.getCards(filterByEntityIds);
      });
    this.calculationStore.dispatch(getMemberEntitiesAction());
  }

  private getCards(filterByEntityIds: string[]) {
    this.calculationStore
      .select(getCardsSelector)
      .pipe(
        filter((response) => !!response && !response.isLoading),
        map((response) => response?.payload?.cards ?? []),
        first(),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((cards) => {
        this.cardOptions = deepClone(cards)?.map((item) => {
          return {
            value: item.id,
            displayValue: item.cardName,
            valueDescription: item.entityName,
            extraData: item.entityComponentId,
          };
        });
        this.initStepDataFormGroup();
      });
    const request = {
      includeSystemCard: true,
      filterByEntityIds,
      sortNames: ['EntityName', 'CardName'],
      sortType: SortType.ASC,
    };
    this.calculationStore.dispatch(getCardsAction({ request }));
  }

  initStepDataFormGroup() {
    this.stepDataFormGroup.markAsUntouched();
    this.stepDataFormGroup.markAsPristine();
    this.stepDataFormGroup?.setControl(
      CALCULATION_OPTION.ByPass,
      new FormControl(this.initStepOptionValue[CALCULATION_OPTION.ByPass] === BOOLEAN_VALUE.TrueValue),
    );
    this.stepDataFormGroup?.setControl(
      CALCULATION_OPTION.StepLabelKey,
      new FormControl(this.initStepOptionValue[CALCULATION_OPTION.StepLabelKey], [
        Validators.required,
        Validators.maxLength(150),
      ]),
    );
    this.stepDataFormGroup?.setControl(
      CALCULATION_OPTION.SectionKey,
      new FormControl(this.initStepOptionValue[CALCULATION_OPTION.SectionKey]),
    );
    this.stepDataFormGroup?.setControl(
      CALCULATION_OPTION.CardKey,
      this.fb.control(this.initStepOptionValue[CALCULATION_OPTION.CardKey], [Validators.required]),
    );
  }

  get byPassControl(): FormControl {
    return this.stepDataFormGroup?.get(CALCULATION_OPTION.ByPass) as FormControl;
  }

  get stepLabelControl(): FormControl {
    return this.stepDataFormGroup?.get(CALCULATION_OPTION.StepLabelKey) as FormControl;
  }

  get sectionControl(): FormControl {
    return this.stepDataFormGroup?.get(CALCULATION_OPTION.SectionKey) as FormControl;
  }

  get cardControl(): FormControl {
    return this.stepDataFormGroup?.get(CALCULATION_OPTION.CardKey) as FormControl;
  }

  onFormValueChange() {
    this.stepDataFormGroup.valueChanges
      .pipe(
        filter((c) => !this.stepDataFormGroup.pristine),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        this.canSubmitChange.emit(true);
      });
  }

  changeCard() {
    const selectedCard = this.cardOptions.find(option => option.value === this.cardControl.value);
    this.sectionControl.setValue(selectedCard?.extraData);
  }
}
