<div
  *ngIf="bannerType"
  [ngClass]="{
    success: bannerType === 'Success',
    fail: bannerType === 'Fail',
    warning: bannerType === 'Warning',
    info: bannerType === 'Info'
  }"
  [ngStyle]="style"
  class="banner"
>
  <div class="banner-title">
    <ng-container *ngIf="bannerType">
      <mat-icon
        *ngIf="!isLoadingInfo; else loadingIcon"
        [ngClass]="bannerIconConfig?.className || ''"
        class="material-icons-round banner-icon"
      >
        {{ bannerIconConfig?.icon }}
      </mat-icon>
      <ng-template #loadingIcon>
        <mat-icon svgIcon="loadingIcon" [ngClass]="bannerIconConfig?.className || ''" class="banner-icon"></mat-icon>
      </ng-template>
      <span class="title">{{ title }}</span>
    </ng-container>
    <mat-icon (click)="closeAlert()" *ngIf="!isHideCloseButton" class="close-icon"> close </mat-icon>
  </div>
  <div class="banner-content">
    <ng-container *ngFor="let item of messages">
      <div>
        {{ item }}
      </div>
    </ng-container>
  </div>
</div>
