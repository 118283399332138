import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Sort } from '@angular/material/sort';

import { BaseComponent } from '@ptg-shared/components';
import { Column, ColumnType } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';

import * as fromReducer from '@ptg-reducers';
import * as fromMember from '@ptg-member/store/reducers';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import * as StatusHistoryActions from '@ptg-member/store/actions/status-history.action';
import { GetListStatusHistoryQuery, History } from '@ptg-member/types/models';
import { clearSetMemberEventStateAction } from '@ptg-member/store/actions/member.action';
import { setMemberEventSelector } from '@ptg-member/store/selectors/member.selector';
import { Menu, MenuItem, ViewFixType } from '@ptg-shared/layout/models/layout.model';
import { STRING_QUERY_PARAM } from '@ptg-shared/layout/constance/layout.const';
import { EntityProfileFixType } from '@ptg-entity-management/types/enums';

const PAGE_SIZE_CONST = '-ptg-entity-status-history-pageSize';

@Component({
  selector: 'ptg-entity-status-history',
  templateUrl: './entity-status-history.component.html',
  styleUrls: ['./entity-status-history.component.scss']
})
export class EntityStatusHistoryComponent extends BaseComponent {
  @Input() memberId!: string;

  readonly EntityProfileFixType = EntityProfileFixType;
  columns: Column[] = [
    {
      name: 'statusDate',
      header: {
        title: 'Event Date',
      },
      type: ColumnType.DateTime,
      templateArgs: { format: 'MM/dd/yyyy' },
    },
    {
      name: 'statusName',
      header: {
        title: 'Status',
      },
    },
    {
      name: 'statusEvent',
      header: {
        title: 'Event',
      },
    },
  ];

  memberNavigationMenu!: Menu[];
  statusHistorysData: History[] = [];
  sortInfo?: Sort = {
    active: 'Name',
    direction: 'asc',
  };
  lengthPg: number | any;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  currentRowIndex: number = 0;
  memberStatusHistoryId?: string;
  currentFund: any = {};
  isLoading = false;

  constructor(
    public route: ActivatedRoute,
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
    public dialog: MatDialog,
    private router: Router
  ) {
    super()
   }

  ngOnInit(): void {
    super.ngOnInit();

    this.getCurrentFund();

    this.getData();
    this.getStatusHistorySelector();
    this._setMemberEventSelector();

    this.memberStore
      .pipe(
        select(fromLayoutReducer.selectProfileNavigationState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state.isReloading && state.memberId) {
          this.memberNavigationMenu = state.menu;
        }
      });
  }

  _setMemberEventSelector() {
    this.memberStore
      .pipe(select(setMemberEventSelector), takeUntil(this.unsubscribe$))
      .subscribe((el) => {
        if (el) {
          if (el.success === true) {
            this.getData();
          }

          this.memberStore.dispatch(
            clearSetMemberEventStateAction()
          );
        }
      });
  }

  getStatusHistorySelector() {
    this.memberStore
    .pipe(
      select(fromMember.selectStatusHistoryState),
      takeUntil(this.unsubscribe$)
    )
    .subscribe((state) => {
      this.lengthPg = state?.statusHistoryList?.total;
      this.isLoading = state?.isLoading;
      this.statusHistorysData = state?.statusHistoryList?.memberStatusHistoryDto.map((item, index) => ({...item, order: index}));
      if (state.createState || state.updateState) {
        this.memberStore.dispatch(
          StatusHistoryActions.clearStatusHistoryState()
        );
      }
    });
  }

  getCurrentFund() {
    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.pageSize = el.defaultPageSize ?? 50;
        this.currentFund = el;
        this.pageSize =
          Number(
            sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)
          ) === 0
            ? this.pageSize
            : Number(
                sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)
              );
      });
  }

  getData() {
    let sortType = 1;
    let sortNames = ['StatusDate'];
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = [capitalizeFirstLetter(this.sortInfo.active)];
      sortType = this.sortInfo.direction === 'desc' ? 1 : 0;
    }
    const query: GetListStatusHistoryQuery = {
      memberId: this.memberId,
      pageIndex: this.pageNumber,
      pageSize: this.pageSize,
      sortNames: sortNames,
      sortType: sortType,
    };
    this.memberStore.dispatch(
      StatusHistoryActions.getStatusHistoryList({ query })
    );
  }

  sortChange(event: any) {
    this.sortInfo = event;
    this.currentRowIndex = 0;
    this.getData();
  }

  changePage(event: PageEvent) {
    this.currentRowIndex = 0;
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;

    this.getData();
  }

  onEditMemberStatus(statusHistory?: History) {
    // TODO: Out-Scope
  }

  selectRow(event: any) {
    this.memberStore
    .pipe(
      select(fromLayoutReducer.selectProfileNavigationState),
      takeUntil(this.unsubscribe$)
    )
    .subscribe((state) => {
      if (state.isReloading && state.memberId) {
        const allMenuItems = this.memberNavigationMenu.reduce((acc: MenuItem[], menu: Menu) => {
          return [...acc, ...menu?.menuItems ?? []];
        }, []);
        
        const menuSameCard = allMenuItems.find(menuitem => menuitem.viewFixType === ViewFixType.StatusHistory);
        
        if (menuSameCard) {
          const url = `${menuSameCard.routerLink.split('/').concat([state.memberId]).filter((x) => x).join('/')}?${STRING_QUERY_PARAM.PROFILE_NAVIGATE}&currentRowIndex=${event?.order}`;
          this.router.navigateByUrl(url);
        }
      }
    });
  }

  addStatusHistory() {
    // TODO: Out-Scope

  }

  manageStatusHistory() {
    // TODO: Out-Scope

  }

  onChangeMemberDetail() {
    this.currentRowIndex = 0;
  }
}
