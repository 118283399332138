import { createReducer, on } from "@ngrx/store";
import { BulkUpdateLogActions } from "../actions";
import { BulkUpdateLog } from "../models/bulk-update-log.model";

export const bulkUpdateLogFeatureKey = 'bulkUpdateLog';

export interface State {
  isError: boolean;
  isLoading: boolean;
  isCanceling: boolean;
  isProceeding: boolean;
  isCanceled: boolean;
  isProceeded: boolean;
  bulkUpdateLog: BulkUpdateLog;
}

const initialState: State = {
  isError: false,
  isLoading: true,
  isCanceling: false,
  isProceeding: false,
  isCanceled: false,
  isProceeded: false,
  bulkUpdateLog: {
    importStatus: '',
    summary: [],
    warnings: [],
    errors: [],
    isDisableReportGenerate: false
  }
};

export const reducer = createReducer(
  initialState,
  on(BulkUpdateLogActions.getBulkUpdateLog, (state) => ({
    ...state,
    isLoading: true,
    isError: false,
    isProceeded: false,
    isProceeding: false,
    isCanceled: false,
    isCanceling: false
  })),
  on(BulkUpdateLogActions.getBulkUpdateLogSuccess, (state, { bulkUpdateLog }) => ({
      ...state,
      isLoading: false,
      bulkUpdateLog
  })),
  on(BulkUpdateLogActions.getBulkUpdateLogFailure, (state, { errorMsg }) => ({
    ...state,
    isError: true,
    isLoading: false
  })),
  on(BulkUpdateLogActions.cancelBulkUpdate, (state, { sessionId }) => ({
    ...state,
    isCanceling: true
  })),
  on(BulkUpdateLogActions.cancelBulkUpdateSuccess, (state) => ({
      ...state,
      isCanceling: false,
      isCanceled: true
  })),
  on(BulkUpdateLogActions.cancelBulkUpdateFailure, (state, { errorMsg }) => ({
    ...state,
    isError: true,
    isCanceling: false
  })),
  on(BulkUpdateLogActions.proceedBulkUpdate, (state, { sessionId }) => ({
    ...state,
    isProceeding: true
  })),
  on(BulkUpdateLogActions.proceedBulkUpdateSuccess, (state) => ({
      ...state,
      isProceeding: false,
      isProceeded: true
  })),
  on(BulkUpdateLogActions.proceedBulkUpdateFailure, (state, { errorMsg }) => ({
    ...state,
    isError: true,
    isProceeding: false
  }))  
);