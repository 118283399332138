<div class="payment-information-configuration" id="payment-information-configuration">
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
    (clickBreadcrumb)="onClickBreadcrumb()">
  </ptg-breadcrumb>

  <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div class="wrap-btn">
      <button mat-raised-button 
        id="button-save" type="submit" 
        style="background-color: #408bf9;color: #fff;"
        >Save</button>
      <button mat-raised-button 
        id="button-cancel" 
        type="button" 
        style="background-color: #acabab;color: #fff" 
        (click)="onCancel()"
        >Cancel</button>
    </div>
    <div class="config-form">
      <div *ngFor="let config of paymentInforConfig">
        <div class="group-control">
          <ptg-toggle-button
            [controlField]="formGroup.get(config.code)"
            [single]="true"
            [displayLabel]="true"
            [label]="config.name"
            (valueChange)="changeToggle($event)"
          ></ptg-toggle-button>
          <div *ngIf="config.children.length > 0 && formGroup.get(config.code)?.value">
            <div *ngFor="let configChild of config.children">
              <ptg-checkbox
                [controlField]="formGroup.get(configChild.code)"
                [label]="configChild.name"
              ></ptg-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
