<div class="note-container">
  <div class="button-container" [ngClass]="'history-view'">
    <div>
      <div class="setting-container">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>note_add</mat-icon>
        </button>
        <mat-menu #menu="matMenu" class="add-menu">
          <button mat-menu-item (click)="onClickAdd(PaymentInfoNoteType.Note)" class="add-menu-item">
            Note
          </button>
          <button *ngIf="allowAddMemo" mat-menu-item (click)="onClickAdd(PaymentInfoNoteType.Memo)" class="add-menu-item">
            Memo
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
  <div *ngFor="let item of dataNotes" [ngClass]="{'note-wrap': isHistory}">
    <div class="note">
      <div class="created-info">
        <div>
          <mat-icon>{{item.type === PaymentInfoNoteType.Note ? 'insert_drive_file' : item.type === PaymentInfoNoteType.Memo ? 'price_change' : 'description'}}</mat-icon>
          <div class="created-date">
            <span class="label-bold">{{item.type === PaymentInfoNoteType.Note ? 'Note' : item.type === PaymentInfoNoteType.Memo ?'Memo' : 'Reason'}} </span>
            <span class="label-bold">{{item.postedDate | date: 'MM/dd/yyyy hh:mm a'}}</span>
          </div>
          <span class="label">{{item.updatedBy ? 'Last Updated By: ' : 'Created by: '}}</span>
          <span class="value">{{item.createdBy}}</span>
        </div>
        <div class="btn-right" *ngIf="item.type === PaymentInfoNoteType.Note || (PaymentInfoNoteType.Memo && allowAddMemo)">
          <ptg-button buttonName="Edit" classInput="button" (clickButton)="onClickEditNote(item, item.type)">
            <mat-icon>edit</mat-icon>
          </ptg-button>
          <ptg-button *ngIf="item.type === PaymentInfoNoteType.Memo" buttonName="Remove" classInput="button" (clickButton)="onClickRemoveMemo(item)">
            <mat-icon>delete_forever</mat-icon>
          </ptg-button>
        </div>
      </div>
      <div class="note-content">
        <pre [ngClass]="{'line-clamp-2': item.isTruncate}" ptgTruncateText>{{item.note}}</pre>
        <ng-container *ngIf="item.isTruncate; else noTruncate">
          <span (click)="item.isTruncate = false">See more</span>
        </ng-container>
        <ng-template #noTruncate>
          <span (click)="item.isTruncate = true">See less</span>
        </ng-template>
      </div>
    </div>
  </div>
  <ng-container *ngIf="dataNotes?.length > 0">
    <ptg-pagination
      #paginator
      [totalRecords]="lengthPg"
      (pageChange)="changePagging($event)"
      [pageSize]="pageSize"
      [pageNumber]="pageNumber"
      [hiddenPageSizeOptions]="true"
    ></ptg-pagination>
  </ng-container>
</div>
