<table
  #table
  mat-table
  [dataSource]="dataSource"
  matSort
  [matSortDisabled]="!sortHeader"
  (matSortChange)="sortData($event)"
  class="form-table"
  cdkDropList
  [cdkDropListDisabled]="!canDropDrag"
  (cdkDropListDropped)="dropTable($event)"
>
  <ng-container *ngFor="let item of styleDisplayedColumns; index as i">
    <ng-container [matColumnDef]="displayedColumns[i]">
      <ng-container *ngIf="!canDropDrag; else candrop">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{this.headerColumns[i]}} </th>
      </ng-container>
      <ng-template #candrop>
        <th mat-header-cell *matHeaderCellDef> {{this.headerColumns[i]}} </th>
      </ng-template>

      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.get(this.displayedColumns[i]) && !element.get(this.displayedColumns[i]).disabled &&
          item !== 'ICON_DROP' && item !== 'ICON_EDIT'">
          <ptg-textbox
            [controlField]="element.get(this.displayedColumns[i])"
          ></ptg-textbox>
        </ng-container>
        <ng-container *ngIf="element.get(this.displayedColumns[i]) && element.get(this.displayedColumns[i]).disabled &&
          item !== 'ICON_DROP' && item !== 'ICON_EDIT'">
          {{element.get(this.displayedColumns[i]).value}}
        </ng-container>

        <ng-container *ngIf="item === 'ICON_DROP'">
          <mat-icon class="ignore-row" aria-hidden="false" aria-label="drag-handle" svgIcon="drag-handle"></mat-icon>
        </ng-container>
        
        <ng-container *ngIf="item === 'ICON_EDIT'">
          <ptg-button *ngIf="element.get(this.displayedColumns[i]) && element.get(this.displayedColumns[i])?.disabled"
            buttonName="Edit" classInput="edit-button" (clickButton)="fnButtonEdit($event, element, 'edit')">
            <mat-icon>edit</mat-icon>
          </ptg-button>
          <ng-container *ngIf="element.get(this.displayedColumns[i]) && !element.get(this.displayedColumns[i])?.disabled">
            <ptg-button buttonName="" classInput="yesno-button" (clickButton)="fnButtonEdit($event, element, 'close')">
              <mat-icon>close</mat-icon>
            </ptg-button>
            <ptg-button buttonName="" classInput="yesno-button" (clickButton)="fnButtonEdit($event, element, 'accept')">
              <mat-icon style="color: #196f57;">check</mat-icon>
            </ptg-button>
          </ng-container>
        </ng-container>
      </td>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!isHiddenHeader">
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: isNotSticky ? false : true"></tr>
  </ng-container>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns;"
    cdkDrag cdkDragLockAxis="y"
    [cdkDragData]="row"
    [class.selected]="tableStriped && row?.id % 2 === 0"
    [class.target-row]="isHighlightRow"
  ></tr>
</table>
