import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
  UploadCalculationDialogComponent
} from '@ptg-member/features/calculation/components/upload-calculation-dialog/upload-calculation-dialog.component';
import { BaseComponent } from '@ptg-shared/components';
import { filter, takeUntil } from 'rxjs/operators';
import { FIRST_PAGE } from '@ptg-shared/controls/pagination';
import * as fromReducer from '@ptg-reducers';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { SortType, STATE } from '@ptg-shared/constance';
import { capitalizeFirstLetter, isEmpty } from '@ptg-shared/utils/string.util';
import { showBanner } from '@ptg-shared/utils/common.util';
import {
  CalculationState,
  clearParameterMappingStateAction,
  clearRetirementBenefitCalculationState,
  getCalculationDetailRequest,
  getCalculationInputMappingRequest,
  getCalculationOutputMappingRequest,
  parameterMappingActionSelector,
  removeParameterMappingAction,
  selectCalculationInputMappingState,
  selectCalculationOutputMappingState,
  selectRetirementBenefitCalculationDetailState,
  selectUploadCalculationFileState
} from '../../store';
import { CalculationListService } from '../../services';
import { ParameterType } from '../../constants';
import {
  CalculationDetail,
  GetCalculationParameterMappingQuery,
  MappingParameter,
  ParameterMappingSection
} from '../../services/models';
import { AddParameterMappingComponent } from '../../components';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { MenuItemSubTitle, MenuItemTitle } from '@ptg-member/constants';
const PAGE_SIZE_CONST = '-ptg-retirement-benefit-calculation-detail-pageSize'

@Component({
  selector: 'ptg-retirement-benefit-calculation-detail',
  templateUrl: './retirement-benefit-calculation-detail.component.html',
  styleUrls: ['./retirement-benefit-calculation-detail.component.scss']
})
export class RetirementBenefitCalculationDetailComponent extends BaseComponent {
  memberId: string = '';
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Calculation List',
      url: ''
    },
    {
      name: 'Calculation File'
    }
  ];
  currentFund: any = {};
  bannerType: BannerType = BannerType.Hidden;
  message = '';
  defaultPageSize: number = 50;
  calculationId: string = '';
  calculationDetail?: CalculationDetail;
  listFieldData: any[] = [];
  calculationInputMapping: ParameterMappingSection = {
    title: 'Input',
    parameterType: ParameterType.Input,
    parameterMappings: [],
    isLoading: true,
    pageIndex: FIRST_PAGE,
    pageSize: 50
  };
  calculationOutputMapping: ParameterMappingSection = {
    title: 'Ouput',
    parameterType: ParameterType.Output,
    parameterMappings: [],
    isLoading: true,
    pageIndex: FIRST_PAGE,
    pageSize: 50
  };

  constructor(
    public dialog: MatDialog,
    public route: ActivatedRoute,
    private calculationStore: Store<CalculationState>,
    private calculationListService: CalculationListService,
    private store: Store<fromReducer.State>,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getCurrentFundState();
    const inputPageSizeInSession = Number(sessionStorage.getItem(this.currentFund.key + ParameterType.Input + PAGE_SIZE_CONST));
    const outputPageSizeInSession = Number(sessionStorage.getItem(this.currentFund.key + ParameterType.Output + PAGE_SIZE_CONST));
    this.calculationInputMapping.pageSize = inputPageSizeInSession === 0 ? this.defaultPageSize : inputPageSizeInSession;
    this.calculationOutputMapping.pageSize = outputPageSizeInSession === 0 ? this.defaultPageSize : outputPageSizeInSession;
    this.getRouteParamsState();
    this.getCalculationDetailState();
    this.getCalculationInputMappingState();
    this.getCalculationOutputMappingState();
    this.checkUploadCalculationState();
    this.selectParameterMappingActionState();
  }

  selectParameterMappingActionState() {
    this.calculationStore
      .pipe(
        select(parameterMappingActionSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state && !isEmpty(state.parameterType)) {
          showBanner.call(this, state.state || '', `${ ParameterType[state.parameterType] } mapping`, state.action || '');
          const currentSection = this.getCurrentSection(state.parameterType);
          if (state.state === STATE.SUCCESS && currentSection) {
            this.getDataParameterMapping(currentSection);
          }
          this.calculationStore.dispatch(clearParameterMappingStateAction());
        }
      });
  }

  getCurrentFundState() {
    this.store.pipe(
      select(fromReducer.selectCurrentFundState),
      takeUntil(this.unsubscribe$)
    ).subscribe(el => {
      this.defaultPageSize = el.defaultPageSize ?? 50;
      this.currentFund = el;
    });
  }

  getRouteParamsState() {
    this.route.params.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((params) => {
      this.memberId = params.memberId;
      this.calculationId = params.calculationId;
      this.listBreadcrumbs[0].url = `/member/calculation/retirement-benefit-calculation-list/${ params.memberId }`
      this.getCalculationDetail();
      this.getDataParameterMapping(this.calculationInputMapping);
      this.getDataParameterMapping(this.calculationOutputMapping);
    });
  }

  getCalculationDetailState() {
    this.calculationStore.pipe(
      select(selectRetirementBenefitCalculationDetailState),
      takeUntil(this.unsubscribe$)
    ).subscribe((state) => {
      this.calculationDetail = state?.payload;
      this.listFieldData = [
        {
          name: 'Name',
          value: state?.payload?.name,
        },
        {
          name: 'Benefit Option',
          value: state?.payload?.benefitEntityName,
        },
        {
          name: 'Document Description',
          value: state?.payload?.description,
        },
        {
          name: 'File Name',
          value: state?.payload?.fileDocument?.documentName,
        },
      ];
    });
  }

  getCalculationInputMappingState() {
    this.calculationStore.pipe(
      select(selectCalculationInputMappingState),
      takeUntil(this.unsubscribe$)
    ).subscribe((state) => {
      this.calculationInputMapping.isLoading = state?.isLoading;
      this.calculationInputMapping.lengthPg = state?.total;
      this.calculationInputMapping.parameterMappings = state?.payload;
    });
  }

  getCalculationOutputMappingState() {
    this.calculationStore.pipe(
      select(selectCalculationOutputMappingState),
      takeUntil(this.unsubscribe$)
    ).subscribe((state) => {
      this.calculationOutputMapping.isLoading = state?.isLoading;
      this.calculationOutputMapping.lengthPg = state?.total;
      this.calculationOutputMapping.parameterMappings = state?.payload;
    });
  }

  getCalculationDetail() {
    this.calculationStore.dispatch(getCalculationDetailRequest({ calculationId: this.calculationId }));
  }

  getDataParameterMapping(section: ParameterMappingSection) {
    let sortType = SortType.ASC;
    let sortField = 'name';
    if (section?.sortInfo?.active && section?.sortInfo?.direction) {
      sortField = capitalizeFirstLetter(section?.sortInfo.active);
      sortType = section?.sortInfo.direction === 'desc' ? SortType.DESC : SortType.ASC;;
    }
    const query: GetCalculationParameterMappingQuery = {
      calculationId: this.calculationId,
      pageIndex: section?.pageIndex || FIRST_PAGE,
      pageSize: section?.pageSize,
      sortField,
      sortType
    };
    if (section.parameterType === ParameterType.Input) {
      this.calculationStore.dispatch(getCalculationInputMappingRequest({ query }));
    } else {
      this.calculationStore.dispatch(getCalculationOutputMappingRequest({ query }));
    }
  }

  sortChange(event: any) {
    const currentSection = this.getCurrentSection(event?.parameterType);
    if (currentSection) {
      currentSection.sortInfo = event.event;
      this.getDataParameterMapping(currentSection);
    }
  }

  changePage(event: any) {
    const currentSection = this.getCurrentSection(event?.parameterType);
    if (currentSection) {
      currentSection.pageSize = event.event.pageSize;
      currentSection.pageIndex = event.event.pageNumber;
      sessionStorage.setItem(this.currentFund.key + currentSection.parameterType + PAGE_SIZE_CONST, event.event.pageSize.toString());
      this.getDataParameterMapping(currentSection);
    }
  }

  getCurrentSection(parameterType?: ParameterType) {
    return parameterType === ParameterType.Input
      ? this.calculationInputMapping
      : this.calculationOutputMapping;
  }

  onClickEditCalculation() {
    this.dialog.open(UploadCalculationDialogComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      autoFocus: false,
      data: this.calculationDetail,
    });
  }

  onClickAddNewParameter(parameterType: ParameterType) {
    this.dialog.open(AddParameterMappingComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      autoFocus: false,
      data: {
        type: parameterType,
        calculationId: this.calculationId,
      },
    });
  }

  onEdit(row: MappingParameter | any) {
    this.dialog.open(AddParameterMappingComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      autoFocus: false,
      data: {
        ...row,
        calculationId: this.calculationId,
      },
    });
  }

  onRemove(row: MappingParameter | any) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        title: 'THIS IS A DESTRUCTIVE ACTION',
        type: ConfirmType.Destruct,
        text: `Removing this mapping ${ row.type === ParameterType.Input ? 'input' : 'output' } may affect the estimations using this calculation. Are you sure you want to proceed?`
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.calculationListService.checkParameterMapping(row.id).subscribe((data: any) => {
          if(data.exists) {
            this.calculationStore.dispatch(removeParameterMappingAction({ id: row.id, parameterType: row.type }));
          } else {
            this.dialog.open(ConfirmPopupComponent, {
              panelClass: 'confirm-popup',
              data: {
                title: 'Error',
                type: ConfirmType.Warning,
                text: data.message,
                cancelButtonTitle: 'Close',
                hideConfirmButton: true,
              },
            });
          }
        });
      }
    });
  }

  checkUploadCalculationState() {
    this.calculationStore
      .pipe(
        select(selectUploadCalculationFileState),
        filter(uploadCalculationFileState => uploadCalculationFileState.state === STATE.SUCCESS),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((uploadCalculationFileState) => {
        if (!uploadCalculationFileState.state) {
          return;
        }
        showBanner.call(this, uploadCalculationFileState.state, '', '', { customMessage: 'Calculation file successfully uploaded.' });
        this.calculationStore.dispatch(clearRetirementBenefitCalculationState());
        this.getCalculationDetail();
        this.getDataParameterMapping(this.calculationInputMapping);
        this.getDataParameterMapping(this.calculationOutputMapping);
      });
  }
}
