import { createReducer, on } from "@ngrx/store";
import { BulkUpdateHistoryActions } from "../actions";
import { BulkUpdateHistoryData } from "../models/bulk-update.model";

export const bulkUpdateHistoryFeatureKey = 'bulkUpdateHistory';

export interface State {
  isLoading: boolean;
  bulkUpdateHistoryData: BulkUpdateHistoryData;
  removeState?: string;
  error?: any;
  success?: boolean;
}

const initialState: State = {
  isLoading: true,
  bulkUpdateHistoryData: {
    total: 0,
    sessions: []
  }
};

export const reducer = createReducer(
  initialState,
  on(BulkUpdateHistoryActions.getBulkUpdateHistory, (state, { initialLoad }) => ({
    ...state,
    isLoading: initialLoad,
    BulkUpdateData: {
      total: 0,
      sessions: []
    }
  })),
  on(BulkUpdateHistoryActions.getBulkUpdateHistorySuccess, (state, { bulkUpdateHistoryData }) => ({
      ...state,
      isLoading: false,
      bulkUpdateHistoryData,
      success: true,
      error: undefined
  })),
  on(BulkUpdateHistoryActions.getBulkUpdateHistoryFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    bulkUpdateHistoryData: {
      total: 0,
      sessions: []
    },
    success: false,
    error: error
  })),
  on(BulkUpdateHistoryActions.removeBulkUpdateHistorySuccess, (state) => ({
    ...state,
    removeState: 'Success'
  })),
  on(BulkUpdateHistoryActions.removeBulkUpdateHistoryClear, (state) => ({
      ...state,
      removeState: ''
  })),
);