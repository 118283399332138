<div class="add-benefit-type" id="benefit-type-id">
  <ptg-title-bar
    [name]="isEdit ? 'Edit' : 'Add New'"
    viewName="Benefit Type"
    [onlyTitle]="true"
  ></ptg-title-bar>

  <form *ngIf="editForm" [formGroup]="editForm" (ngSubmit)="onSubmit()">
    <div class="wrap-btn">
      <button mat-raised-button type="submit" class="submit-button">
        Save
      </button>
      <button
        mat-raised-button
        type="button"
        class="cancel-button"
        (click)="onCancel()"
      >
        Cancel
      </button>
    </div>
    <div class="form-control-container">
      <ptg-textbox
        [controlField]="nameCtrl"
        [hasLabel]="true"
        [maxLength]="255"
        placeholder="Benefit Type Name"
        errorAsync="Benefit Type Name already exists."
      ></ptg-textbox>
      <ptg-textbox
        [controlField]="codeCtrl"
        placeholder="Benefit Type Code"
        errorAsync="Benefit Type Code already exists."
        [hasLabel]="true"
        [maxLength]="5"
      ></ptg-textbox>
      <ptg-select
        [controlField]="memberStatusIdCtrl"
        placeholder="Status"
        [listData]="statuseOptions"
        [isOptionObj]="true"
        [isSetMaxWidthSelect]="true"
        [isRequired]="true"
        class="max-width"
        (changeOptionValue)="onChangeStatus()"       
      >
      </ptg-select>
      <ptg-select
        [controlField]="memberEventIdCtrl"
        placeholder="Status Event"
        [listData]="eventOptions"
        [isOptionObj]="true"
        [isSetMaxWidthSelect]="true"
        [isRequired]="true"
        class="max-width"  
      >
      </ptg-select>
      <ptg-select
        [controlField]="benefitRecordCtrl"
        placeholder="Benefit Record"
        [listData]="benefitRecordOptions"
        [isOptionObj]="true"
        [isSetMaxWidthSelect]="true"
        [isRequired]="true"
        class="max-width"
      >
      </ptg-select>

      <div class="divider"></div>

      <div class="param-list">
        <div
          class="row-param"
          *ngFor="let rowControls of listParameter?.controls; index as i"
          >
          <ptg-select
            [controlField]="rowControls.get('parentType')"
            placeholder="Parent Type"
            [listData]="
              filterBenefitTypes(rowControls.get('parentType')?.value)
            "
            [isOptionObj]="true"
            [isSetMaxWidthSelect]="true"
            (changeOptionValue)="onChangeParentType(rowControls)"
          ></ptg-select>
          <ptg-select
            [controlField]="rowControls.get('relationshipType')"
            [listData]="relationshipTypeOptions"
            [isOptionObj]="true"
            [isSetMaxWidthSelect]="true"
            [isRequired]="rowControls.get('parentType')?.value"
            placeholder="Relationship Type"
            errorRequire="Relationship Type is required."
          ></ptg-select>
          <ptg-textbox
            [hasLabel]="true"
            [controlField]="rowControls.get('parentLabel')"
            [isRequired]="rowControls.get('parentType')?.value"
            errorRequire="Parent Label is required."
            placeholder="Parent Label"
          ></ptg-textbox>
          <ptg-textbox
            [hasLabel]="true"
            [controlField]="rowControls.get('childLabel')"
            [isRequired]="rowControls.get('parentType')?.value"
            errorRequire="Child Label is required."
            placeholder="Child Label"
          ></ptg-textbox>
          <span class="remove-btn" (click)="onClickRemoveParentType(i)" *ngIf="rowControls.get('parentTypeEditable')?.value">
            <mat-icon>delete_forever</mat-icon>
          </span>
        </div>

        <ptg-button
          *ngIf="parentTypesIsValid()"
          buttonName="Add Parent Type"
          class="add-btn"
          classInput="add-button"
          (clickButton)="onClickAddParentType()"
        >
          <mat-icon>add</mat-icon>
        </ptg-button>
      </div>
    </div>
  </form>
</div>
