import { ValidatorFn } from '@angular/forms';

import { Column } from './types/models';

export function getValidatorsFromColumns(
  columnName: string,
  columns: Column[],
  obj: any
): ValidatorFn[] | null {
  const column = columns.find((col) => col.name === columnName);
  if (!column?.validators) return null;

  return Object.keys(column.validators).map((key) =>
    column.validators![key].type(obj)
  );
}
