import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AnnualCertificationService } from '@ptg-employer/services/annual-certification.service';
import { InputType } from '@ptg-member/constance/metadataPropertyType.const';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { Observable, of, Subject, timer } from 'rxjs';
import { catchError, debounceTime, filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';

@Component({
  selector: 'ptg-view-credit-confirmation-dialog',
  templateUrl: './view-credit-confirmation-dialog.component.html',
  styleUrls: ['./view-credit-confirmation-dialog.component.scss'],
})
export class ViewCreditConfirmationDialogComponent implements OnInit {
  readonly InputType = InputType;
  cashJournalControl: FormControl = this.fb.control(0);
  formSubmit$ = new Subject();

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ViewCreditConfirmationDialogComponent>,
    private annualCertificationService: AnnualCertificationService,
    @Inject(MAT_DIALOG_DATA) public data: {
      messages: string[],
      cashJournalEntry: string,
      hideCashJournal: boolean,
      saveButtonLabel?: string,
    },
  ) {
  }

  ngOnInit(): void {
    this.initCashJournalControl();
    this.checkSubmitForm();
  }

  cancel() {
    this.dialogRef.close();
  }

  validateCashJournal(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return timer(300).pipe(
        switchMap((): Observable<ValidationErrors | null> =>
          this.annualCertificationService.verifyCashJournal(control.value.toString()).pipe(
            map(() => {
              return null;
            }),
            catchError(({ error }) => {
              return of({ invalidCashJournal: error?.errorMessage?.[1] });
            }),
          ),
        ),
      );
    };
  }

  checkSubmitForm() {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.cashJournalControl.markAsTouched();
        }),
        debounceTime(500),
        switchMap(() =>
          this.cashJournalControl.statusChanges.pipe(
            startWith(this.cashJournalControl.status),
            filter(status => status !== AbstractControlStatus.PENDING),
            take(1),
          ),
        ),
        filter(status => status === AbstractControlStatus.VALID),
      )
      .subscribe(() => {
        this.dialogRef.close({ cashJournalEntry: Number(this.cashJournalControl.value).toString() });
      });
  }

  initCashJournalControl() {
    if (this.data.hideCashJournal) {
      return;
    }
    this.cashJournalControl = this.fb.control(
      this.data.cashJournalEntry || 0,
      [Validators.required, Validators.maxLength(4)],
      this.validateCashJournal()
    );
  }

}
