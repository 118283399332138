import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
  UploadCalculationDialogComponent,
} from '@ptg-member/features/calculation/components/upload-calculation-dialog/upload-calculation-dialog.component';
import { BaseListComponent } from '@ptg-shared/components/base-list.component';
import { DEFAULT_PAGE_SIZE, SORT_TYPE, STATE } from '@ptg-shared/constance';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ACTION_COLUMN, Column, ColumnType, Row } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { Breadcrumb, FunctionButtonConfig, FunctionButtonData } from '@ptg-shared/types/models/breadcrumb.model';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { showBanner } from '@ptg-shared/utils/common.util';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';
import { filter, takeUntil } from 'rxjs/operators';
import {
  CalculationState,
  clearCalculationState,
  clearRetirementBenefitCalculationState,
  deleteCalculationRequest,
  getCalculationListRequest,
  selectRemoveCalculationFileState,
  selectRetirementBenefitCalculationState,
  selectUploadCalculationFileState
} from '../../store';
import { Calculations, CalculationsFileDocument } from '../../services/models';
import { CalculationType } from '../../types/enums';
import { DocumentsState } from 'src/app/admin/features/file/store/reducers';
import { clearGetDocumentDownloadStateAction, getDocumentDownloadAction } from 'src/app/admin/features/file/store/actions';

@Component({
  selector: 'ptg-retirement-benefit-calculation-list',
  templateUrl: './retirement-benefit-calculation-list.component.html',
  styleUrls: ['./retirement-benefit-calculation-list.component.scss'],
})
export class RetirementBenefitCalculationListComponent extends BaseListComponent {
  PAGE_KEY = 'retirement-benefit-calculation-list';
  listBreadcrumbs: Breadcrumb[] = this.getBreadcrumbs();
  columns: Column[] = [
    {
      name: 'name',
      header: {
        title: 'Calculation Name',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'benefitEntityName',
      header: {
        title: 'Benefit Option',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'postedDate',
      header: {
        title: 'Posted Date',
      },
      type: ColumnType.DateTime,
      truncate: true,
      sortable: true,
    },
    {
      name: 'description',
      header: {
        title: 'Description',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'fileDocument',
      header: {
        title: 'File Name',
      },
      truncate: true,
      sortable: false,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      width: '170px',
    },
  ];
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  calculations: (Calculations & Row)[] = [];
  isLoading?: boolean = false;
  errorMsg?: string;
  sortInfo?: Sort;
  totalRecords: number = 0;
  pageSize: number = DEFAULT_PAGE_SIZE;
  pageNumber: number = FIRST_PAGE;
  functionButtons: FunctionButtonConfig[] = [
    {
      buttonName: 'New Calculation File',
      icon: 'upload_file',
      classInput: 'material-icons-round add-button',
      isDisabled: false,
    },
  ];
  memberId: string = '';


  constructor(
    public layoutService: LayoutService,
    private calculationStore: Store<CalculationState>,
    public route: ActivatedRoute,
    public router: Router,
    private dialog: MatDialog,
    private documentStore: Store<DocumentsState>,
  ) {
    super(layoutService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.checkRoute();
    this.checkRetirementBenefitCalculationsState();
    this.getData();
    this.checkUploadCalculationState();
    this.getCalculationDataState();
  }

  checkRoute() {
    this.route.params
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        this.memberId = params.memberId;
        this.listBreadcrumbs = this.getBreadcrumbs();
      });
  }

  checkRetirementBenefitCalculationsState() {
    this.calculationStore
      .pipe(
        select(selectRetirementBenefitCalculationState),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((retirementBenefitCalculationsState) => {
        this.isLoading = retirementBenefitCalculationsState?.isLoading;
        this.calculations = retirementBenefitCalculationsState?.retirementBenefitCalculations?.calculations ?? [];
        this.totalRecords = retirementBenefitCalculationsState?.retirementBenefitCalculations.total ?? 0;
      });
  }

  onClickEditCalculation(row: any) {
    this.router.navigateByUrl(`/member/calculation/retirement-benefit-calculation/${ row.id }/${ this.memberId }`);
  }

  onClickRemoveCalculation(row: any) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        title: 'THIS IS A DESTRUCTIVE ACTION',
        type: ConfirmType.Destruct,
        text: 'Removing this calculation may affect the estimations using it. Are you sure you want to proceed?'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.calculationStore.dispatch(deleteCalculationRequest({ calculationId: row.id }));
      }
    });
  }

  onChangeSort(event: Sort) {
    this.sortInfo = event;
    this.getData();
  }

  onChangePage(event: PageEvent) {
    super.onChangePage(event);
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    this.getData();
  }

  emitFunction(event: FunctionButtonData) {
    if (event.buttonName === 'New Calculation File') {
      this.dialog.open(UploadCalculationDialogComponent, {
        panelClass: 'dialog-full-screen',
        disableClose: true,
        autoFocus: false,
        data: {},
      });
    }
  }

  getData() {
    let sortType = SORT_TYPE.DESC;
    let sortNames = 'PostedDate';
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = capitalizeFirstLetter(this.sortInfo.active);
      sortType = this.sortInfo.direction === 'desc' ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }
    const query = {
      pageIndex: this.pageNumber,
      pageSize: this.pageSize,
      sortNames: sortNames,
      sortType: sortType,
    };
    this.calculationStore.dispatch(getCalculationListRequest({ query }));
  }

  downloadDocumentFile(fileDocument: CalculationsFileDocument) {
    if (!fileDocument) {
      return;
    }
    const anchor = document.createElement('a');
    anchor.download = fileDocument.documentName;
    anchor.href = fileDocument.fileSnapshot.uri;
    anchor.click();
  }

  getBreadcrumbs() {
    return [
      {
        name: 'Retirement Benefit Overview',
        url: `/member/benefit-overview/${CalculationType.RetirementBenefit}/${ this.memberId }`,
      },
      {
        name: 'Calculation List',
        url: '',
      },
    ];
  }

  checkUploadCalculationState() {
    this.calculationStore
      .pipe(
        select(selectUploadCalculationFileState),
        filter(uploadCalculationFileState => uploadCalculationFileState.state === STATE.SUCCESS),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((uploadCalculationFileState) => {
        if (!uploadCalculationFileState.state) {
          return;
        }
        showBanner.call(this, uploadCalculationFileState.state, '', '', { customMessage: 'Calculation file successfully uploaded.' });
        this.calculationStore.dispatch(clearRetirementBenefitCalculationState());
        this.getData();
      });
  }

  getCalculationDataState() {
    this.calculationStore.pipe(
      select(selectRemoveCalculationFileState),
      takeUntil(this.unsubscribe$)
    ).subscribe(state => {
      if (state) {
        showBanner.call(this, state.state || '', 'Calculation File', state.action || '');
        if (state.state === STATE.SUCCESS) {
          this.getData();
        }
        this.calculationStore.dispatch(clearCalculationState());
      }
    });
  }
}
