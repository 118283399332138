import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '@ptg-shared/components';
import { Column, ColumnType } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';

import * as fromReducer from '@ptg-reducers';
import * as fromMember from '@ptg-member/store/reducers';
import { History } from '@ptg-member/types/models';
import * as MunicipalityServiceHistoryAction from '@ptg-member/store/actions/municipality-service-history.action';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { EntityProfileFixType, EntityViewFixType } from '@ptg-entity-management/types/enums';

const PAGE_SIZE_CONST = '-ptg-entity-service-history-pageSize';

@Component({
  selector: 'ptg-entity-service-history',
  templateUrl: './entity-service-history.component.html',
  styleUrls: ['./entity-service-history.component.scss'],
})
export class EntityServiceHistoryComponent extends BaseComponent {
  @Input() memberId!: string;

  readonly EntityProfileFixType = EntityProfileFixType;
  columns: Column[] = [
    {
      name: 'municipalityName',
      header: {
        title: 'Municipality',
      },
      truncate: true,
    },
    {
      name: 'beginDate',
      header: {
        title: 'Begin Date',
      },
      type: ColumnType.DateTime,
      templateArgs: { format: 'MM/dd/yyyy' },
    },
    {
      name: 'endDate',
      header: {
        title: 'End Date',
      },
      type: ColumnType.DateTime,
      templateArgs: { format: 'MM/dd/yyyy' },
    },
  ];

  serviceHistoryData: History[] = [];
  lengthPg: number | any;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  currentRowIndex: number = 0;
  memberStatusHistoryId?: string;
  currentFund: any = {};
  isLoading = false;

  constructor(
    public route: ActivatedRoute,
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
    public dialog: MatDialog,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getCurrentFund();
    this.getData();
    this.getServicesHistorySelector();
  }

  getCurrentFund() {
    this.store.pipe(select(fromReducer.selectCurrentFundState), takeUntil(this.unsubscribe$)).subscribe((el) => {
      this.pageSize = el.defaultPageSize ?? 50;
      this.currentFund = el;
      this.pageSize =
        Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)) === 0
          ? this.pageSize
          : Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST));
    });
  }

  getServicesHistorySelector() {
    this.memberStore
      .pipe(select(fromMember.selectMunicipalityServiceRecordState), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state) {
          this.lengthPg = state?.serviceRecordsEntity?.total;
          this.isLoading = state?.isLoading;
          this.serviceHistoryData = state?.serviceRecordsEntity.table;
        }
      });
  }

  getData() {
    const query = {
      pageIndex: this.pageNumber,
      pageSize: this.pageSize,
    };
    this.memberStore.dispatch(
      MunicipalityServiceHistoryAction.getMunicipalityServiceHistoryEntityList({
        query: query,
        memberId: this.memberId,
      }),
    );
  }

  changePage(event: PageEvent) {
    this.currentRowIndex = 0;
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;

    this.getData();
  }

  selectRow(event: any) {
    this.memberStore
      .pipe(select(fromLayoutReducer.selectProfileNavigationState), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        state?.memberNavigationList?.memberNavigationMenu?.forEach((menu: any) => {
          let data = menu?.menuItems?.find((item: any) => item?.viewFixType === EntityViewFixType.ServiceHistory);
          if (data) {
            this.router.navigate([
              `member/system-view/service-history/${data.id}/${data.entityViewId}/${event.memberId}`,
            ]);
          }
          return;
        });
      });
  }
  onChangeMemberDetail() {
    this.currentRowIndex = 0;
  }
}
