<div class="panel-container">
  <div class="panel-header">
    <div class="panel-title truncate">{{ title }}</div>

    <div *ngIf="!hideSeparator" class="vertical-separator"></div>
    
    <div *ngIf="panelExtraHeader" class="panel-extra-header">
      <ng-container
        [ngTemplateOutlet]="panelExtraHeader.templateRef"
      ></ng-container>
    </div>

    <div class="flex-1"></div>
  </div>

  <div
    class="panel-body"
    [ngStyle]="{
      padding: noPaddingBody ? '0' : null
    }"
  >
    <ng-container
      *ngIf="panelBody"
      [ngTemplateOutlet]="panelBody.templateRef"
    ></ng-container>
  </div>
</div>
