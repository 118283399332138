import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';
import { STATE } from '@ptg-shared/constance/value.const';

import * as RelatedPersonAction from '../actions/related-person.action';
import { AuditTrail, MemberStatus, RelatedPersonList } from '../../types/models';

export const RelatedPersonFeatureKey = 'relatedPerson';
export interface State {
  isLoading: boolean;
  relatedPersonList: RelatedPersonList;
  createState?: string;
  updateState?: string;
  listMemberStatus: MemberStatus[];
  getAuditTrails?: BaseActionState<AuditTrail[]>;
}

const initialState: State = {
  isLoading: true,
  relatedPersonList: {
    total: 0,
    relatedPersons: [],
  },
  createState: '',
  updateState: '',
  listMemberStatus: [],
};

export const reducer = createReducer(
  initialState,
  on(RelatedPersonAction.getRelatedPersonList, (state, {}) => ({
    ...state,
    isLoading: true,
    relatedPersonList: {
      total: 0,
      relatedPersons: [],
    },
  })),
  on(
    RelatedPersonAction.getRelatedPersonListSuccess,
    (state, { relatedPersonList }) => ({
      ...state,
      isLoading: false,
      relatedPersonList,
    })
  ),
  on(RelatedPersonAction.getRelatedPersonListFailure, (state) => ({
    ...state,
    isLoading: false,
    relatedPersonList: {
      total: 0,
      relatedPersons: [],
    },
  })),
  on(RelatedPersonAction.clearStatusHistoryState, (state) => ({
    ...state,
    createState: '',
    updateState: '',
  })),
  on(RelatedPersonAction.createMemberStatusHistorySuccess, (state) => ({
    ...state,
    createState: STATE.SUCCESS,
  })),
  on(RelatedPersonAction.createMemberStatusHistoryFailure, (state) => ({
    ...state,
    createState: STATE.FAIL,
  })),
  on(RelatedPersonAction.editMemberStatusHistorySuccess, (state) => ({
    ...state,
    updateState: STATE.SUCCESS,
  })),
  on(RelatedPersonAction.editMemberStatusHistoryFailure, (state) => ({
    ...state,
    updateState: STATE.FAIL,
  })),

  on(RelatedPersonAction.getAuditTrails, (state) => ({
    ...state,
    getAuditTrails: {
      isLoading: true,
    },
  })),
  on(RelatedPersonAction.getAuditTrailsSuccess, (state, { response }) => ({
    ...state,
    getAuditTrails: {
      isLoading: false,
      success: true,
      payload: response.auditTrails || [],
      total: response.total || 0,
    },
  })),
  on(RelatedPersonAction.getAuditTrailsFailure, (state, { error }) => ({
    ...state,
    getAuditTrails: {
      isLoading: false,
      success: false,
      error: error,
    },
  }))
);
