<div class="dialog-container">
  <div class="header-title">
    Filter List
  </div>
  <form [formGroup]="editForm" class="edit-form">
    <div *ngFor="let memberFilter of $any(editForm.get('memberFilters'))?.controls; let index = index" class="filter-item-container" [ngClass]="{'has-error': memberFilter.invalid}">
      <div class="filter-name-container">
        <ng-container *ngIf="!memberFilter.value.editMode; else editMode">
          <span class="title">{{ memberFilter.value.name }}</span>
          <div class="title-container">
          </div>
          <ptg-button classInput="default-button"
                      (clickButton)="editFilterName(index)">
            <mat-icon>edit</mat-icon>
          </ptg-button>
          <ptg-button (clickButton)="removeFilter(memberFilter.value.id)"
                      classInput="default-button">
            <mat-icon>delete_forever</mat-icon>
          </ptg-button>
        </ng-container>
      </div>

      <ng-template #editMode>
        <ptg-textbox [controlField]="memberFilter?.controls?.['name']"
                     [hasLabel]="true"
                     [isRequired]="true"
                     [maxLength]="100"
                     errorAsync="Filter Name already exists."
                     placeholder="Filter Name"
        ></ptg-textbox>
        <ptg-button classInput="save-name default-button"
                    (clickButton)="saveName(memberFilter)">
          <mat-icon>check</mat-icon>
        </ptg-button>
        <ptg-button classInput="cancel-edit-name default-button "
                    (clickButton)="closeEditMode(index)">
          <mat-icon>close</mat-icon>
        </ptg-button>
      </ng-template>

      <ptg-button buttonName="Apply"
                  classInput="apply-button"
                  [isDisabled]="isDisabledApply"
                  (clickButton)="applyFilter(memberFilter?.value)"></ptg-button>
    </div>
  </form>

  <div class="footer-button">
    <button (click)="onCancel()" class="cancel-button" mat-raised-button type="button">Cancel</button>
  </div>
</div>
