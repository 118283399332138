<div
  id="profile-navigation-configuration-detail"
  class="profile-navigation-configuration-detail-container"
>
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>

  <div class="flex flex-col pt-6 pb-6 pl-64 pr-64 gap-6">
    <ptg-banner
      *ngIf="bannerType"
      [(bannerType)]="bannerType"
      [message]="message"
    ></ptg-banner>

    <div *ngIf="memberNavigation" class="box">
      <div class="title">
        <span class="title-name">Menu Configuration</span>
        <div class="separator"></div>
        <div class="button-group">
          <ptg-button
            buttonName="Edit"
            classInput="edit-button"
            (clickButton)="onEditMenu()"
          >
            <mat-icon>edit</mat-icon>
          </ptg-button>
        </div>
      </div>
      <div class="detail-container">
        <div class="detail detail-name">
          <span class="label">Menu Name</span>
          <span class="value">
            <ng-container>{{ memberNavigation?.name }}</ng-container>
          </span>
        </div>
        <div class="detail detail-entity">
            <span class="label">Entity</span>
            <span class="value">
              <ng-container>{{ memberNavigation?.entityName }}</ng-container>
            </span>
          </div>
        <div class="detail detail-color">
          <span class="label">Color</span>
          <span class="value">
            <div
              class="color-div"
              [style.background]="memberNavigation?.color"
            ></div>
          </span>
        </div>
        <div class="detail detail-disabled">
          <span class="label" id="label-table-disabled">Single View</span>
          <span class="value" id="value-table-disabled">
            {{ memberNavigation?.isSingleView ? "Yes" : "No" }}
          </span>
        </div>
      </div>
    </div>

    <div class="box">
      <div class="title">
        <span class="title-name">Menu Item</span>
        <ng-container>
          <div class="separator"></div>
          <div class="button-group" [class.disabled]="memberNavigation?.isSingleView && memberNavigation?.menuItems?.length">
            <ptg-button
              buttonName="New Menu Item"
              classInput="edit-button"
              (clickButton)="editMenuItem(undefined)"
            >
              <mat-icon>add</mat-icon>
            </ptg-button>
          </div>
        </ng-container>

      </div>
      <ptg-grid
        [data]="dataTable"
        [columns]="columns"
        notFoundMessage="No Navigation Configuration to Display"
        [isLoading]="isLoading"
        [errorMessage]="errorMsg"
        [fitToParent]="true"
        [paginable]="false"
        [class.remove-drag-drop]="memberNavigation?.menuItems?.length && memberNavigation?.isSingleView"
        [allowDragDrop]="true"
        (rowDrop)="changeItemIndex($event)"
      >
        <ng-template cellContent columnName="action" let-row>
          <ptg-button
            buttonName="Edit"
            classInput="edit-button"
            (clickButton)="editMenuItem(row)"
          >
            <mat-icon>edit</mat-icon>
          </ptg-button>
          <ptg-button
            buttonName="Remove"
            classInput="remove-button"
            (clickButton)="removeRecord(row)"
          >
            <mat-icon>delete_forever</mat-icon>
          </ptg-button>
        </ng-template>
      </ptg-grid>
    </div>
  </div>
</div>
