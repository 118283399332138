import { createSelector } from '@ngrx/store';

import { selectCalculationModuleState } from './selector';

export const stepConfigurationSelector = createSelector(
  selectCalculationModuleState,
  (state) => state?.stepConfiguration
);

export const getStepConfigurationsSelector = createSelector(
  stepConfigurationSelector,
  (state) => state?.getStepConfigurations
);

export const getBenefitTypesSelector = createSelector(
  stepConfigurationSelector,
  (state) => state?.getBenefitTypes
);

export const stepConfigurationsActionStateSelector = createSelector(
  stepConfigurationSelector,
  (state) => state?.actionState
);

export const setStepConfigurationSelector = createSelector(
  stepConfigurationSelector,
  (state) => state?.setStepConfiguration
);

export const getStepConfigurationSelector = createSelector(
  stepConfigurationSelector,
  (state) => state?.getStepConfiguration
);

export const getStepConfigurationPropertiesSelector = createSelector(
  stepConfigurationSelector,
  (state) => state?.getStepConfigurationProperties
);

export const removeStepConfigurationSelector = createSelector(
  stepConfigurationSelector,
  (state) => state?.removeStepConfiguration
);

export const checkAllowRemoveStepConfigurationSelector = createSelector(
  stepConfigurationSelector,
  (state) => state?.allowRemoveStepConfiguration
);

export const getMemberEntitiesSelector = createSelector(
  stepConfigurationSelector,
  (state) => state?.getMemberEntities
);

export const getCardsSelector = createSelector(
  stepConfigurationSelector,
  (state) => state?.getCards
);

export const getCheckStepConfigurationExistsSelector = createSelector(
  stepConfigurationSelector,
  (state) => state?.checkStepConfigurationExists,
);
