<div
  class="retirement-step-configuration h-full"
  id="retirement-step-configuration-list-page"
>
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
    [functionButtons]="functionButtons"
    (emitFunction)="emitFunction($event)"
  ></ptg-breadcrumb>

  <div class="flex flex-col p6 content-container">
    <div *ngIf="bannerType" class="p-6">
      <ptg-banner [(bannerType)]="bannerType" [message]="message"></ptg-banner>
    </div>
    <div class="grid-wrapper h-full">
      <ptg-grid
        [data]="stepConfigurations"
        [columns]="columns"
        [isLoading]="isLoading"
        [fitToParent]="true"
        [paginable]="false"
        (sortChange)="onChangeSort($event)"
        notFoundMessage="No Data to Display"
      >
        <ng-template cellContent columnName="action" let-row>
          <ptg-button
            buttonName="Edit"
            classInput="edit-button"
            (clickButton)="onClickEditStepConfiguration(row)"
          >
            <mat-icon>edit</mat-icon>
          </ptg-button>
          <ptg-button
            buttonName="Remove"
            classInput="remove-button"
            (clickButton)="onClickRemoveStepConfiguration(row)"
          >
            <mat-icon>delete_forever</mat-icon>
          </ptg-button>
        </ng-template>
      </ptg-grid>
    </div>
  </div>
</div>
