import { Component, ContentChildren, Input, QueryList, TemplateRef } from '@angular/core';

import { BaseComponent } from '@ptg-shared/components';
import { DataType } from '@ptg-shared/constance/data-type.const';
import { DetailDisplay } from '@ptg-shared/types/models/detail-display.model';
import { DetailValueContentDirective } from '../detail-display/directives/detail-value-content.directive';

@Component({
  selector: 'ptg-panel-detail-display-box',
  templateUrl: './panel-detail-display-box.component.html',
  styleUrls: ['./panel-detail-display-box.component.scss'],
})
export class PanelDetailDisplayBoxComponent extends BaseComponent {
  readonly DATA_TYPE = DataType;
  @Input() selectedItem!: any;
  @Input() propertyDisplayed!: DetailDisplay[];
  
  @ContentChildren(DetailValueContentDirective) detailValueContents?: QueryList<DetailValueContentDirective>;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getContentTemplate(templateName: string): TemplateRef<any> | undefined {
    let cellContentTemplate: TemplateRef<any> | undefined;
    if (this.detailValueContents && this.detailValueContents.length > 0) {
      const cellContent = this.detailValueContents.find(
        (cell) => cell.templateName === templateName
      );
      if (cellContent) {
        cellContentTemplate = cellContent.templateRef;
      }
    }

    return cellContentTemplate;
  }
}
