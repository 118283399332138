import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as ViewActions from '../actions/view.actions';
import { ViewService } from '@ptg-member/services/view.service';
import { GetEntitiesResponse } from 'src/app/entity-management/services/models';
import { GetListCardResponse, GetViewListResponse, RemoveAccessResponse, ViewDetail, ViewDataless } from '@ptg-member/types/models';
import { EntityService } from '@ptg-entity-management/services';

@Injectable()
export class ViewEffect {
  constructor(
    private actions$: Actions,
    private viewService: ViewService,
    private entityService: EntityService
  ) {}

  getPageData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewActions.getViewList),
      switchMap(({ query }) => {
        return this.viewService.getViewList(query).pipe(
          map((viewList: GetViewListResponse) => {
            return ViewActions.getViewListSuccess({ viewList });
          }),
          catchError((error) => {
            return of(ViewActions.getViewListFailure({ error }));
          })
        );
      })
    )
  );

  getEntities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewActions.getEntitiesAction),
      switchMap(({ query }) =>
        this.entityService.getEntities(query).pipe(
          map((response: GetEntitiesResponse) =>
            ViewActions.getEntitiesSuccessAction({ response })
          ),
          catchError((error) => of(ViewActions.getEntitiesFailureAction({ error })))
        )
      )
    )
  );

  getViewDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewActions.getViewDetail),
      switchMap(({ id }) => {
        return this.viewService.getViewDetail(id).pipe(
          map((viewDetail: ViewDetail) => {
            return ViewActions.getViewDetailSuccess({ viewDetail });
          }),
          catchError((error) => {
            return of(ViewActions.getViewDetailFailure({ error }));
          })
        );
      })
    )
  );

  getRemoveAccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewActions.getRemoveAccesssAction),
      switchMap(({ id }) => {
        return this.viewService.getRemoveAccess(id).pipe(
          map((response: RemoveAccessResponse) => {
            return ViewActions.getRemoveAccessSuccessAction({ response });
          }),
          catchError((error) => {
            return of(ViewActions.getRemoveAccessFailureAction({ error }));
          })
        );
      })
    )
  );

  getCardData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewActions.getCardList),
      switchMap(({ query }) => {
        return this.viewService.getCardList(query).pipe(
          map((cardListRes: GetListCardResponse) => {
            return ViewActions.getCardListSuccess({ cardList: cardListRes });
          }),
          catchError((error) => {
            return of(ViewActions.getCardListFailure({ error }));
          })
        );
      })
    )
  );

  createView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewActions.createView),
      switchMap(({ body }) => {
        return this.viewService.createView(body).pipe(
          map((id: string) => {
            return ViewActions.createViewSuccess({ id });
          }),
          catchError((err) => {
            return of(
              ViewActions.createViewFailure({ errorMsg: err.message })
            );
          })
        );
      })
    )
  );

  updateView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewActions.updateView),
      switchMap(({ id, body }) => {
        return this.viewService.updateView(id, body).pipe(
          map(() => {
            return ViewActions.updateViewSuccess();
          }),
          catchError((err) => {
            return of(
              ViewActions.updateViewFailure({ errorMsg: err.message })
            );
          })
        );
      })
    )
  );

  removeView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewActions.removeView),
      switchMap(({ id }) => {
        return this.viewService.removeView(id).pipe(
          map(() => {
            return ViewActions.removeViewSuccess();
          }),
          catchError((err) => {
            return of(
              ViewActions.removeViewFailure({ errorMsg: err.message })
            );
          })
        );
      })
    )
  );

  getViewDataless$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewActions.getViewDataless),
      switchMap(({ id }) => {
        return this.viewService.getViewDataless(id).pipe(
          map((viewDataless: ViewDataless) => {
            return ViewActions.getViewDatalessSuccess({ viewDataless });
          }),
          catchError((error) => {
            return of(ViewActions.getViewDatalessFailure({ error }));
          })
        );
      })
    )
  );

  updateViewDataless$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ViewActions.updateViewDataless),
      switchMap(({ id, body }) => {
        return this.viewService.updateViewDataless(id, body).pipe(
          map(() => {
            return ViewActions.updateViewDatalessSuccess();
          }),
          catchError((err) => {
            return of(
              ViewActions.updateViewDatalessFailure({ errorMsg: err.message })
            );
          })
        );
      })
    )
  );
}
