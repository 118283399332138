import { Option } from "@ptg-shared/controls/select/select.component";

export const MODULE_KEY = 'buyBackMakeUpModule';

export enum BuyBackMakeUpType {
  BuyBack,
  MakeUp,
}

export const BuyBackMakeUpTypeMap = new Map<number, string>([
  [BuyBackMakeUpType.BuyBack, 'Buyback'],
  [BuyBackMakeUpType.MakeUp, 'Make-up'],
]);

export const BuyBackMakeUpTypeListOption: Option[] = [
  { value: BuyBackMakeUpType.BuyBack, displayValue: 'Buyback' },
  { value: BuyBackMakeUpType.MakeUp, displayValue: 'Make-up' },
];

export enum BuyBackMakeUpTableType {
  List,
  DetailList,
}

export enum BuyBackMakeUpStatus {
  Initiated,
  RemittanceInitiated,
}

export const BuyBackMakeUpStatusMap = new Map<BuyBackMakeUpStatus, string>([
  [BuyBackMakeUpStatus.Initiated, 'Initiated'],
  [BuyBackMakeUpStatus.RemittanceInitiated, 'Remittance Initiated'],
]);

export enum YES_NO_VALUES {
  Yes,
  No,
}
