<div class="dialog-container">
    <div class="header-title">
        Save Filter
    </div>
    <form class="edit-form" [formGroup]="saveForm">
      <div class="input-container">
        <ptg-textbox
          [controlField]="saveForm.get('name')"
          placeholder="Filter Name"
          [hasLabel]="true"
          [isRequired]="true"
          [maxLength]="100"
          errorAsync="Filter Name already exists."
        ></ptg-textbox>
      </div>
      <div class="row-button">
        <button mat-raised-button type="button" class="primary" (click)="formSubmit$.next(true)">Save</button>
        <button mat-raised-button type="button" class="cancel-button" (click)="onCancel()">Cancel</button>
      </div>
    </form>
</div>