import * as fromMember from '../../store/reducers';
import { Store, select } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, ViewChild } from '@angular/core';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import * as fromReducer from '@ptg-reducers';
import { DISCARD_CONFIRM_MESSAGE, ACTION, GUID_EMPTY } from '@ptg-shared/constance';
import { BaseComponent } from '@ptg-shared/components';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { deepClone, showBanner } from '@ptg-shared/utils/common.util';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { SUBMODULE_KEY } from '@ptg-shared/constance/permission.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import {
  ACTION_COLUMN,
  Align,
  Column,
  GridComponent,
  Row,
  getValidatorsFromColumns,
  OutsideReorderInfo
} from '@ptg-shared/controls/grid';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import {
  getCardDetail,
  getEntityComponent,
  getEntityProperty,
  updateCard,
  clearCardState,
  getEntityPropertyVersion,
} from '@ptg-member/store/actions/cart.actions';
import {
  getCardDetailSelector,
  getEntityComponentSelector,
  getEntityPropertySelector,
  updateCardSelector,
  getEntityPropertyVersionSelector,
} from '@ptg-member/store/selectors';
import {
  CardDetail,
  CardEntityProfile,
  GetEntityPropertyResponse,
  CardDetailComponent,
  EntityProperty,
  EntityPropertyOption,
  EntityPropertyItem,
} from '@ptg-member/types/models/card.model';
import { CartService } from '@ptg-member/services/cart.service';
import { EntityPropertyType } from '@ptg-entity-management/types/enums';
import { FundType } from '@ptg-shared/types/enums';
import { getClientSettingAction } from '@ptg-member/store/actions/member-detail.actions';
import { ClientSettingKey } from '@ptg-shared/constance/client-setting.const';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { getClientSettingSelector } from '../../store/reducers';
import { FixedPropertyKey } from '@ptg-entity-management/constants';
import { FixedProperties } from '@ptg-member/types/models';
import { RequireDateOfDeathErrorMessage, RequireNewHireExamErrorMessage } from '@ptg-member/constants';
import { AgePropertyName } from '@ptg-member/constance/member-list.const';

@Component({
  selector: 'ptg-card-detail',
  templateUrl: './card-detail.component.html',
  styleUrls: ['./card-detail.component.scss'],
})
export class EditCardComponent extends BaseComponent {
  readonly ACTION_COLUMN = ACTION_COLUMN;
  readonly EntityPropertyType = EntityPropertyType;
  unsubscribe$ = new Subject<void>();
  // Data need tobe reset
  editForm?: FormGroup;
  addPropertyForm: FormGroup = this.fb.group({
    type: new FormControl('', [Validators.required]),
    propertyName: new FormControl('', [Validators.required]),
    propertyItem: new FormControl([], [Validators.required]),
    label: new FormControl('', [Validators.required]),
    toColumn: new FormControl(1, [Validators.required]),
    includeInSummaryView: new FormControl(true),
    columnName: new FormControl('', [Validators.required]),
    listLabel: new FormControl('', [Validators.required]),
    entityReferencePropertyId: new FormControl(),
  });
  message: string = '';
  detailViewColumn: Record<number, Row[]> = {
    1: [],
    2: [],
    3: [],
    4: [],
  };
  summaryView: Row[] = [];
  orderColumns: Row[] = [];
  summaryOrderColumns: Row[] = [];
  sortRow: (Row)[] = [];
  cardDetailData: CardDetail | undefined;
  entityPropertiesData!: EntityPropertyItem[];
  entityVersionData!: {
    value: string,
    displayValue: string,
    options: Record<string, string>,
    valueDescription: string,
    entityReferenceLinkedId?: string,
    entityReferencePropertyId?: string,
    entityReferencePropertyName?: string,
    type: number,
    fixedKey?: string,
  }[];
  entityPropertiesDataOrigin!: EntityPropertyOption[];
  entityVersionDataOrigin!: EntityPropertyOption[];
  entityComponentDataOrigin!: GetEntityPropertyResponse;
  entityListDetailData!: EntityPropertyOption[];
  entityListSummaryData!: EntityPropertyOption[];
  entityPropertyOrigin!: GetEntityPropertyResponse;
  entityPropertyVersionOrigin!: GetEntityPropertyResponse;
  entityPropertyData!: {
    value: string,
    displayValue: string,
  }[];
  entityComponentItemData!: {
    value: string,
    label: string,
  }[];
  // Data need tobe reset
  viewId!: string;
  isLoading = false;
  bannerType: BannerType = BannerType.Hidden;
  orderColumnsVersion: Row[] = [];
  sortRowVersion: (Row)[] = [];
  includeVersionHistory = false;

  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Participant List',
      moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
      url: '/member',
    },
    {
      name: 'Card List',
      url: '/member/card-list',
    },
    {
      name: 'Card Name',
      url: ' ',
    },
  ];
  configuredListColumns: Column[] = [
    {
      name: 'name',
      truncate: true,
      editable: true,
      controlArgs: {
        maxLength: 250
      },
      validators: {
        required: {
          type: () => Validators.required,
          message: () => `Column Name is required.`,
        },
        maxlength: {
          type: () => Validators.maxLength(250),
          message: (error: any) =>
            `Exceed the ${error.requiredLength} character limit.`,
        },
        existed: {
          type: (obj: any) => this.checkExistsDetail(obj),
          message: () => `Column Name already exists.`,
        },
      },
    },
    {
      name: ACTION_COLUMN,
      align: Align.Right,
      width: '50px',
    },
  ];
  configuredSummaryColumns: Column[] = [
    {
      name: 'name',
      truncate: true,
      editable: true,
      controlArgs: {
        maxLength: 250
      },
      validators: {
        required: {
          type: () => Validators.required,
          message: () => `Column Name is required.`,
        },
        maxlength: {
          type: () => Validators.maxLength(250),
          message: (error: any) =>
            `Exceed the ${error.requiredLength} character limit.`,
        },
        existed: {
          type: (obj: any) => this.checkExistsSummary(obj),
          message: () => `Column Name already exists.`,
        },
      },
    },
    {
      name: ACTION_COLUMN,
      align: Align.Right,
      width: '50px',
    },
  ];
  configuredCardColumns: Column[] = [
    {
      name: 'name',
      truncate: true,
      editable: true,
      controlArgs: {
        maxLength: 250
      },
      validators: {
        required: {
          type: () => Validators.required,
          message: () => `Label is required.`,
        },
        maxlength: {
          type: () => Validators.maxLength(250),
          message: (error: any) =>
            `Exceed the ${error.requiredLength} character limit.`,
        },
        existed: {
          type: (obj: any) => this.checkExistsProperties(obj),
          message: () => `Label already exists.`,
        },
      },
    },
    {
      name: ACTION_COLUMN,
      align: Align.Right,
      width: '50px',
    },
  ];

  configuredCardSummaryColumns: Column[] = [
    {
      name: 'name',
      truncate: true,
      editable: true,
      controlArgs: {
        maxLength: 250
      },
      validators: {
        required: {
          type: () => Validators.required,
          message: () => `Label is required.`,
        },
        maxlength: {
          type: () => Validators.maxLength(250),
          message: (error: any) =>
            `Exceed the ${error.requiredLength} character limit.`,
        },
        existed: {
          type: (obj: any) => this.checkExistsPropertiesSummary(obj),
          message: () => `Label already exists.`,
        },
      },
    },
    {
      name: ACTION_COLUMN,
      align: Align.Right,
      width: '50px',
    },
  ];

  configuredVersionColumns: Column[] = [
    {
      name: 'name',
      truncate: true,
      editable: true,
      controlArgs: {
        maxLength: 150
      },
      validators: {
        required: {
          type: () => Validators.required,
          message: () => `Column Name is required.`,
        },
        maxlength: {
          type: () => Validators.maxLength(150),
          message: (error: any) =>
            `Exceed the ${error.requiredLength} character limit.`,
        },
        existed: {
          type: (obj: any) => this.checkExistsVersion(obj),
          message: () => `Column Name already exists.`,
        },
      },
    },
    {
      name: ACTION_COLUMN,
      align: Align.Right,
      width: '50px',
    },
  ];

  // Constant for dropdown list
  includeAttachmentAtData = [
    {
      value: 0,
      displayValue: 'No Attachment'
    },
    {
      value: 1,
      displayValue: 'Card Level'
    },
    {
      value: 2,
      displayValue: 'List Record'
    }
  ];

  propertyTypes = [
    {
      value: 204,
      displayValue: 'Data'
    },
    {
      value: 203,
      displayValue: 'System'
    },
    {
      value: 54,
      displayValue: 'Entity Reference'
    },
    {
      value: 103,
      displayValue: 'Aggregation'
    },
    {
      value: 104,
      displayValue: 'Calculation'
    },
    {
      value: 205,
      displayValue: 'Identifier'
    },
    {
      value: -1,
      displayValue: 'Blank space'
    },
  ];

  propertyTypesVersion = [
    {
      value: 204,
      displayValue: 'Data'
    },
    {
      value: 203,
      displayValue: 'System'
    },
    {
      value: 103,
      displayValue: 'Aggregation'
    },
    {
      value: 104,
      displayValue: 'Calculation'
    },
    {
      value: 205,
      displayValue: 'Identifier'
    },
  ];

  toColumns = [1, 2, 3, 4];

  listTabs = ['List-Detail View', 'List-Summary View', 'Properties', 'Version History'];
  currentTab = this.listTabs[0];
  propertyDisplayData = [
    {
      value: 0,
      displayValue: 'Column',
      iconConfig: { icon: 'view_week', iconFirst: true }
    },
    {
      value: 1,
      displayValue: 'Table',
      iconConfig: { icon: 'table_rows', iconFirst: true }
    },
  ];
  listNameData: string[] = [];
  listOptionFixed = ['entityCode', 'entityName', 'blank', 'mainRetiree', 'parentBenefit', 'updatedAt', 'updatedBy', 'createdBy', 'createdAt'];
  listNonDataType = [
    EntityPropertyType.System,
    EntityPropertyType['Entity Reference'],
    EntityPropertyType.Aggregation,
    EntityPropertyType.Calculation,
    EntityPropertyType.Identifier,
  ];
  propertyItemError = '';
  listNewHireExamFixedPropertyId: string[] = [];
  listDateOfDeathFixedPropertyId: string[] = [];
  isMemberEntity?: boolean;
  currentOptionProperty: EntityPropertyOption | any;

  @ViewChild('gridOrderColumns')
  gridOrderColumns?: GridComponent<Row>;
  @ViewChild('gridSortRow')
  gridSortRow?: GridComponent<Row>;
  @ViewChild('gridSummaryOrderColumns')
  gridSummaryOrderColumns?: GridComponent<Row>;
  @ViewChild('gridViewColumn1')
  gridViewColumn1?: GridComponent<Row>;
  @ViewChild('gridViewColumn2')
  gridViewColumn2?: GridComponent<Row>;
  @ViewChild('gridViewColumn3')
  gridViewColumn3?: GridComponent<Row>;
  @ViewChild('gridViewColumn4')
  gridViewColumn4?: GridComponent<Row>;
  @ViewChild('gridSummaryView')
  gridSummaryView?: GridComponent<Row>;
  @ViewChild('gridOrderColumnsVersion')
  gridOrderColumnsVersion?: GridComponent<Row>;
  @ViewChild('gridSortRowVersion')
  gridSortRowVersion?: GridComponent<Row>;

  constructor(
    private store: Store<fromReducer.State>,
    public dialogRef: MatDialogRef<EditCardComponent>,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public router: Router,
    private memberStore: Store<fromMember.MemberState>,
    private fb: FormBuilder,
    private cartService: CartService,
    private layoutService: LayoutService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch(LayoutActions.hiddenSideMenu());

    const { prevUrl, prevName } = this.route.snapshot.queryParams;
    if (prevUrl) {
      this.listBreadcrumbs = [
        {
          name: prevName,
          url: prevUrl,
        },
        {
          name: 'Card Name',
          url: ' ',
        },
      ];
    }

    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.viewId = params.id;
    });

    this.loadCardDetail();

    combineLatest([
      this.memberStore.pipe(select(getCardDetailSelector)),
      this.memberStore.pipe(select(getClientSettingSelector))
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([cardDetailState, getFixedPropertiesState]) => {
        if (cardDetailState?.success && cardDetailState?.payload) {
          this.initFormGroup(cardDetailState.payload);
          this.cardDetailData = deepClone(cardDetailState.payload);
          this.isMemberEntity = cardDetailState.payload?.isMemberEntity;
          if (this.listBreadcrumbs[2])
            this.listBreadcrumbs[2].name = cardDetailState.payload.cardName || '';
          else
            this.listBreadcrumbs[1].name = cardDetailState.payload.cardName || '';
          this.editForm?.get('includeAttachmentAt')?.setValue(cardDetailState.payload.includeAttachmentAt);
          this.listNameData = [cardDetailState.payload.listName || '']
          this.editForm?.get('listName')?.setValue(cardDetailState.payload.listName);
          this.editForm?.get('listLabel')?.setValue(cardDetailState.payload.listLabel || '');
          let detailViewLabel = cardDetailState.payload.cardName;
          let propertyDisplay;
          if (cardDetailState.payload.components) {
            this.addComponentsToForm(cardDetailState.payload.components);
            const item = cardDetailState.payload.components.find(i => i.type === 0);
            if (item?.detailViewLabel) {
              detailViewLabel = item.detailViewLabel;
              propertyDisplay = item.propertyDisplay;
            }
          }
          this.editForm?.get('detailViewLabel')?.setValue(detailViewLabel);

          if (propertyDisplay !== undefined) {
            this.editForm?.get('propertyDisplay')?.setValue(propertyDisplay);
          }
          if (cardDetailState.payload.entityId) {
            this.loadPropertyByType(this.viewId);
            if (cardDetailState.payload.entityComponentId) {

              this.loadEntityComponent(this.viewId, cardDetailState.payload.entityComponentId);
              this.listTabs = ['List-Detail View', 'List-Summary View', 'Properties'];
              this.currentTab = 'List-Detail View';
              this.includeAttachmentAtData = [
                {
                  value: 0,
                  displayValue: 'No Attachment'
                },
                {
                  value: 1,
                  displayValue: 'Card Level'
                },
                {
                  value: 2,
                  displayValue: 'List Record'
                }
              ];
            } else {
              this.listTabs = ['Properties'];
              this.currentTab = 'Properties';
              this.includeAttachmentAtData = [
                {
                  value: 0,
                  displayValue: 'No Attachment'
                },
                {
                  value: 1,
                  displayValue: 'Card Level'
                },
              ];
            }

            if (this.includeVersionHistory) {
              this.listTabs.push('Version History');
              this.loadPropertyVersion(this.viewId);
            }
          }
        }

        if (getFixedPropertiesState?.success && getFixedPropertiesState?.payload?.value) {
          const fixedPropertiesSetting = JSON.parse(getFixedPropertiesState?.payload?.value) as FixedProperties;
          this.listDateOfDeathFixedPropertyId = fixedPropertiesSetting?.BvffMember
            ?.filter(p => p.Key === FixedPropertyKey.DateOfDeath || p.Key === FixedPropertyKey.LineOfDutyDeath)
            .map(p => p.EntityPropertyId);
          this.listNewHireExamFixedPropertyId = fixedPropertiesSetting?.BvffMember
            ?.filter(p => p.Key === FixedPropertyKey.NewHireExam || p.Key === FixedPropertyKey.NewHireExamDate)
            .map(p => p.EntityPropertyId);
        }
      });

    // update card status
    this.memberStore.pipe(select(updateCardSelector)).subscribe((data) => {
      if (data?.success) {
        this.loadCardDetail();
        showBanner.call(this, BannerType.Success, 'Card', ACTION.EDIT);
      } else if (data?.error) {
        setTimeout(() => {
          this.isLoading = false;
        }, 1);
        showBanner.call(this, BannerType.Fail, 'Card', ACTION.EDIT);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    // Reset data
    this.editForm?.reset();
    this.addPropertyForm.reset();
    this.memberStore.dispatch(clearCardState());
  }

  private loadCardDetail() {
    this.isLoading = true;
    this.memberStore.dispatch(getCardDetail({ id: this.viewId }));
    if (this.layoutService.fundType === FundType.BVFF) {
      this.memberStore.dispatch(getClientSettingAction({ clientSettingKey: ClientSettingKey.BvffFixedProperties }));
    }
  }

  private checkExistsDetail(obj?: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const currentFieldValue = control?.value?.toLowerCase().trim();
      const existed = [...this.orderColumns].find(item => (
        item?.name !== obj?.name &&
        item?.name?.toLowerCase().trim() === currentFieldValue
      ));
      return existed ? { existed: 'Column Name already exists.' } : null;
    };
  }

  private checkExistsVersion(obj?: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const currentFieldValue = control?.value?.toLowerCase().trim();
      const existed = [...this.orderColumnsVersion].find(item => (
        item?.name !== obj?.name &&
        item?.name?.toLowerCase().trim() === currentFieldValue
      ));
      return existed ? { existed: 'Column Name already exists.' } : null;
    };
  }

  private checkExistsSummary(obj?: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const currentFieldValue = control?.value?.toLowerCase().trim();
      const existed = [...this.summaryOrderColumns].find(item => (
        item?.name !== obj?.name &&
        item?.name?.toLowerCase().trim() === currentFieldValue
      ));
      return existed ? { existed: 'Column Name already exists.' } : null;
    };
  }

  private checkExistsProperties(obj?: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const currentFieldValue = control?.value?.toLowerCase().trim();
      const existed = Object.values(this.detailViewColumn).reduce((flattenedArray, element) => ([
        ...flattenedArray,
        ...element,
      ]), []).find(item => (
        item?.name !== obj?.name &&
        item?.name?.toLowerCase().trim() === currentFieldValue
      ));
      return existed ? { existed: 'Label already exists.' } : null;
    };
  }

  private checkExistsPropertiesSummary(obj?: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const currentFieldValue = control?.value?.toLowerCase().trim();
      const existed = [...this.summaryView].find(item => (
        item?.name !== obj?.name &&
        item?.name?.toLowerCase().trim() === currentFieldValue
      ));
      return existed ? { existed: 'Column Name already exists.' } : null;
    };
  }

  private generateDescriptionList(data: EntityProperty, isProperties = false) {
    if (isProperties) {
      if (data.entityReferencePropertyId)
        return data.entityReferencePropertyName || '';
      if (['mainRetiree', 'parentBenefit'].includes(data.entityPropertyId))
        return 'Entity Profile';
      return '';
    }
    if (data.type === EntityPropertyType['Entity Reference']) {
      return 'Entity Profile';
    }
    if (data.type === EntityPropertyType.Identifier) {
      return 'Identifier';
    }
    if (data.entityReferencePropertyId) {
      return `Entity Reference / ${data.entityReferencePropertyName}`;
    }
    if (data.type === EntityPropertyType.System) {
      return EntityPropertyType[data.type];
    }
    return 'Data';
  }

  private loadEntityComponent(cardId: string, entityComponentId: string) {
    this.memberStore.dispatch(getEntityComponent({ cardId, entityComponentId }))
    this.memberStore
      .pipe(
        select(getEntityComponentSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state?.success && state?.payload && state.payload.properties) {
          this.entityComponentDataOrigin = deepClone(state.payload);
          this.entityComponentDataOrigin.properties = (this.entityComponentDataOrigin.properties || [])?.map(i => {
            const newData = { ...i };
            if (i.entityPropertyId === GUID_EMPTY) {
              newData.entityPropertyId = i.fixedKey || '';
            }
            return newData;
          });
          this.onFilterData4List();
        }
      });
  }

  private loadPropertyByType(cardId: string) {
    this.memberStore.dispatch(getEntityProperty({ id: cardId }))
    this.memberStore
      .pipe(
        select(getEntityPropertySelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state?.success && state?.payload) {
          setTimeout(() => {
            this.isLoading = false;
          }, 1);
          this.entityPropertyOrigin = {
            ...state.payload,
          };
          this.entityPropertyOrigin.properties = this.entityPropertyOrigin.properties?.map(i => {
            const newData = { ...i };
            if (i.entityPropertyId === GUID_EMPTY) {
              newData.entityPropertyId = i.fixedKey || '';
            }
            return newData;
          })
          this.addPropertyForm.get('type')?.setValue(EntityPropertyType.Data);
          this.onFilterData4Properties();
          this.onFilterBySelectProperties();
        }
      });
  }

  private loadPropertyVersion(cardId: string) {
    this.memberStore.dispatch(getEntityPropertyVersion({ id: cardId, isVersionHistory: true }))
    this.memberStore
      .pipe(
        select(getEntityPropertyVersionSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state?.success && state?.payload) {
          this.entityPropertyVersionOrigin = {
            ...state.payload,
          };
          this.entityPropertyVersionOrigin.properties = this.entityPropertyVersionOrigin.properties?.map(i => {
            const newData = { ...i };
            if (i.entityPropertyId === GUID_EMPTY) {
              newData.entityPropertyId = i.fixedKey || '';
            }
            return newData;
          })
          this.onFilterData4Version();
          this.onFilterBySelectVersion();
        }
      });
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: DISCARD_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.router.navigateByUrl('/member/card-list');
      }
    });
  }

  // Initial form group when edit profile overview detail
  private initFormGroup(data?: any): void {
    this.editForm = this.fb.group({
      cardName: new FormControl(data.cardName, {
        validators: [
          Validators.required,
          Validators.maxLength(250),
        ],
        asyncValidators: checkApiValidator(
          this.cartService.checkExitsCardName,
          'cardName',
          data.cardName,
        ),
      }),
      includeAttachmentAt: new FormControl('', [Validators.required]),
      listName: new FormControl(''),
      enableDragDrop: new FormControl(data.enableDragDrop),
      propertyDisplay: new FormControl(this.propertyDisplayData[0].value, [Validators.required]),
      detailViewLabel: new FormControl('', [Validators.required]),
      listLabel: new FormControl('', (data.entityComponentId) ? [
        Validators.required,
        Validators.maxLength(250),
      ] : []),
    });
  }

  private createInlineEditFormControls(
    config: CardEntityProfile & { name?: string },
    columns: Column[],
  ): FormGroup {
    return this.fb.group({
      name: new FormControl(
        config.name,
        getValidatorsFromColumns('name', columns, config)
      ),
    });
  }

  private generateSubtext(data: CardEntityProfile & Row) {
    let propertyItem = data.propertyItemLabel ? ` / ${data.propertyItemLabel}` : '';
    if (data.entityReferencePropertyId) {
      return `Entity Reference / ${data.entityReferencePropertyName} / ${data.propertyName}${propertyItem}`;
    }
    if (data.entityPropertyId === 'blank' || data.entityPropertyId === null) return '';
    const typeName = data.type && this.listNonDataType.includes(data.type) ?
      EntityPropertyType[data.type] :
      'Data';
    let propertyName = data.propertyName ? ` / ${data.propertyName}` : '';
    return `${typeName}${propertyName}${propertyItem}`
  }

  private resetPropertyForm() {
    this.currentOptionProperty = null;
    this.propertyItemError = '';
    this.addPropertyForm.reset();
    this.entityComponentItemData = [];
    setTimeout(() => {
      this.addPropertyForm.get('type')?.setValue(EntityPropertyType.Data);
      this.addPropertyForm.get('toColumn')?.setValue(1);
      this.addPropertyForm.get('includeInSummaryView')?.setValue(true);
      this.onFilterBySelectProperties();
      this.onFilterBySelectVersion();
    }, 1)
  }

  private checkDuplicateName(grid: Row[], name: string, label = 'Label') {
    if (grid.find(i => i.name === name)) {
      this.addPropertyForm.get('label')?.setErrors({ inValidAsync: `${label} already exists.` })
      return true;
    }
    return false;
  }

  private checkSelectItem(listItem: {
    value: string,
    label: string,
  }[], name: string) {
    this.propertyItemError = 'Property Item is required.'
    return listItem && listItem.length > 0 && (!name || name.length === 0);
  }

  addProperty() {
    this.addPropertyForm.markAllAsTouched();
    const formValue = this.addPropertyForm.value;
    if (
      formValue.type !== -1 && (
        !formValue.propertyName ||
        !formValue.label ||
        !formValue.toColumn ||
        this.checkDuplicateName(Object.values(this.detailViewColumn).reduce((flattenedArray, element) => ([
          ...flattenedArray,
          ...element,
        ]), []), formValue.label) ||
        (formValue.includeInSummaryView && this.checkDuplicateName(this.summaryView, formValue.label)) ||
        this.checkSelectItem(this.entityComponentItemData, formValue.propertyItem)
      )
    ) {
      return;
    }
    let propertyName: EntityPropertyItem | undefined;
    if (formValue.entityReferencePropertyId)
      propertyName = this.entityPropertiesData.find(i => i.value === formValue.propertyName &&
        i.entityReferencePropertyId === formValue.entityReferencePropertyId
      );
    else
      propertyName = this.entityPropertiesData.find(i => i.value === formValue.propertyName);
    const propertyItems = (this.entityComponentItemData || []).filter(i => formValue.propertyItem.includes(i.value));
    this.summaryView = [...(this.gridSummaryView?.dataSource || this.summaryView)];
    this.detailViewColumn[1] = [...(this.gridViewColumn1?.dataSource || this.detailViewColumn[1])];
    this.detailViewColumn[2] = [...(this.gridViewColumn2?.dataSource || this.detailViewColumn[2])];
    this.detailViewColumn[3] = [...(this.gridViewColumn3?.dataSource || this.detailViewColumn[3])];
    this.detailViewColumn[4] = [...(this.gridViewColumn4?.dataSource || this.detailViewColumn[4])];

    if (propertyItems && propertyItems.length > 0) {
      propertyItems.forEach(i => {
        const propertyItem = (this.entityComponentItemData || []).find(j => j.value === i.value);
        const entityProfileComponentItems: CardEntityProfile & Row = {
          name: formValue.label,
          entityPropertyId: formValue.propertyName,
          propertyName: propertyName?.displayValue || '',
          type: formValue.type,
          orderColumn: 0,
          orderRow: 0,
          sortType: 0,
          option: propertyItem?.value,
          propertyItem: propertyItem?.value,
          propertyItemLabel: propertyItem?.label,
          entityReferenceLinkedId: propertyName?.entityReferenceLinkedId,
          entityReferencePropertyId: propertyName?.entityReferencePropertyId,
          entityReferencePropertyName: propertyName?.entityReferencePropertyName,
        }
        entityProfileComponentItems.subtext = this.generateSubtext(entityProfileComponentItems);
        entityProfileComponentItems.form = this.createInlineEditFormControls(entityProfileComponentItems, this.configuredCardColumns);
        // // Insert new row into the left card table
        this.detailViewColumn[formValue.toColumn] = [...this.detailViewColumn[formValue.toColumn], entityProfileComponentItems];
        if (formValue.includeInSummaryView) {
          const newItem = { ...entityProfileComponentItems };
          newItem.form = this.createInlineEditFormControls(newItem, this.configuredCardSummaryColumns);
          this.summaryView = [...this.summaryView, newItem];
        }
      })
    } else {
      const entityProfileComponentItems: CardEntityProfile & Row = {
        name: formValue.type === -1 ? 'Blank Space' : formValue.label,
        entityPropertyId: formValue.type === -1 ? 'blank' : formValue.propertyName,
        propertyName: propertyName?.displayValue || '',
        type: formValue.type,
        orderColumn: 0,
        orderRow: 0,
        sortType: 0,
        entityReferenceLinkedId: propertyName?.entityReferenceLinkedId,
        entityReferencePropertyId: propertyName?.entityReferencePropertyId,
        entityReferencePropertyName: propertyName?.entityReferencePropertyName,
        nonEditable: formValue.type === -1,
        fixedKey: propertyName?.fixedKey,
      }
      entityProfileComponentItems.subtext = this.generateSubtext(entityProfileComponentItems);
      entityProfileComponentItems.form = this.createInlineEditFormControls(entityProfileComponentItems, this.configuredCardColumns);
      // Insert new row into the left card table
      this.detailViewColumn[formValue.toColumn] = [...this.detailViewColumn[formValue.toColumn], entityProfileComponentItems];
      if (formValue.type !== -1 && formValue.includeInSummaryView) {
        const newItem = { ...entityProfileComponentItems };
        newItem.form = this.createInlineEditFormControls(newItem, this.configuredCardSummaryColumns);
        this.summaryView = [...this.summaryView, newItem];
      }
    }

    this.resetPropertyForm();
    this.onFilterData4Properties();
  };

  addVersion() {
    this.addPropertyForm.markAllAsTouched();
    const formValue = this.addPropertyForm.value;
    if (
      !formValue.propertyName ||
      !formValue.label ||
      this.checkDuplicateName(this.orderColumnsVersion, formValue.label, 'Column Name') ||
      this.checkSelectItem(this.entityComponentItemData, formValue.propertyItem)
    ) {
      return;
    }
    const propertyName = this.entityVersionData.find(i => i.value === formValue.propertyName);
    const propertyItems = (this.entityComponentItemData || []).filter(i => formValue.propertyItem.includes(i.value));
    this.orderColumnsVersion = [...this.gridOrderColumnsVersion!.dataSource];

    if (propertyItems && propertyItems.length > 0) {
      propertyItems.forEach(i => {
        const propertyItem = (this.entityComponentItemData || []).find(j => j.value === i.value);
        const entityComponentItems: CardEntityProfile & Row = {
          name: formValue.label,
          entityPropertyId: formValue.propertyName,
          propertyName: propertyName?.displayValue || '',
          type: propertyName?.type,
          sortType: 0,
          option: propertyItem?.value,
          propertyItem: propertyItem?.value,
          propertyItemLabel: propertyItem?.label,
          entityReferenceLinkedId: propertyName?.entityReferenceLinkedId,
          entityReferencePropertyId: propertyName?.entityReferencePropertyId,
          entityReferencePropertyName: propertyName?.entityReferencePropertyName,
        }
        entityComponentItems.subtext = this.generateSubtext(entityComponentItems);
        entityComponentItems.form = this.createInlineEditFormControls(entityComponentItems, this.configuredVersionColumns);
        // // Insert new row into the left card table
        this.orderColumnsVersion = [...this.orderColumnsVersion, entityComponentItems];
      })
    } else {
      const entityComponentItems: CardEntityProfile & Row = {
        name: formValue.label,
        entityPropertyId: formValue.propertyName,
        propertyName: propertyName?.displayValue || '',
        type: propertyName?.type,
        sortType: 0,
        entityReferenceLinkedId: propertyName?.entityReferenceLinkedId,
        entityReferencePropertyId: propertyName?.entityReferencePropertyId,
        entityReferencePropertyName: propertyName?.entityReferencePropertyName,
        fixedKey: propertyName?.fixedKey,
      }
      entityComponentItems.subtext = this.generateSubtext(entityComponentItems);
      entityComponentItems.form = this.createInlineEditFormControls(entityComponentItems, this.configuredVersionColumns);
      // // Insert new row into the left card table
      this.orderColumnsVersion = [...this.orderColumnsVersion, entityComponentItems];
    }

    this.resetPropertyForm();
    this.onFilterData4Version();
  };

  private generateSubtextList(data: CardEntityProfile & Row) {
    if (data.type === EntityPropertyType['Entity Reference'])
      return `Entity Profile / ${data.propertyName}`;
    if (data.type === EntityPropertyType.Identifier)
      return `Identifier / ${data.propertyName}`;
    const itemName = data.propertyItemLabel ? ` / ${data.propertyItemLabel}` : '';
    if (data.entityReferencePropertyId)
      return `Entity Reference / ${data.entityReferencePropertyName} / ${data.propertyName}${itemName}`;
    if (data.type === EntityPropertyType.System)
      return `${EntityPropertyType[data.type]} / ${data.propertyName}${itemName}`;
    return `Data / ${data.propertyName}${itemName}`;
  }

  addListDetail() {
    this.addPropertyForm.markAllAsTouched();
    const formValue = this.addPropertyForm.value;
    if (
      !formValue.propertyName ||
      !formValue.label ||
      this.checkDuplicateName(this.orderColumns, formValue.label, 'Column Name') ||
      this.checkSelectItem(this.entityComponentItemData, formValue.propertyItem)
    ) {
      return;
    }
    const propertyName = this.entityListDetailData.find(i => i.value === this.currentOptionProperty.value && i.entityReferencePropertyId === this.currentOptionProperty.entityReferencePropertyId);
    const propertyItems = (this.entityComponentItemData || []).filter(i => formValue.propertyItem.includes(i.value));
    this.orderColumns = [...this.gridOrderColumns!.dataSource];

    if (propertyItems && propertyItems.length > 0) {
      propertyItems.forEach(i => {
        const propertyItem = (this.entityComponentItemData || []).find(j => j.value === i.value);
        const entityComponentItems: CardEntityProfile & Row = {
          name: formValue.label,
          entityPropertyId: formValue.propertyName,
          propertyName: propertyName?.displayValue || '',
          type: propertyName?.type,
          sortType: 0,
          showOnTableList: true,
          option: propertyItem?.value,
          propertyItem: propertyItem?.value,
          propertyItemLabel: propertyItem?.label,
          entityReferenceLinkedId: propertyName?.entityReferenceLinkedId,
          entityReferencePropertyId: propertyName?.entityReferencePropertyId,
          entityReferencePropertyName: propertyName?.entityReferencePropertyName,
        }
        entityComponentItems.subtext = this.generateSubtextList(entityComponentItems);
        entityComponentItems.form = this.createInlineEditFormControls(entityComponentItems, this.configuredListColumns);
        // // Insert new row into the left card table
        this.orderColumns = [...this.orderColumns, entityComponentItems];
      })
    } else {
      const entityComponentItems: CardEntityProfile & Row = {
        name: formValue.label,
        entityPropertyId: formValue.propertyName,
        propertyName: propertyName?.displayValue || '',
        type: propertyName?.type,
        sortType: 0,
        showOnTableList: true,
        entityReferenceLinkedId: propertyName?.entityReferenceLinkedId,
        entityReferencePropertyId: propertyName?.entityReferencePropertyId,
        entityReferencePropertyName: propertyName?.entityReferencePropertyName,
        fixedKey: propertyName?.fixedKey,
      }
      entityComponentItems.subtext = this.generateSubtextList(entityComponentItems);
      entityComponentItems.form = this.createInlineEditFormControls(entityComponentItems, this.configuredListColumns);
      // // Insert new row into the left card table
      this.orderColumns = [...this.orderColumns, entityComponentItems];
    }
    this.resetPropertyForm();
    this.onFilterData4List();
  }

  addListSummary() {
    this.addPropertyForm.markAllAsTouched();
    const formValue = this.addPropertyForm.value;

    if (
      !formValue.propertyName ||
      !formValue.label ||
      this.checkDuplicateName(this.summaryOrderColumns, formValue.label, 'Column Name') ||
      this.checkSelectItem(this.entityComponentItemData, formValue.propertyItem)
    ) {
      return;
    }
    const propertyName = this.entityListSummaryData.find(i => i.value === this.currentOptionProperty.value && i.entityReferencePropertyId === this.currentOptionProperty.entityReferencePropertyId);
    const propertyItems = (this.entityComponentItemData || []).filter(i => formValue.propertyItem.includes(i.value));
    if (propertyItems && propertyItems.length > 0) {
      propertyItems.forEach(i => {
        const propertyItem = (this.entityComponentItemData || []).find(j => j.value === i.value);
        const entityComponentItems: CardEntityProfile & Row = {
          name: formValue.label,
          entityPropertyId: formValue.propertyName,
          propertyName: propertyName?.displayValue || '',
          type: propertyName?.type,
          option: propertyItem?.value,
          propertyItem: propertyItem?.value,
          propertyItemLabel: propertyItem?.label,
          entityReferenceLinkedId: propertyName?.entityReferenceLinkedId,
          entityReferencePropertyId: propertyName?.entityReferencePropertyId,
          entityReferencePropertyName: propertyName?.entityReferencePropertyName,
        }
        entityComponentItems.subtext = this.generateSubtextList(entityComponentItems);
        entityComponentItems.form = this.createInlineEditFormControls(entityComponentItems, this.configuredSummaryColumns);
        // // Insert new row into the left card table
        this.summaryOrderColumns = [...this.summaryOrderColumns, entityComponentItems];
      })
    } else {
      const entityComponentItems: CardEntityProfile & Row = {
        name: formValue.label,
        entityPropertyId: formValue.propertyName,
        propertyName: propertyName?.displayValue || '',
        type: propertyName?.type,
        entityReferenceLinkedId: propertyName?.entityReferenceLinkedId,
        entityReferencePropertyId: propertyName?.entityReferencePropertyId,
        entityReferencePropertyName: propertyName?.entityReferencePropertyName,
        fixedKey: propertyName?.fixedKey,
      }
      entityComponentItems.subtext = this.generateSubtextList(entityComponentItems);
      entityComponentItems.form = this.createInlineEditFormControls(entityComponentItems, this.configuredSummaryColumns);
      // // Insert new row into the left card table
      this.summaryOrderColumns = [...this.summaryOrderColumns, entityComponentItems];
    }
    this.resetPropertyForm();
    this.onFilterData4List();
  }

  onClickMove2Sort(data: Row): void {
    data.isSortColumn = true;
    this.sortRow = [...this.gridSortRow!.dataSource, {
      ...data,
      sortType: data.sortType || 0,
    }];
    const row = this.orderColumns.find(i => i.entityPropertyId === data.entityPropertyId && i.option === data.option);
    if (row) {
      row.isSortColumn = true;
    }
  }

  onClickMove2SortVersion(data: Row): void {
    data.isSortColumn = true;
    this.sortRowVersion = [...this.gridSortRowVersion!.dataSource, {
      ...data,
      sortType: data.sortType || 0,
    }];
    const row = this.orderColumnsVersion.find(i => i.entityPropertyId === data.entityPropertyId && i.option === data.option);
    if (row) {
      row.isSortColumn = true;
    }
  }

  onClickToggleShowOnTableList(row: Row, showOnTableList: boolean): void {
    const showOnTableListLength = this.gridOrderColumns!.dataSource.filter(row => row.showOnTableList);
    
    if (showOnTableListLength?.length > 1 || showOnTableList) {
      row.showOnTableList = showOnTableList;
      this.sortRow = [...this.gridSortRow!.dataSource];
    }
  }

  onClickSort(row: Row, sortType: number): void {
    row.sortType = sortType;
    this.sortRow = [...this.gridSortRow!.dataSource];
  }

  onClickSortVersion(row: Row, sortType: number): void {
    row.sortType = sortType;
    this.sortRowVersion = [...this.gridSortRowVersion!.dataSource];
  }

  onChangeTab(tab: string) {
    this.currentTab = tab;
    this.resetPropertyForm();
  }

  private filter4Data(data: EntityProperty[], dataColumns: Row[], isProperties = false) {
    const properties: Record<string, string[]> = {};
    dataColumns.forEach(i => {
      const key = i.entityPropertyId + (i.entityReferencePropertyId || '');
      if (properties[key]) {
        properties[key].push(i.propertyItem);
      } else {
        properties[key] = i.propertyItem ? [i.propertyItem] : [];
      }
    })

    return data.filter(i => {
      const key = i.entityPropertyId + (i.entityReferencePropertyId || '');
      const item = properties[key];
      if (item) {
        return item.length < Object.keys(i.options || {}).length;
      }
      return true;
    })
      .map(i => {
        const options = { ...i.options };
        const key = i.entityPropertyId + (i.entityReferencePropertyId || '');
        const fixedKey = i.fixedKey || '';
        const item = properties[key] || properties[fixedKey];
        if (item) {
          item.forEach(j => {
            delete options[j];
          })
        }
        return {
          value: i.type === EntityPropertyType.System ? (i?.fixedKey ?? i?.entityPropertyId) : i.entityPropertyId,
          displayValue: i.propertyName,
          options: options,
          type: i.type,
          valueDescription: this.generateDescriptionList(i, isProperties),
          entityReferenceLinkedId: i.entityReferenceLinkedId,
          entityReferencePropertyId: i.entityReferencePropertyId,
          entityReferencePropertyName: i.entityReferencePropertyName,
          fixedKey: i?.fixedKey
        }
      }).sort((a, b) => {
        const nameA = a.displayValue.toUpperCase(); // ignore upper and lowercase
        const nameB = b.displayValue.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
  }

  onFilterData4List() {
    this.entityListDetailData = this.filter4Data(this.entityComponentDataOrigin?.properties || [], this.orderColumns);
    this.entityListSummaryData = this.filter4Data(this.entityComponentDataOrigin?.properties || [], this.summaryOrderColumns);
  }

  onFilterData4Properties() {
    let newArray: Row[] = [];
    Object.values(this.detailViewColumn).forEach(array => {
      newArray = newArray.concat(array);
    });
    this.entityPropertiesDataOrigin = this.filter4Data(this.entityPropertyOrigin?.properties || [], newArray, true);
  }

  onFilterData4Version() {
    this.entityVersionDataOrigin = this.filter4Data(this.entityPropertyVersionOrigin?.properties || [], this.orderColumnsVersion, true);
  }

  onFilterBySelectListDetail(event: EntityPropertyOption) {
    this.currentOptionProperty = event;
    const entityPropertyId = this.currentOptionProperty.value;
    if (entityPropertyId) {
      this.propertyItemError = '';
      const item = this.entityListDetailData.find(i => i.value === entityPropertyId);
      if (item) {
        this.addPropertyForm.get('label')?.setValue(item.displayValue);
        this.entityComponentItemData = Object.keys(item.options || {}).map(i => ({
          value: i,
          label: item.options[i],
        }));
        this.addPropertyForm.get('propertyItem')?.setValue(
          this.entityComponentItemData.length > 0 ?
            this.entityComponentItemData[0].value : []
        );
      }
    }
  }

  onFilterBySelectListSummary(event: EntityPropertyOption) {
    this.currentOptionProperty = event;
    const entityPropertyId = this.currentOptionProperty.value;
    if (entityPropertyId) {
      this.propertyItemError = '';
      const item = this.entityListSummaryData.find(i => i.value === entityPropertyId);
      if (item) {
        this.addPropertyForm.get('label')?.setValue(item.displayValue);
        this.entityComponentItemData = Object.keys(item.options || {}).map(i => ({
          value: i,
          label: item.options[i],
        }));
        this.addPropertyForm.get('propertyItem')?.setValue(
          this.entityComponentItemData.length > 0 ?
            this.entityComponentItemData[0].value : []
        );
      }
    }
  }

  onSelectType() {
    this.addPropertyForm.get('propertyName')?.setValue('');
    this.addPropertyForm.get('label')?.setValue('');
    this.onFilterBySelectProperties();
  }

  onFilterBySelectProperties(row?: Row) {
    const propertyType = this.addPropertyForm.value?.type;
    if (propertyType) {
      this.propertyItemError = '';
      this.entityPropertiesData = (this.entityPropertiesDataOrigin || [])
        .filter(i => {
          if (propertyType === EntityPropertyType['Entity Reference']) {
            return i.entityReferencePropertyId
          }
          if (propertyType === EntityPropertyType.Data) {
            return !i.entityReferencePropertyId && !this.listNonDataType.includes(i.type);
          }

          return !i.entityReferencePropertyId && i.type === propertyType
        })
        .sort((a, b) => {
          const nameA = a.displayValue.toUpperCase(); // ignore upper and lowercase
          const nameB = b.displayValue.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        })
        .sort((a, b) => {
          const nameA = a.valueDescription.toUpperCase(); // ignore upper and lowercase
          const nameB = b.valueDescription.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      this.entityPropertiesData = [
        ...this.entityPropertiesData.filter(i => i.fixedKey),
        ...this.entityPropertiesData.filter(i => !i.fixedKey),
      ]
    }

    if (propertyType === EntityPropertyType.System) {      
      this.entityPropertiesData = [
        ...this.entityPropertiesData.filter(i => i.displayValue === AgePropertyName),
        ...this.entityPropertiesData.filter(i => i.displayValue !== AgePropertyName && i.fixedKey),
        ...this.entityPropertiesData.filter(i => i.displayValue !== AgePropertyName && !i.fixedKey),
      ]
      this.entityPropertiesData = this.entityPropertiesData?.map((item?: any) => {
        return {
          value: item.fixedKey || item.value,
          displayValue: item.displayValue,
          options: item.options,
          valueDescription: item.valueDescription,
          entityReferenceLinkedId: item.entityReferenceLinkedId,
          entityReferencePropertyId: item.entityReferencePropertyId,
          entityReferencePropertyName: item.entityReferencePropertyName,
          type: item.type,
          fixedKey: item.fixedKey
        }
      });
    }

    const entityPropertyId = this.addPropertyForm.value?.propertyName;
    if (entityPropertyId) {
      if (row && row.entityReferencePropertyId) {
        this.addPropertyForm.get('entityReferencePropertyId')?.setValue(row.entityReferencePropertyId);
      }
      this.propertyItemError = '';
      const item = this.entityPropertiesData.find(i => i.value === entityPropertyId);
      if (item) {
        this.addPropertyForm.get('label')?.setValue(item.displayValue);
        this.entityComponentItemData = Object.keys(item.options || {}).map(i => ({
          value: i,
          label: item.options[i],
        }));
        this.addPropertyForm.get('propertyItem')?.setValue(
          this.entityComponentItemData.length > 0 ?
            this.entityComponentItemData[0].value : []
        );
      }
    }
  }

  onSelectTypeVersion() {
    this.addPropertyForm.get('propertyName')?.setValue('');
    this.addPropertyForm.get('label')?.setValue('');
    this.entityComponentItemData = [];
    this.onFilterBySelectVersion();
  }

  onFilterBySelectVersion() {
    const propertyType = this.addPropertyForm.value?.type;
    if (propertyType) {
      this.propertyItemError = '';
      this.entityVersionData = (this.entityVersionDataOrigin || [])
        .filter(i => {
          if (propertyType === EntityPropertyType['Entity Reference']) {
            return i.entityReferencePropertyId
          }
          if (propertyType === EntityPropertyType.Data) {
            return !i.entityReferencePropertyId && !this.listNonDataType.includes(i.type)
          }
          return !i.entityReferencePropertyId && i.type === propertyType
        })
        .sort((a, b) => {
          const nameA = a.displayValue.toUpperCase(); // ignore upper and lowercase
          const nameB = b.displayValue.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        })
        .sort((a, b) => {
          const nameA = a.valueDescription.toUpperCase(); // ignore upper and lowercase
          const nameB = b.valueDescription.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

      this.entityVersionData = [
        ...this.entityVersionData.filter(i => i.fixedKey),
        ...this.entityVersionData.filter(i => !i.fixedKey),
      ];
    }
    const entityPropertyId = this.addPropertyForm.value?.propertyName;
    if (entityPropertyId) {
      this.propertyItemError = '';
      const item = this.entityVersionData.find(i => i.value === entityPropertyId);
      if (item) {
        this.addPropertyForm.get('label')?.setValue(item.displayValue);
        this.entityComponentItemData = Object.keys(item.options || {}).map(i => ({
          value: i,
          label: item.options[i],
        }));
        this.addPropertyForm.get('propertyItem')?.setValue(
          this.entityComponentItemData.length > 0 ?
            this.entityComponentItemData[0].value : []
        );
      }
    }
  }

  onRowActions(data: Row) {
  }

  onOutsideRowDrop(event: OutsideReorderInfo<Row>): void {
    const listData: Record<number, any> = {
      1: this.gridViewColumn1,
      2: this.gridViewColumn2,
      3: this.gridViewColumn3,
      4: this.gridViewColumn4,
    }
    for (let index = 1; index <= Object.keys(listData).length; index++) {
      this.detailViewColumn[index] = [...(listData[index]?.dataSource || [])];
    }
  }

  onOrderDelete(data: Row) {
    if (data.deleted) {
      const row = this.sortRow.find(i => i.entityPropertyId === data.entityPropertyId && i.option === data.option);
      if (row) {
        row.deleted = true;
        this.sortRow = [...this.sortRow]
      }
    }
  }

  onOrderDeleteVersion(data: Row) {
    if (data.deleted) {
      const sortRowVersion = this.gridSortRowVersion?.dataSource || this.sortRowVersion
      const row = sortRowVersion.find(i => i.entityPropertyId === data.entityPropertyId && i.option === data.option);
      if (row) {
        row.deleted = true;
        this.sortRowVersion = [...sortRowVersion]
      }
    }
  }

  onColumnDelete(data: Row) {
    if (data.deleted) {
      const row = this.summaryView.find(i =>
        i.entityPropertyId === data.entityPropertyId &&
        i.entityReferencePropertyId === data.entityReferencePropertyId &&
        i.option === data.option
      );
      if (row) {
        row.deleted = true;
        this.summaryView = [...this.summaryView]
      }
    }
  }

  onColumnSummaryDelete(data: Row) {
    if (data.deleted) {
      const row = this.summaryOrderColumns.find(i =>
        i.entityPropertyId === data.entityPropertyId &&
        i.entityReferencePropertyId === data.entityReferencePropertyId &&
        i.option === data.option
      );
      if (row) {
        row.deleted = true;
        this.summaryOrderColumns = [...this.summaryOrderColumns];
      }
    }
  }

  onSaveInlineValue(data: Row) {
  }

  private camelCaseToWords(s: string) {
    const result = s.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  private addComponentsToForm(components: CardDetailComponent[]) {
    components.forEach(({ type, detailViewColumnType, entityProfileComponentItems = [] }) => {
      const newValue = entityProfileComponentItems.map(i => {
        const columns = type === 3 ?
          this.configuredVersionColumns : type === 1 ?
            this.configuredSummaryColumns : type === 2 ?
              this.configuredListColumns : detailViewColumnType === 0 ?
                this.configuredCardSummaryColumns : this.configuredCardColumns;
        const item: CardEntityProfile & Row = {
          ...i,
          form: this.createInlineEditFormControls(i, columns),
          isSortColumn: i.orderRow !== null,
          nonEditable: i.entityPropertyId === null
        }
        if (this.listOptionFixed.includes(i.option || '')) {
          item.entityPropertyId = i.option;
          item.propertyName = this.camelCaseToWords(i.option || '');
          item.fixedKey = i.option;
        } else {
          item.propertyItem = i.option;
          item.propertyItemLabel = i.option;
        }

        item.subtext = type === 0 || type === 3 ? this.generateSubtext(item) : this.generateSubtextList(item);
        return item;
      });
      switch (type) {
        case 1:
          this.summaryOrderColumns = newValue;
          break;
        case 2:
          this.orderColumns = newValue;
          this.sortRow = this.orderColumns.filter(i => i.orderRow !== null);
          break;
        case 3:
          this.orderColumnsVersion = newValue;
          this.sortRowVersion = this.orderColumnsVersion.filter(i => i.orderRow !== null)
            .sort((a, b) => a.orderRow - b.orderRow);

          this.includeVersionHistory = true;
          this.editForm?.get('listLabel')?.setValidators([
            Validators.required,
            Validators.maxLength(150),
          ])
          break;
        default:
          if (detailViewColumnType === 0)
            this.summaryView = newValue
          else
            this.detailViewColumn[detailViewColumnType || 1] = newValue;
          break;
      }
    })
  }

  private addToComponents(components: CardDetailComponent[], component: CardDetailComponent) {
    const componentByType = components.findIndex(i =>
      i.type === component.type &&
      (i.detailViewColumnType === null || i.detailViewColumnType === component.detailViewColumnType));

    if (componentByType >= 0) {
      components[componentByType] = {
        ...components[componentByType],
        ...component,
      }
    }
  }

  onSaveData() {
    this.editForm?.markAllAsTouched();
    if (this.editForm?.pending) {
      const sub = this.editForm.statusChanges.subscribe(() => {
        if (this.editForm?.valid) {
          this.editForm?.disable();
          this.submit();
        }
        sub.unsubscribe();
      });
    } else if (this.editForm?.valid) {
      this.submit();
    }
  }

  submit() {
    this.editForm?.markAllAsTouched();
    if (
      (this.cardDetailData?.entityComponentId && (
        this.gridSummaryOrderColumns?.formStatus === AbstractControlStatus.INVALID ||
        this.gridOrderColumns?.formStatus === AbstractControlStatus.INVALID
      )) ||
      this.gridSummaryView?.formStatus === AbstractControlStatus.INVALID ||
      this.gridViewColumn1?.formStatus === AbstractControlStatus.INVALID ||
      this.gridViewColumn2?.formStatus === AbstractControlStatus.INVALID ||
      this.gridViewColumn3?.formStatus === AbstractControlStatus.INVALID ||
      this.gridViewColumn4?.formStatus === AbstractControlStatus.INVALID
    ) {
      return;
    }
    if (this.checkBvffReferenceProperties()) {
      return;
    }
    const detailViewColumnType = [
      {
        type: 0,
        entityProfileComponentItems: this.gridSummaryView?.dataSource
      },
      {
        type: 1,
        entityProfileComponentItems: this.gridViewColumn1?.dataSource
      },
      {
        type: 2,
        entityProfileComponentItems: this.gridViewColumn2?.dataSource
      },
      {
        type: 3,
        entityProfileComponentItems: this.gridViewColumn3?.dataSource
      },
      {
        type: 4,
        entityProfileComponentItems: this.gridViewColumn4?.dataSource
      },
    ];
    const listType = [
      {
        type: 1,
        entityProfileComponentItems: this.gridSummaryOrderColumns?.dataSource
      },
      {
        type: 2,
        entityProfileComponentItems: this.gridOrderColumns?.dataSource,
        sortRow: this.gridSortRow?.dataSource
      },
      {
        type: 3,
        entityProfileComponentItems: this.gridOrderColumnsVersion?.dataSource,
        sortRow: this.gridSortRowVersion?.dataSource
      },
    ]

    let components: CardDetailComponent[] = this.cardDetailData?.components ? [...this.cardDetailData?.components] : [];
    let formData = this.editForm?.value;

    // Property components
    detailViewColumnType.forEach(i => {
      const component: CardDetailComponent = {
        type: 0,
        detailViewLabel: formData.detailViewLabel,
        detailViewColumnType: i.type,
        propertyDisplay: formData.propertyDisplay,
        entityProfileComponentItems: (i.entityProfileComponentItems || []).map((j, k) => {
          let option = j.option;
          let entityPropertyId = j.entityPropertyId;
          if (this.listOptionFixed.includes(j.entityPropertyId)) {
            option = j.fixedKey;
            entityPropertyId = null;
          }
          return {
            id: j.id,
            name: j.name,
            entityPropertyId,
            orderColumn: j.deleted ? -1 : k,
            option,
            entityProfileComponentId: j.entityProfileComponentId || undefined,
            entityReferencePropertyName: j.entityReferencePropertyName,
            entityReferencePropertyId: j.entityReferencePropertyId,
            entityReferenceLinkedId: j.entityReferenceLinkedId,
            importKey: j.propertyItem,
          }
        })
      }
      this.addToComponents(components, component);
    });
    listType.forEach(i => {
      const component: CardDetailComponent = {
        type: i.type,
        entityProfileComponentItems: (i.entityProfileComponentItems || []).map((j, k) => {
          let sortType;
          let orderRow;
          (i.sortRow || []).forEach((itemSort, sortIndex) => {
            if (itemSort.entityPropertyId === j.entityPropertyId && itemSort.option === j.option) {
              if (itemSort.deleted) {
                sortType = null;
                orderRow = null;
              } else {
                sortType = itemSort.sortType;
                orderRow = sortIndex;
              }
            }
          });
          let option = j.option;
          let entityPropertyId = j.entityPropertyId;
          if (this.listOptionFixed.includes(j.entityPropertyId)) {
            option = j.entityPropertyId;
            entityPropertyId = null;
          }
          return {
            id: j.id,
            name: j.name,
            entityPropertyId,
            orderColumn: j.deleted ? -1 : k,
            orderRow,
            sortType,// 0: ASC 1: DESC
            option,
            entityProfileComponentId: j.entityProfileComponentId || undefined,
            entityReferencePropertyName: j.entityReferencePropertyName,
            entityReferencePropertyId: j.entityReferencePropertyId,
            entityReferenceLinkedId: j.entityReferenceLinkedId,
            importKey: j.propertyItem,
            ...(i.type === 2 ? { showOnTableList: j.showOnTableList } : {})
          }
        })
      }
      this.addToComponents(components, component);
    });

    // Remove item after drag to new column
    components.forEach(i => {
      if (i.type === 0) {
        const itemOrigin = this.cardDetailData?.components?.find(j =>
          j.type === i.type && j.detailViewColumnType === i.detailViewColumnType
        );
        if (itemOrigin && itemOrigin.entityProfileComponentItems) {
          const currList = i.entityProfileComponentItems?.map(j => j.id);
          itemOrigin.entityProfileComponentItems.forEach(j => {
            if (!currList?.includes(j.id)) {
              i.entityProfileComponentItems?.push({
                ...j,
                orderColumn: -1,
              })
            }
          })
        }
      }
    })

    const cardDetailForm = {
      cardName: formData.cardName,
      enableDragDrop: formData.enableDragDrop,
      listLabel: formData.listLabel,
      includeAttachmentAt: formData.includeAttachmentAt,
      entityComponentId: this.cardDetailData?.entityComponentId,
      components,
    }

    this.isLoading = true;
    this.resetPropertyForm();
    this.memberStore.dispatch(updateCard({
      id: this.viewId,
      body: cardDetailForm
    }))
  }

  private checkBvffReferenceProperties(): boolean {
    if (this.layoutService.fundType !== FundType.BVFF || !this.isMemberEntity) {
      return false;
    }
    const listSelectedPropertyOfDetailCard = [
      ...this.gridViewColumn1?.dataSource || [],
      ...this.gridViewColumn2?.dataSource || [],
      ...this.gridViewColumn3?.dataSource || [],
      ...this.gridViewColumn4?.dataSource || [],
    ];
    const listSelectedPropertyOfSummaryCard = this.gridSummaryView?.dataSource;
    if (
      this.checkIsNotConfigAllReferenceProperties(listSelectedPropertyOfDetailCard, this.listDateOfDeathFixedPropertyId)
      || this.checkIsNotConfigAllReferenceProperties(listSelectedPropertyOfSummaryCard, this.listDateOfDeathFixedPropertyId)
    ) {
      this.showErrorPopup(RequireDateOfDeathErrorMessage);
      return true;
    }

    if (
      this.checkIsNotConfigAllReferenceProperties(listSelectedPropertyOfDetailCard, this.listNewHireExamFixedPropertyId)
      || this.checkIsNotConfigAllReferenceProperties(listSelectedPropertyOfSummaryCard, this.listNewHireExamFixedPropertyId)
    ) {
      this.showErrorPopup(RequireNewHireExamErrorMessage);
      return true;
    }
    return false;
  }

  private checkIsNotConfigAllReferenceProperties(listSelectedProperty: Row[] | undefined, targetListFixedPropertyId: string[]) {
    const listSelectedFixedProperty = listSelectedProperty?.filter(row =>
      !row.deleted
      && targetListFixedPropertyId?.some(p => p === row?.entityPropertyId)
    );
    return listSelectedFixedProperty?.length === 1;
  }

  private showErrorPopup(message: string) {
    this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        title: 'Error',
        type: ConfirmType.Warning,
        text: message,
        cancelButtonTitle: 'Close',
        hideConfirmButton: true,
      },
    });
  }
}
