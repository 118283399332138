<div class="upload-calculation-dialog-container flex flex-col">
  <ptg-breadcrumb [listBreadcrumbs]="[{name: 'Upload Calculation File' }]"></ptg-breadcrumb>
  <div class="upload-form-container flex flex-col p-6 gap-6">
    <div *ngIf="bannerType">
      <ptg-banner [(bannerType)]="bannerType" [message]="message"></ptg-banner>
    </div>
    <div class="flex justify-start gap-3">
      <ptg-button (click)="formSubmit$.next()" classInput="mat-raised-button primary" buttonName="Upload and Save"></ptg-button>
      <ptg-button (click)="onCancel()" classInput="mat-raised-button secondary" buttonName="Cancel"></ptg-button>
    </div>
    <div *ngIf="uploadForm" class="flex flex-col upload-calculation-input-container">
      <ptg-textbox
        class="w-full"
        [controlField]="uploadForm.get('name')"
        [hasLabel]="true"
        [maxLength]="100"
        placeholder="Calculation File Name"
        errorAsync="Calculation File Name already exists."
      ></ptg-textbox>
      <div class="input-group flex">
        <ptg-select
          class="w-full"
          [controlField]="uploadForm.get('benefitEntityId')"
          [isOptionObj]="true"
          [isSetMaxWidthSelect]="true"
          [listData]="listBenefitType"
          placeholder="Benefit Option"
          [isRequired]="true"
        ></ptg-select>
      </div>
      <ptg-input-area
        class="w-full description"
        [controlField]="uploadForm.get('description')"
        [maxLength]="255"
        [ngClass]="{'isEdit': data?.id}"
        errorMaxLength="Exceed the 255 character limit."
        placeholder="Description"
      ></ptg-input-area>
    </div>
    <ptg-upload
      #fileDocument
      (deleteFile)="clearSelectedFile($event)"
      (onUploadAction)="uploadFile($event)"
      [accept]="acceptFile"
      [checkPattern]="checkPattern"
      [isRequired]="true"
      [noUpload]="true"
      [typeFile]="supportedFileTypes"
      [fileSelected]="fileSelected"
      [controlField]="fileControl"
      [ignoreCancel]="true"
      errRequired="Upload Calculation file is required."
      errMsg="Unsupported file format."
      errorAsync="Upload Calculation file already exists."
      class="m-0"
      placeholder="Upload Calculation File"
      uploadButtonName="Upload Calculation File"
    ></ptg-upload>
  </div>

</div>
