import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { SORT_TYPE } from '@ptg-shared/constance';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { Subject } from 'rxjs';
import { filter, startWith, switchMap, take, tap } from 'rxjs/operators';
import { BaseComponent } from '@ptg-shared/components';
import { Router } from '@angular/router';
import { Option } from '@ptg-shared/controls/select/select.component';
import * as fromMember from '../../store/reducers';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { GetEntitiesQuery } from 'src/app/entity-management/services/models';
import { Header } from '@ptg-member/types/models/header.model';
import { createHeader } from '@ptg-member/store/actions/header.actions';
import { getEntitiesAction } from '@ptg-member/store/actions/view.actions';
import { createHeaderSelector, getEntitiesSelector, selectHeaderListState } from '@ptg-member/store/selectors';

@Component({
  selector: 'ptg-add-header',
  templateUrl: './add-header.component.html',
  styleUrls: ['./add-header.component.scss'],
})
export class AddHeaderComponent extends BaseComponent {
  formSubmit$ = new Subject<boolean>();
  listEntity: Option[] = [];
  listHeader: Header[] = [];
  editForm!: FormGroup;
  isContinue = false;
  constructor(
    public fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddHeaderComponent>,
    public memberStore: Store<fromMember.MemberState>,
    public router: Router,
    public switchConfirmPopupService: SwitchConfirmPopupService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getEntityData();
    this.emitFormSubmit();

    this.editForm = this.fb.group({
      headerName: this.fb.control(null, { validators: [Validators.required] }),
      linkedEntity: this.fb.control(null, Validators.required),
    });

    this.memberStore.pipe(select(getEntitiesSelector)).subscribe((data) => {
      this.listEntity = (data?.payload ?? []).map((ele: any) => {
        return {
          value: ele.id,
          displayValue: ele.name,
        };
      });
    });

    this.memberStore.pipe(select(selectHeaderListState)).subscribe((data) => {
      this.listHeader = data?.payload ?? [];
    });

    this.memberStore.pipe(select(createHeaderSelector)).subscribe((data) => {
      if (this.isContinue && data?.payload) {
        this.dialogRef.close();
        this.router.navigateByUrl(`/member/header/${data?.payload?.id}`);
      } else {
        this.editForm.reset();
      }
    });
  }

  ngOnDestroy(): void {
    this.isContinue = false;
  }

  emitFormSubmit(): void {
    this.formSubmit$
      .pipe(
        tap((isContinue) => {
          this.isContinue = isContinue;
          this.editForm.markAllAsTouched();
          this.editForm.get('headerName')?.updateValueAndValidity();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  onSubmit(): void {
    const body = this.editForm.getRawValue();
    const isDuplicateEntity = this.listHeader.some(headerItem => headerItem.headerName?.toLocaleLowerCase() === body.headerName?.toLocaleLowerCase())

    if(isDuplicateEntity) {
      this.editForm.controls["headerName"]?.setErrors({ inValidAsync: true });
      return;
    }

    this.editForm.markAllAsTouched();
    if (!this.editForm.valid) {
      return;
    }
    

    this.memberStore.dispatch(
      createHeader({
        body: {
          name: body?.headerName,
          entityId: body?.linkedEntity
        },
      })
    );
  }

  getEntityData(): void {
    const query: GetEntitiesQuery = {
      sortField: 'name',
      sortType: SORT_TYPE.ASC,
    };

    this.memberStore.dispatch(getEntitiesAction({ query: query }));
  }

  onCancel(): void {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }
}
