<div class="drop-plan-container" [class.show-banner]="bannerType">
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
    (onAdd)="onUpdate()"
    buttonAddLabel="New Plan Year"
  ></ptg-breadcrumb>
  <div class="drop-banner" *ngIf="bannerType">
    <ptg-banner
    [(bannerType)]="bannerType"
    [message]="message"
    ></ptg-banner>
  </div>

  <ptg-grid
    [data]="dataTable"
    [columns]="columns"
    [isLoading]="isLoading"
    [fitToParent]="true"
    [totalRecords]="totalRecord"
    (pageChange)="onChangePage($event)"
    (sortChange)="onChangeSort($event)"
    [pageSize]="pageSize"
    [pageNumber]="pageNumber"
    [(currentRowIndex)]="currentRowIndex"
    (rowClick)="selectRow({ row: $event, index: currentRowIndex })"
    notFoundMessage="No {{ currentPageName }} to display"
  >
    <ng-template cellContent columnName="ACTION_COLUMN" let-row>
      <ptg-button
        buttonName="Edit"
        classInput="delete-button"
        (clickButton)="onUpdate(row)"
      >
        <mat-icon>edit</mat-icon>
      </ptg-button>
    </ng-template>
  </ptg-grid>
</div>
