import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { deepClone } from '@ptg-shared/utils/common.util';

import * as NextPaymentActions from '../../store/actions/next-payment.actions';
import {
  PaymentDeduction,
  PaymentHistoryListResponse,
  PaymentHistoryQuery,
  PaymentNotesQuery,
  PendingPaymentListResponse,
} from '../../types/models';
import { NextPaymentService } from '../../services/next-payment.service';

@Injectable()
export class NextPaymentEffects {
  constructor(
    private actions$: Actions,
    private nextPaymentService: NextPaymentService
  ) {}

  getNextPaymentDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NextPaymentActions.getNextPayment),
      switchMap(({ section, isHistory, targetId, benefitId }) => {
        return this.nextPaymentService
          .getNextPayment(section, isHistory, targetId, benefitId)
          .pipe(
            map((res: any) => {
              if (res.details?.payments) {
                res.details?.payments?.sort((a: any, b: any) => {
                  if (a.order > b.order) {
                    return 1;
                  }
                  return -1;
                });
              }
              return NextPaymentActions.getNextPaymentSuccess({
                dataNextPayment: res.details,
                section,
                depositDate: res.depositDate,
                targetId,
              });
            }),
            catchError((err) => {
              return of(
                NextPaymentActions.getNextPaymentFailure({
                  errorMsg: err.message,
                })
              );
            })
          );
      })
    )
  );

  addNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NextPaymentActions.addNote),
      switchMap(({ body, targetId }) => {
        return this.nextPaymentService.addNote(body, targetId).pipe(
          map((res: any) => {
            return NextPaymentActions.addNoteSuccess();
          }),
          catchError((err) => {
            return of(
              NextPaymentActions.addNoteFailure({ errorMsg: err.message })
            );
          })
        );
      })
    )
  );

  updateEarnings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NextPaymentActions.updateEarnings),
      switchMap(({ body, targetId }) =>
        this.nextPaymentService.updateEarnings(body, targetId).pipe(
          map(() => NextPaymentActions.updateEarningsSuccess()),
          catchError((error) => {
            return of(NextPaymentActions.updateEarningsFailure({ error }));
          })
        )
      )
    )
  );

  recalculateTotalDeductions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NextPaymentActions.recalculateTotalDeductions),
      switchMap(({ body }) =>
        this.nextPaymentService.recalculateTotalDeductions(body).pipe(
          map((res: any) =>
            NextPaymentActions.recalculateTotalDeductionsSuccess(res)
          ),
          catchError((error) => {
            return of(
              NextPaymentActions.recalculateTotalDeductionsFailure({ error })
            );
          })
        )
      )
    )
  );

  updatePayrollSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NextPaymentActions.updatePayrollSettings),
      switchMap(({ body, targetId }) =>
        this.nextPaymentService.updatePayrollSettings(body, targetId).pipe(
          map((res: any) => NextPaymentActions.updatePayrollSettingsSuccess()),
          catchError((error) => {
            return of(
              NextPaymentActions.updatePayrollSettingsFailure({ error })
            );
          })
        )
      )
    )
  );

  getNextPaymentHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NextPaymentActions.getNextPaymentHistory),
      switchMap(({ query, memberId }) => {
        return this.nextPaymentService
          .getNextPaymentHistory(query as PaymentHistoryQuery, memberId)
          .pipe(
            map((response: PaymentHistoryListResponse) => {
              return NextPaymentActions.getNextPaymentHistorySuccess({
                response,
              });
            }),
            catchError((error) => {
              return of(
                NextPaymentActions.getNextPaymentHistoryFailure({ error })
              );
            })
          );
      })
    )
  );

  updatePayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NextPaymentActions.updatePayment),
      switchMap(({ memberId, body, targetId }) => {
        return this.nextPaymentService
          .updatePayment(memberId, body, targetId)
          .pipe(
            map(() => {
              return NextPaymentActions.updatePaymentSuccess();
            }),
            catchError((err) => {
              return of(
                NextPaymentActions.updatePaymentFailure({
                  errorMsg: err.message,
                })
              );
            })
          );
      })
    )
  );

  updateDeduction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NextPaymentActions.updateDeduction),
      switchMap(({ body, targetId }) => {
        return this.nextPaymentService.updateDeduction(body, targetId).pipe(
          map((res: any) => {
            return NextPaymentActions.updateDeductionSuccess();
          }),
          catchError((error) => {
            return of(NextPaymentActions.updateDeductionFailure({ error }));
          })
        );
      })
    )
  );

  getMemberAddressItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NextPaymentActions.getMemberAddressItems),
      switchMap(() => {
        return this.nextPaymentService.getMemberAddressItems().pipe(
          map((res) => {
            return NextPaymentActions.getMemberAddressItemsSuccess({
              memberAddressItems: res.items,
            });
          }),
          catchError((err) => {
            return of(
              NextPaymentActions.getMemberAddressItemsFailure({
                errorMsg: err.message,
              })
            );
          })
        );
      })
    )
  );

  updateMemo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NextPaymentActions.updateMemo),
      switchMap(({ body }) => {
        return this.nextPaymentService.updateMemo(body).pipe(
          map((res: any) => {
            return NextPaymentActions.updateMemoSuccess();
          }),
          catchError((err) => {
            return of(
              NextPaymentActions.updateMemoFailure({ errorMsg: err.message })
            );
          })
        );
      })
    )
  );

  removeMemo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NextPaymentActions.removeMemo),
      switchMap(({ id }) => {
        return this.nextPaymentService.removeMemo(id).pipe(
          map((res: any) => {
            return NextPaymentActions.removeMemoSuccess();
          }),
          catchError((err) => {
            return of(
              NextPaymentActions.removeMemoFailure({ errorMsg: err.message })
            );
          })
        );
      })
    )
  );

  getNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NextPaymentActions.getNotes),
      switchMap(({ query, targetId }) => {
        return this.nextPaymentService
          .getNotes(query as PaymentNotesQuery, targetId)
          .pipe(
            map((paymentInfoNotes: any) => {
              return NextPaymentActions.getNotesSuccess({ paymentInfoNotes });
            }),
            catchError((err) => {
              return of(
                NextPaymentActions.getNotesFailure({ errorMsg: err.message })
              );
            })
          );
      })
    )
  );

  getRepresentativePayees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NextPaymentActions.getRepresentativePayees),
      switchMap(() => {
        return this.nextPaymentService.getRepresentativePayees().pipe(
          map((res: any) => {
            return NextPaymentActions.getRepresentativePayeesSuccess({
              representativePayees: res.representativePayees,
            });
          }),
          catchError((err) => {
            return of(
              NextPaymentActions.getRepresentativePayeesFailure({
                errorMsg: err.message,
              })
            );
          })
        );
      })
    )
  );

  getPendingPayments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NextPaymentActions.getPendingPaymentRequest),
      switchMap(({ query }) => {
        return this.nextPaymentService.getPendingPayments(query).pipe(
          map((response: PendingPaymentListResponse) => {
            return NextPaymentActions.getPendingPaymentSuccess({
              response,
            });
          }),
          catchError((error) => {
            return of(NextPaymentActions.getPendingPaymentFailure({ error }));
          })
        );
      })
    )
  );

  issueOffCyclePayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NextPaymentActions.issueOffCyclePayment),
      switchMap(({ body }) => {
        return this.nextPaymentService.issueOffCyclePayment(body).pipe(
          map((res) => {
            return NextPaymentActions.issueOffCyclePaymentSuccess();
          }),
          catchError((err) => {
            return of(
              NextPaymentActions.issueOffCyclePaymentFailure({
                error: deepClone(err?.error),
              })
            );
          })
        );
      })
    )
  );

  getPaymentDeductions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NextPaymentActions.getPaymentDeductionsRequest),
      switchMap(({ query, clientId }) => {
        return this.nextPaymentService
          .getPaymentDeductions(query, clientId)
          .pipe(
            map(
              (paymentDeductionList: {
                total: number;
                deductions: PaymentDeduction[];
              }) => {
                return NextPaymentActions.getPaymentDeductionsSuccess({
                  paymentDeductions: paymentDeductionList.deductions,
                });
              }
            ),
            catchError((err) => {
              return of(NextPaymentActions.getPaymentDeductionsFailure());
            })
          );
      })
    )
  );

  createOffCyclePayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NextPaymentActions.createOffCyclePaymentRequest),
      switchMap(({ body }) => {
        return this.nextPaymentService.createOffCyclePayment(body).pipe(
          map((newOffCyclePaymentId) => {
            return NextPaymentActions.createOffCyclePaymentSuccess({
              newOffCyclePaymentId,
            });
          }),
          catchError((err) => {
            return of(NextPaymentActions.createOffCyclePaymentFailure());
          })
        );
      })
    )
  );

  getParticipantNextPaymentDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NextPaymentActions.getParticipantNextPaymentRequest),
      switchMap(({ section }) => {
        return this.nextPaymentService.getNextPayment(section || 0).pipe(
          map((res: any) => {
            if (res.details?.payments) {
              res.details?.payments?.sort((a: any, b: any) => {
                if (a.order > b.order) {
                  return 1;
                }
                return -1;
              });
            }
            return NextPaymentActions.getParticipantNextPaymentSuccess({
              participantNextPaymentSetting: res.details,
            });
          }),
          catchError((err) => {
            return of(
              NextPaymentActions.getParticipantNextPaymentFailure({
                errorMsg: err.message,
              })
            );
          })
        );
      })
    )
  );

  withdrawOffCyclePayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NextPaymentActions.withdrawOffCyclePayment),
      switchMap(({ offCyclePaymentId }) =>
        this.nextPaymentService.withdrawOffCyclePayment(offCyclePaymentId).pipe(
          map((res: any) =>
            NextPaymentActions.withdrawOffCyclePaymentSuccess()
          ),
          catchError((err) =>
            of(
              NextPaymentActions.withdrawOffCyclePaymentFailure({
                errorMsg: err.message,
              })
            )
          )
        )
      )
    )
  );

  removeOffCyclePayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NextPaymentActions.removeOffCyclePaymentRequest),
      switchMap(({ offCyclePaymentId }) => {
        return this.nextPaymentService
          .removeOffCyclePayment(offCyclePaymentId)
          .pipe(
            map(() => {
              return NextPaymentActions.removeOffCyclePaymentSuccess();
            }),
            catchError((err) => {
              return of(NextPaymentActions.removeOffCyclePaymentFailure());
            })
          );
      })
    )
  );

  getPaymentInforConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NextPaymentActions.getPaymentInforConfig),
      switchMap(() => {
        return this.nextPaymentService.getPaymentInforConfig().pipe(
          map((res: any) => {
            return NextPaymentActions.getPaymentInforConfigSuccess({
              paymentInforConfigData: res.componentItems,
            });
          }),
          catchError((err) => {
            return of(NextPaymentActions.getPaymentInforConfigFailure());
          })
        );
      })
    )
  );

  savePaymentInforConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NextPaymentActions.savePaymentInforConfig),
      switchMap(({ paymentInforConfig }) => {
        return this.nextPaymentService
          .savePaymentInforConfig(paymentInforConfig)
          .pipe(
            map((res: any) => {
              return NextPaymentActions.savePaymentInforConfigSuccess();
            }),
            catchError((err) => {
              return of(NextPaymentActions.savePaymentInforConfigFailure());
            })
          );
      })
    )
  );

  setOffCyclePaymentPayPeriod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NextPaymentActions.setOffCyclePaymentPayPeriod),
      switchMap(({ id, body }) =>
        this.nextPaymentService
          .setOffCyclePaymentPayPeriod(id, body)
          .pipe(
            map(() =>
              NextPaymentActions.setOffCyclePaymentPayPeriodSuccess()
            ),
            catchError((error) => {
              return of(
                NextPaymentActions.setOffCyclePaymentPayPeriodFailure({ error })
              );
            })
          )
      )
    )
  );
}
