export const MODULE_KEY = 'processingModule';

export const chicagoParkDepositDate: Date = new Date(2023, 5, 1);

export const ProcessingMessage = {
  // payroll
  PayrollUpdateTransaction:
    'Updating transactions for the current Payroll could take some time. System will send a notification email when it completes.',
  PayrollFinalize:
    'Finalizing the current Payroll could take some time. System will send a notification email when it completes.',
  PayrollSendToBank:
    'The payments were submitted, and the system is waiting for confirmation from the bank. This payroll will be finalized when this information is received.',
  PayrollReset: 'Resetting payroll. Please wait a few moment.',
  // service provider
  ServiceProviderUpdateTransaction:
    'Updating transactions for the current Service Provider Payment could take some time. System will send a notification email when it completes.',
  ServiceProviderFinalize:
    'Finalizing the current Service Provider Payment could take some time. System will send a notification email when it completes.',
  ServiceProviderSendToBank:
    'The payments were submitted, and the system is waiting for confirmation from the bank. This Service Provider Payment will be finalized when this information is received.',
  // off-cycle
  OffCycleSelectBank: 'Processing bank information.',
  OffCycleFinalize:
    'Finalizing the current Payments could take some time. You will be redirected back to View off-cycle Payment screen when it is finished.',
  OffCycleSendToBank:
    'The payments were submitted, and the system is waiting for confirmation from the bank. These off-cycle payments will be finalized when this information is received.',
  OffCycleCancel:
    'Canceling the Payment order. You will be redirected back to View off-cycle Payment screen when it is finished.',
};
