import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { stringToBoolean } from '@ptg-shared/utils/string.util';

import { EntityPropertyType, IncludeAttachmentType } from '@ptg-entity-management/types/enums';
import { getEntityValue } from '@ptg-member/helper';
import { CardComponent, ListPropertyForCard } from '@ptg-entity-management/services/models';

@Component({
  selector: 'ptg-entity-detail-card-selected',
  templateUrl: './entity-detail-card-selected.component.html',
  styleUrls: ['./entity-detail-card-selected.component.scss'],
})
export class EntityDetailCardSelectedComponent implements OnInit, OnChanges {
  readonly EntityPropertyType = EntityPropertyType;
  readonly IncludeAttachmentType = IncludeAttachmentType;

  @Input() currentRow!: any;
  @Input() metadataSection?: any;
  @Input() menuItemName!: string;
  @Input() isList?: boolean;
  @Input() memberId!: string;
  @Input() isLoading: boolean = true;
  @Input() isBenefitCard: boolean = false;
  @Input() attachmentType?: IncludeAttachmentType;

  @Output() editButtonEvent = new EventEmitter();
  @Output() removeButtonEvent = new EventEmitter();
  memberNavigationItem: any;
  listValue: any[] = [];
  visibilityOff: boolean = true;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.listValue = [];
    if (changes.currentRow && changes.currentRow.currentValue) {
      this.metadataSection?.forEach((p: any) => {
        this.getProperties(p);
      });
    }
  }

  getProperties(cardComponent: CardComponent): any {
    this.listValue.push(this.setDataProperties(cardComponent));
    this.listValue.sort((a, b) => Number(a.orderColumn) - Number(b.orderColumn));
  }

  setDataProperties(cardComponent: any): any {
    let currentRowData: any;
    if (cardComponent?.entityReferencePropertyId) {
      currentRowData = this.currentRow?.values?.find(
        (item: any) =>
          item.entityPropertyId === cardComponent?.entityReferencePropertyId &&
          item.entityReferencePropertyId === cardComponent?.propertyId,
      );
    } else if (cardComponent?.propertyId) {
      currentRowData = this.currentRow?.values?.find(
        (item: any) => item.entityPropertyId === cardComponent?.propertyId,
      );
    } else {
      currentRowData = this.currentRow?.find((item: any) => item.fixedPropertyKey === cardComponent?.option);
    }

    const entityData: ListPropertyForCard = {
      value: currentRowData?.value,
      options: currentRowData?.options,
      option: currentRowData?.option,
    };
    const opiton = {
      type: cardComponent?.type,
      config: cardComponent?.config,
    };

    const data = getEntityValue(entityData, opiton, true);
    return {
      key: this.getColumnName(cardComponent),
      value: data,
      title: cardComponent?.propertyLabel,
      type: cardComponent.type,
      configs: cardComponent?.config,
      options: cardComponent?.options,
      masked: stringToBoolean(cardComponent?.config?.masked),
      valueUnMasked: this.mapDataUnMasked(entityData),
      visibilityOff: stringToBoolean(cardComponent?.config?.masked),
    };
  }

  getColumnName(column: any): string {
    let columnName = '';
    columnName = column.propertyId;
    if (column.propertyId) {
      columnName = columnName + '_' + column.entityReferencePropertyId;
    }

    if (column.option) {
      columnName = columnName + '_' + column.option;
    }

    return columnName;
  }

  mapDataUnMasked(entityData: any) {
    return entityData?.value?.originalValue;
  }

  ngOnInit(): void {}

  onClickIcon(row: any) {
    row.visibilityOff = !row.visibilityOff;
  }

  edit() {
    this.editButtonEvent.emit(this.currentRow);
  }
  remove() {
    this.removeButtonEvent.emit(this.currentRow);
  }
}
