export enum EntityViewType {
  System,
  Summary,
  Detail,
}

export enum EntityViewFixType {
  ParticipantRelationships,
  PaymentInformation,
  StatusHistory,
  ServiceHistory,
  Payments,
}
