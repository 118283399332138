import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { loadingIcon } from '@ptg-shared/constance/listIcons.const';
import { BannerIconConfig, BannerType } from '@ptg-shared/controls/banner/types/banner.model';

const BANNER_ICON_CONFIG: Record<string, BannerIconConfig> = {
  Success: {
    icon: 'check_circle',
    className: 'success',
  },
  Fail: {
    icon: 'error',
    className: 'fail',
  },
  Warning: {
    icon: 'warning',
    className: 'warning',
  },
  Info: {
    icon: 'info',
    className: 'info',
  },
};

@Component({
  selector: 'ptg-infor-banner',
  templateUrl: './infor-banner.component.html',
  styleUrls: ['./infor-banner.component.scss'],
})
export class InforBannerComponent implements OnInit, OnChanges {
  bannerIconConfig!: BannerIconConfig;

  @Input() bannerType: BannerType = BannerType.Hidden;
  @Input() messages: string[] = [];
  @Input() title: string = '';
  @Input() isHideCloseButton = false;
  @Input() isLoadingInfo: boolean = false;
  @Input() style?: any;

  @Output() closeBanner = new EventEmitter();
  @Output() bannerTypeChange: EventEmitter<BannerType> = new EventEmitter();

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIconLiteral('loadingIcon', sanitizer.bypassSecurityTrustHtml(loadingIcon));
  }

  ngOnInit(): void {}

  closeAlert() {
    this.closeBanner.emit();
    this.bannerTypeChange.emit(BannerType.Hidden);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.message || changes.bannerType) {
      this.getBannerIconConfig();
    }
  }

  getBannerIconConfig(): void {
    this.bannerIconConfig = BANNER_ICON_CONFIG[this.bannerType];
  }
}
