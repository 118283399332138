import { createSelector } from '@ngrx/store';

import { selectCalculationModuleState } from './selector';

export const calculationParameterConfigurationSelector = createSelector(
  selectCalculationModuleState,
  (state) => state?.calculationParameterConfiguration,
);
export const getParameterMappingsSelector = createSelector(
  calculationParameterConfigurationSelector,
  (state) => state?.getParameterMappings,
);
export const getCalculationParametersSelector = createSelector(
  calculationParameterConfigurationSelector,
  (state) => state?.getCalculationParameter,
);

export const setCalculationParameterSelector = createSelector(
  calculationParameterConfigurationSelector,
  (state) => state?.setCalculationParameter,
);

export const getCalculationPropertySelector = createSelector(
  calculationParameterConfigurationSelector,
  (state) => state?.getCalculationProperty,
);
