import { createAction, props } from '@ngrx/store';

import {
  GetDocumentsRequest,
  GetDocumentsResponse,
  GetEmployerDocumentOverviewRequest,
  GetEmployerDocumentOverviewResponse,
  RemoveDocumentBodyRequest,
  ViewGeneratedDocumentRequest,
  ViewGeneratedDocumentResponse,
} from '@ptg-employer/models/employer-document.model';

export const getEmployerDocumentsAction = createAction(
  '[EmployerDocuments/API] Send Request',
  props<{ request: GetDocumentsRequest }>(),
);

export const getEmployerDocumentsSuccessAction = createAction(
  '[EmployerDocuments/API] Success',
  props<{ response: GetDocumentsResponse }>(),
);

export const getEmployerDocumentsFailureAction = createAction(
  '[EmployerDocuments/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetEmployerDocumentsStateAction = createAction(
  '[EmployerDocuments] Clear',
);

export const getGeneratedDocumentAction = createAction(
  '[GetGeneratedDocument/API] Send Request',
  props<{ request: ViewGeneratedDocumentRequest }>(),
);

export const getGeneratedDocumentSuccessAction = createAction(
  '[GetGeneratedDocument/API] Success',
  props<{ response: ViewGeneratedDocumentResponse }>(),
);

export const getGeneratedDocumentFailureAction = createAction(
  '[GetGeneratedDocument/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetGeneratedDocumentStateAction = createAction(
  '[GetGeneratedDocument] Clear',
);

export const uploadEmployerDocumentsAction = createAction(
  '[UploadEmployerDocumentsAction/API] Send Request',
  props<{ body: any }>(),
);

export const uploadEmployerDocumentsSuccessAction = createAction(
  '[UploadEmployerDocuments/API] Success',
);

export const uploadEmployerDocumentsFailureAction = createAction(
  '[UploadEmployerDocuments/API] Failure',
  props<{ error?: any }>(),
);

export const clearUploadEmployerDocumentsStateAction = createAction(
  '[UploadEmployerDocuments] Clear',
);

export const editEmployerDocumentsAction = createAction(
  '[EditEmployerDocuments/API] Send Request',
  props<{ employerId: string, fileId: string, body: any }>(),
);

export const editEmployerDocumentsSuccessAction = createAction(
  '[EditEmployerDocuments/API] Success',
);

export const editEmployerDocumentsFailureAction = createAction(
  '[EditEmployerDocuments/API] Failure',
  props<{ error?: any }>(),
);

export const clearEditEmployerDocumentsStateAction = createAction(
  '[EditEmployerDocuments] Clear',
);

export const removeEmployerDocumentsAction = createAction(
  '[RemoveEmployerDocuments/API] Send Request',
  props<{ body: RemoveDocumentBodyRequest }>(),
);

export const removeEmployerDocumentsSuccessAction = createAction(
  '[RemoveEmployerDocuments/API] Success',
);

export const removeEmployerDocumentsFailureAction = createAction(
  '[RemoveEmployerDocuments/API] Failure',
  props<{ error?: any }>(),
);

export const clearRemoveEmployerDocumentsStateAction = createAction(
  '[RemoveEmployerDocuments] Clear',
);

export const getEmployerDocumentOverviewAction = createAction(
  '[GetEmployerDocumentOverview/API] Send Request',
  props<{ employerId: string, request: GetEmployerDocumentOverviewRequest }>(),
);

export const getEmployerDocumentOverviewSuccessAction = createAction(
  '[GetEmployerDocumentOverview/API] Success',
  props<{ response: GetEmployerDocumentOverviewResponse }>(),
);

export const getEmployerDocumentOverviewFailureAction = createAction(
  '[GetEmployerDocumentOverview/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetEmployerDocumentOverviewStateAction = createAction(
  '[ClearGetEmployerDocumentOverviewState/API] Failure',
  props<{ error?: any }>(),
);

export const uploadDocumentEmployerOverviewAction = createAction(
  '[UploadDocumentEmployerOverview/API] Send Request',
  props<{ body: any }>(),
);

export const uploadDocumentEmployerOverviewSuccessAction = createAction(
  '[UploadDocumentEmployerOverview/API] Success',
);

export const uploadDocumentEmployerOverviewFailureAction = createAction(
  '[UploadDocumentEmployerOverview/API] Failure',
  props<{ error?: any }>(),
);

export const clearUploadDocumentEmployerOverviewStateAction = createAction(
  '[ClearUploadDocumentEmployerOverviewState/API] Failure',
);
