<div class="list-display" [ngClass]="{'noDetail': !dataTable?.length, 'noConfigColumn': (columns?.length === 0 || columns?.length === 1 && sectionHasAttachment)}">
  <div class="list-header">
    {{ menuItemName }} List
    <div class="space-line"></div>
    <div class="edit-btn" (click)="addNew($event)" [ngClass]="{'noConfiguredBtn': (!columns || columns?.length === 0 || columns?.length === 1 && sectionHasAttachment)}">
      <mat-icon>add</mat-icon>
      <span class="title-edit">New {{ menuItemName }}</span>
    </div>
    <div class="edit-btn" *ngIf="dataTable?.length > 0 && isDragDrop" (click)="manageSection($event)" [ngClass]="{'noConfiguredBtn': (!columns || columns?.length === 0 || columns?.length === 1 && sectionHasAttachment)}">
      <mat-icon class="member-section-action-icon"> list_alt </mat-icon>
      <span class="label-icon">Manage</span>
    </div>
  </div>
  <ptg-grid
    *ngIf="columns?.length && (!sectionHasAttachment || (sectionHasAttachment && columns.length > 1))"
    [data]="dataTable"
    [columns]="columns"
    [isLoading]="isLoading"
    [totalRecords]="lengthPg"
    [pageSize]="pageSize"
    [pageNumber]="pageNumber"
    (pageChange)="changePagging($event)"
    (sortChange)="sortChange($event)"
    (rowClick)="selectRow($event)"
    [(currentRowIndex)]="currentRowIndex"
    [errorMessage]="errorMsg"
    [fitToParent]="true"
    [hideScrollbar]="false"
    [notFoundMessage]="'No ' + menuItemName + ' to Display'"
    >
  </ptg-grid>
  <div *ngIf="!isLoading && (!columns || columns?.length === 0 || columns?.length === 1 && sectionHasAttachment)" class="list-data-not-found no-columns-display">
    <div class="message">List not yet configured</div>
  </div>
</div>
