import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: 'ng-template[detailValueContent]'
})
export class DetailValueContentDirective {
  @Input() templateName!: string;

  constructor(public templateRef: TemplateRef<any>) { }

}
