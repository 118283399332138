import { createReducer, on } from '@ngrx/store';

import { STATE } from '@ptg-shared/constance/value.const';

import {
  PropertyDisplayConfiguration,
} from '../../types/models';
import * as ParticipantSearchConfigurationAction from '../actions/participant-search-configuration.actions';
import { SearchConfigurationDisplayConfig, SearchPropertyDisplayConfig } from '@ptg-member/types/models/participant-search-configuration.model';

export const participantSearchConfigurationFeatureKey =
  'participantSearchConfiguration';

export interface State {
  isLoading: boolean;
  propertyConfigs: SearchPropertyDisplayConfig[];
  propertyDisplayConfigurations: PropertyDisplayConfiguration[];
  updateState: string;
  configurations: SearchConfigurationDisplayConfig[];
}

const initialState: State = {
  isLoading: true,
  propertyConfigs: [],
  propertyDisplayConfigurations: [],
  updateState: '',
  configurations: []
};

export const reducer = createReducer(
  initialState,
  on(
    ParticipantSearchConfigurationAction.clearParticipantSearchConfigurationState,
    (state) => ({
      ...state,
      updateState: '',
    })
  ),
  on(
    ParticipantSearchConfigurationAction.getParticipantSearchPropertyListSuccess,
    (state, { propertyConfigs }) => ({
      ...state,
      isLoading: false,
      propertyConfigs,
    })
  ),
  on(
    ParticipantSearchConfigurationAction.getParticipantSearchPropertyListFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      propertyConfigs: [],
    })
  ),
  on(
    ParticipantSearchConfigurationAction.getParticipantSearchConfigurationSuccess,
    (state, { propertyDisplayConfigurations }) => ({
      ...state,
      isLoading: false,
      propertyDisplayConfigurations,
    })
  ),
  on(
    ParticipantSearchConfigurationAction.getParticipantSearchConfigurationFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      propertyDisplayConfigurations: [],
    })
  ),
  on(
    ParticipantSearchConfigurationAction.setParticipantSearchConfigurationSuccess,
    (state) => ({
      ...state,
      isLoading: false,
      updateState: STATE.SUCCESS,
    })
  ),
  on(
    ParticipantSearchConfigurationAction.setParticipantSearchConfigurationFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      propertyDisplayConfigurations: [],
      updateState: STATE.FAIL,
    })
  ),
  on(
    ParticipantSearchConfigurationAction.getParticipantSearchConfigurationEntitySuccess,
    (state, { configurations }) => ({
      ...state,
      isLoading: false,
      configurations,
    })
  ),
  on(
    ParticipantSearchConfigurationAction.getParticipantSearchConfigurationEntityFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      configurations: [],
    })
  ),
);
