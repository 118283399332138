import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { SubHeaderConfigurationService } from '../../services/subheader-configuration.service';
import { getSubHeaderConfigurationDetailAction, getSubHeaderConfigurationDetailFailureAction, getSubHeaderConfigurationDetailSuccessAction, getSubHeaderConfigurationsListAction, getSubHeaderConfigurationsListFailureAction, getSubHeaderConfigurationsListSuccessAction, getSubHeaderDataAction, getSubHeaderDataFailureAction, getSubHeaderDataSuccessAction, setSubHeaderConfigurationDetailAction, setSubHeaderConfigurationDetailFailureAction, setSubHeaderConfigurationDetailSuccessAction } from '../actions/subheader-configuration.action';
import { GetSubHeaderConfigurationsListResponse, GetSubHeaderDataResponse, SubHeaderConfigurationDetailResponse } from '../../services/models/subheader-configuration.model';

@Injectable()
export class SubHeaderConfigurationEffect {
  constructor(
    private actions$: Actions,
    private subHeaderConfigurationService: SubHeaderConfigurationService,
  ) {}

  getSubHeaderConfigurationsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSubHeaderConfigurationsListAction),
      switchMap(({ memberId, request }) =>
        this.subHeaderConfigurationService.getSubHeaderConfigurationsList(memberId, request).pipe(
          map((response: GetSubHeaderConfigurationsListResponse) => getSubHeaderConfigurationsListSuccessAction({ response })),
          catchError((error) => of(getSubHeaderConfigurationsListFailureAction({ error }))),
        ),
      ),
    ),
  );

  getSubHeaderConfigurationDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSubHeaderConfigurationDetailAction),
      switchMap(({ memberId, subHeaderId }) =>
        this.subHeaderConfigurationService.getSubHeaderConfigurationDetail(memberId, subHeaderId).pipe(
          map((response: SubHeaderConfigurationDetailResponse) => getSubHeaderConfigurationDetailSuccessAction({ response }),),
          catchError((error) => of(getSubHeaderConfigurationDetailFailureAction({ error }))),
        ),
      ),
    ),
  );

  setSubHeaderConfigurationDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setSubHeaderConfigurationDetailAction),
      switchMap(({ memberId, subHeaderId, request }) =>
        this.subHeaderConfigurationService.setSubHeaderConfigurationDetail(memberId, subHeaderId, request).pipe(
          map(() => setSubHeaderConfigurationDetailSuccessAction()),
          catchError((error) => {
            let errorMessage = ''
            if (error?.error?.errorType === 'CommonException') {
              errorMessage = error?.error?.errorMessage[0] ?? '';
            }
            return of(setSubHeaderConfigurationDetailFailureAction({ errorMsg: errorMessage }))
          })
        ),
      ),
    ),
  );

  getSubHeaderData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSubHeaderDataAction),
      switchMap(({ memberId }) =>
        this.subHeaderConfigurationService.getSubHeaderData(memberId).pipe(
          map((response: GetSubHeaderDataResponse) => getSubHeaderDataSuccessAction({ response }),),
          catchError((error) => of(getSubHeaderDataFailureAction({ error }))),
        ),
      ),
    ),
  );
}
