import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

import { deepClone } from '@ptg-shared/utils/common.util';
import { getDateString } from '@ptg-shared/utils/string.util';
import { RetirementBenefitHistory } from '../../services/models';
import { CalculationBenefitHistoryStatus } from '../../types/enums';

@Injectable()
export class BenefitOverviewComponentService {
  constructor(private readonly datePipe: DatePipe) {}

  getBenefitCalculationHistories(benefitHistories: RetirementBenefitHistory[] = []): RetirementBenefitHistory[] {
    if (!benefitHistories.length) {
      return [];
    }

    return deepClone(benefitHistories).map((item: RetirementBenefitHistory) => {
      let displayStatus = '';
      if (typeof item.status === 'number') {
        displayStatus = item.updatedDate
          ? `${CalculationBenefitHistoryStatus[item.status]} (${this.datePipe.transform(
              getDateString(item.updatedDate),
              'MM/dd/yyyy',
            )})`
          : CalculationBenefitHistoryStatus[item.status];
      }

      return {
        ...item,
        initiateDate: getDateString(item.initiateDate ?? ''),
        benefitOptionName: item?.benefitOptionName ?? '',
        calcAsOfDate: getDateString(item.calcAsOfDate ?? ''),
        benefitBeginDate: getDateString(item.benefitBeginDate ?? ''),
        benefitEndDate: getDateString(item.benefitEndDate ?? ''),
        displayStatus,
      };
    });
  }
}
