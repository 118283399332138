import { createAction, props } from '@ngrx/store';
import { ParametersQuery, TaxStatusList, PlanList, PlanDetail, ContributionPlanConfigurations, ContributionPlanConfiguration } from '../models/parameters.model';

export const TaxStatusRequest = '[TaxStatus/API] Send Request';
export const TaxStatusFailure = '[TaxStatus/API] Failure';
export const TaxStatusSuccess = '[TaxStatus/API] Success';

export const PlanRequest = '[Plan/API] Send Request';
export const PlanFailure = '[Plan/API] Failure';
export const PlanSuccess = '[Plan/API] Success';

export const PlanDetailRequest = '[PlanDetail/API] Send Request';
export const PlanDetailFailure = '[PlanDetail/API] Failure';
export const PlanDetailSuccess = '[PlanDetail/API] Success';

export const GetContributionPlanConfigurationsRequestAction = '[GetContributionPlanConfigurations/API] Send Request';
export const GetContributionPlanConfigurationsSuccessAction = '[GetContributionPlanConfigurations/API] Success';
export const GetContributionPlanConfigurationsFailureAction = '[GetContributionPlanConfigurations/API] Failure';
export const ClearGetContributionPlanConfigurationsStateAction = '[GetContributionPlanConfigurations] Clear';

export const SetContributionPlanConfigurationsRequestAction = '[SetContributionPlanConfigurations/API] Send Request';
export const SetContributionPlanConfigurationsSuccessAction = '[SetContributionPlanConfigurations/API] Success';
export const SetContributionPlanConfigurationsFailureAction = '[SetContributionPlanConfigurations/API] Failure';

export const getTaxStatusList = createAction(
  TaxStatusRequest,
  props<{ employerId: string, query: ParametersQuery }>()
);
export const getTaxStatusListSuccess = createAction(
  TaxStatusSuccess,
  props<{ taxStatusList: TaxStatusList }>()
);
export const getTaxStatusListFailure = createAction(
  TaxStatusFailure,
  props<{ error?: any }>()
);

export const getPlanList = createAction(
  PlanRequest,
  props<{employerId: string, query: ParametersQuery }>()
);
export const getPlanListSuccess = createAction(
  PlanSuccess,
  props<{ planList: PlanList }>()
);
export const getPlanListFailure = createAction(
  PlanFailure,
  props<{ error?: any }>()
);

export const getPlanDetail = createAction(
  PlanDetailRequest,
  props<{contributionPlanId: string }>()
);
export const getPlanDetailSuccess = createAction(
  PlanDetailSuccess,
  props<{ planDetail: PlanDetail }>()
);
export const getPlanDetailFailure = createAction(
  PlanDetailFailure,
  props<{ error?: any }>()
);

export const getContributionPlanConfigurations = createAction(
  GetContributionPlanConfigurationsRequestAction,
  props<{ employerId: string }>()
);

export const getContributionPlanConfigurationsSuccess = createAction(
  GetContributionPlanConfigurationsSuccessAction,
  props<{ response: ContributionPlanConfigurations }>()
);

export const getContributionPlanConfigurationsFailure = createAction(
  GetContributionPlanConfigurationsFailureAction,
  props<{ error?: any }>()
);

export const clearGetContributionPlanConfigurationsState = createAction(
  ClearGetContributionPlanConfigurationsStateAction
);

export const setContributionPlanConfigurations = createAction(
  SetContributionPlanConfigurationsRequestAction,
  props<{ employerId: string, contributionPlanConfigurations: ContributionPlanConfiguration[] }>()
);

export const setContributionPlanConfigurationsSuccess = createAction(
  SetContributionPlanConfigurationsSuccessAction
);

export const setContributionPlanConfigurationsFailure = createAction(
  SetContributionPlanConfigurationsFailureAction,
  props<{ error?: any }>()
);

