<div class="dialog-container">
  <div class="header-title">Add New View</div>
  <form class="edit-form" [formGroup]="editForm">
    <div class="input-container">
      <div class="flex gap-2 group-control">
        <ptg-textbox
          [controlField]="editForm.get('name')"
          placeholder="View Name"
          [hasLabel]="true"
          [maxLength]="100"
          errorAsync="View Name already exists."
        ></ptg-textbox>
        <ptg-select 
          [controlField]="editForm.get('type')" 
          [listData]="LIST_TYPE" 
          [isOptionObj]="true"
          placeholder="Type" 
          [isSetMaxWidthSelect]="true"
        ></ptg-select>
      </div>
      <ptg-select 
          [controlField]="editForm.get('entity')" 
          [listData]="listEntity" 
          [isOptionObj]="true"
          placeholder="Entity"
          [isSetMaxWidthSelect]="true"
        ></ptg-select>
    </div>

    <div class="row-button">
      <button
        mat-raised-button
        type="button"
        class="primary"
        (click)="formSubmit$.next(true)"
      >
        Create & Continue
      </button>
      <button
        mat-raised-button
        type="button"
        class="secondary"
        (click)="formSubmit$.next(false)"
      >
        Create & Add Another
      </button>
      <button
        mat-raised-button
        type="button"
        class="cancel-button"
        (click)="onCancel()"
      >
        Cancel
      </button>
    </div>
  </form>
</div>
