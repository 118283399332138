<div class="note-container" *ngIf="listAccidentNote.length > 0">
  <div *ngFor="let item of listAccidentNote">
    <div class="note">
      <div class="created-info">
        <div>
          <mat-icon>insert_drive_file</mat-icon>
          <div class="created-date">
            <span class="label-bold">Note</span>
            <span class="label-bold">{{item.dateCreated | date: 'MM/dd/yyyy h:mm a'}}</span>
          </div>
          <span class="label">{{item?.updatedBy ? 'Last Updated By: ' : 'Created By: '}}</span>
          <span class="value">{{item.createdBy}}</span>
        </div>
      </div>
      <div class="note-content">
        <pre [ngClass]="{'line-clamp-2': item.isTruncate}" ptgTruncateText>{{item.note}}</pre>
        <ng-container *ngIf="item.isTruncate; else noTruncate">
          <span (click)="item.isTruncate = false">See more</span>
        </ng-container>
        <ng-template #noTruncate>
          <span (click)="item.isTruncate = true">See less</span>
        </ng-template>
      </div>
    </div>
  </div>
    <ptg-pagination
      #paginator
      [totalRecords]="lengthNotePg"
      (pageChange)="changeNotePaging($event)"
      [pageSize]="pageNoteSize"
      [pageNumber]="pageNoteIndex"
      [hiddenPageSizeOptions]="true"
    ></ptg-pagination>
</div>
