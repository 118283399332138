import { Injectable } from '@angular/core';
import { ExceptionOption } from '@ptg-member/constants';
import { deepClone } from '@ptg-shared/utils/common.util';
import { Option } from '@ptg-shared/controls/select/select.component';

import {
  Entity,
  SystemData,
  BenefitDetail,
  BenefitOption,
  OutputMapping,
  AdditionalInfo,
  EntityProperty,
  PropertyDetail,
  LookupInformation,
  ExceptionConfigurationDetailResponse,
} from '../../services/models/exception-configuration.model';
import { ExceptionType, MonthYearUnit, ExceptionDataSource } from '../../types/enums';
import { EntityPropertyType } from '@ptg-entity-management/types/enums';

@Injectable()
export class EditExceptionConfigurationComponentService {
  // Exceptions don't have [ALL] option in [Benefit Option] dropdown list
  private exceptionsHaveNoAllOption = [
    ExceptionType.MemberMinAgeNotMet, // 2
    ExceptionType.BenefitEffectiveBeforeServiceTerminated, // 8
    ExceptionType.BenefitEffectiveMoreThan6MonthsFuture, // 9
    ExceptionType.MemberDeceasedBeforeBenefitEffective, // 10
    ExceptionType.EffectiveBasePensionAmountNotFound, // 11
    ExceptionType.EffectiveAgeReductionNotFound, // 12
    ExceptionType.EffectiveBasePaidAmountNotFound, // 13
    ExceptionType.EffectivePensionPaymentAmountNotFound, // 14
    ExceptionType.EffectiveMinMonthlyPensionAmountNotFound, // 15
    ExceptionType.EffectiveLumpSumSettlementFactorNotFound, // 16
    ExceptionType.EffectiveJointSurvivorFactorNotFound, // 17
    ExceptionType.EffectiveLongTermDisabilityMemberAmountNotFound, // 21
    ExceptionType.EffectiveLongTermDisabilitySpouseAmountNotFound, // 22
    ExceptionType.EffectiveLongTermDisabilityDependentChildAmountNotFound, // 23
    ExceptionType.EffectiveLODDDeathAmountNotFound, // 30
    ExceptionType.EffectiveLODDFuneralAmountNotFound, // 31
    ExceptionType.EffectiveLODDSurvivorAmountNotFound, // 34
    ExceptionType.EffectiveLODDChildAmountFound, // 35
    ExceptionType.DateValueValidation, // 37
  ];
  // Exceptions have [Benefit Begin Date] dropdown list
  exceptionsHaveBeginDate = [
    ExceptionType.BenefitEffectiveBeforeServiceTerminated, // 8
    ExceptionType.BenefitEffectiveMoreThan6MonthsFuture, // 9
    ExceptionType.MemberDeceasedBeforeBenefitEffective, // 10
    ExceptionType.EffectiveBasePensionAmountNotFound, // 11
    ExceptionType.EffectiveAgeReductionNotFound, // 12
    ExceptionType.EffectiveBasePaidAmountNotFound, // 13
    ExceptionType.EffectivePensionPaymentAmountNotFound, // 14
    ExceptionType.EffectiveMinMonthlyPensionAmountNotFound, // 15
    ExceptionType.EffectiveLumpSumSettlementFactorNotFound, // 16
    ExceptionType.EffectiveJointSurvivorFactorNotFound, // 17
    ExceptionType.EffectiveLODDDeathAmountNotFound, // 30
    ExceptionType.EffectiveLODDFuneralAmountNotFound, // 31
    ExceptionType.EffectiveLODDSurvivorAmountNotFound, // 34
    ExceptionType.EffectiveLODDChildAmountFound, // 35
  ];
  // Exceptions have [Entity List] dropdown list, [Entity Property] dropdown list
  exceptionsHaveEntityList = [
    ExceptionType.SpouseNotFound, // 5
    ExceptionType.MultipleSpousesFound, // 6
    ExceptionType.SpouseMissingDOB, // 7
    ExceptionType.EffectiveJointSurvivorFactorNotFound, // 17
    ExceptionType.SpouseIsDeceased, // 18
    ExceptionType.DifferentSpouseInRetirement, // 20
    ExceptionType.DependentChildMissingDOB, // 24
    ExceptionType.SurvivorNotFound, // 36
  ];
  // Exceptions have [Benefit Option Value] text-box
  exceptionsHaveBenefitOptionValue = [
    ExceptionType.MemberMinAgeNotMet, // 2
    ExceptionType.MemberMinServiceNotMet, // 3
    ExceptionType.MemberMinPensionPaymentsNotMet, // 4
  ];
  // Exceptions have [Editing Validation] toggler
  exceptionsHaveEditingValidationToggler = [
    ExceptionType.BenefitEffectiveBeforeServiceTerminated, // 8
    ExceptionType.BenefitEffectiveMoreThan6MonthsFuture, // 9
    ExceptionType.MemberDeceasedBeforeBenefitEffective, // 10
    ExceptionType.DateValueValidation, // 27
  ];

  getMappingDetailData(exceptionDetailData: ExceptionConfigurationDetailResponse) {
    const {
      exceptionType,
      exceptionDescription: description,
      savedInformation,
      systemData,
      benefitHasInProcessingCalculation,
      isPropertiesEditable,
      isEditingValidation,
      memberEntityData,
    } = exceptionDetailData;

    const savedBenefitDetailList: BenefitDetail[] = deepClone(savedInformation.benefitDetails) ?? [];
    const savedEntityDetailList: PropertyDetail[] = deepClone(savedInformation.propertyDetails) ?? [];
    const { benefitOptionList, entityList } = this.transformDataUponExceptionType(exceptionType, systemData);
    const filteredBenefitOptionList: Option[] = this.filterSelectedBenefitOption(
      benefitOptionList,
      savedBenefitDetailList,
      exceptionType,
    );
    const transformedMemberEntityData = this.getOutputMappingOptionData(memberEntityData, true);

    return {
      exceptionType,
      description,
      savedBenefitDetailList,
      savedEntityDetailList,
      benefitOptionList: filteredBenefitOptionList,
      entityList,
      benefitHasInProcessingCalculation,
      isPropertiesEditable,
      isEditingValidation,
      memberEntityData: transformedMemberEntityData,
    };
  }

  private transformDataUponExceptionType(
    exceptionType: number,
    { entities, benefitOptions, lookupInformation }: SystemData,
  ): { benefitOptionList: Option[]; entityList: Option[] } {
    let entityList: Entity[] = [];
    let benefitOptionList: Option[] = this.getMappingBenefitOptionData(benefitOptions);

    if (this.exceptionsHaveEntityList.includes(exceptionType)) {
      entityList = deepClone(entities);
    }

    if (this.exceptionsHaveNoAllOption.includes(exceptionType)) {
      benefitOptionList = benefitOptionList.filter((option) => option.value !== ExceptionOption.All);
    }

    // Transforms [Entity List] data to Option type
    const entityOptionList: Option[] = this.getMappingEntityOptionData(entityList, lookupInformation);

    return { benefitOptionList, entityList: entityOptionList };
  }

  private getMappingBenefitOptionData(benefitOptionList: BenefitOption[] = []): Option[] {
    return deepClone([
      { displayValue: 'All', value: ExceptionOption.All },
      ...benefitOptionList
        .map((bnfOption) => {
          bnfOption = {
            ...bnfOption,
            outputMapping: bnfOption.outputMapping?.length
              ? (this.getOutputMappingOptionData(bnfOption.outputMapping) as any[])
              : [],
          };
          return {
            displayValue: bnfOption.name,
            value: bnfOption,
          };
        })
        .sort((a: Option, b: Option) => (a.displayValue.toLowerCase() > b.displayValue.toLowerCase() ? 1 : -1)),
    ]);
  }

  private getOutputMappingOptionData(outputMapping: OutputMapping[], isEntity: boolean = false): Option[] {
    return deepClone(outputMapping)
      .map((item: OutputMapping) => {
        const displayValue = isEntity ? item.name?.split('|')[1] ?? '' : item.name;
        let valueDescription = '';
        if (isEntity) {
          valueDescription = item.name?.split('|')[2] ?? '';
        } else {
          valueDescription = typeof item?.source === 'number' ? ExceptionDataSource[item.source] : '';
        }
        return {
          displayValue,
          valueDescription,
          value: {
            name: item.name,
            value: item.id,
            type: null,
            displayValue,
            displaySubValue: valueDescription,
            source: item?.source,
          },
        };
      })
      .sort((a: Option, b: Option) => {
        if ((a.valueDescription?.toLowerCase() ?? '') > (b.valueDescription?.toLowerCase() ?? '')) {
          return 1;
        } else if ((a.valueDescription?.toLowerCase() ?? '') < (b.valueDescription?.toLowerCase() ?? '')) {
          return -1;
        }

        if (a.displayValue.toLowerCase() > b.displayValue.toLowerCase()) {
          return 1;
        } else if (a.displayValue.toLowerCase() < b.displayValue.toLowerCase()) {
          return -1;
        }
        return 0;
      });
  }

  private filterSelectedBenefitOption(
    benefitOptionList: Option[],
    savedBenefitDetailList: BenefitDetail[],
    exceptionType: ExceptionType,
  ): Option[] {
    const selectedBenefitIdList: string[] = savedBenefitDetailList
      .filter((id) => !!id)
      .map((item) => item.benefitId) as string[];

    return exceptionType === ExceptionType.DateValueValidation
      ? benefitOptionList
      : benefitOptionList.map((option) => {
          if (selectedBenefitIdList.includes(option.value?.id)) {
            option.isHide = true;
          }
          return option;
        });
  }

  private getMappingEntityOptionData(entityList: Entity[] = [], lookupInformation: LookupInformation[] = []): Option[] {
    if (!entityList.length) {
      return [];
    }

    return (entityList as any[])
      .map((entity) => {
        entity = {
          ...entity,
          properties: entity.properties
            .map((prop: EntityProperty) => {
              (prop.propertyLookupValues as any[]) = lookupInformation
                .filter((item) => item?.parentId === prop?.configs?.lookupTable)
                .map((item) => ({
                  displayValue: item?.text,
                  value: item,
                }))
                .sort((a: Option, b: Option) => (a.displayValue.toLowerCase() > b.displayValue.toLowerCase() ? 1 : -1));

              return {
                displayValue: prop.propertyName,
                value: prop,
              };
            })
            .sort((a: Option, b: Option) => (a.displayValue.toLowerCase() > b.displayValue.toLowerCase() ? 1 : -1)),
        };
        return {
          displayValue: entity.name,
          value: entity,
        };
      })
      .sort((a: Option, b: Option) => (a.displayValue.toLowerCase() > b.displayValue.toLowerCase() ? 1 : -1));
  }

  getAdditionalBenefitValueObj(exceptionType: number): AdditionalInfo | undefined {
    switch (exceptionType) {
      case ExceptionType.MemberMinAgeNotMet: // 2
        return {
          name: 'Age',
          type: EntityPropertyType['Whole Number'],
          value: '',
          placeholder: 'Member Minimum Age',
        };
      case ExceptionType.MemberMinServiceNotMet: // 3
        return {
          name: 'Service',
          type: EntityPropertyType['Whole Number'],
          value: '',
          placeholder: 'Member Minimum Service',
          unit: MonthYearUnit.Year,
        };
      case ExceptionType.MemberMinPensionPaymentsNotMet: // 4
        return {
          name: 'Min.Pension Payment',
          type: EntityPropertyType['Whole Number'],
          value: '',
          placeholder: 'Member Min. Pension Payment',
        };
      default:
        return;
    }
  }
}
