import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  GetMemberEntityListComponentsResponse,
} from './models';

@Injectable()
export class MemberEntityService {
  constructor(private httpClient: HttpClient) {}

  getMemberEntityListComponents(): Observable<GetMemberEntityListComponentsResponse> {
    return this.httpClient.get<GetMemberEntityListComponentsResponse>(
      `${environment.apiUrl}/entity/entities/member-entity-list-components`
    );
  }
}
