import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BaseComponent } from '@ptg-shared/components';
import * as fromReducer from '@ptg-reducers';
import * as fromMember from '../../store/reducers';
import { FIRST_PAGE } from '@ptg-shared/controls/pagination';
import * as TotalServicesAction from '../../store/actions/total-services.actions';
import { takeUntil } from 'rxjs/operators';
import { TotalServicesReponse } from '@ptg-member/types/models/total-services.model';

@Component({
  selector: 'ptg-total-service-entity',
  templateUrl: './total-service-entity.component.html',
  styleUrls: ['./total-service-entity.component.scss']
})
export class TotalServiceEntityComponent extends BaseComponent {
  @Input() memberId!: string;
  totalServiceData!: TotalServicesReponse;
  pageSize: number = 50;
  pageIndex: number = FIRST_PAGE;
  cardTitle: string = '';

  constructor(
    public route: ActivatedRoute,
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>
  ) {
    super();
   }

  ngOnInit(): void {
    this.getCardTitle();
    this.getData();
    this.getTotalServicesSelector();
  }

  getTotalServicesSelector() {
    this.memberStore
    .pipe(
      select(fromMember.selectTotalServicesState),
      takeUntil(this.unsubscribe$)
    )
    .subscribe((state) => {
      if (state) {
        this.totalServiceData = state;
      }
    });
  }

  getCardTitle() {
    this.store.pipe(select(fromMember.selectEntityViewItems), takeUntil(this.unsubscribe$)).subscribe((el) => {
      const totalServiceEntity = el?.payload?.entityViewItems.find((x) => x.listLabel === 'Totals');
      this.cardTitle = totalServiceEntity && totalServiceEntity.cardName ? totalServiceEntity?.cardName : 'Totals';
    });
  }

  getData() {
    const query = {
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
    }
    this.memberStore.dispatch(TotalServicesAction.getTotalServices({query: query, memberId: this.memberId}))
  }

}
