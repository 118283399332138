<div class="list-attachment-display" >
  <div class="list-attachment-header">
    Card Attachments
    <div class="space-line"></div>
    <div class="edit-btn" (click)="upLoadAttachment()" [ngClass]="{'disabled': isLoading}">
      <mat-icon svgIcon="upload-attachment-icon" style="width: 16px; height: 16px;"></mat-icon>
      <span class="title-edit">Upload Document</span>
    </div>
  </div>
  <ptg-grid
    [data]="memberAttachmentListsData"
    [columns]="columns"
    keyColumn="id"
    [isLoading]="isLoading"
    [errorMessage]="errorMsg"
    [totalRecords]="lengthPg"
    [pageSize]="pageSize"
    [pageNumber]="pageNumber"
    (pageChange)="changePagging($event)"
    (sortChange)="sortChange($event)"
    notFoundMessage="No Attachments to Display"
  >
    <ng-template cellContent columnName="fileName" let-row>
      <a style="color: -webkit-link; cursor: pointer; text-decoration: underline;"
      (click)="openPDF(row)" target="_blank">{{ row.fileName }}</a>
    </ng-template>
   

    <ng-template cellContent columnName="action" let-row>
      <ptg-button
        *ngIf="row.canEdit"
        buttonName="Edit"
        classInput="edit-button"
        (clickButton)="editAttachment(row)"
      >
        <mat-icon>edit</mat-icon>
      </ptg-button>
      <ptg-button
        *ngIf="row.canRemove"
        buttonName="Remove"
        classInput="remove-button"
        (clickButton)="removeAttachment(row)"
      >
        <mat-icon>delete_forever</mat-icon>
      </ptg-button>
    </ng-template>
  </ptg-grid>
</div>
