import { createReducer, on } from '@ngrx/store';

import { FIRST_PAGE } from '@ptg-shared/controls/pagination';
import * as EmployerNavigationActions from '../actions/employer-navigation';
import { EmployerNavigationState } from '../models/employer-detail.model';


export const employerNavigationFeatureKey = 'employerNavigation';

export interface State {
  employerNavigateState: EmployerNavigationState
}

const initialState: State = {
  employerNavigateState: {
    employers: [],
    employerIndex: -1,
    totalPage: 0,
    startPage: 0,
    endPage: 0,
  }
};

export const reducer = createReducer(
  initialState,
  on(EmployerNavigationActions.setEmployerIndex, (state, { employerIndex }) => ({
    ...state,
    employerNavigateState: {
      ...state.employerNavigateState,
      employerIndex
    }
  })),
  on(EmployerNavigationActions.getMoreEmployers, (state, { query }) => ({
    ...state,
    employerNavigateState: {
      ...state.employerNavigateState,
      query
    }
  })),
  on(EmployerNavigationActions.getMoreEmployersSuccess, (state, { employerList, isAppend }) => {
    const page = state.employerNavigateState.query?.pageIndex || FIRST_PAGE;
    let startPage = state.employerNavigateState.startPage;
    let endPage = state.employerNavigateState.endPage;
    if (!startPage || startPage > page) {
      startPage = page;
    }
    if (!endPage || endPage < page) {
      endPage = page;
    }
    const employerIndex = state.employerNavigateState.employers.findIndex(employer => employer?.id === state.employerNavigateState.employers?.[state.employerNavigateState.employerIndex]?.id);
    return {
      ...state,
      isLoading: true,
      employerNavigateState: {
        ...state.employerNavigateState,
        startPage: startPage,
        endPage: endPage,
        employers: isAppend ? [...state.employerNavigateState.employers, ...(employerList.employers || [])] : [...(employerList.employers || []), ...state.employerNavigateState.employers],
        employerIndex
      }
    };
  }),
  on(EmployerNavigationActions.setQueryEmployer, (state, { query }) => {
    const page = state.employerNavigateState.query?.pageIndex || FIRST_PAGE;
    let startPage = state.employerNavigateState.startPage;
    let endPage = state.employerNavigateState.endPage;
    if (!startPage || startPage > page) {
      startPage = page;
    }
    if (!endPage || endPage < page) {
      endPage = page;
    }
    return {
      ...state,
      employerNavigateState: {
        ...state.employerNavigateState,
        query,
        startPage,
        endPage
      }
    };
  }),
  on(EmployerNavigationActions.setEmployers, (state, { employerList, pageSize }) => {
    const employerIndex = state.employerNavigateState.employers.findIndex(employer => employer?.id === state.employerNavigateState.employers[state.employerNavigateState.employerIndex]?.id);
    const totalPage = Math.ceil(employerList.total / pageSize);
    return {
      ...state,
      employerNavigateState: {
        ...state.employerNavigateState,
        employers: employerList.employers,
        totalPage,
        employerIndex
      }
    };
  }),
  on(EmployerNavigationActions.clearEmployerNavigationState, (state) => {
    return {
      ...state,
      employerNavigateState: {
        employers: [],
        employerIndex: -1,
        totalPage: 0,
        startPage: 0,
        endPage: 0,
      }
    };
  })
);
