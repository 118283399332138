import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '@ptg-shared/components';
import { ACTION, STATE } from '@ptg-shared/constance/value.const';
import { showBanner } from '@ptg-shared/utils/common.util';
import { ACTION_COLUMN, Column, Row } from '@ptg-shared/controls/grid';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { DisplayedTabName } from '@ptg-shared/layout/constance/layout.const';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { SUBMODULE_KEY } from '@ptg-shared/constance/permission.const';
import * as fromReducer from '@ptg-reducers';

import { EditLookupTableValueComponent } from '../../components/edit-lookup-table-value/edit-lookup-table-value.component';
import { EditLookupTableComponent } from '../../components/edit-lookup-table/edit-lookup-table.component';
import { ReplaceLookupTableValueComponent } from '../../components/replace-lookup-table-value/replace-lookup-table-value.component';
import * as LookupTableActions from '../../store/actions/lookup-table.actions';
import * as fromMember from '../../store/reducers';
import {
  LookupTableDetail,
  MetadataLookupTableOption,
} from '@ptg-member/types/models';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { LookupTableService } from '@ptg-member/services/lookup-table.service';

@Component({
  selector: 'ptg-lookup-table-detail',
  templateUrl: './lookup-table-detail.component.html',
  styleUrls: ['./lookup-table-detail.component.scss'],
})
export class LookupTableDetailComponent extends BaseComponent {
  bannerType: BannerType = BannerType.Hidden;
  message = '';
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Participant List',
      moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
      url: '/member',
    },
    {
      name: 'Lookup Tables',
      url: '',
    },
  ];
  columns: Column[] = [
    {
      name: 'code',
      header: {
        title: 'ID',
      },
      truncate: true,
    },
    {
      name: 'description',
      header: {
        title: 'Short Description',
      },
      truncate: true,
    },
    {
      name: 'longDescription',
      header: {
        title: 'Long Description',
      },
      truncate: true,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      width: '230px',
    },
  ];
  lookupTableDetailsData!: (MetadataLookupTableOption & Row)[];
  lookupTableDetail!: LookupTableDetail;
  errorMsg?: string;
  isLoading: boolean = true;

  constructor(
    private memberStore: Store<fromMember.MemberState>,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    private store: Store<fromReducer.State>,
    private lookupTableService: LookupTableService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store.dispatch(LayoutActions.hiddenSideMenu());
    this.store.dispatch(
      LayoutActions.selectTab({ tab: DisplayedTabName.Participants })
    );
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.getDetail(params.id);
    });
    this.memberStore
      .select(fromMember.selectLookupTableDetailState)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        this.lookupTableDetail = state.lookupTableDetail;
        this.listBreadcrumbs = [
          {
            name: 'Participant List',
            moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
            url: '/member',
          },
          {
            name: 'Lookup Tables',
            url: '/member/lookup-table',
          },
          {
            name: state.lookupTableDetail.name,
            url: '',
          },
        ];
        this.isLoading = state.isLoading;
        this.lookupTableDetailsData =
          state.lookupTableDetail.metadataLookupTableOptions || [];

        // Get an error message (if any)
        if (state.error) {
          this.errorMsg = state.error.statusText;
        }
      });

    this.memberStore
      .select(fromMember.selectEditLookupTableState)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        showBanner.call(this, state, 'Table', ACTION.EDIT);
        this.memberStore.dispatch(LookupTableActions.clearLookupTableState());
        if (state === STATE.SUCCESS) {
          this.getDetail(this.lookupTableDetail.id);
        }
      });
    this.memberStore
      .select(fromMember.selectCreateLookupTableValueState)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        showBanner.call(this, state || '', 'Value', ACTION.ADD);
        this.memberStore.dispatch(LookupTableActions.clearLookupTableState());
        if (state === STATE.SUCCESS) {
          this.getDetail(this.lookupTableDetail.id);
        }
      });
    this.memberStore
      .select(fromMember.selectEditLookupTableValueState)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        showBanner.call(this, state || '', 'Value', ACTION.EDIT);
        this.memberStore.dispatch(LookupTableActions.clearLookupTableState());
        if (state === STATE.SUCCESS) {
          this.getDetail(this.lookupTableDetail.id);
        }
      });
    this.memberStore
      .select(fromMember.selectRemoveLookupTableValueState)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        showBanner.call(this, state || '', 'Value', ACTION.REMOVE);
        this.memberStore.dispatch(LookupTableActions.clearLookupTableState());
        if (state === STATE.SUCCESS) {
          this.getDetail(this.lookupTableDetail.id);
        }
      });
  }

  getDetail(id: string) {
    if (!id) {
      return;
    }
    this.memberStore.dispatch(LookupTableActions.getLookupTableDetail({ id }));
  }

  editLookupTable() {
    if (!this.lookupTableDetail?.id) {
      return;
    }
    this.dialog.open(EditLookupTableComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        ...this.lookupTableDetail,
        disabled: !this.lookupTableDetail.active,
      },
    });
  }

  editValue(lookupTableValue?: MetadataLookupTableOption & Row) {
    this.dialog.open(EditLookupTableValueComponent, {
      panelClass: ['edit-popup', 'edit-lookup-table-value-dialog'],
      disableClose: true,
      height: 'auto',
      autoFocus: false,
      data: {
        lookupTableValue,
        loopUpTableId: this.lookupTableDetail.id,
      },
    });
  }

  removeRecord(lookupTableValue: MetadataLookupTableOption & Row) {
    const dialogRef = this.dialog.open(ReplaceLookupTableValueComponent, {
      panelClass: ['edit-popup', 'replace-lookup-table-value-dialog'],
      disableClose: true,
      height: 'auto',
      autoFocus: false,
      data: {
        lookupTableValues: this.lookupTableDetailsData,
        lookUpValueId: lookupTableValue.id,
        lookupValueDescription: lookupTableValue.description,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.memberStore.dispatch(
          LookupTableActions.removeLookupTableValue({ body: { ...result } })
        );
      }
    });
  }

  onSelectedTabChange(event: MatTabChangeEvent) {
    if(event.index === 1) {
      this.memberStore.dispatch(LookupTableActions.getDisplayFormatList({ id: this.lookupTableDetail.id }));
    }
  }
}
