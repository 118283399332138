import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SortType } from '@ptg-shared/constance/value.const';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';
import { environment } from 'src/environments/environment';

import {
  LumpSumPaymentHistoryList,
  LumpSumPaymentHistoryQuery,
  LumpSumPaymentList,
} from './models';

@Injectable()
export class LumpSumPaymentHistoryService {
  constructor(private httpClient: HttpClient) {}

  getLumpSumPaymentList(): Observable<LumpSumPaymentList> {
    return this.httpClient.get<LumpSumPaymentList>(
      `${environment.apiUrl}/Payroll/OffCycles/PaymentDetails`
    );
  }

  getLumpSumPaymentHistoryList(
    query: LumpSumPaymentHistoryQuery
  ): Observable<LumpSumPaymentHistoryList> {
    let params = new HttpParams();
    params = params.append('PageSize', query.pageSize || 10);
    params = params.append('PageIndex', query.pageIndex || 1);

    if (query.sortNames) {
      params = params.append(
        'SortNames',
        capitalizeFirstLetter(query.sortNames)
      );
      params = params.append('SortType', query.sortType || SortType.ASC);
    }
    return this.httpClient.get<LumpSumPaymentHistoryList>(
      `${environment.apiUrl}/Payroll/OffCycles/PaymentDetailsHistory`,
      { params }
    );
  }
}
