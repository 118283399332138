import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { ConfirmType } from '../constance/confirm-type.const';
import { CANCEL_CONFIRM_MESSAGE } from '@ptg-shared/constance';
import { ConfirmPopupComponent, DialogData } from '../controls/confirm-popup/confirm-popup.component';

@Injectable({
  providedIn: 'root',
})
export class SwitchConfirmPopupService {
  constructor(public dialog: MatDialog) {}

  public cancelConfirm(
    parentDialogRef: MatDialogRef<any>,
    title?: string,
    text?: string,
    id?: string,
    store?: any,
    action?: any,
  ): Observable<string> {
    const parentPopup = document.getElementById(parentDialogRef.id);
    parentPopup?.classList?.add('hidden-popup');
    const dialogRefConfirm = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: { text: text ? text : CANCEL_CONFIRM_MESSAGE, type: ConfirmType.CancelPopup, title: title },
    });
    const subject = new Subject<string>();
    dialogRefConfirm.afterClosed().subscribe((result: any) => {
      if (result) {
        if (id) {
          document.getElementById(id)?.classList?.remove('hidden-popup');
        }
        if (store && action) {
          store.dispatch(action);
        }
        parentDialogRef.close();
      } else {
        parentPopup?.classList?.remove('hidden-popup');
      }
      subject.next(result);
    });
    return subject.asObservable();
  }

  public attentionConfirm(
    parentDialogRef: MatDialogRef<any>,
    title: string,
    text: string,
    store?: any,
    action?: any,
  ): Observable<string> {
    const parentPopup = document.getElementById(parentDialogRef.id);
    parentPopup?.classList?.add('hidden-popup');
    const dialogRefConfirm = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: { text: text, type: ConfirmType.Attention, title: title, cancelButtonTitle: 'Back' },
    });
    const subject = new Subject<string>();
    dialogRefConfirm.afterClosed().subscribe((result: any) => {
      if (result) {
        if (store && action) {
          store.dispatch(action);
        }
        parentDialogRef.close();
      } else {
        parentPopup?.classList?.remove('hidden-popup');
      }
      subject.next(result);
    });
    return subject.asObservable();
  }

  public confirmDialog(parentDialogRef: MatDialogRef<any>, data: DialogData, callback?: () => void): Observable<string> {
    const parentPopup = document.getElementById(parentDialogRef.id);
    parentPopup?.classList?.add('hidden-popup');
    const dialogRefConfirm = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data,
    });
    const subject = new Subject<string>();
    dialogRefConfirm.afterClosed().subscribe((result: any) => {
      if (result) {
        if (callback) {
          callback();
        }
        parentDialogRef.close();
      } else {
        parentPopup?.classList?.remove('hidden-popup');
      }
      subject.next(result);
    });
    return subject.asObservable();
  }
}
