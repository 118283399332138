import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BaseComponent } from '@ptg-shared/components';
import { Option } from '@ptg-shared/controls/select/select.component';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { deepClone } from '@ptg-shared/utils/common.util';
import { Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { StepConfigurationService } from '../../services';
import { CreateStepConfigurationsRequest } from '../../services/models';
import {
  CalculationState,
  createStepConfigurationsAction,
  getBenefitTypesAction,
  getBenefitTypesSelector,
} from '../../store';
import { BenefitRequestType, CalculationType } from '../../types/enums';

@Component({
  selector: 'ptg-add-step-configuration',
  templateUrl: './add-step-configuration.component.html',
  styleUrls: ['./add-step-configuration.component.scss'],
})
export class AddStepConfigurationComponent extends BaseComponent {
  editForm: FormGroup = this.initFormGroup();
  formSubmit$ = new Subject<boolean>();
  benefitOptions: Option[] = [];
  listSubType: Option[] = [];
  memberId: string = '';

  get benefitOptionControl(): FormControl {
    return this.editForm?.get('benefitEntityId') as FormControl;
  }

  constructor(
    public dialogRef: MatDialogRef<AddStepConfigurationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public switchConfirmPopupService: SwitchConfirmPopupService,
    public router: Router,
    public calculationStore: Store<CalculationState>,
    private stepConfigurationService: StepConfigurationService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getBenefitOptions();
    this.submitForm();
  }

  initFormGroup() {
    return this.fb.group({
      benefitEntityId: this.fb.control(null, {
        validators: [Validators.required],
        asyncValidators: this.stepConfigurationService.validateStepConfigExists(
          this.benefitOptions,
          '',
          false
        ),
      }),
    });
  }

  getBenefitOptions() {
    this.calculationStore
      .pipe(select(getBenefitTypesSelector), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        this.benefitOptions = deepClone(state?.payload ?? [])?.map((item) => {
          return {
            value: item.id,
            displayValue: item.name,
          };
        });
        this.editForm = this.initFormGroup();
      });
    this.calculationStore.dispatch(
      getBenefitTypesAction({ request: { type: BenefitRequestType.UploadCalculationFile } }),
    );
  }

  submitForm() {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
        }),
        debounceTime(500),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1),
          ),
        ),
        filter((status) => status === AbstractControlStatus.VALID),
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  onSubmit() {
    const body = deepClone(
      this.editForm.getRawValue(),
    ) as CreateStepConfigurationsRequest;
    body.calculationType = CalculationType.RetirementBenefit;
    this.calculationStore.dispatch(
      createStepConfigurationsAction({ request: body, isContinue: true }),
    );
    this.dialogRef.close();
  }

  onCancel() {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }
}
