import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { SortType } from '@ptg-shared/constance';

import { CreateCalculationLoddBenefitsRequest, LoddValidationBeforeRecalculateRequest, LoddValidationBeforeRecalculateResponse } from './models';

@Injectable()
export class CalculationLoddBenefitService {
  constructor(private httpClient: HttpClient) {}

  createCalculationLoddBenefits(request: CreateCalculationLoddBenefitsRequest) {
    const { memberId, body } = request;

    return this.httpClient.post(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-lodd-benefit/initialization`,
      body ?? {},
    );
  }

  
  getLoddValidationBeforeRecalculates(
    request: LoddValidationBeforeRecalculateRequest
  ): Observable<LoddValidationBeforeRecalculateResponse> {
    const { memberId, calculationType } = request
    return this.httpClient.get<LoddValidationBeforeRecalculateResponse>(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-lodd-benefit/${calculationType}/validation-before-recalculate`,
      {}
    );
  }

}
