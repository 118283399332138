<div class="status-history-container" id="status-history-container">
  <ptg-title-bar
    [name]="data.statusHistory ? 'Edit' : 'Add New'"
    [viewName]="data.viewName"
    [onlyTitle]="true"
  ></ptg-title-bar>

  <ptg-overview-header-entity
    [targetIdFromDialog]="data?.memberId">
  </ptg-overview-header-entity>

  <form [formGroup]="editForm">
    <div class="wrap-btn">
      <button mat-raised-button type="submit" class="submit-button" (click)="onSubmit()">Save</button>
      <button mat-raised-button type="button" class="cancel-button" (click)="onCancel()">Cancel</button>
    </div>
    <div class="form-control-container">
      <ptg-datepicker
        placeholder="Event Date"
        class="input-field"
        [controlField]="editForm.get('statusDate')"
        [maxDate]="maxDate"
        [errorMaxDate]="'Event Date must not be later than ' + (maxDate | date: 'MM/dd/yyyy') + '.'"
        [isRequired]="true"
      ></ptg-datepicker>
      <ptg-select
        placeholder="Status"
        class="input-field"
        [controlField]="editForm.get('status')"
        [listData]="listOptionStatus"
        errorRequire="Status is required."
        [isOptionObj]="true"
        [isSetMaxWidthSelect]="true"
        (changeOptionValue)="onChangeStatusValue()"
      ></ptg-select>
      <ptg-select
        placeholder="Event"
        class="input-field"
        [controlField]="editForm.get('statusEvent')"
        [listData]="listOptionEvent"
        errorRequire="Event is required."
        [isOptionObj]="true"
        [isSetMaxWidthSelect]="true"
      ></ptg-select>
    </div>
  </form>
</div>




