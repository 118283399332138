import { createAction, props } from '@ngrx/store';

import {
  GetMemberEntityListComponentsResponse,
} from '../../services/models';

export const getMemberEntityListComponentsAction = createAction(
  '[GetMemberEntityListComponents/API] Send Request'
);

export const getMemberEntityListComponentsSuccessAction = createAction(
  '[GetMemberEntityListComponents/API] Success',
  props<{ response: GetMemberEntityListComponentsResponse }>()
);

export const getMemberEntityListComponentsFailureAction = createAction(
  '[GetMemberEntityListComponents/API] Failure',
  props<{ error?: any }>()
);

export const clearGetMemberEntityListComponentsStateAction = createAction(
  '[GetMemberEntityListComponents] Clear'
);
