import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  checkExceptionConfigurationAction,
  checkExceptionConfigurationFailureAction,
  checkExceptionConfigurationSuccessAction,
  getExceptionListAction,
  getExceptionListFailureAction,
  getExceptionListSuccessAction,
  getRetirementBenefitDetailDocumentsAction,
  getRetirementBenefitDetailDocumentsFailureAction,
  getRetirementBenefitDetailDocumentsSuccessAction,
  getCalculationAuditTrailAction,
  getCalculationAuditTrailFailureAction,
  getCalculationAuditTrailSuccessAction,
} from '../actions';

import { RetirementBenefitDetailDocumentService } from '../../services';
import { RetirementBenefitService } from '../../services/retirement-benefit.service';
import {
  GetExceptionResponse,
  GetCalculationAuditTrailResponse,
  GetRetirementBenefitDetailDocumentsResponse,
} from '../../services/models';

@Injectable()
export class RetirementBenefitDetailDocumentEffect {
  constructor(
    private readonly actions$: Actions,
    private readonly retirementBenefitService: RetirementBenefitService,
    private readonly retirementBenefitDetailDocumentService: RetirementBenefitDetailDocumentService,
  ) {}

  getRetirementBenefitDetailDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRetirementBenefitDetailDocumentsAction),
      switchMap(({ request }) =>
        this.retirementBenefitDetailDocumentService.getRetirementBenefitDetailDocuments(request).pipe(
          map((response: GetRetirementBenefitDetailDocumentsResponse) =>
            getRetirementBenefitDetailDocumentsSuccessAction({ response }),
          ),
          catchError((error) => of(getRetirementBenefitDetailDocumentsFailureAction({ error }))),
        ),
      ),
    ),
  );

  getExceptionList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getExceptionListAction),
      switchMap(({ request }) =>
        this.retirementBenefitService.getExceptionList(request).pipe(
          map((response: GetExceptionResponse) => getExceptionListSuccessAction({ response })),
          catchError((error) => of(getExceptionListFailureAction({ error }))),
        ),
      ),
    ),
  );

  getCalculationAuditTrailList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCalculationAuditTrailAction),
      switchMap(({ request }) =>
        this.retirementBenefitService.getCalculationAuditTrailList(request).pipe(
          map((response: GetCalculationAuditTrailResponse) => getCalculationAuditTrailSuccessAction({ response })),
          catchError((error) => of(getCalculationAuditTrailFailureAction({ error }))),
        ),
      ),
    ),
  );

  checkExceptionConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkExceptionConfigurationAction),
      switchMap(({ request, buttonAction }) =>
        this.retirementBenefitService.checkExceptionConfiguration(request).pipe(
          map((response: boolean) => checkExceptionConfigurationSuccessAction({ response, buttonAction })),
          catchError((error) => of(checkExceptionConfigurationFailureAction({ error, buttonAction }))),
        ),
      ),
    ),
  );
}
