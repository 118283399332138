import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';
import { STATE } from '@ptg-shared/constance/value.const';
import * as TotalServicesAction from '../../store/actions/total-services.actions';
import { TotalServicesReponse } from '@ptg-member/types/models/total-services.model';

export const TotalServicesFeatureKey = 'totalServices';

export interface State {
  isLoading: boolean;
  totalServices?: TotalServicesReponse;
}

const initialState : State = {
  isLoading: true,
  totalServices: undefined
}

export const reducer = createReducer(
  initialState,
  on(TotalServicesAction.getTotalServices, (state, {}) => ({
    ...state,
    isLoading: true,
    totalServices: undefined,
  })),
  on(
    TotalServicesAction.getTotalServicesSuccess,
    (state, { totalServices }) => ({
      ...state,
      isLoading: false,
      totalServices: totalServices,
    })
  ),
  on(TotalServicesAction.getetTotalServicesFailure, (state) => ({
    ...state,
    isLoading: false,
    totalServices: undefined,
  })),
)
