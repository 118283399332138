import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as TotalServicesAction from '../../store/actions/total-services.actions';
import { TotalServiceService } from '@ptg-member/services/total-service.service';
import { TotalServicesReponse } from '@ptg-member/types/models/total-services.model';

@Injectable()

export class TotalServicesEffects {
  getStatusHistoryList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TotalServicesAction.getTotalServices),
      switchMap(({ query, memberId }) => {
        return this.totalServiceService.getTotalService(query, memberId).pipe(
          map((data: TotalServicesReponse) => {
            return TotalServicesAction.getTotalServicesSuccess({
              totalServices: data,
            });
          }),
          catchError((err) => {
            return of(TotalServicesAction.getetTotalServicesFailure());
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private totalServiceService: TotalServiceService
  ) {}
}
