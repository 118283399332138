import { OptionValue } from '@ptg-shared/types/models';

import { SectionLayout } from '../../constance/metadata.const';
import { ColumnIndex, DisplaySectionWODataType, SideType } from '../enums';
import { LookupTable } from './lookup-table.model';
import { MemberStatus } from './status.model';

export interface MetadataView {
  metadata: MetadataSection[];
  listStatus: MemberStatus[];
}

export interface MetadataSection {
  key: string;
  name: string;
  importKey: string | null;
  type: SectionLayout;
  hasAttachment: boolean;
  isStatic: boolean;
  onSide: SideType;
  properties: MetadataProperty[];
  aggregations: MetadataAggregation[];
  calculations: MetadataCalculation[];
  blankSpaces: MetadataBlankSpace[];
  lookupTables: LookupTable[];
  order: number;
  sectionTitle?: string | null;
  showSectionDataless: boolean;
  showPropertyDataless: boolean;
  isRemoved: boolean;
  hasData: boolean;
  isDisplay: boolean;
  isDragDrop?: boolean;
}

export interface MetadataSectionsNonListResponse {
  metadata: MetadataSection[];
}

export interface MetadataBlankSpace {
  id: string;
  itemKey: string;
  column: ColumnIndex;
  order: number;
  type: string;
}

export interface MetadataAggregation {
  id: string;
  aggregation: string;
  column: ColumnIndex;
  config: any;
  label: string;
  order: number;
  property: string;
  type: string;
}

export interface MetadataCalculation {
  id: string;
  aggregation: string;
  column: ColumnIndex;
  config: any;
  name: string;
  order: number;
  type: string;
  displayFormat: number;
  displayValue: string;
}
export interface MetadataProperty {
  key: string;
  name: string;
  importKey: string | null;
  type: string;
  config: any;
  options: any[];
  isStatic: boolean;
  isUseOverview?: boolean;
  value?: any;
  column?: ColumnIndex;
  order?: string;
  propValidations: any[];
}

export interface BodyChangeKey {
  upperAdjacentKey: string | null;
  reorderItemKey: string;
}
export interface CheckExist {
  clientKey?: string;
  itemKey?: string;
  sectionName?: string;
  sectionImportKey?: string;
  propertyName?: string;
  propertyImportKey?: string;
}

export interface BodyChangePropertyKey {
  upperAdjacentKey?: string | number;
  reorderPropertyKey?: string | number;
}

export interface PageContent {
  active: boolean;
  content: PageContentDetail[];
  header: string;
  id: string;
  importKey: string;
  navigation: string;
  order: number;
  template: string;
  total: number;
  values: Values;
}

export interface PageContentDetail {
  id: string;
  key: string;
  name: string;
  type: string;
  value: string;
}

export interface Values {
  [name: string]: Value;
}

export interface Value {
  [name: string]: string;
}

export interface PropertyRequest {
  Name: string;
  Type: string;
  Unique?: boolean;
  Encrypted?: boolean;
  lookupTableId?: string;
  Aggregation?: string;
}

export interface PropertyEditData {
  config?: PropertyConfig;
  importKey?: string;
  isStatic?: boolean;
  key?: string;
  name?: string;
  options?: OptionValue[];
  type?: string;
  isEdit?: boolean;
  itemKey?: string;
}

export interface PropertyConfig {
  includeInBulkUpdate?: string;
  inclusiveInRange?: string;
  maxInput?: string;
  minInput?: string;
  readOnly?: string;
  required?: string;
  maximumLength?: string;
  maxLengthInput?: string;
  fractionalLength?: string;
  fractionalLengthInput?: string;
  smsEnabled?: string;
  prefixList?: string;
  listValues?: string;
  boolean?: string;
  affirmative?: string;
  negative?: string;
  lookupTable?: string;
  unique?: string;
  encrypted?: string;
  masked?: string;
  initiated?: string;
  usedStateLookupTable?: string;
  stateLookupTable?: string;
  usedCountryLookupTable?: string;
  countryLookupTable?: string;
  defaultStateLookupTable?: string;
  defaultCountryLookupTable?: string;
  defaultTitleLookupTable?: string;
  excludeFutureDates?: string;
  excludePastDates?: string;
  validation?: string;
  dateValidationExpressions?: string;
}

export interface KeyValueConfig {
  key: string;
  value: string;
}

export interface AddSectionBody {
  name: string;
  type: SectionLayout;
}

export interface ReorderSectionRequestModel {
  moveFromIndex: number;
  moveToIndex: number;
}

export interface SectionOverviewModel {
  metaDataPropertyValues: any;
  sectionData: MetadataSection;
}

export interface MetadataPropertyType {
  displayValue: string;
  value: number;
}

export interface MetadataPropertyTypeView {
  propertyTypes: MetadataPropertyType[];
}

export interface MetadataOverview {
  metadataItems: MetadataSection[];
  listStatus: MemberStatus[];
  profileOverviewName: string;
  metadataOverview: MetadataOverView;
}

export interface PropertyNavType {
  navType: PropertyTypeNavigation;
}

export enum PropertyTypeNavigation {
  Metadata = 0,
  Employer = 1,
}

export enum CardType {
  CardLevel = 1,
  ListRecord = 2
}

export interface MetadataOverView {
  clientKey: string;
  displayListSectionWOData: DisplaySectionWODataType;
  displayNonListSectionWOData: DisplaySectionWODataType;
  isStatic: boolean;
  statuses: string;
}
export interface DateValidationExpression {
  validation?: number;
  validationType?: number;
  value?: string;
}
