import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { OverviewHeaderEntityModule } from '@ptg-member/components/overview-header-entity/overview-header-entity.module';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { CalculationRoutingModule } from './calculation-routing.module';
import { SharedModule } from '@ptg-shared/shared.module';
import { ControlsModule } from '@ptg-shared/controls/controls.module';
import {
  RetirementBenefitCalculationDetailComponent,
  RetirementBenefitCalculationListComponent,
  RetirementBenefitInputOutputListComponent,
  StepConfigurationComponent,
  ExceptionConfigurationComponent,
} from './pages';
import { CalculationEffects } from './store/effects';
import { calculationReducers } from './store/reducers';
import { MODULE_KEY } from './constants';
import {
  AddRetirementBenefitInputOutputComponent,
  AddStepConfigurationComponent,
  EditStepConfigurationComponent,
  RetirementBenefitCalculationDetailHeaderComponent,
  RetirementBenefitCalculationDetailParameterComponent,
  StepConfigurationListStepComponent,
  StepConfigurationNonlistStepComponent,
  UploadCalculationDialogComponent,
  AddParameterMappingComponent,
  EditCalculationParameterComponent,
  EditExceptionConfigurationComponent,
} from './components';
import { CalculationParameterConfigurationComponent } from '@ptg-member/features/calculation/pages/calculation-parameter-configuration/calculation-parameter-configuration.component';
import { AddParameterConfigurationComponent } from './components/add-parameter-configuration/add-parameter-configuration.component';
import { BenefitCalculationConfigurationComponent } from './pages/benefit-calculation-configuration/benefit-calculation-configuration.component';
import { AddOutputConfigurationComponent } from './components/add-output-configuration/add-output-configuration.component';
import { RetirementBenefitDialogComponent } from './components/retirement-benefit-dialog/retirement-benefit-dialog.component';
import { RetirementBenefitDialogService } from './services/retirement-benefit-dialog.service';
import { RetirementBenefitUploadDocumentService } from './services/retirement-benefit-upload-document.service';
import { RetirementBenefitDetailComponent } from './pages/retirement-benefit-detail/retirement-benefit-detail.component';
import {
  BenefitRefundService,
  CheckRetirementDocumentCanRemoveService,
  GetEntityComponentListDataByComponentIdService,
  MemberEntityService,
  RemoveCalculationDocumentDetailService,
  RemoveOverviewDocumentService,
  RetirementBenefitDetailDocumentService,
  RetirementBenefitDetailUploadDocumentService,
  BenefitDetailComponentService,
  CalculationLoddBenefitService,
  CalculationQDROService,
  RetirementBenefitService,
} from './services';
import { RetirementBenefitDetailUploadDocumentComponent } from './components/retirement-benefit-detail-upload-document/retirement-benefit-upload-document.component';
import { BenefitOverviewComponent } from './pages/benefit-overview/benefit-overview.component';
import { EditExceptionComponent } from '@ptg-member/components/edit-exception/edit-exception.component';
import { BeneficiaryConfirmationComponent } from './components/beneficiary-confirmation/beneficiary-confirmation.component';
import { AddDisabilityComponent } from '@ptg-member/features/calculation/components/add-disability/add-disability.component';
import { DisabilityBenefitDetailComponent } from './pages/disability-benefit-detail/disability-benefit-detail.component';
import { RefundsOverviewComponent } from './pages/refunds-overview/refunds-overview.component';
import { RefundsDetailComponent } from './pages/refunds-detail/refunds-detail.component';
import { RefundsCalculationDetailComponent } from './pages/refunds-calculation-detail/refunds-calculation-detail.component';
import { SubHeaderConfigurationComponent } from './pages/subheader-configuration/subheader-configuration.component';
import { EditSubHeaderConfigurationComponent } from './components/edit-subheader-configuration/edit-subheader-configuration.component';
import { EditSubHeaderComponent } from '@ptg-member/components/edit-subheader/edit-subheader.component';
import { RecalculateBenefitDialogComponent } from './components/recalculate-benefit/recalculate-benefit-dialog.component';
import { SubHeaderModule } from '@ptg-member/components/sub-header/sub-header.component.module';
import { DisabilityCalculationDetailComponent } from './pages/disability-calculation-detail/disability-calculation-detail.component';
import { InforBannerComponent } from './components/infor-banner/infor-banner.component';
import { EditPayeeInfoComponent } from './components/edit-payee-info/edit-payee-info.component';
import { ViewBeneficiaryCardComponent } from './components/view-benificiary-card/view-beneficiary-card.component';
import { BenefitOverviewComponentService } from './pages/benefit-overview/benefit-overview.component.service';
import { RetirementBenefitDialogComponentService } from './components/retirement-benefit-dialog/retirement-benefit-dialog.component.service';
import { LoddPayeeInformationTableComponent } from './components/lodd-payee-information-table/lodd-payee-information-table.component';
import { PreviewPdfFileDialogComponent } from './components/preview-pdf-file-dialog/preview-pdf-file-dialog.component';
import { RecalculateSurvivorBenefitDialogComponent } from './components/recalculate-survivor-benefit/recalculate-survivor-benefit-dialog.component';
import { RetirementBenefitQDROComponent } from './components/qdro-information/qdro-information.component';

const COMPONENTS = [
  StepConfigurationComponent,
  AddStepConfigurationComponent,
  EditStepConfigurationComponent,
  StepConfigurationNonlistStepComponent,
  StepConfigurationListStepComponent,
  RetirementBenefitCalculationListComponent,
  RetirementBenefitCalculationDetailComponent,
  UploadCalculationDialogComponent,
  RetirementBenefitCalculationDetailParameterComponent,
  RetirementBenefitCalculationDetailHeaderComponent,
  RetirementBenefitInputOutputListComponent,
  AddRetirementBenefitInputOutputComponent,
  AddParameterMappingComponent,
  CalculationParameterConfigurationComponent,
  AddParameterConfigurationComponent,
  BenefitCalculationConfigurationComponent,
  AddOutputConfigurationComponent,
  RetirementBenefitDialogComponent,
  RetirementBenefitDetailComponent,
  EditCalculationParameterComponent,
  ExceptionConfigurationComponent,
  EditExceptionConfigurationComponent,
  RetirementBenefitDetailUploadDocumentComponent,
  EditPayeeInfoComponent,
  BenefitOverviewComponent,
  EditExceptionComponent,
  BeneficiaryConfirmationComponent,
  AddDisabilityComponent,
  DisabilityBenefitDetailComponent,
  RefundsOverviewComponent,
  RefundsDetailComponent,
  RefundsCalculationDetailComponent,
  SubHeaderConfigurationComponent,
  EditSubHeaderConfigurationComponent,
  EditSubHeaderComponent,
  DisabilityCalculationDetailComponent,
  RecalculateBenefitDialogComponent,
  InforBannerComponent,
  ViewBeneficiaryCardComponent,
  LoddPayeeInformationTableComponent,
  PreviewPdfFileDialogComponent,
  RecalculateSurvivorBenefitDialogComponent,
  RetirementBenefitQDROComponent
];

const SERVICES = [
  RetirementBenefitDialogService,
  RetirementBenefitUploadDocumentService,
  RetirementBenefitDetailDocumentService,
  RetirementBenefitDetailUploadDocumentService,
  RemoveCalculationDocumentDetailService,
  CheckRetirementDocumentCanRemoveService,
  RemoveOverviewDocumentService,
  MemberEntityService,
  GetEntityComponentListDataByComponentIdService,
  BenefitRefundService,
  BenefitDetailComponentService,
  CalculationLoddBenefitService,
  BenefitOverviewComponentService,
  RetirementBenefitDialogComponentService,
  CalculationQDROService,
  RetirementBenefitService,
];

@NgModule({
  declarations: [...COMPONENTS],
  providers: [...SERVICES],
  imports: [
    SharedModule,
    ControlsModule,
    CalculationRoutingModule,
    StoreModule.forFeature(MODULE_KEY, calculationReducers),
    EffectsModule.forFeature(CalculationEffects),
    OverviewHeaderEntityModule,
    SubHeaderModule,
    NgxExtendedPdfViewerModule,
  ],
})
export class CalculationModule {}
