export interface AggregationList {
  items: [AggregationItem] | [] | any;
}

export interface AggregationItem {
  id: string;
  label: string;
  order: number;
  property: string;
  aggregation: string;
}

export interface AggregationPayload {
  name: string;
  propertyKey: string;
  aggregationType: number;
}
