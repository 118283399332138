import { createReducer, on } from '@ngrx/store';
import * as MemberPropertyActions from '@ptg-member/store/actions/member-property.actions';

export const memberPropertyFeatureKey = 'memberProperty';

export interface State {
    page?: string;
    memberId?: string;
    itemKey: string;
    propertyKey: string;
    index: string;
    value: string;
    isReloading: boolean;
}

const initialState: State = {
    page: '',
    memberId: '',
    itemKey: '',
    propertyKey: '',
    index: '',
    value: '',
    isReloading: false
};

export const reducer = createReducer(
  initialState,
  on(MemberPropertyActions.getPropetyDataByMaskedConfigRequest, (state) => ({
    ...state,
    isReloading: false
  })),
  on(MemberPropertyActions.getPropetyDataByMaskedConfigSuccess, (state, { value, query }) => ({
    ...state,
    isReloading: true,
    value,
    page: query.page,
    memberId: query.memberId,
    itemKey: query.itemKey,
    propertyKey: query.propertyKey,
    index: query.index,
  })),
  on(MemberPropertyActions.getPropetyDataByMaskedConfigFailure, (state) => ({
    ...state,
    isReloading: false
  })),
  on(MemberPropertyActions.clearReloadingState, (state) => ({
    ...state,
    isReloading: false
  })),
);
