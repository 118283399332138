import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FundType } from '@ptg-shared/types/enums';
import * as fromReducer from '@ptg-reducers/index';
import { chicagoParkDepositDate } from '@ptg-processing/constants';

import {
  OffCyclePaymentStatusType,
  PaymentInfoType,
} from '../../constance/next-payment.const';
import { OffCyclePayment, PaymentInforConfigObj } from '../../types/models';
import * as fromNextPayment from '../../store/reducers';
import * as NextPaymentActions from '../../store/actions/next-payment.actions';
import { EditEarningsComponent } from '../edit-earnings/edit-earnings.component';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';

@Component({
  selector: 'ptg-earning-detail',
  templateUrl: './earning-detail.component.html',
  styleUrls: ['./earning-detail.component.scss'],
})
export class EarningDetailComponent implements OnInit, OnDestroy {
  readonly PaymentInfoTypeEnum = PaymentInfoType;
  readonly OffCyclePaymentStatusTypeEnum = OffCyclePaymentStatusType;

  earningsInfo: any;
  earnings: any[] = [];
  bucketsInfo: any;
  buckets: any[] = [];
  displayedColumns = ['name', 'currentValue', 'YTDValue'];
  displayedStaticColumn = ['name', 'currentValue', 'YTDValue'];
  hasManualAdjusted: boolean = false;
  offCyclePayment?: OffCyclePayment;
  isBucketsDialog: boolean = false;

  unsubscribe$ = new Subject<void>();
  showEarning: boolean = true;
  isChicagoPark: boolean = false;

  @Input() isHistory?: boolean;
  @Input() paymentInforConfig!: PaymentInforConfigObj;
  @Output() changeBannerEvent = new EventEmitter();
  @Input() breadcrumbs: Breadcrumb[] | undefined;
  @Input() isEntityView: boolean = false;
  @Input() isEstablishBenefit: boolean = true;
  @Input() targetId?: string;
  @Input() benefitId?: string;

  constructor(
    private store: Store<fromNextPayment.MemberState>,
    private dialog: MatDialog,
    private storeFund: Store<fromReducer.State>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
      this.isBucketsDialog = this.data.isBucketsDialog || false;
      this.buckets = this.data.buckets || [];
      this.earningsInfo = this.data.earningsInfo || null;
      this.paymentInforConfig = this.data.paymentInforConfig;
    }

  ngOnInit(): void {
    this.store
    .select(fromNextPayment.selectNextPayment)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((data) => {
      if (data) this.offCyclePayment = data.offCyclePayment;
      let depositDate = data.depositDate
        ? new Date(data.depositDate)
        : new Date();
      this.showEarning =
        this.isChicagoPark && this.isHistory
          ? depositDate >= chicagoParkDepositDate
          : true;
      this.bucketsInfo = data.earningBuckets;
      this.buckets = this.bucketsInfo.earningBuckets;
    });

    this.store
      .select(fromNextPayment.selectNextPaymentEarnings)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state) {
          this.earningsInfo = state;
          this.earnings = this.earningsInfo.earnings;
          this.hasManualAdjusted = this.earnings?.some(
            (item: any) => item.manualAdjustmented
          );
        }
      });

    this.storeFund
      .pipe(select(fromReducer.selectCurrentFundState))
      .subscribe((el) => {
        this.isChicagoPark = el?.fundType === FundType.ChicagoParks;
      });
    
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onClickEdit() {
    const dialogRef = this.dialog.open(EditEarningsComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      autoFocus: false,
      data: {
        ...this.earningsInfo,
        buckets: this.buckets,
        offCyclePayment: this.offCyclePayment,
        breadcrumbs: this.breadcrumbs,
        isEntityView: this.isEntityView,
        isEstablishBenefit: this.isEstablishBenefit,
        targetId: this.targetId,
        benefitId: this.benefitId
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.changeBannerEvent.emit();
      if (result)
        this.store.dispatch(
          NextPaymentActions.updateEarnings({
            body: result,
            targetId: this.offCyclePayment?.id,
          })
        );
    });
  }

  onClickManage() {
    this.dialog.open(EarningDetailComponent, {
      panelClass: 'confirm-popup',
      disableClose: true,
      autoFocus: false,
      height: 'auto',
      width: '800px',
      data: {
        buckets: this.buckets,
        earningsInfo: this.earningsInfo,
        paymentInforConfig: this.paymentInforConfig,
        isBucketsDialog: true

      },
    });
  }

  onClose() {
    this.dialog.closeAll();
  }
}
