import { createAction, props } from '@ngrx/store';
import { MemberParticipantNavigationDetailResponse,
         MemberParticipantNavigationResponse,
         MenuParametersQuery,
         NavigationItemRequest,
         NavigationViewResponse,
         QueryDetailRequest,
         ReorderRequest,
         SetNavigationRequest,
         UpdateNavigationRequest
         } 
from '@ptg-member/types/models/participant-navigation-configuration.model';


export const GetNavigationConfigurationRequestAction = '[GetNavigationConfiguration/API] Send Request';
export const GetNavigationConfigurationSuccessAction = '[GetNavigationConfiguration/API] Success';
export const GetNavigationConfigurationFailureAction = '[GetNavigationConfiguration/API] Failure';

export const GetNavigationConfigurationDetailRequestAction = '[GetNavigationConfigurationDetail/API] Send Request';
export const GetNavigationConfigurationDetailSuccessAction = '[GetNavigationConfigurationDetail/API] Success';
export const GetNavigationConfigurationDetailFailureAction = '[GetNavigationConfigurationDetail/API] Failure';

export const GetViewRequestAction = '[GetView/API] Send Request';
export const GetViewSuccessAction = '[GetView/API] Success';
export const GetViewFailureAction = '[GetView/API] Failure';


export const SetNavigationConfigurationRequestAction = '[SetNavigationConfiguration/API] Send Request';
export const SetNavigationConfigurationSuccessAction = '[SetNavigationConfiguration/API] Success';
export const SetNavigationConfigurationFailureAction = '[SetNavigationConfiguration/API] Failure';

export const UpdateNavigationConfigurationRequestAction = '[UpdateNavigationConfiguration/API] Send Request';
export const UpdateNavigationConfigurationSuccessAction = '[UpdateNavigationConfiguration/API] Success';
export const UpdateNavigationConfigurationFailureAction = '[UpdateNavigationConfiguration/API] Failure';

export const SetNavigationConfigurationItemRequestAction = '[SetNavigationConfigurationItem/API] Send Request';
export const SetNavigationConfigurationItemSuccessAction = '[SetNavigationConfigurationItem/API] Success';
export const SetNavigationConfigurationItemFailureAction = '[SetNavigationConfigurationItem/API] Failure';

export const UpdateNavigationConfigurationItemRequestAction = '[UpdateNavigationConfigurationItem/API] Send Request';
export const UpdateNavigationConfigurationItemSuccessAction = '[UpdateNavigationConfigurationItem/API] Success';
export const UpdateNavigationConfigurationItemFailureAction = '[UpdateNavigationConfigurationItem/API] Failure';

export const ReorderNavigationConfigurationRequest = '[ReorderNavigationConfiguration/API] Send Request';
export const ReorderNavigationConfigurationSuccess = '[ReorderNavigationConfiguration/API] Success';
export const ReorderNavigationConfigurationFailure = '[ReorderNavigationConfiguration/API] Failure';

export const RemoveNavigationConfigurationRequestAction =
  '[RemoveNavigationConfiguration/API] Send Request';
export const RemoveNavigationConfigurationFailureAction =
  '[RemoveNavigationConfiguration/API] Failure';
export const RemoveNavigationConfigurationSuccessAction =
  '[RemoveNavigationConfiguration/API] Success';

  export const RemoveNavigationConfigurationItemRequestAction =
  '[RemoveNavigationConfigurationItem/API] Send Request';
export const RemoveNavigationConfigurationItemFailureAction =
  '[RemoveNavigationConfigurationItem/API] Failure';
export const RemoveNavigationConfigurationItemSuccessAction =
  '[RemoveNavigationConfigurationItem/API] Success';

export const ClearNavigationConfiguration = '[ClearNavigationConfiguration] Clear';

export const getNavigationConfigurationRequest = createAction(
  GetNavigationConfigurationRequestAction,
  props<{ query: MenuParametersQuery }>()
);

export const getNavigationConfigurationSuccess = createAction(
  GetNavigationConfigurationSuccessAction,
  props<{ navigationConfigurationResponse: MemberParticipantNavigationResponse }>()
);

export const getNavigationConfigurationFailure = createAction(
  GetNavigationConfigurationFailureAction,
  props<{ error?: any }>()
);

export const getViewRequest = createAction(
  GetViewRequestAction,
  props<{ entityId?: string }>()
);

export const getViewSuccess = createAction(
  GetViewSuccessAction,
  props<{ viewResponse: NavigationViewResponse }>()
);

export const getViewFailure = createAction(
  GetViewFailureAction,
  props<{ error?: any }>()
);

export const setNavigationConfigurationRequest = createAction(
  SetNavigationConfigurationRequestAction,
  props<{ body: SetNavigationRequest }>()
);

export const setNavigationConfigurationSuccess = createAction(
  SetNavigationConfigurationSuccessAction,
  props<{ id: string }>()
);

export const setNavigationConfigurationFailure = createAction(
  SetNavigationConfigurationFailureAction,
  props<{ error?: any }>()
);

export const getNavigationConfigurationDetailRequest = createAction(
  GetNavigationConfigurationDetailRequestAction,
  props<{ query: QueryDetailRequest }>()
);

export const getNavigationConfigurationDetailSuccess = createAction(
  GetNavigationConfigurationDetailSuccessAction,
  props<{ navigationConfigurationDetailResponse: MemberParticipantNavigationDetailResponse }>()
);

export const getNavigationConfigurationDetailFailure = createAction(
  GetNavigationConfigurationDetailFailureAction,
  props<{ error?: any }>()
);


export const updateNavigationConfigurationRequest = createAction(
  UpdateNavigationConfigurationRequestAction,
  props<{ body: UpdateNavigationRequest }>()
);

export const updateNavigationConfigurationSuccess = createAction(
  UpdateNavigationConfigurationSuccessAction,
);

export const updateNavigationConfigurationFailure = createAction(
  UpdateNavigationConfigurationFailureAction,
  props<{ error?: any }>()
);

export const setNavigationConfigurationItemRequest = createAction(
  SetNavigationConfigurationItemRequestAction,
  props<{ body: NavigationItemRequest }>()
);

export const setNavigationConfigurationItemSuccess = createAction(
  SetNavigationConfigurationItemSuccessAction,
);

export const setNavigationConfigurationItemFailure = createAction(
  SetNavigationConfigurationItemFailureAction,
  props<{ error?: any }>()
);

export const updateNavigationConfigurationItemRequest = createAction(
  UpdateNavigationConfigurationItemRequestAction,
  props<{ body: NavigationItemRequest }>()
);

export const updateNavigationConfigurationItemSuccess = createAction(
  UpdateNavigationConfigurationItemSuccessAction,
);

export const updateNavigationConfigurationItemFailure = createAction(
  UpdateNavigationConfigurationItemFailureAction,
  props<{ error?: any }>()
);

export const removeNavigationConfiguration = createAction(
  RemoveNavigationConfigurationRequestAction,
  props<{ query: QueryDetailRequest }>()
);
export const removeNavigationConfigurationSuccess = createAction(
  RemoveNavigationConfigurationSuccessAction
);
export const removeNavigationConfigurationFailure = createAction(
  RemoveNavigationConfigurationFailureAction,
  props<{ error?: any }>()
);

export const removeNavigationConfigurationItem = createAction(
  RemoveNavigationConfigurationItemRequestAction,
  props<{ query: QueryDetailRequest }>()
);
export const removeNavigationConfigurationItemSuccess = createAction(
  RemoveNavigationConfigurationItemSuccessAction
);
export const removeNavigationConfigurationItemFailure = createAction(
  RemoveNavigationConfigurationItemFailureAction,
  props<{ error?: any }>()
);

export const reorderNavigationConfiguration = createAction(
  ReorderNavigationConfigurationRequest,
  props<{
    body: ReorderRequest;
  }>()
);
export const reorderNavigationConfigurationSuccess = createAction(ReorderNavigationConfigurationSuccess);
export const reorderNavigationConfigurationFailure = createAction(
  ReorderNavigationConfigurationFailure,
  props<{ error?: any }>()
);

export const clearNavigationConfigurationState = createAction(ClearNavigationConfiguration);
