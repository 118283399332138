import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import { NonListConfigurationActions } from '../actions';
import { NonListProperty, NonListItemConfig } from '../../types/models';

export const nonListConfigurationFeatureKey = 'nonListConfiguration';

export interface State {
  getNonListProperties?: BaseActionState<NonListProperty[]>;
  getNonListItemConfigs?: BaseActionState<NonListItemConfig[]>;
  saveNonListItemConfigs?: BaseActionState;
}

const initialState: State = {};

export const reducer = createReducer(
  initialState,
  on(NonListConfigurationActions.getNonListProperties, (state) => ({
    ...state,
    getNonListProperties: {
      isLoading: true,
    },
  })),
  on(
    NonListConfigurationActions.getNonListPropertiesSuccess,
    (state: State, { response }) => ({
      ...state,
      getNonListProperties: {
        isLoading: false,
        success: true,
        payload: response.nonListProperties || [],
      },
    })
  ),
  on(
    NonListConfigurationActions.getNonListPropertiesFailure,
    (state: State, { error }) => ({
      ...state,
      getNonListProperties: createFailureState(error),
    })
  ),
  on(NonListConfigurationActions.clearGetNonListPropertiesState, (state) => ({
    ...initialState,
    getNonListProperties: undefined,
  })),

  on(NonListConfigurationActions.getNonListItemConfigs, (state) => ({
    ...state,
    getNonListItemConfigs: {
      isLoading: true,
    },
  })),
  on(
    NonListConfigurationActions.getNonListItemConfigsSuccess,
    (state: State, { response }) => ({
      ...state,
      getNonListItemConfigs: {
        isLoading: false,
        success: true,
        payload: response.nonListItemConfigs || [],
      },
    })
  ),
  on(
    NonListConfigurationActions.getNonListItemConfigsFailure,
    (state: State, { error }) => ({
      ...state,
      getNonListItemConfigs: createFailureState(error),
    })
  ),

  on(NonListConfigurationActions.saveNonListItemConfigs, (state) => ({
    ...state,
    saveNonListItemConfigs: {
      isLoading: true,
    },
  })),
  on(
    NonListConfigurationActions.saveNonListItemConfigsSuccess,
    (state: State) => ({
      ...state,
      saveNonListItemConfigs: {
        isLoading: false,
        success: true,
      },
    })
  ),
  on(
    NonListConfigurationActions.saveNonListItemConfigsFailure,
    (state: State, { error }) => ({
      ...state,
      saveNonListItemConfigs: createFailureState(error),
    })
  ),
  on(NonListConfigurationActions.clearNonListItemConfigs, (state) => ({
    ...state,
    saveNonListItemConfigs: undefined
  })),
);

const createFailureState = (error: any) => {
  return {
    isLoading: false,
    success: false,
    error: error,
  };
};
