import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { StepConfigurationService } from '../../services';
import {
  GetAllowRemoveStepConfigurationResponse,
  GetBenefitOptionsResponse,
  GetCardsResponse,
  GetMemberEntitiesResponse,
  GetStepConfigurationResponse,
  GetStepConfigurationsResponse,
} from '../../services/models';
import {
  checkAllowRemoveStepConfiguration,
  checkAllowRemoveStepConfigurationFailure,
  checkAllowRemoveStepConfigurationSuccess,
  createStepConfigurationsAction,
  createStepConfigurationsFailureAction,
  createStepConfigurationsSuccessAction,
  getBenefitTypesAction,
  getBenefitTypesFailureAction,
  getBenefitTypesSuccessAction,
  getStepConfigurationAction,
  getStepConfigurationFailureAction,
  getStepConfigurationSuccessAction,
  getStepConfigurationValueAction,
  getStepConfigurationValueSuccessAction,
  getStepConfigurationsAction,
  getStepConfigurationsFailureAction,
  getStepConfigurationsSuccessAction,
  removeStepConfiguration,
  removeStepConfigurationFailure,
  removeStepConfigurationSuccess,
  setStepConfigurationAction,
  setStepConfigurationFailureAction,
  setStepConfigurationSuccessAction,
  getMemberEntitiesAction,
  getMemberEntitiesSuccessAction,
  getMemberEntitiesFailureAction,
  getCardsAction,
  getCardsSuccessAction,
  getCardsFailureAction,
  checkStepConfigurationExistsAction,
  checkStepConfigurationExistsSuccessAction,
  checkStepConfigurationExistsFailureAction,
} from '../actions';

@Injectable()
export class StepConfigurationEffects {
  constructor(
    private actions$: Actions,
    private stepConfigurationService: StepConfigurationService
  ) { }

  getStepConfigurations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getStepConfigurationsAction),
      switchMap(({ request }) =>
        this.stepConfigurationService.getStepConfigurations(request).pipe(
          map((response: GetStepConfigurationsResponse) => getStepConfigurationsSuccessAction({ response })),
          catchError((error) => of(getStepConfigurationsFailureAction({ error })))
        )
      )
    )
  );

  checkStepConfigurationExists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkStepConfigurationExistsAction),
      switchMap(({ request }) =>
        this.stepConfigurationService.checkStepConfigurationExists(request).pipe(
          map((response) => checkStepConfigurationExistsSuccessAction({ response })),
          catchError((error) => of(checkStepConfigurationExistsFailureAction({ error }))),
        ),
      ),
    ),
  );

  createStepConfigurations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createStepConfigurationsAction),
      switchMap(({ request, isContinue }) =>
        this.stepConfigurationService.createStepConfigurations(request).pipe(
          map((response) => createStepConfigurationsSuccessAction({
            isContinue,
            stepConfigurationId: response as string
          })),
          catchError((error) => of(createStepConfigurationsFailureAction({ error })))
        )
      )
    )
  );

  getBenefitTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBenefitTypesAction),
      switchMap(({ request }) =>
        this.stepConfigurationService.getBenefitTypes(request).pipe(
          map((response: GetBenefitOptionsResponse) => getBenefitTypesSuccessAction({ response })),
          catchError((error) => of(getBenefitTypesFailureAction({ error })))
        )
      )
    )
  );

  getStepConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getStepConfigurationAction),
      switchMap(({ stepConfigurationId, memberId }) =>
        this.stepConfigurationService.getStepConfiguration(stepConfigurationId, memberId).pipe(
          map((response: GetStepConfigurationResponse) => getStepConfigurationSuccessAction({ response })),
          catchError((error) => of(getStepConfigurationFailureAction({ error })))
        )
      )
    )
  );

  setStepConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setStepConfigurationAction),
      switchMap(({ request }) =>
        this.stepConfigurationService.setStepConfiguration(request).pipe(
          map(() => setStepConfigurationSuccessAction()),
          catchError((error) => of(setStepConfigurationFailureAction({ error })))
        )
      )
    )
  );

  //get step configuration values base on benefitTypeId and benefitSubTypeId
  getStepConfigurationValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getStepConfigurationValueAction),
      switchMap(({ request }) =>
        this.stepConfigurationService.getStepConfigurationByType(request).pipe(
          map((response: GetStepConfigurationResponse) => getStepConfigurationValueSuccessAction({ response })),
          catchError((error) => of(getStepConfigurationFailureAction({ error })))
        )
      )
    )
  );

  removeStepConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeStepConfiguration),
      switchMap(({stepConfigurationId}) => {
        return this.stepConfigurationService.removeStepConfiguration(stepConfigurationId).pipe(
          map(() => {
            return removeStepConfigurationSuccess();
          }),
          catchError((err) => {
            return of(removeStepConfigurationFailure({ error: err }));
          })
        );
      })
    )
  );

  checlAllowRemoveStepConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkAllowRemoveStepConfiguration),
      switchMap(({stepConfigurationId}) => {
        return this.stepConfigurationService.checkAllowRemoveStepConfiguration(stepConfigurationId).pipe(
          map((response: GetAllowRemoveStepConfigurationResponse) => {
            return checkAllowRemoveStepConfigurationSuccess({response});
          }),
          catchError((err) => {
            return of(checkAllowRemoveStepConfigurationFailure({ error: err }));
          })
        );
      })
    )
  );

  getMemberEntities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMemberEntitiesAction),
      switchMap(() =>
        this.stepConfigurationService.getMemberEntities().pipe(
          map((response: GetMemberEntitiesResponse) => getMemberEntitiesSuccessAction({ response })),
          catchError((error) => of(getMemberEntitiesFailureAction({ error })))
        )
      )
    )
  );

  getCards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCardsAction),
      switchMap(({ request }) =>
        this.stepConfigurationService.getCards(request).pipe(
          map((response: GetCardsResponse) => getCardsSuccessAction({ response })),
          catchError((error) => of(getCardsFailureAction({ error })))
        )
      )
    )
  );

}
