import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BaseComponent } from '@ptg-shared/components';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { Column, ColumnType } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { takeUntil } from 'rxjs/operators';
import * as fromReducer from '@ptg-reducers';
import * as fromMember from '../../store/reducers';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { GetListAddressHistoryQuery } from '../../types/models';
import { EditMemberAddressHistoryComponent } from '@ptg-member/components/edit-member-address-history/edit-member-address-history.component';
import { STATE } from '@ptg-shared/constance';
import { AddressHistoryAction } from '../../store/actions';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';
import { Menu, MenuItem, ViewFixType } from '@ptg-shared/layout/models/layout.model';
import { STRING_QUERY_PARAM } from '@ptg-shared/layout/constance/layout.const';
import { EntityViewItem, InitiationProperty } from '@ptg-entity-management/services/models';
import { Sort } from '@angular/material/sort';
import { LIST_DISPLAY_PREFERENCE_TYPE } from '@ptg-member/types/enums';
import { GUID_EMPTY } from '@ptg-shared/constance';

@Component({
  selector: 'ptg-address-history-entity',
  templateUrl: './address-history-entity.component.html',
  styleUrls: ['./address-history-entity.component.scss']
})
export class AddressHistoryEntityComponent extends BaseComponent {
  readonly LIST_DISPLAY_PREFERENCE_TYPE = LIST_DISPLAY_PREFERENCE_TYPE;
  
  @Input() entityId!: string;
  @Input() memberId!: string;
  @Input() configCard!: EntityViewItem;
  @Input() viewName!: string;
  @Input() viewUrl!: string;
  
  listBreadcrumbs: Breadcrumb[] = [{ name: '' }];
  isLoading = false;
  currentFund: any = {};
  columns: Column[] = [
    {
      name: 'entityPropertyName',
      header: {
        title: 'Address Type',
      },
      truncate: true,
    },
    {
      name: 'effectFrom',
      header: {
        title: 'Effective From',
      },
      truncate: true,
      type: ColumnType.DateTime,
      templateArgs: { format: 'MM/dd/yyyy' },
    },
    {
      name: 'effectTo',
      header: {
        title: 'Effective To',
      },
      truncate: true,
      type: ColumnType.DateTime,
      templateArgs: { format: 'MM/dd/yyyy' },
    },
    {
      name: 'address',
      header: {
        title: 'Address',
      },
      type: ColumnType.Address,
      truncate: true,
    },
  ];
  addressHistoriesData: any[] = [];
  viewId: string = '';
  sortInfo: {} | any = {};
  lengthPg: number | any;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  currentRowIndex: number = 0;
  memberStatusHistoryId?: string;
  memberNavigationMenu!: Menu[];
  properties: InitiationProperty[] = [];  
  constructor(
    public route: ActivatedRoute,
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
    public dialog: MatDialog,
    private router: Router
  ) { 
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getData();
    this.memberStore.dispatch(
      AddressHistoryAction.getAddressProperties({ entityId: this.entityId })
    );
    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.pageSize = el.defaultPageSize ?? 50;
        this.currentFund = el;
      });

    this.pageSize =
      Number(
        sessionStorage.getItem(
          this.currentFund.key + '-ptg-address-history-entity-pageSize'
        )
      ) === 0
        ? this.pageSize
        : Number(
            sessionStorage.getItem(
              this.currentFund.key + '-ptg-address-history-entity-pageSize'
            )
          );

    this.memberStore
      .pipe(
        select(fromMember.selectAddressHistoryState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        this.lengthPg = state?.addressHistoryResult?.total;
        this.isLoading = state?.isLoading;
        this.addressHistoriesData =
        state?.addressHistoryResult?.listAddressHistory.map(el => {
          const address = {
            street1: el.street1,
            street2: el.street2,
            city: el.city,
            state: (state.properties ?? [])[0]?.options.find(item => item.id === el.state)?.text,
            zipCode: el.zipCode,
            country: (state.properties ?? [])[0]?.options.find(item => item.id === el.country)?.text
          };
          const addressType = state.properties?.find(item => item.entityPropertyId === el.code)?.entityPropertyName;
          return {...el, address, addressType, options: (state.properties ?? [])[0]?.options};
        });
        this.properties = state.properties ?? [];
        if (
          state.createAddress == STATE.SUCCESS 
        ) {
          this.getData();
        }
        if (state.createAddress) {
          this.memberStore.dispatch(
            AddressHistoryAction.clearAddressHistoryState()
          );
        }
      });
      this.memberStore
      .pipe(
        select(fromLayoutReducer.selectProfileNavigationState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state.isReloading && state.memberId) {
          this.memberNavigationMenu = state.menu;
        }
      });
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  getData() {
    let sortType = 1;
    let sortNames = ['EffectFrom', 'EffectTo', 'EntityPropertyName'];
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = [capitalizeFirstLetter(this.sortInfo.active)];
      sortType = this.sortInfo.direction === 'desc' ? 1 : 0;
    }
    const query: GetListAddressHistoryQuery = {
      memberId: this.memberId,
      pageIndex: this.pageNumber,
      pageSize: this.pageSize,
      sortNames: sortNames,
      sortType: sortType,
    };
    this.memberStore.dispatch(
      AddressHistoryAction.getAddressHistoryList({ query, entityId: this.entityId })
    );
  }


  sortChange(event: Sort) {
    this.sortInfo = event;
    this.currentRowIndex = 0;
    this.getData();
  }

  changePage(event: PageEvent) {
    this.currentRowIndex = 0;
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    sessionStorage.setItem(
      this.currentFund.key + '-ptg-address-history-entity-pageSize',
      this.pageNumber.toString()
    );
    sessionStorage.setItem(
      this.currentFund.key + '-ptg-address-history-entity-pageSize',
      this.pageSize.toString()
    );
    this.getData();
  }

  selectRow(event?: any) {
    this.memberStore
    .pipe(
      select(fromLayoutReducer.selectProfileNavigationState),
      takeUntil(this.unsubscribe$)
    )
    .subscribe((state) => {
      if (state.isReloading && state.memberId) {
        const allMenuItems = this.memberNavigationMenu.reduce((acc: MenuItem[], menu: Menu) => {
          return [...acc, ...menu?.menuItems ?? []];
        }, []);
        
        const menuSameCard = allMenuItems.find(menuitem => menuitem.viewFixType === ViewFixType.AddressHistory);
        let baseUrl = `member/system-view/address-history/${GUID_EMPTY}/${GUID_EMPTY}`;
        if (menuSameCard) {
          baseUrl = menuSameCard.routerLink;
        }
        let selectRow = '';
        if (event) {
          const currentRowIndex = this.addressHistoriesData.findIndex(
            (item) => item.id === event?.id
          );
          selectRow = `&currentRowIndex=${currentRowIndex}`
        }

        const entityReferenceLinkedId = new URLSearchParams(document.location.search).get("entityReferenceLinkedId");
        this.router.navigateByUrl(`${baseUrl.split('/').concat([state.memberId]).filter((x) => x).join('/')}?${STRING_QUERY_PARAM.PROFILE_NAVIGATE}${selectRow}&prevName=${
          encodeURIComponent(this.viewName)
        }&prevUrl=${
          encodeURIComponent(this.viewUrl)
        }${entityReferenceLinkedId ? `&entityReferenceLinkedId=${entityReferenceLinkedId}` : ''}`);
      }
    });
  }

  onAddMemberAddress() {
    let dialogRef = this.dialog.open(EditMemberAddressHistoryComponent, {
      panelClass: ['dialog-full-screen'],
      autoFocus: false,
      disableClose: true,
      data: {
        entityId: this.entityId,
        memberId: this.memberId,
        viewName: 'Address',
        address: null,
        properties: this.properties
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.memberStore.dispatch(
          AddressHistoryAction.createAddress({body: result})
        );
      }
    });
  }
}
