import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { take } from 'rxjs/operators';

import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import {
  BenefitOptions,
  ComparisonOperator,
  ComparisonOperatorLabel,
  ComparisonOperatorSymbol,
  ExceptionDataSource,
  ExceptionType,
  MonthYearUnit,
} from '@ptg-member/features/calculation/types/enums';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';

import {
  AdditionalInfo,
  BenefitDetail,
} from '@ptg-member/features/calculation/services/models/exception-configuration.model';
import { Option } from '@ptg-shared/controls/select/select.component';

@Injectable()
export class EditExceptionComponentService {
  constructor(private readonly dialog: MatDialog) {}

  get getValidationIntervalList(): Option[] {
    return new Array(5).fill(undefined).map((_, i) => {
      return {
        displayValue: ComparisonOperatorLabel[i],
        value: i as ComparisonOperator,
      };
    });
  }

  isBenefitOptionUsedForCalculation(
    action: 'Add' | 'Remove',
    benefitDetailObj: BenefitDetail,
    savedBenefitDetailList: BenefitDetail[],
    benefitHasInProcessingCalculation: string[],
  ): Promise<boolean> {
    const processingBenefitOptions = benefitDetailObj.benefitOptions ?? BenefitOptions.All;
    const showWarningPopup =
      action === 'Add'
        ? // If the added option is [ALL] option
          (processingBenefitOptions === BenefitOptions.All && !!benefitHasInProcessingCalculation.length) ||
          // If the added option is [Specific] option
          (savedBenefitDetailList.length === 1 && savedBenefitDetailList.every((item) => !item.benefitId)) ||
          benefitHasInProcessingCalculation.includes(benefitDetailObj.benefitId ?? '')
        : // If the removed option is [ALL] option
          (processingBenefitOptions === BenefitOptions.All && !!benefitHasInProcessingCalculation.length) ||
          // If the removed option is [Specific] option
          benefitHasInProcessingCalculation.includes(benefitDetailObj.benefitId ?? '');

    return showWarningPopup
      ? this.dialog
          .open(ConfirmPopupComponent, {
            panelClass: 'confirm-popup',
            autoFocus: false,
            data: {
              title: BannerType.Warning,
              text: 'Exception is used to calculate this benefit option. Are you sure you want to proceed?',
            },
          })
          .afterClosed()
          .pipe(take(1))
          .toPromise()
      : Promise.resolve(true);
  }

  getInitPropertyDetailAdditionalInfos(
    entityValueList: Option[],
    additionalInfos: AdditionalInfo[],
  ): { savedAdditionalEntityValueOption?: Option; savedAdditionalEntityValueChildOption?: Option } {
    const savedAdditionalEntityValueOption = entityValueList.find(
      (item: Option) => item.value?.id === additionalInfos[0]?.value,
    );
    const savedAdditionalEntityValueChildOption = entityValueList.find(
      (item: Option) => item.value?.id === additionalInfos[1]?.value,
    );
    return {
      savedAdditionalEntityValueOption,
      savedAdditionalEntityValueChildOption,
    };
  }

  getInitBenefitDetailAdditionalInfos(
    additionalInfos: AdditionalInfo[],
    exceptionType: ExceptionType | undefined,
    isShowBenefitBeginDateDropdown: boolean,
    isDateValueValidation: boolean,
  ): AdditionalInfo[] {
    // Exception [MemberMinServiceNotMet]
    if (exceptionType === ExceptionType.MemberMinServiceNotMet) {
      return additionalInfos.map((info) => ({
        ...info,
        unit: MonthYearUnit.Year,
      }));
    }
    // Exceptions have [Benefit Begin Date] dropdown list
    if (isShowBenefitBeginDateDropdown) {
      return additionalInfos.map((info) => ({
        ...info,
        displayValue: info.name,
        name: 'Benefit Begin Date',
      }));
    }
    // Exceptions [Date Value Validation]
    if (isDateValueValidation) {
      return additionalInfos.map((info, idx) => {
        const isEntity = info?.source === ExceptionDataSource['Member Entity'];
        let name = '';
        let displaySubValue = '';
        switch (idx) {
          case 1:
            name = ComparisonOperatorSymbol[+info.value];
            break;
          case 2:
            name = isEntity ? info.name.split('|')[1] ?? '' : info.name;
            displaySubValue = isEntity
              ? info.name.split('|')[2] ?? ''
              : ExceptionDataSource[info?.source ?? ExceptionDataSource['Unknown']];
            break;
          default:
            name = info.name;
            break;
        }
        return {
          ...info,
          name,
          displayValue: info.name,
          displaySubValue,
        };
      });
    }
    return additionalInfos;
  }
}
