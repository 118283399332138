<div class="total-service-container">
  <p class="title-name">
    <span class="name">{{cardTitle}}</span>
  </p>
  <div class="content">
    <div class="item-info flex">
      <span class="item-name">Total Service:</span>
      <div class="flex justify-start w-1/2 gap-2">
        <span class="item-value">{{totalServiceData?.muniTotalService}}</span>
      </div>
    </div>
    <div class="item-info flex">
      <span class="item-name">Number of Payments:</span>
      <div class="flex justify-start w-1/2 gap-2">
        <span class="item-value">{{totalServiceData?.muniNumberPayment}}</span>
      </div>
    </div>
    <div class="item-info flex">
      <span class="item-name">Sum of Pension Payments:</span>
      <div class="flex justify-start w-1/2 gap-2">
        <span class="item-value">{{totalServiceData?.muniSumPayment | numberLocalDecimal : "$" || "-"}}</span>
      </div>
    </div>
    <div class="item-info flex">
      <span class="item-name">Total Member Paid Pension:</span>
      <div class="flex justify-start w-1/2 gap-2">
        <span class="item-value">{{totalServiceData?.muniTotalMemberPayment | numberLocalDecimal : "$" || "-"}}</span>
      </div>
    </div>

  </div>
</div>
