import { createAction, props } from '@ngrx/store';

import {
  AddListRequest,
  AddPropertyRequest,
  CreateEntityRequest,
  DeletePropertyRequest,
  GetEntitiesQuery,
  GetEntitiesResponse,
  GetEntityComponentListsRequest,
  GetEntityComponentListsResponse,
  GetEntityDetailRequest,
  GetEntityDetailResponse,
  GetEntityListsRequest,
  GetEntityListsResponse,
  GetEntityPropertiesRequest,
  GetEntityPropertiesResponse,
  GetListDetailRequest,
  GetPropertyTypesRequest,
  GetPropertyTypesResponse,
  UpdateEntityRequest,
  UpdatePropertyItemRequest,
  UpdateListConfigRequest,
  GetEntityPropertyDetailRequest,
  GetEntityPropertyDetailResponse,
  SetEntityPropertyRequest,
  GetEntityPropertiesForCalculationRequest,
  GetEntityPropertiesForCalculationResponse,
  GetEntityReferencesRequest,
  GetEntityReferencesResponse,
  GetEntityInitiationConfigurationRequest,
  GetEntityInitiationConfigurationResponse,
  SetEntityInitiationConfigurationRequest,
  CheckEntityCanRemoveResponse,
  GetEntityListDataByIdQuery,
  GetEntityListDataByIdResponse,
  DeleteEntityListDataQuery,
  ReOrderEntityListDataRequest,
  DeleteEntityComponentRequest,
  BenefitMappingPayload,
} from '../../services/models';
import { ParametersQuery } from '@ptg-employer/models';
import { generateActionName } from '@ptg-shared/utils/common.util';

export const GetEntityDetailRequestAction = '[GetEntityDetail/API] Send Request';
export const GetEntityDetailSuccessAction = '[GetEntityDetail/API] Success';
export const GetEntityDetailFailureAction = '[GetEntityDetail/API] Failure';
export const ClearGetEntityDetailStateAction = '[GetEntityDetail] Clear';
export const UpdateEntityRequestAction = '[UpdateEntity/API] Send Request';
export const UpdateEntitySuccessAction = '[UpdateEntity/API] Success';
export const UpdateEntityFailureAction = '[UpdateEntity/API] Failure';
export const ClearUpdateEntityStateAction = '[UpdateEntity] Clear';

export const GetEntityPropertiesForCalculationRequestAction = '[GetEntityPropertiesForCalculation/API] Send Request';
export const GetEntityPropertiesForCalculationSuccessAction = '[GetEntityPropertiesForCalculation/API] Success';
export const GetEntityPropertiesForCalculationFailureAction = '[GetEntityPropertiesForCalculation/API] Failure';

export const AddPropertyRequestAction = '[Entity/AddProperty/API] Send Request';
export const AddPropertySuccessAction = '[Entity/AddProperty/API] Success';
export const AddPropertyFailureAction = '[Entity/AddProperty/API] Failure';
export const ClearAddPropertyStateAction = '[Entity/AddProperty] Clear';

export const createEntityAction = createAction(
  '[CreateEntity/API] Send Request',
  props<{ request: CreateEntityRequest; isContinue: boolean }>()
);

export const createEntitySuccessAction = createAction(
  '[CreateEntity/API] Success',
  props<{ response: any; isContinue: boolean }>()
);

export const createEntityFailureAction = createAction(
  '[CreateEntity/API] Failure',
  props<{ error?: any }>()
);

export const clearCreateEntityStateAction = createAction(
  '[CreateEntity] Clear'
);

export const getEntitiesAction = createAction(
  '[GetEntities/API] Send Request',
  props<{ query: GetEntitiesQuery }>()
);

export const getEntitiesSuccessAction = createAction(
  '[GetEntities/API] Success',
  props<{ response: GetEntitiesResponse }>()
);

export const getEntitiesFailureAction = createAction(
  '[GetEntities/API] Failure',
  props<{ error?: any }>()
);

export const getAllEntitiesAction = createAction(
  '[GetAllEntities/API] Send Request',
  props<{ query: GetEntitiesQuery }>()
);

export const getAllEntitiesSuccessAction = createAction(
  '[GetAllEntities/API] Success',
  props<{ response: GetEntitiesResponse }>()
);

export const getAllEntitiesFailureAction = createAction(
  '[GetAllEntities/API] Failure',
  props<{ error?: any }>()
);

export const getAllEntityPropertiesAction = createAction(
  '[GetAllEntityProperties/API] Send Request',
  props<{ request: GetEntityPropertiesRequest }>()
);

export const getAllEntityPropertiesSuccessAction = createAction(
  '[GetAllEntityProperties/API] Success',
  props<{ response: GetEntityPropertiesResponse }>()
);

export const getAllEntityPropertiesFailureAction = createAction(
  '[GetAllEntityProperties/API] Failure',
  props<{ error?: any }>()
);

export const getEntityDetail = createAction(
  GetEntityDetailRequestAction,
  props<{ request: GetEntityDetailRequest }>()
);

export const getEntityDetailSuccess = createAction(
  GetEntityDetailSuccessAction,
  props<{ response: GetEntityDetailResponse }>()
);

export const getEntityDetailFailure = createAction(
  GetEntityDetailFailureAction,
  props<{ error?: any }>()
);

export const clearGetEntityDetailState = createAction(
  ClearGetEntityDetailStateAction
);

export const getEntityPropertiesForCalculation = createAction(
  GetEntityPropertiesForCalculationRequestAction,
  props<{ request: GetEntityPropertiesForCalculationRequest }>()
);

export const getEntityPropertiesForCalculationSuccess = createAction(
  GetEntityPropertiesForCalculationSuccessAction,
  props<{ response: GetEntityPropertiesForCalculationResponse }>()
);

export const getEntityPropertiesForCalculationFailure = createAction(
  GetEntityPropertiesForCalculationFailureAction,
  props<{ error?: any }>()
);

export const updateEntity = createAction(
  UpdateEntityRequestAction,
  props<{ request: UpdateEntityRequest }>()
);
export const updateEntitySuccess = createAction(UpdateEntitySuccessAction);
export const updateEntityFailure = createAction(
  UpdateEntityFailureAction,
  props<{ error?: any }>()
);

export const clearUpdateEntityState = createAction(
  ClearUpdateEntityStateAction
);

export const clearGetEntitiesStateAction = createAction('[GetEntities] Clear');

export const AddProperty = createAction(
  AddPropertyRequestAction,
  props<{
    request: AddPropertyRequest;
    isContinue: boolean;
    entityId: string;
    entityComponentId: string;
  }>()
);

export const AddPropertySuccess = createAction(
  AddPropertySuccessAction,
  props<{ response: any; isContinue: boolean; propertyType?: number }>()
);

export const AddPropertyFailure = createAction(
  AddPropertyFailureAction,
  props<{ error?: any }>()
);

export const AddListRequestAction = '[AddList/API] Send Request';
export const AddListSuccessAction = '[AddList/API] Success';
export const AddListFailureAction = '[AddList/API] Failure';
export const ClearAddListStateAction = '[AddList] Clear';

export const addList = createAction(
  AddListRequestAction,
  props<{
    request: AddListRequest;
    isContinue: boolean;
    entityId: string;
  }>()
);

export const addListSuccess = createAction(
  AddListSuccessAction,
  props<{ response: any; isContinue: boolean }>()
);

export const addListFailure = createAction(
  AddListFailureAction,
  props<{ error?: any }>()
);

export const clearAddListState = createAction(ClearAddListStateAction);

export const clearAddPropertyState = createAction(ClearAddPropertyStateAction);

// DeleteProperty
export const DeletePropertyRequestAction = '[DeleteProperty/API] Send Request';
export const DeletePropertySuccessAction = '[DeleteProperty/API] Success';
export const DeletePropertyFailureAction = '[DeleteProperty/API] Failure';
export const ClearDeletePropertyStateAction = '[DeleteProperty] Clear';

// Delete Entity Component
export const DeleteEntityComponentRequestAction = '[DeleteEntityComponent/API] Send Request';
export const DeleteEntityComponentSuccessAction = '[DeleteEntityComponent/API] Send Success';
export const DeleteEntityComponentFailureAction = '[DeleteEntityComponent/API] Send Failure';
export const ClearDeleteEntityComponentStateAction = '[DeleteEntityComponent] Clear';

export const deleteProperty = createAction(
  DeletePropertyRequestAction,
  props<{ request: DeletePropertyRequest }>()
);

export const deletePropertySuccess = createAction(
  DeletePropertySuccessAction,
  props<{ entityPropertyId: string, propertyName: string }>()
);

export const deletePropertyFailure = createAction(
  DeletePropertyFailureAction,
  props<{ error?: any }>()
);

export const clearDeletePropertyState = createAction(
  ClearDeletePropertyStateAction
);

export const deleteEntityComponent = createAction(
  DeleteEntityComponentRequestAction,
  props<{ request: DeletePropertyRequest }>()
);

export const deleteEntityComponentSuccess = createAction(
  DeleteEntityComponentSuccessAction,
  props<{ propertyName: string }>()
);

export const deleteEntityComponentFailure = createAction(
  DeleteEntityComponentFailureAction,
  props<{ error?: any }>()
);

export const clearDeleteEntityComponentState = createAction(
  ClearDeleteEntityComponentStateAction
);

export const GetListDetailRequestAction = '[GetListDetail/API] Send Request';
export const GetListDetailSuccessAction = '[GetListDetail/API] Success';
export const GetListDetailFailureAction = '[GetListDetail/API] Failure';
export const ClearGetListDetailStateAction = '[GetListDetail] Clear';

export const getListDetail = createAction(
  GetListDetailRequestAction,
  props<{ request: GetListDetailRequest }>()
);

export const getListDetailSuccess = createAction(
  GetListDetailSuccessAction,
  props<{ response: any }>()
);

export const getListDetailFailure = createAction(
  GetListDetailFailureAction,
  props<{ error?: any }>()
);

export const clearGetListDetailState = createAction(
  ClearGetListDetailStateAction
);

export const UpdateListConfigRequestAction =
  '[UpdateListConfig/API] Send Request';
export const UpdateListConfigSuccessAction = '[UpdateListConfig/API] Success';
export const UpdateListConfigFailureAction = '[UpdateListConfig/API] Failure';
export const ClearUpdateListConfigStateAction = '[UpdateListConfig] Clear';

export const updateListConfig = createAction(
  UpdateListConfigRequestAction,
  props<{ request: UpdateListConfigRequest }>()
);

export const updateListConfigSuccess = createAction(
  UpdateListConfigSuccessAction
);

export const updateListConfigFailure = createAction(
  UpdateListConfigFailureAction,
  props<{ error?: any }>()
);

export const clearUpdateListConfigState = createAction(
  ClearUpdateListConfigStateAction
);

export const GetPropertyTypesRequestAction =
  '[GetPropertyTypes/API] Send Request';
export const GetPropertyTypesSuccessAction = '[GetPropertyTypes/API] Success';
export const GetPropertyTypesFailureAction = '[GetPropertyTypes/API] Failure';
export const ClearGetPropertyTypesStateAction = '[GetPropertyTypes] Clear';

export const getPropertyTypes = createAction(
  GetPropertyTypesRequestAction,
  props<{ request: GetPropertyTypesRequest }>()
);

export const getPropertyTypesSuccess = createAction(
  GetPropertyTypesSuccessAction,
  props<{ response: GetPropertyTypesResponse }>()
);

export const getPropertyTypesFailure = createAction(
  GetPropertyTypesFailureAction,
  props<{ error?: any }>()
);

export const clearGetPropertyTypesState = createAction(
  ClearGetPropertyTypesStateAction
);

export const UpdatePropertyItemRequestAction =
  '[UpdatePropertyItem/API] Send Request';
export const UpdatePropertyItemSuccessAction =
  '[UpdatePropertyItem/API] Success';
export const UpdatePropertyItemFailureAction =
  '[UpdatePropertyItem/API] Failure';
export const ClearUpdatePropertyItemStateAction = '[UpdatePropertyItem] Clear';

export const updatePropertyItem = createAction(
  UpdatePropertyItemRequestAction,
  props<{ request: UpdatePropertyItemRequest }>()
);

export const updatePropertyItemSuccess = createAction(
  UpdatePropertyItemSuccessAction
);

export const updatePropertyItemFailure = createAction(
  UpdatePropertyItemFailureAction,
  props<{ error?: any }>()
);

export const clearUpdatePropertyItemState = createAction(
  ClearUpdatePropertyItemStateAction
);
export const deleteEntityAction = createAction(
  '[DeleteEntity/API] Send Request',
  props<{ id: string }>()
);

export const deleteEntitySuccessAction = createAction(
  '[DeleteEntity/API] Success'
);

export const deleteEntityFailureAction = createAction(
  '[DeleteEntity/API] Failure',
  props<{ error?: any }>()
);

export const clearDeleteEntityStateAction = createAction(
  '[DeleteEntity] Clear'
);

export const getEntityPropertiesAction = createAction(
  '[GetEntityProperties/API] Send Request',
  props<{ request: GetEntityPropertiesRequest }>()
);

export const getEntityPropertiesSuccessAction = createAction(
  '[GetEntityProperties/API] Success',
  props<{ response: GetEntityPropertiesResponse }>()
);

export const getEntityPropertiesFailureAction = createAction(
  '[GetEntityProperties/API] Failure',
  props<{ error?: any }>()
);

export const clearGetEntityPropertiesStateAction = createAction(
  '[GetEntityProperties] Clear'
);

export const getEntityListsAction = createAction(
  '[GetEntityLists/API] Send Request',
  props<{ request: GetEntityListsRequest }>()
);

export const getEntityListsSuccessAction = createAction(
  '[GetEntityLists/API] Success',
  props<{ response: GetEntityListsResponse }>()
);

export const getEntityListsFailureAction = createAction(
  '[GetEntityLists/API] Failure',
  props<{ error?: any }>()
);

export const clearGetEntityListsStateAction = createAction(
  '[GetEntityLists] Clear'
);

export const getEntityComponentListsAction = createAction(
  '[GetEntityComponentLists/API] Send Request',
  props<{ request: GetEntityComponentListsRequest }>()
);

export const getEntityComponentListsSuccessAction = createAction(
  '[GetEntityComponentLists/API] Success',
  props<{ response: GetEntityComponentListsResponse }>()
);

export const getEntityComponentListsFailureAction = createAction(
  '[GetEntityComponentLists/API] Failure',
  props<{ error?: any }>()
);

export const clearGetEntityComponentListsStateAction = createAction(
  '[GetEntityComponentLists] Clear'
);

export const getEntityPropertyDetailAction = createAction(
  '[GetEntityPropertyDetail/API] Send Request',
  props<{ request: GetEntityPropertyDetailRequest }>()
);

export const getEntityPropertyDetailSuccessAction = createAction(
  '[GetEntityPropertyDetail/API] Success',
  props<{ response: GetEntityPropertyDetailResponse }>()
);

export const getEntityPropertyDetailFailureAction = createAction(
  '[GetEntityPropertyDetail/API] Failure',
  props<{ error?: any }>()
);

export const clearGetEntityPropertyDetailStateAction = createAction(
  '[GetEntityPropertyDetail] Clear'
);

export const setEntityPropertyAction = createAction(
  '[SetEntityProperty/API] Send Request',
  props<{ request: SetEntityPropertyRequest }>()
);

export const setEntityPropertySuccessAction = createAction(
  '[SetEntityProperty/API] Success',
  props<{ propertyName: string }>()
);

export const setEntityPropertyFailureAction = createAction(
  '[SetEntityProperty/API] Failure',
  props<{ error?: any }>()
);

export const clearSetEntityPropertyStateAction = createAction(
  '[SetEntityProperty] Clear'
);

export const getEntityReferencesAction = createAction(
  '[GetEntityReferences/API] Send Request',
  props<{ request: GetEntityReferencesRequest }>()
);

export const getEntityReferencesSuccessAction = createAction(
  '[GetEntityReferences/API] Success',
  props<{ response: GetEntityReferencesResponse }>()
);

export const getEntityReferencesFailureAction = createAction(
  '[GetEntityReferences/API] Failure',
  props<{ error?: any }>()
);

export const clearGetEntityReferencesStateAction = createAction(
  '[GetEntityReferences] Clear'
);

export const getEntityInitiationConfigurationAction = createAction(
  '[GetEntityInitiationConfiguration/API] Send Request',
  props<{ request: GetEntityInitiationConfigurationRequest }>()
);

export const getEntityInitiationConfigurationSuccessAction = createAction(
  '[GetEntityInitiationConfiguration/API] Success',
  props<{ response: GetEntityInitiationConfigurationResponse }>()
);

export const getEntityInitiationConfigurationFailureAction = createAction(
  '[GetEntityInitiationConfiguration/API] Failure',
  props<{ error?: any }>()
);

export const clearGetEntityInitiationConfigurationStateAction = createAction(
  '[GetEntityInitiationConfiguration] Clear'
);

export const setEntityInitiationConfigurationAction = createAction(
  '[SetEntityInitiationConfiguration/API] Send Request',
  props<{ request: SetEntityInitiationConfigurationRequest }>()
);

export const setEntityInitiationConfigurationSuccessAction = createAction(
  '[SetEntityInitiationConfiguration/API] Success'
);

export const setEntityInitiationConfigurationFailureAction = createAction(
  '[SetEntityInitiationConfiguration/API] Failure',
  props<{ error?: any }>()
);

export const clearSetEntityInitiationConfigurationStateAction = createAction(
  '[SetEntityInitiationConfiguration] Clear'
);
export const checkEntityCanRemove = createAction(
  '[CheckCanRemoveEntity/API] Send request',
  props<{ id: string }>()
);
export const checkEntityCanRemoveSuccess = createAction(
  '[CheckCanRemoveEntity/API] Success',
props<{ response: CheckEntityCanRemoveResponse }>()
);
export const checkEntityCanRemoveFailure = createAction(
  '[CheckCanRemoveEntity/API] Failure',
props<{ error?: any }>()
);
export const clearCheckEntityCanRemove = createAction(
  '[CheckCanRemoveEntity/API] Clear'
);

export const getEntityListDataByIdAction = createAction(
  '[GetEntityListDataById/API] Send Request',
  props<{ query: GetEntityListDataByIdQuery }>()
);

export const getEntityListDataByIdSuccessAction = createAction(
  '[GetEntityListDataById/API] Success',
  props<{ response: GetEntityListDataByIdResponse }>()
);

export const getEntityListDataByIdFailureAction = createAction(
  '[GetEntityListDataById/API] Failure',
  props<{ error?: any }>()
);

export const clearGetEntityListDataByIdFailureAction = createAction(
  '[ClearGetEntityListDataById/API] Clear',
);

export const deleteEntityListDataAction = createAction(
  '[DeleteEntityListData/API] Send Request',
  props<{ query: DeleteEntityListDataQuery }>()
);

export const deleteEntityListDataSuccessAction = createAction(
  '[DeleteEntityListData/API] Success'
);

export const deleteEntityListDataFailureAction = createAction(
  '[DeleteEntityListData/API] Failure',
  props<{ error?: any }>()
);

export const clearDeleteEntityListDataStateAction = createAction(
  '[DeleteEntityListData] Clear'
);

export const reOrderEntityListData = createAction(
  '[ReOrderEntityListData/API] Send Request',
  props<{entityComponentId: string, request: ReOrderEntityListDataRequest}>()
);

export const GetListBenefitRequestAction = '[GetListBenefit/API] Send Request';
export const GetListBenefitSuccessAction = '[GetListBenefit/API] Success';
export const GetListBenefitFailureAction = '[GetListBenefit/API] Failure';
export const ClearGetBenefitStateAction = '[GetListBenefit] Clear';

export const getListBenefit = createAction(
  GetListBenefitRequestAction,
  props<{ query: ParametersQuery }>()
);

export const getListBenefitSuccess = createAction(
  GetListBenefitSuccessAction,
  props<{ response: any }>()
);

export const getListBenefitFailure = createAction(
  GetListBenefitFailureAction,
  props<{ error?: any }>()
);

export const clearGetListBenefitState = createAction(
  ClearGetBenefitStateAction
);

export const updateBenefitMapping = createAction(
  generateActionName('UpdateBenefitMapping/API').Send,
  props<{ body: BenefitMappingPayload }>()
);
export const updateBenefitMappingSuccess = createAction(
  generateActionName('UpdateBenefitMapping/API').Success,
);
export const updateBenefitMappingFailure = createAction(
  generateActionName('UpdateBenefitMapping/API').Failure
);
export const clearUpdateBenefitMappingState = createAction(
  generateActionName('UpdateBenefitMapping/API').Clear
);
