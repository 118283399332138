<div class="payroll-settings-container">
  <div class="control-bar" [ngClass]="{'has-status' : participantSetting?.payStatus }">
    <div class="left-title">
      <span class="name">{{ offCyclePayment ? 'Payment Settings' : 'Payroll Settings'}}</span>
      <span [ngClass]="{
        'suspend-status': participantSetting?.payStatus === PayStatusEnum.Suspend,
        'terminated-status': participantSetting?.payStatus === PayStatusEnum.Terminate
      }">
        {{participantSetting?.payStatus === PayStatusEnum.Suspend ?
          'Suspended' : participantSetting?.payStatus === PayStatusEnum.Terminate ?
          'Terminated' : ''}}
      </span>
    </div>
    <div
      *ngIf="
        !isHistory &&
        (!offCyclePayment ||
          (offCyclePayment &&
          offCyclePayment.paymentStatus === OffCyclePaymentStatusTypeEnum.NotIssued))
      "
      class="edit-btn"
      (click)="onClickEdit()"
    >
      <mat-icon>edit</mat-icon>
      <span class="title-edit"> Edit</span>
    </div>
  </div>
  <div class="bottom-content">
    <p>
      <span class="label">Payment Address </span>
      <ptg-view-address [value]="participantSetting?.representativePayee ? participantSetting?.representativePayee?.address : participantSetting?.paymentAddress?.address" [useCode]="true" customClass="text-end"></ptg-view-address>
    </p>
    <p>
      <span class="label">Representative Payee </span>
      <span class="value">{{ participantSetting?.representativePayee?.name ? (participantSetting?.representativePayee?.name | personName) : '-'}}</span>
    </p>
  </div>
  <div class="payroll-settings-content" *ngIf="!offCyclePayment && (indexFederal >= 0 || indexState >= 0)">
    <div class="column-value" style="margin-bottom: 16px;" *ngIf="indexFederal >= 0">
      <div class="title">
        Federal
      </div>
      <p >
        <span class="label">Federal W/H </span>
        <span class="value">{{federalSettings?.federalTableName ? federalSettings?.federalTableName : '-'}}</span>
      </p>
      <div class="formtype1" *ngIf="federalSettings?.form === 1" >
        <p *ngIf="federalSettings?.form === 1 && federalSettings?.federalTableId && (federalSettings?.income || federalSettings?.income === 0)">
          <span class="label">Federal Income </span>
          <span class="value"><span class="dollar-sign">$</span>{{federalSettings?.income || federalSettings?.income === 0 ? (federalSettings?.income | numberDecimal:{ decimal: 2 }) : '-'}}</span>
        </p>
        <p *ngIf="federalSettings?.form === 1 && federalSettings?.federalTableId && (federalSettings?.claimDependents || federalSettings?.claimDependents === 0)">
          <span class="label">Federal Claim Dep. </span>
          <span class="value"><span class="dollar-sign">$</span>{{federalSettings?.claimDependents || federalSettings?.claimDependents === 0 ? (federalSettings?.claimDependents | numberDecimal:{ decimal: 2 }) : '-'}}</span>
        </p>
        <p *ngIf="federalSettings?.form === 1 && federalSettings?.federalTableId && (federalSettings?.additionalIncome || federalSettings?.additionalIncome === 0)">
          <span class="label">Federal Add. Income </span>
          <span class="value"><span class="dollar-sign">$</span>{{federalSettings?.additionalIncome || federalSettings?.additionalIncome === 0 ? (federalSettings?.additionalIncome | numberDecimal:{ decimal: 2 }) : '-'}}</span>
        </p>
        <p *ngIf="federalSettings?.form === 1 && federalSettings?.federalTableId && (federalSettings?.additionalDeductions || federalSettings?.additionalDeductions === 0)">
          <span class="label">Federal Add. Deductions </span>
          <span class="value"><span class="dollar-sign">$</span>{{federalSettings?.additionalDeductions || federalSettings?.additionalDeductions === 0 ? (federalSettings?.additionalDeductions | numberDecimal:{ decimal: 2 }) : '-'}}</span>
        </p>
      </div>
      <p *ngIf="federalSettings?.form === 0 && federalSettings?.federalTableId">
        <span class="label">Federal Exemption </span>
        <span class="value">{{federalSettings?.exemption || federalSettings?.exemption === 0 ? (federalSettings?.exemption | numberDecimal) : '-'}}</span>
      </p>
      <p>
        <span class="label">Federal Add. W/H </span>
        <span class="value" *ngIf="federalSettings?.additionalWithHolding; else spanFederal"><span *ngIf="federalSettings?.additionalWithHolding === 1" class="dollar-sign">$</span>{{federalSettings?.additionalWithHolding === 0 ? '-' : federalSettings?.value | numberDecimal:{ decimal: 2 } }}<span *ngIf="federalSettings?.additionalWithHolding === 2" class="dollar-sign"> %</span></span>
        <ng-template #spanFederal>
          <span>-</span>
        </ng-template>
      </p>
    </div>
    <div class="column-value" *ngIf="indexState >= 0">
      <div class="title">
        State
      </div>
      <p >
        <span class="label">State W/H </span>
        <span class="value">{{stateSettings?.federalTableName ? stateSettings?.federalTableName : '-'}}</span>
      </p>
      <div class="formtype2" *ngIf="stateSettings?.form === 1" >
        <p *ngIf="stateSettings?.form === 1 && stateSettings?.federalTableId && (stateSettings?.income || stateSettings?.income === 0)">
          <span class="label">State Income </span>
          <span class="value"><span class="dollar-sign">$</span>{{stateSettings?.income || stateSettings?.income === 0 ? (stateSettings?.income | numberDecimal:{ decimal: 2 }) : '-'}}</span>
        </p>
        <p *ngIf="stateSettings?.form === 1 && stateSettings?.federalTableId && (stateSettings?.claimDependents || stateSettings?.claimDependents === 0)">
          <span class="label">State Claim Dep. </span>
          <span class="value"><span class="dollar-sign">$</span>{{stateSettings?.claimDependents || stateSettings?.claimDependents === 0 ? (stateSettings?.claimDependents | numberDecimal:{ decimal: 2 }) : '-'}}</span>
        </p>
        <p *ngIf="stateSettings?.form === 1 && stateSettings?.federalTableId && (stateSettings?.additionalIncome || stateSettings?.additionalIncome === 0)">
          <span class="label">State Add. Income </span>
          <span class="value"><span class="dollar-sign">$</span>{{stateSettings?.additionalIncome || stateSettings?.additionalIncome === 0 ? (stateSettings?.additionalIncome | numberDecimal:{ decimal: 2 }) : '-'}}</span>
        </p>
        <p *ngIf="stateSettings?.form === 1 && stateSettings?.federalTableId && (stateSettings?.additionalDeductions || stateSettings?.additionalDeductions === 0)">
          <span class="label">State Add. Deductions </span>
          <span class="value"><span class="dollar-sign">$</span>{{stateSettings?.additionalDeductions || stateSettings?.additionalDeductions === 0 ? (stateSettings?.additionalDeductions | numberDecimal:{ decimal: 2 }) : '-'}}</span>
        </p>
      </div>
      <p *ngIf="stateSettings?.form === 0 && stateSettings?.federalTableId">
        <span class="label">State Exemption </span>
        <span class="value">{{stateSettings?.exemption ? (stateSettings?.exemption | numberDecimal) : '-'}}</span>
      </p>
      <p>
        <span class="label">State Add. W/H </span>
        <span class="value" *ngIf="stateSettings?.additionalWithHolding; else spanState"><span *ngIf="stateSettings?.additionalWithHolding === 1" class="dollar-sign">$</span>{{stateSettings?.additionalWithHolding === 0 ? '-' : stateSettings?.value | numberDecimal:{ decimal: 2 } }}<span *ngIf="stateSettings?.additionalWithHolding === 2" class="dollar-sign"> %</span></span>
        <ng-template #spanState>
          <span>-</span>
        </ng-template>
      </p>
    </div>
  </div>
</div>
