import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[cellEdit]',
})
export class CellEdit {
  @Input() columnName!: string;

  constructor(public templateRef: TemplateRef<any>) {}
}
