import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import {
  filter,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { DateTime } from 'luxon';

import { isEmpty } from '@ptg-shared/utils/string.util';
import { Option } from '@ptg-shared/controls/select/select.component';
import {
  AbstractControlStatus,
  Address,
} from '@ptg-shared/types/models/common.model';
import { AddressPipe } from '@ptg-shared/pipes/address.pipe';
import { PersonNamePipe } from '@ptg-shared/pipes/person-name.pipe';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import {
  isObjectEmpty,
  lowercaseFirstCharacterKeys,
} from '@ptg-shared/utils/common.util';
import * as fromNextPayment from '@ptg-member/store/reducers';
import * as NextPaymentActions from '@ptg-member/store/actions/next-payment.actions';
import {
  MemberAddressItem,
  ParticipantEarning,
  RepresentativePayee,
} from '@ptg-member/types/models';
import * as fromReducer from '@ptg-reducers';
import { MemberState } from '@ptg-member/store/reducers';

import { DeductionType } from '../../../payroll/types/enums';

@Component({
  selector: 'ptg-add-off-cycle-payment-dialog',
  templateUrl: './add-off-cycle-payment-dialog.component.html',
  styleUrls: ['./add-off-cycle-payment-dialog.component.scss'],
})
export class AddOffCyclePaymentDialogComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<void>();
  memberAddressItems: MemberAddressItem[] = [];
  addressOptions: Option[] = [];
  representativePayees: RepresentativePayee[] | undefined = [];
  representativePayeeOptions: Option[] = [];
  offCycleForm!: FormGroup;
  formSubmit$ = new Subject<void>();
  grossPayment: number = 0;
  isShowWithholdTaxes: boolean = false;
  participantSetting!: any;

  constructor(
    public dialogRef: MatDialogRef<AddOffCyclePaymentDialogComponent>,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    private store: Store<MemberState>,
    public dialog: MatDialog,
    private addressPipe: AddressPipe,
    private personNamePipe: PersonNamePipe,
    private fb: FormBuilder
  ) {}

  get earningControls() {
    return (this.offCycleForm.controls['earnings'] as FormArray).controls;
  }

  ngOnInit(): void {
    this.checkSubmitForm();
    this.getData();
    this.getTaxDeductions();
    this.getNextPaymentData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getData() {
    this.store.dispatch(NextPaymentActions.getMemberAddressItems());
    this.store.dispatch(
      NextPaymentActions.getParticipantNextPaymentRequest({ section: 0 })
    );
    this.store.dispatch(NextPaymentActions.getRepresentativePayees());
  }

  setMemberAddress(memberAddressItems: MemberAddressItem[] | undefined) {
    this.memberAddressItems = (memberAddressItems || []).map((addressItem) => {
      return {
        ...addressItem,
        address: lowercaseFirstCharacterKeys(
          JSON.parse(addressItem.address as any)
        ) as Address,
      };
    });
    this.addressOptions = this.memberAddressItems.map((item) => {
      return {
        value: {
          address: item.address,
          clientKey: item.clientKey,
          itemKey: item.itemKey,
          propertyKey: item.propertyKey,
          index: item.index,
        },
        displayValue: this.addressPipe.transform(item.address as any, [], true),
        valueDescription: `${item.itemName} / ${item.propertyName}`,
      };
    });
  }

  setRepresentativePayees(
    representativePayees: RepresentativePayee[] | undefined
  ) {
    this.representativePayees = representativePayees || [];
    this.representativePayeeOptions = this.representativePayees
      .filter(
        (item) =>
          !isObjectEmpty(item.address) &&
          !isObjectEmpty(item.name) &&
          !isEmpty(item.type)
      )
      .map((item) => {
        return {
          value: item,
          displayValue: this.personNamePipe.transform(
            lowercaseFirstCharacterKeys(item.name as any) as any
          ),
          valueDescription: `${item.type} / ${this.addressPipe.transform(
            lowercaseFirstCharacterKeys(item.address as any) as any,
            [],
            true
          )}`,
        };
      });
  }

  getNextPaymentData() {
    combineLatest([
      this.store.select(fromNextPayment.selectParticipantNextPaymentSetting),
      this.store.select(fromNextPayment.selectMemberAddressItemsState),
      this.store.select(fromNextPayment.selectRepresentativePayeesState),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((nextPaymentData) => {
        this.participantSetting = nextPaymentData[0];
        this.setMemberAddress(nextPaymentData[1]);
        this.setRepresentativePayees(nextPaymentData[2]);
        this.initFormGroup();
        this.checkEarningsValueChange();
        this.calculateGrossPayment();
      });
  }

  initFormGroup() {
    const setting = this.participantSetting?.settings;
    this.offCycleForm = this.fb.group({
      participantId: this.fb.control(setting?.participantId),
      createdBy: this.fb.control(''),
      reason: this.fb.control(''),
      withTaxes: this.fb.control(false),
      paymentAddress: this.fb.control(
        setting?.paymentAddress || null,
        Validators.required
      ),
      representativePayee: this.fb.control(
        setting?.representativePayee || null
      ),
      payPeriod: this.fb.control(
        setting?.payPeriod || null,
        Validators.required
      ),
      earnings: this.initEarningArray(),
    });
  }

  checkEarningsValueChange() {
    (
      this.offCycleForm.controls['earnings'] as FormArray
    ).valueChanges.subscribe((value) => {
      (this.offCycleForm.controls['earnings'] as FormArray).markAllAsTouched();
      this.earningControls.forEach((earningControl) => {
        (earningControl as FormGroup).controls['value'].updateValueAndValidity({
          emitEvent: false,
        });
      });
      this.calculateGrossPayment();
    });
  }

  initEarningArray() {
    const formArray = this.fb.array([]);
    (this.participantSetting?.earnings?.earnings || []).forEach(
      (earning: ParticipantEarning) => {
        formArray.push(
          this.fb.group({
            earningItemId: this.fb.control(earning.earningItemId),
            id: this.fb.control(earning.id),
            manualAdjustmented: this.fb.control(earning.manualAdjustmented),
            name: this.fb.control(earning.name),
            order: this.fb.control(earning.order),
            value: this.fb.control(earning.value, this.validateEarningValue()),
          })
        );
      }
    );
    return formArray;
  }

  checkSubmitForm() {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.offCycleForm.markAllAsTouched();
        }),
        switchMap(() =>
          this.offCycleForm.statusChanges.pipe(
            startWith(this.offCycleForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter((status) => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.saveValue();
      });
  }

  saveValue() {
    const formValue = this.offCycleForm.getRawValue();
    const body = {
      ...formValue,
      earnings: formValue.earnings.map((item: ParticipantEarning) => {
        item.value = item.value || 0;
        return item;
      }),
      representativePayee: formValue.representativePayee || null,
      paymentAddress: formValue.paymentAddress || null,
      payPeriod: formValue.payPeriod || null
    };
    this.store.dispatch(
      NextPaymentActions.createOffCyclePaymentRequest({
        body,
      })
    );
    this.dialogRef.close();
  }

  onCancel() {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }

  changeRepresentativePayee() {
    if (this.offCycleForm.controls['representativePayee'].value) {
      const representativePayeeValue =
        this.offCycleForm.controls['representativePayee'].value;
      const value = {
        clientKey: representativePayeeValue.clientKey,
        itemKey: representativePayeeValue.itemKey,
        propertyKey: representativePayeeValue.propertyKey,
        index: representativePayeeValue.index,
        address: representativePayeeValue.address,
      };
      this.offCycleForm.controls['paymentAddress']?.setValue(value);
      this.addressOptions = [
        {
          value,
          displayValue: this.addressPipe.transform(
            representativePayeeValue.address,
            [],
            true
          ) as any,
        },
      ];
      this.offCycleForm.controls['paymentAddress']?.disable();
      return;
    }
    this.offCycleForm.controls['paymentAddress']?.enable();
    this.addressOptions = this.memberAddressItems.map((item) => {
      return {
        value: item,
        displayValue: this.addressPipe.transform(item.address as any, [], true),
        valueDescription: `${item.itemName} / ${item.propertyName}`,
      };
    });
    this.offCycleForm.controls['paymentAddress']?.setValue(
      this.participantSetting?.settings?.paymentAddress
    );
  }

  getTaxDeductions() {
    this.store
      .pipe(
        select(fromNextPayment.selectPaymentDeductionsState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((paymentDeductions) => {
        this.isShowWithholdTaxes = (paymentDeductions || []).some(
          (deduction) => {
            const today = DateTime.now();
            const effectiveStartDate = DateTime.fromISO(
              deduction.effectiveStartDate
            );
            const effectiveEndDate = DateTime.fromISO(
              deduction.effectiveEndDate
            );
            return (
              deduction.deductionType === DeductionType.Tax &&
              today >= effectiveStartDate &&
              today <= effectiveEndDate
            );
          }
        );
      });
    this.store
      .pipe(select(fromReducer.selectCurrentFundState))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((currentFund) => {
        const sortType = 1;
        const sortField = 'DeductionTypeTitle';
        this.store.dispatch(
          NextPaymentActions.getPaymentDeductionsRequest({
            query: { sortField, sortType },
            clientId: currentFund.id,
          })
        );
      });
  }

  calculateGrossPayment() {
    if (!(this.offCycleForm.controls['earnings'] as FormArray).valid) {
      return;
    }
    this.grossPayment = this.earningControls.reduce((result, control) => {
      result += Number(control?.value?.value || 0);
      return result;
    }, 0);
  }

  validateEarningValue(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        !(this.offCycleForm?.controls['earnings'] as FormArray)?.controls
          ?.length
      ) {
        return null;
      }
      if (
        this.earningControls.every(
          (earningControl) =>
            (earningControl as FormGroup).controls['value'].value === null
        )
      ) {
        return {
          crossEarningsValidate: 'At least 1 earnings item is required.',
        };
      }
      if (
        this.earningControls.every(
          (earningControl) =>
            (earningControl as FormGroup).controls['value'].value === null ||
            (earningControl as FormGroup).controls['value'].value === 0
        )
      ) {
        return {
          crossEarningsValidate:
            'At least 1 earnings item must be greater than 0.',
        };
      }
      return null;
    };
  }

  compareWithFunction(o1: any, o2: any) {
    return o1 && o2
      ? o1?.propertyKey === o2?.propertyKey &&
          o1?.itemKey === o2?.itemKey &&
          o1?.index === o2?.index
      : o1 === o2;
  }
}
