<div
  class="profile-navigation-configuration"
  id="profile-navigation-configuration"
>
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
    [functionButtons]="functionButtons"
    (emitFunction)="emitBreadcrumdFunction($event)"
  ></ptg-breadcrumb>

  <div *ngIf="bannerType" class="p-6">
    <ptg-banner [(bannerType)]="bannerType" [message]="message"></ptg-banner>
  </div>

  <ptg-grid
    [data]="memberNavigationsData"
    [columns]="columns"
    [isLoading]="isLoading"
    [paginable]="false"
    [errorMessage]="errorMsg"
    [hideScrollbar]="false"
    [allowDragDrop]="true"
    (rowDrop)="changeItemIndex($event)"
    notFoundMessage="No Navigation Configuration to Display"
  >
    <ng-template cellContent columnName="statusList" let-row>
      <ptg-list-status [statusList]="row.statusList"></ptg-list-status>
    </ng-template>
    <ng-template cellContent columnName="color" let-row>
      <div class="color-div" [style.background]="row.color"></div>
    </ng-template>
    <ng-template cellContent columnName="disabled" let-row>
      <mat-icon *ngIf="row.disabled" style="color: #cbc89f">lock</mat-icon>
    </ng-template>
    <ng-template cellContent columnName="action" let-row>
      <ptg-button
        buttonName="Edit"
        classInput="edit-button"
        (clickButton)="editRecord(row)"
      >
        <mat-icon>edit</mat-icon>
      </ptg-button>
      <ptg-button
        buttonName="Remove"
        classInput="remove-button"
        (clickButton)="removeRecord(row)"
      >
        <mat-icon>delete_forever</mat-icon>
      </ptg-button>
    </ng-template>
  </ptg-grid>
</div>
