<div class="report-config">
  <div class="title">
    <div class="title-name">
      {{title}}
    </div>
  </div>
  <form (ngSubmit)="submitForm()">
    <div class="input-container">
      <ptg-radio-button 
        [controlField]="chooseType" 
        (changeValue)="changeChooseType($event)"
        [listOption]="listChooseType" 
        [isObject]="true">
      </ptg-radio-button>
      <ptg-select 
        *ngIf="chooseType.value == 0" 
        placeholder="Category" 
        [controlField]="category"
        [listData]="listCategories" 
        [isOptionObj]="true" 
        [isSetMaxWidthSelect]="true"
        errorRequire="Category is required.">
      </ptg-select>
      <ptg-textbox 
        *ngIf="chooseType.value == 1" 
        [controlField]="category" 
        placeholder="Category" 
        [hasLabel]="true"
        [maxLength]="255"
        [isRequired]="true"
        errorMaxLength='Exceed the 255 character limit.'
        [errorAsync]="category.value + ' already exists.'">
      </ptg-textbox>
      <ptg-textbox 
        [controlField]="reportName" 
        placeholder="Report Name" 
        [hasLabel]="true"
        [isRequired]="true"
        [maxLength]="255"
        errorMaxLength='Exceed the 255 character limit.'
        [asyncFn]="{ 
          function: participantReportService.checkReportNameExist, 
          fieldCheck: 'reportName' 
        }"
        [errorAsync]="reportName.value + ' already exists.'">
      </ptg-textbox>
      <ptg-input-area 
        [controlField]="description"
        placeholder="Description" 
        [hasLabel]="true"         
        [maxLength]="255"
        errorMaxLength='Exceed the 255 character limit.'>
      </ptg-input-area>
    </div>
      <ptg-upload 
        #fileDocument
        (onUploadAction)="uploadFile($event)" 
        (deleteFile)="removeFile()" 
        class="label-small"
        [checkPattern]="checkPatternGraphic" 
        [errMsg]="'Unsupported file name or file format.'"
        [typeFile]="typeFileGraphic" 
        [noUpload]="true" 
        [isRequired]="true"
        [accept]="acceptGraphic"
        errRequired="Template is required.">
      </ptg-upload>
    <span>
      <img 
        *ngIf="data.logoUri" 
        [src]="data.logoUri" 
        alt="logo-tenant" 
        id="logo-edit"
        style="height: 60px; width: 60px" />
    </span>
    <div class="wrap-btn">
      <button 
        mat-raised-button type="submit" 
        style="background-color: #408bf9; color: #fff">
        Save
      </button>
      <button 
        type="button" 
        style="background-color: #ACABAB; color: #fff; border-width: 0px" 
        (click)="onCancel()">
        Cancel
      </button>
    </div>
  </form>
</div>
