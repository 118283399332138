import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Sort } from '@angular/material/sort';

import { BaseComponent } from '@ptg-shared/components';
import { ACTION_COLUMN, Column, ColumnType, Row } from '@ptg-shared/controls/grid';
import { LayoutActions } from '@ptg-shared/layout/actions';
import * as fromMember from '../../store/reducers';
import {
  ACTION,
  SORT_TYPE,
} from '@ptg-shared/constance';
import * as fromReducer from '@ptg-reducers';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { showBanner } from '@ptg-shared/utils/common.util';
import { VIEW_TYPE } from '@ptg-shared/constance/common.const';
import { NewView, View } from '@ptg-member/types/models';
import { AddViewComponent } from '@ptg-member/components/add-view/add-view.component';
import { createViewSelector, getListSelector, getRemoveAccess, removeViewSelector } from '@ptg-member/store/selectors';
import { clearViewState, getRemoveAccesssAction, getViewList, removeView } from '@ptg-member/store/actions/view.actions';

@Component({
  selector: 'ptg-view-list',
  templateUrl: './view-list.component.html',
  styleUrls: ['./view-list.component.scss'],
})
export class ViewListComponent extends BaseComponent {
  VIEW_TYPE = VIEW_TYPE;
  bannerType: BannerType = BannerType.Hidden;
  message = '';
  crrViewDeleteID!: string ;
  viewsData: (View & Row)[] = [];
  columns: Column[] = [
    {
      name: 'viewName',
      header: {
        title: 'View Name',
      },
      truncate: true,
      sortable: true
    },
    {
      name: 'type',
      header: {
        title: 'Type',
      },
      truncate: true,
      sortable: true,
      cell: (row) => {
        return VIEW_TYPE[row.type];
    }
    },
    {
      name: 'entityName',
      header: {
        title: 'Entity',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: 'cardCount',
      header: {
        title: 'Total Cards',
      },
      type: ColumnType.Decimal,
      templateArgs: { 
        decimal: 0 
      },
      truncate: true,
      sortable: true
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      width: '170px',
    },
  ];
  fundId: string = '';
  sortInfo?: Sort;
  isLoading: boolean = true;
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Participant List',
      url: '/member',
    },
    {
      name: 'View List',
    },
  ];

  constructor(
    private dialog: MatDialog,
    public route: ActivatedRoute,
    private store: Store<fromReducer.State>,
    private memberStore: Store<fromMember.MemberState>,
    public router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getData();

    this.bannerType = BannerType.Hidden

    this.store.dispatch(LayoutActions.hiddenSideMenu());
    const prevUrl = this.route.snapshot.queryParams.prevUrl;
    if (prevUrl === 'member') {
      this.listBreadcrumbs = [
        {
          name: 'Participant List',
          url: '/member',
        },
        {
          name: 'View List',
        },
      ];
    }
    
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.fundId = params.fundId;
    });

    this.memberStore.pipe(select(getListSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data && !data.isLoading) {
        this.isLoading = false;
        this.viewsData = (data.payload ?? []).map(item => {
          return {
            ...item,
            cardCount: item.type === VIEW_TYPE.System ? '-' : item.cardCount
          }
        });
      }
    });

    this.memberStore.pipe(select(getRemoveAccess), takeUntil(this.unsubscribe$)).subscribe((data) => {
      const { listEntityNavigation, listProfileConfigView } = data?.payload ?? {};

      if( data?.success && !data?.isLoading ) {
        if (listEntityNavigation?.length || listProfileConfigView?.length) {
          this.dialog.open(ConfirmPopupComponent, {
            panelClass: 'confirm-popup',
            autoFocus: false,
            data: {
              title: 'Attention',
              text: `
                This View cannot be removed since it is being used in the following Profiles or Navigations: <br/>
                ${(listProfileConfigView ?? []).map(
                  (profileItem) => `<a class="capitalize" target="_blank" href="/member/profiles/${profileItem.id}">${profileItem.name}</a>`
                ).join(", ")}
                ${listProfileConfigView?.length ? ', ' : ''}
                ${(listEntityNavigation ?? []).map(
                 (entityNavigationItem) => `<a class="capitalize" target="_blank" href="/member/participant-navigation-configuration/${entityNavigationItem.id}">${entityNavigationItem.name}</a>`
                ).join(", ")}
              `,
              type: ConfirmType.Warning,
              cancelButtonTitle: 'Close',
              hideConfirmButton: true,
            },
          })
        } else {
          const dialogRef = this.dialog.open(ConfirmPopupComponent, {
            panelClass: 'confirm-popup',
            autoFocus: false,
            data: {
              title: 'Remove Item',
              text: 'Are you sure you want to remove this View?',
              type: ConfirmType.Delete,
            },
          });
      
          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              this.isLoading = true;
              this.memberStore.dispatch(removeView({ id: this.crrViewDeleteID }));
            }
          });
        }
      }
    });

    this.memberStore.pipe(select(createViewSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if( data?.success ) {
        this.getData();
      }
    });

    this.memberStore.pipe(select(removeViewSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if( data?.success ) {
        this.getData();
        showBanner.call(this, BannerType.Success, 'View', ACTION.REMOVE);
      } else if (data?.error) {
        showBanner.call(this, BannerType.Fail, 'View', ACTION.REMOVE);
      }
    },);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.memberStore.dispatch(clearViewState());
  }

  sortChange(event: Sort) {
    this.sortInfo = event;
    this.getData();
  }

  openForm(row?: NewView): void {
    this.dialog.open(AddViewComponent, {
      panelClass: 'edit-popup',
      disableClose: true,
      autoFocus: false,
      width: '856px',
      height: 'auto',
      data: {
        view: row ? row : null
      }
    });
  }

  editView(row?: NewView):void  {
    this.router.navigateByUrl(`/member/view/${row?.id}`);
  }

  onClickRemoveView(row: View): void {
    if (row?.id) {
      this.crrViewDeleteID = row.id;
      this.store.dispatch(getRemoveAccesssAction({ id: row?.id }));
    }
  }

  datalessDisplay(row: View): void {
    this.router.navigateByUrl(`/member/view/${row?.id}/dataless-display`);
  }

  getData(): void {
    this.isLoading = true;
    let query = {
      sortField: '',
      sortType: 0
    };
    if (this.sortInfo) {
      const field = this.sortInfo.active[0].toUpperCase() + this.sortInfo.active.substr(1);
      query = {
        sortField: this.sortInfo.direction ? field : '',
        sortType: this.sortInfo.direction === 'asc' ? SORT_TYPE.ASC : SORT_TYPE.DESC
      };
    }
    this.memberStore.dispatch(getViewList({ query: query }));
  }
}
