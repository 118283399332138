import { createAction, props } from '@ngrx/store';
import { PeriodList, ReportingCalendarQuery, FiscalYearList, FiscalYearRequest } from '../models/reporting-calendar.model';

export const YearListRequest = '[YearList/API] Send Request';
export const YearListFailure = '[YearList/API] Failure';
export const YearListSuccess = '[YearList/API] Success';
export const PeriodListRequest = '[PeriodList/API] Send Request';
export const PeriodListFailure = '[PeriodList/API] Failure';
export const PeriodListSuccess = '[PeriodList/API] Success';
export const AddFiscalYearRequest = '[AddFiscalYear/API] Send Request';
export const AddFiscalYearFailure = '[AddFiscalYear/API] Failure';
export const AddFiscalYearSuccess = '[AddFiscalYear/API] Success';
export const UpdateFiscalYearRequest = '[UpdateFiscalYear/API] Send Request';
export const UpdateFiscalYearFailure = '[UpdateFiscalYear/API] Failure';
export const UpdateFiscalYearSuccess = '[UpdateFiscalYear/API] Success';
export const ClearReportingCalendarState = '[ReportingCalendar] Clear';

export const getYearList = createAction(
  YearListRequest,
  props<{ employerId: string, query: ReportingCalendarQuery }>()
);
export const getYearListSuccess = createAction(
  YearListSuccess,
  props<{ yearList: FiscalYearList }>()
);
export const getYearListFailure = createAction(
  YearListFailure,
  props<{ error?: any }>()
);

export const getPeriodList = createAction(
  PeriodListRequest,
  props<{employerId: string, yearId: string, query?: ReportingCalendarQuery }>()
);
export const getPeriodListSuccess = createAction(
  PeriodListSuccess,
  props<{ periodList: PeriodList }>()
);
export const getPeriodListFailure = createAction(
  PeriodListFailure,
  props<{ error?: any }>()
);

export const addFiscalYear = createAction(
  AddFiscalYearRequest,
  props<{ employerId: string, fiscalYearRequest: FiscalYearRequest }>()
);
export const addFiscalYearSuccess = createAction(
  AddFiscalYearSuccess,
  props<{ yearId: string, yearName?: string}>()
);
export const addFiscalYearFailure = createAction(
  AddFiscalYearFailure,
  props<{ errorMsg: string, yearName?: string}>()
);

export const updateFiscalYear = createAction(
  UpdateFiscalYearRequest,
  props<{ employerId: string, fiscalYearRequest: FiscalYearRequest }>()
);
export const updateFiscalYearSuccess = createAction(
  UpdateFiscalYearSuccess,
  props<{ yearId?: string, yearName?: string }>()
);
export const updateFiscalYearFailure = createAction(
  UpdateFiscalYearFailure,
  props<{ errorMsg: string, yearName?: string }>()
);

export const clearReportingCalendarState = createAction(
    ClearReportingCalendarState
);