
export const API_ERROR_TYPE  = 'CommandException'
export const MODULE_KEY  = 'file-module'
export enum FileType {
    doc = 'doc',
    docx = 'docx',
    csv = 'csv',
    xls = 'xls',
    xlsx = 'xlsx',
    ppt = 'ppt',
    pptx = 'pptx',
    pdf = 'pdf',
    png = 'png',
    jpg = 'jpg',
    jpeg = 'jpeg'
}

export const ListFileType = [
    {
        value: FileType.doc,
        displayValue: 'doc'
    },
    {
        value: FileType.docx,
        displayValue: 'docx'
    },
    {
        value: FileType.csv,
        displayValue: 'csv'
    },
    {
        value: FileType.xls,
        displayValue: 'xls'
    },
    {
        value: FileType.xlsx,
        displayValue: 'xlsx'
    },
    {
        value: FileType.ppt,
        displayValue: 'ppt'
    },
    {
        value: FileType.pptx,
        displayValue: 'pptx'
    },
    {
        value: FileType.pdf,
        displayValue: 'pdf'
    },
    {
        value: FileType.png,
        displayValue: 'png'
    },
    {
        value: FileType.jpg,
        displayValue: 'jpg'
    },
    {
        value: FileType.jpeg,
        displayValue: 'jpeg'
    },
];

export const GENERATE_DOCUMENT_STATUS = {
  COMPLETED: 'Completed'
}

export enum ParticipantDocumentType {
  DeathCertificate = 0,
  MarriedCertificate = 1,
  InvoiceVoucher= 2,
  Other= 3,
  AnnualPensionCertification = 4,
  BirthCertificate = 5,
  DocumentaryEvidence = 6,
  HistoricalScannedRemittance = 7,
  MarriageCertificate = 8,
  MedicalRecords = 9,
  QdroForms = 10,
  QdroDocuments = 11,
  Retire_RehireForm= 12,
  ScannedRetirementForms = 13,
}

export enum ParticipantGenerateDocumentType {
  NoticeofRetirement  = 1000,
  Age65Letter = 1001,
  PensionCertificationReport= 1002,
  CurrentAFRSDisbursementReport= 1003,
  PensionConfirmationLetter = 1004,
}

export enum EmployerDocumentType {
  Other = 10000,
  CertificationForm= 10001,
  Retire_RehireForm = 10002,
  InvoiceVoucher = 10003,
}

export const PARTICIPANT_DOCUMENT_TYPE_OPTIONS = [
    {
        value: ParticipantDocumentType.DeathCertificate,
        displayValue: 'Death Certificate'
    },
    {
        value: ParticipantDocumentType.MarriedCertificate,
        displayValue: 'Married Certificate'
    },
    {
        value: ParticipantDocumentType.InvoiceVoucher,
        displayValue: 'Invoice Voucher'
    },
    {
        value: ParticipantDocumentType.Other,
        displayValue: 'Other'
    },
];

export const PARTICIPANT_DOCUMENT_TYPE_OPTIONS_FOR_BVFF = [
  {
    value: ParticipantDocumentType.AnnualPensionCertification,
    displayValue: 'Annual Pension Certification'
  },
  {
    value: ParticipantDocumentType.BirthCertificate,
    displayValue: 'Birth Certificate'
  },
  {
    value: ParticipantDocumentType.DeathCertificate,
    displayValue: 'Death Certificate'
  },
  {
    value: ParticipantDocumentType.DocumentaryEvidence,
    displayValue: 'Documentary Evidence'
  },
  {
    value: ParticipantDocumentType.HistoricalScannedRemittance,
    displayValue: 'Historical Scanned Remittance'
  },
  {
    value: ParticipantDocumentType.InvoiceVoucher,
    displayValue: 'Invoice Voucher'
  },
  {
    value: ParticipantDocumentType.MarriageCertificate,
    displayValue: 'Marriage Certificate'
  },
  {
    value: ParticipantDocumentType.MedicalRecords,
    displayValue: 'Medical Records'
  },
  {
    value: ParticipantDocumentType.QdroForms,
    displayValue: 'QDRO Forms'
  },
  {
    value: ParticipantDocumentType.Retire_RehireForm,
    displayValue: 'Retire/Rehire Form'
  },
  {
    value: ParticipantDocumentType.ScannedRetirementForms,
    displayValue: 'Scanned Retirement Forms'
  },
  {
    value: ParticipantDocumentType.Other,
    displayValue: 'Other'
  },
];

export const EMPLOYER_DOCUMENT_TYPE_OPTIONS = [
  {
    value: EmployerDocumentType.CertificationForm,
    displayValue: 'Certification Form'
  },
  {
    value: EmployerDocumentType.InvoiceVoucher,
    displayValue: 'Invoice Voucher'
  },
  {
    value: EmployerDocumentType.Retire_RehireForm,
    displayValue: 'Retire/Rehire Form'
  },
  {
    value: EmployerDocumentType.Other,
    displayValue: 'Other'
  },
];
