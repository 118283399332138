import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { ControlsModule } from '@ptg-shared/controls/controls.module';
import { SharedModule } from '@ptg-shared/shared.module';

import * as fromBulkUpdate from './reducers';
import { BulkUpdateEffects } from './effects/bulk-update.effects';
import { BulkUpdateComponent } from './containers/bulk-update/bulk-update.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { FileResultComponent } from './components/file-result/file-result.component';

@NgModule({
    declarations: [UploadFileComponent, FileResultComponent, BulkUpdateComponent],
    exports: [BulkUpdateComponent],
    imports: [
        SharedModule,
        ControlsModule,
        /**
         * StoreModule.forFeature is used for composing state
         * from feature modules. These modules can be loaded
         * eagerly or lazily and will be dynamically added to
         * the existing state.
         */
        StoreModule.forFeature(fromBulkUpdate.bulkUpdateModuleFeatureKey, fromBulkUpdate.reducers),
        /**
         * Effects.forFeature is used to register effects
         * from feature modules. Effects can be loaded
         * eagerly or lazily and will be started immediately.
         *
         * All Effects will only be instantiated once regardless of
         * whether they are registered once or multiple times.
         */
        EffectsModule.forFeature([BulkUpdateEffects]),
    ]
})
export class BulkUpdateModule {}
