import { createAction, props } from "@ngrx/store";
import { NewRole, RolesData, RolesQuery, RolesQueryByProtalType } from "../models/roles.model";

export const RolesRequest = '[Roles/API] Send Request';
export const RolesFailure = '[Roles/API] Failure';
export const RolesSuccess = '[Roles/API] Success';

export const RolesByPortalTypeRequest = '[RolesByPortalType/API] Send Request';
export const RolesByPortalTypeFailure = '[RolesByPortalType/API] Failure';
export const RolesByPortalTypeSuccess = '[RolesByPortalType/API] Success';

export const CreateRoleRequest = '[CreateRole/API] Send Request';
export const CreateRoleFailure = '[CreateRole/API] Failure';
export const CreateRoleSuccess = '[CreateRole/API] Success';

export const UpdateRoleRequest = '[UpdateRole/API] Send Request';
export const UpdateRoleFailure = '[UpdateRole/API] Failure';
export const UpdateRoleSuccess = '[UpdateRole/API] Success';

export const RemoveRoleRequest = '[RemoveRole/API] Send Request';
export const RemoveRoleFailure = '[RemoveRole/API] Failure';
export const RemoveRoleSuccess = '[RemoveRole/API] Success';

export const RolesClear = '[Roles] Clear';

export const GetAllPermissionsRequest = '[AllPermissions/API] Send Request';
export const GetAllPermissionsFailure = '[AllPermissions/API] Failure';
export const GetAllPermissionsSuccess = '[AllPermissions/API] Success';

export const getRoles = createAction(
    RolesRequest,
    props<{ query: RolesQuery }>()
);
export const getRolesSuccess = createAction(
    RolesSuccess,
    props<{ rolesData: RolesData }>()
);
export const getRolesFailure = createAction(
    RolesFailure,
    props<{ error?: any }>()
);

export const getRolesByPortalType = createAction(
    RolesByPortalTypeRequest,
    props<{ query: RolesQueryByProtalType }>()
);
export const getRolesByPortalTypeSuccess = createAction(
    RolesByPortalTypeSuccess,
    props<{ rolesData: RolesData }>()
);
export const getRolesByPortalTypeFailure = createAction(
    RolesByPortalTypeFailure,
    props<{ error?: any }>()
);

export const createRole = createAction(
    CreateRoleRequest,
    props<{ body: NewRole }>()
);
export const createtRoleSuccess = createAction(
    CreateRoleSuccess,
);
export const createRoleFailure = createAction(
    CreateRoleFailure,
    props<{ errorMsg: string }>()
);

export const updateRole = createAction(
    UpdateRoleRequest,
    props<{ body: NewRole }>()
);
export const updateRoleSuccess = createAction(
    UpdateRoleSuccess,
);
export const updateRoleFailure = createAction(
    UpdateRoleFailure,
    props<{ errorMsg: string }>()
);

export const removeRole = createAction(
    RemoveRoleRequest,
    props<{ id: string }>()
);
export const removeRoleSuccess = createAction(
    RemoveRoleSuccess,
);
export const removeRoleFailure = createAction(
    RemoveRoleFailure,
    props<{ errorMsg: string }>()
);

export const rolesClear = createAction(
    RolesClear
);

export const getAllPermissions = createAction(
    GetAllPermissionsRequest,
    props<{ id: string, isGetPortalRole?: boolean }>()
);
export const getAllPermissionsSuccess = createAction(
    GetAllPermissionsSuccess,
    props<{ clientModules: any }>()
);
export const getAllPermissionsFailure = createAction(
    GetAllPermissionsFailure,
    props<{ errorMsg: string }>()
);
