<div class="current-total flex" *ngIf="subHeaderListOnDisplay.length > 0">
  <p class="current-total-txt">{{ subHeaderLeftTitle }}</p>
  <ng-container class="current-total-list flex" *ngFor="let item of subHeaderListOnDisplay; let i = index">
    <div class="current-total-item">
      <p class="label truncate">{{ item?.label }}</p>
      <p class="value flex items-center truncate" [ngSwitch]="item?.subHeader">
        <span class="font-bold truncate" *ngSwitchCase="'Eligible Years'">{{ (item?.value) || '-' }}</span>
        <span class="font-bold truncate" *ngSwitchCase="'Gender'">{{ (item?.value) || '-' }}</span>
        <span class="font-bold truncate" *ngSwitchCase="'Marital Status'">{{ (item?.value) || '-' }}</span>
        <span class="font-bold truncate" *ngSwitchCase="'Member Current Age'">{{ (item?.value) || '-' }}</span>
        <span class="font-bold truncate" *ngSwitchCase="'Member Date of Birth'">{{ (item?.value | date: 'MM/dd/yyyy') || '-' }}</span>
        <span class="font-bold truncate" *ngSwitchCase="'Member Date of Death'">{{ (item?.value | date: 'MM/dd/yyyy') || '-' }}</span>
        <span class="font-bold truncate" *ngSwitchCase="'Number of Payments'">{{ (item?.value) || '-' }}</span>
        <span class="font-bold truncate" *ngSwitchCase="'Spouse Age'">{{ (item?.value) || '-' }}</span>
        <span class="font-bold truncate" *ngSwitchCase="'Years of Service'">{{ (item?.value) || '-' }}</span>
        <span class="font-bold truncate" *ngSwitchDefault>{{ item?.value || '-' }}</span>
      </p>
    </div>
  </ng-container>
</div>