import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import {
  addList,
  addListFailure,
  addListSuccess,
  AddProperty,
  AddPropertyFailure,
  AddPropertySuccess,
  clearAddListState,
  clearAddPropertyState,
  clearCreateEntityStateAction,
  clearDeleteEntityStateAction,
  clearDeletePropertyState,
  clearGetEntityComponentListsStateAction,
  clearGetEntityDetailState,
  clearGetEntityListsStateAction,
  clearGetEntityPropertiesStateAction,
  clearGetListDetailState,
  clearGetPropertyTypesState,
  clearUpdateEntityState,
  clearUpdatePropertyItemState,
  clearUpdateListConfigState,
  createEntityAction,
  createEntityFailureAction,
  createEntitySuccessAction,
  deleteEntityAction,
  deleteEntityFailureAction,
  deleteEntitySuccessAction,
  deleteProperty,
  deletePropertyFailure,
  deletePropertySuccess,
  getEntitiesAction,
  getEntitiesFailureAction,
  getEntitiesSuccessAction,
  getEntityComponentListsAction,
  getEntityComponentListsFailureAction,
  getEntityComponentListsSuccessAction,
  getEntityDetail,
  getEntityDetailFailure,
  getEntityDetailSuccess,
  getEntityListsAction,
  getEntityListsFailureAction,
  getEntityListsSuccessAction,
  getEntityPropertiesAction,
  getEntityPropertiesFailureAction,
  getEntityPropertiesSuccessAction,
  getListDetail,
  getListDetailFailure,
  getListDetailSuccess,
  getPropertyTypes,
  getPropertyTypesFailure,
  getPropertyTypesSuccess,
  updateEntity,
  updateEntityFailure,
  updateEntitySuccess,
  updateListConfig,
  updateListConfigFailure,
  updateListConfigSuccess,
  updatePropertyItem,
  updatePropertyItemFailure,
  updatePropertyItemSuccess,
  getAllEntitiesAction,
  getAllEntitiesFailureAction,
  getAllEntitiesSuccessAction,
  getEntityPropertyDetailAction,
  getEntityPropertyDetailSuccessAction,
  getEntityPropertyDetailFailureAction,
  getAllEntityPropertiesAction,
  getAllEntityPropertiesSuccessAction,
  getAllEntityPropertiesFailureAction,
  clearGetEntityPropertyDetailStateAction,
  setEntityPropertyAction,
  setEntityPropertySuccessAction,
  setEntityPropertyFailureAction,
  clearSetEntityPropertyStateAction,
  getEntityPropertiesForCalculation,
  getEntityPropertiesForCalculationSuccess,
  getEntityPropertiesForCalculationFailure,
  getEntityReferencesAction,
  getEntityReferencesSuccessAction,
  getEntityReferencesFailureAction,
  clearGetEntityReferencesStateAction,
  getEntityInitiationConfigurationAction,
  getEntityInitiationConfigurationSuccessAction,
  getEntityInitiationConfigurationFailureAction,
  clearGetEntityInitiationConfigurationStateAction,
  setEntityInitiationConfigurationAction,
  setEntityInitiationConfigurationSuccessAction,
  setEntityInitiationConfigurationFailureAction,
  clearSetEntityInitiationConfigurationStateAction,
  checkEntityCanRemoveSuccess,
  checkEntityCanRemoveFailure,
  clearCheckEntityCanRemove,
  getEntityListDataByIdAction,
  getEntityListDataByIdFailureAction,
  getEntityListDataByIdSuccessAction,
  deleteEntityListDataAction,
  deleteEntityListDataFailureAction,
  deleteEntityListDataSuccessAction,
  clearDeleteEntityListDataStateAction, clearGetEntityListDataByIdFailureAction, deleteEntityComponent, deleteEntityComponentSuccess, deleteEntityComponentFailure, clearDeleteEntityComponentState, getListBenefit, getListBenefitSuccess, getListBenefitFailure, clearGetListBenefitState, updateBenefitMappingFailure, updateBenefitMappingSuccess, clearUpdateBenefitMappingState,
} from '../actions';
import {
  CheckEntityCanRemoveResponse,
  Entity,
  EntityComponentList,
  EntityList,
  EntityPropertyDetail,
  EntityPropertyForCalculation,
  EntityReference,
  GetEntityDetailResponse,
  GetEntityInitiationConfigurationResponse,
  GetEntityListDataByIdResponse,
  GetEntityPropertiesResponse,
  GetPropertyTypesResponse,
} from '../../services/models';
import { error } from '@angular/compiler/src/util';

export interface EntityState {
  createEntity?: BaseActionState<{ response: any; isContinue: boolean }>;
  updateEntity?: BaseActionState;
  getEntities?: BaseActionState<Entity[]>;
  getAllEntities?: BaseActionState<Entity[]>;
  getEntityDetail?: BaseActionState<GetEntityDetailResponse>;
  addProperty?: BaseActionState<{
    response: any;
    isContinue: boolean;
    propertyType?: number;
  }>;
  addList?: BaseActionState<{ response: any; isContinue: boolean }>;
  deleteProperty?: BaseActionState<{
    entityPropertyId: string;
    propertyName: string;
  }>;
  deleteEntityComponent?: BaseActionState<{
    propertyName: string;
  }>;
  getListDetail?: BaseActionState<any>;
  updateListConfig?: BaseActionState;
  getPropertyTypes?: BaseActionState<GetPropertyTypesResponse>;
  updatePropertyItem?: BaseActionState;
  deleteEntity?: BaseActionState;
  getEntityProperties?: BaseActionState<GetEntityPropertiesResponse>;
  getAllEntityProperties?: BaseActionState<GetEntityPropertiesResponse>;
  getEntityLists?: BaseActionState<EntityList[]>;
  getEntityComponentLists?: BaseActionState<EntityComponentList[]>;
  getEntityPropertyDetail?: BaseActionState<EntityPropertyDetail>;
  getEntityPropertiesForCalculation?: BaseActionState<EntityPropertyForCalculation[]>;
  setEntityProperty?: BaseActionState<{ propertyName: string }>;
  getEntityReferences?: BaseActionState<EntityReference[]>;
  getEntityInitiationConfiguration?: BaseActionState<GetEntityInitiationConfigurationResponse>;
  setEntityInitiationConfiguration?: BaseActionState;
  canRemoveEntity?: BaseActionState<CheckEntityCanRemoveResponse>;
  getEntityListDataById?: BaseActionState<GetEntityListDataByIdResponse>;
  deleteEntityListData?: BaseActionState;
  getListBenefit?: BaseActionState<any>;
  updateBenefitMapping?: BaseActionState;
}

const initialState: EntityState = {};

export const entityReducer = createReducer(
  initialState,
  on(createEntityAction, (state) => ({
    ...state,
    createEntity: {
      isLoading: true,
    },
  })),

  on(createEntityFailureAction, (state: EntityState, { error }) => ({
    ...state,
    createEntity: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),

  on(clearCreateEntityStateAction, (state: EntityState) => ({
    ...state,
    createEntity: undefined,
  })),

  on(getEntitiesAction, (state: EntityState) => ({
    ...state,
    getEntities: {
      isLoading: true,
    },
  })),

  on(getEntitiesFailureAction, (state: EntityState, { error }) => ({
    ...state,
    getEntities: {
      isLoading: false,
      success: false,
      error: error,
      total: 0,
    },
  })),

  on(getEntitiesSuccessAction, (state: EntityState, { response }) => ({
    ...state,
    getEntities: {
      isLoading: false,
      success: true,
      payload: response.entities,
      total: response.total,
    },
  })),

  on(getAllEntitiesAction, (state: EntityState) => ({
    ...state,
    getAllEntities: {
      isLoading: true,
    },
  })),

  on(getAllEntitiesFailureAction, (state: EntityState, { error }) => ({
    ...state,
    getAllEntities: {
      isLoading: false,
      success: false,
      error: error,
      total: 0,
    },
  })),

  on(getAllEntitiesSuccessAction, (state: EntityState, { response }) => ({
    ...state,
    getAllEntities: {
      isLoading: false,
      success: true,
      payload: response.entities,
      total: response.total,
    },
  })),

  on(getEntityDetail, (state) => ({
    ...state,
    getEntityDetail: {
      isLoading: true,
    },
  })),

  on(getEntityDetailSuccess, (state: EntityState, { response }) => ({
    ...state,
    getEntityDetail: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),

  on(getEntityDetailFailure, (state: EntityState, { error }) => ({
    ...state,
    getEntityDetail: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),

  on(clearGetEntityDetailState, (state: EntityState) => ({
    ...state,
    getEntityDetail: undefined,
  })),

  on(updateEntity, (state) => ({
    ...state,
    updateEntity: {
      isLoading: true,
    },
  })),

  on(updateEntitySuccess, (state: EntityState) => ({
    ...state,
    updateEntity: {
      isLoading: false,
      success: true,
    },
  })),

  on(updateEntityFailure, (state: EntityState, { error }) => ({
    ...state,
    updateEntity: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),

  on(clearUpdateEntityState, (state: EntityState) => ({
    ...state,
    updateEntity: undefined,
  })),

  on(AddProperty, (state) => ({
    ...state,
    addProperty: {
      isLoading: true,
    },
  })),
  on(createEntitySuccessAction, (state: EntityState, { response, isContinue }) => ({
    ...state,
    createEntity: {
      isLoading: false,
      success: true,
      payload: {
        response,
        isContinue,
      },
    },
  })),
  on(AddPropertySuccess, (state: EntityState, { response, isContinue, propertyType }) => ({
    ...state,
    addProperty: {
      isLoading: false,
      success: true,
      payload: {
        response,
        isContinue,
        propertyType,
      },
    },
  })),
  on(AddPropertyFailure, (state: EntityState, { error }) => ({
    ...state,
    addProperty: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearAddPropertyState, (state: EntityState) => ({
    ...state,
    addProperty: undefined,
  })),
  on(addList, (state) => ({
    ...state,
    addList: {
      isLoading: true,
    },
  })),
  on(addListSuccess, (state: EntityState, { response, isContinue }) => ({
    ...state,
    addList: {
      isLoading: false,
      success: true,
      payload: {
        isContinue: isContinue,
        response: response,
      },
    },
  })),
  on(addListFailure, (state: EntityState, { error }) => ({
    ...state,
    addList: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearAddListState, (state: EntityState) => ({
    ...state,
    addList: undefined,
  })),
  on(deleteProperty, (state) => ({
    ...state,
    deleteProperty: {
      isLoading: true,
    },
  })),
  on(deletePropertySuccess, (state: EntityState, { entityPropertyId, propertyName }) => ({
    ...state,
    deleteProperty: {
      isLoading: false,
      success: true,
      payload: {
        entityPropertyId,
        propertyName,
      },
    },
  })),
  on(deletePropertyFailure, (state: EntityState, { error }) => ({
    ...state,
    deleteProperty: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearDeletePropertyState, (state: EntityState) => ({
    ...state,
    deleteProperty: undefined,
  })),
  on(deleteEntityComponent, (state) => ({
    ...state,
    deleteEntityComponent: {
      isLoading: true,
    },
  })),
  on(deleteEntityComponentSuccess, (state: EntityState, { propertyName }) => ({
    ...state,
    deleteEntityComponent: {
      isLoading: false,
      success: true,
      payload: {
        propertyName,
      },
    },
  })),
  on(deleteEntityComponentFailure, (state: EntityState, { error }) => ({
    ...state,
    deleteEntityComponent: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearDeleteEntityComponentState, (state: EntityState) => ({
    ...state,
    deleteEntityComponent: undefined,
  })),
  on(getListDetail, (state) => ({
    ...state,
    getListDetail: {
      isLoading: true,
    },
  })),
  on(getListDetailSuccess, (state: EntityState, { response }) => ({
    ...state,
    getListDetail: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getListDetailFailure, (state: EntityState, { error }) => ({
    ...state,
    getListDetail: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetListDetailState, (state: EntityState) => ({
    ...state,
    getListDetail: undefined,
  })),
  on(checkEntityCanRemoveSuccess, (state: EntityState, { response: canRemove }) => ({
    ...state,
    canRemoveEntity: {
      isLoading: false,
      success: true,
      payload: canRemove,
    },
  })),
  on(checkEntityCanRemoveFailure, (state: EntityState, { error }) => ({
    ...state,
    canRemoveEntity: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearCheckEntityCanRemove, (state: EntityState) => ({
    ...state,
    canRemoveEntity: undefined,
  })),
  on(updateListConfig, (state) => ({
    ...state,
    updateListConfig: {
      isLoading: true,
    },
  })),
  on(updateListConfigSuccess, (state: EntityState) => ({
    ...state,
    updateListConfig: {
      isLoading: false,
      success: true,
    },
  })),
  on(updateListConfigFailure, (state: EntityState, { error }) => ({
    ...state,
    updateListConfig: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearUpdateListConfigState, (state: EntityState) => ({
    ...state,
    updateListConfig: undefined,
  })),
  on(getPropertyTypes, (state) => ({
    ...state,
    getPropertyTypes: {
      isLoading: true,
    },
  })),
  on(getPropertyTypesSuccess, (state: EntityState, { response }) => ({
    ...state,
    getPropertyTypes: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getPropertyTypesFailure, (state: EntityState, { error }) => ({
    ...state,
    getPropertyTypes: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetPropertyTypesState, (state: EntityState) => ({
    ...state,
    getPropertyTypes: undefined,
  })),
  on(updatePropertyItem, (state) => ({
    ...state,
    updatePropertyItem: {
      isLoading: true,
    },
  })),
  on(updatePropertyItemSuccess, (state: EntityState) => ({
    ...state,
    updatePropertyItem: {
      isLoading: false,
      success: true,
    },
  })),
  on(updatePropertyItemFailure, (state: EntityState, { error }) => ({
    ...state,
    updatePropertyItem: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearUpdatePropertyItemState, (state: EntityState) => ({
    ...state,
    updatePropertyItem: undefined,
  })),
  on(deleteEntityAction, (state) => ({
    ...state,
    deleteEntity: {
      isLoading: true,
    },
  })),
  on(deleteEntitySuccessAction, (state: EntityState) => ({
    ...state,
    deleteEntity: {
      isLoading: false,
      success: true,
    },
  })),
  on(deleteEntityFailureAction, (state: EntityState, { error }) => ({
    ...state,
    deleteEntity: {
      isLoading: false,
      success: false,
    },
  })),
  on(clearDeleteEntityStateAction, (state: EntityState) => ({
    ...state,
    deleteEntity: undefined,
  })),
  on(getEntityPropertiesAction, (state) => ({
    ...state,
    getEntityProperties: {
      isLoading: true,
    },
  })),
  on(getEntityPropertiesSuccessAction, (state, { response }) => ({
    ...state,
    getEntityProperties: {
      isLoading: false,
      success: true,
      payload: response,
      total: response.total,
    },
  })),
  on(getEntityPropertiesFailureAction, (state, { error }) => ({
    ...state,
    getEntityProperties: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetEntityPropertiesStateAction, (state) => ({
    ...state,
    getEntityProperties: undefined,
  })),
  on(getEntityListsAction, (state) => ({
    ...state,
    getEntityLists: {
      isLoading: true,
    },
  })),
  on(getEntityListsSuccessAction, (state, { response }) => ({
    ...state,
    getEntityLists: {
      isLoading: false,
      success: true,
      payload: response.listItems,
      total: response.total,
    },
  })),
  on(getEntityListsFailureAction, (state, { error }) => ({
    ...state,
    getEntityLists: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetEntityListsStateAction, (state) => ({
    ...state,
    getEntityLists: undefined,
  })),
  on(getEntityComponentListsAction, (state) => ({
    ...state,
    getEntityComponentLists: {
      isLoading: true,
    },
  })),
  on(getEntityComponentListsSuccessAction, (state, { response }) => ({
    ...state,
    getEntityComponentLists: {
      isLoading: false,
      success: true,
      payload: response.listItems,
      total: response.total,
    },
  })),
  on(getEntityComponentListsFailureAction, (state, { error }) => ({
    ...state,
    getEntityComponentLists: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetEntityComponentListsStateAction, (state) => ({
    ...state,
    getEntityComponentLists: undefined,
  })),
  on(getEntityPropertyDetailAction, (state) => ({
    ...state,
    getEntityPropertyDetail: {
      isLoading: true,
    },
  })),
  on(getEntityPropertyDetailSuccessAction, (state, { response }) => ({
    ...state,
    getEntityPropertyDetail: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getEntityPropertyDetailFailureAction, (state, { error }) => ({
    ...state,
    getEntityPropertyDetail: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetEntityPropertyDetailStateAction, (state) => ({
    ...state,
    getEntityPropertyDetail: undefined,
  })),
  on(getEntityPropertiesForCalculation, (state) => ({
    ...state,
    getEntityPropertiesForCalculation: {
      isLoading: true,
    },
  })),
  on(getEntityPropertiesForCalculationSuccess, (state, { response }) => ({
    ...state,
    getEntityPropertiesForCalculation: {
      isLoading: false,
      success: true,
      payload: response.entityProperties,
    },
  })),
  on(getEntityPropertiesForCalculationFailure, (state, { error }) => ({
    ...state,
    getEntityPropertiesForCalculation: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(setEntityPropertyAction, (state) => ({
    ...state,
    setEntityProperty: {
      isLoading: true,
    },
  })),
  on(setEntityPropertySuccessAction, (state, { propertyName }) => ({
    ...state,
    setEntityProperty: {
      isLoading: false,
      success: true,
      payload: {
        propertyName,
      },
    },
  })),
  on(setEntityPropertyFailureAction, (state, { error }) => ({
    ...state,
    setEntityProperty: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearSetEntityPropertyStateAction, (state) => ({
    ...state,
    setEntityProperty: undefined,
  })),
  on(getEntityReferencesAction, (state) => ({
    ...state,
    getEntityReferences: {
      isLoading: true,
    },
  })),
  on(getEntityReferencesSuccessAction, (state, { response }) => ({
    ...state,
    getEntityReferences: {
      isLoading: false,
      success: true,
      payload: response.entities,
    },
  })),
  on(getEntityReferencesFailureAction, (state, { error }) => ({
    ...state,
    getEntityReferences: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetEntityReferencesStateAction, (state) => ({
    ...state,
    getEntityReferences: undefined,
  })),
  on(getAllEntityPropertiesAction, (state) => ({
    ...state,
    getAllEntityProperties: {
      isLoading: true,
    },
  })),
  on(getAllEntityPropertiesSuccessAction, (state, { response }) => ({
    ...state,
    getAllEntityProperties: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getAllEntityPropertiesFailureAction, (state, { error }) => ({
    ...state,
    getAllEntityProperties: {
      isLoading: false,
      success: false,
      payload: error,
    },
  })),
  on(getEntityInitiationConfigurationAction, (state) => ({
    ...state,
    getEntityInitiationConfiguration: {
      isLoading: true,
    },
  })),
  on(getEntityInitiationConfigurationSuccessAction, (state, { response }) => ({
    ...state,
    getEntityInitiationConfiguration: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getEntityInitiationConfigurationFailureAction, (state, { error }) => ({
    ...state,
    getEntityInitiationConfiguration: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetEntityInitiationConfigurationStateAction, (state) => ({
    ...state,
    getEntityInitiationConfiguration: undefined,
  })),
  on(setEntityInitiationConfigurationAction, (state) => ({
    ...state,
    setEntityInitiationConfiguration: {
      isLoading: true,
    },
  })),
  on(setEntityInitiationConfigurationSuccessAction, (state) => ({
    ...state,
    setEntityInitiationConfiguration: {
      isLoading: false,
      success: true,
    },
  })),
  on(setEntityInitiationConfigurationFailureAction, (state, { error }) => ({
    ...state,
    setEntityInitiationConfiguration: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearSetEntityInitiationConfigurationStateAction, (state) => ({
    ...state,
    setEntityInitiationConfiguration: undefined,
  })),

  on(getEntityListDataByIdAction, (state: EntityState) => ({
    ...state,
    getEntityListDataById: {
      isLoading: true,
    },
  })),
  on(getEntityListDataByIdFailureAction, (state: EntityState, { error }) => ({
    ...state,
    getEntityListDataById: {
      isLoading: false,
      success: false,
      error: error,
      total: 0,
    },
  })),
  on(getEntityListDataByIdSuccessAction, (state: EntityState, { response }) => ({
    ...state,
    getEntityListDataById: {
      isLoading: false,
      success: true,
      payload: response,
      total: response.total,
    },
  })),
  on(clearGetEntityListDataByIdFailureAction, (state: EntityState) => ({
    ...state,
    getEntityListDataById: undefined,
  })),
  on(deleteEntityListDataAction, (state) => ({
    ...state,
    deleteEntityListData: {
      isLoading: true,
    },
  })),
  on(deleteEntityListDataSuccessAction, (state: EntityState) => ({
    ...state,
    deleteEntityListData: {
      isLoading: false,
      success: true,
    },
  })),
  on(deleteEntityListDataFailureAction, (state: EntityState, { error }) => ({
    ...state,
    deleteEntityListData: {
      isLoading: false,
      success: false,
      error,
    },
  })),
  on(clearDeleteEntityListDataStateAction, (state: EntityState) => ({
    ...state,
    deleteEntityListData: undefined,
  })),
  on(getListBenefit, (state) => ({
    ...state,
    getListBenefit: {
      isLoading: true,
    },
  })),
  on(getListBenefitSuccess, (state: EntityState, { response }) => ({
    ...state,
    getListBenefit: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getListBenefitFailure, (state: EntityState, { error }) => ({
    ...state,
    getListBenefit: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetListBenefitState, (state: EntityState) => ({
    ...state,
    getListDetail: undefined,
  })),
  on(updateBenefitMappingSuccess, (state: EntityState) => ({
    ...state,
    updateBenefitMapping: {
      isLoading: false,
      success: true,
    },
  })),
  on(updateBenefitMappingFailure, (state: EntityState) => ({
    ...state,
    updateBenefitMapping: {
      isLoading: false,
      success: false,
    },
  })),
  on(clearUpdateBenefitMappingState, (state: EntityState) => ({
    ...state,
    updateBenefitMapping: undefined,
  })),
);
