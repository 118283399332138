import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { CurrencyMaskInputMode } from 'ngx-currency';

@Component({
  selector: 'ptg-input-currency',
  templateUrl: './input-currency.component.html',
  styleUrls: ['./input-currency.component.scss']
})
export class InputCurrencyComponent implements OnInit {
  readonly CurrencyMaskInputMode = CurrencyMaskInputMode;
  @Input() controlField!: AbstractControl | any;
  @Input() placeholder!: string;
  @Input() maxLength?: number;
  @Input() hasLabel?: boolean;
  @Input() isRequired?: boolean;
  @Input() isDisabled?: boolean;
  @Input() class?: string;
  @Input() min?: number;
  @Input() max?: number;

  errorRequire?: string;
  errorPattern?: string;
  errorAsync?: string;
  errorMaxLength?: string;
  errorMin?: string;
  errorMax?: string;
  errorBetween?: string;
  isMatInput = true;
  constructor() { }

  ngOnInit(): void {
    this.errorRequire = `${this.placeholder} is required.`
    this.errorMaxLength = `Exceed the ${this.maxLength} character limit.`
    this.errorMin = `${this.placeholder} must be higher than ${this.min}.`
    this.errorMax = `${this.placeholder} must be lower than ${this.max}.`
    this.errorBetween = `${this.placeholder} must be within the range of ${this.min} - ${this.max}.`

    if (this.isRequired) {
      this.controlField.addValidators(Validators.required);
    }

    if(this.max !== undefined && isFinite(this.max)){
      this.controlField.addValidators(Validators.max, this.max);
    }

    if(this.min !== undefined && isFinite(this.min)){
      this.controlField.addValidators(Validators.min, this.min);
    }

  }

  isValidString(str: any) {
    return str?.trim();
  }
}
