import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CANCEL_CONFIRM_MESSAGE, STATE } from '@ptg-shared/constance/value.const';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { PaymentDeductionsService } from '@ptg-processing/features/payroll/services';

import * as NextPaymentActions from '../../store/actions/next-payment.actions';
import * as fromNextPayment from '../../store/reducers';
import {
  OffCyclePayment,
  ParticipantEarning,
  ParticipantEarningBucket,
  UpdateEarningsData,
} from '../../types/models';
import { TaxType } from '../../constance/member-list.const';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { ActivatedRoute } from '@angular/router';
import { ClearProfileHeaderConfiguration, clearProfileHeaderConfigurationState } from '@ptg-member/store/actions/profile-header-configuration.actions';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';

@Component({
  selector: 'ptg-edit-earnings',
  templateUrl: './edit-earnings.component.html',
  styleUrls: ['./edit-earnings.component.scss'],
})
export class EditEarningsComponent implements OnInit, OnDestroy {
  maxValueNumber = 9999999999999.99;
  formGroup: FormGroup;
  isBucketInputsError: boolean = false;
  buckets: any[] = [];
  oldBuckets: any[] = [];
  grossPayment: number;
  totalDeductions: number;
  netPayment: number;
  offCyclePayment?: OffCyclePayment;
  breadcrumbs: Breadcrumb[] = [];
  breadcrumbsBackup: Breadcrumb[] = [];
  isEstablishBenefit?: boolean = true;
  isEntityView: boolean = false;
  targetId?: string;
  isLoading = true;
  benefitId?: string;

  hiddenFederal: boolean = true;
  hiddenState: boolean = true;
  disabledFederal: boolean = true;
  disabledState: boolean = true;

  get recalculateFederalTaxCtrl(): FormControl {
    return this.formGroup.get('recalculateFederalTax') as FormControl;
  }
  get recalculateStateTaxCtrl() {
    return this.formGroup.get('recalculateStateTax') as FormControl;
  }

  unsubscribe$ = new Subject<void>();

  constructor(
    public route: ActivatedRoute,
    public dialogRef: MatDialogRef<EditEarningsComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<fromNextPayment.MemberState>,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    private paymentDeductionsService: PaymentDeductionsService
  ) {
    // Receives input data from outside
    this.buckets = this.data.buckets || [];
    this.oldBuckets = [...this.buckets];
    this.grossPayment = this.data.grossPayment || 0;
    this.totalDeductions = this.data.totalDeductions || 0;
    this.netPayment = this.data.netPayment || 0;
    this.breadcrumbsBackup = this.data.breadcrumbs || [];
    this.isEstablishBenefit = this.data.isEstablishBenefit;
    this.isEntityView = this.data.isEntityView;
    this.targetId = this.data.targetId;
    this.isLoading = true;
    this.benefitId = this.data.benefitId;

    // Make form gorup and form controls object
    const controls: any = this.buckets.reduce(
      (ctrls, bucket) => {
        ctrls[bucket.bucketName] = new FormControl(bucket.value);
        return ctrls;
      },
      {
        recalculateFederalTax: new FormControl(false),
        recalculateStateTax: new FormControl(false),
      }
    );
    this.formGroup = new FormGroup(controls, {
      validators: [this.earningInputsValidator],
    });
  }

  earningInputsValidator: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    // Check all fields is blank
    const isAllFieldsBlank: boolean = !this.buckets.some(
      (e) => control.get(e.bucketName)?.value === 0 || control.get(e.bucketName)?.value
    );
    if (isAllFieldsBlank) {
      this.isBucketInputsError = true;
      this.buckets.forEach((e) =>
        control.get(e.bucketName)?.setErrors({
          crossEarningsValidate: 'At least 1 bucket item is required.',
        })
      );
      return null;
    }

    // Check least 1 earning value not equals to 0
    const isAllFieldsEqualsZero: boolean = !this.buckets.some(
      (e) => control.get(e.bucketName)?.value
    );
    if (isAllFieldsEqualsZero) {
      this.isBucketInputsError = true;
      this.buckets.forEach((e) =>
        control.get(e.bucketName)?.setErrors(
          control.get(e.bucketName)?.value === 0
            ? {
              crossEarningsValidate:
                'At least 1 bucket item must be greater than 0.',
            }
            : null
        )
      );
      return null;
    }

    // Refresh control errors status
    if (
      !isAllFieldsBlank &&
      !isAllFieldsEqualsZero &&
      this.isBucketInputsError
    ) {
      this.isBucketInputsError = false;
      this.buckets.forEach((e) =>
        control.get(e.bucketName)?.updateValueAndValidity()
      );
    }

    return null;
  };

  ngOnInit(): void {
    this.breadcrumbs = this.breadcrumbsBackup.map(x => x);
    this.breadcrumbs.push({
      name: 'Edit Funding Buckets',
      url: ''
    });
    this.store.dispatch(clearProfileHeaderConfigurationState())
    this.store
      .pipe(
        select(fromNextPayment.selectProfileHeaderConfig),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state) {
          this.isLoading = state?.isLoading;
        }
      });
    // Hidden and disable Recalculate Federal Tax and Recalculate State Tax controls
    this.store
      .select(fromNextPayment.selectNextPaymentDeduction)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        const participantDeductions: any[] = state?.deductions || [];
        this.paymentDeductionsService.getActiveDeduction().subscribe((data) => {
          const deductionList = data && data.deductions ? data.deductions : [];

          // Hidden and disable Recalculate Federal Tax
          this.hiddenFederal =
            !participantDeductions.some(
              (el: any) =>
                JSON.parse(el?.deductionSetting)?.TaxType === TaxType.FederalTax
            ) || !!this.offCyclePayment;
          if (!this.hiddenFederal) {
            this.disabledFederal = !deductionList.some(
              (el: any) =>
                JSON.parse(el?.deductionSetting)?.TaxType === TaxType.FederalTax
            );
          }

          // Hidden and disable Recalculate State Tax
          this.hiddenState =
            !participantDeductions.some(
              (el: any) =>
                JSON.parse(el?.deductionSetting)?.TaxType === TaxType.StateTax
            ) || !!this.offCyclePayment;
          if (!this.hiddenState) {
            this.disabledState = !deductionList.some(
              (el: any) =>
                JSON.parse(el?.deductionSetting)?.TaxType === TaxType.StateTax
            );
          }
        });
      });

    // Listen for the result of recalculate total deductions
    this.store
      .select(fromNextPayment.selectNextPaymentRecalculateTotalDeductions)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state && state?.recalculateTotalDeductionsState === STATE.SUCCESS) {
          // Recalculate Gross payment
          this.grossPayment = this.buckets.reduce(
            (grossPayment, bucket) =>
              grossPayment + Number(this.formGroup.get(bucket.bucketName)?.value),
            0
          );
          // Receive total deductions from state
          this.totalDeductions = state.totalDeductions;

          // Recalculate Net payment
          this.netPayment = this.grossPayment - this.totalDeductions;
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSubmit() {
    if (this.formGroup.valid) {
      const data: UpdateEarningsData = {
        recalculateFederalTax: this.recalculateFederalTaxCtrl.value,
        recalculateStateTax: this.recalculateStateTaxCtrl.value,
        benefitId: this.benefitId,
        earningBuckets: this.buckets.map(
          (bucket) =>
          ({
            ...bucket,
            value: this.formGroup.get(bucket.bucketName)?.value || 0,
          } as ParticipantEarningBucket)
        ),
      };
      this.dialogRef.close(data);
    }
  }

  onCancel(): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: CANCEL_CONFIRM_MESSAGE, type: ConfirmType.Cancel }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
        this.store.dispatch(
          NextPaymentActions.clearTotalDeductionsState()
        );
      }
    });
  }

  onBlurEarningInput(): void {
    // Check earnings changed
    const earningsIsChanged: boolean = this.oldBuckets.some(
      (e) => e.value !== this.formGroup.get(e.bucketName)?.value
    );
    if (this.formGroup.valid && earningsIsChanged) {
      if (
        this.recalculateFederalTaxCtrl.value ||
        this.recalculateStateTaxCtrl.value
      ) {
        this.onlineRecalculate();
      } else {
        this.offlineRecalculate();
      }
    }

    // Update oldEarnings
    this.oldBuckets = this.oldBuckets.map((e) => ({
      ...e,
      value: this.formGroup.get(e.bucketName)?.value,
    }));
  }

  onChangeRecalculateToggle(): void {
    if (this.formGroup.valid) this.onlineRecalculate();
  }

  onlineRecalculate(): void {
    // To Do
    // this.store.dispatch(
    //   NextPaymentActions.recalculateTotalDeductions({
    //     body: {
    //       recalculateFederalTax: this.recalculateFederalTaxCtrl.value,
    //       recalculateStateTax: this.recalculateStateTaxCtrl.value,
    //       earningBuckets: this.buckets.map((bucket) => ({
    //         ...bucket,
    //         value: Number(this.formGroup.get(bucket.bucketName)?.value),
    //       })),
    //     },
    //   })
    // );
  }

  offlineRecalculate(): void {
    // Recalculate Gross payment
    this.grossPayment = this.buckets.reduce(
      (grossPayment, bucket) =>
        grossPayment + Number(this.formGroup.get(bucket.bucketName)?.value),
      0
    );

    // Recalculate Net payment
    this.netPayment = this.grossPayment - this.totalDeductions;
  }
}
