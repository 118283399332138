<ptg-dialog-header
  header="Bulk Update Results">
</ptg-dialog-header>
<div class="file-result">
  <ng-container *ngIf="!isLoading">
    <button mat-raised-button type="button" style="background-color: #fff;color: #000" [disabled]="isProceeding || isCanceling" (click)="onBack()">Back</button>
    <button mat-raised-button type="button" style="background-color: #acabab;color: #fff" [disabled]="isProceeding || isCanceling" (click)="onCancel()" *ngIf="canCancel">Cancel</button>
    <div class="export-file-title">
      <p class="label-small">{{ data.sessionStatus }}</p>
      <div class="add-btn">
        <ptg-button *ngIf="isDownloadValidation"
          buttonName="Validation Report"
          classInput="add-button"
          (clickButton)="onDownloadValidation(doc)"
          [isDisabled]="isDisableGenerateValidation">
        <mat-icon>file_download</mat-icon>
        </ptg-button>
        <ptg-button *ngIf="isDownloadRawFile"
          buttonName="Raw Data File"
          classInput="add-button"
          (clickButton)="onDownload(doc)">
          <mat-icon>file_download</mat-icon>
        </ptg-button>
      </div>
    </div>
    <div class="banner" *ngIf="isCanceling || isCanceled">
      <mat-icon style="color: #a8a8a8;">info</mat-icon>
      <span style="vertical-align: super;">Canceling this Bulk Update.</span>
    </div>
    <div class="banner" *ngIf="isProceeding || isProceeded">
      <mat-icon style="color: #a8a8a8;">check_circle</mat-icon>
      <span style="vertical-align: super;">Proceeding with this Bulk Update.</span>
    </div>
    <ptg-message-result
      [summary]="summaries"
      [warnings]="warnings"
      [errors]="errors"
      [status]="status"
    ></ptg-message-result>
    <div class="btn-bottom">
      <button mat-raised-button type="button" style="background-color: #408bf9;color: #fff;" [disabled]="isProceeding || isCanceling" (click)="onProceed()" *ngIf="canProceed">Proceed</button>
    </div>
  </ng-container>
  <div id="loading-table" *ngIf="isLoading">
    <div class="wrap-item">
      <mat-progress-spinner diameter="50" mode="indeterminate">
      </mat-progress-spinner>
      <p>Loading...</p>
    </div>
  </div>
</div>