import { ColumnType } from '@ptg-shared/controls/grid';
import { PropertyType, PropertyTypeEntity } from './constance/metadataPropertyType.const';
import {
  FIXED_PROPERTIES,
  ICON_REGISTER_FIELDS,
  ICON_STATUS_FIELDS,
} from './constance/member-list.const';
import { EntityPropertyType } from '@ptg-entity-management/types/enums/entity-property.enum';
import { getConcatString, getDateString, stringToBoolean } from '@ptg-shared/utils/string.util';
import { DatePipe } from '@angular/common';
import { ListPropertyForCard } from '@ptg-entity-management/services/models';
import { AddressPipe } from '@ptg-shared/pipes/address.pipe';
import { FixedPropertyKey } from '@ptg-entity-management/constants';

export function getColumConfig(
  columnType: typeof PropertyType,
  configValue: any,
  columnKey: string = ''
) {
  let type = undefined;
  let templateArgs = undefined;
  switch (columnType) {
    case PropertyType.TYPE_DATE:
      type = ColumnType.DateTime;
      templateArgs = { format: 'MM/dd/yyyy' };
      break;
    case PropertyType.TYPE_DATE_TIME:
      type = ColumnType.DateTime;
      templateArgs = { format: 'MM/dd/yyyy h:mm a' };
      break;
    case PropertyType.TYPE_CURRENCY:
      if (columnKey.startsWith('Calculation_')) {
        type = ColumnType.Decimal;
        templateArgs = {
          decimal: configValue?.fractionalLengthInput,
          unit: '$',
          unitPosition: 'left',
        };
      } else {
        type = ColumnType.Decimal;
        templateArgs = { decimal: 2, unit: '$', unitPosition: 'left' };
      }

      break;
    case PropertyType.TYPE_PERCENTAGE:
      if (columnKey.startsWith('Calculation_')) {
        type = ColumnType.Decimal;
        templateArgs = {
          decimal: configValue?.fractionalLengthInput,
          unit: '%',
          unitPosition: 'right',
        };
      } else {
        type = ColumnType.Decimal;
        templateArgs = { decimal: 2, unit: '%', unitPosition: 'right' };
      }
      break;
    case PropertyType.TYPE_DECIMAL:
      type = ColumnType.Decimal;
      templateArgs = {
        decimal: configValue?.fractionalLengthInput
          ? +configValue.fractionalLengthInput
          : 0,
      };
      break;
    case PropertyType.TYPE_WHOLE_NUMBER:
      type = ColumnType.Decimal;
      templateArgs = { decimal: 0 };
      break;
    case PropertyType.TYPE_RICH_TEXT:
      type = ColumnType.RichText;
      break;
    case PropertyType.TYPE_STATUS:
    case PropertyType.TYPE_MEMBER_STATUS:
      type = ColumnType.Icon;
      templateArgs = {
        nameField: ICON_STATUS_FIELDS.statusIconName,
        colorField: ICON_STATUS_FIELDS.statusIconColor,
        labelField:
          columnKey === FIXED_PROPERTIES.StatusEvent
            ? ICON_STATUS_FIELDS.eventIconLabel
            : ICON_STATUS_FIELDS.statusIconLabel,
      };
      break;
    case PropertyType.TYPE_PHONE:
      type = ColumnType.PhoneNumber;
      break;
    case PropertyType.TYPE_REGISTER:
      type = ColumnType.Icon;
      templateArgs = {
        nameField: ICON_REGISTER_FIELDS.registerIconName,
        colorField: ICON_REGISTER_FIELDS.registerIconColor,
        styleField: { 'justify-content': 'center' },
      };
      break;
    case PropertyType.TYPE_ADDRESS:
      type = ColumnType.Address;
      break;
    case PropertyType.TYPE_PERSON_NAME:
      type = ColumnType.PersonName;
      break;
    case PropertyType.TYPE_BOOLEAN:
      type = ColumnType.Binary;
      templateArgs = configValue;
      break;
    case PropertyType.TYPE_ENTITY_REFERENCE:
      type = ColumnType.EntityReference;
      break;      
    default:
      break;
  }
  return {
    type: type,
    templateArgs: templateArgs,
  };
}

export function getEntityPropertyName(columnType: EntityPropertyType) {
  let type: any = '';
  if (columnType === EntityPropertyType['Whole Number']) {
    type = 'Whole_Number';
  } else if (columnType === EntityPropertyType['Person Name']) {
    type = 'Person_Name';
  } else if (columnType === EntityPropertyType['Date Time']) {
    type = 'DateTime';
  } else if (columnType === EntityPropertyType['Entity Reference']) {
    type = 'Entity_Reference';
  } else {
    type = EntityPropertyType[columnType];
  }

  return type;
}

export function getColumConfigEntity(
  columnType: typeof PropertyType,
  configValue: any,
  columnKey: string = ''
) {
  let type = undefined;
  let templateArgs = undefined;
  let fractionalLength = configValue?.fractionalLengthInput;
  if (!fractionalLength && configValue?.calcucation) {
    let configCalculator = JSON.parse(configValue?.calcucation);
    fractionalLength =  !configCalculator?.fractionalLength && configCalculator?.fractionalLength !== 0 ? 2 : configCalculator?.fractionalLength;
  }
  switch (columnType) {
    case PropertyTypeEntity.TYPE_DATE:
      type = ColumnType.DateTime;
      templateArgs = { format: 'MM/dd/yyyy' };
      break;
    case PropertyTypeEntity.TYPE_DATE_TIME:
      type = ColumnType.DateTime;
      templateArgs = { format: 'MM/dd/yyyy h:mm a' };
      break;
    case PropertyTypeEntity.TYPE_CURRENCY:
      type = ColumnType.Decimal;
      templateArgs = { decimal: Number(fractionalLength) ?? 2, unit: '$', unitPosition: 'left' };

      break;
    case PropertyTypeEntity.TYPE_PERCENTAGE:
      type = ColumnType.Decimal;
      templateArgs = { decimal: Number(fractionalLength) ?? 2, unit: '%', unitPosition: 'right' };
      break;
    case PropertyTypeEntity.TYPE_DECIMAL:
      type = ColumnType.Decimal;
      templateArgs = {
        decimal: Number(fractionalLength) ?? 2,
      };
      break;
    case PropertyTypeEntity.TYPE_WHOLE_NUMBER:
      type = ColumnType.Decimal;
      templateArgs = { decimal: 0 };
      break;
    case PropertyTypeEntity.TYPE_RICH_TEXT:
      type = ColumnType.RichText;
      break;
    case PropertyTypeEntity.TYPE_STATUS:
      type = ColumnType.Icon;
      templateArgs = {
        nameField: configValue.IconName,
        colorField: configValue.Color,
        labelField: configValue?.Name ?? configValue?.EventName,
      };
      break;
    case PropertyTypeEntity.TYPE_MEMBER_STATUS:
      type = ColumnType.Icon;
      templateArgs = {
        nameField: ICON_STATUS_FIELDS.statusIconName,
        colorField: ICON_STATUS_FIELDS.statusIconColor,
        labelField:
          columnKey === PropertyTypeEntity.TYPE_STATUS_EVENT
            ? ICON_STATUS_FIELDS.eventIconLabel
            : ICON_STATUS_FIELDS.statusIconLabel,
      };
      break;
    case PropertyTypeEntity.TYPE_PHONE:
      type = ColumnType.PhoneNumber;
      break;
    case PropertyTypeEntity.TYPE_REGISTER:
      type = ColumnType.Icon;
      templateArgs = {
        nameField: columnKey,
        colorField:  `${columnKey}_Register_Color`,
        styleField: { 'justify-content': 'center'},
      };
      break;
    case PropertyTypeEntity.TYPE_LOCKED_REFERENCE:
      type = ColumnType.Icon;
      templateArgs = {
        nameField: columnKey,
        colorField: `${columnKey}_Locked_Color`,
      };
      break;
    case PropertyTypeEntity.TYPE_ADDRESS:
      type = ColumnType.Address;
      break;
    case PropertyTypeEntity.TYPE_PERSON_NAME:
      type = ColumnType.PersonName;
      break;
    case PropertyTypeEntity.TYPE_BOOLEAN:
      type = ColumnType.Binary;
      templateArgs = configValue;
      break;
    case PropertyType.Entity_Reference:
      type = ColumnType.EntityReference;
      break;
    case PropertyType.TYPE_IDENTIFIER:
      type = ColumnType.Identifier;
      break;        
    default:
      break;
  }
  return {
    type: type,
    templateArgs: templateArgs,
    ...(type === ColumnType.Identifier && configValue?.autogenerated === 'true') && {
      isIdentifierTypeAutoGenerated: true,
      prefix: configValue?.patternPrefix,
      suffix: configValue?.patternSuffix,
    }
  };
}

export function getEntityValue(entityData: ListPropertyForCard, options: any, isSelectedRecord?: boolean, isStandAloneProperty?: boolean) {
  let value: any;
  let type = entityData?.displayFieldType ? entityData?.displayFieldType : options?.type;
  const isEntityReference = (options?.displayFieldType && options?.displayFieldType !== null) || isStandAloneProperty;
  if (options?.config?.fixedKey === FixedPropertyKey.Payee) {
    let compareValue = entityData.value;
    const checkName = entityData?.options?.find((item: any) => item.id === compareValue)?.name;
    if (checkName) {
      if (typeof(checkName) === 'object') {
        value = (checkName.prefix || '') + (checkName.prefix ? '. ' : '') + `${ getConcatString([checkName?.first, checkName?.middle, checkName?.last], ' ') }` + (checkName?.suffix ? ', ' + checkName?.suffix : '');
      } else {
        value = checkName;
      }
    }
    return value;
  }
  switch (type) {
    case EntityPropertyType.Address:
      if (isSelectedRecord) {
        value = entityData?.value && ['city', 'country', 'state', 'street1', 'street2', 'zipCode'].every(i => entityData?.value[i] === null) ? null : entityData?.value;
      } else {
        if (isEntityReference) {
          value = entityData?.value;
        } else {
          if (entityData?.option) {
            if (entityData?.option === 'State' || entityData?.option === 'Country') {
              let compareValue = entityData.value
              if (entityData.value && typeof entityData.value === 'object') {
                const keys = Object.keys(entityData.value)
                const key = keys?.find(x => x.toLowerCase() == entityData?.option?.toLowerCase());
                compareValue = entityData.value[key ? key : ''];
              }              
              value = entityData?.options?.find((item: any) => item.id === compareValue)?.text;
            } else {
              if (entityData?.value && typeof entityData?.value === 'object') {
                const keys = Object.keys(entityData?.value)
                const key = keys?.find(x => x.toLowerCase() == entityData?.option?.toLowerCase());
                value = entityData?.value !== null ? entityData?.value[key ? key : ''] : null;
              }
              else {
                value = null;
              }
            }
          } else if (entityData.value && typeof entityData?.value === 'object') {
            const addressPipe = new AddressPipe();
            value = addressPipe.transform(
              entityData?.value,
              entityData?.options,
              true
            );
          }
        }
      }
    break;

    case EntityPropertyType.Binary:
      if (isStandAloneProperty) {
        value = entityData.value !== null ? entityData.value : null;
      } else {
        value = entityData.value ? options?.config?.affirmative : options?.config?.negative;
      }
    break;

    case EntityPropertyType.Date:
      value = new DatePipe('en-US').transform(entityData?.value, 'MM/dd/yyyy');
    break;
  
    case EntityPropertyType['Person Name']:
      if (isEntityReference) {
        value = entityData?.value
      } else {
        if (entityData.option) {
          if (entityData.option === 'Prefix') {
            value = entityData?.options?.find((item: any) => item.id === entityData?.value?.prefix)?.text;
          } else {
            value = entityData.value !== null ? entityData?.value[entityData?.option.toLowerCase()] : null;
          }
        } else {
          let prefix = entityData.value?.prefix ? entityData.value?.prefix : '';
          if (entityData.options) {
            prefix = entityData.options.find((el: any) => el.id === prefix)?.text || '';
          }
          value = (prefix || '') + (prefix ? '. ' : '') + `${ getConcatString([entityData?.value?.first, entityData?.value?.middle, entityData?.value?.last], ' ') }` + (entityData?.value?.suffix ? ', ' + entityData?.value?.suffix : '');
        }
      }
    break;

    case EntityPropertyType.Email:
      value = entityData.value !== null ? stringToBoolean(options?.config?.masked) ? 
        entityData.value?.maskedValue : 
        entityData.value?.originalValue : null;
    break;

    case EntityPropertyType.Phone:
      value = entityData.value !== null ? stringToBoolean(options?.config?.masked) ? 
        entityData?.value?.maskedValue : 
        entityData?.value?.originalValue : null;
    break;

    case EntityPropertyType.SSN:
      if (isSelectedRecord) {
        value = entityData?.value?.maskedValue?.slice(
          entityData?.value?.maskedValue?.length - 4,
          entityData?.value?.maskedValue?.length
        );
      } else {
        value = entityData.value !== null ? stringToBoolean(options?.config?.masked) ? 
        entityData.value?.maskedValue : 
        entityData.value?.originalValue : null;
      }
    break;

    case EntityPropertyType.Status:
      if (entityData.option === "Name") {
        value = entityData?.options?.find((item: any) => item.id === entityData.value?.status)?.name;
      } else if (entityData.option === "Event") {
        value = entityData?.options?.find((item: any) => item.id === entityData.value?.status)?.events?.find((el: any) => el.id === entityData.value?.event)?.name;
      } else if (entityData.option === "Date"){
        value = entityData.value?.statusDate !== '' ? new DatePipe('en-US').transform(entityData.value?.statusDate, 'MM/dd/yyyy') : '';
      }
    break;

    case EntityPropertyType.Lookup:
      value = entityData?.options?.find((item: any) => item.id === entityData.value)?.description;
    break;

    case EntityPropertyType.Tier:
      value = entityData?.options?.find((item: any) => item.id === entityData.value)?.text;
    break;

    case EntityPropertyType['Date Time']:
      value = entityData.value !== '' ? new DatePipe('en-US').transform(getDateString(entityData?.value), 'MM/dd/yyyy hh:mm a') : '';
    break;

    case EntityPropertyType.Identifier:
      if (options.config.autogenerated === 'true' && entityData.value) {
        value = (options?.config?.patternPrefix ?? '') + entityData.value + (options?.config?.patternSuffix ?? '');
      } else {
        value = entityData.value ?? '';
      }
    break;

    default:
      value = entityData?.value
    break;
  }

  return value;
}

export function isEINProperty(id: string) {
  return id?.toUpperCase() === 'C31B4EB6-F6E6-4403-96FE-7AD1C215EF16';
}