import { Component, ContentChild, Input } from '@angular/core';

import { PanelBody, PanelExtraHeader } from './directives';

@Component({
  selector: 'ptg-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent {
  @Input() title!: string;
  @Input() hideSeparator: boolean = false;
  @Input() noPaddingBody: boolean = false;

  @ContentChild(PanelExtraHeader) panelExtraHeader?: PanelExtraHeader;
  @ContentChild(PanelBody) panelBody?: PanelBody;
}
