<div class="member-detail-display" [ngClass]="{ editable: isEditable }">
  <div class="flex">
    <table class="table-content">
      <tr *ngFor="let row of allColumnData">
        <td>
          <ng-container
            [ngTemplateOutlet]="nonListLayout"
            [ngTemplateOutletContext]="{ data: row[0] }"
          ></ng-container>
        </td>
        <td>
          <ng-container
            [ngTemplateOutlet]="nonListLayout"
            [ngTemplateOutletContext]="{ data: row[1] }"
          ></ng-container>
        </td>
        <td>
          <ng-container
            [ngTemplateOutlet]="nonListLayout"
            [ngTemplateOutletContext]="{ data: row[2] }"
          ></ng-container>
        </td>
        <td>
          <ng-container
            [ngTemplateOutlet]="nonListLayout"
            [ngTemplateOutletContext]="{ data: row[3] }"
          ></ng-container>
        </td>
      </tr>
    </table>
  </div>
  <div
    *ngIf="
      !column1Data.length &&
      !column2Data.length &&
      !column3Data.length &&
      !column4Data.length && !isLoading
    "
    class="data-not-found"
  >
    <div class="message">Display has not been configured</div>
  </div>
</div>

<ng-template #nonListLayout let-data="data">
  <div
    *ngIf="data"
    class="flex"
    [ngClass]="{
      'flex-col': currentSectionLayout === PropertyDisplay.Column,
      'gap-3': currentSectionLayout === PropertyDisplay.Table
    }"
  >
    <div class="title-container flex">
      <div class="title flex-grow">{{ data?.title }}</div>
    </div>

    <div class="value-container flex-grow flex">
      <ng-container [ngSwitch]="data.type">
        <span
          *ngSwitchCase="EntityPropertyType.Currency"
        >
          {{ (data.value | numberLocalDecimal : "$") || "-" }}
        </span>
        <span *ngSwitchCase="EntityPropertyType.Date">
          {{ (data.value | date : "MM/dd/yyyy") || "-" }}
        </span>
        <span *ngSwitchCase="EntityPropertyType['Person Name']">
          {{ (data.value | personName : data.options) || "-" }}
        </span>
        <div *ngSwitchCase="EntityPropertyType.Address">
          <ptg-view-address *ngIf="data.value"
            [value]="data?.value"
            [options]="data.options"
          ></ptg-view-address>
          <span *ngIf="!data.value">{{ "-" }}</span>
        </div>
        <span
          *ngSwitchCase="EntityPropertyType.Decimal"
        >
          {{
            (data.value
              | numberDecimal
                : {
                    decimal:
                      data?.configs?.fractionalLengthInput || data.displayValue,
                    isCheckCurrentDecimal: true
                  }) || "-"
          }}
        </span>
        <span
          *ngSwitchCase="EntityPropertyType['Whole Number']"
        >
          {{ (data.value | numberDecimal) || "-" }}
        </span>
        <span *ngSwitchCase="EntityPropertyType.Lookup">{{
          data.value || "-"
        }}</span>
        <span *ngSwitchCase="EntityPropertyType.Binary">{{
          data?.value === null || data?.value === undefined
            ? "-"
            : data?.value
            ? data?.configs?.affirmative
            : data?.configs?.negative
        }}</span>
        <span
          *ngSwitchCase="EntityPropertyType.Percentage"
        >
          {{
            (data.value
              | numberDecimal
                : {
                    decimal:
                      data?.configs?.fractionalLengthInput || data.displayValue,
                    isCheckCurrentDecimal: true
                  }) || "-"
          }}
          {{
            (data.value
            | numberDecimal
              : {
                  decimal:
                    data?.configs?.fractionalLengthInput || data.displayValue,
                  isCheckCurrentDecimal: true
                })
              ? "%"
              : ""
          }}
        </span>
        <span *ngSwitchCase="EntityPropertyType.Employer">{{
          data.value || "-"
        }}</span>
        <span *ngSwitchCase="EntityPropertyType.Tier">{{
          data.value || "-"
        }}</span>
        <span class="value" *ngSwitchCase="EntityPropertyType.Status">
          {{ data.value || "-" }}
        </span>
        <span class="value" *ngSwitchCase="EntityPropertyType.Email">
          {{ (data.visibilityOff ? data?.value : data?.valueUnMasked) }}
        </span>
        <span class="value" *ngSwitchCase="EntityPropertyType.Phone">
          {{ (data.visibilityOff ? data?.value : data?.valueUnMasked) }}
        </span>
        <ng-container>
          <span class="value" *ngSwitchCase="EntityPropertyType.System">
            <a *ngIf="data.entityReferenceId; else system"
            (click)="redirectToProfile(data)"
            href="javascript:void(0)"
            style="
              color: #408BF9;
              cursor: pointer;
              text-decoration: underline;
            "
            >{{ data?.value || "-" }}</a>
            <ng-template #system>

              {{ data?.value || "-" }}
            </ng-template>
          </span>
        </ng-container>
        <ng-container *ngSwitchCase="EntityPropertyType.RichText">
          <ptg-view-rich-text
            [title]="data?.title"
            [content]="data.value"
            [defaultContent]="'-'"
          ></ptg-view-rich-text>
        </ng-container>
        <span *ngSwitchCase="EntityPropertyType['Date Time']">
          {{ (data?.value | date : "MM/dd/yyyy hh:mm a") || "-" }}
        </span>
        <ng-container *ngSwitchDefault>
          <span
            *ngIf="!data.masked; else ssn"
          >
            {{ data?.value || "-" }}
          </span>
          <ng-template #ssn>
            <span style="color: #b1b1b1" *ngIf="data.visibilityOff && data.value">XXX-XX-</span>
            <span>{{ (data.visibilityOff ? data?.value : data?.valueUnMasked) || "-"}}</span>
          </ng-template>
        </ng-container>
      </ng-container>

      <span>
        &nbsp;
        <mat-icon
          *ngIf="data?.masked && data.value"
          class="masked-icon"
          matSuffix
          (click)="onClickIcon(data)"
          >{{ data.visibilityOff ? "visibility_off" : "visibility" }}</mat-icon
        >
      </span>
    </div>
  </div>
</ng-template>
