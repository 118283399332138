import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusEntity'
})
export class StatusEntityPipe implements PipeTransform {

  transform(value: any, isStatusField: boolean, options: any[]): any {
    if (!value) {
      return '';
    }
    const status = options.find((el: any) => el.id === value.status);
    return isStatusField ? status?.name : status?.events.find((el: any) => el.id === value.event)?.name;
  }

}
