<ptg-document-list-view
  #gridDocumentData
  [dataTable]="dataTable"
  [isLoading]="isLoading"
  [pageSize]="pageSize"
  [pageNumber]="pageNumber"
  [totalRecords]="lengthPg"
  [message]="message"
  [bannerType]="bannerType"
  (sortChangeEvent)="sortChange($event)"
  (changePaggingEvent)="changePage($event)"
  (downloadDocumentEvent)="downloadDocument($event)"
  (removeDocumentEvent)="onClickRemoveDocument($event)"
  (removeMultipleDocumentEvent)="onClickRemoveMultipleDocument($event)"
  (downloadMultipleDocumentEvent)="downloadMultipleDocument($event)"
  (applyFilterEvent)="applyFilterEvent($event)"
  (editDocumentEvent)="editDocument($event)"
></ptg-document-list-view>
