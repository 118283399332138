export enum ButtonLabelType {
  Add = 'Add New',
  Remove = 'Remove',
  Edit = 'Edit',
}

export enum IconFontType {
  Add = 'add',
  Remove = 'delete_forever',
  Edit = 'edit',
}
