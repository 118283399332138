import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ptg-three-dots',
  templateUrl: './three-dots.component.html',
  styleUrls: ['./three-dots.component.scss']
})
export class ThreeDotsComponent implements OnInit {
  @Input() name: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
