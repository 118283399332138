import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { EntityProfileConfigService } from '../../services';
import {
  getEntityProfileConfigOverviewAction,
  getEntityProfileConfigOverviewFailureAction,
  getEntityProfileConfigOverviewSuccessAction,
} from '../actions';

@Injectable()
export class EntityProfileConfigEffect {
  constructor(
    private actions$: Actions,
    private entityProfileConfigService: EntityProfileConfigService,
  ) {}

  getEntityProfileConfigOverview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEntityProfileConfigOverviewAction),
      switchMap(({ entityId, recordId }) =>
        this.entityProfileConfigService.getEntityProfileConfigOverview(entityId, recordId).pipe(
          map((response) => getEntityProfileConfigOverviewSuccessAction({ response: { ...response, entityId, recordId } })),
          catchError((error) => of(getEntityProfileConfigOverviewFailureAction({ error }))),
        ),
      ),
    ),
  );
}
