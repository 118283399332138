<div class="dialog-header">
  <span class="title">Edit Deductions</span>
</div>

<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div class="form-content">
    <div *ngIf="!isHiddenTaxes" class="deduction-box">
      <div class="box-title">Taxes</div>

      <div class="flex gap-5">
        <div class="flex-grow">
          <div class="col-title">Deduction</div>
        </div>
        <div style="width: 150px">
          <div class="col-title">Amount</div>
        </div>
        <div class="flex right-padding-space"></div>
      </div>

      <div class="flex flex-col mt-3 gap-4">
        <div *ngIf="federalTax" class="flex gap-5">
          <div class="flex flex-grow">
            <span class="mt-auto mb-auto mr-5">
              {{
                federalTax?.deductionCode +
                  " - " +
                  federalTax?.deductionDescription
              }}
            </span>
          </div>

          <div class="flex" style="width: 200px">
            <ptg-toggle-button
              class="mt-auto mb-auto"
              label="Recalculate"
              [controlField]="recalculateFederalTaxCtrl"
            ></ptg-toggle-button>
          </div>

          <div style="width: 150px">
            <ptg-textbox
              *ngIf="!recalculateFederalTaxCtrl.value"
              [controlField]="federalCtrl"
              [hasLabel]="true"
              placeholder="Amount"
              [isDecimal]="true"
              [isPositive]="true"
              [allowZero]="true"
              inputType="Currency"
              [min]="0.0"
              [max]="MAX_VALUE_NUMBER"
              [maxLength]="16"
              [isRequired]="true"
              errorMinMax="Amount must be within the range of 0.00 - 9,999,999,999,999.99."
            ></ptg-textbox>
          </div>

          <div class="flex right-padding-space"></div>
        </div>

        <div *ngIf="stateTax" class="flex gap-5">
          <div class="flex flex-grow">
            <span class="mt-auto mb-auto mr-5">
              {{
                stateTax?.deductionCode + " - " + stateTax?.deductionDescription
              }}
            </span>
          </div>

          <div class="flex" style="width: 200px">
            <ptg-toggle-button
              class="mt-auto mb-auto"
              label="Recalculate"
              [controlField]="recalculateStateTaxCtrl"
            ></ptg-toggle-button>
          </div>

          <div style="width: 150px">
            <ptg-textbox
              *ngIf="!recalculateStateTaxCtrl.value"
              [controlField]="stateCtrl"
              [hasLabel]="true"
              placeholder="Amount"
              [isDecimal]="true"
              [isPositive]="true"
              [allowZero]="true"
              inputType="Currency"
              [min]="0.0"
              [max]="MAX_VALUE_NUMBER"
              [maxLength]="16"
              [isRequired]="true"
              errorMinMax="Amount must be within the range of 0.00 - 9,999,999,999,999.99."
            ></ptg-textbox>
          </div>

          <div class="flex right-padding-space"></div>
        </div>
      </div>

      <ptg-button
        *ngIf="isDisplayAddTaxes"
        buttonName="Add Taxes"
        classInput="add-button"
        (clickButton)="onClickAddTaxes()"
      >
        <mat-icon>add</mat-icon>
      </ptg-button>

      <div
        *ngIf="
          selectedInsuranceCtrls?.controls?.length ||
          allActiveInsurances?.length ||
          selectedInsuranceCtrls?.controls?.length ||
          allActiveOthers?.length ||
          selectedGarnishmentCtrls?.controls?.length
        "
        class="flex gap-5"
      >
        <div class="line flex-grow"></div>
        <div class="right-padding-space"></div>
      </div>
    </div>

    <div
      *ngIf="
        selectedInsuranceCtrls?.controls?.length || allActiveInsurances?.length
      "
      class="deduction-box"
    >
      <div class="box-title">Insurance</div>

      <div class="flex flex-col gap-4">
        <div
          *ngFor="let row of selectedInsuranceCtrls.controls; index as i"
          class="flex gap-5"
        >
          <div class="flex-grow">
            <ptg-select
              [placeholder]="'Insurance ' + (i + 1)"
              [controlField]="row.get('deduction')"
              [listData]="row.get('deductionOptions')?.value"
              [isOptionObj]="true"
              [errorRequire]="'Insurance ' + (i + 1) + ' is required.'"
              errorDuplicated="Chosen deduction already exists."
              (changeOptionValue)="
                onChangeDeduction(DeductionType.Insurance, row)
              "
            ></ptg-select>
          </div>
          <div style="width: 150px">
            <ptg-textbox
              [controlField]="row.get('amount')"
              [hasLabel]="true"
              placeholder="Amount"
              [isDecimal]="true"
              [isPositive]="true"
              [allowZero]="true"
              [isDisabled]="row.get('deduction')?.disabled"
              inputType="Currency"
              [min]="0.0"
              [max]="MAX_VALUE_NUMBER"
              [maxLength]="16"
              [isRequired]="true"
              errorMinMax="Amount must be within the range of 0.00 - 9,999,999,999,999.99."
            ></ptg-textbox>
          </div>
          <div class="flex right-padding-space">
            <mat-icon
              class="remove-button"
              (click)="onClickRemoveRow(DeductionType.Insurance, i)"
            >
              delete_forever
            </mat-icon>
          </div>
        </div>

        <ptg-button
          *ngIf="showAddButton(DeductionType.Insurance)"
          buttonName="New Insurance"
          classInput="add-button"
          (clickButton)="onClickAddRow(DeductionType.Insurance)"
        >
          <mat-icon>add</mat-icon>
        </ptg-button>
      </div>

      <div
        *ngIf="
          selectedInsuranceCtrls?.controls?.length ||
          allActiveOthers?.length ||
          selectedGarnishmentCtrls?.controls?.length
        "
        class="flex gap-5"
      >
        <div class="line flex-grow"></div>
        <div class="right-padding-space"></div>
      </div>
    </div>

    <div
      *ngIf="selectedOtherCtrls?.controls?.length || allActiveOthers.length"
      class="deduction-box"
    >
      <div class="box-title">Others</div>

      <div class="flex flex-col gap-4">
        <div
          *ngFor="let row of selectedOtherCtrls?.controls; index as i"
          class="flex gap-4"
        >
          <div class="flex-grow">
            <ptg-select
              [placeholder]="'Others ' + (i + 1)"
              [controlField]="row?.get('deduction')"
              [listData]="row.get('deductionOptions')?.value"
              [isOptionObj]="true"
              [errorRequire]="'Others ' + (i + 1) + ' is required.'"
              errorDuplicated="Chosen deduction already exists."
              (changeOptionValue)="onChangeDeduction(DeductionType.Others, row)"
            ></ptg-select>
          </div>
          <div style="width: 150px">
            <ptg-textbox
              [controlField]="row.get('amount')"
              [hasLabel]="true"
              placeholder="Amount"
              [isDecimal]="true"
              [isPositive]="true"
              [allowZero]="true"
              [isDisabled]="row?.get('deduction')?.disabled"
              inputType="Currency"
              [min]="0.0"
              [max]="MAX_VALUE_NUMBER"
              [maxLength]="16"
              [isRequired]="true"
              errorMinMax="Amount must be within the range of 0.00 - 9,999,999,999,999.99."
            ></ptg-textbox>
          </div>
          <div class="flex right-padding-space">
            <mat-icon
              class="remove-button"
              (click)="onClickRemoveRow(DeductionType.Others, i)"
            >
              delete_forever
            </mat-icon>
          </div>
        </div>

        <ptg-button
          *ngIf="showAddButton(DeductionType.Others)"
          buttonName="New Others"
          classInput="add-button"
          (clickButton)="onClickAddRow(DeductionType.Others)"
        >
          <mat-icon>add</mat-icon>
        </ptg-button>
      </div>

      <div
        *ngIf="selectedGarnishmentCtrls?.controls?.length"
        class="flex gap-5"
      >
        <div class="line flex-grow"></div>
        <div class="right-padding-space"></div>
      </div>
    </div>

    <div
      *ngIf="selectedGarnishmentCtrls?.controls?.length"
      class="deduction-box"
    >
      <div class="box-title">Garnishment</div>

      <div class="flex gap-5">
        <div class="flex-grow">
          <div class="col-title">Deduction</div>
        </div>
        <div style="width: 150px">
          <div class="col-title">Amount</div>
        </div>
        <div class="flex right-padding-space"></div>
      </div>

      <div
        *ngFor="let row of selectedGarnishmentCtrls.controls; index as i"
        class="flex gap-4 row-garnishment"
      >
        <div class="flex flex-grow">
          <span class="mt-auto mb-auto">
            {{ row.get('name')?.value }}
          </span>
        </div>

        <ng-container *ngIf="offCyclePayment; else isPayroll">
          <div style="width: 150px">
            <ptg-textbox
              [controlField]="row.get('amount')"
              placeholder="Amount"
              [isDecimal]="true"
              [isPositive]="true"
              [allowZero]="true"
              inputType="Currency"
              [min]="0.0"
              [max]="MAX_VALUE_NUMBER"
              [maxLength]="16"
              [isRequired]="true"
              errorMinMax="Amount must be within the range of 0.00 - 9,999,999,999,999.99."
            ></ptg-textbox>
          </div>
          <div class="flex right-padding-space">
            <mat-icon
              class="remove-button"
              (click)="onClickRemoveRow(DeductionType.Garnishment, i)"
            >
              delete_forever
            </mat-icon>
          </div>
        </ng-container>

        <ng-template #isPayroll>
          <div style="width: 150px">
            <span class="dollar-sign">$</span>
            <span>
              {{ row.get("value")?.value | numberDecimal : { decimal: 2 } }}
            </span>
          </div>
          <div class="flex right-padding-space"></div>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="dialog-footer">
    <button
      mat-raised-button
      type="submit"
      class="save-button"
      [disabled]="!deductions?.length && !activeDeductions?.length"
    >
      Save
    </button>
    <button
      mat-raised-button
      type="button"
      class="cancel-button"
      (click)="onCancel()"
    >
      Cancel
    </button>
  </div>
</form>
