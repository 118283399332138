import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

import { BaseComponent } from '@ptg-shared/components';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { 
  CREATED_AT, 
  CREATED_BY, 
  GUID_EMPTY,
  UPDATED_AT, 
  UPDATED_BY 
} from '@ptg-shared/constance';
import { Column, ColumnType, Row } from '@ptg-shared/controls/grid';
import { FIRST_PAGE } from '@ptg-shared/controls/pagination';
import { getDateString } from '@ptg-shared/utils/string.util';

import * as fromReducer from '@ptg-reducers';
import { SectionLayout } from '@ptg-member/constance/metadata.const';
import { 
  getListDataSnapshotSelector, 
  getStandAlonePropertyOnlyCardDataSelector 
} from '@ptg-member/store/reducers';

import { EntityPropertyType } from '../../types/enums/entity-property.enum';
import { 
  CardComponent, 
  CardProperty, 
  EntityViewItem, 
  GetListPropertyForCardsRequest, 
  GetStandAlonePropertyOnlyCardDataRequest, 
  StandAlonePropertyOnlyCardData 
} from '../../services/models';
import { getCardsByViewAction} from '@ptg-entity-management/store/actions';
import { getCardsByViewSelector } from '@ptg-entity-management/store/selectors';
import { 
  EntityProfileComponentType, 
  IncludeAttachmentType, 
  PropertyDisplay, 
  StandalonePropertiesDisplayRule, 
  ViewType 
} from '@ptg-entity-management/types/enums';
import { 
  getListDataSnapshotAction, 
  getStandAlonePropertyOnlyCardDataAction 
} from '@ptg-member/store/actions/member-detail.actions';
import { getColumConfig, getEntityPropertyName, getEntityValue } from '@ptg-member/helper';

const PAGE_SIZE_CONST = '-ptg-entity-benefit-detail-view-pageSize';

@Component({
  selector: 'ptg-benefit-detail-view',
  templateUrl: './benefit-detail-view.component.html',
  styleUrls: ['./benefit-detail-view.component.scss'],
})
export class BenefitDetailViewComponent extends BaseComponent {
  readonly SectionLayout = SectionLayout;
  readonly ViewType = ViewType;
  readonly IncludeAttachmentType = IncludeAttachmentType;
  readonly StandalonePropertiesDisplayRule = StandalonePropertiesDisplayRule;
  readonly EntityPropertyType = EntityPropertyType;

  isLoadingCards: boolean = true;
  allCards: EntityViewItem[] = [];
  activeCards: EntityViewItem[] = [];

  listBreadcrumbs: Breadcrumb[] = [];
  settings: Breadcrumb[] = [];

  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  isVersionHistory: boolean = false;

  detailSectionLabel: string = '';
  listLabel: string = '';
  isLoadingStandalone: boolean = true;
  standalonePropertyValues?: StandAlonePropertyOnlyCardData[];
  standaloneProperties: CardComponent[] | undefined;
  standaloneViewType?: PropertyDisplay;

  listDetailViewProperties?: any;
  isLoadingListDetailView: boolean = true;
  currentRowIndex: number = 0;
  listDetailViewLabel: string = '';

  currentFund: any = {};
  errorMsg: string = '';
  listCardData: (any & Row)[] = [];
  listCardDataOrigin: (any & Row)[] = [];
  columns: Column[] = [];
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  lengthPg: number = 0;
  isDragDrop: boolean = false;
  card?: EntityViewItem;
  cardId?: string;
  
  optColDates = [CREATED_AT, UPDATED_AT];

  @Input() entityId!: string;
  @Input() recordId!: string;
  @Input() fromScreen?: string;
  @Input() menuId?: string;

  constructor(
    public dialogRef: MatDialogRef<BenefitDetailViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { 
      pageTitle: string; 
      viewName: string, 
      viewId: string, 
      screenId: string, 
      memberId: string, 
      isOverview: boolean, 
      entityId: string; 
      recordId: string 
    },
    public route: ActivatedRoute,
    private store: Store<fromReducer.State>,
    private datePipe: DatePipe
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.listBreadcrumbs = [
      {
        name: this.data.pageTitle,
      },
      {
        name: this.data.viewName ?? '',
        url: '',
      },
    ];
    this.store.dispatch(getCardsByViewAction({ key: 'GET_CARDS_FROM_BENEFIT_OVERVIEW', viewId: this.data.viewId }));

    this.getCardsByViewSelector();
    this.getStandAlonePropertyOnlyCardDataSelector();
    this.getListDataSnapshotSelector();

    this.store.pipe(select(fromReducer.selectCurrentFundState), takeUntil(this.unsubscribe$)).subscribe((el) => {
      this.currentFund = el;
      this.pageSize = el.defaultPageSize ?? 50;

      const defaultPageSize = Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST));
      this.pageSize = defaultPageSize === 0 ? this.pageSize : defaultPageSize;
    });
  }

  getCardsByViewSelector() {
    this.store.pipe(select(getCardsByViewSelector), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (!state) return;

      this.isLoadingCards = state?.isLoading;

      if (!state?.success || !state?.payload || state?.key !== 'GET_CARDS_FROM_BENEFIT_OVERVIEW') return;

      this.card = state?.payload?.entityViewItems[0];
        
      const dataStandAloneProperties = this.card?.cardComponents?.filter(
        (comp) => comp?.componentType === EntityProfileComponentType.Properties,
      );
      this.detailSectionLabel = dataStandAloneProperties?.length ? dataStandAloneProperties[0].detailViewLabel : '';
      this.standaloneProperties = dataStandAloneProperties ?? [];

      let versioningProperties = this.card?.cardComponents?.filter(
        (comp) => comp?.componentType === EntityProfileComponentType['Version History'],
      );
      
      this.standaloneViewType = dataStandAloneProperties?.length
        ? dataStandAloneProperties[0].propertyDisplay
        : PropertyDisplay.Column;
      if (versioningProperties.length === 0) {
        this.getValueStandAlonePropertyAction();
      } else {
        this.listLabel = this.card?.listLabel;
        this.isVersionHistory = true;
        this.getListDataSnapshot();
        this.getListEntity(versioningProperties[0]?.cardProperties);
      }
    });
  }

  getStandAlonePropertyOnlyCardDataSelector() {
    this.store
    .pipe(select(getStandAlonePropertyOnlyCardDataSelector), takeUntil(this.unsubscribe$))
    .subscribe((state) => {
      if (!state) return;

      this.isLoadingStandalone = state.isLoading;

      if (state?.isLoading || !state?.success || !state?.payload) return;

      this.standalonePropertyValues = this.mappingStandalonePropertyValues(state.payload);
    });
  }

  mappingStandalonePropertyValues(values: any) {
    return values.map((item: any) => ({
      recordId: item.recordId,
      entityPropertyId: item.entityPropertyId,
      configs: item.configs,
      propertyName: item.propertyName,
      type: item.type,
      value: this.optColDates.includes(item.option) ? this.datePipe.transform(getDateString(item.value), 'MM/dd/yyyy hh:mm a') : item.value,
      options: item.options,
      option: item.option,
      fixedPropertyKey: item?.fixedPropertyKey,
      entityReferencePropertyId: item?.entityReferencePropertyId,
      optionValue: item?.optionValue,
    }));
  }

  getListDataSnapshotSelector() {
    this.store.pipe(select(getListDataSnapshotSelector), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (!state?.isLoading && state?.success && state?.payload) {
        this.isLoadingListDetailView = state.isLoading;

        this.lengthPg = state.total ?? 0;
  
        const listDetailViewComponent = this.card?.cardComponents?.find(
          (comp) => comp?.componentType === EntityProfileComponentType['List - Detail View'],
        );
        const listDataMappingId =
          listDetailViewComponent?.cardProperties?.map((prop) => ({
            id: this.getColumnName(prop),
            type: prop.type,
            config: prop.config,
            displayFieldType: prop.displayFieldType,
          })) ?? [];
        this.listCardDataOrigin = state.payload || [];
        this.listCardData = (state.payload || [])?.map((item: any) => {
          const obj: any = {};
          item?.values?.forEach((element: any, index: any) => {
            let columnName = element.entityReferencePropertyId ? element.entityReferencePropertyId : '';
            if (element.entityPropertyId) {
              columnName = columnName ? columnName + '_' + element.entityPropertyId : element.entityPropertyId;
            }
  
            if (element.option) {
              columnName = columnName + '_' + element.option;
            }
  
            const options = listDataMappingId.find((item) => item.id === columnName);
  
            obj[`${columnName}`] = getEntityValue(element, options);
          });
          return { ...obj, id: item.id, noOfAttachments: item?.noOfAttachments, values: item.values };
        });
        this.listCardData = this.listCardData.map((elm, index) => {
          const keyCreatedAt = GUID_EMPTY + '_' + CREATED_AT;
          const keyUpdatedAt = GUID_EMPTY + '_' + UPDATED_AT;
          return {
            ...elm,
            [keyCreatedAt]: this.datePipe.transform(getDateString(elm[keyCreatedAt]), 'MM/dd/yyyy hh:mm a'),
            [keyUpdatedAt]: this.datePipe.transform(getDateString(elm[keyUpdatedAt]), 'MM/dd/yyyy hh:mm a')
          }
        });
        this.standalonePropertyValues = this.mappingStandalonePropertyValues(this.listCardDataOrigin[this.currentRowIndex]?.values || []);
      }
    });
  }

  getValueStandAlonePropertyAction() {
    const request: GetStandAlonePropertyOnlyCardDataRequest = {
      cardId: this.card?.cardId || '',
      targetId: this.data.recordId,
      entityId: this.data.entityId,
      screenId: this.data.screenId
    };

    this.store.dispatch(getStandAlonePropertyOnlyCardDataAction({ request }));
  }

  getListDataSnapshot() {
    let request: GetListPropertyForCardsRequest = {};
    request = {
      entityId: this.data.entityId,
      targetId: this.data.recordId,
      cardId: this.card?.cardId,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      screenId: this.data.screenId
    }

    this.store.dispatch(getListDataSnapshotAction({ request }));
  }

  getListEntity(entity: CardProperty[]) {
    this.columns = [];
    entity?.forEach((column: any) => {
      if (column?.showOnTableList === false) {
        return;
      }
      
      const columConfig = getColumConfig(getEntityPropertyName(column.type), column.config);
      const typeDateColFixed = {
        format: 'MM/dd/yyyy hh:mm a'
      }
      this.columns.push({
        name: this.getColumnName(column),
        header: {
          title: column.propertyLabel,
        },
        truncate: true,
        sortable: false,
        type: [
          ColumnType.Address,
          ColumnType.PersonName,
          ColumnType.Binary,
        ].includes(columConfig.type as ColumnType) ? ColumnType.Text : this.optColDates.includes(column.option) ? ColumnType.DateTime : columConfig.type,
        templateArgs: this.optColDates.includes(column.option) ? typeDateColFixed : columConfig.templateArgs,
      });
    });
  }

  getColumnName(column: CardProperty): string {
    let columnName = '';
    const optColNameList = [UPDATED_BY, CREATED_AT, CREATED_BY, UPDATED_AT];
    if(optColNameList.includes(column.option)){
      columnName = GUID_EMPTY;
    } else {
      columnName = column.propertyId;
    }
    if (column.entityReferencePropertyId) {
      columnName = columnName + "_" + column.entityReferencePropertyId;
    }

    if (column.option) {
      columnName = columnName + "_" + column.option;
    }

    return columnName;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onClickBreadcrumb(breadcrumb: Breadcrumb): void {
    if (breadcrumb.name === this.data.pageTitle) this.dialogRef.close();
  }

  onClickEditDetail(): void {}

  selectRow(event: any): void {
    this.standalonePropertyValues = this.mappingStandalonePropertyValues(this.listCardDataOrigin[this.currentRowIndex]?.values || []);
  }

  changePagging(event: any): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;

    sessionStorage.setItem(
      this.currentFund.key + PAGE_SIZE_CONST,
      this.pageSize.toString()
    );
    this.getListDataSnapshot();
  }
  //#endregion
}
