import { createReducer, on } from '@ngrx/store';
import { ACTION, STATE } from '@ptg-shared/constance';
import { BaseActionState } from '@ptg-shared/types/models';
import { EntityProperty, LinkedProperty, Parameter } from '../../services/models';
import { ParameterType } from '../../constants';
import {
  addMasterInputOutputFailure,
  addMasterInputOutputSuccess,
  checkMasterParameterUsingFailureAction,
  checkMasterParameterUsingSuccessAction,
  clearCheckMasterParameterUsingStateAction,
  clearEntityReferenceByLinkedIdStateAction,
  clearGetRetirementBenefitInputOutputsAction,
  clearRetirementBenefitInputOutputState,
  deleteMasterInputOutputFailure,
  deleteMasterInputOutputSuccess,
  editMasterInputOutputFailure,
  editMasterInputOutputSuccess,
  getEntityReferenceByLinkedIdFailure,
  getEntityReferenceByLinkedIdRequest,
  getEntityReferenceByLinkedIdSuccess,
  getLinkedPropertiesFailure,
  getLinkedPropertiesRequest,
  getLinkedPropertiesSuccess,
  getPropertiesByLinkedIdFailure,
  getPropertiesByLinkedIdRequest,
  getPropertiesByLinkedIdSuccess,
  getRetirementBenefitInputOutputsFailure,
  getRetirementBenefitInputOutputsRequest,
  getRetirementBenefitInputOutputsSuccess,
} from '../actions';

export interface InputOutputState {
  listParameter?: BaseActionState<Parameter[]>;
  linkedProperties: LinkedProperty[];
  propertiesList: EntityProperty[];
  actionState?: {
    state: string;
    action: string;
    parameterType: ParameterType;
  };
  checkMasterParameterUsingState?: {
    id: string;
    parameterType: ParameterType;
    isUsing: boolean;
  };
  referenceList?: LinkedProperty[];
}

const initialState: InputOutputState = {
  listParameter: {
    isLoading: true,
  },
  linkedProperties: [],
  propertiesList: [],
};

export const inputOutputReducer = createReducer(
  initialState,
  on(getRetirementBenefitInputOutputsRequest, (state) => ({
    ...state,
    listParameter: {
      isLoading: true,
      total: 0,
      payload: [],
    },
  })),
  on(getRetirementBenefitInputOutputsSuccess, (state, { response }) => ({
    ...state,
    listParameter: {
      isLoading: false,
      total: response.total,
      payload: response.inputOutputMasters,
    },
  })),
  on(getRetirementBenefitInputOutputsFailure, (state) => ({
    ...state,
    listParameter: {
      isLoading: false,
      total: 0,
      payload: [],
    },
  })),
  on(clearGetRetirementBenefitInputOutputsAction, (state) => ({
    ...state,
    listParameter: undefined,
  })),

  on(getLinkedPropertiesRequest, (state) => ({
    ...state,
    linkedProperties: [],
  })),
  on(getLinkedPropertiesSuccess, (state, { linkedProperties }) => ({
    ...state,
    linkedProperties,
  })),
  on(getLinkedPropertiesFailure, (state) => ({
    ...state,
    linkedProperties: [],
  })),

  on(addMasterInputOutputSuccess, (state, { parameterType }) => ({
    ...state,
    actionState: {
      state: STATE.SUCCESS,
      action: ACTION.ADD,
      parameterType,
    },
  })),
  on(addMasterInputOutputFailure, (state, { parameterType }) => ({
    ...state,
    actionState: {
      state: STATE.FAIL,
      action: ACTION.ADD,
      parameterType,
    },
  })),

  on(editMasterInputOutputSuccess, (state, { parameterType }) => ({
    ...state,
    actionState: {
      state: STATE.SUCCESS,
      action: ACTION.EDIT,
      parameterType,
    },
  })),
  on(editMasterInputOutputFailure, (state, { parameterType }) => ({
    ...state,
    actionState: {
      state: STATE.FAIL,
      action: ACTION.EDIT,
      parameterType,
    },
  })),

  on(clearRetirementBenefitInputOutputState, (state) => ({
    ...state,
    actionState: undefined,
  })),

  on(deleteMasterInputOutputSuccess, (state, { parameterType }) => ({
    ...state,
    actionState: {
      state: STATE.SUCCESS,
      action: ACTION.REMOVE,
      parameterType,
    },
  })),
  on(deleteMasterInputOutputFailure, (state, { parameterType }) => ({
    ...state,
    actionState: {
      state: STATE.FAIL,
      action: ACTION.REMOVE,
      parameterType,
    },
  })),

  on(checkMasterParameterUsingSuccessAction, (state, { response, id, parameterType }) => ({
    ...state,
    checkMasterParameterUsingState: {
      id,
      parameterType,
      isUsing: response.exists,
    },
  })),
  on(checkMasterParameterUsingFailureAction, (state, { error }) => ({
    ...state,
    checkMasterParameterUsingState: undefined,
  })),
  on(clearCheckMasterParameterUsingStateAction, (state) => ({
    ...state,
    checkMasterParameterUsingState: undefined,
  })),

  on(getPropertiesByLinkedIdRequest, (state) => ({
    ...state,
    propertiesList: [],
  })),
  on(getPropertiesByLinkedIdSuccess, (state, { response }) => ({
    ...state,
    propertiesList: response.entityProperty,
  })),
  on(getPropertiesByLinkedIdFailure, (state) => ({
    ...state,
    propertiesList: [],
  })),

  on(getEntityReferenceByLinkedIdRequest, (state) => ({
    ...state,
    referenceList: [],
  })),
  on(getEntityReferenceByLinkedIdSuccess, (state, { response }) => ({
    ...state,
    referenceList: response.linkedReferenceProperties,
  })),
  on(getEntityReferenceByLinkedIdFailure, (state) => ({
    ...state,
    referenceList: [],
  })),
  on(clearEntityReferenceByLinkedIdStateAction, (state) => ({
    ...state,
    referenceList: undefined,
  })),
);
