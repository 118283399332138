<div class="distribution-code-mapping">
  <ptg-breadcrumb 
    [listBreadcrumbs]="listBreadcrumbs"
  ></ptg-breadcrumb>
  <div class="banner" *ngIf="bannerType">
    <ptg-banner
      [(bannerType)]="bannerType"
      [message]="message"
    ></ptg-banner>
  </div>
  <ptg-grid-expandable-rows 
    [paginable]="false"
    [dataTable]="dataSource"
    [columnsToDisplay]="columns"
    [columnsExpandToDisplay]="columns"
    [isLoading]="isLoading"
    (sortChange)="sortChange($event)"
    [fitToParent]="true"
    [allowExpandMultiple]="true"
    notFoundMessage="No Data to Display"
  >
    <ng-template cellContent columnName="action" let-row>
      <ptg-button
        *ngIf="!row.benefitPeriod || row.benefitPeriod === '-'"
        buttonName="Edit"
        classInput="edit-button"
        (clickButton)="handleEdit(row)"
      >
        <mat-icon>edit</mat-icon>
      </ptg-button>
    </ng-template>
  </ptg-grid-expandable-rows>
</div>
