<div
  class="profile-navigation-configuration"
  id="profile-navigation-configuration"
>
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
    [functionButtons]="functionButtons"
    (emitFunction)="emitBreadcrumdFunction($event)"
  ></ptg-breadcrumb>

  <div *ngIf="bannerType" class="p-6 navigation-banner">
    <ptg-banner [(bannerType)]="bannerType" [message]="message"></ptg-banner>
  </div>

  <ptg-grid
    [data]="memberNavigationsData"
    [columns]="columns"
    [isLoading]="isLoading"
    [fitToParent]="true"
    [paginable]="true"
    [errorMessage]="errorMsg"
    [totalRecords]="totalRecord"
    (pageChange)="onChangePage($event)"
    notFoundMessage="No Navigation to Display"
    [totalRecords]="totalRecord" 
    [pageSize]="pageSize"
    [pageNumber]="pageNumber"
  >>
    <ng-template cellContent columnName="color" let-row>
      <div class="color-navigation" [style.background]="row.color"></div>
    </ng-template>
    <ng-template cellContent columnName="action" let-row>
      <ptg-button
        buttonName="Edit"
        classInput="edit-button"
        (clickButton)="editRecord(row)"
      >
        <mat-icon>edit</mat-icon>
      </ptg-button>
      <ptg-button
        buttonName="Remove"
        classInput="remove-button"
        (clickButton)="removeRecord(row)"
      >
        <mat-icon>delete_forever</mat-icon>
      </ptg-button>
    </ng-template>
  </ptg-grid>
</div>
