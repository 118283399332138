import { Component, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { filter, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { InputType } from '@ptg-member/constance/metadataPropertyType.const';
import { deepClone } from '@ptg-shared/utils/common.util';
import { DateTime } from 'luxon';
import { Store, select } from '@ngrx/store';
import { DatePipe } from '@angular/common';
import { BaseComponent } from '@ptg-shared/components/base.component';
import { BuybackMakeupYearRecord, EditBuyBackMakeUpInterestFeesRequest } from '../../services/models/buyback-makeup.model';
import { BuyBackMakeUpState } from '../../store/reducers/buyback-makeup.reducer';
import { clearGetBuyBackMakeUpInterestFeesCalculationStateAction, clearGetBuyBackMakeUpYearRecordStateAction, editBuyBackMakeUpInterestFeesAction, getBuyBackMakeUpInterestFeesCalculationAction } from '../../store/actions/buyback-makeup.actions';
import { editBuyBackMakeUpInterestFeesSelector, getBuyBackMakeUpInterestFeesCalculationSelector } from '../../store/selectors/buyback-makeup.selector';
import { getDateString } from '@ptg-shared/utils/string.util';

@Component({
  selector: 'ptg-edit-buyback-makeup-interest-fees',
  templateUrl: './edit-buyback-makeup-interest-fees.component.html',
  styleUrls: ['./edit-buyback-makeup-interest-fees.component.scss']
})
export class EditBuyBackMakeUpInterestFeesComponent extends BaseComponent {
  editForm!: FormGroup;
  formSubmit$ = new Subject<boolean>();
  InputType = InputType;

  memberId: string = this.data.memberId;
  currentBuybackMakeupYearRecord: BuybackMakeupYearRecord | undefined = this.formatTimeZoneForBuybackMakeupYearRecord(this.data.currentBuybackMakeupYearRecord);
  isCalculating: boolean = false;
  snapshotOfData = {
    beginDate: deepClone(this.data.currentBuybackMakeupYearRecord?.interestBegin),
    thruDate: deepClone(this.data.currentBuybackMakeupYearRecord?.interestThru),
  }

  interestBeginMinDate = new Date();
  paymentReceivedMaxDate = new Date();

  errorBeginDateMinDate: string = '';

  constructor(
    public dialogRef: MatDialogRef<EditBuyBackMakeUpInterestFeesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { memberId: string, currentBuybackMakeupYearRecord: BuybackMakeupYearRecord },
    private fb: FormBuilder,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    private dialog: MatDialog,
    private store: Store<BuyBackMakeUpState>,
    public datePipe: DatePipe,
  ) {
    super();
  }

  ngOnInit(): void {
    this.interestBeginMinDate = new Date(`04/01/${this.currentBuybackMakeupYearRecord?.year}`);
    this.errorBeginDateMinDate = `Interest Begin Date should not be earlier than 04/01/${this.currentBuybackMakeupYearRecord?.year}.`;

    this.initFormGroup();

    // Handle when receive Buy Back Make Up Year Record calculation response
    this.store
      .pipe(select(getBuyBackMakeUpInterestFeesCalculationSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data) => {
        if (data) {
          // Disable edit fields when calculating and Enable when calculated completed (both success and failed cases)
          this.isCalculating = data.isLoading;
          
          // If calculate success, overwrite old data by new data
          if (data.success && data.payload?.buybackMakeupYearRecord) {
            this.currentBuybackMakeupYearRecord = this.formatTimeZoneForBuybackMakeupYearRecord(data.payload?.buybackMakeupYearRecord);
            this.totalInterestAmountControl.setValue(data.payload?.buybackMakeupYearRecord.totalInterestAmount);
            this.snapshotOfData = {
              beginDate: deepClone(this.currentBuybackMakeupYearRecord?.interestBegin ?? ''),
              thruDate: deepClone(this.currentBuybackMakeupYearRecord?.interestThru ?? ''),
            }
          }

          // If calculate failed, set current data back to previous version
          if (!data.success && data.error) {
            this.interestBeginDateControl.setValue(this.snapshotOfData.beginDate ?? '');
            this.interestBeginDateControl.markAsTouched();
            this.interestThruDateControl.setValue(this.snapshotOfData.thruDate ?? '');
            this.interestThruDateControl.markAsTouched();
          }
        }
      });

    // Handle after Edit Buy Back Make Up Interest Fees
    this.store
      .pipe(select(editBuyBackMakeUpInterestFeesSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((editBuyBackMakeUpInterestFeesState) => {
        if (editBuyBackMakeUpInterestFeesState) {
          this.store.dispatch(clearGetBuyBackMakeUpInterestFeesCalculationStateAction());
          this.store.dispatch(clearGetBuyBackMakeUpYearRecordStateAction());
          this.dialogRef.close();
        }
      });

    this.submitForm();
  }

  formatTimeZoneForBuybackMakeupYearRecord(record: BuybackMakeupYearRecord): BuybackMakeupYearRecord {
    return {
      ...record,
      
      // -> No transform Time Zone for Interest Begin / Thru Date, Due Date yet
      // interestBegin: this.datePipe.transform(getDateString(record.interestBegin as string), 'yyyy-MM-dd'),
      // interestThru: this.datePipe.transform(getDateString(record.interestThru as string), 'yyyy-MM-dd'),
      // dueDate: this.datePipe.transform(getDateString(record.dueDate as string), 'MM/dd/yyyy'),

      createdDate: this.datePipe.transform(getDateString(record.createdDate as string), 'MM/dd/yyyy'),
    }
  }

  initFormGroup() {
    this.editForm = this.fb.group({
      interestBeginDate: this.fb.control(this.currentBuybackMakeupYearRecord?.interestBegin ?? ''),
      interestThruDate: this.fb.control(this.currentBuybackMakeupYearRecord?.interestThru ?? ''),
      totalInterestAmount: this.fb.control(this.currentBuybackMakeupYearRecord?.totalInterestAmount ?? null),
    });
    this.interestBeginDateControl.markAsTouched();
    this.interestThruDateControl.markAsTouched();
    this.totalInterestAmountControl.markAsTouched();
  }

  get interestBeginDateControl(): FormControl {
    return this.editForm?.get('interestBeginDate') as FormControl;
  }

  get interestThruDateControl(): FormControl {
    return this.editForm?.get('interestThruDate') as FormControl;
  }

  get totalInterestAmountControl(): FormControl {
    return this.editForm?.get('totalInterestAmount') as FormControl;
  }

  validateBeginAndThruDateRelationShip(): boolean {
    // Handle for business logic fields values errors
    const beginDate = DateTime.fromISO(this.interestBeginDateControl.value);
    const thruDate = DateTime.fromISO(this.interestThruDateControl.value);
    if (beginDate >= thruDate) {
      this.interestBeginDateControl.setErrors({ inRange: true });
      this.interestBeginDateControl.markAsTouched();

      this.interestThruDateControl.setErrors({ inRange: true });
      this.interestThruDateControl.markAsTouched();

      return false;
    }
    this.removeFormControlError(this.interestBeginDateControl, 'inRange');
    this.interestBeginDateControl.updateValueAndValidity();
    this.removeFormControlError(this.interestThruDateControl, 'inRange');
    this.interestThruDateControl.updateValueAndValidity();
    return true;
  }

  cleanInterestBeginAndThruDateRelationShipErrorMsg() {
    this.interestBeginDateControl.setErrors(null);
    this.interestBeginDateControl.markAsTouched();

    this.interestThruDateControl.setErrors(null);
    this.interestThruDateControl.markAsTouched();
  }

  onDateChange() {
    // If Date format of Begin Date or Thru Date is Invalid, stop here
    if (this.interestBeginDateControl.hasError('required') || this.interestThruDateControl.hasError('required')) {
      return;
    }

    // If Begin Date >= Thru Date, stop here
    if (this.validateBeginAndThruDateRelationShip() === false) {
      return;
    }

    // If Begin Date < Min Begin Date (1st April of current Remittance Year)
    if (this.interestBeginDateControl.hasError('matDatepickerMin')) {
      return;
    }

    // No validation rules violated
    this.cleanInterestBeginAndThruDateRelationShipErrorMsg();

    // If no validation rules violated, trigger calculating process
    this.getBuybackMakeupInterestFeesCalculationResult();
  }

  removeFormControlError(control: AbstractControl, errorName: string) {
    if (control?.errors && control?.errors[errorName]) {
      delete control.errors[errorName];
      if (Object.keys(control.errors).length === 0) {
        control.setErrors(null);
      }
    }
  }

  onFocusOutTotalInterestAmountInput() {
    const currentTotalInterestAmountInput = this.totalInterestAmountControl.value;
    if (typeof this.currentBuybackMakeupYearRecord !== 'undefined') {
      this.currentBuybackMakeupYearRecord = {
        ...this.currentBuybackMakeupYearRecord,
        cost: (currentTotalInterestAmountInput as number + this.currentBuybackMakeupYearRecord?.balanceDue),
      }
    }
  }

  getBuybackMakeupInterestFeesCalculationResult() {
    // Disable fields while calulation process running
    this.isCalculating = true;

    const beginDate = DateTime.fromISO(this.interestBeginDateControl.value).toFormat('yyyy-MM-dd');
    const thruDate = DateTime.fromISO(this.interestThruDateControl.value).toFormat('yyyy-MM-dd');
    this.store.dispatch(
      getBuyBackMakeUpInterestFeesCalculationAction({
        memberId: this.memberId,
        buyBackMakeUpId: this.currentBuybackMakeupYearRecord?.buybackMakeupId ?? '',
        yearRecordId: this.currentBuybackMakeupYearRecord?.id ?? '',
        interestBeginDate: beginDate,
        interestThruDate: thruDate,
      })
    );
  }

  submitForm() {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter(status => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter(status => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  onSubmit() {
    if (
      ((this.currentBuybackMakeupYearRecord?.cost !== this.currentBuybackMakeupYearRecord?.pensionAmount) &&
      this.currentBuybackMakeupYearRecord?.type === 0) &&
      (this.currentBuybackMakeupYearRecord?.interestPercent === 0 || this.currentBuybackMakeupYearRecord?.interestPercent === null))
    {
      return;
    }
    const request: EditBuyBackMakeUpInterestFeesRequest = {
      type: this.currentBuybackMakeupYearRecord?.type ?? null,
      interestBeginDate: DateTime.fromISO(this.interestBeginDateControl.value).toFormat('yyyy-MM-dd'),
      interestThruDate: DateTime.fromISO(this.interestThruDateControl.value).toFormat('yyyy-MM-dd'),
      totalInterestAmount: this.totalInterestAmountControl.value || 0,
      dueDate: this.currentBuybackMakeupYearRecord?.dueDate ?? '',
      pensionAmount: this.currentBuybackMakeupYearRecord?.pensionAmount ?? null,
      interestPercent: this.currentBuybackMakeupYearRecord?.interestPercent ?? null,
      cost: this.currentBuybackMakeupYearRecord?.cost ?? null,
    }
    this.store.dispatch(
      editBuyBackMakeUpInterestFeesAction({
        memberId: this.memberId,
        buyBackMakeUpId: this.currentBuybackMakeupYearRecord?.buybackMakeupId ?? '',
        yearRecordId: this.currentBuybackMakeupYearRecord?.id ?? '',
        request
      })
    );
  }

  onCancel() {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
    this.store.dispatch(clearGetBuyBackMakeUpInterestFeesCalculationStateAction());
    this.store.dispatch(clearGetBuyBackMakeUpYearRecordStateAction());
  }
}
