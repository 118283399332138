import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';

import { CalculationState } from '@ptg-member/features/calculation/store/reducers';
import { BaseComponent } from '@ptg-shared/components';

import { Align, Column, ColumnType } from '@ptg-shared/controls/grid';
import { takeUntil } from 'rxjs/operators';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { AddEditBuyBackMakeUpRequest, Municipality, MunicipalityForTable } from '../../services/models/buyback-makeup.model';
import { BuyBackMakeUpType, BuyBackMakeUpTypeListOption, BuyBackMakeUpTypeMap } from '../../constants';
import { addBuyBackMakeUpSelector, editBuyBackMakeUpSelector, getListMunicipalitySelector } from '../../store/selectors/buyback-makeup.selector';
import { addBuyBackMakeUpAction, clearAddBuyBackMakeUpStateAction, clearEditBuyBackMakeUpStateAction, editBuyBackMakeUpAction, getListMunicipalityBuyBackMakeUpAction } from '../../store/actions/buyback-makeup.actions';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { Option } from '@ptg-shared/controls/select/select.component';
import { STATE } from '@ptg-shared/constance/value.const';

@Component({
  selector: 'ptg-add-edit-buyback-makeup-dialog',
  templateUrl: './add-edit-buyback-makeup-dialog.component.html',
  styleUrls: ['./add-edit-buyback-makeup-dialog.component.scss'],
})
export class AddEditBuyBackMakeUpDialogComponent extends BaseComponent {
  memberId: string = '';
  buyBackMakeUpId: string = '';
  isAdd: boolean = true;

  isTableLoading: boolean = false;
  totalRecords: number = 0;
  tableData: any[] = [];
  tableColumns: Column[] = [
    {
      name: 'checkbox',
      header: {
        title: '',
      },
      sortable: false,
      truncate: true,
    },
    {
      name: 'year',
      header: {
        title: 'Year',
      },
      sortable: false,
      truncate: true,
    },
    {
      name: 'type',
      header: {
        title: 'Type',
      },
      sortable: false,
      truncate: true,
    },
    {
      name: 'municipalityName',
      header: {
        title: 'Municipality Name',
      },
      sortable: false,
      truncate: true,
    },
    {
      name: 'disabilityFee',
      header: {
        title: 'Disability Fee',
      },
      truncate: true,
      sortable: false,
      align: Align.Right,
      type: ColumnType.Decimal,
      templateArgs:
      {
        decimal: 2,
        unit: '$',
        unitPosition: 'left'
      },
    },
    {
      name: 'pensionFee',
      header: {
        title: 'Pension Fee',
      },
      truncate: true,
      sortable: false,
      align: Align.Right,
      type: ColumnType.Decimal,
      templateArgs:
      {
        decimal: 2,
        unit: '$',
        unitPosition: 'left'
      },
    },
    {
      name: 'metRequirement',
      header: {
        title: 'Met Requirements',
      },
      sortable: false,
      truncate: true,
      cell: (row) => this.formatYesNoValue(row?.metRequirement),
    },
    {
      name: 'refunded',
      header: {
        title: 'Refunded',
      },
      sortable: false,
      truncate: true,
      cell: (row) => this.formatYesNoValue(row?.refunded),
    },
  ];

  formData!: FormGroup;
  recordArrayForm: FormArray = this.fb.array([]);
  listTypeOption: Option[] = BuyBackMakeUpTypeListOption;

  isEdittingOrAdding: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AddEditBuyBackMakeUpDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public store: Store<CalculationState>,
    private fb: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    this.memberId = this.data.memberId;
    this.buyBackMakeUpId = this.data?.buyBackMakeUpId;
    this.isAdd = this.data?.buyBackMakeUpId ? false : true;

    this.initForm();
    this.getData();

    // Handle when receive Buy Back Make Up Add/Edit data response
    this.store
      .pipe(select(getListMunicipalitySelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data) => {
        if (data) {
          this.isTableLoading = data?.isLoading;
          if (data.success) {
            this.totalRecords = data?.total || 0;
            this.tableData = data?.payload?.municipalitys?.map((item: any) => {
              return {
                ...item,
                // Add formatter for fields here if need
              }
            }) ?? [];
            this.initFormGroupAfterRetrieveDataSuccess();
          }
        }
      });

    // Handle after Add Buy Back Make Up in Add-Edit screen
    this.store
      .pipe(select(addBuyBackMakeUpSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((addBuyBackMakeUpState) => {
        if (addBuyBackMakeUpState) {
          this.isEdittingOrAdding = false;
          this.isTableLoading = false;
          if (addBuyBackMakeUpState?.state?.state === STATE.FAIL && addBuyBackMakeUpState?.errorMsg) {
            this.dialog.open(ConfirmPopupComponent, {
              panelClass: 'confirm-popup',
              data: {
                text: addBuyBackMakeUpState?.errorMsg,
                type: ConfirmType.Warning,
                title: 'Error',
                cancelButtonTitle: 'Close',
                hideConfirmButton: true,
              }
            });
            this.store.dispatch(clearAddBuyBackMakeUpStateAction());
          }
          this.dialogRef.close();
        }
      });
    
    // Handle after Edit Buy Back Make Up in Add-Edit screen
    this.store
      .pipe(select(editBuyBackMakeUpSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((editBuyBackMakeUpState) => {
        if (editBuyBackMakeUpState) {
          this.isEdittingOrAdding = false;
          this.isTableLoading = false;
          if (editBuyBackMakeUpState?.state?.state === STATE.FAIL && editBuyBackMakeUpState?.errorMsg) {
            this.dialog.open(ConfirmPopupComponent, {
              panelClass: 'confirm-popup',
              data: {
                text: editBuyBackMakeUpState?.errorMsg,
                type: ConfirmType.Warning,
                title: 'Error',
                cancelButtonTitle: 'Close',
                hideConfirmButton: true,
              }
            });
            this.store.dispatch(clearEditBuyBackMakeUpStateAction());
          }
          this.dialogRef.close();
        }
      });
  }

  initForm() {
    this.recordArrayForm = this.fb.array([]);
  }

  initFormGroupAfterRetrieveDataSuccess() {
    this.recordArrayForm.clear();
    this.tableData = this.tableData.map((record: Municipality, index: number) => {
      const muniList = record?.municipalityBuybackMakeups ?? [];

      // Find current selected Muni and extract related fields
      const isMuniListHaveOnlyOneItem = muniList?.length === 1;
      const selectedMuni = isMuniListHaveOnlyOneItem ? muniList?.[0] : muniList?.find((muni) => muni.isSelected === true);
      const {
        municipalityId: selectedMuniId,
        municipalityName: displayMuniName,
        disabilityFee,
        pensionFee,
        metRequirement,
        refunded, 
      } = selectedMuni || {};

      // Prepare data for Muni Dropdown
      const muniDropdownList = muniList?.map((item) => ({
        value: item?.municipalityId,
        displayValue: item?.municipalityName,
      }))

      // Prepare Form Array for table
      this.recordArrayForm.push(
        this.fb.group({
          checkbox: this.fb.control(this.isAdd ? false : (record?.isCheck ?? false)),
          typeDropdown: this.fb.control(record?.type),
          muniDropdown: this.fb.control(this.isAdd ? (isMuniListHaveOnlyOneItem ? selectedMuniId : '') : (selectedMuniId ?? '')),
        }),
      );

      // Manipulate table data to serve business
      return {
        ...record,
        rowIndex: index,
        isCheck: this.isAdd ? false : (record?.isCheck ?? false),
        disabilityFee: this.isAdd || !selectedMuniId ? (isMuniListHaveOnlyOneItem ? disabilityFee : '') : disabilityFee,
        pensionFee: this.isAdd || !selectedMuniId ? (isMuniListHaveOnlyOneItem ? pensionFee : '') : pensionFee,
        metRequirement: this.isAdd || !selectedMuniId ? (isMuniListHaveOnlyOneItem ? metRequirement : '') : metRequirement,
        refunded: this.isAdd || !selectedMuniId ? (isMuniListHaveOnlyOneItem ? refunded : '') : refunded,

        isShowTypeEditSection: false,
        selectedType:  record?.type ?? BuyBackMakeUpType.BuyBack,
        displayType: BuyBackMakeUpTypeMap.get(record?.type) ?? BuyBackMakeUpTypeMap.get(0),
        listTypeOpt: this.listTypeOption,
        inprogressTypeVal: 2,
        isTypeDropdownSelected: false,

        isShowMuniEditSection: false,
        allowToUseMuniEditBtn: this.isAdd ? (isMuniListHaveOnlyOneItem ? false : true) : !isMuniListHaveOnlyOneItem,
        selectedMuniId: this.isAdd ? (isMuniListHaveOnlyOneItem ? selectedMuniId : '') : selectedMuniId,
        displayMuniName: this.isAdd ? (isMuniListHaveOnlyOneItem ? displayMuniName : '') : displayMuniName,
        listMuniNameOpt: muniDropdownList,
        inprogressMuniVal: '',
        isMuniDropdownSelected: false,
      }
    });
  }

  onNext() {
    // Validate here
    // --> Case "No record checked"
    const isNoRecordChecked = this.tableData.map(row => row.isCheck).every(item => item === false);
    if (isNoRecordChecked) {
      this.dialog.open(ConfirmPopupComponent, {
        panelClass: 'confirm-popup',
        data: {
          text: 'At least 1 record must be selected.',
          type: ConfirmType.Warning,
          title: 'Error',
          cancelButtonTitle: 'Close',
          hideConfirmButton: true,
        }
      });
      return;
    }
    // --> Case "Checked record but not pick Type or Municipality"
    const isAllCheckedRowHaveTypeAndMunicipalityPicked = this.tableData.filter(row => row.isCheck)
      .every(
        row => 
          (row.selectedType === 0 || row.selectedType === 1) && 
          row.selectedMuniId
        );
    if (!isAllCheckedRowHaveTypeAndMunicipalityPicked) {
      this.dialog.open(ConfirmPopupComponent, {
        panelClass: 'confirm-popup',
        data: {
          text: 'Please input Type and Municipality for selected Year(s).',
          type: ConfirmType.Warning,
          title: 'Error',
          cancelButtonTitle: 'Close',
          hideConfirmButton: true,
        }
      });
      return;
    }
    // All passed, call API to Add / Edit
    const request: AddEditBuyBackMakeUpRequest = {
      id: this.isAdd ? null : this.buyBackMakeUpId, // Id of Buy Back Make Up record, `null` in Add new case
      setBuybackMakeupDetails: this.tableData.filter((item) => item?.isCheck === true).map(row => ({
        isCheck: row?.isCheck,
        id: this.isAdd ? null : row?.id, // Id of each Year row, `null` in Add new case
        municipalityId: row?.selectedMuniId, // Id of selected Municipality
        year: row?.year,
        type: row?.selectedType,
      }))
    }
    if (this.isAdd) {
      this.isEdittingOrAdding = true;
      this.isTableLoading = true;
      this.store.dispatch(addBuyBackMakeUpAction({
        memberId: this.memberId,
        request: request,
      }))
    } else {
      this.isEdittingOrAdding = true;
      this.isTableLoading = true;
      this.store.dispatch(editBuyBackMakeUpAction({
        memberId: this.memberId,
        request: request,
      }))
    }
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        title: 'Cancel Action',
        text: 'Are you sure want to cancel any/all changes?',
        type: ConfirmType.Cancel,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  getData() {
    this.store.dispatch(
      getListMunicipalityBuyBackMakeUpAction({
        request: {
          memberId: this.memberId,
          buyBackMakeUpId: this.isAdd ? '' : this.buyBackMakeUpId,
        },
      })
    );
  }
  
  onCheckBoxChange($event: any, row: MunicipalityForTable) {
    const rowIdx = row.rowIndex;
    this.tableData[rowIdx].isCheck = $event;
  }

  showEditSection(row: MunicipalityForTable, fieldName: string) {
    if (fieldName === 'Type') {
      row.isShowTypeEditSection = true;
      row.inprogressTypeVal = 2;
      row.isTypeDropdownSelected = false;
    } else {
      row.isShowMuniEditSection = true;
      row.inprogressMuniVal = '';
      row.isMuniDropdownSelected = false;
    }
  }

  handleDropdownChange(row: MunicipalityForTable, fieldName: string) {
    const rowIdx = row.rowIndex;
    
    if (fieldName === 'Type') {
      row.isTypeDropdownSelected = true;
      const currentTypeValueSelected = (this.recordArrayForm.controls[rowIdx] as FormGroup).controls.typeDropdown.value;
      row.inprogressTypeVal = currentTypeValueSelected;
    } else {
      row.isMuniDropdownSelected = true;
      const currentMuniValueSelected = (this.recordArrayForm.controls[rowIdx] as FormGroup).controls.muniDropdown.value;
      const currentMuniOptionList = row.listMuniNameOpt || [];
      row.inprogressMuniVal = currentMuniOptionList.find((item) => item.value === currentMuniValueSelected)?.value;
    }
  }

  commitEditResult(row: MunicipalityForTable, fieldName: string) {
    const rowIdx = row.rowIndex;
    if (fieldName === 'Type') {
      if (row.isTypeDropdownSelected === true) {
        row.selectedType = row.inprogressTypeVal;
        this.tableData[rowIdx].selectedType = row.inprogressTypeVal;
        (this.recordArrayForm.controls[rowIdx] as FormGroup).controls.typeDropdown.setValue(row.inprogressTypeVal);
        row.displayType = BuyBackMakeUpTypeMap.get(row.inprogressTypeVal ?? 0);
      }

      row.inprogressTypeVal = 2;
      row.isShowTypeEditSection = false;
      row.isTypeDropdownSelected = false;
    } else {
      if (row.isMuniDropdownSelected === true) {
        const currentSelectedMuniOption = row?.municipalityBuybackMakeups.find((item) => item.municipalityId === row?.inprogressMuniVal);
        row.selectedMuniId = row.inprogressMuniVal;
        this.tableData[rowIdx].selectedMuniId = row.inprogressMuniVal;
        (this.recordArrayForm.controls[rowIdx] as FormGroup).controls.muniDropdown.setValue(row.inprogressMuniVal);

        row.displayMuniName = currentSelectedMuniOption?.municipalityName;
        row.disabilityFee = currentSelectedMuniOption?.disabilityFee;
        row.pensionFee = currentSelectedMuniOption?.pensionFee;
        row.metRequirement = currentSelectedMuniOption?.metRequirement;
        row.refunded = currentSelectedMuniOption?.refunded;
      }
      
      row.inprogressMuniVal = '';
      row.isShowMuniEditSection = false;
      row.isMuniDropdownSelected = false;
    }
  }

  discardEditResult(row: MunicipalityForTable, fieldName: string) {
    const rowIdx = row.rowIndex;
    if (fieldName === 'Type') {
      row.isTypeDropdownSelected = false;
      row.isShowTypeEditSection = false;
      (this.recordArrayForm.controls[rowIdx] as FormGroup).controls.typeDropdown.setValue(row.selectedType);
      row.inprogressTypeVal = 2;
    } else {
      row.isMuniDropdownSelected = false;
      row.isShowMuniEditSection = false;
      (this.recordArrayForm.controls[rowIdx] as FormGroup).controls.muniDropdown.setValue(row.selectedMuniId);
      row.inprogressMuniVal = '';
    }
  }

  formatYesNoValue(value: any): any {
    if (value === '' || value === null || typeof value === 'undefined') return '';
    if (value === true) return 'Yes';
    if (value === false) return 'No';
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
