import { PropertyType } from '../../constance/metadata.const';
import { RelationshipType } from '../../constance/participant-relationship.const';

export interface MemberGraphViewData {
  memberGraphViewDatas: ParticipantRelationship[];
}

export interface CardRelationshipDetail {
  entityId: string;
  memberGraphConfigDataItems: MemberGraphConfigDataItem[];
}

export interface ParticipantRelationship {
  isCurrentMember?: boolean;
  memberId: string;
  linkedParticipantId: string | null;
  cardLabel: string;
  relationshipLabel: string;
  parentLabel: string;
  childLabel: string;
  isParentParticipant: boolean;
  entityRelationshipType: number;
  graphRelationshipType: RelationshipType;
  memberGraphConfigDataItems: MemberGraphConfigDataItem[];
  type: number;
  selected?: boolean;
  benefitLabels?: string[];
}

export interface MemberGraphConfigDataItem {
  sectionKey: string;
  propertyKey: string;
  propertyLabel: string;
  value: string;
  order: number;
  type: PropertyType;
  typedValue: any;
  isBenefitProperty?: boolean;
}
