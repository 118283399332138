import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  ParameterMappingsRequest,
  GetCalculationDetailResponse,
  GetCalculationInputMappingResponse,
  GetCalculationOutputMappingResponse,
  GetCalculationParameterMappingQuery,
  GetFileParametersResponse,
  RetirementBenefitCalculations,
  CheckMappingInputOutputExistsResponse,
  CheckMappingInputOutputExistsRequest,
  GetFileParametersRequest,
  UpdateCalculationFileRequest,
  GetListSavePropertyRequest,
  GetListSavePropertyResponse,
} from './models';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';
import { SortType } from '@ptg-shared/constance';

@Injectable({
  providedIn: 'root',
})
export class CalculationListService {
  calculationFile?: File;

  constructor(private httpClient: HttpClient) {}

  getCalculationList(query: any): Observable<RetirementBenefitCalculations> {
    let params = new HttpParams({ fromObject: query as any });
    return this.httpClient.get<RetirementBenefitCalculations>(
      `${environment.apiUrl}/calculations/calculation-options/calculations`,
      { params },
    );
  }

  getCalculationDetail(calculationId: string): Observable<GetCalculationDetailResponse> {
    return this.httpClient.get<GetCalculationDetailResponse>(
      `${environment.apiUrl}/calculations/calculation-options/calculations/${calculationId}`,
    );
  }

  getCalculationInputMapping(
    query: GetCalculationParameterMappingQuery,
  ): Observable<GetCalculationInputMappingResponse> {
    let params = new HttpParams();
    if (query.pageSize) {
      params = params.append('PageSize', query.pageSize);
    }
    params = params.append('PageIndex', query.pageIndex);
    if (query.sortField) {
      params = params.append('SortNames', capitalizeFirstLetter(query.sortField));
      params = params.append('SortType', query.sortType ?? SortType.ASC);
    }
    return this.httpClient.get<GetCalculationInputMappingResponse>(
      `${environment.apiUrl}/calculations/calculation-options/calculations/${query.calculationId}/inputs`,
      { params },
    );
  }

  getCalculationOutputMapping(
    query: GetCalculationParameterMappingQuery,
  ): Observable<GetCalculationOutputMappingResponse> {
    let params = new HttpParams();
    if (query.pageSize) {
      params = params.append('PageSize', query.pageSize);
    }
    params = params.append('PageIndex', query.pageIndex);
    if (query.sortField) {
      params = params.append('SortNames', capitalizeFirstLetter(query.sortField));
      params = params.append('SortType', query.sortType ?? SortType.ASC);
    }
    return this.httpClient.get<GetCalculationOutputMappingResponse>(
      `${environment.apiUrl}/calculations/calculation-options/calculations/${query.calculationId}/outputs`,
      { params },
    );
  }

  checkFileNameExisted = (body: { name: string }) => {
    return this.httpClient.post<{ exists: boolean; message: string }>(
      `${environment.apiUrl}/calculations/calculation-options/calculations/name/existing`,
      body,
    );
  };

  uploadCalculationFile(uploadFormData: UpdateCalculationFileRequest) {
    const body = new FormData();
    body.append('name', uploadFormData.name);
    body.append('description', uploadFormData.description);
    body.append('benefitEntityId', uploadFormData.benefitEntityId);
    if (uploadFormData.id) {
      body.append('id', uploadFormData.id);
    }
    if (this.calculationFile) {
      body.append('file', this.calculationFile);
    }
    return this.httpClient.post(`${environment.apiUrl}/calculations/calculation-options/calculations`, body);
  }

  deleteCalculation(calculationId: string) {
    return this.httpClient.delete(
      `${environment.apiUrl}/calculations/calculation-options/calculations/${calculationId}`,
    );
  }

  createParameterMapping(request: ParameterMappingsRequest): Observable<void> {
    return this.httpClient.post<void>(
      `${environment.apiUrl}/calculations/calculation-options/parameter-mapping`,
      request,
    );
  }

  getFileParameters(request: GetFileParametersRequest): Observable<GetFileParametersResponse> {
    let params = new HttpParams();
    if (request.parameterMappingId) {
      params = params.append('Id', request.parameterMappingId);
    }
    if (request.parameterType !== undefined && request.parameterType !== null) {
      params = params.append('Type', request.parameterType);
    }
    return this.httpClient.get<GetFileParametersResponse>(
      `${environment.apiUrl}/calculations/calculation-options/${request.calculationId}/file-parameters`,
      { params },
    );
  }

  setParameterMapping(request: ParameterMappingsRequest): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/calculations/calculation-options/parameter-mapping/${request.id}`,
      request,
    );
  }

  checkParameterMapping(id: string): Observable<void> {
    return this.httpClient.get<void>(`${environment.apiUrl}/calculations/calculation-options/parameter-mapping/${id}`);
  }

  removeParameterMapping(id: string): Observable<void> {
    return this.httpClient.delete<void>(
      `${environment.apiUrl}/calculations/calculation-options/parameter-mapping/${id}`,
    );
  }

  checkMappingInputOutputExists(
    request: CheckMappingInputOutputExistsRequest,
  ): Observable<CheckMappingInputOutputExistsResponse> {
    let params = new HttpParams();
    if (request.mappingParameterId) {
      params = params.append('MappingPrameterId', request.mappingParameterId);
    }
    if (request.benefitTypeId && request.benefitSubTypeId) {
      params = params.append('BenefitTypeId', request.benefitTypeId);
      params = params.append('BenefitSubTypeId', request.benefitSubTypeId);
    }
    if (request.sheetName && request.fieldName) {
      params = params.append('SheetName', request.sheetName);
      params = params.append('FieldName', request.fieldName);
    }
    return this.httpClient.get<CheckMappingInputOutputExistsResponse>(
      `${environment.apiUrl}/calculations/calculation-options/${request.calculationId}/parameter-mapping/input-outputs/${request.masterParameterId}/existing`,
      { params },
    );
  }

  getListSaveProperty(request: GetListSavePropertyRequest): Observable<GetListSavePropertyResponse> {
    let params = new HttpParams();
    if (request.parameterMappingId) {
      params = params.append('paramMappingId', request.parameterMappingId);
    }
    return this.httpClient.get<GetListSavePropertyResponse>(
      `${environment.apiUrl}/calculations/calculation-options/calculations/${request.calculationId}/save-to-properties/${request.dataType}`,
      { params },
    );
  }
}
