import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { CalculationLoddBenefitService } from '../../services';
import {
  createCalculationLoddBenefitsAction,
  createCalculationLoddBenefitsFailureAction,
  createCalculationLoddBenefitsSuccessAction,

  getLoddValidationBeforeRecalculatesAction,
  getLoddValidationBeforeRecalculatesFailureAction,
  getLoddValidationBeforeRecalculatesSuccessAction,

} from '../actions';

@Injectable()
export class CalculationLoddBenefitEffects {
  constructor(
    private actions$: Actions,
    private calculationLoddBenefitService: CalculationLoddBenefitService
  ) {}

  createCalculationLoddBenefits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createCalculationLoddBenefitsAction),
      switchMap(({ request }) =>
        this.calculationLoddBenefitService.createCalculationLoddBenefits(request).pipe(
          map((response: any) => createCalculationLoddBenefitsSuccessAction({response})),
          catchError((error) => of(createCalculationLoddBenefitsFailureAction({ error })))
        )
      )
    )
  );

  getLoddValidationBeforeRecalculates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getLoddValidationBeforeRecalculatesAction),
      switchMap(({ request }) =>
        this.calculationLoddBenefitService.getLoddValidationBeforeRecalculates(request).pipe(
          map((response: any) => getLoddValidationBeforeRecalculatesSuccessAction({ response })),
          catchError((error) => of(getLoddValidationBeforeRecalculatesFailureAction({ error })))
        )
      )
    )
  );

}
