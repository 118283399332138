import { createAction, props } from "@ngrx/store";
import { AccessAccountsData, AccessAccountsQuery, NewUser } from "../models/access-accounts.model";

export const AccessAccountsRequest = '[AccessAccounts/API] Send Request';
export const AccessAccountsFailure = '[AccessAccounts/API] Failure';
export const AccessAccountsSuccess = '[AccessAccounts/API] Success';

export const CreateAccessAccountRequest = '[CreateAccessAccount/API] Send Request';
export const CreateAccessAccountFailure = '[CreateAccessAccount/API] Failure';
export const CreateAccessAccountSuccess = '[CreateAccessAccount/API] Success';

export const UpdateAccessAccountRequest = '[UpdateAccessAccount/API] Send Request';
export const UpdateAccessAccountFailure = '[UpdateAccessAccount/API] Failure';
export const UpdateAccessAccountSuccess = '[UpdateAccessAccount/API] Success';

export const RemoveAccessAccountRequest = '[RemoveAccessAccount/API] Send Request';
export const RemoveAccessAccountFailure = '[RemoveAccessAccount/API] Failure';
export const RemoveAccessAccountSuccess = '[RemoveAccessAccount/API] Success';

export const AccessAccountClear = '[AccessAccount] Clear';


export const getAccessAccounts = createAction(
    AccessAccountsRequest,
    props<{ query: AccessAccountsQuery }>()
);
export const getAccessAccountsSuccess = createAction(
    AccessAccountsSuccess,
    props<{ accessAccountsData: AccessAccountsData }>()
);
export const getAccessAccountsFailure = createAction(
    AccessAccountsFailure,
    props<{ error?: any }>()
);

export const createAccessAccount = createAction(
    CreateAccessAccountRequest,
    props<{ body: NewUser }>()
);
export const createtAccessAccountSuccess = createAction(
    CreateAccessAccountSuccess,
);
export const createAccessAccountFailure = createAction(
    CreateAccessAccountFailure,
    props<{ errorMsg: string }>()
);

export const updateAccessAccount = createAction(
    UpdateAccessAccountRequest,
    props<{ id: string, body: NewUser}>()
);
export const updateAccessAccountSuccess = createAction(
    UpdateAccessAccountSuccess,
);
export const updateAccessAccountFailure = createAction(
    UpdateAccessAccountFailure,
    props<{ errorMsg: string }>()
);

export const removeAccessAccount = createAction(
    RemoveAccessAccountRequest,
    props<{ id: string }>()
);
export const removeAccessAccountSuccess = createAction(
    RemoveAccessAccountSuccess,
);
export const removeAccessAccountFailure = createAction(
    RemoveAccessAccountFailure,
    props<{ errorMsg: string }>()
);

export const accessAccountClear = createAction(
    AccessAccountClear
);


