import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import * as fromReducer from '@ptg-reducers';
import { BaseComponent } from '@ptg-shared/components';
import { SortType } from '@ptg-shared/constance/value.const';
import { Column, ColumnType, Row } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { getDateString } from '@ptg-shared/utils/string.util';

import * as StatusHistoryActions from '../../store/actions/status-history.action';
import { STATUS_ACTION } from '../../constance/status-action.const';
import * as fromMember from '../../store/reducers';
import {
  AuditTrail,
  GetAuditTrailsRequest,
  StatusAction,
} from '../../types/models';

const PAGE_SIZE_CONST = '-ptg-status-history-audit-pageSize'
@Component({
  selector: 'ptg-status-history-audit',
  templateUrl: './status-history-audit.component.html',
  styleUrls: ['./status-history-audit.component.scss'],
})
export class StatusHistoryAuditComponent extends BaseComponent {
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: '',
    },
    {
      name: 'View Audit Trail',
    },
  ];
  auditTrailList: (AuditTrail & Row)[] = [];
  selectedAuditTrailRows: (any & Row)[] = [];
  selectedAuditTrail?: AuditTrail;
  isLoading = false;
  columns: Column[] = [
    {
      name: 'actionString',
      header: {
        title: 'Action',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: 'modifiedAt',
      header: {
        title: 'Modified At',
      },
      sortable: true,
      type: ColumnType.DateTime,
      templateArgs: { format: 'MM/dd/yyyy h:mm a' },
      truncate: true,
    },
    {
      name: 'modifiedBy',
      header: {
        title: 'Modified By',
      },
      sortable: true,
      truncate: true,
    },
  ];
  selectedAuditTrailColumns: Column[] = [
    {
      name: 'fieldName',
      header: {
        title: 'Field Name',
      },
      truncate: true
    },
    {
      name: 'before',
      header: {
        title: 'Before',
      },
      truncate: true
    },
    {
      name: 'after',
      header: {
        title: 'After',
      },
      truncate: true
    },
  ];
  currentFund: any = {};
  sortInfo?: Sort = {
    active: 'modifiedAt',
    direction: 'desc',
  };
  lengthPg: number = 0;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      statusHistoryMenuName: string;
      memberId: string;
      statusHistoryId: string;
    },
    private memberStore: Store<fromMember.MemberState>,
    public dialogRef: MatDialogRef<StatusHistoryAuditComponent>,
    private store: Store<fromReducer.State>,
    private datePipe: DatePipe
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.listBreadcrumbs[0].name = this.data.statusHistoryMenuName;
    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.pageSize = el.defaultPageSize ?? 50;
        this.currentFund = el;
        this.pageSize = Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)) === 0 ? this.pageSize : Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST));
      
      });
    this.getData();
    this.memberStore
      .pipe(
        select(fromMember.selectGetAuditTrailsState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state) {
          this.isLoading = state.isLoading;

          if (!state.isLoading && state.success) {
            const data = state.payload != null ? state.payload : [];
            this.auditTrailList = data.map((item) => {
              return {
                ...item,
                actionString: this.getStatusAction(item),
                modifiedAt: getDateString(item.modifiedAt)
              };
            });
            this.lengthPg = state.total || 0;
            this.selectedAuditTrail = this.auditTrailList[0];
            const initData = true;
            this.createSelectedAuditTrailRows(
              this.selectedAuditTrail,
              initData
            );
          }
        }
      });
  }
  getStatusAction(item: any) {
    switch (item.action) {
      case StatusAction.CreateStatus:
        return STATUS_ACTION.CREATE_STATUS;
      case StatusAction.UpdateStatus:
        return STATUS_ACTION.UPDATE_STATUS;
    }
    return STATUS_ACTION.UPDATE_STATUS_WO_CHANGED_DATA;
  }
  createSelectedAuditTrailRows(
    selectedAuditTrail: any,
    initData: boolean = false
  ) {
    if (initData || selectedAuditTrail.id != this.selectedAuditTrail?.id) {
      this.selectedAuditTrailRows = [
        {
          fieldName: 'Event Date',
          before: selectedAuditTrail.eventDateBefore
            ? this.datePipe.transform(
                selectedAuditTrail.eventDateBefore,
                'MM/dd/yyyy'
              )
            : 'N/A',
          after: this.datePipe.transform(
            selectedAuditTrail.eventDateAfter,
            'MM/dd/yyyy'
          ),
        },
        {
          fieldName: 'Status',
          before: selectedAuditTrail.statusBefore
            ? selectedAuditTrail.statusBefore
            : 'N/A',
          after: selectedAuditTrail.statusAfter,
        },
        {
          fieldName: 'Event',
          before: selectedAuditTrail.eventBefore
            ? selectedAuditTrail.eventBefore
            : 'N/A',
          after: selectedAuditTrail.eventAfter,
        },
      ];
    }
    this.selectedAuditTrail = selectedAuditTrail;
  }
  getData(): void {
    let request: GetAuditTrailsRequest = {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      memberId: this.data?.memberId,
      statusHistoryId: this.data?.statusHistoryId,
    };

    if (this.sortInfo) {
      const field =
        this.sortInfo.active[0].toUpperCase() + this.sortInfo.active.substr(1);
      request = {
        ...request,
        sortNames: this.sortInfo.direction ? field : '',
        sortType:
          this.sortInfo.direction === 'asc' ? SortType.ASC : SortType.DESC,
      };
    }
    this.memberStore.dispatch(StatusHistoryActions.getAuditTrails({ request }));
  }
  selectRow(row: AuditTrail & Row) {
    this.createSelectedAuditTrailRows(row);
  }
  onChangePage(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
     
    sessionStorage.setItem(this.currentFund.key + PAGE_SIZE_CONST, this.pageSize.toString()); 
    this.getData();
  }

  onChangeSort(event: Sort): void {
    this.sortInfo = event;
    this.sortInfo.active =
      this.sortInfo.active == 'actionString' ? 'action' : this.sortInfo.active;
  }

  onClickBreadcrumb() {
    this.dialogRef.close();
  }
}
