import { createAction, props } from '@ngrx/store';

import {
  CheckExistCheckRetirementDocumentCanRemoveRequest,
  CheckExistCheckRetirementDocumentCanRemoveResponse,
} from '../../services/models';

export const checkRetirementDocumentCanRemoveAction = createAction(
  '[CheckRetirementDocumentCanRemove/API] Send Request',
  props<{ request: CheckExistCheckRetirementDocumentCanRemoveRequest }>()
);

export const checkRetirementDocumentCanRemoveSuccessAction = createAction(
  '[CheckRetirementDocumentCanRemove/API] Success',
  props<{ response: CheckExistCheckRetirementDocumentCanRemoveResponse }>()
);

export const checkRetirementDocumentCanRemoveFailureAction = createAction(
  '[CheckRetirementDocumentCanRemove/API] Failure',
  props<{ error?: any }>()
);

export const clearCheckRetirementDocumentCanRemoveStateAction = createAction(
  '[CheckRetirementDocumentCanRemove] Clear'
);
