import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';
import { STATE } from '@ptg-shared/constance/value.const';

import { AddressHistoryAction } from '../actions';
import { AddressHistoryList } from '../../types/models';
import { InitiationProperty } from '@ptg-entity-management/services/models';

export const AddressHistoryFeatureKey = 'addressHistory';
export interface State {
  isLoading: boolean;
  addressHistoryResult: AddressHistoryList;
  createAddress?: string;
  updateAddress?: string;
  properties?: InitiationProperty[];
  messages?: any;
  checkAddress: string;
}

const initialState: State = {
  isLoading: true,
  addressHistoryResult: {
    total: 0,
    listAddressHistory: [],
  },
  createAddress: '',
  updateAddress: '',
  properties: [],
  messages: [],
  checkAddress: ''
};

export const reducer = createReducer(
  initialState,
  on(AddressHistoryAction.getAddressHistoryList, (state, {}) => ({
    ...state,
    isLoading: true,
    addressHistoryList: {
      total: 0,
      listAddressHistory: [],
    },
  })),
  on(AddressHistoryAction.getListSuccess, (state, { addressHistoryResult }) => ({
    ...state,
    isLoading: false,
    addressHistoryResult,
  })),
  on(AddressHistoryAction.getListFailure, (state) => ({
    ...state,
    isLoading: false,
    addressHistoryResult: {
      total: 0,
      listAddressHistory: [],
    },
  })),
  on(AddressHistoryAction.clearAddressHistoryState, (state) => ({
    ...state,
    createAddress: '',
    updateAddress: '',
    messages: []
  })),
  on(AddressHistoryAction.getAddressProperties, (state) => ({
    ...state,
    properties: [],
  })),
  on(AddressHistoryAction.getAddressPropertiesSuccess, (state, { properties }) => ({
    ...state,
    properties: properties ?? [],
  })),
  on(AddressHistoryAction.getAddressPropertiesFailure, (state) => ({
    ...state,
    properties: [],
  })),
  on(AddressHistoryAction.createAddressSuccess, (state) => ({
    ...state,
    createAddress: STATE.SUCCESS,
  })),
  on(AddressHistoryAction.createAddressFailure, (state) => ({
    ...state,
    createAddress: STATE.FAIL,
  })),
  on(AddressHistoryAction.updateAddressSuccess, (state) => ({
    ...state,
    updateAddress: STATE.SUCCESS,
  })),
  on(AddressHistoryAction.updateAddressFailure, (state) => ({
    ...state,
    updateAddress: STATE.FAIL,
  })),
  on(AddressHistoryAction.checkExitAddress, (state) => ({
    ...state,
    messages: []
  })),
  on(AddressHistoryAction.checkExitAddressSuccess, (state, { messages }) => ({
    ...state,
    messages,
  })),
  on(AddressHistoryAction.checkExitAddressFailure, (state) => ({
    ...state,
  })),
);
