import { Component, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import {
  filter,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import {
  ACTION,
  CANCEL_CONFIRM_MESSAGE,
  STATE,
} from '@ptg-shared/constance/value.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { showBanner } from '@ptg-shared/utils/common.util';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { Option } from '@ptg-shared/controls/select/select.component';
import { BaseComponent } from '@ptg-shared/components';
import { VERTICAL_LINE_SEPARATOR } from '@ptg-shared/constance/common.const';
import {
  ACTION_COLUMN,
  Align,
  Column,
  GridComponent,
  Row,
  getValidatorsFromColumns,
} from '@ptg-shared/controls/grid';
import * as ProfileOverviewConfigurationActions from '@ptg-member/store/actions/profile-overview-configuration.actions';
import {
  CheckboxOption,
  SetProfileOverviewRequest,
  ProfileOverview,
  ProfileOverviewConfiguration,
  PropertyDisplayConfiguration,
  SectionMetadata,
} from '@ptg-member/types/models';
import * as fromMember from '@ptg-member/store/reducers';
import { DisplaySectionWODataType, SideType } from '@ptg-member/types/enums';
import { SectionLayout } from '@ptg-member/constance/metadata.const';
import * as fromReducer from '@ptg-reducers';
import { Status } from '@ptg-employer/models/employer-navigation-configuration.model';

import { ProfileOverviewConfigurationService } from '../../services/profile-overview-configuration.service';

@Component({
  selector: 'ptg-profile-overview-configuration-detail',
  templateUrl: './profile-overview-configuration-detail.component.html',
  styleUrls: ['./profile-overview-configuration-detail.component.scss'],
})
export class ProfileOverviewConfigurationDetailComponent extends BaseComponent {
  readonly ACTION_COLUMN = ACTION_COLUMN;
  readonly SectionLayout = SectionLayout;

  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Overview',
      url: '',
    },
    {
      name: 'Profile Overview View',
      url: '',
    },
    {
      name: 'Profile Overview Configuration',
      url: '',
    },
  ];

  overviewId?: string;
  memberId: string = '';

  bannerType: BannerType = BannerType.Hidden;
  message: string = '';

  editForm?: FormGroup;
  formSubmit$ = new Subject<boolean>();
  isChanged: boolean = false;

  isStatic: boolean = false;
  metadataProfileOverview?: ProfileOverview;

  // Status selection
  private _notUsedStatuses: Status[] = [];
  get notUsedStatuses(): Status[] {
    return this._notUsedStatuses;
  }
  set notUsedStatuses(value: Status[]) {
    this._notUsedStatuses = value;
    this.notUsedStatusOptions = this._notUsedStatuses.map(
      (x) =>
        ({
          displayValue: x.name,
          value: x,
          iconConfig: {
            icon: x.iconName,
            color: x.color,
            iconFirst: true,
          },
        } as Option)
    );
  }
  notUsedStatusOptions: Option[] = [];
  selectedStatuses: Status[] = [];
  get isAddStatusDisabled() {
    return (
      this.isStatic ||
      !this.statusCtrl.value?.id ||
      this.statusCtrl.value?.id === 'Catch-All'
    );
  }

  displayNonListSectionWODataOptions: Option[] = [
    {
      displayValue: 'Title only',
      value: DisplaySectionWODataType.TitleOnly,
    },
    {
      displayValue: 'Title and message',
      value: DisplaySectionWODataType.TitleAndMessage,
    },
    {
      displayValue: 'Title and properties (if any)',
      value: DisplaySectionWODataType.TitleAndProperties_IfAny,
    },
  ];

  displayListSectionWODataOptions: Option[] = [
    {
      displayValue: 'Title only',
      value: DisplaySectionWODataType.TitleOnly,
    },
    {
      displayValue: 'Title and message',
      value: DisplaySectionWODataType.TitleAndMessage,
    },
    {
      displayValue: 'Title, column headers and message',
      value: DisplaySectionWODataType.TitleAndProperties_IfAny,
    },
  ];

  // Add section area
  allSections: SectionMetadata[] = [];
  availableSections: SectionMetadata[] = [];
  availableSectionOptions: Option[] = [];
  private _configuredSections: (ProfileOverviewConfiguration & Row)[] = [];
  get configuredSections(): (ProfileOverviewConfiguration & Row)[] {
    return this._configuredSections;
  }
  set configuredSections(value: (ProfileOverviewConfiguration & Row)[]) {
    this._configuredSections = value;

    // Update the list of available sections / options when changes configuredSections
    this.availableSections = this.allSections.filter(
      (x) => !this.configuredSections.some((y) => y.sectionKey === x.sectionKey)
    );
    this.availableSectionOptions = this.availableSections.map(
      (x) =>
        ({
          displayValue: x.sectionName,
          value: x,
        } as Option)
    );
  }
  propertyOptions: CheckboxOption[] = [];
  propertyDisplayConfigs: PropertyDisplayConfiguration[] = [];

  // Form controls
  get nameCtrl(): FormControl {
    return this.editForm?.get('name') as FormControl;
  }
  get displayNonListSectionWODataCtrl(): FormControl {
    return this.editForm?.get('displayNonListSectionWOData') as FormControl;
  }
  get displayListSectionWODataCtrl(): FormControl {
    return this.editForm?.get('displayListSectionWOData') as FormControl;
  }
  statusCtrl: FormControl = new FormControl(null);
  sectionNameCtrl: FormControl = new FormControl(null, [Validators.required]);
  sectionLabelCtrl: FormControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(100),
    this.checkSectionLabelDuplicated(),
  ]);
  optionsCtrl: FormControl = new FormControl('', [Validators.required]);

  // Table Dataless / Left Column / Right Column
  datalessColumns: Column[] = [
    {
      name: 'name',
      header: {
        title: 'Section Name',
      },
      truncate: true,
    },
    {
      name: 'showSectionDataless',
      header: {
        title: 'Show Section',
      },
    },
    {
      name: 'showPropertyDataless',
      header: {
        title: 'Show Property',
      },
    },
  ];
  configuredSectionColumns: Column[] = [
    {
      name: 'name',
      truncate: true,
      editable: true,
      validators: {
        required: {
          type: (obj: any) => Validators.required,
          message: (error: any, fieldName: string) =>
            `${fieldName} is required.`,
        },
        maxlength: {
          type: (obj: any) => Validators.maxLength(100),
          message: (error: any, fieldName: string) =>
            `Exceed the ${error.requiredLength} character limit.`,
        },
        existed: {
          type: (obj: any) => this.checkExits(obj),
          message: (error: any, fieldName: string) =>
            `${fieldName} already exists.`,
        },
      },
    },
    {
      name: ACTION_COLUMN,
      align: Align.Left,
      width: '50px',
    },
  ];
  leftSections: (ProfileOverviewConfiguration & Row)[] = [];
  rightSections: (ProfileOverviewConfiguration & Row)[] = [];
  isLoadingSections: boolean = true;

  @ViewChild('gridDataless')
  gridDataless?: GridComponent<ProfileOverviewConfiguration>;
  @ViewChild('gridLeftSections')
  gridLeftSections?: GridComponent<ProfileOverviewConfiguration>;
  @ViewChild('gridRightSections')
  gridRightSections?: GridComponent<ProfileOverviewConfiguration>;

  constructor(
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
    public route: ActivatedRoute,
    private fb: FormBuilder,
    private profileOverviewConfigurationService: ProfileOverviewConfigurationService,
    public dialog: MatDialog
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.route.params.subscribe((params) => {
      this.memberId = params.memberId;
      this.listBreadcrumbs[0].url = `/member/detail/${params.memberId}`;
      this.listBreadcrumbs[1].url = `/member/profile-overview-configuration/${params.memberId}`;
      this.overviewId = params.id;
      this.loadData();
    });

    // Listen for get the list of section metadatas
    // Listen for get the list of not used statuses
    combineLatest([
      this.memberStore.select(fromMember.selectGetSectionMetadatas),
      this.memberStore.select(fromMember.selectStatusNotUsedList),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([state1, state2]) => {
        // Get the list of available section metadatas
        if (!state1?.isLoading && state1?.success) {
          this.allSections = state1?.payload || [];
        }

        // Get the list of available section metadatas
        if (!state2?.isLoading && state2?.success) {
          this.notUsedStatuses = state2?.payload || [];
        }

        // All observers is completed
        if (state1 && !state1?.isLoading && state2 && !state2?.isLoading) {
          if (this.overviewId) {
            // If edit, get the profile overview detail from DB
            this.memberStore.dispatch(
              ProfileOverviewConfigurationActions.getProfileOverviewConfiguration(
                {
                  profileOverviewId: this.overviewId,
                }
              )
            );
          } else {
            // If add new, initial form data with default value
            this.configuredSections = [];
            this.initFormGroup();
          }
        }
      });

    // Listen for get the profile overview detail
    this.memberStore
      .pipe(
        select(fromMember.selectProfileOverviewDetail),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        this.isLoadingSections = !!state?.isLoading;

        // Get the list of available section metadatas
        if (!state?.isLoading && state?.success && state?.payload) {
          const overviewData = state.payload;
          const metadataProfileOverview = overviewData.metadataProfileOverview;
          this.isStatic = !!metadataProfileOverview?.isStatic;
          this.selectedStatuses =
            (metadataProfileOverview?.statuses
              ?.map((x) =>
                overviewData.statuses.find(
                  (y) => y.id.toLowerCase() === x.id.toLowerCase()
                )
              )
              .filter((x) => x) as Status[]) || [];

          // Order by side and then by order field
          this.configuredSections =
            metadataProfileOverview?.metadataProfileOverviewConfigurations
              ?.map((config) => {
                const refSection = this.allSections.find(
                  (section) => section.sectionKey === config.sectionKey
                );
                return {
                  ...config,
                  sectionName:
                    config.sectionName || refSection?.sectionName || '',
                  sectionType: refSection?.type,
                };
              })
              .sort((a, b) => {
                if (a.onSide === SideType.Right && b.onSide === SideType.Left)
                  return 1;
                if (a.onSide === SideType.Left && b.onSide === SideType.Right)
                  return -1;
                if (a.order > b.order) return 1;
                if (a.order < b.order) return -1;
                return 0;
              }) || [];

          this.leftSections = this.configuredSections
            .filter((config) => config.onSide === SideType.Left)
            .sort((a, b) =>
              a.order > b.order ? 1 : a.order < b.order ? -1 : 0
            )
            .map(
              (config) =>
                ({
                  ...config,
                  isStatic: config?.sectionKey === "demographics" ? config?.isStatic : false,
                  form: this.createInlineEditFormControls(config),
                } as ProfileOverviewConfiguration & Row)
            );

          this.rightSections = this.configuredSections
            .filter((config) => config.onSide === SideType.Right)
            .sort((a, b) =>
              a.order > b.order ? 1 : a.order < b.order ? -1 : 0
            )
            .map(
              (config) =>
                ({
                  ...config,
                  isStatic: config?.sectionKey === "demographics" ? config?.isStatic : false,
                  form: this.createInlineEditFormControls(config),
                } as ProfileOverviewConfiguration & Row)
            );

          this.initFormGroup(metadataProfileOverview);
        }
      });

    // Listen for Add or Edit result
    this.memberStore
      .pipe(
        select(fromMember.selectSetProfileOverviewState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state && !state?.isLoading) {
          if (state?.success && state?.payload) {
            showBanner.call(
              this,
              STATE.SUCCESS,
              'Profile Overview View',
              this.overviewId ? ACTION.EDIT : ACTION.ADD
            );

            if (!this.overviewId) {
              const url = `${window.location.origin}/profile-overview-configuration/${this.memberId}/detail/${state.payload}`;
              window.history.pushState(null, '', url);
              this.overviewId = state.payload;
            }

            this.resetData();
          } else {
            showBanner.call(
              this,
              STATE.FAIL,
              'Profile Overview View',
              this.overviewId ? ACTION.EDIT : ACTION.ADD
            );
          }
        }
      });

    // Listen for submit form
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm?.markAllAsTouched();
        }),
        switchMap(() =>
          this.editForm!.statusChanges.pipe(
            startWith(this.editForm!.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter(
          (status) =>
            status === AbstractControlStatus.VALID &&
            this.gridLeftSections?.formStatus === AbstractControlStatus.VALID &&
            this.gridRightSections?.formStatus ===
              AbstractControlStatus.VALID &&
            this.selectedStatuses?.length > 0
        )
      )
      .subscribe(() => {
        this.onSubmit();
      });

    this.store.dispatch(LayoutActions.hiddenSideMenu());
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.memberStore.dispatch(
      ProfileOverviewConfigurationActions.clearProfileOverviewConfigurationDetail()
    );
  }

  // Initial form group when edit profile overview detail
  private initFormGroup(data?: ProfileOverview): void {
    this.editForm = this.fb.group({
      name: new FormControl(data?.name || '', [
        Validators.required,
        Validators.maxLength(100),
      ]),
      displayNonListSectionWOData: new FormControl(
        data?.displayNonListSectionWOData || DisplaySectionWODataType.TitleOnly
      ),
      displayListSectionWOData: new FormControl(
        data?.displayListSectionWOData || DisplaySectionWODataType.TitleOnly
      ),
    });

    if (this.isStatic) {
      this.statusCtrl.disable();
    }

    this.nameCtrl.clearAsyncValidators();
    this.nameCtrl.addAsyncValidators(
      checkApiValidator(
        this.profileOverviewConfigurationService.checkExistProfileName,
        'name',
        data?.name || ''
      )
    );
  }

  private loadData(): void {
    this.memberStore.dispatch(
      ProfileOverviewConfigurationActions.getSectionMetadatas()
    );

    this.memberStore.dispatch(
      ProfileOverviewConfigurationActions.getNotUsedStatuses()
    );
  }

  private checkSectionLabelDuplicated(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        this.configuredSections.some(
          (config) =>
            config.name?.trim()?.toLowerCase() ===
            control.value?.trim()?.toLowerCase()
        )
      ) {
        return {
          duplicatedValue: `Section Label already exists.`,
        };
      }

      return null;
    };
  }

  onChangeData(): void {
    this.isChanged = true;
  }

  //#region Handle for Status
  onClickAddStatus(): void {
    const selectedStatus: Status = this.statusCtrl.value;
    this.selectedStatuses = [...this.selectedStatuses, selectedStatus];
    this.notUsedStatuses = this.notUsedStatuses.filter(
      (x) => x.id !== selectedStatus.id
    );
    this.statusCtrl.setValue(null);
    this.isChanged = true;
  }

  onClickRemoveStatus(status: Status): void {
    this.statusCtrl.markAllAsTouched();

    this.selectedStatuses = this.selectedStatuses.filter(
      (x) => x.id !== status.id
    );
    this.notUsedStatuses = [...this.notUsedStatuses, status].sort((a, b) =>
      a.name > b.name ? 1 : a.name < b.name ? -1 : 0
    );
    this.isChanged = true;
  }
  //#endregion

  //#region Handle for Add Section
  onChangeSectionName(): void {
    const selectedSection: SectionMetadata = this.sectionNameCtrl.value;
    this.sectionLabelCtrl.setValue(selectedSection?.sectionName || '');
    this.propertyOptions =
      selectedSection?.options?.map((option) => ({
        checked: false,
        value: option,
      })) || [];
  }

  onChangeOptions(option: CheckboxOption): void {
    this.optionsCtrl.markAsTouched();

    option.checked = !option.checked;
    const options = this.propertyOptions.reduce((result: string, item) => {
      if (item.checked && !item.disabled) {
        result +=
          (!result ? '' : VERTICAL_LINE_SEPARATOR) + item.value.propertyKey;
      }
      return result;
    }, '');
    this.optionsCtrl.setValue(options);
    this.isChanged = true;
  }

  onClickAddSection(): void {
    this.sectionNameCtrl.markAsTouched();
    this.sectionLabelCtrl.markAsTouched();

    if (!this.sectionNameCtrl.valid || !this.sectionLabelCtrl.valid) return;

    const addedSection: SectionMetadata = this.sectionNameCtrl.value;
    const options = this.propertyOptions.reduce((result: string, option) => {
      if (option.checked && !option.disabled) {
        result +=
          (!result ? '' : VERTICAL_LINE_SEPARATOR) + option.value.propertyKey;
      }
      return result;
    }, '');

    if (!options && this.propertyOptions.length){
      this.optionsCtrl.markAsTouched();
      this.optionsCtrl.setValue(options);
      return;
    }
     

    // Append a new row into the left sections table
    const newConfig: ProfileOverviewConfiguration & Row = {
      sectionKey: addedSection.sectionKey,
      sectionName: addedSection.sectionName,
      name: this.sectionLabelCtrl.value,
      order: this.gridLeftSections!.dataSource.length,
      options: options,
      onSide: SideType.Left,
      showSectionDataless: false,
      showPropertyDataless: false,
      isRemoved: false,
      isStatic: addedSection.isStatic,
      sectionType: addedSection.type,
    };
    newConfig.form = this.createInlineEditFormControls(newConfig);

    // Insert new row into the left sections table
    this.leftSections = [...this.gridLeftSections!.dataSource, newConfig];

    // Insert new row into the dataless table
    this.configuredSections = [
      ...this.leftSections,
      ...this.gridRightSections!.dataSource,
    ];

    this.sectionNameCtrl.reset();
    this.sectionLabelCtrl.reset();
    this.propertyOptions = [];
    this.isChanged = true;
  }
  //#endregion

  //#region Handle for Dataless / Left / Right table
  onChangeDatalessToggle(): void {
    // Re-set left sections data
    this.leftSections = this.gridLeftSections!.dataSource.map((section) => {
      const refSection = this.gridDataless!.dataSource.find(
        (x) => x.sectionKey === section.sectionKey
      );
      return {
        ...section,
        showSectionDataless: !!refSection?.showSectionDataless,
        showPropertyDataless: !!refSection?.showPropertyDataless,
      };
    });

    // Re-set right sections data
    this.rightSections = this.gridRightSections!.dataSource.map((section) => {
      const refSection = this.gridDataless!.dataSource.find(
        (x) => x.sectionKey === section.sectionKey
      )!;
      return {
        ...section,
        showSectionDataless: refSection.showSectionDataless,
        showPropertyDataless: refSection.showPropertyDataless,
      };
    });
    this.isChanged = true;
  }

  onSoftDeleteSectionConfig(row: ProfileOverviewConfiguration & Row): void {
    // Soft delete corresponding item at the dataless table
    this.configuredSections = this.gridDataless!.dataSource.map(
      (config: ProfileOverviewConfiguration & Row) => ({
        ...config,
        deleted:
          config.sectionKey === row.sectionKey ? row.deleted : config.deleted,
      })
    );
    this.isChanged = true;
  }

  onClickMove2Right(row: ProfileOverviewConfiguration & Row): void {
    row.onSide = SideType.Right;
    row.order = this.gridRightSections!.dataSource.length + 1;

    this.leftSections = this.gridLeftSections!.dataSource.filter(
      (section) => section.sectionKey !== row.sectionKey
    ).map((section, index) => ({ ...section, order: index + 1 }));

    this.rightSections = [...this.gridRightSections!.dataSource, row];
    this.isChanged = true;
  }

  onClickMove2Left(row: ProfileOverviewConfiguration & Row): void {
    row.onSide = SideType.Left;
    row.order = this.gridLeftSections!.dataSource.length + 1;

    this.rightSections = this.gridRightSections!.dataSource.filter(
      (section) => section.sectionKey !== row.sectionKey
    ).map((section, index) => ({ ...section, order: index + 1 }));

    this.leftSections = [...this.gridLeftSections!.dataSource, row];
    this.isChanged = true;
  }

  private createInlineEditFormControls(
    config: ProfileOverviewConfiguration
  ): FormGroup {
    return this.fb.group({
      name: new FormControl(
        config.name,
        getValidatorsFromColumns('name', this.configuredSectionColumns, config)
      ),
    });
  }

  private checkExits(config: ProfileOverviewConfiguration): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const existed = this.configuredSections.some(
        (row) =>
          row.sectionKey !== config.sectionKey &&
          row.name.toLowerCase().trim() === control.value.toLowerCase().trim()
      );
      return existed ? { existed: true } : null;
    };
  }
  //#endregion

  onSubmit(): void {
    const leftSections = this.getProfileOverviewConfigs(
      this.gridLeftSections!.dataSource
    );
    const rightSections = this.getProfileOverviewConfigs(
      this.gridRightSections!.dataSource
    );
    const metadataProfileOverview: SetProfileOverviewRequest = {
      metadataProfileOverview: {
        id: this.overviewId || undefined,
        name: this.editForm?.value.name || '',
        statuses: this.selectedStatuses,
        displayNonListSectionWOData:
          this.editForm?.value.displayNonListSectionWOData,
        displayListSectionWOData: this.editForm?.value.displayListSectionWOData,
        metadataProfileOverviewConfigurations: [
          ...leftSections,
          ...rightSections,
        ],
      },
    };
    this.memberStore.dispatch(
      ProfileOverviewConfigurationActions.setProfileOverview({
        request: metadataProfileOverview,
      })
    );
    this.isChanged = false;
  }

  private getProfileOverviewConfigs(
    data: ProfileOverviewConfiguration[]
  ): ProfileOverviewConfiguration[] {
    let order: number = 1;
    return data.map((config: ProfileOverviewConfiguration & Row) => {
      // Always exists
      const refSection = this.gridDataless!.dataSource.find(
        (item) => item.sectionKey === config.sectionKey
      )!;
      const newConfig: ProfileOverviewConfiguration = {
        id: config.id,
        sectionKey: config.sectionKey,
        sectionName: config.sectionName,
        name: config.name,
        order: config.deleted ? -1 : order,
        options: config.options,
        onSide: config.onSide,
        showSectionDataless: refSection.showSectionDataless,
        showPropertyDataless: refSection.showPropertyDataless,
        isRemoved: config.isRemoved,
      };

      if (!config.deleted) order++;
      return newConfig;
    });
  }

  onCancel(): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: CANCEL_CONFIRM_MESSAGE,
        type: ConfirmType.Cancel,
        title: 'Cancel Action',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) this.resetData();
    });
  }

  private resetData(): void {
    this.statusCtrl.reset();
    this.sectionNameCtrl.reset();
    this.sectionLabelCtrl.reset();
    this.propertyOptions = [];
    this.isChanged = false;
    this.loadData();
  }
}
