import { createAction, props } from '@ngrx/store';
import { MemberPropetyDataByMaskedConfigQuery } from '../../types/models';

export const GetPropetyDataByMaskedConfigRequest =
  '[GetPropetyDataByMaskedConfig/API] Send Request';
export const GetPropetyDataByMaskedConfigSuccess =
  '[GetPropetyDataByMaskedConfig/API] Success';
export const GetPropetyDataByMaskedConfigFailure =
  '[GetPropetyDataByMaskedConfig/API] Failure';
export const ClearReloadingState = '[ClearReloadingState]';

export const getPropetyDataByMaskedConfigRequest = createAction(
  GetPropetyDataByMaskedConfigRequest,
  props<{ query: MemberPropetyDataByMaskedConfigQuery }>()
);
export const getPropetyDataByMaskedConfigSuccess = createAction(
  GetPropetyDataByMaskedConfigSuccess,
  props<{ value: string; query: MemberPropetyDataByMaskedConfigQuery }>()
);
export const getPropetyDataByMaskedConfigFailure = createAction(
  GetPropetyDataByMaskedConfigFailure
);
export const clearReloadingState = createAction(ClearReloadingState);
