import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DistributionMappingService } from '@ptg-member/services/distributionMapping.service';
import { DistributionMappingAction } from '../actions';
import { DistributionListResponse } from '@ptg-member/types/models/distribution-mapping.model';

@Injectable()
export class DistributionMappingEffect {
  constructor(
    private actions$: Actions,
    private distributionMappingService: DistributionMappingService
  ) {}

  getPageData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DistributionMappingAction.getDataList),
      switchMap(({ query }) => {
        return this.distributionMappingService.getDataList(query).pipe(
          map((response: DistributionListResponse) => {
            response?.distributionCodeMappings.forEach(element => {
              element.modifiedAt += 'Z';
            });
            return DistributionMappingAction.getDataListSuccess({ dataList: response.distributionCodeMappings });
          }),
          catchError((error) => {
            return of(DistributionMappingAction.getDataListFailure({ error }));
          })
        );
      })
    )
  );

  updateMapping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DistributionMappingAction.updateMappingRequest),
      switchMap(({ body, id }) => {
        return this.distributionMappingService.updateDistributionCodeMapping(body, id).pipe(
          map(() => {
            return DistributionMappingAction.updateMappingSuccess();
          }),
          catchError((err) => {
            return of(
              DistributionMappingAction.updateMappingFailure({ error: err.message })
            );
          })
        );
      })
    )
  );
}
