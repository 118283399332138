import { DatePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { merge, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import { Option } from '@ptg-shared/controls/select/select.component';
import { deepClone } from '@ptg-shared/utils/common.util';
import { EmployerFilter } from '@ptg-employer/models/employer-filter.model';

import * as StatusActions from '../../store/actions/status.actions';
import {
  FilterPropertyType,
  HeaderType,
  INPUT_TYPE,
  Operator,
  OPERATOR_BY_TYPE,
  OPERATOR_LABEL,
} from '../../constance/member-list.const';
import { InputType } from '../../constance/metadataPropertyType.const';
import {
  FilterByPropertyConfig,
  FilterInputType,
  MemberFilter,
  MemberStatus,
  PropertyItem,
} from '../../types/models';
import * as fromMember from '../../store/reducers';
import { MemberListActions } from '../../store/actions';
import { OptionValue } from '@ptg-shared/types/models';

@Component({
  selector: 'ptg-add-member-filter',
  templateUrl: './add-member-filter.component.html',
  styleUrls: ['./add-member-filter.component.scss'],
})
export class AddMemberFilterComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<void>();
  listSectionProperty: Option[] = [];
  filterByOptions: Option[] = [];
  filterOperators: Option[] = [];
  filterFormGroup: FormGroup = this.fb.group({
    filterBy: this.fb.control('', [Validators.required]),
    operator: this.fb.control('', [Validators.required]),
    valueFrom: this.fb.control('', [Validators.required]),
    valueTo: this.fb.control('', [this.validateBetweenValue()]),
  });
  currentFilters: MemberFilter[] = [];
  placeholder = {
    from: 'Value',
    to: 'Value To',
  };
  TEXT_BOX_INPUT_TYPE = InputType;
  inputType: FilterInputType = INPUT_TYPE[FilterPropertyType.Text];
  FIXED_PROPERTIES: Record<string, string> = {
    memberid: 'Member Id',
    registered: 'Registered',
    IsLocked: 'Locked',
    last4Ssn: 'SSN',
    paperlessOptedIn: 'Paperless Opted-In',
    smsOptedIn: 'SMS Opted-In',
  };
  booleanOptions: Option[] = [
    { displayValue: 'Yes', value: true },
    { displayValue: 'No', value: false },
  ];
  inputConfig: {
    suffix: string;
    isPositive: boolean;
    isDecimal: boolean;
    allowZero: boolean;
  } = {
    suffix: '',
    isPositive: true,
    isDecimal: true,
    allowZero: true,
  };
  statusOptions: Option[] = [];
  eventOptions: Option[] = [];
  eventOperatorOptions: Option[] = [];
  dateOperatorOptions: Option[] = [];
  valueOptions: Option[] = [];
  isMultipleLineOption: boolean = false;

  constructor(
    private fb: FormBuilder,
    private memberStore: Store<fromMember.MemberState>,
    public dialogRef: MatDialogRef<AddMemberFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { currentFilters: MemberFilter[] }
  ) {}

  ngOnInit(): void {
    this.memberStore.dispatch(
      MemberListActions.getFilterByPropertyListRequest()
    );
    this.memberStore.dispatch(
      StatusActions.getStatus({ query: { SortNames: 'Name', SortType: 0 } })
    );
    this.memberStore
      .select(fromMember.selectMemberStatusList)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((statusList) => {
        this.statusOptions = (deepClone(statusList) as MemberStatus[]).map(
          (status) => {
            return {
              value: status.id,
              displayValue: status.name,
            };
          }
        );
      });
    this.memberStore
      .select(fromMember.selectStatusDetail)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((statusDetailState) => {
        if (
          this.filterFormGroup.value.filterBy.propertyType !==
            FilterPropertyType.Status ||
          !this.filterFormGroup.value.valueFrom
        ) {
          this.eventOptions = [];
          return;
        }
        this.eventOptions = (
          statusDetailState?.statusDetail?.memberEvents || []
        ).map((event) => {
          return {
            value: event.id,
            displayValue: event.name,
          };
        });
      });
    this.memberStore
      .select(fromMember.selectListSectionProperty)
      .pipe(first(),takeUntil(this.unsubscribe$))
      .subscribe((listSectionProperty) => {
        this.mapSectionPropertyData(listSectionProperty);
        if (this.data?.currentFilters) {
          this.currentFilters = deepClone(this.data.currentFilters);
          this.getFilterByOptions();
        }
      });
    this.updateValidityFormGroup();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  mapSectionPropertyData(listSectionProperty: FilterByPropertyConfig[]) {
    this.listSectionProperty = listSectionProperty?.reduce((result, item) => {
      const propertyConfig = JSON.parse(
        JSON.stringify(item)
      ) as FilterByPropertyConfig;

      if (
        propertyConfig.headerType === HeaderType.Property &&
        [
          FilterPropertyType.Address,
          FilterPropertyType['Person Name'],
        ].includes(propertyConfig.propertyType)
      ) {
        propertyConfig.options.forEach((option) => {
          const currentOption = JSON.parse(JSON.stringify(option));
          const config = JSON.parse(
            JSON.stringify(item)
          ) as FilterByPropertyConfig;
          if (currentOption.value === 'Prefix') {
            currentOption.value = 'Salutation';
          }
          if (currentOption) {
            config.isRequired = currentOption.isRequired;
          }
          config.options = [currentOption];
          config.propertyTypeLabel = `${
            FilterPropertyType[config.propertyType]
          } - ${currentOption.value}`;
          result.push({
            value: config,
            displayValue: `${config.propertyName} - ${currentOption.value}`,
            valueDescription: config.sectionName,
          });
        });
        return result;
      }
      propertyConfig.propertyTypeLabel =
        this.FIXED_PROPERTIES[propertyConfig.propertyKey] ||
        FilterPropertyType[propertyConfig.propertyType];
      result.push({
        value: propertyConfig,
        displayValue: this.getValueDisplayProperty(propertyConfig),
        valueDescription: this.getValueDescription(propertyConfig)
      });
      return result;
    }, [] as Option[]);
    this.filterByOptions = [...this.listSectionProperty];
  }

  private getValueDisplayProperty(propertyConfig: any): string {
    if (propertyConfig.headerType === HeaderType.Aggregation) {
      return propertyConfig?.aggregationName;
    }
    if (
      propertyConfig.headerType === HeaderType.Property
    ) {
      return propertyConfig?.propertyName;
    }
    if (propertyConfig.headerType === HeaderType.Calculation) {
      return propertyConfig?.calculationName;
    }
    return '';
  }

  private getValueDescription(propertyConfig: any): string {
    if (propertyConfig.headerType === HeaderType.Aggregation) {
      return `${propertyConfig?.sectionName} / ${propertyConfig?.propertyName}`;
    }
    if (
      propertyConfig.headerType === HeaderType.Property
    ) {
      return propertyConfig?.sectionName;
    }
    if (propertyConfig.headerType === HeaderType.Calculation) {
      return 'Calculation';
    }
    return '';
  }


  onFilterBySelect() {
    this.valueOptions = [];
    if (
      this.filterFormGroup.value.filterBy.propertyType ===
      FilterPropertyType.Status
    ) {
      this.handleFilterStatus();
      this.valueOptions = deepClone(this.statusOptions);
      return;
    }
    const filterBy = this.filterFormGroup.value.filterBy;
    this.filterFormGroup = this.initFilterForm(this.filterFormGroup.value);
    this.inputType = INPUT_TYPE[filterBy.propertyType];
    if (
      ['registered', 'IsLocked', 'paperlessOptedIn', 'smsOptedIn'].includes(
        filterBy.propertyKey
      )
    ) {
      this.valueOptions = deepClone(this.booleanOptions);
    }
    if (
      [
        'Address - State/Province/Territory',
        'Address - Country',
        'Person Name - Salutation',
      ].includes(filterBy.propertyTypeLabel) ||
      filterBy.isUsedLookupTable ||
      filterBy.propertyType === FilterPropertyType.Boolean ||
      filterBy.propertyType === FilterPropertyType.Department ||
      filterBy.propertyType === FilterPropertyType.Employer
    ) {
      this.inputType = 'Boolean';
    }
    if (
      filterBy.headerType === HeaderType.Aggregation &&
      filterBy.aggregationType === 0
    ) {
      this.inputType = INPUT_TYPE[FilterPropertyType['Whole Number']];
    }
    const currentProperty = this.listSectionProperty.find((property) => {
      return (
        property.value.propertyKey === filterBy.propertyKey &&
        property.value.sectionKey === filterBy.sectionKey &&
        property.value.propertyTypeLabel === filterBy.propertyTypeLabel
      );
    }) as any;
    const isRequired = currentProperty?.value?.isRequired;
    const isEncrypted = currentProperty?.value?.isEncryptedProperty;
    const isMultipleLineOption =
      filterBy.propertyType === FilterPropertyType.Department;
    if (filterBy.propertyType === FilterPropertyType.Department) {
      this.isMultipleLineOption = isMultipleLineOption;
    }
    if (filterBy.propertyType === FilterPropertyType.Employer) {
      this.isMultipleLineOption = false;
    }
    if (
      currentProperty?.value?.options?.[0]?.optionValues?.length ||
      currentProperty?.value?.optionValues?.length
    ) {
      this.valueOptions = deepClone(
        currentProperty?.value?.options?.[0]?.optionValues ||
          currentProperty?.value?.optionValues ||
          []
      ).map((option: OptionValue) => {
        return {
          displayValue: option.text,
          value: this.getOptionValue(option),
          valueDescription: this.isMultipleLineOption
            ? option.description
            : undefined,
        };
      });
    } else if (
      currentProperty?.value?.propertyType === FilterPropertyType.List &&
      currentProperty?.value?.isUsedLookupTable
    ) {
      this.valueOptions = deepClone(
        currentProperty?.value?.optionValues || []
      ).map((option: OptionValue) => {
        return {
          displayValue: option.text,
          value: option.id,
        };
      });
    }
    this.filterOperators = this.getOperatorOptions(
      filterBy.headerType === HeaderType.Aggregation &&
        filterBy.aggregationType === 0
        ? 'Count'
        : filterBy.propertyTypeLabel,
      isRequired,
      isEncrypted
    );
    this.inputConfig = this.getInputConfig(
      filterBy.headerType === HeaderType.Aggregation &&
        filterBy.aggregationType === 0
        ? 'Whole Number'
        : filterBy.propertyTypeLabel
    );
    this.filterFormGroup.get('operator')?.setValue('');
    this.filterFormGroup.get('valueFrom')?.setValue('');
    this.filterFormGroup.get('valueTo')?.setValue('');
    this.onFilterOperatorSelect();
  }

  getOptionValue(option: OptionValue) {
    switch (option.type) {
      case 4: {
        return true;
      }
      case 5: {
        return false;
      }
      default: {
        return option.id;
      }
    }
  }

  onFilterOperatorSelect() {
    var operator = this.filterFormGroup.value.operator;
    var operatorOptionsStatus = this.getOperatorOptions('StatusEvent');
    if (this.filterFormGroup.get('filterBy')?.value?.propertyType === FilterPropertyType.Status) {
      if (operator === Operator.NotEquals || operator === Operator.IsAny) {
        this.eventOperatorOptions = operatorOptionsStatus.filter(x => x.value === Operator.IsAny);
      } else {
        this.eventOperatorOptions = operatorOptionsStatus;
      }
      if (operator === Operator.IsAny) {
        this.filterFormGroup = this.fb.group({
          ...this.filterFormGroup.controls,
          valueFrom: [{
            value: '',
            disabled: true
          }],
          valueTo: [{
            value: '',
            disabled: true
          }],
          event: this.fb.group({
            operator: [{
              value: '',
              disabled: false
            }],
            valueFrom: [{
              value: '',
              disabled: true
            }],
          }),
          date: this.fb.group({
            operator: [{
              value: '',
              disabled: true
            }],
            valueFrom: [{
              value: '',
              disabled: true
            }],
            valueTo: [{
              value: '',
              disabled: true
            }],
          }),
        });
      } else {
        this.filterFormGroup = this.fb.group({
          ...this.filterFormGroup.controls,
          valueFrom: this.fb.control('', [Validators.required]),
          valueTo: this.fb.control('', [this.validateBetweenValue()]),
          event: this.fb.group({
            operator: [{
              value: '',
              disabled: true
            }],
            valueFrom: [{
              value: '',
              disabled: true
            }],
          }),
          date: this.fb.group({
            operator: [{
              value: '',
              disabled: true
            }],
            valueFrom: [{
              value: '',
              disabled: true
            }],
            valueTo: [{
              value: '',
              disabled: true
            }],
          }),
        });
      }
      return;
    }

    switch (operator) {
      case Operator.Between: {
        this.placeholder.from = 'Value From';
        this.filterFormGroup.get('valueTo')?.addValidators(Validators.required);
        this.filterFormGroup.get('valueFrom')?.enable();
        break;
      }
      case Operator.IsEmpty:
      case Operator.IsNotEmpty: {
        this.filterFormGroup.get('valueFrom')?.reset('');
        this.filterFormGroup.get('valueFrom')?.disable();
        this.placeholder.from = 'Value';
        this.filterFormGroup
          .get('valueTo')
          ?.removeValidators(Validators.required);
        break;
      }
      default: {
        this.filterFormGroup.get('valueFrom')?.enable();
        this.placeholder.from = 'Value';
        this.filterFormGroup
          .get('valueTo')
          ?.removeValidators(Validators.required);
      }
    }
    this.filterFormGroup.get('valueTo')?.updateValueAndValidity({ emitEvent: false });
  }

  getOperatorOptions(
    filterType: PropertyItem,
    isRequired: boolean = false,
    isEncrypted: boolean = false
  ) {
    return (deepClone(OPERATOR_BY_TYPE[filterType] || []) as Operator[])
      .filter((operator) => {
        return (
          !(
            isRequired &&
            [Operator.IsEmpty, Operator.IsNotEmpty].includes(operator)
          ) &&
          !(
            isEncrypted &&
            filterType !== 'SSN' &&
            ![
              Operator.IsEmpty,
              Operator.IsNotEmpty,
              Operator.Equals,
              Operator.NotEquals,
            ].includes(operator)
          )
        );
      })
      .map((operator) => {
        let displayValue = OPERATOR_LABEL[Operator[operator]];
        if (
          ['DateTime', 'Date'].includes(filterType) &&
          ['GreaterThan', 'LessThan'].includes(Operator[operator])
        ) {
          displayValue = OPERATOR_LABEL['Date' + Operator[operator]];
        }
        return {
          value: operator,
          displayValue,
        };
      });
  }

  addFilter() {
    this.filterFormGroup.markAllAsTouched();
    if (this.filterFormGroup.invalid) {
      return;
    }
    const formValue = this.filterFormGroup.value;
    let propertyType =
      formValue.filterBy.headerType === HeaderType.Aggregation &&
      formValue.filterBy.aggregationType === 0
        ? FilterPropertyType['Whole Number']
        : formValue.filterBy.propertyType;
    const values = [formValue.valueFrom, formValue.valueTo].reduce(
      (result, currentValue) => {
        if (currentValue === '') {
          return result;
        }
        switch (propertyType) {
          case FilterPropertyType.Date: {
            if (currentValue) {
              result.push(
                (currentValue as DateTime)
                  .toUTC(0, { keepLocalTime: true })
                  .toISO()
              );
            }
            break;
          }
          case FilterPropertyType.Status: {
            if (currentValue) {
              result.push(
                this.statusOptions.find(
                  (status) => status.value === currentValue
                )?.displayValue
              );
            }
            break;
          }
          default: {
            result.push(currentValue);
          }
        }
        return result;
      },
      []
    );
    let filterValue: MemberFilter = {
      sectionKey: formValue.filterBy.sectionKey,
      propertyKey: formValue.filterBy.propertyKey,
      operator: formValue.operator,
      type: formValue.filterBy.headerType === HeaderType.Aggregation && formValue.filterBy.aggregationType === 0 ? FilterPropertyType['Whole Number'] : formValue.filterBy.propertyType,
      propertyName: this.getPropertyName(formValue),
      values,
      options: formValue.filterBy?.options?.[0]?.key,
      selectedPropertyItem: `${formValue.filterBy.propertyName}${
        formValue.filterBy.options?.length &&
        formValue.filterBy.propertyType !== FilterPropertyType.Boolean
          ? ` - ${formValue.filterBy?.options?.[0]?.value}`
          : ''
      }`,
      optionValues: this.getOptionValues(),
    };

    if (formValue.filterBy.propertyType === FilterPropertyType.Status) {
      filterValue.options = 'Name';

      const event = {
        options: 'Event',
        operator: formValue.event.operator,
        values: [
          this.eventOptions.find(
            (event) => event.value === formValue.event.valueFrom
          )?.displayValue || '',
        ],
      };
      let date;
      if (formValue.date?.operator) {
        date = {
          options: 'Date',
          operator: formValue.date.operator,
          values: [formValue.date.valueFrom],
        };

        if (formValue.date.operator === Operator.IsCurrentStatus) {
          const fromValue = (formValue.date.valueFrom as DateTime)
            .toUTC(0, { keepLocalTime: true })
            .toISO();
          date.values = [fromValue];
        }

        if (formValue.date.operator === Operator.Between) {
          const fromValue = (formValue.date.valueFrom as DateTime)
            .toUTC(0, { keepLocalTime: true })
            .toISO();
          const toValue = (formValue.date.valueTo as DateTime)
            .toUTC(0, { keepLocalTime: true })
            .toISO();
          date.values = [fromValue, toValue];
        }
      }

      filterValue = {
        ...filterValue,
        event,
        date,
      };
      if (date) {
        filterValue = {
          ...filterValue,
          date,
        };
      }
    }
    this.eventOptions = [];
    this.currentFilters = [...this.currentFilters, filterValue];
    this.filterFormGroup = this.initFilterForm();
    this.filterOperators = [];
    this.inputType = 'Text';
    this.placeholder.from = 'Value';
    this.getFilterByOptions();
  }

  private getPropertyName(formValue: any): string{
    if (formValue?.filterBy?.headerType === HeaderType.Aggregation) {
      return formValue?.filterBy?.aggregationName;
    }
    if (
      formValue?.filterBy?.headerType === HeaderType.Property
    ) {
      return formValue?.filterBy?.propertyName;
    }
    if (formValue?.filterBy?.headerType === HeaderType.Calculation) {
      return formValue?.filterBy?.calculationName;
    }
    return '';
  }

  getInputConfig(propertyItem: PropertyItem) {
    switch (propertyItem) {
      case 'Decimal':
        return {
          allowZero: true,
          isDecimal: true,
          isPositive: false,
          suffix: '',
        };
      case 'Currency':
        return {
          allowZero: true,
          isDecimal: true,
          isPositive: false,
          suffix: '$',
        };
      case 'Percentage':
        return {
          allowZero: true,
          isDecimal: true,
          isPositive: true,
          suffix: '%',
        };
      case 'Phone':
      case 'Address - Zip Code/Postal Code':
      case 'Address - Country':
        return {
          allowZero: true,
          isDecimal: false,
          isPositive: true,
          suffix: '',
        };
      case 'Whole Number':
        return {
          allowZero: true,
          isDecimal: false,
          isPositive: false,
          suffix: '',
        };
      default:
        return {
          allowZero: true,
          isDecimal: true,
          isPositive: true,
          suffix: '',
        };
    }
  }

  removeFilterCondition(removedFilter: MemberFilter | EmployerFilter) {
    const removedIndex = this.currentFilters.findIndex(
      (filter) =>
        filter.propertyKey === removedFilter.propertyKey &&
        filter.sectionKey === removedFilter.sectionKey &&
        filter.options === removedFilter.options
    );
    if (removedIndex > -1) {
      this.currentFilters.splice(removedIndex, 1);
      this.getFilterByOptions();
    }
  }

  getFilterByOptions() {
    this.filterByOptions = deepClone(this.listSectionProperty).filter(
      (item: any) => {
        return !this.currentFilters.find(
          (filter) =>
            filter.propertyKey === item.value.propertyKey &&
            filter.sectionKey === item.value.sectionKey &&
            (filter.type === FilterPropertyType.Status ||
              (filter.options &&
                filter.options === item.value.options?.[0]?.key) ||
              !filter.options)
        );
      }
    );
  }

  onCancel() {
    this.dialogRef.close();
  }

  applyFilter() {
    this.dialogRef.close(this.currentFilters);
  }

  validateBetweenValue(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        !control.parent ||
        control.parent.get('operator')?.value !== Operator.Between
      ) {
        return null;
      }
      const { value: valueFrom } = control.parent.get(
        'valueFrom'
      ) as FormControl;
      const { value: valueTo } = control.parent.get('valueTo') as FormControl;
      const isStatusDate =
        control.parent?.parent?.value?.filterBy?.propertyType ===
        FilterPropertyType.Status;
      const isDate =
        control.parent?.get('filterBy')?.value.propertyType ===
        FilterPropertyType.Date;
      const isDateTime =
        control.parent?.get('filterBy')?.value.propertyType ===
        FilterPropertyType.DateTime;
      if (valueFrom && valueTo && (isDate || isDateTime || isStatusDate)) {
        return DateTime.fromISO(valueFrom) >= DateTime.fromISO(valueTo)
          ? {
              fromToError: `The input value must be greater than ${new DatePipe(
                'en-US'
              ).transform(
                valueFrom,
                isDateTime ? 'MM/dd/yyyy hh:mm a' : 'MM/dd/yyyy'
              )}.`,
            }
          : null;
      }
      if (
        valueTo?.length &&
        valueFrom?.length &&
        (+valueFrom > +valueTo || +valueFrom === +valueTo)
      ) {
        return {
          fromToError: `The input value must be greater than ${valueFrom}.`,
        };
      }
      return null;
    };
  }

  updateValidityFormGroup() {
    merge(
      this.filterFormGroup.controls['valueFrom'].valueChanges,
      this.filterFormGroup.controls['valueTo'].valueChanges
    ).subscribe(() => {
      this.filterFormGroup
        .get('valueTo')
        ?.updateValueAndValidity({ emitEvent: false });
    });
  }

  handleFilterStatus() {
    this.filterFormGroup = this.fb.group({
      ...this.filterFormGroup.controls,
      operator: this.fb.control('', [Validators.required]),
      valueFrom: [{
        value: '',
        disabled: true
      }],
      valueTo: [{
        value: '',
        disabled: true
      }],
      event: this.fb.group({
        operator: [{
          value: '',
          disabled: true
        }],
        valueFrom: [{
          value: '',
          disabled: true
        }],
      }),
      date: this.fb.group({
        operator:[{
          value: '',
          disabled: true
        }],
        valueFrom: [{
          value: '',
          disabled: true
        }],
        valueTo: this.fb.control('', [this.validateBetweenValue()]),
      }),
    });
    this.filterOperators = this.getOperatorOptions('StatusName');
    this.inputType = 'Boolean';
  }

  changeStatus() {
    if (
      this.filterFormGroup.value.filterBy.propertyType !==
      FilterPropertyType.Status
    ) {
      return;
    }
    const statusId = this.filterFormGroup.get('valueFrom')?.value;
    this.filterFormGroup = this.fb.group({
      ...this.filterFormGroup.controls,
      event: this.fb.group({
        operator: this.fb.control(''),
        valueFrom:  [{
          value: '',
          disabled: true
        }],
      }),
      date: this.fb.group({
        operator: [{
          value: '',
          disabled: true
        }],
        valueFrom:  [{
          value: '',
          disabled: true
        }],
        valueTo: this.fb.control('', [this.validateBetweenValue()]),
      }),
    });
    this.memberStore.dispatch(
      StatusActions.getStatusDetail({
        query: { SortNames: 'Name', SortType: 0, Id: statusId },
      })
    );
  }

  changeDateOperator() {
    const dateOperator = this.filterFormGroup.value?.date?.operator;
    switch (dateOperator) {
      case Operator.AtAnyPoint: {
        this.filterFormGroup.get('date')?.get('valueFrom')?.disable();
        this.filterFormGroup.get('date')?.get('valueTo')?.disable();
        break;
      }
      case Operator.Between: {
        if (
          this.filterFormGroup.value.date.valueFrom &&
          !DateTime.isDateTime(this.filterFormGroup.value.date.valueFrom)
        ) {
          this.filterFormGroup.get('date')?.get('valueFrom')?.setValue('');
        }
        this.filterFormGroup.get('date')?.get('valueTo')?.enable();
        this.filterFormGroup.get('date')?.get('valueFrom')?.enable();
        this.filterFormGroup
          .get('date')
          ?.get('valueTo')
          ?.addValidators(Validators.required);
        break;
      }
      case Operator.IsCurrentStatus: {
        this.filterFormGroup.get('date')?.get('valueTo')?.disable();
        this.filterFormGroup.get('date')?.get('valueFrom')?.enable();
        if (
          this.filterFormGroup.value.date.valueFrom &&
          !DateTime.isDateTime(this.filterFormGroup.value.date.valueFrom)
        ) {
          this.filterFormGroup.get('date')?.get('valueFrom')?.setValue('');
        }
        break;
      }
      default: {
        this.filterFormGroup.get('date')?.get('valueTo')?.disable();
        this.filterFormGroup.get('date')?.get('valueFrom')?.enable();
        this.filterFormGroup
          .get('date')
          ?.get('valueFrom')
          ?.addValidators(Validators.required);
      }
    }
    (this.filterFormGroup?.controls['date'] as FormGroup)?.controls[
      'valueFrom'
    ]?.updateValueAndValidity();
  }

  initFilterForm(formValue?: any) {
    return this.fb.group({
      filterBy: this.fb.control(formValue?.filterBy, [Validators.required]),
      operator: this.fb.control('', [Validators.required]),
      valueFrom: this.fb.control('', [Validators.required]),
      valueTo: this.fb.control('', [this.validateBetweenValue()]),
    });
  }

  onEventOperatorSelect() {
    var operatorEventDate = this.getOperatorOptions('StatusDate');
    if (this.filterFormGroup.value?.operator === Operator.IsAny && this.filterFormGroup.value?.event?.operator === Operator.IsAny) {
      this.dateOperatorOptions = operatorEventDate.filter(x => x.value !== Operator.AtAnyPoint);
    } else {
      this.dateOperatorOptions = operatorEventDate; 
    }
    if(this.filterFormGroup.value?.event?.operator === Operator.IsAny ){
      this.filterFormGroup = this.fb.group({
        ...this.filterFormGroup.controls,
        event: this.fb.group({
          operator: this.filterFormGroup.value?.event.operator,
          valueFrom: [{
            value: '',
            disabled: true
          }],
        }),
        date: this.fb.group({
          operator: [{
            value: '',
            disabled: false
          }],
          valueFrom:  [{
            value: '',
            disabled: true
          }],
          valueTo: this.fb.control('', [this.validateBetweenValue()]),
        })
      });
    } else {
      this.filterFormGroup = this.fb.group({
        ...this.filterFormGroup.controls,
        event: this.fb.group({
          operator: this.filterFormGroup.value?.event.operator,
          valueFrom: this.fb.control('',[Validators.required]),
        }),
        date: this.fb.group({
          operator: [{
            value: '',
            disabled: true
          }],
          valueFrom:  [{
            value: '',
            disabled: true
          }],
          valueTo: this.fb.control('', [this.validateBetweenValue()]),
        }),
      });
    
    }
  }

  onEventValueSelect() {
    this.filterFormGroup = this.fb.group({
      ...this.filterFormGroup.controls,
      date: this.fb.group({
        operator: [{
          value: '',
          disabled: false
        }],
        valueFrom:  [{
          value: '',
          disabled: true
        }],
        valueTo: this.fb.control('', [this.validateBetweenValue()]),
      }),
    });
  }

  changeDateValue() {
    (this.filterFormGroup.controls['date'] as FormGroup)?.controls[
      'valueTo'
    ].updateValueAndValidity();
  }

  onValueFromChange(event: any) {
    this.filterFormGroup
      .get('valueTo')
      ?.updateValueAndValidity({ emitEvent: false });
  }

  onChangeFromDate() {
    this.filterFormGroup
      .get('valueTo')
      ?.updateValueAndValidity({ emitEvent: false });
  }

  getOptionValues() {
    const formValue = this.filterFormGroup.value;
    return (
      formValue?.filterBy?.optionValues ||
      formValue?.filterBy?.options?.[0]?.optionValues
    )?.map((optionValue: OptionValue) => {
      return {
        id: optionValue.id,
        text: optionValue.text,
        type: optionValue.type,
      };
    });
  }
}
