import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { getDateFormatISO, getTimeZone } from '@ptg-shared/utils/string.util';
import { SKIP_ERRORS } from '@ptg-interceptor/httpconfig.interceptor';
import { environment } from 'src/environments/environment';

import { BoardBank } from '../../payroll/services/models';
import {
  NextPayment,
  ServiceProviderActionState,
  SetPaymentStepBody,
} from './models';

@Injectable()
export class NextPaymentService {
  memberId: string = '';
  paymentId = '';
  boardBankId: string | undefined = '';
  warrantFile: any;
  selectedPayment: any;
  msgErrorStartingCheckNumber = '';
  isArchived = false;

  constructor(private httpClient: HttpClient) {}

  getNextPayment(): Observable<NextPayment> {
    if (this.isArchived || this.paymentId) {
      let params = new HttpParams();
      params = params.append('VendorNextPaymentId', this.paymentId);
      return this.httpClient.get<NextPayment>(
        `${environment.apiUrl}/Payroll/VendorNextPayment`,
        { params }
      );
    } else {
      return this.httpClient.get<NextPayment>(
        `${environment.apiUrl}/Payroll/VendorNextPayment`
      );
    }
  }

  setPaymentStep(body: SetPaymentStepBody): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/Payroll/VendorNextPayment/SetStep`,
      body
    );
  }

  checkExistWarrantNumber = (body: any) => {
    return this.httpClient.post<void>(
      `${environment.apiUrl}/Payroll/VendorNextPayment/${this.paymentId}/WarrantNumber/Exists`,
      { warrantNumber: body.warrantNumber }
    );
  };

  validateStartingCheckNumber(body: any) {
    return this.httpClient.post<void>(
      `${environment.apiUrl}/Payroll/VendorNextPayment/${this.paymentId}/StartingCheckNumber/Validate`,
      body
    );
  }

  checkApiStartingCheckNumber(editValue?: string): AsyncValidatorFn {
    // Need obs to call api for check
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (
        editValue &&
        editValue.trim() === control.value.trim() &&
        +control.value < 1
      ) {
        return of(null);
      }

      return this.validateStartingCheckNumber({
        startingCheckNumber: control.value?.trim(),
        boardBankId: this.boardBankId,
      }).pipe(
        map((response: any) => {
          if (response && !response.isValid) {
            this.msgErrorStartingCheckNumber = response.message;
            return { inValidAsync: true };
          }
          return null;
        }),
        catchError((err) => {
          this.msgErrorStartingCheckNumber = err.error?.StartingCheckNumber
            ? err.error?.StartingCheckNumber[0]
            : '';
          return of({ inValidAsync: true });
        })
      );
    };
  }

  updateStartingCheckNumber(body: any) {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/Payroll/VendorNextPayment/${this.paymentId}/StartingCheckNumber`,
      body
    );
  }

  updateDepositDate(body: any) {
    let payload = {
      ...body,
      depositDate:
        getDateFormatISO(body.depositDate) +
        'T' +
        new Date().toLocaleTimeString([], { hour12: false }) +
        getTimeZone(),
    };
    return this.httpClient.put<void>(
      `${environment.apiUrl}/Payroll/VendorNextPayment/${this.paymentId}/DepositDate`,
      payload
    );
  }

  updateWarrantNumber(body: any) {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/Payroll/VendorNextPayment/${this.paymentId}/WarrantNumber`,
      body
    );
  }

  getNextPaymentList(query: any): Observable<any> {
    let params = new HttpParams();
    params = params.append('PageSize', query.totalPerPage);
    params = params.append('PageIndex', query.pageIndex);
    params = params.append('StatusType', query.statusType);
    if (query.sortField === 'checkNumber') {
      params = params.append('SortNames', 'CheckNumberValue');
      params = params.append('SortType', query.sortType ? query.sortType : 0);
    } else if (query.sortField) {
      params = params.append(
        'SortNames',
        query.sortField[0].toUpperCase() + query.sortField.substring(1)
      );
      params = params.append('SortType', query.sortType ? query.sortType : 0);
    }
    if (query?.filter?.changesOnly) {
      params = params.append('ChangesOnly', true);
    }
    if (query?.filter?.checksOnly) {
      params = params.append('ChecksOnly', true);
    }
    if (query?.filter?.errorsOnly) {
      params = params.append('ErrorOnly', true);
    }
    return this.httpClient.get(
      `${environment.apiUrl}/Payroll/VendorNextPayment/${this.paymentId}/VendorPayments`,
      { params }
    );
  }

  updateTransaction(body: any) {
    const data = {
      boardBankId: body.boardBankId,
      startingCheckNumber: body.startingCheckNumber,
    };
    return this.httpClient.put<void>(
      `${environment.apiUrl}/Payroll/VendorNextPayment/${this.paymentId}/UpdateTransaction`,
      data
    );
  }

  uploadWarrant() {
    return this.httpClient.post<void>(
      `${environment.apiUrl}/Payroll/VendorNextPayment/${this.paymentId}/UploadWarrant`,
      this.warrantFile
    );
  }

  checkBoardBankDisabled(boardBankId: string) {
    return this.httpClient.post<any>(
      `${environment.apiUrl}/BoardInformation/BoardBanks/${boardBankId}/IsDisabled`,
      null
    );
  }

  getSelectBoardBanks(): Observable<{ boardBanks: BoardBank[] }> {
    return this.httpClient.get<{ boardBanks: BoardBank[] }>(
      `${environment.apiUrl}/BoardInformation/BoardBanks`
    );
  }

  setNextPaymentBoardBank(boardBankId: string) {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/Payroll/VendorNextPayment/${this.paymentId}/SetBoardBank`,
      { boardBankId }
    );
  }

  achSent() {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/Payroll/VendorNextPayment/${this.paymentId}/AchSent`,
      null
    );
  }

  checksPrinted() {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/Payroll/VendorNextPayment/${this.paymentId}/ChecksPrinted`,
      null
    );
  }
  downloadACHFile() {
    return this.httpClient.get(
      `${environment.apiUrl}/Payroll/VendorNextPayment/${this.paymentId}/DownloadAchFile`,
      { responseType: 'text' }
    );
  }

  validateCheckNumber = (body: any) => {
    let dataCheck = {
      vendorNextPaymentId: this.selectedPayment?.vendorNextPaymentId,
      checkNumber: body.checkNumber,
      orderOfPayment: this.selectedPayment?.order,
      vendorId: this.selectedPayment?.vendorId,
      oldCheckNumber: this.selectedPayment?.checkNumber,
    };
    return this.httpClient.post<void>(
      `${environment.apiUrl}/Payroll/VendorNextPayment/ValidateCheckNumber`,
      dataCheck
    );
  };

  editCheckNumber(body: any) {
    let data = {
      ...body,
      vendorNextPaymentId: this.selectedPayment?.vendorNextPaymentId,
      orderOfPayment: this.selectedPayment?.order,
      vendorId: this.selectedPayment?.vendorId,
      oldCheckNumber: this.selectedPayment?.checkNumber,
    };
    return this.httpClient.put(
      `${environment.apiUrl}/Payroll/VendorNextPayment/CheckNumber`,
      data
    );
  }

  finalizePayment() {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/Payroll/VendorNextPayment/${this.paymentId}/Finalize`,
      null
    );
  }

  getMemberAddress() {
    return this.httpClient.get(
      `${environment.apiUrl}/Members/${this.memberId}/MemberAddressItems`
    );
  }

  reCalculateStartingCheckNumber(boardBankId: string) {
    return this.httpClient.get(
      `${environment.apiUrl}/Payroll/VendorNextPayment/${this.paymentId}/RecalculateStartingCheckNumber/${boardBankId}`
    );
  }

  reissuePayment(paymentId: any) {
    return this.httpClient.post(
      `${environment.apiUrl}/Payroll/VendorNextPayment/${paymentId}/ReissuePayment`,
      null
    );
  }

  generateEDISPPayment(paymentId: any, selectedBankId: any, boardBankId: any) {
    return this.httpClient.put(
      `${environment.apiUrl}/Payroll/Payment/${paymentId}/GenerateEDISPPayment`,
      { selectedBankId: selectedBankId, boardBankId: boardBankId },
      { responseType: 'text' }
    );
  }

  getServiceProviderActionState(): Observable<ServiceProviderActionState> {
    let context = new HttpContext().set(SKIP_ERRORS, '404');
    return this.httpClient.get<ServiceProviderActionState>(
      `${environment.apiUrl}/Payroll/VendorNextPayment/${this.paymentId}/ConcurrentState`,
      { context }
    );
  }
}
