import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubHeaderComponent } from './sub-header.component';
import { ControlsModule } from '@ptg-shared/controls/controls.module';
import { SharedModule } from '@ptg-shared/shared.module';

@NgModule({
  declarations: [SubHeaderComponent],
  imports: [
    CommonModule,
    ControlsModule,
    SharedModule,
  ],
  exports: [SubHeaderComponent]
})
export class SubHeaderModule { }