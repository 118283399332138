<div class="refunds-overview-container" id="refunds-overview-page">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>
  <ptg-overview-header-entity #overViewHeader></ptg-overview-header-entity>

  <div class="refunds-overview-wrapper">
    <ptg-sub-header
      [memberId]="memberId"
      [menuItemTitle]="menuItemTitle"
      [menuItemSubTitle]="menuItemSubTitle"
    ></ptg-sub-header>
    <ptg-banner *ngIf="bannerType" [(bannerType)]="bannerType" [message]="message"></ptg-banner>

    <ng-container>
      <div class="wrap-btn">
        <ptg-button
          buttonName="Initiate Refund"
          classInput="primary initiate-button"
          (clickButton)="onClickInitiateBenefit()"
          [isDisabled]="isDisabledInitiateButton"
        ></ptg-button>
      </div>
    </ng-container>

    <div class="benefit-overview-grid flex-grow overflow-hidden">
      <div class="header">
        <span class="title font-bold">Refund History</span>
      </div>
      <ptg-grid
        [data]="benefitCalculationHistories || []"
        [columns]="columnsBenefitHistory"
        [totalRecords]="totalBenefitHistories"
        [isLoading]="isHistoryLoading"
        [pageSize]="pageHistorySize"
        [pageNumber]="pageHistoryIndex"
        [notFoundMessage]="noDataHistories"
        (sortChange)="onChangeSortHistories($event)"
        (pageChange)="onChangeHistoriesPage($event)"
        (rowClick)="openBenefitCalculationDetail($event)"
      >
        <ng-template cellContent columnName="netRefund" let-row>
          <span><span>$ </span> <span>{{ row.netRefund | numberDecimal:{decimal: 2} }}</span></span>
        </ng-template>
        <ng-template cellContent columnName="action" let-row>
          <ptg-button
            stopPropagation
            buttonName="Remove"
            classInput="remove-button"
            (clickButton)="onRemoveHistory(row)"
            *ngIf="row.status === InitiatedStatus"
          >
            <mat-icon>delete_forever</mat-icon>
          </ptg-button>
        </ng-template>
      </ptg-grid>
    </div>
  </div>
</div>
