import { createAction, props } from '@ngrx/store';

export const removeOverviewDocumentAction = createAction(
  '[RemoveRemoveOverviewDocument/API] Send Request',
  props<{ id: string }>()
);

export const removeRemoveOverviewDocumentSuccessAction = createAction(
  '[RemoveRemoveOverviewDocument/API] Success'
);

export const removeRemoveOverviewDocumentFailureAction = createAction(
  '[RemoveRemoveOverviewDocument/API] Failure',
  props<{ error?: any }>()
);

export const clearRemoveOverviewDocumentStateAction = createAction(
  '[RemoveRemoveOverviewDocument] Clear'
);
