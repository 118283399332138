import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DateTime } from 'luxon';

import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import {
  iconDownload,
  iconUpload,
  iconUploadOutline,
} from '@ptg-shared/constance/listIcons.const';
import {
  ACTION,
  BatchResponseStatus,
  PaymentStep,
} from '@ptg-shared/constance/value.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { showBanner, toUtcDate } from '@ptg-shared/utils/common.util';

import { PaymentSelectBankPopupComponent } from '../../../../components/payment-select-bank-popup/payment-select-bank-popup.component';
import { ConcurrentActionType } from '../../../payroll/types/enums';
import { BoardBank } from '../../../payroll/services/models';
import { OffCyclePaymentState } from '../../store/reducers';
import { CompleteOffCyclePaymentScreenState } from '../../services/models';
import { OffCyclePaymentsService } from '../../services';
import {
  achSentAction,
  cancelOffCyclePaymentsAction,
  checksPrintedAction,
  generateEDIOffCycleAction,
  setCompletePaymentBoardBankAction,
  updateCompleteOffCycleDepositDateAction,
} from '../../store/actions';
import { updateDepositDateStateSelector } from '../../store/selectors';

@Component({
  selector: 'ptg-off-cycle-complete-header',
  templateUrl: './off-cycle-complete-header.component.html',
  styleUrls: ['./off-cycle-complete-header.component.scss'],
  providers: [DatePipe],
})
export class OffCycleCompleteHeaderComponent
  implements OnInit, OnChanges, OnDestroy
{
  readonly PaymentStepEnum = PaymentStep;
  readonly ConcurrentActionType = ConcurrentActionType;
  BatchResponseStatus = BatchResponseStatus;
  isEditDepositDate = false;
  isDisplayEditBankButton: boolean = false;
  isProcessing: boolean = false;
  depositDate = new FormControl('', Validators.required);
  checksOnly = new FormControl(false);
  errorsOnly = new FormControl(false);
  achSent = new FormControl(false);
  checksPrinted = new FormControl(false);
  minDate: Date = new Date(new Date().setDate(new Date().getDate() + 1));
  unsubscribe$ = new Subject<void>();

  @Input() offCycleData: any;
  @Input() selectedBank?: BoardBank;
  @Input() isSubmittedFinalize?: boolean;
  @Input() isShowBanner?: boolean;
  @Input() isProcessingSendToBank?: boolean;
  @Input() isProcessingUpdateTransaction?: boolean;
  @Input() isProcessingCancel?: boolean;
  @Input() isProcessingFinalize?: boolean;
  @Input() keptState: boolean = false;
  @Input() screenState?: CompleteOffCyclePaymentScreenState;
  @Output() isProcessingSendToBankChange: EventEmitter<boolean> =
    new EventEmitter();
  @Output() isProcessingUpdateTransactionChange: EventEmitter<boolean> =
    new EventEmitter();
  @Output() isProcessingCancelChange: EventEmitter<boolean> =
    new EventEmitter();
  @Output() filterEvent = new EventEmitter();
  @Output() selectedBankEvent = new EventEmitter();
  @Output() finalizeEvent = new EventEmitter();

  constructor(
    public offCyclePaymentService: OffCyclePaymentsService,
    private store: Store<OffCyclePaymentState>,
    iconRegistry: MatIconRegistry,
    private dialog: MatDialog,
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIconLiteral(
      'upload-icon-outline',
      sanitizer.bypassSecurityTrustHtml(iconUploadOutline)
    );
    iconRegistry.addSvgIconLiteral(
      'upload-icon',
      sanitizer.bypassSecurityTrustHtml(iconUpload)
    );
    iconRegistry.addSvgIconLiteral(
      'download-icon',
      sanitizer.bypassSecurityTrustHtml(iconDownload)
    );
  }

  ngOnInit(): void {
    this.checkDepositDateState();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.offCycleData || changes.screenState) {
      this.depositDate.setValue(this.offCycleData?.depositDate);
      this.achSent.setValue(this.offCycleData?.achSent);
      this.checksPrinted.setValue(this.offCycleData?.checksPrinted);
      this.checksOnly.setValue(
        this.keptState && !!this.screenState?.checksOnly
      );
      this.errorsOnly.setValue(
        this.keptState && !!this.screenState?.errorsOnly
      );
      this.isDisplayEditBankButton =
        this.offCycleData?.status !== PaymentStep.Finalized &&
        ((this.offCycleData?.boardBank?.payingAgent &&
          this.offCycleData?.batchResponseStatus !== BatchResponseStatus.Hold &&
          (!this.offCycleData?.submittedDate ||
            (this.offCycleData?.submittedDate &&
              this.offCycleData?.receiveAcknowledgementFile &&
              this.offCycleData?.batchResponseStatus ===
                BatchResponseStatus.Rejected))) ||
          (!this.offCycleData?.boardBank?.payingAgent &&
            !this.offCycleData?.achSent &&
            !this.offCycleData?.checksPrinted));
      this.checkIsProcessing();
    }
    if (
      changes.isProcessingUpdateTransaction ||
      changes.isProcessingCancel ||
      changes.isProcessingFinalize ||
      changes.isProcessingSendToBank
    ) {
      this.checkIsProcessing();
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  editDepositDate(action: string) {
    this.depositDate.markAsTouched();
    if (action === 'close') {
      this.depositDate.setValue(this.offCycleData?.depositDate);
      this.isEditDepositDate = false;
      return;
    }
    if (!this.depositDate.invalid) {
      this.isEditDepositDate = false;
      const depositDate = toUtcDate(
        DateTime.fromISO(this.depositDate.value)
      ).toString();
      const offCycleId = this.offCycleData.id;
      this.store.dispatch(
        updateCompleteOffCycleDepositDateAction({
          depositDate,
          offCycleId,
        })
      );
    }
  }

  downloadACHFile() {}

  filterData() {
    this.filterEvent.emit({
      checksOnly: this.checksOnly.value,
      errorsOnly: this.errorsOnly.value,
    });
  }

  onAchSent(event: boolean) {
    if (!event) {
      return;
    }
    if (this.offCycleData?.checksPrinted) {
      this.doAchSent();
      return;
    }
    this.validatePaymentInformation();
  }

  validatePaymentInformation(isChecksPrinted: boolean = false) {
    this.offCyclePaymentService
      .validatePaymentInformation(this.offCycleData?.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          if (response.isMissingPaymentInformation) {
            this.handleWarningError(isChecksPrinted, false);
            return;
          }
          this.checkBoardBankDisabled(isChecksPrinted);
        },
        () => {
          this.resetCheckboxValue(isChecksPrinted);
        }
      );
  }

  checkBoardBankDisabled(isChecksPrinted: boolean = false) {
    this.offCyclePaymentService
      .checkBoardBankDisabled(this.offCycleData?.boardBank?.id as string)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          if (res.isDisabled) {
            this.handleWarningError(isChecksPrinted);
            return;
          }
          if (isChecksPrinted) {
            this.doChecksPrinted();
          } else {
            this.doAchSent();
          }
        },
        () => {
          this.resetCheckboxValue(isChecksPrinted);
        }
      );
  }

  handleWarningError(
    isChecksPrinted: boolean = false,
    isBankDisabled: boolean = true
  ) {
    this.showWarningPopup(isBankDisabled);
    this.resetCheckboxValue(isChecksPrinted);
  }

  resetCheckboxValue(isChecksPrinted: boolean = false) {
    if (isChecksPrinted) {
      this.checksPrinted.setValue(false);
    } else {
      this.achSent.setValue(false);
    }
  }

  doAchSent() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        title: 'Confirmation',
        text: 'Are you sure you want to confirm ACH file has been sent? This action cannot be undone.',
        type: ConfirmType.CancelPopup,
        cancelButtonTitle: 'No',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(achSentAction({ body: { achSent: true } }));
      } else {
        this.achSent.setValue(false);
      }
    });
  }

  showWarningPopup(isBankDisabled: boolean = true) {
    const message = isBankDisabled
      ? 'Selected Bank is disabled. Please select another Bank, or modify Bank Information in Fund Management module.'
      : 'Required Payment information of some Payees is missing. Please fill in missing information for these Payees.';
    this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        title: 'Attention',
        text: message,
        type: ConfirmType.Warning,
        cancelButtonTitle: 'Close',
        hideConfirmButton: true,
      },
    });
  }

  onChecksPrinted(event: any) {
    if (!event) {
      return;
    }
    if (this.offCycleData?.achSent) {
      this.doChecksPrinted();
      return;
    }
    this.validatePaymentInformation(true);
  }

  doChecksPrinted() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        title: 'Confirmation',
        text: 'Are you sure you want to confirm checks file has been printed? This action cannot be undone.',
        type: ConfirmType.CancelPopup,
        cancelButtonTitle: 'No',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(
          checksPrintedAction({
            body: { checksPrinted: true },
          })
        );
      } else {
        this.checksPrinted.setValue(false);
      }
    });
  }

  filterErrorsOnly() {
    this.filterEvent.emit({
      errorsOnly: this.errorsOnly.value,
      checksOnly: this.checksOnly.value,
    });
  }

  onOpenSelectBankModal() {
    const dialogRef = this.dialog.open(PaymentSelectBankPopupComponent, {
      panelClass: 'list-table-popup',
      autoFocus: false,
      data: {
        boardBankId: this.offCycleData?.boardBank?.id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.isProcessingUpdateTransaction = true;
        this.isProcessingUpdateTransactionChange.emit(true);
        this.offCycleData = {
          ...this.offCycleData,
          boardBank: result.boardBank,
        };
        this.store.dispatch(
          setCompletePaymentBoardBankAction({
            offCycleId: this.offCycleData.id,
            boardBankId: this.offCycleData.boardBank.id,
          })
        );
      }
    });
  }

  onFinalizePayment() {
    this.finalizeEvent.emit();
  }

  sendToBank() {
    this.isProcessingSendToBankChange.emit(true);
    this.store.dispatch(
      generateEDIOffCycleAction({
        offCycleId: this.offCycleData.id,
        selectedBankId: this.offCycleData.boardBank.bankId,
        boardBankId: this.offCycleData.boardBank.id,
      })
    );
  }

  cancelOffCycle() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        title: 'Confirmation',
        text: 'Are you sure you want to cancel the Complete Off-cycle Payment order?',
        type: ConfirmType.Warning,
        cancelButtonTitle: 'No',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.isProcessingCancel = true;
        this.isProcessingCancelChange.emit(true);
        this.store.dispatch(cancelOffCyclePaymentsAction());
      }
    });
  }

  clickEditDepositDate() {
    this.isEditDepositDate = true;
  }

  checkDepositDateState() {
    this.store
      .pipe(select(updateDepositDateStateSelector))
      .subscribe((updateState) => {
        showBanner.call(this, updateState, 'Deposit Date', ACTION.EDIT);
      });
  }

  checkIsProcessing() {
    this.isProcessing =
      this.isProcessingUpdateTransaction ||
      this.offCycleData?.isProcessingUpdateTransaction ===
        ConcurrentActionType.Processing ||
      this.isProcessingCancel ||
      this.offCycleData?.isProcessingCancel ===
        ConcurrentActionType.Processing ||
      this.isProcessingFinalize ||
      this.offCycleData?.isProcessingFinalize ===
        ConcurrentActionType.Processing ||
      this.isProcessingSendToBank ||
      this.offCycleData?.isProcessingSendToBank ===
        ConcurrentActionType.Processing;
  }
}
