<div class="dialog-container" id="add-accident-note-dialog">
  <div class="header-title">
    Add Note
  </div>
  <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div class="group-control">
      <ptg-input-area
        [controlField]="formGroup.get('note')"
        placeholder="Note"
        errorRequire="Note is required."
        [maxLength]="250"
        [hasLabel]="true"
        errorMaxLength="Exceed the 250 character limit."
      ></ptg-input-area>
    </div>
    <div class="row-button">
      <button mat-raised-button type="submit" style="background-color: #408bf9;color: #fff; margin-right: 8px;">Save</button>
      <button mat-raised-button type="button" style="background-color: #acabab;color: #fff" (click)="onCancel()">Cancel</button>
    </div>
  </form>
</div>

