import { ColumnIndex, NonListPropertyType } from '../enums';

export interface NonListProperty {
  id: string;
  name: string;
  propertyKey: string;
  propertyName: string;
  sectionKey: string;
  sectionName: string;
  propertyType: NonListPropertyType;
}

export interface GetNonListPropertiesResponse {
  nonListProperties: NonListProperty[];
}

export interface GetNonListItemConfigsResponse {
  nonListItemConfigs: NonListItemConfig[];
}

export interface NonListItemConfig {
  id?: string;
  itemKey?: string;
  label: string;
  description?: string;
  property?: string;
  propertyType: NonListPropertyType;
  column: ColumnIndex;
  order?: number;
}

export interface SaveNonListItemConfigsRequest {
  navigationItemId: string;
  nonListItemConfigs: NonListItemConfig[];
}
