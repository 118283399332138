import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ptg-group-aggregation',
  templateUrl: './group-aggregation.component.html',
  styleUrls: ['./group-aggregation.component.scss']
})
export class GroupAggregationComponent implements OnInit {
  @Input() aggregationDetails: any = [];
  constructor() { }

  ngOnInit(): void {
  }

}
