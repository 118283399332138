import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const SetError = '[Get/API] Set error';
export const SetShowErrorDialogCondition = '[Get/API] Set Show Error Dialog Condition';

export const setError = createAction(
  SetError,
  props<{ error: HttpErrorResponse | undefined }>()
);

export const setShowErrorDialogCondition = createAction(
  SetShowErrorDialogCondition,
  props<{ showErrorDialog: boolean }>()
);
