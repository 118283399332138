import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import { GetGetEntityComponentListDataByComponentIdsResponse } from '../../services/models';
import {
  clearGetGetEntityComponentListDataByComponentIdsStateAction,
  getGetEntityComponentListDataByComponentIdsAction,
  getGetEntityComponentListDataByComponentIdsFailureAction,
  getGetEntityComponentListDataByComponentIdsSuccessAction,
} from '../actions';

export interface GetEntityComponentListDataByComponentIdState {
  getGetEntityComponentListDataByComponentIds?: BaseActionState<GetGetEntityComponentListDataByComponentIdsResponse>;
}

const initialState: GetEntityComponentListDataByComponentIdState = {};

export const getEntityComponentListDataByComponentIdReducer = createReducer(
  initialState,
  on(getGetEntityComponentListDataByComponentIdsAction, (state) => ({
    ...state,
    getGetEntityComponentListDataByComponentIds: {
      isLoading: true,
    },
  })),
  on(getGetEntityComponentListDataByComponentIdsSuccessAction, (state, { response }) => ({
    ...state,
    getGetEntityComponentListDataByComponentIds: {
      isLoading: false,
      success: true,
      payload: {
        rows: response.rows,
        columns: response.columns
      }
    },
  })),
  on(getGetEntityComponentListDataByComponentIdsFailureAction, (state, { error }) => ({
    ...state,
    getGetEntityComponentListDataByComponentIds: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetGetEntityComponentListDataByComponentIdsStateAction, (state) => ({
    ...state,
    getGetEntityComponentListDataByComponentIds: undefined,
  }))
);