import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import {
  filter,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { DISCARD_CONFIRM_MESSAGE } from '@ptg-shared/constance/value.const';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';

import * as fromMember from '../../store/reducers';
import { ParticipantNavigationConfigurationService } from '@ptg-member/services/participant-navigation-configuration.service';
import { BaseComponent } from '@ptg-shared/components';
import { ParticipantNavigationConfigurationAction } from '../../store/actions';
import { CheckExistResponse } from '@ptg-member/types/models/participant-navigation-configuration.model';

@Component({
  selector: 'ptg-edit-new-menu',
  templateUrl: './edit-new-menu.component.html',
  styleUrls: ['./edit-new-menu.component.scss'],
})
export class EditNewMenuComponent extends BaseComponent {
  editForm!: FormGroup;
  formSubmit$ = new Subject<boolean>();

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditNewMenuComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private fb: FormBuilder,
    private memberStore: Store<fromMember.MemberState>,
    private participantNavigationConfigurationService: ParticipantNavigationConfigurationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initFormGroup(this.data.memberNavigation);
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter((status) => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  initFormGroup(formData: any) {
    this.editForm = this.fb.group({
      id: this.fb.control(formData.id),
      isSingleView: this.fb.control(formData.isSingleView),
      name: this.fb.control(formData.name, [
        Validators.required,
        Validators.maxLength(100),
      ]),
      color: this.fb.control(formData.color, [
        Validators.required,
        Validators.maxLength(7),
      ]),
    });
  }

  onSubmit() {
    const { name } = this.editForm.value;
    this.participantNavigationConfigurationService
      .checkExits({ name: name, id: this.data.memberNavigation.id })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: CheckExistResponse) => {
        if (res?.existed) {
          this.editForm.get('name')?.setErrors({ inValidAsync: true });
          return;
        }
        const body = this.editForm.value;
        this.memberStore.dispatch(
          ParticipantNavigationConfigurationAction.updateNavigationConfigurationRequest(
            { body }
          )
        );
        this.dialogRef.close();
      });
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: DISCARD_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }
}
