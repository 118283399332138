import { createReducer, on } from '@ngrx/store';

import { STATE } from '@ptg-shared/constance/value.const';

import * as AggregationConfigActions from '../actions/aggregation-config.actions';
import { AggregationList } from '../../types/models';

export const aggregationConfigFeatureKey = 'aggregationConfig';

export interface State {
  isLoading: boolean;
  aggregationList: AggregationList;
  addState?: string;
  editState?: string;
  removeState?: string;
  error?: any;
}

const initialState: State = {
  isLoading: true,
  aggregationList: {
    items: [],
  },
};

export const reducer = createReducer(
  initialState,
  on(AggregationConfigActions.getAggregationList, (state) => ({
    ...state,
    ...initialState,
    error: undefined,
  })),
  on(
    AggregationConfigActions.getAggregationListSuccess,
    (state, { aggregationList }) => ({
      ...state,
      isLoading: false,
      aggregationList,
      error: undefined,
    })
  ),
  on(
    AggregationConfigActions.getAggregationListFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      aggregationList: {
        items: [],
      },
      error: error,
    })
  ),
  on(AggregationConfigActions.addAggregationSuccess, (state) => ({
    ...state,
    addState: STATE.SUCCESS,
  })),
  on(AggregationConfigActions.addAggregationFailure, (state, { errorMsg }) => ({
    ...state,
    addState: STATE.FAIL,
  })),
  on(AggregationConfigActions.removeAggregationItemSuccess, (state) => ({
    ...state,
    removeState: 'Success',
  })),
  on(AggregationConfigActions.removeAggregationItemFailure, (state) => ({
    ...state,
    removeState: 'Fail',
  })),
  on(AggregationConfigActions.aggregationClear, (state) => ({
    ...state,
    removeState: '',
    editState: '',
    addState: '',
  })),
  on(AggregationConfigActions.editAggregationSuccess, (state) => ({
    ...state,
    editState: STATE.SUCCESS,
  })),
  on(AggregationConfigActions.editAggregationFailure, (state) => ({
    ...state,
    editState: STATE.FAIL,
  }))
);
