import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import {
  getSectionData,
  getSectionDataSuccess,
  getSectionDataFailure,
} from '@ptg-member/store/actions/member-detail.actions';
import { getMetadata, getMetadataFailure, getMetadataSuccess } from '@ptg-member/store/actions/metadata.actions';
import { MetadataSection } from '@ptg-member/types/models';

export interface BeneficiaryConfirmationState {
  metaData?: BaseActionState<MetadataSection[]>;
  memberData?: any;
}

const initialState: BeneficiaryConfirmationState = {};

export const beneficiaryConfirmationReducer = createReducer(
  initialState,
  on(getMetadata, (state) => ({
    ...state,
    metaData: {
      isLoading: true,
    },
  })),
  on(getMetadataSuccess, (state, { metadata }) => ({
    ...state,
    metaData: {
      ...state.metaData,
      isLoading: false,
      success: true,
      payload: metadata,
    },
  })),
  on(getMetadataFailure, (state, { error }) => ({
    ...state,
    metaData: {
      ...state.metaData,
      isLoading: false,
      success: false,
      error,
    },
  })),

  on(getSectionData, (state) => ({
    ...state,
    memberData: {
      isLoading: true,
    },
  })),
  on(getSectionDataSuccess, (state, { sectionKey, metaDataPropertyValues }) => ({
    ...state,
    memberData: {
      ...state.memberData,
      isLoading: false,
      success: true,
      [sectionKey]: metaDataPropertyValues,
    },
  })),
  on(getSectionDataFailure, (state, { error, sectionKey }) => ({
    ...state,
    memberData: {
      ...state.memberData,
      isLoading: false,
      success: false,
      [sectionKey]: undefined,
      error,
    },
  }))
);
