import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

// import { InitiateBenefitValidation } from '../../services/models';
import {
  clearGetInitiateBenefitValidationsStateAction,
  getInitiateBenefitValidationsAction,
  getInitiateBenefitValidationsFailureAction,
  getInitiateBenefitValidationsSuccessAction,
  clearCreateInitiateRefundCalculationInformationStateAction,
  createInitiateRefundCalculationInformationAction,
  createInitiateRefundCalculationInformationFailureAction,
  createInitiateRefundCalculationInformationSuccessAction,
  clearGetRefundCalculationRecordsStateAction,
  getRefundCalculationRecordsAction,
  getRefundCalculationRecordsFailureAction,
  getRefundCalculationRecordsSuccessAction,
  clearGetRefundCalculationRecordByIdsStateAction,
  getRefundCalculationRecordByIdsAction,
  getRefundCalculationRecordByIdsFailureAction,
  getRefundCalculationRecordByIdsSuccessAction,
  clearGetCalculationRefundYearsStateAction,
  getCalculationRefundYearsAction,
  getCalculationRefundYearsFailureAction,
  getCalculationRefundYearsSuccessAction,
  clearCreateReSelectRefundYearsStateAction,
  createReSelectRefundYearsAction,
  createReSelectRefundYearsFailureAction,
  createReSelectRefundYearsSuccessAction,
  clearGetValidateBeforeRemoveCalculationBenefitStateAction,
  getValidateBeforeRemoveCalculationBenefitAction,
  getValidateBeforeRemoveCalculationBenefitFailureAction,
  getValidateBeforeRemoveCalculationBenefitSuccessAction,
  getPayeeListAction,
  getPayeeListSuccessAction,
  getPayeeListFailureAction,
  clearGetPayeeListStateAction,
  updatePayeeInformationAction,
  updatePayeeInformationSuccessAction,
  updatePayeeInformationFailureAction,
  clearUpdatePayeeInformationStateAction,
  getRefundPayeeAction,
  getRefundPayeeSuccessAction,
  getRefundPayeeFailureAction,
  clearGetRefundPayeeStateAction,

} from '../actions';
import {
  CalculationRefundRecord,
  CalculationRefundRecordResponse,
  CalculationRefundYear,
  CreateInitiateRefundCalculationInformationResponse,
  GetInitiateBenefitValidations,
  GetRefundCalculationRecords,
  GetRefundPayeeResponse,
  PayeeListResponse,
  UpdatePayeeInfoResponse,
  ValidateBeforeRemoveCalculationBenefit,
} from '../../services/models';

export interface BenefitRefundState {
  getInitiateBenefitValidations?: BaseActionState<GetInitiateBenefitValidations>;
  createInitiateRefundCalculationInformation?: BaseActionState<CreateInitiateRefundCalculationInformationResponse>;
  getRefundCalculationRecords?: BaseActionState<GetRefundCalculationRecords[]>;
  getRefundCalculationRecordByIds?: BaseActionState<CalculationRefundRecordResponse>;
  getCalculationRefundYears?: BaseActionState<CalculationRefundYear[]>;
  updatePayeeInfo?: BaseActionState<UpdatePayeeInfoResponse>;
  getPayeeList?: BaseActionState<PayeeListResponse>;
  getRefundPayee?: BaseActionState<GetRefundPayeeResponse>;
  createReSelectRefundYears?: BaseActionState<boolean>;
  getValidateBeforeRemoveCalculationBenefit?: BaseActionState<ValidateBeforeRemoveCalculationBenefit>;

}

const initialState: BenefitRefundState = {};

export const benefitRefundReducer = createReducer(
  initialState,
  on(getInitiateBenefitValidationsAction, (state) => ({
    ...state,
    getInitiateBenefitValidations: {
      isLoading: true,
    },
  })),
  on(getInitiateBenefitValidationsSuccessAction, (state, { response }) => ({
    ...state,
    getInitiateBenefitValidations: {
      isLoading: false,
      success: true,
      payload: {
        isValidCalculationInitiation: response?.isValidCalculationInitiation,
        isValidMemberPaidPension: response?.isValidMemberPaidPension,
      },
    },
  })),
  on(getInitiateBenefitValidationsFailureAction, (state, { error }) => ({
    ...state,
    getInitiateBenefitValidations: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetInitiateBenefitValidationsStateAction, (state) => ({
    ...state,
    getInitiateBenefitValidations: undefined,
  })),
  // InitiateRefundCalculationInformation
  on(createInitiateRefundCalculationInformationAction, (state) => ({
    ...state,
    createInitiateRefundCalculationInformation: {
      isLoading: true,
    },
  })),
  on(createInitiateRefundCalculationInformationSuccessAction, (state, { response }) => ({
    ...state,
    createInitiateRefundCalculationInformation: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(createInitiateRefundCalculationInformationFailureAction, (state, { error }) => ({
    ...state,
    createInitiateRefundCalculationInformation: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearCreateInitiateRefundCalculationInformationStateAction, (state) => ({
    ...state,
    createInitiateRefundCalculationInformation: undefined,
  })),

  // GetRefundCalculationRecordsAction
  on(getRefundCalculationRecordsAction, (state) => ({
    ...state,
    getRefundCalculationRecords: {
      isLoading: true,
    },
  })),
  on(getRefundCalculationRecordsSuccessAction, (state, { response }) => ({
    ...state,
    getRefundCalculationRecords: {
      isLoading: false,
      success: true,
      payload: response.records,
    },
  })),
  on(getRefundCalculationRecordsFailureAction, (state, { error }) => ({
    ...state,
    getRefundCalculationRecords: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetRefundCalculationRecordsStateAction, (state) => ({
    ...state,
    getRefundCalculationRecords: undefined,
  })),

  // GetRefundCalculationRecordByIdsAction
  on(getRefundCalculationRecordByIdsAction, (state) => ({
    ...state,
    getRefundCalculationRecordByIds: {
      isLoading: true,
    },
  })),
  on(getRefundCalculationRecordByIdsSuccessAction, (state, { response }) => ({
    ...state,
    getRefundCalculationRecordByIds: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getRefundCalculationRecordByIdsFailureAction, (state, { error }) => ({
    ...state,
    getRefundCalculationRecordByIds: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetRefundCalculationRecordByIdsStateAction, (state) => ({
    ...state,
    getRefundCalculationRecordByIds: undefined,
  })),

  // GetCalculationRefundYears
  on(getCalculationRefundYearsAction, (state) => ({
    ...state,
    getCalculationRefundYears: {
      isLoading: true,
    },
  })),
  on(getCalculationRefundYearsSuccessAction, (state, { response }) => ({
    ...state,
    getCalculationRefundYears: {
      isLoading: false,
      success: true,
      payload: response.refundYears,
      total: response.total,
    },
  })),
  on(getCalculationRefundYearsFailureAction, (state, { error }) => ({
    ...state,
    getCalculationRefundYears: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetCalculationRefundYearsStateAction, (state) => ({
    ...state,
    getCalculationRefundYears: undefined,
  })),

  // Update PayeeInformation
  on(updatePayeeInformationAction, (state) => ({
    ...state,
    updatePayeeInfo: {
      isLoading: true,
    },
  })),
  on(updatePayeeInformationSuccessAction, (state, { response }) => ({
    ...state,
    updatePayeeInfo: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(updatePayeeInformationFailureAction, (state, { error }) => ({
    ...state,
    updatePayeeInfo: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearUpdatePayeeInformationStateAction, (state) => ({
    ...state,
    updatePayeeInfo: undefined,
  })),

  // GetPayeeList
  on(getPayeeListAction, (state) => ({
    ...state,
    getPayeeList: {
      isLoading: true,
    },
  })),
  on(getPayeeListSuccessAction, (state, { response }) => ({
    ...state,
    getPayeeList: {
      isLoading: false,
      success: true,
      payload: response,
      total: response.total,
    },
  })),
  on(getPayeeListFailureAction, (state, { error }) => ({
    ...state,
    getPayeeList: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetPayeeListStateAction, (state) => ({
    ...state,
    getPayeeList: undefined,
  })),

  on(getRefundPayeeAction, (state) => ({
    ...state,
    getRefundPayee: {
      isLoading: true,
    },
  })),
  on(getRefundPayeeSuccessAction, (state, { response }) => ({
    ...state,
    getRefundPayee: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getRefundPayeeFailureAction, (state, { error }) => ({
    ...state,
    getRefundPayee: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetRefundPayeeStateAction, (state) => ({
    ...state,
    getRefundPayee: undefined,
  })),

  // ReSelectRefundYears
  on(createReSelectRefundYearsAction, (state) => ({
    ...state,
    createReSelectRefundYears: {
      isLoading: true,
    },
  })),
  on(createReSelectRefundYearsSuccessAction, (state, { response }) => ({
    ...state,
    createReSelectRefundYears: {
      isLoading: false,
      success: true,
      payload: response
    },
  })),
  on(createReSelectRefundYearsFailureAction, (state, { error }) => ({
    ...state,
    createReSelectRefundYears: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearCreateReSelectRefundYearsStateAction, (state) => ({
    ...state,
    createReSelectRefundYears: undefined,
  })),

  on(getValidateBeforeRemoveCalculationBenefitAction, (state) => ({
    ...state,
    getValidateBeforeRemoveCalculationBenefit: {
      isLoading: true,
    },
  })),
  on(getValidateBeforeRemoveCalculationBenefitSuccessAction, (state, { response }) => ({
    ...state,
    getValidateBeforeRemoveCalculationBenefit: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getValidateBeforeRemoveCalculationBenefitFailureAction, (state, { error }) => ({
    ...state,
    getValidateBeforeRemoveCalculationBenefit: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetValidateBeforeRemoveCalculationBenefitStateAction, (state) => ({
    ...state,
    getValidateBeforeRemoveCalculationBenefit: undefined,
  }))


);
