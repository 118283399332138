<div class="upload-popup">
  <p class="title">Upload document</p>
  <ptg-upload
    #fileDocument
    (onUploadAction)="uploadFile($event)"
    [typeFile]="typeFileDocument"
    [checkPattern]="checkPatternDocument"
    [errMsg]="'Unsupported file name or file format.'"
    [noUpload]="true"
    [accept]="acceptDocument"
  ></ptg-upload>
  <div class="wrap-btn">
    <button mat-raised-button type="button" style="background-color: #408bf9;color: #fff;" (click)="onSave()">Save</button>
    <button mat-raised-button type="button" style="background-color: #acabab;color: #fff" (click)="onCancel()">Cancel</button>
  </div>
</div>
