import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import * as fromMember from '../../store/reducers';
import {
  debounceTime,
  filter,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { BaseComponent } from '@ptg-shared/components';
import * as CartActions from '../../store/actions/cart.actions';
import {
  EntitiesItem,
  GetEntityResponse,
  NameItem,
  ParametersQuery,
} from '@ptg-member/types/models/card.model';
import { Subject } from 'rxjs';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { CartService } from '@ptg-member/services/cart.service';
import { State } from '@ptg-member/store/reducers/cart.reducer';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';
import { GUID_EMPTY, STATE } from '@ptg-shared/constance';
@Component({
  selector: 'ptg-add-new-cart',
  templateUrl: './add-new-cart.component.html',
  styleUrls: ['./add-new-cart.component.scss'],
})
export class AddNewCartComponent extends BaseComponent {
  listName: any[] = [];
  listEntity: any[] = [];
  editForm!: FormGroup;
  isLoading: boolean = true;
  formSubmit$ = new Subject<boolean>();
  isContinue: boolean = false;
  constructor(
    public dialog: MatDialog,
    public dialogRefAddNew: MatDialogRef<AddNewCartComponent>,
    private memberStore: Store<fromMember.MemberState>,
    private cartService: CartService,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public dataDetail: any
  ) {
    super();
  }

  ngOnInit(): void {
    this.getListEntity();
    this.createForm();
    this.submitForm();
    this.memberStore
      .pipe(select(fromMember.selectCart), takeUntil(this.unsubscribe$))
      .subscribe((state: State) => {
        if (state.createState) {
          this.isLoading = false;
          if (state.createState === STATE.SUCCESS && this.isContinue) {
            this.onCancel();
            this.router.navigateByUrl(`/member/card/${state.idCart?.id}`);
          } else {
            this.editForm?.reset();
          }
          let sortType = 0;
          let sortNames = [''];
          if (this.dataDetail?.active && this.dataDetail?.direction) {
            sortNames = [capitalizeFirstLetter(this.dataDetail.active)];
            sortType = this.dataDetail.direction === 'desc' ? 1 : 0;
          }
          const query: ParametersQuery = {
            sortNames: sortNames,
            sortType: sortType,
          };
          this.memberStore.dispatch(CartActions.getCartRequest({ query }));
          this.memberStore.dispatch(CartActions.clearCartState());
        }
      });
      
      this.editForm?.get('entityId')
      ?.valueChanges.pipe(debounceTime(500))
      .subscribe((value) => {
        if (value) {
          this.onchangeEntity();
        }
      });
  }

  getListEntity() {
    this.memberStore.dispatch(CartActions.getEntityRequest({ query: { includeVersionHistory: true } }));
    this.memberStore
      .select(fromMember.selectEntity)
      .pipe(
        filter((state: any) => state?.entities?.length),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state: GetEntityResponse) => {
        this.isLoading = false;
        if (state?.entities?.length) {
          this.listEntity = state?.entities?.map((item: EntitiesItem) => {
            return {
              ...item,
              value: item.id,
              displayValue: item.entityName,
              isEnableVersioning: item.isEnableVersioning,
            };
          });
        }
      });
  }

  submitForm() {
    this.formSubmit$
      .pipe(
        tap((isContinue) => {
          this.isContinue = isContinue;
          this.editForm.markAllAsTouched();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter((status) => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.onCreate();
      });
  }

  createForm(): void {
    this.editForm = new FormGroup({
      cardName: new FormControl('', {
        validators: [Validators.required],
        asyncValidators: checkApiValidator(
          this.cartService.checkExitsCardName,
          'cardName'
        ),
      }),
      entityId: new FormControl('', {
        validators: [Validators.required],
      }),
      entityComponentId: new FormControl(''),
    });
  }

  onCreate() {
    this.editForm?.markAllAsTouched();
    if (this.editForm.invalid) return;
    const body = {...this.editForm.value};
    this.isLoading = true;
    if (body.entityComponentId === GUID_EMPTY) {
      body.isVersionHistory = true;
      delete body.entityComponentId;
    }
    
    this.memberStore.dispatch(CartActions.setCartRequest({ body }));
  }

  onchangeEntity() {
    const { entityId } = this.editForm.value;
    const checkEntity = this.listEntity.find((item) => item.value === entityId);
    if (checkEntity) {
      this.listName = checkEntity.listNames.map((item: NameItem) => {
        return {
          ...item,
          value: item.id,
          displayValue: item.listName,
          isVersionHistory: item.isVersionHistory,
        };
      });
    }
    this.editForm.patchValue({
      entityComponentId: ''
    })
  }

  onCancel() {
    this.dialogRefAddNew.close();
  }
}
