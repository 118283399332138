import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, startWith, switchMap, take, tap } from 'rxjs/operators';

import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';

import * as StatusActions from '../../store/actions/status.actions';
import {
  MemberEvent,
  MemberStatusDetail,
} from '../../types/models/status.model';
import * as fromMember from '../../store/reducers';
import { StatusService } from '../../services/status.service';

@Component({
  selector: 'ptg-edit-event',
  templateUrl: './edit-event.component.html',
  styleUrls: ['./edit-event.component.scss'],
})
export class EditEventComponent implements OnInit {
  editForm!: FormGroup;
  formSubmit$ = new Subject<boolean>();
  unsubscribe$ = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditEventComponent>,
    @Inject(MAT_DIALOG_DATA) public memberEvent: MemberEvent,
    private fb: FormBuilder,
    private memberStore: Store<fromMember.MemberState>,
    public statusService: StatusService,
    public switchConfirmPopupService: SwitchConfirmPopupService
  ) {}

  ngOnInit(): void {
    this.initFormGroup(this.memberEvent || ({} as MemberEvent));
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter((status) => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  initFormGroup(formData: MemberEvent) {
    this.editForm = this.fb.group({
      id: this.fb.control(formData.id),
      memberStatusId: this.fb.control(formData.memberStatusId),
      name: this.fb.control(
        formData.name,
        [Validators.required, Validators.maxLength(150)],
        checkApiValidator(
          this.statusService.checkExistEvent,
          'name',
          this.memberEvent.name,
          {
            params: {
              id: this.memberEvent.id,
              memberStatusId: this.memberEvent.memberStatusId,
            },
          }
        )
      ),
      disabled: this.fb.control(formData.disabled),
    });
  }

  onSubmit() {
    const eventDetail = JSON.parse(
      JSON.stringify(this.editForm.value as MemberStatusDetail)
    );
    eventDetail.active = !eventDetail.disabled;
    eventDetail.id = eventDetail.id || undefined;
    delete eventDetail.disabled;
    if (eventDetail.id) {
      this.memberStore.dispatch(StatusActions.editEvent({ eventDetail }));
    } else {
      this.memberStore.dispatch(StatusActions.createEvent({ eventDetail }));
    }
    this.dialogRef.close();
  }

  onCancel() {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }
}
