import * as fromMember from '../../store/reducers';
import { Store, select } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { startWith, switchMap, take, takeUntil, tap, filter } from 'rxjs/operators';
import * as fromReducer from '@ptg-reducers';
import { ACTION, SORT_TYPE } from '@ptg-shared/constance';
import { BaseComponent } from '@ptg-shared/components';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { showBanner } from '@ptg-shared/utils/common.util';
import { Option } from '@ptg-shared/controls/select/select.component';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { ACTION_COLUMN, Align, Column, GridComponent, Row, getValidatorsFromColumns } from '@ptg-shared/controls/grid';
import { SetViewDetailwRequest, View, ViewCard, ViewDetail, entityViewItem } from '@ptg-member/types/models';
import { clearViewState, getCardList, getViewDetail, updateView } from '@ptg-member/store/actions/view.actions';
import { ENTITY_TYPE } from '@ptg-member/constants';
import { getListCardSelector, getListSelector, getViewDetailSelector, updateViewSelector } from '@ptg-member/store/selectors';

@Component({
  selector: 'ptg-view-detail',
  templateUrl: './view-detail.component.html',
  styleUrls: ['./view-detail.component.scss'],
})
export class EditViewComponent extends BaseComponent {
  readonly ACTION_COLUMN = ACTION_COLUMN;

  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Participant List',
      url: '/member',
    },
    {
      name: 'View List',
      url: '/member/view',
    },
    {
      name: 'Current View Name',
    },
  ];

  get EntityTypeEnum() {
    return ENTITY_TYPE; 
  }
  
  message: string = '';
  cardLabelExistsMes = "Card Label already exists.";
  
  viewDetailData!: ViewDetail;
  currentViewType!: ENTITY_TYPE;
  
  editForm?: FormGroup;
  formSubmit$ = new Subject<boolean>();
  isChanged: boolean = false;
  isLoadingViewCard: boolean = true;
  bannerType: BannerType = BannerType.Hidden;

  viewId!: string;
  cardLabelMaxLength: number = 100;

  availableCardConfigs!: Option[] | ViewCard[];
  cardConfigs: ViewCard[] = [];

  listView: View[] = [];
  leftCard: (ViewCard & Row)[] = [];
  rightCard: (ViewCard & Row)[] = [];

  addCardForm: FormGroup = this.fb.group({
    cardLabel: this.fb.control('', [Validators.required, Validators.maxLength(this.cardLabelMaxLength), this.checkExits()]),
    key: this.fb.control('', [Validators.required])
  });

  configuredCardColumns: Column[] = [
    {
      name: 'name',
      controlArgs: {
        placeholder: 'Card Label'
      },
      truncate: true,
      editable: true,
      validators: {
        required: {
          type: (obj: any) => Validators.required,
          message: (error: any) =>
            `Card Label is required.`,
        },
        maxlength: {
          type: (obj: any) => Validators.maxLength(100),
          message: (error: any, fieldName: string) =>
            `Exceed the ${error.requiredLength} character limit.`,
        },
        existed: {
          type: (obj: any) => this.checkExits(obj),
          message: (error: any) =>
            `Card Label already exists.`,
        },
      },
    },
    {
      name: ACTION_COLUMN,
      align: Align.Left,
      width: '50px',
    },
  ];

  // Form controls
  get nameCtrl(): FormControl {
    return this.editForm?.get('name') as FormControl;
  }

  @ViewChild('gridLeftCard')
  gridLeftCard?: GridComponent<ViewCard>;
  @ViewChild('gridRightCard')
  gridRightCard?: GridComponent<ViewCard>;

  constructor(
    private store: Store<fromReducer.State>,
    private dialog: MatDialog,
    private memberStore: Store<fromMember.MemberState>,
    public route: ActivatedRoute,
    private fb: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch(LayoutActions.hiddenSideMenu());

    const { prevUrl, prevName } = this.route.snapshot.queryParams;
    if (prevUrl) {
      this.listBreadcrumbs = [
        {
          name: prevName,
          url: prevUrl,
        },
        {
          name: 'Current View Name',
          url: ' ',
        },
      ];
    }

    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.viewId = params.id;
    });

    // Init form
    this.initFormGroup();

    // Get list card
    this.memberStore.pipe(select(getListCardSelector)).subscribe((data) => {
      if (data) {
        this.cardConfigs = data.payload?.cards ?? [];
      }
      this.getAvailableCardConfigs();
    })

    // get list view
    this.memberStore.pipe(select(getListSelector)).subscribe((data) => {
      this.listView = data?.payload ?? [];
    });

    // update view status
    this.memberStore.pipe(select(updateViewSelector)).subscribe((data) => {
      if (data?.success) {
        this.loadDataViewDetail();
        showBanner.call(this, BannerType.Success, 'View', ACTION.EDIT);
      } else if (data?.error) {
        showBanner.call(this, BannerType.Fail, 'View', ACTION.EDIT);
      }
    });

    // Get view detail
    this.loadDataViewDetail();

    // Listen for submit form
    this.listSubmitForm();
    this.memberStore
      .pipe(
        select(getViewDetailSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state?.success && state?.payload) {
          const { entityViewItemList, name, entityType, cardId } = state.payload;
          const metadataViewDetail = entityViewItemList ?? [];
          const currentViewName = name ?? "";

          this.viewDetailData = state.payload;
          this.listBreadcrumbs[this.listBreadcrumbs.length - 1].name = currentViewName;
          this.editForm?.get('name')?.setValue(currentViewName);
          
          if (entityType) {
            this.currentViewType = entityType;
            if (entityType === ENTITY_TYPE.Detail) {
              if (this.editForm?.get('card')) {
                this.editForm?.patchValue({
                  card: cardId
                })
              } else {
                this.editForm?.addControl("card", new FormControl(cardId || '', [Validators.required]))
              }
            }
          }

          this.leftCard = metadataViewDetail
            .filter((config: entityViewItem) => config.isRightColumn === false)
            .sort((a: entityViewItem, b: entityViewItem) =>
              a.order > b.order ? 1 : a.order < b.order ? -1 : 0
            )
            .map(
              (config: entityViewItem) =>
                ({
                  ...config,
                  form: this.createInlineEditFormControls(config),
                } as ViewCard & Row)
            );

            this.rightCard = metadataViewDetail
            .filter((config: entityViewItem) => config.isRightColumn === true)
            .sort((a: entityViewItem, b: entityViewItem) =>
              a.order > b.order ? 1 : a.order < b.order ? -1 : 0
            )
            .map(
              (config: entityViewItem) =>
                ({
                  ...config,
                  form: this.createInlineEditFormControls(config),
                } as ViewCard & Row)
            );

          this.memberStore.dispatch(getCardList({ query: {
            sortType: SORT_TYPE.ASC,
            sortNames: ["CardName"],
            entityId: this.viewDetailData.entityId,
            ...(entityType === ENTITY_TYPE.Detail ? { includeSystemCard: false } : {})
          } }))

          this.isLoadingViewCard = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.editForm?.reset();
    this.addCardForm.reset();
    this.memberStore.dispatch(clearViewState());
  }

  private loadDataViewDetail() {
    this.memberStore.dispatch(getViewDetail({ id: this.viewId }))
    
  }

  private listSubmitForm() {
    this.formSubmit$
    .pipe(
      tap(() => {
        this.editForm?.markAllAsTouched();
      }),
      switchMap(() =>
        this.editForm!.statusChanges.pipe(
          startWith(this.editForm!.status),
          filter((status) => status !== AbstractControlStatus.PENDING),
          take(1)
        )
      ),
      filter(
        (status) => 
          status === AbstractControlStatus.VALID && ((this.currentViewType === ENTITY_TYPE.Summary &&
              this.gridLeftCard?.formStatus === AbstractControlStatus.VALID &&
              this.gridRightCard?.formStatus === AbstractControlStatus.VALID) || this.currentViewType === ENTITY_TYPE.Detail
          )
      )
    )
    .subscribe(() => {
      this.onSubmit();
    });
  }

  onSubmit(): void {
    if (this.viewDetailData.id) {
      const isDuplicateViewName = this.listView.some(viewItem => viewItem.viewName?.toLowerCase() === this.nameCtrl.value?.toLowerCase());

      if(isDuplicateViewName && this.nameCtrl.value?.toLowerCase() !== this.viewDetailData?.name?.toLowerCase()) {
        this.nameCtrl.setErrors({ inValidAsync: true });
        return;
      }
  
      const leftSections = this.getProfileOverviewConfigs(
        this.gridLeftCard!?.dataSource
      ) ?? [];
      const rightSections = this.getProfileOverviewConfigs(
        this.gridRightCard!?.dataSource
      ) ?? [];

      const metadataProfileOverview: SetViewDetailwRequest = {
        name: this.editForm?.get('name')?.value,
      };
      
      if (this.currentViewType === ENTITY_TYPE.Summary) {
        Object.assign(metadataProfileOverview, {
          entityViewItemList: [
            ...leftSections,
            ...rightSections
          ].filter(item => !(!item?.id && item.isRemoved)),
        });
      } else if (this.currentViewType === ENTITY_TYPE.Detail) {
        Object.assign(metadataProfileOverview, {
          cardId: this.editForm?.get('card')?.value
        });
      }
      
      this.isLoadingViewCard = true;
      this.memberStore.dispatch(updateView({
        id: this.viewDetailData.id,
        body: metadataProfileOverview
      }));
  
      this.addCardForm.reset();
      this.isChanged = false;
    }
  }

  private checkExits(obj?: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {    
      const allCardAdded = [
        ...(this.getProfileOverviewConfigs(this.gridLeftCard!?.dataSource) ?? []), 
        ...(this.getProfileOverviewConfigs(this.gridRightCard!?.dataSource) ?? [])
      ];
      const currentFieldValue = control?.value?.toLowerCase().trim();
      const existed = allCardAdded.find(item => {
        const id = item.id ?? item.cardId;
        return obj && id !== obj.id && item?.name?.toLowerCase().trim() === currentFieldValue;
      });
      return existed ? { existed: this.cardLabelExistsMes } : null;
    };
  }

  private getProfileOverviewConfigs(
    data: ViewCard & Row[]
  ): (entityViewItem & Row)[] {
    let order: number = 1;
    return data?.map((config: ViewCard & Row) => {
      
      // Always exists
      const newConfig: entityViewItem & Row = {
        id: config?.id,
        sectionKey: config?.sectionKey ?? config.cardId,
        sectionName: config?.sectionName ?? config.cardName,
        name: config.name,
        order: config.deleted ? -1 : order,
        isRightColumn: config.isRightColumn,
        isRemoved: config.deleted,
      };

      if (!config.deleted) order++;
      return newConfig;
    });
  }

  private createInlineEditFormControls(
    config: ViewCard & { name?: string }
  ): FormGroup {
    return this.fb.group({
      name: new FormControl(
        config.name,
        getValidatorsFromColumns('name', this.configuredCardColumns, config)
      ),
    });
  }

  onChangeData(): void {
    this.isChanged = true;
    this.touchAllFieldToCheckValidate();
  }

  onCancel(): void {
    this.editForm?.reset();
    this.addCardForm.reset();
    this.loadDataViewDetail();
    this.isChanged = false;
  }

  changeProperty() {
    const selectedConfig = this.cardConfigs.find((card) => card.id == this.addCardForm.value["key"]?.id);
    this.addCardForm.get('cardLabel')?.setValue(selectedConfig?.cardName);
  }

  addProperty() {
    this.addCardForm.markAllAsTouched();
    if (!this.addCardForm.valid) {
      return;
    }
    
    const formValue = this.addCardForm.value;
    const newViewCard: ViewCard & Row = {
      cardId: formValue.key.id,
      cardName: formValue.key.cardName,
      name: formValue.cardLabel,
      order: this.gridLeftCard!.dataSource.length,
      isRightColumn: false,
      isRemoved: false,
    }

    newViewCard.form = this.createInlineEditFormControls(newViewCard);
    
    // Insert new row into the left card table
    this.leftCard = [...this.gridLeftCard!.dataSource, newViewCard];

    // Update card list
    this.getAvailableCardConfigs();

    this.addCardForm.reset();
    this.isChanged = true;
  };

  getAvailableCardConfigs() {
    const allCardAdded = [...this.leftCard, ...this.rightCard].map(item => (item?.sectionKey ?? item?.cardId)?.toLowerCase());

    this.availableCardConfigs = this.cardConfigs.reduce((result: Option[] | any, cardConfig: ViewCard) => {
      if (allCardAdded.includes(cardConfig.id)) {
        return result;
      } else {
        result.push({
          displayValue: cardConfig?.cardName,
          value: this.currentViewType === ENTITY_TYPE.Summary ? cardConfig : cardConfig.id
        });
        return result;
      }
    }, []);
  }

  onSoftDeleteSectionConfig(row: ViewCard & Row): void {
    // Soft delete corresponding item at the dataless table
    this.isChanged = true;
  }

  onClickMove2Right(row: ViewCard & Row): void {
    row.isRightColumn = true;
    row.order = this.gridRightCard!.dataSource.length + 1;
    
    this.leftCard = this.gridLeftCard!.dataSource.filter(
      (card) => (card?.id ?? card.cardId) !== (row?.id ?? row.cardId)
    ).map((card, index) => ({ ...card, order: index + 1 }));

    this.rightCard = [...this.gridRightCard!.dataSource, row];

    this.isChanged = true;
  }

  onClickMove2Left(row: ViewCard & Row): void {
    row.isRightColumn = false;
    row.order = this.gridLeftCard!.dataSource.length + 1;
    
    this.rightCard = this.gridRightCard!.dataSource.filter(
      (card) => (card?.id ?? card.cardId) !== (row?.id ?? row.cardId)
    ).map((card, index) => ({ ...card, order: index + 1 }));

    this.leftCard = [...this.gridLeftCard!.dataSource, row];

    this.isChanged = true;
  }

  // Initial form group when edit profile overview detail
  private initFormGroup(data?: any): void {
    this.editForm = this.fb.group({ 
      name: new FormControl(data?.name || '', [
        Validators.required
      ])
    });

    this.nameCtrl.clearAsyncValidators();
    this.addCardForm.get('cardLabel')?.setErrors({ existed: this.cardLabelExistsMes });
  }

  touchAllFieldToCheckValidate() {
    const allCardAdded = [
      ...(this.gridLeftCard?.dataSource ?? []), 
      ...(this.gridRightCard?.dataSource ?? [])
    ];
    allCardAdded.forEach((el: Row) => {
      el?.form?.get('name')?.markAllAsTouched();
      el?.form?.get('name')?.updateValueAndValidity();
    });
  }
}
