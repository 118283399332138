import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { RetirementBenefitService } from '../../services/retirement-benefit-history.service';
import {
  GetRetirementBenefitDocumentsResponse,
  GetRetirementBenefitHistoriesResponse,
  GetValidateBeforeRemovingDisabilityBenefitsResponse,
} from '../../services/models/retirement-benefit-history.model';
import {
  getRetirementBenefitDocumentsAction,
  getRetirementBenefitDocumentsFailureAction,
  getRetirementBenefitDocumentsSuccessAction,
  getRetirementBenefitDownloadDocumentAction,
  getRetirementBenefitDownloadDocumentFailureAction,
  getRetirementBenefitDownloadDocumentSuccessAction,
  getRetirementBenefitHistoriesAction,
  getRetirementBenefitHistoriesFailureAction,
  getRetirementBenefitHistoriesSuccessAction,
  initiateSurvivorAction,
  initiateSurvivorFailureAction,
  initiateSurvivorSuccessAction,
  removeCalculationBenefitAction,
  removeCalculationBenefitFailureAction,
  removeCalculationBenefitSuccessAction,
  getValidateBeforeRemovingDisabilityBenefitsAction,
  getValidateBeforeRemovingDisabilityBenefitsFailureAction,
  getValidateBeforeRemovingDisabilityBenefitsSuccessAction,
  removeDisabilityBenefitHistoriesAction,
  removeDisabilityBenefitHistoriesSuccessAction,
  removeDisabilityBenefitHistoriesFailureAction,
} from '../actions/retirement-benefit-history.action';

@Injectable()
export class RetirementBenefitEffects {
  constructor(
    private actions$: Actions,
    private retirementBenefitHistory: RetirementBenefitService,
  ) {}

  getRetirementBenefitHistories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRetirementBenefitHistoriesAction),
      switchMap(({ request, memberId, calculationType }) =>
        this.retirementBenefitHistory.getRetirementBenefitHistories(request, memberId, calculationType).pipe(
          map((response: GetRetirementBenefitHistoriesResponse) =>
            getRetirementBenefitHistoriesSuccessAction({ response }),
          ),
          catchError((error) => of(getRetirementBenefitHistoriesFailureAction({ error }))),
        ),
      ),
    ),
  );

  removeCalculationBenefit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeCalculationBenefitAction),
      switchMap(({ id, memberId }) =>
        this.retirementBenefitHistory.removeCalculationBenefit(id, memberId).pipe(
          map(() => removeCalculationBenefitSuccessAction()),
          catchError((error) => of(removeCalculationBenefitFailureAction({ error }))),
        ),
      ),
    ),
  );

  getRetirementBenefitDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRetirementBenefitDocumentsAction),
      switchMap(({ request, memberId, calculationType, calculationBenefitId }) =>
        this.retirementBenefitHistory
          .getRetirementBenefitDocuments(request, memberId, calculationType, calculationBenefitId)
          .pipe(
            map((response: GetRetirementBenefitDocumentsResponse) =>
              getRetirementBenefitDocumentsSuccessAction({ response }),
            ),
            catchError((error) => of(getRetirementBenefitDocumentsFailureAction({ error }))),
          ),
      ),
    ),
  );

  getRetirementBenefitDownloadDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRetirementBenefitDownloadDocumentAction),
      switchMap(({ request }) =>
        this.retirementBenefitHistory.getRetirementBenefitDownloadDocument(request).pipe(
          map((response: Blob) => {
            return getRetirementBenefitDownloadDocumentSuccessAction({ response: [...[response]] });
          }),
          catchError((error) => of(getRetirementBenefitDownloadDocumentFailureAction({ error }))),
        ),
      ),
    ),
  );

  initiateSurvivor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initiateSurvivorAction),
      switchMap(({ body, id }) =>
        this.retirementBenefitHistory.initiateSurvivor(body, id).pipe(
          map((retirementBenefitId) =>
            initiateSurvivorSuccessAction({
              retirementBenefitId,
              calculationType: body.calculationType,
              benefitEntityId: body?.benefitEntityId,
            }),
          ),
          catchError((error) => of(initiateSurvivorFailureAction({ error }))),
        ),
      ),
    ),
  );

  getValidateBeforeRemovingDisabilityBenefits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getValidateBeforeRemovingDisabilityBenefitsAction),
      switchMap(({ request }) =>
        this.retirementBenefitHistory.getValidateBeforeRemovingDisabilityBenefits(request).pipe(
          map((response: GetValidateBeforeRemovingDisabilityBenefitsResponse) =>
            getValidateBeforeRemovingDisabilityBenefitsSuccessAction({ response }),
          ),
          catchError((error) => of(getValidateBeforeRemovingDisabilityBenefitsFailureAction({ error }))),
        ),
      ),
    ),
  );

  removeDisabilityBenefitHistories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeDisabilityBenefitHistoriesAction),
      switchMap(({ request }) =>
        this.retirementBenefitHistory.removeDisabilityBenefitHistories(request).pipe(
          map(() => removeDisabilityBenefitHistoriesSuccessAction()),
          catchError((error) => of(removeDisabilityBenefitHistoriesFailureAction({ error }))),
        ),
      ),
    ),
  );
}
