<div class="incomplete-off-cycle">
  <div class="list-header">
    <span>Incomplete Off-Cycle Payment(s)</span>
    <div class="space-line"></div>
    <ptg-button
      (clickButton)="completePayment()"
      [isDisabled]="isDisabledBtn"
      buttonName="Complete"
      class="slowmotion-btn"
    >
      <mat-icon class="material-icons-round">slow_motion_video</mat-icon>
    </ptg-button>
  </div>

  <ptg-grid
    #gridOffCycles
    [data]="offCyclesData"
    [columns]="columns"
    keyColumn="id"
    notFoundMessage="No Payment to Display"
    [errorMessage]="errorMsg"
    [isLoading]="isLoading"
    [fitToParent]="true"
    [allowSelection]="true"
    [totalRecords]="totalRecords"
    [pageNumber]="pageNumber"
    [pageSize]="pageSize"
    (sortChange)="onChangeSort($event)"
    (pageChange)="onChangePage($event)"
    (rowClick)="onClickRow($event)"
    (selectionChange)="onChangeSelection()"
    [hideScrollbar]="false"
  >
    <ng-template cellContent columnName="processDate" let-row>
      <a
        *ngIf="row.processDate; else defaultDate"
        stopPropagation
        href="javascript:void(0)"
        class="link"
        (click)="onClickProcessDate(row)"
      >
        {{ row.processDate }}
      </a>
      <ng-template #defaultDate>-</ng-template>
    </ng-template>

    <ng-template cellContent columnName="hasNote" let-row>
      <mat-icon *ngIf="row.hasNote" class="note-icon">
        insert_drive_file
      </mat-icon>
    </ng-template>

    <ng-template cellContent columnName="payStatusText" let-row>
      <div
        *ngIf="row.payStatusText"
        class="chip"
        [ngStyle]="{
          'background-color': row.payStatusText.bgColor || '',
          color: row.payStatusText.color || ''
        }"
      >
        {{ row.payStatusText.value }}
      </div>
    </ng-template>
  </ptg-grid>
</div>
