import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { CheckVersionFlagService } from '@ptg-shared/services/check-version-flag.service';

@Directive({
  selector: '[ptgCheckVersionFlag]',
})
export class CheckVersionFlagDirective implements OnInit {
  @Input() ptgCheckVersionFlag!: string | string[];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private checkVersionFlagService: CheckVersionFlagService,
  ) {}

  ngOnInit() {
    if (!this.ptgCheckVersionFlag || this.checkVersionFlagService.checkVersionFlag(this.ptgCheckVersionFlag)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
