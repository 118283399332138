export enum ValidationOperator {
  GreaterThan = 1,
  LessThan = 2,
}

export enum ValidationType {
  Year = 1,
  Property = 2,
  SpecificDate = 3,
}
