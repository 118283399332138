import { createAction, props } from '@ngrx/store';

import { GetCardsByViewResponse, GetEntityViewsResponse } from '../../services/models';

export const getEntityViewsByEntityAction = createAction(
  '[GetEntityViewsByEntity/API] Send Request',
  props<{ entityId: string }>(),
);
export const getEntityViewsByEntitySuccessAction = createAction(
  '[GetEntityViewsByEntity/API] Success',
  props<{ response: GetEntityViewsResponse }>(),
);
export const getEntityViewsByEntityFailureAction = createAction(
  '[GetEntityViewsByEntity/API] Failure',
  props<{ error?: any }>(),
);
export const clearGetEntityViewsByEntityStateAction = createAction('[GetEntityViewsByEntity] Clear');

export const getEntityViewsByCardAction = createAction(
  '[GetEntityViewsByCard/API] Send Request',
  props<{ cardId: string }>(),
);
export const getEntityViewsByCardSuccessAction = createAction(
  '[GetEntityViewsByCard/API] Success',
  props<{ response: GetEntityViewsResponse }>(),
);
export const getEntityViewsByCardFailureAction = createAction(
  '[GetEntityViewsByCard/API] Failure',
  props<{ error?: any }>(),
);
export const clearGetEntityViewsByCardStateAction = createAction('[GetEntityViewsByCard] Clear');

export const getCardsByViewAction = createAction(
  '[GetCardsByView/API] Send Request',
  props<{ key?: string; viewId: string }>(),
);
export const getCardsByViewSuccessAction = createAction(
  '[GetCardsByView/API] Success',
  props<{ key?: string; response: GetCardsByViewResponse }>(),
);
export const getCardsByViewFailureAction = createAction(
  '[GetCardsByView/API] Failure',
  props<{ key?: string; error?: any }>(),
);
export const clearGetCardsByViewStateAction = createAction('[GetCardsByView] Clear');
