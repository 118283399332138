import { Pipe, PipeTransform } from '@angular/core';
import { PaymentStatus } from '../constance/value.const';

@Pipe({
  name: 'paymentStatus'
})
export class PaymentStatusPipe implements PipeTransform {

  transform(value: string): unknown {
    if (value === null) {
      return '';
    }
    if (value === PaymentStatus.Check) {
      return 'Check';
    }
    if (value === PaymentStatus.DirectDeposit) {
      return 'Direct Deposit';
    }
    if (value === PaymentStatus.Both) {
      return 'Both';
    }
    return '';
  }

}
