import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BlobServiceClient } from '@azure/storage-blob';
import * as JSZip from 'jszip';
import { environment } from 'src/environments/environment';
import { FileSignature } from '../types/models/file-signature';
import * as fileSaver from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class AzureStorageService {
  isZipping: boolean = false;
  blobNameCurrent: string = '';
  progress = {
    count: 0,
    loadedBytes: 0,
    size: 1
  };
  private controller: any;
  constructor(private httpClient: HttpClient) { }

  async uploadBlob(file: any, blobName: string): Promise<void> {
    this.progress = {
      count: 0,
      loadedBytes: 0,
      size: 1
    };
    this.controller = new AbortController();
    this.blobNameCurrent = blobName;
    var fileSign = await this.httpClient.get<FileSignature>(`${environment.apiUrl}/Files/StorageAccess`).toPromise();
    var blobServiceClient = new BlobServiceClient(fileSign.value);
    var containerClient = blobServiceClient.getContainerClient('imports');
    var blockBlobClient = containerClient.getBlockBlobClient(blobName);
    var buff = await file.arrayBuffer();

    await blockBlobClient?.uploadData(buff, {
      onProgress: (event) => {
        this.progress.count = Math.round(100 * event.loadedBytes/ file.size);
        this.progress.loadedBytes = event.loadedBytes;
        this.progress.size = file.size;
      },
      abortSignal: this.controller.signal
    });
    await blockBlobClient?.setMetadata({
      "object_type": "Document",
      "label": file.name
    });
  }

  async uploadBlobResource(file: File, blobName: string): Promise<void> {
    this.progress = {
      count: 0,
      loadedBytes: 0,
      size: 1
    };
    this.controller = new AbortController();
    this.blobNameCurrent = blobName;
    var fileSign = await this.httpClient.get<FileSignature>(`${environment.apiUrl}/Files/StorageAccess`).toPromise();
    var blobServiceClient = new BlobServiceClient(fileSign.value);
    var containerClient = blobServiceClient.getContainerClient('resources');
    var blockBlobClient = containerClient.getBlockBlobClient(blobName);
    var buff = await file.arrayBuffer();
    await blockBlobClient?.uploadData(buff, {
      onProgress: (event) => {
        // console.log(Math.round(100 * event.loadedBytes/ file.size));
        this.progress.count = Math.round(100 * event.loadedBytes/ file.size);
        this.progress.loadedBytes = event.loadedBytes;
        this.progress.size = file.size;
      },
      abortSignal: this.controller.signal
    });
    await blockBlobClient?.setMetadata({
      "object_type": "Document",
      "label": file.name
    });
  }

  cancelUpload(){
    this.controller.abort();
  }

  async downloadFiles(listFile: any[], currentFund: any) {
    var resultList: any[] = [];
    listFile.forEach( async (file, i) => {
      if (file) {
        let blobName = `${currentFund.key}/${file.filename}`;
        var fileSign = await this.httpClient.get<FileSignature>(`${environment.apiUrl}/Files/StorageAccess`).toPromise();
        var blobServiceClient = new BlobServiceClient(fileSign.value);
        var containerClient = blobServiceClient.getContainerClient('resources');
        var blockBlobClient = containerClient.getBlockBlobClient(blobName);
        var properties = await blockBlobClient.getProperties();
        var result = await blockBlobClient.download(0, properties.contentLength, {
          onProgress: (progress) => {
            // console.log(`You have download ${progress.loadedBytes} bytes`);
          },
          maxRetryRequests: 10
        });
        // it will return  browser Blob
        var fileBlob = await result.blobBody;
        // const url = window.URL.createObjectURL(fileBlob ? fileBlob : new Blob());
        // window.open(url);
        var fileZ = new File([fileBlob ? fileBlob : new Blob()], result?.metadata?.label as string);
        resultList.push(fileZ);
        if (resultList.length === listFile.length) {
          this.zipFile(resultList, currentFund.name);
        }
      }
    });
  }

  zipFile(listFile: any[], fundName: string) {
    let fileName = `${fundName}_Files_${this.formatDate(new Date().toString())}.zip`
    var zip = new JSZip();
    listFile.forEach(item => {
      zip.file(item?.name, item);
    });
    zip.generateAsync({type:"blob"})
    .then((content) => {
      this.isZipping = false;
      fileSaver.saveAs(content, fileName);
    });
  }

  formatDate(date: string): string {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hour = '' + d.getHours(),
        min = '' + d.getMinutes(),
        sec = '' + d.getSeconds()

    return `${month.padStart(2, '0')}${day.padStart(2, '0')}${year}_${hour.padStart(2, '0')}${min.padStart(2, '0')}${sec.padStart(2, '0')}`;
  }
}
