import { createReducer, on } from "@ngrx/store";
import { LayoutActions } from "../actions";

export const AppContentFeatureKey = 'appContent';

export interface State {
  isLoading: boolean;
  items: any[];
  error?: any,
  success?: boolean
}

const initialState: State = {
  isLoading: true,
  items: []
};

export const reducer = createReducer(
  initialState,
  on(LayoutActions.getAppContentItemRequest, (state) => ({
    ...state,
    isLoading: true,
    items: []
  })),
  on(LayoutActions.getAppContentItemRequestSuccess, (state, { items }) => ({
      ...state,
      isLoading: false,
      items,
      error: undefined,
      success: true
  })),
  on(LayoutActions.getAppContentItemRequestFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    items: [],
    error: error,
    success: false
  }))
);
