<div class="edit-menu-container" id="edit-menu-dialog">
    <ptg-title-bar
        name="Edit"
        viewName="Menu"
        [onlyTitle]="true"
    ></ptg-title-bar>
  
    <form [formGroup]="editForm" (ngSubmit)="formSubmit$.next()">
        <div class="wrap-btn">
            <button mat-raised-button type="submit" class="submit-button">Save</button>
            <button mat-raised-button type="button" class="cancel-button" (click)="onCancel()">Cancel</button>
        </div>
        <div class="form-control-container">
            <ptg-textbox
                placeholder="Menu Name"
                [controlField]="editForm.get('name')"
                [hasLabel]="true"
                [maxLength]="100"
                errorAsync="Menu Name already exists."
            ></ptg-textbox>
            <div class="item-entity">
                <h6>Entity</h6>
                <h2>{{ data?.memberNavigation?.entityName }}</h2>
            </div>
            
            <div class="icon-color-container">
                <ptg-input-color
                    [controlField]="editForm.get('color')"
                    placeholder="Menu Color"
                    errorInvalid="Invalid input."
                ></ptg-input-color>
            </div>
            <ptg-toggle-button
                [controlField]="editForm.get('isSingleView')"
                label="Single View"
                [class.disabled]="data?.memberNavigation?.menuItems?.length > 1"
            ></ptg-toggle-button>
        </div>
    </form>
</div>
  
