import { createAction, props } from '@ngrx/store';

import {
  MemberNotificationOptIns,
  OptInType,
} from '../../types/models';

export const GetNotificationOptInRequest =
  '[GetNotificationOptIn/API] Send Request';
export const GetNotificationOptInSuccess = '[GetNotificationOptIn/API] Success';
export const GetNotificationOptInFailure = '[GetNotificationOptIn/API] Failure';

export const EditNotificationOptInRequest =
  '[EditNotificationOptIn/API] Send Request';
export const EditNotificationOptInSuccess =
  '[EditNotificationOptIn/API] Success';
export const EditNotificationOptInFailure =
  '[EditNotificationOptIn/API] Failure';

export const ClearNotificationOptIn = '[NotificationOptIn] Clear';

export const clearNotificationOptIn = createAction(ClearNotificationOptIn);
export const getNotificationOptIn = createAction(
  GetNotificationOptInRequest,
  props<{ memberId: string }>()
);
export const getNotificationOptInSuccess = createAction(
  GetNotificationOptInSuccess,
  props<{ memberNotificationOptIns: MemberNotificationOptIns[] }>()
);
export const getNotificationOptInFailure = createAction(
  GetNotificationOptInFailure
);

export const editNotificationOptIn = createAction(
  EditNotificationOptInRequest,
  props<{ memberId: string; body: any }>()
);
export const editNotificationOptInSuccess = createAction(
  EditNotificationOptInSuccess,
  props<{ optInType: OptInType }>()
);
export const editNotificationOptInFailure = createAction(
  EditNotificationOptInFailure,
  props<{ optInType: OptInType; errorMsg: string }>()
);
