<div class="property-config-container custom-class">
  <ng-container *ngIf="!listColumnConfig?.length">
    <div class="row-button">
      <button
        mat-raised-button
        type="button"
        class="submit-button"
        [disabled]="!canSubmit"
        (click)="onSubmit()"
      >
        Save
      </button>
      <button
        mat-raised-button
        type="button"
        (click)="onCancel()"
        class="cancel-button"
      >
        Cancel
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="listColumnConfig?.length">
    <div class="column-set-form">
      <form [formGroup]="editColumnForm">
        <ptg-textbox
          placeholder="Column Set Name"
          [errorAsync]="messageExits"
          [controlField]="editColumnForm.get('columnSetName')"
          [hasLabel]="true"
          [maxLength]="255"
        ></ptg-textbox>
        <ptg-select
          (changeOptionValue)="onChangeStartWidth()"
          [controlField]="editColumnForm.get('startWith')"
          [isMultipleLineOption]="true"
          [isOptionObj]="true"
          [listData]="listColumnConfig"
          class="full-width"
          placeholder="Start With"
          [isSetMaxWidthSelect]="true"
          >
        </ptg-select>
      </form>
    </div>
  </ng-container>

  <div class="content-container">
    <div class="box-container add-property-section">
      <div class="box">
        <div class="title">{{ addPropertySection.title }}</div>
        <div class="form-container">
          <ptg-select [isOptionObj]="true"
                      [isSetMaxWidthSelect]="true"
                      [listData]="availablePropertyConfigs"
                      [controlField]="formData.get(isSectionConfiguration ? 'sectionKey' : 'key')"
                      [placeholder]="addPropertySection?.propertyName || 'Property Name'"
                      [isMultipleLineOption]="!isSectionConfiguration"
                      panelClass="property-name-dropdown"
                      (changeOptionValue)="changeProperty()">
          </ptg-select>
          <ng-container *ngIf="!isSectionConfiguration && !canSelectMultipleOption; else sectionProperties">
            <ptg-radio-button *ngIf="propertyOptions?.length"
                              [controlField]="formData.get('options')"
                              [listOption]="$any(propertyOptions)"
                              [isObject]="true"
            ></ptg-radio-button>
          </ng-container>
          <ng-template #sectionProperties>
            <div class="section-property-select-container" [ngClass]="{'hidden': !propertyOptions?.length}">
              <ng-container *ngFor="let propertyOption of $any(propertyOptions); let i = index;">
                <div class="checkbox-container"
                     *ngIf="!propertyOption.disabled">
                  <mat-checkbox [(ngModel)]="propertyOption.checked"
                                (change)="selectSectionProperty()">{{ propertyOption.value.propertyName }}</mat-checkbox>
                </div>
              </ng-container>
            </div>
            <mat-error *ngIf="formData.get('options')?.touched && formData.get('options')?.errors">At least one Section Property is required.</mat-error>
          </ng-template>
          <ptg-textbox [controlField]="formData.get('columnName')"
                       [hasLabel]="true"
                       [maxLength]="columnNameMaxLength"
                       customError="duplicatedValue"
                       [placeholder]="addPropertySection?.columnName || 'Column Name'">
          </ptg-textbox>
          <ptg-button [buttonName]="addPropertySection.title"
                      classInput="add-button"
                      type="button"
                      (clickButton)="addProperty()">
            <mat-icon>add</mat-icon>
          </ptg-button>
        </div>
      </div>
    </div>
    <div class="box-container sort-property">
      <div class="box">
        <div class="title" [ngClass]="{'no-data': !sortPropertySectionDataTable || sortPropertySectionDataTable.length === 0}">{{ sortPropertySection.title }}</div>
        <ptg-grid
          #sortPropertyTable
          [data]="sortPropertySectionDataTable"
          [columns]="orderColumns"
          [notFoundMessage]="''"
          [isLoading]="isLoading"
          [fitToParent]="true"
          [paginable]="false"
          [allowDragDrop]="true"
          [hideHeader]="true"
          [inlineEditable]="true"
          [softDeletable]="true"
          (softDelete)="onSoftDeleteSectionConfig($event)"
          (rowDrop)="changeItem($event, true)"
          (change)="onChangeOrderColumns()"
        >
          <ng-template cellContent [columnName]="'columnName'" let-row>
            <ng-container>              
            <div class="multiple-line-text">
              <span class="caption">{{ row.columnName }}</span>
              <span class="description">{{ row.columnNameDescription }}</span>
            </div>
            </ng-container>
          </ng-template>
          <ng-template cellContent [columnName]="ACTION_COLUMN" let-row>
            <ng-container *ngIf="!row.editing">
              <ptg-button
              *ngIf="!row.deleted && !row.isUsed && row.columnName !== 'Note'"
              (clickButton)="onRowActions({row, type: ACTION.ADD_SORT_ROW})"
              classInput="edit-button"
              >
              <mat-icon>arrow_right_alt</mat-icon>
              </ptg-button>
            </ng-container>
          </ng-template>
        </ptg-grid>
      </div>
    </div>
    <div class="box-container" *ngIf="sortRowSection">
      <div class="box">
        <div class="title" [ngClass]="{'no-data': !sortRowSectionDataTable || sortRowSectionDataTable.length === 0}">{{ sortRowSection.title }}</div>
        <ptg-grid
          #sortRowTable
          [data]="sortRowSectionDataTable"
          [columns]="sortColumns"
          [notFoundMessage]="''"
          [isLoading]="isLoading"
          [fitToParent]="true"
          [paginable]="false"
          [allowDragDrop]="true"
          [hideHeader]="true"
          (rowDrop)="changeItem($event)"
        >
          <ng-template cellContent [columnName]="'columnName'" let-row>
            <ng-container>              
            <div class="multiple-line-text">
              <span class="caption">{{ row.columnName }}</span>
              <span class="description">{{ row.columnNameDescription }}</span>
            </div>
            </ng-container>
          </ng-template>
          <ng-template cellContent [columnName]="ACTION_COLUMN" let-row>            
            <ng-container>        
              <ptg-button 
                *ngIf="!row.deleted"
                (clickButton)="onRowActions({row, type: ACTION.SORT_CHANGE})"
                classInput="edit-button">
                <mat-icon>{{ row.sortType ? 'arrow_downward' : 'arrow_upward' }}</mat-icon>
              </ptg-button>
            </ng-container>
            <ng-container>
              <ptg-button
                *ngIf="!row.deleted"
                classInput="delete-button"
                (clickButton)="onRowActions({row, type: ACTION.REMOVE})"
              >
                <mat-icon>delete_forever</mat-icon>
              </ptg-button>
            </ng-container>
          </ng-template>
        </ptg-grid>
      </div>
    </div>

  </div>

  <ng-container *ngIf="listColumnConfig?.length">
    <div class="row-button btn-control-column">
      <button
        mat-raised-button
        type="button"
        class="submit-button"
        [disabled]="!canSubmit"
        (click)="onSubmitColumnSet()"
      >
        Save
      </button>
      <button
      mat-raised-button
      type="button"
      class="submit-button"
      [disabled]="!canSubmit"
      (click)="onSubmitColumnSet(true)"
      *ngIf="!dataDetail?.columSelected"
    >
      Apply
    </button>
      <button
        mat-raised-button
        type="button"
        (click)="onCancelColumnSet()"
        class="cancel-button"
      >
        Cancel
      </button>
    </div>
  </ng-container>
</div>
