export enum DeductionType {
  Tax,
  Insurance,
  Garnishment,
  Others,
}

export enum TaxSettingType {
  PostTax,
  PreTax,
}

export enum InsuranceType {
  Medical,
  Dental,
  Life,
}

export enum TaxType {
  FederalTax,
  StateTax,
}
