import { createSelector } from '@ngrx/store';

import { selectCalculationModuleState } from './selector';

export const inputOutputSelector = createSelector(selectCalculationModuleState, (state) => state?.inputOutput);

export const selectRetirementBenefitInputOutputListState = createSelector(
  inputOutputSelector,
  (state) => state.listParameter,
);

export const selectLinkedPropertiesState = createSelector(inputOutputSelector, (state) => state.linkedProperties);

export const selectMasterInputOutputActionState = createSelector(inputOutputSelector, (state) => state.actionState);

export const checkMasterParameterUsingSelector = createSelector(
  inputOutputSelector,
  (state) => state?.checkMasterParameterUsingState,
);

export const selectPropertiesByLinkedIdState = createSelector(inputOutputSelector, (state) => state.propertiesList);

export const selectEntityReferenceByLinkedIdState = createSelector(inputOutputSelector, (state) => state.referenceList);
