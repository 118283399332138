import { createAction, props } from '@ngrx/store';
import {
  CreateMemberStatusRequest,
  EditMemberStatusRequest,
  GetAuditTrailsRequest,
  GetAuditTrailsResponse,
  GetListStatusHistoryQuery,
  MemberStatus,
  StatusHistoryList,
} from '../../types/models';

export const StatusHistoryListRequest = '[StatusHistoryList/API] Send Request';
export const StatusHistoryListSuccess = '[StatusHistoryList/API] Success';
export const StatusHistoryListFailure = '[StatusHistoryList/API] Failure';
export const GetMemberStatusListRequest =
  '[GetMemberStatusList/API] Send Request';
export const GetMemberStatusListSuccess = '[GetMemberStatusList/API] Success';
export const GetMemberStatusListFailure = '[GetMemberStatusList/API] Failure';
export const CreateMemberStatusHistoryRequest =
  '[CreateMemberStatusHistory/API] Send Request';
export const CreateMemberStatusHistorySuccess =
  '[CreateMemberStatusHistory/API] Success';
export const CreateMemberStatusHistoryFailure =
  '[CreateMemberStatusHistory/API] Failure';
export const EditMemberStatusHistoryRequest =
  '[EditMemberStatusHistory/API] Send Request';
export const EditMemberStatusHistorySuccess =
  '[EditMemberStatusHistory/API] Success';
export const EditMemberStatusHistoryFailure =
  '[EditMemberStatusHistory/API] Failure';
export const ClearStatusHistoryState = '[ClearStatusHistoryState]';
export const GetAuditTrailsRequestAction = '[GetAuditTrails/API] Send Request';
export const GetAuditTrailsSuccessAction = '[GetAuditTrails/API] Success';
export const GetAuditTrailsFailureAction = '[GetAuditTrails/API] Failure';

export const getStatusHistoryList = createAction(
  StatusHistoryListRequest,
  props<{ query: GetListStatusHistoryQuery }>()
);
export const statusHistoryListSuccess = createAction(
  StatusHistoryListSuccess,
  props<{ statusHistoryList: StatusHistoryList }>()
);
export const statusHistoryListFailure = createAction(StatusHistoryListFailure);
export const getMemberStatusList = createAction(GetMemberStatusListRequest);
export const getMemberStatusListSuccess = createAction(
  GetMemberStatusListSuccess,
  props<{ listMemberStatus: MemberStatus[] }>()
);
export const getMemberStatusListFailure = createAction(
  GetMemberStatusListFailure
);
export const createMemberStatusHistoryRequest = createAction(
  CreateMemberStatusHistoryRequest,
  props<{ body: CreateMemberStatusRequest }>()
);
export const createMemberStatusHistorySuccess = createAction(
  CreateMemberStatusHistorySuccess
);
export const createMemberStatusHistoryFailure = createAction(
  CreateMemberStatusHistoryFailure
);
export const editMemberStatusHistoryRequest = createAction(
  EditMemberStatusHistoryRequest,
  props<{ body: EditMemberStatusRequest }>()
);
export const editMemberStatusHistorySuccess = createAction(
  EditMemberStatusHistorySuccess
);
export const editMemberStatusHistoryFailure = createAction(
  EditMemberStatusHistoryFailure
);
export const clearStatusHistoryState = createAction(ClearStatusHistoryState);
export const getAuditTrails = createAction(
  GetAuditTrailsRequestAction,
  props<{ request: GetAuditTrailsRequest }>()
);
export const getAuditTrailsSuccess = createAction(
  GetAuditTrailsSuccessAction,
  props<{ response: GetAuditTrailsResponse }>()
);
export const getAuditTrailsFailure = createAction(
  GetAuditTrailsFailureAction,
  props<{ error?: any }>()
);
