import { createSelector } from '@ngrx/store';

import { selectCalculationModuleState } from './selector';

export const checkRetirementDocumentCanRemoveSelector = createSelector(
  selectCalculationModuleState,
  (state) => state?.checkRetirementDocumentCanRemove
);

export const checkExistCheckRetirementDocumentCanRemoveSelector = createSelector(
  checkRetirementDocumentCanRemoveSelector,
  (state) => state?.checkExistCheckRetirementDocumentCanRemove
);
