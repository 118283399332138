import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { getProfileConfigList, getEntitiesProfileAction, getProfileDetailAction, getProfileConfigListSuccess, getProfileConfigListFailure, getEntitiesProfileSuccessAction, getEntitiesProfileFailureAction, getProfileDetailSuccessAction, getProfileDetailFailureAction, getProfileViewAction, getProfileViewSuccessAction, getProfileViewFailureAction, getProfileHeaderAction, getProfileHeaderSuccessAction, getProfileHeaderFailureAction, getProfileExistAction, getProfileExistSuccessAction, getProfileExistFailureAction, createProfileAction, createProfileSuccessAction, createProfileFailureAction, removeProfileAction, removeProfileSuccessAction, removeProfileFailureAction, getProfileStatusEventAction, getProfileStatusEventSuccessAction, getProfileStatusEventFailureAction, getProfileMenuAction, getProfileMenuSuccessAction, getProfileMenuFailureAction, UpdateProfileAction, UpdateProfileSuccessAction, UpdateProfileFailureAction,  } from '../actions/profile-configuration.actions';
import { ProfileConfigService } from '@ptg-member/services/profile-configuration.service';
import { of } from 'rxjs';
import { ProfileConfigDetailResponse, ProfileConfigListResponse, ProfileMenuResponse, ProfileOverViewResponse, StatusEventResponse } from '@ptg-member/types/models/profile-configuration.model';
import { GetEntitiesResponse } from '@ptg-entity-management/services/models';
import { EntityService } from '@ptg-entity-management/services';

@Injectable()
export class ProfileConfigEffect {
  constructor(
    private actions$: Actions,
    private ProfileConfigService: ProfileConfigService,
    private entityService: EntityService
  ) {}
  getPageData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProfileConfigList),
      switchMap(({ query }) => {
        return this.ProfileConfigService.getProfileConfigList(query).pipe(
          map((profileConfigList: ProfileConfigListResponse) => {
            return getProfileConfigListSuccess({ profileConfigList });
          }),
          catchError((error) => {
            return of(getProfileConfigListFailure({ error }));
          })
        );
      })
    )
  );

  getEntitiesProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEntitiesProfileAction),
      switchMap(({ query }) =>
        this.entityService.getEntities(query).pipe(
          map((response: GetEntitiesResponse) =>
            getEntitiesProfileSuccessAction({ response })
          ),
          catchError((error) => of(getEntitiesProfileFailureAction({ error })))
        )
      )
    )
  );

  getProfileDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProfileDetailAction),
      switchMap(({ id }) =>
        this.ProfileConfigService.getProfileDetail(id).pipe(
          map((response: ProfileConfigDetailResponse) =>
            getProfileDetailSuccessAction({ response })
          ),
          catchError((error) => of(getProfileDetailFailureAction({ error })))
        )
      )
    )
  );

  getProfileView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProfileViewAction),
      switchMap(({ id }) =>
        this.ProfileConfigService.getProfileView(id).pipe(
          map((response: ProfileOverViewResponse) =>
            getProfileViewSuccessAction({ response })
          ),
          catchError((error) => of(getProfileViewFailureAction({ error })))
        )
      )
    )
  );

  getProfileHeader$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProfileHeaderAction),
      switchMap(({ id }) =>
        this.ProfileConfigService.getProfileHeader(id).pipe(
          map((response: any) =>
            getProfileHeaderSuccessAction({ response })
          ),
          catchError((error) => of(getProfileHeaderFailureAction({ error })))
        )
      )
    )
  );

  getProfileStatusEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProfileStatusEventAction),
      switchMap(({ id }) =>
        this.ProfileConfigService.getProfileStatusEvent(id).pipe(
          map((response: StatusEventResponse) =>
            getProfileStatusEventSuccessAction({ response })
          ),
          catchError((error) => of(getProfileStatusEventFailureAction({ error })))
        )
      )
    )
  );

  getProfileMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProfileMenuAction),
      switchMap(({ id }) =>
        this.ProfileConfigService.getProfileMenu(id).pipe(
          map((response: ProfileMenuResponse) =>
            getProfileMenuSuccessAction({ response })
          ),
          catchError((error) => of(getProfileMenuFailureAction({ error })))
        )
      )
    )
  );

  updateProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UpdateProfileAction),
      switchMap(({ body }) =>
        this.ProfileConfigService.updateProfile(body).pipe(
          map((response: any) =>
            UpdateProfileSuccessAction({ response })
          ),
          catchError((error) => of(UpdateProfileFailureAction({ error })))
        )
      )
    )
  );

  getProfileExist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProfileExistAction),
      switchMap(({ body }) =>
        this.ProfileConfigService.getProfileExist(body).pipe(
          map((response: any) =>
            getProfileExistSuccessAction({ response })
          ),
          catchError((error) => of(getProfileExistFailureAction({ error })))
        )
      )
    )
  );

  createProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createProfileAction),
      switchMap(({ query }) =>
        this.ProfileConfigService.createProfile(query).pipe(
          map((response: any) =>
            createProfileSuccessAction({ response })
          ),
          catchError((error) => of(createProfileFailureAction({ error })))
        )
      )
    )
  );

  removeProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeProfileAction),
      switchMap(({ id }) =>
        this.ProfileConfigService.removeProfile(id).pipe(
          map((response: any) =>
            removeProfileSuccessAction({ response })
          ),
          catchError((error) => of(removeProfileFailureAction({ error })))
        )
      )
    )
  );
}
