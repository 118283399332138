import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Column, ColumnType } from '@ptg-shared/controls/grid';
import { EntityProfileFixType, EntityViewFixType } from '@ptg-entity-management/types/enums';
import { Store, select } from '@ngrx/store';
import * as fromReducer from '@ptg-reducers';
import * as fromMember from '../../store/reducers';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import {
  getParticipantDocuments,
  removeDocumentsParticipant,
} from '@ptg-member/store/actions/participant-documents.actions';
import { BaseComponent } from '@ptg-shared/components';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { Documents } from '@ptg-member/types/models';
import { downloadFile, showBanner } from '@ptg-shared/utils/common.util';
import { selectDowloadMemberDocumentState } from 'src/app/admin/features/file/store/selectors';
import { getDocumentDownloadAction } from 'src/app/admin/features/file/store/actions';
import { ACTION, STATE } from '@ptg-shared/constance';
import { clearRemoveDocumentsStateAction, clearUploadDocumentsStateAction, removeDocumentsAction, uploadDocumentsAction } from '@ptg-member/store/actions/member-document.action';
import { EditDocumentComponent } from 'src/app/admin/features/file/components/edit-document/edit-document.component';
import { EntityType } from 'src/app/admin/features/file/types/enums/entity-type.enum';
import { MemberDocumentService } from '@ptg-member/services/member-document.service';
import { AsyncValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { CheckExistsDocumentNameResponse } from '@ptg-employer/models/employer-document.model';
import { Observable, of, timer } from 'rxjs';
import { selectUploadMemberDocumentState } from '../../store/reducers';
import { Router } from '@angular/router';
import { CALCULATION_DOCUMENT_LOCATION, DOCUMENT_LOCATION } from '@ptg-shared/constance/document-location.const';
import { EntityViewItem } from '@ptg-entity-management/services/models';
import { LIST_DISPLAY_PREFERENCE_TYPE } from '@ptg-member/types/enums';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { DocumentBodyRequest, RemoveDocumentItem } from '@ptg-member/types/models/member-document.model';
import { DocDetail } from '@ptg-member/types/models/participant-documents.model';
import { DatePipe } from '@angular/common';
import { getDateString } from '@ptg-shared/utils/string.util';
import { AccidentDocGenerate } from '@ptg-member/features/accident-claims/constants';

@Component({
  selector: 'ptg-participant-documents',
  templateUrl: './participant-documents.component.html',
  styleUrls: ['./participant-documents.component.scss'],
})
export class ParticipantDocumentsComponent extends BaseComponent implements OnInit {
  @Input() memberId!: string;
  @Input() menuId!: string;
  @Input() viewId!: string;
  @Input() cardNameDoc!: string;
  @Input() configCard!: EntityViewItem;
  @Output() onRemoveRow = new EventEmitter();
  readonly EntityProfileFixType = EntityProfileFixType;
  readonly LIST_DISPLAY_PREFERENCE_TYPE = LIST_DISPLAY_PREFERENCE_TYPE;

  isLoading: boolean = false;
  docData: DocDetail[] = [];
  lengthPg: number | any;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  currentRowIndex: number = 0;
  isDocument: boolean = true;
  downloadFileName: string = 'sample.pdf';
  bannerType: BannerType = BannerType.Hidden;
  currentFund: any = {};
  columns: Column[] = [
    {
      name: 'uploadedDate',
      header: {
        title: 'Date Posted',
      },
      type: ColumnType.DateTime,
      templateArgs: { format: 'MM/dd/yyyy' },
    },
    {
      name: 'documentName',
      header: {
        title: 'Document Name',
      },
      truncate: true,
      style: {
        maxWidth: '150px',
      }
    },
    {
      name: 'documentTypeName',
      header: {
        title: 'Document Type',
      },
      truncate: true,
      style: {
        maxWidth: '150px',
      }
    },
    {
      name: 'fileName',
      header: {
        title: 'File Name',
        style: {
          'max-width': '100px'
        }
      },
      truncate: true,
      width: '100px',
      style: {
        maxWidth: '150px',
      }
    },
    {
      name: 'action',
      header: {
        title: 'Action',
      },
    },
  ];
  constructor(
    private store: Store<fromReducer.State>,
    private memberStore: Store<fromMember.MemberState>,
    private dialog: MatDialog,
    private router: Router,
    private memberDocumentService: MemberDocumentService,
    public datePipe: DatePipe
  ) {
    super();
  }

  ngOnInit(): void {
    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.pageSize = el.defaultPageSize ?? 50;
        this.currentFund = el;
      });

    this.pageSize =
      Number(
        sessionStorage.getItem(
          this.currentFund.key + '-ptg-participant-documents-pageSize'
        )
      ) === 0
        ? this.pageSize
        : Number(
            sessionStorage.getItem(
              this.currentFund.key + '-ptg-participant-documents-pageSize'
            )
          );

    this.getData();
    this.getParticipantDocumentsSelector();

    this.store
      .pipe(select(fromMember.selectRemoveMemberDocumentState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state) {
          this.store.dispatch(clearRemoveDocumentsStateAction());
          if (state.state === STATE.SUCCESS) {
            if (this.pageNumber !== 1 && this.docData.length <= 1) {
              this.pageNumber = this.pageNumber - 1;
            }
            this.getData();
          }
        }
      });

    this.store
      .pipe(select(selectUploadMemberDocumentState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state && state.state !== 'loading') {
          this.store.dispatch(clearUploadDocumentsStateAction());
          this.getData();
        }
      });

  }

  getParticipantDocumentsSelector() {
    this.memberStore
      .pipe(select(fromMember.selectParticipantDocumentsState), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state) {
          this.docData = state?.lstDoc?.participantDocuments?.map((item) => ({
            ...item,
            uploadedDate: item?.uploadedDate ? this.datePipe.transform(getDateString(item.uploadedDate), 'MM/dd/yyyy') : undefined
          } as DocDetail)) ?? [];
          this.isLoading = state?.isLoading;
          this.lengthPg = state?.lstDoc?.total;
        }
      });
  }

  getData() {
    const query = {
      pageIndex: this.pageNumber,
      pageSize: this.pageSize,
    };
    this.memberStore.dispatch(getParticipantDocuments({ query: query, memberId: this.memberId, isShowOnOverView: true }));
  }

  changePage(event: PageEvent) {
    this.currentRowIndex = 0;
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    this.getData();
  }

  onclickRemoveDocument(row: any) {
    this.bannerType = BannerType.Hidden;
    const listRemoveDocumentItem: RemoveDocumentItem[] = [
      {
        memberId: row?.memberId,
        documentId: row?.fileId,
        documentLocation: row?.documentLocation,
        calculationBenefitId: row?.calculationBenefitId,
        calculationRecordId: row?.calculationRecordId,
        calculationType: row?.calculationType,
        calculationBenefitDocumentId: row?.calculationBenefitDocumentId,
        calculationBenefitDocumentDetailId: row?.calculationBenefitDocumentDetailId,
        accidentId: row?.accidentId,
        accidentDocumentId: row?.accidentDocumentId,
      },
    ];
    if(row?.accidentDocumentClassification === AccidentDocGenerate.AccidentPostcard){
      this.onRemoveRow.emit();
    } else {
      if (row?.calculationBenefitDocumentId) {
        this.memberDocumentService.validateBeforeRemoveDocument({ values: listRemoveDocumentItem }).subscribe((res) => {
          if (!res?.isValid && res?.message) {
            this.showErrorPopup(res?.message);
          } else {
            this.removeDocuments({ values: listRemoveDocumentItem });
          }
        });
      } else {
        this.removeDocuments({ values: listRemoveDocumentItem });
      }
    }
  }

  private showErrorPopup(message: string) {
    this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        title: 'Error',
        type: ConfirmType.Warning,
        text: message,
        cancelButtonTitle: 'Close',
        hideConfirmButton: true,
      },
    });
  }

  removeDocuments(body: DocumentBodyRequest) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        text: 'This document will be removed and no longer appear in the system. Are you sure you want to proceed?',
        type: ConfirmType.Destruct,
        title: 'This is a destructive action',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(removeDocumentsAction({ body }));
      }
    });
  }

  downloadDocument(row: any) {
    if (!row) {
      return;
    }
    this.downloadFileName = row?.fileName;
    this.store.dispatch(getDocumentDownloadAction({ fileId: row?.fileId as string, fileName: row.fileName as string }));
  }

  validateExistDocumentNameExist(documentId: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value || !control.value.trim()) {
        return of(null);
      }
      return timer(300).pipe(
        switchMap((): Observable<ValidationErrors | null> =>
          this.memberDocumentService.checkExistDocumentName(
            {
              memberId: this.memberId || '',
              documentName: control.value.toString(),
              documentId: documentId ?? null,
            }
          ).
            pipe(
              map((response: CheckExistsDocumentNameResponse) => {
                if (response?.exists) {
                  return { errMsgDocumentName: 'Document Name already exists.' };
                }
                return null;
              }),
              catchError(({ error }) => {
                return of({ errMsgDocumentName: error?.errorMessage });
              }),
            )
        )
      );
    };
  }

  uploadDocument() {
    // Open form upload
    const currentEntity = {
      entityType: EntityType.Participant,
      entityId: this.memberId,
    }
    const infoForm = {
      isUploadMultipleFile: true,
      validateDocumentName: this.validateExistDocumentNameExist(''),
    }

    const editDocumentDialog = this.dialog.open(EditDocumentComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        currentEntity,
        infoForm
      }
    });
    editDocumentDialog.afterClosed().subscribe((objectUpload: any) => {
      if (objectUpload) {
        const files = objectUpload.files;
        const body: any = {
          participantId: this.memberId,
          fileName: files.length <= 1 ? files[0].name : '', // check if upload multiple or not
          description: objectUpload.description,
          tags: objectUpload.tags,
          showOnOverview: objectUpload.showOnOverview,
          documentName: files.length <= 1 ? objectUpload.documentName : '',
          documentLocationTitle: DOCUMENT_LOCATION.OVERVIEW,
          documentLocationRouter: `/member/summary-view/true/${this.menuId}/${this.viewId}/${this.memberId}`,
          participantDocumentType: objectUpload?.participantDocumentType,
        }
        this.memberDocumentService.documentFile = files;
        this.store.dispatch(uploadDocumentsAction({ body }));
      }
    });
  }

  onManageDocumentList(event: any) {
    this.router.navigateByUrl(`member/individual-document/${this.memberId}?overview=true`);
  }

}
