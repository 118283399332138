import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { switchMapTo } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AddFundResponse, CheckBody, CheckResponse, FundData } from '../models/add-fund.model';

@Injectable({
  providedIn: 'root'
})
export class AddFundService {

  constructor(private httpClient: HttpClient) { }

  addNewFund(dataFund: FundData): Observable<any> {
    return this.httpClient.post<AddFundResponse>(`${environment.apiUrl}/Clients`, dataFund);
  }

  checkExits = (body: CheckBody) => {
    return this.httpClient.post<CheckResponse>(`${environment.apiUrl}/exists`, body);
  }
}
