<div class="benefit-calculation-configuration-container">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>

  <div class="flex flex-col p-6 gap-6">
    <ptg-banner
      *ngIf="bannerType"
      [(bannerType)]="bannerType"
      [message]="message"
    ></ptg-banner>

    <ptg-add-output-configuration
      [outputOptions]="outputOptions"
      [configSectionLabel]="configSectionLabel"
      [displayId]="displayId"
      [configurations]="configurations"
      [isLoading]="isLoading"
      (onSubmitEvent)="onSubmit($event)"
    ></ptg-add-output-configuration>
  </div>
</div>
