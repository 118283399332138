import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';

import { BaseComponent } from '@ptg-shared/components';
import { Option } from '@ptg-shared/controls/select/select.component';

import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { stringToBoolean } from '@ptg-shared/utils/string.util';
import { forkJoin } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { EntityService } from '../../services';
import { CheckExistEntityResponse, CreateEntityRequest } from '../../services/models';
import { createEntityAction, getAllEntitiesAction } from '../../store/actions';
import { EntityState } from '../../store/reducers';
import {
  createEntitySelector,
  getAllEntitiesSelector,
} from '../../store/selectors';

@Component({
  selector: 'ptg-create-entity',
  templateUrl: './create-entity.component.html',
  styleUrls: ['./create-entity.component.scss'],
})
export class CreateEntityComponent extends BaseComponent {
  formGroup?: FormGroup;
  parentEntityOptions: Option[] = [];

  get nameCtrl() {
    return this.formGroup?.get('name') as FormControl;
  }
  get codeCtrl() {
    return this.formGroup?.get('code') as FormControl;
  }
  get parentCtrl() {
    return this.formGroup?.get('parent') as FormControl;
  }
  get descriptionCtrl() {
    return this.formGroup?.get('description') as FormControl;
  }
  get versioningCtrl() {
    return this.formGroup?.get('versioning') as FormControl;
  }

  isContinue = false;
  savedParentName: string = '';
  fromParticipants: string = '';
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public fb: FormBuilder,
    public entityStore: Store<EntityState>,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CreateEntityComponent>,
    public router: Router,
    public route: ActivatedRoute,
    public entityService: EntityService,
    public switchConfirmPopupService: SwitchConfirmPopupService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.createForm();
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((queryParams) => {
        this.fromParticipants = queryParams.fromParticipants;
      });
    this.getParentsEntity();

    this.entityStore
      .pipe(select(getAllEntitiesSelector), takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.parentEntityOptions = (data?.payload ?? []).map((element: any) => {
          return {
            value: element.id,
            displayValue: element.name,
          };
        });
      });
    this.entityStore
      .pipe(select(createEntitySelector), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state?.error) {
          this.dialogRef.close();
        }
        if (state?.payload?.isContinue) {
          const url = stringToBoolean(this.fromParticipants)
            ? `/entity-management/entities/entity/${state?.payload?.response}?fromParticipants=true`
            : `/entity-management/entities/entity/${state?.payload?.response}`;
          this.router.navigateByUrl(url);
          this.dialogRef.close();
        } else {
          //Clear and added when success, add the newly saved entity to the potential parents list.
          this.formGroup?.reset({ name: '',code: '', parent: null, versioning: false, description: '' });
          this.formGroup?.enable();
          this.getParentsEntity();
        }
      });
  }

  private createForm(): void {
    this.formGroup = new FormGroup({
      name: new FormControl({
        validators: [Validators.required, Validators.maxLength(250)],
      }),
      code: new FormControl({
        validators: [Validators.required, Validators.maxLength(250)],
      }),
      parent: new FormControl(null),
      versioning: new FormControl(false),
      description: new FormControl(''),
    });
  }

  onCancel(): void {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }

  onClickCreateAndContinue(): void {
    this.isContinue = true;
    this.onSubmit();
  }

  onClickCreateAndAddAnother(): void {
    this.onSubmit();
  }

  onSubmit(): void {
    this.formGroup?.markAllAsTouched();
    if (this.formGroup?.valid) {
        this.entityService.checkExists({
          name: this.nameCtrl.value.trim(), 
          code: this.codeCtrl.value.trim()
        }).pipe(
          map((el) => {
            return el;
          },
          catchError(async (error) => this.handleError(error))),
          takeUntil(this.unsubscribe$)
        ).subscribe((response) => { 
        if (response.isExistsName) {
          this.nameCtrl.setErrors({inValidAsync: true});
        }
        if (response.isExistsCode) {
          this.codeCtrl.setErrors({inValidAsync: true});
        }
  
        this.formGroup?.markAllAsTouched();
        this.savedParentName =
          this.parentEntityOptions.find(
            (x) => x.value === this.formGroup?.value.parent
          )?.displayValue ?? '';
        const { name, code, parent, versioning, description } = this.formGroup?.value;
        const body: CreateEntityRequest = {
          name: name,
          code: code,
          parentId: parent,
          isEnableVersioning: versioning,
          description: description
        };
    
        if (this.formGroup?.pending) {
          const sub = this.formGroup.statusChanges.subscribe(() => {
            if (this.formGroup?.valid) {
              this.formGroup?.disable();
              this.saveData(body);
            }
            sub.unsubscribe();
          });
        }
        if (this.formGroup?.valid) {
          this.formGroup?.disable();
          this.saveData(body);
        }
      })
    }
  }

  private saveData(body: CreateEntityRequest) {
    this.entityStore.dispatch(
      createEntityAction({ request: body, isContinue: this.isContinue })
    );
  }

  private getParentsEntity() {
    this.entityStore.dispatch(
      getAllEntitiesAction({
        query: {
          sortField: 'Name',
          sortType: 0,
        },
      })
    );
  }

  handleError(error:any) {
    this.dialogRef.close({
      hasError: true
    });
  }
}
