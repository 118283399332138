import { createReducer, on } from '@ngrx/store';
import { BaseActionState } from '@ptg-shared/types/models';
import {
  GetExceptionConfigurationResponse,
  ExceptionConfigurationDetailResponse,
  GetValidationExceptionResponse,
} from '../../services/models/exception-configuration.model';
import {
  getExceptionConfigurationsAction,
  getExceptionConfigurationsFailureAction,
  getExceptionConfigurationsSuccessAction,
  clearGetExceptionConfigurationsStateAction,
  getExceptionConfigurationDetailAction,
  getExceptionConfigurationDetailFailureAction,
  getExceptionConfigurationDetailSuccessAction,
  clearGetExceptionConfigurationDetailStateAction,
  setExceptionConfigurationsDetailAction,
  setExceptionConfigurationsDetailFailureAction,
  setExceptionConfigurationsDetailSuccessAction,
  clearSetExceptionConfigurationsDetailStateAction,
  getValidationExceptionConfigurationAction,
  getValidationExceptionConfigurationSuccessAction,
  getValidationExceptionConfigurationFailureAction,
  clearGetValidationExceptionConfigurationStateAction,
} from '../actions';

export interface ExceptionConfigurationState {
  getExceptionConfiguration?: BaseActionState<GetExceptionConfigurationResponse>;
  getExceptionConfigurationDetail?: BaseActionState<ExceptionConfigurationDetailResponse>;
  getSetExceptionConfigurationDetailResponse?: BaseActionState;
  getValidationExceptionConfigurationResponse?: BaseActionState<GetValidationExceptionResponse>;
}

const initialState: ExceptionConfigurationState = {};

export const exceptionConfigurationReducer = createReducer(
  initialState,
  on(getExceptionConfigurationsAction, (state) => ({
    ...state,
    getExceptionConfiguration: {
      isLoading: true,
    },
  })),
  on(getExceptionConfigurationsSuccessAction, (state, { response }) => ({
    ...state,
    getExceptionConfiguration: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getExceptionConfigurationsFailureAction, (state, { error }) => ({
    ...state,
    getExceptionConfiguration: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetExceptionConfigurationsStateAction, (state) => ({
    ...state,
    getExceptionConfiguration: undefined,
  })),

  on(getExceptionConfigurationDetailAction, (state) => ({
    ...state,
    getExceptionConfigurationDetail: {
      isLoading: true,
    },
  })),
  on(getExceptionConfigurationDetailSuccessAction, (state, { response }) => ({
    ...state,
    getExceptionConfigurationDetail: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getExceptionConfigurationDetailFailureAction, (state, { error }) => ({
    ...state,
    getExceptionConfigurationDetail: {
      isLoading: false,
      success: false,
      error,
    },
  })),
  on(clearGetExceptionConfigurationDetailStateAction, (state) => ({
    ...state,
    getExceptionConfigurationDetail: undefined,
  })),

  on(setExceptionConfigurationsDetailAction, (state) => ({
    ...state,
    getSetExceptionConfigurationDetailResponse: {
      isLoading: true,
    },
  })),
  on(setExceptionConfigurationsDetailSuccessAction, (state, { response }) => ({
    ...state,
    getSetExceptionConfigurationDetailResponse: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(setExceptionConfigurationsDetailFailureAction, (state, { error }) => ({
    ...state,
    getSetExceptionConfigurationDetailResponse: {
      isLoading: false,
      success: false,
      error,
    },
  })),
  on(clearSetExceptionConfigurationsDetailStateAction, (state) => ({
    ...state,
    getSetExceptionConfigurationDetailResponse: undefined,
  })),

  on(getValidationExceptionConfigurationAction, (state) => ({
    ...state,
    getValidationExceptionConfigurationResponse: {
      isLoading: true,
    },
  })),
  on(getValidationExceptionConfigurationSuccessAction, (state, { response }) => ({
    ...state,
    getValidationExceptionConfigurationResponse: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getValidationExceptionConfigurationFailureAction, (state, { error }) => ({
    ...state,
    getValidationExceptionConfigurationResponse: {
      isLoading: false,
      success: false,
      error,
    },
  })),
  on(clearGetValidationExceptionConfigurationStateAction, (state) => ({
    ...state,
    getValidationExceptionConfigurationResponse: undefined,
  })),
);
