import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverviewHeaderEntityComponent } from './overview-header-entity.component';
import { ControlsModule } from '@ptg-shared/controls/controls.module';
import { SharedModule } from '@ptg-shared/shared.module';



@NgModule({
  declarations: [OverviewHeaderEntityComponent],
  imports: [
    CommonModule,
    ControlsModule,
    SharedModule,
  ],
  exports: [OverviewHeaderEntityComponent]
})
export class OverviewHeaderEntityModule { }
