import { Pipe, PipeTransform } from '@angular/core';

import { PersonName } from '@ptg-shared/types/models/common.model';

import { getConcatString } from '../utils/string.util';

@Pipe({
  name: 'customPersonName'
})
export class CustomPersonNamePipe implements PipeTransform {

    transform(value?: PersonName, options?: {id: string, text: string}[]): string {
    if (!value) {
      return '';
    }

    return `${ getConcatString([value.first || '', value.last || ''], ' ') }`;
  }

}
