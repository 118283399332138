<div
  class="profile-header-configuration"
  id="profile-header-configuration-page"
>
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>

  <div class="flex flex-col p-6 gap-6">
    <ptg-banner
      *ngIf="bannerType"
      [(bannerType)]="bannerType"
      [message]="message"
    ></ptg-banner>

    <form [formGroup]="editForm" *ngIf="editForm">
      <div class="wrap-btn">
        <button
          mat-raised-button
          type="submit"
          class="submit-button"
          [disabled]="!canSubmit"
          (click)="formSubmit$.next()"
        >
          Save
        </button>
        <button
          mat-raised-button
          type="button"
          class="cancel-button"
          (click)="onCancel()"
        >
          Cancel
        </button>
      </div>
      <div class="form-control-container">
        <ptg-textbox
          placeholder="Header Name"
          errorAsync="Header Name already exists."
          [ngClass]="{
            'has-error':
              editForm.get('name')?.errors && editForm.get('name')?.touched
          }"
          [controlField]="editForm.get('name')"
          [hasLabel]="true"
          [maxLength]="100"
          (valueChange)="changeValueInput()"
        ></ptg-textbox>
        <div class="status-container">
          <div class="status-select">
            <mat-form-field
              appearance="fill"
              class="select-custom"
              [ngClass]="{ 'mat-form-field-invalid': false }"
            >
              <mat-label>Status</mat-label>
              <mat-select [formControl]="statusSelected">
                <mat-select-trigger>
                  <div class="option-class">
                    <mat-icon
                      *ngIf="statusSelected?.value?.iconName"
                      [style.color]="statusSelected?.value?.color"
                      >{{ statusSelected?.value?.iconName }}</mat-icon
                    >
                    <span>{{ statusSelected?.value?.name }}</span>
                  </div>
                </mat-select-trigger>
                <mat-option
                  class="select-status-option"
                  *ngFor="let option of listStatusOption"
                  [value]="option"
                >
                  <mat-icon [style.color]="option?.color">{{
                    option?.iconName
                  }}</mat-icon>
                  <span>{{ option.name }}</span>
                </mat-option>
              </mat-select>

              <mat-error
                id="mat-error-status"
                *ngIf="statusSelected.touched && statusSelected?.errors"
                class="icon-name-status"
              >
                Status is required.
              </mat-error>
            </mat-form-field>
            <div
              class="add-btn"
              [ngClass]="{
                'add-btn-disable': isStatic || !statusSelected?.value?.name
              }"
            >
              <mat-icon (click)="addStatus()">add_circle</mat-icon>
            </div>
            <div class="chips-style">
              <mat-chip-list>
                <mat-chip *ngFor="let option of editForm.value.statuses">
                  <mat-icon
                    *ngIf="option?.iconName"
                    [style.color]="option?.color"
                    >{{ option?.iconName }}</mat-icon
                  >
                  <span>{{ option?.name }}</span>
                  <div class="remove-btn" *ngIf="!isStatic">
                    <mat-icon (click)="removeStatus(option)">clear</mat-icon>
                  </div>
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </div>
      </div>
    </form>

    <ptg-property-display-configuration
      *ngIf="editForm"
      #profileHeaderConfigs
      [propertyDisplayConfigurations]="propertyDisplayConfigurations"
      [propertyConfigs]="propertyConfigs"
      [addPropertySection]="addPropertySection"
      [sortPropertySection]="sortPropertySection"
      [columnNameMaxLength]="100"
      (onSubmitEvent)="onSubmit()"
      (formValueChange)="changeValueProperty()"
    ></ptg-property-display-configuration>
  </div>
</div>
