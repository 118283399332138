<ptg-grid
  [data]="dataCardList"
  [columns]="columns"
  [totalRecords]="lengthPg"
  [pageSize]="pageSize"
  [pageNumber]="pageNumber"
  [hideScrollbar]="false"
  (pageChange)="onChangePage($event)"
  (sortChange)="onSortChange($event)"
  (rowClick)="onRowClick($event)"
></ptg-grid>
