import { Pipe, PipeTransform } from '@angular/core';
import { getConcatString } from '../utils/string.util';

@Pipe({
  name: 'prefixName',
})
export class PrefixNamePipe implements PipeTransform {
  transform(value: { prefix: string } | string | undefined, options?: { id: string; text: string }[]): string {
    if (!value) {
      return '';
    }
    if (typeof value === 'string') {
      return value;
    }
    let prefix = value?.prefix ? value?.prefix : '';
    if (options) {
      prefix = options.find((el) => el.id === prefix)?.text || '';
    }
    return prefix || '';
  }
}
