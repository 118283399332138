<ptg-dialog class="upload-documents">
  <ng-template dialogContentHeader>
    <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>
    <ptg-overview-header-entity [injectedMemberId]="memberId" [isShowChangeMemberDetailBtn]="false" [isSubHeader]="true"></ptg-overview-header-entity>
  </ng-template>

  <ng-template dialogBody>
    <ptg-banner
      *ngIf="bannerType"
      [(bannerType)]="bannerType"
      [message]="message"
    ></ptg-banner>

    <div class="upload-documents-container" *ngIf="!isUploading else loadingTemplate">
      <div class="upload-documents-btns flex mb-4">
        <ptg-button
          buttonName="Save"
          class="upload-documents-btn mr-4"
          classInput="primary save-button"
          (clickButton)="onSaveUploadDocument()"
          [isDisabled]="isUploading"
        ></ptg-button>
        <ptg-button
          buttonName="Cancel"
          class="upload-documents-btn"
          classInput="primary cancel-button"
          (clickButton)="onCancelUploadDocument()"
        ></ptg-button>
      </div>

      <form [formGroup]="uploadDocumentForm">
        <div class="upload-documents-dropdowns flex">
          <ptg-select
            [isOptionObj]="true"
            [isSetMaxWidthSelect]="true"
            [listData]="calculationDocumentTypeOptions"
            [controlField]="documentTypeControl"
            placeholder="Document Type"
            [isMultipleLineOption]="true"
            panelClass="property-name-dropdown"
          ></ptg-select>

          <ptg-textbox
            placeholder="Document Name"
            errorAsync="Document Name already exists."
            [ngClass]="{ 'has-error': documentNameControl.touched && documentNameControl.errors }"
            [controlField]="documentNameControl"
            [hasLabel]="true"
            [maxLength]="250"
            maxErrorMessage="Exceed the 250 character limit."
          ></ptg-textbox>
        </div>

        <ptg-upload
          #fileDocument
          (onUploadAction)="uploadFile($event)"
          [accept]="acceptFile"
          [checkPattern]="checkPattern"
          [isRequired]="true"
          [typeFile]="supportedFileTypes"
          [fileSelected]="fileSelected"
          [controlField]="$any(fileControl)"
          buttonLabel="Upload"
          [noUpload]="true"
          [isRequired]="true"
          errRequired="Upload Signed Documentation is required."
          class="m-0"
          pageHeader="Signed Documentation"
          [imageFile]="true"
        ></ptg-upload>
      </form>

    </div>

    <ng-template #loadingTemplate>
      <ptg-spinner [isLoading]="isUploading"></ptg-spinner>
    </ng-template>
  </ng-template>
</ptg-dialog>
