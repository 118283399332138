import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as HeaderActions from '../actions/header.actions';
import { GetEntitiesResponse } from 'src/app/entity-management/services/models';
import { HeaderService } from '@ptg-member/services/header.service';
import { GetHeaderListResponse, HeaderDetailResponse, HeaderPropertiesResponse } from '@ptg-member/types/models';
import { ProfileConfigListResponse } from '@ptg-member/types/models/profile-configuration.model';
import { EntityService } from '@ptg-entity-management/services';

@Injectable()
export class HeaderEffect {
  constructor(
    private actions$: Actions,
    private headerService: HeaderService,
    private entityService: EntityService
  ) {}

  getPageData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeaderActions.getHeaderList),
      switchMap(({ query }) => {
        return this.headerService.getHeaderList(query).pipe(
          map((headerList: GetHeaderListResponse) => {
            return HeaderActions.getHeaderListSuccess({ headerList });
          }),
          catchError((error) => {
            return of(HeaderActions.getHeaderListFailure({ error }));
          })
        );
      })
    )
  );
  
  getHeaderProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeaderActions.getHeaderProfile),
      switchMap(({ id }) => {
        return this.headerService.getProfileConifg(id).pipe(
          map((profileConfigs: ProfileConfigListResponse) => {
            return HeaderActions.getHeaderProfileSuccess({ profileConfigs });
          }),
          catchError((error) => {
            return of(HeaderActions.getHeaderProfileFailure({ error }));
          })
        );
      })
    )
  );

  getHeaderDetailData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeaderActions.getHeaderDetail),
      switchMap(({ id }) => {
        return this.headerService.getHeaderDetail(id).pipe(
          map((headerDetail: HeaderDetailResponse) => {
            return HeaderActions.getHeaderDetailSuccess({ headerDetail });
          }),
          catchError((error) => {
            return of(HeaderActions.getHeaderDetailFailure({ error }));
          })
        );
      })
    )
  );

  getHeaderPropertiesData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeaderActions.getHeaderProperties),
      switchMap(({ query }) => {
        return this.headerService.getHeaderProperties(query).pipe(
          map((headerProperties: HeaderPropertiesResponse) => {
            return HeaderActions.getHeaderPropertiesSuccess({ headerProperties });
          }),
          catchError((error) => {
            return of(HeaderActions.getHeaderPropertiesFailure({ error }));
          })
        );
      })
    )
  );

  removeHeader$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeaderActions.removeHeader),
      switchMap(({ id }) => {
        return this.headerService.removeHeader(id).pipe(
          map(() => {
            return HeaderActions.removeHeaderSuccess();
          }),
          catchError((err) => {
            return of(
              HeaderActions.removeHeaderFailure({ error: err.message })
            );
          })
        );
      })
    )
  );

  createHeader$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeaderActions.createHeader),
      switchMap(({ body }) => {
        return this.headerService.createHeader(body).pipe(
          map(( header ) => {
            return HeaderActions.createHeaderSuccess({ body: header });
          }),
          catchError((err) => {
            return of(
              HeaderActions.createHeaderFailure({ error: err.message })
            );
          })
        );
      })
    )
  );

  updateHeader$ = createEffect(() =>
  this.actions$.pipe(
    ofType(HeaderActions.updateHeader),
    switchMap(({ body }) => {
      return this.headerService.updateHeader(body).pipe(
        map(() => {
          return HeaderActions.updateHeaderSuccess();
        }),
        catchError((err) => {
          return of(
            HeaderActions.updateHeaderFailure({ error: err.message })
          );
        })
      );
    })
  )
);

  getEntities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HeaderActions.getEntitiesAction),
      switchMap(({ query }) =>
        this.entityService.getEntities(query).pipe(
          map((response: GetEntitiesResponse) =>
            HeaderActions.getEntitiesSuccessAction({ response })
          ),
          catchError((error) => of(HeaderActions.getEntitiesFailureAction({ error })))
        )
      )
    )
  );
}
