import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ptg-hyperlink-column',
  templateUrl: './hyperlink-column.component.html',
  styleUrls: ['./hyperlink-column.component.scss'],
})
export class HyperlinkColumnComponent {
  @Input() text: string = '';
  @Input() href?: string;

  @Output() clickLink = new EventEmitter<void>();

  onClickLink(): void {
    this.clickLink.emit();
  }
}
