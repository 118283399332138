import { createReducer, on } from "@ngrx/store";
import { HeaderActions } from "../actions";
import { SearchResult } from "../models/search.models";

export const SearchFeatureKey = 'seachMember';

export interface State {
  isLoading: boolean;
  isError: boolean;
  searchResult: SearchResult;
}

const initialState: State = {
  isLoading: true,
  isError: false,
  searchResult: {} as SearchResult
};

export const reducer = createReducer(
  initialState,
  on(HeaderActions.getSearchRequest, (state) => ({
    ...state,
    isLoading: true,
    searchResult: {} as SearchResult
  })),
  on(HeaderActions.getSearchRequestSuccess, (state, { searchResult }) => ({
    ...state,
    isLoading: false,
    isError: false,
    searchResult
  })),
  on(HeaderActions.getSearchRequestFailure, (state, { errorMsg }) => ({
    ...state,
    isLoading: false,
    isError: true,
    searchResult: {} as SearchResult
  })),
  on(HeaderActions.clearSearchList, (state) => ({
    ...state,
    isLoading: true,
    isError: false,
    searchResult: {} as SearchResult
  })),
);
