import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { STRING_QUERY_PARAM } from '@ptg-shared/layout/constance/layout.const';
import { LayoutActions } from '@ptg-shared/layout/actions';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { getDateString } from '@ptg-shared/utils/string.util';
import { BaseComponent } from '@ptg-shared/components';

import * as fromReducer from '@ptg-reducers';
import { ActionItem, MemberListQuery, MemberNavigationState, OverviewData } from '@ptg-member/types/models';
import * as MemberNavigationActions from '@ptg-member/store/actions/member-navigation';
import * as ProfileHeaderConfigurationActions from '@ptg-member/store/actions/profile-header-configuration.actions';
import * as fromMember from '@ptg-member/store/reducers';
import { MemberDetailService } from '@ptg-member/services/member-detail.service';
import { HeaderPropertyConfig } from '@ptg-member/types/models/header-configuration.model';
import { HeaderItemConfig } from '@ptg-member/types/models/header-item-config.model';
import { EditMemberStatusEventComponent } from '@ptg-member/components/edit-member-status-event/edit-member-status-event.component';
import { clearSetMemberEventStateAction } from '@ptg-member/store/actions';
import { setMemberEventSelector } from '@ptg-member/store/selectors/member.selector';
import { EntityManagementState } from '@ptg-entity-management/store/reducers';
import { EntityPropertyType } from '@ptg-entity-management/types/enums';
import { getEntityProfileConfigOverviewSelector } from '@ptg-entity-management/store/selectors';
import { getEntityProfileConfigOverviewAction } from '@ptg-entity-management/store/actions';
import { ScreensKey } from 'src/app/app.const';

@Component({
  selector: 'ptg-entity-overview-header',
  templateUrl: './entity-overview-header.component.html',
  styleUrls: ['./entity-overview-header.component.scss'],
})
export class EntityOverviewHeaderComponent extends BaseComponent {
  @Output() editSectionEvent = new EventEmitter();
  @Output() removeMemberEvent = new EventEmitter();
  @Output() addNoteEvent = new EventEmitter();
  @Output() uploadDocumentEvent = new EventEmitter();
  @Output() lockAccountEvent = new EventEmitter();
  @Output() changeMemberDetailEvent = new EventEmitter();
  @Output() clickActionItem = new EventEmitter<string>();
  @Output() deactivateMemberEvent = new EventEmitter();

  @Input() data: any = {};
  @Input() detail: any = {};
  @Input() overviewData!: OverviewData[];
  @Input() actionItems: ActionItem[] = [];
  @Input() isLocked: boolean = false;
  @Input() identityKey: string = '';
  @Input() memberNavigationState!: MemberNavigationState;
  @Input() canRemove: boolean = false;
  @Input() isSubHeader: boolean = false;
  @Input() targetIdFromDialog?: string;
  @Input() isShowChangeMemberDetailBtn?: boolean = true;
  @Input() injectedMemberId: string | null = null;
  @Input() shouldDisableNavigationButtonsInAllCases: boolean = false;
  @Input() shouldDisableCloseButtonInAllCases: boolean = false;
  @Input() isDeactivate: boolean = false;

  readonly EntityPropertyType = EntityPropertyType;
  readonly ScreensKey = ScreensKey;
  

  unsubscribe$ = new Subject<void>();
  profileHeaders: HeaderPropertyConfig[] | undefined = undefined;
  navigationDisabled: {
    previous: boolean;
    next: boolean;
  } = {
    previous: true,
    next: true,
  };
  fundId!: string;
  recordId: string = '';
  selectUrlBeforeSearch: string = '';
  isHistoryTab: boolean = false;
  accidentId?: string;
  cardId: string = '';
  entityId: string = '';
  isLoading = true;
  memberDetail: any;
  status: any;
  headerItems: HeaderItemConfig[] | undefined = undefined;
  profileName: string = '';
  isOverview?: string;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    private location: Location,
    private store: Store<fromLayoutReducer.State>,
    private memberStore: Store<fromMember.MemberState>,
    private memberDetailService: MemberDetailService,
    private entityManagementStore: Store<EntityManagementState>,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store
    .pipe(select(fromLayoutReducer.selectUrlBeforeSearchState), takeUntil(this.unsubscribe$))
    .subscribe((url) => {
      this.selectUrlBeforeSearch = url ?? '/member';
    });

    this.route.params.subscribe((params) => {
      this.recordId = this.targetIdFromDialog || params.id || params.recordId || this.injectedMemberId;
      this.memberDetailService.memberId = params.id || params.recordId;
      this.accidentId = params.accidentId;
      this.checkMemberIndex();
      this.isHistoryTab = this.router.url.includes(STRING_QUERY_PARAM.SEARCH);
    });
    this.route.queryParams.subscribe((params) => {
      this.isOverview = params.isOverview;
    });

    this.entityManagementStore
      .pipe(select(getEntityProfileConfigOverviewSelector), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (!state?.success || !state?.payload || !state?.payload?.profileConfigOverview) return;

        const profileConfigOverview = state.payload.profileConfigOverview;
        if (this.isOverview === ScreensKey.Participants) {
          this.getMemberDetailData();
          this.cardId = profileConfigOverview.headerId;
          this.headerItems = profileConfigOverview.headerComponentItems;
        } else {
          this.cardId = profileConfigOverview.subPageHeaderId;
          this.headerItems = profileConfigOverview.subPageHeaderComponentItems;
        }
        this.entityId = profileConfigOverview.entityId;
        this.profileName = profileConfigOverview.profileName;

        this.getMemberProfileData();
      });

    this.memberStore
      .pipe(select(fromMember.selectProfileHeaderConfig), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state) {
          this.isLoading = state?.isLoading;
          this.profileHeaders = state?.payload
            ?.filter((x) => x.propertyName !== null)
            .map((item) => {
              if (item.type === EntityPropertyType.Binary) {
                return {
                  ...item,
                  value: item?.value,
                };
              }
              return {
                ...item,
                value:
                  item.value?.maskedValue || item.value?.maskedValue === null ? item.value?.maskedValue : item?.value,
              };
            });
          if (this.headerItems !== undefined && this.profileHeaders !== undefined) {
            this.headerItems = this.headerItems.map((item) => {
              let mappedValue = this.profileHeaders?.find(
                (x) =>
                  x.entityPropertyId === item.propertyId && x.entityReferencePropertyId === item.propertyReferenceId,
              );
              let value = mappedValue?.value;
              if (mappedValue?.type === EntityPropertyType.Status) {
                //TBU Option Date
                this.status = {
                  recordId: mappedValue?.recordId,
                  entityComponentId: mappedValue?.entityComponentId,
                  entityPropertyId: mappedValue?.entityPropertyId,
                  options: this.profileHeaders?.find(
                    (x) =>
                      x.entityPropertyId === item.propertyId &&
                      x.entityReferencePropertyId === item.propertyReferenceId,
                  )?.options,
                  value: value,
                };

                let status = mappedValue?.options?.find((item: any) => item.id === value?.status);
                let temp: any;
                if (item.option === 'Name') {
                  temp = {
                    icon: status.icon,
                    color: status.color,
                    name: status?.name,
                  };
                } else if (item.option === 'Event') {
                  temp = {
                    icon: null,
                    name: status?.events?.find((item: any) => item.id === value?.event)?.name,
                  };
                } else {
                  temp = {
                    icon: null,
                    name: value?.statusDate ? new DatePipe('en-US').transform(value?.statusDate, 'MM/dd/yyyy') : null,
                  };
                }
                value = temp;
              }
              if (
                mappedValue?.type === EntityPropertyType.Address ||
                mappedValue?.type === EntityPropertyType['Person Name']
              ) {
                if (value && typeof value === 'object' && Object.values(value).every((item) => item === null))
                  value = null;
                value = value;
              }
              if (value && mappedValue?.type === EntityPropertyType['Date Time']) {
                value = getDateString(value);
              }
              return {
                ...item,
                value: value,
                type: mappedValue?.type,
                options: mappedValue?.options,
                configs: mappedValue?.configs,
              };
            });
          }
        }
      });

    this.memberStore.pipe(select(fromMember.selectMemberDetail), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state) {
        this.memberDetail = state?.payload;
      }
    });

    this.store.pipe(select(fromReducer.selectCurrentFundState)).subscribe((el) => {
      this.fundId = el.id;
    });

    this.memberStore
      .pipe(select(fromMember.selectMemberNavigationState), debounceTime(300), takeUntil(this.unsubscribe$))
      .subscribe((memberNavigationState) => {
        this.memberNavigationState = memberNavigationState;
        this.checkMemberIndex();
        this.checkNavigateStatus();
      });

    this.entityManagementStore.pipe(select(setMemberEventSelector), takeUntil(this.unsubscribe$)).subscribe((el) => {
      if (el) {
        if (el.success === true) {
          this.getMemberProfileData();
        }

        this.memberStore.dispatch(clearSetMemberEventStateAction());
      }
    });
  }

  checkMemberIndex() {
    if (!this.memberNavigationState || !this.recordId) {
      return;
    }
    const currentIndex = this.memberNavigationState.memberIndex;
    const memberIndex = this.memberNavigationState.members.findIndex(
      (member) => this.recordId === member._memberId || this.recordId === member.Id,
    );
    if (currentIndex === memberIndex) {
      return;
    }
    this.memberStore.dispatch(MemberNavigationActions.setMemberIndex({ memberIndex }));
    this.checkCurrentPage(memberIndex, this.memberNavigationState.query as MemberListQuery);
  }

  checkNavigateStatus() {
    if (this.shouldDisableNavigationButtonsInAllCases === true) {
      this.navigationDisabled = { previous: true, next: true };
      return;
    }
    if (!this.memberNavigationState?.members?.length || this.isHistoryTab) {
      this.navigationDisabled = { previous: true, next: true };
      return;
    }
    const previous = this.memberNavigationState?.memberIndex <= 0;
    const next =
      this.memberNavigationState?.memberIndex === -1 ||
      this.memberNavigationState?.memberIndex >= this.memberNavigationState?.members?.length - 1;
    this.navigationDisabled = { previous, next };
  }

  lockAccount(isLock: boolean) {
    this.lockAccountEvent.emit(isLock);
  }

  removeMember() {
    this.removeMemberEvent.emit();
  }

  deactivateMember() {
    this.deactivateMemberEvent.emit();
  }

  close() {
    this.store.dispatch(LayoutActions.closeTab({}));

    if (this.router.url.includes(STRING_QUERY_PARAM.PROFILE_NAVIGATE)) {
      this.location.back();
      return;
    }
    if (!this.isHistoryTab) {
      this.memberStore.dispatch(MemberNavigationActions.clearMemberNavigationState());
    }
    let url = '/member';
    if (this.router.url.includes(STRING_QUERY_PARAM.SEARCH) && this.selectUrlBeforeSearch) {
      url = this.selectUrlBeforeSearch;
    }
    this.store.dispatch(LayoutActions.setHistory({ history: { urlBeforeSearch: '' } }));
    this.router.navigate([url], { state: { beforeState: this.memberNavigationState.query } });
  }

  onEditMemberStatusEvent() {
    this.dialog.open(EditMemberStatusEventComponent, {
      panelClass: ['edit-popup', 'edit-member-status-event-dialog'],
      disableClose: true,
      height: 'auto',
      autoFocus: false,
      data: {
        entityId: this.entityId,
        memberId: this.recordId,
        recordId: this.status.recordId,
        entityComponentId: this.status.entityComponentId,
        entityPropertyId: this.status.entityPropertyId,
        eventId: this.status.value?.event,
        statusId: this.status.value?.status,
        memberStatusList: this.status.options,
      },
    });
  }

  changeMemberDetail(isNext: boolean = false) {
    this.changeMemberDetailEvent.emit();
    const memberIndex = this.memberNavigationState.memberIndex + (isNext ? +1 : -1);
    const query = this.memberNavigationState.query as MemberListQuery;
    this.checkCurrentPage(memberIndex, query);
    this.memberStore.dispatch(MemberNavigationActions.setMemberIndex({ memberIndex }));
    const id = (this.memberNavigationState.members[memberIndex]?._memberId ||
      this.memberNavigationState.members[memberIndex]?.Id) as string;
    const entityId = this.memberNavigationState.members[memberIndex]?.entityId as string;
    const path = this.router.url.substr(0, this.router.url.lastIndexOf('/'));
    if (id) {
      const url = document.location.search.includes(STRING_QUERY_PARAM.SEARCH)
        ? `${path}/${id}?${STRING_QUERY_PARAM.SEARCH}`
        : `${path}/${id}`;
      if (this.accidentId) {
        this.redirectAccidentDetailPage(id);
      } else {
        this.router.navigateByUrl(url);
      }

      this.entityManagementStore.dispatch(
        getEntityProfileConfigOverviewAction({ entityId: entityId, recordId: id }),
      );
    }
  }

  redirectAccidentDetailPage(memberId: string) {
    this.router.navigateByUrl(`/member/accident/${memberId}`);
  }

  getMemberProfileData() {
    if (this.recordId) {
      this.memberStore.dispatch(
        ProfileHeaderConfigurationActions.getProfileHeaderDataAction({
          cardId: this.cardId,
          memberId: this.recordId,
          entityId: this.entityId,
          screenId: this.profileName.replace(/\s/g, '') + '_overview_',
        }),
      );
    }
  }

  getMemberDetailData() {
    if (this.recordId) {
      this.memberStore.dispatch(
        ProfileHeaderConfigurationActions.getMemberDetailAction({
          memberId: this.recordId,
        }),
      );
    }
  }

  checkCurrentPage(memberIndex: number, memberListQuery: MemberListQuery) {
    const query = JSON.parse(JSON.stringify(memberListQuery));
    let isAppend = false;
    if (memberIndex === 0 && this.memberNavigationState?.startPage > 1) {
      query.pageIndex -= 1;
    }
    if (
      memberIndex === this.memberNavigationState?.members?.length - 1 &&
      memberIndex === this.memberNavigationState?.query?.totalPerPage &&
      this.memberNavigationState.endPage < this.memberNavigationState.totalPage
    ) {
      query.pageIndex += 1;
      isAppend = true;
    }
    if (query.pageIndex !== memberListQuery.pageIndex) {
      this.memberStore.dispatch(
        MemberNavigationActions.getMoreMembers({
          query,
          idClient: this.fundId,
          isAppend,
        }),
      );
    }
  }

  onClickActionItem(id: string): void {
    this.clickActionItem.emit(id);
  }
}
