<div class="edit-exception-configuration">
  <div class="edit-exception-configuration__header">
    <ng-container *ngIf="listBreadcrumbs.length">
      <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>
    </ng-container>
  </div>

  <div class="edit-exception-configuration__body px-6 py-8">
    <div class="flex flex-col gap-4">
      <ptg-banner *ngIf="bannerType" [(bannerType)]="bannerType" [message]="message"></ptg-banner>

      <ptg-edit-exception
        [exceptionType]="exceptionType"
        [description]="savedDescription"
        [savedBenefitDetailList]="savedBenefitDetailList"
        [savedEntityDetailList]="savedEntityDetailList"
        [benefitOptionList]="benefitOptionList"
        [additionalBenefitValueObj]="additionalBenefitValueObj"
        [isShowBenefitBeginDateDropdown]="isShowBenefitBeginDateDropdown"
        [isShowAdditionalBenefitValueTextBox]="isShowAdditionalBenefitValueTextBox"
        [entityList]="entityList"
        [memberEntityData]="memberEntityData"
        [isShowEntityListDropdown]="isShowEntityListDropdown"
        [benefitHasInProcessingCalculation]="benefitHasInProcessingCalculation"
        [isPropertiesEditable]="isPropertiesEditable"
        [isEditingValidation]="isEditingValidation"
        (onSubmitEvent)="getSubmitEvent($event)"
        (onCancelEvent)="getCancelEvent()"
      ></ptg-edit-exception>
    </div>
  </div>
</div>
