import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import {
  GetCardQuery,
  GetListCardResponse,
  NewView,
  RemoveAccessResponse,
  SetViewDetailwRequest,
  ViewDataless,
} from '@ptg-member/types/models';

@Injectable()
export class ViewService {
  get Endpoint(): string {
    return `${environment.apiUrl}/entity`;
  }

  constructor(private httpClient: HttpClient) {}

  getViewList(query: any) {
    let params = new HttpParams();

    if (query.sortField) {
      params = params.append(
        'SortNames',
        query.sortField[0].toUpperCase() + query.sortField.substring(1)
      );
      params = params.append('SortType', query.sortType ? query.sortType : 0);
    }
    
    return this.httpClient.get(`${this.Endpoint}/views`, { params });
  }

  getViewDetail(id: string) {
    return this.httpClient.get(`${this.Endpoint}/views/${id}`);
  }

  getRemoveAccess(id: string): Observable<RemoveAccessResponse> {
    return this.httpClient.get<RemoveAccessResponse>(`${this.Endpoint}/views/${id}/used-configuration`);
  }

  getCardList(query: GetCardQuery): Observable<GetListCardResponse> {
    let body = {}

    if (query.sortNames) {
      body = {
        ...query,
        sortNames: [query?.sortNames],
        sortType: query.sortType || 0,
        entityId: query?.entityId
      }
    }

    let params = new HttpParams({ fromObject: body });
    return this.httpClient.get<GetListCardResponse>(`${this.Endpoint}/cards`, { params });
  }

  checkExits = (bodyCheck: any) => {
    return this.httpClient.post(`${this.Endpoint}/views/Exists`, {
      name: bodyCheck.name,
    });
  };

  createView(body: NewView): Observable<string> {
    return this.httpClient.post<string>(`${this.Endpoint}/views`, body);
  }

  updateView(id: string, body: SetViewDetailwRequest) {
    return this.httpClient.put(`${this.Endpoint}/views/${id}`, body);
  }

  removeView(id: string) {
    return this.httpClient.delete(`${this.Endpoint}/views/${id}`);
  }

  getViewDataless(id: string) {
    return this.httpClient.get(`${this.Endpoint}/views/${id}/dataless-display`);
  }

  updateViewDataless(id: string, body: ViewDataless) {
    return this.httpClient.put(`${this.Endpoint}/views/${id}/dataless-display`, body);
  }
}
