<div class="column-section">
  <ng-container *ngIf="!cardData?.cardNoData">
    <ng-container
    *ngFor="let property of propertiesConfig; let index = index"
  >
    <div
      class="half-row"
      *ngIf="
      property?.value ||
        property?.value === 0 ||
        property?.value === false && property?.type === EntityPropertyType.Binary ||
        (!property?.value &&
        (cardData?.cardNoData && datalessConfig.propertyDisplayPreferenceType === PROPERTY_DISPLAY_PREFERENCE_TYPE.DisplayLabelOnly ||
        datalessConfig?.nonValuePropertyDisplayType === NON_VALUE_PROPERTY_DISPLAY_TYPE.DisplayLabelOnly))
      "
    >
      <div
        class="value-container"
        [ngClass]="{
          'masked-value':
            property?.value && property?.config?.masked == 'true'
        }"
      >
        <span class="lable truncate">{{ property?.propertyLabel }}</span>
        <div class="value-item">
          <ng-container [ngSwitch]="property?.type">
            <span class="value truncate" *ngSwitchCase="EntityPropertyType.Currency">{{
                (property?.value | numberLocalDecimal : "$") || "-"
              }}</span>
            <span class="value truncate" *ngSwitchCase="EntityPropertyType.Date">{{(property?.value | date : "MM/dd/yyyy") || "-"}}</span>
            <span
              class="value truncate"
              *ngSwitchCase="EntityPropertyType['Person Name']"
            >{{ (property?.value | personName : property?.options) || "-" }}</span>
            <div class="value w-100" *ngSwitchCase="EntityPropertyType.Address">
              <ptg-view-address
                [value]="property?.value"
                [options]="property.options"
              ></ptg-view-address>
            </div>
            <span class="value truncate" *ngSwitchCase="EntityPropertyType.Decimal">{{
                (property?.value
                  | numberDecimal
                    : {
                        decimal:
                          property?.config?.fractionalLengthInput,
                        isCheckCurrentDecimal: true
                      }) || "-"
              }}</span>
            <span
              class="value truncate"
              *ngSwitchCase="EntityPropertyType['Whole Number']"
            >{{ (property?.value | numberDecimal) || "-" }}</span>
            <span class="value truncate" *ngSwitchCase="EntityPropertyType.Lookup">{{
                (property?.value
                  | propertyList : property.options) || "-"
              }}</span>
            <span class="value truncate" *ngSwitchCase="EntityPropertyType.Binary">{{
                property?.value === null || property?.value === undefined
                  ? "-"
                  : property?.value
                  ? property.config.affirmative
                  : property.config.negative
              }}</span>
            <span
              class="value truncate"
              *ngSwitchCase="EntityPropertyType.Percentage"
            >{{
                (property?.value | numberDecimal)
                  ? (property?.value | numberDecimal) + "%"
                  : "-"
              }}</span>
            <span class="value truncate" *ngSwitchCase="EntityPropertyType.Employer">{{
                (property?.value
                  | propertyList : property.options) || "-"
              }}</span>
            <span
              class="value truncate"
              *ngSwitchCase="EntityPropertyType.Department"
            >{{
                (property?.value
                  | propertyList : property.options) || "-"
              }}</span>
            <ng-container *ngSwitchCase="EntityPropertyType.RichText">
              <ptg-view-rich-text
                [title]="property?.propertyLabel"
                [content]="property?.value"
              ></ptg-view-rich-text>
            </ng-container>
            <span class="value truncate" *ngSwitchCase="EntityPropertyType['Date Time']">{{
                (property?.value | date : "MM/dd/yyyy hh:mm a") ||
                  "-"
              }}</span>
            <span
              class="value truncate break-word"
              *ngSwitchCase="EntityPropertyType.Email"
            >{{ property?.value || "-" }}</span>
            <span class="value truncate" *ngSwitchCase="EntityPropertyType.Tier">{{
                (property?.value
                  | propertyList : property.options) || "-"
              }}</span>
            <span class="value truncate" *ngSwitchCase="EntityPropertyType.Identifier">
              {{ 
                (property?.config?.autogenerated === 'true' && property?.value
                  ? property?.config?.patternPrefix + property?.value + property?.config?.patternSuffix
                  : property?.value
                ) || "-" 
              }}
            </span>
            <span class="value truncate" *ngSwitchDefault>{{ property?.value || "-" }}</span>
          </ng-container>
          <div *ngIf="property?.value && property?.config?.masked == 'true'" class="masked-icon">
            <mat-icon matSuffix (click)="onClickIcon(property)">{{ property.isMasked ? 'visibility_off' : 'visibility' }}</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  </ng-container>
</div>
<ng-container *ngIf="cardData?.cardNoData && datalessConfig.propertyDisplayPreferenceType === PROPERTY_DISPLAY_PREFERENCE_TYPE.DisplayMessage">
  <div class="data-not-found">No Data to Display</div>
</ng-container>
