import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DistributionDetail, DistributionMappingEditPayload } from '@ptg-member/types/models/distribution-mapping.model';
import { CANCEL_CONFIRM_MESSAGE } from '@ptg-shared/constance';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';

@Component({
  selector: 'ptg-edit-distribution-mapping',
  templateUrl: './edit-distribution-mapping.component.html',
  styleUrls: ['./edit-distribution-mapping.component.scss']
})
export class EditDistributionMappingComponent implements OnInit {
  editForm: FormGroup = new FormGroup({
    formArr: new FormArray([])
  });

  listBenefitPeriod = ['N/A', 'Before', 'After'];
  listDistributionCode = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'W'];

  get formArr() {
    return this.editForm.get('formArr') as FormArray;
  }
  
  constructor(
    public dialogRef: MatDialogRef<EditDistributionMappingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DistributionDetail,
    public dialog: MatDialog,
  ) {
    this.data.distributionCode.forEach((el, i) => {
      this.formArr.push(new FormGroup({
        benefitPeriod: new FormControl({value: el.benefitPeriod, disabled: i === 1}),
        distributionCode1: new FormControl(el.distributionCode1),
        distributionCode2: new FormControl(el.distributionCode2),
      }));
    });
  }

  ngOnInit(): void {
    this.formArr.get('0')?.get('benefitPeriod')?.valueChanges.subscribe(el => {
      switch (el) {
        case this.listBenefitPeriod[2]:
          if (this.formArr.controls.length < 2) {
            this.formArr.push(new FormGroup({
              benefitPeriod: new FormControl({value: this.listBenefitPeriod[1], disabled: true}),
              distributionCode1: new FormControl(),
              distributionCode2: new FormControl(),
            }));
          } else {
            this.formArr.get('1')?.get('benefitPeriod')?.setValue(this.listBenefitPeriod[1]);
          }
          break;

        case this.listBenefitPeriod[1]:
          if (this.formArr.controls.length < 2) {
            this.formArr.push(new FormGroup({
              benefitPeriod: new FormControl({value: this.listBenefitPeriod[2], disabled: true}),
              distributionCode1: new FormControl(),
              distributionCode2: new FormControl(),
            }));
          } else {
            this.formArr.get('1')?.get('benefitPeriod')?.setValue(this.listBenefitPeriod[2]);
          }
          break;
      
        case this.listBenefitPeriod[0]:
          this.formArr.removeAt(1);
          break;

        default:
          break;
      }
    })
    
  }

  onSubmit() {
    this.editForm.markAllAsTouched();
    if (this.formArr.valid) {
      let formData = this.formArr.getRawValue();
      let payload: DistributionMappingEditPayload = {
        isHasBenefitPeriod: false,
        beforeDistributionCode1: formData[0].distributionCode1,
        beforeDistributionCode2: formData[0].distributionCode2,
      };
      if (formData.length < 2) {
        payload = {
          isHasBenefitPeriod: false,
          beforeDistributionCode1: formData[0].distributionCode1,
          beforeDistributionCode2: formData[0].distributionCode2,
        }
      } else {
        payload.isHasBenefitPeriod = true;
        formData.forEach(el => {
          if (el.benefitPeriod === this.listBenefitPeriod[1]) {
            payload.beforeDistributionCode1 = el.distributionCode1;
            payload.beforeDistributionCode2 = el.distributionCode2;
          } else {
            payload.afterDistributionCode1 = el.distributionCode1;
            payload.afterDistributionCode2 = el.distributionCode2;
          }
        })
      }
      this.dialogRef.close(payload);
    }
  }

  onCancelClick() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: CANCEL_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  onClickRemoveRow(index: number) {
    this.formArr.removeAt(index);
    this.formArr.get('0')?.get('benefitPeriod')?.setValue('N/A');
  }
}
