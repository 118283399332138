import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  CheckExistLoddDocumentCanRemoveRequest,
  CheckExistLoddDocumentCanRemoveResponse,
  RemoveRemoveCalculationDocumentDetailRequest,
} from './models';
import { SHOW_LOADING } from '@ptg-interceptor/httpconfig.interceptor';

@Injectable()
export class RemoveCalculationDocumentDetailService {
  constructor(private httpClient: HttpClient) {}

  removeRemoveCalculationDocumentDetail(request: RemoveRemoveCalculationDocumentDetailRequest) {
    const context = new HttpContext().set(SHOW_LOADING, true);
    const { memberId, calculationBenefitId, calculationType, id } = request;

    return this.httpClient.delete<void>(
      `${environment.apiUrl}/Calculations/${memberId}/benefit-processing/calculation-benefit-histories/${calculationType}/${calculationBenefitId}/document/${id}`,
      { context },
    );
  }

  checkExistLoddDocumentCanRemove(
    request: CheckExistLoddDocumentCanRemoveRequest,
  ): Observable<CheckExistLoddDocumentCanRemoveResponse> {
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.get<CheckExistLoddDocumentCanRemoveResponse>(
      `${environment.apiUrl}/Calculations/${request.memberId}/benefit-processing/calculation-benefit-histories/${request.calculationBenefitId}/remove-document-validation`,
      { context },
    );
  }
}
