import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SHOW_LOADING, SKIP_ERRORS } from '@ptg-interceptor/httpconfig.interceptor';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  CalculationBenefitWarningsParams,
  GetCalculationBenefitDetailsRequest,
  GetCalculationBenefitDetailsResponse,
  SetBenefitDetailParams,
  SetBenefitDetailRequest,
  CreateGenerateCalculationWorksheetRequest,
} from './models';

@Injectable({
  providedIn: 'root',
})
export class CalculationBenefitDetailsService {
  constructor(private httpClient: HttpClient) {}

  getCalculationBenefitDetails(
    request: GetCalculationBenefitDetailsRequest,
  ): Observable<GetCalculationBenefitDetailsResponse> {
    const memberId = request.memberId;
    const calculationBenefitId = request.calculationBenefitId;
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.get<GetCalculationBenefitDetailsResponse>(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-benefit-histories/${calculationBenefitId}/details`,
      { context },
    );
  }

  setBenefitDetail(params: SetBenefitDetailParams, request: SetBenefitDetailRequest): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/calculations/${params.memberId}/benefit-processing/calculation-benefit-histories/${params.calculationBenefitId}/details/${params.detailType}`,
      request,
    );
  }

  calculationParameterCloseWarningMessage(params: CalculationBenefitWarningsParams): Observable<boolean> {
    return this.httpClient.put<boolean>(
      `${environment.apiUrl}/calculations/${params.memberId}/benefit-processing/calculation-benefit-histories/${params.calculationBenefitId}/details/${params.detailType}/cancel-warning`,
      {},
    );
  }

  createGenerateCalculationWorksheet(memberId: string, body: CreateGenerateCalculationWorksheetRequest) {
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.post(
      `${ environment.apiUrl }/calculations/calculation-benefit/${memberId}/calculation-worksheet/report`,
      body,
      { observe: 'response', responseType: 'blob', context },
    );
  }

}
