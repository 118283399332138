import { createReducer, on } from '@ngrx/store';
import { BaseActionState } from '@ptg-shared/types/models';
import { MunicipalityHeaderData, Remittance, RemittanceReceipt } from '../models/remittance-submission-history.model';
import { RemittanceSubmissionHistoryActions } from '../actions';
import { ACTION, STATE } from '@ptg-shared/constance';

export const remittanceSubmissionHistoryFeatureKey = 'remittanceSubmissionHistory';

export interface State {
  listRemittance: BaseActionState<Remittance[]>;
  municipalityHeaderData?: MunicipalityHeaderData;
  dataState?: {
    action?: string,
    state?: string,
    newRemittanceId?: string,
    messageError?: string,
    isLoading?: boolean
  }
  remittanceReceiptState: BaseActionState<RemittanceReceipt>;
  getRemittanceReceiptDownload?: BaseActionState<Blob[]>;
}

const initialState: State = {
  listRemittance: {
    isLoading: true
  },
  municipalityHeaderData: {
    municipalityName: '',
    availableCredit: 0,
    totalBalanceDue: 0
  },
  remittanceReceiptState: {
    isLoading: true
  }
};

export const reducer = createReducer(
  initialState,
  on(RemittanceSubmissionHistoryActions.getRemittancesRequest, (state) => ({
    ...state,
    listRemittance: {
      ...state.listRemittance,
      isLoading: true,
      total: 0,
      payload: []
    }
  })),
  on(RemittanceSubmissionHistoryActions.getRemittancesSuccess, (state, { response }) => ({
    ...state,
    listRemittance: {
      ...state.listRemittance,
      isLoading: false,
      total: response?.total,
      payload: response?.remittances,
      success: true,
    },
    municipalityHeaderData: {
      municipalityName: response?.municipalityName,
      availableCredit: response?.availableCredit,
      totalBalanceDue: response?.totalBalanceDue,
      paymentMethod: response?.paymentMethod
    }
  })),
  on(RemittanceSubmissionHistoryActions.getRemittancesFailure, (state) => ({
    ...state,
    listRemittance: {
      ...state.listRemittance,
      isLoading: false,
      total: 0,
      payload: [],
      success: false
    }
  })),

  on(RemittanceSubmissionHistoryActions.clearGetRemittancesState, (state) => ({
    ...state,
    listRemittance: {
      ...state.listRemittance,
      isLoading: true,
      total: 0,
      payload: []
    },
    municipalityHeaderData: undefined
  })),

  on(RemittanceSubmissionHistoryActions.getRemittanceReceiptRequest, (state) => ({
    ...state,
    remittanceReceiptState: {
      isLoading: true,
    }
  })),
  on(RemittanceSubmissionHistoryActions.getRemittanceReceiptSuccess, (state, { response }) => ({
    ...state,
    remittanceReceiptState: {
      isLoading: false,
      success: true,
      payload: response
    },
  })),
  on(RemittanceSubmissionHistoryActions.getRemittanceReceiptFailure, (state) => ({
    ...state,
    remittanceReceiptState: {
      ...state,
      isLoading: false,
      success: false
    }
  })),

  on(RemittanceSubmissionHistoryActions.getDownloadRemittanceReceiptAction, (state) => ({
    ...state,
    getRemittanceReceiptDownload: {
      isLoading: true,
    },
  })),
  on(RemittanceSubmissionHistoryActions.getDownloadRemittanceReceiptSuccessAction, (state, {response}) => ({
    ...state,
    getRemittanceReceiptDownload: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(RemittanceSubmissionHistoryActions.getDownloadRemittanceReceiptFailureAction, (state, { error }) => ({
    ...state,
    getRemittanceReceiptDownload: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(RemittanceSubmissionHistoryActions.clearGetDownloadRemittanceReceiptStateAction, (state) => ({
    ...state,
    getRemittanceReceiptDownload: undefined,
  })),

  on(RemittanceSubmissionHistoryActions.addNewRemittanceRequest, (state, { }) => ({
    ...state,
    dataState: {
      action: ACTION.ADD,
      isLoading: true,
    }
  })),
  on(RemittanceSubmissionHistoryActions.addNewRemittanceSuccess, (state, { newRemittanceId, messageError }) => ({
    ...state,
    dataState: {
      action: ACTION.ADD,
      state: STATE.SUCCESS,
      newRemittanceId,
      messageError,
      isLoading: false,
    }
  })),
  on(RemittanceSubmissionHistoryActions.addNewRemittanceFailure, (state) => ({
    ...state,
    dataState: {
      action: ACTION.ADD,
      state: STATE.FAIL,
      isLoading: false
    }
  })),

  on(RemittanceSubmissionHistoryActions.clearRemittanceSubmissionHistoryState, (state) => ({
    ...state,
    dataState: {
      action: '',
      state: '',
      newRemittanceId: '',
      messageError: '',
      isLoading: false
    }
  })),

);
