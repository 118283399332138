<ptg-panel [title]="card.cardName" [noPaddingBody]="true">
  <ng-template panelExtraHeader>
    <ng-container *ngIf="panelExtraHeader" [ngTemplateOutlet]="panelExtraHeader.templateRef"></ng-container>
  </ng-template>

  <ng-template panelBody class="content flex flex-col p-6 gap-6">
    <div class="demo-content" *ngFor="let component of card?.cardComponents">
      <!-- List -->
      <ptg-entity-summary-card-list
        *ngIf="component.propertyDisplay === null && 
          component.cardProperties?.length &&
          (component.componentType === EntityProfileComponentType['List - Detail View'] 
          || component.componentType === EntityProfileComponentType['List - Summary View'])"
        [targetId]="targetId"
        [entityId]="entityId"
        [card]="card"
        [component]="component"
      ></ptg-entity-summary-card-list>

      <!-- Table -->
      <ptg-entity-summary-card-table
        *ngIf="component.propertyDisplay === PROPERTY_DISPLAY.Table && component.cardProperties.length > 0"
        [component]="component"
        [card]="card"
        [targetId]="targetId"
        [entityId]="entityId"
      ></ptg-entity-summary-card-table>

      <!-- Column -->
      <ptg-entity-summary-card-column
        *ngIf="component.propertyDisplay === PROPERTY_DISPLAY.Column && component.cardProperties.length > 0"
        [component]="component"
        [card]="card"
        [targetId]="targetId"
        [entityId]="entityId"
      ></ptg-entity-summary-card-column>
    </div>
  </ng-template>
</ptg-panel>
