import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  createParameterMappingAction,
  createParameterMappingFailureAction,
  createParameterMappingSuccessAction,
  deleteCalculationFailure,
  deleteCalculationRequest,
  deleteCalculationSuccess,
  getCalculationDetailFailure,
  getCalculationDetailRequest,
  getCalculationDetailSuccess,
  getCalculationInputMappingFailure,
  getCalculationInputMappingRequest,
  getCalculationInputMappingSuccess,
  getCalculationListFailure,
  getCalculationListRequest,
  getCalculationListSuccess,
  getCalculationOutputMappingFailure,
  getCalculationOutputMappingRequest,
  getCalculationOutputMappingSuccess,
  getFileParametersAction,
  getFileParametersFailureAction,
  getFileParametersSuccessAction,
  getListSavePropertyAction,
  getListSavePropertyFailureAction,
  getListSavePropertySuccessAction,
  removeParameterMappingAction,
  removeParameterMappingFailureAction,
  removeParameterMappingSuccessAction,
  setParameterMappingAction,
  setParameterMappingFailureAction,
  setParameterMappingSuccessAction,
  uploadCalculationFileFailure,
  uploadCalculationFileRequest,
  uploadCalculationFileSuccess,
} from '../actions';
import { CalculationListService } from '../../services';
import {
  GetCalculationDetailResponse,
  GetCalculationInputMappingResponse,
  GetCalculationOutputMappingResponse,
  GetFileParametersResponse,
  GetListSavePropertyResponse,
  RetirementBenefitCalculations,
} from '../../services/models';

@Injectable()
export class CalculationListEffects {
  getCalculationList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCalculationListRequest),
      switchMap(({ query }) => {
        return this.calculationListService.getCalculationList(query).pipe(
          map((retirementBenefitCalculations: RetirementBenefitCalculations) => {
            return getCalculationListSuccess({ retirementBenefitCalculations });
          }),
          catchError((error) => {
            return of(getCalculationListFailure({ error }));
          }),
        );
      }),
    ),
  );

  getCalculationDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCalculationDetailRequest),
      switchMap(({ calculationId }) => {
        return this.calculationListService.getCalculationDetail(calculationId).pipe(
          map((res: GetCalculationDetailResponse) => {
            return getCalculationDetailSuccess({ calculationDetail: res.detailCalculationFile });
          }),
          catchError((error) => {
            return of(getCalculationDetailFailure());
          }),
        );
      }),
    ),
  );

  getCalculationInputMapping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCalculationInputMappingRequest),
      switchMap(({ query }) => {
        return this.calculationListService.getCalculationInputMapping(query).pipe(
          map((res: GetCalculationInputMappingResponse) => {
            return getCalculationInputMappingSuccess({ calculationInputMapping: res });
          }),
          catchError((error) => {
            return of(getCalculationInputMappingFailure());
          }),
        );
      }),
    ),
  );

  getCalculationOutputMapping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCalculationOutputMappingRequest),
      switchMap(({ query }) => {
        return this.calculationListService.getCalculationOutputMapping(query).pipe(
          map((res: GetCalculationOutputMappingResponse) => {
            return getCalculationOutputMappingSuccess({ calculationOutputMapping: res });
          }),
          catchError((error) => {
            return of(getCalculationOutputMappingFailure());
          }),
        );
      }),
    ),
  );

  uploadCalculationFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadCalculationFileRequest),
      switchMap(({ uploadFormData, action }) => {
        return this.calculationListService.uploadCalculationFile(uploadFormData).pipe(
          map((response: any) => {
            return uploadCalculationFileSuccess({ action });
          }),
          catchError((error) => {
            return of(uploadCalculationFileFailure({ error, action }));
          }),
        );
      }),
    ),
  );

  deleteCalculation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteCalculationRequest),
      switchMap(({ calculationId }) => {
        return this.calculationListService.deleteCalculation(calculationId).pipe(
          map(() => {
            return deleteCalculationSuccess();
          }),
          catchError((error) => {
            return of(deleteCalculationFailure());
          }),
        );
      }),
    ),
  );

  createParameterMapping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createParameterMappingAction),
      switchMap(({ request }) =>
        this.calculationListService.createParameterMapping(request).pipe(
          map(() => createParameterMappingSuccessAction({ parameterType: request.type })),
          catchError((error) => of(createParameterMappingFailureAction({ parameterType: request.type, error }))),
        ),
      ),
    ),
  );

  getFileParameters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getFileParametersAction),
      switchMap(({ request }) =>
        this.calculationListService.getFileParameters(request).pipe(
          map((response: GetFileParametersResponse) => getFileParametersSuccessAction({ response })),
          catchError((error) => of(getFileParametersFailureAction({ error }))),
        ),
      ),
    ),
  );

  setParameterMapping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setParameterMappingAction),
      switchMap(({ request }) =>
        this.calculationListService.setParameterMapping(request).pipe(
          map(() => setParameterMappingSuccessAction({ parameterType: request.type })),
          catchError((error) => of(setParameterMappingFailureAction({ parameterType: request.type, error }))),
        ),
      ),
    ),
  );

  removeParameterMapping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeParameterMappingAction),
      switchMap(({ id, parameterType }) =>
        this.calculationListService.removeParameterMapping(id).pipe(
          map(() => removeParameterMappingSuccessAction({ parameterType })),
          catchError((error) => of(removeParameterMappingFailureAction({ parameterType, error }))),
        ),
      ),
    ),
  );

  getSaveProperty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getListSavePropertyAction),
      switchMap(({ request }) =>
        this.calculationListService.getListSaveProperty(request).pipe(
          map((response: GetListSavePropertyResponse) => getListSavePropertySuccessAction({ response })),
          catchError((error) => of(getListSavePropertyFailureAction({ error }))),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private calculationListService: CalculationListService,
  ) {}
}
