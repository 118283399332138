<div class="subheader-configuration h-full" id="subheader-configuration-list-page">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>

  <div class="flex flex-col p6 content-container">
    <div class="grid-wrapper h-full">
      <ptg-grid
        [data]="subHeaderConfigurationsList"
        [columns]="columns"
        [isLoading]="isLoading"
        [fitToParent]="true"
        [totalRecords]="totalRecords"
        [pageSize]="pageSize"
        [pageNumber]="pageNumber"
        (sortChange)="onChangeSort($event)"
        (pageChange)="onChangePage($event)"
        notFoundMessage="No Data to Display">
        <ng-template cellContent columnName="action" let-row>
          <ptg-button buttonName="Edit" classInput="edit-button" (clickButton)="onClickEditSubHeaderConfiguration(row)">
            <mat-icon>edit</mat-icon>
          </ptg-button>
        </ng-template>
      </ptg-grid>
    </div>
  </div>
</div>
