import { createReducer, on } from '@ngrx/store';
import {
  GetStepConfigurationResponse,
} from '@ptg-member/features/calculation/services/models';
import {
  CreateRetirementBenefitResponse,
  GetDetectRecalculationBenefitResponse,
} from '@ptg-member/features/calculation/services/models/retirement-benefit-dialog.model';

import {
  clearGetDetectRecalculationBenefitStateAction,
  clearCreateRetirementBenefitDialogsStateAction,
  clearGetStepConfigurationDetailByBenefitOptionStateAction,
  createRetirementBenefitAction,
  createRetirementBenefitFailureAction,
  createRetirementBenefitSuccessAction,
  getBenefitOptionsAction,
  getBenefitOptionsFailureAction,
  getBenefitOptionsStateAction,
  getBenefitOptionsSuccessAction,
  getDetectRecalculationBenefitAction,
  getDetectRecalculationBenefitFailureAction,
  getDetectRecalculationBenefitSuccessAction,
  getStepConfigurationDetailByBenefitOptionAction,
  getStepConfigurationDetailByBenefitOptionFailureAction,
  getStepConfigurationDetailByBenefitOptionSuccessAction,
  getValidateBeforeRecalculationBenefitAction,
  getValidateBeforeRecalculationBenefitSuccessAction,
  getValidateBeforeRecalculationBenefitFailureAction,
  clearGetValidateBeforeRecalculationBenefitStateAction,
} from '@ptg-member/features/calculation/store';
import { BaseActionState } from '@ptg-shared/types/models';
import { StepConfigurationValues } from '../../services/models/retirement-benefit.model';

export interface RetirementBenefitDialogState {
  createRetirementBenefit?: BaseActionState<CreateRetirementBenefitResponse>;
  getBenefitOptions?: BaseActionState<StepConfigurationValues>;
  getStepConfigurationDetailByBenefitOption?: BaseActionState<GetStepConfigurationResponse>;
  getDetectRecalculationBenefit?: BaseActionState<GetDetectRecalculationBenefitResponse>;
  getValidateBeforeRecalculationBenefit?: BaseActionState<boolean>;
}

const initialState: RetirementBenefitDialogState = {};

export const retirementBenefitDialogReducer = createReducer(
  initialState,

  on(createRetirementBenefitAction, (state) => ({
    ...state,
    createRetirementBenefit: {
      isLoading: true,
    },
  })),
  on(createRetirementBenefitSuccessAction, (state, { retirementBenefitId }) => ({
    ...state,
    createRetirementBenefit: {
      isLoading: false,
      success: true,
      payload: {
        retirementBenefitId,
      },
    },
  })),
  on(createRetirementBenefitFailureAction, (state, { error }) => ({
    ...state,
    createRetirementBenefit: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearCreateRetirementBenefitDialogsStateAction, (state) => ({
    ...state,
    createRetirementBenefit: undefined,
  })),

  on(getBenefitOptionsAction, (state) => ({
    ...state,
    getBenefitOptions: {
      isLoading: true,
    },
  })),
  on(getBenefitOptionsSuccessAction, (state, { response }) => ({
    ...state,
    getBenefitOptions: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getBenefitOptionsFailureAction, (state, { error }) => ({
    ...state,
    getBenefitOptions: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(getBenefitOptionsStateAction, (state) => ({
    ...state,
    getBenefitOptions: undefined,
  })),
  on(getStepConfigurationDetailByBenefitOptionAction, (state) => ({
    ...state,
    getStepConfigurationDetailByBenefitOption: {
      isLoading: true,
    },
  })),
  on(getStepConfigurationDetailByBenefitOptionSuccessAction, (state, { response }) => ({
    ...state,
    getStepConfigurationDetailByBenefitOption: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getStepConfigurationDetailByBenefitOptionFailureAction, (state, { error }) => ({
    ...state,
    getStepConfigurationDetailByBenefitOption: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetStepConfigurationDetailByBenefitOptionStateAction, (state) => ({
    ...state,
    getStepConfigurationDetailByBenefitOption: undefined,
  })),
  on(getDetectRecalculationBenefitAction, (state) => ({
    ...state,
    getDetectRecalculationBenefit: {
      isLoading: true,
    },
  })),
  on(getDetectRecalculationBenefitSuccessAction, (state, { response }) => ({
    ...state,
    getDetectRecalculationBenefit: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getDetectRecalculationBenefitFailureAction, (state, { error }) => ({
    ...state,
    getDetectRecalculationBenefit: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetDetectRecalculationBenefitStateAction, (state) => ({
    ...state,
    getDetectRecalculationBenefit: undefined,
  })),

  on(getValidateBeforeRecalculationBenefitAction, (state) => ({
    ...state,
    getValidateBeforeRecalculationBenefit: {
      isLoading: true,
    },
  })),
  on(getValidateBeforeRecalculationBenefitSuccessAction, (state, { response }) => ({
    ...state,
    getValidateBeforeRecalculationBenefit: {
      isLoading: false,
      success: true,
      payload: response.isValidReCalculate,
    },
  })),
  on(getValidateBeforeRecalculationBenefitFailureAction, (state, { error }) => ({
    ...state,
    getValidateBeforeRecalculationBenefit: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetValidateBeforeRecalculationBenefitStateAction, (state) => ({
    ...state,
    getValidateBeforeRecalculationBenefit: undefined,
  }))

);
