import { createSelector } from '@ngrx/store';

import { offCycleSelector } from './selector';

export const offCyclePaymentSelector = createSelector(
  offCycleSelector,
  (state) => state?.offCyclePayment
);

export const getCompleteOffCyclePaymentListSelector = createSelector(
  offCyclePaymentSelector,
  (state) => state?.getCompleteOffCyclePaymentList
);

export const editCheckNumberSelector = createSelector(
  offCyclePaymentSelector,
  (state) => state?.editCheckNumber
);

export const finalizeCompletePaymentStateSelector = createSelector(
  offCyclePaymentSelector,
  (state) => state?.finalizeCompletePaymentState
);

export const setCompletePaymentBoardBankStateSelector = createSelector(
  offCyclePaymentSelector,
  (state) => state?.setCompletePaymentBoardBankState
);

export const getOffCyclePaymentsSelector = createSelector(
  offCyclePaymentSelector,
  (state) => state?.getOffCyclePayments
);

export const completePaymentStateSelector = createSelector(
  offCyclePaymentSelector,
  (state) => state?.completePaymentState
);

export const completePaymentHeaderSelector = createSelector(
  completePaymentStateSelector,
  (state) => state.offCycleData
);

export const missingRequiredValuesSelector = createSelector(
  completePaymentHeaderSelector,
  (state) => state?.isMissingRequiredValues
);

export const reissueOffCycleSelector = createSelector(
  offCyclePaymentSelector,
  (state) => state?.reissueOffCycleState
);

export const generateEDISelector = createSelector(
  offCyclePaymentSelector,
  (state) => state?.generateEDI
);

export const completeOffCyclePaymentIdSelector = createSelector(
  offCyclePaymentSelector,
  (state) => state.completeOffCyclePaymentId
);

export const cancelOffCyclePaymentStateSelector = createSelector(
  offCyclePaymentSelector,
  (state) => state?.cancelOffCyclePaymentState
);

export const updateDepositDateStateSelector = createSelector(
  offCyclePaymentSelector,
  (state) => state.updateDepositDateState
);

export const offCycleActionSelector = createSelector(
  offCyclePaymentSelector,
  (state) => state?.offCycleActionState
);
