interface Location {
  getAuth0ClientId(apiUrl: string): string;
  }

  const auth0Env = {
    eagle_dev: {
      client_id: 'chDmvjfMBFZvtQoHZlTPWS0D5JoTi34H',
      domain: 'https://eagle-ptg-penproplus-api-dev.azurewebsites.net',
    },
    eagle_uat: {
      client_id: 'VJ6wt62eoiU5oSidzAqvYJVQQMqe4wUM',
      domain: 'https://eagle-ptg-penproplus-api-uat.azurewebsites.net',
    },
    demo_uat: {
      client_id: 'mYD5O8w8lojufbmFDEobO6S4DtFUnDXa',
      domain: 'https://demo-ptg-penproplus-api-uat.azurewebsites.net',
    },
    kalamazoo_uat: {
      client_id: 'Pmb2C5BO3OyjnPhwB9aXNP8hweAQxYRm',
      domain: 'https://kalamazoo-ptg-penproplus-api-uat.azurewebsites.net',
    },
    winston_uat: {
      client_id: 'jXiQOrb8wpD9FFW6DJzVYsyiqQMnW40t',
      domain: 'https://winston-ptg-penproplus-api-uat.azurewebsites.net',
    },
    lion_dev: {
      client_id: '07h6hDddXNvjbdUSZDUNvw55DVW6lhJq',
      domain: 'https://lion-ptg-penproplus-api-dev.azurewebsites.net',
    },
    lion_uat: {
      client_id: 'lKetAfCtEuLKfN1UVbLL2MhvhioB9d6W',
      domain: 'https://lion-ptg-penproplus-api-uat.azurewebsites.net',
    },
    pikachu_dev: {
      client_id: 'PJYEKpZ2R5W3QDeHU2N8NBwpqVzhSaNF',
      domain: 'https://pikachu-ptg-penproplus-api-dev.azurewebsites.net',
    },
    pikachu_uat: {
      client_id: '4GqFKgM8rcf2g7smx1Uz3x2Y1wuPV1Fl',
      domain: 'https://pikachu-ptg-penproplus-api-uat.azurewebsites.net',
    },
    dm_dev: {
      client_id: 'kd617mnnbRoFVtOVoDdUBsiXy9XEhlgH',
      domain: 'https://dm-ptg-penproplus-api-dev.azurewebsites.net',
    },
    dm_uat: {
      client_id: '8ejyK0aMCSI1XPVYZyI3Mv1LqzuwGFJZ',
      domain: 'https://dm-ptg-penproplus-api-uat.azurewebsites.net',
    },
    minions_dev: {
      client_id: 'BlmJrYlmeuPdILt1RLrByEyrwJIoxZfg',
      domain: 'https://minions-ptg-penproplus-api-dev.azurewebsites.net',
    },
    minions_uat: {
      client_id: 'x7jzxQxt63Lcly4tep8oZxc7I3Bpymu3',
      domain: 'https://minions-ptg-penproplus-api-uat.azurewebsites.net',
    },
    platform_dev: {
      client_id: '901aUG7m7GumftmR4BTtY9gvvQ1PvzvE',
      domain: 'https://platform-ptg-penproplus-api-dev.azurewebsites.net',
    },
    platform_uat: {
      client_id: 'Fr1JwwXzrE2mWJPtCZ6gMnbGPeGGBJul',
      domain: 'https://platform-ptg-penproplus-api-uat.azurewebsites.net',
    },
    platform_dev_v2: {
      client_id: '0qn30rNy2ltyNZETxWcUAHCjjJQbEdt3',
      domain: 'https://platform-dev-api.ingnp.ptgppplus.org',
    },
    platform_uat_v2: {
      client_id: 'gjJg8RGNg7X1NZp3cbn1IBoJsPBj3qM2',
      domain: 'https://platform-uat-api.ingnp.ptgppplus.org',
    },
    pre_dev: {
      client_id: 'Hgm26fUUu7BqgkUXn086v8cNJwoOoaEW',
      domain: 'https://pre-ptg-penproplus-api-dev.azurewebsites.net',
    },
    pre_uat: {
      client_id: 'ITNJe43WGPzV88XFynGR9oOA9EaWFWM4',
      domain: 'https://pre-ptg-penproplus-api-uat.azurewebsites.net',
    },
    rabbit_dev: {
      client_id: 'byNncCwLNER5vxEW6woaMgDqsaqJvNOf',
      domain: 'https://rabbit-ptg-penproplus-api-dev.azurewebsites.net',
    },
    rabbit_uat: {
      client_id: 'tIJ7ukjCSNSV54mHvNsWHgdcTQsd0LSy',
      domain: 'https://rabbit-ptg-penproplus-api-uat.azurewebsites.net',
    },
    security_check: {
      client_id: '2ADneYGwclmkmnsb2bS4cvlUPFnYwGQ6',
      domain: 'https://security-ptg-penproplus-api-dev.prod.ingnp.ptgppplus.org',
    },
    security_dev: {
      client_id: '6V2RM2fL7tZBvvCqDAXbGy9BbPjcFHfQ',
      domain: 'https://security-ptg-penproplus-api-dev.ingnp.ptgppplus.org',
    },
    staging_dev: {
      client_id: 'F4b2mkJ1asHKr0mZmMhm4fsB1MiJIo0B',
      domain: 'https://staging-ptg-penproplus-api-dev.azurewebsites.net',
    },
    staging_uat: {
      client_id: 'yvPcnpZ9Ym354Csprn02M3Wr09l9mJWy',
      domain: 'https://staging-ptg-penproplus-api-uat.azurewebsites.net',
    },
    stable: {
      client_id: 'hnuVc3QXoTzoearI3SQ2phPDdC50F4pb',
    },
  };

  Location.prototype.getAuth0ClientId = function (apiUrl: string) {
    let auth0ClientId = '';
    switch (apiUrl) {
      case auth0Env.eagle_dev.domain:
        auth0ClientId = auth0Env.eagle_dev.client_id;
        break;
      case auth0Env.eagle_uat.domain:
        auth0ClientId = auth0Env.eagle_uat.client_id;
        break;
      case auth0Env.demo_uat.domain:
        auth0ClientId = auth0Env.demo_uat.client_id;
        break;
      case auth0Env.kalamazoo_uat.domain:
        auth0ClientId = auth0Env.kalamazoo_uat.client_id;
        break;
      case auth0Env.winston_uat.domain:
        auth0ClientId = auth0Env.winston_uat.client_id;
        break;
      case auth0Env.lion_dev.domain:
        auth0ClientId = auth0Env.lion_dev.client_id;
        break;
      case auth0Env.lion_uat.domain:
        auth0ClientId = auth0Env.lion_uat.client_id;
        break;
      case auth0Env.minions_dev.domain:
        auth0ClientId = auth0Env.minions_dev.client_id;
        break;
      case auth0Env.minions_uat.domain:
        auth0ClientId = auth0Env.minions_uat.client_id;
        break;
      case auth0Env.pikachu_dev.domain:
        auth0ClientId = auth0Env.pikachu_dev.client_id;
        break;
      case auth0Env.pikachu_uat.domain:
        auth0ClientId = auth0Env.pikachu_uat.client_id;
        break;
      case auth0Env.platform_dev.domain:
        auth0ClientId = auth0Env.platform_dev.client_id;
        break;
      case auth0Env.platform_uat.domain:
        auth0ClientId = auth0Env.platform_uat.client_id;
        break;
      case auth0Env.platform_dev_v2.domain:
        auth0ClientId = auth0Env.platform_dev_v2.client_id;
        break;
      case auth0Env.platform_uat_v2.domain:
        auth0ClientId = auth0Env.platform_uat_v2.client_id;
        break;
      case auth0Env.pre_dev.domain:
        auth0ClientId = auth0Env.pre_dev.client_id;
        break;
      case auth0Env.pre_uat.domain:
        auth0ClientId = auth0Env.pre_uat.client_id;
        break;
      case auth0Env.rabbit_dev.domain:
        auth0ClientId = auth0Env.rabbit_dev.client_id;
        break;
      case auth0Env.rabbit_uat.domain:
        auth0ClientId = auth0Env.rabbit_uat.client_id;
        break;
      case auth0Env.staging_dev.domain:
        auth0ClientId = auth0Env.staging_dev.client_id;
        break;
      case auth0Env.staging_uat.domain:
        auth0ClientId = auth0Env.staging_uat.client_id;
        break;
      case auth0Env.dm_dev.domain:
        auth0ClientId = auth0Env.dm_dev.client_id;
        break;
      case auth0Env.dm_uat.domain:
        auth0ClientId = auth0Env.dm_uat.client_id;
        break;
      case auth0Env.security_check.domain:
        auth0ClientId = auth0Env.security_check.client_id;
        break;
      case auth0Env.security_dev.domain:
        auth0ClientId = auth0Env.security_dev.client_id;
        break;

      default:
        auth0ClientId = auth0Env.stable.client_id;
        break;
    }

    return auth0ClientId;
  };
