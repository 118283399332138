<div class="member-attachment-modal">
  <div class="title">
    <div class="title-name">
    {{data?.attachment?.id ? 'Edit' : 'Add New'}} Document
    </div>

</div>
<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div class="wrap-btn">
    <button mat-raised-button type="submit" style="background-color: #408bf9;color: #fff; margin-right: 8px;">Save</button>
    <button mat-raised-button type="button" style="background-color: #acabab;color: #fff" (click)="onCancel()">Cancel</button>
  </div>
  <div class="item">
    <ptg-textbox
      [controlField]="formGroup.get('documentName')"
      placeholder="Document Name"
      errorRequire="Document Name is required."
      [maxLength]="100"
      [hasLabel]="true"
      errorMaxLength="Exceed the 100 characters limit.">
    </ptg-textbox>
    <ptg-input-area class="description" [ngClass]="{'isEdit': data?.attachment?.id}"
      [controlField]="formGroup.get('description')"
      placeholder="Description"
      [maxLength]="255"
      errorMaxLength="Exceed the 255 characters limit."
    ></ptg-input-area>
    <ptg-upload
      *ngIf="!data?.attachment?.id"
      #fileDocument
      (onUploadAction)="uploadFile($event)"
      (deleteFile)="removeFile($event)"
      [checkPattern]="checkPatternDocument"
      [errMsg]="'Unsupported file name or file format.'"
      [typeFile]="typeFileDocument"
      [noUpload]="true"
      [isRequired]="true"
      [accept]="acceptDocument"
      uploadButtonName="Upload Document"
      errRequired="Upload Document is required."
      [errDuplicated]="errDuplicated"
    ></ptg-upload>
    <span *ngIf="data?.attachment?.id" class="file-name">{{data?.attachment?.attachment}}

    </span>
  </div>
</form>
</div>
