import { createSelector } from '@ngrx/store';

import { selectCalculationModuleState } from './selector';

export const getEntityComponentListDataByComponentIdSelector = createSelector(
  selectCalculationModuleState,
  (state) => state?.getEntityComponentListDataByComponentId
);

export const getGetEntityComponentListDataByComponentIdsSelector = createSelector(
  getEntityComponentListDataByComponentIdSelector,
  (state) => state?.getGetEntityComponentListDataByComponentIds
);
