import { createAction, props } from '@ngrx/store';

import {
  BodyChangePropertyKey,
  MetadataSection,
  PropertyEditData,
  PropertyRequest,
} from '../../types/models';

export const MetadataSectionRequest = '[MetadataSection/API] Send Request';
export const MetadataSectionFailure = '[MetadataSection/API] Failure';
export const MetadataSectionSuccess = '[MetadataSection/API] Success';
export const MetadataPropertiesOrder =
  '[MetadataPropertiesOrder/API] Send Request';
export const MetadataSectionEditRequest =
  '[MetadataSectionEdit/API] Send Request';
export const MetadataSectionEditSuccess = '[MetadataSectionEdit/API] Success';
export const MetadataSectionEditFailure = '[MetadataSectionEdit/API] Failure';
export const AddPropertyRequest = '[AddProperty/API] Send Request';
export const AddPropertySuccess = '[AddProperty/API] Success';
export const AddPropertyFailure = '[AddProperty/API] Failure';
export const RemovePropertyMetadataRequest =
  '[RemovePropertyMetadata/API] Send Request';
export const RemovePropertyMetadataSuccess =
  '[RemovePropertyMetadata/API] Success';
export const RemovePropertyMetadataFailure =
  '[RemovePropertyMetadata/API] Failure';
export const MetadataClear = '[ClearMetadata] Clear';
export const EditPropertyDetailRequest =
  '[EditPropertyDetail/API] Send Request';
export const EditPropertyDetailSuccess = '[EditPropertyDetail/API] Success';
export const EditPropertyDetailFailure = '[EditPropertyDetail/API] Failure';

export const getMetadataSection = createAction(
  MetadataSectionRequest,
  props<{ key: string }>()
);
export const getMetadataSectionSuccess = createAction(
  MetadataSectionSuccess,
  props<{ metadataSection: MetadataSection }>()
);
export const getMetadataSectionFailure = createAction(
  MetadataSectionFailure,
  props<{ error?: any }>()
);
export const orderTable = createAction(
  MetadataPropertiesOrder,
  props<{ sectionKey: string; body: BodyChangePropertyKey }>()
);
export const editSectionMetadata = createAction(
  MetadataSectionEditRequest,
  props<{ bodyEdit: any }>()
);
export const editSectionMetadataSuccess = createAction(
  MetadataSectionEditSuccess,
  props<{ bodyEdit: any }>()
);
export const editSectionMetadataFailure = createAction(
  MetadataSectionEditFailure,
  props<{ errorMsg: string }>()
);

export const addProperty = createAction(
  AddPropertyRequest,
  props<{
    body: PropertyRequest;
    isContinue: boolean;
  }>()
);
export const addPropertySuccess = createAction(
  AddPropertySuccess,
  props<{ propertyName: string }>()
);
export const addPropertyFailure = createAction(
  AddPropertyFailure,
  props<{ errorMsg: string }>()
);
export const removePropertyMetadata = createAction(
  RemovePropertyMetadataRequest,
  props<{ propertyKey: string }>()
);
export const removePropertyMetadataSuccess = createAction(
  RemovePropertyMetadataSuccess
);
export const removePropertyMetaFailure = createAction(
  RemovePropertyMetadataFailure,
  props<{ errorMsg: string }>()
);
export const metadataClear = createAction(MetadataClear);
export const editPropertyDetail = createAction(
  EditPropertyDetailRequest,
  props<{ properties: PropertyEditData[] }>()
);
export const editPropertyDetailSuccess = createAction(
  EditPropertyDetailSuccess,
  props<{ properties: PropertyEditData[] }>()
);
export const editPropertyDetailFailure = createAction(
  EditPropertyDetailFailure,
  props<{ errorMsg: string }>()
);
