import { createSelector } from '@ngrx/store';

import { entityManagementModuleSelector } from './selector';

export const entityProfileConfigSelector = createSelector(
  entityManagementModuleSelector,
  (state) => state?.entityProfileConfig,
);

export const getEntityProfileConfigOverviewSelector = createSelector(
  entityProfileConfigSelector,
  (state) => state?.getEntityProfileConfigOverview,
);
