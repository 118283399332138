<div class="dialog-container" id="edit-participant-accident-status-dialog">
  <div class="header-title">Edit Status</div>
  <div *ngIf="bannerType" class="flex flex-col pt-6 pb-0 gap-6">
    <ptg-banner [(bannerType)]="bannerType" [message]="message"></ptg-banner>
  </div>
  <form [formGroup]="editForm" (ngSubmit)="formSubmit$.next()">
    <div class="form-container">
      <div class="input-container">
        <div class="input-group">
          <ptg-select
            [placeholder]="'Status'"
            [controlField]="editForm.get('status')"
            [listData]="listChangeStatus"
            [isOptionObj]="true"
            [isRequired]="true"
            class="select-accident-participant-status"
          ></ptg-select>
        </div>
        <ptg-input-area
          [controlField]="editForm.get('note')"
          [hasLabel]="true"
          class="input-description"
          placeholder="Note"
          [maxLength]="250"
          errorMaxLength="Exceed the 250 character limit."
        ></ptg-input-area>
      </div>
    </div>
    <div class="row-button">
      <button class="submit-button" mat-raised-button type="submit">Save</button>
      <button (click)="onCancel()" class="cancel-button" mat-raised-button type="button">Cancel</button>
    </div>
  </form>
</div>
