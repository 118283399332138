import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Sort } from '@angular/material/sort';

import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { SUBMODULE_KEY } from '@ptg-shared/constance/permission.const';
import { ACTION_COLUMN, Align, Column, Row } from '@ptg-shared/controls/grid';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ACTION, SORT_TYPE } from '@ptg-shared/constance';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { showBanner } from '@ptg-shared/utils/common.util';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { BaseComponent } from '@ptg-shared/components';
import { LayoutActions } from '@ptg-shared/layout/actions';

import { ProfileConfig } from '@ptg-member/types/models/profile-configuration.model';
import { MemberState } from '@ptg-member/store/reducers';
import { Header } from '@ptg-member/types/models';
import * as fromReducer from '@ptg-reducers';
import * as fromMember from '../../store/selectors';
import { clearProfileConfigState, getProfileConfigList, removeProfileAction } from '@ptg-member/store/actions/profile-configuration.actions';
import { AddProfileComponent } from '@ptg-member/components/add-profile/add-profile.component';

const PAGE_SIZE_CONST = '-ptg-profile-config-pagesize'

@Component({
  selector: 'ptg-profile-configuration',
  templateUrl: './profile-configuration.component.html',
  styleUrls: ['./profile-configuration.component.scss'],
})

export class ProfileListComponent extends BaseComponent {

  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Participant List',
      moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
      url: '/member',
    },
    {
      name: 'Profile List',
    },
  ];
  columns: Column[] = [
    {
      name: 'name',
      header: {
        title: 'Profile Name',
      },
      truncate: true,
      sortable: true,
      width: '15%',
    },
    {
      name: 'entityName',
      header: {
        title: 'Entity',
      },
      truncate: true,
      sortable: true,
      width: '10%',
    },
    {
      name: 'totalMenu',
      header: {
        title: 'Total Menus',
      },
      sortable: true,
      align: Align.Right,
      width: '10%',
    },
    {
      name: 'statusEvents',
      header: {
        title: 'Status Event',
      },
      width: '35%',
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      width: '10%',
    },
  ];

  sortInfo?: Sort;
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';

  profileConfigsData: (Header & Row)[] = [];
  isLoading: boolean = true;
  isLoadingRemove = false;

  currentFund: any = {};
  lengthPg: number | any;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  constructor(
    private memberStore: Store<MemberState>,
    public dialog: MatDialog,
    public router: Router,
    private store: Store<fromReducer.State>
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store.dispatch(LayoutActions.hiddenSideMenu());
    this.store
    .pipe(
      select(fromReducer.selectCurrentFundState),
      takeUntil(this.unsubscribe$)
    )
    .subscribe((el) => {
      this.pageSize = el.defaultPageSize ?? 50;
      this.currentFund = el;
      this.pageSize = Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)) === 0 ? this.pageSize : Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST));
      this.getData();
    });
    this.store.pipe(select(fromMember.getEntitiesProfileListSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.isLoading = !!data?.isLoading;
      this.profileConfigsData = data?.payload?.profileConfigs ?? [];
      this.lengthPg = data?.total ?? 0;
    });

    this.memberStore.pipe(select(fromMember.createProfileSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data?.success) {
        this.getData();
      }
    });

    this.memberStore.pipe(select(fromMember.removeProfileSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        this.isLoadingRemove = data?.isLoading;
      }
      if( data?.success ) {
        this.getData();
        showBanner.call(this, BannerType.Success, 'Profile', ACTION.REMOVE);
      } else if (data?.error) {
        showBanner.call(this, BannerType.Fail, 'Profile', ACTION.REMOVE);
      }
    });
  }

  ngOnDestroy(): void {
    this.store.dispatch(clearProfileConfigState());
  }

  getData(): void {
    let query = {
      sortField: '',
      sortType: 0,
      pageIndex: this.pageNumber,
      pageSize: this.pageSize,
    };

    if (this.sortInfo) {
      const field = this.sortInfo.active[0].toUpperCase() + this.sortInfo.active.substr(1);
      query = {
        ...query,
        sortField: this.sortInfo.direction ? field : '',
        sortType: this.sortInfo.direction === 'asc' ? SORT_TYPE.ASC : SORT_TYPE.DESC
      };
    }
    this.store.dispatch(getProfileConfigList({ query: query }));
  }
  sortChange(event: Sort) {
    this.sortInfo = event;
    this.getData();
  }

  editView(row?: ProfileConfig):void  {
    this.router.navigateByUrl(`/member/profiles/${row?.id}`);
  }

  onClickRemoveProfileConfig(row: any): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        title: 'Remove Item',
        text: 'Are you sure you want to remove this Profile?',
        type: ConfirmType.Delete,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.memberStore.dispatch(removeProfileAction({ id: row.id }));
      }
    });
  }

  openForm(): void {
    this.dialog.open(AddProfileComponent, {
      panelClass: 'edit-popup',
      disableClose: true,
      autoFocus: false,
      width: '856px',
      height: 'auto',
      data: {
        entityProfile: this.profileConfigsData
      }
    });
  }

  onChangePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    sessionStorage.setItem(this.currentFund.key + PAGE_SIZE_CONST, this.pageSize.toString());
    this.getData();
  }

  closeBanner() {
    this.message = '';
  }
}
