<div class="status-history-container">
  <div class="flex-grow overflow-hidden">
    <ptg-list-display-box
      [title]="cardTitle"
      pageName="Service History"
      [isLoading]="isLoading"
      [dataTable]="serviceHistoryData"
      [columns]="columns"
      [lengthPg]="lengthPg"
      [pageSize]="pageSize"
      [pageNumber]="pageNumber"
      [currentRowIndex]="currentRowIndex"
      (changePage)="changePage($event)"
      (selectRow)="selectRow($event)"
    ></ptg-list-display-box>
  </div>
</div>
