import { createSelector } from '@ngrx/store';

import { selectCalculationModuleState } from './selector';

export const removeOverviewDocumentSelector = createSelector(
  selectCalculationModuleState,
  (state) => state?.removeOverviewDocument,
);

export const removeRemoveOverviewDocumentSelector = createSelector(
  removeOverviewDocumentSelector,
  (state) => state?.removeRemoveOverviewDocument,
);
