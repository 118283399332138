export enum ConfirmType {
  Error,
  Confirm,
  Cancel,
  CancelPopup,
  Delete,
  Destruct,
  Warning,
  Attention,
  Success,
  ConfirmSave,
  ConfirmWithDontShowAgain
}

