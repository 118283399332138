import { createReducer, on } from '@ngrx/store';

import { MemberBenefitListActions } from '../actions';
import { MembersBenefitList, MembersBenefitType } from '../../types/models';

export const memberBenefitListFeatureKey = 'memberBenefitList';
export interface State {
  isLoading: boolean;
  memberBenefitList: MembersBenefitList;
  establishBenefit?: any;
  memberBenefitType: MembersBenefitType;
}

const initialState: State = {
  isLoading: true,
  memberBenefitList: {
    total: 0,
    membersBenefit: [],
  },
  memberBenefitType: {
    benefitType: [],
  },
  establishBenefit: {},
};

export const reducer = createReducer(
  initialState,
  on(MemberBenefitListActions.getMemberBenefitEligible, (state, {}) => ({
    ...state,
    isLoading: true,
    memberBenefitList: {
      total: 0,
      membersBenefit: [],
    },
    memberBenefitType: {
      benefitType: [],
    },
  })),
  on(
    MemberBenefitListActions.getMemberBenefitEligibleSuccess,
    (state, { memberBenefitList }) => ({
      ...state,
      isLoading: false,
      memberBenefitList,
    })
  ),
  on(
    MemberBenefitListActions.getMemberBenefitEligibleFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      memberBenefitList: {
        total: 0,
        membersBenefit: [],
      },
    })
  ),

  on(
    MemberBenefitListActions.establishMemberBenefitSuccess,
    (state, { memberId }) => ({
      ...state,
      establishBenefit: {
        state: 'Success',
        memberId,
      },
    })
  ),
  on(
    MemberBenefitListActions.establishMemberBenefitFailure,
    (state: State, { errorMsg }) => ({
      ...state,
      establishBenefit: {
        state: 'Fail',
      },
    })
  ),

  on(
    MemberBenefitListActions.getMemberBenefitTypeSuccess,
    (state, { memberBenefitType }) => ({
      ...state,
      isLoading: false,
      memberBenefitType,
    })
  ),
  on(
    MemberBenefitListActions.getMemberBenefitTypeFailure,
    (state, { errorMsg }) => ({
      ...state,
      isLoading: false,
      memberBenefitType: {
        benefitType: [],
      },
    })
  ),

  on(MemberBenefitListActions.clearMemberBenefitState, (state) => ({
    ...state,
    establishBenefit: {},
  }))
);
