import {
  Input,
  OnInit,
  Output,
  Component,
  OnChanges,
  QueryList,
  ViewChild,
  ElementRef,
  ViewChildren,
  EventEmitter,
  SimpleChanges,
  AfterViewInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BaseComponent } from '@ptg-shared/components';
import { MetadataSection } from '@ptg-member/types/models';
import { BodyContent, CardDescriptionActionItemType, CardDescriptionData } from './types/models';

@Component({
  selector: 'ptg-card-description',
  templateUrl: './card-description.component.html',
  styleUrls: ['./card-description.component.scss'],
})
export class CardDescriptionComponent extends BaseComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('contentBody') bodyEl!: ElementRef;
  @ViewChildren('contentEl') contentElList!: QueryList<ElementRef>;

  @Input() data!: CardDescriptionData;
  @Input() isCustomData?: boolean = false;

  @Output() actionEmitter = new EventEmitter<MetadataSection>();
  @Output() fieldClick: EventEmitter<BodyContent> = new EventEmitter();
  showMenu = false;

  constructor(public readonly route: ActivatedRoute) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.data) {
      const currentValue = changes.data.currentValue;

      this.showMenu =
        currentValue?.action?.showMenu ||
        (!currentValue?.action?.showMenu &&
          currentValue?.action?.list?.filter((item: CardDescriptionActionItemType) => !item.hidden)?.length > 1);
    }
  }

  ngOnInit(): void {}

  /**
   * Invoke the input callback function & notify to the parent component
   * @param callback The input callback function from an action
   */
  onActionClick(callback: any): void {
    callback();
    this.actionEmitter.emit();
  }

  onFieldClick(property: BodyContent) {
    this.fieldClick.emit(property);
  }
}
