<div class="dialog-container" id="edit-member-status-event-dialog">
  <div class="header-title">
    Edit Event
  </div>

  <form (ngSubmit)="formSubmit$.next()" [formGroup]="editForm" class="edit-form">
    <div class="input-container">
      <ptg-select [controlField]="editForm.get('statusId')"
                  [isOptionObj]="true"
                  [isRequired]="true"
                  [isSetMaxWidthSelect]="true"
                  [listData]="listStatus"
                  placeholder="Status"
      ></ptg-select>
      <ptg-select [controlField]="editForm.get('eventId')"
                  [isOptionObj]="true"
                  [isRequired]="true"
                  [listData]="listEvent"
                  [isSetMaxWidthSelect]="true"
                  placeholder="Event"
      ></ptg-select>
    </div>
    <div class="row-button">
      <button class="submit-button" mat-raised-button type="submit">Save</button>
      <button (click)="onCancel()" class="cancel-button" mat-raised-button type="button">Cancel</button>
    </div>
  </form>
</div>
