import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { uploadFileBlue } from '../../constance/listIcons.const';

@Component({
  selector: 'ptg-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent implements OnInit, OnChanges {
  active = new FormControl(false);

  @Input() title!: string;
  @Input() hasStatus?: boolean;
  @Input() activeValue?: boolean;
  @Input() isWelcome?: boolean;
  @Input() isSummary?: boolean;
  @Input() isDisabled?: boolean;
  @Input() isPublishable?: boolean;

  @Output() edit = new EventEmitter();
  @Output() publish = new EventEmitter();
  @Output() changeToggle = new EventEmitter();
  
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIconLiteral('upload-icon', sanitizer.bypassSecurityTrustHtml(uploadFileBlue));
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.active = new FormControl(this.activeValue);
  }

  onEdit() {
    this.edit.emit('');
  }

  onPublish() {
    this.publish.emit('');
  }

  setStatusView() {
    this.changeToggle.emit(!!this.active.value)
  }
}
