import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BaseComponent } from '@ptg-shared/components';
import { Align, Column, ColumnType } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';
import * as fromMember from '../../store/reducers';
import * as fromReducer from '@ptg-reducers';
import { takeUntil } from 'rxjs/operators';
import * as DropPlanActions from '../../store/actions/drop-plan.actions';
import { State } from '@ptg-member/store/reducers/drop-plan.reducer';
import { ParametersQuery } from '@ptg-employer/models';
import { DropPlan } from '@ptg-member/types/models/drop-plan.model';
import { MatDialog } from '@angular/material/dialog';
import { EditDropPlanComponent } from '@ptg-member/components/edit-drop-plan/edit-drop-plan.component';
import { DropPlanService } from '@ptg-member/services/drop-plan.service';
import { ACTION } from '@ptg-shared/constance';
import { showBanner } from '@ptg-shared/utils/common.util';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';

@Component({
  selector: 'ptg-drop-plan',
  templateUrl: './drop-plan.component.html',
  styleUrls: ['./drop-plan.component.scss']
})
export class DropPlanComponent extends BaseComponent {
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  sortInfo: {} | any = {};
  dataTable: any[] = [];
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'DROP Plan',
      url: '',
    },
  ];
  columns: Column[] = [
    {
      name: 'default',
      header: {
        title: '',
      },
      width: '72px',
    },
    {
      name: 'planYear',
      header: {
        title: 'Plan Year',
      },
      sortable: true,
      truncate: true,
      width: '270px',
    },
    
    {
      name: 'beginDate',
      header: {
        title: 'Begin Date',
      },
      type: ColumnType.DateTime,
      templateArgs: { format: 'MM/dd/yyyy' },
      sortable: true,
      truncate: true,
      width: '270px',
    },
    {
      name: 'endDate',
      header: {
        title: 'End Date',
      },
      type: ColumnType.DateTime,
      templateArgs: { format: 'MM/dd/yyyy' },
      sortable: true,
      truncate: true,
      width: '270px',
    },
    {
      name: 'annualInterestRate',
      header: {
        title: 'Annual Interest Rate',
      },
      truncate: true,
      sortable: true,
      align: Align.Right,
      type: ColumnType.Decimal,
      templateArgs: 
      { 
        decimal: 2,
        unit: '%', 
        unitPosition: 'right' 
      },
      width: '180px',
    },
    {
      name: 'monthInterestRate',
      header: {
        title: 'Monthly Interest Rate',
      },
      truncate: true,
      sortable: true,
      align: Align.Right,
      type: ColumnType.Decimal,
      templateArgs: 
      { 
        decimal: 2,
        unit: '%', 
        unitPosition: 'right' 
      },
      width: '270px',
    },
    {
      name: 'ACTION_COLUMN',
      header: {
        title: 'Action',
      },
      width: '270px',
    },
  ];
  isLoading: boolean = false;
  totalRecord: number = 0;
  currentRowIndex: number = 0;
  currentPageName: string = 'DROP Plan';
  currentFund: any;
  pageName = 'ptg-drop-plan';
  selectedDropPlan: DropPlan | undefined;
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  constructor(
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
    public dialog: MatDialog,
    private dropPlanService: DropPlanService
  ) {  super(); }

  ngOnInit(): void {
    this.store
    .pipe(
      select(fromReducer.selectCurrentFundState),
      takeUntil(this.unsubscribe$)
    )
    .subscribe((el) => {
      this.pageSize = el.defaultPageSize ?? 50;
      this.currentFund = el;
    });
    this.pageSize = Number(sessionStorage.getItem(this.currentFund.key + '-' + this.pageName + '-pageSize')) === 0?  this.pageSize: Number(sessionStorage.getItem(this.currentFund.key + '-' + this.pageName + '-pageSize'));
    this.getData();

    this.memberStore
      .pipe(
        select(fromMember.selectDropPlan),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state: State) => {
        this.dataTable = state.dropPlan.dropPlans;
        this.isLoading = state.isLoading;
        this.totalRecord = state.dropPlan.total;
        if (this.dataTable.length > 0) {
          this.currentRowIndex = 0;
          this.selectedDropPlan = this.dataTable[this.currentRowIndex];
        }
        if (state.updateState) {
          showBanner.call(
            this,
            state.updateState || '',
            `DROP Plan`,
            this.dropPlanService.dropPlanId ? ACTION.EDIT : ACTION.ADD
          );
          this.getData();
          this.memberStore.dispatch(
            DropPlanActions.clearDropPlanState()
          );
          this.dropPlanService.dropPlanId = '';
        }
    });
  }

  onChangePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    sessionStorage.setItem(this.currentFund.key + '-' + this.pageName + '-pageSize', this.pageSize.toString());
    sessionStorage.setItem(this.currentFund.key+ '-' + this.pageName + '-pageNumber', this.pageNumber.toString()); 
    this.getData();
  }

  getData() {
    let sortType = 0;
    let sortNames = ['BeginDate'];
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = [capitalizeFirstLetter(this.sortInfo.active)];
      sortType = this.sortInfo.direction === 'desc' ? 1 : 0;
    }
    const query: ParametersQuery = {
      pageIndex: this.pageNumber,
      pageSize: this.pageSize,
      sortNames: sortNames,
      sortType: sortType,
    };
    this.memberStore.dispatch(
      DropPlanActions.getDropPlan({ query: query })
    );
  }

  onUpdate(data?: DropPlan) {
    this.dropPlanService.dropPlanId = data?.id ? data?.id : '';
    this.dialog.open(EditDropPlanComponent, {
      panelClass: ['dialog-full-screen'],
      autoFocus: false,
      disableClose: true,
      data: {
        ...data,
      },
    });
  }

  onChangeSort(event: any) {
    this.sortInfo = event;
    this.getData();
  }

  selectRow(event: any) {
    this.selectedDropPlan = event.row;
    this.currentRowIndex = this.dataTable.findIndex(
      (item) => item.id === event?.row?.id
    );
  }
}
