import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { getDateString } from '@ptg-shared/utils/string.util';

import { MemberDetailService } from '../../services/member-detail.service';
import { OffCyclePayment } from '../../types/models';
import { OffCyclePaymentStatusType } from '../../constance/next-payment.const';
import * as fromNextPayment from '../../store/reducers';
import * as NextPaymentActions from '../../store/actions/next-payment.actions';
import { PaymentEditPopupComponent } from '../../components/payment-edit/payment-edit-popup.component';

@Component({
  selector: 'ptg-payment-detail',
  templateUrl: './payment-detail.component.html',
  styleUrls: ['./payment-detail.component.scss'],
})
export class PaymentDetailComponent implements OnInit, OnDestroy, OnChanges {
  readonly OffCyclePaymentStatusTypeEnum = OffCyclePaymentStatusType;

  dataSource = [];
  netPayment = 0;
  displayedColumns = [
    'order',
    'paymentType',
    'percentage',
    'amount',
    'paymentContent?.account',
  ];
  offCyclePayment?: OffCyclePayment;

  unsubscribe$ = new Subject<void>();

  @Input() isHistory?: boolean;
  @Input() benefitId?: string;
  @Output() changeBannerEvent = new EventEmitter();

  constructor(
    private store: Store<fromNextPayment.MemberState>,
    public dialog: MatDialog,
    private memberDetailService: MemberDetailService
  ) {}

  ngOnInit(): void {
    this.store
      .select(fromNextPayment.selectNextPayment)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data) this.offCyclePayment = data.offCyclePayment;
      });

    this.store
      .select(fromNextPayment.selectNextPaymentPayment)
      .subscribe((el) => {
        this.dataSource = el?.map((item: any, index: number) => ({
          ...item,
          index: index,
          postedTime: getDateString(item?.postedTime),
        }));
      });

    this.store
      .select(fromNextPayment.selectNextPaymentEarnings)
      .subscribe((el) => {
        this.netPayment = el?.netPayment;
      });
  }

  ngOnChanges(): void {
    if (this.isHistory) {
      this.displayedColumns = [
        'order',
        'transactionId',
        'postedTime',
        'paymentType',
        'percentage',
        'amount',
        'paymentContent?.account',
      ];
    } else {
      this.displayedColumns = [
        'order',
        'paymentType',
        'percentage',
        'amount',
        'paymentContent?.account',
      ];
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onClickEdit(): void {
    let dialogRef = this.dialog.open(PaymentEditPopupComponent, {
      panelClass: 'payment-edit-popup',
      data: {
        netPayment: this.netPayment,
        payments: this.dataSource,
        offCyclePayment: this.offCyclePayment,
        benefitId: this.benefitId
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.changeBannerEvent.emit();
      if (result)
        this.store.dispatch(
          NextPaymentActions.updatePayment({
            memberId: this.memberDetailService.memberId,
            body: result,
            targetId: this.offCyclePayment?.id,
          })
        );
    });
  }
}
