import { Injectable, Input } from '@angular/core';

import { Row } from '@ptg-shared/controls/grid';

// This class is open to common implementation
// Please discuss with your team before implementing
@Injectable()
export abstract class BaseGridTemplateComponent<T extends Row> {
  public static genericInputs: string[] = ['row'];

  @Input() row?: T;
}
