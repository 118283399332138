import { Component, Input } from '@angular/core';

@Component({
  selector: 'ptg-phone-column',
  templateUrl: './phone-column.component.html',
  styleUrls: ['./phone-column.component.scss'],
})
export class PhoneColumnComponent {
  @Input() value!: number;
  @Input() emptyValueDisplay!: string;
}
