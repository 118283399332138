import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import {
  RetirementBenefitDocument,
  GetRetirementBenefitHistoriesResponse,
  GetValidateBeforeRemovingDisabilityBenefitsResponse,
} from '../../services/models/retirement-benefit-history.model';
import {
  clearGetRetirementBenefitHistoriesStateAction,
  getRetirementBenefitHistoriesAction,
  getRetirementBenefitHistoriesFailureAction,
  getRetirementBenefitHistoriesSuccessAction,
  getRetirementBenefitDocumentsAction,
  getRetirementBenefitDocumentsSuccessAction,
  getRetirementBenefitDocumentsFailureAction,
  clearGetRetirementBenefitDocumentsStateAction,
  getRetirementBenefitDownloadDocumentAction,
  getRetirementBenefitDownloadDocumentSuccessAction,
  getRetirementBenefitDownloadDocumentFailureAction,
  clearGetRetirementBenefitDownloadDocumentStateAction,
  removeCalculationBenefitAction,
  removeCalculationBenefitSuccessAction,
  removeCalculationBenefitFailureAction,
  initiateSurvivorAction,
  initiateSurvivorSuccessAction,
  initiateSurvivorFailureAction,
  clearInitiateSurvivorStateAction,
  clearRemoveCalculationBenefitAction,
  clearGetValidateBeforeRemovingDisabilityBenefitsStateAction,
  getValidateBeforeRemovingDisabilityBenefitsAction,
  getValidateBeforeRemovingDisabilityBenefitsFailureAction,
  getValidateBeforeRemovingDisabilityBenefitsSuccessAction,
  clearRemoveDisabilityBenefitHistoriesStateAction,
  removeDisabilityBenefitHistoriesAction,
  removeDisabilityBenefitHistoriesFailureAction,
  removeDisabilityBenefitHistoriesSuccessAction,
} from '../actions/retirement-benefit-history.action';
import { ACTION, ActionState, STATE } from '@ptg-shared/constance';

export interface RetirementBenefitState {
  getRetirementBenefitHistories?: BaseActionState<GetRetirementBenefitHistoriesResponse>;
  removeCalculationBenefit?: ActionState;
  getRetirementBenefitDocuments?: BaseActionState<RetirementBenefitDocument[]>;
  getRetirementBenefitDownloadDocument?: BaseActionState<Blob[]>;
  initiateSurvivor?: BaseActionState<any>;
  getValidateBeforeRemovingDisabilityBenefits?: BaseActionState<GetValidateBeforeRemovingDisabilityBenefitsResponse>;
  removeDisabilityBenefitHistories?: ActionState;
}

const initialState: RetirementBenefitState = {};

export const retirementBenefitReducer = createReducer(
  initialState,
  on(getRetirementBenefitHistoriesAction, (state) => ({
    ...state,
    getRetirementBenefitHistories: {
      isLoading: true,
    },
  })),
  on(getRetirementBenefitHistoriesSuccessAction, (state, { response }) => ({
    ...state,
    getRetirementBenefitHistories: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getRetirementBenefitHistoriesFailureAction, (state, { error }) => ({
    ...state,
    getRetirementBenefitHistories: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetRetirementBenefitHistoriesStateAction, (state) => ({
    ...state,
    getRetirementBenefitHistories: undefined,
  })),

  on(removeCalculationBenefitAction, (state) => ({
    ...state,
    removeCalculationBenefit: undefined,
  })),
  on(removeCalculationBenefitSuccessAction, (state, {}) => ({
    ...state,
    removeCalculationBenefit: {
      state: STATE.SUCCESS,
      action: ACTION.REMOVE,
    },
  })),
  on(removeCalculationBenefitFailureAction, (state, {}) => {
    return {
      ...state,
      removeCalculationBenefit: {
        state: STATE.FAIL,
        action: ACTION.REMOVE,
      },
    };
  }),
  on(clearRemoveCalculationBenefitAction, (state) => ({
    ...state,
    removeCalculationBenefit: undefined,
  })),

  on(getRetirementBenefitDocumentsAction, (state) => ({
    ...state,
    getRetirementBenefitDocuments: {
      isLoading: true,
    },
  })),
  on(getRetirementBenefitDocumentsSuccessAction, (state, { response }) => ({
    ...state,
    getRetirementBenefitDocuments: {
      isLoading: false,
      success: true,
      payload: response?.benefitDocuments,
      total: response?.total,
    },
  })),
  on(getRetirementBenefitDocumentsFailureAction, (state, { error }) => ({
    ...state,
    getRetirementBenefitDocuments: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetRetirementBenefitDocumentsStateAction, (state) => ({
    ...state,
    getRetirementBenefitDocuments: undefined,
  })),
  on(getRetirementBenefitDownloadDocumentAction, (state) => ({
    ...state,
    getRetirementBenefitDownloadDocument: {
      isLoading: true,
    },
  })),
  on(getRetirementBenefitDownloadDocumentSuccessAction, (state, { response }) => ({
    ...state,
    getRetirementBenefitDownloadDocument: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getRetirementBenefitDownloadDocumentFailureAction, (state, { error }) => ({
    ...state,
    getRetirementBenefitDownloadDocument: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetRetirementBenefitDownloadDocumentStateAction, (state) => ({
    ...state,
    getRetirementBenefitDownloadDocument: undefined,
  })),

  on(initiateSurvivorAction, (state) => ({
    ...state,
    initiateSurvivor: {
      isLoading: true,
    },
  })),
  on(initiateSurvivorSuccessAction, (state, { retirementBenefitId, calculationType, benefitEntityId }) => ({
    ...state,
    initiateSurvivor: {
      isLoading: false,
      success: true,
      payload: {
        retirementBenefitId,
        calculationType,
        benefitEntityId,
      },
    },
  })),
  on(initiateSurvivorFailureAction, (state, { error }) => ({
    ...state,
    initiateSurvivor: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearInitiateSurvivorStateAction, (state) => ({
    ...state,
    initiateSurvivor: undefined,
  })),

  on(getValidateBeforeRemovingDisabilityBenefitsAction, (state) => ({
    ...state,
    getValidateBeforeRemovingDisabilityBenefits: {
      isLoading: true,
    },
  })),
  on(getValidateBeforeRemovingDisabilityBenefitsSuccessAction, (state, { response }) => ({
    ...state,
    getValidateBeforeRemovingDisabilityBenefits: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getValidateBeforeRemovingDisabilityBenefitsFailureAction, (state, { error }) => ({
    ...state,
    getValidateBeforeRemovingDisabilityBenefits: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetValidateBeforeRemovingDisabilityBenefitsStateAction, (state) => ({
    ...state,
    getValidateBeforeRemovingDisabilityBenefits: undefined,
  })),

  on(removeDisabilityBenefitHistoriesAction, (state) => ({
    ...state,
    removeDisabilityBenefitHistories: undefined,
  })),
  on(removeDisabilityBenefitHistoriesSuccessAction, (state) => ({
    ...state,
    removeDisabilityBenefitHistories: {
      state: STATE.SUCCESS,
      action: ACTION.REMOVE,
    },
  })),
  on(removeDisabilityBenefitHistoriesFailureAction, (state, { error }) => ({
    ...state,
    removeDisabilityBenefitHistories: {
      state: STATE.FAIL,
      action: ACTION.REMOVE,
    },
  })),
  on(clearRemoveDisabilityBenefitHistoriesStateAction, (state) => ({
    ...state,
    removeDisabilityBenefitHistories: undefined,
  })),
);
