import { createReducer, on } from '@ngrx/store';
import { Header, HeaderDetailResponse, HeaderPropertiesResponse } from '@ptg-member/types/models';
import { BaseActionState } from '@ptg-shared/types/models';
import { DistributionMappingAction } from '../actions';
import { DistributionDetail } from '@ptg-member/types/models/distribution-mapping.model';

export interface State {
  getDataList?: BaseActionState<DistributionDetail[]>;
  updateMapping?: BaseActionState<any[]>;
}

export const distributionMappingKey = 'distributionMapping';

const initialState: State = {
  getDataList: {
    isLoading: true,
    success: true,
    payload: []
  }
};

export const reducer = createReducer(
  initialState,
  on(DistributionMappingAction.clearHeaderDetailState, (state) => ({
    ...state,
    updateMapping: undefined
  })),
  on(DistributionMappingAction.getDataList, () => ({
    ...initialState
  })),
  on(DistributionMappingAction.getDataListSuccess, (state, { dataList }) => ({
    ...state,
    getDataList: {
      isLoading: false,
      success: true,
      payload: dataList
    }
  })),
  on(DistributionMappingAction.getDataListFailure, (state, { error }) => ({
    ...state,
    getDataList: {
      isLoading: false,
      success: false,
      payload: []
    }
  })),
  on(DistributionMappingAction.updateMappingSuccess, (state) => ({
    ...state,
    updateMapping: {
      isLoading: false,
      success: true,
    }
  })),
  on(DistributionMappingAction.updateMappingFailure, (state, { error }) => ({
    ...state,
    updateMapping: {
      isLoading: false,
      success: false,
      error: error
    }
  })),
);
