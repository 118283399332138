import { Pipe, PipeTransform } from '@angular/core';
import { ItemBenefitEntity } from '@ptg-entity-management/services/models';

@Pipe({
  name: 'parentBenefitEntityName'
})
export class ParentBenefitEntityNamePipe implements PipeTransform {

  transform(value: ItemBenefitEntity[] ): string {
    if (!value?.length) {
      return '-';
    } 
    return `${value.map((item) => `${item.name}`).join(', ')}`;
  }

}
