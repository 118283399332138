import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as DropPlanActions from '../../store/actions/drop-plan.actions';

import { DropPlanService } from '@ptg-member/services/drop-plan.service';
import { GetDropPlanResponse } from '@ptg-member/types/models/drop-plan.model';

@Injectable()
export class DropPlanEffects {
  constructor(
    private actions$: Actions,
    private dropPlanService: DropPlanService
  ) {}

  getDropPlan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DropPlanActions.GetDropPlanRequestAction),
      switchMap(({ query }) => {
        return this.dropPlanService.getDropPlan(query).pipe(
          map((dropPlan: GetDropPlanResponse) => {
            return DropPlanActions.getDropPlanSuccess({
              dropPlan,
            });
          }),
          catchError((err) => {
            return of(
              DropPlanActions.getDropPlanFailure({
                error: err.message,
              })
            );
          })
        );
      })
    )
  );
  setTierConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DropPlanActions.SetDropPlanRequestAction),
      switchMap(({ body }) => {
        return this.dropPlanService.setDropPlan(body).pipe(
          map(() => {
            return DropPlanActions.setDropPlanSuccess();
          }),
          catchError((err) => {
            return of(
              DropPlanActions.setDropPlanFailure({
                error: err.message,
              })
            );
          })
        );
      })
    )
  );    
}
