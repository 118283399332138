import { createReducer, on } from '@ngrx/store';

import { STATE } from '@ptg-shared/constance/value.const';

import { UPLOAD_STATUS } from '../../constance/lookupTable.const';
import * as LookupTableActions from '../actions/lookup-table.actions';
import {
  DisplayFormatRes,
  ImportSessionDetail,
  LookupTable,
  LookupTableDetail,
} from '../../types/models';

export const lookupTableFeatureKey = 'lookupTable';

export interface State {
  isLoading: boolean;
  lookupTableList: LookupTable[];
  createState: string;
  updateState: string;
  lookupTableDetailState: {
    isLoading: boolean;
    lookupTableDetail: LookupTableDetail;
    createState?: string;
    updateState?: string;
    removeState?: string;
    error?: any;
  };
  importState: {
    sessionDetail: ImportSessionDetail;
  };
  error?: any;
  metadataLookupTableDisplayFormats?: DisplayFormatRes;
  createDisplayFormatState: string;
  editDisplayFormatState: string;
}

const initialState: State = {
  isLoading: true,
  lookupTableList: [],
  createState: '',
  updateState: '',
  lookupTableDetailState: {
    isLoading: false,
    lookupTableDetail: {} as LookupTableDetail,
    createState: '',
    updateState: '',
    removeState: '',
    error: undefined,
  },
  importState: {
    sessionDetail: {} as ImportSessionDetail,
  },
  error: undefined,
  metadataLookupTableDisplayFormats: {} as DisplayFormatRes,
  createDisplayFormatState: '',
  editDisplayFormatState: ''
};

export const reducer = createReducer(
  initialState,
  on(LookupTableActions.clearLookupTableState, (state, {}) => ({
    ...state,
    createState: '',
    updateState: '',
    lookupTableDetailState: {
      ...state.lookupTableDetailState,
      createState: '',
      updateState: '',
      removeState: '',
      error: undefined,
    },
    importState: {
      sessionDetail: {} as ImportSessionDetail,
    },
    metadataLookupTableDisplayFormats: {} as DisplayFormatRes,
    createDisplayFormatState: '',
    editDisplayFormatState: ''
  })),
  on(LookupTableActions.getLookupTableList, (state, {}) => ({
    ...state,
    isLoading: true,
    lookupTableList: [],
    error: undefined,
  })),
  on(
    LookupTableActions.getLookupTableListSuccess,
    (state, { lookupTableList }) => ({
      ...state,
      isLoading: false,
      lookupTableList,
      error: undefined,
    })
  ),
  on(LookupTableActions.getLookupTableListFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    lookupTableList: [],
    error: error,
  })),
  on(LookupTableActions.getLookupTableDetail, (state, {}) => ({
    ...state,
    lookupTableDetailState: {
      isLoading: true,
      lookupTableDetail: {} as LookupTableDetail,
      error: undefined,
    },
  })),
  on(
    LookupTableActions.getLookupTableDetailSuccess,
    (state, { lookupTableDetail }) => ({
      ...state,
      lookupTableDetailState: {
        isLoading: false,
        lookupTableDetail,
        error: undefined,
      },
    })
  ),
  on(LookupTableActions.getLookupTableDetailFailure, (state, { error }) => ({
    ...state,
    lookupTableDetailState: {
      isLoading: false,
      lookupTableDetail: state.lookupTableDetailState.lookupTableDetail,
      error: error,
    },
  })),
  on(LookupTableActions.createLookupTableSuccess, (state) => ({
    ...state,
    createState: STATE.SUCCESS,
  })),
  on(LookupTableActions.createLookupTableFailure, (state, { errorMsg }) => ({
    ...state,
    createState: STATE.FAIL,
  })),
  on(LookupTableActions.updateLookupTableSuccess, (state) => ({
    ...state,
    updateState: STATE.SUCCESS,
  })),
  on(LookupTableActions.updateLookupTableFailure, (state, { errorMsg }) => ({
    ...state,
    updateState: STATE.FAIL,
  })),
  on(LookupTableActions.createLookupTableValueSuccess, (state) => ({
    ...state,
    lookupTableDetailState: {
      ...state.lookupTableDetailState,
      createState: STATE.SUCCESS,
      error: undefined,
    },
  })),
  on(LookupTableActions.createLookupTableValueFailure, (state, { error }) => ({
    ...state,
    lookupTableDetailState: {
      ...state.lookupTableDetailState,
      createState: STATE.FAIL,
      error: error,
    },
  })),
  on(LookupTableActions.updateLookupTableValueSuccess, (state) => ({
    ...state,
    lookupTableDetailState: {
      ...state.lookupTableDetailState,
      updateState: STATE.SUCCESS,
      error: undefined,
    },
  })),
  on(LookupTableActions.updateLookupTableValueFailure, (state, { error }) => ({
    ...state,
    lookupTableDetailState: {
      ...state.lookupTableDetailState,
      updateState: STATE.FAIL,
      error: error,
    },
  })),
  on(LookupTableActions.removeLookupTableValueSuccess, (state) => ({
    ...state,
    lookupTableDetailState: {
      ...state.lookupTableDetailState,
      removeState: STATE.SUCCESS,
      error: undefined,
    },
  })),
  on(LookupTableActions.removeLookupTableValueFailure, (state, { error }) => ({
    ...state,
    lookupTableDetailState: {
      ...state.lookupTableDetailState,
      removeState: STATE.FAIL,
      error: error,
    },
  })),
  on(
    LookupTableActions.getImportSessionDetailSuccess,
    (state, { sessionDetail }) => ({
      ...state,
      importState: {
        sessionDetail,
      },
    })
  ),
  on(
    LookupTableActions.getImportSessionDetailFailure,
    (state, { errorMsg }) => ({
      ...state,
    })
  ),
  on(LookupTableActions.cancelImportSuccess, (state) => ({
    ...state,
    importState: {
      sessionDetail: {
        ...state.importState.sessionDetail,
        status: UPLOAD_STATUS.CANCELED,
      },
    },
  })),
  on(LookupTableActions.getDisplayFormatListSuccess,
    (state, { metadataLookupTableDisplayFormats }) => ({
      ...state,
      isLoading: false,
      metadataLookupTableDisplayFormats: metadataLookupTableDisplayFormats,
    })
  ),
  on(LookupTableActions.getDisplayFormatListFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
    })
  ),
  on(LookupTableActions.createDisplayFormatSuccess, (state) => ({
    ...state,
    createDisplayFormatState: STATE.SUCCESS,
  })),
  on(LookupTableActions.createDisplayFormatFailure, (state, { errorMsg }) => ({
    ...state,
    createDisplayFormatState: STATE.FAIL,
  })),
  on(LookupTableActions.editDisplayFormatSuccess, (state) => ({
    ...state,
    editDisplayFormatState: STATE.SUCCESS,
  })),
  on(LookupTableActions.editDisplayFormatFailure, (state, { errorMsg }) => ({
    ...state,
    editDisplayFormatState: STATE.FAIL,
  })),
);
