import { Operator } from '@ptg-member/constance/member-list.const';
import { FilterInputType } from 'src/app/employer/models/employer-filter.model';

export enum FilterOperator {
  IsEmpty,
  IsNotEmpty,
  Equals,
  NotEquals,
  Contains,
  NotContains,
  GreaterThan,
  LessThan,
  Between,
  IsAny,
  IsCurrentStatus,
  WithinLastDay,
  AtAnyPoint,
}

export const EMPLOYER_OPERATOR_LABEL: Record<string, string> = {
  IsEmpty: 'has no value',
  IsNotEmpty: 'has a value',
  Equals: 'is',
  NotEquals: 'is not',
  Contains: 'does contain',
  NotContains: 'does not contain',
  GreaterThan: 'is or greater than',
  LessThan: 'is or less than',
  Between: 'is between',
  DateGreaterThan: 'is on or after',
  DateLessThan: 'is on or before',
  IsAny: 'is any',
  IsCurrentStatus: 'is current status',
  WithinLastDay: 'within last days',
  AtAnyPoint: 'at any point',
};

export const INPUT_TYPE: Record<number, FilterInputType> = {
  1: 'Text',
  2: 'Text',
  3: 'Number',
  4: 'Number',
  5: 'Number',
  6: 'Number',
  7: 'Date',
  9: 'Boolean',
  10: 'Number',
  11: 'Text',
  // Type 50 of `Municipality Type` or some types share the same value of 50
  // should be consider as a List (Boolean in here) (Dropdown), not Text (Textbox)
  50: 'Boolean',
  100: 'Text',
  101: 'Text',
  102: 'Text',
  8: 'DateTime',
  51: 'Text',
  52: 'Text',
  103: 'Number',
};

export const EMPLOYER_OPERATOR_BY_TYPE: Record<string, FilterOperator[]> = {
  Text: [
    FilterOperator.IsEmpty,
    FilterOperator.IsNotEmpty,
    FilterOperator.Equals,
    FilterOperator.NotEquals,
    FilterOperator.Contains,
    FilterOperator.NotContains,
  ],
  'Text - Account Type': [
    FilterOperator.Equals,
    FilterOperator.NotEquals
  ],
  'Text - Payment Method': [
    FilterOperator.Equals,
    FilterOperator.NotEquals
  ],
  'Text - Bank Name': [
    FilterOperator.Contains,
    FilterOperator.NotContains,
  ],
  'Whole Number': [
    FilterOperator.IsEmpty,
    FilterOperator.IsNotEmpty,
    FilterOperator.Equals,
    FilterOperator.NotEquals,
    FilterOperator.GreaterThan,
    FilterOperator.LessThan,
    FilterOperator.Between,
  ],
  Date: [
    FilterOperator.IsEmpty,
    FilterOperator.IsNotEmpty,
    FilterOperator.Equals,
    FilterOperator.NotEquals,
    FilterOperator.GreaterThan,
    FilterOperator.LessThan,
    FilterOperator.Between,
  ],
  Email: [
    FilterOperator.IsEmpty,
    FilterOperator.IsNotEmpty,
    FilterOperator.Equals,
    FilterOperator.NotEquals,
    FilterOperator.Contains,
    FilterOperator.NotContains,
  ],
  Phone: [
    FilterOperator.IsEmpty,
    FilterOperator.IsNotEmpty,
    FilterOperator.Equals,
    FilterOperator.NotEquals,
    FilterOperator.Contains,
    FilterOperator.NotContains,
  ],
  Binary: [
    FilterOperator.IsEmpty,
    FilterOperator.IsNotEmpty,
    FilterOperator.Equals
  ],
  Lookup: [
    FilterOperator.IsEmpty,
    FilterOperator.IsNotEmpty,
    FilterOperator.Equals,
    FilterOperator.NotEquals,
  ],
  'Address - Street 1': [
    FilterOperator.Equals,
    FilterOperator.NotEquals,
    FilterOperator.Contains,
    FilterOperator.NotContains,
  ],
  'Address - Street 2 (Apartment No./Suite No.)': [
    FilterOperator.IsEmpty,
    FilterOperator.IsNotEmpty,
    FilterOperator.Equals,
    FilterOperator.NotEquals,
    FilterOperator.Contains,
    FilterOperator.NotContains,
  ],
  'Address - City': [
    FilterOperator.Equals,
    FilterOperator.NotEquals,
    FilterOperator.Contains,
    FilterOperator.NotContains,
  ],
  'Address - State/Province/Territory': [FilterOperator.Equals, FilterOperator.NotEquals],
  'Address - Zip Code/Postal Code': [
    FilterOperator.Equals,
    FilterOperator.NotEquals,
    FilterOperator.Contains,
    FilterOperator.NotContains,
  ],
  'Address - Country': [
    FilterOperator.IsEmpty,
    FilterOperator.IsNotEmpty,
    FilterOperator.Equals,
    FilterOperator.NotEquals,
  ],
  'Person Name - Salutation': [
    FilterOperator.IsEmpty,
    FilterOperator.IsNotEmpty,
    FilterOperator.Equals,
    FilterOperator.NotEquals,
  ],
  'Person Name - First Name': [
    FilterOperator.Equals,
    FilterOperator.NotEquals,
    FilterOperator.Contains,
    FilterOperator.NotContains,
  ],
  'Person Name - Middle Name': [
    FilterOperator.IsEmpty,
    FilterOperator.IsNotEmpty,
    FilterOperator.Equals,
    FilterOperator.NotEquals,
    FilterOperator.Contains,
    FilterOperator.NotContains,
  ],
  'Person Name - Last Name': [
    FilterOperator.Equals,
    FilterOperator.NotEquals,
    FilterOperator.Contains,
    FilterOperator.NotContains,
  ],
  'Person Name - Suffix': [
    FilterOperator.IsEmpty,
    FilterOperator.IsNotEmpty,
    FilterOperator.Equals,
    FilterOperator.NotEquals,
    FilterOperator.Contains,
    FilterOperator.NotContains,
  ],
  Routing: [
    FilterOperator.Equals,
    FilterOperator.NotEquals,
    FilterOperator.Contains,
    FilterOperator.NotContains,
  ],
  Account: [
    FilterOperator.Equals,
    FilterOperator.NotEquals,
    FilterOperator.Contains,
    FilterOperator.NotContains,
  ],
  AccountType: [
    FilterOperator.Equals,
    FilterOperator.NotEquals,
  ],
  BankName: [
    FilterOperator.Contains,
    FilterOperator.NotContains,
  ],
  Count: [
    FilterOperator.Equals,
    FilterOperator.NotEquals,
    FilterOperator.GreaterThan,
    FilterOperator.LessThan,
    FilterOperator.Between,
  ],
  Boolean: [FilterOperator.Equals],
  List: [
    FilterOperator.IsEmpty,
    FilterOperator.IsNotEmpty,
    FilterOperator.Equals,
    FilterOperator.NotEquals,
  ],
  Aggregation: [
    FilterOperator.Equals,
    FilterOperator.NotEquals,
    FilterOperator.GreaterThan,
    FilterOperator.LessThan,
    FilterOperator.Between,
  ],
  Method: [
    FilterOperator.Equals,
    FilterOperator.NotEquals,
  ],
};

export enum FilterPropertyType {
  Text = 1,
  Email = 2,
  Phone = 3,
  'Whole Number' = 4,
  Currency = 5,
  Decimal = 6,
  Date = 7,
  DateTime = 8,
  Boolean = 9,
  Percentage = 10,
  SSN = 11,
  Register = 200,
  List = 50,
  Address = 100,
  'Person Name' = 101,
  Aggregation = 103,
}

export enum HeaderType {
  Property = 0,
  Aggregation = 1,
}

export const FIXED_SECTION_KEY = {
  CONTACT: 'fixed__employercontacts',
  PAYMENT_PREFERENCES: 'fixed__employerpaymentpreferences',
}

export enum LookupTableType {
  State = 1,
  Country = 2,
  None = 3,
  YesValue = 4,
  NoValue = 5,
}

export const DIRECT_DEPOSIT_PROPERTY_KEYS = ['Routing', 'Account', 'AccountType', 'BankName'];
