import { createSelector } from '@ngrx/store';

import { selectCalculationModuleState } from './selector';

export const retirementBenefitUploadDocumentSelector = createSelector(
  selectCalculationModuleState,
  (state) => state?.retirementBenefitUploadDocument
);

export const createRetirementBenefitUploadDocumentsSelector = createSelector(
  retirementBenefitUploadDocumentSelector,
  (state) => state?.createRetirementBenefitUploadDocuments
);

export const editRetirementBenefitDocumentsSelector = createSelector(
  retirementBenefitUploadDocumentSelector,
  (state) => state?.editRetirementBenefitDocumentState
);
