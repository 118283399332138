import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { MemberEntityService } from '../../services';
import { GetMemberEntityListComponentsResponse } from '../../services/models';
import {
  getMemberEntityListComponentsAction,
  getMemberEntityListComponentsFailureAction,
  getMemberEntityListComponentsSuccessAction,
} from '../actions';

@Injectable()
export class MemberEntityListComponentsEffects {
  constructor(
    private actions$: Actions,
    private memberEntityService: MemberEntityService
  ) {}

  getMemberEntityListComponents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMemberEntityListComponentsAction),
      switchMap(() =>
        this.memberEntityService.getMemberEntityListComponents().pipe(
          map((response: GetMemberEntityListComponentsResponse) => getMemberEntityListComponentsSuccessAction({ response })),
          catchError((error) => of(getMemberEntityListComponentsFailureAction({ error })))
        )
      )
    )
  );
}
