import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as fromReducer from '../../reducers/index';
import { BodyChangeIndex, Page } from '../models/navigation-page.models';
@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  activeFund: any;

  constructor(private httpClient: HttpClient, private store: Store<fromReducer.State>) { 
    this.store.pipe(select(fromReducer.selectCurrentFundState)).subscribe(el => this.activeFund = el);
  }

  getPageList() {
    let params = new HttpParams();
    params = params.append('clientId', this.activeFund?.id);
    return this.httpClient.get<Page>(`${environment.apiUrl}/AppContent/Navigations`, { params });
  }

  orderTable(body: BodyChangeIndex) {
    let params = {} as any;
    params.clientId = this.activeFund?.id;
    params.reorderItemId = body.reorderItemId;
    if (body.upperAdjacentId) {
      params.upperAdjacentId = body.upperAdjacentId;
    }
    return this.httpClient.put(`${environment.apiUrl}/AppContent/PageDefinitions/${body.reorderItemId}/Order`, params);
  }

  updatePageInfor(id: string, body: any) {
    return this.httpClient.put(`${environment.apiUrl}/AppContent/${id}/Navigation`, body);
  }
  
  checkExits = (bodyChange: any) => {
    let body = {navigation: bodyChange.navigation, pageDefinitionId: bodyChange.pageDefinitionId, clientId: this.activeFund?.id}
    return this.httpClient.post(`${environment.apiUrl}/AppContent/Navigation/Exists`, body);
  }  
  toggleFeature(id : string, active: boolean) : Observable<any> {   
    let body = {
      clientId: this.activeFund?.id, 
      active: active
    }
    return this.httpClient.put(`${environment.apiUrl}/AppContent/${id}/Status`,body);
  }
}
