import { Component, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { LookupTableService } from '@ptg-member/services/lookup-table.service';
import { BaseComponent } from '@ptg-shared/components';
import { DISCARD_CONFIRM_MESSAGE } from '@ptg-shared/constance';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { takeUntil } from 'rxjs/operators';
import * as fromMember from '../../store/reducers';
import * as LookupTableActions from '../../store/actions/lookup-table.actions';
import { DisplayFormatDetailForm, ExitsDisplayFormatRes } from '@ptg-member/types/models';
@Component({
  selector: 'ptg-edit-display-format',
  templateUrl: './edit-display-format.component.html',
  styleUrls: ['./edit-display-format.component.scss']
})
export class EditDisplayFormatComponent extends BaseComponent {
  editForm: FormGroup;
  constructor( 
    @Inject(MAT_DIALOG_DATA) public dataDetail: any,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditDisplayFormatComponent>,
    public dialog: MatDialog,
    private lookupTableService: LookupTableService,
    private memberStore: Store<fromMember.MemberState>,
  ) { 
    super();
    this.editForm = this.fb.group({
      metadataLookupTableDisplayFormats: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.initPage();
  }

  get metadataLookupTableDisplayFormats() {
    return this.editForm.controls['metadataLookupTableDisplayFormats'] as FormArray;
  }
  
  initPage() {
    this.addNewScreen();
  }

  submit() {
    this.validateForm();
    if (this.editForm.invalid) {
      return;
    }
    const { metadataLookupTableDisplayFormats } = this.editForm.value;

    const body = {
      lookupTableId: this.dataDetail.id,
      displayFormats: metadataLookupTableDisplayFormats
    };
    if(this.dataDetail?.data) {
      body.displayFormats = metadataLookupTableDisplayFormats.map((item: any) => {
        item.id = this.dataDetail.data.id;
        return item;
      });
    }
    this.lookupTableService.checkExitsDisplayFormats(body).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((res: ExitsDisplayFormatRes) => {
        if(res?.exists) {
          this.metadataLookupTableDisplayFormats.controls.forEach((item , index) => {
            const stepForm = item as FormGroup;
            if(res.displayFormats[index]?.isExistsId) {
              stepForm.get('screenId')?.setErrors({inValidAsync:true});
            }
            if(res.displayFormats[index]?.isExistsName) {
              stepForm.get('screenName')?.setErrors({inValidAsync:true});
            }
          });
        } else {
          this.updateDisplayFormats();
        }
    }, error => {
      this.updateDisplayFormats();
    });
  }
  
  validateForm() {
    this.metadataLookupTableDisplayFormats.controls.forEach((item) => {
      const stepForm = item as FormGroup;
      const { showDescription , showId, showLongDescription } = stepForm.value;
      const toggleField = stepForm?.get('showId');
      if(showDescription || showId || showLongDescription ) {
        toggleField?.setErrors(null);
        toggleField?.markAsPristine();
      } else {
        toggleField?.setErrors({ required: true });
        toggleField?.markAsDirty();
      }
      if(!this.dataDetail?.data) {
        this.checkDuplicate(item);
      }
    });
  }
  
  checkDuplicate(data: any) {
    const tableDisplayFormats = this.metadataLookupTableDisplayFormats.value;
    const stepForm = data as FormGroup;
    const { screenId, screenName } = stepForm.value;
    const duplicateScreenId = tableDisplayFormats.map((item: DisplayFormatDetailForm) => item.screenId.toLowerCase()).filter((item: DisplayFormatDetailForm, i: number, data: any) => data.indexOf(item) !== i);
    const duplicateScreenName = tableDisplayFormats.map((item: DisplayFormatDetailForm) => item.screenName.toLowerCase()).filter((item: DisplayFormatDetailForm, i: number, data: any) => data.indexOf(item) !== i);
    if(screenId) {
      if (duplicateScreenId?.length && duplicateScreenId.find((value: string) => value === screenId.toLowerCase())) {
        stepForm.get('screenId')?.setErrors({ inValidAsync: true });
      } else {
        stepForm.get('screenId')?.setErrors(null);
      }
    }
    if(screenName) { 
      if (duplicateScreenName?.length && duplicateScreenName.find((value: string) => value === screenName.toLowerCase())) {
        stepForm.get('screenName')?.setErrors({ inValidAsync: true });
      } else {
        stepForm.get('screenName')?.setErrors(null);
      }
    }
  }

  onMappingFieldChanged(value: boolean) {
    if(!value) return;
    this.metadataLookupTableDisplayFormats.controls.forEach((item) => {
      const stepForm = item as FormGroup;
      const { showDescription , showId, showLongDescription } = stepForm.value;
      const toggleField = stepForm?.get('showId');
      if(showDescription || showId || showLongDescription ) {
        toggleField?.setErrors(null);
        toggleField?.markAsPristine();
      }
    });
  }

  updateDisplayFormats() {
    const { metadataLookupTableDisplayFormats } = this.editForm.value;

    if(this.dataDetail?.data) {
      const body = {
        ...metadataLookupTableDisplayFormats[0],
        lookupTableId: this.dataDetail.id,
        id: this.dataDetail.data.id,
      };
      this.memberStore.dispatch(LookupTableActions.editDisplayFormatRequest({ body })); 
    } else {

      const body = {
        lookupTableId: this.dataDetail.id,
        displayFormats: metadataLookupTableDisplayFormats
      };
      this.memberStore.dispatch(LookupTableActions.createDisplayFormatRequest({ body }));
    }
    this.dialogRef.close(true);
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: DISCARD_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  addNewScreen() {
    const displayFormatForm = this.fb.group({
      screenId: [
        this.dataDetail?.data ? this.dataDetail.data.screenId : '',[Validators.required]
      ],
      screenName: [
        this.dataDetail?.data ? this.dataDetail.data.screenName : '',[Validators.required],
      ],
      showId: [this.dataDetail?.data ? this.dataDetail?.data.showId : false],
      showDescription: [this.dataDetail?.data ? this.dataDetail?.data.showDescription : true],
      showLongDescription: [this.dataDetail?.data ? this.dataDetail?.data.showLongDescription : false],
    });

    this.metadataLookupTableDisplayFormats.push(displayFormatForm);
  }

  deleteCondition(index: number) {
    this.metadataLookupTableDisplayFormats.removeAt(index);
  }
}
