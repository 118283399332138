import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, tap, switchMap, startWith, take } from 'rxjs/operators';

import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import * as fromReducer from '@ptg-reducers';

import { FilterInfo } from '../../types/models';
import { ProfileNavigationConfigurationService } from '../../services/profile-navigation-configuration.service';
import * as ProfileNavigationConfigurationActions from '../../store/actions/profile-navigation-configuration.action';

@Component({
  selector: 'ptg-add-menu',
  templateUrl: './add-menu.component.html',
  styleUrls: ['./add-menu.component.scss'],
})
export class AddMenuComponent implements OnInit {
  addForm: FormGroup = this.fb.group({
    name: this.fb.control('', {
      validators: [Validators.required],
    }),
  });
  formSubmit$ = new Subject<boolean>();
  unsubscribe$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FilterInfo[],
    public fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddMenuComponent>,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    public profileNavigationService: ProfileNavigationConfigurationService,
    private store: Store<fromReducer.State>
  ) {}

  ngOnInit(): void {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.addForm.markAllAsTouched();
        }),
        switchMap(() =>
          this.addForm.statusChanges.pipe(
            startWith(this.addForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter((status) => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  onSubmit() {
    this.addForm.markAllAsTouched();
    if (!this.addForm.valid) {
      return;
    }
    this.store.dispatch(
      ProfileNavigationConfigurationActions.createMemberNavigationRequest({
        menuName: this.addForm.value.name,
      })
    );
    this.dialogRef.close();
  }

  onCancel() {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
