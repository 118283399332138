<div class="payment-history-screen">
  <ptg-next-payment
    [ngStyle]="{'display': !isLoading && dataTable && dataTable.length > 0 ? 'initial' : 'none'}"
    [isHistory]="true"
  ></ptg-next-payment>

  <ng-container *ngIf="isLoading">
    <ptg-breadcrumb
      *ngIf="historyService.isBackNextPayroll"
      [listBreadcrumbs]="listBreadcrumbsHistory"
    ></ptg-breadcrumb>
    <ptg-overview-header></ptg-overview-header>
    <div class="spinner" *ngIf="true">
      <div class="wrap-item">
        <mat-progress-spinner diameter="50" mode="indeterminate">
        </mat-progress-spinner>
        <p>Loading...</p>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!isLoading && (!dataTable || dataTable?.length === 0)">
    <ptg-breadcrumb
      *ngIf="historyService.isBackNextPayroll"
      [listBreadcrumbs]="listBreadcrumbsHistory"
    ></ptg-breadcrumb>
    <ptg-overview-header></ptg-overview-header>
    <div class="data-not-found">
      No Data to Display
    </div>
  </ng-container>
</div>