import { createSelector } from '@ngrx/store';
import { selectFileModuleState } from './selector';

export const tagsSelector = createSelector(
  selectFileModuleState,
  (state) => state?.tags
);

export const getTagListSelector = createSelector(
  tagsSelector,
  (state) => state?.tagList
)

export const addTagListSelector = createSelector(
  tagsSelector,
  (state) => state?.tagDetailState
)

export const removeTagSelector = createSelector(
  tagsSelector,
  (state) => state?.removeTagState
)
