<div class="status-history-container">
  <div class="flex-grow overflow-hidden">
    <ptg-list-display-box
      title="Status History"
      pageName="Status"
      [isLoading]="isLoading"
      [dataTable]="statusHistorysData"
      [columns]="columns"
      [sortInfo]="sortInfo"
      [lengthPg]="lengthPg"
      [pageSize]="pageSize"
      [pageNumber]="pageNumber"
      [currentRowIndex]="currentRowIndex"
      (changePage)="changePage($event)"
      (sortChange)="sortChange($event)"
      (selectRow)="selectRow($event)"
      (addItem)="onEditMemberStatus(undefined)"
    ></ptg-list-display-box>
  </div>
</div>