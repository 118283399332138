import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { EntityService } from '../../services';
import {
  addList,
  addListFailure,
  addListSuccess,
  AddProperty,
  AddPropertyFailure,
  AddPropertySuccess,
  checkEntityCanRemoveFailure,
  checkEntityCanRemoveSuccess,
  checkEntityCanRemove,
  createEntityAction,
  createEntityFailureAction,
  createEntitySuccessAction,
  deleteEntityAction,
  deleteEntityFailureAction,
  deleteEntitySuccessAction,
  deleteProperty,
  deletePropertyFailure,
  deletePropertySuccess,
  getAllEntitiesAction,
  getAllEntitiesFailureAction,
  getAllEntitiesSuccessAction,
  getAllEntityPropertiesAction,
  getAllEntityPropertiesFailureAction,
  getAllEntityPropertiesSuccessAction,
  getEntitiesAction,
  getEntitiesFailureAction,
  getEntitiesSuccessAction,
  getEntityComponentListsAction,
  getEntityComponentListsFailureAction,
  getEntityComponentListsSuccessAction,
  getEntityDetail,
  getEntityDetailFailure,
  getEntityDetailSuccess,
  getEntityInitiationConfigurationAction,
  getEntityInitiationConfigurationFailureAction,
  getEntityInitiationConfigurationSuccessAction,
  getEntityListsAction,
  getEntityListsFailureAction,
  getEntityListsSuccessAction,
  getEntityPropertiesAction,
  getEntityPropertiesFailureAction,
  getEntityPropertiesForCalculation,
  getEntityPropertiesForCalculationFailure,
  getEntityPropertiesForCalculationSuccess,
  getEntityPropertiesSuccessAction,
  getEntityPropertyDetailAction,
  getEntityPropertyDetailFailureAction,
  getEntityPropertyDetailSuccessAction,
  getEntityReferencesAction,
  getEntityReferencesFailureAction,
  getEntityReferencesSuccessAction,
  getListDetail,
  getListDetailFailure,
  getListDetailSuccess,
  getPropertyTypes,
  getPropertyTypesFailure,
  getPropertyTypesSuccess,
  setEntityInitiationConfigurationAction,
  setEntityInitiationConfigurationFailureAction,
  setEntityInitiationConfigurationSuccessAction,
  setEntityPropertyAction,
  setEntityPropertyFailureAction,
  setEntityPropertySuccessAction,
  updateEntity,
  updateEntityFailure,
  updateEntitySuccess,
  updateListConfig,
  updateListConfigFailure,
  updateListConfigSuccess,
  updatePropertyItem,
  updatePropertyItemFailure,
  updatePropertyItemSuccess,
  getEntityListDataByIdAction,
  getEntityListDataByIdSuccessAction,
  getEntityListDataByIdFailureAction,
  deleteEntityListDataAction,
  deleteEntityListDataSuccessAction,
  deleteEntityListDataFailureAction,
  reOrderEntityListData,
  deleteEntityComponentSuccess,
  deleteEntityComponentFailure,
  deleteEntityComponent,
  getListBenefit,
  getListBenefitSuccess,
  getListBenefitFailure,
  updateBenefitMapping,
  updateBenefitMappingFailure,
  updateBenefitMappingSuccess,
} from '../actions';
import {
  CheckEntityCanRemoveResponse,
  GetEntitiesResponse,
  GetEntityComponentListsResponse,
  GetEntityDetailResponse,
  GetEntityInitiationConfigurationResponse,
  GetEntityListDataByIdResponse,
  GetEntityListsResponse,
  GetEntityPropertiesForCalculationResponse,
  GetEntityPropertiesResponse,
  GetEntityPropertyDetailResponse,
  GetEntityReferencesResponse,
  GetPropertyTypesResponse,
} from '../../services/models';

@Injectable()
export class EntityEffect {
  constructor(
    private actions$: Actions,
    private entityService: EntityService
  ) { }

  createEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createEntityAction),
      switchMap(({ request, isContinue }) =>
        this.entityService.createEntity(request).pipe(
          map((response: any) =>
            createEntitySuccessAction({ response, isContinue })
          ),
          catchError((error) => of(createEntityFailureAction({ error })))
        )
      )
    )
  );

  getEntities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEntitiesAction),
      switchMap(({ query }) =>
        this.entityService.getEntities(query).pipe(
          map((response: GetEntitiesResponse) =>
            getEntitiesSuccessAction({ response })
          ),
          catchError((error) => of(getEntitiesFailureAction({ error })))
        )
      )
    )
  );

  getAllEntities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllEntitiesAction),
      switchMap(({ query }) =>
        this.entityService.getEntities(query).pipe(
          map((response: GetEntitiesResponse) =>
            getAllEntitiesSuccessAction({ response })
          ),
          catchError((error) => of(getAllEntitiesFailureAction({ error })))
        )
      )
    )
  );

  getEntityDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEntityDetail),
      switchMap(({ request }) => {
        return this.entityService.getEntityDetail(request).pipe(
          map((response: GetEntityDetailResponse) => {
            return getEntityDetailSuccess({ response });
          }),
          catchError((error) => {
            return of(getEntityDetailFailure({ error }));
          })
        );
      })
    )
  );

  updateEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateEntity),
      switchMap(({ request }) => {
        return this.entityService.updateEntity(request).pipe(
          map(() => {
            return updateEntitySuccess();
          }),
          catchError((error) => {
            return of(updateEntityFailure({ error }));
          })
        );
      })
    )
  );

  addProperty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddProperty),
      switchMap(({ request, isContinue, entityComponentId }) => {
        return this.entityService
          .addProperty(request, entityComponentId)
          .pipe(
            map((response: any) => {
              return AddPropertySuccess({
                response: response,
                isContinue: isContinue,
                propertyType: request.type,
              });
            }),
            catchError((error) => {
              return of(AddPropertyFailure({ error }));
            })
          );
      })
    )
  );

  addList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addList),
      switchMap(({ request, isContinue, entityId }) => {
        return this.entityService.addList(request, entityId).pipe(
          map((response: any) => {
            return addListSuccess({
              response: response,
              isContinue: isContinue,
            });
          }),
          catchError((error) => {
            return of(addListFailure({ error }));
          })
        );
      })
    )
  );

  deleteProperty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteProperty),
      switchMap(({ request }) => {
        return this.entityService.deleteProperty(request).pipe(
          map(() => {
            return deletePropertySuccess({
              entityPropertyId: request.entityPropertyId || '',
              propertyName: request.propertyName,
            });
          }),
          catchError((error) => {
            return of(deletePropertyFailure({ error }));
          })
        );
      })
    )
  );

  deleteEntityComponent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteEntityComponent),
      switchMap(({ request }) => {
        return this.entityService.deleteEntityComponent(request.entityComponentId).pipe(
          map(() => {
            return deleteEntityComponentSuccess({
              propertyName: request.propertyName,
            });
          }),
          catchError((error) => {
            return of(deleteEntityComponentFailure({ error }));
          })
        );
      })
    )
  );

  getListDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getListDetail),
      switchMap(({ request }) => {
        return this.entityService.getListDetail(request).pipe(
          map((response: any) => {
            return getListDetailSuccess({ response });
          }),
          catchError((error) => {
            return of(getListDetailFailure({ error }));
          })
        );
      })
    )
  );

  updateListConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateListConfig),
      switchMap(({ request }) => {
        return this.entityService.updateListConfig(request).pipe(
          map(() => {
            return updateListConfigSuccess();
          }),
          catchError((error) => {
            return of(updateListConfigFailure({ error }));
          })
        );
      })
    )
  );

  getPropertyTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPropertyTypes),
      switchMap(({ request }) => {
        return this.entityService.getPropertyTypes(request).pipe(
          map((response: GetPropertyTypesResponse) => {
            return getPropertyTypesSuccess({ response });
          }),
          catchError((error) => {
            return of(getPropertyTypesFailure({ error }));
          })
        );
      })
    )
  );

  deleteEntity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteEntityAction),
      switchMap(({ id }) => {
        return this.entityService.deleteEntity(id).pipe(
          map(() => {
            return deleteEntitySuccessAction();
          }),
          catchError((error) => {
            return of(deleteEntityFailureAction({ error }));
          })
        );
      })
    )
  );

  updatePropertyItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePropertyItem),
      switchMap(({ request }) => {
        return this.entityService.updatePropertyItem(request).pipe(
          map(() => {
            return updatePropertyItemSuccess();
          }),
          catchError((error) => {
            return of(updatePropertyItemFailure({ error }));
          })
        );
      })
    )
  );

  getEntityProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEntityPropertiesAction),
      switchMap(({ request }) =>
        this.entityService.getEntityProperties(request).pipe(
          map((response: GetEntityPropertiesResponse) =>
            getEntityPropertiesSuccessAction({ response })
          ),
          catchError((error) => of(getEntityPropertiesFailureAction({ error })))
        )
      )
    )
  );

  getAllEntityProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllEntityPropertiesAction),
      switchMap(({ request }) =>
        this.entityService.getEntityProperties(request).pipe(
          map((response: GetEntityPropertiesResponse) =>
          getAllEntityPropertiesSuccessAction({ response })
          ),
          catchError((error) => of(getAllEntityPropertiesFailureAction({ error })))
        )
      )
    )
  );

  getEntityLists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEntityListsAction),
      switchMap(({ request }) =>
        this.entityService.getEntityLists(request).pipe(
          map((response: GetEntityListsResponse) =>
            getEntityListsSuccessAction({ response })
          ),
          catchError((error) => of(getEntityListsFailureAction({ error })))
        )
      )
    )
  );

  getEntityComponentLists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEntityComponentListsAction),
      switchMap(({ request }) =>
        this.entityService.getEntityComponentLists(request).pipe(
          map((response: GetEntityComponentListsResponse) =>
            getEntityComponentListsSuccessAction({ response })
          ),
          catchError((error) =>
            of(getEntityComponentListsFailureAction({ error }))
          )
        )
      )
    )
  );

  getEntityPropertyDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEntityPropertyDetailAction),
      switchMap(({ request }) =>
        this.entityService.getEntityPropertyDetail(request).pipe(
          map((response: GetEntityPropertyDetailResponse) =>
            getEntityPropertyDetailSuccessAction({ response })
          ),
          catchError((error) =>
            of(getEntityPropertyDetailFailureAction({ error }))
          )
        )
      )
    )
  );

  getEntityPropertiesForCalculation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEntityPropertiesForCalculation),
      switchMap(({ request }) =>
        this.entityService.getEntityPropertiesForCalculation(request).pipe(
          map((response: GetEntityPropertiesForCalculationResponse) =>
            getEntityPropertiesForCalculationSuccess({ response })
          ),
          catchError((error) =>
            of(getEntityPropertiesForCalculationFailure({ error }))
          )
        )
      )
    )
  );

  setEntityProperty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setEntityPropertyAction),
      switchMap(({ request }) =>
        this.entityService.setEntityProperty(request).pipe(
          map(() =>
            setEntityPropertySuccessAction({ propertyName: request.name })
          ),
          catchError((error) => of(setEntityPropertyFailureAction({ error })))
        )
      )
    )
  );

  getEntityReferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEntityReferencesAction),
      switchMap(({ request }) =>
        this.entityService.getEntityReferences(request).pipe(
          map((response: GetEntityReferencesResponse) =>
            getEntityReferencesSuccessAction({ response })
          ),
          catchError((error) => of(getEntityReferencesFailureAction({ error })))
        )
      )
    )
  );

  getEntityInitiationConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEntityInitiationConfigurationAction),
      switchMap(({ request }) =>
        this.entityService.getEntityInitiationConfiguration(request).pipe(
          map((response: GetEntityInitiationConfigurationResponse) =>
            getEntityInitiationConfigurationSuccessAction({ response })
          ),
          catchError((error) =>
            of(getEntityInitiationConfigurationFailureAction({ error }))
          )
        )
      )
    )
  );

  setEntityInitiationConfiguration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setEntityInitiationConfigurationAction),
      switchMap(({ request }) =>
        this.entityService.setEntityInitiationConfiguration(request).pipe(
          map(() => setEntityInitiationConfigurationSuccessAction()),
          catchError((error) =>
            of(setEntityInitiationConfigurationFailureAction({ error }))
          )
        )
      )
    )
  );

  checkEntityCanRemove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkEntityCanRemove),
      switchMap(({ id }) => {
        return this.entityService.checkEntityCanRemove(id).pipe(
          map((response: CheckEntityCanRemoveResponse) => {
            return checkEntityCanRemoveSuccess({response: response});
          }),
          catchError((error) =>
            of(checkEntityCanRemoveFailure({ error }))
          )
        )
        })
    )
  );

  getEntityListDataById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEntityListDataByIdAction),
      switchMap(({ query }) =>
        this.entityService.getEntityListDataById(query).pipe(
          map((response: GetEntityListDataByIdResponse) =>
            getEntityListDataByIdSuccessAction({ response })
          ),
          catchError((error) => of(getEntityListDataByIdFailureAction({ error })))
        )
      )
    )
  );

  deleteEntityListData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteEntityListDataAction),
      switchMap(({ query }) => {
        return this.entityService.deleteEntityListData(query).pipe(
          map(() => {
            return deleteEntityListDataSuccessAction();
          }),
          catchError((error) => {
            return of(deleteEntityListDataFailureAction({ error }));
          })
        );
      })
    )
  );

  reOrderEntityListData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reOrderEntityListData),
      switchMap(({ entityComponentId, request }) => {
        return this.entityService.reOrderEntityListData(entityComponentId, request);
      })
    )
  );

  getListBenefit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getListBenefit),
      switchMap(({ query }) =>
        this.entityService.getListBenefit(query).pipe(
          map((response: any) =>
            getListBenefitSuccess({ response })
          ),
          catchError((error) => of(getListBenefitFailure({ error })))
        )
      )
    )
  );

  updateBenefitMapping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateBenefitMapping),
      switchMap(({body}) => {
        return this.entityService
          .updateBenefitMapping(body)
          .pipe(
            map(() => {
              return updateBenefitMappingSuccess();
            }),
            catchError((error) => {
              return of(updateBenefitMappingFailure());
            })
          );
      })
    )
  );
}
