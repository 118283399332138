import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReleaseVersionResponsive } from '../models/menu.model';
import { environment } from 'src/environments/environment';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReleaseVersionService {
  releaseVersion$: Observable<ReleaseVersionResponsive>;

  constructor(private httpClient: HttpClient) {
    this.releaseVersion$ = this.getReleaseVersion().pipe(shareReplay(1));
  }

  getReleaseVersion(): Observable<ReleaseVersionResponsive> {
    return this.httpClient.get<ReleaseVersionResponsive>(`${environment.apiUrl}/api/ReleaseNote`);
  }
}
