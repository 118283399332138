import { SortType } from '@ptg-shared/constance/value.const';

import { MemberStatus } from './status.model';

export interface StatusHistoryList {
  memberStatusHistoryDto: History[];
  total: number;
}

export interface MunicipalityList {
  payments: History[];
  total: number;
  totalService: string;
}
export interface MunicipalityServiceRecordList {
  serviceRecords: History[];
  total: number;
  beginDate: string;
  endDate: string;
  dateOfDeath: string;
  totalService: string;
}

export interface MunicipalityServiceRecordListEntity {
  table: History[];
  total: number;
  beginDate: string;
  endDate: string;
  totalService: string;
}

export interface History extends StatusHistory, MunicipalityPayment, MunicipalityServiceHistory {
  isEditingMetRequirement?: boolean;
  isEditMetRequirement?: boolean;
  metRequirementValue?: boolean | null;
}

export interface StatusHistory {
  id: string;
  statusId: string;
  eventId: string;
  statusDate: string;
  statusName?: string;
  statusEvent?: string;
  _typedValue?: MemberStatus;
  lastModifiedBy?: string;
  lastModifiedAt?: string;
}
export interface MunicipalityPayment {
  id: string;
  year: number;
  municipalityName: string;
  disabilityFees: number;
  muniPaidPension?: number;
  memberPaidPension?: number;
  sumOfPensionPayments?: number;
  metRequirement?: string;
  lastEvent?: string;
  receiptNumber?: string;
  paymentDate?: string;
}
export interface MunicipalityServiceHistory {
  id: string;
  municipalityId: string;
  municipalityName: string;
  beginDate: string;
  endDate: string;
  serviceTime: string;
}

export interface GetListStatusHistoryQuery {
  pageIndex: number;
  pageSize: number;
  sortNames?: string[];
  sortType?: number;
  memberId: string;
  memberStatusHistoryId?: string;
}

export interface CreateMemberStatusRequest {
  memberId: string;
  statusId: string;
  eventId: string;
  statusDate: string;
}

export interface EditMemberStatusRequest {
  id: string;
  memberId: string;
  statusId: string;
  eventId: string;
  statusDate: string;
}
export interface GetAuditTrailsRequest {
  pageSize?: number;
  pageNumber?: number;
  sortNames?: string;
  sortType?: SortType;
  memberId?: string;
  statusHistoryId?: string;
}

export interface GetAuditTrailsResponse {
  auditTrails?: AuditTrail[];
  total?: number;
}
export interface AuditTrail {
  id?: string;
  action: string;
  actionString?: string;
  modifiedAt: string;
  modifiedBy: string;
  eventDateBefore: Date;
  eventDateAfter: Date;
  statusBefore: string;
  statusAfter: string;
  eventBefore: string;
  eventAfter: string;
}
export enum StatusAction {
  CreateStatus,
  UpdateStatus,
  UpdateStatusWOChangedData,
}
