import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { CalculationBenefitDetailsService } from '../../services';
import { GetCalculationBenefitDetailsResponse, CreateGenerateCalculationWorksheetResponse } from '../../services/models';
import {
  closeBenefitWarningMessageAction,
  closeBenefitWarningMessageFailureAction,
  closeBenefitWarningMessageSuccessAction,
  getCalculationBenefitDetailsAction,
  getCalculationBenefitDetailsFailureAction,
  getCalculationBenefitDetailsSuccessAction,
  setBenefitDetailAction,
  setBenefitDetailFailureAction,
  setBenefitDetailSuccessAction,
  createGenerateCalculationWorksheetAction,
  createGenerateCalculationWorksheetFailureAction,
  createGenerateCalculationWorksheetSuccessAction,
} from '../actions';

@Injectable()
export class GetCalculationBenefitDetailsEffects {
  constructor(
    private actions$: Actions,
    private calculationBenefitDetailsService: CalculationBenefitDetailsService,
  ) {}

  getCalculationBenefitDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCalculationBenefitDetailsAction),
      switchMap(({ request }) =>
        this.calculationBenefitDetailsService.getCalculationBenefitDetails(request).pipe(
          map((response: GetCalculationBenefitDetailsResponse) =>
            getCalculationBenefitDetailsSuccessAction({ response }),
          ),
          catchError((error) => of(getCalculationBenefitDetailsFailureAction({ error }))),
        ),
      ),
    ),
  );

  setBenefitDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setBenefitDetailAction),
      switchMap(({ params, request, editItem }) => {
        return this.calculationBenefitDetailsService.setBenefitDetail(params, request).pipe(
          map(() => setBenefitDetailSuccessAction({ editItem })),
          catchError((error) => of(setBenefitDetailFailureAction({ error, editItem }))),
        );
      }),
    ),
  );

  closeBenefitDetailWarningMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(closeBenefitWarningMessageAction),
      switchMap(({ params }) => {
        return this.calculationBenefitDetailsService.calculationParameterCloseWarningMessage(params).pipe(
          map(() => closeBenefitWarningMessageSuccessAction()),
          catchError((error) => of(closeBenefitWarningMessageFailureAction({ error }))),
        );
      }),
    ),
  );

  createGenerateCalculationWorksheet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createGenerateCalculationWorksheetAction),
      switchMap(({ memberId, request }) =>
        this.calculationBenefitDetailsService.createGenerateCalculationWorksheet(memberId, request).pipe(
          map((res: any) => {
            const contentDisposition = res.headers.get('content-disposition');
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            let fileName = '';
            if (matches?.[1]) {
              fileName = matches[1].replace(/['"\s]/g, '');
            }
            const response = {
              fileName,
              file: [res.body],
            };
            return createGenerateCalculationWorksheetSuccessAction({ response });
          }),
          catchError((error) => of(createGenerateCalculationWorksheetFailureAction({ error })))
        )
      )
    )
  );
}
