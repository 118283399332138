import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverviewHeaderComponent } from './overview-header.component';
import { ControlsModule } from '@ptg-shared/controls/controls.module';
import { SharedModule } from '@ptg-shared/shared.module';



@NgModule({
  declarations: [OverviewHeaderComponent],
  imports: [
    CommonModule,
    ControlsModule,
    SharedModule,
  ],
  exports: [OverviewHeaderComponent]
})
export class OverviewHeaderModule { }
