import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Auth0Service } from '../auth/services/auth0.service';
import {ADMIN_SYSTEM} from 'src/app/shared/constance/value.const';

@Injectable({
  providedIn: 'root'
})
export class CheckRoleService {
  constructor(private _router: Router, private authService: Auth0Service) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let role = '';
    if (localStorage.getItem('role') && !this.authService.Role) {
      role = localStorage.getItem('role') as string;
    } else if (this.authService.Role) {
      role = this.authService.Role
    }
    console.log('---------------' + role + '-----------------')
    if (role === ADMIN_SYSTEM) {
      return true;
    } else {
      this._router.navigateByUrl('/permission');
    }
    return false;
  }
}
