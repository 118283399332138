import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { environment } from 'src/environments/environment';
import * as fromReducer from '@ptg-reducers';

import {
  BodyChangePropertyKey,
  CheckExist,
  MetadataSection,
  PropertyRequest,
} from '../types/models';

@Injectable({
  providedIn: 'root',
})
export class MetadataSectionService {
  sectionKey: string = '';
  propertyKey: string = '';
  ssnLabel: any = '';
  activeFund: any;

  constructor(
    private httpClient: HttpClient,
    private store: Store<fromReducer.State>
  ) {
    this.store
      .pipe(select(fromReducer.selectCurrentFundState))
      .subscribe((el) => (this.activeFund = el));
  }

  getMetadataSection(key: string): Observable<MetadataSection> {
    // return this.httpClient.get<MetadataSection>(
    //   `${environment.apiUrl}/Clients/${this.activeFund?.key}/Metadata/${key}`
    // );

    // TODO remove when complete module entity
   return of();
  }

  orderTable(sectionKey: string, body: BodyChangePropertyKey) {
    // return this.httpClient.put(
    //   `${environment.apiUrl}/Clients/${this.activeFund?.key}/Metadata/${sectionKey}/Properties/${body.reorderPropertyKey}/Order`,
    //   body
    // );

    // TODO remove when complete module entity
   return of();
  }

  checkExits = (bodyCheck: CheckExist) => {
    // let body = {
    //   ...bodyCheck,
    //   clientKey: this.activeFund?.key,
    //   itemKey: this.sectionKey,
    // };
    // return this.httpClient.post(`${environment.apiUrl}/metadata/exists`, body);

    // TODO remove when complete module entity
   return of();
  };

  checkExitProperty = (bodyCheck: CheckExist) => {
    // let body = {
    //   ...bodyCheck,
    //   clientKey: this.activeFund?.key,
    //   itemKey: this.sectionKey,
    //   PropertyKey: this.propertyKey,
    // };
    // return this.httpClient.post(`${environment.apiUrl}/metadata/exists`, body);

    // TODO remove when complete module entity
   return of();
  };

  editSectionMetadata(data: any) {
    // let body = {
    //   ...data,
    //   clientKey: this.activeFund?.key,
    //   key: this.sectionKey,
    // };
    // if (body.type) {
    //   body.type = body.type.toString();
    // }
    // return this.httpClient.put(
    //   `${environment.apiUrl}/Clients/${this.activeFund?.id}/Metadata`,
    //   body
    // );

    // TODO remove when complete module entity
   return of();
  }

  editPropertyDetail(properties: any) {
    // let body = {
    //   name: properties[0].name,
    //   importKey: properties[0].importKey,
    //   configs: properties[0].config,
    //   options: properties[0].options,
    // };
    // return this.httpClient.put(
    //   `${environment.apiUrl}/Clients/${this.activeFund?.key}/Metadata/${this.sectionKey}/${properties[0].key}/Detail`,
    //   body
    // );

    // TODO remove when complete module entity
   return of();
  }

  addProperty(body: PropertyRequest) {
    // return this.httpClient.post(
    //   `${environment.apiUrl}/Clients/${this.activeFund?.key}/Metadata/${this.sectionKey}/Property`,
    //   body
    // );

    // TODO remove when complete module entity
   return of();
  }

  checkPropertyExits = (request: any) => {
    // return this.httpClient.post(
    //   `${environment.apiUrl}/Clients/${this.activeFund?.key}/Metadata/${this.sectionKey}/Property/Exists`,
    //   request
    // );

    // TODO remove when complete module entity
   return of();
  };

  removePropertyMetadata(propertyKey: string) {
    return this.httpClient.delete(
      `${environment.apiUrl}/Clients/${this.activeFund?.key}/Metadata/${this.sectionKey}/${propertyKey}/Delete`
    );

    // TODO remove when complete module entity
   return of();
  }
}
