import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { CheckRetirementDocumentCanRemoveService } from '../../services';
import { CheckExistCheckRetirementDocumentCanRemoveResponse } from '../../services/models';
import {
  checkRetirementDocumentCanRemoveAction,
  checkRetirementDocumentCanRemoveFailureAction,
  checkRetirementDocumentCanRemoveSuccessAction,
} from '../actions';

@Injectable()
export class CheckRetirementDocumentCanRemoveEffects {
  constructor(
    private actions$: Actions,
    private checkRetirementDocumentCanRemoveService: CheckRetirementDocumentCanRemoveService
  ) {}

  checkExistCheckRetirementDocumentCanRemove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkRetirementDocumentCanRemoveAction),
      switchMap(({ request }) =>
        this.checkRetirementDocumentCanRemoveService.checkExistCheckRetirementDocumentCanRemove(request).pipe(
          map((response: CheckExistCheckRetirementDocumentCanRemoveResponse) => checkRetirementDocumentCanRemoveSuccessAction({ response })),
          catchError((error) => of(checkRetirementDocumentCanRemoveFailureAction({ error })))
        )
      )
    )
  );
}
