import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BaseListComponent } from '@ptg-shared/components/base-list.component';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ACTION_COLUMN, Column } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { DEFAULT_PAGE_SIZE, STATE, SortType } from '@ptg-shared/constance';
import { capitalizeFirstLetter, isEmpty } from '@ptg-shared/utils/string.util';
import { AddRetirementBenefitInputOutputComponent } from '@ptg-member/features/calculation/components/add-retirement-benefit-input-output/add-retirement-benefit-input-output.component';
import { showBanner } from '@ptg-shared/utils/common.util';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ParameterType } from '@ptg-member/features/calculation/constants';
import {
  CalculationState,
  checkMasterParameterUsingAction,
  clearCheckMasterParameterUsingStateAction,
  clearRetirementBenefitInputOutputState,
  deleteMasterInputOutputRequest,
  getRetirementBenefitInputOutputsRequest,
} from '../../store';
import { GetRetirementBenefitInputOutputListQuery, Parameter } from '../../services/models';
import {
  selectRetirementBenefitInputOutputListState,
  selectMasterInputOutputActionState,
  checkMasterParameterUsingSelector,
} from '../../store/selectors/input-output.selector';
import { CalculationType } from '../../types/enums';

@Component({
  selector: 'ptg-retirement-benefit-input-output-list',
  templateUrl: './retirement-benefit-input-output-list.component.html',
  styleUrls: ['./retirement-benefit-input-output-list.component.scss'],
})
export class RetirementBenefitInputOutputListComponent extends BaseListComponent {
  readonly PAGE_KEY = 'ptg-retirement-benefit-input-output-list';
  ParameterType = ParameterType;
  listBreadcrumbs: Breadcrumb[] = this.getBreadcrumbs();
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  isLoading = true;
  sortInfo: Sort = { active: '', direction: 'asc' };
  lengthPg: number = 0;
  pageSize: number = DEFAULT_PAGE_SIZE;
  pageIndex: number = FIRST_PAGE;
  listParameter: Parameter[] = [];
  columns: Column[] = [
    {
      name: 'name',
      header: {
        title: 'Name',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'type',
      header: {
        title: 'Parameter Type',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'dataTypeLabel',
      header: {
        title: 'Data Type',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'description',
      header: {
        title: 'Description',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'linkedEntityPropertyName',
      header: {
        title: 'Linked Property',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      width: '170px',
    },
  ];
  memberId: string = '';

  constructor(
    public layoutService: LayoutService,
    public route: ActivatedRoute,
    private dialog: MatDialog,
    private calculationStore: Store<CalculationState>,
  ) {
    super(layoutService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getRouteData();
    this.getData();
    this.getRetirementBenefitInputOutputListState();
    this.getRetirementBenefitInputOutputActionState();
    this.getCheckMasterParameterUsingState();
  }

  getRouteData() {
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.memberId = params.memberId;
      this.listBreadcrumbs = this.getBreadcrumbs();
    });
  }

  getBreadcrumbs() {
    return [
      {
        name: 'Retirement Benefit Overview',
        url: `/member/benefit-overview/${CalculationType.RetirementBenefit}/${this.memberId}`,
      },
      {
        name: 'Input/Output Parameter',
      },
    ];
  }

  getData() {
    let sortType = SortType.ASC;
    let sortField = 'name';
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortField = capitalizeFirstLetter(this.sortInfo.active);
      sortType = this.sortInfo.direction === 'desc' ? SortType.DESC : SortType.ASC;
    }
    const query: GetRetirementBenefitInputOutputListQuery = {
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      sortField,
      sortType,
    };
    this.calculationStore.dispatch(getRetirementBenefitInputOutputsRequest({ query }));
  }

  getRetirementBenefitInputOutputListState() {
    this.calculationStore.select(selectRetirementBenefitInputOutputListState)
      .pipe(
        tap(response => this.isLoading = !!response?.isLoading),
        filter((response) => !!response && !response.isLoading),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        this.lengthPg = state?.total ?? 0;
        this.listParameter = state?.payload || [];
      });
  }

  getRetirementBenefitInputOutputActionState() {
    this.calculationStore
      .pipe(select(selectMasterInputOutputActionState), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state && !isEmpty(state.parameterType)) {
          showBanner.call(this, state.state || '', ParameterType[state.parameterType], state.action || '');
          if (state.state === STATE.SUCCESS) {
            this.getData();
          }
          this.calculationStore.dispatch(clearRetirementBenefitInputOutputState());
        }
      });
  }

  onChangeSort(event: Sort) {
    this.sortInfo = event;
    this.getData();
  }

  onChangePage(event: PageEvent) {
    super.onChangePage(event);
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageNumber;
    this.getData();
  }

  onClickAddNewInputOutput() {
    this.dialog.open(AddRetirementBenefitInputOutputComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      autoFocus: false,
    });
  }

  onClickEdit(row: Parameter) {
    this.dialog.open(AddRetirementBenefitInputOutputComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      autoFocus: false,
      data: row,
    });
  }

  getCheckMasterParameterUsingState() {
    this.calculationStore
      .pipe(select(checkMasterParameterUsingSelector), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state?.id) {
          state.isUsing
            ? this.showUsingConfirmPopup(state.parameterType)
            : this.showNotUsingConfirmPopup(state.id, state.parameterType);
          this.calculationStore.dispatch(clearCheckMasterParameterUsingStateAction());
        }
      });
  }

  onClickRemove(row: Parameter) {
    this.calculationStore.dispatch(checkMasterParameterUsingAction({ id: row.id, parameterType: row.type }));
  }

  showNotUsingConfirmPopup(id: string, parameterType: ParameterType) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        text: `Are you sure you want to remove this ${ParameterType[parameterType]}?`,
        type: ConfirmType.Delete,
        title: 'Remove Item',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.calculationStore.dispatch(deleteMasterInputOutputRequest({ id, parameterType }));
      }
    });
  }

  showUsingConfirmPopup(parameterType: ParameterType) {
    const typeLabel = ParameterType[parameterType];
    this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        title: 'Warning',
        text: `Cannot remove this ${typeLabel}. This ${typeLabel} has been used for ${typeLabel} mapping.`,
        type: ConfirmType.Warning,
        cancelButtonTitle: 'Close',
        hideConfirmButton: true,
      },
    });
  }
}
