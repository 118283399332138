import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import { CreateRetirementBenefitDetailUploadDocumentRequest } from './models';
import { SHOW_LOADING } from '@ptg-interceptor/httpconfig.interceptor';

@Injectable()
export class RetirementBenefitDetailUploadDocumentService {
  constructor(private httpClient: HttpClient) {}

  createRetirementBenefitDetailUploadDocument(
    request: CreateRetirementBenefitDetailUploadDocumentRequest,
  ): Observable<void> {
    const context = new HttpContext().set(SHOW_LOADING, true);
    const formData = new FormData();
    let params = new HttpParams();
    const { memberId, calculationType, calculationBenefitId, files, calculationRecordId } = request;
    files?.forEach((item: any, index: number) => {
      formData.append(`files[${index}].id`, item.id || '');
      formData.append(`files[${index}].uploadDate`, new Date(item.uploadDate).toISOString() ?? '');
      formData.append(`files[${index}].documentName`, item.documentName || '');
      formData.append(`files[${index}].type`, item.type?.toString() || '');
      formData.append(`files[${index}].file`, item.file || '');

      if (item?.tags?.length) {
        item.tags.forEach((tag: any, idx: number) => {
          formData.append(`files[${index}].tags[${idx}]`, tag);
        });
      }

      formData.append(`files[${index}].fileName`, item.fileName || '');
      formData.append(`files[${index}].documentLocationTitle`, item.documentLocationTitle || '');
      formData.append(`files[${index}].documentLocationRouter`, item.documentLocationRouter || '');
      formData.append(`files[${index}].documentDescription`, item.documentDescription || '');
      formData.append(`files[${index}].showOnOverview`, (!!item.showOnOverview as boolean)?.toString());
    });
    if (calculationRecordId) params = new HttpParams({ fromObject: { calculationRecordId: calculationRecordId } });
    return this.httpClient.post<void>(
      `${environment.apiUrl}/Calculations/${memberId}/benefit-processing/calculation-benefit-histories/${calculationType}/${calculationBenefitId}/document`,
      formData,
      { params, context },
    );
  }
}
