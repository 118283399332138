<div class="box">
  <div class="title-container">
    <span>Payment Detail History</span>
  </div>

  <ptg-grid
    [data]="lumpSumPaymentData"
    [columns]="columns"
    keyColumn="id"
    [errorMessage]="errorMsg"
    [isLoading]="isLoading"
    [fitToParent]="true"
    [totalRecords]="totalRecords"
    [pageSize]="pageSize"
    [pageNumber]="pageNumber"
    (pageChange)="onChangePage($event)"
    (sortChange)="onChangeSort($event)"
    (rowClick)="selectPayment($event)">
  </ptg-grid>
</div>
