import { createSelector } from '@ngrx/store';

import { selectCalculationModuleState } from './selector';

export const retirementBenefitHistorySelector = createSelector(
  selectCalculationModuleState,
(state) => state?.retirementBenefit
);

export const getRetirementBenefitHistorySelector = createSelector(
retirementBenefitHistorySelector,
(state) => state?.getRetirementBenefitHistories
);

export const removeCalculationBenefitSelector = createSelector(
  retirementBenefitHistorySelector,
  (state) => state?.removeCalculationBenefit,
);

export const retirementBenefitDocumentSelector = createSelector(
    selectCalculationModuleState,
  (state) => state?.retirementBenefit
);

export const getRetirementBenefitDocumentSelector = createSelector(
  retirementBenefitDocumentSelector,
  (state) => state?.getRetirementBenefitDocuments
);

export const retirementBenefitDownloadDocumentSelector = createSelector(
  retirementBenefitDocumentSelector,
  (state) => state
);

export const getRetirementBenefitDownloadDocumentSelector = createSelector(
  retirementBenefitDownloadDocumentSelector,
  (state) => state?.getRetirementBenefitDownloadDocument
);

export const getInitiateSurvivorSelector = createSelector(
  retirementBenefitDownloadDocumentSelector,
  (state) => state?.initiateSurvivor
);

export const validateBeforeRemovingDisabilityBenefitSelector = createSelector(
  retirementBenefitHistorySelector,
  (state) => {
    return state?.getValidateBeforeRemovingDisabilityBenefits
  }
);

export const removeDisabilityBenefitHistoriesSelector = createSelector(
  retirementBenefitHistorySelector,
  (state) => state?.removeDisabilityBenefitHistories
);


