import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, DoCheck, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher, mixinErrorState } from "@angular/material/core";
import { MatFormFieldControl } from '@angular/material/form-field';
import { QuillEditorComponent } from 'ngx-quill';
import { Subscription } from 'rxjs';

/* class is used in custom material form field component/wrapper
    provide material functionality to work with erorsState & ErrorStateMatcher  */
export class CustomErrorStateBase {
  constructor(public _defaultErrorStateMatcher: ErrorStateMatcher,
              public _parentForm: NgForm,
              public _parentFormGroup: FormGroupDirective,
              public ngControl: NgControl) {
  }
}


/* mixinErrorState - material function that appy logic related with material forms errors  */
export const CustomErrorStateMixin= mixinErrorState(CustomErrorStateBase);

@Directive({
  selector: '[ptgCustomRichTextEditor]',
  providers: [{ provide: MatFormFieldControl, useExisting: CustomRichTextEditorDirective }]
})
export class CustomRichTextEditorDirective extends CustomErrorStateMixin implements OnInit, OnDestroy, DoCheck, MatFormFieldControl<CustomRichTextEditorDirective> {

  static nextId = 0;
  _quillInstance: any;
  _quillSubscription: Subscription;
  controlType = 'quill-wrapper-directive';
  @HostBinding() id = `${ this.controlType }-id-${ CustomRichTextEditorDirective.nextId++ }`;
  focused!: boolean;
  @HostBinding('attr.aria-describedby') describedBy = '';
  @Input() hasFloatLabel: boolean = false;

  constructor(
    public _defaultErrorStateMatcher: ErrorStateMatcher,
    @Optional() public _parentForm: NgForm,
    @Optional() public _parentFormGroup: FormGroupDirective,
    @Optional() @Self() public ngControl: NgControl,
    private focusMonitor: FocusMonitor,
    private elementRef: ElementRef<HTMLElement>,
    quillEditor: QuillEditorComponent) {

    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);

    this._quillSubscription = quillEditor.onEditorCreated.subscribe((event) => this.quillEditorCreated(event));

    focusMonitor.monitor(elementRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  private _value!: any;

  set value(newValue: CustomRichTextEditorDirective | null) {
    this._value = newValue;
    this.stateChanges.next();
  }

  private _placeholder!: string;

  @Input()
  get placeholder() {

    return this._placeholder;
  }

  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  get empty() {
    let isEmpty = true;
    ;
    if (this._quillInstance) {
      // since quill always apply new line '/n' at the end of file, lenght === 1 means there is no text inside
      isEmpty = this._quillInstance.getLength() === 1;
    }
    return isEmpty;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.hasFloatLabel && (this.focused || !this.empty);
  }

  private _required = false;

  @Input()
  get required() {
    return this._required;
  }

  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._quillSubscription.unsubscribe();
    this.focusMonitor.stopMonitoring(this.elementRef.nativeElement);
  }

  ngDoCheck() {
    if (this.ngControl) {
      // We need to re-evaluate this on every change detection cycle, because there are some
      // error triggers that we can't subscribe to (e.g. parent form submissions).
      this.updateErrorState();
    }
  }

  // this two properties are taken from base class: CustomErrorStateMixin - through material mixinErrorState
  // errorState = false;
  // stateChanges = new Subject<void>();

  quillEditorCreated($event: any) {
    this._quillInstance = $event;
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
  }
}
