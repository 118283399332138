<div class="next-payment">
  <ptg-breadcrumb
    *ngIf="historyService.isBackNextPayroll"
    [listBreadcrumbs]="
      breadcrumbs ||
      (isHistory && !offCyclePaymentId
        ? listBreadcrumbsBackHistory
        : offCyclePaymentId
        ? listBreadcrumbsBackToOffCyclePayments
        : listBreadcrumbsBackToNextPayroll)
    "
    [settings]="settings"
    (onSelectSetting)="selectPaymentInforConfig($event)"
  ></ptg-breadcrumb>

  <div *ngIf="!isEntityView; else entityView">
    <ptg-overview-header
      *ngIf="isEstablishBenefit && !isLoading"
    ></ptg-overview-header>  
  </div>
  <ng-template #entityView>
    <ptg-overview-header-entity></ptg-overview-header-entity>
  </ng-template>

  <div class="next-payment-content flex flex-col p-6 gap-6">
    <ptg-banner
      *ngIf="bannerType"
      [(bannerType)]="bannerType"
      [message]="message"
    ></ptg-banner>

    <div class="no-data" *ngIf="!isEstablishBenefit && !isLoading">
      <p>No Data to Display</p>
    </div>

    <ptg-progress-spinner *ngIf="isLoading"></ptg-progress-spinner>

    <div *ngIf="isEstablishBenefit && !isLoading" [ngClass]="{ 'entity-view': isEntityView }">
      <div
        class="content"
        *ngIf="
          isEstablishBenefit &&
          isLoading === false &&
          (paymentListConfig === 'none' || paymentListConfig === 'show')
        "
      >
        <div
          class="detail-section"
          [ngClass]="{ 'history-view': isHistory }"
          *ngIf="!isHiddenDetailContent"
        >
          <span class="payment-date">
            {{
              offCyclePayment?.paymentInfoType === PaymentInfoTypeEnum.Reissue
                ? "Off-Cycle Payment - Reissue for Transaction " +
                  originalTransactionId
                : offCyclePayment?.paymentInfoType ===
                  PaymentInfoTypeEnum["Off-Cycle"]
                ? "Off-Cycle Payment"
                : offCyclePayment?.paymentInfoType ===
                  PaymentInfoTypeEnum.Reversal
                ? "Off-Cycle Payment - Reversal of Transaction " +
                  originalTransactionId
                : isMobileAl
                ? "Payment"
                : "Recurring Payment"
            }}
            {{
              isHistory && depositDate
                ? ": " + (depositDate | date : "MM/dd/yyyy")
                : ""
            }}
          </span>
          <div>
            <div class="pay-period" *ngIf="offCyclePaymentId || isHistory">
              <label class="label" *ngIf="!isEditPayPeriod">Pay Period: </label>
              <ng-container *ngIf="isEditPayPeriod; else textPayPeriod">
                <ptg-textbox
                  [controlField]="payPeriod"
                  placeholder="Pay Period"
                  [hasLabel]="true"
                  [maxLength]="25"
                  [isRequired]="true"
                ></ptg-textbox>
                <div class="close-check">
                  <ptg-button
                    buttonName=""
                    classInput="yesno-button"
                    (clickButton)="editPayPeriod('close')"
                  >
                    <mat-icon>close</mat-icon>
                  </ptg-button>
                  <ptg-button
                    buttonName=""
                    classInput="yesno-button"
                    (clickButton)="editPayPeriod('save')"
                  >
                    <mat-icon style="color: #196f57">check</mat-icon>
                  </ptg-button>
                </div>
              </ng-container>
              <ng-template #textPayPeriod>
                <span>{{ payPeriod.value || "-" }}</span>
                <ptg-button *ngIf="notIssueOffCyclePaymentId"
                  buttonName=""
                  classInput="edit-button"
                  (clickButton)="isEditPayPeriod = true; payPeriod.setValue(payPeriod.value)"
                  buttonName=""
                  classInput="edit-button"
                  (clickButton)="beginEditPayPeriod()"
                >
                  <mat-icon>edit</mat-icon>
                </ptg-button>
              </ng-template>
            </div>
          </div>
  
          <ptg-note-memo-detail
            *ngIf="paymentInforConfig.NOTE"
            [isHistory]="isHistory"
            [depositDate]="depositDate"
            [isBackNextPayroll]="historyService.isBackNextPayroll"
            [offCyclePaymentId]="offCyclePaymentId"
            [paymentSourceId]="paymentSourceId"
            (onAddAction)="onClickAdd($event)"
            (onRemoveMemo)="onRemoveMemo($event)"
          ></ptg-note-memo-detail>
  
          <div
            class="flex gap-3 mt-6"
            *ngIf="
              paymentInforConfig.EARNINGS ||
              paymentInforConfig.DEDUCTIONS ||
              paymentInforConfig.PAYROLLSETTINGS
            "
          >
            <div class="section-container" *ngIf="paymentInforConfig.EARNINGS">
              <ptg-earning-detail
                [isHistory]="isHistory"
                [paymentInforConfig]="paymentInforConfig"
                (changeBannerEvent)="changeBannerEarning()"
                [breadcrumbs]="breadcrumbs"
                [isEntityView]="isEntityView"
                [isEstablishBenefit]="isEstablishBenefit"
                [targetId]="selectedTargetId"
                [benefitId]="benefitId"
              ></ptg-earning-detail>
            </div>
            <div class="section-container" *ngIf="paymentInforConfig.DEDUCTIONS">
              <ptg-deduction-detail
                [isHistory]="isHistory"
                [benefitId]="benefitId"
                [paymentInforConfig]="paymentInforConfig"
                (changeBannerEvent)="changeBannerDeduction()"
              ></ptg-deduction-detail>
            </div>
            <div
              class="section-container"
              *ngIf="paymentInforConfig.PAYROLLSETTINGS"
            >
              <ptg-payroll-settings-detail
                [isHistory]="isHistory"
                (changeBannerEvent)="changeBannerPayrollSetting()"
              ></ptg-payroll-settings-detail>
            </div>
          </div>
  
          <div class="flex mt-6" *ngIf="paymentInforConfig.PAYMENTS">
            <div class="section-container">
              <ptg-payment-detail
                [isHistory]="isHistory"
                [benefitId]="benefitId"
                (changeBannerEvent)="changeBannerPayment()"
              ></ptg-payment-detail>
            </div>
          </div>
        </div>
  
        <div
          class="wrap-btn"
          *ngIf="
            notIssueOffCyclePaymentId ||
            (isIssuedOffCyclePayment && !offCyclePayment?.offCycleId)
          "
        >
          <ng-container *ngIf="notIssueOffCyclePaymentId">
            <button
              mat-raised-button
              type="button"
              class="btn-issue-payment"
              (click)="onClickIssueOffCyclePayment()"
            >
              Issue Payment
            </button>
            <button
              *ngIf="!isReissuedOffCyclePayment"
              mat-raised-button
              type="button"
              class="btn-remove-payment"
              (click)="onClickRemoveOffCyclePayment()"
            >
              Remove
            </button>
          </ng-container>
          <button
            *ngIf="isIssuedOffCyclePayment && !offCyclePayment?.offCycleId"
            mat-raised-button
            type="button"
            class="btn-withdraw"
            (click)="onClickWithdrawPayment()"
          >
            Withdraw Payment
          </button>
        </div>
        <ptg-pending-payment
          *ngIf="paymentInforConfig.PENDINGPAYMENTS"
          [isShowNote]="isShowNote"
          [selectedRowId]="offCyclePaymentId || payeeId"
          [isEncryptedConfiguredName]="isEncryptedConfiguredName"
          (onSelectRow)="onSelectRowPendingPayment($event)"
          (onClickNewOffCycle)="onClickNewOffCycle()"
        ></ptg-pending-payment>
        <div class="history">
          <ptg-payment-history
            [isShowNote]="isShowNote"
            [selectedRowId]="offCyclePaymentId || payeeId"
            [isHistory]="isHistory"
            [paymentInforConfig]="paymentInforConfig"
            [isEncryptedConfiguredName]="isEncryptedConfiguredName"
            (onClickNewOffCycle)="onClickNewOffCycle()"
            (onSelectRow)="onSelectPaymentHistoryRow($event)"
          ></ptg-payment-history>
        </div>
      </div>
    </div>

    <div
      *ngIf="isLoading === false && paymentListConfig === 'hide'"
      class="data-not-found"
      [ngClass]="{ 'no-columns-display': paymentListConfig === 'hide' }"
    >
      <mat-icon>grid_off</mat-icon>
      <div class="message">List not yet configured</div>
    </div>
  </div>
</div>
