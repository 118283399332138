import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import { MetadataSection } from '../../types/models/metadata.model';
import * as MetadataDetailActions from '../../store/actions/metadata-detail.actions';
import { MetadataSectionService } from '../../services/metadata-detail.service';

@Injectable()
export class MetadataDetailEffects {
  addProperty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MetadataDetailActions.addProperty),
      switchMap(({ body, isContinue }) => {
        return this.metadataSectionService.addProperty(body).pipe(
          map(() => {
            return MetadataDetailActions.addPropertySuccess({
              propertyName: isContinue ? body.Name : '',
            });
          }),
          catchError((err) => {
            return of(
              MetadataDetailActions.addPropertyFailure({
                errorMsg: err.message,
              })
            );
          })
        );
      })
    )
  );

  getMetadataSection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MetadataDetailActions.getMetadataSection),
      switchMap(({ key }) => {
        return this.metadataSectionService.getMetadataSection(key).pipe(
          map((metadataSection: MetadataSection) => {
            return MetadataDetailActions.getMetadataSectionSuccess({
              metadataSection,
            });
          }),
          catchError((error) => {
            return of(
              MetadataDetailActions.getMetadataSectionFailure({ error })
            );
          })
        );
      })
    )
  );

  orderTable$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MetadataDetailActions.orderTable),
        switchMap(({ sectionKey, body }) => {
          return this.metadataSectionService.orderTable(sectionKey, body);
        })
      ),
    { dispatch: false }
  );

  editSectionMeta$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MetadataDetailActions.editSectionMetadata),
      switchMap(({ bodyEdit }) => {
        return this.metadataSectionService.editSectionMetadata(bodyEdit).pipe(
          map(() => {
            return MetadataDetailActions.editSectionMetadataSuccess({
              bodyEdit,
            });
          }),
          catchError((err) => {
            return of(
              MetadataDetailActions.editSectionMetadataFailure({
                errorMsg: err.message,
              })
            );
          })
        );
      })
    )
  );

  removePropertyMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MetadataDetailActions.removePropertyMetadata),
      switchMap((action) => {
        return this.metadataSectionService
          .removePropertyMetadata(action.propertyKey)
          .pipe(
            map(() => {
              return MetadataDetailActions.removePropertyMetadataSuccess();
            }),
            catchError((err) => {
              return of(
                MetadataDetailActions.removePropertyMetaFailure({
                  errorMsg: err.message,
                })
              );
            })
          );
      })
    )
  );

  editPropertyDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MetadataDetailActions.editPropertyDetail),
      switchMap(({ properties }) => {
        return this.metadataSectionService.editPropertyDetail(properties).pipe(
          map(() => {
            return MetadataDetailActions.editPropertyDetailSuccess({
              properties,
            });
          }),
          catchError((err) => {
            return of(
              MetadataDetailActions.editPropertyDetailFailure({
                errorMsg: err.message,
              })
            );
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private metadataSectionService: MetadataSectionService
  ) {}
}
