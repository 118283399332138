import { createSelector } from "@ngrx/store";
import { selectMemberModuleState } from "../reducers";

export const cardListSelector = createSelector(
  selectMemberModuleState,
  (state) => state?.cartMember
);

export const getCardDetailSelector = createSelector(
  cardListSelector,
  (state) => state?.getCardDetail
)

export const getEntityComponentSelector = createSelector(
  cardListSelector,
  (state) => state?.getEntityComponent
)

export const getEntityPropertySelector = createSelector(
  cardListSelector,
  (state) => state?.getEntityProperty
)

export const getEntityPropertyVersionSelector = createSelector(
  cardListSelector,
  (state) => state?.getEntityPropertyVersion
)

export const updateCardSelector = createSelector(
  cardListSelector,
  (state) => state?.updateCard
)