import { Component, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import {
  ACTION_COLUMN,
  Align,
  Column,
  GridComponent,
  ReorderInfo,
  Row,
  getValidatorsFromColumns,
} from '@ptg-shared/controls/grid';
import { BaseComponent } from '@ptg-shared/components';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ACTION, CANCEL_CONFIRM_MESSAGE } from '@ptg-shared/constance';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { Option } from '@ptg-shared/controls/select/select.component';

import * as fromReducer from '@ptg-reducers';
import { EntityPropertyType } from '../../types/enums';
import {
  getEntityDetailSelector,
  getEntityInitiationConfigurationSelector,
  setEntityInitiationConfigurationSelector,
} from '../../store/selectors';
import {
  ConfiguredProperties,
  EntityConfigs,
  EntityHeaderDetail,
  EntityInitiationConfiguration,
  EntityPropertyOptions,
  GetEntityDetailResponse,
  GetEntityInitiationConfigurationRequest,
  OrderTitle,
} from '../../services/models';
import { EntityState } from '../../store/reducers';
import {
  clearSetEntityInitiationConfigurationStateAction,
  getEntityInitiationConfigurationAction,
  setEntityInitiationConfigurationAction,
} from '../../store/actions';

import { showBanner } from '@ptg-shared/utils/common.util';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'ptg-configure-initiation',
  templateUrl: './configure-initiation.component.html',
  styleUrls: ['./configure-initiation.component.scss'],
})
export class ConfigureInitiationComponent extends BaseComponent {
  @ViewChild('sortPropertyTable')
  gridview!: GridComponent<EntityInitiationConfiguration>;
  readonly EntityPropertyType = EntityPropertyType;
  readonly ACTION_COLUMN = ACTION_COLUMN;

  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  canSubmit: boolean = false;
  listBreadcrumbs: Breadcrumb[] = [];
  screenSource?: string;
  isLoading = true;
  entity?: GetEntityDetailResponse;
  entityId!: string;
  entityTable: (EntityInitiationConfiguration & Row)[] = [];
  entityPropertySetting: ConfiguredProperties[] = [];
  availableEntityOptions!: Option[] | any[];
  entityPropertyOptions: EntityPropertyOptions[] = [];
  entityConfigs: EntityConfigs = {
    title: 'Add Property',
    propertyName: 'Property Name',
    label: 'Label',
    buttonName: 'Add to Initiation',
  };

  orderTitle: OrderTitle = {
    title: 'Order Properties',
  };

  ACTION = {
    ADD_SORT_ROW: 'addSortRow',
    EDIT_COLUMN_NAME: 'editColumnName',
    REMOVE: 'remove',
    SORT_CHANGE: 'sortChange',
  };

  entityHeaderDetail: EntityHeaderDetail = {
    entityLabel: '',
    entityName: '',
  };

  formData: FormGroup = this.fb.group({
    entityLabel: this.fb.control('', {
      validators: [Validators.required],
    }),
    propertyName: this.fb.control('', {
      validators: [Validators.required],
    }),
    columnName: this.fb.control('', [
      Validators.required,
      Validators.maxLength(150),
      this.checkDuplicated(),
    ]),
  });

  get entityLabelCtrl() {
    return this.formData?.get('entityLabel') as FormControl;
  }

  get propertyNameCtrl() {
    return this.formData?.get('propertyName') as FormControl;
  }

  get labelCtrl() {
    return this.formData?.get('columnName') as FormControl;
  }

  orderColumns: Column[] = [
    {
      name: 'columnName',
      editable: true,
      truncate: true,
      validators: {
        required: {
          type: (obj: any) => Validators.required,
          message: (error: any, fieldName: string) =>
            `${fieldName} is required.`,
        },
        maxlength: {
          type: (obj: any) => Validators.maxLength(150),
          message: (error: any, fieldName: string) =>
            `Exceed the ${error.requiredLength} character limit.`,
        },
        existed: {
          type: (obj: any) => this.checkExits(obj),
          message: (error: any, fieldName: string) =>
            `${fieldName} already exists.`,
        },
      },
      controlArgs: {
        placeholder: 'Label',
      },
    },
    {
      name: ACTION_COLUMN,
      align: Align.Right,
      width: '50px',
    },
  ];

  constructor(
    public fb: FormBuilder,
    private store: Store<fromReducer.State>,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ConfigureInitiationComponent>,
    public router: Router,
    public entityStore: Store<EntityState>,
    public route: ActivatedRoute
  ) {
    super(route);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store.dispatch(LayoutActions.hiddenSideMenu());
    this.getEntityInformation();
    this.getInitiationConfigsAction();

    this.getInitiationConfigsSelector();
    this.saveInitiationConfigsSelector();
  }

  getEntityInformation(): void {
    this.route.params.subscribe((params) => {
      this.entityId = params.id;
    });

    combineLatest([
      this.entityStore.pipe(select(getEntityDetailSelector)), 
      this.store.pipe(select(fromReducer.selectCurrentFundState))
    ]).pipe(takeUntil(this.unsubscribe$)).subscribe(([state, currentFund]) => {
      if (state) {
        this.isLoading = state.isLoading;
        this.entity = state.payload;
      }
      this.listBreadcrumbs = [
        {
          name:
            this.screenSource === 'participants'
              ? 'Participant List'
              : 'Fund List',
          url:
            this.screenSource === 'participants' ? '/member' : '/fund-list',
        },
        {
          name: currentFund?.name + ' Entity List',
          url: `/entity-management/entities${
            this.screenSource === 'participants'
              ? '?fromParticipants=true'
              : ''
          }`,
        },
        {
          name: 'Entity Initiation Detail',
        },
      ];
    });
  }

  getInitiationConfigsAction(): void {
    let request: GetEntityInitiationConfigurationRequest = {
      entityId: this.entityId,
    };

    this.entityStore.dispatch(
      getEntityInitiationConfigurationAction({ request })
    );
  }

  saveInitiationConfigsSelector(): void {
    this.entityStore
      .pipe(
        select(setEntityInitiationConfigurationSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state && !state?.isLoading) {
          if (state?.success) {
            this.bannerType = BannerType.Success;
            this.getInitiationConfigsAction();
          } else {
            this.bannerType = BannerType.Fail;
          }

          showBanner.call(
            this,
            this.bannerType,
            `Entity Initiation`,
            ACTION.EDIT
          );

          this.store.dispatch(
            clearSetEntityInitiationConfigurationStateAction()
          );
        }
      });
  }

  getInitiationConfigsSelector(): void {
    this.entityStore
      .pipe(
        select(getEntityInitiationConfigurationSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state && !state.isLoading) {
          this.isLoading = state.isLoading;
          this.entityPropertyOptions = state?.payload?.properties || [];
          this.entityHeaderDetail.entityLabel = state?.payload?.entityLabel;
          this.entityHeaderDetail.entityName = state?.payload?.entityName;
          this.entityLabelCtrl.setValue(this.entityHeaderDetail.entityLabel);
          this.entityPropertySetting =
            state?.payload?.configuredProperties || [];
          this.getAvailablePropertyConfigs();
          this.getDataTable(state?.payload?.configuredProperties);
        }
      });
  }

  getDataTable(data?: ConfiguredProperties[]) {
    this.entityTable =
      data
        ?.map((config) => {
          const obj = {
            id: config.id,
            columnName: config.label,
            columnNameDescription: config.entityPropertyName,
            entityPropertyId: config.entityPropertyId,
            order: config.order,
          };
          return this.createInlineEditFormControls(obj);
        })
        .sort((a, b) => Number(a.order) - Number(b.order)) || [];
  }

  onSubmit(): void {
    if (this.gridview.formStatus !== AbstractControlStatus.VALID) {
      return;
    }
    const result = this.getSubmitData();
    this.entityStore.dispatch(
      setEntityInitiationConfigurationAction({
        request: {
          entityId: this.entityId,
          label: this.entityLabelCtrl?.value,
          configuredProperties: result,
        },
      })
    );
    this.resetAddPropertyForm();
    this.canSubmit = false;
  }

  getSubmitData() {
    let order = 1;
    const result: (any & Row)[] = this.entityTable?.map((item: any & Row) => {
      const obj = {
        id: item?.id,
        entityPropertyId: item?.entityPropertyId,
        entityPropertyName: item?.columnNameDescription,
        label: item?.form?.value?.columnName,
        order: item.deleted ? -1 : order,
      };
      order += item.deleted ? 0 : 1;
      return obj;
    });

    return result;
  }

  onCancel(): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: CANCEL_CONFIRM_MESSAGE,
        type: ConfirmType.Cancel,
        title: 'Cancel Action',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getInitiationConfigsSelector();
        this.getAvailablePropertyConfigs();
        this.resetAddPropertyForm();
      }
    });
    this.canSubmit = false;
  }

  changeProperty(): void {
    const labelName = this.availableEntityOptions?.find(
      (item) => item.value === this.propertyNameCtrl?.value
    )?.displayValue;
    this.labelCtrl?.setValue(labelName);
  }

  addProperty(): void {
    this.formData.markAllAsTouched();
    if (!this.propertyNameCtrl.value || !this.labelCtrl.value ) {
      if (!this.propertyNameCtrl.value) {
        this.propertyNameCtrl.setErrors({ required: true });
      }

      if (!this.labelCtrl.value) {
        this.labelCtrl.setErrors({ required: true });
      }
      return;
    }
    if (!this.formData.valid) {
      return;
    }
    const formValue = this.formData.value;
    const addedIndex = this.availableEntityOptions.findIndex(
      (item) => item.value === formValue.propertyName
    );

    var newRecord: EntityInitiationConfiguration = {
      id: undefined,
      columnName: formValue.columnName,
      columnNameDescription:
        this.availableEntityOptions[addedIndex]?.displayValue,
      entityPropertyId: this.availableEntityOptions[addedIndex]?.value,
    };

    this.entityTable = [
      ...this.entityTable,
      this.createInlineEditFormControls(newRecord),
    ];

    this.setAvailablePropertyConfigs(addedIndex);
    this.resetAddPropertyForm();
    this.canSubmit = true;
  }

  private checkExits(obj: EntityInitiationConfiguration): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const allRows = [...this.entityTable];
      const existed = allRows.some(
        (item) =>
          !(item.entityPropertyId === obj.entityPropertyId) &&
          item.form?.value?.columnName?.toLowerCase()?.trim() ===
            control.value.toLowerCase().trim()
      );
      return existed ? { existed: true } : null;
    };
  }

  changeItem(event: ReorderInfo) {
    this.canSubmit = true;
    this.entityTable = this.gridview?.dataSource;
  }

  getAvailablePropertyConfigs() {
    this.availableEntityOptions = (this.entityPropertyOptions as any).reduce(
      (result: Option[] | any, propertyConfig: any) => {
        const isExisted = this.entityPropertySetting.find((item: any) => {
          return this.isExisted(item, propertyConfig);
        });

        if (isExisted) {
          return result;
        }
        result.push({
          value: propertyConfig.id,
          displayValue: propertyConfig?.name,
          valueDescription: propertyConfig?.name,
        });
        return result;
      },
      [] as Option[]
    );
  }

  isExisted(item: ConfiguredProperties, config: EntityPropertyOptions) {
    const isExistedProperty = item.entityPropertyId === config.id;
    const isAllOptionSelected = isExistedProperty;
    return isAllOptionSelected;
  }

  setAvailablePropertyConfigs(addedIndex: number) {
    this.availableEntityOptions.splice(addedIndex, 1);
  }

  private createInlineEditFormControls(
    obj: EntityInitiationConfiguration
  ): EntityInitiationConfiguration {
    var result = {
      ...obj,
      form: this.fb.group({
        columnName: new FormControl(
          obj.columnName,
          getValidatorsFromColumns(
            this.orderColumns[0].name,
            this.orderColumns,
            obj
          )
        ),
      }),
    };
    return result;
  }

  resetAddPropertyForm() {
    this.propertyNameCtrl.setValue('');
    this.labelCtrl.setValue('');

    this.propertyNameCtrl.setErrors(null);
    this.labelCtrl.setErrors(null);

    this.labelCtrl.markAsUntouched();
    this.propertyNameCtrl.markAsUntouched();
  }

  checkDuplicated(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.entityTable?.length) {
        return null;
      }
      if (
        this.entityTable?.find(
          (item: { columnName: string }) =>
            item.columnName?.trim()?.toLowerCase() ===
            control.value?.trim()?.toLowerCase()
        )
      ) {
        return { duplicatedValue: 'Label already exists.' };
      }
      return null;
    };
  }

  onSoftDeleteSectionConfig(row: EntityInitiationConfiguration) {
    this.canSubmit = true;
    row.deleted = true;
    const sortItem = this.entityTable.find(
      (item) => item.entityPropertyId === row.entityPropertyId
    );
    if (sortItem) {
      sortItem.deleted = true;
    }

    const index = this.entityTable.findIndex(
      (item) => item.entityPropertyId === row.entityPropertyId
    );
    this.entityTable[index].deleted = true;
    this.entityTable = [...this.entityTable];
  }

  markAsDataChanged(): void {
    this.canSubmit = true;
  }
}
