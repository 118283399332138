import { SectionLayout } from '../../constance/metadata.const';

export interface MemberNavigationList {
  memberNavigationMenu: MemberNavigationResponse[];
  listStatus: Status[];
  fundType?: number;
  isShowSms?: boolean;
  isMember?: boolean;
  profileName?: string;
  overviewViewId?: string;
  deepestProfileTargetId?: string;
  overviewLabel?: string;
  id?: string;
  entityId?: string;
}

export interface MemberNavigationResponse {
  id: string;
  name: string;
  color: string;
  order: number;
  active: boolean;
  totalItem: number;
  menuItem: MemberNavigationItem[];
  menuItems: MemberNavigationItem[];
  statusList: string[];
}

export interface MemberNavigationItem {
  id: string;
  name: string;
  itemKey: string;
  itemName: string;
  order: number;
  type: SectionLayout;
  entityViewId: string;
}

export interface Status {
  id: string;
  name: string;
  color: string;
  iconName: string;
  active: boolean;
}

export interface MemberNavigation {
  id: string;
  name: string;
  color: string;
  order: number;
  disabled: boolean;
  totalItem: number;
  menuItems: MemberNavigationItem[];
  statusList: Status[];
}

export interface ChangeIndexRequest {
  upperAdjacentKey?: string | number;
  reorderPropertyKey?: string | number;
}

export interface CheckMenuExistRequest {
  id?: string;
  clientKey: string;
  name: string;
}

export interface CheckMenuItemExistRequest {
  subMenuName: string;
  navigationItemId: string;
  navigationMenuId: string;
}

export interface MemberNavigationDetailResponse {
  id: string;
  name: string;
  color: string;
  active: boolean;
  memberNavigationItems: MemberNavigationItem[];
  status: string[];
  listStatus: Status[];
}

export interface SetMemberNavigationRequest {
  id: string;
  name: string;
  color: string;
  active: boolean;
  status: string[];
}

export interface SetMemberNavigationItemRequest {
  id: string;
  name: string;
  itemKey: string;
  memberNavigationId: string;
}

export interface CreateMemberNavigationItem {
  name: string;
  itemKey: string;
  order: number;
}

export interface RemoveMemberNavigationItem {
  id: string;
  memberNavigationId: string;
}

export interface SectionList {
  sections: Section[];
}

export interface Section {
  sectionKey: string;
  sectionName: string;
}