<div *ngIf="offCycleData" class="header-container">
  <div class="payroll-information">
    <div class="payee-information-container">
      <div class="payee-information payees">
        <mat-icon>verified_user</mat-icon>
        <div class="information">
          <span class="label">Payees</span>
          <span class="value">
            {{ offCycleData?.totalPayee || 0 | number }}
          </span>
        </div>
      </div>
      <div class="vertical-separator"></div>
      <div class="payee-information">
        <mat-icon>supervisor_account</mat-icon>
        <div class="information">
          <span class="label">Service Providers</span>
          <span class="value">
            {{ offCycleData?.totalServiceProvider || 0 | number }}
          </span>
        </div>
      </div>
      <div class="vertical-separator"></div>
      <div class="payee-information">
        <mat-icon>account_balance</mat-icon>
        <div class="information">
          <span class="label">Direct Deposit Accounts</span>
          <span class="value">
            {{ offCycleData?.totalDirectDepositAccounts || 0 | number }}
          </span>
        </div>
      </div>
      <div class="vertical-separator"></div>
      <div class="payee-information">
        <mat-icon>print</mat-icon>
        <div class="information">
          <span class="label">Checks</span>
          <span class="value">
            {{ offCycleData?.totalCheck || 0 | number }}
          </span>
        </div>
      </div>
      <div class="vertical-separator"></div>
      <div class="payee-information">
        <mat-icon>account_balance</mat-icon>
        <div class="information">
          <span class="label">Wire Transfer</span>
          <span class="value">
            {{ offCycleData?.totalWireTransfer || 0 | number }}
          </span>
        </div>
      </div>
    </div>
    <div class="payment-information-container">
      <div class="payment-information">
        <span class="label">Total Gross</span>
        <span class="value">
          {{ offCycleData?.totalGross || 0 | numberLocalDecimal : "$" }}
        </span>
      </div>
      <div class="payment-information">
        <span class="label">Total Deductions</span>
        <span class="value">
          {{ offCycleData?.totalDeduction || 0 | numberLocalDecimal : "$" }}
        </span>
      </div>
      <div class="payment-information total-net">
        <span class="label">Total Net</span>
        <span class="value">
          {{ offCycleData?.totalNet || 0 | numberLocalDecimal : "$" }}
        </span>
      </div>
    </div>
  </div>
  <div class="edit-group-container">
    <div class="payee-information-container">
      <div class="payee-information">
        <div
          *ngIf="offCycleData?.status === PaymentStepEnum.Finalized"
          class="information"
          [ngStyle]="{ 'margin-right': '55px' }"
        >
          <span class="label">Posted to Register</span>
          <div *ngIf="offCycleData?.finalizedDate">
            <span
              class="value"
              *ngIf="
                offCycleData?.isManualFinalize ||
                (!offCycleData?.isManualFinalize &&
                  offCycleData?.batchResponseStatus ===
                    BatchResponseStatus.Accepted)
              "
            >
              {{ offCycleData?.finalizedDate | date : "MM/dd/yyyy" }}
            </span>
          </div>
        </div>
        <div
          class="information"
          *ngIf="
            !offCycleData?.boardBank &&
              offCycleData?.status !== PaymentStepEnum.Finalized;
            else boardBankSelected
          "
        >
          <div class="input-group-container">
            <div
              class="btn-select-bank"
              [ngClass]="{ 'btn-disabled': isProcessing }"
              (click)="onOpenSelectBankModal()"
            >
              <mat-icon>account_balance</mat-icon>
              <span class="label">Select Bank</span>
            </div>
          </div>
        </div>
        <ng-template #boardBankSelected>
          <div
            class="select-bank"
            *ngIf="
              offCycleData?.boardBank ||
              (!offCycleData?.boardBank &&
                offCycleData?.status === PaymentStepEnum.Finalized)
            "
          >
            <div class="input-group-container">
              <div class="selected-bank">
                <span class="label">Selected Bank</span>
                <mat-icon>check_circle</mat-icon>
                <div *ngIf="offCycleData?.boardBank">
                  {{ offCycleData?.boardBank?.bankName + " - " }}
                  <span class="value">
                    {{
                      offCycleData?.boardBank?.accountNumber || "" | mask : true
                    }}
                  </span>
                  {{
                    (offCycleData?.boardBank?.accountNumber || ""
                      | mask : false) +
                      (offCycleData?.boardBank?.accountType === 0
                        ? " - Checking"
                        : " - Savings")
                  }}
                </div>
              </div>
              <div
                class="edit-btn"
                [ngClass]="{
                  'btn-disabled':
                    isProcessingUpdateTransaction ||
                    isProcessingCancel ||
                    offCycleData?.isProcessingCancel ===
                      ConcurrentActionType.Processing
                }"
                *ngIf="isDisplayEditBankButton"
              >
                <span class="btn-edit" (click)="onOpenSelectBankModal()">
                  <mat-icon>account_balance</mat-icon>
                </span>
              </div>
            </div>
          </div>
        </ng-template>
        <div
          *ngIf="offCycleData?.status !== PaymentStepEnum.Finalized"
          class="information"
        >
          <ng-container *ngIf="isEditDepositDate; else textDepositDate">
            <div class="input-group-container">
              <ptg-datepicker
                [filterWeekends]="true"
                [minDate]="minDate"
                errorMinDate="Deposit Date must be a future date."
                [controlField]="depositDate"
                placeholder="Deposit Date"
                [isRequired]="true"
              ></ptg-datepicker>
              <div class="close-check">
                <ptg-button
                  buttonName=""
                  classInput="yesno-button"
                  (clickButton)="editDepositDate('close')"
                >
                  <mat-icon>close</mat-icon>
                </ptg-button>
                <ptg-button
                  buttonName=""
                  classInput="yesno-button"
                  (clickButton)="editDepositDate('save')"
                >
                  <mat-icon class="btn-yes">check</mat-icon>
                </ptg-button>
              </div>
            </div>
          </ng-container>
          <ng-template #textDepositDate>
            <span class="label">Deposit Date</span>
            <div>
              <span class="value">
                {{ depositDate?.value || "" | date : "MM/dd/yyyy" }}
              </span>
              <ptg-button
                classInput="edit-button"
                (clickButton)="clickEditDepositDate()"
                [isDisabled]="isProcessing"
                *ngIf="
                  (offCycleData?.boardBank?.payingAgent &&
                    offCycleData?.batchResponseStatus !==
                      BatchResponseStatus.Hold &&
                    (!offCycleData?.submittedDate ||
                      (offCycleData?.submittedDate &&
                        offCycleData?.receiveAcknowledgementFile &&
                        offCycleData?.batchResponseStatus ===
                          BatchResponseStatus.Rejected))) ||
                  (!offCycleData?.boardBank?.payingAgent &&
                    !offCycleData?.achSent &&
                    !offCycleData?.checksPrinted)
                "
              >
                <mat-icon>edit</mat-icon>
              </ptg-button>
            </div>
          </ng-template>
        </div>
        <div
          *ngIf="
            !offCycleData?.boardBank?.payingAgent &&
            !offCycleData?.hideAchSent &&
            offCycleData?.boardBank &&
            offCycleData?.status !== PaymentStepEnum.Finalized
          "
          class="signed-warrant"
        >
          <div class="signed-warrant-text">
            <div class="circle-icon">
              <span class="label">ACH File</span>
              <mat-icon class="check-circle">check_circle</mat-icon>
              <span class="text">Ready</span>
            </div>
            <div class="circle-icon">
              <mat-icon
                class="download-icon"
                svgIcon="download-icon"
                aria-hidden="false"
                aria-label="download-icon"
              ></mat-icon>
              <a
                #fileName
                href="javascript:void(0)"
                (click)="downloadACHFile()"
                class="value"
              >
                ACH_{{ offCycleData?.depositDate | date : "YYMMdd" }}.txt
              </a>
            </div>
          </div>
        </div>
        <div
          class="information"
          *ngIf="
            !offCycleData?.boardBank?.payingAgent &&
            offCycleData?.status === PaymentStepEnum.Finalized &&
            offCycleData?.achSentBy &&
            offCycleData?.achSentDate
          "
        >
          <span class="label">ACH File</span>
          <div class="sub-information">
            <span class="fileName">
              ACH_{{ offCycleData?.depositDate | date : "YYMMdd" }}.txt
            </span>
            <mat-icon>check_circle</mat-icon>
            <span class="account-send">
              Sent by {{ offCycleData?.achSentBy }}
              {{ offCycleData?.achSentDate | date : "MM/dd/yyyy" }}
            </span>
          </div>
        </div>
        <div class="check-box-container">
          <ptg-toggle-button
            *ngIf="
              !offCycleData?.hideChecksOnly &&
              offCycleData?.status !== PaymentStepEnum.Finalized
            "
            [controlField]="checksOnly"
            label="Checks Only"
            (valueChange)="filterData()"
          ></ptg-toggle-button>
          <ng-container
            *ngIf="
              offCycleData?.boardBank?.payingAgent &&
              offCycleData?.submittedDate &&
              offCycleData?.receiveAcknowledgementFile &&
              offCycleData?.totalError
            "
          >
            <ptg-toggle-button
              [controlField]="errorsOnly"
              label="Errors Only"
              (valueChange)="filterData()"
            ></ptg-toggle-button>
          </ng-container>
          <ptg-button
            *ngIf="
              !offCycleData?.boardBank?.payingAgent &&
              offCycleData?.status !== PaymentStepEnum.Finalized
            "
            buttonName="Print Checks"
            [isDisabled]="isProcessing"
            classInput="secondary print-btn"
            (clickButton)="({})"
          >
            <mat-icon>print</mat-icon>
          </ptg-button>
          <ptg-checkbox
            *ngIf="
              !offCycleData?.hideAchSent &&
              !offCycleData?.boardBank?.payingAgent &&
              offCycleData?.status !== PaymentStepEnum.Finalized &&
              offCycleData?.depositDate &&
              offCycleData?.boardBank?.id
            "
            class="achBtn"
            [controlField]="achSent"
            [isDisabled]="offCycleData?.achSent"
            label="ACH Sent"
            (changeValue)="onAchSent($event)"
          ></ptg-checkbox>
          <ptg-checkbox
            *ngIf="
              !offCycleData?.hideChecksPrinted &&
              !offCycleData?.boardBank?.payingAgent &&
              offCycleData?.status !== PaymentStepEnum.Finalized &&
              offCycleData?.depositDate &&
              offCycleData?.boardBank?.id
            "
            [controlField]="checksPrinted"
            [isDisabled]="offCycleData?.checksPrinted"
            label="Checks Printed"
            (changeValue)="onChecksPrinted($event)"
          ></ptg-checkbox>
        </div>
      </div>
      <div class="filter-container">
        <ptg-button
          *ngIf="
            offCycleData?.status !== PaymentStepEnum.Finalized &&
            (((!offCycleData?.boardBank ||
              !offCycleData?.boardBank?.payingAgent) &&
              !offCycleData?.achSent &&
              !offCycleData?.checksPrinted) ||
              (offCycleData?.boardBank?.payingAgent &&
                (!offCycleData?.submittedDate ||
                  (!!offCycleData?.submittedDate &&
                    offCycleData?.batchResponseStatus ===
                      BatchResponseStatus.Rejected))))
          "
          [isDisabled]="isProcessing"
          (clickButton)="cancelOffCycle()"
          buttonName="Cancel"
          classInput="secondary"
        ></ptg-button>
        <ptg-button
          *ngIf="
            (!offCycleData?.boardBank?.payingAgent ||
              (offCycleData?.boardBank?.payingAgent &&
                (!offCycleData?.allowSubmitToBank ||
                  (offCycleData?.receiveAcknowledgementFile &&
                    offCycleData?.batchResponseStatus ===
                      BatchResponseStatus.Accepted &&
                    offCycleData?.autoFinalizedError)))) &&
            offCycleData?.status !== PaymentStepEnum.Finalized
          "
          buttonName="Finalize"
          classInput="primary"
          [isDisabled]="
            (isSubmittedFinalize && !offCycleData?.boardBank?.payingAgent) ||
            (offCycleData?.boardBank?.payingAgent && isShowBanner) ||
            isProcessing
          "
          (clickButton)="onFinalizePayment()"
        ></ptg-button>
        <ptg-button
          *ngIf="
            offCycleData?.boardBank?.payingAgent &&
            offCycleData?.allowSubmitToBank &&
            offCycleData?.status !== PaymentStepEnum.Finalized &&
            !offCycleData?.autoFinalizedError
          "
          [isDisabled]="
            isProcessing ||
            !offCycleData.depositDate ||
            (!!offCycleData?.submittedDate &&
              (!offCycleData?.receiveAcknowledgementFile ||
                (offCycleData?.receiveAcknowledgementFile &&
                  offCycleData?.batchResponseStatus ===
                    BatchResponseStatus.Hold)))
          "
          buttonName="Send to Bank"
          classInput="primary"
          (clickButton)="sendToBank()"
        ></ptg-button>
      </div>
    </div>
  </div>
</div>
