import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { getDateString } from '../utils/string.util';
import { formatUtcDateString } from '@ptg-shared/utils/common.util';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(date: Date | string, hideTime: boolean = true, ignoreTimeZone: boolean = false): string {
    const format = hideTime ? 'MM/dd/yyyy' : 'MM/dd/yyyy hh:mm a'
    if (typeof date === 'string') {
      date = new Date(getDateString(date));
    }
    if (ignoreTimeZone) {
      return formatUtcDateString(date.toISOString(), format);
    }
    return new DatePipe('en-US').transform(date, format) || '';
  }

}
