<div class="view-current-filter">
    <div *ngFor="let filter of listChipData; let index = index" class="chip-item-container">
      <div class="chip-item">
        <span [class]="content.className" *ngFor="let content of filter.contents" >{{ content.value }}</span>
      </div>
      <button *ngIf="canRemove" (click)="removeFilter(index)" class="remove-button">
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
  </div>
  