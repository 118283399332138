import { HttpErrorResponse } from '@angular/common/http';
import { createReducer, on } from '@ngrx/store';
import { InterceptorActions } from 'src/app/shared/layout/actions';
import { setShowErrorDialogCondition } from 'src/app/shared/layout/actions/interceptor.actions';

export const InterceptorFeatureKey = 'Interceptor';

export interface State {
  error?: HttpErrorResponse;
  showErrorDialog: boolean
}

const initialState: State = {
  error: undefined,
  showErrorDialog: true
};

export const reducer = createReducer(
  initialState,
  on(InterceptorActions.setError, (state, { error }) => {
    return ({
      ...state,
      error
    });
  }),
  on(InterceptorActions.setShowErrorDialogCondition, (state, { showErrorDialog }) => {
    return ({
      ...state,
      showErrorDialog
    });
  }),
);
