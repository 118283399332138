import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  documentAttachAction, documentAttachActionFailureAction, documentAttachActionSuccessAction,
  getParticipantDocuments,
  getParticipantDocumentsBenefitsAction,
  getParticipantDocumentsBenefitsFailureAction,
  getParticipantDocumentsBenefitsSuccessAction,
  getParticipantDocumentsFailure,
  getParticipantDocumentsSuccess,
  removeDocumentsParticipant,
  removeDocumentsParticipantFailure,
  removeDocumentsParticipantSuccess,
} from '../actions/participant-documents.actions';
import { ParticipantDocumentsService } from '@ptg-member/services/participant-documents.service';
import { Observable, of } from 'rxjs';
import { GetParticipantDocumentsBenefitsResponse, ParticipantDocumentsListModel } from '@ptg-member/types/models/participant-documents.model';

@Injectable()
export class ParticipantDocumentsEffects {
  constructor(
    private actions$: Actions,
    private participantDocService: ParticipantDocumentsService,
  ) {}

  getParticipantDocumentsList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getParticipantDocuments),
      switchMap(({query, memberId, isShowOnOverView }) => {
        return this.participantDocService.getParticipantDocumentsList(query, memberId, isShowOnOverView).pipe(
          map((res: ParticipantDocumentsListModel) => {
            return getParticipantDocumentsSuccess({lstDoc: res});
          }),
          catchError((err: any) => {
            return of(getParticipantDocumentsFailure({ err }));
          }),
        );
      }),
    );
  });

  removeDocumentsParticipant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeDocumentsParticipant),
      switchMap(({ memberId, documentId }) => {
        return this.participantDocService.removeDocumentsParticipant(memberId, documentId).pipe(
          map(() => {
            return removeDocumentsParticipantSuccess();
          }),
          catchError((err) => {
            return of(
              removeDocumentsParticipantFailure({
                errorMsg: err.message,
              })
            );
          })
        );
      })
    )
  );

  documentAttach$ = createEffect(() =>
    this.actions$.pipe(
      ofType(documentAttachAction),
      switchMap(({ request }) =>
        this.participantDocService.documentAttach(request).pipe(
          map((response: any) => documentAttachActionSuccessAction()),
          catchError((error) => of(documentAttachActionFailureAction({ error }))),
        ),
      ),
    ),
  );

  getParticipantDocumentsBenefits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getParticipantDocumentsBenefitsAction),
      switchMap(({ memberId }) =>
        this.participantDocService.getParticipantDocumentsUseForBenefit(memberId).pipe(
          map((response: GetParticipantDocumentsBenefitsResponse) => getParticipantDocumentsBenefitsSuccessAction({ response })),
          catchError((error) => of(getParticipantDocumentsBenefitsFailureAction({ error })))
        )
      )
    )
  );
}
