import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as TagsAction from '../actions/tags.action';
import { TagService } from '../../services';
import { of } from 'rxjs';

@Injectable()
export class TagsEffects {
  constructor(
    private actions$: Actions,
    private tagService: TagService,
  ) {
  }

  addTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TagsAction.addTagAction),
      switchMap(({ tagRequest }) => {
        return this.tagService.addTag(tagRequest).pipe(
          map(() => TagsAction.addTagSuccessAction()),
          catchError(err => of(TagsAction.addTagFailureAction(err))),
        );
      }),
    ),
  );

  editTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TagsAction.editTagAction),
      switchMap(({ tagRequest }) => {
        return this.tagService.editTag(tagRequest).pipe(
          map(() => TagsAction.editTagSuccessAction()),
          catchError(err => of(TagsAction.editTagFailureAction(err))),
        );
      }),
    ),
  );

  getTagList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TagsAction.getTagListAction),
      switchMap(({ request }) => {
        return this.tagService.getTagList(request).pipe(
          map(response => TagsAction.getTagListActionSuccess({ response })),
          catchError(err => of(TagsAction.getTagListActionFailure(err)))
        )
      })
    )
  );

  removeTag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TagsAction.removeTagAction),
      switchMap(({ tagId }) =>
        this.tagService.removeTag(tagId).pipe(
          map(() => TagsAction.removeTagSuccessAction()),
          catchError(err => of(TagsAction.removeTagFailureAction(err)))
        )
      )
    )
  );
}
