import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppSettingService {

  constructor(private httpClient: HttpClient) { }

  getAppSettings() {
    return this.httpClient.get<any>(`${environment.apiUrl}/AppSetting`);
  }
}
