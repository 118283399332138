<div class="member-list-configuration" id="member-list-configuration-page">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"></ptg-breadcrumb>

  <div class="flex flex-col p-6 gap-6">
    <ptg-banner
      *ngIf="bannerType"
      [(bannerType)]="bannerType"
      [message]="message"
    ></ptg-banner>
    <ptg-search-property-display-configuration
    [propertyDisplayConfigurations]="propertyDisplayConfigurations"
    [propertyConfigs]="propertyConfigs"
    [addPropertySection]="addPropertySection"
    [sortPropertySection]="sortPropertySection"
    [sortRowSection]="sortRowSection"
    (onSubmitEvent)="onSubmit($event)"
    [canSelectMultipleOption]="true"
    ></ptg-search-property-display-configuration>
  </div>
</div>
