import { Component, Input, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '@ptg-shared/components';
import { FileService } from '@ptg-shared/services/file-service';
import { HistoryService } from '@ptg-shared/services/history.service';
import { SignatoryService } from '@ptg-shared/services/signatory-service';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import * as fromReducer from '@ptg-reducers';
import { SectionLayout } from '@ptg-member/constance/metadata.const';
import * as fromMember from '@ptg-member/store/reducers';
import { CreateEntityDataComponent } from '../create-entity-data/create-entity-data.component';
import { getListPropertyForCardsSelector, getStandAlonePropertyOnlyCardDataSelector } from '@ptg-member/store/reducers';
import {
  getListPropertyForCardsAction,
  getMemberEntityAttachmentsAction,
  getStandAlonePropertyOnlyCardDataAction,
} from '@ptg-member/store/actions/member-detail.actions';

import { EntityPropertyType } from '../../types/enums/entity-property.enum';
import { EntityManagementState, EntityState } from '../../store/reducers';
import { CardProperty, EntityProfileConfigOverview, EntityViewItem, GetListPropertyForCardsRequest, GetMemberEntityAttachmentsRequest, GetStandAlonePropertyOnlyCardDataRequest, MemberEntityAttachments, StandAlonePropertyOnlyCardData } from '../../services/models';
import { Align, Column, ColumnType, Row } from '@ptg-shared/controls/grid';
import { getColumConfig, getEntityPropertyName, getEntityValue } from '@ptg-member/helper';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { SortType } from '@ptg-shared/constance';
import { Sort } from '@angular/material/sort';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { deleteEntityListDataAction } from '@ptg-entity-management/store/actions';
import { PropertyType } from '@ptg-member/constance/metadataPropertyType.const';
import { EntityProfileComponentType, IncludeAttachmentType, PropertyDisplay, StandalonePropertiesDisplayRule, ViewType } from '@ptg-entity-management/types/enums';

const PAGE_SIZE_CONST = '-ptg-entity-detail-view-pageSize';
const PAGE_SIZE_ATT_CONST = '-ptg-entity-detail-view-att-pageSize';
const VersionHistory = 'fixed__versionhistory';
const Screen1099RInfo = '1099-R Info';

@Component({
  selector: 'ptg-entity-detail-view',
  templateUrl: './entity-detail-view.component.html',
  styleUrls: ['./entity-detail-view.component.scss'],
})
export class EntityDetailViewComponent extends BaseComponent {
  readonly SectionLayout = SectionLayout;
  readonly ViewType = ViewType;
  readonly IncludeAttachmentType = IncludeAttachmentType;
  readonly StandalonePropertiesDisplayRule = StandalonePropertiesDisplayRule;
  readonly EntityPropertyType = EntityPropertyType;

  isNoProfileConfigured: boolean = false;
  listBreadcrumbs: Breadcrumb[] = [];
  settings: Breadcrumb[] = [];
  isDisabledGear: boolean = false;
  pageTitle: string = '';

  bannerType: BannerType = BannerType.Hidden;
  message: string = '';

  viewType?: ViewType;
  isList: boolean = true;
  isVersionHistory: boolean = false;
  detailSectionLabel: string = '';
  displayRule: StandalonePropertiesDisplayRule = StandalonePropertiesDisplayRule.None;
  isEditable: boolean = true;
  isLoadingStandalone: boolean = true;
  standalonePropertyValues?: StandAlonePropertyOnlyCardData[];
  standaloneProperties: any[] = [];
  standaloneViewType?: PropertyDisplay;
  screenId?: string;

  listDetailViewProperties?: any;
  isLoadingListDetailView: boolean = true;
  currentRowIndex: number = 0;
  selectedRecordData: any;
  listDetailViewLabel: string = '';

  attachmentType: IncludeAttachmentType = IncludeAttachmentType.NoAttachment;
  attPageSize: number = 50;
  attPageNumber: number = FIRST_PAGE;
  attLengthPg: number = 0;
  attSortInfo: Sort = {
    active: 'Name',
    direction: 'asc',
  };
  isLoadingAttachments: boolean = true;
  attachments: (MemberEntityAttachments & Row)[] = [];

  currentFund: any = {};
  errorMsg: string = '';
  listCardData: (any & Row)[] = [];
  listCardDataOrigin: (any & Row)[] = [];
  columns: Column[] = [];
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  lengthPg: number = 0;
  sortInfo: Sort = {
    active: 'Name',
    direction: 'asc',
  };
  isDragDrop: boolean = false;

  @Input() entityId!: string;
  @Input() targetId!: string;
  @Input() isOverview?: boolean;
  @Input() menuId?: string;
  @Input() profileConfigOverview?: EntityProfileConfigOverview;
  @Input() card?: EntityViewItem;

  constructor(
    public route: ActivatedRoute,
    private store: Store<fromReducer.State>,
    private memberStore: Store<fromMember.MemberState>,
    private entityManagementStore: Store<EntityManagementState>,
    private router: Router,
    private dialog: MatDialog,
    private signatoryService: SignatoryService,
    private fileService: FileService,
    public historyService: HistoryService,
    public entityStore: Store<EntityState>,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.store
      .pipe(select(getStandAlonePropertyOnlyCardDataSelector), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (!state) return;

        this.isLoadingStandalone = state.isLoading;

        if (state?.isLoading || !state?.success || !state?.payload) return;

        this.standalonePropertyValues = state.payload.map((item) => ({
          recordId: item.recordId,
          entityPropertyId: item.entityPropertyId,
          configs: item.configs,
          propertyName: item.propertyName,
          type: item.type,
          value: item.value,
          options: item.options,
          option: item.option,
          fixedPropertyKey: item?.fixedPropertyKey,
          entityReferencePropertyId: item?.entityReferencePropertyId,
          optionValue: item?.optionValue,
        }));
      });

    this.store.pipe(select(getListPropertyForCardsSelector), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (!state) return;

      this.isLoadingListDetailView = state.isLoading;

      if (state?.isLoading || !state?.success || !state?.payload) return;

      this.lengthPg = state.total ?? 0;

      const listDetailViewComponent = this.card?.cardComponents?.find(
        (comp) => comp?.componentType === EntityProfileComponentType['List - Detail View'],
      );
      const listDataMappingId =
        listDetailViewComponent?.cardProperties?.map((prop) => ({
          id: this._getColumnName(prop),
          type: prop.type,
          config: prop.config,
          displayFieldType: prop.displayFieldType,
        })) ?? [];
      this.listCardDataOrigin = state.payload || [];
      this.listCardData = (state.payload || [])?.map((item: any) => {
        const obj: any = {};
        item?.values?.forEach((element: any, index: any) => {
          let columnName = element.entityReferencePropertyId ? element.entityReferencePropertyId : '';
          if (element.entityPropertyId) {
            columnName = columnName ? columnName + '_' + element.entityPropertyId : element.entityPropertyId;
          }

          if (element.option) {
            columnName = columnName + '_' + element.option;
          }

          const options = listDataMappingId.find((item) => item.id === columnName);

          obj[`${columnName}`] = getEntityValue(element, options);
        });
        return { ...obj, id: item.id, noOfAttachments: item?.noOfAttachments, values: item.values };
      });

      this.selectedRecordData = this.listCardDataOrigin ? this.listCardDataOrigin[this.currentRowIndex] : [];

      if (this.listCardData.length && this.attachmentType === IncludeAttachmentType.ListRecord) {
        this._getEntityAttachmentAction(false, this.listCardData[0]?.id);
        this.targetId = this.listCardData ? this.listCardData[0]?.id : '';
      }
    });

    this.store
      .pipe(select(fromMember.getMemberEntityAttachmentsSelector), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (!state) return;

        this.isLoadingAttachments = state.isLoading;

        if (state?.isLoading || !state?.success || !state?.payload) return;

        this.attLengthPg = state.total ?? 0;
        this.attachments = state.payload.map((att) => ({
          ...att,
          attachment: att?.fileSnapshot?.fileName,
        }));
      });

    this.store.pipe(select(fromReducer.selectCurrentFundState), takeUntil(this.unsubscribe$)).subscribe((el) => {
      this.currentFund = el;
      this.pageSize = el.defaultPageSize ?? 50;
      this.attPageSize = el.defaultPageSize ?? 50;

      const defaultPageSize = Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST));
      const defaultAttPageSize = Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_ATT_CONST));
      this.pageSize = defaultPageSize === 0 ? this.pageSize : defaultPageSize;
      this.attPageSize = defaultAttPageSize === 0 ? this.attPageSize : defaultAttPageSize;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.profileConfigOverview || changes.card) && this.profileConfigOverview && this.card) {
      // Create breadcrumbs
      if (this.profileConfigOverview?.navigations?.length) {
        this.isNoProfileConfigured = false;

        // TODO check logic get screenId later
        // Get screenId
        if (this.isOverview) {
          this.screenId = `${this.profileConfigOverview.profileName?.replace(/\s/g, '')}_overview_`;
        } else {
          const currentMenu = this.profileConfigOverview.navigations?.find(
            (x) => x.menuItems.find((item) => item.id === this.menuId) != null,
          );
          const menuName = currentMenu?.name;
          const menuItemName = currentMenu?.menuItems?.find((x) => x.id === this.menuId)?.name;
          this.screenId = `${this.profileConfigOverview.profileName?.replace(/\s/g, '')}_${menuName?.replace(
            /\s/g,
            '',
          )}_${menuItemName?.replace(/\s/g, '')}_`;
        }

        // Build breadcrumbs
        const breadcrumbs = [];
        const menuItems = this.profileConfigOverview.navigations
          .map((item) => item.menuItems)
          .reduce((a, b) => a.concat(b));
        this.pageTitle =
          menuItems.find((item) => item?.entityViewId === this.profileConfigOverview?.entityViewId)?.name ??
          this.profileConfigOverview?.overviewLabel ??
          '';

        breadcrumbs.push({
          name: this.pageTitle,
          url: '',
        });
        this.listBreadcrumbs = breadcrumbs;

        //#region Get Detail section data
        const isProperty = !!this.card?.cardComponents?.some(
          (comp) => comp.componentType === EntityProfileComponentType.Properties && comp.cardProperties?.length > 0,
        );
        const isList = !!this.card?.cardComponents?.some(
          (comp) =>
            comp.componentType === EntityProfileComponentType['List - Detail View'] ||
            comp.componentType === EntityProfileComponentType['List - Summary View'],
        );
        this.isVersionHistory = !!this.card?.cardComponents?.some(
          (comp) => comp.componentType === EntityProfileComponentType['Version History'],
        );

        if (isProperty && isList) {
          this.viewType = ViewType.ListAndProperty;
        } else if (isProperty) {
          this.viewType = ViewType.PropertyOnly;
        } else {
          this.viewType = ViewType.ListOnly;
        }

        this.isList = this.viewType === ViewType.ListAndProperty || this.viewType === ViewType.ListOnly;

        //
        const dataStandAloneProperties = this.card?.cardComponents?.filter(
          (comp) => comp?.componentType === EntityProfileComponentType.Properties,
        );
        this.detailSectionLabel = dataStandAloneProperties?.length ? dataStandAloneProperties[0].detailViewLabel : '';
        this.standaloneProperties = dataStandAloneProperties ?? [];
        this.standaloneViewType = dataStandAloneProperties?.length
          ? dataStandAloneProperties[0].propertyDisplay
          : PropertyDisplay.Column;

        //
        this.displayRule = this.card?.displayRule ?? StandalonePropertiesDisplayRule.None;
        this.isEditable = this.card?.displayRule !== StandalonePropertiesDisplayRule.None;

        //
        if (this.viewType === ViewType.ListAndProperty || this.viewType === ViewType.PropertyOnly) {
          this._getStandAlonePropertyValues();
        }
        //#endregion

        //
        const listDetailViewComponent = this.card?.cardComponents?.find(
          (comp) => comp.componentType === EntityProfileComponentType['List - Detail View'],
        );
        // this.getListEntity(listDetailViewComponent?.cardProperties || []);
        this.listDetailViewProperties = listDetailViewComponent?.cardProperties ?? [];
        this.listDetailViewLabel = this.card.listLabel;

        //
        if (this.isList) {
          this._getListEntityDataAction();
        }
        if (this.isVersionHistory) {
          this._getListEntityDataAction(false, VersionHistory);
        }

        // Attachments
        this.attachmentType = this.card?.attachmentType;

        // List data
        this.isDragDrop = this.card?.isDragDrop;

        this.columns = [];
        listDetailViewComponent?.cardProperties?.forEach((column: any) => {
          const columConfig = getColumConfig(getEntityPropertyName(column.type), column.config);

          this.columns.push({
            name: this._getColumnName(column),
            header: {
              title: column.propertyLabel,
            },
            truncate: true,
            sortable: !this.isDragDrop,
            type: [
              ColumnType.Address,
              ColumnType.PersonName,
              ColumnType.Binary,
            ].includes(columConfig.type as ColumnType) ? ColumnType.Text : columConfig.type,
            align: column.type === PropertyType.TYPE_REGISTER ? Align.Center : undefined,
            templateArgs: columConfig.templateArgs,
          });
        });

        if (this.attachmentType === IncludeAttachmentType.ListRecord) {
          this.columns.push({
            name: 'noOfAttachments',
            header: {
              title: 'Attachment',
            },
            align: Align.Right,
          });
        }
      } else {
        this.isNoProfileConfigured = true;
      }
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private _getStandAlonePropertyValues() {
    const request: GetStandAlonePropertyOnlyCardDataRequest = {
      cardId: this.card?.cardId ?? '',
      targetId: this.targetId,
      entityId: this.entityId,
      screenId: this.screenId,
    };

    this.store.dispatch(getStandAlonePropertyOnlyCardDataAction({ request }));
  }

  private _getColumnName(column: CardProperty): string {
    let columnName = '';
    columnName = column.propertyId;
    if (column.entityReferencePropertyId) {
      columnName = columnName + '_' + column.entityReferencePropertyId;
    }

    if (column.option) {
      columnName = columnName + '_' + column.option;
    }

    return columnName;
  }

  private _getListEntityDataAction(jumpToFirst?: boolean, fixedCardName?: string): void {
    if (jumpToFirst) {
      this.pageNumber = FIRST_PAGE;
    }

    let request: GetListPropertyForCardsRequest = {};
    if (this.isVersionHistory) {
      request = {
        entityId: this.entityId,
        targetId: this.targetId,
        cardId: this.card?.cardId,
        // isSummary: this.isSummaryView, // TODO cuongnh20
        fixedCardName: fixedCardName,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
    } else {
      request = {
        entityId: this.entityId,
        targetId: this.targetId,
        cardId: this.card?.cardId,
        // isSummary: this.isSummaryView, // TODO cuongnh20
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
    }

    if (this.sortInfo) {
      const field = this.sortInfo.active[0].toUpperCase() + this.sortInfo.active.substr(1);
      request = {
        ...request,
        sortNames: this.sortInfo.direction ? field : '',
        sortType: this.sortInfo.direction === 'asc' ? SortType.ASC : SortType.DESC,
        screenId: this.screenId,
      };
    }

    this.store.dispatch(getListPropertyForCardsAction({ request }));
  }

  //#region Detail section
  onClickEditDetail(): void {
    const dialogRef = this.dialog.open(CreateEntityDataComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      autoFocus: false,
      data: {
        section: this.card,
        memberId: this.targetId,
        // cardData: this.standAlonePropertyValue, // TODO cuongnh20
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === 'submit') {
        // this.getValueStandAlonePropertyAction(); // TODO cuongnh20
      }
    });
  }
  //#endregion

  //#region Selected Record
  onClickEditRecordSelected(row: Row): void {
    const dialogRef = this.dialog.open(CreateEntityDataComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      autoFocus: false,
      data: {
        section: {
          cardId: this.card?.cardId,
          cardName: this.card?.cardName,
          entityId: this.entityId,
          entityComponentId: row.values[0].entityComponentId,
        },
        // isSummaryView: this.isSummaryView, // TODO cuongnh20
        cardData: row.values,
        recordId: row.id,
        memberId: this.targetId,
        isList: true,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      // if (result === 'submit') {
      //   this.bannerType = BannerType.Success;
      //   this.entityStore.dispatch(clearUpdateEntityListDataStateAction());
      //   showBanner.call(this, this.bannerType, this.cardName, ACTION.EDIT);
      //   this.getListEntityDataAction();
      // } else if (result === 'submitFail') {
      //   this.bannerType = BannerType.Fail;
      //   this.entityStore.dispatch(clearUpdateEntityListDataStateAction());
      //   showBanner.call(this, this.bannerType, this.cardName, ACTION.EDIT);
      // }
    });
  }

  onClickRemoveRecordSelected(row: Row): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        type: ConfirmType.Delete,
        title: 'Remove Record',
        cancelButtonTitle: 'No',
        text: 'Are you sure you want to remove this record?',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.entityStore.dispatch(
          deleteEntityListDataAction({
            query: {
              id: row.id,
              entityComponentId: row.values[0].entityComponentId,
            },
          }),
        );
      }
    });
  }
  //#endregion

  //#region Manage Attachment
  onChangePageManageAttachments(event: PageEvent): void {
    this.attPageSize = event.pageSize;
    this.attPageNumber = event.pageNumber;

    this._getEntityAttachmentAction(false, this.targetId);
  }

  private _getEntityAttachmentAction(jumpToFirst?: boolean, targetId?: string): void {
    if (jumpToFirst) {
      this.attPageNumber = FIRST_PAGE;
    }

    let request: GetMemberEntityAttachmentsRequest = {
      targetId: targetId,
      memberId: this.targetId,
      pageNumber: this.attPageNumber,
      pageSize: this.attPageSize,
    };

    if (this.sortInfo) {
      const field = this.sortInfo.active[0].toUpperCase() + this.sortInfo.active.substr(1);
      request = {
        ...request,
        sortNames: this.sortInfo.direction ? field : '',
        sortType: this.sortInfo.direction === 'asc' ? SortType.ASC : SortType.DESC,
      };
    }

    this.store.dispatch(getMemberEntityAttachmentsAction({ request }));
  }
  //#endregion

  //#region List data
  changePaggingListView(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;

    sessionStorage.setItem(this.currentFund.key + PAGE_SIZE_CONST, this.pageSize.toString());

    if (this.isVersionHistory) {
      this._getListEntityDataAction(false, VersionHistory);
    } else {
      this._getListEntityDataAction();
    }
  }

  openMetaDataForm(section: any, addToTop: boolean = true, isAddNew: boolean = true) {
    const dialogRef = this.dialog.open(CreateEntityDataComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      autoFocus: false,
      data: {
        entityComponentId: '',
        section: {
          cardId: this.card?.cardId,
          cardName: this.listDetailViewLabel,
          entityId: this.card?.entityId,
          entityComponentId: this.card?.cardComponents?.find(
            ({ componentType }) =>
              componentType ===
              (isAddNew ? EntityProfileComponentType['List - Summary View'] : EntityProfileComponentType.Properties),
          )?.componentId,
        },
        // isSummaryView: this.isSummaryView, // TODO cuongnh20
        isAddRecord: isAddNew,
        addToTop,
        memberId: this.targetId,
        isList: true,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === 'submit') {
        this._getListEntityDataAction();
      }
    });
  }

  manageSection() {
    // this.router.navigateByUrl(`/entity-management/entities/${
    //   this.entityId
    // }/cards/${
    //   this.cardId
    // }/list-data/${
    //   this.memberId
    // }?attachmentType=${
    //   this.attachmentType
    // }&isSummaryView=${
    //   this.isSummaryView
    // }&viewName=${
    //   this.viewName
    // }&cardName=${
    //   this.listLabel
    // }&isBenefitCard=${
    //   this.isBenefitCard
    // }&attachmentLabel=${
    //   this.cardName
    // }&viewUrl=${
    //   encodeURIComponent(`/member/detail-view/${this.isOverview}/${this.viewId}/${this.memberId}`)
    // }`);
  }

  sortChangeListData(event: any): void {
    this.sortInfo = event;
  }

  selectRow(event: any): void {
    this.targetId = event.id;
    this.selectedRecordData = this.listCardDataOrigin?.find((item: any) => item?.id === event?.id);
    if (this.attachmentType === IncludeAttachmentType.ListRecord) {
      this._getEntityAttachmentAction(true, event.id);
    }
  }
  //#endregion
}
