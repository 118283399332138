import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'M/d/y',
  },
  display: {
    dateInput: 'MM/dd/y',
    monthYearLabel: 'MMMM y',
    dateA11yLabel: 'DDD',
    monthYearA11yLabel: 'MMMM y'
  },
};

@Component({
  selector: 'ptg-input-datetime',
  templateUrl: './input-datetime.component.html',
  styleUrls: ['./input-datetime.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: LuxonDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
  ]
})

export class InputDatetimeComponent implements OnInit {

  @Input() controlField!: AbstractControl | any;
  @Input() placeholder!: string;
  @Input() errorRequire?: string;
  @Input() errorInvalid?: string;
  @Input() errorMinDate = 'Invalid Date format';
  @Input() errorMaxDate = 'Invalid Date format';
  @Input() class?: string;
  @Input() minDate = new Date(1799, 11, 31);
  @Input() maxDate = new Date(9999, 11, 31);

  constructor() { }

  ngOnInit(): void {
  }

}
