import { createAction, props } from '@ngrx/store';

import {
  GetRetirementBenefitDocumentsResponse,
  GetRetirementBenefitRequest,
  GetRetirementBenefitHistoriesResponse,
  GetRetirementBenefitDownloadDocumentRequest,
  GetRetirementBenefitDownloadDocumentResponse,
  GetValidateBeforeRemovingDisabilityBenefitsRequest,
  GetValidateBeforeRemovingDisabilityBenefitsResponse,
  RemoveDisabilityBenefitHistoryRequest,
} from '../../services/models/retirement-benefit-history.model';
import { CalculationType } from '../../types/enums';
import { CreateRetirementBenefitRequest } from '../../services/models/retirement-benefit-dialog.model';

export const getRetirementBenefitHistoriesAction = createAction(
  '[GetRetirementBenefitHistories/API] Send Request',
  props<{ request: GetRetirementBenefitRequest; memberId: string; calculationType: CalculationType }>(),
);

export const getRetirementBenefitHistoriesSuccessAction = createAction(
  '[GetRetirementBenefitHistories/API] Success',
  props<{ response: GetRetirementBenefitHistoriesResponse }>(),
);

export const getRetirementBenefitHistoriesFailureAction = createAction(
  '[GetRetirementBenefitHistories/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetRetirementBenefitHistoriesStateAction = createAction('[GetRetirementBenefitHistories] Clear');

export const removeCalculationBenefitAction = createAction(
  '[RemoveCalculationBenefit/API] Send Request',
  props<{ id: string; memberId: string }>(),
);
export const removeCalculationBenefitSuccessAction = createAction('[RemoveCalculationBenefit/API] Success');
export const removeCalculationBenefitFailureAction = createAction(
  '[RemoveCalculationBenefit/API] Failure',
  props<{ error?: any }>(),
);

export const clearRemoveCalculationBenefitAction = createAction('[ClearRemoveCalculationBenefit] Clear');

export const getRetirementBenefitDocumentsAction = createAction(
  '[GetRetirementBenefitDocuments/API] Send Request',
  props<{
    request: GetRetirementBenefitRequest;
    memberId: string;
    calculationType: CalculationType;
    calculationBenefitId?: string;
  }>(),
);

export const getRetirementBenefitDocumentsSuccessAction = createAction(
  '[GetRetirementBenefitDocuments/API] Success',
  props<{ response: GetRetirementBenefitDocumentsResponse }>(),
);

export const getRetirementBenefitDocumentsFailureAction = createAction(
  '[GetRetirementBenefitDocuments/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetRetirementBenefitDocumentsStateAction = createAction('[GetRetirementBenefitDocuments] Clear');

export const getRetirementBenefitDownloadDocumentAction = createAction(
  '[GetRetirementBenefitDownloadDocument/API] Send Request',
  props<{ request: GetRetirementBenefitDownloadDocumentRequest }>(),
);

export const getRetirementBenefitDownloadDocumentSuccessAction = createAction(
  '[GetRetirementBenefitDownloadDocument/API] Success',
  props<{ response: Blob[] }>(),
);

export const getRetirementBenefitDownloadDocumentFailureAction = createAction(
  '[GetRetirementBenefitDownloadDocument/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetRetirementBenefitDownloadDocumentStateAction = createAction(
  '[GetRetirementBenefitDownloadDocument] Clear',
);

export const initiateSurvivorAction = createAction(
  '[InitiateSurvivor/API] Send Request',
  props<{ body: CreateRetirementBenefitRequest; id: string }>(),
);

export const initiateSurvivorSuccessAction = createAction(
  '[InitiateSurvivor/API] Success',
  props<{ retirementBenefitId: string; calculationType: CalculationType, benefitEntityId?: string }>(),
);

export const initiateSurvivorFailureAction = createAction('[InitiateSurvivor/API] Failure', props<{ error?: any }>());

export const clearInitiateSurvivorStateAction = createAction('[ClearInitiateSurvivor] Clear');

export const getValidateBeforeRemovingDisabilityBenefitsAction = createAction(
  '[GetValidateBeforeRemovingDisabilityBenefits/API] Send Request',
  props<{ request: GetValidateBeforeRemovingDisabilityBenefitsRequest }>(),
);

export const getValidateBeforeRemovingDisabilityBenefitsSuccessAction = createAction(
  '[GetValidateBeforeRemovingDisabilityBenefits/API] Success',
  props<{ response: GetValidateBeforeRemovingDisabilityBenefitsResponse }>(),
);

export const getValidateBeforeRemovingDisabilityBenefitsFailureAction = createAction(
  '[GetValidateBeforeRemovingDisabilityBenefits/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetValidateBeforeRemovingDisabilityBenefitsStateAction = createAction(
  '[GetValidateBeforeRemovingDisabilityBenefits] Clear',
);

export const removeDisabilityBenefitHistoriesAction = createAction(
  '[RemoveDisabilityBenefitHistories/API] Send Request',
  props<{ request: RemoveDisabilityBenefitHistoryRequest }>(),
);

export const removeDisabilityBenefitHistoriesSuccessAction = createAction(
  '[RemoveDisabilityBenefitHistories/API] Success',
);

export const removeDisabilityBenefitHistoriesFailureAction = createAction(
  '[RemoveDisabilityBenefitHistories/API] Failure',
  props<{ error?: any }>(),
);

export const clearRemoveDisabilityBenefitHistoriesStateAction = createAction(
  '[RemoveDisabilityBenefitHistories] Clear',
);
