import { createAction, props } from "@ngrx/store";
import {
  CreateProfileRequest,
  GetProfileConfigsQuery,
  ProfileConfigDetailResponse,
  ProfileConfigListResponse,
  ProfileExistRequest,
  ProfileMenuResponse,
  StatusEventResponse,
  UpdateProfileRequest
} from "../../types/models/profile-configuration.model";
import { GetEntityResponse } from "@ptg-member/types/models/card.model";
import { GetEntitiesResponse } from "@ptg-entity-management/services/models";
export const profileConfigListRequest = '[profileConfigListRequest/API] Send Request';

export const clearProfileConfigState= createAction(
    '[clearProfileConfigState]'
);

export const getProfileConfigList = createAction(
    profileConfigListRequest,
    props<{ query: GetProfileConfigsQuery }>()
);
export const getProfileConfigListSuccess = createAction(
    '[profileConfigListRequest/API] Success',
    props<{ profileConfigList: ProfileConfigListResponse }>()
);
export const getProfileConfigListFailure = createAction(
    '[profileConfigListRequest/API] Failure',
    props<{ error?: any }>()
);
  
export const getEntitiesProfileAction = createAction(
    '[EntitiesProfileEntities/API] Send Request',
    props<{ query: any }>()
);
export const getEntitiesProfileSuccessAction = createAction(
    '[EntitiesProfileEntities/API] Success',
    props<{ response: GetEntitiesResponse }>()
);
export const getEntitiesProfileFailureAction = createAction(
    '[EntitiesProfileEntities/API] Failure',
    props<{ error?: any }>()
);

export const getProfileViewAction = createAction(
    '[ProfileViewEntities/API] Send Request',
    props<{ id: string }>()
);
export const getProfileViewSuccessAction = createAction(
    '[ProfileViewEntities/API] Success',
    props<{ response: any }>()
);
export const getProfileViewFailureAction = createAction(
    '[ProfileViewEntities/API] Failure',
    props<{ error?: any }>()
);

export const getProfileDetailAction = createAction(
    '[ProfileDetailEntities/API] Send Request',
    props<{ id: string }>()
);
export const getProfileDetailSuccessAction = createAction(
    '[ProfileDetailEntities/API] Success',
    props<{ response: ProfileConfigDetailResponse }>()
);
export const getProfileDetailFailureAction = createAction(
    '[ProfileDetailEntities/API] Failure',
    props<{ error?: any }>()
);

export const getProfileHeaderAction = createAction(
    '[ProfileHeaderEntities/API] Send Request',
    props<{ id: string }>()
);
export const getProfileHeaderSuccessAction = createAction(
    '[ProfileHeaderEntities/API] Success',
    props<{ response: any }>()
);
export const getProfileHeaderFailureAction = createAction(
    '[ProfileHeaderEntities/API] Failure',
    props<{ error?: any }>()
);

export const clearProfileViewHeaderAction = createAction(
    '[ClearProfileViewHeaderEntities] Clear',
);

export const getProfileExistAction = createAction(
    '[ProfileExistEntities/API] Send Request',
    props<{ body: ProfileExistRequest }>()
);
export const getProfileExistSuccessAction = createAction(
    '[ProfileExistEntities/API] Success',
    props<{ response: any }>()
);
export const getProfileExistFailureAction = createAction(
    '[ProfileExistEntities/API] Failure',
    props<{ error?: any }>()
);

export const createProfileAction = createAction(
    '[CreateProfileEntities/API] Send Request',
    props<{ query: CreateProfileRequest }>()
);
export const createProfileSuccessAction = createAction(
    '[CreateProfileEntities/API] Success',
    props<{ response: any }>()
);
export const createProfileFailureAction = createAction(
    '[CreateProfileEntities/API] Failure',
    props<{ error?: any }>()
);

export const removeProfileAction = createAction(
    '[removeProfileEntities/API] Send Request',
    props<{ id: string }>()
);
export const removeProfileSuccessAction = createAction(
    '[removeProfileEntities/API] Success',
    props<{ response: any }>()
);
export const removeProfileFailureAction = createAction(
    '[removeProfileEntities/API] Failure',
    props<{ error?: any }>()
);

export const UpdateProfileAction = createAction(
    '[UpdateProfileEntities/API] Send Request',
    props<{ body: UpdateProfileRequest }>()
);
export const UpdateProfileSuccessAction = createAction(
    '[UpdateProfileEntities/API] Success',
    props<{ response: any }>()
);
export const UpdateProfileFailureAction = createAction(
    '[UpdateProfileEntities/API] Failure',
    props<{ error?: any }>()
);

export const getProfileStatusEventAction = createAction(
    '[ProfileStatusEventEntities/API] Send Request',
    props<{ id: string }>()
);
export const getProfileStatusEventSuccessAction = createAction(
    '[ProfileStatusEventEntities/API] Success',
    props<{ response: StatusEventResponse }>()
);
export const getProfileStatusEventFailureAction = createAction(
    '[ProfileStatusEventEntities/API] Failure',
    props<{ error?: any }>()
);

export const getProfileMenuAction = createAction(
    '[ProfileMenuEntities/API] Send Request',
    props<{ id: string }>()
);
export const getProfileMenuSuccessAction = createAction(
    '[ProfileMenuEntities/API] Success',
    props<{ response: ProfileMenuResponse }>()
);
export const getProfileMenuFailureAction = createAction(
    '[ProfileMenuEntities/API] Failure',
    props<{ error?: any }>()
);

