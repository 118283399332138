import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models/base-action-state';

import * as MetadataActions from '../actions/metadata.actions';
import { MetadataSection } from '../../types/models';

export const metadataFeatureKey = 'metadata';
export interface State {
  isLoading: boolean;
  metadata: BaseActionState<MetadataSection[]>;
  newSectionKey: string;
  isContinue: boolean;
  removeState: string;
  createSuccess: boolean;
}

const initialState: State = {
  isLoading: true,
  metadata: {
    isLoading: true,
  },
  newSectionKey: '',
  isContinue: false,
  removeState: '',
  createSuccess: false,
};

export const reducer = createReducer(
  initialState,
  on(MetadataActions.getMetadata, (state, {}) => ({
    ...state,
    isLoading: true,
    metadata: {
      isLoading: true,
    },
  })),
  on(MetadataActions.getMetadataSuccess, (state: State, { metadata }) => ({
    ...state,
    metadata: {
      isLoading: false,
      success: true,
      payload: metadata,
      total: metadata.length,
      error: undefined,
    },
  })),
  on(MetadataActions.getMetadataFailure, (state: State, { error }) => ({
    ...state,
    isLoading: false,
    metadata: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(MetadataActions.clearMetadata, (state, {}) => ({
    ...initialState,
    newSectionKey: '',
    isContinue: false,
    removeState: '',
    createSuccess: false,
  })),
  on(
    MetadataActions.addSectionSuccess,
    (state, { newSectionKey, isContinue }) => ({
      ...state,
      isLoading: false,
      newSectionKey,
      isContinue,
      createSuccess: true,
    })
  ),
  on(MetadataActions.addSectionFailure, (state, { errorMsg }) => ({
    ...state,
    isLoading: false,
  })),
  on(MetadataActions.removeSectionMetadataSuccess, (state) => ({
    ...state,
    removeState: 'Success',
  })),
  on(MetadataActions.removeSectionMetaFailure, (state) => ({
    ...state,
    removeState: 'Fail',
  })),

 

);
