<div class="list-display" [ngClass]="{'noDetail': !dataTable?.length, 'noConfigColumn': (columns?.length === 0 || columns?.length === 1 && isListRecord)}">
    <div class="list-header">
      <span class="title truncate">
        {{ menuItemName }} List
      </span>
      <div class="space-line"></div>
      <div class="edit-btn title" *ngIf="!isBenefitCard" (click)="addNew($event)" 
        [ngClass]="{
          'noConfiguredBtn': (!columns || columns?.length === 0 || columns?.length === 1 && isListRecord),
          'disabled': isLoading
        }">
        <mat-icon>add</mat-icon>
        <span class="title-edit truncate">New {{ menuItemName }}</span>
      </div>
      <div class="edit-btn" *ngIf="dataTable?.length > 0 && isDragDrop" (click)="manageSection($event)" 
        [ngClass]="{
          'noConfiguredBtn': (!columns || columns?.length === 0 || columns?.length === 1 && isListRecord),
          'disabled': isLoading
        }">
        <mat-icon class="member-section-action-icon"> list_alt </mat-icon>
        <span class="label-icon">Manage</span>
      </div>
    </div>
    <ptg-grid
      *ngIf="columns?.length && (!isListRecord || (isListRecord && columns.length > 1))"
      [data]="dataTable"
      [columns]="columns"
      [isLoading]="isLoading"
      [totalRecords]="lengthPg"
      [pageSize]="pageSize"
      [pageNumber]="pageNumber"
      (pageChange)="changePagging($event)"
      (sortChange)="sortChange($event)"
      (rowClick)="selectRow($event)"
      [(currentRowIndex)]="currentRowIndex"
      [errorMessage]="errorMsg"
      [fitToParent]="true"
      [hideScrollbar]="false"
      [notFoundMessage]="'No ' + menuItemName + ' to Display'"
    >
      <ng-template cellContent columnName="noOfAttachments" let-row>
        <a
          (click)="viewAttachment(row)"
          href="javascript:void(0)"
          class="link"
          >{{ row.noOfAttachments }}</a
        >
      </ng-template>
    </ptg-grid>
    <div *ngIf="!isLoading && (!columns || columns?.length === 0 || columns?.length === 1 && isListRecord)" class="list-data-not-found no-columns-display">
      <div class="message">{{ isCourtOrder ? 'List not yet configured' : 'Display has not been configured'}}</div>
    </div>
  </div>
  