<div class="dialog-container">
  <div class="header-title">
    Add New Property
  </div>
  <form class="edit-form" [formGroup]="editForm">
    <div>
      <div class="input-container">
        <ptg-textbox
          [controlField]="editForm.get('propertyName')"
          placeholder="Name"
          [hasLabel]="true"
          [isRequired]="true"
          [errorAsync]="editForm?.get('propertyName')?.touched && editForm?.get('propertyName')?.errors?.['inValidAsync'] && editForm?.get('propertyName')?.errors?.message"
        ></ptg-textbox>

        <ptg-select
          placeholder="Property Type"
          [controlField]="editForm.get('propertyType')"
          [listData]="properties"
          errorRequire="Property Type is required."
          [isOptionObj]="true"
        ></ptg-select>
      </div>
      <div class="toggle-container">
        <div *ngIf="showEncrypted">
          <ptg-toggle-button
            [controlField]="editForm.get('encrypted')"
            label="Encrypted"
          ></ptg-toggle-button>
        </div>
        <div *ngIf="showUnique">
          <ptg-toggle-button
            [controlField]="editForm.get('unique')"
            label="Unique"
          ></ptg-toggle-button>
        </div>
        <div class="lookup-table-select-container" *ngIf="editForm.value.propertyType === PROPERTY_TYPE.Lookup">
          <ptg-select
            placeholder="Lookup Table"
            [controlField]="editForm.get('lookupTableId')"
            [listData]="lookupTableOptions"
            [isOptionObj]="true"
            [isSetMaxWidthSelect]="true"
            customError="lookupEnabledError"
            errorRequire="Lookup Table is required."
          ></ptg-select>
        </div>
      </div>
    </div>

    <div class="row-button">
      <button mat-raised-button type="button" class="primary" (click)="formSubmit$.next(true)">Create & Continue</button>
      <button mat-raised-button type="button" class="secondary" (click)="formSubmit$.next(false)">Create & Add Another</button>
      <button mat-raised-button type="button" class="cancel-button" (click)="onCancel()">Cancel</button>
    </div>
  </form>
</div>
