import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { map, catchError, takeUntil } from 'rxjs/operators';

import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';

import { NextPaymentState } from '../../features/service-provider/store/reducers';
import { NextPaymentService } from '../../features/service-provider/services';

@Component({
  selector: 'ptg-select-bank-popup',
  templateUrl: './payment-select-bank-popup.component.html',
  styleUrls: ['./payment-select-bank-popup.component.scss']
})
export class PaymentSelectBankPopupComponent implements OnInit {
  editForm!: FormGroup;
  listBoardBanks!: any[];
  subscriptions: any[] = [];
  isPayingAgent: boolean = false;
  unsubscribe$ = new Subject<void>();
  
  constructor(
    private store: Store<NextPaymentState>,
    public dialogRef: MatDialogRef<PaymentSelectBankPopupComponent>,
    private nextPaymentService: NextPaymentService,
    private dialog: MatDialog,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.onInitForm();
    this.getSelectBoardBanks();
  }

  getSelectBoardBanks() {
    this.nextPaymentService.getSelectBoardBanks().subscribe(
      (res) => {
        const selectedBank = res.boardBanks.find(x => x.id === this.data?.boardBankId);
        this.editForm.get('boardBankId')?.setValue(selectedBank?.id);
        this.listBoardBanks = res.boardBanks.map(x => {
          const accountNumberLength = x.accountNumber.length;
          return {
            value: x.id,
            displayValue: [x.bankName, accountNumberLength > 4 ? x.accountNumber.substring(accountNumberLength - 4).padStart(accountNumberLength, 'X') : x.accountNumber, x.accountType === 0 ? 'Checking' : 'Savings', ].join(' - ') + (x.payingAgent? ' (Paying Agent)' : ''),
            boardBank: x
          }
        });
      },
      (error) => {
        this.listBoardBanks = [];
        this.editForm.get('boardBankId')?.setValue(null);
      }
    )
  }

  onInitForm() {
    this.editForm = new FormGroup({
      boardBankId: new FormControl(this.data?.boardBankId, {
        validators: Validators.required,
        asyncValidators: this.checkBoardBankDisabledValidator(this.data?.boardBankId)
      })
    })
  }

  onSubmit(): void {
    this.nextPaymentService.checkBoardBankDisabled(this.editForm.get('boardBankId')?.value.trim() as string)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(
      (res: any) => {
        if (res.isDisabled) {
          this.editForm.get('boardBankId')?.setErrors({inValidAsync: true});
          return;
        }
        this.editForm.get('boardBankId')?.setErrors(null);
        if (this.editForm.pending) {
          let sub = this.editForm.statusChanges.subscribe(() => {
            if (this.editForm.valid) {
              this.onSave();
            }
            sub.unsubscribe();
          });
        } else if (this.editForm.valid) {
          this.onSave();
        }
        return;
      },
      () => {
      });
  }

  onSave() {
    const boardBankId = this.editForm.get("boardBankId")?.value;
    const boardBank = this.listBoardBanks.find(bank => bank.value === boardBankId)?.boardBank;
    this.nextPaymentService.boardBankId = boardBank.id;
    this.dialogRef.close({ boardBank });
  }

  onCancel() {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(el => el.unsubscribe());
  }

  checkBoardBankDisabledValidator(editValue?: string) : AsyncValidatorFn {
    // Need obs to call api for check
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (editValue && editValue === control.value.trim()) {
        return of(null);
      }

      return this.nextPaymentService.checkBoardBankDisabled(control.value.trim()).pipe(
        map((response: any) => {
          if (response && response.isDisabled) {
            return {inValidAsync: true};
          }
          return null;
        }),
        catchError((err) => {
          return of(null);
        })
      );
    };
  }
}
