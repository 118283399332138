import { createAction, props } from '@ngrx/store';

import { BodyChangeIndex } from '@ptg-fund-management/models/navigation-page.models';

import {
  ChangeIndexRequest,
  CreateMemberNavigationItem,
  MemberNavigation,
  MemberNavigationList,
  RemoveMemberNavigationItem,
  Section,
  SetMemberNavigationItemRequest,
  SetMemberNavigationRequest,
  Status,
} from '../../types/models';

export const MemberNavigationListRequest =
  '[MemberNavigationList/API] Send Request';
export const MemberNavigationListSuccess = '[MemberNavigationList/API] Success';
export const MemberNavigationListFailure = '[MemberNavigationList/API] Failure';
export const ReorderMemberNavigationListRequest =
  '[ReorderMemberNavigationList/API] Send Request';
export const ReorderMemberNavigationListSuccess =
  '[ReorderMemberNavigationList/API] Success';
export const ReorderMemberNavigationListFailure =
  '[ReorderMemberNavigationList/API] Failure';
export const ClearStateMemberNavigation = '[ClearStateMemberNavigation]';
export const CreateMemberNavigationRequest =
  '[CreateMemberNavigation/API] Send Request';
export const CreateMemberNavigationSuccess =
  '[CreateMemberNavigation/API] Success';
export const CreateMemberNavigationFailure =
  '[CreateMemberNavigation/API] Failure';
export const RemoveMemberNavigationRequest =
  '[RemoveMemberNavigation/API] Send Request';
export const RemoveMemberNavigationSuccess =
  '[RemoveMemberNavigation/API] Success';
export const RemoveMemberNavigationFailure =
  '[RemoveMemberNavigation/API] Failure';
export const MemberNavigationDetailRequest =
  '[MemberNavigationDetail/API] Send Request';
export const MemberNavigationDetailSuccess =
  '[MemberNavigationDetail/API] Success';
export const MemberNavigationDetailFailure =
  '[MemberNavigationDetail/API] Failure';
export const ReorderMemberNavigationItemRequest =
  '[ReorderMemberNavigationItem/API] Send Request';
export const ReorderMemberNavigationItemSuccess =
  '[ReorderMemberNavigationItem/API] Success';
export const ReorderMemberNavigationItemFailure =
  '[ReorderMemberNavigationItem/API] Failure';
export const EditMemberNavigationRequest =
  '[EditMemberNavigation/API] Send Request';
export const EditMemberNavigationSuccess = '[EditMemberNavigation/API] Success';
export const EditMemberNavigationFailure = '[EditMemberNavigation/API] Failure';
export const CreateMemberNavigationItemRequest =
  '[CreateMemberNavigationItem/API] Send Request';
export const CreateMemberNavigationItemSuccess =
  '[CreateMemberNavigationItem/API] Success';
export const CreateMemberNavigationItemFailure =
  '[CreateMemberNavigationItem/API] Failure';
export const EditMemberNavigationItemRequest =
  '[EditMemberNavigationItem/API] Send Request';
export const EditMemberNavigationItemSuccess =
  '[EditMemberNavigationItem/API] Success';
export const EditMemberNavigationItemFailure =
  '[EditMemberNavigationItem/API] Failure';
export const RemoveMemberNavigationItemRequest =
  '[RemoveMemberNavigationItem/API] Send Request';
export const RemoveMemberNavigationItemSuccess =
  '[RemoveMemberNavigationItem/API] Success';
export const RemoveMemberNavigationItemFailure =
  '[RemoveMemberNavigationItem/API] Failure';
export const GetSectionListRequest = '[GetSectionList/API] Send Request';
export const GetSectionListSuccess = '[GetSectionList/API] Success';
export const GetSectionListFailure = '[GetSectionList/API] Failure';

export const GetNonListPropertiesRequestAction =
  '[GetNonListProperties/API] Send Request';
export const GetNonListPropertiesSuccessAction =
  '[GetNonListProperties/API] Success';
export const GetNonListPropertiesFailureAction =
  '[GetNonListProperties/API] Failure';
export const ClearGetNonListPropertiesStateAction =
  '[GetNonListProperties] Clear';

export const GetNonListItemConfigsRequestAction =
  '[GetNonListItemConfigs/API] Send Request';
export const GetNonListItemConfigsSuccessAction =
  '[GetNonListItemConfigs/API] Success';
export const GetNonListItemConfigsFailureAction =
  '[GetNonListItemConfigs/API] Failure';

export const getMemberNavigationList = createAction(
  MemberNavigationListRequest
);
export const getMemberNavigationListSuccess = createAction(
  MemberNavigationListSuccess,
  props<{ memberNavigations: MemberNavigationList }>()
);
export const getMemberNavigationListFailure = createAction(
  MemberNavigationListFailure,
  props<{ error?: any }>()
);
export const reorderMemberNavigationList = createAction(
  ReorderMemberNavigationListRequest,
  props<{ body: ChangeIndexRequest }>()
);
export const reorderMemberNavigationListSuccess = createAction(
  ReorderMemberNavigationListSuccess
);
export const reorderMemberNavigationListFailure = createAction(
  ReorderMemberNavigationListFailure,
  props<{ errorMsg: string }>()
);
export const clearStateMemberNavigation = createAction(
  ClearStateMemberNavigation
);
export const createMemberNavigationRequest = createAction(
  CreateMemberNavigationRequest,
  props<{ menuName: string }>()
);
export const createMemberNavigationSuccess = createAction(
  CreateMemberNavigationSuccess
);
export const createMemberNavigationFailure = createAction(
  CreateMemberNavigationFailure
);
export const removeMemberNavigationRequest = createAction(
  RemoveMemberNavigationRequest,
  props<{ menuId: string }>()
);
export const removeMemberNavigationSuccess = createAction(
  RemoveMemberNavigationSuccess
);
export const removeMemberNavigationFailure = createAction(
  RemoveMemberNavigationFailure
);
export const getMemberNavigationDetail = createAction(
  MemberNavigationDetailRequest,
  props<{ menuId: string }>()
);
export const memberNavigationDetailSuccess = createAction(
  MemberNavigationDetailSuccess,
  props<{ memberNavigation: MemberNavigation; listAllStatus: Status[] }>()
);
export const memberNavigationDetailFailure = createAction(
  MemberNavigationDetailFailure,
  props<{ error?: any }>()
);
export const reorderMemberNavigationItem = createAction(
  ReorderMemberNavigationItemRequest,
  props<{ menuId: string; body: BodyChangeIndex }>()
);
export const reorderMemberNavigationItemSuccess = createAction(
  ReorderMemberNavigationItemSuccess
);
export const reorderMemberNavigationItemFailure = createAction(
  ReorderMemberNavigationItemFailure,
  props<{ error?: any }>()
);
export const editMemberNavigation = createAction(
  EditMemberNavigationRequest,
  props<{ body: SetMemberNavigationRequest }>()
);
export const editMemberNavigationSuccess = createAction(
  EditMemberNavigationSuccess
);
export const editMemberNavigationFailure = createAction(
  EditMemberNavigationFailure,
  props<{ error?: any }>()
);
export const createMemberNavigationItem = createAction(
  CreateMemberNavigationItemRequest,
  props<{ menuId: string; body: CreateMemberNavigationItem }>()
);
export const createMemberNavigationItemSuccess = createAction(
  CreateMemberNavigationItemSuccess
);
export const createMemberNavigationItemFailure = createAction(
  CreateMemberNavigationItemFailure,
  props<{ error?: any }>()
);
export const editMemberNavigationItem = createAction(
  EditMemberNavigationItemRequest,
  props<{ body: SetMemberNavigationItemRequest }>()
);
export const editMemberNavigationItemSuccess = createAction(
  EditMemberNavigationItemSuccess
);
export const editMemberNavigationItemFailure = createAction(
  EditMemberNavigationItemFailure,
  props<{ error?: any }>()
);
export const removeMemberNavigationItem = createAction(
  RemoveMemberNavigationItemRequest,
  props<{ body: RemoveMemberNavigationItem }>()
);
export const removeMemberNavigationItemSuccess = createAction(
  RemoveMemberNavigationItemSuccess
);
export const removeMemberNavigationItemFailure = createAction(
  RemoveMemberNavigationItemFailure,
  props<{ error?: any }>()
);
export const getSectionList = createAction(GetSectionListRequest);
export const getSectionListSuccess = createAction(
  GetSectionListSuccess,
  props<{ listSection: Section[] }>()
);
export const getSectionListFailure = createAction(GetSectionListFailure);
