export enum Validation {
  GreaterThan = 1,
  LessThan = 2
}
export enum ValidationType{
  Age = 1, 
  Property = 2,
  SpecificDate = 3
}

export const ValidationOptions: any[] = 
[
  {value: 1, displayValue: 'Greater than'},
  {value: 2, displayValue: 'Less than'}
]

export const ValidationTypeOptions: any[] = 
[
  {value: 1, displayValue: 'Age'},
  {value: 2, displayValue: 'Date Property'},
  {value: 3, displayValue: 'Specific Date'}
]