import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { BenefitRefundService } from '../../services';
import {
  CalculationRefundRecordResponse,
  CalculationRefundYearResponse,
  CreateInitiateRefundCalculationInformationResponse,
  GetInitiateBenefitValidationsResponse,
  GetRefundCalculationRecordsResponse,
  GetRefundPayeeResponse,
  PayeeListResponse,
  UpdatePayeeInfoResponse,
  ValidateBeforeRemoveCalculationBenefit,
} from '../../services/models';
import {
  createInitiateRefundCalculationInformationAction,
  createInitiateRefundCalculationInformationFailureAction,
  createInitiateRefundCalculationInformationSuccessAction,
  getInitiateBenefitValidationsAction,
  getInitiateBenefitValidationsFailureAction,
  getInitiateBenefitValidationsSuccessAction,
  getRefundCalculationRecordsAction,
  getRefundCalculationRecordsFailureAction,
  getRefundCalculationRecordsSuccessAction,
  getRefundCalculationRecordByIdsAction,
  getRefundCalculationRecordByIdsFailureAction,
  getRefundCalculationRecordByIdsSuccessAction,
  getCalculationRefundYearsAction,
  getCalculationRefundYearsFailureAction,
  getCalculationRefundYearsSuccessAction,
  createReSelectRefundYearsAction,
  createReSelectRefundYearsFailureAction,
  createReSelectRefundYearsSuccessAction,
  getValidateBeforeRemoveCalculationBenefitAction,
  getValidateBeforeRemoveCalculationBenefitFailureAction,
  getValidateBeforeRemoveCalculationBenefitSuccessAction,
  getPayeeListAction,
  getPayeeListSuccessAction,
  getPayeeListFailureAction,
  updatePayeeInformationAction,
  updatePayeeInformationSuccessAction,
  updatePayeeInformationFailureAction,
  getRefundPayeeAction,
  getRefundPayeeSuccessAction,
  getRefundPayeeFailureAction,
} from '../actions';

@Injectable()
export class InitiateBenefitEffect {
  constructor(
    private actions$: Actions,
    private BenefitRefundService: BenefitRefundService,
  ) {}

  //getInitiateBenefitValidations
  getInitiateBenefitValidations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getInitiateBenefitValidationsAction),
      switchMap(({ request }) =>
        this.BenefitRefundService.getInitiateBenefitValidations(request).pipe(
          map((response: GetInitiateBenefitValidationsResponse) =>
            getInitiateBenefitValidationsSuccessAction({ response }),
          ),
          catchError((error) => of(getInitiateBenefitValidationsFailureAction({ error }))),
        ),
      ),
    ),
  );

  //createInitiateRefundCalculationInformation
  createInitiateRefundCalculationInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createInitiateRefundCalculationInformationAction),
      switchMap(({ request }) =>
        this.BenefitRefundService.createInitiateRefundCalculationInformation(request).pipe(
          map((response: CreateInitiateRefundCalculationInformationResponse) =>
            createInitiateRefundCalculationInformationSuccessAction({ response }),
          ),
          catchError((error) => of(createInitiateRefundCalculationInformationFailureAction({ error }))),
        ),
      ),
    ),
  );

  //getRefundCalculationRecords
  getRefundCalculationRecords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRefundCalculationRecordsAction),
      switchMap(({ request }) =>
        this.BenefitRefundService.getRefundCalculationRecords(request).pipe(
          map((response: GetRefundCalculationRecordsResponse) =>
            getRefundCalculationRecordsSuccessAction({ response }),
          ),
          catchError((error) => of(getRefundCalculationRecordsFailureAction({ error }))),
        ),
      ),
    ),
  );

  // GetRefundCalculationRecordByIds
  getRefundCalculationRecordByIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRefundCalculationRecordByIdsAction),
      switchMap(({ request }) =>
        this.BenefitRefundService.getRefundCalculationRecordByIds(request).pipe(
          map((response: CalculationRefundRecordResponse) =>
            getRefundCalculationRecordByIdsSuccessAction({ response }),
          ),
          catchError((error) => of(getRefundCalculationRecordByIdsFailureAction({ error }))),
        ),
      ),
    ),
  );

  // GetCalculationRefundYears
  getCalculationRefundYears$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCalculationRefundYearsAction),
      switchMap(({ request, getRefundYearRequest }) =>
        this.BenefitRefundService.getCalculationRefundYears(request, getRefundYearRequest).pipe(
          map((response: CalculationRefundYearResponse) => getCalculationRefundYearsSuccessAction({ response })),
          catchError((error) => of(getCalculationRefundYearsFailureAction({ error }))),
        ),
      ),
    ),
  );

  //createReSelectRefundYears
  createReSelectRefundYears$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createReSelectRefundYearsAction),
      switchMap(({ request }) =>
        this.BenefitRefundService.createReSelectRefundYears(request).pipe(
          map((response: boolean) => createReSelectRefundYearsSuccessAction({response})),
          catchError((error) => of(createReSelectRefundYearsFailureAction({ error })))
        )
      )
    )
  );

  // Update PayeeInformation
  updatePayeeInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePayeeInformationAction),
      switchMap(({ request }) =>
        this.BenefitRefundService.updatePayeeInformation(request).pipe(
          map((response: UpdatePayeeInfoResponse) => updatePayeeInformationSuccessAction({ response })),
          catchError((error) => of(updatePayeeInformationFailureAction({ error }))),
        ),
      ),
    ),
  );

  // GetPayeeList
  getPayeeList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPayeeListAction),
      switchMap(({ request }) =>
        this.BenefitRefundService.getPayeeList(request).pipe(
          map((response: PayeeListResponse) => getPayeeListSuccessAction({ response })),
          catchError((error) => of(getPayeeListFailureAction({ error }))),
        ),
      ),
    ),
  );

  getRefundPayee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRefundPayeeAction),
      switchMap(({ request }) =>
        this.BenefitRefundService.getRefundPayee(request).pipe(
          map((response: GetRefundPayeeResponse) =>
            getRefundPayeeSuccessAction({ response }),
          ),
          catchError((error) => of(getRefundPayeeFailureAction({ error }))),
        ),
      ),
    ),
  );

  //getValidateBeforeRemoveCalculationBenefit
  getValidateBeforeRemoveCalculationBenefit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getValidateBeforeRemoveCalculationBenefitAction),
      switchMap(({ request }) =>
        this.BenefitRefundService.getValidateBeforeRemoveCalculationBenefit(request).pipe(
          map((response: ValidateBeforeRemoveCalculationBenefit) => getValidateBeforeRemoveCalculationBenefitSuccessAction({ response })),
          catchError((error) => of(getValidateBeforeRemoveCalculationBenefitFailureAction({ error })))
        )
      )
    )
  );

}
