import { createAction, props } from '@ngrx/store';
import { ActionType } from '@ptg-shared/types/models/common.model';
import {
  CalculationDetail,
  ParameterMappingsRequest,
  GetCalculationInputMappingResponse,
  GetCalculationOutputMappingResponse,
  GetCalculationParameterMappingQuery,
  GetFileParametersResponse,
  RetirementBenefitCalculations,
  GetFileParametersRequest,
  UpdateCalculationFileRequest,
  GetListSavePropertyRequest,
  GetListSavePropertyResponse,
} from '../../services/models';
import { ParameterType } from '../../constants';

export const GetCalculationListRequest = '[RetirementBenefitCalculationList/API] Get Calculation List Request';
export const GetCalculationListSuccess = '[RetirementBenefitCalculationList/API] Get Calculation List Success';
export const GetCalculationListFailure = '[RetirementBenefitCalculationList/API] Get Calculation List Failure';
export const ClearRetirementBenefitCalculationState =
  '[RetirementBenefitCalculation] Clear Retirement Benefit Calculation State';

export const GetCalculationDetailRequest = '[GetRetirementBenefitCalculationDetail/API] Request';
export const GetCalculationDetailSuccess = '[GetRetirementBenefitCalculationDetail/API] Success';
export const GetCalculationDetailFailure = '[GetRetirementBenefitCalculationDetail/API] Failure';

export const GetCalculationInputMappingRequest = '[GetCalculationInputMapping/API] Request';
export const GetCalculationInputMappingSuccess = '[GetCalculationInputMapping/API] Success';
export const GetCalculationInputMappingFailure = '[GetCalculationInputMapping/API] Failure';

export const GetCalculationOutputMappingRequest = '[GetCalculationOutputMapping/API] Request';
export const GetCalculationOutputMappingSuccess = '[GetCalculationOutputMapping/API] Success';
export const GetCalculationOutputMappingFailure = '[GetCalculationOutputMapping/API] Failure';

export const UploadCalculationFileRequest = '[RetirementBenefitCalculation/API] Upload Calculation File Request';
export const UploadCalculationFileSuccess = '[RetirementBenefitCalculation/API] Upload Calculation File Success';
export const UploadCalculationFileFailure = '[RetirementBenefitCalculation/API] Upload Calculation File Failure';

export const DeleteCalculationRequest = '[DeleteRetirementBenefitCalculation/API] Send Request';
export const DeleteCalculationSuccess = '[DeleteRetirementBenefitCalculation/API] Success';
export const DeleteCalculationFailure = '[DeleteRetirementBenefitCalculation/API] Failure';

export const ClearCalculationState = '[ClearRetirementBenefitCalculationState] Clear';

export const getCalculationListRequest = createAction(GetCalculationListRequest, props<{ query: any }>());
export const getCalculationListSuccess = createAction(
  GetCalculationListSuccess,
  props<{ retirementBenefitCalculations: RetirementBenefitCalculations }>(),
);
export const getCalculationListFailure = createAction(GetCalculationListFailure, props<{ error: any }>());

export const getCalculationDetailRequest = createAction(
  GetCalculationDetailRequest,
  props<{ calculationId: string }>(),
);
export const getCalculationDetailSuccess = createAction(
  GetCalculationDetailSuccess,
  props<{ calculationDetail: CalculationDetail }>(),
);
export const getCalculationDetailFailure = createAction(GetCalculationDetailFailure);

export const getCalculationInputMappingRequest = createAction(
  GetCalculationInputMappingRequest,
  props<{ query: GetCalculationParameterMappingQuery }>(),
);
export const getCalculationInputMappingSuccess = createAction(
  GetCalculationInputMappingSuccess,
  props<{ calculationInputMapping: GetCalculationInputMappingResponse }>(),
);
export const getCalculationInputMappingFailure = createAction(GetCalculationInputMappingFailure);

export const getCalculationOutputMappingRequest = createAction(
  GetCalculationOutputMappingRequest,
  props<{ query: GetCalculationParameterMappingQuery }>(),
);
export const getCalculationOutputMappingSuccess = createAction(
  GetCalculationOutputMappingSuccess,
  props<{ calculationOutputMapping: GetCalculationOutputMappingResponse }>(),
);
export const getCalculationOutputMappingFailure = createAction(GetCalculationOutputMappingFailure);

export const uploadCalculationFileRequest = createAction(
  UploadCalculationFileRequest,
  props<{ uploadFormData: UpdateCalculationFileRequest; action: ActionType }>(),
);
export const uploadCalculationFileSuccess = createAction(UploadCalculationFileSuccess, props<{ action: ActionType }>());
export const uploadCalculationFileFailure = createAction(
  UploadCalculationFileFailure,
  props<{ error: any; action: ActionType }>(),
);
export const clearRetirementBenefitCalculationState = createAction(ClearRetirementBenefitCalculationState);

export const deleteCalculationRequest = createAction(DeleteCalculationRequest, props<{ calculationId: string }>());
export const deleteCalculationSuccess = createAction(DeleteCalculationSuccess);
export const deleteCalculationFailure = createAction(DeleteCalculationFailure);

export const clearCalculationState = createAction(ClearCalculationState);

export const createParameterMappingAction = createAction(
  '[CreateParameterMappings/API] Send Request',
  props<{ request: ParameterMappingsRequest }>(),
);

export const createParameterMappingSuccessAction = createAction(
  '[CreateParameterMappings/API] Success',
  props<{ parameterType: ParameterType }>(),
);

export const createParameterMappingFailureAction = createAction(
  '[CreateParameterMappings/API] Failure',
  props<{ parameterType: ParameterType; error?: any }>(),
);

export const clearParameterMappingStateAction = createAction('[CreateParameterMappings] Clear');

export const getFileParametersAction = createAction(
  '[GetFileParameters/API] Send Request',
  props<{ request: GetFileParametersRequest }>(),
);
export const getFileParametersSuccessAction = createAction(
  '[GetFileParameters/API] Success',
  props<{ response: GetFileParametersResponse }>(),
);
export const getFileParametersFailureAction = createAction('[GetFileParameters/API] Failure', props<{ error?: any }>());
export const clearGetFileParametersAction = createAction(
  '[ClearGetFileParameters] Clear'
);

export const setParameterMappingAction = createAction(
  '[SetParameterMappings/API] Send Request',
  props<{ request: ParameterMappingsRequest }>(),
);

export const setParameterMappingSuccessAction = createAction(
  '[SetParameterMappings/API] Success',
  props<{ parameterType: ParameterType }>(),
);

export const setParameterMappingFailureAction = createAction(
  '[SetParameterMappings/API] Failure',
  props<{ parameterType: ParameterType; error?: any }>(),
);

export const removeParameterMappingAction = createAction(
  '[RemoveParameterMappings/API] Send Request',
  props<{ id: string; parameterType: ParameterType }>(),
);

export const removeParameterMappingSuccessAction = createAction(
  '[RemoveParameterMappings/API] Success',
  props<{ parameterType: ParameterType }>(),
);

export const removeParameterMappingFailureAction = createAction(
  '[RemoveParameterMappings/API] Failure',
  props<{ parameterType: ParameterType; error?: any }>(),
);

export const getListSavePropertyAction = createAction(
  '[GetListSaveProperty/API] Send Request',
  props<{ request: GetListSavePropertyRequest }>(),
);

export const getListSavePropertySuccessAction = createAction(
  '[GetListSaveProperty/API] Success',
  props<{ response: GetListSavePropertyResponse }>(),
);

export const getListSavePropertyFailureAction = createAction(
  '[GetListSaveProperty/API] Failure',
  props<{ error?: any }>(),
);
export const clearGetListSavePropertyAction = createAction(
  '[ClearGetListSaveProperty] Clear'
);
