<div class="deduction-detail">
  <div class="control-bar">
    <span class="name"> Deductions </span>
    <div
      *ngIf="
        !isHistory &&
        (!offCyclePayment ||
          (offCyclePayment &&
            offCyclePayment.paymentInfoType !== PaymentInfoTypeEnum.Reissue &&
            offCyclePayment.paymentStatus === OffCyclePaymentStatusTypeEnum.NotIssued))
      "
      class="edit-btn"
      (click)="onClickEdit()"
    >
      <mat-icon>edit</mat-icon>
      <span class="title-edit"> Edit</span>
    </div>
  </div>
  <div>
    <table mat-table [dataSource]="deductions" class="table-content" [hidden]="offCyclePayment && offCyclePayment.paymentInfoType === PaymentInfoTypeEnum.Reissue">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'border-bottom': element?.isLastDeduction}">
          <span style="display: flex">
            <mat-icon
              *ngIf="element.manualAdjustmented"
              matTooltip="Manually Adjusted"
              matTooltipClass="custom-tooltip">star</mat-icon>
            <span *ngIf="!element?.isTotal" [ngClass]="{'not-star': hasManualAdjusted && !element.manualAdjustmented, 'inactive-value': !offCyclePayment && !element.active}">
              {{
                (element?.deductionCode ? element?.deductionCode : "") +
                  (element?.deductionDescription
                    ? " - " + element?.deductionDescription
                    : "")
              }}
            </span>
            <span *ngIf="element.isTotal" [ngClass]="{'not-star': hasManualAdjusted && !element.manualAdjustmented}">{{ element?.name }}</span>
          </span>
          <span *ngIf="!offCyclePayment && !isHistory && !element.isTotal" class="date-label" 
              [ngClass]="{'not-star': hasManualAdjusted, 'inactive-value': !element.active}">
                {{ 
                  (element.startDate | dateFormat) +  ' - ' + (element.endDate ? (element.endDate | dateFormat) : 'N/A') 
                }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="currentValue">
        <th mat-header-cell *matHeaderCellDef><span *ngIf="deductions?.length">Current</span></th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'border-bottom': element?.isLastDeduction}">
          <span class="dollar-sign" [ngClass]="{'inactive-value': !element.active}">$</span>
          <span [ngClass]="{'inactive-value': !element.active}">{{ element?.value | numberDecimal : { decimal: 2 } }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="YTDValue" *ngIf="paymentInforConfig.DEDUCTIONSYEARTODATE">
        <th mat-header-cell *matHeaderCellDef><span *ngIf="deductions?.length">YTD</span></th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'border-bottom': element?.isLastDeduction}">
          <span class="dollar-sign" [ngClass]="{'inactive-value': !element.active}">$</span>
          <span [ngClass]="{'inactive-value': !element.active}">{{ element?.ytd | numberDecimal : { decimal: 2 } }}</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
