import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GetStepConfigurationResponse } from '@ptg-member/features/calculation/services/models';
import {
  GetDetectRecalculationBenefitResponse, GetValidateBeforeRecalculationBenefitResponse,
} from '@ptg-member/features/calculation/services/models/retirement-benefit-dialog.model';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  createRetirementBenefitAction,
  createRetirementBenefitFailureAction,
  createRetirementBenefitSuccessAction,
  getDetectRecalculationBenefitAction,
  getDetectRecalculationBenefitFailureAction,
  getDetectRecalculationBenefitSuccessAction,
  getStepConfigurationDetailByBenefitOptionAction,
  getStepConfigurationDetailByBenefitOptionFailureAction,
  getStepConfigurationDetailByBenefitOptionSuccessAction,
  getValidateBeforeRecalculationBenefitAction,
  getValidateBeforeRecalculationBenefitFailureAction, getValidateBeforeRecalculationBenefitSuccessAction,
} from '@ptg-member/features/calculation/store';
import { RetirementBenefitDialogService } from '../../services/retirement-benefit-dialog.service';

@Injectable()
export class RetirementBenefitDialogEffect {
  constructor(
    private actions$: Actions,
    private retirementBenefitDialogService: RetirementBenefitDialogService,
  ) {}

  createRetirementBenefit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createRetirementBenefitAction),
      switchMap(({ request }) =>
        this.retirementBenefitDialogService.createRetirementBenefit(request).pipe(
          map((retirementBenefitId) => createRetirementBenefitSuccessAction({ retirementBenefitId })),
          catchError((error) => of(createRetirementBenefitFailureAction({ error }))),
        ),
      ),
    ),
  );

  getStepConfigurationDetailByBenefitOption$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getStepConfigurationDetailByBenefitOptionAction),
      switchMap(({ memberId, benefitEntityId, calculationType }) =>
        this.retirementBenefitDialogService.getStepConfigurationDetailByBenefitOption(memberId, benefitEntityId, calculationType).pipe(
          map((response: GetStepConfigurationResponse) => getStepConfigurationDetailByBenefitOptionSuccessAction({ response })),
          catchError((error) => of(getStepConfigurationDetailByBenefitOptionFailureAction({ error })))
        )
      )
    )
  );

  getDetectRecalculationBenefit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDetectRecalculationBenefitAction),
      switchMap(({ request }) =>
        this.retirementBenefitDialogService.getDetectRecalculationBenefit(request).pipe(
          map((response: GetDetectRecalculationBenefitResponse) => getDetectRecalculationBenefitSuccessAction({ response })),
          catchError((error) => of(getDetectRecalculationBenefitFailureAction({ error })))
        )
      )
    )
  );

  getValidateBeforeRecalculationBenefit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getValidateBeforeRecalculationBenefitAction),
      switchMap(({memberId, calcAsOfDate}) =>
        this.retirementBenefitDialogService.getValidateBeforeRecalculationBenefit(memberId, calcAsOfDate).pipe(
          map((response: GetValidateBeforeRecalculationBenefitResponse) => getValidateBeforeRecalculationBenefitSuccessAction({ response })),
          catchError((error) => of(getValidateBeforeRecalculationBenefitFailureAction({ error })))
        )
      )
    )
  );

}
