<div class="edit-distribution-mapping">
  <div class="header-title">
    <span>Edit Superannuation Mapping</span>
  </div>
  <div class="main">
    <form [formGroup]="editForm" (ngSubmit)="onSubmit()">
      <div class="wrap-btn">
        <button mat-raised-button type="submit" class="save-btn"> Save </button>
        <button mat-raised-button type="button" class="cancel-btn" (click)="onCancelClick()">Cancel</button>
      </div>
      <div class="line" *ngFor="let gr of formArr.controls; let i = index">
        <ptg-select
          style="width: 20%;"
          placeholder="59 1/2"
          [controlField]="gr.get('benefitPeriod')"
          [listData]="listBenefitPeriod"
        ></ptg-select>

        <ptg-select
          style="width: 30%;"
          placeholder="Distribution Code 1"
          [controlField]="gr.get('distributionCode1')"
          [listData]="listDistributionCode"
          [isRequired]="true"
        ></ptg-select>

        <ptg-select
          style="width: 30%;"
          placeholder="Distribution Code 2"
          [controlField]="gr.get('distributionCode2')"
          [listData]="listDistributionCode"
          [isRequired]="true"
        ></ptg-select>

        <ptg-button
          *ngIf="i !== 0"
          buttonName=""
          classInput="remove-button"
          (clickButton)="onClickRemoveRow(i)"
        >
          <mat-icon>delete_forever</mat-icon>
        </ptg-button>
      </div>
    </form>
  </div>
</div>
