<div class="bulk-update">
    <ptg-breadcrumb
        [listBreadcrumbs]="listBreadcrumbs"
        [functionButtons]="functionButtons"
        (emitFunction)="emitFunction()">
    </ptg-breadcrumb>
    <ptg-grid
        [data]="bulkUpdateData"
        [columns]="columns"
        notFoundMessage="No History to Display"
        keyColumn="id"
        [errorMessage]="errorMsg"
        [isLoading]="isLoading"
        [fitToParent]="true"
        [totalRecords]="totalRecords"
        [pageSize]="pageSize"
        [pageNumber]="pageNumber"
        (pageChange)="onChangePage($event)"
        (sortChange)="onChangeSort($event)"
        (rowClick)="showDetail($event)">
        <ng-template cellContent columnName="fileSize" let-row>
            <span>
            {{ row.fileSize | fileSize }}
            </span>
        </ng-template>
        <ng-template cellContent columnName="removeButton" let-row>
            <ptg-button *ngIf="row?.status === 'Invalid' || row?.status === 'Canceled' || row?.status === 'Erroneous'"
                stopPropagation
                buttonName="Remove"
                classInput="remove-button"
                (clickButton)="onRemoveClick(row)">
              <mat-icon>delete_forever</mat-icon>
            </ptg-button>
        </ng-template>
    </ptg-grid>
</div>