import { Component, Inject } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  filter,
  map,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import { LookupTable, MetadataPropertyType } from '@ptg-member/types/models';
import { BaseComponent } from '@ptg-shared/components';
import { Option } from '@ptg-shared/controls/select/select.component';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';

import { EntityPropertyType } from '../../types/enums';
import {
  addPropertySelector,
  getEntitiesSelector,
  getEntityComponentListsSelector,
  getEntityReferencesSelector,
  getPropertyTypesSelector,
} from '../../store/selectors';
import { AddPropertyComponent } from '@ptg-member/components/add-property/add-property.component';
import {
  AddPropertyRequest,
  AggregationType,
  EntityComponentList,
  GetEntityComponentListsRequest,
  GetEntityReferencesRequest,
  GetPropertyTypesRequest,
} from '../../services/models';
import { EditPropertyItemComponent } from '../edit-property-item/edit-property-item.component';
import * as fromMember from '@ptg-member/store/reducers';
import * as LookupTableActions from '@ptg-member/store/actions/lookup-table.actions';
import { EntityService } from '../../services';
import { EditAggregationPropertyItemComponent } from '../edit-aggregation-property-item/edit-aggregation-property-item.component';
import { EditCalculationPropertyItemComponent } from '../edit-calculation-property-item/edit-calculation-property-item.component';
import { EntityState } from '../../store/reducers';
import {
  AddProperty,
  getEntityComponentListsAction,
  getEntityReferencesAction,
  getPropertyTypes,
} from '../../store/actions';
import { EditEntityReferenceComponent } from '../edit-entity-reference/edit-entity-reference.component';

@Component({
  selector: 'ptg-add-property-item',
  templateUrl: './add-property-item.component.html',
  styleUrls: ['./add-property-item.component.scss'],
})
export class AddPropertyItemComponent extends BaseComponent {
  readonly EntityPropertyType = EntityPropertyType;

  editForm: FormGroup = this.fb.group({
    propertyName: this.fb.control('', {
      validators: [Validators.required],
      asyncValidators: checkApiValidator(
        this.entityService.checkPropertyNameExist,
        'name',
        undefined,
        {
          params: {
            entityId: this.data?.entityId,
            entityComponentId: this.data?.entityComponentId,
          },
        }
      ),
    }),
    propertyType: this.fb.control('', [Validators.required]),
    unique: this.fb.control(false),
    encrypted: this.fb.control(false),
    lookupTableId: this.fb.control(''),
    entityReferenceId: this.fb.control(''),
    dataType: this.fb.control(''),
    listId: this.fb.control(''),
    propertyId: this.fb.control(''),
    aggregateId: this.fb.control(''),
  });

  get propertyNameCtrl() {
    return this.editForm?.get('propertyName') as FormControl;
  }
  get propertyTypeCtrl() {
    return this.editForm?.get('propertyType') as FormControl;
  }
  get uniqueCtrl() {
    return this.editForm?.get('unique') as FormControl;
  }
  get encryptedCtrl() {
    return this.editForm?.get('encrypted') as FormControl;
  }
  get lookupTableIdCtrl() {
    return this.editForm?.get('lookupTableId') as FormControl;
  }
  get entityReferenceIdCtrl() {
    return this.editForm?.get('entityReferenceId') as FormControl;
  }
  get dataTypeCtrl() {
    return this.editForm?.get('dataType') as FormControl;
  }
  get listIdCtrl() {
    return this.editForm?.get('listId') as FormControl;
  }
  get propertyIdCtrl() {
    return this.editForm?.get('propertyId') as FormControl;
  }
  get aggregateIdCtrl() {
    return this.editForm?.get('aggregateId') as FormControl;
  }

  properties!: MetadataPropertyType[];
  dataProperties!: MetadataPropertyType[];
  formSubmit$ = new Subject<boolean>();
  isContinue = false;
  propertiesUnique = [
    EntityPropertyType.Currency,
    EntityPropertyType.Text,
    EntityPropertyType.Decimal,
    EntityPropertyType.Email,
    EntityPropertyType['Whole Number'],
    EntityPropertyType.Phone,
    EntityPropertyType.Date,
    EntityPropertyType.Percentage,
    EntityPropertyType.SSN,
  ];
  propertiesEncrypted = [
    EntityPropertyType.Text,
    EntityPropertyType.SSN,
    EntityPropertyType.Phone,
    EntityPropertyType.Email,
    EntityPropertyType.Address,
    EntityPropertyType['Person Name'],
  ];
  showUnique = false;
  showEncrypted = false;
  lookupTableOptions: Option[] = [];
  listOptions: Option[] = [];
  propertyOptions: Option[] = [];
  aggregateOptions: Option[] = [];
  entityReferenceOptions: Option[] = [];
  lookupTables: LookupTable[] | undefined = [];
  entityComponentData: EntityComponentList[] = [];
  entityPropertyId: string = '';
  listParameter = new FormArray([]);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      clientId: string;
      entity: any;
      entityId: string;
      entityComponentId: string;
      addInList: boolean;
    },
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<AddPropertyComponent>,
    public dialog: MatDialog,
    public entityStore: Store<EntityState>,
    private memberStore: Store<fromMember.MemberState>,
    private entityService: EntityService,
    private switchConfirmPopupService: SwitchConfirmPopupService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.getPropertyAction();

    this.getLookupTableListSelector();
    this.getEntityComponentSelector();
    this.getEntitySelector();
    this.getAddPropertyTypeSelector();
    this.getPropertyTypeSelector();
    this.getEntityReferenceSelector();
    this.onSubmitForm();
  }

  getLookupTableListAction(): void {
    this.memberStore.dispatch(
      LookupTableActions.getLookupTableList({
        query: { SortNames: 'Name', SortType: 0 },
      })
    );
  }

  getLookupTableListSelector(): void {
    this.entityStore
      .pipe(select(fromMember.selectLookupTable))
      .subscribe((state) => {
        if (state && !state?.isLoading) {
          this.lookupTableOptions = state?.lookupTableList
            ?.filter((item: any) => item.active)
            ?.map((item: any) => {
              return {
                displayValue: item?.name,
                value: item?.id,
              };
            });
        }
      });
  }

  getEntityComponentAction(): void {
    const request: GetEntityComponentListsRequest = {
      entityId: this.data?.entityId,
    };

    this.entityStore.dispatch(getEntityComponentListsAction({ request }));
  }

  getEntityComponentSelector(): void {
    this.entityStore
      .pipe(select(getEntityComponentListsSelector))
      .subscribe((state) => {
        if (state && !state?.isLoading) {
          this.entityComponentData = state.payload || [];

          this.listOptions = this.entityComponentData?.map((element: any) => {
            return {
              value: element.id,
              displayValue: element.name,
            };
          });
        }
      });
  }

  getEntityReferenceAction(): void {
    const request: GetEntityReferencesRequest = {};

    this.entityStore.dispatch(
      getEntityReferencesAction({
        request,
      })
    );
  }

  getEntityReferenceSelector(): void {
    this.entityStore
      .pipe(select(getEntityReferencesSelector), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state && !state?.isLoading) {
          this.entityReferenceOptions = (state?.payload ?? []).map(
            (element) => {
              return {
                value: element.id,
                displayValue: element.name,
              };
            }
          );

          if (this.listParameter?.length === 1) {
            this.listParameter?.controls[0]
              .get('entityOptions')
              ?.setValue(this.getAvailableEntityReferenceConfigs());
          }
        }
      });
  }

  getPropertyAction(): void {
    const request: GetPropertyTypesRequest = {
      type: 2,
    };

    this.entityStore.dispatch(getPropertyTypes({ request }));
  }

  getEntitySelector(): void {
    this.entityStore.pipe(select(getEntitiesSelector)).subscribe((data) => {
      this.entityReferenceOptions = (data?.payload ?? []).map(
        (element: any) => {
          return {
            value: element.id,
            displayValue: element.name,
          };
        }
      );
    });
  }

  getAddPropertyTypeSelector(): void {
    this.entityStore
      .pipe(select(addPropertySelector), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state && state?.success) {
          if (state?.success && state.payload?.isContinue) {
            this.entityPropertyId = state?.payload?.response;
            const propertyType = state?.payload?.propertyType;

            this.dialogRef.close();

            let dialogRef;
            switch (propertyType) {
              case EntityPropertyType['Entity Reference']:
                dialogRef = this.dialog.open(
                  EditEntityReferenceComponent,
                  {
                  panelClass: 'edit-popup',
                  disableClose: true,
                  autoFocus: false,
                  width: '800px',
                  height: 'auto',
                  data: {
                    entityId: this.data?.entityId,
                    entityComponentId: this.data?.entityComponentId,
                    entityPropertyId: this.entityPropertyId,
                  },
                  }
                );
                break;
              case EntityPropertyType.Aggregation:
                dialogRef = this.dialog.open(
                  EditAggregationPropertyItemComponent,
                  {
                    panelClass: 'edit-popup',
                    disableClose: true,
                    autoFocus: false,
                    width: '800px',
                    height: 'auto',
                    data: {
                      entityId: this.data?.entityId,
                      entityComponentId: this.data?.entityComponentId,
                      entityPropertyId: this.entityPropertyId,
                    },
                  }
                );
                break;
              case EntityPropertyType.Calculation:
                dialogRef = this.dialog.open(
                  EditCalculationPropertyItemComponent,
                  {
                    panelClass: 'edit-popup',
                    disableClose: true,
                    autoFocus: false,
                    width: '800px',
                    height: 'auto',
                    data: {
                      entityId: this.data?.entityId,
                      entityComponentId: this.data?.entityComponentId,
                      entityPropertyId: this.entityPropertyId,
                    },
                  }
                );
                break;
              default:
                dialogRef = this.dialog.open(EditPropertyItemComponent, {
                  panelClass: 'edit-popup',
                  disableClose: true,
                  autoFocus: false,
                  width: '800px',
                  height: 'auto',
                  data: {
                    entityId: this.data?.entityId,
                    entityComponentId: this.data?.entityComponentId,
                    entityPropertyId: this.entityPropertyId,
                  },
                });
                break;
            }
            dialogRef.afterClosed().subscribe((result: any) => {
              if (result) {
                this.dialogRef.close(result);
              }
            });
          } else {
            if (!state?.success) {
              this.dialogRef.close(state);
            }
          }
        }
      });
  }

  getPropertyTypeSelector(): void {
    this.entityStore
      .pipe(
        select(getPropertyTypesSelector),
        map((state) => state?.payload),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((payload) => {
        if (payload) {
          this.properties = (payload.entityPropertyTypes ?? [])
            .filter(
              (item: any) =>
                !this.data.addInList ||
                (this.data.addInList &&
                  item.value !== EntityPropertyType.Aggregation &&
                  item.value !== EntityPropertyType.Calculation)
            )
            .map((element: any) => {
              return {
                value: element.value,
                displayValue: element.displayValue,
              };
            });
          this.dataProperties = (payload.propertyTypes ?? [])
            ?.filter(
              (item: any) =>
                item.value !== EntityPropertyType.Benefit &&
                item.value !== EntityPropertyType.Department &&
                item.value !== EntityPropertyType.Employer
            )
            ?.map((element: any) => {
              return {
                value: element.value,
                displayValue: element.displayValue,
              };
            });
        }
      });
  }

  onValueChangePropertyType(): void {
    this.showEncrypted = false;
    this.showUnique = false;
    this.uniqueCtrl.setValue(null);
    this.encryptedCtrl.setValue(null);
    if (
      this.propertyTypeCtrl?.value !== EntityPropertyType['Entity Reference']
    ) {
      this.listParameter?.clear();
      this.entityReferenceIdCtrl?.setValue('');
      this.entityReferenceIdCtrl?.clearValidators();
      this.entityReferenceIdCtrl?.updateValueAndValidity();
    }

    if (this.propertyTypeCtrl?.value !== EntityPropertyType.Data) {
      this.dataTypeCtrl?.setValue('');
      this.dataTypeCtrl?.clearValidators();
      this.dataTypeCtrl?.updateValueAndValidity();

      this.propertyIdCtrl?.setValue('');
      this.propertyIdCtrl?.clearValidators();
      this.propertyIdCtrl?.updateValueAndValidity();
      
      this.lookupTableIdCtrl?.setValue('');
      this.lookupTableIdCtrl?.clearValidators();
      this.lookupTableIdCtrl?.updateValueAndValidity();
    }

    if (this.propertyTypeCtrl?.value !== EntityPropertyType.Aggregation) {
      this.listIdCtrl?.setValue('');
      this.listIdCtrl?.clearValidators();
      this.listIdCtrl?.updateValueAndValidity();

      this.propertyIdCtrl?.setValue('');
      this.propertyIdCtrl?.clearValidators();
      this.propertyIdCtrl?.updateValueAndValidity();

      this.aggregateIdCtrl?.setValue('');
      this.aggregateIdCtrl?.clearValidators();
      this.aggregateIdCtrl?.updateValueAndValidity();
    }

    if (this.propertyTypeCtrl?.value === EntityPropertyType.Data) {
      this.getLookupTableListAction();
      this.dataProperties = this.dataProperties.filter(item => 
        item?.value !== EntityPropertyType.Identifier
      );
    }

    if (
      this.propertyTypeCtrl?.value === EntityPropertyType['Entity Reference']
    ) {
      this.listParameter = new FormArray([]);
      this.getEntityReferenceAction();
      if (this.listParameter.length === 0) {
        this.listParameter.push(
          new FormGroup({
            entityId: new FormControl('', {
              validators: [Validators.required],
            }),
            entityOptions: new FormControl(''),
          })
        );
      }
    }

    if (this.propertyTypeCtrl?.value === EntityPropertyType.Aggregation) {
      this.getEntityComponentAction();
    }
  }

  onValueChangeDataType(): void {
    this.uniqueCtrl?.setValue(false);
    this.encryptedCtrl?.setValue(false);
    this.showUnique = this.propertiesUnique.includes(this.dataTypeCtrl?.value);
    this.showEncrypted = this.propertiesEncrypted.includes(
      this.dataTypeCtrl?.value
    );
    this.uniqueCtrl?.enable({ emitEvent: false });
    this.encryptedCtrl?.enable({ emitEvent: false });
    if (this.dataTypeCtrl?.value === EntityPropertyType.SSN) {
      this.uniqueCtrl?.setValue(true);
      this.encryptedCtrl?.setValue(true);
      this.encryptedCtrl?.disable({ emitEvent: false });
    }
    if (this.dataTypeCtrl?.value !== EntityPropertyType.Lookup) {
      this.lookupTableIdCtrl?.setValue('');
      this.lookupTableIdCtrl?.clearValidators();
      this.lookupTableIdCtrl?.setErrors(null);
    }
    if (this.dataTypeCtrl?.value !== EntityPropertyType['Entity Reference']) {
      this.lookupTableIdCtrl?.setValue('');
      this.lookupTableIdCtrl?.clearValidators();
      this.lookupTableIdCtrl?.setErrors(null);
    }
  }

  onSubmitForm(): void {
    this.formSubmit$
      .pipe(
        tap((isContinue) => {
          this.isContinue = isContinue;
            this.editForm.markAllAsTouched();
          this.listParameter?.markAllAsTouched();
            this.editForm.get('propertyName')?.updateValueAndValidity();
        }),
        switchMap(() =>
              this.editForm.statusChanges.pipe(
                startWith(this.editForm.status),
                filter((status) => status !== AbstractControlStatus.PENDING),
                take(1)
          )
        ),
        filter((status) => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  onSubmit(): void {
    this.editForm?.markAllAsTouched();
    let body: AddPropertyRequest = {};

    body = {
      name: this.propertyNameCtrl?.value,
      importKey: '',
      configs: {},
    };

    switch (this.propertyTypeCtrl?.value) {
      case EntityPropertyType.Data:
        body.type = this.dataTypeCtrl.value;
        if (this.showUnique) {
          body.configs.unique = this.uniqueCtrl?.value;
        }

        if (this.showEncrypted) {
          body.configs.encrypted = this.encryptedCtrl?.value;
        }

        if (this.dataTypeCtrl.value === EntityPropertyType.Lookup) {
          body.configs.lookupTable = this.lookupTableIdCtrl?.value;
        }

        break;
      case EntityPropertyType.Aggregation:
        body.type = EntityPropertyType.Aggregation;
        body.configs.aggregation = {
          entityComponentId: this.listIdCtrl?.value,
          entityPropertyId: this.propertyIdCtrl?.value,
          aggregationType: this.aggregateIdCtrl?.value,
        };
        break;
      case EntityPropertyType.Calculation:
        body.type = EntityPropertyType.Calculation;
        break;
      case EntityPropertyType['Entity Reference']:
        body.type = EntityPropertyType['Entity Reference'];
        let arrEntityReferences = this.listParameter?.value.map((item: any) => {
          return {
            entityId: item?.entityId,
          };
        });

        body.configs.entityReference = {
          entityReferences: arrEntityReferences,
        };
        break;
      case EntityPropertyType.Identifier:
        body.type = EntityPropertyType.Identifier;
        break;
    }

    if (this.editForm?.pending || this.listParameter?.pending) {
      const sub = this.editForm.statusChanges.subscribe(() => {
        if (this.editForm?.valid && this.listParameter?.valid) {
          this.saveData(body);
        }
        sub.unsubscribe();
      });
    } else if (this.editForm?.valid && this.listParameter?.valid) {
      this.saveData(body);
      if (!this.isContinue) {
        this.editForm.reset();
        this.listParameter?.reset();
        this.showUnique = false;
        this.showEncrypted = false;
        this.propertyOptions = [];
      }     
    }
  }

  saveData(body: AddPropertyRequest): void {
    if (!this.editForm?.valid || !this.listParameter.valid) {
      return;
    }

    this.entityStore.dispatch(
      AddProperty({
        request: body,
        isContinue: this.isContinue,
        entityId: this.data?.entityId,
        entityComponentId: this.data?.entityComponentId,
      })
    );
    this.aggregateOptions = [];
  }

  onCancel(): void {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }

  onChangeListOption(): void {
    this.propertyOptions = [];

    let property = this.entityComponentData?.find(
      (item: any) => item.id === this.listIdCtrl.value
    )?.entityProperties;

    this.propertyOptions = property
      ?.filter(
        (ele: any) =>
          ele.type !== EntityPropertyType.Aggregation &&
          ele.type !== EntityPropertyType.System &&
          ele.type !== EntityPropertyType.Calculation
      )
      ?.map((item: any) => {
        return {
          value: item.id,
          displayValue: item.name,
        };
      });
  }

  onChangePropertyType(): void {
    if (this.propertyOptions.length > 0) {
      let property = this.entityComponentData?.find(
        (item: any) => item.id === this.listIdCtrl?.value
      )?.entityProperties;

      let propertyType = property?.find(
        (item: any) => item.id === this.propertyIdCtrl?.value
      ).type;

      if (
        propertyType === EntityPropertyType.Date ||
        propertyType === EntityPropertyType['Date Time']
      ) {
        this.aggregateOptions = [
          {
            displayValue: 'MIN',
            value: AggregationType.Min,
          },
          {
            displayValue: 'MAX',
            value: AggregationType.Max,
          },
          {
            displayValue: 'COUNT',
            value: AggregationType.Count,
          },
        ];
      } else if (
        propertyType === EntityPropertyType.Currency ||
        propertyType === EntityPropertyType.Percentage ||
        propertyType === EntityPropertyType['Whole Number'] ||
        propertyType === EntityPropertyType.Decimal
      ) {
        this.aggregateOptions = [
          {
            displayValue: 'SUM',
            value: AggregationType.Sum,
          },
          {
            displayValue: 'AVERAGE',
            value: AggregationType.Average,
          },
          {
            displayValue: 'MIN',
            value: AggregationType.Min,
          },
          {
            displayValue: 'MAX',
            value: AggregationType.Max,
          },
          {
            displayValue: 'COUNT',
            value: AggregationType.Count,
          },
        ];
      } else {
        this.aggregateOptions = [
          {
            displayValue: 'COUNT',
            value: AggregationType.Count,
          },
        ];
      }
    } else {
      this.aggregateOptions = [];
    }
  }

  addNewButtonIsValid(): boolean {
    return this.listParameter.length < this.entityReferenceOptions.length && !this.listParameter.getRawValue().some((param) => {
      return param?.entityId === '';
    });
  }

  onClickAddNew() {
    this.listParameter.push(
      new FormGroup({
        entityId: new FormControl('', {
          validators: [Validators.required],
        }),
        entityOptions: new FormControl(
          this.getAvailableEntityReferenceConfigs()
        ),
      })
    );
  }

  onClickRemoveRow(index: number): void {
    this.listParameter.removeAt(index);
    this.listParameter.updateValueAndValidity();
    this.refreshEntityOptions();
  }

  getAvailableEntityReferenceConfigs(entityId?: string) {
    let options: Option[] = [];
    const selectedEntity = this.listParameter.controls.map(
      (item) => item.get('entityId')?.value
    );
    options = this.entityReferenceOptions
      .filter(
        (item) =>
          item.value === entityId ||
          !selectedEntity.some((y) => y === item.value)
      )
      .map(
        (item) =>
          ({
            displayValue: item.displayValue,
            value: item.value,
          } as Option)
      );

    return options;
  }

  refreshEntityOptions(): void {
    let options: FormArray;
    options = this.listParameter;

    options?.controls.forEach((item) => {
      item
        .get('entityOptions')
        ?.patchValue(
          this.getAvailableEntityReferenceConfigs(item.get('entityId')?.value)
        );
    });
  }

  onChangeEntityValue(): void {
    this.refreshEntityOptions();
  }
}
