import { createSelector } from '@ngrx/store';

import { selectCalculationModuleState } from './selector';

export const disabilityBenefitSelector = createSelector(
  selectCalculationModuleState,
  (state) => state?.disabilityBenefit,
);

export const getDisabilityBenefitDetailSelector = createSelector(
  disabilityBenefitSelector,
  (state) => state?.getDisabilityBenefitDetail,
);

export const completeDisabilityBenefitSelector = createSelector(
  disabilityBenefitSelector,
  (state) => state?.completeDisabilityBenefit,
);

export const approveDisabilityBenefitSelector = createSelector(
  disabilityBenefitSelector,
  (state) => state?.approveDisabilityBenefit,
);

export const validateDisabilityBenefitSelector = createSelector(
  disabilityBenefitSelector,
  (state) => state?.validateDisabilityBenefit,
);

export const validateBeforeComputingSelector = createSelector(
  disabilityBenefitSelector,
  (state) => state?.validateDisabilityBenefit,
);

export const computeDisabilityBenefitSelector = createSelector(
  disabilityBenefitSelector,
  (state) => state?.computeDisabilityBenefit,
);

export const initiateDisabilityBenefitSelector = createSelector(
  disabilityBenefitSelector,
  (state) => state?.initiateDisabilityBenefit,
);

export const getTotalDaysUsedSelector = createSelector(disabilityBenefitSelector, (state) => state?.getTotalDaysUsed);
export const removeDisabilityCalculationSelector = createSelector(
  disabilityBenefitSelector,
  (state) => state?.removeDisabilityCalculation,
);

export const getDisabilityCalculationDetailSelector = createSelector(
  disabilityBenefitSelector,
  (state) => state?.getDisabilityCalculationDetail,
);

export const validateRemoveCalculationSelector = createSelector(
  disabilityBenefitSelector,
  (state) => state?.validateDisabilityBenefit,
);

export const editCalculationDetailSelector = createSelector(
  disabilityBenefitSelector,
  (state) => state?.editCalculationDetail,
);
