import { Component, Inject } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, Subject, of, timer } from 'rxjs';
import {
  filter,
  tap,
  switchMap,
  startWith,
  take,
  takeUntil,
  catchError,
  map,
} from 'rxjs/operators';

import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import * as fromMember from '../../store/reducers';
import { AddMenuComponent } from '../add-menu/add-menu.component';
import { BaseComponent } from '@ptg-shared/components';
import { ParticipantNavigationConfigurationAction } from '../../store/actions';
import { ParticipantNavigationConfigurationService } from '@ptg-member/services/participant-navigation-configuration.service';
import {
  CheckExistItemRequest,
  EntityView,
  MenuItems,
} from '@ptg-member/types/models/participant-navigation-configuration.model';
import { deepClone } from '@ptg-shared/utils/common.util';

@Component({
  selector: 'ptg-edit-new-menu-item',
  templateUrl: './edit-new-menu-item.component.html',
  styleUrls: ['./edit-new-menu-item.component.scss'],
})
export class EditNewMenuItemComponent extends BaseComponent {
  addForm!: FormGroup;
  formSubmit$ = new Subject<boolean>();
  listView: EntityView[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddMenuComponent>,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    public participantNavigationConfigurationService: ParticipantNavigationConfigurationService,
    private memberStore: Store<fromMember.MemberState>
  ) {
    super();
  }

  ngOnInit(): void {
    this.getListView();
    this.initFormGroup(this.data.memberNavigationItem);
    this.formSubmit$
      .pipe(
        tap(() => {
          this.addForm.markAllAsTouched();
        }),
        switchMap(() =>
          this.addForm.statusChanges.pipe(
            startWith(this.addForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter((status) => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  getListView() {
    this.memberStore.dispatch(
      ParticipantNavigationConfigurationAction.getViewRequest({entityId: this.data.memberNavigation.entityId})
    );
    this.memberStore
      .select(fromMember.selectParticipantView)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state: any) => {
        if (state?.entityViews.length) {
          this.listView = state.entityViews.map((item: EntityView) => {
            return {
              ...item,
              value: item.id,
              displayValue: item.viewName,
              valueDescription: item.entityName
            };
          });
          let cloneMenuItems =  deepClone(this.data.memberNavigation.menuItems || []);
          if(this.data.memberNavigationItem) {
            cloneMenuItems = cloneMenuItems.filter((value: MenuItems) => value.entityViewId !== this.data.memberNavigationItem.entityViewId);
          }
          this.listView = this.listView.filter(
            (item: EntityView) =>
              !cloneMenuItems?.find(
                (value: MenuItems) => value.entityViewId === item.id
              )
          );
        }
      });
  }

  initFormGroup(formData: any) {
    this.addForm = this.fb.group({
      navigationId: this.fb.control(this.data.memberNavigation.id),
      id: this.fb.control(formData?.id),
      name: this.fb.control(formData?.name, {
        validators: [Validators.required],
        asyncValidators: this.validateNameExisted()
      }),
      entityViewId: this.fb.control(formData?.entityViewId, {
        validators: [Validators.required],
      }),
    });
  }

  validateNameExisted(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return timer(300).pipe(
        switchMap((): Observable<ValidationErrors | null> =>
          this.participantNavigationConfigurationService.checkExitsItem({
            name: control.value.toString(),
            id: this.addForm.get('id')?.value,
            navigationId: this.data.memberNavigation.id
          }).pipe(
            map((res: any) => {
              if (res.existed) {
                return { errorMessageName: 'Menu Item Name already exists.' };
              }
              return null;
            }),
            catchError(({ error }) => {
              return of({ errorMessageName: error?.errorMessage });
            }),
          )
        )
      );
    };
  }

  onSubmit() {
    this.addForm.markAllAsTouched();
    if (!this.addForm.valid) {
      return;
    }
    this.updateData();
  }

  updateData() {
    const body = this.addForm.value;
    if (this.data.memberNavigationItem) {
      this.memberStore.dispatch(
        ParticipantNavigationConfigurationAction.updateNavigationConfigurationItemRequest(
          { body }
        )
      );
    } else {
      this.memberStore.dispatch(
        ParticipantNavigationConfigurationAction.setNavigationConfigurationItemRequest(
          { body }
        )
      );
    }
    this.dialogRef.close();
  }

  onCancel() {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }
}
