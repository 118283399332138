import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { EmployerOverviewHeaderComponent } from '@ptg-employer/components/employer-overview-header/employer-overview-header.component';
import { OverviewHeaderEntityComponent } from '@ptg-member/components/overview-header-entity/overview-header-entity.component';
import { BaseComponent } from '@ptg-shared/components';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ACTION_COLUMN, Column, ColumnType, GridComponent, Row } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { Breadcrumb, FunctionButtonConfig, FunctionButtonData } from '@ptg-shared/types/models/breadcrumb.model';
import { SearchFilterDocumentsComponent } from '../../components/search-filter-documents/search-filter-documents.component';
import { DocumentFilter } from '../../services/models/documents.model';
import { EntityType } from '../../types/enums/entity-type.enum';
import { Store, select } from '@ngrx/store';
import * as fromReducer from '@ptg-reducers';
import { takeUntil } from 'rxjs/operators';
import { FundType } from '@ptg-shared/types/enums';
import { ENTITY_ORGANIZATION_GUID } from '@ptg-shared/constance';
import { DocumentState } from '@ptg-employer/reducers/employer-document.reducer';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';

@Component({
  selector: 'ptg-individual-document-list',
  templateUrl: './individual-document-list.component.html',
  styleUrls: ['./individual-document-list.component.scss']
})
export class IndividualDocumentListComponent extends BaseComponent {
  readonly EntityType = EntityType;
  @ViewChild(OverviewHeaderEntityComponent) overViewHeaderEntity!: OverviewHeaderEntityComponent;
  @ViewChild(EmployerOverviewHeaderComponent) overviewEmployerComponent!: EmployerOverviewHeaderComponent;
  @ViewChild('gridIndividualDocumentList') gridIndividualDocumentList!: GridComponent<any>;
  @Input() dataTable: (any & Row)[] = [];
  @Input() sortInfo: any = {};
  @Input() totalRecords!: number | any;
  @Input() pageSize: number = 50;
  @Input() pageNumber: number = FIRST_PAGE;
  @Input() entityType!: EntityType;
  @Input() entityId!: string;
  @Input() isLoading!: boolean;
  @Input() message!: string;
  @Input() bannerType!: BannerType;
  @Input() isLoadingInfoBanner!: boolean;
  @Input() isHideCloseButton!: boolean;
  @Input() employerName!: string;

  @Output() sortChangeEvent = new EventEmitter();
  @Output() changePaggingEvent = new EventEmitter();
  @Output() uploadDocumentEvent = new EventEmitter();
  @Output() downloadDocumentEvent = new EventEmitter();
  @Output() downloadMultipleDocumentEvent = new EventEmitter();
  @Output() exportDocumentEvent = new EventEmitter();
  @Output() removeMultipleDocumentEvent = new EventEmitter();
  @Output() removeDocumentEvent = new EventEmitter();
  @Output() editDocumentEvent = new EventEmitter();
  @Output() applyFilterEvent = new EventEmitter();
  @Output() generateDocumentEvent = new EventEmitter();
  @Output() onChangeEmployerInHeader = new EventEmitter();
  @Output() bannerTypeChange: EventEmitter<BannerType> = new EventEmitter();

  currentFilters!: DocumentFilter | null;

  isApplyMunicipalityFilter: boolean = false;
  currentFund: any = {};
  columns: Column[] = [
    {
      name: 'documentName',
      header: {
        title: 'Document Name',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'documentTypeName',
      header: {
        title: 'Document Type',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'uploadedDate',
      header: {
        title: 'Uploaded Date',
      },
      type: ColumnType.DateTime,
      templateArgs: {
        format: 'MM/dd/yyyy',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'fileName',
      header: {
        title: 'File Name',
      },
      // type: ColumnType.Link,
      truncate: true,
      sortable: true,
    },
    {
      name: 'documentTags',
      header: {
        title: 'Document Tags',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action'
      },
    }
  ];
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Document List',
    },
  ];
  functionButtons: FunctionButtonConfig[] = [
    {
      buttonName: 'Upload',
      icon: 'upload',
      classInput: 'add-button',
      class: 'export-button',

    },
    {
      buttonName: 'Download',
      icon: 'file_download',
      classInput: 'add-button',
      class: 'export-button',
      isDisabled: true
    },
    {
      buttonName: 'Generate',
      icon: 'file_download',
      classInput: 'add-button',
      class: 'export-button',
    },
    {
      buttonName: 'Remove',
      icon: 'delete_forever',
      classInput: 'add-button',
      class: 'remove-button',
      isDisabled: true
    },
  ];

  FILTER_ACTION = {
    ADD: 'add',
    LOAD: 'load',
  };

  filterOptions: { iconName: string; label: string; value: string }[] = [
    {
      iconName: 'add_circle',
      label: 'New Filter',
      value: this.FILTER_ACTION.ADD,
    },
    {
      iconName: 'upload',
      label: 'Load Filter',
      value: this.FILTER_ACTION.LOAD,
    },
  ];
selectedFilter: string = this.filterOptions[0].value;
  listRecordSelected: any = [];
  constructor(
    private dialog: MatDialog,
    private store: Store<DocumentState>,
    private fundStore: Store<fromReducer.State>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getCurrentFund();
    this.getOverviewEntityId();
    if (this.currentFund.fundType === FundType.ChicagoParks) {
      this.functionButtons[2].isDisabled = true;
    }

    // FIXME apply to 96391 only
    if (this.entityType === EntityType.Employer) {
      this.functionButtons = [
        {
          buttonName: 'Upload',
          icon: 'upload',
          classInput: 'add-button',
          class: 'export-button',

        },
        {
          buttonName: 'Download',
          icon: 'file_download',
          classInput: 'add-button',
          class: 'export-button',
          isDisabled: true
        },
        {
          buttonName: 'Remove',
          icon: 'delete_forever',
          classInput: 'add-button',
          class: 'remove-button',
          isDisabled: true
        },
      ];
    }
  }

  getOverviewEntityId() {
    this.store
      .pipe(select(fromLayoutReducer.selectProfileNavigationState), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state?.menu && state?.menu.length > 0) {
          const overviewView = state.memberNavigationList as any;
          this.entityId = overviewView.entityId;
          if (this.entityId === ENTITY_ORGANIZATION_GUID) {
            this.functionButtons = [
              {
                buttonName: 'Upload',
                icon: 'upload',
                classInput: 'add-button',
                class: 'export-button',

              },
              {
                buttonName: 'Download',
                icon: 'file_download',
                classInput: 'add-button',
                class: 'export-button',
                isDisabled: true
              },
              {
                buttonName: 'Remove',
                icon: 'delete_forever',
                classInput: 'add-button',
                class: 'remove-button',
                isDisabled: true
              },
            ];
          }
        }
      });
  }

  getCurrentFund() {
    this.fundStore.pipe(select(fromReducer.selectCurrentFundState), takeUntil(this.unsubscribe$)).subscribe((el) => {
      this.currentFund = el;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.employerName) {
      this.employerName = changes.employerName.currentValue || '';
    }
  }

  editFilterDialog() {
    // edit filter
    const searchFilterDocumentDialog = this.dialog.open(SearchFilterDocumentsComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: false,
      data: {
        currentFilter: this.currentFilters
      }
    });
    searchFilterDocumentDialog.afterClosed().subscribe((documentFilter: any) => {
      this.currentFilters = documentFilter;
      this.applyFilterEvent.emit(documentFilter);
    });
  }

  clearFilter() {
    this.currentFilters = null;
    this.applyFilterEvent.emit(null);
  }

  onFilterSelect() {
    // Open add new filter popup
    const searchFilterDocumentDialog = this.dialog.open(SearchFilterDocumentsComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: false,
      data: {}
    });
    searchFilterDocumentDialog.afterClosed().subscribe((documentFilter: any) => {
      this.currentFilters = documentFilter;
      this.applyFilterEvent.emit(documentFilter);
    });
    return;
  }

  emitFunction(event: FunctionButtonData) {
    switch (event.buttonName) {
      case 'Upload': {
        this.uploadDocumentEvent.emit();
        break;
      }
      case 'Download': {
        this.downloadMultipleDocumentEvent.emit(this.listRecordSelected);
        break;
      }
      case 'Export': {
        this.exportDocumentEvent.emit();
        break;
      }
      case 'Remove': {
        this.removeMultipleDocumentEvent.emit(this.listRecordSelected);
        break;
      }
      case 'Generate': {
        this.generateDocumentEvent.emit();
        break;
      }
      default: {
        break;
      }
    }
  }

  onChangeSelection() {
    this.listRecordSelected = this.gridIndividualDocumentList.selection.selected
      .filter((row: any & Row) => row.checked && !row.hideCheckBox);
    this.functionButtons[1].isDisabled = !this.gridIndividualDocumentList.selection.selected.length;
    // this.functionButtons[3].isDisabled = !this.gridIndividualDocumentList.selection.selected.length;

    // FIXME apply to 96391 only
    if (this.entityType === EntityType.Employer || this.entityId === ENTITY_ORGANIZATION_GUID) {
      this.functionButtons[2].isDisabled = !this.gridIndividualDocumentList.selection.selected.length;
    } else {
      this.functionButtons[3].isDisabled = !this.gridIndividualDocumentList.selection.selected.length;
    }

    if (this.currentFund.fundType === FundType.ChicagoParks) {
      this.functionButtons[2].isDisabled = true;
    }
  }

  downloadDocument(row: any) {
    this.downloadDocumentEvent.emit(row)
  }

  onEditIndividualDocument(row: any) {
    this.editDocumentEvent.emit(row);
  }

  onRemoveIndividualDocument(row: any) {
    this.removeDocumentEvent.emit(row);
  }

  onChangeSort(event: Sort) {
    this.sortChangeEvent.emit(event);
  }

  onChangePage(event: PageEvent) {
    this.changePaggingEvent.emit(event);
  }

  onChangeEmployerInHeaderEmit() {
    this.onChangeEmployerInHeader.emit();
  }

  closeBanner() {
    this.bannerTypeChange.emit(BannerType.Hidden);
  }
}
