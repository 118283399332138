import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import {
  filter,
  map,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { BaseComponent } from '@ptg-shared/components';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';

import { AddListRequest } from '../../services/models';
import { addSectionSelector } from '../../store/selectors';
import { EntityService } from '../../services';
import { EntityState } from '../../store/reducers';
import { addList } from '../../store/actions';

@Component({
  selector: 'ptg-add-entity-list-item',
  templateUrl: './add-entity-list-item.component.html',
  styleUrls: ['./add-entity-list-item.component.scss'],
})
export class AddEntityListItemComponent extends BaseComponent {
  formSubmit$ = new Subject<boolean>();
  isContinue = false;

  editForm: FormGroup = this.fb.group({
    name: this.fb.control('', {
      validators: [Validators.required],
      asyncValidators: checkApiValidator(
        this.entityService.checkListExists,
        'name',
        undefined,
        {
          params: {
            entityId: this.popupData?.entityId,
          },
        }
      ),
    }),
  });
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public popupData: {
      clientId: string;
      entity: any;
      entityId: string;
    },
    public fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddEntityListItemComponent>,
    public entityStore: Store<EntityState>,
    public router: Router,
    public entityService: EntityService,
    public switchConfirmPopupService: SwitchConfirmPopupService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.emitFormSubmit();

    this.entityStore
      .pipe(
        select(addSectionSelector),
        map((state) => state?.payload),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((payload) => {
        if (payload?.isContinue) {
          this.router.navigateByUrl(
            `/entity-management/entities/entity/${this.popupData?.entityId}/list/${payload?.response}`
          );
          this.dialogRef.close();
        }
      });
  }

  emitFormSubmit(): void {
    this.formSubmit$
      .pipe(
        tap((isContinue) => {
          this.isContinue = isContinue;
          this.editForm.markAllAsTouched();
          this.editForm.get('name')?.updateValueAndValidity();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter((status) => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  onSubmit(): void {
    this.editForm.markAllAsTouched();
    if (!this.editForm.valid) {
      return;
    }
    const body: AddListRequest = Object.assign({
      name: this.editForm.value.name,
    });
    this.entityStore.dispatch(
      addList({
        request: body,
        isContinue: this.isContinue,
        entityId: this.popupData?.entityId,
      })
    );
    if (!this.isContinue) {
      this.editForm.reset({
        name: '',
      });
    }
  }

  onCancel(): void {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }
}
