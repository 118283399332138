export interface AggregationGroupBody {
  groupName: string,
  aggregations: string[]
}

export interface AggregationGroupBodyChangeKey {
  upperAdjacentId?: string | number;
  reorderItemId?: string | number;
}

export interface AggregationGroup {
  id: string;
  groupLabel: string;
  aggregations: string[];
}

export interface AggregationGroupDetails {
  id: string;
  label: string;
  items: [
    {
      order: number;
      name: string;
      value: string,
      aggregationType: number;
      propertyType: number
    }
  ]
}
