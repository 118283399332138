<ptg-generated-document
  [listBreadcrumbs]="listBreadcrumbs"
  [generatedDocumentInfo]="generatedDocumentInfo"
  [columns]="columns"
  [disableSelection]="tableDisableSelection"
  [dataTable]="dataTable"
  [isLoading]="isLoading"
  [pageSize]="pageSize"
  [pageNumber]="pageNumber"
  [totalRecords]="lengthPg"
  [message]="message"
  [bannerType]="bannerType"
  (downloadFileEvent)="onDownloadFile($event)"
  (sortChangeEvent)="sortChange($event)"
  (changePagingEvent)="changePage($event)"
  (attachToProfileEvent)="onAttachToProfile($event)"
></ptg-generated-document>
