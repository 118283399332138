export interface GetTierConfigurationsResponse {
    tiers: Tier[];
    totalCount: number;
}

export interface Tier {
    id: string;
    clientId: string;
    clientKey: string;
    tierCode: string;
    tierName: string;
    salaryCapping: number;
    modifiedBy: string;
    modifiedByName: string;
    modifiedDate: string;
    conditions: TierCondition[];
    plans: TierPlan[];
}

export interface TierCondition {
    id: string;
    propertyKey: string;
    propertyName: string;
    propertyType: number;
    operatorType: number;
    operatorName: string;
    firstValue: string;
    firstValueDisplay: string;
    secondValue: string;
    secondValueDisplay: string;
}

export interface TierPlan {
    id: string;
    code: string;
    name: string;
    employerName: string;
}

export interface SetTierConfiguration {
    tierConfigurations?: any;
}

export interface ParametersQueryConditionLookup {
    type?: number;
    propertyKey: string;
}
export interface PlanResponse {
    plans: PlanDetail[];
}

export interface PlanDetail {
    planId: string;
    code: string;
    name: string;
    employerName: string;
    value?: string,
    valueDescription?: string,
    displayValue?: string,
    selected?: boolean,
}

export interface ConditionPropertiesResponse {
    properties: PropertiesDetail[];
}

export interface PropertiesDetail {
    propertyKey: string;
    propertyName: string;
    propertyType: number;
    sectionName: string;
    value?: string,
    valueDescription?: string,
    displayValue?: string,
    selected?: boolean,
}

export interface ParametersQueryOperators {
    propertyType?: number;
    propertyKey?: string;
}
export interface ConditionOperatorsResponse {
    tierOperators: TierOperatorsDetail[];
}

export interface TierOperatorsDetail {
    operatorType: number;
    operatorName: string;
}

export interface ConditionLookupResponse {
    result: ResultDetail[];
}

export interface ResultDetail {
    id: string;
    subText: string;
    value: string,
    valueDescription?: string,
    displayValue?: string,
}

export interface BinaryValuesResponse {
    result: BinaryDetail[];
}

export interface BinaryDetail {
    value: string;
    valueLabel: string;
}

export interface EditTierRequest {
    salaryCapping: number;
    tierCode: string;
    tierName: string;
    plans: PlansRequest[];
    conditions: ConditionRequest[];
}

export interface PlansRequest {
    planId: string
}

export interface ConditionRequest {
    firstValue: string;
    secondValue: string | null;
    propertyKey: string;
    operatorType: number;
}
export interface ParametersQueryEditRequest {
    id: string;
}

export interface ParametersQueryCheckExits{
    tierId?: string;
    tierCode: string;
    tierName: string;
}

export interface CheckExitsResponsive{
    isCodeExist?: boolean;
    isNameExist?: boolean;
}