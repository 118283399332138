import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { SORT_TYPE } from '@ptg-shared/constance';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { Subject, combineLatest } from 'rxjs';
import { filter, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { BaseComponent } from '@ptg-shared/components';
import { Router } from '@angular/router';
import * as fromMember from '../../store/selectors';
import { Option } from '@ptg-shared/controls/select/select.component';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { NewView } from '../../types/models';
import { GetEntitiesQuery } from '@ptg-entity-management/services/models';
import { LIST_TYPE } from '../../constants';
import { 
  clearProfileViewHeaderAction,
  createProfileAction,
  getEntitiesProfileAction,
  getProfileExistAction,
  getProfileHeaderAction,
  getProfileViewAction 
} from '../../store/actions/profile-configuration.actions';
import { MemberState } from '@ptg-member/store/reducers';

@Component({
  selector: 'ptg-add-profile',
  templateUrl: './add-profile.component.html',
  styleUrls: ['./add-profile.component.scss'],
})
export class AddProfileComponent extends BaseComponent {
  readonly LIST_TYPE = LIST_TYPE;
  formSubmit$ = new Subject<boolean>();
  isContinue = false;
  listEntity: Option[] = [];
  listOverviewView: Option[] = [];
  listOverviewHeader: Option[] = [];
  listSubPageHeader: Option[] = [];
  editForm: FormGroup;
  isLoading: boolean = true;
  usedEntityProfileId: any;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      entityProfile: any;
    },
    public fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddProfileComponent>,
    public memberStore: Store<MemberState>,
    public router: Router,
    public switchConfirmPopupService: SwitchConfirmPopupService
  ) {
    super();

    this.editForm = this.fb.group({
      name: this.fb.control("", {validators: [Validators.required, Validators.maxLength(100)]}),
      entity: this.fb.control("", Validators.required),
      overviewView: this.fb.control("", Validators.required),
      overviewHeader: this.fb.control("", Validators.required),
      subPageHeader: this.fb.control("", Validators.required)
    });
  }

  get nameCtrl() {
    return this.editForm?.get('name') as FormControl;
  }
  get entityCtrl() {
    return this.editForm?.get('entity') as FormControl;
  }
  get overviewViewCtrl() {
    return this.editForm?.get('overviewView') as FormControl;
  }
  get overviewHeaderCtrl() {
    return this.editForm?.get('overviewHeader') as FormControl;
  }
  get subPageHeaderCtrl() {
    return this.editForm?.get('subPageHeader') as FormControl;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.usedEntityProfileId = this.data.entityProfile?.filter((item: any) => item.entityName !== 'Member').map((item: any) => item.entityId);
    this.memberStore.dispatch(clearProfileViewHeaderAction());
    this.getEntityData();
    this.emitFormSubmit();

    this.memberStore.pipe(select(fromMember.getEntitiesProfileSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.listEntity = (data?.payload ?? [])
      .filter((listEntityItem: any) => !(this.usedEntityProfileId.includes(listEntityItem?.id)))
      .map((ele) => {
        return {
          value: ele.id,
          displayValue: ele.name,
        };
      });
      this.isLoading = false;
    });

    combineLatest([
      this.memberStore.pipe(select(fromMember.getProfileViewSelector), takeUntil(this.unsubscribe$)),
      this.memberStore.pipe(select(fromMember.getProfileHeaderSelector), takeUntil(this.unsubscribe$))
    ]).subscribe(([dataOverview, dataHeader]) => {
      this.listOverviewView = (dataOverview?.payload ?? []).map((ele) => {
        return {
          value: ele.id,
          displayValue: ele.viewName,
        };
      });

      const currentHeaderList = (dataHeader?.payload ?? []).map((ele) => {
        return {
          value: ele.id,
          displayValue: ele.profileName,
        };
      });

      this.listSubPageHeader = currentHeaderList;
      this.listOverviewHeader = currentHeaderList;
    });

    this.memberStore.pipe(select(fromMember.createProfileSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data?.success) {
        const usedEntityItem = this.listEntity.filter((item: any) => item.displayValue !== 'Member' && item?.value === this.editForm?.value?.entity) as any;
        this.listEntity = this.listEntity.filter((item: any) => item.value !== usedEntityItem[0]?.value);
      }
    });

    this.memberStore.pipe(select(fromMember.getProfileExistSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data?.success) {
        if(data?.payload?.isExisted) {
          this.editForm.controls["name"]?.setErrors({ inValidAsync: true });
          return;
        } else {
          const body = this.editForm.getRawValue();
          this.memberStore.dispatch(createProfileAction({ query: {
            name: body.name,
            entityId: body.entity,
            entityViewId: body.overviewView,
            headerId: body.overviewHeader,
            subpageHeaderId: body.subPageHeader,
          } }));
        }
      }
    });

    this.memberStore.pipe(select(fromMember.createProfileSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data?.success) {
        if (this.isContinue) {
          this.dialogRef.close();
          this.router.navigateByUrl(`/member/profiles/${data?.payload}`);
        } else {
          this.editForm.reset();
        }
        this.resetOverList();
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy ()
    this.editForm?.reset();
    this.isContinue = false;
    this.memberStore.dispatch(clearProfileViewHeaderAction());
  }

  resetOverList() {
    this.listOverviewView = [];
    this.listOverviewHeader = [];
    this.listSubPageHeader = [];
  }

  changeEntity() {
    const currentEntityValue = this.editForm.get('entity')?.value;

    this.memberStore.dispatch(getProfileViewAction({ id: currentEntityValue }));
    this.memberStore.dispatch(getProfileHeaderAction({ id: currentEntityValue }));
  }

  onSubmit(): void {
    this.editForm.markAllAsTouched();
    if (!this.editForm.valid) {
      return;
    }

    this.memberStore.dispatch(getProfileExistAction({ body: {
      name: this.editForm.get('name')?.value?.trim()
    } }));
  }

  emitFormSubmit(): void {
    this.formSubmit$
      .pipe(
        tap((isContinue) => {
          this.isContinue = isContinue;
          this.editForm.markAllAsTouched();
          this.editForm.get('name')?.updateValueAndValidity();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter((status) => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  getEntityData(): void {
    const query: GetEntitiesQuery = {
      sortField: 'name',
      sortType: SORT_TYPE.ASC,
    };

    this.memberStore.dispatch(getEntitiesProfileAction({ query: query }));
  }

  onCancel(): void {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }
}
