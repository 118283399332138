import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ParameterType } from '@ptg-member/features/calculation/constants';
import { SortType } from '@ptg-shared/constance';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  CheckMasterParameterUsingResponse,
  GetLinkedPropertiesResponse,
  GetPropertiesByLinkedIdResponse,
  GetReferenceByLinkedIdResponse,
  GetRetirementBenefitInputOutputListQuery,
  GetRetirementBenefitInputOutputListResponse,
  MasterInputOutput,
} from './models';
import { CheckExistResponse } from '@ptg-shared/types/models/common.model';
import { deepClone } from '@ptg-shared/utils/common.util';
import { EntityPropertyType } from '@ptg-entity-management/types/enums';

@Injectable({
  providedIn: 'root',
})
export class InputOutputService {
  constructor(private httpClient: HttpClient) {}

  getInputOutputList(
    query: GetRetirementBenefitInputOutputListQuery,
  ): Observable<GetRetirementBenefitInputOutputListResponse> {
    let params = new HttpParams();
    if (query.pageSize) {
      params = params.append('PageSize', query.pageSize);
    }
    if (query.pageIndex) {
      params = params.append('PageIndex', query.pageIndex);
    }
    if (query.sortField) {
      params = params.append('SortNames', capitalizeFirstLetter(query.sortField));
      params = params.append('SortType', query.sortType ?? SortType.ASC);
    }
    if (query.calculationId) {
      params = params.append('CalculationId', query.calculationId);
    }
    return this.httpClient.get<GetRetirementBenefitInputOutputListResponse>(
      `${environment.apiUrl}/calculations/calculation-options/input-outputs`,
      { params },
    );
  }

  checkMasterInputOutputNameExists = (query: { id: string; name: string; type: ParameterType }) => {
    const params = new HttpParams({ fromObject: deepClone(query) });
    return this.httpClient.get<CheckExistResponse>(
      `${environment.apiUrl}/calculations/calculation-options/input-outputs/name/existing`,
      { params },
    );
  };

  addMasterInputOutput(body: MasterInputOutput) {
    return this.httpClient.post(`${environment.apiUrl}/calculations/calculation-options/input-outputs`, body);
  }

  editMasterInputOutput(id: string, body: MasterInputOutput) {
    return this.httpClient.put(`${environment.apiUrl}/calculations/calculation-options/input-outputs/${id}`, body);
  }

  getLinkedProperties(dataType: EntityPropertyType, lookupTableId?: string): Observable<GetLinkedPropertiesResponse> {
    let params = new HttpParams();
    params = params.append('DataType', dataType);
    params = params.append('LookupTableId', lookupTableId ?? '');
    return this.httpClient.get<GetLinkedPropertiesResponse>(
      `${environment.apiUrl}/calculations/calculation-options/input-outputs/linked-properties`,
      { params },
    );
  }

  deleteMasterInputOutput(id: string) {
    return this.httpClient.delete(`${environment.apiUrl}/calculations/calculation-options/input-outputs/${id}`);
  }

  checkMasterParameterUsing(id: string): Observable<CheckMasterParameterUsingResponse> {
    return this.httpClient.get<CheckMasterParameterUsingResponse>(
      `${environment.apiUrl}/calculations/calculation-options/input-outputs/${id}/using`,
    );
  }

  getPropertiesByLinkedId(
    dataType: EntityPropertyType,
    linkedPropertyId?: string,
  ): Observable<GetPropertiesByLinkedIdResponse> {
    let params = new HttpParams();
    params = params.append('DataType', dataType);
    params = params.append('LinkedPropertyId', linkedPropertyId ?? '');
    return this.httpClient.get<GetPropertiesByLinkedIdResponse>(
      `${environment.apiUrl}/calculations/calculation-options/input-outputs/properties-by-linked-property`,
      { params },
    );
  }

  getEntityReferenceByLinkedId(
    dataType: EntityPropertyType,
    linkedPropertyId?: string,
    lookupTableId?: string,
  ): Observable<GetReferenceByLinkedIdResponse> {
    let params = new HttpParams();
    params = params.append('DataType', dataType);
    params = params.append('LinkedPropertyId', linkedPropertyId ?? '');
    params = params.append('LookupTableId', lookupTableId ?? '');
    return this.httpClient.get<GetReferenceByLinkedIdResponse>(
      `${environment.apiUrl}/calculations/calculation-options/input-outputs/linked-entity-reference-properties`,
      { params },
    );
  }
}
