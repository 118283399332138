import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '@ptg-shared/components';
import { BatchResponseStatus } from '@ptg-shared/constance/value.const';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { Align, Column, ColumnType, Row } from '@ptg-shared/controls/grid';

import { LumpSumPaymentInformation } from '../../services/models';
import { getLumpSumPaymentAction } from '../../store/actions';
import { OffCyclePaymentHistoryState } from '../../store/reducers';
import { getLumpSumPaymentSelector } from '../../store/selectors';

const datepipe = new DatePipe('en-US');
@Component({
  selector: 'ptg-lump-sum-payment-list',
  templateUrl: './lump-sum-payment-list.component.html',
  styleUrls: ['./lump-sum-payment-list.component.scss'],
})
export class LumpSumPaymentListComponent extends BaseComponent {
  columns: Column[] = [
    {
      name: 'payingAgentError',
      truncate: true,
      style: {
        color: '#EF4C53',
        width: '30px',
      },
    },
    {
      name: 'depositDate',
      header: {
        title: 'Deposit Date',
      },
      type: ColumnType.DateTime,
      templateArgs: {
        format: 'MM/dd/yyyy',
      },
      truncate: true,
    },
    {
      name: 'totalPayee',
      header: {
        title: 'Total Payees',
      },
      align: Align.Right,
      truncate: true,
    },
    {
      name: 'totalServiceProvider',
      header: {
        title: 'Total Service Providers',
      },
      align: Align.Right,
      truncate: true,
    },
    {
      name: 'gross',
      header: {
        title: 'Gross',
      },
      type: ColumnType.Decimal,
      templateArgs: {
        unit: '$',
        unitPosition: 'left',
      },
      truncate: true,
    },
  ];

  errorMsg: string = '';
  paymentData: (LumpSumPaymentInformation & Row)[] = [];
  isLoading: boolean = false;
  bannerType: BannerType = BannerType.Hidden;
  message: string =
    'There are one or more payments with issues requiring attention. Please click on the highlighted payments to view details.';

  constructor(
    private store: Store<OffCyclePaymentHistoryState>,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getLumpSumPaymentList();
  }

  getLumpSumPaymentList() {
    this.store.dispatch(getLumpSumPaymentAction());
    this.store
      .pipe(select(getLumpSumPaymentSelector), takeUntil(this.unsubscribe$))
      .subscribe((el) => {
        this.paymentData = el.data;
        this.isLoading = el.isLoading;
        this.paymentData = this.paymentData.map((item: any) => {
          return {
            ...item,
            hasError: this.checkPayingAgentError(item),
            iconName: this.checkPayingAgentError(item) ? 'error' : '',
            color: this.checkPayingAgentError(item) ? '#EF4C53' : '',
          };
        });

        this.bannerType = this.paymentData.some((item: any) => item.hasError)
          ? BannerType.Fail
          : BannerType.Hidden;
        if (!el.success && el.error) {
          this.errorMsg = el.error.statusText;
        }
      });
  }

  checkPayingAgentError(payment: LumpSumPaymentInformation) {
    if (payment.boardBank?.payingAgent) {
      let currentDateTime = new Date() as any;
      let submittedDate = new Date(
        `${datepipe.transform(payment.submittedDate, 'MM/dd/yyyy hh:mm a')} UTC`
      ) as any;
      let time = Math.abs(currentDateTime - submittedDate);
      return (
        payment.batchResponseStatus === BatchResponseStatus.Rejected ||
        payment.batchResponseStatus === BatchResponseStatus.Hold ||
        (payment.batchResponseStatus === BatchResponseStatus.Accepted &&
          payment.autoFinalizedError) ||
        (!payment.receiveAcknowledgementFile && time / 3600000 - 1 > 0)
      );
    }
    return false;
  }

  selectPayment(event: any) {
    if (!event?.id) {
      return;
    }
    void this.router.navigateByUrl(
      `/processing/off-cycle-payments/complete/${event?.id}`
    );
  }
}
