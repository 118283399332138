import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import {
  CreateGenerateCalculationWorksheetResponse,
  GetCalculationBenefitDetailsResponse,
} from '../../services/models';
import {
  clearCloseBenefitWarningMessageStateAction,
  clearGetCalculationBenefitDetailsStateAction,
  clearSetBenefitDetailStateAction,
  closeBenefitWarningMessageAction,
  closeBenefitWarningMessageFailureAction,
  closeBenefitWarningMessageSuccessAction,
  getCalculationBenefitDetailsAction,
  getCalculationBenefitDetailsFailureAction,
  getCalculationBenefitDetailsSuccessAction,
  setBenefitDetailAction,
  setBenefitDetailFailureAction,
  setBenefitDetailSuccessAction,
  clearCreateGenerateCalculationWorksheetStateAction,
  createGenerateCalculationWorksheetAction,
  createGenerateCalculationWorksheetFailureAction,
  createGenerateCalculationWorksheetSuccessAction,
} from '../actions';

export interface GetCalculationBenefitDetailsState {
  getGetCalculationBenefitDetails?: BaseActionState<GetCalculationBenefitDetailsResponse>;
  setBenefitDetail?: BaseActionState<{ editItem: string }>;
  closeBenefitDetailWarningMessage?: BaseActionState;
  createGenerateCalculationWorksheet?: BaseActionState<CreateGenerateCalculationWorksheetResponse>;
}

const initialState: GetCalculationBenefitDetailsState = {};

export const getCalculationBenefitDetailsReducer = createReducer(
  initialState,
  on(getCalculationBenefitDetailsAction, (state) => ({
    ...state,
    getGetCalculationBenefitDetails: {
      isLoading: true,
    },
  })),
  on(getCalculationBenefitDetailsSuccessAction, (state, { response }) => ({
    ...state,
    getGetCalculationBenefitDetails: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getCalculationBenefitDetailsFailureAction, (state, { error }) => ({
    ...state,
    getGetCalculationBenefitDetails: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetCalculationBenefitDetailsStateAction, (state) => ({
    ...state,
    getGetCalculationBenefitDetails: undefined,
  })),
  on(setBenefitDetailAction, (state) => ({
    ...state,
    setBenefitDetail: {
      isLoading: true,
    },
  })),
  on(setBenefitDetailSuccessAction, (state, { editItem }) => ({
    ...state,
    setBenefitDetail: {
      isLoading: false,
      success: true,
      payload: { editItem },
    },
  })),
  on(setBenefitDetailFailureAction, (state, { error, editItem }) => ({
    ...state,
    setBenefitDetail: {
      isLoading: false,
      success: false,
      error: error,
      payload: { editItem },
    },
  })),
  on(clearSetBenefitDetailStateAction, (state) => ({
    ...state,
    setBenefitDetail: undefined,
  })),
  on(closeBenefitWarningMessageAction, (state) => ({
    ...state,
    closeBenefitDetailWarningMessage: {
      isLoading: true,
    },
  })),
  on(closeBenefitWarningMessageSuccessAction, (state) => ({
    ...state,
    closeBenefitDetailWarningMessage: {
      isLoading: false,
      success: true,
    },
  })),
  on(closeBenefitWarningMessageFailureAction, (state, { error }) => ({
    ...state,
    closeBenefitDetailWarningMessage: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearCloseBenefitWarningMessageStateAction, (state) => ({
    ...state,
    closeBenefitDetailWarningMessage: undefined,
  })),

  on(createGenerateCalculationWorksheetAction, (state) => ({
    ...state,
    createGenerateCalculationWorksheet: {
      isLoading: true,
    },
  })),
  on(createGenerateCalculationWorksheetSuccessAction, (state, { response }) => ({
    ...state,
    createGenerateCalculationWorksheet: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(createGenerateCalculationWorksheetFailureAction, (state, { error }) => ({
    ...state,
    createGenerateCalculationWorksheet: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearCreateGenerateCalculationWorksheetStateAction, (state) => ({
    ...state,
    createGenerateCalculationWorksheet: undefined,
  }))

);
