import { createSelector } from '@ngrx/store';

import { selectCalculationModuleState } from './selector';

export const retirementBenefitDetailSelector = createSelector(
  selectCalculationModuleState,
  (state) => state?.retirementBenefitDetailDocument,
);

export const getRetirementBenefitDetailDocumentsSelector = createSelector(
  retirementBenefitDetailSelector,
  (state) => state?.getRetirementBenefitDetailDocuments,
);

export const getExceptionListSelector = createSelector(
  retirementBenefitDetailSelector,
  (state) => state?.getExceptionList,
);

export const checkExceptionSelector = createSelector(
  retirementBenefitDetailSelector,
  (state) => state?.checkExceptionResult,
);

export const getCalculationAuditTrailSelector = createSelector(
  retirementBenefitDetailSelector,
  (state) => state?.getCalculationAuditTrailList,
);
