import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import {
  clearCreateCalculationLoddBenefitsStateAction,
  createCalculationLoddBenefitsAction,
  createCalculationLoddBenefitsFailureAction,
  createCalculationLoddBenefitsSuccessAction,

  clearGetLoddValidationBeforeRecalculatesStateAction,
  getLoddValidationBeforeRecalculatesAction,
  getLoddValidationBeforeRecalculatesFailureAction,
  getLoddValidationBeforeRecalculatesSuccessAction,
} from '../actions';
import { CreateCalculationLoddBenefitsResponse, LoddValidationBeforeRecalculateResponse } from '../../services/models';

export interface CalculationLoddBenefitState {
  createCalculationLoddBenefits?: BaseActionState<CreateCalculationLoddBenefitsResponse>;
  getLoddValidationBeforeRecalculates?: BaseActionState<LoddValidationBeforeRecalculateResponse>;
}

const initialState: CalculationLoddBenefitState = {};

export const calculationLoddBenefitReducer = createReducer(
  initialState,
  on(createCalculationLoddBenefitsAction, (state) => ({
    ...state,
    createCalculationLoddBenefits: {
      isLoading: true,
    },
  })),
  on(createCalculationLoddBenefitsSuccessAction, (state, {response}) => ({
    ...state,
    createCalculationLoddBenefits: {
      isLoading: false,
      success: true,
      payload: response
    },
  })),
  on(createCalculationLoddBenefitsFailureAction, (state, { error }) => ({
    ...state,
    createCalculationLoddBenefits: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearCreateCalculationLoddBenefitsStateAction, (state) => ({
    ...state,
    createCalculationLoddBenefits: undefined,
  })),

  // LoddValidationBeforeRecalculatesAction
  on(getLoddValidationBeforeRecalculatesAction, (state) => ({
    ...state,
    getLoddValidationBeforeRecalculates: {
      isLoading: true,
    },
  })),
  on(getLoddValidationBeforeRecalculatesSuccessAction, (state, { response }) => ({
    ...state,
    getLoddValidationBeforeRecalculates: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getLoddValidationBeforeRecalculatesFailureAction, (state, { error }) => ({
    ...state,
    getLoddValidationBeforeRecalculates: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetLoddValidationBeforeRecalculatesStateAction, (state) => ({
    ...state,
    getLoddValidationBeforeRecalculates: undefined,
  }))

);
