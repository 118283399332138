<div class="pagination-container flex justify-between" *ngIf="pager">
  <div class="group-info">
    <ng-container *ngIf="!hiddenPageSizeOptions">
      <mat-form-field appearance="fill" class="pagination-select">
        <mat-select
          [value]="pageSize"
          (selectionChange)="onChangePageSize($event)"
          panelClass="pagination-option"
        >
          <mat-option
            *ngFor="let pageSizeOption of pageSizeOptions"
            [value]="pageSizeOption"
          >
            {{ pageSizeOption }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <div>
      {{ hiddenPageSizeOptions ? pageSize : "" }} row/page showing
      {{ pager.startIndex + 1 }} - {{ pager.endIndex + 1 }} of
      {{ pager.totalRecords }}
    </div>
  </div>

  <div class="group-info">
    <div>
      Page {{ pager.totalPages ? pager.pageNumber : 0 }} of
      {{ pager.totalPages }}
    </div>
  </div>

  <ul class="pagination-navigate" *ngIf="pager">
    <li [ngClass]="{ disabled: pager.pageNumber === FIRST_PAGE }">
      <a (click)="jumpToFirst()" class="page-link">
        <mat-icon>first_page</mat-icon>
      </a>
    </li>
    <li [ngClass]="{ disabled: pager.pageNumber === FIRST_PAGE }">
      <a (click)="jumpToPrev()" class="page-link">
        <mat-icon>navigate_before</mat-icon>
      </a>
    </li>
    <li *ngFor="let pageNumber of pager.pages; let index = index">
      <a
        (click)="jumpTo(pageNumber)"
        [ngClass]="{ active: pager.pageNumber === pageNumber }"
        class="page-link"
      >
        {{ pageNumber }}
      </a>
    </li>
    <li [ngClass]="{ disabled: pager.pageNumber === pager.totalPages || !pager.totalPages }">
      <a (click)="jumpToNext()" class="page-link">
        <mat-icon>navigate_next</mat-icon>
      </a>
    </li>
    <li [ngClass]="{ disabled: pager.pageNumber === pager.totalPages || !pager.totalPages }">
      <a (click)="jumpToLast()" class="page-link">
        <mat-icon>last_page</mat-icon>
      </a>
    </li>
  </ul>
</div>
