import { createSelector } from "@ngrx/store";
import { selectMemberModuleState } from "../reducers";

// view
export const viewListSelector = createSelector(
    selectMemberModuleState,
    (state) => state?.entityView
  );
  
  export const getListSelector = createSelector(
    viewListSelector,
    (state) => state?.getViews
  );
  
  export const createViewSelector = createSelector(
    viewListSelector,
    (state) => state?.createView
  );

  export const getRemoveAccess = createSelector(
    viewListSelector,
    (state) => state?.getRemoveAccess
  );
  
  export const removeViewSelector = createSelector(
    viewListSelector,
    (state) => state?.removeView
  );
  
  export const updateViewSelector = createSelector(
    viewListSelector,
    (state) => state?.updateView
  );
  
  export const getListCardSelector = createSelector(
    viewListSelector,
    (state) => state?.getCards
  );
  
  export const getViewDetailSelector = createSelector(
    viewListSelector,
    (state) => state?.getViewDetail
  )
  
  export const getEntitiesSelector = createSelector(
    viewListSelector,
    (state) => state.getEntities
  );

  export const getViewDatalessSelector = createSelector(
    viewListSelector,
    (state) => state?.getViewDataless
  )

  export const updateViewDatalessSelector = createSelector(
    viewListSelector,
    (state) => state?.updateViewDataless
  );