import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import { CheckExistCheckRetirementDocumentCanRemoveResponse } from '../../services/models';
import {
  clearCheckRetirementDocumentCanRemoveStateAction,
  checkRetirementDocumentCanRemoveAction,
  checkRetirementDocumentCanRemoveFailureAction,
  checkRetirementDocumentCanRemoveSuccessAction,
} from '../actions';

export interface CheckRetirementDocumentCanRemoveState {
  checkExistCheckRetirementDocumentCanRemove?: BaseActionState<CheckExistCheckRetirementDocumentCanRemoveResponse>;
}

const initialState: CheckRetirementDocumentCanRemoveState = {};

export const checkRetirementDocumentCanRemoveReducer = createReducer(
  initialState,
  on(checkRetirementDocumentCanRemoveAction, (state) => ({
    ...state,
    checkExistCheckRetirementDocumentCanRemove: {
      isLoading: true,
    },
  })),
  on(checkRetirementDocumentCanRemoveSuccessAction, (state, { response }) => ({
    ...state,
    checkExistCheckRetirementDocumentCanRemove: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(checkRetirementDocumentCanRemoveFailureAction, (state, { error }) => ({
    ...state,
    checkExistCheckRetirementDocumentCanRemove: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearCheckRetirementDocumentCanRemoveStateAction, (state) => ({
    ...state,
    checkExistCheckRetirementDocumentCanRemove: undefined,
  }))
);