import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { MembersListComponent } from '@ptg-member/containers/members-list/members-list.component';
import { CheckFundService } from '@ptg-shared/services/check-fund.service';
import { CheckPermissionService } from '@ptg-shared/services/check-permission.service';
import { AccidentClaimsComponent } from './pages/accident-claims/accident-claims.component';
import { AccidentDetailComponent } from './pages/accident-detail/accident-detail/accident-detail.component';

export const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: MembersListComponent,
  },
  {
    path: 'accident/:accidentId/:memberId',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: AccidentDetailComponent,
  },
  {
    path: 'accident/:memberId',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: AccidentClaimsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccidentParticipantsRoutingModule {
}
