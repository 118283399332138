import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { BaseComponent } from '@ptg-shared/components';
import { Column, GridComponent, Row } from '@ptg-shared/controls/grid';
import { STATE } from '@ptg-shared/constance/value.const';

import { ScreenType } from '../../types/enums';
import {
  clearCompleteOffCyclePaymentIdAction,
  completeOffCyclePaymentsAction,
} from '../../store/actions';
import { OffCyclePaymentState } from '../../store/reducers';
import { completeOffCyclePaymentIdSelector } from '../../store/selectors';

@Component({
  selector: 'ptg-incomplete-off-cycle',
  templateUrl: './incomplete-off-cycle.component.html',
  styleUrls: ['./incomplete-off-cycle.component.scss'],
})
export class InCompleteOffCycleComponent extends BaseComponent {
  isDisabledBtn: boolean = true;

  @ViewChild('gridOffCycles') gridOffCycles!: GridComponent<any>;

  @Input() offCyclesData: (any & Row)[] = [];
  @Input() columns: Column[] = [];
  @Input() totalRecords: number = 0;
  @Input() pageNumber: number = FIRST_PAGE;
  @Input() pageSize: number = 50;
  @Input() isLoading: boolean = true;
  @Input() errorMsg?: string;

  @Output() sortChange = new EventEmitter<Sort>();
  @Output() pageChange = new EventEmitter<PageEvent>();
  @Output() rowClick = new EventEmitter<any & Row>();

  constructor(
    private store: Store<OffCyclePaymentState>,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.checkCompletedOffCyclePayments();
  }

  onChangeSort(event: Sort): void {
    this.sortChange.emit(event);
  }

  onChangePage(event: PageEvent): void {
    this.pageChange.emit(event);
  }

  onClickRow(row: any & Row): void {
    this.rowClick.emit(row);
  }

  onChangeSelection(): void {
    this.isDisabledBtn = !this.gridOffCycles?.selection?.selected?.filter(
      (row: any & Row) => row.checked && !row.hideCheckBox
    ).length;
  }

  completePayment() {
    this.isDisabledBtn = true;
    const offCyclePaymentIds = this.gridOffCycles?.selection?.selected
      .filter((item: any) => item.checked)
      .map((item: any) => item.id);
    if (!offCyclePaymentIds.length) {
      return;
    }

    this.store.dispatch(completeOffCyclePaymentsAction({ offCyclePaymentIds }));
  }

  onClickProcessDate(row: any & Row): void {
    this.router.navigateByUrl(
      `/processing/off-cycle-payments/complete/${row.offCycleId}?prevScreen=${ScreenType.OffCyclePayments}`
    );
  }

  checkCompletedOffCyclePayments() {
    this.store
      .pipe(
        select(completeOffCyclePaymentIdSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((completeOffCyclePaymentId) => {
        if (completeOffCyclePaymentId === STATE.FAIL) {
          this.isDisabledBtn = false;
        } else if (completeOffCyclePaymentId) {
          void this.router.navigateByUrl(
            `/processing/off-cycle-payments/complete/${completeOffCyclePaymentId}?prevScreen=${ScreenType.OffCyclePayments}`
          );
        }

        this.store.dispatch(clearCompleteOffCyclePaymentIdAction());
      });
  }
}
