import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';

import { BaseComponent } from '@ptg-shared/components';
import * as fromReducer from '@ptg-reducers';
import * as fromMember from '../../store/selectors';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { Subject, combineLatest } from 'rxjs';
import { Option } from '@ptg-shared/controls/select/select.component';
import { filter, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { ActivatedRoute } from '@angular/router';
import { ACTION } from '@ptg-shared/constance';
import { deepClone, showBanner } from '@ptg-shared/utils/common.util';
import { Header } from '../../types/models';
import { MemberState } from '../../store/reducers';
import { ACTION_COLUMN, Align, Column, GridComponent, Row, getValidatorsFromColumns } from '@ptg-shared/controls/grid';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ProfileConfigService } from '../../services/profile-configuration.service';
import { UpdateProfileAction, getProfileDetailAction, getProfileHeaderAction, getProfileMenuAction, getProfileStatusEventAction, getProfileViewAction } from '../../store/actions/profile-configuration.actions';
import { AllStatusOption, NavigationMenu, NewNavigationMenu, NewStatus, NewStatusEvent, ProfileConfigDetail, StatusEvent, UpdateProfileRequest } from '../../types/models/profile-configuration.model';

@Component({
  selector: 'ptg-profile-detail',
  templateUrl: './profile-detail.component.html',
  styleUrls: ['./profile-detail.component.scss'],
})
export class ProfileConfigDetailComponent extends BaseComponent implements OnInit {
  readonly ACTION_COLUMN = ACTION_COLUMN;

  profileDetailData!: ProfileConfigDetail;

  message: string = '';
  profileId!: string;
  leftCard: (NavigationMenu & Row)[] = [];
  bannerType: BannerType = BannerType.Hidden;

  isChanged: boolean = false;
  isLoadingViewCard: boolean = true;
  isLoadingAddMenuItem: boolean = false;
  listOverviewView: Option[] = [];
  listOverviewHeader: Option[] = [];
  listSubPageHeader: Option[] = [];
  @ViewChild('gridLeftCard')
  gridLeftCard?: GridComponent<NavigationMenu>;

  editForm?: FormGroup;
  formSubmit$ = new Subject<boolean>();

  addPropertyForm: FormGroup = this.fb.group({
    menuName: this.fb.control('', [Validators.required]),
    menuLabel: this.fb.control('', [Validators.required]),
  });

  statusSelected = this.fb.control(
    {
      value: {},
      disabled: false,
    },
    [this.validateSelectedStatus(this)]
  );

  listStatusOption: NewStatus[] = [];
  listStatusAll: StatusEvent[] = [];
  listProfileMenu: NavigationMenu[] = [];
  availableMenuConfigs!: Option[];

  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Participant List',
      url: '/member',
    },
    {
      name: 'Profile List',
      url: '/member/profile-configuration',
    },
    {
      name: 'Current Profile Name',
    },
  ];

  configuredPropertyColumns: Column[] = [
    {
      name: 'name',
      truncate: true,
      editable: true,
      validators: {
        required: {
          type: (obj: any) => Validators.required,
          message: (error: any, fieldName: string) =>
            `Label is required.`,
        },
        existed: {
          type: (obj: any) => this.checkExits(obj),
          message: (error: any, fieldName: string) =>
            `Label already exists.`,
        },
        maxlength: {
          type: (obj: any) => Validators.maxLength(100),
          message: (error: any, fieldName: string) =>
            `Exceed the ${error.requiredLength} character limit.`,
        },
      },
    },
    {
      name: ACTION_COLUMN,
      align: Align.Left,
      width: '50px',
    },
  ];

    // Form controls
    get nameCtrl(): FormControl {
      return this.editForm?.get('name') as FormControl;
    }
    get labelCtrl() {
      return this.editForm?.get('label') as FormControl;
    }
    get overviewViewCtrl() {
      return this.editForm?.get('overviewView') as FormControl;
    }
    get overviewHeaderCtrl() {
      return this.editForm?.get('overviewHeader') as FormControl;
    }
    get subPageHeaderCtrl() {
      return this.editForm?.get('subPageHeader') as FormControl;
    }
    get menuNameCtrl() {
      return this.addPropertyForm?.get('menuName') as FormControl;
    }
    get menuLabelCtrl() {
      return this.addPropertyForm?.get('menuLabel') as FormControl;
    }

  constructor(
    private store: Store<fromReducer.State>,
    private dialog: MatDialog,
    public memberStore: Store<MemberState>,
    public profileConfigServive: ProfileConfigService,
    private fb: FormBuilder,
    public route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.profileId = params.id;
    });

    this.initFormGroup();

    // Get view detail
    this.loadDataProfileDetail();

    // Listen for submit form
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm?.markAllAsTouched();
          this.statusSelected.markAsTouched();
          this.statusSelected.updateValueAndValidity();
        }),
        switchMap(() =>
          this.editForm!.statusChanges.pipe(
            startWith(this.editForm!.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter(
          (status) =>
            status === AbstractControlStatus.VALID &&
            this.gridLeftCard?.formStatus === AbstractControlStatus.VALID
        )
      )
      .subscribe(() => {
        this.onSubmit();
      });

    this.memberStore
      .pipe(
        select(fromMember.getProfileStatusEventSelector),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        if (state?.success && state?.payload) {
          this.listStatusAll = state?.payload?.statusEvents;
          this.listStatusOption = this.getAllStatus();
        }

        this.getListStatusOption(this.profileDetailData?.statusEvents);
      });

    this.memberStore
      .pipe(
        select(fromMember.getProfileMenuSelector),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((data) => {
        if (data?.success && data.payload?.navigationMenus) {
          this.listProfileMenu = data.payload?.navigationMenus;
          this.menuNameCtrl?.reset();
        } else {
          this.listProfileMenu = [];
        }

        this.getAvailableMenuConfigs();
      });

    // Listen update status
    this.memberStore.pipe(select(fromMember.updateProfileSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data?.success) {
        this.loadDataProfileDetail(false);
        showBanner.call(this, BannerType.Success, 'Profile', ACTION.EDIT);
      } else if (data?.error) {
        showBanner.call(this, BannerType.Fail, 'Profile', ACTION.EDIT);
      }
      this.addPropertyForm.reset();
    })
    this.store.dispatch(LayoutActions.hiddenSideMenu());
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.editForm?.reset();
    this.addPropertyForm?.reset();
    this.bannerType = BannerType.Hidden;
  }

  changeProperty() {
    const currentPropertyName = this.menuNameCtrl?.value?.name;
    currentPropertyName && this.menuLabelCtrl?.setValue(currentPropertyName);
  }

  getAvailableMenuConfigs() {
    const allPropertyAdded = this.leftCard?.map((item: NavigationMenu & Row) => item?.entityNavigationId ?? item?.menuId);

    this.availableMenuConfigs = this.listProfileMenu.reduce((result: Option[], menuConfig: NavigationMenu) => {
      if (allPropertyAdded.includes(menuConfig.id)) {
        return result;
      } else {
        result.push({
          value: menuConfig,
          displayValue: menuConfig?.name,
        });
        return result;
      }
    }, [] as Option[]);
  }

  getAllStatus() {
    return deepClone(this.listStatusAll) as NewStatus[];
  }

  private checkExits(obj?: any) {
    return (control: AbstractControl): ValidationErrors | null => {
      const currentFieldValue = control?.value?.toLowerCase().trim();

      const existed = this.leftCard.find(item => (
          item?.name !== obj?.name &&
          item?.name?.toLowerCase().trim() === currentFieldValue
        ));

      return existed ? { existed: "Menu Label already exists." } : null;
    };
  }

  onChangeData(): void {
    this.isChanged = true;
  }

  loadDataProfileDetail(isInit = true) {
    this.memberStore.dispatch(getProfileDetailAction({ id: this.profileId }))
    this.memberStore
      .pipe(
        select(fromMember.getProfileDetailSelector),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        if (state?.success && state?.payload?.profile.id) {
          const profileConfigData = state.payload?.profile;
          const { entityId } = profileConfigData;

          if (profileConfigData?.isMember) {
            // Get list status event
            this.memberStore.dispatch(getProfileStatusEventAction({ id: this.profileId }));
          }

          this.initFormGroup(profileConfigData)
          this.profileDetailData = profileConfigData;
          this.listBreadcrumbs[2].name = profileConfigData.name;

          this.leftCard = (this.profileDetailData?.menus ?? [])
            .filter((config: any) => config)
            .sort((a: any, b: any) =>
              a.order > b.order ? 1 : a.order < b.order ? -1 : 0
            )
            ?.map(
              (config: any) => {
                const crrConfig = {
                  ...config,
                  name: config.menuLabel,
                  menuName: config.name
                }
                return ({
                  ...crrConfig,
                  form: this.createInlineEditFormControls(crrConfig),
                } as NavigationMenu & Row);
              }
            );

            if (entityId && isInit) {
              this.getOverViewList(entityId);
              this.memberStore.dispatch(getProfileMenuAction({ id: entityId }));
            }
          this.isLoadingViewCard = false;
        }
      });
  }

  getOverViewList(entityId: string) {
    this.memberStore.dispatch(getProfileViewAction({ id: entityId }));
    this.memberStore.dispatch(getProfileHeaderAction({ id: entityId }));

    combineLatest([
      this.memberStore.pipe(select(fromMember.getProfileViewSelector), takeUntil(this.unsubscribe$)),
      this.memberStore.pipe(select(fromMember.getProfileHeaderSelector), takeUntil(this.unsubscribe$))
    ]).subscribe((data) => {

      this.listOverviewView = (data[0]?.payload ?? [])?.map((ele) => {
        return {
          value: ele.id,
          displayValue: ele.viewName,
        };
      });

      const currentHeaderList = (data[1]?.payload ?? [])?.map((ele) => {
        return {
          value: ele.id,
          displayValue: ele.profileName,
        };
      });

      this.listSubPageHeader = currentHeaderList;
      this.listOverviewHeader = currentHeaderList;
    });
  }

  onCancel(): void {
    this.isChanged = false;
    this.editForm?.reset();
    this.addPropertyForm.reset();
    this.loadDataProfileDetail(false);
  }

   private getProfileOverviewConfigs(
    data: (NavigationMenu & Row)[]
  ): (NewNavigationMenu & Row)[] {
    let order: number = 1;
    return data?.map((config: (NavigationMenu & Row)) => {
      // Always exists
      const newConfig: NewNavigationMenu & Row = {
        ...(config?.id ? { id: config?.id } : {}),
        profileId: this.profileDetailData.id ?? "",
        entityNavigationId:  config?.entityNavigationId ?? config.menuId,
        name: config.menuName,
        menuLabel: config.name,
        order: config.deleted ? -1 : order,
        isRemoved: config.isRemoved,
      };

      if (!config.deleted) order++;
      return newConfig;
    });
  }

  onSubmit(): void {
    this.profileConfigServive.getProfileExist({
      id: this.profileDetailData?.id,
      name: this.nameCtrl?.value?.trim()
    }).pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
      if (!res?.isExisted) {
        this.isLoadingViewCard = true;
        const formValue = this.editForm?.value;

        const leftSections = (this.getProfileOverviewConfigs(
          this.gridLeftCard!?.dataSource
        ) ?? []).filter(item => !(!item?.id && item.isRemoved));

        const prevStatusEve = this.profileDetailData.statusEvents?.map((item => item.id));
        let lstStatus = formValue?.statuses?.map((status: NewStatusEvent) => ({
          profileId: this.profileDetailData?.id,
          statusEventId: status.id,
          ...(prevStatusEve.includes(status.id) ? { id: status.id } : {}),
        }));

        const metadataProfileOverview: UpdateProfileRequest = {
          id: this.profileDetailData.id ?? "",
          name: this.nameCtrl.value.trim(),
          entityId: this.profileDetailData.entityId,
          entityViewId: this.overviewViewCtrl?.value,
          headerId: this.overviewHeaderCtrl?.value,
          subPageHeaderId: this.subPageHeaderCtrl?.value,
          overviewLabel: this.labelCtrl?.value?.trim(),
          lstMenu: leftSections.filter(section => section.order !== -1),
          ...(this.profileDetailData?.isMember ? { lstStatus } : {})
        };

        this.memberStore.dispatch(UpdateProfileAction({
          body: metadataProfileOverview
        }))
      } else {
        this.nameCtrl.setErrors({ inValidAsync: true });
      }
    }, (error) => {
      if (error) {
        showBanner.call(this, BannerType.Fail, 'Profile', ACTION.EDIT);
      }
    })
  }

  private createInlineEditFormControls(
    config: NavigationMenu & Row
  ): FormGroup {
    return this.fb.group({
      name: new FormControl(
        config?.name,
        getValidatorsFromColumns('name', this.configuredPropertyColumns, config)
      ),
    });
  }

  // Initial form group when edit profile overview detail
  private initFormGroup(data?: ProfileConfigDetail): void {
    this.editForm = this.fb.group({
      name: new FormControl(data?.name ?? "", [
        Validators.required
      ]),
      label: new FormControl(data?.overviewLabel ?? "", [
        Validators.required
      ]),
      overviewView: this.fb.control(data?.entityViewId  ?? "", Validators.required),
      overviewHeader: this.fb.control(data?.headerId ?? "", Validators.required),
      subPageHeader: this.fb.control(data?.subPageHeaderId ?? "", Validators.required)
    });

    this.nameCtrl.clearAsyncValidators();
  }

  validateSelectedStatus(
    that: ProfileConfigDetailComponent
  ): ValidatorFn {
    return (): ValidationErrors | null => {
      if (
        !that.editForm?.controls['statuses']?.touched ||
        that.editForm?.value?.statuses?.length
      ) {
        return null;
      }
      return { required: true };
    };
  }

  getListStatusOption(listSelectedStatus: StatusEvent[]) {
    const listSelectedStatusForm = [...(listSelectedStatus ?? [])]?.sort(
      (a, b) => (`${a.statusName}${a.statusEventName}`).localeCompare((`${b.statusName}${b.statusEventName}`))
    );

    this.editForm?.setControl(
      'statuses',
      this.fb.array(listSelectedStatusForm, Validators.required)
    );

    const listStatus = this.getAllStatus()?.filter(
      (status) =>
        !listSelectedStatus?.find(
          (selected) =>
            (selected?.id?.toLowerCase() === status?.id?.toLowerCase())
        )
    ).filter(item => item?.id);

    const optionAll = listStatus.reduce((acc: NewStatus[], statusOption: NewStatus, index, crrStatusOption) => {
      if (acc.find(item => item.id === statusOption.id)) {
        return acc;
      }

      if (statusOption.isNotShowAllText === false) {
        const groupStatusEvent = listStatus.filter(item => (item.statusName === statusOption.statusName && item.isNotShowAllText === false))
        return [...acc, { isShowAllOption: true, statusName: statusOption.statusName, statusEventName: `${statusOption.statusName} - All` }, ...groupStatusEvent];
      } else {
        return [...acc, statusOption];
      }
    }, []);

    const optionAllText = optionAll.filter(item => item?.isShowAllOption).sort((a, b) => a.statusName.localeCompare(b.statusName));
    const optionObb = optionAll.filter(item => !item?.isShowAllOption).sort(
      (a, b) => (`${a.statusName}${a.statusEventName}`).localeCompare((`${b.statusName}${b.statusEventName}`))
    );

    this.listStatusOption = [...optionAllText, ...optionObb];
  }

  addStatus() {
    const currentStatusSelected = this.statusSelected.value;
    if (!this.statusSelected?.value.id && !currentStatusSelected?.isShowAllOption) {
      return;
    }

    let newStatusEvent: NewStatus[] = [];
    if (currentStatusSelected?.isShowAllOption) {
      newStatusEvent = this.listStatusOption.filter(item => item.statusName === currentStatusSelected.statusName && item.isNotShowAllText === false)
    } else {
      newStatusEvent = [currentStatusSelected];
    }

    const listStatusSelected = [
      ...JSON.parse(JSON.stringify((this.editForm?.value.statuses ?? []))),
      ...newStatusEvent,
    ];
    this.isChanged = true;
    this.statusSelected.setValue({});
    this.getListStatusOption(listStatusSelected);
    this.editForm?.controls['statuses'].markAsTouched();
    this.statusSelected.updateValueAndValidity({ emitEvent: false });
  }

  removeStatus(deletedStatus: StatusEvent) {
    const deletedIndex = this.editForm?.value.statuses.findIndex(
      (status: StatusEvent) =>
        status?.id?.toLowerCase() === deletedStatus?.id?.toLowerCase()
    );
    if (deletedIndex > -1) {
      const listSelectedStatus = deepClone(this.editForm?.value.statuses);
      const currentDeleteSelected: StatusEvent & AllStatusOption = listSelectedStatus[deletedIndex];
      listSelectedStatus.splice(deletedIndex, 1);
      if (this.profileDetailData.statusEvents.find(item => item.id === currentDeleteSelected.id)) {
        this.listStatusAll = [...this.listStatusAll, currentDeleteSelected];
        this.listStatusAll = [...new Map(this.listStatusAll.map(v => [JSON.stringify(v), v])).values()];
      }
      this.getListStatusOption([...listSelectedStatus]);
      this.editForm?.controls['statuses'].markAsTouched();
    }
    this.isChanged = true;
    this.statusSelected.markAllAsTouched();
    this.statusSelected.updateValueAndValidity({ emitEvent: false });
  }

  addProperty() {
    if (this.profileDetailData.id) {
      this.addPropertyForm.markAllAsTouched();

      if (!this.addPropertyForm.valid) {
        return;
      }

      this.isLoadingAddMenuItem = true;

      const formValue = this.addPropertyForm.value;
      const isDuplicateMenuItemDraft = this.gridLeftCard?.dataSource.find((item: Row) => item?.name?.trim() === formValue.menuLabel.trim());

      if (!(isDuplicateMenuItemDraft)) {
        const newMenuItem: NavigationMenu & Row = {
          isRemoved: false,
          name: formValue.menuLabel,
          menuId: formValue.menuName.id,
          menuName: formValue.menuName.name,
          order: this.gridLeftCard!.dataSource.length,
        }
        newMenuItem.form = this.createInlineEditFormControls(newMenuItem);

        // Insert new row into the left card table
        this.leftCard = [...this.gridLeftCard!.dataSource, newMenuItem];

        // Update card list
        this.getAvailableMenuConfigs();

        this.addPropertyForm.reset();
        this.isChanged = true;
      } else {
        this.menuLabelCtrl?.setErrors({ inValidAsync: true });
      }

      this.isLoadingAddMenuItem = false;
    }
  };
}
