<div class="edit-subheader">
  <div class="flex flex-col">
    <div class="wrap-btn mb-8">
      <button
        mat-flat-button
        type="submit"
        class="submit-button"
        (click)="onSubmit()"
      >
        Save
      </button>
      <button mat-stroked-button type="button" class="cancel-button" (click)="onCancel()">Cancel</button>
    </div>

    <div class="input-area__wrapper">
      <ptg-input-area
        placeholder="Description"
        [controlField]="descriptionControl"
      >
      </ptg-input-area>
    </div>

    <div class="flex flex-col gap-6">
      <div *ngIf="isSpouseAge" class="selection__wrapper flex flex-col gap-4 mb-6 spouse-age-section">
        <div class="k-font-weight-bold">Configure Property in Component</div>
        <div class="flex flex-wrap gap-4">
          <ptg-select
            placeholder="List"
            [listData]="entityList"
            [controlField]="entityControl"
            [isSetMaxWidthSelect]="true"
            [isOptionObj]="true"
            [isRequired]="isSpouseAge"
            (changeOptionValue)="onChangeList()"
          ></ptg-select>
          <ptg-select
            placeholder="Property"
            [listData]="propertyList"
            [controlField]="propertyControl"
            [isSetMaxWidthSelect]="true"
            [isOptionObj]="true"
            [isRequired]="isSpouseAge"
            (changeOptionValue)="onChangeProperty()"
          ></ptg-select>
          <ptg-select
            placeholder="Value"
            [listData]="entityValueList"
            [controlField]="additionalEntityValueControl"
            [isSetMaxWidthSelect]="true"
            [isOptionObj]="true"
            [isRequired]="isSpouseAge"
          ></ptg-select>
        </div>
      </div>

      <div class="selection__wrapper flex flex-col gap-4">
        <div class="k-font-weight-bold">Choose Menu item to apply Sub-header component</div>
        <div class="flex flex-wrap gap-4">
          <ptg-select
            placeholder="Menu Item"
            [listData]="menuItemOptionList"
            [controlField]="menuItemDropdownControl"
            [isSetMaxWidthSelect]="true"
            [isOptionObj]="true"
            [isRequired]="true"
            [isMultipleLineOption]="true"
            errorRequire="Menu Item is required."
            panelClass="menu-item-dropdown"
          >
          </ptg-select>

          <ptg-textbox
            placeholder="Label"
            [controlField]="labelTextboxControl"
            [hasLabel]="true"
            [isRequired]="true"
            errorRequire="Label is required."
            [maxLength]="250"
            errorMaxLength="Exceed the 250 character limit."
          >
          </ptg-textbox>

          <button
            mat-icon-button
            (click)="onAddNewChip()"
            [disabled]="
              !menuItemDropdownControl.value || menuItemDropdownControl.invalid || 
              !labelTextboxControl.value || labelTextboxControl.invalid
            "
          >
            <mat-icon>add_circle</mat-icon>
          </button>
        </div>
      </div>

      <mat-chip-list *ngIf="chipListControl?.length">
        <mat-chip *ngFor="let item of chipListControl.controls; index as i" (removed)="onRemoveChip(i)">
          <span class="ml-2 pl-2">
            <span>{{ item.value?.menuItem?.title === 'All' ? 'All Menu Items' : item.value?.menuItem?.title }}:&nbsp;</span>
            <span class="chip__txt--primary">
              <span class="mr-1">{{ item.value?.label }}</span>
            </span>
          </span>

          <button matChipRemove>
            <mat-icon>close</mat-icon>
          </button>
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>
</div>
