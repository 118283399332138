<div class="refunds-overview-container" id="refunds-overview-page">
  <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs" [settings]="settings"></ptg-breadcrumb>

  <ptg-overview-header-entity #overViewHeader></ptg-overview-header-entity>

  <div class="refunds-overview-wrapper">
    <ptg-sub-header
      [memberId]="memberId"
      [menuItemTitle]="menuItemTitle"
      [menuItemSubTitle]="menuItemSubTitle"
    ></ptg-sub-header>
    <ptg-banner *ngIf="bannerType" [(bannerType)]="bannerType" [message]="message"></ptg-banner>

    <div class="benefit-overview-grid flex-grow overflow-hidden">
      <div class="header">
        <span class="title font-bold">Refund Calculation</span>
      </div>
      <ptg-grid
        [data]="refundCalculationRecords"
        [columns]="columnsRefundCalculationRecord"
        [totalRecords]="totalBenefitHistories"
        [isLoading]="isHistoryLoading"
        [notFoundMessage]="noDataHistories"
        [paginable]="false"
        (sortChange)="onChangeSortHistories($event)"
        (rowClick)="openBenefitCalculationDetail($event)"
      >
        <ng-template cellContent columnName="netRefund" let-row>
          <span
            ><span>$ </span> <span>{{ row.netRefund | numberDecimal: { decimal: 2 } }}</span></span
          >
        </ng-template>
      </ptg-grid>
    </div>

    <div class="benefit-overview-grid flex-grow overflow-hidden">
      <div class="header document-header flex justify-between">
        <span class="title font-bold">Refund Documents</span>
        <ptg-button
          buttonName="Upload Document"
          classInput="upload-button"
          class="upload-file"
          (clickButton)="onUploadDocuments()"
          *ngIf="isInitiateStatus"
        >
          <mat-icon aria-hidden="false" aria-label="upload-icon">upload_file</mat-icon>
        </ptg-button>
      </div>
      <ptg-grid
        [data]="retirementBenefitDocuments"
        [columns]="columnsBenefitDocuments"
        [totalRecords]="totalBenefitDocuments"
        [isLoading]="isDocumentLoading"
        [pageSize]="pageDocumentSize"
        [pageNumber]="pageDocumentIndex"
        notFoundMessage="No Refund Documents to Display"
        (sortChange)="onChangeSortDocuments($event)"
        (pageChange)="onChangeDocumentsPage($event)"
      >
        <ng-template cellContent columnName="fileName" let-row>
          <div class="flex file-document-container">
            <span (click)="onDownloadFile(row)" class="file-document-name" target="_blank"
              ><span class="truncate">{{ row?.fileName }}</span></span
            >
          </div>
        </ng-template>
        <ng-template cellContent columnName="action" let-row>
          <ptg-button
            buttonName="Edit"
            classInput="edit-button"
            (clickButton)="onEditDocument(row)"
            *ngIf="isInitiateStatus"
          >
            <mat-icon>edit</mat-icon>
          </ptg-button>
          <ptg-button
            stopPropagation
            buttonName="Remove"
            classInput="remove-button"
            (clickButton)="onRemoveDocument(row)"
            *ngIf="isInitiateStatus"
          >
            <mat-icon>delete_forever</mat-icon>
          </ptg-button>
        </ng-template>
      </ptg-grid>
    </div>
  </div>
</div>
