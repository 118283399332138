import { createAction, props } from '@ngrx/store';

import { GetEntityProfileConfigOverviewResponse } from '../../services/models';

export const getEntityProfileConfigOverviewAction = createAction(
  '[GetEntityProfileConfigOverview/API] Send Request',
  props<{ entityId: string; recordId: string }>(),
);
export const getEntityProfileConfigOverviewSuccessAction = createAction(
  '[GetEntityProfileConfigOverview/API] Success',
  props<{ response: GetEntityProfileConfigOverviewResponse }>(),
);
export const getEntityProfileConfigOverviewFailureAction = createAction(
  '[GetEntityProfileConfigOverview/API] Failure',
  props<{ error: any }>(),
);
export const clearGetEntityProfileConfigOverviewStateAction = createAction('[GetEntityProfileConfigOverview] Clear');
