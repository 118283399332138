import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { PaymentInfor, GetPaymentHistories } from './models';

@Injectable()
export class PaymentHistoryService {
  constructor(private httpClient: HttpClient) {}

  getPaymentNoneFinalized() {
    return this.httpClient.get<PaymentInfor>(
      `${environment.apiUrl}/Payroll/VendorNextPayment/GetNoneFinalized`
    );
  }

  getListPayment(query: any) {
    let params = new HttpParams();
    if (query.totalPerPage) {
      params = params.append('PageSize', query.totalPerPage);
    }
    params = params.append('PageIndex', query.pageIndex);

    if (query.sortField) {
      params = params.append(
        'SortNames',
        query.sortField[0].toUpperCase() + query.sortField.substring(1)
      );
      params = params.append('SortType', query.sortType ? query.sortType : 0);
    }
    return this.httpClient.get<GetPaymentHistories>(
      `${environment.apiUrl}/Payroll/VendorNextPayment/GetHistories`,
      { params }
    );
  }
}
