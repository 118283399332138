import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetHeaderListRequest } from '@ptg-member/types/models';
import { DistributionListResponse, DistributionMappingEditPayload } from '@ptg-member/types/models/distribution-mapping.model';
import { SortType } from '@ptg-shared/constance';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DistributionMappingService {
  constructor(private httpClient: HttpClient) { }

  getDataList(query: GetHeaderListRequest): Observable<any> {
    let params = new HttpParams();

    if (query.sortField) {
      params = params.append('SortNames', capitalizeFirstLetter(query.sortField));
      params = params.append('SortType', query.sortType ?? SortType.ASC);
    }
    return this.httpClient.get<DistributionListResponse>(`${environment.apiUrl}/Members/distribution-code-mapping`, { params });
  }

  updateDistributionCodeMapping(body: DistributionMappingEditPayload, id: string) {
    return this.httpClient.put<DistributionListResponse>(`${environment.apiUrl}/Members/distribution-code-mapping/${id}`, body);
  }
}
