import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Sort } from '@angular/material/sort';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ACTION_COLUMN, Align, Column, Row } from '@ptg-shared/controls/grid';
import { Breadcrumb, FunctionButtonConfig, FunctionButtonData } from '@ptg-shared/types/models/breadcrumb.model';
import { ACTION, SORT_TYPE, STATE } from '@ptg-shared/constance';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil, filter, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { BaseListComponent } from '@ptg-shared/components/base-list.component';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';
import { GetStepConfigurationsRequest, StepConfiguration } from '../../services/models';
import { AddStepConfigurationComponent } from '../../components';
import {
  CalculationState,
  checkAllowRemoveStepConfiguration,
  checkAllowRemoveStepConfigurationSelector, clearCheckAllowRemoveStepConfigurationStateAction,
  clearStepConfigurationsActionStateAction,
  getStepConfigurationsAction,
  getStepConfigurationsSelector,
  removeStepConfiguration,
  removeStepConfigurationSelector,
  stepConfigurationsActionStateSelector,
} from '../../store';
import { showBanner } from '@ptg-shared/utils/common.util';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { CalculationType } from '../../types/enums';

@Component({
  selector: 'ptg-step-configuration',
  templateUrl: './step-configuration.component.html',
  styleUrls: ['./step-configuration.component.scss'],
})
export class StepConfigurationComponent extends BaseListComponent {
  listBreadcrumbs: Breadcrumb[] = this.getBreadcrumbs();
  columns: Column[] = [
    {
      name: 'benefitEntityName',
      header: {
        title: 'Benefit Option',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'stepCount',
      header: {
        title: 'Step Count',
      },
      truncate: true,
      sortable: true,
      align: Align.Right,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      width: '500px',
    },
  ];
  isLoading?: boolean = false;
  stepConfigurations: (StepConfiguration & Row)[] = [];
  sortInfo?: Sort;
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  memberId: string = '';
  functionButtons: FunctionButtonConfig[] = [
    {
      buttonName: 'New Step Configuration',
      icon: 'add',
      classInput: 'material-icons-round add-button',
      isDisabled: false,
    },
  ];

  constructor(
    public calculationStore: Store<CalculationState>,
    public route: ActivatedRoute,
    public router: Router,
    private dialog: MatDialog,
    public layoutService: LayoutService,
  ) {
    super(layoutService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.checkRoute();
    this.getData();
    this.getRetirementStepConfigurationState();
    this.selectStepConfigurationActionState();
  }

  checkRoute() {
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      if (params?.memberId) {
        this.memberId = params.memberId;
        this.listBreadcrumbs = this.getBreadcrumbs();
      }
    });
  }

  getBreadcrumbs() {
    return [
      {
        name: 'Retirement Benefit Overview',
        url: `/member/benefit-overview/${CalculationType.RetirementBenefit}/${this.memberId}`,
      },
      {
        name: 'Step Configuration',
        url: '',
      },
    ];
  }

  getRetirementStepConfigurationState() {
    this.calculationStore
      .pipe(select(getStepConfigurationsSelector), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        this.isLoading = state?.isLoading;
        this.stepConfigurations = state?.payload ?? [];
      });
  }

  getData() {
    let sortType = SORT_TYPE.DESC;
    let sortNames = 'BenefitType';
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = capitalizeFirstLetter(this.sortInfo.active);
      sortType = this.sortInfo.direction === 'desc' ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }
    const request: GetStepConfigurationsRequest = {
      sortNames: sortNames,
      sortType: sortType,
    };

    this.calculationStore.dispatch(getStepConfigurationsAction({ request }));
  }

  selectStepConfigurationActionState() {
    this.calculationStore
      .pipe(select(stepConfigurationsActionStateSelector), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state) {
          this.calculationStore.dispatch(clearStepConfigurationsActionStateAction());
          if (state.action === ACTION.ADD && state?.stepConfigurationId && state.isContinue) {
            void this.router.navigateByUrl(
              `/member/calculation/step-configuration/${state?.stepConfigurationId}/${this.memberId}`,
            );
          } else {
            showBanner.call(this, state.state || '', 'Step Configuration', state.action || '');
            if (state.state === STATE.SUCCESS) {
              this.getData();
            }
          }
        }
      });
  }

  emitFunction(event: FunctionButtonData) {
    if (event.buttonName === 'New Step Configuration') {
      this.dialog.open(AddStepConfigurationComponent, {
        panelClass: 'add-step-configuration-popup',
        width: '848px',
        autoFocus: false,
        data: {
          memberId: this.memberId,
        },
      });
    }
  }

  onChangeSort(event: Sort) {
    this.sortInfo = event;
    this.getData();
  }

  onClickEditStepConfiguration(row: StepConfiguration) {
    this.handleActionItem(row, 'edited', () => {
      this.router.navigateByUrl(`/member/calculation/step-configuration/${row.id}/${this.memberId}`);
    });
  }

  onClickRemoveStepConfiguration(row: StepConfiguration) {
    this.handleActionItem(row, 'removed', () => {
      this.handleRemoveStepConfiguration(row.id);
    });
  }

  handleActionItem(row: StepConfiguration, type: string, callback: (() => void) | undefined) {
    const stepConfigurationId = row.id;
    this.calculationStore
      .select(checkAllowRemoveStepConfigurationSelector)
      .pipe(
        filter((result) => !!result),
        take(1),
      )
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.calculationStore.dispatch(clearCheckAllowRemoveStepConfigurationStateAction());
        if (res?.payload?.isValid) {
          if (callback) {
            callback();
          }
        }
        //case validation error, we can't delete/edit it
        if (!res?.payload?.isValid) {
          this.dialog.open(ConfirmPopupComponent, {
            panelClass: 'confirm-popup',
            data: {
              text: `There were participants initiated benefit for ${row.benefitEntityName}. This configuration cannot be ${type}.`,
              title: 'Error',
              type: ConfirmType.Warning,
              cancelButtonTitle: 'Close',
              hideConfirmButton: true,
            },
          });
        }
      });
    this.calculationStore.dispatch(checkAllowRemoveStepConfiguration({ stepConfigurationId }));
  }

  handleRemoveStepConfiguration(stepConfigurationId: string) {
    const ALERT_MESSAGE = `Are you sure you want to remove this Step Configuration?`;
    const title = 'Step Configuration';
    const confirmResult = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: ALERT_MESSAGE,
        title: 'Remove Step Configuration',
        cancelButtonTitle: 'Cancel',
        type: ConfirmType.Delete,
      },
    });
    confirmResult.afterClosed().subscribe((result) => {
      if (result) {
        this.calculationStore.dispatch(removeStepConfiguration({ stepConfigurationId }));
        this.calculationStore
          .select(removeStepConfigurationSelector)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((res) => {
            if (!res) {
              return;
            }
            if (res?.success) {
              this.getData();
              showBanner.call(this, STATE.SUCCESS, title, ACTION.REMOVE);
            }
            //case can't delete step configuration
            if (!res?.success) {
              showBanner.call(this, STATE.FAIL, title, ACTION.REMOVE);
            }
          });
      }
    });
  }
}
