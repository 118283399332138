<div class="manage-status-history">
  <div class="header">
    <ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"> </ptg-breadcrumb>

    <div class="title-button flex">
      <div class="line-space"></div>
      <div class="flex">
        <ptg-button buttonName="New Status" classInput="edit-button" (clickButton)="onClickAddStatus()">
          <mat-icon>add</mat-icon>
        </ptg-button>
      </div>
    </div>
  </div>

  <div class="content flex flex-col p-3 gap-1">
    <ptg-banner class="banner" *ngIf="message" [(bannerType)]="bannerType" [message]="message"></ptg-banner>

    <div class="flex-grow">
      <ptg-grid
        [columns]="columns"
        [data]="statusHistorysData"
        [fitToParent]="true"
        [isLoading]="isLoading"
        [pageSize]="pageSize"
        [pageNumber]="pageNumber"
        [totalRecords]="lengthPg"
        [hideScrollbar]="false"
        keyColumn="id"
        notFoundMessage="No Status History to Display"
        (sortChange)="onChangeSort($event)"
        (pageChange)="onChangePage($event)"
      >
        <ng-template cellContent columnName="action" let-row>
          <ptg-button buttonName="Edit" classInput="edit-button" (clickButton)="onClickEditProperty(row)">
            <mat-icon>edit</mat-icon>
          </ptg-button>
        </ng-template>
      </ptg-grid>
    </div>
  </div>
</div>
