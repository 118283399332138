import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { RemoveCalculationDocumentDetailService } from '../../services';
import {
  checkLoddDocumentCanEditAction,
  checkLoddDocumentCanEditFailureAction,
  checkLoddDocumentCanEditSuccessAction,
  checkLoddDocumentCanRemoveAction,
  checkLoddDocumentCanRemoveFailureAction,
  checkLoddDocumentCanRemoveSuccessAction,
  removeRemoveCalculationDocumentDetailAction,
  removeRemoveCalculationDocumentDetailFailureAction,
  removeRemoveCalculationDocumentDetailSuccessAction,
} from '../actions';
import { CheckExistLoddDocumentCanRemoveResponse } from '../../services/models';

@Injectable()
export class RemoveCalculationDocumentDetailEffects {
  constructor(
    private actions$: Actions,
    private removeCalculationDocumentDetailService: RemoveCalculationDocumentDetailService
  ) {}

  removeRemoveCalculationDocumentDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeRemoveCalculationDocumentDetailAction),
      switchMap(({ request }) =>
        this.removeCalculationDocumentDetailService.removeRemoveCalculationDocumentDetail(request).pipe(
          map(() => removeRemoveCalculationDocumentDetailSuccessAction()),
          catchError((error) => of(removeRemoveCalculationDocumentDetailFailureAction({ error })))
        )
      )
    )
  );

  checkExistCheckRetirementDocumentCanRemove$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkLoddDocumentCanRemoveAction),
      switchMap(({ request }) =>
        this.removeCalculationDocumentDetailService.checkExistLoddDocumentCanRemove(request).pipe(
          map((response: CheckExistLoddDocumentCanRemoveResponse) => checkLoddDocumentCanRemoveSuccessAction({ response })),
          catchError((error) => of(checkLoddDocumentCanRemoveFailureAction({ error })))
        )
      )
    )
  );

  checkExistCheckRetirementDocumentCanEdit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkLoddDocumentCanEditAction),
      switchMap(({ request }) =>
        this.removeCalculationDocumentDetailService.checkExistLoddDocumentCanRemove(request).pipe(
          map((response: CheckExistLoddDocumentCanRemoveResponse) => checkLoddDocumentCanEditSuccessAction({ response })),
          catchError((error) => of(checkLoddDocumentCanEditFailureAction({ error })))
        )
      )
    )
  );
}
