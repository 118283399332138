import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import { CreateRetirementBenefitUploadDocumentsRequest, EditRetirementBenefitDocumentRequest } from './models';
import { SHOW_LOADING } from '@ptg-interceptor/httpconfig.interceptor';

@Injectable()
export class RetirementBenefitUploadDocumentService {
  constructor(private httpClient: HttpClient) {}

  createRetirementBenefitUploadDocuments(request: CreateRetirementBenefitUploadDocumentsRequest): Observable<void> {
    const formData = new FormData();
    const memberId = request.memberId;
    const calculationType = request.calculationType;
    const requestFile = request.file;
    const uploadDate = new Date(requestFile[0]?.uploadDate);
    formData.append('documentName', requestFile[0].documentName || '');
    formData.append('type', requestFile[0].calculationDocumentType?.toString() || '');
    formData.append('uploadDate', uploadDate.toISOString() || '');
    formData.append('file', requestFile[0].file || '');

    if (request?.fileName) {
      formData.append('fileName', request?.fileName);
    }

    if (request?.tags?.length) {
      request?.tags?.forEach((tag, idx) => {
        formData.append(`tags[${idx}]`, tag);
      });
    }

    if (request?.documentLocationTitle) {
      formData.append('documentLocationTitle', request?.documentLocationTitle);
    }

    if (request?.documentLocationRouter) {
      formData.append('documentLocationRouter', request?.documentLocationRouter);
    }

    if (request?.documentDescription) {
      formData.append('documentDescription', request?.documentDescription);
    }

    if (request?.showOnOverview) {
      formData.append('showOnOverview', (request?.showOnOverview as boolean)?.toString());
    }

    if (request?.calculationBenefitId) {
      const calculationBenefitId = request.calculationBenefitId;
      let params = new HttpParams({ fromObject: { calculationBenefitId: calculationBenefitId } });
      return this.httpClient.post<void>(
        `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-benefit-documents/${calculationType}`,
        formData,
        { params },
      );
    }
    return this.httpClient.post<void>(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-benefit-documents/${calculationType}`,
      formData,
    );
  }

  editRetirementBenefitDocument(
    memberId: string,
    calculationBenefitDocumentId: string,
    request: EditRetirementBenefitDocumentRequest,
  ) {
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.put(
      `${environment.apiUrl}/calculations/${memberId}/${calculationBenefitDocumentId}/benefit-processing/calculation-benefit-documents`,
      request,
      { context },
    );
  }
}
