<ng-container *ngIf="isLogin; else loadingCheck">
  <ptg-layout>
    <router-outlet></router-outlet>
  </ptg-layout>
</ng-container>

<ng-template #loadingCheck>
  <div id="loading-check">
    <div class="wrap-item">
      <mat-progress-spinner diameter="50" mode="indeterminate">
      </mat-progress-spinner>
      <p>Loading...</p>
    </div>
  </div>
</ng-template>
