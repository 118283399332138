import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import * as AggregationConfigActions from '../actions/aggregation-config.actions';
import { AggregationConfigService } from '../../services/aggregation-config.service';

@Injectable()
export class AggregationConfigEffects {
  addAggregation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AggregationConfigActions.addAggregation),
      switchMap(({ body }) => {
        return this.aggregationConfigService.addAggregation(body).pipe(
          map((res: any) => {
            this.aggregationConfigService.currentNewAggregationId = res;
            return AggregationConfigActions.addAggregationSuccess();
          }),
          catchError((err) => {
            return of(
              AggregationConfigActions.addAggregationFailure({
                errorMsg: err.message,
              })
            );
          })
        );
      })
    )
  );

  getAggregationList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AggregationConfigActions.getAggregationList),
      switchMap(() => {
        return this.aggregationConfigService.getAggregationList().pipe(
          map((aggregationList: any) => {
            return AggregationConfigActions.getAggregationListSuccess({
              aggregationList,
            });
          }),
          catchError((error) => {
            return of(
              AggregationConfigActions.getAggregationListFailure({ error })
            );
          })
        );
      })
    )
  );

  orderTable$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AggregationConfigActions.orderTable),
        switchMap(({ body }) => {
          return this.aggregationConfigService.orderTable(body);
        })
      ),
    { dispatch: false }
  );

  removeAggregationItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AggregationConfigActions.removeAggregationItem),
      switchMap(({ aggregationId }) => {
        return this.aggregationConfigService
          .removeAggregation(aggregationId)
          .pipe(
            map(() => {
              return AggregationConfigActions.removeAggregationItemSuccess();
            }),
            catchError((err) => {
              return of(
                AggregationConfigActions.removeAggregationItemFailure({
                  errorMsg: err.message,
                })
              );
            })
          );
      })
    )
  );

  editAggregation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AggregationConfigActions.editAggregation),
      switchMap(({ body }) => {
        return this.aggregationConfigService.editAggregation(body).pipe(
          map(() => {
            return AggregationConfigActions.editAggregationSuccess();
          }),
          catchError((err) => {
            return of(AggregationConfigActions.editAggregationFailure());
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private aggregationConfigService: AggregationConfigService
  ) {}
}
