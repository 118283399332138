import { createSelector } from '@ngrx/store';
import { selectCalculationModuleState } from './selector';

export const subHeaderConfigurationSelector = createSelector(
  selectCalculationModuleState,
  (state) => state?.subHeaderConfiguration,
);

export const getSubHeaderConfigurationsListSelector = createSelector(
  subHeaderConfigurationSelector,
  (state) => state?.getSubHeaderConfigurationsListState,
);

export const getSubHeaderConfigurationDetailSelector = createSelector(
  subHeaderConfigurationSelector,
  (state) => state?.getSubHeaderConfigurationDetailState,
);

export const setSubHeaderConfigurationDetailSelector = createSelector(
  subHeaderConfigurationSelector,
  (state) => state?.setSubHeaderConfigurationDetailState,
);

export const getSubHeaderDataSelector = createSelector(
  subHeaderConfigurationSelector,
  (state) => state?.getSubHeaderDataState,
);
