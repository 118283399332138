import { createReducer, on } from '@ngrx/store';
import { ACTION, ActionState, STATE } from '@ptg-shared/constance';
import { BaseActionState } from '@ptg-shared/types/models';
import {
  addBuyBackMakeUpAction,
  addBuyBackMakeUpFailureAction,
  addBuyBackMakeUpSuccessAction,
  clearAddBuyBackMakeUpStateAction,
  clearEditBuyBackMakeUpInterestFeesStateAction,
  clearEditBuyBackMakeUpStateAction,
  clearGetBuyBackMakeUpInterestFeesCalculationStateAction,
  clearGetBuyBackMakeUpYearRecordStateAction,
  clearGetEligibleYearStateAction,
  clearGetListBuyBackMakeUpDetailStateAction,
  clearGetListBuyBackMakeUpStateAction,
  clearGetListMunicipalityBuyBackMakeUpStateAction,
  clearInitiateRemittanceBuyBackMakeUpStateAction,
  clearRemoveBuyBackMakeUpStateAction,
  editBuyBackMakeUpAction,
  editBuyBackMakeUpFailureAction,
  editBuyBackMakeUpInterestFeesAction,
  editBuyBackMakeUpInterestFeesFailureAction,
  editBuyBackMakeUpInterestFeesSuccessAction,
  editBuyBackMakeUpSuccessAction,
  getBuyBackMakeUpInterestFeesCalculationAction,
  getBuyBackMakeUpInterestFeesCalculationFailureAction,
  getBuyBackMakeUpInterestFeesCalculationSuccessAction,
  getBuyBackMakeUpYearRecordAction,
  getBuyBackMakeUpYearRecordFailureAction,
  getBuyBackMakeUpYearRecordSuccessAction,
  getEligibleYearAction,
  getEligibleYearFailureAction,
  getEligibleYearSuccessAction,
  getListBuyBackMakeUpAction,
  getListBuyBackMakeUpDetailAction,
  getListBuyBackMakeUpDetailFailureAction,
  getListBuyBackMakeUpDetailSuccessAction,
  getListBuyBackMakeUpFailureAction,
  getListBuyBackMakeUpSuccessAction,
  getListMunicipalityBuyBackMakeUpAction,
  getListMunicipalityBuyBackMakeUpFailureAction,
  getListMunicipalityBuyBackMakeUpSuccessAction,
  initiateRemittanceBuyBackMakeUpAction,
  initiateRemittanceBuyBackMakeUpFailureAction,
  initiateRemittanceBuyBackMakeUpSuccessAction,
  removeBuyBackMakeUpAction,
  removeBuyBackMakeUpFailureAction,
  removeBuyBackMakeUpSuccessAction,
} from '../actions/buyback-makeup.actions';
import {
  BuyBackMakeUpYearRecordResponse,
  BuybackMakeup,
  BuybackMakeupDetail,
  GetEligibleYearResponse,
  GetListMunicipalityResponse,
} from '../../services/models/buyback-makeup.model';

export interface BuyBackMakeUpState {
  getListBuyBackMakeUpState?: BaseActionState<BuybackMakeup[]>;
  getListBuyBackMakeUpDetailState?: BaseActionState<BuybackMakeupDetail[]>;
  removeBuyBackMakeUpState?: ActionState;
  getEligibleYearState?: BaseActionState<GetEligibleYearResponse>;
  getListMunicipalityState?: BaseActionState<GetListMunicipalityResponse>;
  addBuyBackMakeUpState?: {
    state: ActionState,
    errorMsg?: string
  };
  editBuyBackMakeUpState?: {
    state: ActionState,
    errorMsg?: string
  };
  initiateRemittanceBuyBackMakeUpState?: {
    state: ActionState,
    errorMsg?: string,
    municipalityId?: string,
    messageError?: string,
  };
  editBuyBackMakeUpInterestFeesState?: {
    state: ActionState,
    errorMsg?: string
  };
  getBuyBackMakeUpYearRecordState?: BaseActionState<BuyBackMakeUpYearRecordResponse>;
  getBuyBackMakeUpInterestFeesCalculationState?: BaseActionState<BuyBackMakeUpYearRecordResponse>;
}

const initialState: BuyBackMakeUpState = {};

export const buyBackMakeUpReducer = createReducer(
  initialState,
  
  // Get List Buy Back Make Up
  on(getListBuyBackMakeUpAction, (state) => ({
    ...state,
    getListBuyBackMakeUpState: {
      isLoading: true,
    },
  })),
  on(getListBuyBackMakeUpSuccessAction, (state, { response }) => ({
    ...state,
    getListBuyBackMakeUpState: {
      isLoading: false,
      success: true,
      payload: response.buybackMakeups,
      total: response.total,
    },
  })),
  on(getListBuyBackMakeUpFailureAction, (state, { error }) => ({
    ...state,
    getListBuyBackMakeUpState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetListBuyBackMakeUpStateAction, (state) => ({
    ...state,
    getListBuyBackMakeUpState: undefined,
  })),

  // Get List Buy Back Up Detail
  on(getListBuyBackMakeUpDetailAction, (state) => ({
    ...state,
    getListBuyBackMakeUpDetailState: {
      isLoading: true,
    },
  })),
  on(getListBuyBackMakeUpDetailSuccessAction, (state, { response }) => ({
    ...state,
    getListBuyBackMakeUpDetailState: {
      isLoading: false,
      success: true,
      payload: response.buybackMakeupDetails,
      total: response.total,
    },
  })),
  on(getListBuyBackMakeUpDetailFailureAction, (state, { error }) => ({
    ...state,
    getListBuyBackMakeUpDetailState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetListBuyBackMakeUpDetailStateAction, (state) => ({
    ...state,
    getListBuyBackMakeUpDetailState: undefined,
  })),

  // Remove Buy Back Make Up
  on(removeBuyBackMakeUpAction, (state) => ({
    ...state,
    isLoading: false,
    removeBuyBackMakeUpState: undefined
  })),
  on(removeBuyBackMakeUpSuccessAction, (state, { }) => ({
    ...state,
    isLoading: false,
    removeBuyBackMakeUpState: {
      action: ACTION.REMOVE,
      state: STATE.SUCCESS,
    },
  })),
  on(removeBuyBackMakeUpFailureAction, (state, { error }) => ({
    ...state,
    removeBuyBackMakeUpState: {
      action: ACTION.REMOVE,
      state: STATE.FAIL,
      errorMsg: error ?? ''
    },
  })),
  on(clearRemoveBuyBackMakeUpStateAction, (state) => ({
    ...state,
    removeBuyBackMakeUpState: undefined,
  })),

  // Get Eligible Year
  on(getEligibleYearAction, (state) => ({
    ...state,
    getEligibleYearState: {
      isLoading: true,
    },
  })),
  on(getEligibleYearSuccessAction, (state, { response }) => ({
    ...state,
    getEligibleYearState: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getEligibleYearFailureAction, (state, { error }) => ({
    ...state,
    getEligibleYearState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetEligibleYearStateAction, (state) => ({
    ...state,
    getEligibleYearState: undefined,
  })),

  // Get List Municipality
  on(getListMunicipalityBuyBackMakeUpAction, (state) => ({
    ...state,
    getListMunicipalityState: {
      isLoading: true,
    },
  })),
  on(getListMunicipalityBuyBackMakeUpSuccessAction, (state, { response }) => ({
    ...state,
    getListMunicipalityState: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getListMunicipalityBuyBackMakeUpFailureAction, (state, { error }) => ({
    ...state,
    getListMunicipalityState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetListMunicipalityBuyBackMakeUpStateAction, (state) => ({
    ...state,
    getListMunicipalityState: undefined,
  })),

  // Add Buy Back Make Up
  on(addBuyBackMakeUpAction, (state) => ({
    ...state,
    isLoading: false,
    addBuyBackMakeUpState: undefined,
  })),
  on(addBuyBackMakeUpSuccessAction, (state, { }) => ({
    ...state,
    isLoading: false,
    addBuyBackMakeUpState: {
      state: {
        action: ACTION.ADD,
        state: STATE.SUCCESS,
      }
    },
  })),
  on(addBuyBackMakeUpFailureAction, (state, { errorMsg }) => ({
    ...state,
    isLoading: false,
    addBuyBackMakeUpState: {
      state: {
        action: ACTION.ADD,
        state: STATE.FAIL,
      },
      errorMsg: errorMsg,
    },
  })),
  on(clearAddBuyBackMakeUpStateAction, (state) => ({
    ...state,
    isLoading: false,
    addBuyBackMakeUpState: undefined,
  })),

  // Edit Buy Back Make Up
  on(editBuyBackMakeUpAction, (state) => ({
    ...state,
    isLoading: false,
    editBuyBackMakeUpState: undefined,
  })),
  on(editBuyBackMakeUpSuccessAction, (state, { }) => ({
    ...state,
    isLoading: false,
    editBuyBackMakeUpState: {
      state: {
        action: ACTION.EDIT,
        state: STATE.SUCCESS,
      }
    },
  })),
  on(editBuyBackMakeUpFailureAction, (state, { errorMsg }) => ({
    ...state,
    editBuyBackMakeUpState: {
      state: {
        action: ACTION.EDIT,
        state: STATE.FAIL,
      },
      errorMsg: errorMsg,
    },
  })),
  on(clearEditBuyBackMakeUpStateAction, (state) => ({
    ...state,
    isLoading: false,
    editBuyBackMakeUpState: undefined,
  })),

  // Initiate Remittance Buy Back Make Up
   on(initiateRemittanceBuyBackMakeUpAction, (state) => ({
    ...state,
    isLoading: false,
    initiateRemittanceBuyBackMakeUpState: undefined,
  })),
  on(initiateRemittanceBuyBackMakeUpSuccessAction, (state, { response }) => ({
    ...state,
    isLoading: false,
    initiateRemittanceBuyBackMakeUpState: {
      state: {
        action: ACTION.SAVE,
        state: STATE.SUCCESS,
      },
      municipalityId: response.municipalityId,
      messageError: response.messageError,
    },
  })),
  on(initiateRemittanceBuyBackMakeUpFailureAction, (state, { errorMsg }) => ({
    ...state,
    initiateRemittanceBuyBackMakeUpState: {
      state: {
        action: ACTION.SAVE,
        state: STATE.FAIL,
      },
      errorMsg: errorMsg,
    },
  })),
  on(clearInitiateRemittanceBuyBackMakeUpStateAction, (state) => ({
    ...state,
    isLoading: false,
    initiateRemittanceBuyBackMakeUpState: undefined,
  })),

  // Edit Buy Back Make Up Interest Fees
  on(editBuyBackMakeUpInterestFeesAction, (state) => ({
    ...state,
    isLoading: false,
    editBuyBackMakeUpInterestFeesState: undefined,
  })),
  on(editBuyBackMakeUpInterestFeesSuccessAction, (state, { }) => ({
    ...state,
    isLoading: false,
    editBuyBackMakeUpInterestFeesState: {
      state: {
        action: ACTION.EDIT,
        state: STATE.SUCCESS,
      }
    },
  })),
  on(editBuyBackMakeUpInterestFeesFailureAction, (state, { errorMsg }) => ({
    ...state,
    editBuyBackMakeUpInterestFeesState: {
      state: {
        action: ACTION.EDIT,
        state: STATE.FAIL,
      },
      errorMsg: errorMsg,
    },
  })),
  on(clearEditBuyBackMakeUpInterestFeesStateAction, (state) => ({
    ...state,
    isLoading: false,
    editBuyBackMakeUpInterestFeesState: undefined,
  })),

  // Get Buy Back Make Up Year Record
  on(getBuyBackMakeUpYearRecordAction, (state) => ({
    ...state,
    getBuyBackMakeUpYearRecordState: {
      isLoading: true,
    },
  })),
  on(getBuyBackMakeUpYearRecordSuccessAction, (state, { response }) => ({
    ...state,
    getBuyBackMakeUpYearRecordState: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getBuyBackMakeUpYearRecordFailureAction, (state, { error }) => ({
    ...state,
    getBuyBackMakeUpYearRecordState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetBuyBackMakeUpYearRecordStateAction, (state) => ({
    ...state,
    getBuyBackMakeUpYearRecordState: undefined,
  })),

  // Get Buy Back Make Up Interest Fees Calculation
  on(getBuyBackMakeUpInterestFeesCalculationAction, (state) => ({
    ...state,
    getBuyBackMakeUpInterestFeesCalculationState: {
      isLoading: true,
    },
  })),
  on(getBuyBackMakeUpInterestFeesCalculationSuccessAction, (state, { response }) => ({
    ...state,
    getBuyBackMakeUpInterestFeesCalculationState: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getBuyBackMakeUpInterestFeesCalculationFailureAction, (state, { error }) => ({
    ...state,
    getBuyBackMakeUpInterestFeesCalculationState: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetBuyBackMakeUpInterestFeesCalculationStateAction, (state) => ({
    ...state,
    getBuyBackMakeUpInterestFeesCalculationState: undefined,
  })),
);
