import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Observable, of, Subject, timer } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '@ptg-shared/components';
import { CheckUniqueTagRequest, Tag } from '../../services/models/tag.models';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { catchError, filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { addTagAction, editTagAction } from '../../store/actions';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { TagsState } from '../../store/reducers';
import { TagService } from '../../services';
import { tagsSelector } from '../../store/selectors';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { CANCEL_CONFIRM_MESSAGE } from '@ptg-shared/constance';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';

@Component({
  selector: 'ptg-add-tag',
  templateUrl: './add-tag.component.html',
  styleUrls: ['./add-tag.component.scss'],
})
export class AddTagComponent extends BaseComponent implements OnInit {
  editForm!: FormGroup;
  formSubmit$ = new Subject<boolean>();

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddTagComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      tagDetail: Tag | null,
    },
    private fb: FormBuilder,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    private tagStore: Store<TagsState>,
    private tagService: TagService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initFormGroup();
    this.onCheckCloseModal();
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1),
          ),
        ),
        filter((status) => status === AbstractControlStatus.VALID),
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: CANCEL_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  initFormGroup() {
    const tagData = this.data?.tagDetail;
    this.editForm = this.fb.group({
      id: tagData?.id ?? '',
      name: this.fb.control(tagData?.name, {
        validators: [Validators.required],
        asyncValidators: this.validateTagName(),
      }),
      description: this.fb.control(tagData?.description),
    });
  }

  onCheckCloseModal() {
    this.tagStore.pipe(
      select(tagsSelector),
    ).subscribe((el) => {
      if (!el?.isLoading && el?.tagDetailState?.state) {
        this.dialogRef.close({ isSuccess: true });
      }
    });
  }

  validateTagName(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const request: CheckUniqueTagRequest = {
        id: this.data.tagDetail?.id ?? '',
        name: control.value,
      };

      return timer(300).pipe(
        switchMap((): Observable<ValidationErrors | null> =>
          this.tagService.checkExistsTag(request).pipe(
            map((res: any) => {
              if (res?.exists) {
                return { errorMessageTagName: 'Tag Name already exists.' };
              }
              return null;
            }),
            catchError(({ error }) => {
              return of({ errorMessageTagName: error?.errorMessage });
            }),
          ),
        ),
      );
    };
  }

  onSubmit() {
    const tagData = JSON.parse(
      JSON.stringify(this.editForm.value as Tag),
    );

    if (tagData.id) {
      this.tagStore.dispatch(editTagAction({ tagRequest: tagData }));
    } else {
      this.tagStore.dispatch(addTagAction({ tagRequest: tagData }));
    }
  }

}
