import { createAction, props } from "@ngrx/store";
import { GetParticipantDocumentsBenefitsResponse, ParticipantDocumentsListModel } from "@ptg-member/types/models/participant-documents.model";
import { DocumentAttachRequest } from '@ptg-member/services/models';

export const ParticipantDocumentsRequestAction = '[GetParticipantDocuments/API] Send Request';
export const ParticipantDocumentsSuccessAction = '[GetParticipantDocuments/API] Success';
export const ParticipantDocumentsFailureAction = '[GetParticipantDocuments/API] Failure';

export const RemoveParticipantDocumentsAction = '[RemoveDocumentsParticipant/API] Send Request';
export const RemoveParticipantDocumentsSuccessAction = '[RemoveDocumentsParticipant/API] Success';
export const RemoveParticipantDocumentsFailureAction = '[RemoveDocumentsParticipant/API] Failure';

export const GetParticipantDocumentsDownloadAction = '[GetParticipantDocumentDownload/API] Send Request';
export const GetParticipantDocumentsDownloadSuccessAction = '[GetParticipantDocumentDownload/API] Success';
export const GetParticipantDocumentsDownloadFailureAction = '[GetParticipantDocumentDownload/API] Failure';


//get list all doc
export const getParticipantDocuments = createAction(
  ParticipantDocumentsRequestAction,
  props<{query: any, memberId: string, isShowOnOverView: boolean}>()
);
export const getParticipantDocumentsSuccess = createAction(
  ParticipantDocumentsSuccessAction,
  props<{lstDoc: ParticipantDocumentsListModel}>()
);
export const getParticipantDocumentsFailure = createAction(
  ParticipantDocumentsFailureAction,
  props<{ err?: any }>()
);

//remove doc detail
export const removeDocumentsParticipant = createAction(
  RemoveParticipantDocumentsAction,
  props<{ memberId: string, documentId: string }>()
);
export const removeDocumentsParticipantSuccess = createAction(
  RemoveParticipantDocumentsSuccessAction
);
export const removeDocumentsParticipantFailure = createAction(
  RemoveParticipantDocumentsFailureAction,
  props<{ errorMsg?: string }>()
);

// document attach to profile
export const documentAttachAction = createAction(
  '[DocumentAttach/API] Send Request',
  props<{ request: DocumentAttachRequest }>(),
);
export const documentAttachActionSuccessAction = createAction(
  '[DocumentAttach/API] Success',
);
export const documentAttachActionFailureAction = createAction(
  '[DocumentAttach/API] Failure',
  props<{ error?: any }>(),
);
export const clearDocumentAttachStateAction = createAction(
  '[DocumentAttach] Clear',
);

export const getParticipantDocumentsBenefitsAction = createAction(
  '[GetParticipantDocumentsBenefits/API] Send Request',
  props<{ memberId: string }>()
);

export const getParticipantDocumentsBenefitsSuccessAction = createAction(
  '[GetParticipantDocumentsBenefits/API] Success',
  props<{ response: GetParticipantDocumentsBenefitsResponse }>()
);

export const getParticipantDocumentsBenefitsFailureAction = createAction(
  '[GetParticipantDocumentsBenefits/API] Failure',
  props<{ error?: any }>()
);

export const clearGetParticipantDocumentsBenefitsStateAction = createAction(
  '[GetParticipantDocumentsBenefits] Clear'
);
