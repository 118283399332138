<div class="dialog-container">
  <div class="header-title">
    Add New Section
  </div>
  <form class="edit-form" [formGroup]="editForm">
    <div class="input-container">
      <ptg-textbox
        [controlField]="editForm.get('name')"
        placeholder="Section Name"
        [hasLabel]="true"
        [isRequired]="true"
        errorAsync="Section Name already exists."
      ></ptg-textbox>

      <ptg-toggle-button
        [controlField]="editForm.get('isList')"
        label="Is a List"
      ></ptg-toggle-button>
    </div>

    <div class="row-button">
      <button mat-raised-button type="button" class="primary" (click)="formSubmit$.next(true)">Create & Continue</button>
      <button mat-raised-button type="button" class="secondary" (click)="formSubmit$.next(false)">Create & Add Another</button>
      <button mat-raised-button type="button" class="cancel-button" (click)="onCancel()">Cancel</button>
    </div>
  </form>
</div>
