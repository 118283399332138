export enum MunicipalityType {
    FireDepartmentFees,
    ReserveOfficerFees,
    EMSDFees
}

export const ListMunicipalityType = [
    {
        value: MunicipalityType.FireDepartmentFees,
        displayValue: 'Fire Department Fees'
    },
    {
        value: MunicipalityType.ReserveOfficerFees,
        displayValue: 'Reserve Officer Fees'
    },
    {
        value: MunicipalityType.EMSDFees,
        displayValue: 'EMSD Fees'
    }
];