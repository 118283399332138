import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  GetPaymentListConfigurationsResponse,
  SetPaymentListConfigurations,
} from '../types/models';

@Injectable()
export class PaymentListConfigurationService {
  constructor(private httpClient: HttpClient) {}

  getPaymentListConfiguration(
    id?: string
  ): Observable<GetPaymentListConfigurationsResponse> {
    return this.httpClient.get<GetPaymentListConfigurationsResponse>(
      `${environment.apiUrl}/Payroll/GetPaymentListConfigurations`
    );
  }

  setPaymentListConfiguration(body: SetPaymentListConfigurations) {
    return this.httpClient.put<SetPaymentListConfigurations>(
      `${environment.apiUrl}/Payroll/SetPaymentListConfiguration`,
      body
    );
  }
}
