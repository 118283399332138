import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  OffCyclePayment,
  PaymentInforConfigObj,
} from '../../types/models/next-payment.model';
import {
  OffCyclePaymentStatusType,
  PaymentInfoType,
} from '../../constance/next-payment.const';
import * as fromNextPayment from '../../store/reducers';
import * as NextPaymentActions from '../../store/actions/next-payment.actions';
import { EditDeductionsComponent } from '../edit-deductions/edit-deductions.component';
import { EditDeductionsRecurringComponent } from '../edit-deductions-recurring/edit-deductions-recurring.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'ptg-deduction-detail',
  templateUrl: './deduction-detail.component.html',
  styleUrls: ['./deduction-detail.component.scss'],
})
export class DeductionDetailComponent implements OnInit, OnDestroy {
  readonly PaymentInfoTypeEnum = PaymentInfoType;
  readonly OffCyclePaymentStatusTypeEnum = OffCyclePaymentStatusType;

  deductionsInfo: any;
  earningInfo: any;
  deductions: any[] = [];
  displayedColumns = ['name', 'currentValue'];
  hasManualAdjusted: boolean = false;
  offCyclePayment?: OffCyclePayment;
  unsubscribe$ = new Subject<void>();

  @Input() isHistory?: boolean;
  @Input() benefitId?: string;
  @Input() paymentInforConfig!: PaymentInforConfigObj;
  @Output() changeBannerEvent = new EventEmitter();

  constructor(
    private store: Store<fromNextPayment.MemberState>,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.setDisplayedColumns();
    this.store
      .select(fromNextPayment.selectNextPayment)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data) this.offCyclePayment = data.offCyclePayment;
        this.setDisplayedColumns();
      });

    
      this.store.pipe(
        select(fromNextPayment.selectNextPaymentDeduction)
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state) {
          this.deductionsInfo = state;
          this.deductions = this.deductionsInfo?.deductions;
          const currentDateString = new DatePipe('en-US').transform(new Date(),'MM-dd-yyyy') as string;
          const currentDate = new Date(currentDateString);
          this.deductions = this.deductions?.map((item, index) => {
            return {
              ...item,
              active: !item.startDate ||
                      item.startDate && (!item.endDate && !(currentDate.valueOf() < new Date(item.startDate).valueOf()) ||
                                          item.endDate && !(currentDate.valueOf() < new Date(item.startDate).valueOf() || currentDate.valueOf() > new Date(item.endDate).valueOf())),
              isLastDeduction: index === this.deductions.length - 1
            }
          });
          if (this.deductions?.length > 0 ) {
            this.deductions.push({
                isTotal: true,
                name: this.isHistory || this.offCyclePayment ? 'Total Deductions' : 'Total Deductions (All)',
                active: true,
                value: this.deductionsInfo?.totalDeductions,
                ytd: this.deductionsInfo?.ytdTotalDeductions
            });
            if (!this.isHistory && !this.offCyclePayment) {
              this.deductions.splice(this.deductions.length - 1, 0, {
                isTotal: true,
                name: 'Total Deductions (Active)',
                active: true,
                value: this.deductionsInfo?.totalActiveDeductions,
                ytd: this.deductionsInfo?.ytdTotalActiveDeductions,
              });
            }
          }
          this.deductions = [...this.deductions];
          this.hasManualAdjusted = this.deductions?.some(
            (item: any) => item.manualAdjustmented
          );
        }
      });
  }

  setDisplayedColumns() {
    if (!this.paymentInforConfig.DEDUCTIONSYEARTODATE) {
      this.displayedColumns = ['name', 'currentValue'];
    } else {
      this.displayedColumns = ['name', 'currentValue', 'YTDValue'];
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onClickEdit(): void {
    let dialogRef: any;
    if (this.offCyclePayment) {
      dialogRef = this.dialog.open(EditDeductionsComponent, {
        panelClass: 'edit-popup',
        disableClose: true,
        autoFocus: false,
        height: 'auto',
        width: '800px',
        data: {
          ...this.deductionsInfo,
          offCyclePayment: this.offCyclePayment,
        },
      });
    } else {
      dialogRef = this.dialog.open(EditDeductionsRecurringComponent, {
        panelClass: 'dialog-full-screen',
        disableClose: true,
        autoFocus: false,
        data: {
          ...this.deductionsInfo
        },
      });
    }

    dialogRef.afterClosed().subscribe((result: any) => {
      this.changeBannerEvent.emit();
      if (result)
        this.store.dispatch(
          NextPaymentActions.updateDeduction({
            body: {...result, benefitId: this.benefitId },
            targetId: this.offCyclePayment?.id,
          })
        );
    });
  }
}
