import { createAction, props } from '@ngrx/store';

import {
  GetGetEntityComponentListDataByComponentIdsRequest,
  GetGetEntityComponentListDataByComponentIdsResponse,
} from '../../services/models';

export const getGetEntityComponentListDataByComponentIdsAction = createAction(
  '[GetGetEntityComponentListDataByComponentIds/API] Send Request',
  props<{ request: GetGetEntityComponentListDataByComponentIdsRequest }>()
);

export const getGetEntityComponentListDataByComponentIdsSuccessAction = createAction(
  '[GetGetEntityComponentListDataByComponentIds/API] Success',
  props<{ response: GetGetEntityComponentListDataByComponentIdsResponse }>()
);

export const getGetEntityComponentListDataByComponentIdsFailureAction = createAction(
  '[GetGetEntityComponentListDataByComponentIds/API] Failure',
  props<{ error?: any }>()
);

export const clearGetGetEntityComponentListDataByComponentIdsStateAction = createAction(
  '[GetGetEntityComponentListDataByComponentIds] Clear'
);
