import { createSelector } from '@ngrx/store';

import { entityManagementModuleSelector } from '../../store/selectors';

export const entitySelector = createSelector(entityManagementModuleSelector, (state) => state?.entity);

export const createEntitySelector = createSelector(entitySelector, (state) => state?.createEntity);

export const getEntitiesSelector = createSelector(entitySelector, (state) => state?.getEntities);

export const getAllEntitiesSelector = createSelector(entitySelector, (state) => state?.getAllEntities);

export const getAllEntityPropertiesSelector = createSelector(entitySelector, (state) => state?.getAllEntityProperties);

export const getEntityDetailSelector = createSelector(entitySelector, (state) => state?.getEntityDetail);

export const updateEntitySelector = createSelector(entitySelector, (state) => state?.updateEntity);

export const addPropertySelector = createSelector(entitySelector, (state) => state?.addProperty);

export const addSectionSelector = createSelector(entitySelector, (state) => state?.addList);

export const deletePropertySelector = createSelector(entitySelector, (state) => state?.deleteProperty);

export const deleteEntityComponentSelector = createSelector(entitySelector, (state) => state?.deleteEntityComponent);

export const getSectionDetailSelector = createSelector(entitySelector, (state) => state?.getListDetail);

export const updateListConfigSelector = createSelector(entitySelector, (state) => state?.updateListConfig);

export const getPropertyTypesSelector = createSelector(entitySelector, (state) => state?.getPropertyTypes);

export const updatePropertytemSelector = createSelector(entitySelector, (state) => state?.updatePropertyItem);

export const deleteEntitySelector = createSelector(entitySelector, (state) => state?.deleteEntity);

export const getEntityPropertiesSelector = createSelector(entitySelector, (state) => state?.getEntityProperties);

export const getEntityListsSelector = createSelector(entitySelector, (state) => state?.getEntityLists);

export const getEntityComponentListsSelector = createSelector(
  entitySelector,
  (state) => state?.getEntityComponentLists,
);

export const getEntityPropertyDetailSelector = createSelector(
  entitySelector,
  (state) => state?.getEntityPropertyDetail,
);

export const getEntityPropertiesForCalculationSelector = createSelector(
  entitySelector,
  (state) => state?.getEntityPropertiesForCalculation,
);

export const setEntityPropertySelector = createSelector(entitySelector, (state) => state?.setEntityProperty);

export const getEntityReferencesSelector = createSelector(entitySelector, (state) => state?.getEntityReferences);

export const getEntityInitiationConfigurationSelector = createSelector(
  entitySelector,
  (state) => state?.getEntityInitiationConfiguration,
);

export const setEntityInitiationConfigurationSelector = createSelector(
  entitySelector,
  (state) => state?.setEntityInitiationConfiguration,
);

export const canRemoveEntitySelector = createSelector(entitySelector, (state) => state?.canRemoveEntity);

export const getEntityListDataByIdSelector = createSelector(entitySelector, (state) => state?.getEntityListDataById);

export const deleteEntityListDataSelector = createSelector(entitySelector, (state) => state?.deleteEntityListData);

export const getListBenefitSelector = createSelector(entitySelector, (state) => state?.getListBenefit);

export const updateBenefitMappingSelector = createSelector(entitySelector, (state) => state?.updateBenefitMapping);