import { Component, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { Option } from 'src/app/shared/controls/select/select.component';
import { takeUntil } from 'rxjs/operators';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { CalculationDocumentType, CalculationType, DisplayDocumentTypeName, ResponseStatus } from '../../types/enums';
import { CALCULATION_DOCUMENT_TYPE_OPTIONS, DISABILITY_DOCUMENT_TYPE_OPTIONS } from '../../constants';
import { RetirementBenefitUploadDocumentService } from '../../services';
import { RetirementBenefitDialogService } from '../../services/retirement-benefit-dialog.service';
import { UploadComponent } from '@ptg-shared/controls/upload/upload.component';
import { Store } from '@ngrx/store';
import {
  CalculationState,
  clearCreateRetirementBenefitDetailUploadDocumentStateAction,
  clearCreateRetirementBenefitUploadDocumentsStateAction,
  clearGetRetirementBenefitDownloadDocumentStateAction,
  createRetirementBenefitDetailUploadDocumentAction,
  getRetirementBenefitDocumentSelector,
  getRetirementBenefitDocumentsAction,
  getRetirementBenefitDownloadDocumentSelector,
} from '../../store';
import {
  CalculationBenefitDocumentInputDto,
  CreateRetirementBenefitDetailUploadDocumentRequest,
  RetirementBenefitDetailDocument,
  RetirementBenefitDocument,
} from '../../services/models';
import { deepClone, downloadFile } from '@ptg-shared/utils/common.util';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { DEFAULT_PAGE_SIZE } from '@ptg-shared/constance';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { RadioOption } from '@ptg-shared/controls/radio-button/radio-button.component';
import { ACTION_COLUMN, Align, Column, ColumnType } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { createRetirementBenefitDetailUploadDocumentSelector } from '../../store/selectors/';
import { DateTime } from 'luxon';
import { BaseListComponent } from '@ptg-shared/components/base-list.component';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { Sort } from '@angular/material/sort';
import { localeCompare } from '@ptg-shared/utils/string.util';
import { RetirementBenefitDialogComponentService } from '../retirement-benefit-dialog/retirement-benefit-dialog.component.service';
import { FundType } from '@ptg-shared/types/enums';
import { DocumentsState } from 'src/app/admin/features/file/store/reducers';
import {
  clearGetDocumentDownloadStateAction,
  getDocumentDownloadAction,
} from 'src/app/admin/features/file/store/actions';

@Component({
  selector: 'ptg-retirement-benefit-upload-document',
  templateUrl: './retirement-benefit-upload-document.component.html',
  styleUrls: ['./retirement-benefit-upload-document.component.scss'],
  providers: [RetirementBenefitDialogComponentService],
})
export class RetirementBenefitDetailUploadDocumentComponent extends BaseListComponent {
  memberId: string = '';
  listBreadcrumbs: Breadcrumb[] = this.getBreadcrumbs();
  listProperty!: Option[];
  uploadDocumentForm!: FormGroup;
  selectExistDocumentForm!: FormGroup;

  checkNewDocForm: FormGroup = this.fb.group({
    isNewDocument: this.fb.control(true),
  });

  acceptFile = 'application/pdf';
  supportedFileTypes = 'pdf file format';
  checkPattern = new RegExp(/^[\x00-\x7F]+\.(pdf)$/, 'i');
  fileSelected!: File;
  uploadDate!: Date;
  @ViewChild('fileDocument')
  private fileDocument!: UploadComponent;

  file?: File;

  calculationDocumentTypeOptions: Option[] = deepClone(CALCULATION_DOCUMENT_TYPE_OPTIONS);
  isUploading: boolean = false;

  bannerType: BannerType = BannerType.Hidden;
  message: string = '';

  existDocumentList: Option[] = [];

  calculationType: CalculationType = CalculationType.RetirementBenefit;
  calculationRecordId: string = '';

  newDocumentOptions: RadioOption[] = [
    { value: false, label: 'Existing Document' },
    { value: true, label: 'New Document' },
  ];

  benefitDocuments: RetirementBenefitDocument[] = [];
  documentList: CalculationBenefitDocumentInputDto[] = [];
  filteredDocuments: CalculationBenefitDocumentInputDto[] = [];
  sortInfo: any;
  selectedDocument: any;
  detailBenefitDocument: RetirementBenefitDetailDocument[] | undefined = [];

  pageNumber = FIRST_PAGE;
  pageSize = DEFAULT_PAGE_SIZE;

  downloadFileName: string = '';
  calculationBenefitId!: string;

  notFoundMessage:string = '';
  documentTableTitle: string = '';

  documentColumns: Column[] = [
    {
      name: 'uploadDate',
      header: {
        title: 'Upload Date',
      },
      sortable: true,
      type: ColumnType.DateTime,
      templateArgs: {
        format: 'MM/dd/yyyy',
      },
      truncate: true,
    },
    {
      name: 'documentName',
      header: {
        title: 'Document Name',
      },
      align: Align.Left,
      sortable: true,
      truncate: true,
    },
    {
      name: 'fileName',
      header: {
        title: 'File Name',
      },
      align: Align.Left,
      sortable: true,
      truncate: true,
    },
    {
      name: 'type',
      header: {
        title: 'Document Type',
      },
      align: Align.Left,
      sortable: true,
      truncate: true,
      cell: (row) => {
        return DisplayDocumentTypeName[row.type];
      },
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      width: '200px',
    },
  ];

  constructor(
    public route: ActivatedRoute,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private retirementBenefitDialogService: RetirementBenefitDialogService,
    public retirementBenefitUploadDocumentService: RetirementBenefitUploadDocumentService,
    public dialogRef: MatDialogRef<RetirementBenefitDetailUploadDocumentComponent>,
    public dialog: MatDialog,
    private calculationStore: Store<CalculationState>,
    private documentStore: Store<DocumentsState>,
    public layoutService: LayoutService,
    private readonly retirementBenefitDialogComponentService: RetirementBenefitDialogComponentService,
  ) {
    super(layoutService);
  }

  // Init function
  ngOnInit(): void {
    this.memberId = this.data.memberId;
    this.detailBenefitDocument = this.data.documentList;
    this.calculationBenefitId = this.data.calculationBenefitId;
    if (this.data.calculationType) this.calculationType = this.data.calculationType;
    if (this.data.calculationRecordId) this.calculationRecordId = this.data.calculationRecordId;
    this.checkRoute();
    this.getRetirementBenefitDocumentData();
    this.selectBenefitDocument();
    this.uploadDocumentForm = this.initUploadDocumentFormGroup();
    this.selectExistDocumentForm = this.initSelectDocumentGroup();
    this.selectorSelectedDownloadFile();
    this.uploadDocumentSelector();
    this.setValueByType();
  }

  checkRoute() {
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.listBreadcrumbs = this.getBreadcrumbs();
    });
  }

  getBreadcrumbs() {
    return [
      {
        name: 'Add Document',
      },
    ];
  }

  initUploadDocumentFormGroup() {
    return this.fb.group({
      documentName: this.fb.control('', {
        validators: [Validators.required],
        asyncValidators: checkApiValidator(this.retirementBenefitDialogService.checkExits, 'name', undefined, {
          params: { memberId: this.memberId },
        }),
      }),
      documentType: this.fb.control('', [Validators.required]),
      file: this.fb.control(null, [Validators.required]),
    });
  }

  initSelectDocumentGroup() {
    return this.fb.group({
      existingDocumentType: [''],
      documentId: this.fb.control(null, [Validators.required, this.checkDuplicateValidator()]),
    });
  }

  get documentTypeControl(): FormControl {
    return this.uploadDocumentForm.get('documentType') as FormControl;
  }

  get documentNameControl(): FormControl {
    return this.uploadDocumentForm.get('documentName') as FormControl;
  }

  get fileControl(): FormControl {
    return this.uploadDocumentForm.get('file') as FormControl;
  }

  get isNewDocumentControl(): FormControl {
    return this.checkNewDocForm?.get('isNewDocument') as FormControl;
  }

  get existingDocumentTypeControl(): FormControl {
    return this.selectExistDocumentForm?.get('existingDocumentType') as FormControl;
  }

  get documentIdControl(): FormControl {
    return this.selectExistDocumentForm?.get('documentId') as FormControl;
  }

  getRetirementBenefitDocumentData() {
    this.calculationStore.dispatch(
      getRetirementBenefitDocumentsAction({
        request: {},
        memberId: this.memberId,
        calculationType: this.calculationType,
        calculationBenefitId: this.calculationBenefitId
      }),
    );
  }

  selectBenefitDocument() {
    this.calculationStore
      .select(getRetirementBenefitDocumentSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((retirementBenefitDocument) => {
        if (!retirementBenefitDocument?.isLoading && retirementBenefitDocument?.success) {
          this.benefitDocuments = retirementBenefitDocument?.payload || [];
          this.filterExistingDocument();
        }
      });
  }

  changeDocumentId() {
    const selectedDocument = this.documentList.findIndex((document) => document.id === this.documentIdControl.value);
    if (selectedDocument !== -1) {
      this.documentIdControl.setErrors({ duplicated: true });
      return;
    }
    return true;
  }

  onChangeDocumentName() {
    const documentNameId = this.documentIdControl.value;
    this.selectedDocument = this.benefitDocuments.find((document) => document.id === documentNameId) || {};
    this.existingDocumentTypeControl.setValue(this.selectedDocument?.documentTypeName);
  }

  addExistingDocument() {
    this.documentIdControl?.markAllAsTouched();
    if (this.documentIdControl?.invalid) {
      return;
    }
    const cloneSelectedDocument = deepClone(this.selectedDocument);

    const document = {
      ...cloneSelectedDocument,
      fileName: cloneSelectedDocument.fileDocument.documentName,
      type: cloneSelectedDocument.documentType,
      index: new Date().getTime(),
      uploadDate: new Date(),
    };
    this.documentList = [...this.documentList, document];
    this.selectedDocument = null;
    this.selectExistDocumentForm.reset();
    this.filterExistingDocument();
    this.getFilteredDocuments();
  }

  validationBeforeSave() {
    const controls = this.uploadDocumentForm.controls;
    for (const control in controls) {
      if (controls[control].invalid) {
        if (control === 'file') {
          this.fileDocument.firstTouch = false;
          this.fileDocument.fileSelected = false;
        } else {
          this.uploadDocumentForm.get(control)?.markAllAsTouched();
        }
      }
    }
  }

  uploadFile(selectedFile?: File) {
    if (this.isNewDocumentControl.value && this.uploadDocumentForm.invalid) {
      this.validationBeforeSave();
      return;
    }
    const existDocumentName = this.documentList.findIndex(
      (document) => document.documentName?.localeCompare(this.documentNameControl.value) === 0,
    );
    if (existDocumentName !== -1) {
      this.documentNameControl.setErrors({ duplicated: true });
      return;
    }
    this.file = selectedFile;

    const document = {
      uploadDate: new Date(),
      documentName: this.documentNameControl.value,
      type: this.documentTypeControl.value,
      fileName: selectedFile?.name,
      file: selectedFile,
      documentTypeName: CalculationDocumentType[this.documentTypeControl.value],
      index: new Date().getTime(),
    };

    this.documentList = [...this.documentList, document];
    this.getFilteredDocuments();
    if (this.file) {
      this.fileDocument.fileSelected = null;
      this.fileDocument.isDuplicated = false;
      this.fileDocument.firstTouch = true;
      this.fileControl.setValue(null);
      this.fileDocument.inputFile.nativeElement.value = '';
      this.fileControl.reset('');
      this.documentNameControl.reset();
      this.documentTypeControl.reset();
    }
  }

  uploadDocumentSelector() {
    this.calculationStore
      .select(createRetirementBenefitDetailUploadDocumentSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((retirementBenefitDocument) => {
        if (retirementBenefitDocument && !retirementBenefitDocument?.isLoading) {
          if (retirementBenefitDocument?.success) {
            this.isUploading = retirementBenefitDocument?.isLoading;
            this.onCloseUploadDialog(ResponseStatus.Success);
          } else {
            this.onCloseUploadDialog(ResponseStatus.Error);
          }

          this.calculationStore.dispatch(clearCreateRetirementBenefitDetailUploadDocumentStateAction());
        }
      });
  }

  onSaveUploadDocument() {
    if (!this.documentList.length) {
      this.onCloseUploadDialog('close');
      return;
    }
    let fileRequest = this.documentList.map((doc) => {
      if (doc.id) {
        return {
          id: doc.id,
          uploadDate: doc.uploadDate,
          type: doc.type,
        };
      } else {
        return {
          uploadDate: doc.uploadDate,
          documentName: doc.documentName,
          type: doc.type,
          file: doc.file,
        };
      }
    });
    this.isUploading = true;
    const request: CreateRetirementBenefitDetailUploadDocumentRequest = {
      memberId: this.memberId,
      calculationType: this.calculationType,
      calculationBenefitId: this.calculationBenefitId,
      calculationRecordId: this.calculationRecordId,
      files: fileRequest,
    };
    this.calculationStore.dispatch(createRetirementBenefitDetailUploadDocumentAction({ request }));
  }

  onCloseUploadDialog(event: string) {
    this.dialogRef.close({
      event: event,
    });
    this.calculationStore.dispatch(clearCreateRetirementBenefitUploadDocumentsStateAction());
  }

  onCancelUploadDocument() {
    const ALERT_MESSAGE = 'Are you sure you want to cancel any/all changes?';
    const confirmDialog = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: ALERT_MESSAGE,
        type: ConfirmType.ConfirmWithDontShowAgain,
        title: 'Cancel Action',
        cancelButtonTitle: 'No',
      },
    });

    confirmDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.onCloseUploadDialog('close');
      }
    });
  }

  removeDocument(row: any) {
    this.documentList.splice(this.documentList.findIndex((document) => document === row));
    this.filterExistingDocument();
    this.getFilteredDocuments();
  }

  downloadSelectedFile(row: any) {
    // For downloading file on server
    if (row?.id) {
      this.documentStore.dispatch(clearGetDocumentDownloadStateAction());
      this.documentStore.dispatch(
        getDocumentDownloadAction({ fileId: row?.fileId as string, fileName: row.fileName as string }),
      );
    }
    // For downloading file that recently upload to table
    if(this.file) downloadFile.call(this, this.file, this.file?.name);
  }

  selectorSelectedDownloadFile() {
    this.calculationStore
      .select(getRetirementBenefitDownloadDocumentSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((downloadDocument) => {
        if (!downloadDocument?.isLoading && downloadDocument?.success) {
          let blobFile = downloadDocument?.payload ? downloadDocument?.payload[0] : new Blob();
          downloadFile.call(this, blobFile, this.downloadFileName);

          this.calculationStore.dispatch(clearGetRetirementBenefitDownloadDocumentStateAction());
        }
      });
  }

  onSortDocumentsTable(sort: Sort) {
    this.sortInfo = sort;
    this.getFilteredDocuments();
  }

  private checkDuplicateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.documentList.length) {
        return null;
      }
      const selectedDocument = this.documentList.findIndex((document) => document.id === control.value);
      if (selectedDocument !== -1) {
        return { documentExisted: 'Document Name already exists.' };
      }
      return null;
    };
  }

  private filterExistingDocument() {
    const existDocumentList = this.benefitDocuments
      .filter((document) => {
        return !this.detailBenefitDocument?.some((detailDocument) => detailDocument.id === document.id);
      })
      .filter((document) => {
        return !this.documentList.find((item) => item.id === document.id);
      });
    if (existDocumentList.length) {
      this.existDocumentList = existDocumentList
        .map((doc) => {
          return {
            value: doc.id,
            displayValue: doc.documentName,
          };
        })
        .sort((a, b) => a.displayValue.localeCompare(b.displayValue));
    }
  }

  private getFilteredDocuments() {
    this.filteredDocuments = [...this.documentList].sort((a: any, b: any) => {
      if (!this.sortInfo?.direction || !this.sortInfo.active) {
        if (
          DateTime.fromISO(a.uploadDate.toString()).startOf('day') <=
          DateTime.fromISO(b.uploadDate.toString()).startOf('day')
        ) {
          return -1;
        }
        return (a?.documentName ?? '').localeCompare(b?.documentName ?? '');
      }

      let ascendingSort = this.sortInfo?.direction === 'asc' ? 1 : -1;

      if (this.sortInfo.active === 'uploadDate') {
        const currentDate = DateTime.fromISO(a.uploadDate).startOf('day');
        const nextDate = DateTime.fromISO(b.uploadDate).startOf('day');
        return (currentDate <= nextDate ? -1 : 1) * ascendingSort;
      }
      const currentItem = (a as any)?.[this.sortInfo.active] ?? '';
      const nextItem = (b as any)?.[this.sortInfo.active] ?? '';
      return localeCompare(currentItem, nextItem) * ascendingSort;
    }).slice((this.pageNumber - 1) * this.pageSize, this.pageNumber * this.pageSize);
  }

  private setValueByType() {
    switch (this.calculationType) {
      case CalculationType.RetirementBenefit:
        this.notFoundMessage = 'No data to Display';
        this.documentTableTitle = 'Retirement Benefit Documents';
        this.calculationDocumentTypeOptions =
          this.retirementBenefitDialogComponentService.getCalculationDocumentTypeList(
            this.layoutService.fundType as unknown as FundType,
          );
        break;
      case CalculationType.Refund:
        this.notFoundMessage = 'No Refund Documents to Display';
        this.documentTableTitle = 'Refund Documents';
        this.calculationDocumentTypeOptions = this.calculationDocumentTypeOptions.filter(
          (item) => item.value === CalculationDocumentType.InvoiceVoucher,
        );
        break;
      case CalculationType.DisabilityLongTerm:
      case CalculationType.DisabilityShortTerm:
      case CalculationType.Disability:
        this.notFoundMessage = 'No Disability Documents to Display';
        this.documentTableTitle = 'Disability Documents';
        this.calculationDocumentTypeOptions = deepClone(DISABILITY_DOCUMENT_TYPE_OPTIONS);
        break;
      default:
        break;
    }
  }

  onChangePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    this.getFilteredDocuments();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
