import { EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { STYLE_COLUMN } from './gridview_transferring.const';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { dragHandle } from '../../constance/listIcons.const';

export interface ConfigColums {
  styleColums: string[];
  displayedColumns: string[];
  headerColumns: string[];
}

@Component({
  selector: 'ptg-gridview_transferring',
  templateUrl: './gridview_transferring.component.html',
  styleUrls: ['./gridview_transferring.component.scss']
})
export class GridviewTransferringComponent implements OnInit, OnChanges {
  
  @Input() configColums!: ConfigColums;
  @Input() dataTableLeft!: {} | any;
  @Input() dataTableRight!: {} | any;
  @Input() labelLeft?: string;
  @Input() labelRight?: string;
  @Input() sectionKey?: string;

  @Output() onDropDragAction = new EventEmitter();

  STYLE_COLUMN = STYLE_COLUMN;
  styleDisplayedColumns: string[] = [];
  displayedColumns: string[] = [];
  headerColumns: string[] = [];
  dataSourceLeft: never | any = new MatTableDataSource([]);
  dataSourceRight: never | any = new MatTableDataSource([]);
  isTruncated = true;
  isDragDisabled = true;

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIconLiteral('drag-handle', sanitizer.bypassSecurityTrustHtml(dragHandle));
  }

  ngOnInit() {
    this.styleDisplayedColumns = this.configColums.styleColums;
    this.displayedColumns = this.configColums.displayedColumns;
    this.headerColumns = this.configColums.headerColumns;
    this.dataSourceLeft = new MatTableDataSource(this.dataTableLeft.length === 0 ? [{noDrag: true}] : this.dataTableLeft);
    this.dataSourceRight = new MatTableDataSource(this.dataTableRight.length === 0 ? [{noDrag: true}] : this.dataTableRight);
    
  }

  ngOnChanges(changes: SimpleChanges) {
    this.dataSourceLeft = new MatTableDataSource(this.dataTableLeft.length === 0 ? [{noDrag: true}] : this.dataTableLeft);
    this.dataSourceRight = new MatTableDataSource(this.dataTableRight.length === 0 ? [{noDrag: true}] : this.dataTableRight);
  } 

  drop(event: CdkDragDrop<any>) {
    this.isDragDisabled = true;
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data.filteredData, event.previousIndex, event.currentIndex);
      if (event.container.id === 'list-right') {        
        this.dataSourceRight = new MatTableDataSource(event.container.data.filteredData);
        this.onDropDragAction.emit(this.dataSourceRight.filteredData);
      } else {
        this.dataSourceLeft = new MatTableDataSource(event.container.data.filteredData);
      }
    } else {
      if (this.sectionKey && event.previousContainer.id === 'list-right' &&  event.previousContainer.data.filteredData[event.previousIndex].key.split('.')[0] !== this.sectionKey) {
      
      } else {
        transferArrayItem(
          event.previousContainer.data.filteredData,
          event.container.data.filteredData,
          event.previousIndex,
          event.currentIndex,
        );
        if (event.previousContainer.id === 'list-right') {
          this.dataSourceRight = new MatTableDataSource(event.previousContainer.data.filteredData.length === 0 ? [{noDrag: true}] : event.previousContainer.data.filteredData.filter((item: any) => !item.noDrag));
          this.dataSourceLeft = new MatTableDataSource(event.container.data.filteredData.filter((item: any) => !item.noDrag));
        } else {
          this.dataSourceRight = new MatTableDataSource(event.container.data.filteredData.filter((item: any) => !item.noDrag));
          this.dataSourceLeft = new MatTableDataSource(event.previousContainer.data.filteredData.length === 0 ? [{noDrag: true}] : event.previousContainer.data.filteredData.filter((item: any) => !item.noDrag));
        }
        this.onDropDragAction.emit(this.dataSourceRight.filteredData.filter((item: any) => !item.noDrag));
      }        
    }
  }

  freeDragArea() {
    this.isDragDisabled = false;
  }
}
