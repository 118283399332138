import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ControlsModule } from 'src/app/shared/controls/controls.module';
import { ViewValueComponent } from 'src/app/shared/controls/view-value/view-value.component';
import { PipeModule } from 'src/app/shared/pipes/pipe.module';


@NgModule({
  declarations: [ViewValueComponent],
  imports: [
    CommonModule,
    ControlsModule,
    PipeModule,
    MatIconModule
  ],
  exports: [ViewValueComponent]
})
export class ViewValueModule {
}
