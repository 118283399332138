import { createSelector } from '@ngrx/store';

import { selectCalculationModuleState } from './selector';

export const calculationLoddBenefitSelector = createSelector(
  selectCalculationModuleState,
  (state) => state?.calculationLoddBenefit
);

export const createCalculationLoddBenefitsSelector = createSelector(
  calculationLoddBenefitSelector,
  (state) => state?.createCalculationLoddBenefits
);

export const loddValidationBeforeRecalculateSelector = createSelector(
  selectCalculationModuleState,
  (state) => state
);

export const getLoddValidationBeforeRecalculatesSelector = createSelector(
  calculationLoddBenefitSelector,
  (state) => state?.getLoddValidationBeforeRecalculates
);

