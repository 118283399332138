import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BulkUpdateHistoryActions, BulkUpdateLogActions } from '../actions';
import { BulkUpdateLog } from '../models/bulk-update-log.model';
import { BulkUpdateHistoryData } from '../models/bulk-update.model';
import { BulkUpdateService } from '../services/bulk-update.service';

@Injectable()
export class BulkUpdateEffects {
  getHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BulkUpdateHistoryActions.getBulkUpdateHistory),
      switchMap(action => {
        return this.bulkUpdateService.getHistory(action.target, action.targetType, action.pagination).pipe(
          map((bulkUpdateHistoryData: BulkUpdateHistoryData) => {
            bulkUpdateHistoryData.sessions.forEach(item => {
              item.dateUploaded = !item.dateUploaded || item.dateUploaded.includes('+') || item.dateUploaded.includes('Z') ? item.dateUploaded : item.dateUploaded + 'Z';
              item.importedAt = !item.importedAt || item.importedAt.includes('+') || item.importedAt.includes('Z') ? item.importedAt : item.importedAt + 'Z';
            });
            return BulkUpdateHistoryActions.getBulkUpdateHistorySuccess({ bulkUpdateHistoryData });
          }),
          catchError((error) => {
            if (error instanceof Error) {
              error = { message: `${error.stack} - ${error.message}` };
              console.log(error.message);
            }
            return of(BulkUpdateHistoryActions.getBulkUpdateHistoryFailure({ error }));
          })
        );
      })
    )
  );

  getLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BulkUpdateLogActions.getBulkUpdateLog),
      switchMap(action => {
        return this.bulkUpdateService.getLogs(action.sessionId).pipe(
          map((bulkUpdateLog: BulkUpdateLog) => {
            return BulkUpdateLogActions.getBulkUpdateLogSuccess({ bulkUpdateLog });
          }),
          catchError((err) => {
            return of(BulkUpdateLogActions.getBulkUpdateLogFailure({ errorMsg: err.message }));
          })
        );
      })
    )
  );

  cancelSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BulkUpdateLogActions.cancelBulkUpdate),
      switchMap(action => {
        return this.bulkUpdateService.cancelSession(action.sessionId).pipe(
          map(() => {
            return BulkUpdateLogActions.cancelBulkUpdateSuccess();
          }),
          catchError((err) => {
            return of(BulkUpdateLogActions.cancelBulkUpdateFailure({ errorMsg: err.message }));
          })
        );
      })
    )
  );

  continueSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BulkUpdateLogActions.proceedBulkUpdate),
      switchMap(action => {
        return this.bulkUpdateService.continueSession(action.sessionId).pipe(
          map(() => {
            return BulkUpdateLogActions.proceedBulkUpdateSuccess();
          }),
          catchError((err) => {
            return of(BulkUpdateLogActions.proceedBulkUpdateFailure({ errorMsg: err.message }));
          })
        );
      })
    )
  );

  removeBulkUpdateHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BulkUpdateHistoryActions.removeBulkUpdateHistory),
      switchMap(action => {
        return this.bulkUpdateService.removeHistory(action.sessionId).pipe(
          map(() => {
            return BulkUpdateHistoryActions.removeBulkUpdateHistorySuccess();
          }),
          catchError((err) => {
            return of(BulkUpdateHistoryActions.removeBulkUpdateHistoryFailure({ errorMsg: err.message }));
          })
        );
      })
    )
  );

  constructor(private actions$: Actions, private bulkUpdateService: BulkUpdateService) {}
}