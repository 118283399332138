import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { CheckPermissionService } from '../../../services/check-permission.service';

@Directive({
  selector: '[ptgCheckPermission]'
})
export class CheckPermissionDirective implements OnInit, OnDestroy {
  @Input() ptgCheckPermission!: string | string[];
  private onDestroy$ = new Subject<boolean>();

  constructor(
    private checkPermissionService: CheckPermissionService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnInit() {
    if (!this.ptgCheckPermission || this.checkPermissionService.checkPermission(this.ptgCheckPermission)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

}
