import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CreateNotificationAlertRequest, FundListQuery, GetFundListResponse, GetNotificationAlertResponse, TimeZones } from '../models/fund-list.model';

@Injectable({
  providedIn: 'root'
})
export class FundListService {

  constructor(private httpClient: HttpClient) { }

  clientList(query?: FundListQuery): Observable<GetFundListResponse> {
    let params = new HttpParams();
    if (query?.pageSize) {
      params = params.append('PageSize', query.pageSize.toString());
    }
    if (query?.pageIndex) {
      params = params.append('PageIndex', query.pageIndex.toString());
    }

    if (query?.sortField) {
      params = params.append('SortNames', query.sortField);
      if (query.sortField !== 'Name') {
        params = params.append('SortNames', 'Name');
      }
      params = params.append('SortType',  query.sortType ?  query.sortType : 0);
    } else {
      params = params.append('SortNames', 'Name');
      params = params.append('SortType', 0);
    }

    return this.httpClient.get<GetFundListResponse>(`${environment.apiUrl}/Clients`, {params}).pipe(
      map(el => ({...el, isLoading: false}))
    );
  }

  changeStatus(active: boolean, id: string): Observable<any> {
    if (active) {
      return this.httpClient.delete(`${environment.apiUrl}/Clients/${id}/Inactive`);
    } else {
      return this.httpClient.put(`${environment.apiUrl}/Clients/${id}/Inactive`, null);
    }
  }

  remove(id: string): Observable<any> {
    return this.httpClient.delete(`${environment.apiUrl}/Clients/${id}`);
  }

  getTimeZones(): Observable<TimeZones> {
    return this.httpClient.get<TimeZones>(`${ environment.apiUrl }/Clients/TimeZones`);
  }

  getNotificationAlert(): Observable<GetNotificationAlertResponse> {
    return this.httpClient.get<GetNotificationAlertResponse>(`${ environment.apiUrl }/Clients/notification-alert`);
  }

  updateNotificationAlertIsRead(notificationAlertId: string): Observable<any> {
    return this.httpClient.put(`${ environment.apiUrl }/Clients/notification-alert/${notificationAlertId}`, null);
  }

  createNotificationAlert(request: CreateNotificationAlertRequest) {
    return this.httpClient.post(`${ environment.apiUrl }/Clients/notification-alert`, request);
  }
}
