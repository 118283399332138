import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as ParticipantRelationshipActions from '../../store/actions/participant-relationship.actions';
import { ParticipantRelationshipService } from '../../services/participant-relationship.service';
import { MemberGraphViewData } from '../../types/models';

@Injectable()
export class ParticipantRelationshipEffects {
  getParticipantRelationships$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ParticipantRelationshipActions.getParticipantRelationship),
      switchMap(({ memberId, screenId }) => {
        return this.participantRelationshipService
          .getParticipantRelationships(memberId, screenId)
          .pipe(
            map((res: MemberGraphViewData) => {
              return ParticipantRelationshipActions.getParticipantRelationshipSuccess(
                { participantRelationships: res.memberGraphViewDatas }
              );
            }),
            catchError((err) => {
              return of(
                ParticipantRelationshipActions.getParticipantRelationshipFailure(
                  { errorMsg: err.message }
                )
              );
            })
          );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private participantRelationshipService: ParticipantRelationshipService
  ) {}
}
