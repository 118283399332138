<mat-form-field class="input-form-field {{class}}" appearance="fill" *ngIf="controlField">
  <mat-label *ngIf="hasLabel">{{placeholder}}</mat-label>
  <input matInput type="text" ptgNumberOnly
         [formControl]="inputControl"
         [placeholder]="hasLabel ? '' : placeholder"
         [maxlength]="maxLength ? maxLength : 'none'"
         [isPositive]="isPositive"
         [isDecimal]="isDecimal"
         [allowZero]="allowZero"
         [isStrict]="isStrict"
         (blur)="setNumberValue()">
  <mat-error *ngIf="inputControl?.errors?.required">{{errorRequire}}</mat-error>
  <mat-error *ngIf="inputControl?.errors?.pattern">{{errorPattern}}</mat-error>
  <mat-error *ngIf="inputControl?.errors?.inValidAsync">{{errorAsync}}</mat-error>
  <mat-error *ngIf="inputControl?.errors?.maxlength">{{errorMaxLength}}</mat-error>
  <mat-error *ngIf="inputControl?.errors?.min">{{errorMin}}</mat-error>
  <mat-error *ngIf="inputControl?.errors?.max">{{errorMax}}</mat-error>
  <mat-error *ngIf="controlField?.errors?.[customError]">{{controlField.getError(customError)}}</mat-error>
</mat-form-field>
