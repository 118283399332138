import { createAction, props } from '@ngrx/store';

import {
  OffCycleData,
  GetOffCyclePaymentsResponse,
  OffCyclePaymentListQuery,
  OffCycleActionState,
} from '../../services/models';

export const clearOffCycleStateAction = createAction(
  '[ClearOffCycleActionState] Clear'
);

export const getOffCyclePaymentsAction = createAction(
  '[GetOffCyclePayment/API] Send Request',
  props<{ query: OffCyclePaymentListQuery; isCompleted: boolean }>()
);
export const getOffCyclePaymentsSuccessAction = createAction(
  '[GetOffCyclePayment/API] Success',
  props<{ response: GetOffCyclePaymentsResponse }>()
);
export const getOffCyclePaymentsFailureAction = createAction(
  '[GetOffCyclePayment/API] Failure',
  props<{ error?: any }>()
);

export const getOffCycleAction = createAction(
  '[GetOffCycle/API] Send Request',
  props<{ offCycleId: string }>()
);
export const getOffCycleSuccessAction = createAction(
  '[GetOffCycle/API] Success',
  props<{ payees: OffCycleData }>()
);
export const getOffCycleFailureAction = createAction(
  '[GetOffCycle/API] Failure',
  props<{ errorMsg: string }>()
);

export const getCompletePaymentListAction = createAction(
  '[CompletePaymentList/API] Send Request',
  props<{ query: any }>()
);
export const getCompletePaymentListSuccessAction = createAction(
  '[CompletePaymentList/API] Success',
  props<{ response: GetOffCyclePaymentsResponse }>()
);
export const getCompletePaymentListFailureAction = createAction(
  '[CompletePaymentList/API] Failure',
  props<{ error?: any }>()
);

export const achSentAction = createAction(
  '[AchSentCompletePayment/API] Send Request',
  props<{ body: any }>()
);
export const achSentSuccessAction = createAction(
  '[AchSentCompletePayment/API] Success',
  props<{ body: any }>()
);
export const achSentFailureAction = createAction(
  '[AchSentCompletePayment/API] Failure',
  props<{ errorMsg: string }>()
);

export const checksPrintedAction = createAction(
  '[ChecksPrintedCompletePayment/API] Send Request',
  props<{ body: any }>()
);
export const checksPrintedSuccessAction = createAction(
  '[ChecksPrintedCompletePayment/API] Success',
  props<{ body: any }>()
);
export const checksPrintedFailureAction = createAction(
  '[ChecksPrintedCompletePayment/API] Failure',
  props<{ errorMsg: string }>()
);

export const editCheckNumberAction = createAction(
  '[EditCheckNumberCompletePayment/API] Send Request',
  props<{ body: any }>()
);
export const editCheckNumberSuccessAction = createAction(
  '[EditCheckNumberCompletePayment/API] Success'
);
export const editCheckNumberFailureAction = createAction(
  '[EditCheckNumberCompletePayment/API] Failure',
  props<{ errorMsg: string }>()
);
export const clearEditCheckNumberStateAction = createAction(
  '[ClearEditCheckNumberCompletePaymentState] Clear'
);

export const generateEDIOffCycleAction = createAction(
  '[GenerateEDIOffCycle/API] Send Request',
  props<{ offCycleId: any; selectedBankId: any; boardBankId: any }>()
);
export const generateEDIOffCycleSuccessAction = createAction(
  '[GenerateEDIOffCycle/API] Success',
  props<{ fileContent: any }>()
);
export const generateEDIOffCycleFailureAction = createAction(
  '[GenerateEDIOffCycle/API] Failure',
  props<{ errorMsg: string }>()
);
export const clearGenerateEDIOffCycleStateAction = createAction(
  '[ClearGenerateEDIOffCycleState] Clear'
);

export const reissueOffCycleAction = createAction(
  '[ReissueOffCycle/API] Send Request',
  props<{ offCycleId: any }>()
);
export const reissueOffCycleSuccessAction = createAction(
  '[ReissueOffCycle/API] Success'
);
export const reissueOffCycleFailureAction = createAction(
  '[ReissueOffCycle/API] Failure',
  props<{ errorMsg: string }>()
);
export const clearReissueOffCycleStateAction = createAction(
  '[ClearReissueOffCycleState] Clear'
);

export const finalizeCompletePaymentAction = createAction(
  '[FinalizeCompletePayment/API] Send Request'
);
export const finalizeCompletePaymentSuccessAction = createAction(
  '[FinalizeCompletePayment/API] Success'
);
export const finalizeCompletePaymentFailureAction = createAction(
  '[FinalizeCompletePayment/API] Failure',
  props<{ errorMsg: string }>()
);
export const clearFinalizeCompletePaymentStateAction = createAction(
  '[ClearFinalizeCompletePaymentState] Clear'
);

export const setCompletePaymentBoardBankAction = createAction(
  '[SetCompletePaymentBoardBank/API] Send Request',
  props<{ boardBankId: any; offCycleId: any }>()
);
export const setCompletePaymentBoardBankSuccessAction = createAction(
  '[SetCompletePaymentBoardBank/API] Success'
);
export const setCompletePaymentBoardBankFailureAction = createAction(
  '[SetCompletePaymentBoardBank/API] Failure',
  props<{ errorMsg: string }>()
);
export const clearSetBoardBankOffCycleStateAction = createAction(
  '[ClearSetBoardBankOffCycleState] Clear'
);

export const completeOffCyclePaymentsAction = createAction(
  '[CompleteOffCyclePayments/API] Send Request',
  props<{ offCyclePaymentIds: string[] }>()
);
export const completeOffCyclePaymentsSuccessAction = createAction(
  '[CompleteOffCyclePayments/API] Success',
  props<{ completeOffCyclePaymentId: string }>()
);
export const completeOffCyclePaymentsFailureAction = createAction(
  '[CompleteOffCyclePayments/API] Failure',
  props<{ errorMsg: string }>()
);
export const clearCompleteOffCyclePaymentIdAction = createAction(
  '[ClearCompleteOffCyclePaymentId] Clear'
);

export const updateCompleteOffCycleDepositDateAction = createAction(
  '[UpdateCompleteOffCycleDepositDate/API] Send Request',
  props<{ depositDate: string; offCycleId: string }>()
);
export const updateCompleteOffCycleDepositDateSuccessAction = createAction(
  '[UpdateCompleteOffCycleDepositDate/API] Success'
);
export const updateCompleteOffCycleDepositDateFailureAction = createAction(
  '[UpdateCompleteOffCycleDepositDate/API] Failure',
  props<{ errorMsg: string }>()
);
export const clearUpdateCompleteOffCycleDepositDateStateAction = createAction(
  '[ClearUpdateCompleteOffCycleDepositDateState] Clear'
);

export const cancelOffCyclePaymentsAction = createAction(
  '[CancelOffCyclePayments/API] Send Request'
);
export const cancelOffCyclePaymentsSuccessAction = createAction(
  '[CancelOffCyclePayments/API] Success'
);
export const cancelOffCyclePaymentsFailureAction = createAction(
  '[CancelOffCyclePayments/API] Failure',
  props<{ errorMsg: string }>()
);
export const clearCancelOffCyclePaymentsStateAction = createAction(
  '[CancelOffCyclePayments/API] Clear'
);

export const queueCancelOffCyclePaymentsSuccessAction = createAction(
  '[QueueCancelOffCyclePayments/API] Success'
);

export const getOffCycleActionStateRequestAction = createAction(
  '[GetOffCycleActionState/API] Send Request'
);
export const getOffCycleActionStateSuccessAction = createAction(
  '[GetOffCycleActionState/API] Success',
  props<{ offCycleActionState: OffCycleActionState }>()
);
export const getOffCycleActionStateFailureAction = createAction(
  '[GetOffCycleActionState/API] Failure'
);
