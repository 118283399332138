import { createAction, props } from '@ngrx/store';

import {
  CalculationBenefitWarningsParams,
  GetCalculationBenefitDetailsRequest,
  GetCalculationBenefitDetailsResponse,
  SetBenefitDetailParams,
  SetBenefitDetailRequest,
} from '../../services/models';
import {
  CreateGenerateCalculationWorksheetRequest,
  CreateGenerateCalculationWorksheetResponse,
} from '../../services/models';



export const getCalculationBenefitDetailsAction = createAction(
  '[GetCalculationBenefitDetails/API] Send Request',
  props<{ request: GetCalculationBenefitDetailsRequest }>(),
);

export const getCalculationBenefitDetailsSuccessAction = createAction(
  '[GetCalculationBenefitDetails/API] Success',
  props<{ response: GetCalculationBenefitDetailsResponse }>(),
);

export const getCalculationBenefitDetailsFailureAction = createAction(
  '[GetCalculationBenefitDetails/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetCalculationBenefitDetailsStateAction = createAction('[GetCalculationBenefitDetails] Clear');

export const setBenefitDetailAction = createAction(
  '[SetBenefitDetail/API] Send Request',
  props<{ params: SetBenefitDetailParams; request: SetBenefitDetailRequest; editItem: string }>(),
);

export const setBenefitDetailSuccessAction = createAction(
  '[SetBenefitDetail/API] Success',
  props<{ editItem: string }>(),
);

export const setBenefitDetailFailureAction = createAction(
  '[SetBenefitDetail/API] Failure',
  props<{ error?: any; editItem: string }>(),
);

export const clearSetBenefitDetailStateAction = createAction('[SetBenefitDetail] Clear');

export const closeBenefitWarningMessageAction = createAction(
  '[CloseBenefitWarningMessage/API] Send Request',
  props<{ params: CalculationBenefitWarningsParams }>(),
);

export const closeBenefitWarningMessageSuccessAction = createAction('[CloseBenefitWarningMessage/API] Success');

export const closeBenefitWarningMessageFailureAction = createAction(
  '[CloseBenefitWarningMessage/API] Failure',
  props<{ error?: any }>(),
);

export const clearCloseBenefitWarningMessageStateAction = createAction('[CloseBenefitWarningMessage] Clear');

export const createGenerateCalculationWorksheetAction = createAction(
  '[CreateGenerateCalculationWorksheet/API] Send Request',
  props<{ memberId: string, request: CreateGenerateCalculationWorksheetRequest }>()
);

export const createGenerateCalculationWorksheetSuccessAction = createAction(
  '[CreateGenerateCalculationWorksheet/API] Success',
  props<{ response: CreateGenerateCalculationWorksheetResponse }>()
);

export const createGenerateCalculationWorksheetFailureAction = createAction(
  '[CreateGenerateCalculationWorksheet/API] Failure',
  props<{ error?: any }>()
);

export const clearCreateGenerateCalculationWorksheetStateAction = createAction(
  '[CreateGenerateCalculationWorksheet] Clear'
);

