import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { map, takeUntil } from 'rxjs/operators';

import { BaseComponent } from '@ptg-shared/components';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ACTION_COLUMN, Column, Row } from '@ptg-shared/controls/grid';
import { Option } from '@ptg-shared/controls/select/select.component';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { showBanner } from '@ptg-shared/utils/common.util';
import { ACTION, DISCARD_CONFIRM_MESSAGE } from '@ptg-shared/constance';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { EditPropertyItemComponent } from '../edit-property-item/edit-property-item.component';
import { AddPropertyItemComponent } from '../add-property-item/add-property-item.component';
import { LayoutActions } from '@ptg-shared/layout/actions';
import * as fromReducer from '@ptg-reducers';

import { EntityPropertyType } from '../../types/enums';
import { EntityService } from '../../services';
import {
  addPropertySelector,
  deletePropertySelector,
  getEntitiesSelector,
  getSectionDetailSelector,
  setEntityPropertySelector,
  updateListConfigSelector,
} from '../../store/selectors';
import {
  CheckEntityPropertyInUsedResponse,
  DeletePropertyRequest,
  GetListDetailRequest,
} from '../../services/models';
import { EditListItemConfigurationComponent } from '../edit-list-item-configuration/edit-list-item-configuration.component';
import { EditAggregationPropertyItemComponent } from '../edit-aggregation-property-item/edit-aggregation-property-item.component';
import { EditCalculationPropertyItemComponent } from '../edit-calculation-property-item/edit-calculation-property-item.component';
import { EntityState } from '../../store/reducers';
import {
  clearAddPropertyState,
  clearDeletePropertyState,
  clearSetEntityPropertyStateAction,
  clearUpdateListConfigState,
  clearUpdatePropertyItemState,
  deleteProperty,
  getListDetail,
} from '../../store/actions';
import { EditEntityReferenceComponent } from '../edit-entity-reference/edit-entity-reference.component';

@Component({
  selector: 'ptg-edit-entity-list-item',
  templateUrl: './edit-entity-list-item.component.html',
  styleUrls: ['./edit-entity-list-item.component.scss'],
})
export class EditListItemComponent extends BaseComponent {
  bannerType: BannerType = BannerType.Hidden;
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Fund List',
      url: '/fund-list',
    },
    {
      name: 'Entity List',
      url: '/entity-management',
    },
    {
      name: 'Edit Entity',
    },
  ];
  message = '';
  formGroup?: FormGroup;
  parentEntityOptions: Option[] = [];
  isLoading = true;
  isLoadingProperty = true;
  propertiesData: (any & Row)[] = [];
  propertiesColumns: Column[] = [
    {
      name: 'propertyName',
      header: {
        title: 'Name',
      },
      truncate: true,
    },
    {
      name: 'type',
      header: {
        title: 'Type',
      },
      cell: (row) => {
        return this.getType(row.type);
      },
      truncate: true,
    },
    {
      name: 'dataType',
      header: {
        title: 'Data Type',
      },
      cell: (row) => {
        return this.getDataType(row.dataType);
      },
      truncate: true,
    },
    {
      name: 'importKey',
      header: {
        title: 'Import Label',
      },
      truncate: true,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      width: '170px',
    },
  ];
  entityData: any;
  section: any;
  title: string = '';
  clientId!: string;
  entityId!: string;
  entityComponentId!: string;
  PROPERTY_TYPE = EntityPropertyType;

  get nameCtrl() {
    return this.formGroup?.get('name') as FormControl;
  }
  get parentCtrl() {
    return this.formGroup?.get('parent') as FormControl;
  }
  get descriptionCtrl() {
    return this.formGroup?.get('description') as FormControl;
  }

  constructor(
    public fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditListItemComponent>,
    public router: Router,
    public entityService: EntityService,
    public entityStore: Store<EntityState>,
    public route: ActivatedRoute,
    private store: Store<fromReducer.State>
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store.dispatch(LayoutActions.hiddenSideMenu());
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.clientId = params.fundId;
      this.entityId = params.entityid;
      this.entityComponentId = params.entityComponentId;
    });
    this.createForm();
    this.getDataSubscribe();
    this.getData();
  }

  private getData(): void {
    const request: GetListDetailRequest = {
      entityComponentId: this.entityComponentId,
      entityId: this.entityId,
    };
    this.entityStore.dispatch(getListDetail({ request }));
  }

  private getDataSubscribe(): void {
    this.entityStore
      .pipe(select(getEntitiesSelector), takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.parentEntityOptions = (data?.payload ?? []).map((element: any) => {
          return {
            value: element.id,
            displayValue: element.name,
          };
        });
      });

    this.entityStore
      .pipe(
        select(addPropertySelector),
        map((state) => state?.payload),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((payload) => {
        if (payload && !payload.isContinue) {
          this.isLoadingProperty = true;
          this.getData();
          this.entityStore.dispatch(clearAddPropertyState());
        }
      });

    this.entityStore
      .pipe(select(getSectionDetailSelector), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state?.payload) {
          this.entityData = state?.payload;
          this.isLoading = state.isLoading;
          this.listBreadcrumbs = [
            {
              name: 'Fund List',
              url: '/fund-list',
            },
            {
              name: 'Entity List',
              url: `/entity-management/entities`,
            },
            {
              name: this.entityData.entityName,
              url: `/entity-management/entities/entity/${this.entityId}`,
            },
            {
              name: `${state?.payload?.name}`,
            },
          ];
          if (state?.payload) {
            this.section = {
              ...state?.payload,
              entityObjectId: state?.payload?.id,
            };

          }
          this.propertiesData = (state?.payload?.entityProperties ?? []).map(
            (item: any) => {
              return {
                ...item,
                entityPropertyId: item.id,
                type: item?.type,
                dataType: item?.type,
              };
            }
          );
          this.isLoadingProperty = false;
        }
      });

    this.entityStore
      .pipe(select(deletePropertySelector), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state) {
          if (state?.success === undefined) {
            return;
          }
          if (state?.success) {
            this.bannerType = BannerType.Success;
          } else {
            this.bannerType = BannerType.Fail;
          }
          showBanner.call(
            this,
            this.bannerType,
            state.payload?.propertyName || '',
            ACTION.REMOVE
          );
          this.getData();
          this.entityStore.dispatch(clearDeletePropertyState());
        }
      });

    this.entityStore
      .pipe(select(updateListConfigSelector), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state) {
          if (state?.success === undefined) {
            return;
          }
          if (state?.success) {
            this.bannerType = BannerType.Success;
          } else {
            this.bannerType = BannerType.Fail;
          }
          showBanner.call(
            this,
            this.bannerType,
            `List Configuration`,
            ACTION.EDIT
          );
          this.getData();
          this.entityStore.dispatch(clearUpdateListConfigState());
        }
      });

    this.entityStore
      .pipe(select(setEntityPropertySelector), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state && !state?.isLoading) {
          if (state?.success) {
            this.bannerType = BannerType.Success;
          } else {
            this.bannerType = BannerType.Fail;
          }
          showBanner.call(
            this,
            this.bannerType,
            state.payload?.propertyName || '',
            ACTION.EDIT
          );
          this.getData();
          this.entityStore.dispatch(clearSetEntityPropertyStateAction());
        }
      });
  }

  getType(entityPropertyType: EntityPropertyType): string {
    if (
      entityPropertyType === EntityPropertyType.Calculation ||
      entityPropertyType === EntityPropertyType.System ||
      entityPropertyType === EntityPropertyType.Aggregation ||
      entityPropertyType === EntityPropertyType['Entity Reference'] ||
      entityPropertyType === EntityPropertyType.Identifier
    ) {
      return EntityPropertyType[entityPropertyType];
    } else {
      return 'Data';
    }
  }

  getDataType(entityPropertyType: EntityPropertyType): string {
    let dataType = '';
    if (
      entityPropertyType === EntityPropertyType.Data ||
      entityPropertyType === EntityPropertyType.Calculation ||
      entityPropertyType === EntityPropertyType.System ||
      entityPropertyType === EntityPropertyType.Aggregation ||
      entityPropertyType === EntityPropertyType['Entity Reference'] ||
      entityPropertyType === EntityPropertyType.Identifier
    ) {
      dataType = '';
    } else {
      dataType = EntityPropertyType[entityPropertyType];
    }
    return dataType;
  }

  private createForm(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(''),
      parent: new FormControl(null),
      description: new FormControl(''),
    });
  }

  onCancel(): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: DISCARD_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  onClickRemoveProperty(row: any): void {
    let dialogRef: any;
    let request: DeletePropertyRequest = {
      entityId: this.entityId,
      entityComponentId: row.entityComponentId,
      entityPropertyId: row.entityPropertyId,
      propertyName: row.propertyName || row.name,
      isRemoveProperty: true
    };
    this.entityService
      .checkPropertyUsing(request)
      .subscribe((res: CheckEntityPropertyInUsedResponse) => {
        if (res?.exists) {
          dialogRef = this.dialog.open(ConfirmPopupComponent, {
            panelClass: 'confirm-popup',
            autoFocus: false,
            data: {
              title: 'Attention',
              text: 'This property cannot be removed because it is being used in a different configuration.',
              type: ConfirmType.Warning,
              cancelButtonTitle: 'Close',
              hideConfirmButton: true,
            },
          });
        } else {
          dialogRef = this.dialog.open(ConfirmPopupComponent, {
            panelClass: 'confirm-popup',
            autoFocus: false,
            data: {
              title: 'This is a destructive action',
              text: 'Removing this property will remove all of its related data. Are you sure you want to proceed?',
              type: ConfirmType.Destruct,
            },
          });
          dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
              this.isLoadingProperty = true;
              this.entityStore.dispatch(deleteProperty({ request }));
            }
          });
        }
      });
  }

  onClickEditProperty(row: any): void {
    this.entityStore.dispatch(clearUpdatePropertyItemState());
    let dialogRef;
    switch (row.type) {
      case EntityPropertyType.System:
        dialogRef = this.dialog.open(ConfirmPopupComponent, {
          panelClass: 'confirm-popup',
          data: {
            title: 'Warning',
            text: 'Cannot edit system property.',
            type: ConfirmType.Warning,
            cancelButtonTitle: 'Close',
            hideConfirmButton: true,
          },
        });
        break;
      case EntityPropertyType.Aggregation:
        dialogRef = this.dialog.open(EditAggregationPropertyItemComponent, {
          panelClass: 'edit-popup',
          disableClose: true,
          autoFocus: false,
          width: '800px',
          height: 'auto',
          data: {
            entityId: this.entityId,
            entityComponentId: this.entityComponentId,
            entityPropertyId: row.id,
          },
        });
        break;
      case EntityPropertyType['Entity Reference']:
        dialogRef = this.dialog.open(EditEntityReferenceComponent, {
          panelClass: 'edit-popup',
          disableClose: true,
          autoFocus: false,
          width: '800px',
          height: 'auto',
          data: {
            entityId: this.entityId,
            entityComponentId: this.entityComponentId,
            entityPropertyId: row.id,
          },
        });
        break;
      case EntityPropertyType.Calculation:
        dialogRef = this.dialog.open(EditCalculationPropertyItemComponent, {
          panelClass: 'edit-popup',
          disableClose: true,
          autoFocus: false,
          width: '800px',
          height: 'auto',
          data: {
            entityId: this.entityId,
            entityComponentId: this.entityComponentId,
            entityPropertyId: row.id,
          },
        });
        break;
      default:
        dialogRef = this.dialog.open(EditPropertyItemComponent, {
          panelClass: 'edit-popup',
          disableClose: true,
          autoFocus: false,
          width: '800px',
          height: 'auto',
          data: {
            entityId: this.entityId,
            entityComponentId: this.entityComponentId,
            entityPropertyId: row.id,
          },
        });
        break;
    }
  }

  onClickAddNewProperty(): void {
    this.entityStore.dispatch(clearAddPropertyState());
    const dialogRef = this.dialog.open(AddPropertyItemComponent, {
      panelClass: 'edit-popup',
      disableClose: true,
      autoFocus: false,
      width: '800px',
      height: 'auto',
      data: {
        clientId: this.clientId,
        entity: this.section,
        entityId: this.entityId,
        entityComponentId: this.entityComponentId,
        addInList: true,
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (result?.success === undefined) {
          return;
        }
        this.entityStore.dispatch(clearAddPropertyState());
        this.getData();
      } else {
        this.getData();
      }
    });
  }

  onEditListConfiguration(): void {
    this.dialog.open(EditListItemConfigurationComponent, {
      panelClass: 'edit-popup',
      disableClose: true,
      autoFocus: false,
      width: '860px',
      height: 'auto',
      data: {
        clientId: this.clientId,
        list: this.section,
        entityId: this.entityId,
      },
    });
  }
}
