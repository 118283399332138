import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import { EntityListComponent } from '../../services/models';
import {
  clearGetMemberEntityListComponentsStateAction,
  getMemberEntityListComponentsAction,
  getMemberEntityListComponentsFailureAction,
  getMemberEntityListComponentsSuccessAction,
} from '../actions';

export interface MemberEntityState {
  getMemberEntityListComponents?: BaseActionState<EntityListComponent[]>;
}

const initialState: MemberEntityState = {};

export const memberEntityReducer = createReducer(
  initialState,
  on(getMemberEntityListComponentsAction, (state) => ({
    ...state,
    getMemberEntityListComponents: {
      isLoading: true,
    },
  })),
  on(getMemberEntityListComponentsSuccessAction, (state, { response }) => ({
    ...state,
    getMemberEntityListComponents: {
      isLoading: false,
      success: true,
      payload: response.entityListComponents,
    },
  })),
  on(getMemberEntityListComponentsFailureAction, (state, { error }) => ({
    ...state,
    getMemberEntityListComponents: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetMemberEntityListComponentsStateAction, (state) => ({
    ...state,
    getMemberEntityListComponents: undefined,
  })),
);
