import { createAction, props } from '@ngrx/store';
import { ReportCategoryResponse, UpdateReportCategoriesRequest } from '@ptg-member/types/models/report.model';

export const GetReportCategoriesRequestAction = '[GetReportCategories/API] Send Request';
export const GetReportCategoriesSuccessAction = '[GetReportCategories/API] Failure';
export const GetReportCategoriesFailureAction = '[GetReportCategories/API] Success';
export const UpdateReportCategoriesRequestAction = '[UpdateReportCategories/API] Send Request';
export const UpdateReportCategoriesSuccessAction = '[UpdateReportCategories/API] Failure';
export const UpdateReportCategoriesFailureAction = '[UpdateReportCategories/API] Success';
export const ClearReportCategoriesState =  '[ClearReportCategorieState] Clear';

export const getReportCategories = createAction(GetReportCategoriesRequestAction);
export const getReportCategoriesSuccess = createAction(
  GetReportCategoriesSuccessAction,
  props<{ response: ReportCategoryResponse }>()
);
export const getReportCategoriesFailure = createAction(
  GetReportCategoriesFailureAction,
  props<{ error?: any }>()
);

export const updateReportCategories = createAction(
  UpdateReportCategoriesRequestAction,
  props<{ request: UpdateReportCategoriesRequest }>()
);
export const updateReportCategoriesSuccess = createAction(UpdateReportCategoriesSuccessAction);
export const updateReportCategoriesFailure = createAction(
  UpdateReportCategoriesFailureAction,
  props<{ error?: any }>()
);
export const clearReportCategoriesState = createAction(ClearReportCategoriesState);