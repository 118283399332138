import { createAction, props } from '@ngrx/store';
import { ExportTransactionRequest, TransactionRequest, TransactionResponse } from '@ptg-employer/models/transactions.model';

export const GetMunicipalityTransactionRequestAction = '[GetMunicipalityTransaction/API] Send Request';
export const GetMunicipalityTransactionSuccessAction = '[GetMunicipalityTransaction/API] Success';
export const GetMunicipalityTransactionFailureAction = '[GetMunicipalityTransaction/API] Failure';
export const ClearGetMunicipalityTransactionStateAction = '[GetMunicipalityTransaction] Clear';

export const GetTransactionRequestAction = '[GetTransaction/API] Send Request';
export const GetTransactionSuccessAction = '[GetTransaction/API] Success';
export const GetTransactionFailureAction = '[GetTransaction/API] Failure';
export const ClearGetTransactionStateAction = '[GetTransaction] Clear';

export const ClearCashJournalTransactionState = '[CashJournalTransactionState] Clear';
export const EditCashJournalTransactionRequest = '[EditCashJournalTransaction/API] Send Request';
export const EditCashJournalTransactionFailure = '[EditCashJournalTransaction/API] Failure';
export const EditCashJournalTransactionSuccess = '[EditCashJournalTransaction/API] Success';

export const GetExportTransactionRequestAction = '[GetExportTransaction/API] Send Request';
export const GetExportTransactionSuccessAction = '[GetExportTransaction/API] Success';
export const GetExportTransactionFailureAction = '[GetExportTransaction/API] Failure';
export const ClearGetExportTransactionStateAction = '[GetExportTransaction] Clear';

export const clearCashJournalTransactionState = createAction(
  ClearCashJournalTransactionState,
);
export const getMunicipalityTransaction = createAction(
  GetMunicipalityTransactionRequestAction,
  props<{ municipalityId: string, query: TransactionRequest }>()
);
export const getMunicipalityTransactionSuccess = createAction(
  GetMunicipalityTransactionSuccessAction,
  props<{ response: TransactionResponse }>()
);
export const getMunicipalityTransactionFailure = createAction(
  GetMunicipalityTransactionFailureAction,
  props<{ error?: any }>()
);
export const clearGetMunicipalityTransactionState = createAction(
  ClearGetMunicipalityTransactionStateAction
);

export const getTransaction = createAction(
  GetTransactionRequestAction,
  props<{ query: TransactionRequest }>()
);
export const getTransactionSuccess = createAction(
  GetTransactionSuccessAction,
  props<{ response: TransactionResponse }>()
);
export const getTransactionFailure = createAction(
  GetTransactionFailureAction,
  props<{ error?: any }>()
);
export const clearGetTransactionState = createAction(
  ClearGetTransactionStateAction
);

export const editCashJournalTransaction = createAction(
  EditCashJournalTransactionRequest,
  props<{ transactionId: string, cashJournalEntry: string, }>()
);
export const editCashJournalTransactionSuccess = createAction(
  EditCashJournalTransactionSuccess
);
export const editCashJournalTransactionFailure = createAction(
  EditCashJournalTransactionFailure,
  props<{ errorMsg: string }>()
);

export const getExportTransaction = createAction(
  GetExportTransactionRequestAction,
  props<{ query: ExportTransactionRequest }>()
);
export const getExportTransactionSuccess = createAction(
  GetExportTransactionSuccessAction,
  props<{ response: Blob[] }>()
);
export const getExportTransactionFailure = createAction(
  GetExportTransactionFailureAction,
  props<{ error?: any }>()
);
export const clearGetExportTransactionState = createAction(
  ClearGetExportTransactionStateAction
);
