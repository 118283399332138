import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmType } from '../../constance/confirm-type.const';
import { FormControl } from '@angular/forms';

export interface IconConfig {
  icon?: string;
  style?: string;
  class?: string;
  isSvg?: boolean;
}

export interface DialogData {
  text: string;
  type?: ConfirmType;
  title?: string;
  confirm?: boolean,
  cancelButtonTitle?: string,
  hideConfirmButton?: boolean,
  haveDontShowAgainCheckbox?: boolean,
  noTruncate?: boolean,
  saveButtonTitle?: string,
  hideSaveAsButton?: boolean,
  iconConfig?: IconConfig,
}

@Component({
  selector: 'ptg-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss'],
})
export class ConfirmPopupComponent implements OnInit {
  ConfirmType = ConfirmType;
  title: string = '';
  iconConfig: IconConfig = this.popupIconConfig;
  cancelButtonTitle: string = '';
  saveButtonTitle: string = 'Save';
  displayCheckBox = new FormControl(false);

  constructor(
    public dialogRef: MatDialogRef<ConfirmPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    this.data.confirm = true;
  }

  ngOnInit(): void {
    this.setPopupInfo();
    this.setCancelButtonTitle();
    this.setSaveButtonTitle();
  }

  setPopupInfo() {
    this.title = this.data.title ? this.data.title : '';
    switch (this.data.type) {
      case ConfirmType.Cancel:
      case ConfirmType.CancelPopup:
        this.title = this.data.title ? this.data.title : 'Cancel Action';
        break;
      case ConfirmType.Delete:
        this.title = this.data.title ? this.data.title : 'Remove Action';
        break;
      case ConfirmType.Destruct:
        this.title = 'THIS IS A DESTRUCTIVE ACTION';
        break;
    }
  }

  setCancelButtonTitle() {
    this.cancelButtonTitle = this.data.cancelButtonTitle || '';
    switch (this.data.type) {
      case ConfirmType.Error:
      case ConfirmType.Confirm:
        this.cancelButtonTitle = this.cancelButtonTitle || 'Close';
        break;
      case ConfirmType.CancelPopup:
        this.cancelButtonTitle = this.cancelButtonTitle ? this.cancelButtonTitle : 'Back';
        break;
      case ConfirmType.Warning:
      case ConfirmType.Destruct:
        this.cancelButtonTitle = this.cancelButtonTitle || 'Cancel';
        break;
      case ConfirmType.Attention:
        this.cancelButtonTitle = this.cancelButtonTitle ? this.cancelButtonTitle : 'No';
        break;
      default:
        this.cancelButtonTitle = this.cancelButtonTitle || 'No';
        break;
    }
  }

  onSaveAsClick() {
    this.dialogRef.close({ isSaveAs: true });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onConfirmWithDontShowAgain() {
    this.dialogRef.close({ dontShowAgain: this.displayCheckBox.value });
  }

  get popupIconConfig(): IconConfig {
    switch (this.data.type) {
      case ConfirmType.Cancel:
      case ConfirmType.CancelPopup:
        return {
          icon: 'cancel',
          style: 'color: #FDD855',
        };
      case ConfirmType.Error:
        return {
          icon: 'dangerous',
          style: 'color: #ff0000',
        };
      case ConfirmType.Warning:
        return {
          icon: 'error',
          style: 'color: #FD8A03',
        };
      case ConfirmType.Delete:
        return {
          icon: 'delete_forever',
          style: 'color: #E02E2E',
          class: 'material-icons-round',
        };
      case ConfirmType.Destruct:
        return {
          icon: 'warning',
          style: 'color: #E02E2E',
          class: 'material-icons-round',
        };
      case ConfirmType.Success:
        return {
          icon: 'check_circle',
          style: 'color: #14C457',
        };
      default:
        return {
          icon: 'error',
          style: 'color: #FDD855',
        };
    }
  }

  private setSaveButtonTitle() {
    this.saveButtonTitle = this.data.saveButtonTitle ?? 'Save';
  }
}
