export enum PropertyType {
  Text = 1,
  Email = 2,
  Phone = 3,
  'Whole Number' = 4,
  Currency = 5,
  Decimal = 6,
  Date = 7,
  Lookup = 50,
  Address = 100,
  'Person Name' = 101,
  Employer = 51,
  Binary = 9,
  Percentage = 10,
  SSN = 11,
  Aggregation = 103,
  'Elapsed Time' = 14,
  'Date Time' = 8,
  Department = 52,
  Benefit = 53,
}

const property_config = [
  { Key: 'readOnly', Value: 'true' },
  { Key: 'required', Value: 'true' },
  { Key: 'includeInBulkUpdate', Value: 'true' },
  { Key: 'inclusiveInRange', Value: 'true' },
  { Key: 'minInput', Value: '1' },
  { Key: 'maxInput', Value: '1000' },
  { Key: 'maximumLength', Value: 'true' },
  { Key: 'maxLengthInput', Value: '5' },
  { Key: 'fractionalLength', Value: 'true' },
  { Key: 'fractionalLengthInput', Value: '5' },
  { Key: 'SMSEnabled', Value: 'true' },
  { Key: 'prefixList', Value: 'true' },
  { Key: 'prefixListOpts', Value: '["Mr","Mrs","Ms"]' },
  { Key: 'listValues', Value: 'true' },
  { Key: 'listValueOpts', Value: '["Mr","Mrs","Ms"]' },
];

export enum BooleanPropertyConfigType {
  ReadOnly = 'readOnly',
  Required = 'required',
  IncludeInBulkUpdate = 'includeInBulkUpdate',
  InclusiveInRange = 'inclusiveInRange',
  MaximumLength = 'maximumLength',
  FractionalLength = 'fractionalLength',
  SMSEnabled = 'smsEnabled',
  PrefixList = 'prefixList',
  ListValues = 'listValues',
  Boolean = 'boolean',
  Initiated = 'initiated',
  UsedCountryLookupTable = 'usedCountryLookupTable',
  UsedStateLookupTable = 'usedStateLookupTable',
  Masked = 'masked',
  ExcludeFutureDates = 'excludeFutureDates',
  ExcludePastDates = 'excludePastDates',
  Validation = 'validation',
}

export enum PropertyConfigTypeValue {
  MinInput = 'minInput',
  MaxInput = 'maxInput',
  MaxLengthInput = 'maxLengthInput',
  FractionalLengthInput = 'fractionalLengthInput',
  LookupTable = 'lookupTable',
  Affirmative = 'affirmative',
  Negative = 'negative',
  CountryLookupTable = 'countryLookupTable',
  StateLookupTable = 'stateLookupTable',
}

export enum PropertyConfigDefaultLookupTable {
  DefaultStateLookupTable = 'defaultStateLookupTable',
  DefaultCountryLookupTable = 'defaultCountryLookupTable',
  DefaultTitleLookupTable = 'defaultTitleLookupTable',
}

export enum DefaultBooleanValue {
  Affirmative = 'Yes',
  Negative = 'No',
}

export type BooleanPropertyConfigTypeValue =
  | BooleanPropertyConfigType.ReadOnly
  | BooleanPropertyConfigType.Required
  | BooleanPropertyConfigType.IncludeInBulkUpdate
  | BooleanPropertyConfigType.InclusiveInRange
  | BooleanPropertyConfigType.MaximumLength
  | BooleanPropertyConfigType.FractionalLength
  | BooleanPropertyConfigType.SMSEnabled
  | BooleanPropertyConfigType.PrefixList
  | BooleanPropertyConfigType.ListValues
  | BooleanPropertyConfigType.Boolean
  | BooleanPropertyConfigType.UsedStateLookupTable
  | BooleanPropertyConfigType.UsedCountryLookupTable
  | BooleanPropertyConfigType.Initiated;

export enum PropertyTypeLabel {
  Currency = 'Currency',
  Text = 'Text',
  Decimal = 'Decimal',
  Email = 'Email',
  'Whole Number' = 'Whole Number',
  Phone = 'Phone',
  Date = 'Date',
  Address = 'Address',
  'Person Name' = 'Person Name',
  Lookup = 'Lookup',
  Binary = 'Binary',
  Employer = 'Employer',
  SSN = 'SSN',
  RichText = 'RichText',
}

export type PropertyTypeLabelValue = keyof typeof PropertyTypeLabel;

export enum SectionLayout {
  List,
  Table,
  Column,
  StatusHistory,
  Payment,
  PaymentHistory,
  ParticipantRelationship,
  MunicipalityServiceRecord,
  MunicipalityPayment,
  MunicipalityTotalService,
}

export enum FixedSection {
  Status = 'fixed__statushistory',
  ParticipantRelationship = 'fixed__memberrelationship',
  ServiceHistory = 'fixed__servicerecord',
  MuniTotal = 'fixed__municipalitytotal',
}

export const PrefixFixedSectionKey = 'fixed__';

export enum MenuItemName {
  ServiceHistory = 'Service History',
}

export const FixedSectionKey = {
  Demographics: 'demographics',
};
