import { createAction, props } from '@ngrx/store';
import { ParametersQuery } from '@ptg-employer/models';
import { GetDropPlanResponse, SetDropPlanRequest } from '@ptg-member/types/models/drop-plan.model';

export const GetDropPlanRequestAction =
  '[GetDropPlan/API] Send Request';
export const GetDropPlanSuccessAction =
  '[GetDropPlan/API] Success';
export const GetDropPlanFailureAction =
  '[GetDropPlan/API] Failure';

  export const SetDropPlanRequestAction =
  '[SetDropPlan/API] Send Request';
export const SetDropPlanSuccessAction =
  '[SetDropPlan/API] Success';
export const SetDropPlanFailureAction =
  '[SetDropPlan/API] Failure';


export const ClearDropPlan = '[ClearDropPlan] Clear';


export const getDropPlan = createAction(
  GetDropPlanRequestAction,
  props<{ query: ParametersQuery }>()
);

export const getDropPlanSuccess = createAction(
  GetDropPlanSuccessAction,
  props<{ dropPlan: GetDropPlanResponse }>()
);

export const getDropPlanFailure = createAction(
  GetDropPlanFailureAction,
  props<{ error?: any }>()
);

export const setDropPlan = createAction(
  SetDropPlanRequestAction,
  props<{ body: SetDropPlanRequest }>()
);

export const setDropPlanSuccess = createAction(
  SetDropPlanSuccessAction
);

export const setDropPlanFailure = createAction(
  SetDropPlanFailureAction,
  props<{ error?: any }>()
);

export const clearDropPlanState = createAction(ClearDropPlan);
