import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'binary'
})
export class BinaryPipe implements PipeTransform {

  transform(value: any, configs: any): unknown {
    if (value === 'true') {
      return configs.affirmative;
    }
    else {
      return configs.negative;
    }
  }

}
