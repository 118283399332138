import { Component, Input } from '@angular/core';

import { Address } from '@ptg-shared/types/models/common.model';

@Component({
  selector: 'ptg-address-column',
  templateUrl: './address-column.component.html',
  styleUrls: ['./address-column.component.scss'],
})
export class AddressColumnComponent {
  @Input() value!: Address;
  @Input() emptyValueDisplay!: string;
}
