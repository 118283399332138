<div class="dialog-container">
  <h3>Add New Menu</h3>
  <form class="edit-form" [formGroup]="addForm">
    <div class="input-container">
      <ptg-textbox
        [controlField]="addForm.get('name')"
        placeholder="Menu Name"
        [hasLabel]="true"
        [isRequired]="true"
        [maxLength]="100"
        errorAsync="Menu Name already exists."
      ></ptg-textbox>
      <ptg-select
        [controlField]="addForm.get('entityId')"
        [isOptionObj]="true"
        [isSetMaxWidthSelect]="true"
        [isCheckChange]="true"
        [listData]="listEntity"
        class="full-width"
        placeholder="Entity"
      >
      </ptg-select>
      <ptg-toggle-button
        [controlField]="addForm.get('isSingleView')"
        label="Single View"
      ></ptg-toggle-button>
    </div>
    <div class="wrap-btn">
      <button
        mat-raised-button
        type="button"
        class="btn-create-continue"
        (click)="formSubmit$.next(true)"
      >
        Create & Continue
      </button>
      <button
        mat-raised-button
        type="button"
        class="btn-create-add"
        (click)="formSubmit$.next(false)"
      >
        Create & Add Another
      </button>
      <button
        mat-raised-button
        type="button"
        class="btn-cancel"
        (click)="onCancel()"
      >
        Cancel
      </button>
    </div>
  </form>
</div>
