import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from '../utils/string.util';
import { GranulationOptionsType } from '@ptg-member/features/calculation/types/enums';

@Pipe({
  name: 'granulationOptions',
})
export class GranulationOptionsPipe implements PipeTransform {
  transform(value: string, option?: string): string {
    if (isEmpty(value)) return '';

    value = value?.replace(/\//gi, '|');

    if (isEmpty(option)) return value;

    const optionValue = parseInt(option ?? '0');
    const listValue = value?.split('|');
    if (optionValue === GranulationOptionsType.None || optionValue === GranulationOptionsType.YearsMonthsDays) {
      return value;
    }
    if (optionValue === GranulationOptionsType.Year) {
      return listValue[0].replace('Y', '');
    }
    if (optionValue === GranulationOptionsType.YearsMonths) {
      if (listValue.length <= 2) {
        return value;
      }

      return `${listValue[0]}|${listValue[1]}`;
    }

    return value;
  }
}
