import { Component, OnInit } from '@angular/core';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { Column } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { Sort } from '@angular/material/sort';
import { GeneratedDocumentInfo } from 'src/app/admin/features/file/types/models/generated-document.model';
import { ActivatedRoute } from '@angular/router';
import { BaseListComponent } from '@ptg-shared/components/base-list.component';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { DocumentsState } from 'src/app/admin/features/file/store/reducers';
import {
  clearGetDocumentDownloadStateAction,
  getDocumentDownloadAction,
  getGeneratedReportDetailAction,
} from 'src/app/admin/features/file/store/actions';
import { getGeneratedReportDetailSelector } from 'src/app/admin/features/file/store/selectors';
import { capitalizeFirstLetter, getDateString } from '@ptg-shared/utils/string.util';
import { DatePipe } from '@angular/common';
import { ACTION, SortType } from '@ptg-shared/constance';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import * as fromMember from '@ptg-member/store/reducers';
import { documentAttachSelector } from '@ptg-member/store/reducers';
import {
  clearDocumentAttachStateAction,
  documentAttachAction,
} from '@ptg-member/store/actions/participant-documents.actions';
import { DocumentAttachRequest } from '@ptg-member/services/models';
import { FileType } from 'src/app/admin/features/file/constants';
import { DOCUMENT_TEMPLATE } from 'src/app/admin/features/template/constants';
import { DOCUMENT_LOCATION } from '@ptg-shared/constance/document-location.const';
import { showBanner } from '@ptg-shared/utils/common.util';

@Component({
  selector: 'ptg-participant-generated-age65-letter',
  templateUrl: './participant-generated-age65-letter.component.html',
  styleUrls: ['./participant-generated-age65-letter.component.scss'],
})
export class ParticipantGeneratedAge65LetterComponent extends BaseListComponent implements OnInit {
  title = '';
  listBreadcrumbs: Breadcrumb[] = [];
  generatedDocumentInfo!: GeneratedDocumentInfo;

  columns: Column[] = [
    {
      name: 'firstName',
      header: {
        title: 'First Name',
      },
      width: '15%',
      truncate: true,
      sortable: true,
    },
    {
      name: 'lastName',
      header: {
        title: 'Last Name',
      },
      width: '15%',
      truncate: true,
      sortable: true,
    },
    {
      name: 'ssn',
      header: {
        title: 'SSN',
      },
      width: '15%',
      truncate: true,
      sortable: true,
    },
    {
      name: 'isAttached',
      header: {
        title: 'Attach to Profile',
      },
      width: '15%',
      truncate: true,
      sortable: true,
    },
    {
      name: 'fileName',
      header: {
        title: 'File Name',
      },
      width: '40%',
      truncate: true,
      sortable: false,
    },
  ];
  isLoading = false;
  tableDisableSelection = false;
  sortInfo: {} | any = {};
  lengthPg: number | any;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  bannerType: BannerType = BannerType.Hidden;
  message = '';
  generateId!: string;
  downloadFileName: string = '';

  dataTable!: any[];

  constructor(
    public route: ActivatedRoute,
    public layoutService: LayoutService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private documentStore: Store<DocumentsState>,
    private memberStore: Store<fromMember.MemberState>,
  ) {
    super(layoutService);
  }

  private get getBreadcrumbs(): Breadcrumb[] {
    return [
      {
        name: DOCUMENT_LOCATION.AGE65_LETTER,
        url: '/member/generate-age65-letter',
      },
      {
        name: this.title,
      },
    ];
  }


  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.generateId = params.id;
      this.getData();
    });

    this.listBreadcrumbs = this.getBreadcrumbs;
    this.selectGeneratedDetailSelector();
    this.selectDocumentAttachSelector();
  }

  selectGeneratedDetailSelector() {
    this.documentStore
      .pipe(select(getGeneratedReportDetailSelector),
        takeUntil(this.unsubscribe$),
      ).subscribe((data) => {
      if (data) {
        this.isLoading = data?.isLoading;
        if (data?.success) {
          this.lengthPg = data.payload?.total;
          this.dataTable = data.payload?.documents?.map((item: any) => {
            return {
              ...item,
              isDisabledCheckBox: !!item.isAttached,
              id: item.fileId,
            };
          }) ?? [];

          this.tableDisableSelection = data.payload?.documents?.every((item: any) => item.isAttached);
          this.title = data.payload?.zipFileName;
          this.listBreadcrumbs = this.getBreadcrumbs;
          this.generatedDocumentInfo = {
            template: DOCUMENT_TEMPLATE.AGE65_LETTER,
            fileExtension: FileType.docx,
            generatedAt: this.datePipe.transform(getDateString(data.payload?.generatedAt), 'MM/dd/yyyy h:mm a') ?? '',
            generatedBy: data.payload?.generatedBy,
            tags: data.payload?.tags,
          };
        }
      }
    });
  }

  getData() {
    let sortType = SortType.ASC;
    let sortNames = ['FirstName', 'LastName', 'SSN'];
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      if (this.sortInfo?.active === 'ssn') {
        sortNames = [(this.sortInfo.active).toUpperCase()];
      } else {
        sortNames = [capitalizeFirstLetter(this.sortInfo.active)];
      }
      sortType = this.sortInfo.direction === 'desc' ? SortType.DESC : SortType.ASC;
    }
    this.documentStore.dispatch(getGeneratedReportDetailAction({
      request: {
        generateId: this.generateId,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        sortNames,
        sortType,
      },
    }));
  }

  changePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    this.getData();
  }

  sortChange(sort: Sort) {
    this.sortInfo = sort;
    this.getData();
  }

  onAttachToProfile(listSelectedRow: any) {
    const listSelected = listSelectedRow.map((item: any) => {
      return {
        fileId: item.fileId,
        memberId: item.memberId,
      };
    });
    if (listSelected.length < 1) {
      this.showErrorPopup();
    } else {
      const attachRequest: DocumentAttachRequest = {
        attachInformations: listSelected ?? [],
      };
      this.memberStore.dispatch(documentAttachAction({ request: attachRequest }));
    }
  }

  selectDocumentAttachSelector() {
    this.memberStore
      .pipe(select(documentAttachSelector),
        takeUntil(this.unsubscribe$),
      ).subscribe((data) => {
      if (data) {
        this.isLoading = data?.isLoading;
        showBanner.call(this, data?.state, 'Document', ACTION.ATTACH);
        this.memberStore.dispatch(clearDocumentAttachStateAction());
        this.getData();
      }
    });
  }

  showErrorPopup() {
    this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: 'Please select a record before attaching document to profile.',
        title: 'Attention',
        type: ConfirmType.Warning,
        cancelButtonTitle: 'Close',
        hideConfirmButton: true,
      },
    });
  }

  onDownloadFile(row: any) {
    this.bannerType = BannerType.Hidden;
    if (!row) {
      return;
    }
    this.downloadFileName = row?.fileName;
    this.documentStore.dispatch(clearGetDocumentDownloadStateAction());
    this.documentStore.dispatch(
      getDocumentDownloadAction({ fileId: row?.fileId as string, fileName: row.fileName as string }),
    );
  }

}
