<div class="dialog-container">
    <div class="header-title">
        Add Menu
    </div>
    <form class="edit-form" [formGroup]="addForm">
      <div class="input-container">
        <ptg-textbox
          [controlField]="addForm.get('name')"
          placeholder="Menu Name"
          [hasLabel]="true"
          [isRequired]="true"
          [maxLength]="100"
        ></ptg-textbox>
      </div>
      <div class="row-button">
        <button mat-raised-button type="button" class="primary" (click)="formSubmit$.next(true)">Add</button>
        <button mat-raised-button type="button" class="cancel-button" (click)="onCancel()">Cancel</button>
      </div>
    </form>
</div>