<div class="retirement-benefit-calculation-detail-container" id="retirement-benefit-calculation-detail-page">
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
  ></ptg-breadcrumb>

  <div class="flex flex-col p-6 gap-6">
    <ptg-banner
      *ngIf="bannerType"
      [(bannerType)]="bannerType"
      [message]="message"
    ></ptg-banner>

    <ptg-retirement-benefit-calculation-detail-header
      title="Calculation File"
      [listFieldData]="listFieldData"
      (onEdit)="onClickEditCalculation()"
    ></ptg-retirement-benefit-calculation-detail-header>

    <ptg-retirement-benefit-calculation-detail-parameter
      *ngFor="let section of [calculationInputMapping, calculationOutputMapping]"
      [parameterMappingSection]="section"
      (sortChange)="sortChange($event)"
      (changePage)="changePage($event)"
      (onEdit)="onEdit($event)"
      (onRemove)="onRemove($event)"
      (onAdd)="onClickAddNewParameter($event)"
    ></ptg-retirement-benefit-calculation-detail-parameter>
  </div>
</div>
