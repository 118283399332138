export const STYLE_COLUMN = {
  CHECKBOX: 'checkbox',
  TEXT: 'Text',
  DATE_TIME: 'DateTime',
  ICON_LOCK: 'iconLock',
  ICON_NOT_REGISTERED: 'iconNotRegistered',
  ICON_BIO: 'iconBio',
  BUTTON_VIEW: 'buttonView',
  BUTTON_EDIT: 'buttonEdit',
  BUTTON_DOWNLOAD: 'buttonDownload',
  BUTTON_MEMBER: 'buttonMember',
  BUTTON_FUND: 'buttonFund',
  BUTTON_APP_CONTENT: 'buttonAppContent',
  BUTTON_REMOVE: 'buttonRemove',
  BUTTON_RESOURCE_CENTER: 'buttonResourceCenter',
  BUTTON_REMOVE_ACCOUNT: 'buttonRemoveAcc',
  BUTTON_LIST: 'buttonList',
  BUTTON_EDIT_ROW: 'buttonEditRow',
  GROUP_BUTTON: 'groupButton',
  LINK: 'link',
  IMAGE: 'image',
  STATUS: 'status',
  TEXT_HTML: 'text-html',
  FILE_SIZE: 'fileSize',
  BUTTON_RESTORE: 'buttonRestore',
  CURRENCY: 'Currency',
  PAYMENT_STATUS:'payment-status',
  DECIMAL: 'Decimal',
  BUTTON_TOGGLE: 'buttonToggle',
  NUMBER: 'number',
  DATE: 'Date',
  DATE_RANGE: 'dateRange',
  ACTION: 'Action',
  ADDRESS: 'Address',
  PERSON_NAME: 'Person_Name',
  WHOLE_NUMBER: 'Whole_Number',
  ICON_MARK: 'iconMark',
  PHONE: 'phone',
  ICON_DISABLED: 'iconDisabled',
  DEDUCTION_ICON_DISABLED: 'deductionIconDisabled',
  DESCRIPTION_ENABLE_SORT: 'description_enable_sort',
  ICON_NOTE: 'icon_note',
  NAME_PLUS: 'namePlus',
  PAYMENT_TYPE: 'paymentType',
  TEXT_PLUS: 'textPlus',
  CURRENCY_PLUS: 'currencyPlus',
  EDIT_CHECK_NUMBER: 'editCheckNumber',
  EDIT_CHECK_NUMBER_PAYMENT:'editCheckNumberPayment',
  MEMBER_STATUS: 'member_status',
  ICON_REGISTERED: 'iconRegistered',
  MULTIPLE_LINE_TEXT: 'multipleLineText',
  SORT_TYPE: 'sortType',
  PERCENTAGE: 'Percentage',
  BALLOON: 'balloon',
  CHIP: 'chip',
  COLOR: 'color',
  LIST_STATUS: 'listStatus',
  LOOKUP: 'Lookup',
  BINARY: 'Binary',
  EMAIL: 'Email',
  PHONE_NUMBER: 'Phone',
  EMPLOYER: 'Employer',
  SSN_NUMBER: 'SSN',
  REGISTER_STATUS: 'register-status',
  RICH_TEXT: 'RichText',
  DEPARTMENT: 'Department',
  TRANSACTION_STATUS: 'transaction_status ',
  URL: 'Url',
  MISSING_ADDRESS: 'missing-address',
  TEXT_WITH_EMPTY: 'text-empty',
  PERSON_OUTLINE: 'person_outline'
};
