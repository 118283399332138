import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { BaseComponent } from '@ptg-shared/components';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { SUBMODULE_KEY } from '@ptg-shared/constance/permission.const';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import * as fromMember from '@ptg-member/store/reducers';
import { clearUpdateMemberCardStateAction } from '@ptg-member/store/actions';
import { updateMemberCardSelector } from '@ptg-member/store/selectors/member.selector';

import { EntityProfileConfigOverview, EntityViewItem } from '../../services/models';
import { EntityProfileFixType, EntityViewType } from '../../types/enums';

@Component({
  selector: 'ptg-entity-summary-view',
  templateUrl: './entity-summary-view.component.html',
  styleUrls: ['./entity-summary-view.component.scss'],
})
export class EntitySummaryViewComponent extends BaseComponent implements OnChanges {
  readonly BannerType = BannerType;
  readonly EntityViewType = EntityViewType;
  readonly EntityProfileFixType = EntityProfileFixType;

  listBreadcrumbs: Breadcrumb[] = [];
  settings: Breadcrumb[] = [];
  pageTitle: string = '';

  warningBanner: BannerType = BannerType.Hidden;
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';

  @Input() entityId!: string;
  @Input() targetId!: string;
  @Input() screenId?: string;
  @Input() profileConfigOverview?: EntityProfileConfigOverview;
  @Input() cards: EntityViewItem[] = [];

  constructor(
    public dialog: MatDialog,
    public route: ActivatedRoute,
    private memberStore: Store<fromMember.MemberState>,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    // Update member card
    this.memberStore.pipe(select(updateMemberCardSelector), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state) {
        if (state.success === true) {
          this.bannerType = BannerType.Success;
          this.message = `${state.payload?.cardName} successfully updated.`;
        }

        this.memberStore.dispatch(clearUpdateMemberCardStateAction());
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.profileConfigOverview) {
      this.listBreadcrumbs = [
        {
          name: 'Participants',
          moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
          url: '/member',
        },
        {
          name: '',
          url: '',
        },
      ];

      if (this.profileConfigOverview) {
        this.pageTitle = this.profileConfigOverview?.overviewLabel ?? '';
        this.listBreadcrumbs[1] = {
          name: this.pageTitle,
          url: '',
        };
      }
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
