<div class="generate-age65-letter-container">
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
  ></ptg-breadcrumb>

  <div class="generate-age65-letter-section flex flex-col p-6">
    <form [formGroup]="editForm" (ngSubmit)="formSubmit$.next()" class="flex flex-col gap-4">
      <div class="flex date-field-section gap-x-4">
        <ptg-datepicker
          [controlField]="editForm.get('fromDate')"
          placeholder="From Date"
          [isRequired]="true"
          [maxDate]="maxDate"
          [errorMaxDate]="errorFromPossibleDate"
          (changeValue)="controlDate()"
          customError="customFromError"
        ></ptg-datepicker>
        <ptg-datepicker
          [controlField]="editForm.get('thruDate')"
          placeholder="Thru Date"
          [isRequired]="true"
          [minDate]="today"
          [maxDate]="maxDate"
          [errorMinDate]="errorThruPossibleDate"
          [errorMaxDate]="errorThruPossibleDate"
          (changeValue)="controlDate()"
          customError="customThruError"
        ></ptg-datepicker>
      </div>
      <div class="flex">
        <div class="autocomplete-chip-section">
          <div class="auto-complete-section">
            <div class="select-container">
              <div class="select-tag">
                <mat-form-field class="auto-complete" appearance="fill">
                  <mat-label>Tags</mat-label>
                  <input class="custom-input" type="text" matInput [formControl]="tagsAutoCompleteControl"
                         [matAutocomplete]="auto" (blur)="validateTags()" (focus)="tagsFocus($event)">
                  <span class="custom-arrow mat-select-arrow-wrapper"><span class="mat-select-arrow"></span></span>
                  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayTagFn">
                    <mat-option *ngFor="let option of filteredByTypingTagOptions | async" [value]="option">
                      {{ option.displayValue }}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error
                    *ngIf="!tagsAutoCompleteControl?.errors?.required && tagsAutoCompleteControl?.errors?.inValidAsync">
                    Value must be selected from result list.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="chip-container">
                  <span class="add-chip-btn-section">
                    <button
                      mat-icon-button
                      (click)="onAddNewChip()"
                      [disabled]="!tagsAutoCompleteControl.value || tagsAutoCompleteControl?.errors?.inValidAsync"
                    >
                      <mat-icon>add_circle</mat-icon>
                    </button>
                  </span>
                <div class="tags-chip-list-section">
                  <mat-chip-list *ngIf="tagsChipListControl?.length">
                    <mat-chip *ngFor="let item of tagsChipListControl.controls; index as i"
                              (removed)="onRemoveChip(item.value, i)">
                        <span class="chip-wrapper">
                          <span class="chip__txt--primary">
                            <span class="mr-1">{{ item.value.displayValue }}</span>
                          </span>
                        </span>
                      <button matChipRemove>
                        <mat-icon>close</mat-icon>
                      </button>
                    </mat-chip>
                  </mat-chip-list>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex">
        <ptg-button
          classInput="primary"
          buttonName="Generate Report"
          type="submit"
          class="generate-button"
          [isDisabled]="isValidateLoading || isGenerateLoading"
        >
          <mat-icon class="material-icons-round">download</mat-icon>
        </ptg-button>
      </div>
    </form>

    <div
      class="flex-grow overflow-hidden generate-letters-section mt-12"
      [ngClass]="{'is-hidden': isGenerateLoading || isValidateLoading}"
    >
      <div class="header">
        <span class="title font-bold">Generated Reports</span>
      </div>
      <ptg-grid
        [data]="tableData"
        [isLoading]="isTableLoading"
        [columns]="columns"
        [pageSize]="pageSize"
        [pageNumber]="pageIndex"
        [totalRecords]="totalRecords"
        (pageChange)="onChangePage($event)"
        (sortChange)="onSortChange($event)"
        [fitToParent]="true"
        notFoundMessage="No Data to Display"
      >
        <ng-template cellContent columnName="fileName" let-row>
          <div class="flex file-document-container">
            <span stopPropagation (click)="downloadLetter(row)" class="file-document-name" target="_blank">
              <span class="truncate">{{ row?.status === 'Completed' ? row?.fileName : '' }}</span>
            </span>
          </div>
        </ng-template>
        <ng-template cellContent columnName="thruDate" let-row>
          <span class="truncate">{{ row?.thruDate ?? '' }}</span>
        </ng-template>
        <ng-template cellContent columnName="numberOfRecords" let-row>
          <span class="truncate">{{ row?.numberOfRecords ?? '' }}</span>
        </ng-template>
      </ptg-grid>
    </div>
    <div
      id="loading-check"
      class="is-loading-section"
      [ngClass]="{'is-hidden': !isGenerateLoading && !isValidateLoading}"
    >
      <div class="wrap-item">
        <mat-progress-spinner diameter="50" mode="indeterminate">
        </mat-progress-spinner>
        <p>{{ isGenerateLoading ? 'Generating...' : 'Loading...' }}</p>
      </div>
    </div>
  </div>
</div>
