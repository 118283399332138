<ptg-dialog
  class="update-entity-dialog"
  [title]="'Add New Entity'"
  [footerPosition]="'bottom'"
  [footerAlign]="'right'"
  [isFullScreen]="false"
>
  <ng-template dialogBody>
    <form *ngIf="formGroup" id="formData" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
      <div class="flex gap-2 group-control">
        <ptg-textbox
          [controlField]="nameCtrl"
          [isRequired]="true"
          placeholder="Entity Name"
          [maxLength]="250"
          [hasLabel]="true"
          [errorAsync]="'Entity Name already exists.'"
        ></ptg-textbox>
        <ptg-textbox
          [controlField]="codeCtrl"
          [isRequired]="true"
          placeholder="Entity Code"
          [maxLength]="250"
          [hasLabel]="true"
          [errorAsync]="'Entity Code already exists.'"
        ></ptg-textbox>
      </div>
      <div class="flex gap-2 group-control">
        <ptg-select
          [controlField]="parentCtrl"
          [listData]="parentEntityOptions"
          [isOptionObj]="true"
          placeholder="Parent Entity"
          [hasNoneValue]="true"
          [isSetMaxWidthSelect]="true"
        ></ptg-select>
        <ptg-toggle-button
          [controlField]="versioningCtrl"
          [label]="'Enable Versioning'"
        ></ptg-toggle-button>
      </div>
      <ptg-input-area 
        [controlField]="descriptionCtrl"
        placeholder="Description"
      ></ptg-input-area>
    </form>
  </ng-template>

  <ng-template dialogFooter>
    <button mat-raised-button type="button" class="submit" (click)="onClickCreateAndContinue()">
      Create & Continue
    </button>

    <button mat-raised-button type="button" class="submit second-button" (click)="onClickCreateAndAddAnother()">
      Create & Add Another
    </button>

    <button mat-raised-button type="button" class="cancel" (click)="onCancel()">
      Cancel
    </button>
  </ng-template>
</ptg-dialog>