import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { takeUntil, filter } from 'rxjs/operators';

import { BaseComponent } from '@ptg-shared/components';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';

import { Store } from '@ngrx/store';
import {
  CalculationState,
  getBenefitCalculationParameterMappingAction,
  getBenefitCalculationParameterMappingSelector,
  getCalculationDisplayConfigurationByTypeAction,
  getCalculationDisplayConfigurationByTypeSelector,
  setModifyCalculationDisplayConfigurationAction,
  setModifyCalculationDisplayConfigurationSelector,
  clearSetModifyCalculationDisplayConfigurationStateAction,
} from '@ptg-member/features/calculation/store';

import { deepClone, showBanner } from '@ptg-shared/utils/common.util';
import { Option } from '@ptg-shared/controls/select/select.component';

import {
  ParameterMapping,
  DisplayOutputsConfiguration,
  ModifiedDisplayOutputsConfiguration,
} from '../../services/models';
import { ACTION, STATE } from '@ptg-shared/constance';
import { CalculationType, GetParameterMappingType } from '../../types/enums';

@Component({
  selector: 'ptg-benefit-calculation-configuration',
  templateUrl: './benefit-calculation-configuration.component.html',
  styleUrls: ['./benefit-calculation-configuration.component.scss'],
})
export class BenefitCalculationConfigurationComponent extends BaseComponent implements OnInit {
  bannerType: BannerType = BannerType.Hidden;
  listBreadcrumbs: Breadcrumb[] = this.getBreadcrumbs;

  configSectionLabel = '';
  displayId = '';
  outputOptions!: Option[];
  configurations: DisplayOutputsConfiguration[] = [];

  message = '';
  memberId = '';
  isLoading = false;

  constructor(
    public readonly router: Router,
    public readonly dialog: MatDialog,
    public readonly route: ActivatedRoute,
    public readonly calculationStore: Store<CalculationState>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.checkRouteParams();
    this.getData();
    this.selectBenefitCalculationConfigurationState();
    this.selectSetModifiedDisplayOutputsConfigurationState();
  }

  onSubmit(displayOutputsConfiguration: ModifiedDisplayOutputsConfiguration): void {
    this.calculationStore.dispatch(
      setModifyCalculationDisplayConfigurationAction({
        id: this.displayId,
        request: displayOutputsConfiguration,
      }),
    );
  }

  private get getBreadcrumbs(): Breadcrumb[] {
    return [
      {
        name: 'Retirement Benefit Overview',
        url: `/member/benefit-overview/${CalculationType.RetirementBenefit}/${this.memberId}`,
      },
      {
        name: 'Benefit Calculation Display Configuration',
        url: '',
      },
    ];
  }

  private checkRouteParams(): void {
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe(({ memberId }) => {
      if (memberId) {
        this.memberId = memberId;
        this.listBreadcrumbs = this.getBreadcrumbs;
      }
    });
  }

  private getData(): void {
    this.calculationStore.dispatch(
      getBenefitCalculationParameterMappingAction({
        parameterType: GetParameterMappingType.Output,
      }),
    );
    this.calculationStore.dispatch(
      getCalculationDisplayConfigurationByTypeAction({
        calculationType: CalculationType.RetirementBenefit,
      }),
    );
  }

  private selectBenefitCalculationConfigurationState(): void {
    this.calculationStore
      .select(getBenefitCalculationParameterMappingSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((parameterMappings) => {
        this.getOutputOptions(parameterMappings?.payload?.outputMappings ?? []);
      });

    this.calculationStore
      .select(getCalculationDisplayConfigurationByTypeSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((calculationParameters) => {
        this.isLoading = !!calculationParameters?.isLoading;
        if (!calculationParameters?.success) {
          return;
        }
        this.configSectionLabel = calculationParameters?.payload?.displayConfiguration?.label ?? 'Benefit Calculation';
        this.displayId = calculationParameters?.payload?.displayConfiguration?.id ?? '';

        this.configurations = (calculationParameters?.payload?.displayOutputsConfiguration ?? []).map((calculation) => {
          return {
            ...calculation,
            description: [calculation.parameterName, calculation.benefitOptionName]
              .filter((x) => !!x)
              .join('/'),
          };
        });
      });
  }

  private selectSetModifiedDisplayOutputsConfigurationState(): void {
    this.calculationStore
      .select(setModifyCalculationDisplayConfigurationSelector)
      .pipe(
        filter((setCalculationParameter) => !!setCalculationParameter && !setCalculationParameter?.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((setCalculationParameter) => {
        showBanner.call(
          this,
          setCalculationParameter?.success ? STATE.SUCCESS : STATE.FAIL,
          'Benefit Calculation Display Configuration',
          ACTION.EDIT,
        );
        this.calculationStore.dispatch(clearSetModifyCalculationDisplayConfigurationStateAction());
        if (setCalculationParameter?.success) {
          this.calculationStore.dispatch(
            getCalculationDisplayConfigurationByTypeAction({
              calculationType: CalculationType.RetirementBenefit,
            }),
          );
        }
      });
  }

  private getOutputOptions(parameterMappings: ParameterMapping[]) {
    this.outputOptions = deepClone(parameterMappings).map((parameterMapping) => {
      return {
        value: parameterMapping,
        displayValue: parameterMapping.name,
        valueDescription: parameterMapping.benefitEntityName,
      };
    });
  }
}
