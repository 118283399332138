import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { SortType } from '@ptg-shared/constance';

import {
  GetCanEditOrRemoveRequest,
  GetCanEditOrRemoveResponse,
  GetDisbursementReportDetailRequest,
  GetDisbursementReportDetailResponse,
} from './models';

@Injectable()
export class DisbursementReportDetailService {
  constructor(private httpClient: HttpClient) {}

  getDisbursementReportDetail(
    request: GetDisbursementReportDetailRequest,
    clientId: string,
    disbursementReportId: string
  ): Observable<GetDisbursementReportDetailResponse> {
    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }

    return this.httpClient.get<GetDisbursementReportDetailResponse>(
      `${environment.apiUrl}/disbursement-reports/${disbursementReportId}`,
      { params }
    );
  }
  getCanEditOrRemove(request: GetCanEditOrRemoveRequest): Observable<GetCanEditOrRemoveResponse> {
    let params = new HttpParams();
    params = params.append('isEditing', request.isEditing ?? false)
    return this.httpClient.get<GetCanEditOrRemoveResponse>(
      `${environment.apiUrl}/disbursement-reports/${request.disbursementReportId}/payments/${request.disbursementReportPaymentId}/validation`,
      { params }
    );
  }
}
