<div class="generated-document-list h-full">
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
    (onAdd)="onAttachToProfile()"
    buttonAddLabel="Attach to Profile"
  ></ptg-breadcrumb>
  <div class="generated-document flex flex-col gap-y-6 h-full">
    <div class="px-6 pt-6">
      <ptg-banner
        *ngIf="bannerType"
        [(bannerType)]="bannerType"
        [message]="message"
      ></ptg-banner>
      <div class="generated-info flex">
        <div class="item">
          <p class="text-gray-500 text-sm">Template</p>
          <p>{{ generatedDocumentInfo?.template }}</p>
        </div>
        <div class="item ml-12">
          <p class="text-gray-500 text-sm">File Extension</p>
          <p>{{ generatedDocumentInfo?.fileExtension }}</p>
        </div>
        <div class="item ml-12">
          <p class="text-gray-500 text-sm">Generated At</p>
          <p>{{ generatedDocumentInfo?.generatedAt }}</p>
        </div>
        <div class="item ml-12">
          <p class="text-gray-500 text-sm">Generated By</p>
          <p>{{ generatedDocumentInfo?.generatedBy }}</p>
        </div>
      </div>
      <div class="flex pt-4 items-center">
        <label class="text-sm text-gray-500">Tags</label>
        <div class="tags-chip-list-section">
          <mat-chip-list *ngIf="generatedDocumentInfo?.tags?.length">
            <mat-chip *ngFor="let item of generatedDocumentInfo?.tags">
            <span class="chip-wrapper">
              <span class="chip__txt--primary">
                <span class="mr-1">{{ item.name || '' }}</span>
              </span>
            </span>
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
    </div>
    <div class="flex-grow overflow-hidden">
      <ptg-grid
        #gridGeneratedDocumentList
        [allowSelection]="true"
        [disableSelection]="disableSelection"
        [data]="dataTable"
        [columns]="columns"
        [isLoading]="isLoading"
        [totalRecords]="totalRecords"
        [pageSize]="pageSize"
        [pageNumber]="pageNumber"
        (pageChange)="onChangePage($event)"
        (sortChange)="onChangeSort($event)"
        (selectionChange)="onChangeSelection()"
        [fitToParent]="true"
        [hideScrollbar]="false"
        notFoundMessage="No Data to Display"
      >
        <ng-template cellContent columnName="fileName" let-row>
          <div class="flex file-document-container">
            <span (click)="onDownloadFile(row)" class="file-document-name" target="_blank">
              <span class="truncate">{{ row?.fileName }}</span>
            </span>
          </div>
        </ng-template>
        <ng-template cellContent columnName="isAttached" let-row>
          {{ row?.isAttached ? 'Yes' : 'No' }}
        </ng-template>
      </ptg-grid>
    </div>
  </div>
</div>
