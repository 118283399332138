import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { NewVendor, VendorList, VendorListQuery } from './models';

@Injectable()
export class VendorMaintenanceService {
  bankInfo: any;
  clientId: string;
  get Endpoint(): string {
    return `${environment.apiUrl}/Vendors`;
  }

  constructor(private httpClient: HttpClient) {
    this.clientId = '';
  }

  vendorList(query: VendorListQuery, clientId: string): Observable<VendorList> {
    this.clientId = clientId;
    let params = new HttpParams();
    params = params.append('ClientId', clientId);
    if (query.totalPerPage) {
      params = params.append('PageSize', query.totalPerPage);
    }
    params = params.append('PageIndex', query.pageIndex);

    if (query.sortField) {
      params = params.append(
        'SortNames',
        query.sortField === 'providerTypeString'
          ? 'ProviderType'
          : query.sortField[0].toUpperCase() + query.sortField.substring(1)
      );
      params = params.append(
        'SortNames',
        query.sortField === 'paymentTypeString'
          ? 'PaymentType'
          : query.sortField[0].toUpperCase() + query.sortField.substring(1)
      );
      params = params.append('SortType', query.sortType ? query.sortType : 0);
    }

    return this.httpClient.get<VendorList>(this.Endpoint, { params });
  }

  checkExits = (bodyCheck: any) => {
    let body = {
      ...bodyCheck,
      clientId: this.clientId,
    };

    return this.httpClient.post(`${this.Endpoint}/Exists`, body);
  };

  createVendor(body: NewVendor) {
    return this.httpClient.post(this.Endpoint, body);
  }

  updateVendor(id: string, body: NewVendor) {
    return this.httpClient.put(`${this.Endpoint}/${id}`, body);
  }

  removeVendor(id: string) {
    return this.httpClient.delete(`${this.Endpoint}/${id}`);
  }

  getBankInfo(routingNumber: number) {
    return this.httpClient.post(
      `${environment.apiUrl}/Banks/Detail/${routingNumber}`,
      { boardInformationId: null, boardBankId: null }
    );
  }

  checkApiValidator(editValue?: string): AsyncValidatorFn {
    // Need obs to call api for check
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (editValue && editValue.trim() === control.value.trim()) {
        return of(null);
      }

      if (control.value.trim().length < 9) {
        this.bankInfo = null;
        return of({ inValidAsync: true });
      }

      return this.getBankInfo(control.value?.trim()).pipe(
        map((response: any) => {
          if (!response || response.isDisabled) {
            this.bankInfo = null;
            return { inValidAsync: true };
          }

          this.bankInfo = response;
          return null;
        }),
        catchError((err) => {
          this.bankInfo = null;
          return of({ inValidAsync: true });
        })
      );
    };
  }

  getVendorByDeductionType(deductionType: number, includeId: string) {
    let params = new HttpParams();
    if (includeId) {
      params = params.append('includeId', includeId);
    }
    return this.httpClient.get<any>(`${this.Endpoint}/${deductionType}`, {
      params,
    });
  }
}
