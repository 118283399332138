<div class="retirement-benefit-calculation-list h-full" id="retirement-benefit-calculation-list-page">
  <ptg-breadcrumb
    [listBreadcrumbs]="listBreadcrumbs"
    [functionButtons]="functionButtons"
    (emitFunction)="emitFunction($event)"
  ></ptg-breadcrumb>

  <div class="flex flex-col p6 content-container">
    <div *ngIf="bannerType" class="p-6">
      <ptg-banner [(bannerType)]="bannerType" [message]="message"></ptg-banner>
    </div>
    <div class="grid-wrapper h-full">
      <ptg-grid
        [data]="calculations"
        [columns]="columns"
        [isLoading]="isLoading"
        [totalRecords]="totalRecords"
        [pageSize]="pageSize"
        [pageNumber]="pageNumber"
        [fitToParent]="true"
        [errorMessage]="errorMsg"
        (pageChange)="onChangePage($event)"
        (sortChange)="onChangeSort($event)"
        notFoundMessage="No Calculation to Display"
      >
      <ng-template cellContent columnName="fileDocument" let-row>
        <div class="flex file-document-container">
          <mat-icon
            class="material-icons-outlined mr-3 download-icon"
            (click)="downloadDocumentFile(row?.fileDocument)"
          >file_download</mat-icon>
          <span class="truncate">{{ row?.fileDocument?.documentName }}</span>

          </div>
        </ng-template>
        <ng-template cellContent columnName="action" let-row>
          <ptg-button
            buttonName="Edit"
            classInput="edit-button"
            (clickButton)="onClickEditCalculation(row)"
          >
            <mat-icon>edit</mat-icon>
          </ptg-button>
          <ptg-button
            buttonName="Remove"
            classInput="remove-button"
            (clickButton)="onClickRemoveCalculation(row)"
          >
            <mat-icon>delete_forever</mat-icon>
          </ptg-button>
        </ng-template>
      </ptg-grid>
    </div>
  </div>
</div>
