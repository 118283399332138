<div class="card-list-container">
    <ptg-breadcrumb
      [listBreadcrumbs]="listBreadcrumbs"
      (onAdd)="onCreateNewCart()"
      buttonAddLabel="New Card"
    ></ptg-breadcrumb>
    <div class="drop-banner" *ngIf="bannerType">
      <ptg-banner
      [(bannerType)]="bannerType"
      [message]="message"
      ></ptg-banner>
    </div>
  
    <ptg-grid
      [data]="dataTable"
      [columns]="columns"
      [isLoading]="isLoading"
      [fitToParent]="true"
      [paginable]="false"
      (sortChange)="onChangeSort($event)"
      notFoundMessage="No Card to Display"
    >
      <ng-template cellContent columnName="includesList" let-row>
        <mat-icon class="icon-table" *ngIf="row.includesList">
          check_circle
        </mat-icon>
      </ng-template>
      <ng-template cellContent columnName="action" let-row>
        <ptg-button
          buttonName="Edit"
          classInput="edit-button"
          (clickButton)="onUpdateCart(row)"
        >
          <mat-icon>edit</mat-icon>
        </ptg-button>
        <ptg-button 
          buttonName="Remove" 
          classInput="remove-button" 
          [isDisabled]="row?.isDisabled" 
          (clickButton)="onClickRemoveItem(row)">
            <mat-icon>delete_forever</mat-icon>
          </ptg-button>
      </ng-template>
    </ptg-grid>
  </div>
  