import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { BaseComponent } from '@ptg-shared/components';
import { STATE } from '@ptg-shared/constance';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { deepClone, showBanner } from '@ptg-shared/utils/common.util';
import { Subject } from 'rxjs';
import { filter, startWith, switchMap, take, tap } from 'rxjs/operators';
import { Option } from 'src/app/shared/controls/select/select.component';
import { EditParticipantAccidentStatusRequest } from '../../services/models';
import { editParticipantAccidentStatusAction } from '../../store/actions/accident-participants.action';
import { AccidentParticipantsState } from '../../store/reducers/accident-participants.reducer';

@Component({
  selector: 'ptg-edit-participant-accident-status',
  templateUrl: './edit-participant-accident-status.component.html',
  styleUrls: ['./edit-participant-accident-status.component.scss'],
})
export class EditParticipantAccidentStatusComponent extends BaseComponent {
  editForm!: FormGroup;
  formSubmit$ = new Subject<boolean>();
  listChangeStatus: Option[] = []
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditParticipantAccidentStatusComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      memberId: string,
      accidentId: string,
      listChangeStatus: Option[],
      can: Option[],
      canEditStatus: boolean,
    },
    private fb: FormBuilder,
    private store: Store<AccidentParticipantsState>,
    private switchConfirmPopupService: SwitchConfirmPopupService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.listChangeStatus = this.data.listChangeStatus;
    this.initFormGroup();
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter((status) => status === AbstractControlStatus.VALID)
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  onCancel() {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }

  initFormGroup() {
    this.editForm = this.fb.group({
      status: this.fb.control(''),
      note: this.fb.control('')
    });
  }

  get status(): FormControl {
    return this.editForm.get('status') as FormControl;
  }
  get note(): FormControl {
    return this.editForm.get('note') as FormControl;
  }

  onSubmit() {
    if (!this.data?.canEditStatus) {
      showBanner.call(this, STATE.FAIL, '', '', {
        customMessage: 'Accident has been added to a Disability Benefit record.',
      });
      return;
    }
    const editParticipantAccidentStatusRequest = deepClone(this.editForm.value as EditParticipantAccidentStatusRequest);

    this.store.dispatch(
      editParticipantAccidentStatusAction({
        memberId: this.data.memberId,
        accidentId: this.data.accidentId,
        editParticipantAccidentStatusRequest: editParticipantAccidentStatusRequest,
      })
    );

    this.dialogRef.close();
  }
}
