<div class="dialog-container">
  <div class="header-title">Add New Header</div>
  <form class="edit-form" [formGroup]="editForm">
    <div class="input-container">
      <ptg-textbox
        [controlField]="editForm.get('headerName')"
        placeholder="Header Name"
        [hasLabel]="true"
        [maxLength]="250"
        errorAsync="Header Name already exists."
      ></ptg-textbox>
      <ptg-select
        [controlField]="editForm.get('linkedEntity')"
        [listData]="listEntity"
        [isOptionObj]="true"
        placeholder="Entity"
      ></ptg-select>
    </div>

    <div class="row-button">
      <button
        mat-raised-button
        type="button"
        class="primary"
        (click)="formSubmit$.next(true)"
      >
        Create & Continue
      </button>
      <button
        mat-raised-button
        type="button"
        class="secondary"
        (click)="formSubmit$.next(false)"
      >
        Create & Add Another
      </button>
      <button
        mat-raised-button
        type="button"
        class="cancel-button"
        (click)="onCancel()"
      >
        Cancel
      </button>
    </div>
  </form>
</div>
