import { Component, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import * as fromReducer from '@ptg-reducers';

import { EntityPropertyType } from '@ptg-entity-management/types/enums';
import { NON_VALUE_PROPERTY_DISPLAY_TYPE, PROPERTY_DISPLAY_PREFERENCE_TYPE } from '@ptg-member/types/enums';
import { BaseComponent } from '@ptg-shared/components';
import { getStandAlonePropertyOnlyCardDataAction } from '@ptg-member/store/actions/member-detail.actions';
import { getStandAlonePropertyOnlyCardDataSelector } from '@ptg-member/store/reducers';
import { CardComponent, CardProperty, EntityViewItem, GetStandAlonePropertyOnlyCardDataRequest } from '@ptg-entity-management/services/models';

@Component({
  selector: 'ptg-entity-summary-card-table',
  templateUrl: './entity-summary-card-table.component.html',
  styleUrls: ['./entity-summary-card-table.component.scss'],
})
export class EntitySummaryCardTableComponent extends BaseComponent {
  readonly PROPERTY_DISPLAY_PREFERENCE_TYPE = PROPERTY_DISPLAY_PREFERENCE_TYPE;
  readonly NON_VALUE_PROPERTY_DISPLAY_TYPE = NON_VALUE_PROPERTY_DISPLAY_TYPE;
  readonly EntityPropertyType = EntityPropertyType;

  @Input() component!: CardComponent;
  @Input() card!: EntityViewItem;
  @Input() cardData: any;
  @Input() targetId!: string;
  @Input() entityId!: string;
  
  propertiesConfig?: CardProperty[];
  datalessConfig: any;

  constructor(private store: Store<fromReducer.State>) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.datalessConfig = this.card?.datalessConfig;
    this.propertiesConfig = this.component.cardProperties;

    this.store
      .pipe(
        select(getStandAlonePropertyOnlyCardDataSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data) => {
        if (data?.success && !data?.isLoading && data?.payload) {
          this.cardData = data?.payload;
        }
      });

    // Get Data Table Column
    const request: GetStandAlonePropertyOnlyCardDataRequest = {
      cardId: this.card?.cardId,
      targetId: this.targetId,
      entityId: this.entityId
    };

    this.store.dispatch(getStandAlonePropertyOnlyCardDataAction({ request }));
  }

  onClickIcon(property: CardProperty) {
    if (property.isMasked) {
      property.value = property.valueMask.originalValue;
      property.isMasked = false;
    } else {
      property.value = property.valueMask.maskedValue;
      property.isMasked = true;
    }
  }
}
