import { createReducer, on } from '@ngrx/store';
import {
  addTagAction,
  addTagFailureAction,
  addTagSuccessAction,
  clearRemoveTagStateAction,
  clearTagDetailStateAction,
  editTagAction,
  editTagFailureAction,
  editTagSuccessAction,
  getTagListAction,
  getTagListActionFailure,
  getTagListActionSuccess,
  removeTagAction,
  removeTagFailureAction,
  removeTagSuccessAction,
} from '../actions';
import { ACTION, ActionState, STATE } from '@ptg-shared/constance';
import { BaseActionState } from '@ptg-shared/types/models';
import { Tag } from '../../services/models/tag.models';

export interface TagDetailState extends ActionState {
  error?: any;
}

export interface RemoveTagState extends ActionState {
  error?: any;
  isLoading: boolean;
}

export interface TagsState {
  tagList?: BaseActionState<Tag[]>,
  tagDetailState?: TagDetailState
  removeTagState?: RemoveTagState;
  isLoading?: boolean,
}

const initialState: TagsState = {
  removeTagState: {
    isLoading: false,
    action: ACTION.REMOVE,
    state: '',
  },
};

export const tagsReducer = createReducer(
  initialState,
  on(clearTagDetailStateAction, (state) => ({
    ...state,
    tagDetailState: undefined,
  })),
  on(addTagAction, (state) => ({
    ...state,
    isLoading: true,
    tagDetailState: undefined,
  })),
  on(addTagSuccessAction, (state, {}) => ({
    ...state,
    isLoading: false,
    tagDetailState: {
      action: ACTION.ADD,
      state: STATE.SUCCESS,
    },
  })),
  on(addTagFailureAction, (state, { error }) => ({
    ...state,
    isLoading: false,
    tagDetailState: {
      action: ACTION.ADD,
      state: STATE.FAIL,
      error,
    },
  })),
  on(editTagAction, (state) => ({
    ...state,
    isLoading: true,
    tagDetailState: undefined,
  })),
  on(editTagSuccessAction, (state, {}) => ({
    ...state,
    isLoading: false,
    tagDetailState: {
      action: ACTION.EDIT,
      state: STATE.SUCCESS,
    },
  })),
  on(editTagFailureAction, (state, { error }) => ({
    ...state,
    isLoading: false,
    tagDetailState: {
      action: ACTION.EDIT,
      state: STATE.FAIL,
      error,
    },
  })),
  on(getTagListAction, (state) => ({
    ...state,
    isLoading: true,
    tagList: {
      isLoading: true,
    },
  })),
  on(getTagListActionSuccess, (state, { response }) => ({
    ...state,
    isLoading: false,
    tagList: {
      isLoading: false,
      success: true,
      payload: response.documentTags,
      total: response.total,
    },
  })),
  on(getTagListActionFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    tagList: {
      isLoading: false,
      success: false,
      error,
    },
  })),
  on(removeTagAction, (state) => ({
    ...state,
    isLoading: false,
    removeTagState: {
      isLoading: true,
      action: ACTION.REMOVE,
      state: '',
    },
  })),
  on(removeTagSuccessAction, (state) => ({
    ...state,
    isLoading: false,
    removeTagState: {
      isLoading: false,
      action: ACTION.REMOVE,
      state: STATE.SUCCESS,
    },
  })),
  on(removeTagFailureAction, (state, { error }) => ({
    ...state,
    isLoading: false,
    removeTagState: {
      isLoading: false,
      action: ACTION.REMOVE,
      state: STATE.FAIL,
      error,
    },
  })),
  on(clearRemoveTagStateAction, (state) => ({
    ...state,
    removeTagState: {
      isLoading: true,
      action: ACTION.REMOVE,
      state: '',
    },
  })),
);
