<div *ngIf="addressHistoriesData.length > 0 || !configCard?.datalessConfig?.isHideEmptyCard" class="address-history-container" id="address-history-page">
  <div class="flex-grow overflow-hidden">
    <ptg-list-display-box
      [title]="configCard.cardName"
      pageName="Data"
      [isLoading]="isLoading"
      [dataTable]="addressHistoriesData"
      [columns]="addressHistoriesData.length > 0 || configCard?.datalessConfig?.listDisplayPreferenceType !== LIST_DISPLAY_PREFERENCE_TYPE.Hide ? columns : []"
      [sortInfo]="sortInfo"
      [lengthPg]="lengthPg"
      [pageSize]="pageSize"
      [pageNumber]="pageNumber"
      [isAddress]="true"
      [currentRowIndex]="currentRowIndex"
      [paginable]="addressHistoriesData.length > 0 || configCard?.datalessConfig?.listDisplayPreferenceType !== LIST_DISPLAY_PREFERENCE_TYPE.Hide"
      [isHideNotFoundMessage]="configCard?.datalessConfig?.listDisplayPreferenceType === LIST_DISPLAY_PREFERENCE_TYPE.Hide || 
        configCard?.datalessConfig?.listDisplayPreferenceType === LIST_DISPLAY_PREFERENCE_TYPE.DisplayColumnHeaders"
      (changePage)="changePage($event)"
      (sortChange)="sortChange($event)"
      (selectRow)="selectRow($event)"
    >
    <ng-container>
      <div>
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="Icon-button with a menu"
            class="group-button ignore-row"
          >
            <mat-icon class="ignore-row">more_horiz</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="onAddMemberAddress()">
              <mat-icon class="member-section-action-icon">add</mat-icon>
              <span class="label-icon">Add New</span>
            </button>
            <button
              mat-menu-item
              *ngIf="addressHistoriesData?.length"
              (click)="selectRow()"
            >
              <mat-icon class="member-section-action-icon"> list_alt </mat-icon>
              <span class="label-icon">Manage</span>
            </button>
          </mat-menu>
      </div>
    </ng-container>
    </ptg-list-display-box>
  </div>
</div>
  