<div class="content">
  <div class="content-left">
    <p class="label-small">{{labelLeft}}</p>
    <div class="grid">
      <table id="list-left" mat-table [dataSource]="dataSourceLeft" class="mat-elevation-z8 custom-table" [cdkDropListData]="dataSourceLeft"
      cdkDropList [cdkDropListConnectedTo]="['list-right']" (cdkDropListDropped)="drop($event)"
      >
        <ng-container *ngIf="dataSourceLeft.filteredData.length > 0">
          <ng-container *ngFor="let item of styleDisplayedColumns; index as i;">
            <ng-container [ngSwitch]="item">

              <ng-container *ngSwitchCase="STYLE_COLUMN.BUTTON_LIST" [matColumnDef]="displayedColumns[i]">
                <td mat-cell *matCellDef="let element">
                  <ptg-button (mouseDownButton)="freeDragArea()"
                    *ngIf="!element.noDrag" classInput="list-button">
                    <mat-icon class="ignore-row" aria-hidden="false" aria-label="drag-handle" svgIcon="drag-handle"></mat-icon>
                  </ptg-button>
                </td>
              </ng-container>

              <ng-container *ngSwitchCase="STYLE_COLUMN.TEXT" [matColumnDef]="displayedColumns[i]">
                <td mat-cell *matCellDef="let element">
                  <ng-container *ngIf="element[displayedColumns[i]]">
                    <div class="property-name">{{element[displayedColumns[i]][1]}}</div>
                    <div class="section-name">{{element[displayedColumns[i]][0]}}</div>
                  </ng-container>
                </td>
              </ng-container>

            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="dataSourceLeft.filteredData.length === 0">
        </ng-container>         
        <tr mat-row *matRowDef="let row; let idx = index; columns: displayedColumns;" cdkDrag 
          [class.grab-row]="!row.noDrag" [cdkDragDisabled]="isDragDisabled" [class.no-drag-row]="row.noDrag"
        ></tr>
      </table>
    </div>
  </div>
  <div class="content-right">
    <p class="label-small">{{labelRight}}</p>
    <div class="grid">
      <table id="list-right" mat-table [dataSource]="dataSourceRight" class="mat-elevation-z8 custom-table" [cdkDropListData]="dataSourceRight"
      cdkDropList [cdkDropListConnectedTo]="['list-left']" (cdkDropListDropped)="drop($event)"
      >
        <ng-container *ngFor="let item of styleDisplayedColumns; index as i;">
          <ng-container [ngSwitch]="item">
            <ng-container *ngSwitchCase="STYLE_COLUMN.BUTTON_LIST" [matColumnDef]="displayedColumns[i]">
              <td mat-cell *matCellDef="let element">
                <ptg-button *ngIf="!element.noDrag" classInput="list-button" (mouseDownButton)="freeDragArea()">
                  <mat-icon class="ignore-row" aria-hidden="false" aria-label="drag-handle" svgIcon="drag-handle"></mat-icon>
                </ptg-button>
              </td>
            </ng-container>

            <ng-container *ngSwitchCase="STYLE_COLUMN.TEXT" [matColumnDef]="displayedColumns[i]">
              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element[displayedColumns[i]]">
                  <div class="property-name">{{element[displayedColumns[i]][1]}}</div>
                  <div class="section-name">{{element[displayedColumns[i]][0]}}</div>
                </ng-container>
              </td>
            </ng-container>

            
          </ng-container>
        </ng-container>
        <tr mat-row *matRowDef="let row; let idx = index; columns: displayedColumns;" cdkDrag
          [class.grab-row]="!row.noDrag" [cdkDragDisabled]="isDragDisabled" [class.no-drag-row]="row.noDrag"
        ></tr>
      </table>
    </div>
  </div>
</div>
