import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { takeUntil } from 'rxjs/operators';

import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ACTION_COLUMN, Align, Column } from '@ptg-shared/controls/grid';
import { BaseComponent } from '@ptg-shared/components';
import {
  Breadcrumb,
  FunctionButtonConfig,
  FunctionButtonData,
} from '@ptg-shared/types/models/breadcrumb.model';
import { LayoutActions } from '@ptg-shared/layout/actions';
import * as fromReducer from '@ptg-reducers';
import * as fromMember from '../../store/reducers';
import { AddNewMenuComponent } from '@ptg-member/components/add-new-menu/add-new-menu.component';
import { ParticipantNavigationConfigurationAction } from '../../store/actions';
import {
  MenuParametersQuery,
  NavigationMenuItem,
  ProfileResponse,
} from '@ptg-member/types/models/participant-navigation-configuration.model';
import { ParticipantNavigationConfigurationService } from '@ptg-member/services/participant-navigation-configuration.service';
import { showBanner } from '@ptg-shared/utils/common.util';
import { ACTION, STATE } from '@ptg-shared/constance';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
@Component({
  selector: 'ptg-participant-navigation-configuration',
  templateUrl: './participant-navigation-configuration.component.html',
  styleUrls: ['./participant-navigation-configuration.component.scss'],
})
export class ParticipantNavigationConfigurationComponent extends BaseComponent {
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Participant List',
      url: '/member',
    },
    {
      name: 'Navigation Configuration',
      url: '',
    },
  ];
  functionButtons: FunctionButtonConfig[] = [
    {
      buttonName: 'New Menu',
      icon: 'add',
      classInput: 'add-button',
    },
  ];
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  isLoading: boolean = true;
  errorMsg: string = '';
  columns: Column[] = [
    {
      name: 'name',
      header: {
        title: 'Menu Name',
      },
      sortable: false,
      truncate: true,
    },
    {
      name: 'entityName',
      header: {
        title: 'Entity',
      },
      truncate: true,
    },
    {
      name: 'totalItem',
      header: {
        title: 'Total Items',
      },
      align: Align.Right,
    },
    {
      name: 'color',
      header: {
        title: 'Menu Color',
      },
      align: Align.Center,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      width: '230px',
    },
  ];
  memberNavigationsData: NavigationMenuItem[] = [];
  currentPageName: string = 'Tier';
  selectedNavigation: NavigationMenuItem | undefined;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  currentRowIndex: number = 0;
  totalRecord: number = 0;
  currentFund: any;
  pageName = 'ptg-participant-navigation-configuration';
  constructor(
    public route: ActivatedRoute,
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
    public dialog: MatDialog,
    private router: Router,
    private participantNavigationConfigurationService: ParticipantNavigationConfigurationService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store.dispatch(LayoutActions.hiddenSideMenu());
    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.pageSize = el.defaultPageSize ?? 50;
        this.currentFund = el;
      });
    this.pageSize =
      Number(
        sessionStorage.getItem(
          this.currentFund.key + '-' + this.pageName + '-pageSize'
        )
      ) === 0
        ? this.pageSize
        : Number(
            sessionStorage.getItem(
              this.currentFund.key + '-' + this.pageName + '-pageSize'
            )
          );
    this.getData();
    this.memberStore
      .pipe(
        select(fromMember.selectParticipantNavigationConfiguration),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        this.isLoading = false;
        this.memberNavigationsData =
          state?.navigationConfigurationResponse?.navigationMenus ?? [];
        this.totalRecord =  state?.navigationConfigurationResponse?.total ?? 0;
        showBanner.call(
          this,
          state.removeMenuState || '',
          'Menu',
          ACTION.REMOVE
        );
        if (state.removeMenuState == STATE.SUCCESS) {
          this.getData();
        }
        if (state.removeMenuState) {
          this.memberStore.dispatch(
            ParticipantNavigationConfigurationAction.clearNavigationConfigurationState()
          );
        }
      });
  }

  getData() {
    const query: MenuParametersQuery = {
      pageIndex: this.pageNumber,
      pageSize: this.pageSize,
    };
    this.memberStore.dispatch(
      ParticipantNavigationConfigurationAction.getNavigationConfigurationRequest({ query })
    );
  }

  emitBreadcrumdFunction(event: FunctionButtonData) {
    if (event.buttonName === 'New Menu') {
      this.addMenu();
      return;
    }
  }

  removeRecord(memberNavigation: NavigationMenuItem) {
    this.participantNavigationConfigurationService
      .getNavigationConfigurationProfile(memberNavigation.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: ProfileResponse) => {
        if (res?.listEntityProfile.length) {
          this.dialog.open(ConfirmPopupComponent, {
            panelClass: 'confirm-popup',
            data: {
              text: `This Menu cannot be removed since it is being used in the following Profile: ${res.listEntityProfile
                .map((item: any) => item.name)
                .join(', ')}`,
              type: ConfirmType.Warning,
              title: 'Attention',
              cancelButtonTitle: 'Close',
              hideConfirmButton: true,
            },
          });
        } else {
          const dialogRef = this.dialog.open(ConfirmPopupComponent, {
            panelClass: 'confirm-popup',
            autoFocus: false,
            data: {
              title: 'Remove Menu',
              text: `Are you sure you want to remove this Menu?`,
              type: ConfirmType.Delete,
            },
          });

          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              const query = {
                id: memberNavigation.id,
              };
              this.memberStore.dispatch(
                ParticipantNavigationConfigurationAction.removeNavigationConfiguration(
                  { query }
                )
              );
            }
          });
        }
      });
  }

  editRecord(memberNavigation: NavigationMenuItem) {
    this.router.navigateByUrl(
      `/member/participant-navigation-configuration/${memberNavigation.id}`
    );
  }

  addMenu() {
    this.dialog.open(AddNewMenuComponent, {
      panelClass: ['edit-popup', 'add-menu-popup'],
      disableClose: true,
      autoFocus: false,
      height: 'auto',
      width: '848px',
      data : {
        query: {
          pageIndex: this.pageNumber,
          pageSize: this.pageSize,
        }
      }
    });
  }

  onChangePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    sessionStorage.setItem(this.currentFund.key + '-' + this.pageName + '-pageSize', this.pageSize.toString());
    sessionStorage.setItem(this.currentFund.key+ '-' + this.pageName + '-pageNumber', this.pageNumber.toString()); 
    this.getData();
  }
}
