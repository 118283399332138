import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import * as PaymentListConfigurationActions from '../actions/payment-list-configuration.actions';
import { PropertyDisplayConfiguration } from '../../types/models/property-display-configuration.model';

export const paymentListConfigurationFeatureKey = 'paymentListConfiguration';

export interface State {
  setPaymentListConfiguration?: BaseActionState;
  getPaymentListConfiguration?: BaseActionState<PropertyDisplayConfiguration[]>;
}

const initialState: State = {};

export const reducer = createReducer(
  initialState,

  on(PaymentListConfigurationActions.setPaymentListConfigurations, (state) => ({
    ...state,
    setPaymentListConfiguration: {
      isLoading: true,
    },
  })),
  on(
    PaymentListConfigurationActions.setPaymentListConfigurationsSuccess,
    (state) => ({
      ...state,
      setPaymentListConfiguration: {
        isLoading: false,
        success: true,
      },
    })
  ),
  on(
    PaymentListConfigurationActions.setPaymentListConfigurationsFailure,
    (state, { error }) => ({
      ...state,
      setPaymentListConfiguration: {
        isLoading: false,
        success: false,
        error: error,
      },
    })
  ),
  on(
    PaymentListConfigurationActions.getPaymentListConfigurations,
    (state, {}) => ({
      ...state,
      getPaymentListConfiguration: {
        isLoading: true,
      },
    })
  ),
  on(
    PaymentListConfigurationActions.getPaymentListConfigurationsSuccess,
    (state: State, { response }) => ({
      ...state,
      getPaymentListConfiguration: {
        isLoading: false,
        success: true,
        payload: response.paymentListConfigs || [],
      },
    })
  ),
  on(
    PaymentListConfigurationActions.getPaymentListConfigurationsFailure,
    (state: State, { error }) => ({
      ...state,
      getPaymentListConfiguration: {
        isLoading: false,
        success: false,
        error: error,
      },
    })
  ),
  on(
    PaymentListConfigurationActions.clearPaymentListConfigurationState,
    (state) => ({
      ...state,
      setPaymentListConfiguration: {
        isLoading: false,
      },
    })
  )
);
