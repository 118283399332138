import { createSelector } from '@ngrx/store';

import { selectCalculationModuleState } from './selector';

export const removeCalculationDocumentDetailSelector = createSelector(
  selectCalculationModuleState,
  (state) => state?.removeRemoveCalculationDocumentDetail
);

export const removeRemoveCalculationDocumentDetailSelector = createSelector(
  removeCalculationDocumentDetailSelector,
  (state) => state?.removeRemoveCalculationDocumentDetail
);

export const checkExistLoddDocumentCanRemoveSelector = createSelector(
  removeCalculationDocumentDetailSelector,
  (state) => state?.checkExistLoddDocumentCanRemove
);

export const checkExistLoddDocumentCanEditSelector = createSelector(
  removeCalculationDocumentDetailSelector,
  (state) => state?.checkExistLoddDocumentCanEdit
);
