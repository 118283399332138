import {
  AfterViewChecked,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { DateTime } from 'luxon';

import { Auth0Service } from '@ptg-shared/auth/services/auth0.service';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { PERMISSION_KEY } from '@ptg-shared/constance/permission.const';
import {
  ADMIN_FUND,
  ADMIN_SYSTEM,
  DISCARD_CONFIRM_MESSAGE,
} from '@ptg-shared/constance/value.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { CheckPermissionService } from '@ptg-shared/services/check-permission.service';
import { deepClone } from '@ptg-shared/utils/common.util';
import { SearchResult } from '@ptg-shared/layout/models/search.models';
import { HeaderActions } from '@ptg-shared/layout/actions';
import {
  getValueWithoutFormat,
  isEmpty,
  stringToBoolean,
} from '@ptg-shared/utils/string.util';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import * as fromLayout from '@ptg-shared/layout/reducers';
import { personSearch } from '@ptg-shared/constance/listIcons.const';
import {
  DateValidationValue,
  FieldData,
  FormControlDetail,
  OptionValue,
  PropValidation,
} from '@ptg-shared/types/models';
import {
  LookupTableType,
  ValidationType,
  ValidationOperator,
} from '@ptg-shared/types/enums';
import * as fromReducer from '@ptg-reducers';

import {
  MemberBenefitListActions,
  MemberDetailActions,
} from '../../store/actions';
import {
  MetadataSection,
  PropertyDisplayConfiguration,
} from '../../types/models';
import * as fromMember from '../../store/reducers';
import * as ParticipantSearchConfigurationActions from '../../store/actions/participant-search-configuration.actions';
import { PropertyTypeLabel } from '../../constance/metadata.const';
import { PropertyType } from '../../constance/metadataPropertyType.const';
import { MemberBenefitService } from '../../services/member-benefit.service';
import { MemberDetailService } from '../../services/member-detail.service';
import { SCREEN_ID } from '../../constance/lookupTable.const';

@Component({
  selector: 'ptg-establish-benefit',
  templateUrl: './establish-benefit.component.html',
  styleUrls: ['./establish-benefit.component.scss'],
})
export class EstablishBenefitComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  readonly PropertyType = PropertyType;
  @ViewChild('participantSearchTrigger')
  participantSearchTrigger!: MatAutocompleteTrigger;
  formGroup!: FormGroup;
  listBenefitType: any[] = [];
  optionsBenefitType: any = [];
  disableParticipant: boolean = false;
  isLoading: boolean = true;
  search$ = new BehaviorSubject('');
  ADMIN_FUND = ADMIN_FUND;
  ADMIN_SYSTEM = ADMIN_SYSTEM;
  PERMISSION_KEY = PERMISSION_KEY;
  searchResultColumns: string[] = [];
  searchResultColumnTypes: string[] = [];
  searchResultList: Record<string, any>[] = [];
  unsubscribe$ = new Subject<void>();
  isError: boolean = false;
  activeFund: any;
  memberId: string;
  metaData: MetadataSection[] = [];
  itemKey!: string;
  currentSection!: any;
  currentMemberDetailUnmask: any;
  lstFormControl: FormControlDetail[] = [];
  searchMemberType: number = 1;
  hasConfig: boolean = false;
  benefitTypeId!: string;
  lstInputAddress: FieldData[] = [
    new FieldData('street1', 'Street1', 'Street1', 'text', true, '46', 'w380'),
    new FieldData(
      'street2',
      'Street2',
      'Street 2 (Apartment No./Suite No.)',
      'text',
      false,
      '46',
      'w380'
    ),
    new FieldData('city', 'City', 'City', 'text', true, 'none', 'w182'),
    new FieldData(
      'state',
      'State',
      'State/Province/Territory',
      'select',
      true,
      'none',
      'w182'
    ),
    new FieldData(
      'zipCode',
      'ZipCode',
      'Zip Code/Postal Code',
      'text',
      true,
      '10',
      'w182'
    ),
    new FieldData(
      'country',
      'Country',
      'Country',
      'select',
      false,
      'none',
      'w182'
    ),
  ];
  lstInputPersonName: FieldData[] = [
    new FieldData(
      'prefix',
      'Prefix',
      'Prefix',
      'select',
      false,
      'none',
      'w167'
    ),
    new FieldData(
      'first',
      'First',
      'First Name',
      'text',
      true,
      'none',
      'w152 first-name'
    ),
    new FieldData(
      'middle',
      'Middle',
      'Middle Name',
      'text',
      false,
      'none',
      'w152'
    ),
    new FieldData(
      'last',
      'Last',
      'Last Name',
      'text',
      true,
      'none',
      'w152 last-name'
    ),
    new FieldData('suffix', 'Suffix', 'Suffix', 'text', false, 'none', 'w89'),
  ];
  selectedBenefitType: any;
  selectedBenefitTypeId!: string;

  isSelectMember: boolean = false;

  datePropValidation: PropValidation[] = [];
  propValidation: string[] = [];
  dateValidationValue: DateValidationValue[] = [];

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<EstablishBenefitComponent>,
    private memberStore: Store<fromMember.MemberState>,
    private storeFund: Store<fromReducer.State>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public authService: Auth0Service,
    public checkPermissionService: CheckPermissionService,
    public memberBenefitService: MemberBenefitService,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private memberDetailService: MemberDetailService,
    private store: Store<fromReducer.State>,
    private searchStore: Store<fromLayout.State>
  ) {
    iconRegistry.addSvgIconLiteral(
      'personSearch',
      sanitizer.bypassSecurityTrustHtml(personSearch)
    );
    this.memberId = this.data.memberId;
  }

  ngOnInit(): void {
    this.storeFund
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => (this.activeFund = el));
    this.onInitForm();
    this.checkParticipantSearchConfig();
    this.getListBenefitType();

    this.searchStore
      .pipe(select(fromLayout.selectSearchState), takeUntil(this.unsubscribe$))
      .subscribe((searchState) => {
        let searchData: SearchResult = deepClone(
          searchState.searchResult || {}
        );
        this.searchResultColumns = (searchData.columns || []).map(
          (item) => item.columnName
        );
        this.searchResultColumnTypes = (searchData.columns || []).map(
          (item) => item.type
        );
        this.searchResultList = (searchData.members || []).map(
          (member, index) => {
            return {
              ...this.getColumnValue(searchData, index),
              _memberId: member._memberId,
              _participantName:
                `${member['demographics_name_First_0']} ${member['demographics_name_Last_0']}`.trim(),
            };
          }
        );
        this.isLoading = searchState.isLoading;
        this.isError = searchState.isError;
      });

    this.search$.pipe(debounceTime(500)).subscribe((el) => {
      this.searchStore.dispatch(HeaderActions.clearSearchList());
      if (el && el.length > 1) {
        this.searchStore.dispatch(
          HeaderActions.getSearchRequest({
            searchValue: el,
            idClient: this.activeFund?.id,
            searchMemberType: this.searchMemberType,
            benefitTypeId: this.formGroup.get('benefitType')?.value,
            screenId: '',
          })
        );
      }
    });

    this.store
      .pipe(select(fromMember.selectMetaData), takeUntil(this.unsubscribe$))
      .subscribe((el) => {
        this.metaData = el || [];

        if (
          this.metaData &&
          this.metaData.length > 0 &&
          this.listBenefitType.length === 1
        ) {
          this.formGroup
            .get('benefitType')
            ?.setValue(this.listBenefitType[0].id);
          this.onChangeBenefitType();
        }
      });

    this.memberStore
      .pipe(
        select(fromMember.selectMemberDataUnMasked),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((memberData) => {
        this.currentMemberDetailUnmask = deepClone(memberData);
        this.setLstFormControl();
        this.setFormGroupCs();
        this.setValue();
        this.applyValidation();
      });
  }

  setValue() {
    const sectionData = this.currentMemberDetailUnmask[this.itemKey];
    if (!sectionData) {
      return;
    }

    this.lstFormControl.forEach((element) => {
      const value = sectionData[element.name];
      if (isEmpty(value)) {
        return;
      }
      if (
        element.type == PropertyType.TYPE_ADDRESS ||
        element.type == PropertyType.TYPE_PERSON_NAME
      ) {
        element.lstChildFormControl?.forEach((item: any) => {
          item.formControl?.setValue(value[item.key]);
        });
      } else if (element.type == PropertyType.TYPE_BOOLEAN) {
        element.formControl?.setValue(value === 'true' ? true : false);
      } else {
        if (element.fractionalLength || element.fractionalLength === 0) {
          const valueConvert = (+value).toFixed(element.fractionalLength);
          element.formControl?.setValue(valueConvert);
        } else {
          element.formControl?.setValue(value);
        }
      }
    });
  }

  ngAfterViewChecked() {
    this.participantSearchTrigger?.updatePosition();
  }

  onInitForm() {
    this.formGroup = new FormGroup({
      benefitType: new FormControl(this.data.members?.benefitType, {
        validators: Validators.required,
      }),
      participant: new FormControl(null, { validators: Validators.required }),
      participantId: new FormControl(null),
    });
  }

  getListBenefitType() {
    this.memberBenefitService.getBenefitType(this.data.memberId).subscribe(
      (res: any) => {
        this.listBenefitType = res.benefitTypes;
        this.optionsBenefitType = res.benefitTypes.map((item: any) => {
          return {
            value: item.id,
            displayValue: `${item.code} - ${item.name}`,
          };
        });

        if (this.listBenefitType && this.listBenefitType.length > 0) {
          const query = {
            screenId: SCREEN_ID.ParticipantBenefitEligible,
          };
          this.store.dispatch(MemberDetailActions.getMetadata({ query }));
        }
      },
      (error) => {
        this.listBenefitType = [];
      }
    );
  }

  onChangeBenefitType() {
    if (
      this.selectedBenefitTypeId === this.formGroup.get('benefitType')?.value
    ) {
      return;
    }

    this.lstFormControl = [];
    this.selectedBenefitTypeId = this.formGroup.get('benefitType')?.value;
    this.selectedBenefitType = this.listBenefitType.find(
      (item: any) => item.id === this.formGroup.get('benefitType')?.value
    );

    if (!!this.selectedBenefitType?.parentTypes && !this.hasConfig) {
      return;
    }

    this.changeValidationParticipant();

    const metaDataSection = this.metaData.find(
      (item) => item.key === this.selectedBenefitType?.sectionKey
    );
    if (metaDataSection) {
      this.itemKey = metaDataSection.key;
      this.currentSection = metaDataSection;

      this.disableParticipant = false;
      this.formGroup.get('participant')?.setValue(null);
      this.formGroup.get('participantId')?.setValue('');

      this.memberStore.dispatch(
        MemberDetailActions.getMemberDetailUnMaskedRequest({
          memberId: this.memberId,
          listSection: [this.itemKey],
          isAllData: false,
        })
      );
    } else {
      this.itemKey = '';
      this.currentSection = null;
    }
  }

  setLstFormControl() {
    this.currentSection?.properties.forEach((prop: any) => {
      if (prop.type === PropertyType.TYPE_BENEFIT) {
        return;
      }
      prop = { ...prop };
      if (
        prop.type === PropertyType.TYPE_ADDRESS ||
        prop.type === PropertyType.TYPE_PERSON_NAME
      ) {
        // Address type | Person name type
        const lstChildForm =
          prop.type === PropertyType.TYPE_ADDRESS
            ? JSON.parse(JSON.stringify(this.lstInputAddress))
            : JSON.parse(JSON.stringify(this.lstInputPersonName));
        lstChildForm.forEach((inp: FieldData) => {
          const formControl = new FormControl('');
          inp.lstOption = [];
          if (
            inp.type === 'select' &&
            prop.config?.prefixList === 'true' &&
            prop.options &&
            prop.options.length > 0
          ) {
            inp.lstOption = prop.options.map((x: any) => {
              return {
                displayValue: x.text,
                value: x.id,
              };
            });
          }
          if (inp.key === 'state' && prop.options && prop.options.length > 0) {
            inp.lstOption = deepClone(prop)
              .options.filter(
                (option: OptionValue) => option.type === LookupTableType.State
              )
              .map((option: OptionValue) => {
                return {
                  displayValue: option.text,
                  value: option.id,
                };
              });
          }
          if (
            inp.key === 'country' &&
            prop.options &&
            prop.options.length > 0
          ) {
            inp.lstOption = deepClone(prop)
              .options.filter(
                (option: OptionValue) => option.type === LookupTableType.Country
              )
              .map((option: OptionValue) => {
                return {
                  displayValue: option.text,
                  value: option.id,
                };
              });
          }
          inp.formControl = formControl;
          if (stringToBoolean(prop.config?.readOnly)) {
            formControl.disable();
          }
        });
        this.lstFormControl.push({
          name: prop.key,
          label: prop.name,
          type: prop.type,
          lstChildFormControl: lstChildForm,
          isRequired: prop.config?.required === 'true',
        } as FormControlDetail);
      } else {
        // One input type
        const formControl = new FormControl('');
        const formControlDetail: FormControlDetail = {
          name: prop.key,
          label: prop.name,
          type: prop.type,
          lstOption: [],
          formControl: formControl,
        };
        let maxLengthAttr = 'none';

        // validate by property config
        const isExistingMinVal =
          stringToBoolean(prop.config?.inclusiveInRange) &&
          this.isValidString(prop.config?.minInput) &&
          !isNaN(Number(prop.config?.minInput));
        const isExistingMaxVal =
          stringToBoolean(prop.config?.inclusiveInRange) &&
          this.isValidString(prop.config?.maxInput) &&
          !isNaN(Number(prop.config?.maxInput));
        const isExistingMaxLengthVal =
          stringToBoolean(prop.config?.maximumLength) &&
          this.isValidString(prop.config?.maxLengthInput) &&
          !isNaN(Number(prop.config?.maxLengthInput));
        const isExistingFracLengthVal =
          stringToBoolean(prop.config?.fractionalLength) &&
          this.isValidString(prop.config?.fractionalLengthInput) &&
          !isNaN(Number(prop.config?.fractionalLengthInput));

        formControlDetail.isRequired = prop.config?.required === 'true';

        if (isExistingMinVal) {
          formControlDetail.min = prop.config?.minInput.trim();
        }
        if (isExistingMaxVal) {
          formControlDetail.max = prop.config?.maxInput.trim();
        }
        if (isExistingMaxLengthVal && !isExistingMinVal && !isExistingMaxVal) {
          maxLengthAttr = prop.config?.maxLengthInput;
        }
        if (isExistingFracLengthVal) {
          const fractionalLength = Number(prop.config?.fractionalLengthInput);
          if (fractionalLength === 0) {
            prop.type = PropertyType.TYPE_WHOLE_NUMBER;
          } else {
            const rgx = new RegExp(
              `^[+-]?[0-9]{1,999999}(?:\\.[0-9]{0,${fractionalLength}})?$`
            );
            formControl.addValidators(Validators.pattern(rgx));
            formControlDetail.fractionalLength = fractionalLength.toString();
          }
        }

        if (prop.type === PropertyType.TYPE_DATE) {
          let mindate: Date | undefined = undefined;
          let maxdate: Date | undefined = undefined;
          const currentDate = new Date();
          currentDate.setHours(0);
          if (prop.config?.excludeFutureDates?.toLowerCase() == 'true') {
            currentDate.setHours(24);
            maxdate = currentDate;
          }

          if (prop.config?.excludePastDates?.toLowerCase() == 'true') {
            currentDate.setHours(-24);
            mindate = currentDate;
          }

          if (prop.config?.validation?.toLowerCase() == 'true') {
            this.propValidation.push(prop.key);
            formControlDetail.isValidation = true;

            const rules = JSON.parse(prop.config.dateValidationExpressions);
            if (rules?.length > 0) {
              rules.forEach((rule: any) => {
                switch (rule.validationType) {
                  case ValidationType.Year:
                    const yearValidation = +rule.value;
                    if (yearValidation) {
                      const vaidationDate = new Date();
                      vaidationDate.setFullYear(
                        vaidationDate.getFullYear() - yearValidation
                      );
                      if (rule.validation === ValidationOperator.LessThan) {
                        let dateVal = new Date(vaidationDate);
                        dateVal.setHours(0);
                        if (mindate == undefined || dateVal > mindate) {
                          mindate = dateVal;
                        }
                      }

                      if (rule.validation === ValidationOperator.GreaterThan) {
                        let dateVal = new Date(vaidationDate);
                        dateVal.setHours(0);
                        if (maxdate == undefined || dateVal < maxdate) {
                          maxdate = dateVal;
                        }
                      }
                    }
                    break;
                  case ValidationType.SpecificDate:
                    const dateValidation = rule.value;
                    if (dateValidation) {
                      if (rule.validation === ValidationOperator.LessThan) {
                        const dateVal = new Date(dateValidation);
                        dateVal.setHours(0);
                        if (maxdate == undefined || dateVal < maxdate) {
                          maxdate = dateVal;
                        }
                      }

                      if (rule.validation === ValidationOperator.GreaterThan) {
                        const dateVal = new Date(dateValidation);
                        dateVal.setHours(0);
                        if (mindate == undefined || dateVal > mindate) {
                          mindate = dateVal;
                        }
                      }
                    }
                    break;
                  case ValidationType.Property:
                    this.datePropValidation.push({
                      property: prop.key,
                      validationProp: rule.value,
                      operator: rule.validation,
                    });
                }
              });
            }
          }

          this.dateValidationValue.push({
            property: prop.key,
            max: maxdate,
            min: mindate,
          });

          if (mindate) {
            this.setMinDate(formControlDetail, mindate);
          }

          if (maxdate) {
            this.setMaxDate(formControlDetail, maxdate);
          }

          this.setValidationMessage(formControlDetail);
        }

        if (prop.type === PropertyType.TYPE_CURRENCY) {
          formControl.setValue(null);
        }
        if (
          prop.type === PropertyType.TYPE_DECIMAL &&
          !isExistingFracLengthVal
        ) {
          prop.type = PropertyType.TYPE_WHOLE_NUMBER;
        }

        if (prop.type === PropertyType.TYPE_PERCENTAGE) {
          formControl.addValidators(Validators.min(Number(0)));
          formControlDetail.min = 0;

          formControl.addValidators(Validators.max(Number(100)));
          formControlDetail.max = 100;

          const rgx = new RegExp(`^[+-]?[0-9]{1,999999}(?:\\.[0-9]{0,${2}})?$`);
          formControl.addValidators(Validators.pattern(rgx));
          formControlDetail.fractionalLength = 2;
        }

        // set list option for List type
        if (
          prop.type === PropertyType.TYPE_LIST &&
          prop.options &&
          prop.options.length > 0
        ) {
          prop.lstOption = prop.options.map((x: any) => ({
            displayValue: x.text,
            value: x.id,
          }));
        } else if (
          (prop.type === PropertyType.TYPE_EMPLOYER ||
            prop.type === PropertyType.TYPE_DEPARTMENT ||
            prop.type === PropertyType.TYPE_TIER) &&
          prop.options &&
          prop.options.length > 0
        ) {
          const sectionData =
            this.currentMemberDetailUnmask[this.currentSection.key] || {};
          const currentValue = sectionData[prop.key];
          prop.lstOption = prop.options
            .filter(
              (option: any) =>
                option.active ||
                option.text === currentValue ||
                option.id === currentValue
            )
            .map((x: any) => ({
              displayValue: x.text,
              value: x.id,
              valueDescription: x.description,
            }));
        } else if (
          prop.type === PropertyType.TYPE_LIST &&
          prop.options &&
          prop.options.length > 0
        ) {
          prop.lstOption = prop.options.map((x: any) => ({
            displayValue: x.text,
            value: x.id,
          }));
        }
        if (stringToBoolean(prop.config?.readOnly)) {
          formControl.disable();
        }
        if (prop.config?.unique === 'true') {
          formControl.addAsyncValidators(
            checkApiValidator(
              this.memberDetailService.checkUnique,
              'value',
              undefined,
              {
                params: {
                  sectionKey: this.itemKey,
                  propKey: prop.key,
                  propType: prop.type,
                  memberId: this.memberId,
                  index: this.currentMemberDetailUnmask
                    ? this.currentMemberDetailUnmask[this.itemKey]?.index
                    : undefined,
                },
              }
            )
          );
        }

        formControlDetail.type = prop.type;
        formControlDetail.maxLength = maxLengthAttr;
        formControlDetail.lstOption = prop.lstOption;
        formControlDetail.config = prop.config;
        this.lstFormControl.push(formControlDetail);
      }
    });
  }

  setFormGroupCs() {
    this.lstFormControl.forEach((f) => {
      if (f.formControl !== undefined) {
        this.formGroup.setControl(f.name, f.formControl);
      } else {
        f.lstChildFormControl?.forEach((x) => {
          if (x.formControl !== undefined) {
            this.formGroup.setControl(f.name + x.name, x.formControl);
          }
        });
      }
    });
  }

  isValidString(str: any) {
    return str?.trim();
  }

  searchMember() {
    this.isSelectMember = false;
    this.search$.next(this.formGroup.get('participant')?.value);
  }

  removeParticipant() {
    this.disableParticipant = false;
    this.formGroup.get('participant')?.setValue(null);
    this.formGroup.get('participant')?.enable();
    this.formGroup.get('participantId')?.setValue('');
  }

  selectMember(option: any) {
    this.disableParticipant = true;
    this.formGroup.get('participant')?.setValue(option._participantName);
    this.formGroup.get('participant')?.disable();
    this.formGroup.get('participantId')?.setValue(option._memberId);
    this.participantSearchTrigger.closePanel();
    this.isSelectMember = true;
  }

  getColumnValue(searchResult: SearchResult, index: number) {
    const currentMember = searchResult.members[index];
    return searchResult.columns.reduce((result, currentColumn) => {
      const columnKey = currentColumn.columnKey;
      let value = '';
      if (!currentColumn.listSubField.length) {
        value = currentMember[columnKey];
        if (value && currentColumn.type === PropertyType.TYPE_DATE_TIME) {
          value = DateTime.fromISO(value)
            .toLocal()
            .toFormat('MM/dd/yyyy hh:mm a');
        }
      } else {
        value = currentColumn.listSubField.reduce((fieldValue, currentItem) => {
          let currentSubItemValue = currentMember[currentItem.key];
          switch (currentColumn.type) {
            case PropertyType.TYPE_STATUS:
            case PropertyType.TYPE_PERSON_NAME: {
              if (currentItem.key === 'Fixed_status_Date') {
                currentSubItemValue =
                  DateTime.fromISO(currentSubItemValue).toFormat('MM/dd/yyyy');
              }
              if (fieldValue === null) {
                fieldValue = '';
              }
              fieldValue += `${!fieldValue || !currentSubItemValue ? '' : ' '}${
                currentSubItemValue || ''
              }`;
              break;
            }
            case PropertyType.TYPE_ADDRESS: {
              if (fieldValue === null) {
                fieldValue = {};
              }
              fieldValue[currentItem.option.toLowerCase()] =
                currentSubItemValue;
            }
          }
          return fieldValue;
        }, null as any);
      }
      let type = deepClone(currentColumn.type);
      if (type === PropertyType.TYPE_PERSON_NAME) {
        type = PropertyTypeLabel.Text;
      }
      result[currentColumn.columnName] = {
        value,
        type,
        typedValue: currentMember._typedValue,
      };
      return result;
    }, {} as any);
  }

  onSubmit() {
    this.selectedBenefitType = this.listBenefitType.find(
      (item: any) => item.id === this.formGroup.get('benefitType')?.value
    );

    if (!!this.selectedBenefitType?.parentTypes && !this.hasConfig) {
      return;
    }

    if (!this.isSelectMember && this.selectedBenefitType?.parentTypes?.length) {
      this.formGroup.get('participant')?.setErrors({ required: true });
      return;
    }

    if (!this.formGroup.invalid) {
      if (this.formGroup.pending) {
        let sub = this.formGroup.statusChanges.subscribe(() => {
          if (this.formGroup.valid) {
            this.establishBenefit();
          }
          sub.unsubscribe();
        });
      } else if (this.formGroup.valid) {
        this.establishBenefit();
      }
    }
  }

  establishBenefit() {
    const body: any = {
      benefitTypeId: this.formGroup.get('benefitType')?.value,
      parentParticipantId:
        this.selectedBenefitType?.parentTypes?.length && this.hasConfig
          ? this.formGroup.get('participantId')?.value
          : null,
      metadataProperties: [],
    };
    for (const f of this.lstFormControl) {
      let value = f.formControl?.value;
      switch (f.type) {
        case PropertyType.TYPE_ADDRESS:
        case PropertyType.TYPE_PERSON_NAME:
          const objVal: any = {};
          let isHaveData = false;
          for (const childForm of f.lstChildFormControl || []) {
            if (childForm.formControl?.value) {
              isHaveData = true;
            }
            objVal[childForm.name] = childForm.formControl?.value;
          }
          value = isHaveData ? JSON.stringify(objVal) : '';
          break;
        case PropertyType.TYPE_DATE:
          const datepipe = new DatePipe('en-US');
          value = value ? datepipe.transform(value, 'yyyy-MM-dd') : '';
          break;
        case PropertyType.TYPE_UPLOAD_DOC:
          break;
        case PropertyType.TYPE_BOOLEAN:
          value = value ? 'true' : 'false';
          break;
        case PropertyType.TYPE_PERCENTAGE:
          value = value.toString();
          value = value.endsWith('.')
            ? value.substring(0, value.length - 1)
            : value;
          break;
        case PropertyType.TYPE_SSN:
        case PropertyType.TYPE_PHONE:
          value = getValueWithoutFormat(value);
          break;
        default:
          value = isEmpty(value) ? '' : value.toString();
      }
      body.metadataProperties.push({
        clientKey: this.activeFund.key,
        itemKey: this.itemKey,
        propertyKey: f.name,
        propertyValue: value,
      });
    }
    this.memberStore.dispatch(
      MemberBenefitListActions.establishMemberBenefit({
        body: body,
        memberId: this.memberId,
      })
    );
    this.dialogRef.close();
  }

  checkParticipantSearchConfig() {
    this.memberStore
      .pipe(
        select(fromMember.selectParticipantSearchPropertyDisplayConfigurations),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((propertyDisplayConfigurations) => {
        this.hasConfig = (
          deepClone(
            propertyDisplayConfigurations
          ) as PropertyDisplayConfiguration[]
        ).some((config) => config.orderRow !== null);
        this.changeValidationParticipant();
      });

    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((currentFund) => {
        if (currentFund?.id) {
          this.memberStore.dispatch(
            ParticipantSearchConfigurationActions.getParticipantSearchConfiguration()
          );
        }
      });
  }

  changeValidationParticipant() {
    if (this.selectedBenefitType?.parentTypes?.length && this.hasConfig) {
      this.formGroup.get('participant')?.enable();
      this.formGroup.get('participant')?.setValidators([Validators.required]);
    } else {
      this.formGroup.get('participant')?.disable();
      this.formGroup.get('participant')?.setValue(null);
      this.formGroup.get('participant')?.clearValidators();
      this.formGroup.get('participant')?.setErrors(null);
      this.formGroup.get('participantId')?.setValue(null);
    }
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: DISCARD_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  dateValueChange(event: any, form: any) {
    if (this.datePropValidation) {
      //validate property rules
      const validateRules = this.datePropValidation.filter(
        (o) => o.property === form.name
      );
      if (validateRules) {
        for (let index = 0; index < validateRules.length; index++) {
          const rule = validateRules[index];
          const compareProperty = rule.validationProp;

          const compareForm = this.lstFormControl.find(
            (o) => o.name === compareProperty
          );
          if (compareForm) {
            const compareControl = compareForm.formControl;
            if (compareControl) {
              const valueCompare = compareControl.value;
              if (valueCompare) {
                if (rule.operator === ValidationOperator.LessThan) {
                  let dateValidation = new Date(valueCompare);
                  dateValidation = this.compareMinDateValidation(
                    form.name,
                    dateValidation
                  );
                  this.setMinDate(form.formControl, dateValidation);
                }

                if (rule.operator === ValidationOperator.GreaterThan) {
                  let dateValidation = new Date(valueCompare);
                  dateValidation = this.compareMaxDateValidation(
                    form.name,
                    dateValidation
                  );
                  this.setMaxDate(form.formControl, dateValidation);
                }

                this.setValidationMessage(valueCompare);
              }
            }
          }
        }
      }

      //validate property
      const validateProps = this.datePropValidation.filter(
        (o) => o.validationProp == form.name
      );

      validateProps.forEach((prop) => {
        const sourceForm = this.lstFormControl.find(
          (o) => o.name === prop.property
        );
        if (sourceForm) {
          if (prop.operator === ValidationOperator.LessThan) {
            let valDate = new Date(event);
            valDate = this.compareMaxDateValidation(prop.property, valDate);
            this.setMaxDate(sourceForm, valDate);
          }

          if (prop.operator === ValidationOperator.GreaterThan) {
            let valDate = new Date(event);
            valDate = this.compareMinDateValidation(prop.property, valDate);
            this.setMinDate(sourceForm, valDate);
          }

          this.setValidationMessage(sourceForm);
        }
      });
    }
  }

  private setMaxDate(
    formControlDetail: FormControlDetail,
    vaidationDate: Date
  ) {
    if (vaidationDate) {
      vaidationDate.setHours(-24);

      formControlDetail.max = vaidationDate;
    }
  }

  private setMinDate(
    formControlDetail: FormControlDetail,
    vaidationDate: Date
  ) {
    if (vaidationDate) {
      vaidationDate.setHours(24);
      formControlDetail.min = vaidationDate;
    }
  }

  private compareMaxDateValidation(property: string, dateVal: Date) {
    if (this.dateValidationValue?.length > 0) {
      const val = this.dateValidationValue.find((o) => o.property === property);
      if (val && val.max) {
        dateVal.setHours(0);
        if (val.max < dateVal) {
          dateVal = val.max;
        }
      }
    }
    return dateVal;
  }

  private compareMinDateValidation(property: string, dateVal: Date) {
    if (this.dateValidationValue?.length > 0) {
      const val = this.dateValidationValue.find((o) => o.property === property);
      if (val && val.min) {
        dateVal.setHours(0);
        if (val.min > dateVal) {
          dateVal = val.min;
        }
      }
    }
    return dateVal;
  }

  applyValidation() {
    if (!this.data.isAddNewMember && this.propValidation?.length > 0) {
      this.datePropValidation.forEach((prop) => {
        const compareForm = this.lstFormControl.find(
          (o) => o.name == prop.validationProp
        );
        if (compareForm) {
          const compareControl = compareForm.formControl;
          if (compareControl) {
            const valueCompare = compareControl.value;
            if (valueCompare) {
              const sourceForm = this.lstFormControl.find(
                (o) => o.name === prop.property
              );
              if (sourceForm) {
                if (prop.operator === ValidationOperator.LessThan) {
                  let dateVal = new Date(valueCompare);
                  dateVal = this.compareMaxDateValidation(
                    prop.property,
                    dateVal
                  );
                  this.setMaxDate(sourceForm, dateVal);
                }
                if (prop.operator === ValidationOperator.GreaterThan) {
                  let dateVal = new Date(valueCompare);
                  dateVal = this.compareMinDateValidation(
                    prop.property,
                    dateVal
                  );
                  this.setMinDate(sourceForm, dateVal);
                }
              }
            }
          }
        }
      });

      this.propValidation.forEach((probVal) => {
        const form = this.lstFormControl.find((o) => o.name === probVal);
        form?.formControl.markAsTouched();
      });
    }
  }

  private setValidationMessage(formControlDetail: FormControlDetail) {
    const min = formControlDetail.min as Date;
    const max = formControlDetail.max as Date;
    const current = new Date();

    min?.setHours(0, 0, 0, 0);
    max?.setHours(0, 0, 0, 0);
    current.setHours(0, 0, 0, 0);

    formControlDetail.minMessage = formControlDetail.maxMessage =
      'The input does not meet the validation.';

    if (min?.getTime() === current.getTime()) {
      formControlDetail.minMessage = 'The input must be greater than today';
    }

    if (max?.getTime() === current.getTime()) {
      formControlDetail.maxMessage = 'The input must be less than today';
    }

    if (max && min && min.getTime() >= max.getTime()) {
      formControlDetail.maxMessage = formControlDetail.minMessage =
        'No date satifies the validation.';
    }
  }
}
