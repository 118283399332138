<div class="address-container" [ngClass]="customClass">
  <ng-container *ngIf="address; else defaultTemplate">
    <ng-container *ngIf="!singleLine">
      <div class="item" *ngIf="address?.street1">{{ address?.street1 }}</div>
      <div class="item" *ngIf="address?.street2">{{ address?.street2 }}</div>
      <div class="item" *ngIf="address?.additional">{{ address?.additional }}</div>
      <div class="item" *ngIf="address?.country">{{ address?.country }}</div>
    </ng-container>
    <ng-container *ngIf="singleLine">
      <span class="item">{{ address | address }}</span>
    </ng-container>

  </ng-container>
</div>

<ng-template #defaultTemplate>
  <div class="item">{{ defaultValue }}</div>
</ng-template>
