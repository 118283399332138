import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BuyBackMakeUpService } from '../../services/buyback-makeup.service';
import {
  getListBuyBackMakeUpAction,
  getListBuyBackMakeUpSuccessAction,
  getListBuyBackMakeUpFailureAction,
  getListBuyBackMakeUpDetailAction,
  getListBuyBackMakeUpDetailSuccessAction,
  getListBuyBackMakeUpDetailFailureAction,
  removeBuyBackMakeUpAction,
  removeBuyBackMakeUpSuccessAction,
  removeBuyBackMakeUpFailureAction,
  getEligibleYearAction,
  getEligibleYearFailureAction,
  getEligibleYearSuccessAction,
  addBuyBackMakeUpAction,
  addBuyBackMakeUpFailureAction,
  addBuyBackMakeUpSuccessAction,
  editBuyBackMakeUpAction,
  editBuyBackMakeUpFailureAction,
  editBuyBackMakeUpSuccessAction,
  getListMunicipalityBuyBackMakeUpAction,
  getListMunicipalityBuyBackMakeUpFailureAction,
  getListMunicipalityBuyBackMakeUpSuccessAction,
  editBuyBackMakeUpInterestFeesAction,
  editBuyBackMakeUpInterestFeesFailureAction,
  editBuyBackMakeUpInterestFeesSuccessAction,
  initiateRemittanceBuyBackMakeUpAction,
  initiateRemittanceBuyBackMakeUpFailureAction,
  initiateRemittanceBuyBackMakeUpSuccessAction,
  getBuyBackMakeUpYearRecordAction,
  getBuyBackMakeUpYearRecordFailureAction,
  getBuyBackMakeUpYearRecordSuccessAction,
  getBuyBackMakeUpInterestFeesCalculationAction,
  getBuyBackMakeUpInterestFeesCalculationFailureAction,
  getBuyBackMakeUpInterestFeesCalculationSuccessAction,
} from '../actions/buyback-makeup.actions';
import {
  BuyBackMakeUpYearRecordResponse,
  GetListMunicipalityResponse,
  InitiatedRemittanceBuyBackMakeUpResponse,
} from '../../services/models/buyback-makeup.model';

@Injectable()
export class BuyBackMakeUpEffects {
  constructor(
    private actions$: Actions,
    private buyBackMakeUpService: BuyBackMakeUpService
  ) { }

  getListBuyBackMakeUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getListBuyBackMakeUpAction),
      switchMap(({ request }) =>
        this.buyBackMakeUpService.getListBuyBackMakeUp(request).pipe(
          map((response: any) => getListBuyBackMakeUpSuccessAction({ response })),
          catchError((error) => of(getListBuyBackMakeUpFailureAction({ error })))
        )
      )
    )
  );

  getListBuyBackMakeUpDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getListBuyBackMakeUpDetailAction),
      switchMap(({ request }) =>
        this.buyBackMakeUpService.getListBuyBackMakeUpDetail(request).pipe(
          map((response: any) => getListBuyBackMakeUpDetailSuccessAction({ response })),
          catchError((error) => of(getListBuyBackMakeUpDetailFailureAction({ error })))
        )
      )
    )
  );

  removeBuyBackMakeUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeBuyBackMakeUpAction),
      switchMap(({ memberId, buyBackMakeUpId }) => {
        return this.buyBackMakeUpService.removeBuyBackMakeUp(memberId, buyBackMakeUpId).pipe(
          map(() => removeBuyBackMakeUpSuccessAction()),
          catchError((error) => {
            let errorMessage = ''
            if (error?.error?.errorType === 'CommonException') {
              errorMessage = error?.error?.errorMessage[0] ?? '';
            }
            return of(removeBuyBackMakeUpFailureAction({ error: errorMessage }))
          })
        )
      })
    )
  );

  getEligibleYear$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEligibleYearAction),
      switchMap(({ request }) =>
        this.buyBackMakeUpService.getEligibleYear(request).pipe(
          map((response: any) => getEligibleYearSuccessAction({ response })),
          catchError((error) => of(getEligibleYearFailureAction({ error })))
        )
      )
    )
  );

  getListMunicipality$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getListMunicipalityBuyBackMakeUpAction),
      switchMap(({ request }) =>
        this.buyBackMakeUpService.getListMunicipality(request).pipe(
          map((response: GetListMunicipalityResponse) => getListMunicipalityBuyBackMakeUpSuccessAction({ response })),
          catchError((error) => of(getListMunicipalityBuyBackMakeUpFailureAction({ error })))
        )
      )
    )
  );

  addBuyBackMakeUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addBuyBackMakeUpAction),
      switchMap(({ request, memberId }) =>
        this.buyBackMakeUpService.addEditBuyBackMakeUp(request, memberId).pipe(
          map(() => addBuyBackMakeUpSuccessAction()),
          catchError((error) => {
            let errorMessage = ''
            if (error?.error?.errorType === 'CommandException') {
              errorMessage = error?.error?.errorMessage?.[1] ?? '';
            }
            return of(addBuyBackMakeUpFailureAction({ errorMsg: errorMessage }));
          })
        )
      )
    )
  );

  editBuyBackMakeUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editBuyBackMakeUpAction),
      switchMap(({ request, memberId }) =>
        this.buyBackMakeUpService.addEditBuyBackMakeUp(request, memberId).pipe(
          map(() => editBuyBackMakeUpSuccessAction()),
          catchError((error) => {
            let errorMessage = ''
            if (error?.error?.errorType === 'CommandException') {
              errorMessage = error?.error?.errorMessage?.[1] ?? '';
            }
            return of(editBuyBackMakeUpFailureAction({ errorMsg: errorMessage }));
          })
        )
      )
    )
  );

  initiateRemittance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initiateRemittanceBuyBackMakeUpAction),
      switchMap(({ memberId, buybackMakeupId }) =>
        this.buyBackMakeUpService.initiateRemittanceBuyBackMakeUp(memberId, buybackMakeupId).pipe(
          map((response: InitiatedRemittanceBuyBackMakeUpResponse) => initiateRemittanceBuyBackMakeUpSuccessAction({ response })),
          catchError((error) => {
            let errorMessage = ''
            if (error?.error?.errorType === 'CommandException') {
              errorMessage = error?.error?.errorMessage?.[1] ?? '';
            }
            return of(initiateRemittanceBuyBackMakeUpFailureAction({ errorMsg: errorMessage }));
          })
        )
      )
    )
  );

  editBuyBackMakeUpInterestFees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editBuyBackMakeUpInterestFeesAction),
      switchMap(({ 
        memberId,
        buyBackMakeUpId,
        yearRecordId,
        request, 
      }) =>
        this.buyBackMakeUpService.editBuyBackMakeUpInterestFees(
          memberId,
          buyBackMakeUpId,
          yearRecordId,
          request
        ).pipe(
          map(() => editBuyBackMakeUpInterestFeesSuccessAction()),
          catchError((error) => {
            let errorMessage = ''
            if (error?.error?.errorType === 'CommandException') {
              errorMessage = error?.error?.errorMessage?.[1] ?? '';
            }
            return of(editBuyBackMakeUpInterestFeesFailureAction({ errorMsg: errorMessage }));
          })
        )
      )
    )
  );

  getBuyBackMakeUpYearRecord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBuyBackMakeUpYearRecordAction),
      switchMap(({ memberId, buyBackMakeUpId, yearRecordId }) =>
        this.buyBackMakeUpService.getBuyBackMakeUpYearRecord(memberId, buyBackMakeUpId, yearRecordId).pipe(
          map((response: BuyBackMakeUpYearRecordResponse) => getBuyBackMakeUpYearRecordSuccessAction({ response })),
          catchError((error) => of(getBuyBackMakeUpYearRecordFailureAction({ error })))
        )
      )
    )
  );

  getBuyBackMakeUpInterestFeesCalculation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBuyBackMakeUpInterestFeesCalculationAction),
      switchMap(({ memberId, buyBackMakeUpId, yearRecordId, interestBeginDate, interestThruDate }) =>
        this.buyBackMakeUpService.getBuybackMakeupInterestFeesCalculationResult(memberId, buyBackMakeUpId, yearRecordId, interestBeginDate, interestThruDate).pipe(
          map((response: BuyBackMakeUpYearRecordResponse) => getBuyBackMakeUpInterestFeesCalculationSuccessAction({ response })),
          catchError((error) => of(getBuyBackMakeUpInterestFeesCalculationFailureAction({ error })))
        )
      )
    )
  );

}
