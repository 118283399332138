import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { BaseComponent } from '@ptg-shared/components';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ACTION, SortType } from '@ptg-shared/constance';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { ACTION_COLUMN, Column, Row } from '@ptg-shared/controls/grid';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { showBanner } from '@ptg-shared/utils/common.util';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { stringToBoolean } from '@ptg-shared/utils/string.util';
import * as fromReducer from '@ptg-reducers';

import { EntityPropertyType } from '../../types/enums';
import {
  addPropertySelector,
  addSectionSelector,
  deleteEntityComponentSelector,
  deletePropertySelector,
  getEntityDetailSelector,
  getEntityListsSelector,
  getEntityPropertiesSelector,
  setEntityPropertySelector,
  updateEntitySelector,
} from '../../store/selectors';
import { EntityService } from '../../services';
import {
  CheckEntityPropertyInUsedResponse,
  DeletePropertyRequest,
  GetEntityDetailResponse,
  GetEntityListsRequest,
  GetEntityPropertiesRequest,
} from '../../services/models';
import { AddEntityListItemComponent } from '../add-entity-list-item/add-entity-list-item.component';
import { EditEntityInformationComponent } from '../edit-entity-information/edit-entity-information.component';
import { AddPropertyItemComponent } from '../add-property-item/add-property-item.component';
import { EditAggregationPropertyItemComponent } from '../edit-aggregation-property-item/edit-aggregation-property-item.component';
import { EditCalculationPropertyItemComponent } from '../edit-calculation-property-item/edit-calculation-property-item.component';
import { EditPropertyItemComponent } from '../edit-property-item/edit-property-item.component';
import { EntityState } from '../../store/reducers';
import {
  clearAddListState,
  clearAddPropertyState,
  clearCreateEntityStateAction,
  clearDeleteEntityComponentState,
  clearDeletePropertyState,
  clearSetEntityPropertyStateAction,
  clearUpdateEntityState,
  clearUpdatePropertyItemState,
  deleteEntityComponent,
  deleteProperty,
  getEntityDetail,
  getEntityListsAction,
  getEntityPropertiesAction,
} from '../../store/actions';
import { EditEntityReferenceComponent } from '../edit-entity-reference/edit-entity-reference.component';

const PAGE_SIZE_CONST = '-ptg-edit-entity-pageSize';
const PAGE_SIZE_CONST_SECTION = '-ptg-edit-entity-pageSize-section';

@Component({
  selector: 'ptg-edit-entity',
  templateUrl: './edit-entity.component.html',
  styleUrls: ['./edit-entity.component.scss'],
})
export class EditEntityComponent extends BaseComponent {
  readonly EntityPropertyType = EntityPropertyType;
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  fromParticipants: string = '';

  listBreadcrumbs: Breadcrumb[] = [];
  isLoading = true;
  isLoadingProperty = true;
  isLoadingSection = true;
  sortInfo: {} | any = {};
  totalPropertyRecords: number | any;
  pageSizeProperty: number = 50;
  pageNumberProperty: number = FIRST_PAGE;
  totalRecordsSection: number | any;
  pageSizeSection: number = 50;
  pageNumberSection: number = FIRST_PAGE;
  currentFund: any = {};
  entityComponentId: string = '';
  propertiesData: (any & Row)[] = [];
  propertiesColumns: Column[] = [
    {
      name: 'propertyName',
      header: {
        title: 'Property Name',
      },
      width: '30%',
      truncate: true,
      sortable: true,
    },
    {
      name: 'typeName',
      header: {
        title: 'Type',
      },
      width: '20%',
      truncate: true,
      sortable: true,
    },
    {
      name: 'dataTypeName',
      header: {
        title: 'Data Type',
      },
      width: '20%',
      truncate: true,
      sortable: true,
    },
    {
      name: 'importKey',
      header: {
        title: 'Import Label',
      },
      width: '20%',
      truncate: true,
      sortable: true,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      width: '170px',
    },
  ];
  sectionsData: (any & Row)[] = [];
  sectionsColumns: Column[] = [
    {
      name: 'name',
      header: {
        title: 'List Name',
      },
      width: '30%',
      truncate: true,
      sortable: true,
    },
    {
      name: 'totalProperty',
      header: {
        title: 'Total Properties',
      },
      width: '20%',
      truncate: true,
      sortable: true,
    },
    {
      name: 'importLabel',
      header: {
        title: 'Import Label',
      },
      width: '20%',
      truncate: true,
      sortable: true,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      width: '170px',
    },
  ];

  entity?: GetEntityDetailResponse;
  clientId!: string;
  entityId!: string;
  currentPropertyName: string = '';

  constructor(
    public fb: FormBuilder,
    private store: Store<fromReducer.State>,
    public dialog: MatDialog,
    public router: Router,
    public entityService: EntityService,
    public entityStore: Store<EntityState>,
    public route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    combineLatest([this.route.params, this.route.queryParams])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([params, queryParams]) => {
        this.clientId = params.fundId;
        this.entityId = params.id;
        this.fromParticipants = queryParams.fromParticipants;
      });
    this.store.dispatch(LayoutActions.hiddenSideMenu());
    this.getEntityDetailAction();
    this.deleteDataAction();
    this.deleteEntityComponentAction();
    this.getEntityListAction();
    this.getEntityPropertiesAction();

    this.getCurrentFundSelector();
    this.getEntityDetailSelector();
    this.getEntityPropertiesSelector();
    this.getEntityListSelector();
    this.getAddPropertySelector();
    this.getAddListSelector();
    this.getUpdatePropertySelector();
    this.getUpdateEntityDetailSelector();
  }

  getCurrentFundSelector() {
    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.pageSizeProperty = el.defaultPageSize ?? 50;
        this.pageSizeSection = el.defaultPageSize ?? 50;
        this.currentFund = el;
      });
    this.pageSizeProperty =
      Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)) ===
        0
        ? this.pageSizeProperty
        : Number(
          sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST)
        );
    this.pageSizeSection =
      Number(
        sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST_SECTION)
      ) === 0
        ? this.pageSizeSection
        : Number(
          sessionStorage.getItem(
            this.currentFund.key + PAGE_SIZE_CONST_SECTION
          )
        );
  }

  getEntityDetailAction() {
    this.entityStore.dispatch(
      getEntityDetail({
        request: {
          id: this.entityId,
        },
      })
    );
  }

  getEntityDetailSelector() {
    combineLatest([
      this.entityStore.pipe(select(getEntityDetailSelector)), 
      this.store.pipe(select(fromReducer.selectCurrentFundState))
    ]).pipe(takeUntil(this.unsubscribe$)).subscribe(([state, currentFund]) => {
      if (state) {
        this.isLoading = state.isLoading;
        this.entity = state.payload;
      }
      this.listBreadcrumbs = [
        {
          name: stringToBoolean(this.fromParticipants)
            ? 'Participant List'
            : 'Fund List',
          url: stringToBoolean(this.fromParticipants)
            ? '/member'
            : '/fund-list',
        },
        {
          name: currentFund?.name + ' Entity List',
          url: stringToBoolean(this.fromParticipants)
            ? `/entity-management/entities?fromParticipants=true`
            : `/entity-management/entities`,
        },
        {
          name: state?.payload?.entityName ?? '',
          url: `/entity-management/entities/edit/${this.entityId}`,
        },
      ];
    });
  }

  getUpdateEntityDetailSelector(): void {
    this.entityStore
      .pipe(select(updateEntitySelector), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state && !state.isLoading) {
          if (state?.success) {
            this.bannerType = BannerType.Success;
          } else {
            this.bannerType = BannerType.Fail;
          }
          showBanner.call(
            this,
            this.bannerType,
            'General Information',
            ACTION.EDIT
          );
        }
      });
  }

  getEntityPropertiesAction(jumpToFirst?: boolean): void {
    if (jumpToFirst) {
      this.pageNumberProperty = FIRST_PAGE;
    }

    let request: GetEntityPropertiesRequest = {
      entityId: this.entityId,
      pageNumber: this.pageNumberProperty,
      pageSize: this.pageSizeProperty,
    };

    if (this.sortInfo.active) {
      const field =
        this.sortInfo?.active[0].toUpperCase() +
        this.sortInfo?.active.substr(1);
      request = {
        ...request,
        sortNames: this.sortInfo?.direction ? field : '',
        sortType:
          this.sortInfo?.direction === 'asc' ? SortType.ASC : SortType.DESC,
      };
    }

    this.entityStore.dispatch(getEntityPropertiesAction({ request }));
  }

  getEntityPropertiesSelector(): void {
    this.entityStore
      .pipe(select(getEntityPropertiesSelector), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state && !state.isLoading) {
          this.entityComponentId = state.payload?.entityComponentId || '';
          this.totalPropertyRecords = state.total;
          this.propertiesData = (state?.payload?.propertyItems ?? []).map(
            (item: any) => {
              return {
                id: item?.id,
                propertyName: item?.propertyName,
                typeName: item?.typeName,
                dataTypeName: item?.dataTypeName,
                importKey: item?.importKey,
                inheritance: item?.inheritance,
                isSystem: item?.type === EntityPropertyType.System,
                entityComponentId: item.entityComponentId,
                entityPropertyId: item.id,
                type: item?.type,
              };
            }
        );
        this.isLoadingProperty = false;
      }
      });
  }

  getEntityListAction(jumpToFirst?: boolean): void {
    if (jumpToFirst) {
      this.pageNumberSection = FIRST_PAGE;
    }

    let request: GetEntityListsRequest = {
      pageNumber: this.pageNumberSection,
      pageSize: this.pageSizeSection,
      entityId: this.entityId,
    };

    if (this.sortInfo.active) {
      const field =
        this.sortInfo?.active[0].toUpperCase() +
        this.sortInfo?.active.substr(1);
      request = {
        ...request,
        sortNames: this.sortInfo?.direction ? field : '',
        sortType:
          this.sortInfo?.direction === 'asc' ? SortType.ASC : SortType.DESC,
      };
    }

    this.entityStore.dispatch(getEntityListsAction({ request }));
  }

  getEntityListSelector(): void {
    this.entityStore
      .pipe(select(getEntityListsSelector), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state && !state.isLoading) {
          this.totalRecordsSection = state.total;
          this.sectionsData = (state?.payload ?? []).map((item: any) => {
            return {
              id: item?.id,
              name: item?.name,
              importLabel: item?.importLabel,
              totalProperty: item?.totalProperty,
              inheritance: item?.inheritance,
            };
          });
          this.isLoadingSection = false;
        }
      });
  }

  getAddPropertySelector(): void {
    this.entityStore
      .pipe(
        select(addPropertySelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (!state?.payload?.isContinue) {
          this.isLoadingProperty = true;
        }

        this.getEntityPropertiesAction(true);
      });
  }

  getAddListSelector(): void {
    this.entityStore
      .pipe(
        select(addSectionSelector),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        if (state) {
          this.isLoadingSection = true
        }
        if (!state?.payload?.isContinue) {
          this.getEntityListAction(true);
        }
      });
  }

  getUpdatePropertySelector(): void {
    this.entityStore
      .pipe(select(setEntityPropertySelector), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state && !state?.isLoading) {
          if (state?.success) {
            this.bannerType = BannerType.Success;
          } else {
            this.bannerType = BannerType.Fail;
          }
          showBanner.call(
            this,
            this.bannerType,
            state.payload?.propertyName || this.currentPropertyName || '',
            ACTION.EDIT
          );
          this.currentPropertyName = '';
          this.getEntityDetailAction();
          this.getEntityPropertiesAction();
          this.entityStore.dispatch(clearSetEntityPropertyStateAction());
        }
      });
  }

  private deleteDataAction(): void {
    this.entityStore
      .pipe(select(deletePropertySelector), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state) {
          if (state?.success === undefined) {
            return;
          }
          if (state?.success) {
            this.bannerType = BannerType.Success;
            this.getEntityPropertiesAction();
          } else {
            this.bannerType = BannerType.Fail;
          }
          showBanner.call(
            this,
            this.bannerType,
            state?.payload?.propertyName || '',
            ACTION.REMOVE
          );
          this.entityStore.dispatch(clearDeletePropertyState());
        }
      });
  }

  private deleteEntityComponentAction(): void {
    this.entityStore
      .pipe(select(deleteEntityComponentSelector), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state) {
          if (state?.success === undefined) {
            return;
          }
          if (state?.success) {
            this.bannerType = BannerType.Success;
            this.getEntityListAction();
          } else {
            this.bannerType = BannerType.Fail;
          }
          showBanner.call(
            this,
            this.bannerType,
            state?.payload?.propertyName || '',
            ACTION.REMOVE
          );
          this.entityStore.dispatch(clearDeleteEntityComponentState());
        }
      });
  }

  onClickRemovePropertyOrList(row: any, removeList: boolean = true): void {
    let dialogRef: any;
    let request: DeletePropertyRequest = {
      entityId: this.entityId,
      entityComponentId: removeList ? row.id : row.entityComponentId,
      entityPropertyId: row.entityPropertyId,
      propertyName: row.propertyName || row.name,
      isRemoveProperty: !removeList,
    };
    this.entityService
      .checkPropertyUsing(request)
      .subscribe((res: CheckEntityPropertyInUsedResponse) => {
        if (res?.exists) {
          dialogRef = this.dialog.open(ConfirmPopupComponent, {
            panelClass: 'confirm-popup',
            autoFocus: false,
            data: {
              title: 'Attention',
              text: res.message,
              type: ConfirmType.Warning,
              cancelButtonTitle: 'Close',
              hideConfirmButton: true,
            },
          });
        } else {
          dialogRef = this.dialog.open(ConfirmPopupComponent, {
            panelClass: 'confirm-popup',
            autoFocus: false,
            data: {
              title: 'This is a destructive action',
              text: `Removing this ${removeList ? 'list' : 'property'
                } will remove all of its related data. Are you sure you want to proceed?`,
              type: ConfirmType.Destruct,
            },
          });
          dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
              this.isLoadingSection = removeList;
              this.isLoadingProperty = !removeList;
              if (removeList) {
                this.entityStore.dispatch(deleteEntityComponent({ request }));
              } else {
                this.entityStore.dispatch(deleteProperty({ request }));
              }
            }
          });
        }
      });
  }

  onClickEditProperty(row: any): void {
    this.currentPropertyName = row.propertyName;
    if (row.isSystem) {
      this.dialog.open(ConfirmPopupComponent, {
        panelClass: 'confirm-popup',
        data: {
          title: 'Warning',
          text: 'Cannot edit system property.',
          type: ConfirmType.Warning,
          cancelButtonTitle: 'Close',
          hideConfirmButton: true,
        },
      });
      return;
    }
    this.entityStore.dispatch(clearUpdatePropertyItemState());
    this.getEntityPropertiesAction();

    let dialogRef;
    switch (row.type) {
      case EntityPropertyType['Entity Reference']:
        dialogRef = this.dialog.open(EditEntityReferenceComponent, {
          panelClass: 'edit-popup',
          disableClose: true,
          autoFocus: false,
          width: '800px',
          height: 'auto',
          data: {
            ...row,
            entityId: this.entityId,
            entityComponentId: this.entityComponentId,
            entityPropertyId: row.id,
          },
        });
        break;
      case EntityPropertyType.Aggregation:
        dialogRef = this.dialog.open(EditAggregationPropertyItemComponent, {
          panelClass: 'edit-popup',
          disableClose: true,
          autoFocus: false,
          width: '800px',
          height: 'auto',
          data: {
            entityId: this.entityId,
            entityComponentId: this.entityComponentId,
            entityPropertyId: row.id,
          },
        });
        break;
      case EntityPropertyType.Calculation:
        dialogRef = this.dialog.open(EditCalculationPropertyItemComponent, {
          panelClass: 'edit-popup',
          disableClose: true,
          autoFocus: false,
          width: '800px',
          height: 'auto',
          data: {
            entityId: this.entityId,
            entityComponentId: this.entityComponentId,
            entityPropertyId: row.id,
          },
        });
        break;
      default:
        dialogRef = this.dialog.open(EditPropertyItemComponent, {
          panelClass: 'edit-popup',
          disableClose: true,
          autoFocus: false,
          width: '800px',
          height: 'auto',
          data: {
            entityId: this.entityId,
            entityComponentId: this.entityComponentId,
            entityPropertyId: row.id,
            entityProperties: this.propertiesData,
          },
        });
        break;
    }
  }

  onClickAddNewProperty(): void {
    this.entityStore.dispatch(clearAddPropertyState());
    const dialogRef = this.dialog.open(AddPropertyItemComponent, {
      panelClass: 'edit-popup',
      disableClose: true,
      autoFocus: false,
      width: '800px',
      height: 'auto',
      data: {
        clientId: this.clientId,
        entity: this.entity,
        entityId: this.entityId,
        entityComponentId: this.entityComponentId,
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (result?.success === undefined) {
          return;
        }

        this.entityStore.dispatch(clearAddPropertyState());
      }
    });
  }

  onClickAddNewList(): void {
    this.entityStore.dispatch(clearAddListState());
    this.entityStore.dispatch(clearCreateEntityStateAction());
    const dialogRef = this.dialog.open(AddEntityListItemComponent, {
      panelClass: 'edit-popup',
      disableClose: true,
      autoFocus: false,
      width: '848px',
      height: 'auto',
      data: {
        clientId: this.clientId,
        entity: this.entity,
        entityId: this.entityId,
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (result?.success === undefined) {
          return;
        }
        if (result?.success) {
          this.entityStore.dispatch(clearUpdateEntityState());
          this.getEntityListAction();
        }
      }
    });
  }

  onClickEditList(row: any): void {
    this.router.navigateByUrl(
      `/entity-management/entities/entity/${this.entityId}/list/${row?.id}`,
      { state: this.entity }
    );
  }

  onClickEditEntity(): void {
    const dialogRef = this.dialog.open(EditEntityInformationComponent, {
      panelClass: 'edit-popup',
      disableClose: true,
      autoFocus: false,
      width: '856px',
      height: 'auto',
      data: { entity: this.entity },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getEntityDetailAction();
        this.getEntityPropertiesAction();
        this.getEntityListAction();
        this.entityStore.dispatch(clearUpdateEntityState());
      }
    });
  }

  onChangePageProperty(event: PageEvent) {
    this.pageSizeProperty = event.pageSize;
    this.pageNumberProperty = event.pageNumber;

    sessionStorage.setItem(
      this.currentFund.key + PAGE_SIZE_CONST,
      this.pageSizeProperty.toString()
    );

    this.getEntityPropertiesAction();
  }

  onChangePageSection(event: PageEvent) {
    this.pageSizeSection = event.pageSize;
    this.pageNumberSection = event.pageNumber;

    sessionStorage.setItem(
      this.currentFund.key + PAGE_SIZE_CONST,
      this.pageSizeSection.toString()
    );
    this.getEntityListAction();
  }

  onChangeSortSection(event: any) {
    this.sortInfo = event;
  }

  onChangeSortProperty(event: any) {
    this.sortInfo = event;
  }
}
