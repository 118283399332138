import { SortType } from '@ptg-shared/constance/value.const';

import { PropertyType } from '../../constance/metadata.const';

export interface GetCalculationsRequest {
  pageSize?: number;
  pageNumber?: number;
  sortNames?: string;
  sortType?: SortType;
}

export interface GetCalculationsResponse {
  viewCalculation?: Calculation[];
  total?: number;
}

export interface Calculation {
  id: string;
  calculationName: string;
  displayFormat: string;
  calculationFormula: string;
}

export interface AddCalculationRequest {
  name: string;
  metadataPropertyType: PropertyType;
  displayValue: string;
  calculationComponents: MetadataCalculationComponent[];
}

export interface MetadataCalculationComponent {
  operator?: Operator;
  dataType: CalculationDataType;
  value: string;
  order: number;
  includeEndDate?: boolean;
  propertyType: CalculationPropertyType;
}

export interface UpdateCalculationRequest {
  id: string;
  name: string;
}

export interface CalculationPropertiesResponse {
  calculationProperties: CalculationProperty[];
}

export interface CalculationProperty {
  id: string;
  propertyKey: string;
  propertyName: string;
  sectionKey: string;
  sectionName: string;
  name: string;
  propertyType: CalculationPropertyType;
}

export interface CalculationDetailResponse {
  calculationData: CalculationDetail;
}

export interface CalculationDetail {
  id: string;
  clientId: string;
  name: string;
  displayFormat: PropertyType;
  displayValue: string;
  calculationComponents?: MetadataCalculationComponent[];
}

export enum CalculationPropertyType {
  ParticipantMetadata,
  Aggregation,
  Calculation,
}

export enum Operator {
  Plus,
  Minus,
  Multiply,
  Divide,
}

export enum CalculationDataType {
  Property,
  Number,
}
