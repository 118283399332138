<mat-form-field class="input-form-field {{class}}" appearance="fill" *ngIf="controlField">
    <mat-label *ngIf="hasLabel">{{placeholder}}</mat-label>
    <input
        matInput
        currencyMask
        [formControl]="controlField"
        [placeholder]="hasLabel ? '' : placeholder"
        [maxlength]="maxLength ? maxLength : 'none'"
        autocomplete="off"
        [options]="{ align : 'left', inputMode: CurrencyMaskInputMode.NATURAL, allowNegative: false, nullable: true }"
        >
    <mat-error *ngIf="controlField?.errors?.required">{{errorRequire}}</mat-error>
    <mat-error *ngIf="controlField?.errors?.maxlength">{{errorMaxLength}}</mat-error>
    <mat-error *ngIf="min && !max && controlField?.errors?.min">{{errorMin}}</mat-error>
    <mat-error *ngIf="!min && max && controlField?.errors?.max">{{errorMax}}</mat-error>
    <mat-error *ngIf="min && max && (controlField?.errors?.min || controlField?.errors?.max)">{{errorBetween}}</mat-error>
</mat-form-field>
