export interface ReportCategoryResponse {
  reportCategories: ReportCategory[];
}

export interface ReportCategory {
  deleted?: boolean;
  id: string;
  clientId: string;
  name: string;
  order: number;
  isDeleted?: boolean;
  reports: any[];
}

export interface UpdateReportCategoriesRequest {
  clientId: string;
  reportCategoriesUpdateModels: ReportCategoriesUpdateModel[];
}
export interface ReportCategoriesUpdateModel {
  id: string;
  isDeleted: boolean;
  name: string;
  order: number;
}
