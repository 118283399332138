import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject, combineLatest } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

import { DisplayedTabName } from '@ptg-shared/layout/constance/layout.const';
import { SUBMODULE_KEY } from '@ptg-shared/constance/permission.const';
import { ACTION } from '@ptg-shared/constance/value.const';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { deepClone, showBanner } from '@ptg-shared/utils/common.util';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import * as fromReducer from '@ptg-reducers';

import * as ParticipantSearchConfigurationActions from '../../store/actions/participant-search-configuration.actions';
import {
  ColumnSetPropertyDisplayConfiguration,
  PropertyDisplayConfig,
  PropertyDisplayConfiguration,
  SectionConfig,
} from '../../types/models';
import * as fromMember from '../../store/reducers';
import { SearchConfigurationDisplayConfig, SearchPropertyDisplayConfig } from '@ptg-member/types/models/participant-search-configuration.model';

@Component({
  selector: 'ptg-participant-search-configuration',
  templateUrl: './participant-search-configuration.component.html',
  styleUrls: ['./participant-search-configuration.component.scss'],
})
export class ParticipantSearchConfigurationComponent implements OnInit {
  unsubscribe$ = new Subject<void>();
  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Participant List',
      moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
      url: '/member',
    },
    {
      name: 'Participant Search Configuration',
      url: '',
    },
  ];
  propertyConfigs: SearchPropertyDisplayConfig[] = [];
  propertyDisplayConfigurations: SearchConfigurationDisplayConfig[] = [];
  addPropertySection: SectionConfig = {
    title: 'Add Property',
    columnName: 'Label Name',
    propertyName: 'Property Name',
  };
  sortPropertySection: SectionConfig = {
    title: 'Order Properties In Result List',
  };
  sortRowSection: SectionConfig = { title: 'Rank Searchable Properties' };
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  entityId: string = '';
  constructor(
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
    private route: ActivatedRoute
    
  ) {}

  ngOnInit(): void {
    this.initPage();
    this.memberStore
      .pipe(
        select(fromMember.selectParticipantSearchPropertyConfigs),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((propertyConfigs) => {
        if (propertyConfigs.length) {
          this.propertyConfigs = deepClone(propertyConfigs).map((item: SearchPropertyDisplayConfig) => {
            if(!item.type) {
              item.id = item.importKey;
            }
            if(item.propertyNameEntityRef) {
              item.id = `${item.id}|${item.propertyRefId}`;
            }
            return item;
          });
        }
      });

    this.memberStore
      .pipe(
        select(fromMember.selectParticipantSearchPropertyDisplayConfigurationsEntity),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((configurations) => {
        this.propertyDisplayConfigurations = deepClone(configurations ?? []).map((item:SearchConfigurationDisplayConfig) => {
          if(item.entityReferencePropertyId) {
            item.entityPropertyId = `${item.entityPropertyId}|${item.entityReferencePropertyId}`;
          }
          return item;
        });
      });

    this.memberStore
      .pipe(
        select(fromMember.selectParticipantSearchConfigurationUpdateState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        this.memberStore.dispatch(
          ParticipantSearchConfigurationActions.getParticipantSearchConfigurationEntity({ entityId: this.entityId })
        );
        this.memberStore.dispatch(
          ParticipantSearchConfigurationActions.clearParticipantSearchConfigurationState()
        );
        showBanner.call(
          this,
          state,
          'Participant Search Configuration',
          ACTION.EDIT
        );
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initPage() {
    this.store.dispatch(LayoutActions.hiddenSideMenu());
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.entityId = params.id;
      if(this.entityId) {
        this.memberStore.dispatch(
          ParticipantSearchConfigurationActions.getParticipantSearchPropertyList({ entityId: this.entityId })
        );
        this.memberStore.dispatch(
          ParticipantSearchConfigurationActions.getParticipantSearchConfigurationEntity({ entityId: this.entityId })
        );
      }
    }); 
  }

  onSubmit(listMemberSearchConfig: SearchConfigurationDisplayConfig[]) {
    const body = {
      searchViewConfigList: listMemberSearchConfig
    };
    this.memberStore.dispatch(
      ParticipantSearchConfigurationActions.setParticipantSearchConfiguration({
        body, entityId: this.entityId
      })
    );
    this.memberStore
      .pipe(select(fromMember.selectMemberNavigationState), take(1))
      .subscribe((navigationState) => {
        if (navigationState.query?.pageIndex) {
          this.store.dispatch(
            LayoutActions.closeTab({
              tabName: DisplayedTabName.SearchedParticipant,
            })
          );
        }
      });
  }
}
