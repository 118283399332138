import { createReducer, on } from '@ngrx/store';
import { BaseActionState } from '@ptg-shared/types/models';
import {
  BenefitOption,
  CheckStepConfigurationExistsResponse,
  GetAllowRemoveStepConfigurationResponse,
  GetCardsResponse,
  GetMemberEntitiesResponse,
  GetStepConfigurationResponse,
  Property,
  StepConfiguration,
} from '../../services/models';
import {
  checkAllowRemoveStepConfigurationSuccess,
  clearGetStepConfigurationStateAction,
  clearGetStepConfigurationsStateAction,
  clearSetStepConfigurationStateAction,
  clearStepConfigurationsActionStateAction,
  createStepConfigurationsFailureAction,
  createStepConfigurationsSuccessAction,
  getBenefitTypesAction,
  getBenefitTypesFailureAction,
  getBenefitTypesSuccessAction,
  getStepConfigurationAction,
  getStepConfigurationFailureAction,
  getStepConfigurationPropertiesFailureAction,
  getStepConfigurationPropertiesSuccessAction,
  getStepConfigurationSuccessAction,
  getStepConfigurationValueAction,
  getStepConfigurationValueSuccessAction,
  getStepConfigurationsAction,
  getStepConfigurationsFailureAction,
  getStepConfigurationsSuccessAction,
  removeStepConfigurationFailure,
  removeStepConfigurationSuccess,
  setStepConfigurationFailureAction,
  setStepConfigurationSuccessAction,
  clearCheckAllowRemoveStepConfigurationStateAction,
  clearBenefitTypeStateAction,
  getMemberEntitiesAction,
  getMemberEntitiesSuccessAction,
  getMemberEntitiesFailureAction,
  clearGetMemberEntitiesStateAction,
  getCardsAction,
  getCardsSuccessAction,
  getCardsFailureAction,
  clearGetCardsStateAction,
  checkStepConfigurationExistsAction,
  checkStepConfigurationExistsSuccessAction,
  checkStepConfigurationExistsFailureAction,
  clearCheckStepConfigurationExistsStateAction,
} from '../actions';
import { ACTION, STATE } from '@ptg-shared/constance';

export interface StepConfigurationState {
  getStepConfigurations?: BaseActionState<StepConfiguration[]>;
  actionState?: {
    action: string;
    state: string;
    isContinue?: boolean;
    stepConfigurationId?: string;
  };
  setStepConfiguration?: BaseActionState;
  getBenefitTypes?: BaseActionState<BenefitOption[]>;
  getStepConfiguration?: BaseActionState<GetStepConfigurationResponse>;
  getStepConfigurationProperties?: BaseActionState<Property[]>;
  removeStepConfiguration?: BaseActionState;
  allowRemoveStepConfiguration?: BaseActionState<GetAllowRemoveStepConfigurationResponse>;
  getMemberEntities?: BaseActionState<GetMemberEntitiesResponse>;
  getCards?: BaseActionState<GetCardsResponse>;
  checkStepConfigurationExists?: BaseActionState<CheckStepConfigurationExistsResponse>;
}

const initialState: StepConfigurationState = {};

export const stepConfigurationReducer = createReducer(
  initialState,
  on(getStepConfigurationsAction, (state) => ({
    ...state,
    getStepConfigurations: {
      isLoading: true,
    },
  })),
  on(getStepConfigurationsSuccessAction, (state, { response }) => ({
    ...state,
    getStepConfigurations: {
      isLoading: false,
      success: true,
      payload: response.stepConfigurations,
    },
  })),
  on(getStepConfigurationsFailureAction, (state, { error }) => ({
    ...state,
    getStepConfigurations: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetStepConfigurationsStateAction, (state) => ({
    ...state,
    getStepConfigurations: undefined,
  })),

  on(createStepConfigurationsSuccessAction, (state, { stepConfigurationId, isContinue }) => ({
    ...state,
    actionState: {
      action: ACTION.ADD,
      state: STATE.SUCCESS,
      isContinue,
      stepConfigurationId,
    },
  })),
  on(createStepConfigurationsFailureAction, (state, { error }) => ({
    ...state,
    actionState: {
      action: ACTION.ADD,
      state: STATE.FAIL,
    },
  })),

  on(clearStepConfigurationsActionStateAction, (state) => ({
    ...state,
    actionState: undefined,
  })),

  on(getBenefitTypesAction, (state) => ({
    ...state,
    getBenefitTypes: {
      isLoading: true,
    },
  })),
  on(getBenefitTypesSuccessAction, (state, { response }) => ({
    ...state,
    getBenefitTypes: {
      isLoading: false,
      success: true,
      payload: response?.benefitOptions,
    },
  })),
  on(getBenefitTypesFailureAction, (state, { error }) => ({
    ...state,
    getBenefitTypes: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearBenefitTypeStateAction, (state) => ({
    ...state,
    getBenefitTypes: undefined,
  })),

  on(getStepConfigurationAction, (state) => ({
    ...state,
    getStepConfiguration: {
      isLoading: true,
    },
  })),
  on(getStepConfigurationSuccessAction, (state, { response }) => ({
    ...state,
    getStepConfiguration: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getStepConfigurationFailureAction, (state, { error }) => ({
    ...state,
    getStepConfiguration: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetStepConfigurationStateAction, (state) => ({
    ...state,
    getStepConfiguration: undefined,
  })),

  on(setStepConfigurationSuccessAction, (state) => ({
    ...state,
    setStepConfiguration: {
      isLoading: false,
      success: true,
    },
  })),
  on(setStepConfigurationFailureAction, (state, { error }) => ({
    ...state,
    setStepConfiguration: {
      isLoading: false,
      success: false,
      error: error.error,
    },
  })),
  on(clearSetStepConfigurationStateAction, (state) => ({
    ...state,
    setStepConfiguration: undefined,
  })),

  on(getStepConfigurationPropertiesSuccessAction, (state, { response }) => ({
    ...state,
    getStepConfigurationProperties: {
      isLoading: false,
      success: true,
      payload: response.listSectionProperty,
    },
  })),
  on(getStepConfigurationPropertiesFailureAction, (state, { error }) => ({
    ...state,
    getStepConfigurationProperties: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),

  on(getStepConfigurationValueAction, (state) => ({
    ...state,
    getStepConfiguration: {
      isLoading: true,
    },
  })),

  on(getStepConfigurationValueSuccessAction, (state, { response }) => ({
    ...state,
    getStepConfiguration: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getStepConfigurationsFailureAction, (state, { error }) => ({
    ...state,
    getStepConfigurations: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),

  on(removeStepConfigurationSuccess, (state) => ({
    ...state,
    removeStepConfiguration: {
      isLoading: false,
      success: true,
    },
  })),
  on(removeStepConfigurationFailure, (state, { error }) => ({
    ...state,
    removeStepConfiguration: {
      isLoading: false,
      success: false,
      error: error.error,
    },
  })),
  on(checkAllowRemoveStepConfigurationSuccess, (state, { response }) => ({
    ...state,
    allowRemoveStepConfiguration: {
      isLoading: false,
      payload: response,
    },
  })),
  on(removeStepConfigurationFailure, (state, { error }) => ({
    ...state,
    allowRemoveStepConfiguration: {
      isLoading: false,
      success: false,
      error: error.error,
    },
  })),
  on(clearCheckAllowRemoveStepConfigurationStateAction, (state) => ({
    ...state,
    allowRemoveStepConfiguration: undefined,
  })),

  on(getMemberEntitiesAction, (state) => ({
    ...state,
    getMemberEntities: {
      isLoading: true,
    },
  })),
  on(getMemberEntitiesSuccessAction, (state, { response }) => ({
    ...state,
    getMemberEntities: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getMemberEntitiesFailureAction, (state, { error }) => ({
    ...state,
    getMemberEntities: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetMemberEntitiesStateAction, (state) => ({
    ...state,
    getMemberEntities: undefined,
  })),

  on(getCardsAction, (state) => ({
    ...state,
    getCards: {
      isLoading: true,
    },
  })),
  on(getCardsSuccessAction, (state, { response }) => ({
    ...state,
    getCards: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getCardsFailureAction, (state, { error }) => ({
    ...state,
    getCards: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetCardsStateAction, (state) => ({
    ...state,
    getCards: undefined,
  })),

  on(checkStepConfigurationExistsAction, (state) => ({
    ...state,
    checkStepConfigurationExists: {
      isLoading: true,
    },
  })),
  on(checkStepConfigurationExistsSuccessAction, (state, { response }) => ({
    ...state,
    checkStepConfigurationExists: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(checkStepConfigurationExistsFailureAction, (state, { error }) => ({
    ...state,
    checkStepConfigurationExists: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearCheckStepConfigurationExistsStateAction, (state) => ({
    ...state,
    checkStepConfigurationExists: undefined,
  })),
);
