import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import {
  CheckExistStatusBody,
  GetStatusQuery,
  MemberEvent,
  MemberStatusDetail,
  MemberStatusList,
} from '../types/models';

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  constructor(private httpClient: HttpClient) {}

  getMemberStatus(query: GetStatusQuery): Observable<MemberStatusList> {
    const params = new HttpParams({ fromObject: query as any });
    return this.httpClient.get<MemberStatusList>(
      `${environment.apiUrl}/Members/GetMemberStatus`,
      { params }
    );
  }

  createMemberStatus(statusDetail: MemberStatusDetail): Observable<void> {
    return this.httpClient.post<void>(
      `${environment.apiUrl}/Members/CreateMemberStatus`,
      statusDetail
    );
  }

  editMemberStatus(statusDetail: MemberStatusDetail): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/Members/SetMemberStatus`,
      statusDetail
    );
  }

  removeMemberStatus(statusId: string) {
    return this.httpClient.delete<void>(
      `${environment.apiUrl}/Members/RemoveMemberStatus/${statusId}`
    );
  }

  validateMemberStatus(body: any) {
    return this.httpClient.post(
      `${environment.apiUrl}/Members/ValidateMemberStatus`,
      body
    );
  }

  checkExistStatus = (body: CheckExistStatusBody) => {
    return this.httpClient.post(
      `${environment.apiUrl}/Members/MemberStatus/Exists`,
      body
    );
  };

  getStatusDetail(query: GetStatusQuery): Observable<MemberStatusDetail> {
    const params = new HttpParams({ fromObject: query as any });
    return this.httpClient.get<MemberStatusDetail>(
      `${environment.apiUrl}/MemberStatus/GetDetail`,
      { params }
    );
  }

  createEvent(eventDetail: MemberEvent): Observable<void> {
    return this.httpClient.post<void>(
      `${environment.apiUrl}/Members/CreateMemberEvent`,
      eventDetail
    );
  }

  editEvent(eventDetail: MemberEvent): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/Members/SetEvent`,
      eventDetail
    );
  }
  removeMemberEvent(eventId: string) {
    return this.httpClient.delete<void>(
      `${environment.apiUrl}/Members/RemoveMemberEVent/${eventId}`
    );
  }

  checkExistEvent = (
    body: CheckExistStatusBody
  ): Observable<{ exists: boolean; message: string }> => {
    return this.httpClient.post<{ exists: boolean; message: string }>(
      `${environment.apiUrl}/Members/MemberEvent/Exists`,
      body
    );
  };
}
