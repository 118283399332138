import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { interval, Subject, Subscription } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { DateTime } from 'luxon';

import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { AzureStorageService } from '@ptg-shared/services/azure-storage.service';

import * as fromReducer from '../../../reducers/index';
import { BulkUpdateService } from '../../services/bulk-update.service';

@Component({
  selector: 'ptg-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent implements OnInit, OnDestroy {
  isUploading?: boolean;
  fileUpload: FormControl = new FormControl();
  supportedFileTypes: string;
  checkPattern: RegExp;
  acceptFile = '';
  bannerType: BannerType = BannerType.Hidden;
  message =
    'There are other validated import sessions in this section (or in the Mixed Bulk Upload/Add screen), please consider finishing/canceling them before importing another file.';
  isFetching: boolean = false;
  pollInterval?: Subscription;
  unsubscribe$ = new Subject<void>();
  listUniqueKey: any[] = [];
  activeFund: any;
  uniqueKey = new FormControl('');

  constructor(
    private bulkUpdateService: BulkUpdateService,
    private store: Store<fromReducer.State>,
    private azureStorageService: AzureStorageService,
    private dialogRef: MatDialogRef<UploadFileComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      name: string;
      target: string;
      targetType: string;
      fileTypes: string[];
      listNoun: string[];
    }
  ) {
    this.checkPattern = new RegExp(`^[\\x00-\\x7F]+\\.(${data.fileTypes.join('|')})$`, 'i');
    this.supportedFileTypes = data.fileTypes.reduce((pV, cV, i) => {
      if (i === 0) {
        return cV;
      } else if (i === data.fileTypes.length - 1) {
        return pV + ' or ' + cV;
      }
      return pV + ', ' + cV;
    }, '') + ' file format';
    this.acceptFile = `.${ data.fileTypes.join(', .') }`;
  }

  ngOnInit(): void {
    this.store
      .pipe(select(fromReducer.selectCurrentFundState))
      .subscribe((el) => {
        this.activeFund = el;
      });

    if (this.data.target === 'Metadata') {
      this.bulkUpdateService
        .getUniqueKey(this.data.targetType)
        .subscribe((res: any) => {
          this.listUniqueKey = res ? this.createList(res) : [];
        });
    }

    this.pollInterval = interval(2500)
      .pipe(
        startWith(0),
        filter(() => !this.isFetching),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => this.checkValidateExistsImport());
  }

  createList(arr: any[]) {
    return arr.map((item) => ({
      displayValue: item?.propertyName,
      value: item?.sectionKey + '_' + item?.propertyKey,
      valueDescription: item?.sectionName,
    }));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onNoClick() {
    this.dialogRef.close('canceled');
  }

  uploadFile(event: File): void {
    this.uniqueKey.markAllAsTouched();
    if (this.uniqueKey.valid || this.data.target === 'AppContent') {
      this.isUploading = true;
      var providedFileName = event.name;
      if (providedFileName.indexOf('___') >= 0) {
        var fileParts = providedFileName.split('___');
        providedFileName = fileParts.join('_');
      }
      var blobName = `${
        this.activeFund?.key
      }/${this.data.target.toLowerCase()}/${this.data.targetType.toLowerCase()}/${
        this.uniqueKey.value || 'propertyKey'
      }/${this.formatDate(
        new Date().toString()
      )}___${this.formatTimeZoneFile()}___${providedFileName}`;
      this.azureStorageService.uploadBlob(event, blobName).then(() => {
        setTimeout(() => {
          this.isUploading = false;
          this.dialogRef.close('uploaded');
        }, 750);
      });
    }
  }

  checkValidateExistsImport() {
    this.bulkUpdateService
      .validateExistsImport(this.data.target, this.data.targetType)
      .subscribe((response) => {
        if (response.exists) {
          this.bannerType = BannerType.Warning;
        } else {
          this.closeBanner();
        }
      });
  }

  closeBanner() {
    this.bannerType = BannerType.Hidden;
  }

  private formatDate(date: string): string {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear().toString().substr(2),
      hour = '' + d.getHours(),
      min = '' + d.getMinutes(),
      sec = '' + d.getSeconds();

    return `${year}${month.padStart(2, '0')}${day.padStart(
      2,
      '0'
    )}${hour.padStart(2, '0')}${min.padStart(2, '0')}${sec.padStart(2, '0')}`;
  }
  private formatTimeZoneFile(): string {
    return this.data.target === 'Metadata'
      ? DateTime.now().toFormat('ZZZ')
      : '';
  }
}
