import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { UploadComponent } from 'src/app/shared/controls/upload/upload.component';

@Component({
  selector: 'ptg-upload-popup',
  templateUrl: './upload-popup.component.html',
  styleUrls: ['./upload-popup.component.scss']
})
export class UploadPopupComponent implements OnInit, AfterViewInit {
  acceptDocument = this.data?.acceptDocument ?? 'application/pdf';
  typeFileDocument = this.data?.typeFileDocument ?? 'pdf file format';
  checkPatternDocument = new RegExp(this.data?.checkPatternDocument ?? /^[\x00-\x7F]+\.(pdf)$/, 'i');
  file: any;

  @ViewChild('fileDocument')
  private fileDocument!: UploadComponent;

  constructor(
    public dialogRef: MatDialogRef<UploadPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private switchConfirmPopupService: SwitchConfirmPopupService
  ) { }

  ngOnInit(): void {
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.fileDocument.controlField.setValue(this.data.fileName)
    });
  }

  onSave() {
    if (!this.fileDocument.hasError) {
      if (this.file) {
        this.dialogRef.close({file: this.file});
      } else if (this.fileDocument.controlField.value) {
        this.dialogRef.close();
      } else {
        this.dialogRef.close({file: null});
      }
    }
  }

  onCancel() {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }

  uploadFile(event: any) {
    this.file = event;
  }
}
