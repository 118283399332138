import { createAction, props } from '@ngrx/store';

import { CreateNoteRequest, GetNotesRequest, GetNotesResponse } from '../models/employer-note.model';

export const getNotesAction = createAction(
  '[GetNotes/API] Send Request',
  props<{ request: GetNotesRequest; employerId: string }>(),
);

export const getNotesSuccessAction = createAction('[GetNotes/API] Success', props<{ response: GetNotesResponse }>());

export const getNotesFailureAction = createAction('[GetNotes/API] Failure', props<{ error?: any }>());

export const clearGetNotesStateAction = createAction('[GetNotes] Clear');

export const createNoteAction = createAction(
  '[CreateNote/API] Send Request',
  props<{ request: CreateNoteRequest; employerId: string; isManageNote: boolean }>(),
);

export const createNoteSuccessAction = createAction('[CreateNote/API] Success', props<{ isManageNote: boolean }>());

export const createNoteFailureAction = createAction('[CreateNote/API] Failure', props<{ error?: any }>());

export const clearCreateNoteStateAction = createAction('[CreateNote] Clear');
