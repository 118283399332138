<div class="dialog-container">
    <div class="header-title">
        {{ data.memberNavigationItem ? 'Edit' : 'Add' }} Menu Item
    </div>
    <form class="edit-form" [formGroup]="editForm">
      <div class="input-container">
        <ptg-select
            placeholder="Metadata Section"
            [controlField]="editForm.get('itemKey')"
            [listData]="listOption"
            errorRequire="Metadata Section is required."
            [isOptionObj]="true"
            [isSetMaxWidthSelect]="true"
        ></ptg-select>
        <ptg-textbox
          [controlField]="editForm.get('name')"
          placeholder="Menu Item Name"
          [hasLabel]="true"
          [isRequired]="true"
          [maxLength]="100"
          errorAsync="Menu Item Name already exists."
        ></ptg-textbox>
      </div>
      <div class="row-button">
        <button mat-raised-button type="button" class="primary" (click)="formSubmit$.next(true)">{{ data.memberNavigationItem ? 'Save' : 'Add' }}</button>
        <button mat-raised-button type="button" class="cancel-button" (click)="onCancel()">Cancel</button>
      </div>
    </form>
</div>
