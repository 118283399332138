import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { RemoveOverviewDocumentService } from '../../services';
import {
  removeOverviewDocumentAction,
  removeRemoveOverviewDocumentFailureAction,
  removeRemoveOverviewDocumentSuccessAction,
} from '../actions';

@Injectable()
export class RemoveOverviewDocumentEffects {
  constructor(
    private actions$: Actions,
    private removeOverviewDocumentService: RemoveOverviewDocumentService,
  ) {}

  removeRemoveOverviewDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeOverviewDocumentAction),
      switchMap(({ id }) =>
        this.removeOverviewDocumentService.removeRemoveOverviewDocument(id).pipe(
          map(() => removeRemoveOverviewDocumentSuccessAction()),
          catchError((error) => of(removeRemoveOverviewDocumentFailureAction({ error }))),
        ),
      ),
    ),
  );
}
