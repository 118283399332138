import { Component, ContentChild, Input } from '@angular/core';

import { DialogBody, DialogContentHeader, DialogFooter } from './directives';

@Component({
  selector: 'ptg-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  @Input() title!: string;
  @Input() footerPosition: 'top' | 'bottom' = 'bottom';
  @Input() footerAlign: 'left' | 'right' = 'left';
  @Input() isFullScreen: boolean = false;

  @ContentChild(DialogBody) dialogBody?: DialogBody;
  @ContentChild(DialogFooter) dialogFooter?: DialogFooter;
  @ContentChild(DialogContentHeader) dialogContentHeader?: DialogContentHeader;
}
