import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { RetirementBenefitDetailUploadDocumentService } from '../../services';
import {
  createRetirementBenefitDetailUploadDocumentAction,
  createRetirementBenefitDetailUploadDocumentFailureAction,
  createRetirementBenefitDetailUploadDocumentSuccessAction,
} from '../actions';

@Injectable()
export class RetirementBenefitDetailUploadDocumentEffects {
  constructor(
    private actions$: Actions,
    private retirementBenefitDetailUploadDocumentService: RetirementBenefitDetailUploadDocumentService
  ) {}

  createRetirementBenefitDetailUploadDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createRetirementBenefitDetailUploadDocumentAction),
      switchMap(({ request }) =>
        this.retirementBenefitDetailUploadDocumentService.createRetirementBenefitDetailUploadDocument(request).pipe(
          map(() => createRetirementBenefitDetailUploadDocumentSuccessAction()),
          catchError((error) => of(createRetirementBenefitDetailUploadDocumentFailureAction({ error })))
        )
      )
    )
  );
}
