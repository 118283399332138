import { createAction, props } from '@ngrx/store';

import { ParticipantRelationship } from '../../types/models';

export const GetParticipantRelationshipRequest =
  '[GetParticipantRelationship/API] Send Request';
export const GetParticipantRelationshipSuccess =
  '[GetParticipantRelationship/API] Success';
export const GetParticipantRelationshipFailure =
  '[GetParticipantRelationship/API] Failure';

export const getParticipantRelationship = createAction(
  GetParticipantRelationshipRequest,
  props<{ memberId: string, screenId: string }>()
);
export const getParticipantRelationshipSuccess = createAction(
  GetParticipantRelationshipSuccess,
  props<{ participantRelationships: ParticipantRelationship[] }>()
);
export const getParticipantRelationshipFailure = createAction(
  GetParticipantRelationshipFailure,
  props<{ errorMsg: string }>()
);
