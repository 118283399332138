<div class="edit-display-format-container">
  <ptg-title-bar
    [name]="dataDetail?.data ? 'Edit Screen' : 'Add New Screen'"
    [onlyTitle]="true"
  ></ptg-title-bar>

  <form [formGroup]="editForm">
    <div class="wrap-btn">
      <button
        mat-raised-button
        type="submit"
        class="submit-button"
        (click)="submit()"
      >
        Save
      </button>
      <button
        mat-raised-button
        type="button"
        class="cancel-button"
        (click)="onCancel()"
      >
        Cancel
      </button>
    </div>
    <ng-container formArrayName="metadataLookupTableDisplayFormats">
      <ng-container
        *ngFor="
          let item of metadataLookupTableDisplayFormats.controls;
          let i = index
        "
      >
        <div class="form-control-container" [formGroupName]="i">
          <div class="col-item flex" [class.action-edit]="dataDetail?.data" [class.style-item]="i">
            <ptg-textbox
              *ngIf="!dataDetail?.data"
                placeholder="Screen ID"
                errorAsync="Screen ID already exists."
                [controlField]="item.get('screenId')"
                [hasLabel]="true"
                [maxLength]="250"
                [isRequired]="true"
            ></ptg-textbox>
            <ptg-textbox
              placeholder="Screen Name"
              errorAsync="Screen Name already exists."
              [controlField]="item.get('screenName')"
              [hasLabel]="true"
              [isRequired]="true"
              [maxLength]="250"
            ></ptg-textbox>
            <ptg-button
              *ngIf="metadataLookupTableDisplayFormats.controls.length > 1"
              (clickButton)="deleteCondition(i)"
              buttonName=""
              class="clear-style-btn"
              classInput="delete-button"
              [class.hidden-remove]="!i"
            >
              <mat-icon>delete_forever</mat-icon>
            </ptg-button>
          </div>
          <div class="col-item-toggle flex">
            <ptg-toggle-button
              [controlField]="item.get('showId')"
              label="ID"
              (valueChange)="onMappingFieldChanged($event)"
            ></ptg-toggle-button>
            <ptg-toggle-button
              [controlField]="item.get('showDescription')"
              label="Short Description"
              (valueChange)="onMappingFieldChanged($event)"
            ></ptg-toggle-button>
            <ptg-toggle-button
              [controlField]="item.get('showLongDescription')"
              label="Long Description"
              (valueChange)="onMappingFieldChanged($event)"
            ></ptg-toggle-button>
            <mat-error *ngIf="item.get('showId')?.errors" class="message-error">
              At least one toggle must be on.
            </mat-error>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <button
      mat-raised-button
      type="button"
      class="add-condition"
      (click)="addNewScreen()"
      *ngIf="!dataDetail?.data"
      [class.hidden-button]="metadataLookupTableDisplayFormats?.invalid"
    >
      <mat-icon>add</mat-icon>
      Add New Screen
    </button>
    <ng-container *ngIf="dataDetail?.data">
      <p class="screen-name">Screen ID</p>
      <p class="screen-value">{{dataDetail?.data.screenId}}</p>
    </ng-container>
  </form>
</div>
