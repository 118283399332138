import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { takeUntil, filter } from 'rxjs/operators';

import {
  CalculationState,
  getSubHeaderConfigurationDetailSelector,
  getSubHeaderConfigurationDetailAction,
  setSubHeaderConfigurationDetailSelector,
  setSubHeaderConfigurationDetailAction,
  clearGetSubHeaderConfigurationDetailStateAction,
  clearSetSubHeaderConfigurationDetailStateAction,
} from '../../store';
import { Store, select } from '@ngrx/store';

import { BaseComponent } from '@ptg-shared/components';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { capitalizeFirstLetterOfEachWord } from '@ptg-shared/utils/string.util';
import { CalculationDetailOption, MenuItem, MenuItemsList } from '../../services/models/subheader-configuration.model';
import { CANCEL_CONFIRM_MESSAGE } from '@ptg-shared/constance/value.const';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { showBanner } from '@ptg-shared/utils/common.util';

@Component({
  selector: 'ptg-edit-subheader-configuration',
  templateUrl: './edit-subheader-configuration.component.html',
  styleUrls: ['./edit-subheader-configuration.component.scss'],
})
export class EditSubHeaderConfigurationComponent extends BaseComponent {
  title = '';
  memberId: string = '';
  
  listBreadcrumbs: Breadcrumb[] = [];

  subHeaderId: string = '';
  description = '';
  menuItemsList: MenuItemsList[] = [];
  calculationDetailOption: CalculationDetailOption[] = [];
  chipList: MenuItem[] = [];

  isSpouseAge: boolean = false;

  isLoading: boolean = true;

  currentSelectedEntityId: string = '';
  currentSelectedPropertyId: string = '';
  currentSelectedValueId: string = '';

  bannerType: BannerType = BannerType.Hidden;
  message: string = '';

  constructor(
    public readonly dialog: MatDialog,
    public readonly route: ActivatedRoute,
    public readonly router: Router,
    public readonly dialogRef: MatDialogRef<EditSubHeaderConfigurationComponent>,

    private readonly store: Store<CalculationState>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getSnapshotRouteData();
    this.registerGetSubHeaderConfigurationDetailSelector();
    this.registerSetSubHeaderConfigurationDetailSelector();

    this.getSubHeaderConfigurationDetail();
  }

  getSnapshotRouteData(): void {
    const params = this.route.snapshot.params;

    if (params?.memberId) {
      this.memberId = params.memberId;
    }

    if (params?.subHeaderId) {
      this.subHeaderId = params.subHeaderId;
    }
  }

  registerGetSubHeaderConfigurationDetailSelector(): void {
    this.store
      .pipe(
        select(getSubHeaderConfigurationDetailSelector),
        filter((res) => !res?.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.isLoading = false;
        if (!response?.payload) {
          return;
        }
        const subHeaderName = response?.payload?.subHeader?.name ?? 'Edit Sub-header Configuration';
        this.isSpouseAge = response?.payload?.subHeader?.name === 'Spouse Age';
        this.title = capitalizeFirstLetterOfEachWord(subHeaderName);
        this.listBreadcrumbs = this.getBreadcrumbs;
        this.description = response?.payload?.subHeader?.description ?? '';
        this.menuItemsList = response?.payload?.menuItemsList;
        this.calculationDetailOption = response?.payload?.calculationDetailOption;
        this.currentSelectedEntityId = response?.payload?.subHeader?.calculationDetail?.memberListId;
        this.currentSelectedPropertyId = response?.payload?.subHeader?.calculationDetail?.propertyId;
        this.currentSelectedValueId = response?.payload?.subHeader?.calculationDetail?.lookUpId;
        this.chipList = response?.payload?.subHeader?.menuItems;
      });
  }

  registerSetSubHeaderConfigurationDetailSelector(): void {
    this.store
      .pipe(
        select(setSubHeaderConfigurationDetailSelector),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        if (response) {
          this.isLoading = false;
          showBanner.call(this, response?.state?.state, this.title, response?.state?.action);
          this.store.dispatch(clearSetSubHeaderConfigurationDetailStateAction());
          this.getSubHeaderConfigurationDetail();
        }
      });
  }

  getSubHeaderConfigurationDetail(): void {
    this.isLoading = true;
    this.store.dispatch(getSubHeaderConfigurationDetailAction({
      memberId: this.memberId,
      subHeaderId: this.subHeaderId,
    }));
  }

  handleClickSaveBtn(request: any): void {
    this.isLoading = true;
    this.store.dispatch(
      setSubHeaderConfigurationDetailAction({
        memberId: this.memberId,
        subHeaderId: this.subHeaderId,
        request,
      }),
    );
  }

  handleClickCancelBtn(): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: CANCEL_CONFIRM_MESSAGE,
        type: ConfirmType.Cancel,
        title: 'Cancel Action',
        cancelButtonTitle: 'Back',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.store.dispatch(clearGetSubHeaderConfigurationDetailStateAction());
        this.router.navigateByUrl(`/member/calculation/subheader-configuration/${this.memberId}`);
      }
    });
  }

  private get getBreadcrumbs(): Breadcrumb[] {
    return [
      {
        name: 'Sub-header Component Configuration',
        url: `/member/calculation/subheader-configuration/${this.memberId}`,
      },
      { name: this.title },
    ];
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store.dispatch(clearGetSubHeaderConfigurationDetailStateAction());
  }
}
