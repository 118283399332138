<mat-slide-toggle
  *ngIf="controlField; else noFormControl"
  [formControl]="controlField"
  [disabled]="disabled"
  (change)="onToggle($event)"
>
  <ng-container *ngTemplateOutlet="toggleContent"> </ng-container>
</mat-slide-toggle>

<ng-template #noFormControl>
  <mat-slide-toggle
    [disabled]="disabled"
    [checked]="value"
    (change)="onToggle($event)"
  >
    <ng-container *ngTemplateOutlet="toggleContent"> </ng-container>
  </mat-slide-toggle>
</ng-template>

<ng-template #toggleContent>
  <span
    *ngIf="!single; else singleControl"
    [ngClass]="{ 'include-child': isIncludeChild && controlField?.value }"
  >
    {{ label }}
  </span>
  <ng-template #singleControl>
    <span [ngClass]="{ 'inactive-text': !controlField?.value }">
      {{ displayLabel ? label : controlField?.value ? "Active" : "Inactive" }}
    </span>
  </ng-template>
</ng-template>
