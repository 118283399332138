import { createAction, props } from '@ngrx/store';
import { EmployerList, GetListEmployerQuery } from 'src/app/employer/models/employer.model';

export const SetEmployerIndex = '[SetEmployerIndex] Set';
export const GetMoreEmployersRequest = '[GetMoreEmployersRequest/API] Send Request';
export const GetMoreEmployersSuccess = '[GetMoreEmployersSuccess/API] Success';
export const SetEmployers = '[SetEmployers/API] Set';
export const SetQueryEmployer = '[SetQueryEmployer/API] Set';
export const ClearEmployerNavigationState = '[ClearEmployerNavigationState] Clear';


export const setEmployerIndex = createAction(
  SetEmployerIndex,
  props<{ employerIndex: number }>()
);
export const getMoreEmployers = createAction(
  GetMoreEmployersRequest,
  props<{ query: GetListEmployerQuery, idClient: string, isAppend: boolean }>()
);
export const getMoreEmployersSuccess = createAction(
  GetMoreEmployersSuccess,
  props<{ employerList: EmployerList, isAppend: boolean }>()
);
export const setQueryEmployer = createAction(
  SetQueryEmployer,
  props<{ query: GetListEmployerQuery }>()
);
export const setEmployers = createAction(
  SetEmployers,
  props<{ employerList: EmployerList, pageSize: number }>()
);
export const clearEmployerNavigationState = createAction(
  ClearEmployerNavigationState,
);