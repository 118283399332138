import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CardProperty } from '@ptg-entity-management/services/models';

import { EntityPropertyType } from '@ptg-entity-management/types/enums';
import { NON_VALUE_PROPERTY_DISPLAY_TYPE, PROPERTY_DISPLAY_PREFERENCE_TYPE } from '@ptg-member/types/enums';

@Component({
  selector: 'ptg-member-summary-card-table',
  templateUrl: './member-summary-card-table.component.html',
  styleUrls: ['./member-summary-card-table.component.scss'],
})
export class MemberSummaryCardTableComponent implements OnInit, OnChanges {
  readonly PROPERTY_DISPLAY_PREFERENCE_TYPE = PROPERTY_DISPLAY_PREFERENCE_TYPE;
  readonly NON_VALUE_PROPERTY_DISPLAY_TYPE = NON_VALUE_PROPERTY_DISPLAY_TYPE;
  readonly EntityPropertyType = EntityPropertyType;

  @Input() propertiesConfig: CardProperty[] = [];
  @Input() cardData: any;
  @Input() configDataless: any;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.propertiesConfig.forEach(property => {
      if (property?.type === EntityPropertyType.Decimal || property?.type === EntityPropertyType.Currency || property?.type === EntityPropertyType.Percentage) {
        let fractionalLength = property?.config?.fractionalLengthInput;
        if (!fractionalLength && property?.config?.calcucation) {
          let configCalculator = JSON.parse(property?.config?.calcucation);
          fractionalLength = configCalculator?.fractionalLength;
          property.config.fractionalLengthInput = !fractionalLength && fractionalLength !== 0 ? 2 : fractionalLength;
        }
      }
    });
  }

  ngOnInit(): void {}

  onClickIcon(property: CardProperty) {
    if (property.isMasked) {
      property.value = property.valueMask.originalValue;
      property.isMasked = false;
    } else {
      property.value = property.valueMask.maskedValue;
      property.isMasked = true;
    }
  }
}
