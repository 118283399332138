import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '@ptg-shared/shared.module';
import { ControlsModule } from '@ptg-shared/controls/controls.module';
import { MODULE_KEY } from './constants';
import { OverviewHeaderModule } from '@ptg-member/components/overview-header/overview-header.module';
import { BuyBackMakeUpComponent } from './pages/buyback-makeup/buyback-makeup.component';
import { AddEditBuyBackMakeUpDialogComponent } from './components/add-edit-buyback-makeup-dialog/add-edit-buyback-makeup-dialog.component';
import { BuyBackMakeUpService } from './services/buyback-makeup.service';
import { PurchaseServiceEffect, purchaseServiceReducer } from './store';
import { OverviewHeaderEntityModule } from '@ptg-member/components/overview-header-entity/overview-header-entity.module';
import { EditBuyBackMakeUpInterestFeesComponent } from './components/edit-buyback-makeup-interest-fee/edit-buyback-makeup-interest-fees.component';
import { SubHeaderModule } from '@ptg-member/components/sub-header/sub-header.component.module';

const COMPONENTS = [
  BuyBackMakeUpComponent,
  AddEditBuyBackMakeUpDialogComponent,
  EditBuyBackMakeUpInterestFeesComponent,
];

const SERVICES = [
  BuyBackMakeUpService,
];

@NgModule({
  declarations: [...COMPONENTS],
  providers: [...SERVICES],
  imports: [
    SharedModule,
    ControlsModule,
    StoreModule.forFeature(MODULE_KEY, purchaseServiceReducer),
    EffectsModule.forFeature(PurchaseServiceEffect),
    OverviewHeaderModule,
    OverviewHeaderEntityModule,
    SubHeaderModule,
  ],
})
export class PurchaseServicesModule {}
