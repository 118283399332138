<ptg-breadcrumb [listBreadcrumbs]="listBreadcrumbs"> </ptg-breadcrumb>
<div class="add-parameter-mapping" id="add-parameter-mapping">
  <form [formGroup]="formAddParameterMapping" class="add-parameter-mapping-form">
    <div class="wrap-btn">
      <button mat-raised-button type="button" class="btn-save" id="btn-save" (click)="formSubmit$.next()">Save</button>
      <button mat-raised-button type="button" class="btn-cancel" id="btn-cancel" (click)="onCancel()">Cancel</button>
    </div>
    <div class="input-container">
      <ptg-select
        [controlField]="masterParameterIdControl"
        [placeholder]="data?.type === ParameterType.Input ? 'Input Name' : 'Output Name'"
        [isOptionObj]="true"
        [isRequired]="true"
        [isSetMaxWidthSelect]="true"
        [listData]="listMasterParameter"
        (changeOptionValue)="onChangeParameterType()"
        customError="existsMessage"
      ></ptg-select>
      <ptg-select
        [controlField]="sheetNameControl"
        placeholder="Sheet"
        [isRequired]="true"
        [isSetMaxWidthSelect]="true"
        [listData]="listSheetName"
        [isCheckChange]="true"
        (changeOptionValue)="onChangeSheetNameValue()"
      ></ptg-select>
      <ptg-select
        [controlField]="fieldNameControl"
        [isDisabledSelect]="!sheetNameControl.value"
        placeholder="Mapping Field"
        [isRequired]="true"
        [isSetMaxWidthSelect]="true"
        [listData]="listFieldName"
        [isCheckChange]="true"
        (changeOptionValue)="onChangeFieldNameValue()"
      ></ptg-select>
      <ptg-select
        [controlField]="savePropertyControl"
        placeholder="Save to Property"
        [isSetMaxWidthSelect]="true"
        [listData]="listSaveProperty"
        [isCheckChange]="true"
        [isOptionObj]="true"
        (changeOptionValue)="onChangeFieldNameValue()"
      ></ptg-select>
    </div>
  </form>
</div>
