import { Component, Input } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { Align, Column, ColumnType, Row } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { BaseComponent } from '@ptg-shared/components';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { MemberDetailActions } from '@ptg-member/store/actions';
import * as fromMember from '@ptg-member/store/reducers';
import { SortType } from '@ptg-shared/constance';
import { getListPropertyForCardsSelector } from '@ptg-member/store/reducers';
import { PropertyType } from '@ptg-member/constance/metadataPropertyType.const';
import { getColumConfig, getEntityPropertyName, getEntityValue } from '@ptg-member/helper';

import { EntityPropertyType } from '../../types/enums';
import { CardComponent, CardProperty, EntityViewItem, GetListPropertyForCardsRequest } from '@ptg-entity-management/services/models';

@Component({
  selector: 'ptg-entity-summary-card-list',
  templateUrl: './entity-summary-card-list.component.html',
  styleUrls: ['./entity-summary-card-list.component.scss'],
})
export class EntitySummaryCardListComponent extends BaseComponent {
  sortInfo?: Sort;
  currentRowIndex: number = 0;
  columns: Column[] = [];
  lengthPg: number = 0;
  pageSize: number = 10;
  pageNumber: number = FIRST_PAGE;
  dataCardList: any;
  dataCardNoneList: any;

  @Input() entityId!: string;
  @Input() targetId!: string;
  @Input() screenId?: string;
  @Input() card!: EntityViewItem;
  @Input() component!: CardComponent;

  constructor(
    public dialog: MatDialog,
    private memberStore: Store<fromMember.MemberState>,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.memberStore.pipe(select(getListPropertyForCardsSelector), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state?.isLoading || !state?.success || !state?.payload) return;

      const listDataMappingId: any = this.component.cardProperties.map((prop) => ({
        id: this._getColumnName(prop.propertyId, prop.entityReferencePropertyId, prop.option),
        type: prop.type,
        config: prop.config,
      }));
      this.columns = this._mapProppertiesToColumns(this.component.cardProperties);
      this.lengthPg = state.total ?? 0;
      this.dataCardList = state.payload.map((item, indexRow: number) => {
        const obj: any = {};
        item?.values?.forEach((col: any) => {
          const columnName = this._getColumnName(col.entityPropertyId, col.entityReferencePropertyId, col.option);

          const options = listDataMappingId?.find((item: any) => item.id === columnName);
          obj[columnName] = getEntityValue(col, options);
          if (col?.recordLinkedId) {
            obj['recordLinkedId'] = col?.recordLinkedId;
          }
          if (col?.propertyType === EntityPropertyType['Entity Reference']) {
            obj[`${columnName}_recordId`] = col.recordId;
          }
          if (col?.isExistProfile != undefined) {
            obj[`${columnName}_isExistProfile`] = col.isExistProfile;
          }
          if (col?.entityReferenceLinkedId) {
            obj['entityReferenceLinkedId'] = col?.entityReferenceLinkedId;
          }
          if (col?.options) {
            obj['options'] = col?.options;
          }
          obj['order'] = indexRow;
        });
        return { ...obj, id: item.id, noOfAttachments: item?.noOfAttachments };
      });
    });
    this.getDataList();
  }

  private getDataList(): void {
    let request: GetListPropertyForCardsRequest = {
      entityId: this.entityId,
      targetId: this.targetId,
      cardId: this.card.cardId,
      isSummary: true,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      screenId: this.screenId,
    };

    if (this.sortInfo) {
      const field = this.sortInfo.active[0].toUpperCase() + this.sortInfo.active.substr(1);
      request = {
        ...request,
        sortNames: this.sortInfo.direction ? field : '',
        sortType: this.sortInfo.direction === 'asc' ? SortType.ASC : SortType.DESC,
      };
    }

    this.memberStore.dispatch(MemberDetailActions.getListPropertyForCardsAction({ request }));
  }

  private _mapProppertiesToColumns(properties: CardProperty[]): Column[] {
    return properties?.map((prop) => {
      const columConfig = getColumConfig(getEntityPropertyName(prop.type), prop.config);

      return {
        name: this._getColumnName(prop.propertyId, prop.entityReferencePropertyId, prop.option),
        header: {
          title: prop.propertyLabel,
        },
        truncate: true,
        sortable: !this.card.isDragDrop,
        type: [
          ColumnType.Address,
          ColumnType.PersonName,
          ColumnType.Binary,
        ].includes(columConfig.type as ColumnType) ? ColumnType.Text : columConfig.type,
        align: prop.type === PropertyType.TYPE_REGISTER ? Align.Center : undefined,
        templateArgs:
          columConfig.type === ColumnType.EntityReference
            ? {
                onClick: (row: any & Row) => {
                  // this._onClickEntityReference(row, prop);
                },
              }
            : columConfig.templateArgs,
      } as Column;
    });
  }

  private _getColumnName(propertyId: string, entityReferencePropertyId: string, option: string): string {
    let columnName = propertyId;
    if (entityReferencePropertyId) {
      columnName = columnName + '_' + entityReferencePropertyId;
    }
    if (option) {
      columnName = columnName + '_' + option;
    }
    return columnName;
  }

  // private _onClickEntityReference(row: any & Row, column: CardProperty) {
  //   if (this.card.isBenefitCard) {
  //     if (row.entityReferenceLinkedId) {
  //       this.entityViewStore.dispatch(getEntityViewsByEntityAction({ entityId: row.entityReferenceLinkedId }));
  //     }
  //   } else {
  //     const propertyName = this._buildPropertyName(column);
  //     const recordId = row[`${propertyName}_recordId`];
  //     const isExistProfile = row[`${propertyName}_isExistProfile`];

  //     if (isExistProfile === false) {
  //       return;
  //     }

  //     this.store
  //       .pipe(select(fromLayoutReducer.selectProfileNavigationState), takeUntil(this.unsubscribe$))
  //       .subscribe((state) => {
  //         if (state?.menu && state?.menu.length > 0 && recordId) {
  //           const overviewView = state.memberNavigationList as any;
  //           let url = '';
  //           if ((state.memberNavigationList as any)?.isOverviewDetailView) {
  //             url = `/member/detail-view/true/${overviewView.id}/${overviewView.overviewViewId}/${recordId}?${STRING_QUERY_PARAM.PROFILE_NAVIGATE}`;
  //           } else {
  //             url = `/member/summary-view/true/${overviewView.id}/${overviewView.overviewViewId}/${recordId}?${STRING_QUERY_PARAM.PROFILE_NAVIGATE}`;
  //           }

  //           this.store.dispatch(LayoutActions.clearProfileNavigationItemState());
  //           this.store.dispatch(
  //             LayoutActions.selectTab({
  //               tab: DisplayedTabName.IndividualParticipant,
  //               url,
  //             }),
  //           );
  //         }
  //       });
  //   }
  // }

  // private _buildPropertyName(prop: CardProperties) {
  //   let propertyName = '';
  //   if (prop.entityReferencePropertyId) {
  //     propertyName += prop.entityReferencePropertyId;
  //   }
  //   if (prop.propertyId) {
  //     if (propertyName) {
  //       propertyName += '_';
  //     }
  //     propertyName += prop.propertyId;
  //   }
  //   if (prop.option) {
  //     if (propertyName) {
  //       propertyName += '_';
  //     }
  //     propertyName += prop.option;
  //   }
  //   return propertyName;
  // }

  // private _getPropertyValue(type: EntityPropertyType, value: any, option: string, options: any) {
  //   if (type === EntityPropertyType.Status) {
  //     return this._getStatusValue(value, option, options);
  //   } else if (type === EntityPropertyType.Address || type === EntityPropertyType['Person Name']) {
  //     if (value && typeof value === 'object' && Object.values(value).every((item) => item === null)) return null;
  //     return value;
  //   } else if (value?.maskedValue || value?.maskedValue === null) {
  //     return value?.maskedValue;
  //   } else if (value && type === EntityPropertyType['Date Time']) {
  //     return getDateString(value);
  //   }
  //   return value;
  // }

  onSortChange(event: Sort): void {}

  onChangePage(event: PageEvent): void {}

  onRowClick(row: any & Row): void {
    if (this.card?.isExistDetailConfig || this.card?.viewFixType != null) {
      // this.memberStore
      //   .pipe(select(fromLayoutReducer.selectProfileNavigationState), takeUntil(this.unsubscribe$))
      //   .subscribe((state) => {
      //     if (state.isReloading && state.memberId) {
      //       const allMenuItems = this.menus.reduce((acc: MenuItem[], menu: Menu) => {
      //         return [...acc, ...(menu?.menuItems ?? [])];
      //       }, []);
      //       const menuSameCard = allMenuItems.find(
      //         (menuitem) =>
      //           menuitem.cardId?.includes(this.card.cardId) ||
      //           (menuitem.viewFixType !== null && menuitem.viewFixType === this.card.viewFixType),
      //       );
      //       if (menuSameCard) {
      //         const url = `${menuSameCard.routerLink
      //           .split('/')
      //           .concat([state.memberId])
      //           .filter((x) => x)
      //           .join('/')}?${STRING_QUERY_PARAM.PROFILE_NAVIGATE}&currentRowIndex=${row?.order}`;
      //         this.router.navigateByUrl(url);
      //       } else if (this.card.cardId) {
      //         this.entityManagementStore.dispatch(getEntityViewsByCardAction({ cardId: this.card.cardId }));
      //       }
      //     }
      //   });
    } else {
      this.dialog.open(ConfirmPopupComponent, {
        panelClass: 'confirm-popup',
        data: {
          title: 'Warning',
          text: 'The Detailed View is Not Configured',
          type: ConfirmType.Warning,
          cancelButtonTitle: 'Close',
          hideConfirmButton: true,
        },
      });
    }
  }
}
